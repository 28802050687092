import { ApolloError, gql } from "@apollo/client";
import React from "react";
import { GetCustomerBreakdownConfigs } from "@nb-api-graphql-generated/GetCustomerBreakdownConfigs";
import { CategoricalBreakdownConfig } from "@north-beam/nb-common";
import { useNorthbeamQuery } from "@utils/hooks";

export interface CustomerBreakdownConfigsContextType {
  value?: CategoricalBreakdownConfig[];
  error?: ApolloError;
}

const CustomerBreakdownConfigsContext =
  React.createContext<CustomerBreakdownConfigsContextType>({});

export const useCustomerBreakdownConfigs = () =>
  React.useContext(CustomerBreakdownConfigsContext);

export interface CustomerBreakdownConfigProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

export function CustomerBreakdownConfigProvider(
  props: CustomerBreakdownConfigProviderProps,
) {
  const { data } = useNorthbeamQuery<GetCustomerBreakdownConfigs>(QUERY, {
    pollInterval: 600_000,
    doNotRaiseErrorOnFailure: true,
  });

  const value = data?.me?.customerBreakdownConfigs;

  return (
    <CustomerBreakdownConfigsContext.Provider {...props} value={{ value }} />
  );
}

const QUERY = gql`
  query GetCustomerBreakdownConfigs {
    me {
      id
      customerBreakdownConfigs
    }
  }
`;
