import { PrimaryButton } from "@shared/buttons";
import { Modal } from "@shared/modals/modalV2";

import React, { useState } from "react";
import { useSavedViewsAtom } from "../atoms";
import { Input } from "./ca-top-bar";
import { Button } from "./saved-views-control";

export function CreateSavedViewModal({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) {
  const [savedViews, setSavedViews] = useSavedViewsAtom();
  const [searchText, setSearchText] = useState<string>();

  return (
    <Modal
      isOpen={open}
      widthClassname="w-[37rem]"
      heightClassname="h-[18rem]"
      withOverflow={false}
    >
      <div className="flex flex-col py-5 px-7 relative h-full">
        <div className="text-lg font-semibold">
          Save current page selection as a new view
        </div>
        <div className="mt-5 text-subtitle font-light">Instructions</div>
        <div className="mt-5">
          <div className="text-sm ">SAVED VIEW NAME</div>
          <Input
            className="mt-2 w-[15rem]"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="New saved view name..."
            id="search-metrics"
          />
        </div>
        <div className="absolute bottom-8 right-8">
          <Button
            onClick={() => {
              closeModal();
              setSearchText(undefined);
            }}
            className="mr-4"
          >
            <div className="flex flex-row items-center">Cancel</div>
          </Button>
          <PrimaryButton
            className="bg-cosmic h-[3rem] rounded-lg"
            isDisabled={!searchText?.trim()}
            onClick={() => {
              searchText &&
                setSavedViews([
                  ...savedViews,
                  { id: searchText, isDefault: false, title: searchText },
                ]);
              setSearchText(undefined);
              closeModal();
            }}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
}
