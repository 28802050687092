import microsoftAdsLogo from "@assets/microsoft-logo.svg";
import { useUser } from "@components/user-context";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { ConnectionCard } from "@pages/settings/connection-card";
import {
  ConnectionStatusType,
  LoginComponentChildrenProps,
  LoginComponentWithFrontendIntegrationProps,
} from "@pages/settings/connection-status-manager";
import { CardProps2 } from "@pages/settings/core";
import { extractError } from "@utils/index";
import { launchOAuthPopup } from "@utils/oauth2-handler";
import randomBytes from "randombytes";
import React from "react";
import { serializeError } from "serialize-error";

async function openMicrosoftPopup(
  oauthClientId: string,
  oauthScopes: string,
  nonce: string,
) {
  const params = new URLSearchParams();
  params.append("client_id", oauthClientId);
  params.append(
    "redirect_uri",
    `${window.location.origin}/callbacks/microsoft`,
  );
  params.append("response_type", "code");
  params.append("response_mode", "query");
  params.append("code_challenge", nonce);
  params.append("scope", oauthScopes);

  return launchOAuthPopup(
    `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${params.toString()}`,
  );
}

const type = ConnectionType.integration_microsoft;

export function MicrosoftConnectionCard(props: CardProps2) {
  return (
    <ConnectionCard
      type={type}
      connectionTypeName="Microsoft Ads"
      connectionCallToAction={`
        Integrate your Microsoft Ads data to start receiving insights about
        your Microsoft ad spend.
      `}
      logoUrl={microsoftAdsLogo}
      logoAltText="Microsoft Ads Logo"
    >
      {({ setStatus, accounts }: LoginComponentChildrenProps) => (
        <MicrosoftLoginComponent
          setStatus={setStatus}
          accounts={accounts}
          integrationDetails={props.integrationDetails}
        />
      )}
    </ConnectionCard>
  );
}

function MicrosoftLoginComponent({
  setStatus,
  integrationDetails,
}: LoginComponentWithFrontendIntegrationProps) {
  const { user } = useUser();
  const [isButtonDisabled, setButtonDisabled] = React.useState(false);

  const { microsoftAuthBlob } = integrationDetails;
  const onConnectClicked = React.useCallback(async () => {
    try {
      const nonce = randomBytes(64).toString("hex");
      setButtonDisabled(true);
      const tokenPayload = await openMicrosoftPopup(
        microsoftAuthBlob.client_id,
        microsoftAuthBlob.scope,
        nonce,
      );

      const result = await user.callApi({
        method: "post",
        url: "/api/callbacks/microsoft",
        data: {
          tokenPayload,
          nonce,
          redirectURI: `${window.location.origin}/callbacks/microsoft`,
        },
      });

      if (result.error) {
        setStatus({
          type: ConnectionStatusType.HAS_ERROR,
          errorMessage: `An error occurred: ${serializeError(
            extractError(result.error),
          )}`,
        });
      } else {
        setStatus({
          type: ConnectionStatusType.SELECTING_ACCOUNT,
          accountList: result.accounts,
        });
      }
    } catch (e) {
      setStatus({
        type: ConnectionStatusType.HAS_ERROR,
        errorMessage: `An error occurred: ${serializeError(extractError(e))}`,
      });
    }
    setButtonDisabled(false);
  }, [setStatus, user, microsoftAuthBlob, setButtonDisabled]);

  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-auto">
          <button
            disabled={isButtonDisabled}
            className="btn btn-primary"
            onClick={onConnectClicked}
          >
            {isButtonDisabled ? "Please Wait..." : "Connect"}
          </button>
        </div>
      </div>
    </div>
  );
}
