import React from "react";
import Select from "react-select";
import { ITimeGranularity, TimeGranularity } from "@utils/constants";
import { HierarchyDropdown } from "@shared/selects/dropdown-indicators";

interface TimeGranularitySelectProps {
  className?: string;
  isLoading: boolean;
  timeGranularity: ITimeGranularity;
  onChange: (obj: any) => void;
}

export const TimeGranularitySelect = ({
  className,
  isLoading,
  timeGranularity,
  onChange,
}: TimeGranularitySelectProps) => {
  return (
    <Select
      className={className}
      isDisabled={isLoading}
      isClearable={false}
      isSearchable={false}
      defaultValue={TimeGranularity.toReactSelect(timeGranularity)}
      value={TimeGranularity.toReactSelect(timeGranularity)}
      options={TimeGranularity.reactSelectOptions}
      components={{ DropdownIndicator: HierarchyDropdown }}
      onChange={onChange}
    />
  );
};
