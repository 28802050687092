import { IntegratedSummary, SummaryType } from "@devexpress/dx-react-grid";

const medianSummary = (rows: any[], getValue: (row: any) => any) => {
  if (!rows.length) {
    return null;
  }

  const sortedRows = rows.sort((a: any, b: any) => getValue(a) - getValue(b));
  if (rows.length % 2 === 1) {
    return getValue(sortedRows[(sortedRows.length + 1) / 2]);
  }

  const halfIndex = sortedRows.length / 2;
  return (
    (getValue(sortedRows[halfIndex]) + getValue(sortedRows[halfIndex + 1])) / 2
  );
};

export const summaryCalculator = (
  type: SummaryType,
  rows: any[],
  getValue: (row: any) => any,
) => {
  if (rows.length === 0) {
    return null;
  }

  if (type === "median") {
    return medianSummary(rows, getValue);
  }

  if (!["sum", "avg", "count"].includes(type)) {
    // HACK to use the precalculated summary value
    // TODO: Do the calculation for each row separately with the table or in the API
    return IntegratedSummary.defaultCalculator("sum", rows, getValue);
  }

  return IntegratedSummary.defaultCalculator(type, rows, getValue);
};
