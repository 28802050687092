import React, { Ref, forwardRef } from "react";
import clsx from "clsx";

interface CheckboxProps {
  label?: string;
  value: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
  inputClassName?: string;
  isDisabled?: boolean;
}

export const Checkbox = forwardRef(
  (
    {
      label,
      value,
      onChange,
      className,
      inputClassName,
      isDisabled,
    }: CheckboxProps,
    ref: Ref<any>,
  ) => {
    const toggleChecked = () => !isDisabled && onChange?.(!value);

    return (
      <span className={clsx("inline-flex items-center", className)}>
        <input
          ref={ref}
          className={clsx(
            {
              "cursor-not-allowed pointer-events-none": isDisabled,
              "cursor-pointer": !isDisabled,
            },
            inputClassName,
          )}
          disabled={isDisabled}
          type="checkbox"
          checked={value}
          onChange={toggleChecked}
        />
        {label && (
          <label
            className="ml-2 my-0.5 leading-0 cursor-pointer"
            onClick={toggleChecked}
          >
            {label}
          </label>
        )}
      </span>
    );
  },
);
