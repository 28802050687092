import { gql } from "@apollo/client";
import { Creatives } from "@components/creatives";
import DebugCodeBlock from "@components/DebugCodeBlock";
import { GenericExternalLink } from "@components/generic-external-link";
import {
  ChartArea,
  TimeSeries,
  TimeSeriesPoint,
  TopLevelSummary,
  TopLevelSummaryMetric,
} from "@components/reports/chart-area";
import { CompanionWrapper } from "@components/reports/control-center-utilities";
import { StuckToTop } from "@components/stuck-to-top";
import { TitleSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import {
  GetCampaign,
  GetCampaignVariables,
} from "@nb-api-graphql-generated/GetCampaign";
import {
  DateInterval,
  deslugify,
  iterateHourlyOverInterval,
  iterateOverInterval,
  jsonHash,
  todayLocalAsISODate,
  UnreachableCaseError,
} from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import { CommonGQLFragments } from "@utils/common-gql-fragments";
import { useNorthbeamQuery } from "@utils/hooks";
import {
  addToMetricPoint,
  makeOverviewRows,
  newMetricPoint,
  preprocessForChart,
} from "@utils/metrics";
import { leaves } from "@utils/row-with-children";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BidSettingsBlock } from "./bid-settings";
import { CommonTouchpointsPage } from "./common-touchpoints";
import { ControlCenter } from "./control-center";
import { Hierarchy } from "./hierarchy";
import { LabelEditor } from "./label-editor";
import {
  makeReportStateQuery,
  parseState,
  ReportState,
  reportStateToGQLVariables,
} from "./report-state";
import { SubLevelBreakdownPage } from "./sub-level-breakdown";
import { TargetingSettingsBlock } from "./targeting-settings";
import { AdIcon } from "./utils";

export const GET_CAMPAIGN = gql`
  query GetCampaign(
    $id: ID!
    $dateRange: JSONObject!
    $compareDateRange: JSONObject!
    $attributionMethod: String!
    $attributionWindows: [String!]!
    $nbAccountingMode: String!
    $timeGranularity: String
  ) {
    me {
      id
      adObject(id: $id) {
        id
        idOnPlatform
        nbtPlatformID
        inPlatformURL
        name
        platform
        statusLine
        creativesData
        targetingSettings
        bidSettings
        hierarchy
        metadata
        formattedFacebookData
        formattedGoogleData
        formattedTiktokData
        formattedPinterestData
        labelsDigest {
          key
          value
          isManualLabel
          canDelete
          resolutionOrder
        }
        topLevel(
          dateRange: $dateRange
          compareDateRange: $compareDateRange
          attributionMethod: $attributionMethod
          attributionWindows: $attributionWindows
          nbAccountingMode: $nbAccountingMode
          timeGranularity: $timeGranularity
        ) {
          endDate
          startDate
          rows {
            ...SalesReportRowAll
          }
          hasV1Forecasts
          customGoals {
            id
            displayName
          }
        }

        hasCommonTouchpoints
      }
    }
  }

  ${CommonGQLFragments.salesReportRowAll}
`;

export function AdObjectEditor() {
  const { id: _id } = useParams<{
    id: string;
  }>();
  const id = _id!;
  const { user } = useUser();
  const navigate = useNavigate();
  const { search } = useLocation();

  const [isTopLevelLoading, setTopLevelLoading] = React.useState(false);

  // Tricky chain here:
  // 1. URL is updated with new slug
  // 2. URL slug determines report parameters in child components
  const updateReport = React.useCallback(
    (newReportState: ReportState) => {
      const current = new URLSearchParams(search);
      for (const [key, value] of makeReportStateQuery(
        newReportState,
      ).entries()) {
        current.set(key, value);
      }

      navigate({ search: current.toString() });
    },
    [navigate, search],
  );

  const state = parseState(user, search);

  return (
    <div>
      <StuckToTop>
        {() => (
          <ControlCenter
            reportState={state}
            onUpdate={updateReport}
            isLoading={isTopLevelLoading}
          />
        )}
      </StuckToTop>
      <CompanionWrapper>
        <Inner id={id} state={state} setTopLevelLoading={setTopLevelLoading} />
      </CompanionWrapper>
    </div>
  );
}

interface InnerProps {
  id: string;
  state: ReportState;
  setTopLevelLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function Inner({ id, state, setTopLevelLoading }: InnerProps) {
  const { user } = useUser();
  const anchor = todayLocalAsISODate();
  const gqlVariables = React.useMemo(() => {
    return reportStateToGQLVariables(state, anchor);
  }, [state, anchor]);

  const { loading, data } = useNorthbeamQuery<
    GetCampaign,
    GetCampaignVariables
  >(GET_CAMPAIGN, {
    variables: {
      id,
      ...gqlVariables,
    },
  });

  const adObject = data?.me.adObject;

  React.useEffect(() => {
    setTopLevelLoading(loading);
  }, [setTopLevelLoading, loading]);

  const modifiedTopLevel = React.useMemo(() => {
    if (!adObject) {
      return null;
    }

    const { topLevel } = adObject;

    if (!topLevel) {
      return null;
    }

    if (topLevel.rows.length === 0) {
      return { metrics: [] };
    }

    const metrics = makeOverviewRows(
      gqlVariables.attributionMethod,
      state.attributionWindow,
      false,
      state.nbAccountingMode,
      topLevel.customGoals,
      state.timeGranularity,
      user,
    );
    const chartableRowsRoot = preprocessForChart(topLevel.rows, [], []);

    if (!chartableRowsRoot) {
      return null;
    }

    const rv: TopLevelSummary = {
      metrics: metrics.map((metric) => {
        const serieses: TimeSeries[] = [];
        const leafRows = leaves(chartableRowsRoot);
        const currentTotal = newMetricPoint();
        const compareTotal = newMetricPoint();
        for (const date in chartableRowsRoot.series) {
          const point = chartableRowsRoot.series[date];
          const target =
            date >= topLevel.startDate ? currentTotal : compareTotal;
          addToMetricPoint(target, point);
        }

        const currentValue = metric.calculate(currentTotal);
        const compareValue = metric.calculate(compareTotal);
        const { isPositiveChangeGood, formatApproximate } = metric;

        for (const leafRow of leafRows) {
          const name =
            leafRow.description.length === 0
              ? "All"
              : leafRow.description
                  .map((v) => v.value || "(not set)")
                  .join(" / ");
          const points: TimeSeriesPoint[] = [];
          const dateRange = {
            startDate: topLevel.startDate,
            endDate: topLevel.endDate,
          };

          let dates: string[] = [];
          if (state.timeGranularity === "daily") {
            dates = Array.from(iterateOverInterval(dateRange));
          } else if (state.timeGranularity === "hourly") {
            dates = Array.from(iterateHourlyOverInterval(dateRange));
          }

          // Value
          let value = 0;
          for (const date of dates) {
            const metricPoint = leafRow.series[date];
            const value_ = metricPoint ? metric.calculate(metricPoint) : 0.0;
            if (metric.displayCumulativeForChart) {
              value += value_;
            } else {
              value = value_;
            }
            points.push({ date, value });
          }
          const chartSeries: TimeSeries = { name, points };
          serieses.push(chartSeries);
        }

        return {
          currentValue: formatApproximate(currentValue),
          format: metric.format,
          name: metric.name,
          xAxisFormat: "date",
          timeSerieses: serieses,
          description: metric.descriptionHTML,
          hideComparisonFromTabDisplay:
            metric.hideComparisonFromTabDisplay ?? false,
          changeFractionAreaText: metric.changeFractionAreaTextForTabDisplay,
          comparison: {
            changeFraction: (currentValue - compareValue) / compareValue,
            isPositiveChangeGood,
          },
        } as TopLevelSummaryMetric;
      }),
    };

    return rv;
  }, [adObject, gqlVariables.attributionMethod, state, user]);

  if (loading) {
    return <TitleSlide>Please wait...</TitleSlide>;
  }

  if (!adObject) {
    return (
      <TitleSlide>
        Could not find this Traffic Source. Please contact Northbeam support!
      </TitleSlide>
    );
  }

  const {
    name,
    creativesData,
    metadata,
    nbtPlatformID,
    idOnPlatform,
    inPlatformURL,
    bidSettings,
    platform,
    targetingSettings,
    statusLine,
    labelsDigest,
    hierarchy,
    hasCommonTouchpoints,
  } = adObject;

  return (
    <div className="px-4">
      <div className="row my-5">
        <div className="col">
          <h1 className="text-truncate flex items-center" title={name}>
            <AdIcon platform={nbtPlatformID} sizeInEM={1} />
            {name}
          </h1>
          <div className="row mt-2">
            <div className="col">
              <StatusLine
                idOnPlatform={idOnPlatform}
                inPlatformURL={inPlatformURL}
                platform={platform}
                statusLine={statusLine}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col">
          <h3>Performance</h3>
          <TopLevel
            platform={platform}
            topLevel={modifiedTopLevel}
            start={gqlVariables.dateRange.startDate}
            end={gqlVariables.dateRange.endDate}
          />
        </div>
      </div>
      <div className="row my-5">
        <div className="col">
          <h3>Details</h3>
          <DetailsSection
            id={id}
            state={state}
            anchor={anchor}
            platform={platform}
            creativesData={creativesData}
            targetingSettings={targetingSettings}
            bidSettings={bidSettings}
            labels={labelsDigest}
            metadata={metadata}
            dateRange={gqlVariables.dateRange}
            hierarchy={hierarchy}
            // Note, all view models end with "__va", which is how we are able to remove Common Click Touchpoints from these models
            hasCommonTouchpoints={
              hasCommonTouchpoints && !state.attributionModel.endsWith("__va")
            }
          />
        </div>
      </div>
    </div>
  );
}

interface DescriptionItem {
  key: string;
  value: string;
}

function StatusLine(props: {
  platform: string;
  idOnPlatform: string | null;
  inPlatformURL: string | null;
  statusLine: DescriptionItem[];
}) {
  const { platform, idOnPlatform, inPlatformURL, statusLine } = props;

  const finalStatusLine = React.useMemo(() => {
    const rv: React.ReactNode[] = statusLine.map((kv) => (
      <StatusLineItem
        itemKey={kv.key}
        itemValue={kv.value}
        key={jsonHash(kv)}
      />
    ));

    if (idOnPlatform && inPlatformURL) {
      rv.push(
        <div
          className="d-inline-block"
          key={jsonHash({ idOnPlatform, inPlatformURL })}
        >
          <GenericExternalLink
            href={inPlatformURL}
            onClick={() => {
              logEvent("Clicked In Platform URL", {
                Platform: platform,
                "Campaign ID": idOnPlatform,
              });
            }}
          >
            View in platform <i className="fas fa-external-link-alt"></i>
          </GenericExternalLink>
        </div>,
      );
    }
    return rv.reduce<React.ReactNode[]>(
      (accu, el, idx) =>
        accu === null
          ? [el]
          : [
              ...accu,
              <div className="d-inline-block mx-2" key={`spacer-${idx}`}>
                &bull;
              </div>,
              el,
            ],
      [],
    );
  }, [statusLine, inPlatformURL, idOnPlatform, platform]);

  return <div>{finalStatusLine}</div>;
}

function StatusLineItem(props: { itemKey: string; itemValue: string }) {
  const { itemKey: key, itemValue: value } = props;
  return (
    <div className="d-inline-block">
      <span className="font-weight-bold">{key}:</span>{" "}
      <span className="text-muted">{value}</span>
    </div>
  );
}

function TopLevel(props: {
  start: string;
  end: string;
  platform: string;
  topLevel?: TopLevelSummary | null;
}) {
  const { topLevel, start, end } = props;

  if (!topLevel) {
    return (
      <div className="row no-gutter mb-3">
        <div className="col">
          <div className="card">
            <div className="card-body">
              No performance data for this Traffic Source.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (topLevel.metrics.length === 0) {
    return (
      <div className="row no-gutter mb-3">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div>
                No performance data for this Traffic Source from{" "}
                <b>{moment(start).format("MMM DD, YYYY")}</b> –{" "}
                <b>{moment(end).format("MMM DD, YYYY")}</b>.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row no-gutter mb-3">
      <div className="col">
        <ChartArea topLevel={topLevel} isLoading={false} />
      </div>
    </div>
  );
}

const TABS = [
  "Performance Breakdown",
  "Creatives",
  "Targeting Settings",
  "Bid & Budget",
  "Hierarchy",
  "Common Click Touchpoints",
  "Breakdown Labels",
  "Raw Metadata",
] as const;
type Tab = (typeof TABS)[number];

export function DetailsSection(props: {
  id: string;
  anchor: string;
  state: ReportState;
  platform: string;
  creativesData: any;
  targetingSettings: any | null;
  bidSettings: any | null;
  labels: any | null;
  metadata: any;
  dateRange: DateInterval;
  hierarchy: any;
  hasCommonTouchpoints: boolean;
}) {
  const {
    id,
    state,
    creativesData,
    targetingSettings,
    bidSettings,
    metadata,
    labels,
    dateRange,
    hierarchy,
    hasCommonTouchpoints,
  } = props;

  const adKey = deslugify(id);

  const tabNameToDisplayObject: Record<Tab, any> = {
    "Breakdown Labels": labels,
    "Performance Breakdown": !adKey.ad,
    Creatives: creativesData,
    "Targeting Settings": targetingSettings,
    "Bid & Budget": bidSettings,
    Hierarchy: hierarchy,
    "Common Click Touchpoints": hasCommonTouchpoints,
    "Raw Metadata": metadata,
  };
  const tabsForAdObject = TABS.filter((t) => !!tabNameToDisplayObject[t]);

  const [tab, setTab] = React.useState<Tab>(tabsForAdObject[0]);

  let component: React.ReactNode | null = null;
  if (tab === "Breakdown Labels") {
    component = <LabelEditor id={id} labels={labels} />;
  } else if (tab === "Creatives") {
    component = <Creatives {...creativesData} />;
  } else if (tab === "Targeting Settings") {
    component = (
      <TargetingSettingsBlock targetingSettings={targetingSettings} />
    );
  } else if (tab === "Bid & Budget") {
    component = <BidSettingsBlock result={bidSettings} />;
  } else if (tab === "Hierarchy") {
    component = <Hierarchy hierarchy={hierarchy} />;
  } else if (tab === "Raw Metadata") {
    component = <DebugCodeBlock data={metadata} />;
  } else if (tab === "Common Click Touchpoints") {
    logEvent("Open Common Click Touchpoints in details section", { state });
    component = (
      <CommonTouchpointsPage id={id} dateRange={dateRange} state={state} />
    );
  } else if (tab === "Performance Breakdown") {
    component = <SubLevelBreakdownPage id={id} state={state} />;
  } else {
    throw new UnreachableCaseError(tab);
  }

  return (
    <div className="flex">
      <div className="text-nowrap">
        <div className="tab-link-group-vertical">
          {tabsForAdObject.map((t) => {
            return (
              <button
                key={t}
                className={classNames({
                  "tab-link": true,
                  "tab-link-right": true,
                  active: t === tab,
                })}
                onClick={() => {
                  logEvent("Selects Traffic Source Detail Tab", {
                    "Tab name": t,
                  });
                  setTab(t);
                }}
              >
                {t}
              </button>
            );
          })}
        </div>
      </div>
      <div className="flex-grow-1 border rounded p-3" style={{ width: 1 }}>
        {component}
      </div>
    </div>
  );
}
