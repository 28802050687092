import React from "react";
import { UtmGuidePlatformProps } from "..";
import { ConstructDestinationUrlComponent } from "./shared/construct-destination-url-component";

export function CNN5Things({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <ol>
        <ConstructDestinationUrlComponent urlParamPairs={urlParamPairs} />
      </ol>
    </>
  );
}
