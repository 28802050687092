import { useUser } from "@components/user-context";
import { FetchSanitizedConnectionsByType, FetchSanitizedConnectionsByTypeVariables, FetchSanitizedConnectionsByType_me_sanitizedConnections } from "@nb-api-graphql-generated/FetchSanitizedConnectionsByType";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import {
  AccountValidationInfo,
  ConnectionIssue,
  ConnectionValidationResult,
} from "@north-beam/nb-common";
import { jsonHash } from "@north-beam/nb-common";
import { useNorthbeamQuery } from "@utils/hooks";
import _ from "lodash";
import React, { CSSProperties, useEffect, useState } from "react";
import AddAdditionalAccount from "./add-additional-account";
import ConnectionStatusManager, {
  LoginComponentChildrenProps,
} from "./connection-status-manager";
import DeleteConnectionButton from "./delete-connection-button";
import ManageExistingConnection from "./manage-existing-connection";
import { FETCH_SANITIZED_CONNECTIONS_BY_TYPE } from "./queries";
import ShopifyStatusLine from "./shopify-status-line";
import UpdateLoginCustomerId from "./update-login-customer-id";

type Connection = FetchSanitizedConnectionsByType_me_sanitizedConnections;

enum ConnectionStatusType {
  INITIAL_LOADING = "InitialLoading",
  SUCCESSFULLY_CONNECTED = "SuccessfullyConnected",
  REQUIRES_CONNECTION = "RequiresConnection",
  HAS_ERROR = "HasError",
}
interface InitialLoading {
  type: ConnectionStatusType.INITIAL_LOADING;
  connection?: null;
  connections?: null;
  validationResults?: null;
}

interface SuccessfullyConnected {
  type: ConnectionStatusType.SUCCESSFULLY_CONNECTED;
  connection: Connection;
  connections: Connection[];
  validationResults: ConnectionValidationResult[];
}

interface RequiresConnection {
  type: ConnectionStatusType.REQUIRES_CONNECTION;
  connection: Connection | null;
  connections: Connection[];
  validationResults: ConnectionValidationResult[];
}
interface HasError {
  type: ConnectionStatusType.HAS_ERROR;
  errorMessage: string;
  connection?: null;
  connections?: null;
  validationResults?: ConnectionValidationResult[];
}

type Status =
  | InitialLoading
  | SuccessfullyConnected
  | RequiresConnection
  | HasError;

const divCardStyle: CSSProperties = {
  minHeight: "5em",
};

export interface ConnectionComponentProps {
  id: string | null;
  integrationDetails: any;
}

export interface ConnectionCardProps {
  type: ConnectionType;
  connectionTypeName: string;
  connectionCallToAction: string;
  logoUrl: string;
  logoAltText: string;
  children: (props: LoginComponentChildrenProps) => React.ReactNode;
  successfulConnectionExtraText?: () => React.ReactNode;
  allowsMultipleAccounts?: boolean;
  cardFooter?: React.ReactNode;
}

export function ConnectionCard({
  type,
  connectionTypeName,
  connectionCallToAction,
  logoUrl,
  logoAltText,
  children,
  successfulConnectionExtraText,
  allowsMultipleAccounts,
  cardFooter,
}: ConnectionCardProps) {
  const [allProfiles, setAllProfiles] = useState<any>([]);
  const { data, loading: fetchLoading } = useNorthbeamQuery<
    FetchSanitizedConnectionsByType,
    FetchSanitizedConnectionsByTypeVariables
  >(FETCH_SANITIZED_CONNECTIONS_BY_TYPE, {
    variables: { type },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const tempAllProfiles: any[] = [];
    data?.me?.sanitizedConnections.forEach((connection) => {
      tempAllProfiles.push(connection.allProfiles);
    });
    setAllProfiles(tempAllProfiles);
  }, [data]);

  const { user } = useUser();
  const [status, setStatus] = useState<Status>({
    type: ConnectionStatusType.INITIAL_LOADING,
  });

  useEffect(() => {
    if (fetchLoading) {
      setStatus({
        type: ConnectionStatusType.INITIAL_LOADING,
      });
    } else {
      // TODO - refactor the singular connection, it is needed to handle multiple accounts in metadata
      // handle this by making each of those account into its own connection in connections, then remove the logic to display multiple accounts in metadata
      const connection = data?.me.sanitizedConnections[0] ?? null;
      const connections: Connection[] = data?.me.sanitizedConnections || [];
      const validationResults: ConnectionValidationResult[] = [];
      if (connections.length) {
        connections.forEach((connection) => {
          const connectionValidation =
            connection.connectionValidation as ConnectionValidationResult;
          validationResults.push(connectionValidation);
        });
      }

      const connectedAccounts: AccountValidationInfo[] = [];
      validationResults.forEach((result) => {
        if (result.connectedAccounts.length) {
          connectedAccounts.push(...result.connectedAccounts);
        }
      });

      const allConnectionLevelIssues: ConnectionIssue[] = [];
      validationResults.forEach((result) => {
        allConnectionLevelIssues.push(...result.connectionLevelIssues);
      });
      if (
        !connection ||
        !validationResults.length ||
        allConnectionLevelIssues.some((v) => v.reconnectRecommended) ||
        connectedAccounts.some((v) => v.connectionIssue?.reconnectRecommended)
      ) {
        setStatus({
          type: ConnectionStatusType.REQUIRES_CONNECTION,
          connection,
          connections,
          validationResults,
        });
      } else {
        setStatus({
          type: ConnectionStatusType.SUCCESSFULLY_CONNECTED,
          connection: connection as Connection,
          connections,
          validationResults,
        });
      }
    }
  }, [data, fetchLoading]);

  const connectedAccounts: AccountValidationInfo[] = [];
  if (status.validationResults) {
    // amazon seller has multiple validation results and each result has multiple connected accounts
    // get all connected accounts for each validation result
    status.validationResults.forEach((result) => {
      if (result?.connectedAccounts.length) {
        connectedAccounts.push(...result.connectedAccounts);
      }
    });
  }

  const shouldAllowMultipleConnections =
    allowsMultipleAccounts &&
    user.isAdmin &&
    status.type === ConnectionStatusType.SUCCESSFULLY_CONNECTED &&
    status?.connection?.id;

  const renderDeleteConnectionButton = (accountId: string) => {
    if (!status?.connection) {
      return null;
    }
    const additionalAccounts =
      status?.connection?.data.metadata.extraAccountIds || [];
    return (
      <ManageExistingConnection
        connectionId={status.connection.id}
        connectionType={type}
      >
        {(updateConnection, updateInfo, destroyConnection, destroyInfo) => (
          <DeleteConnectionButton
            updateConnection={updateConnection}
            updateInfo={updateInfo}
            destroyConnection={destroyConnection}
            destroyInfo={destroyInfo}
            additionalAccounts={additionalAccounts}
            accountId={accountId}
            connectionType={type}
          />
        )}
      </ManageExistingConnection>
    );
  };

  function renderAccountValidationInfoAsStatusLine(
    account: AccountValidationInfo,
  ) {
    if (status.connection?.type === ConnectionType.integration_shopify) {
      return (
        <ShopifyStatusLine
          connectionIssue={account.connectionIssue}
          shopUrl={account.name}
        />
      );
    }
    const emoji = account.connectionIssue ? "🚨" : "✔️";
    const suffix = account.connectionIssue ? (
      <tr>
        <td colSpan={4}>
          <div
            className="alert alert-warning mt-1"
            style={{ whiteSpace: "pre-line" }}
          >
            <b>Error</b>: <i>{account.connectionIssue.message}</i>
          </div>
        </td>
      </tr>
    ) : null;
    const id =
      typeof account.accountIdentifier === "string"
        ? account.accountIdentifier
        : jsonHash(account.accountIdentifier);

    let amazonSellerIdString = "";
    if (status.connection?.type === ConnectionType.integration_amazon_seller) {
      const countryCountMap: any = _.countBy(id.split(", "));
      Object.keys(countryCountMap).forEach((country) => {
        amazonSellerIdString += `${country}(${countryCountMap[country]}) `;
      });
    }
    return (
      <React.Fragment key={id}>
        <tr className="tr text-small">
          <td className="td">{emoji}</td>
          <td className="td">{account.name}</td>
          <td className="td text-xs break-words">
            {status.connection?.type ===
            ConnectionType.integration_amazon_seller
              ? amazonSellerIdString
              : id}
          </td>
          {user.isAdmin && (
            <td className="td">{renderDeleteConnectionButton(id)}</td>
          )}
        </tr>
        {suffix}
      </React.Fragment>
    );
  }

  function renderConnectionLevelIssueAsStatusLine(
    connectionIssue: ConnectionIssue,
  ) {
    const emoji = "🚨";
    return (
      <li key={jsonHash(connectionIssue)}>
        {emoji} [Connection-level] <b>Error</b>:{" "}
        <i>{connectionIssue.message}</i>
      </li>
    );
  }

  const shouldAllowLoginCustomerAccountChange = () =>
    type === ConnectionType.integration_google_adwords;

  const renderManagementSection = () => {
    if (
      status.type === ConnectionStatusType.INITIAL_LOADING ||
      status?.connection === null
    ) {
      return null;
    }

    if (shouldAllowMultipleConnections && status.connection?.id) {
      return (
        <ManageExistingConnection
          connectionId={status.connection.id}
          connectionType={type}
        >
          {(updateConnection) => (
            <>
              {shouldAllowLoginCustomerAccountChange() && (
                <UpdateLoginCustomerId
                  loginCustomerId={status?.connection?.data.login_customer_id}
                  updateConnection={updateConnection}
                />
              )}
              <AddAdditionalAccount
                integrationType={status?.connection?.type}
                connectedAccounts={connectedAccounts}
                extraAccountIds={
                  status?.connection?.data.metadata.extraAccountIds
                }
                updateConnection={updateConnection}
                availableAccounts={allProfiles}
              />
            </>
          )}
        </ManageExistingConnection>
      );
    } else {
      return null;
    }
  };

  const renderSuccessfulConnection = () => {
    if (
      status.type !== ConnectionStatusType.SUCCESSFULLY_CONNECTED ||
      shouldAllowMultipleConnections
    ) {
      return null;
    }
    if (successfulConnectionExtraText) {
      return successfulConnectionExtraText();
    }
    return (
      <p className="card-text">
        <em>
          Note: if you want to connect a different account, please contact us!
        </em>
      </p>
    );
  };

  let content: React.ReactNode;

  const shouldRenderShopifyExtraAccounts =
    status.connection?.type === ConnectionType.integration_shopify &&
    connectedAccounts.length;

  if (status.type === ConnectionStatusType.INITIAL_LOADING) {
    content = (
      <div style={divCardStyle} className="text-center flex items-center">
        <p>Please wait...</p>
      </div>
    );
  } else if (
    status.type === ConnectionStatusType.SUCCESSFULLY_CONNECTED ||
    shouldRenderShopifyExtraAccounts
  ) {
    content = (
      <>
        <p className="card-text">Currently connected! Linked accounts:</p>
        <table className="table" style={{ tableLayout: "fixed" }}>
          <thead>
            <tr className="tr">
              <th className="th w-1/12"></th>
              <th className="th w-1/4">Name</th>
              <th className="th">Details</th>
              {user.isAdmin &&
                status.connection?.type !==
                  ConnectionType.integration_shopify && (
                  <th className="th w-1/5">Manage</th>
                )}
            </tr>
          </thead>
          <tbody>
            {connectedAccounts.map(renderAccountValidationInfoAsStatusLine)}
          </tbody>
        </table>

        {status.connection?.type ===
          ConnectionType.integration_amazon_seller && (
          <ConnectionStatusManager
            id={null}
            type={type}
            accounts={connectedAccounts}
          >
            {children}
          </ConnectionStatusManager>
        )}

        {renderManagementSection()}
        {status.connection?.type !== ConnectionType.integration_amazon_seller &&
          renderSuccessfulConnection()}
      </>
    );
  } else {
    let header = null;
    if (status.validationResults?.length) {
      const allConnectionLevelIssues: ConnectionIssue[] = [];
      status.validationResults.forEach((result) => {
        allConnectionLevelIssues.push(...result.connectionLevelIssues);
      });
      const statusLines = [
        ...connectedAccounts
          .filter((v) => v.connectionIssue?.reconnectRecommended)
          .map(renderAccountValidationInfoAsStatusLine),
        ...allConnectionLevelIssues.map(renderConnectionLevelIssueAsStatusLine),
      ];
      header = (
        <div className="alert alert-danger" role="alert">
          <p>We ran into a few issues! Please reconnect your account below.</p>
          <ul className="mb-0">{statusLines}</ul>
        </div>
      );
    }
    content = (
      <>
        {header}
        <p className="card-text">{connectionCallToAction}</p>
        <ConnectionStatusManager
          id={status.connection?.id ?? null}
          type={type}
          accounts={connectedAccounts}
        >
          {children}
        </ConnectionStatusManager>
        {renderManagementSection()}
      </>
    );
  }

  return (
    <div className="card mb-3 rounded">
      <div className="card-header flex">
        <div className="flex-fill">
          <img
            src={logoUrl}
            alt={logoAltText}
            className="align-self-start mr-3"
            style={{
              height: "2rem",
              width: "auto",
            }}
          />
          {connectionTypeName}
        </div>
      </div>
      <div className="card-body">
        {content}
        {cardFooter}
      </div>
    </div>
  );
}
