import moment, { MomentInput } from "moment";

/**
 * Function to calculate the difference in days between two datetimes rounded up.
 * Rounded up means if the difference in 2 datetime is 26 hours than it would return 2.
 * If the difference in 2 datetimes is 5 hours than it would return 1.
 * @param {MomentInput} datetime1 - The first datetime.
 * @param {MomentInput} datetime2 - The second datetime.
 * @returns {Number} - Diff in datetimes rounded up
 */
export const differenceInDays = (
  datetime1: MomentInput,
  datetime2: MomentInput,
) => {
  const start = moment(datetime1);
  const end = moment(datetime2);
  const diffInHours = end.diff(start, "hours");
  const diffInDays = diffInHours / 24;
  return Math.ceil(diffInDays);
};

/**
 * Function to get the date object with time set to the start of the day of the local timezone.
 * @param {string|number} - The date string in YYYY-MM-DD format or number in milliseconds
 * @returns {Date} - The date object with time set to the start of the day of the local timezone
 */
export const getDateOnly = (date: string | number) => {
  const dt = new Date(date);
  const dateOnly = new Date(dt.toISOString().split("T")[0]);
  const minutesOffset = dateOnly.getTimezoneOffset() * 60;
  const dtDateOnly = new Date(dateOnly.valueOf() + minutesOffset * 1000);
  return dtDateOnly;
};
