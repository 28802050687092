import React from "react";
import classNames from "classnames";
import { PropsWithChildren } from "react";

export function OrderCard({
  className,
  children,
}: PropsWithChildren<{ className: string }>) {
  return (
    <div
      className={classNames("bg-white rounded-md elevation-1", className)}
    >
      {children}
    </div>
  );
}
