import {
  decodeJson,
  encodeJson,
  QueryParamConfig,
  useQueryParam,
} from "use-query-params";
import { DateInterval } from "@north-beam/nb-common";
import { isEqual } from "lodash";

type AnnotationParam = DateInterval | null;

const DateRangeJsonParam: QueryParamConfig<AnnotationParam> = {
  encode: (value) => encodeJson(value),
  decode: (value) => decodeJson(value),
  equals: (a, b) => isEqual(a, b),
};

export const useAnnotationParam = () => {
  const [annotationParam, setAnnotationParam] = useQueryParam<AnnotationParam>(
    "annotations",
    DateRangeJsonParam,
  );
  const [createAnnotationDateParam, setCreateAnnotationDateParam] =
    useQueryParam<string | undefined>("createAnnotationDate", undefined);
  const [
    filteredAnnotationDateRangeParam,
    setFilteredAnnotationDateRangeParam,
  ] = useQueryParam<AnnotationParam>(
    "filteredAnnotationDateRange",
    DateRangeJsonParam,
  );

  const listDateRangeAnnotations = (value: AnnotationParam) =>
    setAnnotationParam(value);
  const listFilteredDateRangeAnnotations = (value: AnnotationParam) =>
    setFilteredAnnotationDateRangeParam(value);
  const removeFilteredDateRangeAnnotations = () =>
    setFilteredAnnotationDateRangeParam(null);
  const showCreateAnnotationOnDate = (annotatedDate: string) =>
    setCreateAnnotationDateParam(annotatedDate);
  const closeFlyout = () => setAnnotationParam(null);

  return {
    annotationParam,
    setAnnotationParam,
    isFlyoutOpen: Boolean(annotationParam),
    closeFlyout,
    listDateRangeAnnotations,

    filteredAnnotationDateRangeParam,
    removeFilteredDateRangeAnnotations,
    listFilteredDateRangeAnnotations,
    showCreateAnnotationOnDate,
    createAnnotationDateParam,
    setCreateAnnotationDateParam,
  };
};
