import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { InterleaveSpans } from "@components/utilities";
import {
  AlarmConditionV2,
  AnomalyCondition,
  isSimpleAlarmCondition,
  MetricValueConfig,
  Subject,
} from "@north-beam/nb-common";
import { Breakdown } from "@north-beam/nb-common";
import { MetricFormat as ProtocolMetricFormat } from "@north-beam/nb-common";
import { formatNumberExact, jsonHash } from "@north-beam/nb-common";
import Interweave from "interweave";
import React from "react";

export function FormatSubject(props: { subject: Subject }) {
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const { subject } = props;

  const breakdownKeyToValues: Record<
    string,
    Record<string, string>
  > = React.useMemo(() => {
    const rv: Record<string, Record<string, string>> = {};
    for (const breakdown of breakdownConfigs) {
      const { key, choices } = breakdown;
      rv[key] = {};
      for (const { value, label } of choices) {
        rv[key][value] = label;
      }
    }
    return rv;
  }, [breakdownConfigs]);

  const breakdownToName: Record<string, string> = React.useMemo(() => {
    const rv: Record<string, string> = {};
    for (const breakdown of breakdownConfigs) {
      rv[breakdown.key] = breakdown.name;
    }
    return rv;
  }, [breakdownConfigs]);

  switch (subject.type) {
    case "all": {
      return <p>All campaigns (blended)</p>;
    }
    case "individuals": {
      return <p>Individual campaigns</p>;
    }
    case "groupedBy": {
      const { param } = subject;
      return (
        <React.Fragment>
          <p>
            Campaigns by{" "}
            <InterleaveSpans
              text={param.map((v) => breakdownToName[v.key] ?? v.key)}
              sep=", "
              Wrapper={Strong}
            />{" "}
            (blended):
          </p>
          <PropertyTable
            breakdowns={param}
            breakdownKeyToValues={breakdownKeyToValues}
          />
        </React.Fragment>
      );
    }
    case "individualsMatching": {
      const { param } = subject;
      return (
        <>
          <p>Active individual campaigns matching:</p>
          <PropertyTable
            breakdowns={param}
            breakdownKeyToValues={breakdownKeyToValues}
          />
        </>
      );
    }
  }
}

function PropertyTable({
  breakdownKeyToValues,
  breakdowns,
}: {
  breakdownKeyToValues: Record<string, Record<string, string>>;
  breakdowns: Breakdown[];
}) {
  return (
    <table className="table table-sm table-bordered" style={{ fontSize: 12 }}>
      <thead className="thead-light">
        <tr>
          <th>Key</th>
          <th>Values</th>
        </tr>
      </thead>
      <tbody>
        {breakdowns.map(({ key, values }) => (
          <tr key={key}>
            <td>{key}</td>
            <td>
              <ul>
                {values.map((v) => (
                  <li key={v}>{breakdownKeyToValues[key]?.[v] ?? v}</li>
                ))}
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function FormatMetricValueConfig({
  capitalized,
  config,
  metricFormat,
  metricName,
}: {
  capitalized: boolean;
  config: MetricValueConfig;
  metricFormat: ProtocolMetricFormat;
  metricName: string;
}) {
  if (config.type === "FixedValueConfig") {
    return <b>{formatNumberExact(config.value, metricFormat)}</b>;
  }
  const { windowEndOffsetDays, windowStartOffsetDays } = config;
  const article = capitalized ? "The" : "the";
  return (
    <Interweave
      content={`${article} average ${metricName} between <b>${windowStartOffsetDays} days ago</b> and <b>${windowEndOffsetDays} days ago</b>`}
    />
  );
}

function FormatAnomalyCondition({
  anomalyCondition,
}: {
  anomalyCondition: AnomalyCondition;
}) {
  if (anomalyCondition.type === "OutsidePercentageOfReferenceValue") {
    return (
      <Interweave
        content={`deviates by more than <b>${formatNumberExact(
          anomalyCondition.percentage,
          "percentage",
        )}</b>`}
      />
    );
  }

  if (anomalyCondition.type === "AboveReferenceValue") {
    return <>goes above</>;
  }

  return <>goes below</>;
}

export function FormatAlarmCondition(props: {
  alarmCondition: AlarmConditionV2;
}) {
  const { alarmCondition } = props;
  if (isSimpleAlarmCondition(alarmCondition)) {
    const { anomalyCondition, currentValue, referenceValue, metric } =
      alarmCondition;

    return (
      <>
        <FormatMetricValueConfig
          capitalized={true}
          metricName={metric.name}
          config={currentValue}
          metricFormat={metric.format}
        />{" "}
        <FormatAnomalyCondition anomalyCondition={anomalyCondition} />{" "}
        <FormatMetricValueConfig
          capitalized={false}
          metricName={metric.name}
          config={referenceValue}
          metricFormat={metric.format}
        />
      </>
    );
  } else {
    const { combinator, children } = alarmCondition;

    const text =
      combinator === "and"
        ? "All of the following"
        : "At least one of the following";

    return (
      <>
        {text}:
        <ul>
          {children.map((child) => (
            <li key={jsonHash(child)}>
              <FormatAlarmCondition alarmCondition={child} />{" "}
            </li>
          ))}
        </ul>
      </>
    );
  }
}

const Strong = ({ children, ...rest }: any) => (
  <strong {...rest}>{children}</strong>
);
