import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";

import { Menu, Transition } from "@headlessui/react";
import { PrimaryButton } from "@shared/buttons";
import { Modal } from "@shared/modals/modalV2";
import clsx from "clsx";
import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { useSavedViewsAtom } from "../atoms";
import { CreateSavedViewModal } from "./create-saved-view-modal";
import { SelectedSavedView } from "./selected-saved-view";

export function SavedViewsControl() {
  return (
    <div className="flex flex-row items-center ml-5 ">
      <SavedViewSelector />
      <SavedViewCreator />
      <div className="h-[4rem] border-l-[1.5px] border-#C1C5CD" />
    </div>
  );
}

function SavedViewCreator() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setModalIsOpen(true)} className="ml-3 mr-2">
        <div className="flex flex-row items-center">
          <span>Save</span>
          <i className="ml-3 text-lg fa-regular fa-circle-plus"></i>
        </div>
      </Button>
      <CreateSavedViewModal
        open={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
      />
    </>
  );
}

function SavedViewSelector() {
  const [savedViewOptions] = useSavedViewsAtom();
  const [selectedSaveView, setSelectedSaveView] = useState(savedViewOptions[0]);
  const [savedViewManagerModalIsOpen, setSavedViewMangerModalIsOpen] =
    useState(false);
  return (
    <div className="flex flex-row items-center">
      <SavedViewsModal
        open={savedViewManagerModalIsOpen}
        closeModal={() => setSavedViewMangerModalIsOpen(false)}
      />
      <div className="text-2xl font-semibold  w-[15rem] truncate">
        {selectedSaveView.title}
      </div>
      <Menu as="div" className="ml-5 mr-2 relative">
        {({ open }) => (
          <>
            <Menu.Button>
              <div
                className={clsx(
                  "flex items-center justify-center h-[2.3rem] w-[2.3rem]  border-[#8d929a] border-[1px] rounded-full",
                  {
                    "bg-[#F3F5FF]": open,
                    "bg-white": !open,
                    "hover:bg-[#F3F5FF]": true,
                  },
                )}
              >
                <i
                  className={clsx(
                    "font-semibold fa-solid fa-sharp cursor-pointer text-cosmic text-sm ",
                    {
                      "fa-chevron-down": !open,
                      "fa-chevron-up": open,
                    },
                  )}
                />
              </div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform ease-out duration-100"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute -right-5 z-10 mt-2 w-[19rem] origin-top-right rounded-[8px] bg-white elevation-1 pb-5 pt-4 pl-6 pr-2">
                <div className="font-semibold flex flex-row w-full items-center">
                  <div>Saved Views</div>
                  <button
                    className="ml-auto flex flex-row items-center text-cosmic cursor-pointer text-sm hover:bg-cosmic-3 hover:rounded-2xl px-3 py-1"
                    onClick={() => setSavedViewMangerModalIsOpen(true)}
                  >
                    <i className="mr-2.5 text-lg fa-solid fa-gear"></i>
                    <div>MANAGE</div>
                  </button>
                </div>
                <div>
                  {savedViewOptions.map((view) => {
                    return (
                      <Menu.Item key={view.title}>
                        <MenuItem
                          title={view.title}
                          isDefault={view.isDefault}
                          active={view.title === selectedSaveView.title}
                          onClick={() => setSelectedSaveView(view)}
                        />
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

function SavedViewsModal({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) {
  const [savedViews, setSavedViews] = useSavedViewsAtom();
  const [modifiedSaveViews, setModifiedSavedViews] = useState(savedViews);
  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    setModifiedSavedViews(savedViews);
  }, [savedViews]);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = modifiedSaveViews.findIndex(
        ({ id }) => id === active.id,
      );
      const newIndex = modifiedSaveViews.findIndex(({ id }) => id === over?.id);

      const newOrdering = arrayMove(modifiedSaveViews, oldIndex, newIndex);
      setModifiedSavedViews(newOrdering);
    }
  };

  return (
    <Modal
      isOpen={open}
      widthClassname="w-[40rem]"
      heightClassname="h-[45rem]"
      withOverflow={false}
      testId="metric-customization-modal"
    >
      <div className="flex flex-col py-5 px-7 relative h-full">
        <div className="text-lg font-semibold">Manage saved views</div>
        <div className="mt-5 text-subtitle font-light">
          Saved views are accessible by all users on your Northbeam account.
          Share, rename, or arrange the order these views appear in the menu.
          The view sorted to the top will become the default view.
        </div>
        <div className="border border-[#C1C5CD] mt-7 h-[28rem] w-[36rem] rounded-lg self-center relative overflow-y-auto">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[
              restrictToVerticalAxis,
              restrictToFirstScrollableAncestor,
            ]}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={modifiedSaveViews.map((view) => view.id)}>
              {modifiedSaveViews.map((view) => (
                <SelectedSavedView key={view.id} savedView={view} />
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <div className="absolute bottom-8 right-8">
          <Button onClick={closeModal} className="mr-4">
            <div className="flex flex-row items-center">Cancel</div>
          </Button>
          <PrimaryButton
            className="bg-cosmic h-[3rem] rounded-lg"
            onClick={() => {
              setSavedViews(modifiedSaveViews);
              closeModal();
            }}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
}

function MenuItem({
  title,
  isDefault,
  active,
  onClick,
}: {
  title: string;
  isDefault: boolean;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <div className="flex flex-row items-center mt-4">
      <div className="w-[2rem]">
        {active && <i className="fa-regular fa-check text-cosmic"></i>}
      </div>
      <div className="flex flex-col cursor-pointer" onClick={onClick}>
        <div className="truncate w-[14rem]">{title}</div>
        {isDefault && (
          <div className="text-bold text-sm text-[#BAC4D4]">default</div>
        )}
      </div>
    </div>
  );
}

export function Button({
  children,
  onClick,
  className,
}: PropsWithChildren<{ onClick: () => void; className?: string }>) {
  return (
    <button
      className={clsx("py-2 px-2 text-cosmic", className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
