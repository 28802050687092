import { difference } from "lodash";

type ValidatorReturn = boolean | string;

export const required = "Field is required";

export const notEmpty =
  ({ errorMessage }: { errorMessage?: string } = {}) =>
  (value: string): ValidatorReturn => {
    const message = errorMessage || "Field cannot be empty";
    return value.trim().length > 0 || message;
  };

export const maxCharacterLength =
  ({ maxLength, errorMessage }: { maxLength: number; errorMessage?: string }) =>
  (value: string): ValidatorReturn => {
    const message = errorMessage || `${maxLength} character limit`;
    return value.length < maxLength || message;
  };

// If it appears twice in list then it is not unique
export const uniqueFromListWithSelf =
  ({
    compareValues,
    errorMessage,
  }: {
    compareValues: string[];
    errorMessage?: string;
  }) =>
  (value: string): ValidatorReturn => {
    const message = errorMessage || "Field must be unique";
    const trimmedValue = value.trim();
    const trimmedCompareValues = compareValues.map((val) => val.trim());
    const valueIndex = trimmedCompareValues.indexOf(trimmedValue);
    trimmedCompareValues.splice(valueIndex, 1);

    const compareDiffCount =
      trimmedCompareValues.length -
      difference(trimmedCompareValues, [value.trim()]).length;
    const isUnique = compareDiffCount !== 0;

    return !isUnique || message;
  };

export const substringFromListWithSelf =
  ({
    compareValues,
    errorMessage,
  }: {
    compareValues: string[];
    errorMessage?: string;
  }) =>
  (value: string): ValidatorReturn => {
    const message = errorMessage || "Field must not be a substring";
    const trimmedValue = value.trim();
    const trimmedCompareValues = compareValues.map((val) => val.trim());
    const valueIndex = trimmedCompareValues.indexOf(trimmedValue);
    trimmedCompareValues.splice(valueIndex, 1);

    const isSubstring = !trimmedCompareValues.some((val) =>
      val.includes(trimmedValue),
    );
    return isSubstring || message;
  };

export const uniqueFromList =
  ({
    compareValues,
    errorMessage,
  }: {
    compareValues: string[];
    errorMessage?: string;
  }) =>
  (value: string): ValidatorReturn => {
    const message = errorMessage || "Field must be unique";
    return !compareValues.includes(value) || message;
  };
