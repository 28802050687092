import {
  SubscriptionTypeEnum,
  ValueComparisonType,
  ValueIntComparisonInput,
} from "@nb-api-graphql-generated/global-types";
import { atom, useAtom } from "jotai";

const orderIdAtom = atom<string | null>(null);

const orderValueAtom = atom<{
  comparisonOperator?: ValueComparisonType;
  value?: string;
} | null>(null);
const numberOfOrderItemsAtom = atom<Partial<ValueIntComparisonInput> | null>(
  null,
);
const numberOfTouchpointsAtom = atom<Partial<ValueIntComparisonInput> | null>(
  null,
);

const orderTypesAtom = atom<string[] | null>(null);

const attributedAtom = atom<string[] | null>(null);
const orderTagsAtom = atom<string[] | null>(null);
const customerTagsAtom = atom<string[] | null>(null);
const subscriptionsAtom = atom<SubscriptionTypeEnum[] | null>(null);
const productsPurchasedAtom = atom<string[] | null>(null);
const discountCodesAtom = atom<string[] | null>(null);

export const defaultOrderPageSorting = {
  sortingField: "date",
  sortingOrder: "desc",
};
const orderPageSortingAtom = atom<{
  sortingField: string;
  sortingOrder: string;
} | null>(defaultOrderPageSorting);

const orderSummaryGraphKPILoading = atom(false);
const orderSummaryGraphLoading = atom(false);
const orderTableLoading = atom(false);

export const useOrderSummaryGraphKPILoading = () =>
  useAtom(orderSummaryGraphKPILoading);
export const useOrderSummaryGraphLoading = () =>
  useAtom(orderSummaryGraphLoading);
export const useOrderTableLoading = () => useAtom(orderTableLoading);
export const useOrderIdAtom = () => useAtom(orderIdAtom);

export const useOrderValueAtom = () => useAtom(orderValueAtom);

export const useNumberOfOrderItemsAtom = () => useAtom(numberOfOrderItemsAtom);

export const useNumberOfTouchpointsAtom = () =>
  useAtom(numberOfTouchpointsAtom);

export const useOrderTypesAtom = () => useAtom(orderTypesAtom);
export const useAttributedAtom = () => useAtom(attributedAtom);
export const useOrderTagsAtom = () => useAtom(orderTagsAtom);
export const useCustomerTagsAtom = () => useAtom(customerTagsAtom);
export const useSubscriptionsAtom = () => useAtom(subscriptionsAtom);
export const useProductsPurchasedAtom = () => useAtom(productsPurchasedAtom);
export const useDiscountCodesAtom = () => useAtom(discountCodesAtom);
export const useOrderPageSortingAtom = () => useAtom(orderPageSortingAtom);
