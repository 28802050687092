import { b64Encode } from "@north-beam/nb-common";

const hash = (value: string) => b64Encode(value).slice(0, 8);

export const generateAnonEmailIfNecessary = <T extends string | undefined>(
  value: T,
  doAnon: boolean,
) => (doAnon && value ? `c_${hash(value)}@gmail.com` : value);

export const generateAnonBase64Hash = <T extends string | undefined>({
  value,
  prefix,
  doAnon,
}: {
  value: T;
  prefix: string;
  doAnon: boolean;
}) => (doAnon && value ? `${prefix}-${b64Encode(value)}` : value);
