import React from "react";
import { Radio } from "./radio";

interface RadioGroupProps<T> {
  options: { label: string; value: T }[];
  onChange: (value: T) => void;
  value: T;
}

export const RadioGroup = <T extends string>({
  options,
  onChange,
  value,
}: RadioGroupProps<T>) => {
  return (
    <div>
      {options.map((option) => (
        <Radio<T>
          key={option.value}
          label={option.label}
          value={option.value}
          checked={value === option.value}
          onChange={onChange}
        />
      ))}
    </div>
  );
};
