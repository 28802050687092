import { NumberInput } from "@components/utilities";
import {
  AlarmConditionV2,
  AnomalyCondition,
  CompositeAlarmConditionV2,
  isSimpleAlarmCondition,
  MetricValueConfig,
  SimpleAlarmConditionV2,
} from "@north-beam/nb-common";
import { BreakdownConfig } from "@north-beam/nb-common";
import { Metric, MetricFragment } from "@north-beam/nb-common";
import { enumType } from "@utils/enum-type";
import classNames from "classnames";
import _ from "lodash";
import { Range } from "rc-slider";
import React from "react";
import Select from "react-select";

export function AlarmConditionsSelectorRoot(props: {
  setSelf: (value: AlarmConditionV2) => void;
  value: AlarmConditionV2;
  metrics: MetricFragment[];
}) {
  const { setSelf, value, metrics } = props;

  return (
    <>
      <AlarmConditionsSelector {...props} />
      <HR />
      <div className="row">
        <div className="col flex flex-col items-center">
          <button
            className="btn btn-block btn-link btn-sm"
            onClick={() =>
              setSelf({
                isLeaf: false,
                version: "v2",
                combinator: "and",
                children: [value, makeDefaultAlarmCondition(metrics)],
              })
            }
            style={{
              color: "var(--blue)",
            }}
          >
            <i className="fas fa-plus-circle" />
            &ensp;Add condition
          </button>
        </div>
        <div className="col flex flex-col items-center">
          <button
            className="btn btn-block btn-link btn-sm"
            disabled={isSimpleAlarmCondition(value)}
            onClick={() => {
              if (isSimpleAlarmCondition(value)) {
                return;
              }
              setSelf((value as CompositeAlarmConditionV2).children[0]);
            }}
            style={{
              color: "var(--red)",
            }}
          >
            <i className="fas fa-minus-circle"></i>&ensp;Remove condition
          </button>
        </div>
      </div>
    </>
  );
}

function AlarmConditionsSelector(props: {
  setSelf: (value: AlarmConditionV2) => void;
  value: AlarmConditionV2;
  metrics: MetricFragment[];
}) {
  const { setSelf, value, metrics } = props;

  return isSimpleAlarmCondition(value) ? (
    <SimpleAlarmConditionSelector
      setSelf={(value) => setSelf(value)}
      value={value}
    />
  ) : (
    <CompositeAlarmConditionSelector
      value={value}
      setSelf={(value) => setSelf(value)}
      metrics={metrics}
    />
  );
}

const HR = () => <hr style={{ marginLeft: -15, marginRight: -15 }} />;

function CompositeAlarmConditionSelector({
  value,
  setSelf,
  metrics,
}: {
  setSelf: (value: AlarmConditionV2) => void;
  value: CompositeAlarmConditionV2;
  metrics: MetricFragment[];
}) {
  const { children, combinator } = value;

  const [first, second] = children;
  /**
   * 1
   *    - simple 1
   * 2
   *    - complex 3
   *      - simple 1
   *      - simple 2
   * 3
   *    - complex 5
   *      - complex 3
   *        - simple 1
   *        - simple 2
   *      - simple 4
   * 4
   *    - complex 7
   *      - complex 5
   *        - complex 3
   *          - simple 1
   *          - simple 2
   *        - simple 4
   *      - simple 6
   */

  const updateFirst = React.useCallback(
    (val: AlarmConditionV2) => {
      setSelf({ ...value, children: [val, second] });
    },
    [setSelf, value, second],
  );

  const updateSecond = React.useCallback(
    (val: SimpleAlarmConditionV2) => {
      setSelf({ ...value, children: [first, val] });
    },
    [setSelf, value, first],
  );

  const updateCombinator = React.useCallback(
    (combinator: "and" | "or") => {
      setSelf({ ...value, combinator });
    },
    [setSelf, value],
  );

  return (
    <>
      <AlarmConditionsSelector
        value={first}
        setSelf={updateFirst}
        metrics={metrics}
      />
      <HR />

      <div className="d-inline-block w-100">
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <label
            className={classNames("btn btn-outline-primary", {
              active: combinator === "and",
            })}
            onClick={() => updateCombinator("and")}
          >
            <input
              type="radio"
              name="options"
              id="option1"
              checked={combinator === "and"}
            />{" "}
            AND
          </label>
          <label
            className={classNames("btn btn-outline-primary", {
              active: combinator === "or",
            })}
          >
            <input
              type="radio"
              name="options"
              id="option2"
              checked={combinator === "or"}
              onClick={() => updateCombinator("or")}
            />{" "}
            OR
          </label>
        </div>
      </div>
      <HR />
      <SimpleAlarmConditionSelector
        setSelf={updateSecond}
        value={second as SimpleAlarmConditionV2}
      />
    </>
  );
}

function SimpleAlarmConditionSelector(props: {
  setSelf: (value: SimpleAlarmConditionV2) => void;
  value: SimpleAlarmConditionV2;
}) {
  const { setSelf, value } = props;
  const { isDisabled, metrics } = useCtx();
  const { metric, currentValue, referenceValue, anomalyCondition } = value;

  const updateMetric = React.useCallback(
    (metric: Metric) =>
      setSelf({
        ...value,
        metric,
      }),
    [setSelf, value],
  );

  const updateCurrentValueWindow = React.useCallback(
    (windowEndOffsetDays: number) =>
      setSelf({
        ...value,
        currentValue: { ...currentValue, windowEndOffsetDays },
      }),
    [setSelf, value, currentValue],
  );

  const updateReferenceValue = React.useCallback(
    (referenceValue: MetricValueConfig) =>
      setSelf({
        ...value,
        referenceValue,
      }),
    [setSelf, value],
  );

  const updateAnomalyCondition = React.useCallback(
    (anomalyCondition: AnomalyCondition) =>
      setSelf({
        ...value,
        anomalyCondition,
      }),
    [setSelf, value],
  );

  return (
    <div>
      <div className="row">
        <div className="col flex flex-col">
          <div className="nb-side-label text-muted">
            <small>Metric</small>
          </div>
          <div className="flex-fill">
            <Select
              tabIndex={10}
              isSearchable
              isDisabled={isDisabled}
              placeholder={"select metric..."}
              options={metrics}
              value={metric}
              onChange={updateMetric as any}
              getOptionLabel={(v) => v.name}
              getOptionValue={(v) => v.id}
              tabSelectsValue
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col flex flex-col">
          <div className="nb-side-label text-muted">
            <small>Window size days</small>
          </div>
          <div className="flex-fill">
            <NumberInput
              tabIndex={30}
              disabled={isDisabled}
              className="form-control"
              value={currentValue.windowEndOffsetDays}
              format="integer"
              onValueChange={(v: number | undefined) => {
                if (typeof v === "number") updateCurrentValueWindow(v);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="form-group row">
        <div className="col flex flex-col">
          <div className="nb-side-label text-muted">
            <small>Reference value</small>
          </div>
          <div className="flex-fill">
            <MetricValueConfigEditor
              current={referenceValue}
              setValue={updateReferenceValue}
              tabIndex={40}
              metric={metric}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col flex flex-col">
          <div className="nb-side-label text-muted">
            <small>Anomaly conditions</small>
          </div>
          <div className="flex-fill">
            <AnomalyConditionEditor
              current={anomalyCondition}
              setValue={updateAnomalyCondition}
              tabIndex={50}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function MetricValueConfigEditor({
  current,
  setValue,
  tabIndex,
  metric,
}: {
  current: MetricValueConfig;
  setValue: (value: MetricValueConfig) => void;
  tabIndex: number;
  metric: MetricFragment;
}) {
  const { isDisabled } = useCtx();
  const updateType = React.useCallback(
    (v: any) => {
      const value: keyof (typeof MetricValueType)["enum"] = v.value;
      if (value === current.type) {
        return;
      }
      if (value === "AveragedValueConfig") {
        setValue({
          type: value,
          windowStartOffsetDays: 8,
          windowEndOffsetDays: 14,
          averagingMode: { type: "UniformAveragingMode" },
        });
      } else if (value === "FixedValueConfig") {
        setValue({
          type: value,
          value: 0,
        });
      }
    },
    [current, setValue],
  );
  const select = (
    <Select
      tabIndex={tabIndex}
      isDisabled={isDisabled}
      isSearchable={false}
      options={MetricValueType.reactSelectOptions}
      value={MetricValueType.toReactSelect(current.type)}
      onChange={updateType}
    />
  );

  const numericEditors =
    current.type === "FixedValueConfig" ? (
      <NumberInput
        tabIndex={tabIndex + 1}
        value={current.value}
        onValueChange={(value) => {
          if (typeof value === "number") {
            setValue({ ...current, value });
          }
        }}
        format={metric.format}
      />
    ) : (
      <div className="nb-interval-picker">
        <Range
          min={1}
          max={60}
          disabled={isDisabled}
          value={[current.windowStartOffsetDays, current.windowEndOffsetDays]}
          onChange={(value: number[]) => {
            const [lo, hi] = _.sortBy(value);
            setValue({
              ...current,
              windowStartOffsetDays: lo,
              windowEndOffsetDays: hi,
            });
          }}
        />
        <div className="text-center">
          {current.windowStartOffsetDays} days &ndash;{" "}
          {current.windowEndOffsetDays} days
        </div>
      </div>
    );
  return (
    <div>
      <div>{select}</div>
      <hr />
      <div>{numericEditors}</div>
    </div>
  );
}

function AnomalyConditionEditor({
  current,
  setValue,
  tabIndex,
}: {
  current: AnomalyCondition;
  setValue: (value: AnomalyCondition) => void;
  tabIndex: number;
}) {
  const { isDisabled } = useCtx();
  const updateType = React.useCallback(
    (v: any) => {
      const value: keyof (typeof AnomalyConditionType)["enum"] = v.value;
      if (value === current.type) {
        return;
      }
      if (value === "OutsidePercentageOfReferenceValue") {
        setValue({
          type: value,
          percentage: 0.2,
        });
      } else {
        setValue({ type: value });
      }
    },
    [current, setValue],
  );

  const select = (
    <Select
      tabIndex={tabIndex}
      isDisabled={isDisabled}
      isSearchable={false}
      options={AnomalyConditionType.reactSelectOptions}
      value={AnomalyConditionType.toReactSelect(current.type)}
      onChange={updateType}
    />
  );

  const numericEditors =
    current.type === "OutsidePercentageOfReferenceValue" ? (
      <NumberInput
        tabIndex={tabIndex + 1}
        value={current.percentage}
        onValueChange={(percentage) => {
          if (typeof percentage === "number") {
            setValue({ ...current, percentage });
          }
        }}
        format={"percentage"}
      />
    ) : null;
  return (
    <div>
      <div>{select}</div>
      <hr />
      <div>{numericEditors}</div>
    </div>
  );
}

const MetricValueType = enumType({
  enumObject: {
    FixedValueConfig: "Fixed value",
    AveragedValueConfig: "Moving average",
  },
  keyOrder: ["FixedValueConfig", "AveragedValueConfig"],
});

const AnomalyConditionType = enumType({
  enumObject: {
    AboveReferenceValue: "Above reference value",
    BelowReferenceValue: "Below reference value",
    OutsidePercentageOfReferenceValue: "Outside percentage of reference value",
  },
  keyOrder: [
    "AboveReferenceValue",
    "BelowReferenceValue",
    "OutsidePercentageOfReferenceValue",
  ],
});

export function makeDefaultAlarmCondition(
  metrics: MetricFragment[],
): SimpleAlarmConditionV2 {
  return {
    version: "v2",
    isLeaf: true,
    metric: metrics[0],
    currentValue: {
      type: "AveragedValueConfig",
      windowStartOffsetDays: 1,
      windowEndOffsetDays: 7,
      averagingMode: { type: "UniformAveragingMode" },
    },
    referenceValue: {
      type: "FixedValueConfig",
      value: 0,
    },
    anomalyCondition: {
      type: "BelowReferenceValue",
    },
  };
}

export interface EditorContextType {
  isSaving: boolean;
  isDisabled: boolean;
  metrics: MetricFragment[];
  breakdowns: BreakdownConfig[];
}

export const EditorContext = React.createContext<EditorContextType>({
  isSaving: false,
  isDisabled: true,
  metrics: [],
  breakdowns: [],
});
export const useCtx = () => React.useContext(EditorContext);
