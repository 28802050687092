import { gql } from "@apollo/client";

const Fragments = {
  labelRuleAll: gql`
    fragment LabelRuleAll on LabelRule {
      id
      level
      labelKey
      active
      createdAt
      updatedAt
      labelRules
      notificationSettings
    }
  `,
};

export const GET_LABEL_RULES = gql`
  query GetLabelRules {
    me {
      id
      labelRules {
        ...LabelRuleAll
      }
    }
  }
  ${Fragments.labelRuleAll}
`;

export const GET_LABEL_RULE = gql`
  query GetLabelRule($level: String!, $labelKey: String!) {
    me {
      id
      labelRule(level: $level, labelKey: $labelKey) {
        ...LabelRuleAll
      }
    }
  }
  ${Fragments.labelRuleAll}
`;

export const SIMULATE_LABEL_RULE_GROUP = gql`
  query SimulateLabelRuleGroup(
    $seed: Int!
    $level: String!
    $labelRules: [JSONObject!]!
  ) {
    me {
      id
      simulateLabelRuleGroup(
        seed: $seed
        level: $level
        labelRules: $labelRules
      )
    }
  }
`;

export const SET_LABEL_RULE_ACTIVE_STATE = gql`
  mutation SetLabelRuleActiveState(
    $level: String!
    $labelKey: String!
    $activeState: Boolean!
  ) {
    setLabelRuleActiveState(
      level: $level
      activeState: $activeState
      labelKey: $labelKey
    ) {
      ...LabelRuleAll
    }
  }
  ${Fragments.labelRuleAll}
`;

export const SET_LABEL_RULE_NOTIFICATION_SETTINGS = gql`
  mutation SetLabelRuleNotificationSettings(
    $level: String!
    $labelKey: String!
    $notificationSettings: JSONObject!
  ) {
    setLabelRuleNotificationSettings(
      level: $level
      notificationSettings: $notificationSettings
      labelKey: $labelKey
    ) {
      ...LabelRuleAll
    }
  }
  ${Fragments.labelRuleAll}
`;

export const UPDATE_LABEL_RULE = gql`
  mutation UpdateLabelRule(
    $level: String!
    $labelKey: String!
    $labelRules: [JSONObject!]!
  ) {
    updateLabelRule(
      level: $level
      labelKey: $labelKey
      labelRules: $labelRules
    ) {
      ...LabelRuleAll
    }
  }
  ${Fragments.labelRuleAll}
`;
