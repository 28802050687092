import { SalesViewTemplate } from "../../sales-views-context";

export const overviewTransactionsAndCac: SalesViewTemplate = {
  id: "overview_transactions_and_cac",
  name: "Overview: Transactions and CAC",
  isTemplate: true,
  data: {
    filters: {
      attributionModel: "northbeam_custom",
      attributionWindow: "1",
      nbAccountingMode: "accrual",
      granularity: "platform",
      timeGranularity: "daily",
      dateRange: "last7Days",
      compareDateRange: "lastPeriod",
      breakdowns: [
        {
          key: "Platform (Northbeam)",
          values: [],
        },
      ],
    },
    columnConfigurations: [
      {
        name: "Name",
        width: 450,
        visible: true,
      },
      {
        name: "Spend",
        width: 180,
        visible: true,
      },
      {
        name: "Transactions",
        width: 163,
        visible: true,
      },
      {
        name: "CAC",
        width: 120,
        visible: true,
      },
      {
        name: "LTV CAC",
        width: 120,
        visible: true,
      },
      {
        name: "LTV ROAS",
        width: 120,
        visible: true,
      },
      {
        name: "CTR",
        width: 120,
        visible: true,
      },
      {
        name: "CPM",
        width: 120,
        visible: true,
      },
      {
        name: "eCPC",
        width: 120,
        visible: true,
      },
      { name: "AOV", visible: true, width: 120 },
      { name: "AOV (1st time)", visible: true, width: 259 },
      { name: "AOV (Returning)", visible: true, width: 269 },
    ],
    selectedMetricBoxes: [
      "Spend",
      "Attributed Rev",
      "ROAS",
      "CAC",
      "LTV CAC",
      "LTV ROAS",
    ],
  },
};
