import classNames from "classnames";
import React, { PropsWithChildren } from "react";

export type CardProps = {
  className?: string;
};

export function Card({ children, className }: PropsWithChildren<CardProps>) {
  return (
    <div
      className={classNames(
        "container 2xl:w-[43rem] xl:w-[40rem] lg:w-[30rem] md:w-[25rem] shadow-card",
        className,
      )}
    >
      {children}
    </div>
  );
}
