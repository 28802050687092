import {
  CategoryGroupedConfig,
  ColumnConfig,
  MetricCategory,
  MetricConfig,
} from "@shared/metric-customization-modal/metric-category-builder";
import { ModalPanel } from "@shared/modals/modal-panel";
import classNames from "classnames";
import React from "react";

interface CategoriesPanelProps {
  categories: CategoryGroupedConfig<MetricConfig | ColumnConfig>;
  selectedCategory: MetricCategory;
  setSelectedCategory: (category: MetricCategory) => void;
}

export const CategoriesPanel = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}: CategoriesPanelProps) => {
  const filteredMetricCategories = Object.values(MetricCategory).filter(
    (category) => categories[category].length > 0,
  );

  return (
    <ModalPanel title="Metric Categories" className="p-3">
      {filteredMetricCategories.map((category) => (
        <div
          key={category}
          className={classNames(
            "cursor-pointer font-semibold leading-8 mb-3",
            category === selectedCategory ? "text-primary" : "text-[#717680]",
          )}
          onClick={() => setSelectedCategory(category)}
        >
          {category}
        </div>
      ))}
    </ModalPanel>
  );
};
