import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";
import { ConstructDestinationUrlComponent } from "./shared/construct-destination-url-component";

export function LiveIntent({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to all <b>destination URLs</b> for
        new and existing ads.
      </p>
      <ol>
        <ConstructDestinationUrlComponent urlParamPairs={urlParamPairs} />
        <li>
          Follow the instructions{" "}
          <GenericExternalLink href="https://support.liveintent.com/hc/en-us/articles/360000822966-Click-URL-Macros-and-Accepted-Characters">
            here
          </GenericExternalLink>{" "}
          to edit or create a new ad with Northbeam&apos;s URL parameters. The
          link you copied above will be valid in <b>LiveIntent</b>.
        </li>
      </ol>
    </>
  );
}
