import { usePageTitle } from "@/atoms/page-title-atom";
import { useUser } from "@components/user-context";
import { FETCH_DOCUMENTS } from "@gql/documents";
import Icon from "@lib/icons";
import { Documents } from "@nb-api-graphql-generated/Documents";
import { IconButton } from "@shared/buttons";
import { useNorthbeamQuery } from "@utils/hooks";
import { parseISO, format, compareAsc } from "date-fns";
import React, { useEffect, useState } from "react";
import { AdminDeleteModal, AdminUploadModal } from "./customer-documents/admin";

export default function CustomerDocumentsPage() {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Documents");
  }, [setPageTitle]);

  const { user } = useUser();
  const [isAdminUploadModalOpen, setAdminUploadModalOpen] = useState(false);

  const [toDeleteDocument, setToDeleteDocument] =
    useState<Documents["me"]["documents"][number]>();
  const { data, refetch, loading } = useNorthbeamQuery<Documents>(
    FETCH_DOCUMENTS,
    { notifyOnNetworkStatusChange: true },
  );

  useRefetchingOnExpiration(data?.me?.documents, refetch);

  if (!data) return null;

  const documentContentTypes = data?.me?.documentContentTypes;

  return (
    <div className="container p-4">
      {toDeleteDocument && (
        <AdminDeleteModal
          onClose={() => setToDeleteDocument(undefined)}
          document={toDeleteDocument}
        />
      )}
      <div className="row mt-4">
        <div className={`col-md-12`}>
          <header className="border-bottom">
            <div className="d-flex align-items-center mb-2">
              <h1 className="mb-0">Documents</h1>
              {user.isAdmin && (
                <>
                  <IconButton
                    leftIcon={<Icon type="upload" />}
                    type="button"
                    size="sm"
                    className="btn-primary ml-3"
                    onClick={() =>
                      setAdminUploadModalOpen(!isAdminUploadModalOpen)
                    }
                  >
                    Admin Upload
                  </IconButton>
                  <AdminUploadModal
                    isOpen={isAdminUploadModalOpen}
                    onClose={() => setAdminUploadModalOpen(false)}
                    onUpload={() => {
                      refetch();
                      setAdminUploadModalOpen(false);
                    }}
                  />
                </>
              )}
              <IconButton
                leftIcon={<i className="fa-light fa-rotate-right" />}
                type="button"
                size="sm"
                className="btn-primary ml-3 w-[6rem]"
                loading={loading}
                onClick={() => {
                  refetch();
                }}
              >
                Refresh
              </IconButton>
            </div>

            <p>Find documents uploaded by the Northbeam team here.</p>
          </header>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-12">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="th-sm">
                  Document
                </th>
                <th scope="col" className="th-sm">
                  Updated At
                </th>
                <th scope="col" className="th-sm">
                  Created At
                </th>
                <th scope="col" className="th-sm">
                  Content
                </th>

                <th scope="col" className="th-sm">
                  <div>Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.me.documents.length > 0 ? (
                data.me.documents.map((document) => {
                  const content =
                    documentContentTypes[document.metadata.contentType] ||
                    document.metadata.contentType;
                  return (
                    <tr key={document.id}>
                      <td className="align-middle">
                        <a
                          href={document.link.authenticatedURL}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {document.name}
                        </a>
                      </td>
                      <td className="align-middle">
                        {prettifyDate(document.metadata.updatedAt)}
                      </td>
                      <td className="align-middle">
                        {prettifyDate(document.metadata.createdAt)}
                      </td>
                      <td className="align-middle">{content}</td>
                      {user.isAdmin || !document.isAdminOnly ? (
                        <td className="align-middle">
                          <IconButton
                            leftIcon={<Icon type="trash" />}
                            type="button"
                            className="btn-outline-danger mr-1 w-[5rem]"
                            size="sm"
                            onClick={() => {
                              setToDeleteDocument(document);
                            }}
                          >
                            Delete
                          </IconButton>
                        </td>
                      ) : (
                        <td className="align-middle" />
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={user.isAdmin ? 5 : 4}>
                    No documents have been currently uploaded.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function useRefetchingOnExpiration(
  documents: { link: { expiresAt: string } }[] | null | undefined,
  refetch: () => void,
) {
  useEffect(() => {
    if (!documents) return;

    const expirations: Date[] = documents.map(({ link }) => {
      return parseISO(link.expiresAt);
    });
    const sortedExpirations = expirations.sort((first, second) => {
      return compareAsc(first, second);
    });
    const tenMinutesFromNow = Date.now() + 10 * 60 * 1000;
    const minimumExpiration =
      sortedExpirations[0]?.getTime() || tenMinutesFromNow;
    const timeoutHandle = setTimeout(() => {
      refetch();
    }, minimumExpiration - Date.now());

    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [documents, refetch]);
}

const dateFormat = "PPPPpppp";
function prettifyDate(isoDateString: string) {
  return format(parseISO(isoDateString), dateFormat);
}
