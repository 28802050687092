import classNames from "classnames";
import { get } from "lodash";
import React, { forwardRef, TextareaHTMLAttributes } from "react";

interface TextAreaInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  onChange: (event: any) => void;

  name?: string;
  label?: string | React.ReactElement;
  value?: string;
  defaultValue?: string;
  onBlur?: (event: any) => void;
  className?: string;
  placeholder?: string;
  width?: string;
  maxWidth?: string;
  errorMessage?: string;
  errors?: any;
  isDisabled?: boolean;
  rows?: number;
}

export const TextAreaInput = forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>(
  (
    {
      name,
      label,
      value,
      defaultValue,
      onChange,
      onBlur,
      className,
      placeholder,
      width,
      maxWidth,
      errorMessage,
      errors,
      isDisabled = false,
      rows = 4,
    },
    ref,
  ) => {
    // Access the error message from react-hook-form
    const formErrorMessage = get(errors, `${name}.message`);
    const hasError = errorMessage || formErrorMessage;

    return (
      <div
        className={classNames(
          "nb-input-container",
          label && "has-label",
          errors && "has-error",
          className,
        )}
        style={{ width, maxWidth }}
      >
        {label && <label className="label">{label}</label>}

        <textarea
          name={name}
          ref={ref}
          className="nb-input"
          onBlur={onBlur}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          rows={rows}
        />

        {hasError && (
          <div className="error-message">
            {errorMessage ?? formErrorMessage}
          </div>
        )}
      </div>
    );
  },
);
