import React from "react";
import { InstallationScriptDetails } from "@north-beam/nb-common";
import { LegacyRechargeTag } from "./legacy-recharge-tag";
import { NorthbeamPixelTag } from "./northbeam-pixel-tag";
import { ShopifyOrderStatusTag } from "./shopify-order-status-tag";
import { ShopifyPostPurchaseUpsellTag } from "./shopify-post-purchase-upsell-tag";

interface TrackingScriptTagsProps {
  installationScripts: InstallationScriptDetails;
}
export const TrackingScriptTags = ({
  installationScripts,
}: TrackingScriptTagsProps): React.ReactElement => (
  <>
    <NorthbeamPixelTag installationScripts={installationScripts} />
    <ShopifyOrderStatusTag installationScripts={installationScripts} />
    <ShopifyPostPurchaseUpsellTag installationScripts={installationScripts} />
    <LegacyRechargeTag installationScripts={installationScripts} />
  </>
);
