import { gql } from "@apollo/client";
import { GenericExternalLink } from "@components/generic-external-link";
import { RefreshAMIs } from "@nb-api-graphql-generated/RefreshAMIs";
import { useNorthbeamMutation } from "@utils/hooks";
import React, { FC, useEffect, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import checkCircleIcon from "./assets/check-circle-icon.svg";
import exclamationMarkIcon from "./assets/exclamation-mark-icon.svg";
import { StepName } from "./definitions";
import { StepContainer } from "./step-container";

interface AccountMaintenanceItemVerification {
  dnsComplete: boolean;
  conversionPixelComplete: boolean;
  testUrlsForNBPixelSetupComplete: boolean;
}

export const CompletedStep: FC<Partial<StepWizardChildProps>> = ({
  previousStep,
}) => {
  const [verification, setVerification] =
    useState<AccountMaintenanceItemVerification | null>(null);
  const [refreshAMIs, { loading, data }] =
    useNorthbeamMutation<RefreshAMIs>(REFRESH_AMIS);

  useEffect(() => {
    refreshAMIs();
  }, [refreshAMIs]);

  useEffect(() => {
    if (data) {
      setVerification({
        dnsComplete: data.refreshDNSAMI,
        conversionPixelComplete: data.refreshConversionPixelAMI,
        testUrlsForNBPixelSetupComplete: data.refreshTestUrlsForNBPixelSetupAMI,
      });
    }
  }, [data]);

  const DNSRow = () => {
    if (verification?.dnsComplete) {
      return (
        <tr className="mt-4">
          <td className="pl-0">
            <img src={checkCircleIcon} alt="Circle check icon" />
          </td>
          <td>
            DNS: You&apos;ve succesfully added the <b>A record</b>.
          </td>
          <td></td>
        </tr>
      );
    } else {
      return (
        <tr className="mt-4">
          <td className="pl-0">
            <img src={exclamationMarkIcon} alt="Exclamation mark icon" />
          </td>
          <td>
            DNS Setup: We&apos;re not detecting the <b>A record</b> in your DNS
            server.
          </td>
          <td>
            <button
              className="btn btn-sm btn-outline-info"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => (window.location.hash = StepName.DNS_SETUP)}
            >
              Go back
            </button>
          </td>
        </tr>
      );
    }
  };

  const BasePixelRow = () => {
    if (verification?.testUrlsForNBPixelSetupComplete) {
      return (
        <tr className="mt-4">
          <td className="pl-0">
            <img src={checkCircleIcon} alt="Circle check icon" />
          </td>
          <td>
            Pixel Placement: We&apos;ve detected the pixel on the landing page
            of your website.
          </td>
          <td></td>
        </tr>
      );
    } else {
      return (
        <tr className="mt-4">
          <td className="pl-0">
            <img src={exclamationMarkIcon} alt="Exclamation mark icon" />
          </td>
          <td>
            Pixel Placement: We&apos;re not detecting the pixel on the landing
            page of your website.
          </td>
          <td>
            <button
              className="btn btn-sm btn-outline-info"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => (window.location.hash = StepName.PIXEL_PLACEMENT)}
            >
              Go back
            </button>
          </td>
        </tr>
      );
    }
  };

  const ConversionPixelRow = () => {
    if (verification?.conversionPixelComplete) {
      return (
        <tr className="mt-4">
          <td className="pl-0">
            <img src={checkCircleIcon} alt="Circle check icon" />
          </td>
          <td>
            Pixel Placement: We&apos;ve detected conversion events from your
            post-purchase pixel in the last 24 hours.
          </td>
          <td></td>
        </tr>
      );
    } else {
      return (
        <tr className="mt-4">
          <td className="pl-0">
            <img src={exclamationMarkIcon} alt="Exclamation mark icon" />
          </td>
          <td>
            Pixel Placement: We have not detected the pixel on your purchase
            pages in the last 24 hours.
          </td>
          <td>
            <button
              className="btn btn-sm btn-outline-info"
              style={{ whiteSpace: "nowrap" }}
              onClick={() => (window.location.hash = StepName.PIXEL_PLACEMENT)}
            >
              Go back
            </button>
          </td>
        </tr>
      );
    }
  };

  const renderAppSuccess = () => (
    <>
      <h3>You&apos;ve succesfully onboarded!</h3>
      <hr />
      <p>
        You&apos;re onboarded with Northbeam. It&apos;ll take about 24 hours for
        your data to populate. If you have any other questions, please reach out
        to us at <a href="mailto:success@northbeam.io">success@northbeam.io</a>
      </p>
      <GenericExternalLink
        href="https://info.northbeam.io/knowledge"
        className="btn btn-outline-info mt-4"
      >
        Learn how to use Northbeam
      </GenericExternalLink>
      <GenericExternalLink
        href="https://info.northbeam.io/knowledge/understanding-northbeams-attribution-models"
        className="btn btn-outline-info ml-4 mt-4"
      >
        Learn how our attribution models work
      </GenericExternalLink>
    </>
  );

  const renderActionItems = () => (
    <>
      <h3>Something needs your attention</h3>
      <hr />
      <p>
        Upon validation, we found a few steps that need completing before we can
        start importing your data.
      </p>
      <table className="table table-borderless table-md mb-0 text-left mt-4">
        <tbody>
          <DNSRow />
          <BasePixelRow />
          <ConversionPixelRow />
        </tbody>
      </table>
      <p className="mt-4">
        Note: Once fixed, return to the Account Managers page and click
        &ldquo;Finish&rdquo; to validate again.
      </p>
    </>
  );

  const renderChildren = () => {
    if (loading) {
      return "Verifying your setup...";
    }

    if (!verification) {
      return "Unable to verify your setup. Please Contact Northbeam Support";
    }

    const verificationsPassed = !Object.values(verification).some(
      (val) => !val,
    );

    if (!verificationsPassed) {
      return renderActionItems();
    }

    return renderAppSuccess();
  };

  return (
    <StepContainer
      onMoveBackwardButtonClicked={() => {
        previousStep && previousStep();
      }}
    >
      <>{renderChildren()}</>
    </StepContainer>
  );
};

const REFRESH_AMIS = gql`
  mutation RefreshAMIs {
    refreshDNSAMI
    refreshConversionPixelAMI
    refreshTestUrlsForNBPixelSetupAMI
    refreshOrdersSyncAMI
  }
`;
