import { useEffect } from "react";
import { useLazyRestApi } from "./use-lazy-rest-api";

export enum HTTP_METHOD {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE",
}

export type RestApiOptions = {
  method: HTTP_METHOD;
  data?: Record<string, any>;
};

export type RestApiResponse<T> = {
  loading: boolean;
  data: T | null;
  error: string | null;
};

export const useRestApi = <T>(
  url: string,
  options: RestApiOptions = {
    method: HTTP_METHOD.GET,
  },
): RestApiResponse<T> => {
  const { method, data: body } = options;
  const { data, loading, error, call } = useLazyRestApi<T>();

  useEffect(() => {
    call(url, { method, data: body });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    loading,
    error,
  };
};
