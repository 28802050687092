import classNames from "classnames";
import { get } from "lodash";
import React, { forwardRef, InputHTMLAttributes, ReactNode, Ref } from "react";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string | React.ReactElement;
  value?: string;
  defaultValue?: string;
  onChange: (event: any) => void;
  className?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  width?: string;
  maxWidth?: string;
  errorMessage?: string;
  errors?: any;
  isDisabled?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name,
      label,
      value,
      defaultValue,
      onChange,
      className,
      leftIcon = null,
      rightIcon = null,
      width,
      maxWidth,
      errorMessage,
      errors,
      isDisabled = false,
      ...props
    },
    ref: Ref<any>,
  ) => {
    // Access the error message from react-hook-form
    const formErrorMessage = get(errors, `${name}.message`);
    const hasError = errorMessage || formErrorMessage;

    return (
      <div
        className={classNames(
          "nb-input-container",
          label && "has-label",
          errors && "has-error",
          className,
        )}
        style={{ width, maxWidth }}
      >
        {label && <label className="label">{label}</label>}

        <span className={classNames(leftIcon && "absolute-vertical-center")}>
          {leftIcon && <span className="pl-2.5">{leftIcon}</span>}
        </span>

        <input
          name={name}
          ref={ref}
          className={classNames(
            "nb-input",
            rightIcon && "pr-10",
            leftIcon && "pl-10",
          )}
          type="text"
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          {...props}
        />

        <span
          className={classNames(
            rightIcon && "absolute-vertical-center right-0",
          )}
        >
          {rightIcon && <span className="pr-2.5">{rightIcon}</span>}
        </span>

        {hasError && (
          <div className="error-message">
            {errorMessage ?? formErrorMessage}
          </div>
        )}
      </div>
    );
  },
);
