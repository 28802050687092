import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";
import { CopyButton } from "./shared/copy-button";

export function Outbrain({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to all existing campaigns. To do so,
        navigate to each campaign&apos;s <b>settings</b> page, and going down to
        the <b>Track</b> section. If you need help finding this section, you can
        look at these{" "}
        <GenericExternalLink href="https://www.outbrain.com/help/advertisers/utm-tracking/">
          instructions
        </GenericExternalLink>
        .
      </p>
      <CopyButton urlParamPairs={urlParamPairs} />
      <p>
        Copy the Northbeam UTM parameters text and paste it into the{" "}
        <b>Suffix</b> input field.
      </p>
    </>
  );
}
