import { TimeSeries, TimeSeriesPoint } from "@north-beam/nb-common";
import { ChartData, FontSpec, TooltipModel } from "chart.js";
import chroma from "chroma-js";
import { sortBy } from "lodash";

export function customTooltip({ tooltip }: { tooltip: TooltipModel<any> }) {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = "<div></div>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem: any): string[][] | null {
    if ((bodyItem?.before?.[0] ?? "").startsWith("[HIDE]")) {
      return null;
    }

    const rows: string[][] = [];

    const mainRow = [];
    for (let i = 0; i < bodyItem.lines.length; ++i) {
      mainRow.push(
        `<td align="right"><b>${bodyItem.before[i] ?? ""} &nbsp;</b></td>`,
      );
      mainRow.push(`<td align="right">${bodyItem.lines[i] ?? ""}</td>`);
    }
    rows.push(mainRow);
    return rows;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines: any[] = tooltip.title;
    const bodyLines = tooltip.body.map(getBody);
    const numCols =
      Math.max(...bodyLines.map((line) => line?.[0]?.length ?? 0)) ?? 0;

    const innerHTML = [
      "<table>",
      "<thead>",
      ...titleLines.map(
        (line) => `<tr><th colspan=${numCols + 1}>${line}</th></tr>`,
      ),
      "</thead>",
      "<tbody>",
      ...bodyLines.map((body, i) => {
        if (!body) {
          return "";
        }

        const colors = tooltip.labelColors[i] as any;
        const icon = `<i class="fas fa-circle" style="color: ${colors.borderColor}"></i>`;

        const outputRows: string[][] = [];
        for (let rowNum = 0; rowNum < body.length; ++rowNum) {
          const tds = [...body[rowNum]];
          if (rowNum === 0) {
            tds.unshift(`<td>${icon} &nbsp;</td>`);
          } else {
            tds.unshift(`<td></td>`);
          }
          outputRows.push(tds);
        }

        return outputRows.map((vs) => `<tr>${vs.join("")}</tr>`).join("");
      }),
      "</tbody>",
      "</table>",
    ].join("");

    const table = document.createElement("div");
    table.classList.add("shadow", "rounded", "p-2", "chart-tooltip");

    const { bodyFont } = tooltip.options;
    Object.assign(table.style, {
      fontFamily: (bodyFont as FontSpec).family,
      fontSize: (bodyFont as FontSpec).size + "px",
      fontStyle: (bodyFont as FontSpec).style,
    } as CSSStyleDeclaration);
    table.innerHTML = innerHTML;

    tooltipEl.innerHTML = table.outerHTML;
  }

  // `this` will be the overall tooltip
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const position = this._chart.canvas.getBoundingClientRect();
  const update: any = {
    opacity: "1",
    zIndex: "3000",
    position: "absolute",
    left: "",
    right: "",
    top: position.top + window.pageYOffset + tooltip.caretY + "px",
    pointerEvents: "none",
    margin: "10px",
    transform: `translateY(-100%)`,
  };

  // This will ensure that the tooltip doesn't get cut off by the window on the edges of the screen
  if (tooltip.caretX < window.innerWidth / 4) {
    update["left"] = position.left + window.pageXOffset + tooltip.caretX + "px";
  } else {
    update["right"] =
      window.innerWidth -
      position.right +
      -window.pageXOffset +
      (position.width - tooltip.caretX) +
      "px";
  }

  // Display, position, and set styles for font
  Object.assign(tooltipEl.style, update as CSSStyleDeclaration);
}

declare module "chroma-js" {
  interface Cubehelix {
    hue(h: number): Cubehelix;
    lightness(h: number): Cubehelix;
  }
}

const makePoint = (point: TimeSeriesPoint) => {
  const { date: x, value: y } = point;

  // Sanity check. We should not be showing extra large numbers on the sales page.
  // Treat any extra large number as "Infinity"
  let yValue = y === "Infinity" ? NaN : y ?? 0;
  if (yValue >= 1e10) {
    yValue = Infinity;
  }

  return { x, y: yValue };
};

export const makeDatasets = (
  ts: TimeSeries,
  color: string,
  options: {
    compareLabel?: string;
    compareSuffix?: string;
    bandSuffix?: [string, string];
  },
): ChartData<any>["datasets"][number][] => {
  const { name, bandPoints } = ts;
  let { points, comparePoints } = ts;
  if (points) {
    points = sortBy(points, "date");
  }

  if (comparePoints) {
    comparePoints = sortBy(comparePoints, "date");
  }

  if (bandPoints) {
    bandPoints[0] = sortBy(bandPoints[0], "date");
    bandPoints[1] = sortBy(bandPoints[1], "date");
  }

  const data = points.map(makePoint);

  const base = {
    label: name,
    data,
    lineTension: 0,
    fill: false,
    backgroundColor: color,
    borderColor: color,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    borderWidth: 2.5,
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointBorderWidth: 0,
    pointHoverRadius: 3.5,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointHoverBorderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 0,
    spanGaps: false,
  };

  const rv: ChartData<any>["datasets"][number][] = [base];

  if (bandPoints) {
    const [low, high] = bandPoints;

    const compLow = JSON.parse(JSON.stringify(base));
    compLow.label = `${name}${options.bandSuffix?.[0] ?? " (lower bound)"}`;
    compLow.data = low.map(makePoint);
    compLow.borderDash = [5, 5];
    rv.push(compLow);

    const compHigh = JSON.parse(JSON.stringify(base));
    compHigh.label = `${name}${options.bandSuffix?.[1] ?? " (upper bound)"}`;
    compHigh.data = high.map(makePoint);
    compHigh.borderDash = [5, 5];
    compHigh.fill = "-1";
    compHigh.backgroundColor = chroma(compHigh.backgroundColor)
      .alpha(0.3)
      .hex("rgba");
    rv.push(compHigh);
  }

  if (comparePoints) {
    const comp = JSON.parse(JSON.stringify(base));
    const label =
      options.compareLabel ??
      `${name}${options.compareSuffix ?? " (comparison)"}`;
    comp.label = label;
    comp.data = comparePoints.map(makePoint);
    comp.borderDash = [5, 5];
    rv.push(comp);
  }

  return rv;
};
