import { useUser } from "@components/user-context";
import { HTTP_METHOD, useRestApi } from "@lib/hooks";
import checkCircleIcon from "@pages/onboarding/assets/check-circle-icon.svg";
import exclamationMarkIcon from "@pages/onboarding/assets/exclamation-mark-icon.svg";
import Spinner from "@shared/spinner";
import React, { useEffect, useState } from "react";

type ShopifyPixelConnectionType = {
  connected: boolean;
  pixelId?: string;
  collectorDomain?: string;
};

type ShopifyPixelProps = {
  shopUrl: string;
};

export default function ShopifyPixel({ shopUrl }: ShopifyPixelProps) {
  // NOTE: The below endpoint doesn't yet exist but this is currently an unused component
  const { isAdmin } = useUser().user;
  const [pixelId, setPixelId] = useState<string>();
  const [pixelCollectorDomain, setPixelColllectorDomain] = useState<string>();
  const { data, loading, error } = useRestApi<ShopifyPixelConnectionType>(
    "/api/shopify-pixel/verify-and-connect",
    {
      method: HTTP_METHOD.POST,
      data: { shopUrl },
    },
  );

  const renderIcon = () => {
    if (loading || (!data && !error)) {
      return <Spinner />;
    }
    if (data?.connected) {
      return (
        <img
          className="mr-2 inline-block"
          src={checkCircleIcon}
          alt="Circle check icon"
        />
      );
    }

    return (
      <img
        className="mr-2 inline-block"
        src={exclamationMarkIcon}
        alt="Exclamation mark icon"
      />
    );
  };

  const renderMessage = () => {
    if (loading) return null;
    if (error) {
      return (
        <span className="text-red-600 text-sm">
          Error connecting pixel. Contact Northbeam Support
        </span>
      );
    }
    if (data?.connected) {
      return <span className="text-green-600 text-sm">Pixel connected 🚀</span>;
    }

    return <span className="text-amber-600 text-sm">Pixel status unknown</span>;
  };

  useEffect(() => {
    if (isAdmin && !loading && !error && data) {
      const { pixelId, collectorDomain } = data;
      pixelId && setPixelId(pixelId);
      collectorDomain && setPixelColllectorDomain(collectorDomain);
    }
  }, [isAdmin, data, error, loading]);

  const renderAdminComponent = () => {
    // Note: This can go away if this doesn't seem to be a
    // common problem. It might be nice at that time to replace
    // this with admin functionality to hit an endpoint which runs
    // a mutation on the pixel so settings can be updated.
    if (!isAdmin) {
      return null;
    }
    if (loading) {
      return null;
    }
    if (pixelId && pixelCollectorDomain) {
      return (
        <div className="m-3 border p-1">
          <p className="text-xs m-0 font-bold">Admin Note</p>
          <span className="text-xs">
            <span className="font-bold">ID:</span> {pixelId}
          </span>
          <span className="text-xs">
            <span className="font-bold ml-2">Domain:</span>{" "}
            {pixelCollectorDomain}
          </span>
        </div>
      );
    } else {
      return (
        <div className="m-3 border p-1">
          <p className="text-xs m-0">Admin Troubleshooting Help</p>
          <span className="text-xs m-0">
            Have client uninstall the Northbeam app on their shopify admin page,
            and then re-install at apps.shopify.com/north-beam.
          </span>
        </div>
      );
    }
  };

  return (
    <div className="mb-2">
      {renderIcon()}
      {renderMessage()}
      {renderAdminComponent()}
    </div>
  );
}
