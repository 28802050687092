import config from "@/environment";
import DatasetDoesNotExist from "@components/error-pages/dataset-does-not-exist";
import { LoadingSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import { Navbar as NavbarQuery } from "@nb-api-graphql-generated/Navbar";
import { PrimaryButton } from "@shared/buttons";
import { logEvent } from "@utils/analytics";
import { prefs } from "@utils/client-side-preferences";
import { useNorthbeamQuery } from "@utils/hooks";
import clsx from "clsx";
import React, { ReactElement, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useAppVersion } from "../../data/app-version";
import { NAVBAR_QUERY } from "../navbar";
import { CollapsibleSidebar } from "./collapsible-sidebar";
import { HeaderHamburgerMenu } from "./header-hamburger-menu";
import { ImpersonateClientSelect } from "./impersonate-client-select";
import { NotificationBellWithMenu } from "./notification-bell-with-menu";
import { TopBarDataFreshnessTooltip } from "./top-bar-data-freshness-tooltip";
import { TopBarLogoAndDynamicTitle } from "./top-bar-logo-and-dynamic-title";
export interface UserItem {
  id: string;
  email: string;
  isAdmin: boolean;
  companyName: string | null;
}

const ReloadPageToast = () => (
  <div>
    Northbeam has been updated.{" "}
    <PrimaryButton
      onClick={() => {
        logEvent("App reload due to server version change");
        window.location.reload();
      }}
    >
      Reload
    </PrimaryButton>
  </div>
);

const parseConnectionIssues = (data: NavbarQuery | undefined) => {
  try {
    return (
      data?.me?.accountMaintenanceItems?.map(
        (item) => JSON.parse(item.id).id,
      ) ?? []
    );
  } catch {
    return [];
  }
};

export const TopBar = ({
  onLogout,
  children,
}: {
  onLogout(): void;
  children: ReactElement | ReactElement[];
}) => {
  const { data, loading } = useNorthbeamQuery<NavbarQuery>(NAVBAR_QUERY, {
    pollInterval: 600_000,
    doNotRaiseErrorOnFailure: true,
  });

  const { appVersion } = useAppVersion();
  const { user } = useUser();
  const { addToast } = useToasts();

  const [usersList, setUsersList] = useState<UserItem[] | null>(
    data?.users || null,
  );

  const [numIncidents, setNumIncidents] = useState<number>(
    data?.me?.activeIncidentCount ?? 0,
  );

  const [numMaintenanceItems, setNumMaintenanceItems] = useState<number>(
    data?.me?.activeAccountMaintenanceItemCount ?? 0,
  );

  const [brokenConnections, setBrokenConnections] = useState<string[]>(
    parseConnectionIssues(data),
  );

  const [currentAppVersion, setCurrentAppVersion] = useState<string | null>(
    appVersion,
  );

  const [currentServerVersion, setCurrentServerVersion] = useState<
    string | undefined
  >(data?.currentServerVersion);

  const [activePage, setActivePage] = useState<string>("");

  useEffect(() => {
    // HACK HACK HACK
    // This attempts to find the first path argument after the client ID in the URL bar
    const splitPathName = window.location.pathname.split("/");
    if (splitPathName.length >= 3) {
      setActivePage(splitPathName[2]);
    }

    // eslint-disable-next-line
  }, [window.location.pathname]);

  const [datasetExists, setDatasetExists] = useState<boolean>(false);

  // eslint-disable-next-line
  const [_useDevelopmentAnalyticsColors, setUseDevelopmentAnalyticsColors] =
    useState<boolean>(false);

  // Reload page if app or server version changes
  useEffect(() => {
    if (!currentAppVersion) {
      setCurrentAppVersion(appVersion);
    }

    if (!currentServerVersion) {
      setCurrentServerVersion(data?.currentServerVersion);
    }

    if (
      (appVersion && currentAppVersion && currentAppVersion !== appVersion) ||
      (data?.currentServerVersion &&
        currentServerVersion &&
        currentServerVersion !== data.currentServerVersion)
    ) {
      addToast(<ReloadPageToast />, {
        appearance: "info",
        autoDismiss: false,
      });
      return;
    }
  }, [
    appVersion,
    currentAppVersion,
    setCurrentAppVersion,
    currentServerVersion,
    data?.currentServerVersion,
    addToast,
  ]);

  useEffect(() => {
    const users = data?.users || null;
    setDatasetExists(!!data?.me?.datasetExists);
    if (users && users.length > 1) {
      setUsersList(users);
    }

    setBrokenConnections(parseConnectionIssues(data));
    setNumIncidents(data?.me?.activeIncidentCount ?? 0);
    setNumMaintenanceItems(data?.me?.activeAccountMaintenanceItemCount ?? 0);
  }, [data]);

  useEffect(() => {
    setUseDevelopmentAnalyticsColors(
      config.isDevelopmentEnvironment || prefs.useDevelopmentAnalyticsData(),
    );
  }, [setUseDevelopmentAnalyticsColors]);

  if (loading || !data) {
    return (
      <div className="w-full">
        <LoadingSlide />
        <div className="hidden">{children}</div>
      </div>
    );
  }

  return (
    <>
      <CollapsibleSidebar />
      <header
        className={clsx(
          "w-full h-14 absolute flex justify-content-between items-center bg-white z-[102]",
          "border-b border-gray-2",
        )}
      >
        <TopBarLogoAndDynamicTitle />
        <div className="flex items-center space-x-2 mr-4">
          <TopBarDataFreshnessTooltip
            dataBlessingTimes={data.me.dataBlessingTimes}
            timezone={data.me.timezone}
            brokenConnections={brokenConnections}
          />
          <TopBarDivider />
          {usersList && usersList.length > 0 && (
            <ImpersonateClientSelect user={user} usersList={usersList!} />
          )}
          <NotificationBellWithMenu
            numIncidents={numIncidents}
            numMaintenanceItems={numMaintenanceItems}
          />
          <HeaderHamburgerMenu onLogout={onLogout} />
        </div>
      </header>

      {datasetExists ||
      activePage === "settings" ||
      process.env.REACT_APP_NB_ENV === "dev-local" ? (
        children
      ) : (
        <DatasetDoesNotExist />
      )}
    </>
  );
};

export const TopBarDivider = ({ className }: { className?: string }) => (
  <div className={clsx("h-6 w-[1.1px] bg-[#C1C5CD]", className)} />
);
