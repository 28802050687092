import classNames from "classnames";
import moment from "moment";
import React, { useRef } from "react";
import { DateRangePicker as DRP, FocusedInputShape } from "react-dates";
import Select from "react-select";
import {
  DateInterval,
  FixedDateRange,
  jsonHash,
  todayLocalAsISODate,
} from "@north-beam/nb-common";
import { parseDateRangeArgument } from "@utils/index";
import { DateRangeChoiceV2, isFixedDateRange } from "@utils/constants";
import {
  useEffectOnce,
  useEventListener,
  useOnClickOutside,
} from "usehooks-ts";
import { OptionType } from "@shared/selects/select";

type Or<T = OptionType> = T | FixedDateRange;

const DATE_FORMAT = "MMM DD, YYYY";

interface DateRangePickerProps<Value extends string> {
  className?: string;
  dropdownIconClassName?: string;
  nonCustomChoices: OptionType<Value>[];
  disabled?: boolean;
  current: Or<OptionType<Value>>;
  maxLookbackDays?: number;
  onUpdate: (dateRange: Or) => void;
  timezone: string;
}

export function DateRangePicker<Value extends string = string>({
  current,
  className,
  onUpdate,
  disabled = false,
  nonCustomChoices,
  dropdownIconClassName,
  maxLookbackDays,
  timezone,
}: DateRangePickerProps<Value>) {
  const [isOpen, setOpen] = React.useState(false);
  const [isCalendarOpen, setCalendarOpen] = React.useState(false);
  const toggleDropdown = () => {
    setOpen(!isOpen);
    setCalendarOpen(false);
  };
  const cancel = () => setOpen(false);

  useEffectOnce(() => {
    moment.tz.setDefault(timezone);
  });

  const commit = (dateRange: Or) => {
    toggleDropdown();

    if (jsonHash(current) !== jsonHash(dateRange)) {
      onUpdate(dateRange);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Escape") {
      cancel();
      event.stopPropagation();
    }
  };

  const buttonText = formatDateRangeText(current);
  const indicator = `fas ${dropdownIconClassName || "fa-calendar-alt"}`;

  const firstDropdownChoices = [
    ...nonCustomChoices,
    { label: "Custom date...", value: "" },
  ];

  const openCalendar = isOpen && isCalendarOpen;
  const openFirstDropdown = isOpen && !isCalendarOpen;

  const firstDropdownSelect = (v: OptionType | null) => {
    if (v?.value) {
      commit(v);
    } else {
      setCalendarOpen(true);
    }
  };

  const ref = useRef(null);
  useOnClickOutside(ref, cancel);
  useEventListener("keydown", (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      cancel();
      event.stopPropagation();
    }
  });

  return (
    <div className={classNames(className)} ref={ref}>
      <div className="nb-date-picker">
        {openCalendar && (
          <span className="calendar">
            <Calendar
              onSelect={commit}
              maxLookbackDays={maxLookbackDays ?? 20 * 365}
            />
          </span>
        )}
        <button
          className={classNames("date-picker-button", isOpen && "active")}
          onClick={toggleDropdown}
          disabled={disabled}
          type="button"
        >
          <div className="date-picker-content">
            <div className="date-text">{buttonText}</div>
            <div className="date-indicator">
              <i className={indicator} />
            </div>
          </div>
        </button>
      </div>
      {openFirstDropdown && (
        <Select<OptionType, false>
          autoFocus
          isDisabled={disabled}
          backspaceRemovesValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          isSearchable={false}
          styles={{
            menu: () => ({
              backgroundColor: "white",
              borderRadius: 4,
              boxShadow: `0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1)`,
              marginTop: 8,
              position: "absolute",
              zIndex: 1002,
              minWidth: 240,
            }),
          }}
          menuIsOpen={true}
          onKeyDown={handleKeyDown}
          onChange={firstDropdownSelect}
          components={{ Control: () => null }}
          closeMenuOnSelect={true}
          options={firstDropdownChoices}
          tabSelectsValue={false}
          getOptionLabel={(v) => v.label}
          getOptionValue={(v) => v.label}
        />
      )}
    </div>
  );
}

interface CalendarProps {
  onSelect(dateRange: FixedDateRange): void;
  maxLookbackDays: number;
}

function Calendar(props: CalendarProps) {
  const { onSelect, maxLookbackDays } = props;

  const [startDate, setStartDate] = React.useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = React.useState<moment.Moment | null>(null);
  const [focusedInput, setFocusedInput] =
    React.useState<FocusedInputShape | null>("startDate");

  const isOutsideRange = React.useCallback(
    (day: moment.Moment) => {
      const today = moment().startOf("day");
      const daysBeforeToday = today.diff(day.startOf("day"), "days");
      return daysBeforeToday < 0 || daysBeforeToday >= maxLookbackDays;
    },
    [maxLookbackDays],
  );

  const saveDates = React.useCallback(
    (arg: { startDate: moment.Moment; endDate: moment.Moment }) => {
      const { startDate, endDate } = arg;
      if (!startDate || !endDate) {
        return;
      }

      onSelect({
        type: "fixed",
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      });
    },
    [onSelect],
  );

  return (
    <DRP
      startDateId="startDate"
      endDateId="endDate"
      startDate={startDate}
      endDate={endDate}
      focusedInput={focusedInput}
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
      }}
      noBorder
      onFocusChange={(v) => setFocusedInput(v)}
      displayFormat="MMM DD YYYY"
      numberOfMonths={2}
      minimumNights={0}
      initialVisibleMonth={lastMonth}
      isOutsideRange={isOutsideRange}
      onClose={saveDates}
      small={true}
      block={true}
      hideKeyboardShortcutsPanel
    />
  );
}

function lastMonth() {
  return moment().startOf("month").subtract(1, "month");
}

export function formatDateRangeText(or: Or) {
  if (isFixedDateRange(or)) {
    return <FormattedDateRange dateRange={or} />;
  } else {
    return (or as any).label;
  }
}

export function formatDateRange(dateRange: DateInterval) {
  const { startDate, endDate } = dateRange;
  const start = moment(startDate);
  const end = moment(endDate);
  if (start.isSame(end, "day")) {
    return start.format(DATE_FORMAT);
  } else if (start.isSame(end, "year")) {
    return `${start.format("MMM D")} – ${end.format("MMM D")}`;
  }

  return `${start.format(DATE_FORMAT)} – ${end.format(DATE_FORMAT)}`;
}

export function FormattedDateRange({ dateRange }: { dateRange: DateInterval }) {
  return <React.Fragment>{formatDateRange(dateRange)}</React.Fragment>;
}

export function DateRangeDisplay({
  dateRange,
  onConversionToFixed,
}: {
  dateRange: FixedDateRange | DateRangeChoiceV2;
  onConversionToFixed?: (dr: FixedDateRange) => void;
}) {
  const today = todayLocalAsISODate();
  const anchor = today;
  const dr = parseDateRangeArgument(dateRange, anchor);
  const isFixed = typeof dateRange !== "string";

  const start = moment(dr.startDate).format(DATE_FORMAT);
  const end = moment(dr.endDate).format(DATE_FORMAT);

  const numDays = moment(dr.endDate).diff(moment(dr.startDate), "days") + 1;
  const noun = numDays === 1 ? "day" : "days";

  let topHalf: React.ReactNode;
  if (isFixed || dateRange === "monthToDate") {
    topHalf = (
      <div>
        {start} — {end} ({numDays} {noun})
      </div>
    );
  } else {
    const displayable = start === end ? start : `${start} — ${end}`;
    topHalf = (
      <div
        onClick={() =>
          onConversionToFixed && onConversionToFixed({ ...dr, type: "fixed" })
        }
      >
        {displayable}
      </div>
    );
  }

  const showNotice = dr.endDate >= today;
  let bottomHalf: any = null;
  if (showNotice) {
    bottomHalf = (
      <p className="text-danger" style={{ maxWidth: 240 }}>
        Reports including the current day may return incomplete data.
      </p>
    );
  }

  return (
    <>
      {topHalf}
      {bottomHalf}
    </>
  );
}
