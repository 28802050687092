import { usePageTitle } from "@/atoms/page-title-atom";
import { TimeSeriesChart } from "@components/reports/charts";
import { LoadingSlide, TitleSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import { H1 } from "@components/utilities";
import {
  GetIncident,
  GetIncidentVariables,
  GetIncident_me_alertConfig_incident,
  GetIncident_me_alertConfig_incident_adObject,
} from "@nb-api-graphql-generated/GetIncident";
import { SubjectItem } from "@north-beam/nb-common";
import { TimeSeries } from "@north-beam/nb-common";
import {
  DateInterval,
  deslugify,
  FixedDateRange,
  todayLocalAsISODate,
} from "@north-beam/nb-common";
import { flattenAlarmCondition } from "@north-beam/nb-common";
import { DetailsSection } from "@pages/objects/ad-object-editor";
import { AdIcon } from "@pages/objects/utils";
import { logEvent, LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import classNames from "classnames";
import Interweave from "interweave";
import _ from "lodash";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  ClickToExpandSection,
  computeDateRangeForIncident,
  DismissButton,
  formAttributionMethodFromMetricIds,
  formIconPlatformName,
  formLinkForSubjectItem,
  formMetricHeader,
  formTimeContextText,
  MetricDetails,
  MetricDetailsDisplay,
  SnoozeButton,
} from "./components/incident-views";
import { flattenResultRows, MetricFragment } from "./components/utils";
import { GET_INCIDENT } from "./queries";

export function IncidentPage({ alertConfigId }: { alertConfigId: string }) {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Incident");
  }, [setPageTitle]);
  const { user } = useUser();

  const { subjectItemSlug = "", startAt = "" } = useParams<{
    subjectItemSlug: string;
    startAt: string;
  }>();

  const subjectItem: SubjectItem = React.useMemo(
    () => deslugify(subjectItemSlug),
    [subjectItemSlug],
  );

  const { data, loading } = useNorthbeamQuery<
    GetIncident,
    GetIncidentVariables
  >(GET_INCIDENT, {
    variables: {
      alertConfigId,
      subjectItem,
      startAt,
    },
  });

  const incident = data?.me.alertConfig?.incident;

  const attributionModel = React.useMemo(() => {
    if (!incident) {
      return undefined;
    }
    const conds = flattenAlarmCondition(incident.latestPoint.alarmCondition);
    const metricIds = conds.map((cond) => cond.metric.id);

    return formAttributionMethodFromMetricIds(metricIds);
  }, [incident]);

  const metricHeaders: MetricDetails[] | undefined = React.useMemo(() => {
    if (!incident) {
      return undefined;
    }
    const conditions = flattenAlarmCondition(
      incident.latestPoint.alarmCondition,
    );
    const resultRows = flattenResultRows(
      incident.latestPoint.previewRow.resultRow,
    );

    const rv: MetricDetails[] = [];
    for (let i = 0; i < conditions.length; ++i) {
      rv.push(formMetricHeader(resultRows[i], conditions[i]));
    }

    if (!rv.some((h) => h.name === "Spend")) {
      rv.push({
        name: "Spend",
        id: "spend",
        format: "dollars",
        value: incident.latestPoint.previewRow.averageDailySpend,
        referenceValue: null,
        isReferenceValueFixed: true,
        windowSizeDays: conditions[0].currentValue.windowEndOffsetDays,
      });
    }

    return rv;
  }, [incident]);

  if (loading) {
    return <LoadingSlide />;
  }

  if (!incident || !metricHeaders || !attributionModel) {
    return (
      <TitleSlide>
        <span>Not found. :(</span>
      </TitleSlide>
    );
  }

  const dateRange = computeDateRangeForIncident(incident);
  const adObject = incident.adObject;
  const iconPlatformName = formIconPlatformName(subjectItem);
  const rightText: string = formTimeContextText(incident);

  return (
    <div className="container mb-4">
      <LogOnMount
        name="View incident details"
        data={{
          alertConfigId,
          subjectItem,
          startAt,
        }}
      />
      <div className="row">
        <div className="col">
          <H1 className="flex items-center">
            <AdIcon sizeInEM={1} platform={iconPlatformName} />{" "}
            {incident.subjectItem.name}
          </H1>
          <div className="flex mb-4 text-muted">
            <div className="flex-grow-1">
              Triggered by:{" "}
              <Link
                to={user.pathFromRoot(`/monitor/view/${incident.config.id}`)}
              >
                {incident.config.name}
              </Link>
            </div>
            <div>{rightText}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Incident data={incident} metricHeaders={metricHeaders} />
        </div>
      </div>
      <div className="row">
        <div className="col nb-incident-chart">
          <h5 className="mt-4">Performance Metrics</h5>
          <ChartSection
            data={incident.chartPoints}
            metricHeaders={metricHeaders}
          />
        </div>
      </div>
      {adObject && (
        <div className="row">
          <div className="col">
            <div className="my-3">
              <h5 className="mt-4">Campaign details</h5>
              <AdObjectSection
                adObject={adObject}
                dateRange={dateRange}
                attributionModel={attributionModel}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Incident({
  data,
}: {
  data: GetIncident_me_alertConfig_incident;
  metricHeaders: MetricDetails[];
}) {
  const { user } = useUser();
  const showSnoozeButton = data.status !== "closed";
  const showDismissButton = data.status !== "closed";

  const subjectItem: SubjectItem = data.subjectItem.key;
  const reportLink = formLinkForSubjectItem(
    user,
    data.latestPoint.alarmCondition,
    subjectItem,
    computeDateRangeForIncident(data),
  );

  const description = data.description ?? "";
  const clickToExpandSection =
    data.actionItemsConfig.clickToExpandSection ?? "";

  return (
    <div>
      <div>
        {description && <Interweave content={description} />}
        {clickToExpandSection && (
          <ClickToExpandSection
            clickToExpandMoreHTML={clickToExpandSection}
            alertConfigId={data.config.id}
            subjectItem={subjectItem}
            startAt={data.startAt}
          />
        )}
        {!description && !clickToExpandSection && (
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            mattis, turpis nec fermentum finibus, nisl tellus luctus ipsum, eget
            tincidunt justo felis in lacus. Integer elementum auctor venenatis.
            Morbi porta auctor felis, dignissim facilisis erat pharetra eu.
            Aenean iaculis odio sed eleifend sodales. Phasellus porttitor arcu
            rutrum ultrices interdum.
          </p>
        )}
      </div>
      <div className="mt-3">
        <Link
          className="btn btn-outline-primary mr-2"
          to={reportLink}
          onClick={() =>
            logEvent("Incident: clicks on sales report button", {
              alertConfigId: data.config.id,
              subjectItem: subjectItem,
              startAt: data.startAt,
            })
          }
        >
          View sales report
        </Link>
        {showSnoozeButton && (
          <div className="mr-2 d-inline-block">
            <SnoozeButton
              alertConfigId={data.config.id}
              subjectItem={data.subjectItem.key}
              startAt={data.startAt}
              ignoredUntil={data.ignoredUntil}
            />
          </div>
        )}
        {showDismissButton && (
          <div className="mr-2 d-inline-block">
            <DismissButton
              alertConfigId={data.config.id}
              subjectItem={data.subjectItem.key}
              startAt={data.startAt}
            />
          </div>
        )}
      </div>
    </div>
  );
}

interface ChartPoints {
  metrics: MetricFragment[];
  dateRange: DateInterval;
  dimensionIdToName: Record<string, string>;
  rows: {
    value: string;
    date: string;
    metricId: string;
    dimensionId: string;
  }[];
}

function ChartSection({
  data,
  metricHeaders,
}: {
  data: ChartPoints;
  metricHeaders: MetricDetails[];
}) {
  const { dimensionIdToName, rows } = data;

  const [selectedMetricIndex, setSelectedMetricIndex] = React.useState(0);

  const actualMetricIndex = React.useMemo(
    () => Math.min(selectedMetricIndex, metricHeaders.length - 1),
    [selectedMetricIndex, metricHeaders],
  );

  const selectedMetricHeader = React.useMemo(
    () => metricHeaders[actualMetricIndex],
    [metricHeaders, actualMetricIndex],
  );

  const reactHeader = (
    <div className="flex border-bottom">
      {metricHeaders.map((metricHeader, index) => (
        <div
          className={classNames(
            "m-3 p-3 border rounded nb-incident-metric-header",
            {
              active: index === actualMetricIndex,
            },
          )}
          key={metricHeader.id}
          onClick={() => setSelectedMetricIndex(index)}
        >
          <MetricDetailsDisplay {...metricHeader} />
        </div>
      ))}
    </div>
  );

  const timeSerieses: TimeSeries[] = React.useMemo(() => {
    return _.chain(rows)
      .filter((v) => v.metricId === selectedMetricHeader.id)
      .groupBy("dimensionId")
      .map((rows, key) => {
        rows = _.sortBy(rows, "date");
        return {
          name: dimensionIdToName[key],
          points: rows.map((v) => ({
            date: v.date,
            value: v.value,
          })),
        } as TimeSeries;
      })
      .value();
  }, [selectedMetricHeader, rows, dimensionIdToName]);

  const reactTimeSerieses = (
    <div className="m-3">
      <TimeSeriesChart
        yLabel={selectedMetricHeader.name}
        format={selectedMetricHeader.format}
        xAxisFormat="date"
        serieses={timeSerieses}
        noLegend={true}
      />
    </div>
  );

  return (
    <div className="border rounded mb-3">
      {reactHeader}
      {reactTimeSerieses}
    </div>
  );
}

function AdObjectSection({
  adObject,
  dateRange,
  attributionModel,
}: {
  adObject: GetIncident_me_alertConfig_incident_adObject;
  dateRange: DateInterval;
  attributionModel: string;
}) {
  const fdr: FixedDateRange = {
    type: "fixed",
    ...dateRange,
  };
  const anchor = todayLocalAsISODate();
  return (
    <DetailsSection
      {...adObject}
      labels={null}
      dateRange={dateRange}
      anchor={anchor}
      state={{
        dateRange: fdr,
        attributionModel,
        attributionWindow: "1",
        nbAccountingMode: "accrual",
        compareDateRange: "lastPeriod",
        timeGranularity: "daily",
      }}
    />
  );
}
