import {
  DELETE_CUSTOM_SALES_VIEW,
  GET_CUSTOM_SALES_VIEWS,
  GET_CUSTOM_SALES_VIEWS_ORDERING,
  INSERT_CUSTOM_SALES_VIEW,
  UPSERT_CUSTOM_SALES_VIEWS_ORDERING,
} from "@gql/custom-sales-view";
import {
  DeleteCustomSalesView,
  DeleteCustomSalesViewVariables,
} from "@nb-api-graphql-generated/DeleteCustomSalesView";
import { GetCustomSalesViews } from "@nb-api-graphql-generated/GetCustomSalesViews";
import { GetCustomSalesViewsOrdering } from "@nb-api-graphql-generated/GetCustomSalesViewsOrdering";
import {
  InsertCustomSalesView,
  InsertCustomSalesViewVariables,
} from "@nb-api-graphql-generated/InsertCustomSalesView";
import {
  UpsertCustomSalesViewsOrdering,
  UpsertCustomSalesViewsOrderingVariables,
} from "@nb-api-graphql-generated/UpsertCustomSalesViewsOrdering";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import { cloneDeep, sortBy } from "lodash";
import { useCallback, useMemo } from "react";
import { SalesView, SalesViewTemplate } from "./sales-views-context";
import { salesViewsTemplates } from "./saved-views/sales-views-templates";
import { normalizeMetricKey } from "./tab-table-section/table/table-utils";

export const useCustomSalesViews = () => {
  const {
    data,
    loading: isCustomSalesViewsLoading,
    refetch: refetchCustomSalesViews,
  } = useNorthbeamQuery<GetCustomSalesViews>(GET_CUSTOM_SALES_VIEWS);

  const {
    data: salesViewsOrdering,
    loading: isCustomSalesViewsOrderingLoading,
    refetch: refetchCustomSalesViewsOrdering,
  } = useNorthbeamQuery<GetCustomSalesViewsOrdering>(
    GET_CUSTOM_SALES_VIEWS_ORDERING,
  );

  const salesViews = useMemo(() => {
    if (isCustomSalesViewsLoading || isCustomSalesViewsOrderingLoading) {
      return [];
    }
    const normalizedMetricNamesCustomViews = (
      data?.me?.customSalesViews ?? []
    ).map((customView: SalesView) => {
      const customViewCopy = cloneDeep(customView);
      customViewCopy.data.columnConfigurations.forEach((column) => {
        column.name = normalizeMetricKey(column.name);
      });

      const selectedMetricBoxes = customViewCopy.data.selectedMetricBoxes;
      if (selectedMetricBoxes) {
        customViewCopy.data.selectedMetricBoxes =
          selectedMetricBoxes.map(normalizeMetricKey);
      }
      return customViewCopy;
    });

    const customSalesViews: (SalesView | SalesViewTemplate)[] = [
      ...salesViewsTemplates,
      ...normalizedMetricNamesCustomViews,
    ];
    const ordering: SalesView["id"][] = (
      salesViewsOrdering?.me?.customSalesViewsOrdering ?? []
    ).map(normalizeMetricKey);

    return sortBy(customSalesViews, ({ id }) => {
      const order = ordering.indexOf(id);
      // New ones go to the end
      return order === -1 ? Infinity : order;
    });
  }, [
    data,
    isCustomSalesViewsLoading,
    isCustomSalesViewsOrderingLoading,
    salesViewsOrdering,
  ]);

  const [createCustomSalesViewMutation] = useNorthbeamMutation<
    InsertCustomSalesView,
    InsertCustomSalesViewVariables
  >(INSERT_CUSTOM_SALES_VIEW);

  const createCustomSalesView = useCallback(
    async (name: SalesView["name"], data: SalesView["data"]) =>
      createCustomSalesViewMutation({
        variables: {
          name,
          data,
        },
      }),
    [createCustomSalesViewMutation],
  );

  const [deleteCustomSalesViewMutation] = useNorthbeamMutation<
    DeleteCustomSalesView,
    DeleteCustomSalesViewVariables
  >(DELETE_CUSTOM_SALES_VIEW);

  const deleteCustomSalesView = useCallback(
    async (id: string) => {
      await deleteCustomSalesViewMutation({
        variables: { id },
      });
      await refetchCustomSalesViews();
    },
    [deleteCustomSalesViewMutation, refetchCustomSalesViews],
  );

  const [upsertUserSalesViewsOrdering] = useNorthbeamMutation<
    UpsertCustomSalesViewsOrdering,
    UpsertCustomSalesViewsOrderingVariables
  >(UPSERT_CUSTOM_SALES_VIEWS_ORDERING);

  const upsertSalesViewsOrdering = useCallback(
    async (ids: string[]) => {
      await upsertUserSalesViewsOrdering({
        variables: { ids },
      });
      await refetchCustomSalesViewsOrdering();
    },
    [upsertUserSalesViewsOrdering, refetchCustomSalesViewsOrdering],
  );

  return {
    salesViews,
    isCustomSalesViewsLoading:
      isCustomSalesViewsLoading || isCustomSalesViewsOrderingLoading,
    upsertSalesViewsOrdering,
    refetchCustomSalesViews,
    createCustomSalesView,
    deleteCustomSalesView,
  };
};
