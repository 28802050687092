import React from "react";
import Select from "react-select";
import { INBAccountingMode, NBAccountingMode } from "@utils/constants";
import { AccountingDropdown } from "@shared/selects/dropdown-indicators";

interface NbAccountingModeSelectProps {
  className?: string;
  isLoading: boolean;
  nbAccountingMode: INBAccountingMode;
  onChange: (obj: any) => void;
}

export const NbAccountingModeSelect = ({
  className,
  isLoading,
  nbAccountingMode,
  onChange,
}: NbAccountingModeSelectProps) => {
  return (
    <Select
      className={className}
      isDisabled={isLoading}
      isClearable={false}
      isSearchable={false}
      defaultValue={NBAccountingMode.toReactSelect(nbAccountingMode)}
      value={NBAccountingMode.toReactSelect(nbAccountingMode)}
      components={{ DropdownIndicator: AccountingDropdown }}
      options={NBAccountingMode.reactSelectOptions}
      onChange={onChange}
    />
  );
};
