import React from "react";

import { Card } from "@shared/card";
import { CustomerTouchpoint, JourneyCard } from "./JourneyCard";

export type CustomerJourneyProps = {
  touchpoints: CustomerTouchpoint[];
  isUnknownTouchpoints: boolean;
};

export function CustomerJourney({
  touchpoints,
  isUnknownTouchpoints,
}: CustomerJourneyProps) {
  return (
    <div className="ml-7">
      <Card className=" py-3 w-[43rem] rounded-md bg-white	">
        <div className="flex w-full font-semibold mb-2">
          <div>Journey</div>
          {/* A completed customer journey will always have a purchase (this is not counted in the touchpoint though) */}
          <div className="ml-auto">
            {isUnknownTouchpoints
              ? "Touchpoints not available"
              : `${touchpoints.length - 1} Touchpoints`}
          </div>
        </div>
        <div className="h-[43rem] overflow-auto">
          {touchpoints.length <= 1 && !isUnknownTouchpoints && (
            <div
              key={`customer-journey-no-touchpoint`}
              className="rounded-md bg-[#F2F3F4] mb-2 h-[6.313rem] flex items-center	justify-center"
            >
              <div>
                <div className="flex flex-row mb-3 ">
                  We were unable to detect the accurate touchpoints of this
                  order.
                </div>
              </div>
            </div>
          )}
          {touchpoints.map((touchpoint, i) => (
            <div
              className={i - 1 !== touchpoints.length ? "mb-2" : undefined}
              key={`customer-journey-touchpoint-${i}`}
            >
              <JourneyCard touchpoint={touchpoint} />
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}
