import { FormattedDateRange } from "@components/date-range-picker";
import { GenericExternalLink } from "@components/generic-external-link";
import { NoDataView } from "@components/title-slide-view";
import { GenericTooltip } from "@components/tooltip-container";
import { useUser } from "@components/user-context";
import { H1 } from "@components/utilities";
import { GetOverviewSnapshot_me_overviewPage } from "@nb-api-graphql-generated/GetOverviewSnapshot";
import { todayLocalAsISODate } from "@north-beam/nb-common";
import { parseDateRangeArgument } from "@utils/index";
import React from "react";
import { BreakdownView } from "./breakdown-view";
import { MetricCharts } from "./metric-charts";
import { FetchReportParams } from "./overview";

export const CashSnapshot = ({
  response: { snapshotMetrics, snapshotBreakdownTableRows },
  bodyState,
  breakdown,
  isLoading,
}: FetchReportParams<GetOverviewSnapshot_me_overviewPage>) => {
  const { user } = useUser();
  const { dateRange: dr, attributionModel } = bodyState;
  const anchor = todayLocalAsISODate();
  const dateRange = parseDateRangeArgument(dr, anchor);

  let lowerSection = <NoDataView />;

  if (snapshotMetrics && snapshotBreakdownTableRows) {
    lowerSection = (
      <>
        <div className="row px-4 mb-3 nb-print-block bg-white enableDesignRefresh-overview-charts">
          <div className="col" data-test="snapshot-chart">
            <MetricCharts
              metricsOverview={snapshotMetrics}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="row px-4 my-3 nb-print-block nb-breakdown-table">
          <div className="col p-0" data-test="snapshot-breakdown">
            <BreakdownView
              breakdownTitle="Breakdown (Cash snapshot)"
              rows={snapshotBreakdownTableRows}
              isLoading={isLoading}
              attributionWindow="infinity"
              attributionModel={attributionModel}
              breakdown={breakdown}
              snapshotSection
            />
          </div>
        </div>
      </>
    );
  }

  const amEnum = user.attributionMethodEnum;

  return (
    <>
      <div className="px-4 row nb-print-block">
        <div className="col p-0">
          <H1 className="mt-2">
            Cash snapshot{" "}
            <small>
              (<FormattedDateRange dateRange={dateRange} />)
            </small>
          </H1>
          <p className="mt-3 mb-4 nb-accounting-methods-short-explanation">
            <GenericTooltip
              content={
                <>
                  Your current model is{" "}
                  <b>{amEnum.toReactSelect(attributionModel).label}</b>. To
                  learn more about attribution models and windows, check out our{" "}
                  <GenericExternalLink href="https://info.northbeam.io/knowledge/understanding-northbeam-accounting-cash-vs-accrual-performance">
                    FAQs.
                  </GenericExternalLink>
                </>
              }
            >
              This model attributes conversions to the{" "}
              <strong>day that they occurred</strong>, not to prior marketing
              efforts.
            </GenericTooltip>
          </p>
          <p className="nb-accounting-methods-read-more">
            <small>
              <GenericExternalLink href="https://info.northbeam.io/knowledge/understanding-northbeam-accounting-cash-vs-accrual-performance">
                Read more about Northbeam accounting methods.
              </GenericExternalLink>
            </small>
          </p>
        </div>
      </div>
      {lowerSection}
    </>
  );
};
