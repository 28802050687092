import { formatDateTime } from "@north-beam/nb-common";
import React from "react";
import { Annotation } from "@features/custom-annotations";

interface AnnotationMetadataProps {
  annotatedDate?: Annotation["annotatedDate"];
  updatedAt?: Annotation["updatedAt"];
  user?: Annotation["user"];
}

const DISPLAY_FORMAT = "MMM D, YYYY";

const displayUser = (user?: Annotation["user"]) => {
  if (user?.first_name || user?.last_name) {
    return `, By ${user?.first_name ?? ""} ${user.last_name ?? ""}`;
  }
  return "";
};

export const AnnotationMetadata = ({
  annotatedDate,
  updatedAt,
  user,
}: AnnotationMetadataProps) => (
  <div className="text-xxs flex justify-between text-subtitle">
    <span>
      {annotatedDate && formatDateTime(annotatedDate, DISPLAY_FORMAT)}
    </span>
    <span>
      Last update:{" "}
      {updatedAt && formatDateTime(updatedAt, `${DISPLAY_FORMAT} @h:mmA`)}
      {displayUser(user)}
    </span>
  </div>
);
