import { ControlBar, ControlBarElement } from "@components/control-bar";

import { formatDateRangeText } from "@components/date-range-picker";
import { GenericDropdown } from "@components/dropdown";

import { StuckToTop } from "@components/stuck-to-top";
import { DateRangeSelect } from "@shared/selects";
import { CompareDateRangeSelect } from "@shared/selects/compare-date-range-select";
import { TimeGranularitySelect } from "@shared/selects/time-granularity-select";
import {
  CompareDateRangeEnum,
  DateRangeChoiceEnum,
  TimeGranularity,
} from "@utils/constants";
import classNames from "classnames";
import React from "react";

import { useReportBodyState } from "./orders-control-state";

import {
  EcomPlatformFriendlyNames,
  PlatformFriendlyNames,
} from "@/constants/platform-friendly-names";
import { GenericTooltip } from "@components/tooltip-container";
import { useOrderQueriesLoading } from "@hooks/use-order-page-loading";
import {
  useOrderPageAdjustForReturns,
  useOrderPageAdPlatforms,
  useOrderPageEcommercePlatforms,
} from "@hooks/use-orders-filters";
import { FetchOrderPlatformAndEcommerceOptions } from "@nb-api-graphql-generated/FetchOrderPlatformAndEcommerceOptions";
import { FETCH_ORDER_PLATFORMS_AND_ECOMMERCE_OPTIONS } from "@pages/settings/queries";
import { prettyLogEvent } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import { reasonableGuessAtNaming } from "@utils/pretty-names";
import { compact } from "lodash";
import { MultiBadgeEditor } from "./orders-table/editors";

export interface ReportBodyControlProps {
  isLoading: boolean;
}

type SelectorProps = {
  isLoading: boolean;
  options: string[];
};

const AdPlatformSelector = ({ isLoading, options }: SelectorProps) => {
  const [adPlatforms, setAdPlatforms] = useOrderPageAdPlatforms();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <div className="w-full">
      <div>
        <label>Ad Platforms</label>
        <GenericTooltip
          content={
            <>
              An ad platform represents the platform used as part of a
              touchpoint in the customer journey
            </>
          }
          overlayClass=""
        />
      </div>
      <MultiBadgeEditor
        type="ad-platform"
        shouldAnon={false}
        options={options.map((o) => ({
          value: o,
          label: PlatformFriendlyNames[o] || reasonableGuessAtNaming(o),
        }))}
        isLoading={isLoading}
        onChange={(values) => {
          const platforms = values?.map((i) => i.value);

          prettyLogEvent({
            page: "Orders",
            action: "onSelect",
            additionalActionInfo: "ad-platform",
            data: { platforms },
          });
          setAdPlatforms(platforms);
        }}
        value={
          adPlatforms?.length
            ? compact(adPlatforms).map((i) => ({
                value: i,
                label: PlatformFriendlyNames[i] || reasonableGuessAtNaming(i),
              }))
            : []
        }
        disabled={orderQueriesLoading}
      />
    </div>
  );
};

const EcommercePlatformSelector = ({ isLoading, options }: SelectorProps) => {
  const [ecommercePlatforms, setEcommercePlatforms] =
    useOrderPageEcommercePlatforms();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <div className="w-full">
      <div>
        <label>E-commerce Platforms</label>
        <GenericTooltip
          content={
            <>
              An e-commerce platform is the platform responsible for managing
              the order
            </>
          }
          overlayClass=""
        />
      </div>
      <MultiBadgeEditor
        type="ecommerce-platform"
        shouldAnon={false}
        options={options.map((o) => ({
          value: o,
          label: EcomPlatformFriendlyNames[o] || reasonableGuessAtNaming(o),
        }))}
        isLoading={isLoading}
        onChange={(values) => {
          const platforms = values?.map((i) => i.value);
          prettyLogEvent({
            page: "Orders",
            action: "onSelect",
            additionalActionInfo: "e-commerce-platform",
            data: { platforms },
          });
          setEcommercePlatforms(platforms);
        }}
        disabled={orderQueriesLoading}
        value={
          ecommercePlatforms?.length
            ? compact(ecommercePlatforms).map((i) => ({
                value: i,
                label:
                  EcomPlatformFriendlyNames[i] || reasonableGuessAtNaming(i),
              }))
            : []
        }
      />
    </div>
  );
};

export const OrdersControl = ({ isLoading }: ReportBodyControlProps) => {
  const {
    state,

    timeGranularity,
    setTimeGranularity,

    setDateRange,
    setCompareDateRange,
  } = useReportBodyState();

  const [adjustForReturns, setAdjustForReturns] =
    useOrderPageAdjustForReturns();

  const orderQueriesLoading = useOrderQueriesLoading();

  let maxLookbackDays = 20 * 365;
  let maxLookbackDaysCompare = 20 * 365;

  if (timeGranularity === "hourly") {
    maxLookbackDays = 8;
    maxLookbackDaysCompare = 15;
  }

  const dateRangeCurrent =
    typeof state.dateRange === "string"
      ? DateRangeChoiceEnum.toReactSelect(state.dateRange)
      : state.dateRange;

  const compareDateRangeCurrent =
    typeof state.compareDateRange === "string"
      ? CompareDateRangeEnum.toReactSelect(state.compareDateRange)
      : state.compareDateRange;

  const { data: optionsData, loading } = useNorthbeamQuery<
    FetchOrderPlatformAndEcommerceOptions,
    unknown
  >(FETCH_ORDER_PLATFORMS_AND_ECOMMERCE_OPTIONS);

  return (
    <StuckToTop>
      {() => (
        <ControlBar className="elevation-1 mr-0">
          <div className="flex justify-center">
            <div className="flex w-[40rem]">
              <EcommercePlatformSelector
                isLoading={loading}
                options={optionsData?.me.ecommercePlatforms || []}
              />
              <div className="mr-4" />
              <AdPlatformSelector
                isLoading={loading}
                options={optionsData?.me.adPlatforms || []}
              />
            </div>
            <div className="flex">
              <label className="container justify-center mt-9">
                <input
                  type="checkbox"
                  checked={adjustForReturns}
                  onChange={() => {
                    prettyLogEvent({
                      page: "Orders",
                      action: "onSelect",
                      additionalActionInfo: "adjust-for-returns",
                      data: {
                        adjustForReturns: !adjustForReturns,
                      },
                    });
                    setAdjustForReturns(!adjustForReturns);
                  }}
                  className="mr-2"
                  disabled={orderQueriesLoading}
                />
                Adjust for returns
              </label>
            </div>
          </div>
          <div className="flex flex-row items-center justify-content-end pl-5 ml-auto">
            <GenericDropdown
              renderButton={({ toggle }) => (
                <button
                  className="dropdown-btn btn btn-outline-secondary dropdown-toggle custom-dropdown-toggle flex items-center"
                  style={{ height: 61 }}
                  disabled={isLoading}
                  type="button"
                  onClick={toggle}
                >
                  <div className="flex flex-column align-items-start mr-2">
                    <div style={{ marginBottom: -4, fontSize: 14 }}>
                      {formatDateRangeText(dateRangeCurrent)},{" "}
                      {TimeGranularity.getLabel(timeGranularity)}
                    </div>
                    <small className="text-muted mt-1 text-small">
                      Compared to {formatDateRangeText(compareDateRangeCurrent)}
                    </small>
                  </div>
                </button>
              )}
              renderList={({ isOpen }) => (
                <div
                  className={classNames(
                    "dropdown-menu dropdown-menu-right rounded",
                    { show: isOpen },
                  )}
                  style={{
                    position: "absolute",
                    minWidth: "20rem",
                    marginTop: "0.5rem",
                  }}
                >
                  <ControlBarElement
                    title="Time granularity"
                    parentClassName="mt-2 mb-3 px-2.5 nb-control-bar-element"
                  >
                    <TimeGranularitySelect
                      isLoading={isLoading}
                      timeGranularity={timeGranularity}
                      onChange={(granuarlity) => {
                        prettyLogEvent({
                          page: "Orders",
                          action: "onSelect",
                          additionalActionInfo: "granularity",
                          data: {
                            granuarlity,
                          },
                        });
                        setTimeGranularity(granuarlity);
                      }}
                    />
                  </ControlBarElement>
                  <ControlBarElement
                    title="Date range"
                    parentClassName="mb-3 px-2.5 nb-control-bar-element"
                  >
                    <DateRangeSelect
                      isLoading={isLoading}
                      maxLookbackDays={maxLookbackDays}
                      dateRange={state.dateRange}
                      onUpdate={(dateRange) => {
                        prettyLogEvent({
                          page: "Orders",
                          action: "onSelect",
                          additionalActionInfo: "date-range",
                          data: {
                            dateRange,
                          },
                        });
                        setDateRange(dateRange);
                      }}
                      convertDateRangeToFixed={setDateRange}
                    />
                  </ControlBarElement>
                  <ControlBarElement
                    title="Compare to"
                    parentClassName="mb-2 px-2.5 nb-control-bar-element"
                  >
                    <CompareDateRangeSelect
                      isLoading={isLoading}
                      dateRange={state.dateRange}
                      compareDateRange={state.compareDateRange}
                      onUpdate={(dateRange) => {
                        prettyLogEvent({
                          page: "Orders",
                          action: "onSelect",
                          additionalActionInfo: "compare-date-range",
                          data: {
                            dateRange,
                          },
                        });
                        setCompareDateRange(dateRange);
                      }}
                      convertDateRangeToFixed={setCompareDateRange}
                      maxLookbackDays={maxLookbackDaysCompare}
                    />
                  </ControlBarElement>
                </div>
              )}
            />
          </div>
        </ControlBar>
      )}
    </StuckToTop>
  );
};
