import { GetCampaign_me_adObject } from "@nb-api-graphql-generated/GetCampaign";
import { CommonTouchpointsPage } from "@pages/objects/common-touchpoints";
import { ReportBodyState } from "@pages/sales/report-body-control";
import React from "react";

interface CommonTouchPointsInterface {
  state: ReportBodyState;
  adObject: GetCampaign_me_adObject;
}
export const CommonTouchPoints = ({
  state,
  adObject,
}: CommonTouchPointsInterface) => {
  if (!adObject.topLevel) {
    return null;
  }
  const dateRange = {
    startDate: adObject.topLevel.startDate,
    endDate: adObject.topLevel.endDate,
  };
  return (
    <CommonTouchpointsPage
      id={adObject.id}
      dateRange={dateRange}
      state={state}
    />
  );
};
