import { emptyText } from "@/constants/empty-text";
import { FetchOrder_me_order } from "@nb-api-graphql-generated/FetchOrder";
import { generateAnonBase64Hash } from "@utils/anonymous";
import { prefs } from "@utils/client-side-preferences";
import { CustomerTouchpoint } from "./JourneyCard";

const getPlatform = (platformString: string) => {
  try {
    const platformJson = JSON.parse(platformString);
    return platformJson.source;
  } catch {
    return platformString;
  }
};

const getEventDate = (eventTs: string | { value: string }) => {
  if (typeof eventTs === "string") return new Date(eventTs);
  return new Date(eventTs.value);
};

const getCampign = (campaignString: string) => {
  const doAnon = prefs.isDemoMode();
  const campaign = () => {
    try {
      const json = JSON.parse(campaignString);
      return json.campaign;
    } catch {
      return campaignString;
    }
  };

  return generateAnonBase64Hash({
    value: campaign(),
    prefix: "campaign",
    doAnon,
  });
};

const getAdset = (adSetString: string) => {
  const doAnon = prefs.isDemoMode();

  const adset = () => {
    try {
      const json = JSON.parse(adSetString);
      return json.adset;
    } catch {
      return adSetString;
    }
  };
  return generateAnonBase64Hash({ value: adset(), prefix: "adset", doAnon });
};

const getAd = (adString: string) => {
  const doAnon = prefs.isDemoMode();

  const ad = () => {
    try {
      const json = JSON.parse(adString);
      return json.ad;
    } catch {
      return adString;
    }
  };
  return generateAnonBase64Hash({ value: ad(), prefix: "ad", doAnon });
};

function generateTouchpoint(
  nonConversion: any,
  nameMappings: FetchOrder_me_order["priorNonConversionsKeyNameMapping"],
): CustomerTouchpoint | null {
  const date = getEventDate(nonConversion.event_ts);
  const touchpointClass = nonConversion.touchpoint_class;
  // we don't care if the touchpoint is an excluded touchpoint
  if (touchpointClass === "excluded") return null;

  // touchpoints are generally determined by `kind`
  const kind = nonConversion.kind;

  // if the `kind` is "direct", make a direct touchpoint
  if (kind === "direct") {
    return {
      type: "direct-visit",
      date,
    };
  }

  // if the `kind` is "referer", make a referer touchpoint
  if (kind === "referer") {
    return {
      type: "referral",
      date,
      source: nonConversion.campaign,
    };
  }

  if (kind === null) {
    const touchpointPlatform = nonConversion.platform as string;
    // if the platform starts with `partnership-` then it is an influencer touchpoint
    if (touchpointPlatform.startsWith("partnership-")) {
      const potentialPlatform = touchpointPlatform.split("partnership-")?.[1];
      return {
        type: "influencer",
        date,
        handle: nonConversion.campaign,

        platform: potentialPlatform === "lp" ? undefined : potentialPlatform,
      };
    }

    // otherwise it's just an normal ad platform

    return {
      type: "ad-platform",
      date,
      platform: touchpointPlatform,
      campaignName:
        getCampign(nameMappings.campaignMap[nonConversion.campaign]) ||
        emptyText,
      adsetName:
        getAdset(nameMappings.adsetMap[nonConversion.adset]) || emptyText,
      adName: getAd(nameMappings.adMap[nonConversion.ad]) || emptyText,
    };
  }

  // Klaviyo

  if (
    nonConversion.platform.toLowerCase().includes("klaviyo") ||
    nonConversion.campaign.toLowerCase().includes("klayivo")
  ) {
    return {
      type: "klaviyo",
      date,
    };
  }

  if (kind === "utm") {
    // utms are funky in that their platform is JSON
    const { medium, source } = JSON.parse(nonConversion.platform);

    // Klaviyo email (Make sure this matches the rule named "[OPTIONAL] Map all utm_medium=email to Klaviyo"
    // in `system-labels.platform-northbeam.json`)
    if (
      (medium.includes("email") || source.includes("email")) &&
      (RegExp("\\([a-zA-Z0-9]{4,8}\\)$").test(nonConversion.campaign) ||
        RegExp("\\([a-zA-Z0-9]{20,30}\\)$").test(nonConversion.campaign))
    ) {
      return {
        type: "klaviyo",
        date,
      };
    }

    // if they both are email, then make a normal email
    if (medium.includes("email") || source.includes("email")) {
      return {
        type: "email",
        date,
        campaignName: getCampign(
          nameMappings.campaignMap[nonConversion.campaign],
        ),
      };
    }

    const platform = getPlatform(nonConversion.platform).trim() || emptyText;

    return {
      type: "utm",
      date,
      platform,
      source,
      campaignName:
        getCampign(nameMappings.campaignMap[nonConversion.campaign]) ||
        emptyText,
      medium,
      content: getAd(nameMappings.adMap[nonConversion.ad]) || emptyText,
    };
  }

  if (kind === "query") {
    return {
      type: "ad-platform",
      date,
      platform: "Query",
      campaignName:
        getCampign(nameMappings.campaignMap[nonConversion.campaign]) ||
        emptyText,
      adsetName: emptyText,
      adName: emptyText,
    };
  }

  const platform = getPlatform(nonConversion.platform);

  return {
    type: "ad-platform",
    date,
    platform,
    campaignName:
      getCampign(nameMappings.campaignMap[nonConversion.campaign]) || emptyText,
    adsetName:
      getAdset(nameMappings.adsetMap[nonConversion.adset]) || emptyText,
    adName: getAd(nameMappings.adMap[nonConversion.ad]) || emptyText,
  };
}

export function generateTouchpoints(order: FetchOrder_me_order) {
  const doAnon = prefs.isDemoMode();
  const touchpoints: CustomerTouchpoint[] = [];
  const nameMappings = order.priorNonConversionsKeyNameMapping;

  order.priorNonConversions?.forEach((nonConversion) => {
    const touchpoint = generateTouchpoint(nonConversion, nameMappings);
    if (touchpoint) touchpoints.push(touchpoint);
  });
  touchpoints.push({
    type: "purchase",
    date: new Date(order.occurredAt.value),
    amountInDollars: order.revenueInDollars,
    items: order.products.map(
      (product) =>
        `${generateAnonBase64Hash({
          value: product.title,
          prefix: "product",
          doAnon,
        })} (x${product.quantity})`,
    ),
  });
  return touchpoints;
}
