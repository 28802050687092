import { ControlBar, ControlBarElement } from "@components/control-bar";
import { AlertChart, TimeSeriesWithColor } from "@components/reports/charts";
import { TitleSlide } from "@components/title-slide-view";
import { User, useUser } from "@components/user-context";
import { Preview, PreviewVariables } from "@nb-api-graphql-generated/Preview";
import {
  AlertPreviewRowV2,
  jsonHash,
  todayLocalAsISODate,
  TriggerConditionsV2,
} from "@north-beam/nb-common";
import { colorScheme1, spaceColorScheme } from "@pages/objects/label-colors";
import { useNorthbeamQuery } from "@utils/hooks";
import { ReactSelectOption } from "@utils/index";
import _ from "lodash";
import moment from "moment";
import React from "react";
import Select from "react-select";
import { PREVIEW } from "../queries";
import { AlertConfigState } from "./alert-config-editor";
import { makeSeriesBySubjectItem } from "./alert-preview-chart-viewer";
import { AlertRunTable } from "./alert-run-table";
import {
  flattenAlarmCondition,
  flattenResultRows,
  MetricFragment,
} from "./utils";

export interface AlertSimulatePaneProps {
  preview: AlertConfigState | undefined;
  id?: string;
}

export function AlertSimulatePane(props: AlertSimulatePaneProps) {
  const { preview, id } = props;
  const { user } = useUser();

  const [current, setCurrent] = React.useState<AlertConfigState | undefined>(
    preview,
  );

  const date = todayLocalAsISODate();

  const variables = React.useMemo(() => {
    if (!current) {
      return undefined;
    }
    const triggerConditions: TriggerConditionsV2 = {
      version: "v2",
      alarmCondition: current.alarmCondition,
      subject: current.subject,
    };
    return {
      triggerConditions,
      date,
      days: 7,
      configId: id,
    };
  }, [current, id, date]);

  const { loading, data } = useNorthbeamQuery<Preview, PreviewVariables>(
    PREVIEW,
    {
      variables,
      skip: !current,
    },
  );

  const response = data?.me?.previewCustomAlertConfig;

  const previews: AlertPreviewRowV2[] | undefined = React.useMemo(() => {
    if (response) {
      return response.previews;
    }
  }, [response]);

  const allChoices = React.useMemo(() => {
    if (!current || !previews) {
      return;
    }

    return parsePreviews(previews);
  }, [current, previews]);

  // HACK: alarmCondition is a tree-like structure, and so is previews.resultRows.
  // HACK: they, in fact, have the same tree-like structure.
  // HACK: we exploit this by flattening both trees and using numeric indices to cross
  // HACK: reference the correct SimpleAlarmConditionV2 to every SimpleAlarmConditionResultRow.
  const simpleAlarmConditions = React.useMemo(
    () => (current ? flattenAlarmCondition(current.alarmCondition) : undefined),
    [current],
  );
  const metrics = React.useMemo(() => {
    if (simpleAlarmConditions) {
      return simpleAlarmConditions.map((v, index) => ({
        ...v.metric,
        index,
      }));
    }
  }, [simpleAlarmConditions]);

  const [selectedMetricIndex, setSelectedMetricIndex] =
    React.useState<number>(0);

  const [selectedChoice, setSelectedChoice] = React.useState<
    ReactSelectOption<string> | undefined
  >(allChoices?.[0]);

  const actualSelectedChoice = React.useMemo(() => {
    return selectedChoice ?? allChoices?.[0];
  }, [selectedChoice, allChoices]);

  const subjectItemToColor = React.useMemo(() => {
    if (previews) {
      return makeSubjectItemHashToColor(previews, user);
    }
  }, [previews, user]);

  const byDate: AlertPreviewRowV2[][] | undefined = React.useMemo(() => {
    if (previews && actualSelectedChoice) {
      return _.chain(previews)
        .filter((v) => jsonHash(v.subjectItem) === actualSelectedChoice.value)
        .groupBy("runAt")
        .values()
        .sortBy((v) => v[0].runAt)
        .reverse()
        .value();
    }
  }, [actualSelectedChoice, previews]);

  const resultRows = React.useMemo(() => {
    if (previews && actualSelectedChoice) {
      return _.chain(previews)
        .filter((v) => jsonHash(v.subjectItem) === actualSelectedChoice.value)
        .map((p) => {
          const rows = flattenResultRows(p.resultRow);
          return rows[selectedMetricIndex] ?? rows[0];
        })
        .value();
    }
  }, [selectedMetricIndex, actualSelectedChoice, previews]);

  const allSerieses = React.useMemo(() => {
    if (
      current &&
      subjectItemToColor &&
      metrics &&
      resultRows &&
      simpleAlarmConditions
    ) {
      return makeSeriesBySubjectItem(
        subjectItemToColor,
        resultRows,
        metrics[selectedMetricIndex],
        simpleAlarmConditions[selectedMetricIndex],
      );
    }
  }, [
    subjectItemToColor,
    resultRows,
    metrics,
    selectedMetricIndex,
    current,
    simpleAlarmConditions,
  ]);

  const serieses = React.useMemo(() => {
    if (allSerieses && actualSelectedChoice) {
      return [allSerieses[actualSelectedChoice.value]].filter((v) => !!v);
    }
  }, [actualSelectedChoice, allSerieses]);

  return (
    <div className="px-4">
      <ControlBar>
        <ControlBarElement title="Metric">
          <Select
            isSearchable={true}
            isDisabled={!metrics || loading}
            options={metrics ?? []}
            value={
              metrics?.[selectedMetricIndex] ?? (metrics?.[0] as any) ?? null
            }
            getOptionLabel={(v) => v.name}
            getOptionValue={(v) => v.id}
            onChange={(v: any) => setSelectedMetricIndex(v.index)}
          />
        </ControlBarElement>
        <ControlBarElement title="Subject">
          <Select
            isSearchable={true}
            isDisabled={!allChoices || loading}
            options={allChoices ?? []}
            value={actualSelectedChoice ?? null}
            onChange={(v: any) => setSelectedChoice(v)}
          />
        </ControlBarElement>
        <ControlBarElement title="Update">
          <button
            className="btn btn-primary btn-small"
            disabled={jsonHash(current) === jsonHash(preview) && !loading}
            onClick={() => setCurrent(preview)}
          >
            Update simulation
          </button>
        </ControlBarElement>
      </ControlBar>
      <div className="row">
        <div className="col">
          <div className="mx-2 mt-5">
            {!current ? (
              <TitleSlide className="mt-5">
                <p>
                  When you&apos;ve filled out the alert parameters, we&apos;ll
                  show you here whether you would have received them in the
                  past.
                </p>
              </TitleSlide>
            ) : loading ? (
              <TitleSlide>Loading simulation...</TitleSlide>
            ) : allChoices!.length > 0 ? (
              <InnerInner
                serieses={serieses!}
                metric={metrics![selectedMetricIndex]}
                byDate={byDate!}
                state={current}
              />
            ) : (
              <TitleSlide className="mt-5">
                This alert is not monitoring any subjects. Please update the
                alert parameters!
              </TitleSlide>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function InnerInner({
  metric,
  serieses,
  byDate,
  state,
}: {
  metric: MetricFragment;
  serieses: TimeSeriesWithColor[];
  byDate: AlertPreviewRowV2[][];
  state: AlertConfigState;
}) {
  return (
    <>
      <div className="row my-3">
        <div className="col">
          <div className="flex items-center border p-3">
            <AlertChart
              serieses={serieses}
              format={metric.format}
              height={300}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {byDate.map((ps) => {
            const runAt = ps[0].runAt;
            return (
              <div className="row mt-5" key={runAt}>
                <div className="col">
                  <h5 className="font-weight-bold">
                    {moment(runAt).format("MMM D, YYYY")}
                  </h5>
                  <hr />
                  <AlertRunTable
                    runAt={runAt}
                    results={ps.map((context) => ({ context }))}
                    alarmCondition={state.alarmCondition}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

function makeSubjectItemHashToColor(previews: any[], user: User) {
  const subjectItemHashes = _.chain(previews)
    .map((v) => [jsonHash(v.subjectItem), 1])
    .fromPairs()
    .keys()
    .sort()
    .value();
  const colors = user.featureFlags.enableDesignRefresh
    ? spaceColorScheme(subjectItemHashes.length)
    : colorScheme1(subjectItemHashes.length);

  return _.fromPairs(_.zip(subjectItemHashes, colors));
}

function parsePreviews(
  previewses: AlertPreviewRowV2[],
): ReactSelectOption<string>[] {
  const serieses = _.chain(previewses)
    .groupBy((p) => jsonHash(p.subjectItem))
    .value();

  const keys = Object.keys(serieses).sort();
  const rv = keys.map((key) => {
    const name = serieses[key][0].subjectItemName;
    const numAlarms = _.filter(
      serieses[key],
      (v) => v.conditionTriggered,
    ).length;
    const numSkips = _.filter(serieses[key], (v) => !!v.skipReason).length;

    let label = name;
    if (numAlarms) {
      label = `⚠️ ${label}`;
    } else if (numSkips === serieses[key].length) {
      label = `🔵 ${label}`;
    } else {
      label = `⚪ ${label}`;
    }

    return {
      value: key,
      label,
      numAlarms,
      numSkips,
    };
  });

  return _.sortBy(rv, (v) => -(1000 * v.numAlarms + -v.numSkips));
}
