import React from "react";
import { TextInput } from "@shared/text-input";

interface TableSearchInputProps {
  searchValue: string;
  setSearchValue: (search: string) => void;
}

export const TableSearchInput = ({
  searchValue,
  setSearchValue,
}: TableSearchInputProps) => (
  <TextInput
    className="w-96 mb-4"
    placeholder="Filter by name"
    value={searchValue}
    leftIcon={<i className="fa-solid fa-magnifying-glass" />}
    onChange={({ target }) => setSearchValue(target.value)}
  />
);
