import { gql } from "@apollo/client";

export const GET_CUSTOM_ANNOTATIONS = gql`
  query GetClientCustomCustomAnnotations($startDate: String, $endDate: String) {
    me {
      clientCustomAnnotations(startDate: $startDate, endDate: $endDate) {
        annotatedDate
        description
        clientId
        id
        updatedAt
        user
        userId
      }
    }
  }
`;

export const CREATE_CUSTOM_ANNOTATION = gql`
  mutation CreateCustomAnnotation(
    $annotatedDate: String!
    $description: String!
  ) {
    attemptToAddCustomAnnotation(
      annotatedDate: $annotatedDate
      description: $description
    ) {
      id
      clientId
      userId
      annotatedDate
      description
      updatedAt
    }
  }
`;

export const UPDATE_CUSTOM_ANNOTATION = gql`
  mutation UpdateCustomAnnotation($id: String!, $description: String!) {
    updateCustomAnnotation(id: $id, description: $description) {
      id
      clientId
      userId
      annotatedDate
      description
      updatedAt
    }
  }
`;

export const DELETE_CUSTOM_ANNOTATION = gql`
  mutation DeleteCustomAnnotation($id: String!) {
    attemptToRemoveCustomAnnotation(id: $id)
  }
`;
