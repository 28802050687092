import React from "react";
import TextAd from "../TextAd";

export default function ResponsiveSearchAd(props: any) {
  const { headlines, descriptions } = props;
  const headline =
    headlines?.[0] ||
    props?.responsiveDisplayAd?.headlines?.[0] ||
    props?.responsiveSearchAd?.headlines?.[0];
  const description =
    descriptions?.[0] ||
    props?.responsiveDisplayAd?.descriptions?.[0] ||
    props?.responsiveSearchAd?.descriptions?.[0];

  return (
    <TextAd
      headline={
        headline?.text || headline?.asset?.assetText || "[Headline missing]"
      }
      description1={
        description?.text ||
        description?.asset?.assetText ||
        "[Description missing]"
      }
      displayUrl="[responsive display URL]"
    />
  );
}
