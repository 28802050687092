import React from "react";
import { InstallationScriptDetails } from "@north-beam/nb-common";
import { TrackingScriptCard } from "./tracking-script-card";

interface TrackingScriptTagsProps {
  installationScripts: InstallationScriptDetails;
  startExpanded?: boolean;
}
export const NorthbeamPixelTag = ({
  installationScripts,
  startExpanded,
}: TrackingScriptTagsProps): React.ReactElement => (
  <TrackingScriptCard
    displayName="Northbeam Pixel"
    header="Please place this pixel on every page of your website. This is Northbeam's primary pixel."
    scriptCode={installationScripts.northbeam_pixel}
    startExpanded={startExpanded}
    descriptionHTML={
      <>
        <p>
          Please install this script in the <code>head</code> section of every
          page in your website.
        </p>
        <p>
          This pixel needs to be installed on every page of your website. We
          recommend following the Google Tag Manager instructions if you use
          that service.
        </p>
        <ul>
          <li>
            Instructions for Shopify:{" "}
            <a
              href="https://docs.google.com/document/d/1IlK50HE0CAObCN39ocrbLbkG0gPWMu6V51gx7PnoU70/edit"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </li>
          <li>
            Instructions for Google Tag Manager:{" "}
            <a
              href="https://docs.google.com/document/d/1dVtHp-0vy_QnrbcBrYbCHa0kx76Xavr_xXFvt2tHxX8/edit"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </li>
        </ul>
      </>
    }
  />
);
