import React, { useState } from "react";

export function RegexTips() {
  const [isRegexTipsShowing, setRegexTipsShowing] = useState<boolean>(false);

  return (
    <>
      <p>
        Tips for using regex matchers:{" "}
        <button
          className="btn btn-link p-0"
          onClick={() => setRegexTipsShowing(!isRegexTipsShowing)}
        >
          click to {isRegexTipsShowing ? "hide" : "show"}
        </button>
      </p>
      {isRegexTipsShowing && (
        <ul>
          <li className="mb-3">
            <div>
              <b>Substring search</b>. If your regex only has numbers, letters,
              underscores, spaces, and dashes, then it will perform a substring
              search.
            </div>
            <div>
              Example: <kbd>pattern</kbd> will match{" "}
              <code>my&nbsp;pattern</code> and <code>my_patternx</code>, but not{" "}
              <code>my&nbsp;pat&nbsp;tern</code>.
            </div>
          </li>
          <li className="mb-3">
            <div>
              <b>Starts with</b>. Add <kbd>^</kbd> to the start of your regex to
              match from the start of the string.
            </div>
            <div>
              Example: <kbd>^pattern</kbd> will match{" "}
              <code>pattern&nbsp;x</code>, but not <code>my&nbsp;pattern</code>.
            </div>
          </li>
          <li className="mb-3">
            <div>
              <b>Ends with</b>. Add <kbd>$</kbd> to the end of your regex to
              match from the end of the string.
            </div>
            <div>
              Example: <kbd>pattern$</kbd> will match{" "}
              <code>my&nbsp;pattern</code>, but not <code>pattern&nbsp;x</code>.
            </div>
          </li>
          <li className="mb-3">
            <div>
              <b>Case insensitive match</b>. Add <kbd>(?i)</kbd> to the very
              start of your regex (even before <kbd>^</kbd>, if you are using
              it) to make the match case insensitive.
            </div>
            <div>
              Example: <kbd>(?i)pattern</kbd> will match <code>pattern</code>,{" "}
              <code>PATTERN</code>, and <code>PaTtErN</code>.
            </div>
          </li>
          <li className="mb-3">
            <div>
              <b>Special characters</b>. The following characters need to be
              prefixed with <kbd>\</kbd> in order to match the character itself:{" "}
              <kbd>.*+?^${}()|[]\</kbd>.
            </div>
            <div>
              Example: <kbd>^\$</kbd> will match <code>$10</code>, but not{" "}
              <code>10$</code>.
            </div>
          </li>
        </ul>
      )}
    </>
  );
}
