import { H1 } from "@components/utilities";
import { Calendar } from "@pages/settings/simple-calendar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  CreateCustomAnnotation,
  CreateCustomAnnotationVariables,
} from "@nb-api-graphql-generated/CreateCustomAnnotation";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import trashBin from "@assets/trash-bin.svg";
import {
  DeleteCustomAnnotation,
  DeleteCustomAnnotationVariables,
} from "@nb-api-graphql-generated/DeleteCustomAnnotation";
import {
  CREATE_CUSTOM_ANNOTATION,
  GET_CUSTOM_ANNOTATIONS,
  DELETE_CUSTOM_ANNOTATION,
} from "@gql/custom-annotations";
import { GetClientCustomCustomAnnotations } from "@nb-api-graphql-generated/GetClientCustomCustomAnnotations";
import { sortBy } from "lodash";
import { Annotation } from "@features/custom-annotations";

export function CustomAnnotations() {
  const [description, setDescription] = useState<string>("");
  const [date, setDate] = useState<moment.Moment | null>(null);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);

  const [addCustomAnnotation] = useNorthbeamMutation<
    CreateCustomAnnotation,
    CreateCustomAnnotationVariables
  >(CREATE_CUSTOM_ANNOTATION);

  const [removeCustomAnnotation] = useNorthbeamMutation<
    DeleteCustomAnnotation,
    DeleteCustomAnnotationVariables
  >(DELETE_CUSTOM_ANNOTATION);

  const { loading, data } = useNorthbeamQuery<GetClientCustomCustomAnnotations>(
    GET_CUSTOM_ANNOTATIONS,
    {
      variables: {
        startDate: null,
        endDate: null,
      },
      fetchPolicy: "cache-and-network",
    },
  );

  const onCreateCustomAnnotation = async () => {
    if (!date) {
      return;
    }
    const annotatedDate = date.format("YYYY-MM-DD");
    const result = await addCustomAnnotation({
      variables: {
        description,
        annotatedDate,
      },
    });

    const newAnnotation = result.data?.attemptToAddCustomAnnotation;
    if (newAnnotation) {
      const annotationsCopy = [...annotations];
      const annotationIndex = annotationsCopy.findIndex(
        ({ id }) => id === newAnnotation.id,
      );

      if (annotationIndex !== -1) {
        annotationsCopy[annotationIndex] = newAnnotation;
        setAnnotations(annotationsCopy);
      } else {
        annotationsCopy.push(newAnnotation);
      }
      setAnnotations(sortBy(annotationsCopy, "annotatedDate").reverse());
    }
  };

  const onDeleteCustomAnnotation = async (id: string) => {
    const an = annotations.filter((annotation) => {
      return annotation.id !== id;
    });
    setAnnotations(an);
    await removeCustomAnnotation({
      variables: {
        id,
      },
    });
  };

  useEffect(() => {
    const annotations = data?.me.clientCustomAnnotations || [];
    setAnnotations(annotations);
  }, [data?.me?.clientCustomAnnotations]);

  return (
    <>
      <H1>Custom Annotations</H1>
      <hr />
      <div className="row col-8">
        <div className="py-2 my-2">
          <div className="control-label">Description</div>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>
      </div>
      <div className="control-label">Date</div>
      <Calendar onSelect={setDate} date={date} />
      <div className="row col-8">
        <div className="py-2 my-2">
          <button
            disabled={!date || !description}
            tabIndex={70}
            className="btn btn-primary btn-block"
            onClick={onCreateCustomAnnotation}
          >
            Submit
          </button>
        </div>
      </div>
      <h3>Annotation Archive</h3>
      {loading ? (
        <p>Please wait...</p>
      ) : (
        <div>
          {annotations.map((annotation: Annotation) => (
            <div
              key={annotation.id}
              className="flex justify-content-between my-2"
              style={{
                backgroundColor: "#e9ecef",
              }}
            >
              <div className="flex">
                <div className="m-2" style={{ minWidth: "6em" }}>
                  {annotation.annotatedDate}
                </div>
                <div className="m-2">{annotation.description}</div>
              </div>
              <div
                className="m-2"
                onClick={() => {
                  onDeleteCustomAnnotation(annotation.id);
                }}
              >
                <img alt={"Trash Bin"} src={trashBin} />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
