import React from "react";
import { LogOnMount } from "@utils/analytics";

export const Currency = () => (
  <>
    <LogOnMount name="Currency page" />
    <p>
      Unfortunately, at this time we only display data in U.S. Dollars. Your
      interest in this feature has been logged, and we are working hard to
      enable conversions for all major currencies. We will reach out to all
      accounts when conversion capabilities have been enabled.
    </p>
  </>
);
