import { ControlBar, ControlBarElement } from "@components/control-bar";
import {
  DateRangeDisplay,
  DateRangePicker,
  formatDateRangeText,
} from "@components/date-range-picker";
import { GenericDropdown } from "@components/dropdown";
import { useUser } from "@components/user-context";
import { FixedDateRange, todayLocalAsISODate } from "@north-beam/nb-common";
import {
  AccountingDropdown,
  HierarchyDropdown,
} from "@shared/selects/dropdown-indicators";
import {
  CompareDateRangeEnum,
  DailyReactSelectChoices,
  DateRangeChoiceEnum,
  DateRangeChoiceV2,
  HourlyCompareReactSelectChoices,
  HourlyReactSelectChoices,
  ICompareDateRangeEnum,
  IMCAttributionWindowDays,
  INBAccountingMode,
  ITimeGranularity,
  MCAttributionWindowDays,
  MCAttributionWindowDaysChoicesDaily,
  MCAttributionWindowDaysChoicesHourly,
  NBAccountingMode,
  resolveCompareDateRange,
  TimeGranularity,
} from "@utils/constants";
import { parseDateRangeArgument } from "@utils/index";
import classNames from "classnames";
import React from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { ReportState } from "./report-state";

export interface ControlCenterProps {
  onUpdate: (state: ReportState) => void;
  isLoading: boolean;
  reportState: ReportState;
}

export function ControlCenter(props: ControlCenterProps) {
  const { user } = useUser();
  const { reportState, isLoading, onUpdate } = props;

  const AttributionMethod = React.useMemo(
    () => user.attributionMethodEnum,
    [user],
  );

  const {
    dateRange,
    compareDateRange,
    attributionModel,
    nbAccountingMode,
    timeGranularity,
    attributionWindow,
  } = reportState;

  const update = React.useCallback(
    (action: ReportAction) => onUpdate(ReportStateReducer(reportState, action)),
    [onUpdate, reportState],
  );

  const convertDateRangeToFixed = React.useCallback(
    (dr: FixedDateRange) => {
      update({
        type: "UpdateDateRange",
        value: dr,
      });
    },
    [update],
  );

  const convertCompareDateRangeToFixed = React.useCallback(
    (dr: FixedDateRange) => {
      update({
        type: "UpdateCompareDateRange",
        value: dr,
      });
    },
    [update],
  );

  let maxLookbackDays = 20 * 365;
  let maxLookbackDaysCompare = 20 * 365;
  let allowedDateNonCustomOptions = DailyReactSelectChoices;
  let allowedCompareDateNonCustomOptions =
    CompareDateRangeEnum.reactSelectOptions;
  let attributionWindowChoices = MCAttributionWindowDaysChoicesDaily;

  if (timeGranularity === "hourly") {
    maxLookbackDays = 8;
    maxLookbackDaysCompare = 15;
    allowedDateNonCustomOptions = HourlyReactSelectChoices;
    allowedCompareDateNonCustomOptions = HourlyCompareReactSelectChoices;
    attributionWindowChoices = MCAttributionWindowDaysChoicesHourly;
  }

  let attributionWindowTitle: string | React.ReactElement =
    "Attribution window";
  if (!attributionWindowChoices.find((v) => v.value === attributionWindow)) {
    attributionWindowTitle = (
      <>
        <span>Attribution window</span>
        &nbsp;-&nbsp;
        <span className="text-danger">
          Unsupported window; please pick another.
        </span>
      </>
    );
  }

  const dateRangeCurrent =
    typeof dateRange === "string"
      ? DateRangeChoiceEnum.toReactSelect(dateRange)
      : dateRange;

  const compareDateRangeCurrent =
    typeof compareDateRange === "string"
      ? CompareDateRangeEnum.toReactSelect(compareDateRange)
      : compareDateRange;

  const anchor = todayLocalAsISODate();
  const dr = parseDateRangeArgument(dateRange, anchor);
  const cdr = resolveCompareDateRange(dr, compareDateRange);

  return (
    <ControlBar>
      <div
        className="flex flex-row items-center"
        style={{ flex: "3 3 auto", maxWidth: 1280 }}
      >
        <ControlBarElement
          title={"Attribution model"}
          parentClassName="nb-control-bar-element"
          style={{ flex: "6 6 0%" }}
        >
          <Select
            isDisabled={isLoading}
            defaultValue={AttributionMethod.toReactSelect(attributionModel)}
            value={AttributionMethod.toReactSelect(attributionModel)}
            isClearable={false}
            isSearchable={false}
            onChange={(object) => {
              update({
                type: "UpdateAttributionMethod",
                value: (object as any)?.value,
              });
            }}
            components={{ DropdownIndicator: DBDropdown }}
            options={AttributionMethod.reactSelectOptions}
          />
        </ControlBarElement>
        <ControlBarElement
          title={attributionWindowTitle}
          parentClassName="pl-2 nb-control-bar-element"
          style={{ flex: "4 4 0%" }}
        >
          <Select
            isDisabled={isLoading}
            defaultValue={MCAttributionWindowDays.toReactSelect(
              attributionWindow,
            )}
            value={MCAttributionWindowDays.toReactSelect(attributionWindow)}
            isClearable={false}
            isSearchable={false}
            onChange={(object) => {
              update({
                type: "UpdateAttributionWindow",
                value: (object as any)?.value,
              });
            }}
            components={{ DropdownIndicator: HourglassDropdown }}
            options={attributionWindowChoices}
          />
        </ControlBarElement>
        <ControlBarElement
          title="Accounting mode"
          parentClassName="pl-2 nb-control-bar-element"
          style={{ flex: "4 4 0%" }}
        >
          <Select
            isDisabled={isLoading}
            defaultValue={NBAccountingMode.toReactSelect(nbAccountingMode)}
            value={NBAccountingMode.toReactSelect(nbAccountingMode)}
            isClearable={false}
            isSearchable={false}
            onChange={(object) => {
              update({
                type: "UpdateAccountingMode",
                value: (object as any)?.value,
              });
            }}
            components={{ DropdownIndicator: AccountingDropdown }}
            options={NBAccountingMode.reactSelectOptions}
          />
        </ControlBarElement>
      </div>
      <div
        className="flex flex-row items-center justify-content-end pl-5"
        style={{ flex: "0" }}
      >
        <GenericDropdown
          renderButton={({ toggle }) => (
            <button
              className="dropdown-btn btn btn-outline-secondary dropdown-toggle flex items-center"
              style={{ height: 61 }}
              disabled={isLoading}
              type="button"
              onClick={toggle}
            >
              <div className="flex flex-column align-items-start mr-2">
                <div style={{ marginBottom: -4, fontSize: 14 }}>
                  {formatDateRangeText(dateRangeCurrent)},{" "}
                  {TimeGranularity.getLabel(timeGranularity)}
                </div>
                <small className="text-muted mt-1 text-small">
                  Compared to {formatDateRangeText(compareDateRangeCurrent)}
                </small>
              </div>
            </button>
          )}
          renderList={({ isOpen, toggle }) => (
            <div
              className={classNames(
                "dropdown-menu dropdown-menu-right rounded",
                { show: isOpen },
              )}
              style={{
                position: "absolute",
                minWidth: "20rem",
                marginTop: "0.5rem",
              }}
            >
              <ControlBarElement
                title="Time granularity"
                parentClassName="mt-2 mb-3 px-3 nb-control-bar-element"
              >
                <Select
                  isDisabled={isLoading}
                  defaultValue={TimeGranularity.toReactSelect(timeGranularity)}
                  value={TimeGranularity.toReactSelect(timeGranularity)}
                  isClearable={false}
                  isSearchable={false}
                  onChange={(object) => {
                    update({
                      type: "UpdateTimeGranularity",
                      value: (object as any)?.value,
                    });
                  }}
                  components={{ DropdownIndicator: HierarchyDropdown }}
                  options={TimeGranularity.reactSelectOptions}
                />
              </ControlBarElement>
              <ControlBarElement
                title="Date range"
                parentClassName="mb-3 px-3 nb-control-bar-element"
              >
                <DateRangePicker<DateRangeChoiceV2>
                  disabled={isLoading}
                  nonCustomChoices={allowedDateNonCustomOptions}
                  maxLookbackDays={maxLookbackDays}
                  current={dateRangeCurrent}
                  onUpdate={(dateRange) =>
                    update({
                      type: "UpdateDateRange",
                      value:
                        "value" in dateRange
                          ? (dateRange.value as DateRangeChoiceV2)
                          : dateRange,
                    })
                  }
                  timezone={user.timezone}
                />
                <small className="text-muted mt-1 text-small d-block">
                  <DateRangeDisplay
                    dateRange={dateRange}
                    onConversionToFixed={convertDateRangeToFixed}
                  />
                </small>
              </ControlBarElement>
              <ControlBarElement
                title="Compare to"
                parentClassName="mb-2 px-3 nb-control-bar-element"
              >
                <DateRangePicker<ICompareDateRangeEnum>
                  disabled={isLoading}
                  nonCustomChoices={allowedCompareDateNonCustomOptions}
                  maxLookbackDays={maxLookbackDaysCompare}
                  current={compareDateRangeCurrent}
                  onUpdate={(compareDateRange) =>
                    update({
                      type: "UpdateCompareDateRange",
                      value:
                        "value" in compareDateRange
                          ? (compareDateRange.value as ICompareDateRangeEnum)
                          : compareDateRange,
                    })
                  }
                  timezone={user.timezone}
                />
                <small className="text-muted mt-1 text-small d-block">
                  <DateRangeDisplay
                    dateRange={{ type: "fixed", ...cdr }}
                    onConversionToFixed={convertCompareDateRangeToFixed}
                  />
                </small>
              </ControlBarElement>
            </div>
          )}
        />
      </div>
    </ControlBar>
  );
}

interface UpdateDateRange {
  type: "UpdateDateRange";
  value: DateRangeChoiceV2 | FixedDateRange;
}

interface UpdateCompareDateRange {
  type: "UpdateCompareDateRange";
  value: ICompareDateRangeEnum | FixedDateRange;
}

interface UpdateAttributionMethod {
  type: "UpdateAttributionMethod";
  value: any;
}

interface UpdateTimeGranularity {
  type: "UpdateTimeGranularity";
  value: ITimeGranularity;
}

interface UpdateAttributionWindow {
  type: "UpdateAttributionWindow";
  value: IMCAttributionWindowDays;
}

interface UpdateAccountingMode {
  type: "UpdateAccountingMode";
  value: INBAccountingMode;
}

export type ReportAction =
  | UpdateDateRange
  | UpdateCompareDateRange
  | UpdateTimeGranularity
  | UpdateAttributionMethod
  | UpdateAttributionWindow
  | UpdateAccountingMode;

export function ReportStateReducer(
  state: ReportState,
  action: ReportAction,
): ReportState {
  switch (action.type) {
    case "UpdateDateRange":
      return { ...state, dateRange: action.value };
    case "UpdateCompareDateRange":
      return { ...state, compareDateRange: action.value };
    case "UpdateTimeGranularity":
      return {
        ...state,
        timeGranularity: action.value,
        dateRange: action.value === "daily" ? "last28Days" : "last3Days",
        compareDateRange: "lastPeriod",
      };
    case "UpdateAttributionMethod":
      return { ...state, attributionModel: action.value };
    case "UpdateAttributionWindow":
      return { ...state, attributionWindow: action.value };
    case "UpdateAccountingMode":
      return { ...state, nbAccountingMode: action.value };
  }
}

function DBDropdown(props: DropdownIndicatorProps<any>) {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-database"></i>
    </components.DropdownIndicator>
  );
}

export function HourglassDropdown(props: DropdownIndicatorProps<any>) {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-hourglass"></i>
    </components.DropdownIndicator>
  );
}
