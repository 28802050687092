import React from "react";

import { OrderSummaryGraph } from "./line-graph";
import { OrderCard } from "./order-card";
import { OrdersMetrics } from "./metrics";

export function OrdersChart() {
  return (
    <OrderCard className="my-4 p-4">
      <div className="text-lg	font-semibold">Orders Summary</div>
      <OrdersMetrics />
      <OrderSummaryGraph />
    </OrderCard>
  );
}
