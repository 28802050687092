import { useEffect } from "react";
import { useDOMNode } from "./use-dom-node";

export const useLocationHashScroll = (
  id: string,
): ((ref: HTMLElement | null) => void) => {
  const [node, refCallback] = useDOMNode();

  useEffect(() => {
    if (!node) return;

    if (window.location.hash === `#${id}`) {
      node.scrollIntoView();
    }
  }, [id, node]);

  return refCallback;
};
