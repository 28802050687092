export enum StepName {
  INTRODUCTION = "introduction",
  INTRO_VIDEO = "intro-video",
  BASIC_DETAILS = "basic-details",
  PIXEL_PLACEMENT = "pixel-placement",
  DNS_SETUP = "dns-setup",
  GOOGLE_ANALYTICS = "google-analytics",
  INTEGRATIONS = "integrations",
  UTMS = "utms",
  BILLING = "billing",
  ACCOUNT_MANAGERS = "account-managers",
  COMPLETE = "complete",
}
