import { GetCampaign_me_adObject } from "@nb-api-graphql-generated/GetCampaign";
import React from "react";
import Select from "react-select";
import { SUPPORTED_PLATFORMS } from "./flyout";
import { BidBudgetSection } from "./shared/bid-budget";
import { RawJsonSection } from "./shared/raw-json";
import { TargetingSettingsSection } from "./shared/targeting-settings";

interface AdsetDetailProps {
    adObject: GetCampaign_me_adObject
    refetchData: (id: any) => void;
}

export const AdsetDetail = ({adObject, refetchData} : AdsetDetailProps) => {
    let currentAdsetIndex = 0;
    let adsetOptions = [];
    let campaignName = '';
    if (adObject.hierarchy) {
        campaignName = adObject.hierarchy.name;
        const adsets = adObject.hierarchy.adsets || [];
        adsetOptions = adsets.map((adset: any, index: number) => {
            if (adset.id === adObject.id) {
                currentAdsetIndex = index;
            }
            return {
                value: adset.id, 
                label: `Adset: ${adset.name}`
            }
        });
    } else if (adObject.topLevel?.rows?.length) {
        const row = adObject.topLevel.rows[0];
        if (row.extraDetails?.length) {
            row.extraDetails.forEach((detail) => {
                if (detail.name === 'Campaign') {
                    campaignName = detail.value;
                }
            });
        }
    }

    return (
        <>
            {campaignName && <div className="bg-[#E5E5E5] px-2 py-2">
                Campaign: {campaignName}
            </div>}
            {adsetOptions.length ? <div className="py-2">
                <Select 
                    options={adsetOptions}
                    value={adsetOptions[currentAdsetIndex]}
                    onChange={refetchData}
                />
            </div> : 
            <div className="bg-[#E5E5E5] my-2 px-2 py-2">
                Adset: {adObject.name}
            </div>
            }
            <BidBudgetSection statusLine={adObject?.statusLine}/>
            {SUPPORTED_PLATFORMS.includes(adObject.nbtPlatformID) && <TargetingSettingsSection adObject={adObject}/>}
            {!SUPPORTED_PLATFORMS.includes(adObject.nbtPlatformID) && <RawJsonSection metaData={adObject.metadata}/>}
        </>
    )
}