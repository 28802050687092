import React, { FC, useEffect, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { FetchAttributionQuestionIds } from "@nb-api-graphql-generated/FetchAttributionQuestionIds";
import {
  SetEnquireAttributionQuestionIds,
  SetEnquireAttributionQuestionIdsVariables,
} from "@nb-api-graphql-generated/SetEnquireAttributionQuestionIds";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import {
  FETCH_ATTRIBUTION_QUESTION_IDS,
  SET_ENQUIRE_ATTRIBUTION_QUESTION_IDS,
} from "../queries";
import appsList from "./images/appsList.png";
import previewEnquireLabsURL from "./images/previewEnquireLabsURL.png";
import shopifyMainPage from "./images/shopifyMainPage.png";

export const SetAttributionQuestionId: FC<Partial<StepWizardChildProps>> = ({
  previousStep,
  isActive,
}) => {
  const [attributionQuestionIds, setAttributionQuestionIds] =
    useState<string>("");
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);

  const [setEnquireAttributionQuestionIds, { loading }] = useNorthbeamMutation<
    SetEnquireAttributionQuestionIds,
    SetEnquireAttributionQuestionIdsVariables
  >(SET_ENQUIRE_ATTRIBUTION_QUESTION_IDS, {
    refetchQueries: [{ query: FETCH_ATTRIBUTION_QUESTION_IDS }],
  });

  const { data, loading: loadingAttributionQuestionIds } =
    useNorthbeamQuery<FetchAttributionQuestionIds>(
      FETCH_ATTRIBUTION_QUESTION_IDS,
      {},
    );

  useEffect(() => {
    setAttributionQuestionIds(
      data?.me?.attributionQuestionIds?.join(",") || "",
    );
  }, [data?.me?.attributionQuestionIds]);

  return (
    <div className={(!isActive && "d-none") || ""}>
      <button className="btn btn-secondary mb-4" onClick={previousStep}>
        Back to uploading historical data
      </button>
      <h3>
        <b>Setting attribution question ID</b>
      </h3>
      <ol>
        <li>
          Go to your Shopify store page and click on <b>Apps</b>.
        </li>
        <img
          className="img-fluid mb-4 mt-3"
          src={shopifyMainPage}
          alt="Shopify main page screencap"
        />
        <li>
          Choose <b>Enquire Post-Purchase Surveys</b> from the list. If you do
          not see Enquire, you need to{" "}
          <a href="https://enquirelabs.com/" target="_blank" rel="noreferrer">
            sign up
          </a>{" "}
          for their service.
        </li>
        <img
          className="img-fluid mb-4 mt-3"
          src={appsList}
          alt="Shopify apps list screencap"
        />
        <li>
          Click on the <b>Preview</b> button for your attribution question. The
          text of this question often looks like &ldquo;Where&apos;d you first
          hear about us?& or &ldquo;What brought you to our store?&rdquo; If you
          have multiple attribution questions, please repeat the following steps
          for each question.
        </li>
        <li className="mt-3">
          Extract the attribution question ID from the URL, as shown below, and
          copy it to your clipboard.
          <img
            className="img-fluid mb-4"
            src={previewEnquireLabsURL}
            alt="Preview Enquire Labs Question screencap"
          />
        </li>
        <li>
          Paste each attribution ID here, seperated by commas, and click{" "}
          <code>
            <b>Set attribution question ids</b>
          </code>
          <div className="mt-2">
            <input
              type="text"
              className="form-control"
              value={attributionQuestionIds}
              onChange={(event) =>
                setAttributionQuestionIds(event.target.value)
              }
              placeholder="E.g. 123,543,9456 or 4567"
            ></input>
          </div>
          <button
            disabled={
              !attributionQuestionIds ||
              loading ||
              loadingAttributionQuestionIds
            }
            className="btn btn-info mt-2"
            onClick={async () => {
              setUploadComplete(false);
              await setEnquireAttributionQuestionIds({
                variables: {
                  attributionQuestionIds: attributionQuestionIds.split(","),
                  withReplacement: true,
                },
              });

              setUploadComplete(true);
            }}
          >
            Set attribution question ids
          </button>
          {uploadComplete && (
            <p style={{ color: "green" }}>
              Looks good! Feel free to add another ID.
            </p>
          )}
        </li>
      </ol>
    </div>
  );
};
