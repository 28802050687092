import { DateRangeQueryNames } from "@/constants/date-range-params";
import { useDateRangeQueryState } from "@hooks/use-date-range-query-select";
import { FixedDateRange, todayLocalAsISODate } from "@north-beam/nb-common";
import {
  CompareDateRangeEnum,
  DateRangeChoiceEnum,
  ITimeGranularity,
  resolveCompareDateRange,
} from "@utils/constants";
import { parseDateRangeArgument } from "@utils/index";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useReportBodyState = () => {
  const location = useLocation();
  const [state, setState] = useDateRangeQueryState();

  useEffect(() => {
    if (location.search === "") {
      setState(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateRange = useMemo(
    () =>
      parseDateRangeArgument(
        state[DateRangeQueryNames.DATE_RANGE],
        todayLocalAsISODate(),
      ),
    [state],
  );

  return {
    state,

    timeGranularity: state[DateRangeQueryNames.TIME_GRANULARITY],
    setTimeGranularity: useCallback(
      ({ value }: { value: ITimeGranularity }) =>
        setState({ [DateRangeQueryNames.TIME_GRANULARITY]: value }),
      [setState],
    ),

    dateRange,
    setDateRange: useCallback(
      (
        dateRange:
          | FixedDateRange
          | (typeof DateRangeChoiceEnum.reactSelectOptions)[0],
      ) =>
        setState({
          [DateRangeQueryNames.DATE_RANGE]:
            "value" in dateRange ? dateRange.value : dateRange,
        }),
      [setState],
    ),

    compareDateRange: useMemo(
      () => resolveCompareDateRange(dateRange, state.compareDateRange),
      [dateRange, state.compareDateRange],
    ),
    setCompareDateRange: useCallback(
      (
        dateRange:
          | FixedDateRange
          | (typeof CompareDateRangeEnum.reactSelectOptions)[0],
      ) =>
        setState({
          [DateRangeQueryNames.COMPARE_DATE_RANGE]:
            "value" in dateRange ? dateRange.value : dateRange,
        }),
      [setState],
    ),
  };
};
