import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";

export function ImpactRadius({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        For each of the following parameter pairs, input them into{" "}
        <b>Partner Tracking Template</b> page.
      </p>
      <p>
        For more instructions, please consult the official{" "}
        <GenericExternalLink href="https://impact-helpdesk.freshdesk.com/support/solutions/articles/48001144239-gateway-tracking-settings?accountType=ADVERTISER#partner-tracking-template-0-3">
          documentation
        </GenericExternalLink>
        .
      </p>
      <table className="table table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {urlParamPairs.map(({ key, value }) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
