import { useUser } from "@components/user-context";
import React from "react";

type BasicUser = {
  id: string;
  email: string;
};

type AccountManagerTableProps = {
  managerUsers: BasicUser[];
  managersEmailsVerified: any;
  onRemoveManager: any;
  isAccountOwner: boolean;
  userEmail: string;
  addAccountManager: any;
};

type ManagerEmailVerificationStatus = {
  loading: boolean;
  verified: boolean;
  error: boolean;
};

const AccountManagerTable = ({
  managerUsers,
  managersEmailsVerified,
  onRemoveManager,
  isAccountOwner,
  userEmail,
  addAccountManager,
}: AccountManagerTableProps) => {
  const { user } = useUser();

  const renderManagerStatus = (
    email: string,
    emailStatus: ManagerEmailVerificationStatus,
  ) => {
    if (!emailStatus) {
      return null;
    }

    if (emailStatus.loading) {
      return (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    }

    if (emailStatus.error) {
      return <span className="text-danger">Error loading details</span>;
    }

    if (emailStatus.verified) {
      return <span className="text-muted">Active</span>;
    }

    if (emailStatus.verified === false) {
      return (
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => addAccountManager(email)}
        >
          Resend invite
        </button>
      );
    }

    return null;
  };

  const renderManagerRows = () => {
    return managerUsers.map(({ id, email }) => {
      const verificationData = managersEmailsVerified[email];
      return (
        <tr className="tr" key={id}>
          <td className="td">{email}</td>
          <td className="td">{renderManagerStatus(email, verificationData)}</td>
          <td className="td">
            {isAccountOwner &&
              email !== userEmail &&
              id !== user.displayUserId && (
                <div className="d-inline" style={{ color: "red" }}>
                  Delete{" "}
                  <i
                    className="fa-regular fa-times"
                    style={{ cursor: "pointer" }}
                    onClick={() => onRemoveManager(id)}
                  />
                </div>
              )}
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table">
      <tr className="tr">
        <th className="th">Current Managers</th>
        <th className="th">Status</th>
        <th className="th">Manage</th>
      </tr>
      {renderManagerRows()}
    </table>
  );
};

export default AccountManagerTable;
