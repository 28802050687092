import React from "react";

export default function ResponsiveDisplayAd(props: any) {
  const { headlines, descriptions } = props;
  const headline = headlines?.[0]?.text || "[Headline missing]";
  const description = descriptions?.[0]?.text || "[Description missing]";

  return (
    <div className="bg-white">
      <div className="bg-dark text-white flex flex-row justify-content-center items-center h-52">
        Responsive Display Ad
      </div>
      <div className="p-3">
        <h5>{headline}</h5>
        <small>{description}</small>
      </div>
    </div>
  );
}
