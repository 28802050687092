import React from "react";
import { Route, Routes } from "react-router-dom";
import { Page404 } from "@components/title-slide-view";
import { PathsPage } from "./paths";
import { Report } from "./report";
import CustomerDocumentsPage from "@pages/customer-documents";

export const Customers = () => (
  <Routes>
    <Route index element={<Report />} />
    <Route path="/documents" element={<CustomerDocumentsPage />} />
    <Route path="/paths" element={<PathsPage />} />
    <Route path="/*" element={<Page404 />} />
  </Routes>
);
