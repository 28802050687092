import React from "react";
import { UtmGuidePlatformProps } from "..";
import { CopyButton } from "./shared/copy-button";

export function Google({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will only need to set your UTM tracking parameters in the{" "}
        <b>Final URL Suffix</b> at the <b>Account level</b> for Google Ads.
        Please follow the instructions in the video below to do so:
      </p>
      <CopyButton urlParamPairs={urlParamPairs} hideQuestionMark />
      <iframe
        src="https://www.loom.com/embed/62739ccc2bfe4989a8e0a4b14ffca5b8"
        title="Google ad UTM tracking description"
        width="100%"
        height="500"
        frameBorder="0"
        className="mt-2"
      />
    </>
  );
}
