import { Option } from "@/types/react-select";
import { LoadingSpinner } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import {
  FETCH_ORDER_TAG_SUBSCRIPTION_ASSOCIATIONS,
  FETCH_PAGINATED_ORDER_TAGS,
  UPSERT_ORDER_TAG_SUBSCRIPTION_ASSOCIATIONS,
} from "@gql/subscription-order-tags";
import { FetchOrderTagSubscriptionAssociations } from "@nb-api-graphql-generated/FetchOrderTagSubscriptionAssociations";
import {
  FetchPaginatedOrderTags,
  FetchPaginatedOrderTagsVariables,
} from "@nb-api-graphql-generated/FetchPaginatedOrderTags";
import { SubscriptionTypeEnum } from "@nb-api-graphql-generated/global-types";
import {
  UpsertOrderTagSubscriptionAssociations,
  UpsertOrderTagSubscriptionAssociationsVariables,
} from "@nb-api-graphql-generated/UpsertOrderTagSubscriptionAssociations";
import { PrimaryButton } from "@shared/buttons";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import clsx from "clsx";
import { motion, useAnimation } from "framer-motion";
import { groupBy, omit, remove } from "lodash";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Select, {
  ControlProps,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  components,
} from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

type OrderTagOptions = MultiValue<
  Option<
    FetchPaginatedOrderTags["me"]["paginatedOrderTags"]["orderTags"][number]["tag"]
  >
>;
export function SubscriptionOrderTags() {
  const [expandedHelp, setExpandedHelp] = useState(false);

  return (
    <div className="w-full pr-10">
      <div className="text-3xl font-semibold">Subscription order tags</div>
      <div className="border-t-[1px] border-[#D9D9D9] w-full mt-3" />
      <div className="elevation-1 rounded-md border-[#DADAFB] border-[1px] mt-10">
        <div
          className={clsx(
            "flex flex-row items-center h-10 px-3 bg-[#F5F8FE] rounded-t-md",
            { "rounded-b-md": !expandedHelp },
          )}
        >
          <div>
            <i className="fa-solid fa-circle-question mr-1 text-primary" /> How
            it works
          </div>
          <i
            onClick={() => setExpandedHelp(!expandedHelp)}
            className={clsx("fas mr-2 text-primary ml-auto cursor-pointer", {
              "fa-chevron-down": expandedHelp,
              "fa-chevron-up": !expandedHelp,
            })}
          />
        </div>
        <Collapsable expanded={expandedHelp}>
          <div className="ml-2 mt-5">
            Please read more{" "}
            <a
              href="https://info.northbeam.io/knowledge/what-is-northbeam-subscription-analytics"
              target={"_blank"}
              rel="noreferrer"
            >
              here
            </a>{" "}
            to learn about Northbeam Subscription Analytics
          </div>

          <iframe
            src="https://www.youtube.com/embed/n40dAVAn6B0"
            title="Subscription order tags help video"
            width="100%"
            height="500"
            className="mt-5 mb-5"
          />
        </Collapsable>
      </div>
      <SubscriptionOrderTagsInput />
    </div>
  );
}

export const SubscriptionLabelToValueMapping = {
  "First Time": SubscriptionTypeEnum.FIRST_TIME,
  Recurring: SubscriptionTypeEnum.RETURNING,
  Other: SubscriptionTypeEnum.OTHER,
  "Non-subscription": SubscriptionTypeEnum.NONE,
} as const;
export const SubscriptionValueToLabelMapping = {
  [SubscriptionTypeEnum.FIRST_TIME]: "First Time",
  [SubscriptionTypeEnum.RETURNING]: "Recurring",
  [SubscriptionTypeEnum.OTHER]: "Other",
  [SubscriptionTypeEnum.NONE]: "Non-subscription",
} as const;
export const SUBSCRIPTION_TYPES: Option<SubscriptionTypeEnum>[] = [
  { label: "First Time", value: SubscriptionTypeEnum.FIRST_TIME },
  { label: "Recurring", value: SubscriptionTypeEnum.RETURNING },
  { label: "Other", value: SubscriptionTypeEnum.OTHER },
  { label: "Non-subscription", value: SubscriptionTypeEnum.NONE },
];

const USABLE_SUBSCRIPTION_TYPES = SUBSCRIPTION_TYPES.filter(
  (sub) => sub.value !== SubscriptionTypeEnum.NONE,
);
function SubscriptionOrderTagsInput() {
  const [subscriptionCategory, setSubsctiptionCategory] = useState<
    (typeof USABLE_SUBSCRIPTION_TYPES)[number]
  >(USABLE_SUBSCRIPTION_TYPES[0]);

  const [selectedOrderTags, setSelectedOrderTags] = useState<OrderTagOptions>(
    [],
  );
  const {
    data,
    loading: loadingFetch,
    refetch,
  } = useNorthbeamQuery<FetchOrderTagSubscriptionAssociations>(
    FETCH_ORDER_TAG_SUBSCRIPTION_ASSOCIATIONS,
    { variables: {}, notifyOnNetworkStatusChange: true },
  );
  const allOrderTagAssociations = useMemo(
    () =>
      (data?.me.orderTagSubscriptionAssociations ?? []).map((association) =>
        omit(association, "__typename"),
      ),
    [data?.me.orderTagSubscriptionAssociations],
  );

  const [upsertOrderTagSubscriotions, { loading: loadingUpsert }] =
    useNorthbeamMutation<
      UpsertOrderTagSubscriptionAssociations,
      UpsertOrderTagSubscriptionAssociationsVariables
    >(UPSERT_ORDER_TAG_SUBSCRIPTION_ASSOCIATIONS, {
      onCompleted() {
        refetch();
      },
    });

  const subscriptionToOrderTagMapping = useMemo(() => {
    return groupBy(
      data?.me.orderTagSubscriptionAssociations,
      "subscriptionType",
    );
  }, [data?.me.orderTagSubscriptionAssociations]);

  const onDelete = (
    orderTag: UpsertOrderTagSubscriptionAssociationsVariables["associations"][number]["orderTag"],
  ) => {
    const filteredAssociations = remove(
      allOrderTagAssociations,
      (association) => association.orderTag !== orderTag,
    );
    upsertOrderTagSubscriotions({
      variables: { associations: filteredAssociations },
    });
  };

  const onAdd = (
    newOrderTagAssocations: UpsertOrderTagSubscriptionAssociationsVariables["associations"],
  ) => {
    upsertOrderTagSubscriotions({
      variables: {
        associations: [...allOrderTagAssociations, ...newOrderTagAssocations],
      },
    });
    setSelectedOrderTags([]);
  };

  const loading = loadingFetch || loadingUpsert;
  const exclusionList = useMemo(
    () => allOrderTagAssociations.map(({ orderTag }) => orderTag),
    [allOrderTagAssociations],
  );

  return (
    <div className="mt-10">
      <div className="text-lg font-semibold">Search and select order tags</div>
      <div className="flex flex-row">
        <OrderTagsSelector
          exclusionList={exclusionList}
          onChange={setSelectedOrderTags}
          value={selectedOrderTags}
        />
        <Selector
          options={USABLE_SUBSCRIPTION_TYPES}
          onChange={(option) => setSubsctiptionCategory(option)}
          value={subscriptionCategory}
          className={"w-[15rem] self-end ml-5"}
          label="Subscription category"
          name={"subscription-category"}
        />
        <PrimaryButton
          className="w-[5.5rem] h-[2.4rem] ml-5 self-end"
          isDisabled={!selectedOrderTags?.length || !subscriptionCategory}
          onClick={() => {
            // early exit if someone we get into this error state
            if (!selectedOrderTags?.length || !subscriptionCategory) return;

            const associations: UpsertOrderTagSubscriptionAssociationsVariables["associations"] =
              selectedOrderTags.map((orderTagOption) => ({
                subscriptionType: subscriptionCategory.value,
                orderTag: orderTagOption.value,
              }));
            onAdd(associations);
          }}
        >
          <i className="fa-regular fa-plus" /> Add
        </PrimaryButton>
      </div>

      <div className="mt-10 w-full">
        <div className="text-lg font-semibold">Mapped order tags</div>
        <div className="text-subtitle mt-5">
          Map any subscription order tags to Northbeam&apos;s subscription
          categories. If you dont know if the tag relates to &apos;first
          time&apos; or &apos;recurring&apos;, put it in the &apos;other&apos;
          category.
        </div>
        <div className="text-subtitle">
          Any updates to your mappings will only be reflected after your next
          data refresh.
        </div>
        <div className="relative">
          {loading && <LoadingSpinner />}
          <table
            className={clsx("table-auto w-full mt-6", {
              "opacity-25": loading,
            })}
          >
            <thead>
              <tr
                className="h-[4rem] font-semibold	border-b-[1px] border-[#BFC1C9]"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #717680",
                }}
              >
                <th className="w-[15rem] font-semibold pl-2 border-r-[1px] border-[#EAE9E9]">
                  Subscription category
                </th>
                <th className="font-semibold pl-2">Order tag(s)</th>
              </tr>
            </thead>
            <tbody>
              {USABLE_SUBSCRIPTION_TYPES.map((subscriptionOption) => {
                const orderTagAssociations =
                  subscriptionToOrderTagMapping[subscriptionOption.value] ?? [];
                return (
                  <tr
                    key={subscriptionOption.value}
                    className={"min-h-[4rem] border-b-[1px]  border-[#EAE9E9]"}
                  >
                    <td className="pl-2 border-r-[1px] border-[#EAE9E9]">
                      {subscriptionOption.label}
                    </td>
                    <td className="pl-2 flex flex-row items-center min-h-[4rem] flex-wrap	">
                      {orderTagAssociations.map((orderTagAssociation) => {
                        return (
                          <OrderTagPill
                            key={orderTagAssociation.orderTag}
                            value={orderTagAssociation.orderTag}
                            onDelete={onDelete}
                          />
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function OrderTagPill({
  value,
  onDelete,
}: {
  value: string;
  onDelete: (
    orderTag: UpsertOrderTagSubscriptionAssociationsVariables["associations"][number]["orderTag"],
  ) => void;
}) {
  return (
    <div
      className={clsx("rounded-[3rem] text-[#666666] m-1 py-1 px-5 w-fit  ")}
      style={{
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #717680",
      }}
    >
      {value}
      <i
        className=" ml-5 fa-solid cursor-pointer text-lg fa-xmark"
        onClick={() => onDelete(value)}
      />
    </div>
  );
}

function Collapsable({
  children,
  expanded,
}: PropsWithChildren<{ expanded?: boolean }>) {
  const controls = useAnimation();

  const variants = {
    expanded: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 },
  };

  useEffect(() => {
    if (expanded) {
      controls.start("expanded");
    } else {
      controls.start("collapsed");
    }
  }, [controls, expanded]);

  return (
    <motion.div
      initial="collapsed"
      className="z-0 overflow-hidden"
      animate={controls}
      transition={{ duration: 0.3 }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
}

export const Selector = <T,>({
  isLoading,
  options,
  onChange,
  value,
  disabled = false,
  className,
  label,
  name,
}: {
  isLoading?: boolean;
  options: Option<T>[];
  onChange: (option: Option<T>) => void;
  value: Option<T> | undefined;
  disabled?: boolean;
  className?: string;
  label?: string;
  name: string;
}) => {
  return (
    <div className={className}>
      {label && (
        <label htmlFor={name} className="mb-1 text-sm  text-subtitle">
          {label}
        </label>
      )}
      <Select
        name={name}
        isDisabled={disabled}
        value={value}
        onChange={(option) => option && onChange(option)}
        isMulti={false}
        isLoading={isLoading}
        styles={{
          control: (styles) => ({
            ...styles,
            fontSize: "0.9rem",
            fontWeight: 400,
          }),
          option: (styles) => {
            return { ...styles, fontSize: "0.9rem", fontWeight: 400 };
          },
        }}
        options={options}
        className={"w-full"}
      />
    </div>
  );
};

const Control = <T,>({ children, ...props }: ControlProps<T, true>) => {
  return (
    <components.Control {...props}>
      <i className="fa-solid ml-2 fa-magnifying-glass text-primary font-[900] text-[14px]" />
      {children}
    </components.Control>
  );
};

export const OrderTagsSelector = ({
  onChange,
  value,
  exclusionList,
}: {
  onChange: (options: OrderTagOptions) => void;
  value: OrderTagOptions;
  exclusionList: string[];
}) => {
  const { user } = useUser();

  const loadOptions = useCallback(
    async (
      search: string,
      prevOptions: OptionsOrGroups<any, GroupBase<any>>,
    ) => {
      const limit = 1000;

      const result = await user.apolloClient.query<
        FetchPaginatedOrderTags,
        FetchPaginatedOrderTagsVariables
      >({
        query: FETCH_PAGINATED_ORDER_TAGS,
        variables: {
          offset: prevOptions.length,
          limit,
          search,
          exclusionList,
        },
      });

      const newTags = result.data.me.paginatedOrderTags.orderTags;

      return {
        options: newTags.map(({ tag, count }) => ({
          label: `${tag} - ${count} order${count !== 1 ? "s" : ""}`,
          value: tag,
        })),
        hasMore: !(newTags.length < limit),
      };
    },
    [user.apolloClient, exclusionList],
  );

  return (
    <div className="w-[20rem]">
      <label htmlFor={"order-tags"} className="mb-1 text-sm  text-subtitle">
        Search order tags
      </label>

      <AsyncPaginate
        debounceTimeout={300}
        name="order-tags"
        isDisabled={false}
        value={value}
        onChange={onChange}
        isMulti
        components={{ Control: Control }}
        placeholder={"Search Order Tags..."}
        styles={{
          control: (styles) => ({
            ...styles,
            fontSize: "0.9rem",
            fontWeight: 400,
          }),
          option: (styles) => {
            return { ...styles, fontSize: "0.9rem", fontWeight: 400 };
          },
        }}
        cacheUniqs={[exclusionList]}
        loadOptions={loadOptions}
        className="w-full"
      />
    </div>
  );
};
