import { gql } from "@apollo/client";
import adwordsLogo from "@assets/adwords-logo.svg";
import amazonLogo from "@assets/amazon-icon.png";
import fbLogo from "@assets/fb-logo.svg";
import klaviyoLogo from "@assets/klaviyo-icon.png";
import microsoftLogo from "@assets/microsoft-icon.png";
import pinterestLogoIcon from "@assets/pinterest-logo-icon.svg";
import snapchatLogo from "@assets/snapchat-logo.svg";
import tiktokLogo from "@assets/tiktok-logo.svg";
import { FetchSettingsDetails } from "@nb-api-graphql-generated/FetchSettingsDetails";
import { FetchUserIntegrations as FetchUserIntegrationsQuery } from "@nb-api-graphql-generated/FetchUserIntegrations";
import { AmazonConnectionCard } from "@pages/settings/connections/amazon";
import { FBConnectionCard } from "@pages/settings/connections/facebook";
import { AdwordsConnectionCard } from "@pages/settings/connections/google-ads";
import { KlaviyoConnectionCard } from "@pages/settings/connections/klaviyo";
import { LinkedInConnectionCard } from "@pages/settings/connections/linkedin";
import { MicrosoftConnectionCard } from "@pages/settings/connections/microsoft";
import { PinterestConnectionCard } from "@pages/settings/connections/pinterest";
import { SnapchatConnectionCard } from "@pages/settings/connections/snapchat";
import { TikTokConnectionCard } from "@pages/settings/connections/tiktok";
import { DataWrapper } from "@pages/settings/data-wrapper";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { FC, useEffect, useState } from "react";
import StepWizard, { StepWizardChildProps } from "react-step-wizard";
import { ConnectionCardWrapper } from "./connection-card-wrapper";
import { ConnectionsCollectionStep } from "./connections-collection-step";

export const ONE_CLICK_PLATFORM_INTEGRATIONS = {
  Facebook: fbLogo,
  AdWords: adwordsLogo,
  Amazon: amazonLogo,
  Klaviyo: klaviyoLogo,
  //  "LinkedIn": linkedinLogo ,
  Microsoft: microsoftLogo,
  Pinterest: pinterestLogoIcon,
  Snapchat: snapchatLogo,
  TikTok: tiktokLogo,
} as const;

export const Connections: FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
  goToNamedStep,
}) => {
  const { loading, data: integrationChoicesData } =
    useNorthbeamQuery<FetchUserIntegrationsQuery>(
      FETCH_CLIENT_INTEGRATION_CHOICES,
    );

  const [integrations, setIntegrations] = useState<string[]>([]);

  useEffect(() => {
    setIntegrations(integrationChoicesData?.me?.fetchUserIntegrations || []);
  }, [integrationChoicesData?.me?.fetchUserIntegrations]);

  const platformNeedToBeConnected = (platform: string) => {
    return integrations.indexOf(platform) !== -1;
  };

  const toggleIntegrations = (platform: string) => {
    let newPlatforms = integrations;
    if (platformNeedToBeConnected(platform)) {
      newPlatforms = integrations.filter(
        (existingPlatform) => existingPlatform !== platform,
      );
    } else {
      newPlatforms = [...integrations, platform];
    }
    setIntegrations(newPlatforms);
  };

  if (!nextStep || !goToNamedStep || !previousStep) {
    return <></>;
  }

  if (loading) {
    return <></>;
  }

  return (
    <DataWrapper>
      {(data: FetchSettingsDetails) => {
        const { integrationDetails } = data;

        return (
          <StepWizard
            isLazyMount
            transitions={{
              enterRight: "",
              enterLeft: "",
              exitRight: "",
              exitLeft: "",
            }}
          >
            <ConnectionsCollectionStep
              stepName="collection"
              integrations={integrations}
              toggleIntegrations={toggleIntegrations}
              outerPreviousStep={previousStep}
              outerNextStep={nextStep}
            />
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="Facebook"
            >
              <FBConnectionCard integrationDetails={integrationDetails} />
            </ConnectionCardWrapper>
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="AdWords"
            >
              <AdwordsConnectionCard integrationDetails={integrationDetails} />
            </ConnectionCardWrapper>
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="Microsoft"
            >
              <MicrosoftConnectionCard
                integrationDetails={integrationDetails}
              />
            </ConnectionCardWrapper>
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="Snapchat"
            >
              <SnapchatConnectionCard integrationDetails={integrationDetails} />
            </ConnectionCardWrapper>
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="LinkedIn"
            >
              <LinkedInConnectionCard integrationDetails={integrationDetails} />
            </ConnectionCardWrapper>
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="Pinterest"
            >
              <PinterestConnectionCard
                integrationDetails={integrationDetails}
              />
            </ConnectionCardWrapper>
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="TikTok"
            >
              <TikTokConnectionCard integrationDetails={integrationDetails} />
            </ConnectionCardWrapper>
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="Amazon"
            >
              <AmazonConnectionCard integrationDetails={integrationDetails} />
            </ConnectionCardWrapper>
            <ConnectionCardWrapper
              integrations={integrations}
              goToNextStep={nextStep}
              goToMajorStep={goToNamedStep}
              stepName="Klaviyo"
            >
              <KlaviyoConnectionCard integrationDetails={integrationDetails} />
            </ConnectionCardWrapper>
          </StepWizard>
        );
      }}
    </DataWrapper>
  );
};

const FETCH_CLIENT_INTEGRATION_CHOICES = gql`
  query FetchUserIntegrations {
    me {
      fetchUserIntegrations
    }
  }
`;
