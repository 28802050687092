import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { GenericTooltip } from "@components/tooltip-container";
import { H1 } from "@components/utilities";
import { todayLocalAsISODate } from "@north-beam/nb-common";
import { LogOnMount } from "@utils/analytics";

interface RadioOptionsProps {
  name: string;
  stateChangeFunc: (arg0: string) => void;
  id: string;
  copy: string;
}

const RadioOption = ({
  name,
  stateChangeFunc,
  id,
  copy,
}: RadioOptionsProps) => (
  <label className="mr-4">
    <input
      type="radio"
      name={name}
      onChange={(event) => stateChangeFunc(event.target.id)}
      id={id}
    />{" "}
    {copy}
  </label>
);

interface NameSubsectionProps {
  name: string;
  tooltip: string;
  value: string | null;
  stateChangeFunc: (arg0: string | null) => void;
  excludeInputGroup?: boolean;
}

const NameSubsection = ({
  name,
  tooltip,
  stateChangeFunc,
  value,
}: NameSubsectionProps) => (
  <div className="col py-2 my-2">
    <div className="control-label mb-2 custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        checked={value !== null}
        onChange={(event) =>
          event.target.checked ? stateChangeFunc("") : stateChangeFunc(null)
        }
        id={`labelCheckbox-${name}`}
      />
      <label className="custom-control-label" htmlFor={`labelCheckbox-${name}`}>
        <GenericTooltip content={tooltip}>
          <b>{name}</b>
        </GenericTooltip>
      </label>
    </div>
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        value={value || ""}
        placeholder={name}
        onChange={(event) => stateChangeFunc(event.target.value)}
        disabled={value === null}
      />
    </div>
  </div>
);

export const NamingManual = () => {
  const { addToast } = useToasts();

  const [sourcePage, setSourcePage] = useState<string | null>("");
  const [landingPage, setLandingPage] = useState<string | null>(null);
  const [product, setProduct] = useState<string | null>(null);
  const [offer, setOffer] = useState<string | null>(null);
  const [videoLength, setVideoLength] = useState<string | null>(null);
  const [adType, setAdType] = useState<string | null>(null);
  const [creativeType, setCreativeType] = useState<string | null>(null);
  const [valueProp, setValueProp] = useState<string | null>(null);
  const [copyLength, setCopyLength] = useState<string | null>(null);
  const [copy, setCopy] = useState<string | null>(null);
  const [cta, setCta] = useState<string | null>(null);
  const [headline, setHeadline] = useState<string | null>(null);
  const [postId, setPostId] = useState<string | null>(null);
  const [date, setDate] = useState<string>(todayLocalAsISODate());

  const [constructedString, setConstructedString] = useState<string>("");

  const formatInput = (
    input: string | null,
    seperator: string,
    fallback: string,
  ): string => {
    if (input === null) {
      return "";
    }

    if (input === "") {
      return fallback + seperator;
    }

    return input.toUpperCase().split(" ").join("-") + seperator;
  };

  useEffect(() => {
    setConstructedString(
      formatInput(sourcePage, "_", "[PAGE]") +
        formatInput(landingPage, "_", "[LANDING-PAGE]") +
        formatInput(product, "_", "[PRODUCT]") +
        formatInput(offer, "_", "[OFFER]") +
        formatInput(videoLength, "-", "[VIDEO-LENGTH]") +
        formatInput(adType, "_", "[AD-TYPE]") +
        formatInput(creativeType, "_", "[CREATIVE-TYPE]") +
        formatInput(valueProp, "_", "[VALUE-PROP]") +
        formatInput(copyLength, "-", "[COPY-LENGTH]") +
        formatInput(copy, "_", "[COPY]") +
        formatInput(headline, "_", "[HEADLINE]") +
        formatInput(cta, "_", "[CTA]") +
        formatInput(postId, "_", "[POSTID]") +
        date,
    );
  }, [
    sourcePage,
    landingPage,
    product,
    offer,
    videoLength,
    adType,
    creativeType,
    valueProp,
    copyLength,
    copy,
    headline,
    cta,
    postId,
    date,
  ]);

  const copyText = React.useCallback(() => {
    window.navigator.clipboard.writeText(constructedString);
    addToast("Copied to clipboard!", { appearance: "success" });
  }, [constructedString, addToast]);

  return (
    <div className="container pt-4">
      <LogOnMount name="Visit Naming Manual Page" />
      <H1>Naming conventions manual</H1>
      <p>
        Use this guide to create naming schemes for Ads.
        {/*
          + ------------------------------------------------------------------------------------- +
          | 2022-05-29 DAN NOTE: REMOVING FOR NOW. DAVE SAYS HE'LL HAVE A BETTER GUIDE NEXT WEEK. |
          + ------------------------------------------------------------------------------------- +

          For a glossary of
          terms, examples, and instructions for Dynamic Creative Tests, check out
          our{" "}
          <GenericExternalLink href="https://docs.google.com/document/d/14lajvRbe0NIDcEQVc5OFseprQW-Bh3z2dqsLN02uTbM/edit?usp=sharing">
            instruction manual.
          </GenericExternalLink>
        */}
      </p>
      <hr />
      <div className="input-group">
        <textarea
          rows={2}
          className="form-control mb-2"
          style={{ wordBreak: "break-all" }}
          value={constructedString}
          onChange={(event) => setConstructedString(event.target.value)}
        />
      </div>
      <div className="row col">
        <button
          className="btn btn-primary mb-3 mt-2"
          type="button"
          onClick={copyText}
          disabled={
            sourcePage === "" ||
            landingPage === "" ||
            product === "" ||
            offer === "" ||
            videoLength === "" ||
            adType === "" ||
            creativeType === "" ||
            valueProp === "" ||
            copyLength === "" ||
            copy === "" ||
            headline === "" ||
            cta === "" ||
            postId === "" ||
            date === ""
          }
        >
          Copy text
        </button>
      </div>
      <div className="row">
        <NameSubsection
          name="Source page"
          tooltip="Page from which the ad is running."
          value={sourcePage}
          stateChangeFunc={setSourcePage}
        />
        <NameSubsection
          name="Landing page"
          tooltip="Landing page on your website that the ad directs to. For example HP for homepage."
          value={landingPage}
          stateChangeFunc={setLandingPage}
        />
        <NameSubsection
          name="Product"
          tooltip="What are you selling with this ad?"
          value={product}
          stateChangeFunc={setProduct}
        />
        <NameSubsection
          name="Offer"
          tooltip="What kind of promotion is this? For example, BFCM for Black-Friday, Cyber-Monday."
          value={offer}
          stateChangeFunc={setOffer}
        />
      </div>
      <div className="row">
        <NameSubsection
          name="Ad type"
          tooltip="Ad format (Carousel, Single Image, Video, etc.)"
          value={adType}
          stateChangeFunc={setAdType}
        />
        <NameSubsection
          name="Creative type"
          tooltip="User generated content (UGC), Meme, Lifestyle, etc."
          value={creativeType}
          stateChangeFunc={setCreativeType}
        />
        <NameSubsection
          name="Value prop"
          tooltip="A granular description of the angle for the ad. For example, 'Gifting' for ads that are geared towards shoppers who are buying this item as a gift. Try to keep this to two words or less."
          value={valueProp}
          stateChangeFunc={setValueProp}
        />
        <NameSubsection
          name="Copy"
          tooltip="High-level description of the ad copy (Brand, Product, Gifting, etc.)"
          value={copy}
          stateChangeFunc={setCopy}
        />
      </div>
      <div className="row">
        <NameSubsection
          name="Headline"
          tooltip="The angle that the headline is attempting to make."
          value={headline}
          stateChangeFunc={setHeadline}
        />
        <NameSubsection
          name="CTA"
          tooltip="The call to action (CTA) for the ad."
          value={cta}
          stateChangeFunc={setCta}
        />
        <NameSubsection
          name="Post ID"
          tooltip="The ID of the post that you are putting the ad in."
          value={postId}
          stateChangeFunc={setPostId}
        />
        <div className="col py-2 my-2">
          <div className="control-label mb-2">
            <b>Date</b>
          </div>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={date}
              placeholder="Date"
              onChange={(event) => setDate(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col py-2 my-2">
          <div className="control-label mb-2 custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={copyLength !== null}
              onChange={(event) =>
                event.target.checked ? setCopyLength("") : setCopyLength(null)
              }
              id={`labelCheckbox-Copy Length`}
            />
            <label
              className="custom-control-label"
              htmlFor={`labelCheckbox-Copy Length`}
            >
              <GenericTooltip content='This is subjective, but a good heurstic might be: Small for ads that are less than the "See more" length, Medium for ads that are exactly the "See more" length, and Large for ads that are longer than the "See more" length.'>
                <b>Copy Length</b>
              </GenericTooltip>
            </label>
          </div>
          <div className="btn-group btn-group-toggle" role="group">
            <RadioOption
              name="copy-length"
              stateChangeFunc={setCopyLength}
              id="S"
              copy="Small"
            />
            <RadioOption
              name="copy-length"
              stateChangeFunc={setCopyLength}
              id="M"
              copy="Medium"
            />
            <RadioOption
              name="copy-length"
              stateChangeFunc={setCopyLength}
              id="L"
              copy="Large"
            />
          </div>
        </div>
        <div className="col py-2 my-2">
          <div className="control-label mb-2 custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={videoLength !== null}
              onChange={(event) =>
                event.target.checked ? setVideoLength("") : setVideoLength(null)
              }
              id={`labelCheckbox-Video Length`}
            />
            <label
              className="custom-control-label"
              htmlFor={`labelCheckbox-Video Length`}
            >
              <GenericTooltip content="Short (<9 seconds), Medium (15 - 30 seconds), Long (30+ seconds)">
                <b>Video Length</b>
              </GenericTooltip>
            </label>
          </div>
          <div className="btn-group btn-group-toggle" role="group">
            <div className="btn-group btn-group-toggle" role="group">
              <RadioOption
                name="video-length"
                stateChangeFunc={setVideoLength}
                id="S"
                copy="Short"
              />
              <RadioOption
                name="video-length"
                stateChangeFunc={setVideoLength}
                id="M"
                copy="Medium"
              />
              <RadioOption
                name="video-length"
                stateChangeFunc={setVideoLength}
                id="L"
                copy="Long"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
