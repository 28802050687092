import { ControlBar, ControlBarElement } from "@components/control-bar";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { formatDateRangeText } from "@components/date-range-picker";
import { GenericDropdown } from "@components/dropdown";
import { ExpandableBreakdownSelector } from "@components/reports/breakdown-selector";
import { GenericTooltip } from "@components/tooltip-container";
import { useUser } from "@components/user-context";
import { AttributionModelSelect, DateRangeSelect } from "@shared/selects";
import Select from "react-select";
import { HierarchyDropdown } from "@shared/selects/dropdown-indicators";
import { DateRangeChoiceEnum } from "@utils/constants";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Granularity, useReportBodyState } from "./use-report-body-control";

export interface ReportBodyControlProps {
  isLoading: boolean;
}

export function ReportBodyControl({ isLoading }: ReportBodyControlProps) {
  const { user } = useUser();
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const {
    attributionModel1,
    setAttributionModel1,

    attributionModel2,
    setAttributionModel2,

    granularity,
    setGranularity,

    breakdowns,
    setBreakdowns,

    state,
    setDateRange,
  } = useReportBodyState();

  const dateRangeCurrent =
    typeof state.dateRange === "string"
      ? DateRangeChoiceEnum.toReactSelect(state.dateRange)
      : state.dateRange;

  return (
    <ControlBar>
      <div className="flex flex-row items-center" style={{ minWidth: "60rem" }}>
        <ControlBarElement title="Attribution model 1">
          <AttributionModelSelect
            isLoading={isLoading}
            attributionModel={attributionModel1}
            onChange={setAttributionModel1}
          />
        </ControlBarElement>
        <ControlBarElement title="Attribution model 2">
          <AttributionModelSelect
            isLoading={isLoading}
            attributionModel={attributionModel2}
            onChange={setAttributionModel2}
          />
        </ControlBarElement>
        <ControlBarElement title="Granularity">
          <Select
            isDisabled={isLoading}
            isClearable={false}
            isSearchable={false}
            value={Granularity.toReactSelect(granularity)}
            options={Granularity.reactSelectOptions}
            components={{ DropdownIndicator: HierarchyDropdown }}
            onChange={setGranularity as any}
          />
        </ControlBarElement>
        <ControlBarElement
          title={
            <GenericTooltip
              mouseLeaveDelay={0.1}
              content={
                <>
                  <p>
                    Breakdowns allow you to organize your marketing and sales
                    efforts in a way that makes sense for your business.
                  </p>
                  <p>Edit a Breakdown by clicking on it.</p>
                  <p>
                    To bulk edit labels for all of your Breakdowns, you can
                    either go to the{" "}
                    <Link to="../objects">Manual Breakdown Label Editor</Link>{" "}
                    page, or the{" "}
                    <Link to="../breakdown-editor">Breakdown Manager</Link>
                    {user.isAdmin && (
                      <>
                        <Link to="../rules">Automatic Label Rules</Link> page
                      </>
                    )}{" "}
                    to set automated labeling rules.
                  </p>
                </>
              }
            >
              Breakdown by
            </GenericTooltip>
          }
        >
          <ExpandableBreakdownSelector
            disabled={isLoading}
            current={breakdowns}
            available={breakdownConfigs}
            allConfigs={breakdownConfigs}
            onUpdate={setBreakdowns}
          />
        </ControlBarElement>
      </div>
      <div className="flex flex-row items-center">
        <GenericDropdown
          renderButton={({ toggle }) => (
            <button
              className="dropdown-btn btn btn-outline-secondary dropdown-toggle"
              disabled={isLoading}
              type="button"
              onClick={toggle}
            >
              {formatDateRangeText(dateRangeCurrent)}
            </button>
          )}
          renderList={({ isOpen }) => (
            <div
              className={classNames(
                "dropdown-menu dropdown-menu-right rounded",
                { show: isOpen },
              )}
              style={{
                position: "absolute",
                minWidth: "20rem",
                marginTop: "0.5rem",
              }}
            >
              <ControlBarElement
                title="Date range"
                parentClassName="mb-2 px-3 nb-control-bar-element d-block"
              >
                <DateRangeSelect
                  isLoading={isLoading}
                  maxLookbackDays={20 * 365}
                  convertDateRangeToFixed={setDateRange}
                  dateRange={state.dateRange}
                  onUpdate={setDateRange}
                />
              </ControlBarElement>
            </div>
          )}
        />
      </div>
    </ControlBar>
  );
}
