"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.castEnums = exports.castEnum = exports.InvalidEnumTypeError = void 0;
var InvalidEnumTypeError = /** @class */ (function (_super) {
    __extends(InvalidEnumTypeError, _super);
    function InvalidEnumTypeError(value, enumType) {
        var _this = _super.call(this, "".concat(Object.values(enumType), " does not include ").concat(value)) || this;
        Object.setPrototypeOf(_this, InvalidEnumTypeError.prototype);
        return _this;
    }
    return InvalidEnumTypeError;
}(TypeError));
exports.InvalidEnumTypeError = InvalidEnumTypeError;
function castEnum(value, enumerator) {
    if (Object.values(enumerator).includes(value)) {
        return value;
    }
    throw new InvalidEnumTypeError(value, enumerator);
}
exports.castEnum = castEnum;
function castEnums(values, enumerator) {
    return values.map(function (value) { return castEnum(value, enumerator); });
}
exports.castEnums = castEnums;
