"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterMetric = exports.GenericMetric = void 0;
var GenericMetric = /** @class */ (function () {
    function GenericMetric(datasetToColumnName, alias, datasets) {
        var supportedDataset = datasets.find(function (dataset) {
            return Object.keys(datasetToColumnName).includes(dataset.alias);
        });
        if (!supportedDataset) {
            // In this scenario, we should not be using this metric
            // If we try to access it, display this error message
            var preppedErrorMessage = "This metric - ".concat(alias, " - is not supported by any of these datasets: ").concat(datasets
                .map(function (dataset) { return dataset.alias; })
                .join(", "));
            this.columnName = preppedErrorMessage;
            this.alias = preppedErrorMessage;
            this.dataset = datasets[0];
            return;
        }
        this.columnName = datasetToColumnName[supportedDataset.alias];
        this.alias = alias;
        this.dataset = supportedDataset;
    }
    GenericMetric.prototype.constructAggregatorClause = function () {
        return "sum(".concat(this.dataset.alias, ".").concat(this.columnName, ")");
    };
    GenericMetric.prototype.constructSelectClause = function () {
        return "".concat(this.dataset.alias, ".").concat(this.columnName);
    };
    GenericMetric.prototype.constructOrderingOrGroupingClause = function () {
        return "".concat(this.dataset.alias, ".").concat(this.columnName);
    };
    return GenericMetric;
}());
exports.GenericMetric = GenericMetric;
var FilterMetric = /** @class */ (function (_super) {
    __extends(FilterMetric, _super);
    function FilterMetric() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return FilterMetric;
}(GenericMetric));
exports.FilterMetric = FilterMetric;
