import { gql } from "@apollo/client";
import { ProviderWrapperProps } from "@components/utilities";
import { GetSalesBreakdownConfigs } from "@nb-api-graphql-generated/GetSalesBreakdownConfigs";
import { CategoricalBreakdownConfig } from "@north-beam/nb-common";
import { useNorthbeamQuery } from "@utils/hooks";
import React from "react";

export const defaultSalesBreakdownConfigs: CategoricalBreakdownConfig[] = [
  {
    key: "Platform (Northbeam)",
    name: "Platform (Northbeam)",
    choices: [],
    type: "categorical",
    default: true,
  },
];

export interface SalesBreakdownConfigsContextType {
  value: CategoricalBreakdownConfig[];
  refresh: () => Promise<void>;
}

const SalesBreakdownConfigsContext =
  React.createContext<SalesBreakdownConfigsContextType>({
    value: defaultSalesBreakdownConfigs,
    refresh: () => Promise.resolve(),
  });

export const useSalesBreakdownConfigs = () =>
  React.useContext(SalesBreakdownConfigsContext);

export function SalesBreakdownConfigsProvider(props: ProviderWrapperProps) {
  const { data, refetch } = useNorthbeamQuery<GetSalesBreakdownConfigs>(
    SALES_BREAKDOWN_QUERY,
    {
      pollInterval: 600_000,
      doNotRaiseErrorOnFailure: true,
    },
  );

  const value = data?.me?.salesBreakdownConfigs;

  const breakdownConfigs: CategoricalBreakdownConfig[] = React.useMemo(() => {
    if (!value) {
      return defaultSalesBreakdownConfigs;
    }
    return value.map((v) => ({ type: "categorical", ...v }));
  }, [value]);

  const refresh = React.useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <SalesBreakdownConfigsContext.Provider
      {...props}
      value={{ value: breakdownConfigs, refresh }}
    />
  );
}

export const SALES_BREAKDOWN_QUERY = gql`
  query GetSalesBreakdownConfigs {
    me {
      id
      salesBreakdownConfigs {
        key
        name
        choices {
          value
          label
        }
      }
    }
  }
`;
