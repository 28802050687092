import classNames from "classnames";
import React, { ReactNode } from "react";

interface ModalPanelProps {
  title: string;
  children: ReactNode;
  className?: string;
}

export const ModalPanel = ({
  title,
  children,
  className,
  ...props
}: ModalPanelProps) => (
  <div {...props}>
    <span className="text-[#717680] text-xs font-bold leading-8">{title}</span>
    <div
      className={classNames(
        "overflow-y-auto h-full max-h-[57vh] border border-[#C4C4C4] rounded-md",
        className,
      )}
    >
      {children}
    </div>
  </div>
);
