import classNames from "classnames";
import React from "react";
import { ReactSelectOption } from "@utils/index";

export interface BootstrapDropdownButtonProps {
  buttonText: React.ReactNode;
  buttonClassName: string;
  disabled?: boolean;
  choices: ReactSelectOption<string>[];
  onSelected: (value: string) => void;
}

export function BootstrapDropdownButton({
  buttonText,
  buttonClassName,
  disabled,
  choices,
  onSelected,
}: BootstrapDropdownButtonProps) {
  const [open, setOpen] = React.useState(false);

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const handleClick: EventListener = React.useCallback(
    (event) => {
      if (!dropdownRef.current) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (dropdownRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    },
    [setOpen, dropdownRef],
  );

  React.useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClick);
      return () => document.removeEventListener("mousedown", handleClick);
    }
  }, [handleClick, open]);

  return (
    <div className={classNames("dropdown", open && "show")}>
      <button
        className={classNames(buttonClassName, "button-toggle")}
        disabled={disabled}
        type="button"
        onClick={() => {
          if (!open) setOpen(true);
        }}
      >
        {buttonText}
      </button>
      <div
        className={classNames("dropdown-menu", open && "show")}
        ref={dropdownRef}
      >
        {choices.map(({ label, value }) => (
          <button
            key={value}
            className="dropdown-item"
            disabled={disabled}
            onClick={() => {
              onSelected(value);
              setOpen(false);
            }}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}
