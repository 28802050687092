import { gql } from "@apollo/client";
import {
  PathElementComponent,
  PathElementListUnordered,
} from "@components/path-element";
import { PaginationBar } from "@components/reports/native-table";
import { LoadingSlide } from "@components/title-slide-view";
import styled from "@emotion/styled";
import {
  GetCommonTouchpoints,
  GetCommonTouchpointsVariables,
} from "@nb-api-graphql-generated/GetCommonTouchpoints";
import { Label } from "@north-beam/nb-common";
import { MetricFormat } from "@north-beam/nb-common";
import { DateInterval, formatNumberExact } from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import { enumType } from "@utils/enum-type";
import { useNorthbeamQuery } from "@utils/hooks";
import _ from "lodash";
import React from "react";
import Select from "react-select";
import {
  CellProps,
  Column,
  HeaderProps,
  Row,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { makeReportStateQuery, ReportState } from "./report-state";

export const GET_COMMON_TOUCHPOINTS = gql`
  query GetCommonTouchpoints($id: ID!, $dateRange: JSONObject!) {
    me {
      id
      adObject(id: $id) {
        id
        commonTouchpoints(dateRange: $dateRange)
      }
    }
  }
`;

export function CommonTouchpointsPage({
  id,
  dateRange,
  state,
}: {
  id: string;
  dateRange: DateInterval;
  state: ReportState;
}) {
  const { data, loading } = useNorthbeamQuery<
    GetCommonTouchpoints,
    GetCommonTouchpointsVariables
  >(GET_COMMON_TOUCHPOINTS, {
    variables: { id, dateRange },
  });

  let inner: React.ReactElement;

  if (loading) {
    inner = <LoadingSlide />;
  } else {
    const commonTouchpoints = data?.me.adObject?.commonTouchpoints;
    if (
      !commonTouchpoints ||
      Object.keys(commonTouchpoints.adKeyHashToLabels).length === 0
    ) {
      inner = <span>No touchpoints found for this campaign.</span>;
    } else {
      inner = <Inner response={commonTouchpoints} state={state} />;
    }
  }

  return inner;
}

function Inner({ response, state }: { response: any; state: ReportState }) {
  const reportParams = makeReportStateQuery(state).toString();

  const [relativePosition, setRelativePosition] =
    React.useState<IRelativePositionChoice>("beforeOrAfter");

  const unaggregatedRows = React.useMemo(() => {
    if (relativePosition === "beforeOrAfter") {
      return response.commonTouchpointsBeforeOrAfter;
    } else if (relativePosition === "before") {
      return response.commonTouchpointsBefore;
    } else if (relativePosition === "after") {
      return response.commonTouchpointsAfter;
    }
  }, [response, relativePosition]);

  const adKeyHashToLabels: Record<string, Label[]> = React.useMemo(
    () => response.adKeyHashToLabels,
    [response],
  );

  const allLabelChoices = React.useMemo(() => {
    const keys: Record<string, number> = {};
    for (const labelses of Object.values(adKeyHashToLabels)) {
      for (const { key } of labelses) {
        if (!(key in keys)) {
          keys[key] = 0;
        }
        keys[key] += 1;
      }
    }

    const labelChoices = _.chain(keys)
      .toPairs()
      .sortBy((v) => v[1])
      .reverse()
      .map(([key, _]) => ({ value: key, label: key }))
      .value();
    return [{ value: "", label: "(none)" }, ...labelChoices];
  }, [adKeyHashToLabels]);

  const [currentLabelChoiceValue, setCurrentLabelChoiceValue] =
    React.useState("");
  const currentLabelChoiceLabel = currentLabelChoiceValue || "(none)";
  const currentLabelChoice = {
    value: currentLabelChoiceValue,
    label: currentLabelChoiceLabel,
  };

  const pathElement = response.grandTotal.pathElements[0];
  const rows = aggregateRowsByLabelChoice(
    currentLabelChoiceValue,
    unaggregatedRows,
    adKeyHashToLabels,
  );

  return (
    <>
      <div className="flex items-center">
        <div>
          Position of touchpoint relative to{" "}
          <span style={{ fontSize: "12px" }}>
            <PathElementComponent
              element={pathElement}
              adObjectReportParams={reportParams}
              pillWidthEM={14}
            />
          </span>
          :&nbsp;
        </div>
        <div className="flex-grow-1">
          <Select
            value={RelativePositionChoice.toReactSelect(relativePosition)}
            options={RelativePositionChoice.reactSelectOptions}
            onChange={(v: any) => {
              logEvent("Change Common Click Touchpoints relative position", {
                value: v.value,
              });
              setRelativePosition(v.value);
            }}
          />
        </div>
      </div>

      <div className="my-3 flex items-center">
        <div>Campaign Label breakdown:&nbsp;</div>
        <div className="flex-grow-1">
          <Select
            value={currentLabelChoice}
            options={allLabelChoices}
            onChange={(v: any) => {
              logEvent(
                "Change Common Click Touchpoints campaign level breakdown",
                {
                  value: v.value,
                },
              );
              setCurrentLabelChoiceValue(v.value);
            }}
          />
        </div>
      </div>

      <hr />

      <div className="my-3">
        This page shows you which other sales and marketing efforts (the
        &ldquo;common touchpoints&rdquo;) assist{" "}
        <span style={{ fontSize: "12px" }}>
          <PathElementComponent
            element={pathElement}
            adObjectReportParams={reportParams}
            pillWidthEM={14}
          />
        </span>{" "}
        in driving conversions.
      </div>

      <div className="my-3">
        Each row in the table below represents a distinct group of touchpoints
        that all appeared in the same customer path as{" "}
        <span style={{ fontSize: "12px" }}>
          <PathElementComponent
            element={pathElement}
            adObjectReportParams={reportParams}
            pillWidthEM={14}
          />
        </span>
        .
      </div>

      <hr />

      <TableDisplay
        rows={rows}
        grandTotal={response.grandTotal}
        reportParams={reportParams}
      />
    </>
  );
}

function aggregateRowsByLabelChoice(
  labelChoiceValue: string,
  unaggregatedRows: any[],
  adKeyHashToLabels: Record<string, Label[]>,
) {
  if (!labelChoiceValue) {
    return unaggregatedRows;
  }

  const descriptorToMetrics: Record<string, CommonTouchpointsMetrics> = {};
  for (const row of unaggregatedRows) {
    const {
      pathElements,
      numPaths,
      numConvertingPaths,
      revenueFromConvertingPaths,
    } = row;
    const descriptor = unorderedAdKeyPathToDescriptor(pathElements);
    const metrics = descriptorToMetrics[descriptor] ?? {
      numPaths: 0,
      numConvertingPaths: 0,
      revenueFromConvertingPaths: 0,
    };

    metrics.numPaths += numPaths;
    metrics.numConvertingPaths += numConvertingPaths;
    metrics.revenueFromConvertingPaths += revenueFromConvertingPaths;

    descriptorToMetrics[descriptor] = metrics;
  }

  const rv = [];
  for (const descriptor in descriptorToMetrics) {
    const metrics = descriptorToMetrics[descriptor];
    const pathElementsValues: string[] = JSON.parse(descriptor);
    rv.push({
      ...metrics,
      pathElements: pathElementsValues.map((value) => ({
        name: value || "(not set)",
        objectId: null,
        nbtPlatformID: null,
      })),
    });
  }
  return rv;

  function unorderedAdKeyPathToDescriptor(
    pathElements: PathElementFromResponse[],
  ) {
    const values: Record<string, true> = {};
    for (const { elementId } of pathElements) {
      const labels = adKeyHashToLabels[elementId];
      let value = "";
      if (labels) {
        const kv = labels.find((l) => l.key === labelChoiceValue);
        if (kv) {
          value = kv.value;
        }
      }

      values[value] = true;
    }

    return JSON.stringify(_.chain(values).keys().sortBy().value());
  }
}

function TableDisplay({
  rows,
  grandTotal,
  reportParams,
}: {
  rows: any[];
  grandTotal: any;
  reportParams: string;
}) {
  const { numPaths, numConvertingPaths, revenueFromConvertingPaths } =
    grandTotal;

  const makeFactColumn = React.useCallback(
    (
      id: string,
      accessor: any,
      format: MetricFormat,
      title: string,
      total: number | undefined,
    ) => ({
      id,
      accessor,
      Header: ({ column }: HeaderProps<any>) => (
        <div className="nb-common-touchpoints-cell flex">
          <div>
            {column.canSort && (
              <>
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <i className="fas fa-sort-down"></i>
                    ) : (
                      <i className="fas fa-sort-up"></i>
                    )
                  ) : (
                    <i className="fas fa-sort"></i>
                  )}
                </span>
                &nbsp;&nbsp;
              </>
            )}
          </div>
          <div className="flex-grow-1">
            <h6>{title}</h6>
            <div className="text-muted">
              {typeof total === "undefined" ? (
                <>&nbsp;</>
              ) : (
                <>({formatNumberExact(total, format)} total)</>
              )}
            </div>
          </div>
        </div>
      ),
      Cell: ({ cell }: CellProps<any>) => (
        <div className="nb-common-touchpoints-cell">
          <div>{formatNumberExact(cell.value, format)}</div>
          <div className="text-muted">
            {typeof total === "undefined" ? (
              <>&nbsp;</>
            ) : (
              <>
                ({formatNumberExact(cell.value / total, "percentage")} of total)
              </>
            )}
          </div>
        </div>
      ),
      width: 3,
      sortDescFirst: true,
    }),
    [],
  );

  const columns: Column<any>[] = React.useMemo(() => {
    return [
      {
        id: "touchpoint",
        Header: () => (
          <div className="nb-common-touchpoints-cell touchpoint-group">
            <h6>Touchpoint Group (unordered)</h6>
            <small>&nbsp;</small>
          </div>
        ),
        Cell: ({ row }: CellProps<any>) => (
          <div className="nb-common-touchpoints-cell touchpoint-group">
            {row.original.pathElements &&
            row.original.pathElements.length > 0 ? (
              <PathElementListUnordered
                pathElements={row.original.pathElements}
                adObjectReportParams={reportParams}
                pillWidthEM={14}
              />
            ) : (
              "(none)"
            )}
          </div>
        ),
        width: 6,
      },
      makeFactColumn(
        "numPaths",
        "numPaths",
        "integer",
        "Common Paths",
        numPaths,
      ),
      makeFactColumn(
        "numConvertingPaths",
        "numConvertingPaths",
        "integer",
        "Common Converting Paths",
        numConvertingPaths,
      ),
      makeFactColumn(
        "revenueFromConvertingPaths",
        "revenueFromConvertingPaths",
        "dollars",
        "Converting Revenue",
        revenueFromConvertingPaths,
      ),
      makeFactColumn(
        "commonConversionRate",
        (original: any) => original.numConvertingPaths / original.numPaths,
        "percentage",
        "Common Conversion Rate",
        undefined,
      ),
    ] as Column<any>[];
  }, [
    reportParams,
    numPaths,
    numConvertingPaths,
    revenueFromConvertingPaths,
    makeFactColumn,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable<any>(
    {
      columns,
      data: rows,
      initialState: { pageSize: 20 },
    },
    useFlexLayout,
    useSortBy,
    usePagination,
  );

  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
  };

  return (
    <Style>
      <PaginationBar {...paginationProps} />

      <div
        {...getTableProps()}
        className="table nb-virtual-table table-bordered"
        style={{ fontSize: "12px" }}
      >
        <div className="thead thead-light">
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line
            <div className="tr" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line
                <div
                  className="th"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="tbody" {...getTableBodyProps()}>
          {page.map((row: Row<any>) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line
              <div className="tr" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  // eslint-disable-next-line
                  <div className="td" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </Style>
  );
}

const Style = styled.div`
  .nb-common-touchpoints-cell {
    text-align: right;
    width: 100%;
    h6 {
      height: 3.25em;
    }

    &.touchpoint-group {
      text-align: left;
    }
  }
`;

enum RelativePositionChoiceEnum {
  beforeOrAfter = "Before or after (any position)",
  before = "Before",
  after = "After",
}

const RelativePositionChoice = enumType({
  enumObject: RelativePositionChoiceEnum,
  keyOrder: ["beforeOrAfter", "before", "after"],
});

type IRelativePositionChoice = keyof typeof RelativePositionChoiceEnum;

interface PathElementFromResponse {
  elementId: string;
}

interface CommonTouchpointsMetrics {
  numPaths: number;
  numConvertingPaths: number;
  revenueFromConvertingPaths: number;
}
