import {
  ApolloError,
  DocumentNode,
  MutationHookOptions,
  MutationResult,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import Bugsnag from "@bugsnag/browser";
import { useUser } from "@components/user-context";
import { useState } from "react";

export function useNorthbeamQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    doNotRaiseErrorOnFailure?: boolean;
    useHeavy?: boolean;
  },
): QueryResult<TData, TVariables> {
  const { user } = useUser();

  const useHeavy = options?.useHeavy;
  const result = useQuery<TData, TVariables>(query, {
    ...options,
    client: useHeavy ? user.heavyApolloClient : undefined,
  });

  if (result.error && !options?.doNotRaiseErrorOnFailure) {
    Bugsnag.setContext(
      `GraphQL query failure. \nQuery: ${JSON.stringify(
        query,
      )}\nOptions: ${JSON.stringify(options)}`,
    );
    throw result.error;
  }

  return result;
}

export function useNorthbeamLazyQuery<
  TData = any,
  TVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    doNotRaiseErrorOnFailure?: boolean;
    useHeavy?: boolean;
  },
) {
  const { user } = useUser();
  const useHeavy = options?.useHeavy;

  const [loadData, data] = useLazyQuery<TData, TVariables>(query, {
    ...options,
    client: useHeavy ? user.heavyApolloClient : undefined,
  });
  if (data.error && !options?.doNotRaiseErrorOnFailure) {
    Bugsnag.setContext(
      `GraphQL lazy query failure. \nQuery: ${JSON.stringify(
        query,
      )}\nOptions: ${JSON.stringify(options)}`,
    );
    throw data.error;
  }

  return { loadData, data };
}

export function useNorthbeamMutation<
  TData = any,
  TVariables = OperationVariables,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & {
    doNotRaiseErrorOnFailure?: boolean;
  },
): MutationTuple<TData, TVariables> {
  const [caller, result] = useMutation<TData, TVariables>(mutation, options);

  if (result.error && !options?.doNotRaiseErrorOnFailure) {
    Bugsnag.setContext(
      `GraphQL mutation failure. \nMutation: ${JSON.stringify(
        mutation,
      )}\nOptions: ${JSON.stringify(options)}`,
    );
    throw result.error;
  }

  return [caller, result];
}

type MutationPromiseTuple<TData, TVariables> = [
  (variables: TVariables) => Promise<TData>,
  MutationResult<TData>,
];
// This is a wrapper around useMutation that wraps the mutation function in a promise
export function useNorthbeamMutationPromise<
  TData = any,
  TVariables = OperationVariables,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & {
    doNotRaiseErrorOnFailure?: boolean;
  },
): MutationPromiseTuple<TData, TVariables> {
  const [promise, setPromise] = useState<{
    resolve: (data: TData) => void;
    reject: (error: ApolloError) => void;
  }>();

  const onCompleted = (data: TData) => promise!.resolve(data);
  const onError = (error: ApolloError) => promise!.reject(error);
  const [caller, result] = useMutation<TData, TVariables>(mutation, {
    ...options,
    onCompleted,
    onError,
  });

  const callerPromise = (variables: TVariables) =>
    new Promise<TData>((resolve, reject) => {
      setPromise({ resolve, reject });
      caller({ variables });
    });

  if (result.error && !options?.doNotRaiseErrorOnFailure) {
    Bugsnag.setContext(
      `GraphQL mutation failure. \nMutation: ${JSON.stringify(
        mutation,
      )}\nOptions: ${JSON.stringify(options)}`,
    );
    throw result.error;
  }

  return [callerPromise, result];
}
