import { gql } from "@apollo/client";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { GenericTooltip, HTMLTooltip } from "@components/tooltip-container";
import {
  AddLabel,
  AddLabelVariables,
} from "@nb-api-graphql-generated/AddLabel";
import {
  RemoveLabel,
  RemoveLabelVariables,
} from "@nb-api-graphql-generated/RemoveLabel";
import {
  CategoricalBreakdownConfig,
  jsonHash,
  Label,
} from "@north-beam/nb-common";
import { notifyLabelsCache } from "@shared/notify-labels-cache";
import { useNorthbeamMutation } from "@utils/hooks";
import { toReactSelectLabel, toReactSelectLabelMulti } from "@utils/index";
import _ from "lodash";
import React from "react";
import CreatableSelect from "react-select/creatable";
import { useToasts } from "react-toast-notifications";

const ADD_LABEL = gql`
  mutation AddLabel($id: ID!, $key: String!, $value: String!) {
    addLabelToAdObject(id: $id, key: $key, value: $value) {
      id
      labelsDigest {
        key
        value
        isManualLabel
        canDelete
        resolutionOrder
      }
    }
  }
`;

const REMOVE_LABEL = gql`
  mutation RemoveLabel($id: ID!, $key: String!) {
    removeLabelFromAdObject(id: $id, key: $key) {
      id
      labelsDigest {
        key
        value
        isManualLabel
        canDelete
        resolutionOrder
      }
    }
  }
`;

export function LabelEditor(props: {
  id: string;
  labels: { key: string; value: string }[];
}) {
  const { id, labels } = props;
  const { addToast } = useToasts();
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();

  const [keyInputValue, setKeyInputValue] = React.useState<string | null>(null);
  const [valueInputValue, setValueInputValue] = React.useState<string | null>(
    null,
  );

  const [addLabel, { loading: addLabelLoading }] = useNorthbeamMutation<
    AddLabel,
    AddLabelVariables
  >(ADD_LABEL);
  const [removeLabel, { loading: removeLabelLoading }] = useNorthbeamMutation<
    RemoveLabel,
    RemoveLabelVariables
  >(REMOVE_LABEL);

  const isMutating = addLabelLoading || removeLabelLoading;

  const trySubmit = React.useCallback(async () => {
    if (isMutating) {
      return;
    }

    const key = keyInputValue?.trim();
    const value = valueInputValue?.trim();

    if (key && value) {
      for (const { message, triggered } of LABEL_KEY_VALIDATIONS) {
        if (triggered(key)) {
          addToast(message, { appearance: "warning" });
          return;
        }
      }

      for (const { message, triggered } of LABEL_VALUE_VALIDATIONS) {
        if (triggered(value)) {
          addToast(message, { appearance: "warning" });
          return;
        }
      }

      await addLabel({ variables: { id, key, value } });

      setKeyInputValue(null);
      setValueInputValue(null);

      notifyLabelsCache();
    }
  }, [isMutating, id, addLabel, keyInputValue, valueInputValue, addToast]);

  const removeKey = React.useCallback(
    async (key: string) => {
      await removeLabel({ variables: { id, key } });

      notifyLabelsCache();
    },
    [removeLabel, id],
  );

  const kvPairs = labels as Label[];
  const categoricals: CategoricalBreakdownConfig[] = breakdownConfigs;
  const bds = categoricals;

  const keyOptions = toReactSelectLabelMulti(bds.map((v) => v.key));
  const currentBreakdown = !keyInputValue
    ? undefined
    : bds.find((v) => v.key === keyInputValue);
  const valueOptions = (currentBreakdown && currentBreakdown.choices) ?? [];

  return (
    <table className="table table-striped table-bordered nb-small-font">
      <thead>
        <tr className="flex">
          <th scope="col" className="col-1 text-center">
            <i className="fas fa-cogs"></i>
          </th>
          <th scope="col" className="col-5">
            Label key
          </th>
          <th scope="col" className="col-5">
            Label value
          </th>
          <th scope="col" className="col-1"></th>
        </tr>
      </thead>
      <tbody>
        {kvPairs.length === 0 && (
          <tr className="flex">
            <td className="col-1"></td>
            <td className="col-5 text-muted">no labels added (yet!)</td>
            <td className="col-5"></td>
            <td className="col-1"></td>
          </tr>
        )}
        {kvPairs.map((pair) => {
          const { canDelete, isManualLabel, resolutionOrder, key, value } =
            pair;
          const hasFallback = resolutionOrder.length > 1;

          const iconInner: React.ReactElement =
            resolutionOrder[0].isRoot && isManualLabel ? (
              <i className="fas fa-edit"></i>
            ) : (
              <i className="fas fa-cogs"></i>
            );

          const tooltipContentPrefix = isManualLabel ? (
            <p>
              This label overrides values set from Automatic Label Rules or from
              higher-level Traffic Source.
            </p>
          ) : (
            <>
              <p>
                This campaign label was automatically generated from your
                Automatic Label Rules.
              </p>
              <p>You can override this value by adding a label below.</p>
            </>
          );

          const tooltipContent = (
            <>
              {tooltipContentPrefix}
              <table className="table table-striped table-sm table-border">
                <thead>
                  <tr>
                    <th scope="col" align="center">
                      Priority
                    </th>
                    <th scope="col">Value</th>
                    <th scope="col">Level</th>
                    <th scope="col" align="center">
                      Auto?
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {resolutionOrder.map((v, idx) => (
                    <tr
                      key={jsonHash(v)}
                      className={idx === 0 ? "table-primary" : ""}
                    >
                      <th scope="row" align="center">
                        {v.priority}
                      </th>
                      <td>
                        <b>{v.value}</b>
                      </td>
                      <td className="text-muted">{_.capitalize(v.level)}</td>
                      <td className="text-muted" align="center">
                        {!v.isManualLabel && <i className="fas fa-cogs"></i>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          );

          const icon = (
            <GenericTooltip noInfoCircle content={tooltipContent}>
              {iconInner}
            </GenericTooltip>
          );

          return (
            <tr key={jsonHash({ key, value })} className="flex">
              <td className="col-1" align="center">
                {icon}
              </td>
              <td className="col-5">{key}</td>
              <td className="col-5">
                {value || <span className="text-muted">(none)</span>}
              </td>
              <td className="col-1" align="center">
                {canDelete ? (
                  <button
                    className="btn btn-link text-danger p-0"
                    disabled={isMutating}
                    onClick={() => removeKey(pair.key)}
                  >
                    {hasFallback ? (
                      <HTMLTooltip
                        noInfoCircle
                        html={`Click here to remove this override. The resulting label value will be <b>${resolutionOrder[1].value}</b>`}
                      >
                        <i className="fas fa-undo"></i>
                      </HTMLTooltip>
                    ) : (
                      <HTMLTooltip
                        noInfoCircle
                        html="Click here to delete this label key-value pair."
                      >
                        <i className="fas fa-trash-alt" />
                      </HTMLTooltip>
                    )}
                  </button>
                ) : null}
              </td>
            </tr>
          );
        })}
        <tr className="flex">
          <td className="col-1"></td>
          <td className="col-5">
            <CreatableSelect
              isClearable
              onChange={(value: any) => setKeyInputValue(value?.value ?? null)}
              value={toReactSelectLabel(keyInputValue)}
              options={keyOptions}
              placeholder="Enter key..."
            />
          </td>
          <td className="col-5">
            <CreatableSelect
              isClearable
              isDisabled={!keyInputValue}
              value={toReactSelectLabel(valueInputValue)}
              onChange={(value: any) =>
                setValueInputValue(value?.value ?? null)
              }
              options={valueOptions}
              placeholder="Enter value..."
            />
          </td>
          <td className="col-1" align="center">
            <button
              className="btn btn-link p-0"
              disabled={isMutating}
              onClick={trySubmit}
            >
              <i className="fas fa-plus-square" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const INVALID_CHARACTERS = {
  message: `Contains one of these invalid characters: =`,
  triggered: (value: string) => !!/=/.test(value),
};

const TOO_LONG = {
  message: `Label key is too long (needs to be under 60 characters).`,
  triggered: (value: string) => value.length > 60,
};

// eslint-disable-next-line
const ASCII_REGEX = /^[\x00-\x7F]*$/;
const ASCII_ONLY = {
  message: `Contains invalid characters (like emoji).`,
  triggered: (value: string) => !ASCII_REGEX.test(value),
};

const LABEL_KEY_VALIDATIONS = [INVALID_CHARACTERS, TOO_LONG, ASCII_ONLY];

const LABEL_VALUE_VALIDATIONS = [INVALID_CHARACTERS];
