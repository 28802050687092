import React, { ReactNode } from "react";
import { toast, Toaster } from "react-hot-toast";

const DEFAULT_TOAST_DURATION = 5000;

export const NotificationManager = () => (
  <Toaster
    position="top-center"
    containerClassName="nb-toast-container"
    containerStyle={{ top: 64 }}
    toastOptions={{ className: "nb-toast" }}
    gutter={3}
  />
);

const Notification = ({ message, caption }: any) => (
  <div className="flex flex-col">
    <div className="text-lg font-bold">{message}</div>
    {caption && <div>{caption}</div>}
  </div>
);

interface NotificationArgs {
  message: string;
  caption?: string | ReactNode;
  duration?: number;
}

export const notifySuccess = ({
  message,
  caption,
  duration = DEFAULT_TOAST_DURATION,
}: NotificationArgs) => {
  if (message) {
    return toast.success(<Notification message={message} caption={caption} />, {
      duration,

      icon: null,
      style: {
        background: "#CFF1CE",
      },
    });
  }
};

export const notifyError = ({
  message,
  caption,
  duration = DEFAULT_TOAST_DURATION,
}: NotificationArgs) => {
  if (message) {
    return toast.error(<Notification message={message} caption={caption} />, {
      duration,
      icon: null,
      style: {
        backgroundColor: "#FBCDCD",
      },
    });
  }
};

// TODO: Implement toastWarning and toastInfo if needed
