import { GetOverviewPerformance_me_overviewPage_performanceMetrics as OverviewPageMetric } from "@nb-api-graphql-generated/GetOverviewPerformance";
import { formatNumberApproximate } from "@north-beam/nb-common";
import { fmtDateInterval } from "@utils/index";
import React, { useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { MetricBarChart } from "./metric-bar-chart";
import { MetricSelect } from "./metric-select";

export const MetricGroupBox = ({
  metrics,
  groupName,
  isLoading,
}: {
  metrics: OverviewPageMetric[];
  groupName: string;
  isLoading: boolean;
}) => {
  const [selectedMetricIndex, setSelectedMetricIndex] = useState<number>(0);
  const selectedMetric = useMemo(
    () => metrics[selectedMetricIndex],
    [metrics, selectedMetricIndex],
  );

  const numDates = selectedMetric.timeSeriesPointDateRanges.length;

  const currentValueDisplay = useMemo(() => {
    let prefix = "";
    if (selectedMetric.currentValueDescription) {
      prefix = selectedMetric.currentValueDescription + " ";
    }
    let dateRangeFormatted = "Unknown";
    if (selectedMetric.timeSeriesPointDateRanges.length > 0) {
      const startDate = selectedMetric.timeSeriesPointDateRanges[0].startDate;
      const endDate =
        selectedMetric.timeSeriesPointDateRanges[numDates - 1].endDate;
      dateRangeFormatted = fmtDateInterval({ startDate, endDate });
    }

    return `${prefix}(${dateRangeFormatted})`;
  }, [selectedMetric, numDates]);

  return (
    <div className="elevation-1 border mb-3 py-3 rounded text-left col-6">
      <AutoSizer disableHeight>
        {({ width }) => (
          <div
            className={isLoading ? "waiting-interstitial" : ""}
            style={{ width }}
          >
            <div className="mb-4 ml-5 pl-3 pt-2">
              <div className="flex">
                <div className="flex-grow-1 h6 text-muted">
                  <MetricSelect
                    selectedMetric={selectedMetric}
                    selectMetricIndex={setSelectedMetricIndex}
                    metrics={metrics}
                  />
                </div>
                <div className="h6 text-muted mr-3 small">{groupName}</div>
              </div>
              <div>
                <strong style={{ fontSize: "27px" }}>
                  {formatNumberApproximate(
                    Number(selectedMetric.currentValue),
                    selectedMetric.format,
                  )}
                  &nbsp;
                </strong>
                <small className="text-muted">{currentValueDisplay}</small>
              </div>
            </div>
            <div style={{ width, height: width / 3, position: "relative" }}>
              <MetricBarChart selectedMetric={selectedMetric} />
            </div>
          </div>
        )}
      </AutoSizer>
    </div>
  );
};
