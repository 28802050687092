import klaviyoLogo from "@assets/klaviyo.svg";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { ConnectionCard } from "@pages/settings/connection-card";
import {
  LoginComponentChildrenProps,
  LoginComponentProps,
} from "@pages/settings/connection-status-manager";
import { CardProps2 } from "@pages/settings/core";
import React from "react";

const type = ConnectionType.integration_klaviyo;

export function KlaviyoConnectionCard(props: CardProps2) {
  return (
    <ConnectionCard
      type={type}
      connectionTypeName="Klaviyo"
      connectionCallToAction={`
        Integrate your Klaviyo account to start receiving insights about
        email signups and email conversions.
      `}
      logoUrl={klaviyoLogo}
      logoAltText="Klaviyo Logo"
    >
      {({
        upsertConnection,
        isLoading,
        accounts,
      }: LoginComponentChildrenProps) => (
        <KlaviyoLoginComponent
          upsertConnection={upsertConnection}
          isLoading={isLoading}
          accounts={accounts}
        />
      )}
    </ConnectionCard>
  );
}

function KlaviyoLoginComponent({
  upsertConnection,
  isLoading,
}: Omit<LoginComponentProps, "setStatus" | "integrationDetails">) {
  const [publicKey, setPublicKey] = React.useState("");
  const [privateKey, setPrivateKey] = React.useState("");

  const onConnectClicked = React.useCallback(async () => {
    await upsertConnection({
      public_key: publicKey,
      private_key: privateKey,
    });
  }, [publicKey, privateKey, upsertConnection]);

  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-auto">
          <label className="sr-only" htmlFor="klaviyoPublicKey">
            Public Key
          </label>
          <div className="input-group">
            <input
              disabled={isLoading}
              type="text"
              className="form-control"
              value={publicKey}
              placeholder="Public key..."
              id="klaviyoPublicKey"
              onChange={(event) => setPublicKey(event.target.value)}
            />
          </div>
        </div>
        <div className="col-auto">
          <label className="sr-only" htmlFor="klaviyoPrivateKey">
            Private Key
          </label>
          <div className="input-group">
            <input
              disabled={isLoading}
              type="text"
              className="form-control"
              value={privateKey}
              placeholder="Private key..."
              id="klaviyoPrivateKey"
              onChange={(event) => setPrivateKey(event.target.value)}
            />
          </div>
        </div>
        <div className="col-auto">
          <button
            disabled={isLoading || !publicKey || !privateKey}
            className="btn btn-primary"
            onClick={onConnectClicked}
          >
            {isLoading ? "Please Wait..." : "Connect"}
          </button>
        </div>
      </div>
    </div>
  );
}
