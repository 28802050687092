import { gql } from "@apollo/client";
import { RegexTips } from "@components/regex-tips";
import { LoadingSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import { H1, LightSwitch } from "@components/utilities";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FetchDiscountCodeMappings as FetchDiscountCodeMappingsQuery } from "@nb-api-graphql-generated/FetchDiscountCodeMappings";
import {
  UpdateDiscountCodeMappings,
  UpdateDiscountCodeMappingsVariables,
} from "@nb-api-graphql-generated/UpdateDiscountCodeMappings";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import _ from "lodash";
import { ParseResult } from "papaparse";
import React, { useEffect, useState } from "react";
import { CSVReader } from "react-papaparse";
import { v4 as uuidv4 } from "uuid";

interface DiscountCodeMapping {
  id: string;
  regex: string;
  campaignKey: string;
  active: boolean;
}

function DiscountCodeMappingRow({
  mapping,
  setMapping,
}: {
  mapping: DiscountCodeMapping;
  setMapping: (arg0: DiscountCodeMapping) => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: mapping.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr id={mapping.id} ref={setNodeRef} style={style}>
      <td {...attributes} {...listeners} className="text-center align-middle">
        <i className="fa-regular fa-grip-vertical" />
      </td>
      <td align="center">
        <div className="flex justify-content-center">
          <LightSwitch
            size="medium"
            isSet={mapping.active}
            disabled={false}
            onChange={() => {
              setMapping({ ...mapping, active: !mapping.active });
            }}
            id={"light-switch" + mapping.id}
          />
        </div>
      </td>
      <td>
        <textarea
          rows={3}
          className="form-control"
          value={mapping.regex}
          onChange={(event) => {
            setMapping({ ...mapping, regex: event.target.value });
          }}
        />
      </td>
      <td>
        <textarea
          className="form-control"
          rows={3}
          value={mapping.campaignKey}
          onChange={(event) =>
            setMapping({ ...mapping, campaignKey: event.target.value })
          }
        />
      </td>
    </tr>
  );
}

export function DiscountCodeMappingsContainer() {
  const [synced, setSynced] = useState<boolean>(true);
  const [uploadFileError, setUploadFileError] = useState<string>("");
  const { user } = useUser();

  const [discountCodeMappings, setDiscountCodeMappings] = useState<
    DiscountCodeMapping[]
  >([]);

  const { data, loading } = useNorthbeamQuery<FetchDiscountCodeMappingsQuery>(
    FETCH_DISCOUNT_CODE_MAPPINGS,
  );

  const [updateDiscountCodeMappings] = useNorthbeamMutation<
    UpdateDiscountCodeMappings,
    UpdateDiscountCodeMappingsVariables
  >(UPDATE_DISCOUNT_CODE_MAPPINGS);

  useEffect(() => {
    if (data?.me?.discountCodeMappings?.discountCodeMappingObject) {
      setDiscountCodeMappings(
        data.me.discountCodeMappings.discountCodeMappingObject,
      );
    }
  }, [data?.me?.discountCodeMappings?.discountCodeMappingObject]);

  const debouncedOnUpdate = React.useMemo(
    () =>
      _.debounce((newData: DiscountCodeMapping[]) => {
        updateDiscountCodeMappings({
          variables: { discountCodeMappings: newData },
        });
        setSynced(true);
      }, 500),
    [updateDiscountCodeMappings, setSynced],
  );

  const setDataWithSync = React.useCallback(
    (newData) => {
      setDiscountCodeMappings(newData);
      setSynced(false);
      debouncedOnUpdate(newData);
    },
    [setDiscountCodeMappings, debouncedOnUpdate, setSynced],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = discountCodeMappings.findIndex(
        (mapping) => mapping.id === active.id,
      );
      const newIndex = discountCodeMappings.findIndex(
        (mapping) => mapping.id === over?.id,
      );

      const newOrder = arrayMove(discountCodeMappings, oldIndex, newIndex);

      setDataWithSync(newOrder);
    }
  };

  const validateFileAndUpload = async (
    results: ParseResult<any>[],
    file: File,
  ) => {
    const formData = new FormData();
    formData.append("file", file);
    await user
      .callApi({
        url: "/api/uploads/discountCodes",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(
        ({
          discountCodeMappings,
        }: {
          discountCodeMappings: DiscountCodeMapping[];
        }) => {
          setDiscountCodeMappings(discountCodeMappings);
          setUploadFileError("");
        },
      )
      .catch((err) => {
        if (err?.response?.data?.message) {
          setUploadFileError(err.response.data.message);
        } else {
          setUploadFileError(
            "Internal server error, please contact Northbeam support.",
          );
        }
      });
  };

  const renderCSVUploadForm = () => {
    return (
      <div style={{ padding: "10px" }}>
        <CSVReader
          onDrop={(data, file) => validateFileAndUpload(data, file)}
          onError={setUploadFileError}
          style={{}}
          config={{}}
        >
          <span>Drop CSV file here or click to upload.</span>
        </CSVReader>
        {uploadFileError && <p style={{ color: "red" }}> {uploadFileError} </p>}
      </div>
    );
  };

  const renderCSVInstructions = () => {
    return (
      <div className="card" style={{ marginBottom: "10px" }}>
        <strong className="card-header">
          Bulk update your discount codes with a CSV File
        </strong>
        <div className="card-body">
          <p>
            Generate a CSV file with only 2 columns. The first column should be
            the Discount Code, and the second column should be that code&apos;s
            corresponding Campaign Key. Do not include any column names or
            headers.
          </p>
          <p>
            This data will update your current mappings, replacing any new
            Campaign Keys of existing Discount Codes, and adding new entries for
            new Discount Codes
          </p>
          <p>
            Your Discount Codes will be transformed to a regex upon upload. Do
            not use a regex in your CSV file, just type the discount code.
          </p>
          <p>
            If you want to delete an existing Discount Code, add it to your CSV
            and keep the second column for that row blank.
          </p>
        </div>
      </div>
    );
  };

  const newDiscountCodeMapping: DiscountCodeMapping = {
    id: uuidv4(),
    regex: "",
    campaignKey: "",
    active: false,
  };

  return (
    <>
      <LogOnMount name="Visit Discount Code Mappings Page" />
      <H1>Discount code mappings</H1>
      <p>
        Create mappings between discount codes and custom campaign keys to
        better track the discount codes&apos; performance.
      </p>
      <hr />

      {!loading && (
        <div className="container">
          <div className="row col mt-3">
            <div>
              Sync status:{" "}
              {synced ? <i className="fa-regular fa-check" /> : "..."}
            </div>
          </div>

          <div className="row col mt-3">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th></th>
                  <th className="text-center th-sm" scope="col">
                    Active?
                  </th>
                  <th scope="col" className="th-lg">
                    Discount code regex matcher
                  </th>
                  <th scope="col" className="th-lg">
                    Campaign Key
                  </th>
                </tr>
              </thead>
              <tbody>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <SortableContext items={discountCodeMappings}>
                    {discountCodeMappings.map((mapping, index) => (
                      <DiscountCodeMappingRow
                        key={mapping.id}
                        mapping={mapping}
                        setMapping={(newMapping) => {
                          const newData = [...discountCodeMappings];
                          newData[index] = newMapping;
                          setDataWithSync(newData);
                        }}
                      />
                    ))}
                  </SortableContext>
                </DndContext>

                <tr>
                  <td colSpan={4} align="center" valign="middle">
                    <button
                      className="btn btn-link"
                      onClick={() =>
                        setDataWithSync([
                          ...discountCodeMappings,
                          newDiscountCodeMapping,
                        ])
                      }
                    >
                      Create new discount code mapping
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="row col">
            <RegexTips />
          </div>
          {renderCSVInstructions()}
          {renderCSVUploadForm()}
        </div>
      )}
      {loading && <LoadingSlide />}
    </>
  );
}

export const FETCH_DISCOUNT_CODE_MAPPINGS = gql`
  query FetchDiscountCodeMappings {
    me {
      discountCodeMappings {
        id
        discountCodeMappingObject
      }
    }
  }
`;

export const UPDATE_DISCOUNT_CODE_MAPPINGS = gql`
  mutation UpdateDiscountCodeMappings($discountCodeMappings: [JSONObject!]!) {
    updateDiscountCodeMappings(discountCodeMappings: $discountCodeMappings)
  }
`;
