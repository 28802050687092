// value widgets
export { default as FuncWidget } from "react-awesome-query-builder/lib/components/rule/FuncWidget";
export { default as ValueFieldWidget } from "react-awesome-query-builder/lib/components/rule/ValueField";
export { default as VanillaButton } from "./core/VanillaButton";
export { default as VanillaButtonGroup } from "./core/VanillaButtonGroup";
export { default as vanillaConfirm } from "./core/vanillaConfirm";
// core components
export { default as VanillaConjs } from "./core/VanillaConjs";
// field select widget
export { default as VanillaFieldSelect } from "./core/VanillaFieldSelect";
export { default as VanillaValueSources } from "./core/VanillaValueSources";
export { default as VanillaBooleanWidget } from "./value/VanillaBoolean";
export { default as VanillaDateWidget } from "./value/VanillaDate";
export { default as VanillaDateTimeWidget } from "./value/VanillaDateTime";
export { default as VanillaMultiSelectWidget } from "./value/VanillaMultiSelect";
export { default as VanillaNumberWidget } from "./value/VanillaNumber";
export { default as VanillaSelectWidget } from "./value/VanillaSelect";
export { default as VanillaSliderWidget } from "./value/VanillaSlider";
export { default as VanillaTextWidget } from "./value/VanillaText";
export { default as VanillaTextAreaWidget } from "./value/VanillaTextArea";
export { default as VanillaTimeWidget } from "./value/VanillaTime";

export const VanillaProvider = ({ config, children }) => children;
