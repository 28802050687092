import classNames from "classnames";
import React from "react";
import { Card } from "./card";

export type CardDetailsProps = {
  title: string;
  details: {
    heading: string;
    items: string[];
  }[];
  className?: string;
};

export function CardDetails({ details, title, className }: CardDetailsProps) {
  return (
    <Card className={classNames(className, "pt-3 pb-5 bg-white rounded-md")}>
      <p className="text-lg font-semibold">{title}</p>
      <div className={"grid gap-4 grid-cols-card auto-rows-auto"}>
        {details.map((detail, i) => (
          <React.Fragment key={`card-detail-${i}`}>
            <div className="mr-4" key={`${detail}-${i}-heading`}>
              {detail.heading}
            </div>
            <div key={`${detail}-${i}-items`}>
              {detail.items.map((item, itemNumber) => (
                <div
                  key={`detail-item-${detail.heading}-item-${itemNumber}`}
                  className={"break-all"}
                >
                  {item}{" "}
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </Card>
  );
}
