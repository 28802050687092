import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "@components/user-context";

interface PrintPageButtonProps {
  className?: string;
  search: string;
}

export function PrintPageButton({ className, search }: PrintPageButtonProps) {
  const { user } = useUser();

  return (
    <div className={classNames(className)}>
      <Link
        className="btn btn-link btn-small"
        to={`${user.pathFromRoot("/overview-print")}${search}`}
      >
        Print page
      </Link>
    </div>
  );
}
