import React from "react";
import Select from "react-select";
import {
  IMCAttributionWindowDays,
  ITimeGranularity,
  MCAttributionWindowDays,
  MCAttributionWindowDaysChoicesDaily,
  MCAttributionWindowDaysChoicesHourly,
} from "@utils/constants";

interface AttributionWindowSelectProps {
  className?: string;
  isLoading: boolean;
  attributionModel: string;
  attributionWindow: IMCAttributionWindowDays;
  timeGranularity: ITimeGranularity;
  onChange: (obj: any) => void;
}

function toClicksAndViewsLabel<T>({ value }: { value: T }) {
  return { value, label: `${value} day click / 1 day view` };
}

export const AttributionWindowSelect = ({
  className,
  isLoading,
  attributionModel,
  attributionWindow,
  timeGranularity,
  onChange,
}: AttributionWindowSelectProps) => {
  let value = MCAttributionWindowDays.toReactSelectUnsafe(attributionWindow);
  let options =
    timeGranularity === "daily"
      ? MCAttributionWindowDaysChoicesDaily
      : MCAttributionWindowDaysChoicesHourly;

  if (attributionModel === "northbeam_custom__va") {
    value = toClicksAndViewsLabel(value);
    options = options.map(toClicksAndViewsLabel);
  }

  return (
    <Select
      className={className}
      isDisabled={isLoading}
      isClearable={false}
      isSearchable={false}
      value={value}
      options={options}
      onChange={onChange}
    />
  );
};
