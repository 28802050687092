import { CustomJsonParam } from "@/constants/date-range-params";
import { useUser } from "@components/user-context";
import { FetchOrderSummaryVariables } from "@nb-api-graphql-generated/FetchOrderSummary";
import {
  SubscriptionTypeEnum,
  ValueFloatComparisonInput,
  ValueIntComparisonInput,
} from "@nb-api-graphql-generated/global-types";
import { b64Decode, todayLocalAsISODate } from "@north-beam/nb-common";
import { prefs } from "@utils/client-side-preferences";
import { ITimeGranularity, resolveCompareDateRange } from "@utils/constants";
import { parseDateRangeArgument } from "@utils/index";
import { compact } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { useDateRangeQueryState } from "./use-date-range-query-select";

export enum OrderFilters {
  "subscriptions" = "subscriptions",
  "orderTags" = "orderTags",
  "customerTags" = "customerTags",
  "orderTypes" = "orderTypes",
  "orderValue" = "orderValue",
  "products" = "products",
  "orderId" = "orderId",
  "numberOfOriginalItems" = "numberOfOriginalItems",
  "numberOfTouchpoints" = "numberOfTouchpoints",
  "attributed" = "attributed",
  "discountCodes" = "discountCodes",
}

export const ValueIntComparisonParam =
  CustomJsonParam<ValueIntComparisonInput | null>();
export const ValueFloatComparisonParam =
  CustomJsonParam<ValueFloatComparisonInput | null>();

export const SubscriptionArrayParam = CustomJsonParam<
  SubscriptionTypeEnum[] | null
>();

export const useOrdersQueryParams = () =>
  useQueryParams({
    [OrderFilters.subscriptions]: SubscriptionArrayParam,
    [OrderFilters.orderTags]: ArrayParam,
    [OrderFilters.customerTags]: ArrayParam,
    [OrderFilters.orderTypes]: ArrayParam,
    [OrderFilters.orderValue]: ValueFloatComparisonParam,
    [OrderFilters.products]: ArrayParam,
    [OrderFilters.orderId]: StringParam,
    [OrderFilters.numberOfOriginalItems]: ValueIntComparisonParam,
    [OrderFilters.numberOfTouchpoints]: ValueIntComparisonParam,
    [OrderFilters.attributed]: ArrayParam,
    [OrderFilters.discountCodes]: ArrayParam,
  });

export const useOrderPageAdjustForReturns = () =>
  useQueryParam("adjustForReturns", withDefault(BooleanParam, false));

export const useOrderPageEcommercePlatforms = () =>
  useQueryParam("ecommercePlatforms", ArrayParam);

export const useOrderPageAdPlatforms = () =>
  useQueryParam("adPlatforms", ArrayParam);

const stripPrefixAndDecode = <T extends string>(values: T[]): T[] => {
  return values?.map((t) => {
    const toDecodeIndx = t.indexOf("-");
    return b64Decode(t.slice(toDecodeIndx + 1)) as T;
  });
};

export const useOrdersPageFilters = () => {
  const [dateRangeState] = useDateRangeQueryState();
  const [adjustForReturns] = useOrderPageAdjustForReturns();
  const [adPlatforms] = useOrderPageAdPlatforms();
  const [ecommercePlatforms] = useOrderPageEcommercePlatforms();

  const [
    {
      orderTags,
      customerTags,
      subscriptions,
      orderTypes,
      orderValue,
      products,
      orderId,
      numberOfOriginalItems,
      numberOfTouchpoints,
      attributed,
      discountCodes,
    },
  ] = useOrdersQueryParams();
  const { user } = useUser();

  // filter options
  const dateRange = parseDateRangeArgument(
    dateRangeState.dateRange,
    todayLocalAsISODate(),
  );

  const filterOptions: FetchOrderSummaryVariables["filterOptions"] =
    useMemo(() => {
      const isAnon = prefs.isDemoMode();
      const compactTags = compact(orderTags);
      const compactCustomerTags = compact(customerTags);
      const compactSubscriptions = compact(subscriptions);
      const compactDiscountCodes = compact(discountCodes);

      const decodedDiscountCodes = isAnon
        ? stripPrefixAndDecode(compactDiscountCodes)
        : compactDiscountCodes;

      const decodedTags = isAnon
        ? stripPrefixAndDecode(compactTags)
        : compactTags;
      const decodedCustomerTags = isAnon
        ? stripPrefixAndDecode(compactCustomerTags)
        : compactCustomerTags;

      const compactProducts = compact(products);
      const decodedProducts = isAnon
        ? stripPrefixAndDecode(compactProducts)
        : compactProducts;

      return {
        dateRange: {
          start: moment(dateRange.startDate)
            .tz(user.timezone)
            .startOf("day")
            .toDate(),
          end: moment(dateRange.endDate)
            .tz(user.timezone)
            .endOf("day")
            .toDate(),
        },
        discountCodes: decodedDiscountCodes?.length
          ? decodedDiscountCodes
          : null,
        subscriptions: compactSubscriptions?.length
          ? compactSubscriptions
          : null,
        customerTags: decodedCustomerTags?.length ? decodedCustomerTags : null,
        orderTags: decodedTags?.length ? decodedTags : null,
        orderTypes: orderTypes?.length
          ? compact(
              orderTypes?.map((orderType) => {
                if (orderType === "First time") return "first_time";
                if (orderType === "Returning") return "returning";
                return null;
              }),
            )
          : null,
        orderValue,
        products: decodedProducts?.length ? compact(decodedProducts) : null,
        orderId,
        numberOfOriginalItems: numberOfOriginalItems,
        numberOfTouchpoints: numberOfTouchpoints,
        attributed: attributed?.length ? compact(attributed) : null,
        adjustForReturns,
        adPlatforms: adPlatforms?.length ? compact(adPlatforms) : null,
        ecommercePlatforms: ecommercePlatforms?.length
          ? compact(ecommercePlatforms)
          : null,
      };
    }, [
      dateRange.startDate,
      dateRange.endDate,
      user.timezone,
      discountCodes,
      orderTags,
      customerTags,
      subscriptions,
      orderTypes,
      orderValue,
      products,
      orderId,
      numberOfOriginalItems,
      numberOfTouchpoints,
      attributed,
      adjustForReturns,
      adPlatforms,
      ecommercePlatforms,
    ]);

  return filterOptions;
};

export enum APIGranularity {
  "DAY" = "DAY",
  "HOUR" = "HOUR",
}

const toAPIGranularityUnit = (granularity: ITimeGranularity) => {
  if (granularity === "daily") return APIGranularity.DAY;
  return APIGranularity.HOUR;
};

export const useOrderGraphFilters = () => {
  const orderPageFilterOptions = useOrdersPageFilters();
  const [dateRangeState] = useDateRangeQueryState();
  const dateRange = parseDateRangeArgument(
    dateRangeState.dateRange,
    todayLocalAsISODate(),
  );
  const { user } = useUser();

  const compareDateRange = resolveCompareDateRange(
    dateRange,
    dateRangeState.compareDateRange,
  );

  const filterOptions = useMemo(
    () => ({
      ...orderPageFilterOptions,
      compareDateRange: {
        start: moment(compareDateRange.startDate)
          .tz(user.timezone)
          .startOf("day")
          .toDate(),
        end: moment(compareDateRange.endDate)
          .tz(user.timezone)
          .endOf("day")
          .toDate(),
      },
      granularity: toAPIGranularityUnit(dateRangeState.timeGranularity),
    }),
    [
      compareDateRange.endDate,
      compareDateRange.startDate,
      dateRangeState.timeGranularity,
      orderPageFilterOptions,
      user.timezone,
    ],
  );

  return filterOptions;
};
