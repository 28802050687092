import React from "react";
import { UtmGuidePlatformProps } from "..";
import { ConstructDestinationUrlComponent } from "./shared/construct-destination-url-component";

export function TikTok({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to all <b>destination URLs</b> for
        new and existing ads.
      </p>
      <ol>
        <ConstructDestinationUrlComponent urlParamPairs={urlParamPairs} />
        {/* <li>
          When creating a new ad, follow{" "}
          <GenericExternalLink href="https://help.elama.global/hc/en-us/articles/360011708820-How-to-Advertise-on-TikTok-Step-by-Step-Guide#:~:text=URL%3A%C2%A0Enter%20the%20URL%20of%20the%20website%20page%20with%20UTM%20tags%20%E2%80%94%20we%20recommend%20adding%20them%20manually%20to%20track%20the%20effectiveness%20of%20advertising%20in%20Google%20Analytics.e">
            these instructions
          </GenericExternalLink>{" "}
          and paste the copied link as the <b>URL</b>.
        </li> */}
      </ol>
    </>
  );
}
