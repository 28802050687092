import { Flyout } from "@shared/flyouts";
import React, { useEffect } from "react";
import { AnnotationForm } from "./annotation-form";
import { AnnotationList } from "./annotation-list/annotation-list";
import { useAnnotationParam } from "./hooks/use-annotation-param";
import { useAnnotationForm } from "./hooks/use-annotation-form";
import { usePrettyLogEventPage } from "@utils/analytics";
import { useUser } from "@components/user-context";
import clsx from "clsx";

export const AnnotationsFlyout = () => {
  const userData = useUser();
  const {
    closeFlyout,
    isFlyoutOpen,
    createAnnotationDateParam,
    setCreateAnnotationDateParam,
    removeFilteredDateRangeAnnotations,
  } = useAnnotationParam();
  const {
    newAnnotation,
    editAnnotation,
    currentAnnotation,
    clearCurrentAnnotation,
  } = useAnnotationForm();
  const prettyLogEventPage = usePrettyLogEventPage();

  const close = () => {
    closeFlyout();
    clearCurrentAnnotation();
    removeFilteredDateRangeAnnotations();
  };

  useEffect(() => {
    if (!isFlyoutOpen) {
      clearCurrentAnnotation();
    }
  }, [isFlyoutOpen, clearCurrentAnnotation]);

  useEffect(() => {
    if (createAnnotationDateParam) {
      newAnnotation(createAnnotationDateParam);
      setCreateAnnotationDateParam(undefined);
    }
  }, [createAnnotationDateParam, newAnnotation, setCreateAnnotationDateParam]);

  const clickNewAnnotation = () => {
    newAnnotation();
    prettyLogEventPage({
      domain: "Annotations",
      action: "onClick",
      additionalActionInfo: "New Note button",
    });
  };

  if (!isFlyoutOpen) {
    return null;
  }

  return (
    <Flyout
      open={isFlyoutOpen}
      direction="right"
      className={clsx(
        !userData.user.featureFlags.enableDesignRefresh && "mt-16",
        "px-4",
      )}
      onClose={close}
      drawerWidth={500}
    >
      <div data-testid="annotation-flyout">
        {currentAnnotation ? (
          <AnnotationForm
            currentAnnotation={currentAnnotation}
            clearCurrentAnnotation={clearCurrentAnnotation}
          />
        ) : (
          <AnnotationList
            newAnnotation={clickNewAnnotation}
            editAnnotation={editAnnotation}
          />
        )}
      </div>
    </Flyout>
  );
};
