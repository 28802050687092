import { gql } from "@apollo/client";

import React from "react";

export type FeatureFlag = {
  displayName: string;
  name: string;
  isSet: boolean;
};

export const NorthbeamLabs = () => {
  return (
    <div>
      Feature Flags have been migrated to{" "}
      <a
        href="https://northbeam.retool.com/apps/95396028-a27b-11ed-9605-93a53d833470/Customer%20Operations%20-%20Check%20Me%20First/Feature%20Flags"
        target="_blank"
        rel="noreferrer"
      >
        Retool
      </a>
      <div>
        <span className="font-semibold mt-2">NOTE:</span> You must be signed
        into Retool via the Retool site to see this feature
      </div>
      <iframe
        src="https://northbeam.retool.com/apps/Customer%20Operations%20-%20Check%20Me%20First/Feature%20Flags"
        width="100%"
        height="1000px"
        title="feature-flag-retool"
        className="mt-2"
        frameBorder="0"
      />
    </div>
  );
};

export const UPDATE_FEATURE_FLAG = gql`
  mutation UpdateUserFeatureFlag($featureFlagName: String!, $isSet: Boolean!) {
    updateUserFeatureFlag(featureFlagName: $featureFlagName, isSet: $isSet)
  }
`;
