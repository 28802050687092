import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import DnsEntry from "@pages/settings/dns-entry";
import { StepContainer } from "./step-container";

export const DnsEntryStep: FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
}) => {
  return (
    <>
      <StepContainer
        onMoveBackwardButtonClicked={() => {
          previousStep && previousStep();
        }}
        onMoveForwardButtonClicked={() => {
          nextStep && nextStep();
        }}
        onSkipLinkClicked={() => {
          nextStep && nextStep();
        }}
        videoDemoLink="https://drive.google.com/file/d/14Q0N9XZ9fyjtU2uf9BH2Q9b-OSGGQHRt/view?usp=sharing"
        stepByStepLink="https://scribehow.com/shared/DNS_Walkthrough__YUhGxAm4TaS5plZPPmdPFA"
      >
        <h3>Setup DNS Entry</h3>
        <hr />
        <p>
          Setting up a DNS entry improves our ability to track your customers
          when they return to your website after their first visit.
        </p>

        <DnsEntry />
      </StepContainer>
    </>
  );
};
