import { useUsableBenchmarkWindowsAtom } from "@/atoms/benchmarking-atom";
import { BenchmarkAttributionWindowOptions } from "@/constants/benchmarking";
import { usePrevious } from "@hooks/use-previous";
import { useEffect } from "react";
import { useUserBasedSetting } from "./use-user-based-setting";

export const useBenchmarkAttributionWindow = () => {
  return useUserBasedSetting(
    "benchmarkingCurrentAttributionWindow",
    BenchmarkAttributionWindowOptions[0],
  );
};

export const useShowBenchmarks = () => {
  const [collapsed, setCollapsed] = useUserBasedSetting(
    "benchmarkModuleIsCollapsed",
    false,
  );
  const [attributionWindow] = useBenchmarkAttributionWindow();
  const [usableAttributionWindowSet] = useUsableBenchmarkWindowsAtom();
  const [hasClosedForAvailableWindow, setHasClosedForAvailableWindow] =
    useUserBasedSetting(
      `hasClosedForAvailableWindow-${attributionWindow.value}`,
      false,
    );
  const previousIsClosed = usePrevious(collapsed);

  useEffect(() => {
    if (
      !previousIsClosed &&
      collapsed &&
      usableAttributionWindowSet?.has(attributionWindow.value) &&
      !hasClosedForAvailableWindow
    ) {
      setHasClosedForAvailableWindow(true);
    }
  }, [
    attributionWindow.value,
    hasClosedForAvailableWindow,
    collapsed,
    previousIsClosed,
    setHasClosedForAvailableWindow,
    usableAttributionWindowSet,
  ]);

  useEffect(() => {
    if (
      collapsed &&
      !hasClosedForAvailableWindow &&
      usableAttributionWindowSet?.has(attributionWindow.value)
    ) {
      setCollapsed(false);
    }
    // we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [collapsed, setCollapsed] as const;
};

export const useShowNBCalculatedBenchmarks = () => {
  return useUserBasedSetting("showNBCalculatedBenchmarks", false);
};
