import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { DateRangePicker } from "@components/date-range-picker";
import { BreakdownSelector } from "@components/reports/breakdown-selector";
import { useUser } from "@components/user-context";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import {
  createDataExportSalesMetric,
  createInitialMetrics,
} from "@features/data-export/utils";
import { Breakdown, FixedDateRange } from "@north-beam/nb-common";
import { useReportBodyState } from "@pages/sales/report-body-control";
import { Checkbox } from "@shared/checkbox";
import { ColumnConfig } from "@shared/metric-customization-modal/metric-category-builder";
import { Radio } from "@shared/radio-group";
import { usePrettyLogEventPage } from "@utils/analytics";
import {
  DateRangeChoiceEnum,
  DateRangeChoiceV2,
  IGranularity,
  IMCAttributionWindowDays,
  INBAccountingMode,
  MCAttributionWindowDaysChoicesDaily,
  NBAccountingMode,
} from "@utils/constants";
import { SalesPageMetricSpec } from "@utils/metrics";
import { AnimatePresence } from "framer-motion";
import { sortBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  DataExportSalesMetric,
  SalesMetricsConfig,
  SalesMetricsExportAggregation,
} from "../../types";
import { Accordion } from "./accordion";
import { AccordionWithCheckboxes } from "./accordion-with-checkboxes";
import { AccordionWithRadios } from "./accordion-with-radios";
import { MetricEditor } from "./metric-editor";
import { SelectedMetric } from "./selected-metric";

const LEVEL_OPTIONS: { id: IGranularity; label: string }[] = [
  { id: "platform", label: "Platform" },
  { id: "campaign", label: "Campaign" },
  { id: "adset", label: "Adset" },
  { id: "ad", label: "Ad" },
];

interface Props {
  salesPageMetricSpecs: SalesPageMetricSpec[];
  columnConfigs: ColumnConfig[];
  onChange(config: SalesMetricsConfig): void;
}

export function SalesMetricsConfigurator({
  salesPageMetricSpecs,
  columnConfigs,
  onChange,
}: Props) {
  const prettyLogEventPage = usePrettyLogEventPage();

  const { user } = useUser();
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();

  const sensors = useSensors(useSensor(PointerSensor));

  const {
    state,
    attributionModel,
    attributionWindow,
    nbAccountingMode,
    breakdowns,
    granularity,
    timeGranularity,
  } = useReportBodyState();

  const initialAttributionModelIds = [attributionModel];
  const initialAttributionWindowIds = [attributionWindow];
  const initialAccountingModeIds = [nbAccountingMode];

  const [timeFrame, setTimeFrame] = useState<
    DateRangeChoiceV2 | FixedDateRange
  >(state.dateRange);

  const [selectedMetrics, setSelectedMetrics] = useState<
    DataExportSalesMetric[]
  >(() =>
    createInitialMetrics(
      user.attributionMethodEnum.reactSelectOptions.map((e) => e.value),
      salesPageMetricSpecs,
      columnConfigs,
      initialAttributionModelIds,
      initialAttributionWindowIds,
      initialAccountingModeIds,
    ),
  );

  const [editedMetricId, setEditedMetricId] = useState<string | null>(null);
  const [selectedAttributionModelIds, setSelectedAttributionModelIds] =
    useState(() => new Set<string>(initialAttributionModelIds));
  const [selectedAttributionWindowIds, setSelectedAttributionWindowIds] =
    useState(
      () => new Set<IMCAttributionWindowDays>(initialAttributionWindowIds),
    );
  const [selectedAccountingModeIds, setSelectedAccountingModeIds] = useState(
    () => new Set<INBAccountingMode>(initialAccountingModeIds),
  );
  const [exportAggregation, setExportAggregation] =
    useState<SalesMetricsExportAggregation>("breakdown");
  const [removeZeroSpend, setRemoveZeroSpend] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(granularity);
  const [selectedBreakdowns, setSelectedBreakdowns] = useState<Breakdown[]>(
    () => breakdowns,
  );

  useEffect(() => {
    const defaultAttributionModels =
      user.attributionMethodEnum.reactSelectOptions
        .filter((e) => selectedAttributionModelIds.has(e.value))
        .map((e) => e.value);

    const defaultAttributionWindows =
      MCAttributionWindowDaysChoicesDaily.filter((e) =>
        selectedAttributionWindowIds.has(e.value),
      ).map((e) => e.value);

    const defaultAccountingModes = NBAccountingMode.reactSelectOptions
      .filter((e) => selectedAccountingModeIds.has(e.value))
      .map((e) => e.value);

    onChange({
      metrics: selectedMetrics,
      exportAggregation,
      removeZeroSpend,
      level: selectedLevel,
      breakdowns: selectedBreakdowns,
      defaultAttributionModels,
      defaultAttributionWindows,
      defaultAccountingModes,
      timeFrame,
      timeGranularity,
    });
  }, [
    onChange,
    user,
    selectedMetrics,
    exportAggregation,
    removeZeroSpend,
    selectedLevel,
    selectedBreakdowns,
    selectedAttributionModelIds,
    selectedAttributionWindowIds,
    selectedAccountingModeIds,
    timeFrame,
    timeGranularity,
  ]);

  const sortedSalesPageMetricSpecs = useMemo(
    () => sortBy(salesPageMetricSpecs, (e) => e.name.toLowerCase()),
    [salesPageMetricSpecs],
  );

  const defaultSalesPageMetricSpecs = useMemo(
    () => sortedSalesPageMetricSpecs.filter((e) => e.group === "default"),
    [sortedSalesPageMetricSpecs],
  );

  const subscriptionsSalesPageMetricSpecs = useMemo(
    () => sortedSalesPageMetricSpecs.filter((e) => e.group === "subscriptions"),
    [sortedSalesPageMetricSpecs],
  );

  const editedMetric = useMemo(
    () => selectedMetrics.find(({ id }) => id === editedMetricId),
    [selectedMetrics, editedMetricId],
  );

  const updateSelectedMetricsWithAttributionModelIds = (
    newSelectedAttributionModelIds: Set<string>,
  ) => {
    const newSelectedMetrics = selectedMetrics.map((metric) => {
      const metricSpec = salesPageMetricSpecs.find((e) => e.id === metric.id)!;
      const specAttributionModel = metricSpec.modifiers.attributionModel;

      if (specAttributionModel === "all") {
        const attributionModels = new Set<string>(
          newSelectedAttributionModelIds,
        );

        if (attributionModels.size === 0) {
          attributionModels.add(
            user.attributionMethodEnum.reactSelectOptions[0].value,
          );
        }

        return { ...metric, attributionModels };
      } else {
        return metric;
      }
    });

    setSelectedMetrics(newSelectedMetrics);
  };

  const updateSelectedMetricsWithAttributionWindowIds = (
    selectedAttributionWindowIds: Set<IMCAttributionWindowDays>,
  ) => {
    const newSelectedMetrics = selectedMetrics.map((metric) => {
      const metricSpec = salesPageMetricSpecs.find((e) => e.id === metric.id)!;
      const specAttributionWindow = metricSpec.modifiers.attributionWindows;

      if (specAttributionWindow.length > 1) {
        const intersection = new Set(
          [...specAttributionWindow].filter((e) =>
            selectedAttributionWindowIds.has(e),
          ),
        );

        if (intersection.size === 0) {
          intersection.add(specAttributionWindow[0]);
        }

        return { ...metric, attributionWindows: intersection };
      } else {
        return metric;
      }
    });

    setSelectedMetrics(newSelectedMetrics);
  };

  const updateSelectedMetricsWithAccountingModeIds = (
    selectedAccountingModeIds: Set<INBAccountingMode>,
  ) => {
    const newSelectedMetrics = selectedMetrics.map((metric) => {
      const metricSpec = salesPageMetricSpecs.find((e) => e.id === metric.id)!;
      const specAccountingModes = metricSpec.modifiers.accountingModes;

      if (specAccountingModes.length > 1) {
        const intersection = new Set(
          [...specAccountingModes].filter((e) =>
            selectedAccountingModeIds.has(e),
          ),
        );

        if (intersection.size === 0) {
          intersection.add(specAccountingModes[0]);
        }

        return { ...metric, accountingModes: intersection };
      } else {
        return metric;
      }
    });

    setSelectedMetrics(newSelectedMetrics);
  };

  const updateAccountingModes = (metrics: DataExportSalesMetric[]) => {
    const hasAccrualOnly = metrics.some((metric) => {
      const metricSpec = salesPageMetricSpecs.find((e) => e.id === metric.id)!;
      const accountingModes = metricSpec.modifiers.accountingModes;
      return accountingModes.length === 1 && accountingModes[0] === "accrual";
    });

    const hasCashOnly = metrics.some((metric) => {
      const metricSpec = salesPageMetricSpecs.find((e) => e.id === metric.id)!;
      const accountingModes = metricSpec.modifiers.accountingModes;
      return accountingModes.length === 1 && accountingModes[0] === "cash";
    });

    if (hasAccrualOnly || hasCashOnly) {
      const newVals = new Set(selectedAccountingModeIds);
      if (hasAccrualOnly) newVals.add("accrual");
      if (hasCashOnly) newVals.add("cash");

      setSelectedAccountingModeIds(newVals);
    }
  };

  const buildOptionsForMetricSection = (
    groupSalesPageMetricSpecs: SalesPageMetricSpec[],
  ) =>
    groupSalesPageMetricSpecs.map(({ id, name }) => {
      const isSelected = selectedMetrics.some((e) => e.id === id);

      return {
        id: id,
        label: name,
        isSelected,
        isDisabled: isSelected && selectedMetrics.length === 1,
      };
    });

  const changeSingleMetric = (val: boolean, id: string) => {
    prettyLogEventPage({
      domain: "DataExport",
      action: "onSelect",
      additionalActionInfo: "Metric changed",
    });

    if (val) {
      const metricSpec = salesPageMetricSpecs.find((e) => e.id === id)!;

      const newMetrics = [
        ...selectedMetrics,
        createDataExportSalesMetric(
          user.attributionMethodEnum.reactSelectOptions.map((e) => e.value),
          metricSpec,
          selectedAttributionModelIds,
          selectedAttributionWindowIds,
          selectedAccountingModeIds,
        ),
      ];

      setSelectedMetrics(newMetrics);
      updateAccountingModes(newMetrics);
    } else {
      setSelectedMetrics(selectedMetrics.filter((e) => e.id !== id));
    }
  };

  const changeAllMetrics = (
    val: boolean,
    groupSalesPageMetricSpecs: SalesPageMetricSpec[],
  ) => {
    prettyLogEventPage({
      domain: "DataExport",
      action: "onSelect",
      additionalActionInfo: "Metric all changed",
    });

    const createDefaultMetricSpec = (metricSpec: SalesPageMetricSpec) =>
      createDataExportSalesMetric(
        user.attributionMethodEnum.reactSelectOptions.map((e) => e.value),
        metricSpec,
        selectedAttributionModelIds,
        selectedAttributionWindowIds,
        selectedAccountingModeIds,
      );

    if (val) {
      const newMetrics = [...selectedMetrics];

      for (const metricSpec of groupSalesPageMetricSpecs) {
        if (!newMetrics.some((e) => e.id === metricSpec.id)) {
          newMetrics.push(createDefaultMetricSpec(metricSpec));
        }
      }

      setSelectedMetrics(newMetrics);
      updateAccountingModes(newMetrics);
    } else {
      const groupMetricSpecIds = groupSalesPageMetricSpecs.map((e) => e.id);

      setSelectedMetrics((prevVal) => {
        const newVal = prevVal.filter(
          (e) => !groupMetricSpecIds.includes(e.id),
        );

        if (newVal.length === 0) {
          newVal.push(createDefaultMetricSpec(sortedSalesPageMetricSpecs[0]));
        }

        return newVal;
      });
    }
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      prettyLogEventPage({
        domain: "DataExport",
        action: "Other",
        additionalActionInfo: "Metrics reordered",
      });

      const oldIndex = selectedMetrics.findIndex(({ id }) => id === active.id);
      const newIndex = selectedMetrics.findIndex(({ id }) => id === over?.id);

      const newOrdering = arrayMove(selectedMetrics, oldIndex, newIndex);
      setSelectedMetrics(newOrdering);
    }
  };

  return (
    <div className="h-full grid grid-cols-[14rem_auto_18rem] overflow-hidden relative">
      <AnimatePresence>
        {editedMetric && (
          <MetricEditor
            metricSpec={
              salesPageMetricSpecs.find((e) => e.id === editedMetric.id)!
            }
            metric={editedMetric}
            onUpdateMetric={(metric) => {
              prettyLogEventPage({
                domain: "DataExport",
                action: "Other",
                additionalActionInfo: "Metric updated",
              });

              setSelectedMetrics(
                selectedMetrics.map((e) => {
                  if (e.id === metric.id) {
                    return { ...metric };
                  } else {
                    return e;
                  }
                }),
              );
            }}
            onClose={() => {
              prettyLogEventPage({
                domain: "DataExport",
                action: "onClose",
                additionalActionInfo: "Edit metric panel closed",
              });

              setEditedMetricId(null);
            }}
          />
        )}
      </AnimatePresence>

      <div className="bg-[#F5F7FDB2] pl-7 pr-7 pt-5 pb-5">
        {/* <div className="mb-10">
          <h3 className="text-base font-semibold m-0 mb-3">Saved exports</h3>
          <div className="font-normal text-[0.65rem] text-gray-600 mb-1">
            Export Name
          </div>
          <ReactSelect
            components={{
              DropdownIndicator: (props) => (
                <components.DropdownIndicator {...props}>
                  <i className="fa-solid fa-chevron-down text-[0.6rem] text-gray-500 px-2" />
                </components.DropdownIndicator>
              ),
            }}
            classNames={{
              control: () =>
                "shadow-none rounded-md text-[0.6rem] min-h-[30px]",
              indicatorSeparator: () => "hidden",
              dropdownIndicator: () => "p-0",
              input: () => "text-gray-500 leading-3",
            }}
            value="No exports to select"
            inputValue="No exports to select"
          />
        </div> */}

        <div>
          <h3 className="text-base font-semibold m-0 mb-3">
            Select time frame
          </h3>
          <div className="font-normal text-[0.65rem] text-gray-600 mb-1">
            Time Frame
          </div>
          <DateRangePicker<DateRangeChoiceV2>
            className="data-export-date-picker"
            dropdownIconClassName="fa-calendar text-gray-500 text-xs leading-5"
            disabled={false}
            nonCustomChoices={DateRangeChoiceEnum.reactSelectOptions}
            current={
              (typeof timeFrame === "string"
                ? DateRangeChoiceEnum.toReactSelect(timeFrame)
                : timeFrame)!
            }
            onUpdate={(dateRange) => {
              prettyLogEventPage({
                domain: "DataExport",
                action: "onSelect",
                additionalActionInfo: "Time Frame changed",
              });

              const value =
                "value" in dateRange
                  ? (dateRange.value as DateRangeChoiceV2)
                  : dateRange;

              setTimeFrame(value);
            }}
            timezone={user.timezone}
            maxLookbackDays={20 * 365}
          />
        </div>
      </div>

      <div className="py-4 overflow-y-scroll">
        <AccordionWithCheckboxes<string>
          title="Select metrics"
          options={buildOptionsForMetricSection(defaultSalesPageMetricSpecs)}
          isExpandedByDefault={true}
          onChange={(id, val) => changeSingleMetric(val, id)}
          onChangeAll={(val) =>
            changeAllMetrics(val, defaultSalesPageMetricSpecs)
          }
        />

        <AccordionWithCheckboxes<string>
          title="Subscription metrics"
          options={buildOptionsForMetricSection(
            subscriptionsSalesPageMetricSpecs,
          )}
          onChange={(id, val) => changeSingleMetric(val, id)}
          onChangeAll={(val) =>
            changeAllMetrics(val, subscriptionsSalesPageMetricSpecs)
          }
        />

        <AccordionWithCheckboxes<string>
          title="Attribution model"
          options={user.attributionMethodEnum.reactSelectOptions.map(
            ({ value, label }) => {
              const isSelected = selectedAttributionModelIds.has(value);

              return {
                id: value,
                label,
                isSelected,
                isDisabled:
                  isSelected && selectedAttributionModelIds.size === 1,
              };
            },
          )}
          onChange={(id, val) => {
            prettyLogEventPage({
              domain: "DataExport",
              action: "onSelect",
              additionalActionInfo: "Attribution method changed",
            });

            val
              ? selectedAttributionModelIds.add(id)
              : selectedAttributionModelIds.delete(id);

            const newSelectedAttributionModelIds = new Set(
              selectedAttributionModelIds,
            );
            setSelectedAttributionModelIds(
              new Set(newSelectedAttributionModelIds),
            );
            updateSelectedMetricsWithAttributionModelIds(
              newSelectedAttributionModelIds,
            );
          }}
          onChangeAll={(val) => {
            prettyLogEventPage({
              domain: "DataExport",
              action: "onSelect",
              additionalActionInfo: "Attribution method all changed",
            });

            for (const { value } of [
              ...user.attributionMethodEnum.reactSelectOptions,
            ].reverse()) {
              if (val) {
                selectedAttributionModelIds.add(value);
              } else if (selectedAttributionModelIds.size > 1) {
                selectedAttributionModelIds.delete(value);
              }
            }

            const newSelectedAttributionModelIds = new Set(
              selectedAttributionModelIds,
            );
            setSelectedAttributionModelIds(
              new Set(newSelectedAttributionModelIds),
            );
            updateSelectedMetricsWithAttributionModelIds(
              newSelectedAttributionModelIds,
            );
          }}
        />

        <AccordionWithCheckboxes<IMCAttributionWindowDays>
          title="Attribution window"
          options={MCAttributionWindowDaysChoicesDaily.map(
            ({ value, label }) => {
              const isSelected = selectedAttributionWindowIds.has(value);

              return {
                id: value,
                label,
                isSelected,
                isDisabled:
                  isSelected && selectedAttributionWindowIds.size === 1,
              };
            },
          )}
          onChange={(id, val) => {
            prettyLogEventPage({
              domain: "DataExport",
              action: "onSelect",
              additionalActionInfo: "Attribution window changed",
            });

            val
              ? selectedAttributionWindowIds.add(id)
              : selectedAttributionWindowIds.delete(id);

            const newSelectedAttributionWindowIds = new Set(
              selectedAttributionWindowIds,
            );
            setSelectedAttributionWindowIds(newSelectedAttributionWindowIds);
            updateSelectedMetricsWithAttributionWindowIds(
              newSelectedAttributionWindowIds,
            );
          }}
          onChangeAll={(val) => {
            prettyLogEventPage({
              domain: "DataExport",
              action: "onSelect",
              additionalActionInfo: "Attribution window all changed",
            });

            for (const { value } of [
              ...MCAttributionWindowDaysChoicesDaily,
            ].reverse()) {
              if (val) {
                selectedAttributionWindowIds.add(value);
              } else if (selectedAttributionWindowIds.size > 1) {
                selectedAttributionWindowIds.delete(value);
              }
            }

            const newSelectedAttributionWindowIds = new Set(
              selectedAttributionWindowIds,
            );
            setSelectedAttributionWindowIds(newSelectedAttributionWindowIds);
            updateSelectedMetricsWithAttributionWindowIds(
              newSelectedAttributionWindowIds,
            );
          }}
        />

        <AccordionWithCheckboxes<INBAccountingMode>
          title="Accounting mode"
          options={NBAccountingMode.reactSelectOptions.map(
            ({ value, label }) => {
              const isSelected = selectedAccountingModeIds.has(value);

              return {
                id: value,
                label,
                isSelected,
                isDisabled: isSelected && selectedAccountingModeIds.size === 1,
              };
            },
          )}
          onChange={(id, val) => {
            prettyLogEventPage({
              domain: "DataExport",
              action: "onSelect",
              additionalActionInfo: "Accounting mode changed",
            });

            val
              ? selectedAccountingModeIds.add(id)
              : selectedAccountingModeIds.delete(id);

            const newSelectedAccountingModeIds = new Set(
              selectedAccountingModeIds,
            );
            setSelectedAccountingModeIds(newSelectedAccountingModeIds);
            updateSelectedMetricsWithAccountingModeIds(
              newSelectedAccountingModeIds,
            );
          }}
          onChangeAll={(val) => {
            prettyLogEventPage({
              domain: "DataExport",
              action: "onSelect",
              additionalActionInfo: "Accounting mode all changed",
            });

            for (const { value } of [
              ...NBAccountingMode.reactSelectOptions,
            ].reverse()) {
              if (val) {
                selectedAccountingModeIds.add(value);
              } else if (selectedAccountingModeIds.size > 1) {
                selectedAccountingModeIds.delete(value);
              }
            }

            const newSelectedAccountingModeIds = new Set(
              selectedAccountingModeIds,
            );
            setSelectedAccountingModeIds(newSelectedAccountingModeIds);
            updateSelectedMetricsWithAccountingModeIds(
              newSelectedAccountingModeIds,
            );
          }}
        />

        <AccordionWithRadios<IGranularity>
          title="Level"
          options={LEVEL_OPTIONS.map((e) => ({
            ...e,
            isSelected: selectedLevel === e.id,
          }))}
          onChange={(val) => {
            prettyLogEventPage({
              domain: "DataExport",
              action: "onSelect",
              additionalActionInfo: "Level changed",
            });

            setSelectedLevel(val);
          }}
        />

        <Accordion title="Breakdown by">
          <p className="text-xs text-zinc-500 font-light m-0">
            Add your Northbeam breakdown values:
          </p>

          <div className="mt-4 pb-[220px] w-full xl:w-3/4">
            <BreakdownSelector
              disabled={false}
              current={selectedBreakdowns}
              available={breakdownConfigs}
              allConfigs={breakdownConfigs}
              menuListStyles={{ maxHeight: "200px" }}
              tabIndex={"0"}
              onUpdate={(values) => {
                prettyLogEventPage({
                  domain: "DataExport",
                  action: "onSelect",
                  additionalActionInfo: "Breakdown changed",
                });

                setSelectedBreakdowns(values);
              }}
            />
          </div>
        </Accordion>

        <Accordion title="Data export options">
          <p className="text-xs text-zinc-500 font-light mb-5">
            Select from any of the following extra options:
          </p>

          <div className="flex flex-wrap text-[12px] font-light gap-y-4 mb-4">
            <div className="flex-1 min-w-[13rem]">
              <Radio
                label="Export aggregated data"
                value={"breakdown"}
                checked={exportAggregation === "breakdown"}
                onChange={(val) => {
                  prettyLogEventPage({
                    domain: "DataExport",
                    action: "onSelect",
                    additionalActionInfo: "Export aggregation changed",
                  });

                  setExportAggregation(val);
                }}
                labelClassName="cursor-pointer"
                inputClassName="cursor-pointer h-3 w-3"
              />
            </div>

            <div className="flex-1 min-w-[13rem]">
              <Radio
                label="Export data broken down by day"
                value={"date"}
                checked={exportAggregation === "date"}
                onChange={(val) => {
                  prettyLogEventPage({
                    domain: "DataExport",
                    action: "onSelect",
                    additionalActionInfo: "Export aggregation changed",
                  });

                  setExportAggregation(val);
                }}
                labelClassName="cursor-pointer"
                inputClassName="cursor-pointer h-3 w-3"
              />
            </div>
          </div>

          <div className="text-[12px] font-light mb-4">
            <Checkbox
              inputClassName="w-3 h-3 mr-0.5"
              value={removeZeroSpend}
              onChange={(val) => {
                prettyLogEventPage({
                  domain: "DataExport",
                  action: "onSelect",
                  additionalActionInfo: "Remove 0 spend changed",
                });

                setRemoveZeroSpend(val);
              }}
              label="Remove $0 spend / 0 transactions items"
            />
          </div>
        </Accordion>
      </div>

      <div
        className="h-full border-l border-gray-100 overflow-hidden
                   grid grid-rows-[6.5rem_auto] bg-white z-40"
      >
        <div className="pl-7 pr-7 pt-5">
          <h3 className="text-lg font-semibold m-0 mb-2">Metrics</h3>
          <p className="text-xs text-zinc-500 font-light m-0">
            Edit attribution properties per each metric
          </p>
        </div>

        <div className="space-y-3 overflow-y-auto pl-7 pr-7 pb-5">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[
              restrictToVerticalAxis,
              restrictToFirstScrollableAncestor,
            ]}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={selectedMetrics.map(({ id }) => id)}>
              {selectedMetrics.map((e) => (
                <SelectedMetric
                  key={e.id}
                  metric={e}
                  isEdited={e.id === editedMetricId}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </div>
  );
}
