import _ from "lodash";
import pluralize from "pluralize";
import { Range } from "rc-slider";
import React from "react";

export interface NumberInterval {
  minimum: number;
  maximum: number;
}

export interface NumberRangePickerProps {
  disabled: boolean;
  bounds: NumberInterval;
  value: NumberInterval;
  noun: string;
  onUpdate: (mi: NumberInterval) => void;
}

export function NumberRangePicker(props: NumberRangePickerProps) {
  const { disabled, value, bounds, noun, onUpdate } = props;

  const min = bounds.minimum;
  const max = bounds.maximum;

  const lo = value.minimum;
  const hi = value.maximum;

  const onChange = React.useCallback(
    (value: number[]) => {
      const [lo, hi] = _.sortBy(value);
      onUpdate({
        minimum: lo,
        maximum: hi,
      });
    },
    [onUpdate],
  );

  return (
    <div className="nb-month-interval-picker">
      <Range
        min={min}
        max={max}
        disabled={disabled}
        value={[lo, hi]}
        onChange={onChange}
      />
      <div className="text-center">
        {`${value.minimum} — ${value.maximum} ${pluralize(noun)}`}
      </div>
    </div>
  );
}
