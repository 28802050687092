import { Modal } from "@components/shared/modals/modalV2";
import { CategoriesPanel } from "@pages/sales/tab-table-section/table/customize-table-modal/catgories-panel";
import { SecondaryButton } from "@shared/buttons";
import { MetricVisibilityPanel } from "@shared/metric-customization-modal/metric-visibility-panel";
import Interweave from "interweave";
import { cloneDeep, filter, findIndex, findLastIndex } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  CategoryGroupedConfig,
  createMetricCategories,
  MetricCategory,
  MetricConfig,
} from "./metric-category-builder";
import { ReorderPanel } from "./reorder-panel";

interface MetricCustomizationModalProps<T extends MetricConfig> {
  metrics: T[];
  onSave: (arg0: T[]) => void;
  onCancel?: () => void;
  title: string;
}

const DEFAULT_METRIC = "Spend";

export const MetricCustomizationModal = <T extends MetricConfig>({
  metrics,
  onSave,
  onCancel: _onCancel,
  title,
}: MetricCustomizationModalProps<T>) => {
  const [customizationModalOpen, setCustomizationModalOpen] = useState(false);
  const [configsCopy, setConfigsCopy] = useState<T[]>([]);
  const { addToast } = useToasts();
  const [selectedCategory, setSelectedCategory] = useState<MetricCategory>(
    MetricCategory.NORTHBEAM,
  );

  const showMustHaveOneMetricToast = () => {
    addToast(
      <div>
        <Interweave content="Must have at least one metric." />
      </div>,
      { appearance: "warning", autoDismiss: true },
    );
  };

  useEffect(() => {
    setConfigsCopy(metrics);
  }, [metrics]);

  const visibleMetrics = useMemo(
    () => configsCopy.filter(({ visible }) => visible),
    [configsCopy],
  );

  const toggleVisibility = (metricToToggle: T) => {
    if (visibleMetrics.length === 1 && metricToToggle.visible) {
      showMustHaveOneMetricToast();
    } else {
      setConfigsCopy((prevConfigsCopy) => {
        const metricIndex = findIndex(prevConfigsCopy, [
          "name",
          metricToToggle.name,
        ]);
        const [metric] = prevConfigsCopy.splice(metricIndex, 1);
        const lastVisibleMetricIndex = findLastIndex(
          prevConfigsCopy,
          "visible",
        );
        prevConfigsCopy.splice(lastVisibleMetricIndex + 1, 0, {
          ...metric,
          visible: !metric.visible,
        });
        return cloneDeep(prevConfigsCopy);
      });
    }
  };

  const toggleAllVisibility = (metrics: T[], visible: boolean) => {
    const metricNames = metrics.map((metric) => metric.name);
    setConfigsCopy((prevConfigsCopy) => {
      const toggledVisibilityConfigs = prevConfigsCopy.map((metric) => {
        if (metricNames.includes(metric.name)) {
          return { ...metric, visible };
        }
        return metric;
      });

      const visibleCount = filter(toggledVisibilityConfigs, "visible");
      if (visibleCount.length === 0) {
        showMustHaveOneMetricToast();
        return toggledVisibilityConfigs.map((metric) => {
          if (metric.name === DEFAULT_METRIC) {
            return { ...metric, visible: true };
          }
          return metric;
        });
      }
      return toggledVisibilityConfigs;
    });
  };

  const reorderVisibleMetrics = (newOrder: T[]) => {
    setConfigsCopy((prevConfigsCopy) => {
      const configsWithoutNewOrder = prevConfigsCopy.filter(({ name }) => {
        return !newOrder.map((metric) => metric.name).includes(name);
      });
      return [...newOrder, ...configsWithoutNewOrder];
    });
  };

  const categories = useMemo<CategoryGroupedConfig<T>>(
    () => createMetricCategories(configsCopy),
    [configsCopy],
  );

  const onCancel = () => {
    _onCancel?.();
    setCustomizationModalOpen(false);
  };

  return (
    <div>
      <SecondaryButton onClick={() => setCustomizationModalOpen(true)}>
        Customize
      </SecondaryButton>

      <Modal
        isOpen={customizationModalOpen}
        widthClassname="w-[80rem]"
        withOverflow={false}
        closeButton
        closeModal={onCancel}
        testId="metric-customization-modal"
      >
        <div className="grid grid-rows-[auto_61vh_auto] p-7">
          <h2 className="font-bold">{title}</h2>
          <div className="grid grid-cols-[19%_50%_29%] gap-2 overflow-y-hidden">
            <CategoriesPanel
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            <MetricVisibilityPanel
              visibilityOfMetrics={categories[selectedCategory]}
              toggleMetricVisibility={toggleVisibility}
              toggleVisibilityOfAllMetrics={toggleAllVisibility}
            />
            <ReorderPanel
              reorderVisibleMetrics={reorderVisibleMetrics}
              visibleMetrics={visibleMetrics}
            />
          </div>
          <div className="mt-4">
            <button
              className="btn btn-primary mr-3"
              onClick={() => {
                onSave(configsCopy);
                setCustomizationModalOpen(false);
              }}
            >
              Apply
            </button>
            <button className="btn" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
