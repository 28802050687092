import { BootstrapDropdownButton } from "@components/bootstrap-dropdown-button";
import ChangeFractionDisplay from "@components/change-fraction";
import { User, useUser } from "@components/user-context";
import {
  DismissIncident,
  DismissIncidentVariables,
} from "@nb-api-graphql-generated/DismissIncident";
import {
  SnoozeIncident,
  SnoozeIncidentVariables,
} from "@nb-api-graphql-generated/SnoozeIncident";
import {
  ActionItemsConfig,
  AlarmConditionV2,
  AlertPreviewRowV2,
  SimpleAlarmConditionResultRow,
  SimpleAlarmConditionV2,
  SubjectItem,
  TriggerConditionsV2,
} from "@north-beam/nb-common";
import { Breakdown } from "@north-beam/nb-common";
import { MetricFormat } from "@north-beam/nb-common";
import {
  DateInterval,
  dateRangeLength,
  FixedDateRange,
  formatDate,
  formatNumberExact,
  jsonHash,
  shiftISODate,
  slugify,
  todayLocalAsISODate,
} from "@north-beam/nb-common";
import { makeReportStateQuery as makeReportStateQueryAdObject } from "@pages/objects/report-state";
import {
  AdIcon,
  NB_MONITOR_SUBJECTITEM_ALL,
  NB_MONITOR_SUBJECTITEM_GROUPED,
} from "@pages/objects/utils";
import { makeReportBodyStateQuery as makeReportStateQuerySales } from "@pages/sales/report-body-control";
import { logEvent } from "@utils/analytics";
import { useNorthbeamMutation } from "@utils/hooks";
import classNames from "classnames";
import Interweave from "interweave";
import React from "react";
import { Link } from "react-router-dom";
import { DISMISS_INCIDENT, SNOOZE_INCIDENT } from "../queries";
import { flattenAlarmCondition, flattenResultRows } from "./utils";

export function CollapsibleIncidentPreview({
  data,
}: {
  data: ProtocolIncident;
}) {
  const { user } = useUser();
  const [expanded, setExpanded] = React.useState(false);

  const metricHeaders: MetricDetails[] | undefined = React.useMemo(() => {
    const conditions = flattenAlarmCondition(data.latestPoint.alarmCondition);
    const resultRows = flattenResultRows(data.latestPoint.previewRow.resultRow);

    const rv: MetricDetails[] = [];
    for (let i = 0; i < conditions.length; ++i) {
      rv.push(formMetricHeader(resultRows[i], conditions[i]));
    }

    if (!rv.some((h) => h.name === "Spend")) {
      rv.push({
        name: "Spend",
        id: "spend",
        format: "dollars",
        value: data.latestPoint.previewRow.averageDailySpend,
        referenceValue: null,
        isReferenceValueFixed: true,
        windowSizeDays: conditions[0].currentValue.windowEndOffsetDays,
      });
    }

    return rv;
  }, [data]);

  const showSnoozeButton = data.status !== "closed";
  const showDismissButtons = data.status !== "closed";

  const rightText: string = formTimeContextText(data);

  const iconPlatformName = formIconPlatformName(data.subjectItem.key);

  return (
    <div className="nb-incident-object border rounded my-3">
      <div
        className={classNames("nb-incident-row nb-incident-top-row", {
          "border-bottom": expanded,
        })}
        onClick={() => {
          if (!expanded) {
            logEvent("Expands incident accordion", {
              alertConfigId: data.config.id,
              subjectItem: data.subjectItem.key,
              startAt: data.startAt,
            });
          }
          setExpanded(!expanded);
        }}
      >
        <div className="nb-icon-section">
          <i
            className={classNames({
              fas: true,
              "fa-angle-up": expanded,
              "fa-angle-down": !expanded,
            })}
          ></i>
        </div>
        <div className="nb-title-section">
          <div className="flex items-center flex-grow-1">
            <AdIcon sizeInEM={1} platform={iconPlatformName} />{" "}
            <b>{data.subjectItem.name}</b>
          </div>
          <div className="text-nowrap">
            <span className="text-muted">{rightText}</span>
          </div>
        </div>
      </div>
      <div className={classNames("nb-incident-row", { "d-none": !expanded })}>
        <div className="nb-icon-section"></div>
        <div className="nb-details-section">
          <div className="my-3 text-muted">
            Triggered by:{" "}
            <Link to={user.pathFromRoot(`/monitor/view/${data.config.id}`)}>
              {data.config.name}
            </Link>
          </div>
          <div>
            {data.description ? (
              <Interweave content={data.description} />
            ) : (
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                mattis, turpis nec fermentum finibus, nisl tellus luctus ipsum,
                eget tincidunt justo felis in lacus. Integer elementum auctor
                venenatis. Morbi porta auctor felis, dignissim facilisis erat
                pharetra eu. Aenean iaculis odio sed eleifend sodales. Phasellus
                porttitor arcu rutrum ultrices interdum.
              </p>
            )}
          </div>
          <div className="flex flex-row my-4">
            {metricHeaders.map((metricHeader) => (
              <div className="pr-5" key={jsonHash(metricHeader)}>
                <MetricDetailsDisplay {...metricHeader} />
              </div>
            ))}
          </div>
          <div className="my-3">
            <div className="mr-2 d-inline-block">
              <Link
                className="btn btn-outline-primary"
                to={user.pathFromRoot(
                  `/monitor/view/${data.config.id}/incidents/${slugify(
                    data.subjectItem.key,
                  )}/${data.startAt}`,
                )}
              >
                See more details
              </Link>
            </div>
            {showSnoozeButton && (
              <div className="mr-2 d-inline-block">
                <SnoozeButton
                  alertConfigId={data.config.id}
                  subjectItem={data.subjectItem.key}
                  startAt={data.startAt}
                  ignoredUntil={data.ignoredUntil}
                />
              </div>
            )}
            {showDismissButtons && (
              <div className="mr-2 d-inline-block">
                <DismissButton
                  alertConfigId={data.config.id}
                  subjectItem={data.subjectItem.key}
                  startAt={data.startAt}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function MetricDetailsDisplay({
  name,
  value,
  referenceValue,
  isReferenceValueFixed,
  format,
  windowSizeDays,
}: MetricDetails) {
  let changeFraction = <>&nbsp;</>;
  if (referenceValue !== null && !isReferenceValueFixed) {
    const fraction = (value - referenceValue) / referenceValue;
    changeFraction = (
      <ChangeFractionDisplay
        changeFraction={fraction}
        isPositiveChangeGood={true}
      />
    );
  }

  return (
    <div className="nb-result-row-display">
      <div className="text-muted">
        <small>{name}</small>
      </div>
      <div className="flex">
        <div className="flex-grow-1">
          <b>{formatNumberExact(value, format)}</b>
        </div>
        <div className="ml-3">{changeFraction}</div>
      </div>
      <div className="text-muted">
        <small>daily average over last {windowSizeDays} days</small>
      </div>
    </div>
  );
}

export function SnoozeButton({
  alertConfigId,
  subjectItem,
  startAt,
  ignoredUntil,
}: {
  alertConfigId: string;
  subjectItem: SubjectItem;
  startAt: string;
  ignoredUntil: string | null;
}) {
  const today = todayLocalAsISODate();

  const labels = React.useMemo(() => {
    const twoDays = shiftISODate(today, 2);
    const threeDays = shiftISODate(today, 3);
    const oneWeek = shiftISODate(today, 7);
    return [
      { value: twoDays, label: `Until ${formatDate(twoDays)} (2 days)` },
      { value: threeDays, label: `Until ${formatDate(threeDays)} (3 days)` },
      { value: oneWeek, label: `Until ${formatDate(oneWeek)} (7 days)` },
    ];
  }, [today]);

  const text =
    ignoredUntil === null || ignoredUntil <= today
      ? "Snooze"
      : `Snoozed until ${formatDate(ignoredUntil)} (click to change)`;

  const [snoozeIncident, { loading }] = useNorthbeamMutation<
    SnoozeIncident,
    SnoozeIncidentVariables
  >(SNOOZE_INCIDENT);

  const callback = React.useCallback(
    (value: string) => {
      logEvent("Snoozes incident", {
        alertConfigId,
        subjectItem,
        startAt,
        numDays:
          dateRangeLength({
            startDate: today,
            endDate: value,
          }) - 1,
      });
      snoozeIncident({
        variables: {
          alertConfigId,
          subjectItem,
          startAt,
          snoozeUntil: value,
        },
      });
    },
    [alertConfigId, subjectItem, startAt, snoozeIncident, today],
  );

  return (
    <BootstrapDropdownButton
      buttonText={
        <>
          {text} <i className="fas fa-caret-down"></i>
        </>
      }
      disabled={loading}
      buttonClassName="btn btn-outline-secondary"
      choices={labels}
      onSelected={callback}
    />
  );
}

export function DismissButton({
  alertConfigId,
  subjectItem,
  startAt,
}: {
  alertConfigId: string;
  subjectItem: SubjectItem;
  startAt: string;
}) {
  const labels = React.useMemo(
    () => [
      { value: "dismiss", label: "Dismiss" },
      {
        value: "exclude",
        label: `Do not notify me about this subject again`,
      },
    ],
    [],
  );

  const [dismissIncident, { loading }] = useNorthbeamMutation<
    DismissIncident,
    DismissIncidentVariables
  >(DISMISS_INCIDENT);

  const callback = React.useCallback(
    (resolutionStatus: string) => {
      logEvent("Closed incident", {
        alertConfigId,
        subjectItem,
        startAt,
        resolutionStatus,
      });
      dismissIncident({
        variables: {
          alertConfigId,
          subjectItem,
          startAt,
          resolutionStatus,
        },
      });
    },
    [alertConfigId, subjectItem, startAt, dismissIncident],
  );

  return (
    <BootstrapDropdownButton
      buttonText={
        <>
          Close <i className="fas fa-caret-down"></i>
        </>
      }
      disabled={loading}
      buttonClassName="btn btn-outline-secondary"
      choices={labels}
      onSelected={callback}
    />
  );
}

export interface ClickToExpandSectionProps {
  alertConfigId: string;
  subjectItem: SubjectItem;
  startAt: string;
  clickToExpandMoreHTML: string;
}

export function ClickToExpandSection({
  alertConfigId,
  subjectItem,
  startAt,
  clickToExpandMoreHTML,
}: ClickToExpandSectionProps) {
  const [expanded, setExpanded] = React.useState(false);

  if (!expanded) {
    return (
      <button
        className="btn btn-link btn-sm p-0"
        onClick={() => {
          logEvent("Alert Incident content expansion click", {
            alertConfigId,
            subjectItem,
            startAt,
          });
          setExpanded(true);
        }}
      >
        What does this mean? (Click to read more){" "}
        <i className="fas fa-angle-double-right"></i>
      </button>
    );
  }

  return <Interweave content={clickToExpandMoreHTML} />;
}

export interface MetricDetails {
  id: string;
  name: string;
  format: MetricFormat;
  value: number;
  referenceValue: number | null;
  isReferenceValueFixed: boolean;
  windowSizeDays: number;
}

export function formMetricHeader(
  result: SimpleAlarmConditionResultRow,
  condition: SimpleAlarmConditionV2,
): MetricDetails {
  return {
    id: result.metric.id,
    name: result.metric.name,
    format: result.metric.format,
    value: Number(result.currentValueComputed),
    referenceValue: Number(result.referenceValueComputed),
    isReferenceValueFixed: condition.referenceValue.type === "FixedValueConfig",
    windowSizeDays: condition.currentValue.windowEndOffsetDays,
  };
}

interface ProtocolSubjectItem {
  id: string;
  key: SubjectItem;
  name: string;
}

interface ProtocolAlertConfig {
  id: string;
  name: string;
  triggerConditions: TriggerConditionsV2;
}

interface ProtocolIncidentLatestPoint {
  id: string;
  previewRow: AlertPreviewRowV2;
  alarmCondition: AlarmConditionV2;
}

export interface ProtocolIncident {
  id: string;
  subjectItem: ProtocolSubjectItem;
  status: string;
  startAt: string;
  endAt: string | null;
  ignoredUntil: string | null;
  description: string;
  config: ProtocolAlertConfig;
  latestPoint: ProtocolIncidentLatestPoint;
  actionItemsConfig: ActionItemsConfig;
}

export function formAttributionMethodFromMetricIds(
  metricIds: string[],
): string {
  let attributionMethod: any = "platform";
  for (const metricId of metricIds) {
    if (metricId.match(/^ga[A-Z]/)) {
      attributionMethod = "last_touch";
    } else {
      // See nb-api code to see how this is formed
      // key-metrics.tsx
      const parts = metricId.split(":");
      if (parts[1] === "northbeam") {
        if (parts[2]) {
          attributionMethod = parts[2];
        } else if (attributionMethod !== "platform") {
          attributionMethod = "northbeam_custom";
        }
      }
    }
  }

  return attributionMethod;
}

export function formLinkForSubjectItem(
  user: User,
  alarmCondition: AlarmConditionV2,
  item: SubjectItem,
  dr: DateInterval,
): string {
  const conds = flattenAlarmCondition(alarmCondition);
  const metricIds = conds.map((cond) => cond.metric.id);

  const attributionModel = formAttributionMethodFromMetricIds(metricIds);

  const breakdowns: Breakdown[] = [];
  if (item.type === "grouped") {
    for (const { key, value } of item.groups) {
      breakdowns.push({ key, values: [value] });
    }
  }

  const dateRange: FixedDateRange = {
    type: "fixed",
    ...dr,
  };

  if (item.type === "individual") {
    const search = makeReportStateQueryAdObject({
      dateRange,
      attributionModel,
      attributionWindow: "1",
      nbAccountingMode: "accrual",
      compareDateRange: "lastPeriod",
      timeGranularity: "daily",
    }).toString();
    return user.pathFromRoot(`/objects/${slugify(item.ad_key)}?${search}`);
  } else {
    const params = makeReportStateQuerySales({
      attributionModel: attributionModel,
      attributionWindow: "1",
      nbAccountingMode: "accrual",
      columnSet: "overview",
      breakdowns,
      granularity: "campaign",
      timeGranularity: "daily",
      dateRange,
      compareDateRange: "lastPeriod",
    });
    return user.pathFromRoot(`/sales?${params.toString()}`);
  }
}

export function computeDateRangeForIncident(
  incident: ProtocolIncident,
): DateInterval {
  const conditions = flattenAlarmCondition(incident.latestPoint.alarmCondition);
  const endDate = shiftISODate(incident.endAt ?? todayLocalAsISODate(), -1);
  const lookback = Math.max(
    ...conditions.flatMap((v) => {
      const rv = [v.currentValue.windowEndOffsetDays];
      if (v.referenceValue.type === "AveragedValueConfig") {
        rv.push(v.referenceValue.windowEndOffsetDays);
        rv.push(v.referenceValue.windowStartOffsetDays);
      }
      return rv;
    }),
  );
  const startDate = shiftISODate(incident.startAt, -lookback);
  return { startDate, endDate };
}

export function formIconPlatformName(subjectItem: SubjectItem) {
  return subjectItem.type === "grouped"
    ? NB_MONITOR_SUBJECTITEM_GROUPED
    : subjectItem.type === "all"
    ? NB_MONITOR_SUBJECTITEM_ALL
    : subjectItem.ad_key.platform;
}

export function formTimeContextText(data: ProtocolIncident) {
  if (data.endAt) {
    const endedDaysAgoNumber =
      dateRangeLength({
        startDate: data.endAt,
        endDate: todayLocalAsISODate(),
      }) - 1;

    return endedDaysAgoNumber === 0
      ? "Ended today"
      : endedDaysAgoNumber === 1
      ? "Ended 1 day ago"
      : `Ended ${endedDaysAgoNumber} days ago`;
  } else {
    const startedDaysAgoNumber =
      dateRangeLength({
        startDate: data.startAt,
        endDate: todayLocalAsISODate(),
      }) - 1;

    return startedDaysAgoNumber === 0
      ? "Opened today"
      : startedDaysAgoNumber === 1
      ? "Opened 1 day ago"
      : `Opened ${startedDaysAgoNumber} days ago`;
  }
}
