import classNames from "classnames";
import React, { ReactNode, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import Icon from "./icons";

export const Button = ({
  className = null,
  kind = "primary",
  outline = false,
  children = null,
  ...props
}: {
  className?: string | null;
  kind?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link";
  outline?: boolean;
  children?: React.ReactNode;
  [key: string]: any;
}) => {
  return (
    <button
      className={classNames(
        "btn",
        `btn${outline ? "-outline" : ""}-${kind}`,
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export const CopyButton = ({
  valueToCopy,
  noOutline = false,
  children,
  ...props
}: {
  valueToCopy: string;
  noOutline?: boolean;
  children?: ReactNode;
  [key: string]: any;
}) => {
  const { addToast } = useToasts();

  const copy = useCallback(() => {
    window.navigator.clipboard.writeText(valueToCopy);
    addToast("Copied to clipboard!", { appearance: "success" });
  }, [valueToCopy, addToast]);

  return (
    <Button outline={!noOutline} onClick={copy} {...props}>
      <Icon type="copy" className="mr-2" />
      {children ? children : "Copy"}
    </Button>
  );
};
