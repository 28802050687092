import { gql } from "@apollo/client";
import { GenericExternalLink } from "@components/generic-external-link";
import { LoadingSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import { CreateAPIKey } from "@nb-api-graphql-generated/CreateAPIKey";
import { FetchApiKeys as FetchApiKeysQuery } from "@nb-api-graphql-generated/FetchApiKeys";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect, useState } from "react";

export const ApiKeys = () => {
  const { user } = useUser();
  const [apiKeys, setApiKeys] = useState<string[]>([]);

  const { loading, data } =
    useNorthbeamQuery<FetchApiKeysQuery>(FETCH_API_KEYS);

  useEffect(() => {
    setApiKeys(data?.me?.apiKeys || []);
  }, [data]);

  const [createNewApiKey, { loading: createNewApiKeyLoading }] =
    useNorthbeamMutation<CreateAPIKey>(CREATE_API_KEY, {
      refetchQueries: [{ query: FETCH_API_KEYS }],
      awaitRefetchQueries: true,
    });

  const renderAPIKeyRows = () => {
    return apiKeys.map((key) => {
      return (
        <tr className="tr" key={key}>
          <td className="td">{user.displayUserId}</td>
          <td className="td">{key}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <p>
        If your brand is not on Shopify, or uses Shopify in conjunction with
        another service (including custom platforms), please follow these{" "}
        <GenericExternalLink href="https://northbeam-orders-api.readme.io/reference/post_orders">
          instructions
        </GenericExternalLink>{" "}
        to set up a server-to-server connection.
      </p>
      <p>
        You will also need to implement the <code>firePurchaseEvent</code>{" "}
        JavaScript function at <b>every</b> conversion point in your store. You
        can find the instructions for that event{" "}
        <GenericExternalLink href="https://northbeam-pixel-api.readme.io/recipes">
          here
        </GenericExternalLink>
        .
      </p>

      {!loading && (
        <>
          {apiKeys.length > 0 && (
            <table className="table">
              <tr className="tr">
                <th className="th">Data-Client-ID</th>
                <th className="th">API Key</th>
              </tr>
              {renderAPIKeyRows()}
            </table>
          )}
          {apiKeys.length === 0 && (
            <>
              <hr />
              <p>You do not have any API Keys yet.</p>
            </>
          )}
          <button
            className="btn btn-primary"
            onClick={() => createNewApiKey()}
            disabled={createNewApiKeyLoading}
          >
            Create new API Key
          </button>
        </>
      )}
      {loading && <LoadingSlide />}
    </>
  );
};

export const FETCH_API_KEYS = gql`
  query FetchApiKeys {
    me {
      id
      apiKeys
    }
  }
`;

export const CREATE_API_KEY = gql`
  mutation CreateAPIKey {
    generateServerToServerAPIKey
  }
`;
