import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";
import { ConstructDestinationUrlComponent } from "./shared/construct-destination-url-component";

export function YahooDSP({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to all <b>destination URLs</b> for
        new and existing ads. For more information, you can look at{" "}
        <b>Yahoo DSP</b>&apos;s{" "}
        <GenericExternalLink href="https://developer.yahoo.com/dsp/docs/macros/macros.html">
          documentation
        </GenericExternalLink>
      </p>
      <ol>
        <ConstructDestinationUrlComponent urlParamPairs={urlParamPairs} />
        <li>
          Use the copied URL when creating an ad with <b>Yahoo DSP</b>
        </li>
      </ol>
    </>
  );
}
