import classNames from "classnames";
import moment, { Moment } from "moment";
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { get } from "lodash";
import { SingleDatePicker } from "react-dates";
import {
  useEffectOnce,
  useEventListener,
  useOnClickOutside,
  useToggle,
} from "usehooks-ts";
import "./date-picker.css";

const DISPLAY_FORMAT = "MMM D, YYYY";

interface DatePickerProps {
  name?: string;
  label?: string;
  className?: string;
  isDisabled?: boolean;
  value?: string | null;
  onChange: (value: string | null) => void;
  timezone: string;
  placeholder?: string;
  isOutsideRange?: (date: Moment) => boolean;
  errorMessage?: string;
  errors?: any;
}

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  (
    {
      name,
      label,
      value,
      className,
      onChange,
      isDisabled = false,
      timezone,
      placeholder = "Select Date",
      isOutsideRange,
      errorMessage,
      errors,
      ...props
    },
    ref,
  ) => {
    const [isOpen, toggleOpen, setIsOpen] = useToggle();
    const close = () => setIsOpen(false);
    useEffectOnce(() => {
      moment.tz.setDefault(timezone);
    });

    const onDateSelect = (newDate: string) => {
      close();
      onChange(newDate);
    };

    const innerRef = useRef(null);
    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
      ref,
      () => innerRef.current,
    );
    useOnClickOutside(innerRef, close);
    useEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        close();
        event.stopPropagation();
      }
    });

    const displayText = value
      ? moment(value).format(DISPLAY_FORMAT)
      : placeholder;

    // Access the error message from react-hook-form
    const formErrorMessage = get(errors, `${name}.message`);
    const hasError = errorMessage || formErrorMessage;

    return (
      <div
        className={classNames(
          "nb-date-picker nb-input-container",
          errors && "has-error",
          className,
        )}
        ref={innerRef}
        {...props}
      >
        {label && <label className="label">{label}</label>}
        <button
          className={classNames(
            "date-picker-button nb-input",
            isOpen && "active",
          )}
          onClick={toggleOpen}
          disabled={isDisabled}
          type="button"
        >
          <div className="date-picker-content">
            <div className={classNames("date-text", !value && "text-gray-400")}>
              {displayText}
            </div>
            <div className="date-indicator">
              <i className="fas fa-calendar-alt" />
            </div>
          </div>
        </button>
        {isOpen && (
          <span className="calendar">
            <Calendar
              date={value}
              onChange={onDateSelect}
              isOpen={isOpen}
              isOutsideRange={isOutsideRange}
            />
          </span>
        )}
        {hasError && (
          <div className="error-message">
            {errorMessage ?? formErrorMessage}
          </div>
        )}
      </div>
    );
  },
);

interface CalendarProps {
  date?: string | null;
  onChange(date: string | null): void;
  isOutsideRange?: (date: Moment) => boolean;
  isOpen: boolean;
}

const lastMonth = () => moment().startOf("month").subtract(1, "month");

const Calendar = ({
  date,
  onChange,
  isOpen,
  isOutsideRange = () => false,
}: CalendarProps) => {
  const dateMoment = useMemo(() => (date ? moment(date) : null), [date]);

  const selectDate = (date: Moment | null) => {
    if (date) {
      onChange(date.format("YYYY-MM-DD"));
    }
  };

  return (
    <SingleDatePicker
      id="single-date-picker"
      placeholder=""
      date={dateMoment}
      onDateChange={selectDate}
      focused={isOpen}
      noBorder
      initialVisibleMonth={lastMonth}
      onFocusChange={() => null}
      displayFormat={DISPLAY_FORMAT}
      numberOfMonths={2}
      isOutsideRange={isOutsideRange}
      small={true}
      block={true}
      hideKeyboardShortcutsPanel
    />
  );
};
