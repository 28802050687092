import { SalesViewTemplate } from "../../sales-views-context";

export const customerTypeTransactionsAndCac: SalesViewTemplate = {
  id: "customer_type_transactions_and_cac",
  name: "Customer type: Transactions and CAC",
  isTemplate: true,
  data: {
    filters: {
      attributionModel: "northbeam_custom",
      attributionWindow: "1",
      nbAccountingMode: "accrual",
      granularity: "platform",
      timeGranularity: "daily",
      dateRange: "last7Days",
      compareDateRange: "lastPeriod",
      breakdowns: [
        {
          key: "Platform (Northbeam)",
          values: [],
        },
      ],
    },
    columnConfigurations: [
      {
        name: "Name",
        width: 450,
        visible: true,
      },
      {
        name: "Spend",
        width: 180,
        visible: true,
      },
      {
        name: "CAC (1st time)",
        width: 173,
        visible: true,
      },
      {
        name: "Transactions (1st time)",
        width: 259,
        visible: true,
      },
      {
        name: "Transactions (Returning)",
        width: 269,
        visible: true,
      },
      {
        name: "CAC (Returning)",
        width: 182,
        visible: true,
      },
      {
        name: "LTV CAC",
        width: 120,
        visible: true,
      },
      {
        name: "LTV ROAS",
        width: 120,
        visible: true,
      },
    ],
    selectedMetricBoxes: [
      "Spend",
      "Attributed Rev",
      "ROAS",
      "CAC",
      "LTV CAC",
      "LTV ROAS",
    ],
  },
};
