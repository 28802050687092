import { RulesLogic } from "json-logic-js";
import _ from "lodash";
import {
  Config as ConfigType,
  Fields,
  ImmutableTree,
  Utils as QbUtils,
} from "react-awesome-query-builder";
import BasicConfig from "@components/raqb-bootstrap/config";
import { createEmptyLabelTargetFields } from "@north-beam/nb-common";

const SKIP_FIELDS = [["name"], ["iconType"]];

const FIELD_NAMES: Record<string, string> = {
  nbt: "Traffic Sources with NBT",
  "nbt.kind": "NBT kind",
  "nbt.platform": "NBT platform",
  "nbt.campaignName": "NBT campaign name",
  "nbt.adsetName": "NBT adset name",
  "nbt.adName": "NBT ad name",
  utm: "Traffic Sources with UTM but not NBT",
  "utm.source": "utm_source",
  "utm.medium": "utm_medium",
  "utm.campaign": "utm_campaign",
  "utm.term": "utm_term",
  "utm.content": "utm_content",
  adKey: 'Raw Traffic Source id ("adKey")',
  "adKey.kind": "adKey.kind",
  "adKey.platform": "adKey.platform",
  "adKey.campaign": "adKey.campaign",
  "adKey.adset": "adKey.adset",
  "adKey.ad": "adKey.ad",
  referral: "Traffic Source referral info only",
  "referral.domain": "Referrer domain",
  google__campaign: "Google Campaigns",
  "google__campaign.advertisingChannelType": "Advertising Channel Type",
  "google__campaign.advertisingChannelSubType": "Advertising Channel Sub Type",
  "google__campaign.isDynamicSearch": "Is Dynamic Search",
  facebook__campaign: "Facebook Campaigns",
  "facebook__campaign.objective": "Objective",
  inferred: "Inferred",
  "inferred.targeting": "Targeting",
};

function makeFields(skeleton: Record<string, any>): Fields {
  return traverse([], skeleton);

  function traverse(path: string[], item: Record<string, any>) {
    const rv: any = {};
    for (const key in item) {
      const value = item[key];
      const subPath = [...path, key];

      if (SKIP_FIELDS.find((v) => _.isEqual(v, subPath))) {
        continue;
      }

      let type = "text";
      if (typeof value === "object") {
        type = "!struct";
      } else if (typeof value === "string") {
        type = "text";
      } else {
        throw new Error(`Invalid type: ${typeof value}`);
      }

      const label = FIELD_NAMES[subPath.join(".")] ?? key;
      rv[key] = { type, label };

      if (type === "!struct") {
        rv[key].subfields = traverse(subPath, value);
      }
    }
    return rv;
  }
}

export const MATCHER_CONFIG: Readonly<ConfigType> = {
  ...BasicConfig,
  settings: {
    ...BasicConfig.settings,
    showNot: false,
    valueSourcesInfo: {
      value: {
        label: "Value",
      },
    },
  },
  fields: makeFields(createEmptyLabelTargetFields()),
} as any;

export function jsonLogicToSQL(jsonLogic: RulesLogic) {
  const tree = jsonLogicToTree(jsonLogic);
  return QbUtils.sqlFormat(tree, MATCHER_CONFIG);
}

export function defaultMatcher(): RulesLogic {
  return { or: [{ "==": [{ var: "utm.source" }, "google"] }] };
}

export function treeToJsonLogic(tree: ImmutableTree): RulesLogic {
  return QbUtils.jsonLogicFormat(tree, MATCHER_CONFIG).logic! as RulesLogic;
}

export function jsonLogicToTree(jsonLogic: RulesLogic): ImmutableTree {
  return QbUtils.loadFromJsonLogic(jsonLogic, MATCHER_CONFIG);
}
