import { gql } from "@apollo/client";
import { useUser } from "@components/user-context";
import {
  UpdateUseUTMsWithGA,
  UpdateUseUTMsWithGAVariables,
} from "@nb-api-graphql-generated/UpdateUseUTMsWithGA";
import { UseUTMsWithGA as UseUTMsWithGAQuery } from "@nb-api-graphql-generated/UseUTMsWithGA";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import AccordionWrapper from "react-faq-component";
import LegacyUTMs, { analyticsTools } from "./legacy-utms";
import { AdRoll } from "./platform-components/ad-roll";
import { AvantLink } from "./platform-components/avant-link";
import { CNN5Things } from "./platform-components/cnn-5-things";
import { Criteo } from "./platform-components/criteo";
import { Facebook } from "./platform-components/facebook";
import { Google } from "./platform-components/google";
import { Grin } from "./platform-components/grin";
import { ImpactRadius } from "./platform-components/impact-radius";
import { Klaviyo } from "./platform-components/klaviyo";
import { LiveIntent } from "./platform-components/live-intent";
import { Outbrain } from "./platform-components/outbrain";
import { Partnerize } from "./platform-components/partnerize";
import { Pinterest } from "./platform-components/pinterest";
import { Postscript } from "./platform-components/postscript";
import { Rakuten } from "./platform-components/rakuten";
import { RetentionScience } from "./platform-components/retention-science";
import { Revcontent } from "./platform-components/revcontent";
import { ShareASale } from "./platform-components/share-a-sale";
import { CheckUrlValidity } from "./platform-components/shared/check-url-validity";
import { Snapchat } from "./platform-components/snapchat";
import { StackAdapt } from "./platform-components/stack-adapt";
import { Taboola } from "./platform-components/taboola";
import { TikTok } from "./platform-components/tiktok";
import { Twitter } from "./platform-components/twitter";
import { YahooDSP } from "./platform-components/yahoo-dsp";
import { YahooGemini } from "./platform-components/yahoo-gemini";

export interface UtmGuidePlatformProps {
  urlParamPairs: UrlParamPair[];
}

export interface UrlParamPair {
  key: string;
  value: string;
}

export interface UtmGuidePlatformConfig {
  name: string;
  urlParamPairs: UrlParamPair[];
  component: React.FC<UtmGuidePlatformProps>;
}

export const utmGuidePlatformConfigs: UtmGuidePlatformConfig[] = _.sortBy(
  [
    {
      name: "AvantLink",
      urlParamPairs: [
        { key: "nb_platform", value: "avantlink" },
        { key: "utm_source", value: "[PUBLISHER_ID]" },
        { key: "utm_medium", value: "avantlink" },
        { key: "utm_campaign", value: "[AD_NAME]" },
        { key: "nb_pid", value: "[PUBLISHER_ID]" },
        { key: "nb_wid", value: "[WEBSITE_ID]" },
        { key: "nb_tt", value: "[TOOL_TYPE]" },
        { key: "nb_aid", value: "[AD_ID]" },
      ],
      component: AvantLink,
    },
    {
      name: "CNN5Things",
      urlParamPairs: [
        { key: "nb_platform", value: "CNN" },
        { key: "utm_source", value: "CNN" },
        { key: "utm_medium", value: "partner-newsletter" },
        {
          key: "utm_campaign",
          value: `CNN5Things${new Date()
            .toLocaleString("default", { month: "short", year: "numeric" })
            .replaceAll(" ", "")}`,
        },
      ],
      component: CNN5Things,
    },
    {
      name: "Klaviyo",
      component: Klaviyo,
      urlParamPairs: [
        {
          key: "nb_klid",
          value: "[Klaviyo Profile Id]",
        },
      ],
    },
    {
      name: "Facebook Ads",
      urlParamPairs: [
        {
          key: "nbt",
          value:
            "nb%3Afb%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adset.id}}%3A{{ad.id}}",
        },
        { key: "nb_placement", value: "{{placement}}" },
      ],
      component: Facebook,
    },
    {
      name: "Google Ads",
      component: Google,
      urlParamPairs: [
        {
          key: "nbt",
          value:
            "nb%3Aadwords%3A{network}%3A{campaignid}%3A{adgroupid}%3A{creative}",
        },
        { key: "nb_adtype", value: "{adtype}" },
        { key: "nb_kwd", value: "{keyword}" },
        { key: "nb_ti", value: "{targetid}" },
        { key: "nb_mi", value: "{merchant_id}" },
        { key: "nb_pc", value: "{product_channel}" },
        { key: "nb_pi", value: "{product_id}" },
        { key: "nb_ppi", value: "{product_partition_id}" },
        { key: "nb_placement", value: "{placement}" },
        { key: "nb_li_ms", value: "{loc_interest_ms}" },
        { key: "nb_lp_ms", value: "{loc_interest_ms}" },
        { key: "nb_fii", value: "{feeditemid}" },
        { key: "nb_ap", value: "{adposition}" },
        { key: "nb_mt", value: "{matchtype}" },
      ],
    },
    {
      name: "Snapchat Ads",
      component: Snapchat,
      urlParamPairs: [
        {
          key: "nbt",
          value:
            "nb%3Asnapchat%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adSet.id}}%3A{{ad.id}}",
        },
      ],
    },
    {
      name: "Rakuten",
      component: Rakuten,
      urlParamPairs: [
        { key: "nb_platform", value: "rakuten" },
        { key: "nb_publisher", value: "[publisherID]" },
        { key: "nb_creative", value: "[creativeID]" },
        { key: "nb_creative_type", value: "[creativetype]" },
        { key: "nb_offer", value: "[offerID]" },
        { key: "nb_publisher_name", value: "[publishername]" },
      ],
    },
    {
      name: "Pinterest",
      component: Pinterest,
      urlParamPairs: [
        {
          key: "nbt",
          value: "nb%3Apinterest%3A%3A{campaignid}%3A{adgroupid}%3A{adid}",
        },
        { key: "nb_kwd", value: "{keyword}" },
        { key: "nb_device", value: "{device}" },
        { key: "nb_platform", value: "pinterest" },
        { key: "nb_pn", value: "{product_name}" },
        { key: "nb_ppgid", value: "{promoted_product_group_id}" },
        { key: "nb_pid", value: "{product_id}" },
        { key: "nb_ppid", value: "{product_partition_id}" },
        { key: "utm_medium", value: "paid" },
        { key: "utm_source", value: "pinterest" },
        { key: "utm_campaign", value: "{campaign_name}" },
        { key: "utm_term", value: "{adgroup_name}" },
      ],
    },
    {
      name: "Taboola",
      component: Taboola,
      urlParamPairs: [
        { key: "utm_source", value: "taboola" },
        { key: "utm_medium", value: "referral" },
        { key: "utm_campaign", value: "{campaign_name}" },
        { key: "utm_term", value: "{site}" },
        { key: "utm_content", value: "{title}" },
        { key: "nb_platform", value: "taboola" },
        { key: "nb_sid", value: "{site_id}" },
        { key: "nb_cid", value: "{campaign_id}" },
        { key: "nb_ciid", value: "{campaign_item_id}" },
        { key: "nb_device", value: "{platform}" },
        { key: "nb_cpc", value: "{cpc}" },
      ],
    },
    {
      name: "Outbrain",
      component: Outbrain,
      urlParamPairs: [
        { key: "utm_source", value: "outbrain" },
        { key: "utm_medium", value: "discovery" },
        { key: "utm_campaign", value: "{{campaign_name}}" },
        { key: "utm_content", value: "{{ad_title}}" },
        { key: "utm_term", value: "{{publisher_name}}_{{section_name}}" },
        { key: "nb_platform", value: "outbrain" },
        { key: "nb_aid", value: "{{aid}}" },
        { key: "nb_cid", value: "{{campaign_id}}" },
        { key: "nb_reqid", value: "{{req_id}}" },
        { key: "nb_secid", value: "{{section_id}}" },
        { key: "nb_plinkid", value: "{{promoted_link_id}}" },
        { key: "nb_docid", value: "{{doc_id}}" },
        { key: "nb_pubid", value: "{{publisher_id}}" },
        { key: "nb_idfa", value: "{{idfa}}" },
        { key: "nb_androidid", value: "{{android_id}}" },
      ],
    },
    {
      name: "StackAdapt",
      component: StackAdapt,
      urlParamPairs: [
        { key: "utm_source", value: "stackadapt" },
        { key: "utm_medium", value: "display" },
        { key: "utm_campaign", value: "{SA_CAMPAIGN_NAME}" },
        { key: "utm_content", value: "{SA_CREATIVE_NAME}" },
        { key: "utm_term", value: "{SA_APP_NAME}_{SA_BUNDLE_ID}" },
        { key: "nb_platform", value: "stackadapt" },
        { key: "nb_ref_id", value: "{SA_REF_ID}" },
        { key: "nb_ref_domain", value: "{SA_REF_DOMAIN}" },
        { key: "nb_ref_page", value: "{SA_REF_PAGE}" },
        { key: "nb_source_id", value: "{SA_SOURCE_ID}" },
        { key: "nb_channel_type", value: "{SA_CHANNEL_TYPE}" },
        { key: "nb_advertiser_id", value: "{SA_ADVERTISER_ID}" },
        { key: "nb_sub_advertiser_id", value: "{SA_SUB_ADVERTISER_ID}" },
        { key: "nb_cid", value: "{SA_CAMPAIGN_ID}" },
        { key: "nb_cname", value: "{SA_CAMPAIGN_NAME}" },
        { key: "nb_line_item_id", value: "{SA_LINE_ITEM_ID}" },
        { key: "nb_creative_id", value: "{SA_CREATIVE_ID}" },
        { key: "nb_placement_id", value: "{SA_PLACEMENT_ID}" },
        { key: "nb_imp_height", value: "{SA_HEIGHT}" },
        { key: "nb_imp_width", value: "{SA_WIDTH}" },
        { key: "nb_sa_do_not_track", value: "{SA_DO_NOT_TRACK}" },
        { key: "nb_lat", value: "{SA_LOCATION_LAT}" },
        { key: "nb_long", value: "{SA_LOCATION_LONG}" },
        { key: "nb_app_name", value: "{SA_APP_NAME}" },
        { key: "nb_bundle_id", value: "{SA_BUNDLE_ID}" },
        { key: "nb_store_url", value: "{SA_STORE_URL}" },
        { key: "nb_app_version", value: "{SA_APP_VERSION}" },
        { key: "nb_device_id", value: "{SA_DEVICE_ID}" },
        { key: "nb_device_os", value: "{SA_DEVICE_OS}" },
        { key: "nb_idfa_md5", value: "{SA_IDFA_MD5}" },
        { key: "nb_idfa_sha", value: "{SA_IDFA_SHA}" },
        { key: "nb_geo_state", value: "{SA_GEO_STATE}" },
        { key: "nb_geo_dma", value: "{SA_GEO_DMA}" },
        { key: "nb_geo_city", value: "{SA_GEO_CITY}" },
        { key: "nb_geo_postal", value: "{SA_GEO_POSTAL}" },
        { key: "nb_geo_country", value: "{SA_GEO_COUNTRY}" },
        { key: "nb_creative_name", value: "{SA_CREATIVE_NAME}" },
        { key: "nb_creative_height", value: "{SA_CREATIVE_HEIGHT}" },
        { key: "nb_creative_width", value: "{SA_CREATIVE_WIDTH}" },
        { key: "nb_device_model", value: "{SA_DEVICE_MODEL}" },
        { key: "nb_day_of_week", value: "{SA_DAY_OF_WEEK}" },
        { key: "nb_month", value: "{SA_MONTH}" },
      ],
    },
    {
      name: "ShareASale",
      component: ShareASale,
      urlParamPairs: [
        { key: "utm_source", value: "shareasale" },
        { key: "utm_medium", value: "affiliate" },
        { key: "nb_platform", value: "shareasale" },
      ],
    },
    {
      name: "Partnerize",
      component: Partnerize,
      urlParamPairs: [
        { key: "utm_source", value: "partnerize" },
        { key: "utm_medium", value: "affiliate" },
        { key: "utm_campaign", value: "{subid}" },
        { key: "utm_content", value: "{creative}" },
        { key: "nb_platform", value: "partnerize" },
        { key: "nb_clid", value: "{clid}" },
        { key: "nb_subid", value: "{subid}" },
        { key: "nb_creative", value: "{creative}" },
      ],
    },
    {
      name: "Revcontent",
      component: Revcontent,
      urlParamPairs: [
        { key: "utm_source", value: "revcontent" },
        { key: "utm_medium", value: "native" },
        { key: "utm_campaign", value: "{boost_id}" },
        { key: "utm_term", value: "{adv_targets}" },
        { key: "utm_content", value: "{content_headline}" },
        { key: "nb_platform", value: "revcontent" },
        { key: "nb_bid", value: "{boost_id}" },
        { key: "nb_tid", value: "{target_id}" },
        { key: "nb_wid", value: "{widget_id}" },
        { key: "nb_cid", value: "{content_id}" },
      ],
    },
    // Based on instructions here: https://impact-helpdesk.freshdesk.com/support/solutions/articles/48001144239-gateway-tracking-settings?accountType=ADVERTISER#campaign-tracking-template-0-4
    {
      name: "ImpactRadius",
      component: ImpactRadius,
      urlParamPairs: [
        { key: "utm_source", value: "{irmpname}" },
        { key: "utm_medium", value: "impact" },
        { key: "utm_campaign", value: "{ircid}" },
        { key: "utm_content", value: "{iradname}" },
        { key: "nb_platform", value: "impact" },
        { key: "nb_adid", value: "{iradid}" },
        { key: "nb_cid", value: "{ircid}" },
        { key: "nb_pid", value: "{irpid}" },
        { key: "nb_aname", value: "{iradname}" },
        { key: "nb_atype", value: "{iradtype}" },
        { key: "nb_asize", value: "{iradsize}" },
        { key: "nb_product_sku", value: "{prodsku}" },
        { key: "nb_partner_name", value: "{irmpname}" },
        { key: "nb_mtype", value: "{irmptype}" },
        { key: "nb_partner_shared_id", value: "{sharedid}" },
      ],
    },
    {
      name: "TikTok Ads",
      component: TikTok,
      urlParamPairs: [
        { key: "utm_source", value: "tiktok" },
        { key: "utm_medium", value: "paid" },
        { key: "nb_platform", value: "tiktok" },
        {
          key: "nbt",
          value: "nb%3Atiktok%3A%3A__CAMPAIGN_ID__%3A__AID__%3A__CID__",
        },
      ],
    },
    {
      name: "Criteo",
      urlParamPairs: [
        { key: "utm_source", value: "criteo" },
        { key: "utm_medium", value: "paid" },
        { key: "nb_platform", value: "criteo" },
        { key: "nb_campaignid", value: "{{campaignid}}" },
        { key: "nb_adsetid", value: "{{adsetid}}" },
        { key: "nb_creativetype", value: "{{creative_type}}" },
        { key: "nb_deviceid", value: "{{device_id}}" },
        { key: "nb_domain", value: "{{domain}}" },
        { key: "nb_gdpr", value: "{{gdpr}}" },
        { key: "nb_gdprconsent", value: "{{gdpr_consent}}" },
        { key: "nb_os", value: "{{os}}" },
      ],
      component: Criteo,
    },
    {
      name: "Twitter Ads",
      component: Twitter,
      urlParamPairs: [
        { key: "utm_source", value: "twitter" },
        { key: "utm_medium", value: "paid" },
        { key: "nb_platform", value: "twitter" },
      ],
    },
    {
      name: "AdRoll",
      urlParamPairs: [
        { key: "utm_source", value: "adroll" },
        { key: "utm_medium", value: "paid" },
        { key: "nb_platform", value: "adroll" },
        { key: "nb_ct", value: "[ADROLL:CAMPAIGN_TYPE]" },
        { key: "nb_aaeid", value: "[ADROLL:ADVERTISABLE_EID]" },
        { key: "nb_ceid", value: "[ADROLL:CAMPAIGN_EID]" },
        { key: "nb_ageid", value: "[ADROLL:AD_GROUP_EID]" },
        { key: "nb_aid", value: "[ADROLL:AD_EID]" },
        { key: "nb_mid", value: "[ADROLL:MOBILE_ID]" },
        { key: "nb_cid", value: "[ADROLL:CLICK_ID]" },
        { key: "nb_d", value: "[ADROLL:DOMAIN]" },
        { key: "nb_n", value: "[ADROLL:NETWORK]" },
        { key: "nb_dt", value: "[ADROLL:DEVICE_TYPE]" },
      ],
      component: AdRoll,
    },
    {
      name: "Grin",
      component: Grin,
      urlParamPairs: [
        { key: "utm_campaign", value: "[instagram_username]" },
        { key: "utm_source", value: "grin" },
        { key: "utm_medium", value: "influencer" },
        { key: "nb_platform", value: "grin" },
        { key: "nb_igu", value: "[instagram_username]" },
        { key: "nb_ytu", value: "[youtube_username]" },
        { key: "nb_ttu", value: "[tiktok_username]" },
        { key: "nb_twitchu", value: "[twitch_username]" },
        { key: "nb_twtru", value: "[twitter_username]" },
      ],
    },
    {
      name: "Yahoo Gemini",
      component: YahooGemini,
      urlParamPairs: [
        { key: "nb_platform", value: "yg" },
        { key: "nb_cid", value: "{campaignid}" },
        { key: "nb_agid", value: "{adgroupid}" },
        { key: "nb_kwd", value: "{keyword}" },
        { key: "nb_bmt", value: "{bidmatchtype}" },
        { key: "nb_mt", value: "{matchtype}" },
        { key: "nb_qs", value: "{querystring}" },
        { key: "nb_aid", value: "{adid}" },
        { key: "nb_creative", value: "{creative}" },
        { key: "nb_oii", value: "{orderitemid}" },
        { key: "nb_network", value: "{network}" },
        { key: "nb_device", value: "{device}" },
        { key: "nb_adxid", value: "{adxid}" },
        { key: "nb_adxtype", value: "{adxtype}" },
        { key: "nb_tid", value: "{targetid}" },
        { key: "utm_source", value: "yahoo-gemini" },
        { key: "utm_medium", value: "{network}" },
      ],
    },
    // macros not used GDPR_CONSENT_#, CLICKURL, CLICKURLENCENC
    // https://developer.yahoo.com/dsp/docs/macros/macros.html
    {
      name: "Yahoo DSP",
      component: YahooDSP,
      urlParamPairs: [
        { key: "nb_platform", value: "ydsp" },
        { key: "nb_cid", value: "{ORDERID}" },
        { key: "nb_aid", value: "{TRAFFICKEDADID}" },
        { key: "nb_adv", value: "{ADV_ID}" },
        { key: "nb_advli", value: "{PLACEMENTID}" },
        { key: "nb_cturle", value: "{CLICKURLENC}" },
        { key: "nb_crid", value: "{LIBRARYADID}" },
        { key: "nb_gdpra", value: "{GDPR}" },
        { key: "nb_iabusp", value: "{US_PRIVACY}" },
        { key: "nb_heig", value: "{HEIGHT}" },
        { key: "nb_widt", value: "{WIDTH}" },
        { key: "nb_pubid", value: "{PUBLISHERID}" },
        { key: "nb_rand", value: "{REQUESTID}" },
        { key: "utm_source", value: "yahoo-dsp" },
        { key: "utm_medium", value: "paid" },
        { key: "utm_campaign", value: "{ORDERID}" },
      ],
    },
    {
      name: "Retention Science",
      urlParamPairs: [
        { key: "utm_source", value: "ReSci-{{ rs_stage_name }}" },
        { key: "utm_medium", value: "email" },
        { key: "utm_campaign", value: "{{ rs_template_name }}" },
        { key: "nb_platform", value: "resci" },
        { key: "nb_sn", value: "{{ rs_stage_name }}" },
        { key: "nb_tn", value: "{{ rs_template_name }}" },
        { key: "nb_oid", value: "{{ rs_offerid }}" },
      ],
      component: RetentionScience,
    },
    {
      name: "LiveIntent",
      component: LiveIntent,
      urlParamPairs: [
        /* eslint-disable no-template-curly-in-string */
        { key: "nb_platform", value: "liveintent" },
        { key: "nb_crid", value: "${CREATIVE_ID}" },
        { key: "nb_pid", value: "${PUBLISHER_ID}" },
        { key: "nb_caid", value: "${CAMPAIGN_ID}" },
        { key: "nb_liid", value: "${LINE_ITEM_ID}" },
        { key: "nb_tid", value: "${TEMPLATE_ID}" },
        { key: "nb_device", value: "${DEVICE_TYPE}" },
        { key: "nb_did", value: "${DECISION_ID}" },
        { key: "utm_source", value: "liveintent" },
        { key: "utm_medium", value: "native-email" },
        /* eslint-enable no-template-curly-in-string */
      ],
    },
    {
      name: "Postscript",
      component: Postscript,
      urlParamPairs: [
        { key: "utm_source", value: "DYNAMIC - CAMPAIGN NAME" },
        { key: "utm_medium", value: "sms" },
      ],
    },
    {
      name: "Microsoft",
      component: () => (
        <p>
          Sit back and relax! You don&apos;t need to do anything for this
          platform, we&apos;ve already got you covered.
        </p>
      ),
      urlParamPairs: [],
    },
  ],
  (v) => v.name.toLowerCase(),
);

export function UtmGuide() {
  const { user } = useUser();
  const { data } = useNorthbeamQuery<UseUTMsWithGAQuery>(
    USE_UTMS_WITH_GA_QUERY,
  );

  const [updateUseUTMsWithGA] = useNorthbeamMutation<
    UpdateUseUTMsWithGA,
    UpdateUseUTMsWithGAVariables
  >(UPDATE_USE_UTMS_WITH_GA_QUERY);

  const [excludeUtms, setExcludeUtms] = useState<boolean | null>(null);
  const [accordionData, setAccordionData] = useState<any>(true);

  useEffect(() => {
    if (data?.me?.onboardingDetails?.useUTMsWithGA !== undefined) {
      setExcludeUtms(data?.me?.onboardingDetails?.useUTMsWithGA);
    }
  }, [data]);

  useEffect(() => {
    setAccordionData({
      rows: utmGuidePlatformConfigs.map((config) => {
        let urlParamPairs = config.urlParamPairs;
        if (excludeUtms) {
          urlParamPairs = config.urlParamPairs.filter(
            ({ key }) =>
              ![
                "utm_source",
                "utm_medium",
                "utm_campaign",
                "utm_term",
                "utm_content",
              ].includes(key),
          );
        }
        return {
          title: config.name,
          content: (
            <>
              <config.component urlParamPairs={urlParamPairs} />
              {user.isAdmin && (
                <CheckUrlValidity
                  isAdmin={user.isAdmin}
                  urlParamPairs={urlParamPairs}
                />
              )}
            </>
          ),
        };
      }),
    });
  }, [excludeUtms, user.isAdmin]);

  const config = {
    tabFocus: true,
  };

  const styles = {
    bgColor: "white",
    rowContentTextSize: "16px",
    rowContentPaddingTop: "10px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "20px",
    rowContentPaddingRight: "20px",
    arrowColor: "black",
  };

  return (
    <>
      <hr />
      <h5 className="secondary-font-purple mb-3">
        <b>
          For each platform you have ads on, please add UTM tracking. Follow the
          instructions for each platform below to do so.
        </b>
      </h5>
      <p>
        Northbeam requires your advertising to have UTM parameters added on
        every platform.
      </p>
      <p>
        If you advertise on a channel that is NOT listed below, please reach out
        to <a href="mailto:success@northbeam.io">success@northbeam.io</a> and we
        will be happy to assist you.
      </p>
      <p>
        These UTMs give us the data that is necessary to accurately report with
        our platform. In some situations, we are able to automatically place
        UTMs. Unless already stated in instructions below, this is typically a
        manual part of onboarding.
      </p>
      <p>
        <b>
          Note: If you skip any of your platforms, your reporting will be
          significantly limited on that platform. Additionally, you should begin
          by adding tracking parameters to <i>Klaviyo</i> and <i>Google Ads</i>,
          if applicable.
        </b>
      </p>
      <hr />
      <h4 className="mb-3">Northbeam UTMs</h4>
      <UtmGuide.GAAlert
        utmExclusionValue={excludeUtms}
        onChange={(shouldExclude) => {
          setExcludeUtms(shouldExclude);
          updateUseUTMsWithGA({
            variables: { useUTMsWithGA: shouldExclude },
          });
        }}
      />
      {excludeUtms != null && (
        <>
          <div className="row mb-5">
            <div className="col">
              <AccordionWrapper
                data={accordionData}
                styles={styles}
                config={config}
              />
            </div>
          </div>
          <LegacyUTMs analyticsTools={analyticsTools} />
        </>
      )}
    </>
  );
}

UtmGuide.GAAlert = function GAAlert({
  utmExclusionValue,
  onChange,
}: {
  utmExclusionValue: boolean | null;
  onChange: (value: boolean) => void;
}) {
  return (
    <div className="alert alert-info">
      <div>Do you use UTMs with Google Analytics?</div>
      <div>
        <small>
          We use this information to filter out specific UTMs that GA populates
          for us.
        </small>
      </div>
      <div className="mt-2">
        <button
          onClick={() => onChange(true)}
          className={classNames(
            "btn",
            "btn-sm",
            "btn-outline-info",
            utmExclusionValue && "active",
          )}
        >
          Yes
        </button>
        <button
          onClick={() => onChange(false)}
          className={classNames(
            "btn",
            "btn-sm",
            "ml-3",
            "btn-outline-info",
            utmExclusionValue === false && "active",
          )}
        >
          No
        </button>
      </div>
    </div>
  );
};

export const USE_UTMS_WITH_GA_QUERY = gql`
  query UseUTMsWithGA {
    me {
      onboardingDetails {
        useUTMsWithGA
      }
    }
  }
`;

const UPDATE_USE_UTMS_WITH_GA_QUERY = gql`
  mutation UpdateUseUTMsWithGA($useUTMsWithGA: Boolean!) {
    updateUseUTMsWithGA(useUTMsWithGA: $useUTMsWithGA)
  }
`;
