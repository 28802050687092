import { Checkbox } from "@shared/checkbox";
import { chunk } from "lodash";
import React, { useMemo } from "react";
import { ModalPanel } from "../modals/modal-panel";
import { MetricConfig } from "./metric-category-builder";

interface MetricVisibilityPanelProps<T extends MetricConfig> {
  visibilityOfMetrics: T[];
  toggleMetricVisibility: (arg0: T) => void;
  toggleVisibilityOfAllMetrics?: (columns: T[], visible: boolean) => void;
}

export const MetricVisibilityPanel = <T extends MetricConfig>({
  visibilityOfMetrics,
  toggleMetricVisibility,
  toggleVisibilityOfAllMetrics,
}: MetricVisibilityPanelProps<T>) => {
  const COLUMN_COUNT = 2;

  const isAllSelected = useMemo(
    () => visibilityOfMetrics?.every(({ visible }) => visible),
    [visibilityOfMetrics],
  );

  const toggleAllSelected = () => {
    if (toggleVisibilityOfAllMetrics) {
      if (isAllSelected) {
        toggleVisibilityOfAllMetrics(visibilityOfMetrics, false);
      } else {
        toggleVisibilityOfAllMetrics(visibilityOfMetrics, true);
      }
    }
  };

  const metricOrderedVisibility = useMemo(() => {
    if (visibilityOfMetrics.length < 3) {
      return visibilityOfMetrics;
    }

    const newOrder = [];
    const numPermetric = Math.ceil(visibilityOfMetrics.length / COLUMN_COUNT);
    const metricsChunked = chunk(
      visibilityOfMetrics.sort((a, b) => {
        // HACK HACK HACK
        // To get strings to sort correctly with special characters, I need to strip those characters here
        // Otherwise "FB Shops purchases (7D Click)" will come _after_ "FB Shops purchases (7D Click, 1D View)"
        return a.name
          .replace(/[^\w\s]/gi, "")
          .localeCompare(b.name.replace(/[^\w\s]/gi, ""));
      }),

      numPermetric,
    );
    for (let i = 0; i < numPermetric; i++) {
      for (let j = 0; j < COLUMN_COUNT; j++) {
        if (metricsChunked[j][i]) {
          newOrder.push(metricsChunked[j][i]);
        }
      }
    }

    return newOrder;
  }, [visibilityOfMetrics]);

  return (
    <ModalPanel
      title="Metrics"
      className="p-3"
      data-testid="metric-visibility-panel"
    >
      <div
        className={`grid grid-cols-${COLUMN_COUNT} text-sm text-[#596577] gap-0.5`}
      >
        {visibilityOfMetrics.length === 0 ? (
          <div>No metrics to display</div>
        ) : (
          <>
            {toggleVisibilityOfAllMetrics && (
              <div
                className="col-span-2 flex-horizontal-center cursor-pointer h-9 mb-3"
                onClick={() => toggleAllSelected()}
              >
                <Checkbox
                  className="mr-3"
                  onChange={toggleAllSelected}
                  value={isAllSelected}
                />
                <span>{isAllSelected ? "Unselect" : "Select"} All</span>
              </div>
            )}

            {metricOrderedVisibility.map(({ name, visible }) => (
              <div
                key={name}
                className="flex-horizontal-center cursor-pointer h-9"
                onClick={() => toggleMetricVisibility({ name, visible } as T)}
              >
                <Checkbox
                  className="mr-3"
                  onChange={() => {
                    // Do nothing
                  }}
                  value={!!visible}
                />
                <span>{name}</span>
              </div>
            ))}
          </>
        )}
      </div>
    </ModalPanel>
  );
};
