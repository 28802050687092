import { usePageTitle } from "@/atoms/page-title-atom";
import { gql } from "@apollo/client";
import { ControlBar, ControlBarElement } from "@components/control-bar";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { ExpandableBreakdownSelector } from "@components/reports/breakdown-selector";
import { CompanionWrapper } from "@components/reports/control-center-utilities";
import { PaginationBar } from "@components/reports/native-table";
import { StuckToTop } from "@components/stuck-to-top";
import { LoadingSlide } from "@components/title-slide-view";
import { Bubble, H1 } from "@components/utilities";
import styled from "@emotion/styled";
import {
  BulkAddAdObjectLabel,
  BulkAddAdObjectLabelVariables,
} from "@nb-api-graphql-generated/BulkAddAdObjectLabel";
import {
  ListAdObjects,
  ListAdObjectsVariables,
} from "@nb-api-graphql-generated/ListAdObjects";
import {
  Breakdown,
  BreakdownConfig,
  CategoricalBreakdownConfig,
} from "@north-beam/nb-common";
import { notifyLabelsCache } from "@shared/notify-labels-cache";
import { logEvent, LogOnMount } from "@utils/analytics";
import {
  Granularity,
  IGranularity,
  parseBreakdownList,
} from "@utils/constants";
import { enumType } from "@utils/enum-type";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import {
  mergeLocationSearchParams,
  toReactSelectLabel,
  toReactSelectLabelMulti,
} from "@utils/index";
import classNames from "classnames";
import deepEqual from "deep-equal";
import _ from "lodash";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select, { components, DropdownIndicatorProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  CellProps,
  Column,
  HeaderProps,
  Row,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { pickColorDark } from "./label-colors";
import { AdIcon } from "./utils";

export function AdObjectList() {
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();

  React.useEffect(() => {
    // eslint-disable-next-line
    if (breakdownConfigs.length > 1) {
      logEvent("Visit Ad Objects List");
    }
  }, [breakdownConfigs]);

  return <Inner breakdownConfigs={breakdownConfigs} />;
}

function Inner({
  breakdownConfigs,
}: {
  breakdownConfigs: CategoricalBreakdownConfig[];
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const params: QueryParams = parseQueryParams(location.search);
  const variables: ListAdObjectsVariables = {
    activeOnly: params.showCampaignsMode === "activeOnly",
    filters: params.filters,
    granularity: params.granularity,
  };

  const { loading, data } = useNorthbeamQuery<
    ListAdObjects,
    ListAdObjectsVariables
  >(LIST_CAMPAIGNS, {
    variables,
  });

  const updateQuery = React.useCallback(
    (newQuery: QueryParams) => {
      const search = location.search;
      const newParams = makeQueryString(newQuery);
      navigate({ search: mergeLocationSearchParams(search, newParams) });
    },
    [navigate, location.search],
  );

  return (
    <div>
      <StuckToTop>
        {() => (
          <ControlCenter
            initial={params}
            onSubmit={updateQuery}
            isLoading={loading}
            breakdownConfigs={breakdownConfigs}
          />
        )}
      </StuckToTop>
      <CompanionWrapper>
        <DynamicArea
          loading={loading}
          data={data}
          breakdownConfigs={breakdownConfigs}
          params={params}
        />
      </CompanionWrapper>
    </div>
  );
}

function DynamicArea({
  loading,
  data,
  breakdownConfigs,
  params,
}: {
  loading: boolean;
  data: any;
  breakdownConfigs: BreakdownConfig[];
  params: QueryParams;
}) {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Breakdowns Editor");
  }, [setPageTitle]);

  if (loading) {
    return <LoadingSlide />;
  }

  const { adObjects } = data.me;

  return (
    <div className="container-xl px-4">
      <LogOnMount name="Visit Traffic Source Labels Page" />
      <div className="row">
        <div className="col">
          <H1>Breakdowns Editor</H1>
          <p>Click on one of the Traffic Sources to see more about it.</p>
        </div>
      </div>
      <AdObjectListTable
        breakdownConfigs={breakdownConfigs}
        adObjects={adObjects}
        isLoading={loading}
        granularity={params.granularity}
      />
    </div>
  );
}

function Labels(props: CellProps<any>) {
  const divEl: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [size, setSize] = React.useState<"pending" | "ok" | "overflow">(
    "pending",
  );
  const [collapsed, setCollapsed] = React.useState<boolean>(true);

  const labels: { key: string; value: string }[] = React.useMemo(() => {
    return _.chain(props.cell.value || {})
      .toPairs()
      .map(([key, value]) => ({ key, value }))
      .sortBy((v) => v.key.toLowerCase())
      .value();
  }, [props]);

  React.useEffect(() => {
    // TODO: not perfect but works ok -- this effect needs to re-fire whenever
    // TODO: the client window changes
    if (divEl.current) {
      const { clientHeight, scrollHeight } = divEl.current;
      setSize(clientHeight < scrollHeight ? "overflow" : "ok");
    } else {
      setSize("pending");
    }
  }, []);

  return (
    <div className="flex">
      <div
        className="text-wrap flex-wrap w-100"
        ref={divEl}
        style={{
          lineHeight: "1.5rem",
          maxHeight: collapsed ? "2rem" : "none",
          overflowY: "hidden",
        }}
      >
        {labels.length === 0 && <Bubble color={"transparent"}>&nbsp;</Bubble>}
        {labels.map((label: any) => (
          <Label label={label} key={JSON.stringify(label)} />
        ))}
      </div>
      <div className="flex-shrink-1">
        <button
          className="btn btn-link btn-sm"
          style={{ fontSize: "12px" }}
          onClick={() => setCollapsed(!collapsed)}
        >
          {size !== "overflow" ? null : collapsed ? (
            <React.Fragment>
              <i className="fas fa-chevron-down d-inline"></i>&nbsp;More
            </React.Fragment>
          ) : (
            <React.Fragment>
              <i className="fas fa-chevron-up d-inline"></i>&nbsp;Less
            </React.Fragment>
          )}
        </button>
      </div>
    </div>
  );
}

function Label(props: { label: { key: string; value: string } }) {
  const truncLimit = 15;
  const { label } = props;
  const { key, value } = label;
  const truncatedKey =
    key.length > truncLimit ? key.substr(0, truncLimit - 3) + "..." : key;
  const truncatedValue =
    value.length > truncLimit ? value.substr(0, truncLimit - 3) + "..." : value;
  const bgColor = pickColorDark(key);
  return (
    <Bubble color={bgColor} title={`${key} = ${value}`}>
      <span className="text-truncate">{truncatedKey}</span>{" "}
      <span className="text-truncate">=</span>{" "}
      <span className="text-truncate">{truncatedValue}</span>
    </Bubble>
  );
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
  font-size: 14px;

  /* This will make the table scrollable when it gets too small */
  .table-wrap {
    display: block;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function AdObjectListTable(props: {
  adObjects: any[];
  isLoading: boolean;
  breakdownConfigs: BreakdownConfig[];
  granularity: IGranularity;
}) {
  const {
    isLoading,
    adObjects: adObjects_,
    breakdownConfigs,
    granularity,
  } = props;
  const adObjects = React.useMemo(
    () =>
      adObjects_.map((v) => ({
        ...v,
        numLabels: Object.keys(v.labels).length,
      })),
    [adObjects_],
  );
  const bds = breakdownConfigs as CategoricalBreakdownConfig[];
  const defaultColumn: Column<any> = React.useMemo(
    () => ({ disableSortBy: true } as unknown as Column<any>),
    [],
  );

  const labelsSortFunc = React.useCallback((a: Row<any>, b: Row<any>) => {
    const aHasMoreLabels = a.original.numLabels > b.original.numLabels;
    return aHasMoreLabels ? 1 : -1;
  }, []);

  const columns: Column<any>[] = React.useMemo(
    () =>
      [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }: CellProps<any>) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        {
          Header: Granularity.getLabel(granularity),
          accessor: "name",
          filter: "text",
          Cell: ({ cell, row }: CellProps<any>) => (
            <Link title={cell.value} to={row.original.id}>
              <div className="flex items-center">
                <AdIcon sizeInEM={1} platform={row.original.nbtPlatformID} />
                <div className="text-truncate" style={{ maxWidth: "18rem" }}>
                  {cell.value}
                </div>
              </div>
            </Link>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
        },
        {
          disableSortBy: false,
          Header: ({ column }: HeaderProps<any>) => {
            return (
              <span>
                Labels &nbsp;&nbsp;
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <i className="fas fa-sort-down"></i>
                  ) : (
                    <i className="fas fa-sort-up"></i>
                  )
                ) : (
                  <i className="fas fa-sort"></i>
                )}
              </span>
            );
          },
          sortType: labelsSortFunc,
          accessor: "labels",
          Cell: Labels,
        },
      ] as Column<any>[],
    [labelsSortFunc, granularity],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { selectedRowIds, globalFilter, pageIndex },
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable<any>(
    {
      columns,
      data: adObjects,
      defaultColumn,
      autoResetGlobalFilter: false,
      initialState: { pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
  };

  const RenderRow = React.useCallback(
    (row: Row<any>) => {
      prepareRow(row);
      const style = (row as any).style;
      return (
        <tr
          {...row.getRowProps({ style })}
          className={row.isSelected ? "table-primary" : ""}
        >
          {row.cells.map((cell) => (
            // eslint-disable-next-line
            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
          ))}
        </tr>
      );
    },
    [prepareRow],
  );

  const [keyInputValue, setKeyInputValue] = React.useState<string | null>(null);
  const [valueInputValue, setValueInputValue] = React.useState<string | null>(
    null,
  );

  const numSelectedIds = Object.values(selectedRowIds).filter((v) => v).length;
  const keyOptions = toReactSelectLabelMulti(bds.map((v) => v.key));
  const currentBreakdown = !keyInputValue
    ? undefined
    : bds.find((v) => v.key === keyInputValue);
  const valueOptions = (currentBreakdown && currentBreakdown.choices) ?? [];

  const [bulkAddAdObjectLabel, { loading: isMutating }] = useNorthbeamMutation<
    BulkAddAdObjectLabel,
    BulkAddAdObjectLabelVariables
  >(BULK_ADD_AD_OBJECT_LABEL);

  const trySubmit = React.useCallback(async () => {
    if (!keyInputValue || !valueInputValue || isMutating) {
      return;
    }

    const selectIds = Object.keys(selectedRowIds).map(
      (k) => adObjects[parseInt(k)].id,
    );
    const variables = {
      ids: selectIds,
      labelKey: keyInputValue,
      labelValue: valueInputValue,
    };

    await bulkAddAdObjectLabel({ variables });
    setKeyInputValue(null);
    setValueInputValue(null);

    notifyLabelsCache();
  }, [
    adObjects,
    bulkAddAdObjectLabel,
    isMutating,
    selectedRowIds,
    keyInputValue,
    valueInputValue,
    setKeyInputValue,
    setValueInputValue,
  ]);

  return (
    <Styles>
      <PaginationBar {...paginationProps} />

      <nav className="navbar border-top border-left border-right">
        <ul className="nav nav-pills">
          <span className="nav-link">
            <strong>{numSelectedIds}</strong> selected
          </span>
          {(numSelectedIds > 0 && (
            <div className="form-inline">
              <div className="d-inline mr-2" style={{ width: 150 }}>
                <CreatableSelect
                  isClearable
                  options={keyOptions}
                  isDisabled={isMutating}
                  onChange={(value: any) =>
                    setKeyInputValue(value?.value ?? null)
                  }
                  value={toReactSelectLabel(keyInputValue)}
                  placeholder="Enter key..."
                />
              </div>
              <div className="d-inline mr-2" style={{ width: 150 }}>
                <CreatableSelect
                  className="mr-2"
                  isClearable
                  isDisabled={!keyInputValue || isMutating}
                  value={toReactSelectLabel(valueInputValue)}
                  onChange={(value: any) =>
                    setValueInputValue(value?.value ?? null)
                  }
                  options={valueOptions}
                  placeholder="Enter value..."
                />
              </div>
              <button
                className="btn btn-primary btn-sm"
                disabled={!keyInputValue || !valueInputValue || isMutating}
                onClick={trySubmit}
              >
                Bulk Label
              </button>
            </div>
          )) || (
            <li className="nav-item">
              <span className="nav-link">
                <strong>Pro tip:</strong> Use the checkboxes to label multiple
                Traffic Sources at once.
              </span>
            </li>
          )}
        </ul>
        <ul className="nav nav-right">
          <li className="nav-item">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </li>
        </ul>
      </nav>
      <div
        className={classNames(
          isLoading && "waiting-interstitial",
          "table-wrap",
        )}
      >
        <table {...getTableProps()} className="table table-bordered">
          <thead className="thead-light">
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>{page.map(RenderRow)}</tbody>
        </table>
      </div>
    </Styles>
  );
}

export interface GlobalFilterProps {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
}

export function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}: GlobalFilterProps) {
  const [value, setValue] = React.useState<string>(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <input
      className="form-control"
      placeholder="Filter by name..."
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
}

interface QueryParams {
  readonly showCampaignsMode: IShowCampaignsMode;
  readonly filters: Breakdown[];
  readonly granularity: IGranularity;
}

interface ControlCenterProps {
  onSubmit: (query: QueryParams) => void;
  isLoading: boolean;
  breakdownConfigs: BreakdownConfig[];
  initial: QueryParams;
}

function ControlCenter(props: ControlCenterProps) {
  const { onSubmit, isLoading, breakdownConfigs, initial } = props;

  const [proposed, setProposed] = React.useState(initial);

  React.useEffect(() => {
    setProposed(initial);
  }, [initial]);

  return (
    <ControlBar>
      <div className="flex flex-row items-center w-100">
        <ControlBarElement
          title={"Customize"}
          parentClassName="nb-control-bar-element"
          style={{ flex: "6 6 0%", maxWidth: 240 }}
        >
          <Select<{ label: string; value: IShowCampaignsMode }>
            isDisabled={isLoading}
            isClearable={false}
            isSearchable={false}
            onChange={(v) => {
              if (v) {
                setProposed({ ...proposed, showCampaignsMode: v.value });
              }
            }}
            components={{ DropdownIndicator: DBDropdown }}
            value={ShowCampaignsMode.toReactSelect(proposed.showCampaignsMode)}
            options={ShowCampaignsMode.reactSelectOptions}
          />
        </ControlBarElement>
        <ControlBarElement
          title={"Granularity"}
          parentClassName="pl-2 nb-control-bar-element"
          style={{ flex: "6 6 0%", maxWidth: 240 }}
        >
          <Select<{ label: string; value: IGranularity }>
            isDisabled={isLoading}
            isClearable={false}
            isSearchable={false}
            onChange={(v) => {
              if (v) {
                setProposed({ ...proposed, granularity: v.value });
              }
            }}
            components={{ DropdownIndicator: DBDropdown }}
            value={Granularity.toReactSelect(proposed.granularity)}
            options={Granularity.reactSelectOptions}
          />
        </ControlBarElement>
        <ControlBarElement
          title={"Filters"}
          parentClassName="pl-2 nb-control-bar-element"
          style={{ flex: "6 6 0%", maxWidth: 360 }}
        >
          <ExpandableBreakdownSelector
            disabled={isLoading}
            current={proposed.filters}
            available={breakdownConfigs}
            allConfigs={breakdownConfigs}
            onUpdate={(filters) => setProposed((v) => ({ ...v, filters }))}
          />
        </ControlBarElement>
        <ControlBarElement
          title={""}
          parentClassName="pl-2 nb-control-bar-element"
          style={{ flex: "6 6 0%", paddingTop: "1.1rem", maxWidth: 160 }}
        >
          <button
            className="btn btn-block btn-primary"
            disabled={isLoading || deepEqual(initial, proposed)}
            onClick={() => onSubmit(proposed)}
          >
            Update
          </button>
        </ControlBarElement>
      </div>
    </ControlBar>
  );
}

function DBDropdown(props: DropdownIndicatorProps<any>) {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-database"></i>
    </components.DropdownIndicator>
  );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  },
);

function parseQueryParams(query: string): QueryParams {
  const params = new URLSearchParams(query);

  const showCampaignsMode =
    ShowCampaignsMode.fromString(params.get("showCampaignsMode")) ?? "all";
  const filters = parseBreakdownList(params.get("filters")) ?? [];
  const granularity =
    Granularity.fromString(params.get("granularity")) ?? "campaign";
  return {
    showCampaignsMode,
    filters,
    granularity,
  };
}

export function makeQueryString(state: QueryParams) {
  const search = new URLSearchParams();
  search.set("filters", JSON.stringify(state.filters));
  search.set("showCampaignsMode", String(state.showCampaignsMode || "all"));
  search.set("granularity", state.granularity);
  return search;
}

const LIST_CAMPAIGNS = gql`
  query ListAdObjects(
    $activeOnly: Boolean!
    $filters: [JSONObject!]!
    $granularity: String!
  ) {
    me {
      id
      adObjects(
        activeOnly: $activeOnly
        filters: $filters
        granularity: $granularity
      ) {
        id
        adKey
        name
        status
        nbtPlatformID
        labels
      }
    }
  }
`;

const BULK_ADD_AD_OBJECT_LABEL = gql`
  mutation BulkAddAdObjectLabel(
    $ids: [ID!]!
    $labelKey: String!
    $labelValue: String!
  ) {
    bulkAddAdObjectLabel(
      ids: $ids
      labelKey: $labelKey
      labelValue: $labelValue
    ) {
      id
      labels
    }
  }
`;

enum ShowCampaignsModeEnum {
  all = "Show all",
  activeOnly = "Show active only",
}

const ShowCampaignsMode = enumType({
  enumObject: ShowCampaignsModeEnum,
  keyOrder: ["all", "activeOnly"],
});

type IShowCampaignsMode = keyof typeof ShowCampaignsModeEnum;
