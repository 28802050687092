import React from "react";

export default ({
  config,
  valueSources,
  valueSrc,
  title,
  setValueSrc,
  readonly,
}) => {
  const renderOptions = valueSources =>
    valueSources.map(([srcKey, info]) => (
      <option key={srcKey} value={srcKey}>
        {info.label}
      </option>
    ));

  const onChange = e => setValueSrc(e.target.value);

  return (
    <select
      className="custom-select"
      onChange={onChange}
      value={valueSrc}
      disabled={readonly}
    >
      {renderOptions(valueSources)}
    </select>
  );
};
