import { gql } from "@apollo/client";

export const CREATE_DATA_EXPORT_PERFORMANCE_METRICS_CONFIG = gql`
  mutation CreateDataExportPerformanceMetricsConfig(
    $data: DataExportPerformanceMetricsConfigInput!
  ) {
    createDataExportPerformanceMetricsConfig(data: $data) {
      id
    }
  }
`;
