import { getDateOnly } from "@utils/datetime";
import { Chart, PointElement } from "chart.js";
import { format } from "date-fns";
import { AnnotationData } from "./annotation-plugin-helpers";

const ANNOTATION_TOOLTIP_ID = "chartjs-annotation-tooltip";

const getOrCreateTooltip = () => {
  let tooltipEl = document.getElementById(ANNOTATION_TOOLTIP_ID);

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = ANNOTATION_TOOLTIP_ID;
    document.body.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const setTooltipPosition = (
  chart: Chart,
  point: PointElement,
  tooltipEl: HTMLElement,
) => {
  const { top, left } = chart.canvas.getBoundingClientRect();
  tooltipEl.style.opacity = "1";
  tooltipEl.style.transform = `
    translate(-${tooltipEl.offsetWidth / 2}px, -${tooltipEl.offsetHeight}px)
  `;
  tooltipEl.style.left = left + point.x + "px";
  // 36 is the height of the icon
  tooltipEl.style.top = top + chart.height - 36 + "px";
};

export const addAnnotationTooltip = (chart: Chart, data: AnnotationData) => {
  const tooltipEl = getOrCreateTooltip();
  const { point } = data;
  const date = (point as any).$context.raw?.date;

  if (date) {
    tooltipEl.innerHTML = date ? format(getDateOnly(date), "MMM d") : "";
    setTooltipPosition(chart, point, tooltipEl);
  }
};

export const showAnnotationsTooltip = (chart: Chart, data: AnnotationData) => {
  const tooltipEl = getOrCreateTooltip();
  const { point, clusteredDates } = data;

  if (clusteredDates) {
    tooltipEl.innerHTML = clusteredDates
      .map(
        (date, index) =>
          `<div
            class="${
              index + 1 === clusteredDates.length ? "" : "mb-1"
            } font-bold"
          >
            ${format(getDateOnly(date), "MMM d")}
          </div>`,
      )
      .join("");
    setTooltipPosition(chart, point, tooltipEl);
  }
};

export const removeAnnotationTooltip = () => {
  const tooltipEl = document.getElementById(ANNOTATION_TOOLTIP_ID);
  if (tooltipEl) {
    tooltipEl.style.opacity = "0";
  }
};
