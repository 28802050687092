import { gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GenericExternalLink } from "@components/generic-external-link";
import { AssignCollectorIpAddressIfNotYetAssigned } from "@nb-api-graphql-generated/AssignCollectorIpAddressIfNotYetAssigned";
import { FetchCollectorSubdomain as FetchCollectorSubdomainQuery } from "@nb-api-graphql-generated/FetchCollectorSubdomain";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import cloudflare from "./images/cloudflare.png";
import dnsIcon from "./images/dnsIcon.png";
import dnsIconCloudflare from "./images/dnsIconCloudflare.png";
import genericARecordImage from "./images/genericARecordImage.png";
import goDaddy from "./images/goDaddyARecord.png";
import googleDomains from "./images/googleDomains.png";
import menuIcon from "./images/menuIcon.png";

export default function DnsEntry() {
  const [selectedProvider, setSelectedProvider] = useState<string>("generic");
  const [ipAddress, setIpAddress] = useState<string>("");
  const [collectorSubdomain, setCollectorSubdomain] = useState<string | null>(
    null,
  );
  const options = [
    { value: "generic", label: "Generic solution" },
    { value: "cloudflare", label: "Cloudflare" },
    { value: "go_daddy", label: "GoDaddy" },
    { value: "google", label: "Google Domains" },
  ];

  const { loading: fetchSubdomainQueryLoading, data: subdomainData } =
    useNorthbeamQuery<FetchCollectorSubdomainQuery>(FETCH_COLLECTOR_SUBDOMAIN);

  const [assignCollectorIpAddressIfNotYetAssigned, { data, loading }] =
    useNorthbeamMutation<AssignCollectorIpAddressIfNotYetAssigned>(
      ASSIGN_COLLECTOR_IP_ADDRESS_IF_NOT_YET_ASSIGNED,
    );

  useEffect(() => {
    assignCollectorIpAddressIfNotYetAssigned();
  }, [assignCollectorIpAddressIfNotYetAssigned]);

  useEffect(() => {
    setIpAddress(data?.assignCollectorIpAddressIfNotYetAssigned || "");
  }, [data]);

  useEffect(() => {
    if (subdomainData?.me?.collectorSubdomain) {
      setCollectorSubdomain(subdomainData?.me?.collectorSubdomain);
    }
  }, [subdomainData?.me?.collectorSubdomain, setCollectorSubdomain]);

  let display = <></>;

  switch (selectedProvider) {
    case "generic":
      display = (
        <>
          <h3 className="mt-4 mb-3">
            <b>Generic solution</b>
          </h3>
          <ol>
            <li>
              In your DNS, add an <b>A record</b> that points the subdomain{" "}
              <code>{collectorSubdomain}</code> to <code>{ipAddress}</code>.
            </li>
            <li>
              In the below example <b>Host</b> would be{" "}
              <code>{collectorSubdomain}</code> and <b>Points to</b> would be{" "}
              <code>{ipAddress}</code>
            </li>
            <ul className="mt-2">
              <li>
                If the subdomain <code>{collectorSubdomain}</code> is already
                mapped to something, please get in touch with us so we can help
                you pick a different subdomain name.
              </li>
            </ul>
          </ol>
          <img
            className="img-fluid mt-2 mb-4"
            src={genericARecordImage}
            alt="Generic DNS A record"
          />
        </>
      );
      break;
    case "cloudflare":
      display = (
        <>
          <h3 className="mt-4 mb-3">
            <b>Cloudflare</b>
          </h3>
          <p>
            Setting up on Cloudflare resembles the other DNS providers, with one
            exception: you will want to turn off proxying. Here are instructions
            for the full setup:
          </p>
          <ol>
            <li>
              Log into your{" "}
              <GenericExternalLink href="https://dash.cloudflare.com/login">
                Cloudflare account
              </GenericExternalLink>{" "}
              and choose the domain you want to edit.
            </li>
            <li>
              Click on the <b>DNS section</b>
              <img
                className="img-fluid mt-2 mb-4"
                src={dnsIconCloudflare}
                alt="Cloudflare DNS icon screencap"
              />
            </li>
            <li>
              Add a record as shown, taking care to turn off proxying.
              <ul>
                <li>
                  Set the <b>Type</b> to <b>A</b>
                </li>
                <li>
                  Set the <b>Name</b> to <b>{collectorSubdomain}</b>
                </li>
                <li>
                  Set the <b>Content</b> to <code>{ipAddress}</code>
                </li>
                <li>
                  Set the <b>TTL</b> to <b>Auto</b>
                </li>
                <li>
                  Set the <b>Proxy status</b> to <b>DNS only</b>
                </li>
              </ul>
            </li>
          </ol>
          <img
            className="img-fluid"
            src={cloudflare}
            alt="Cloudflare screencap"
          />
        </>
      );
      break;
    case "go_daddy":
      display = (
        <>
          <h3 className="mt-4 mb-3">
            <b>GoDaddy</b>
          </h3>
          <p>
            GoDaddy has good instructions{" "}
            <GenericExternalLink href="https://www.godaddy.com/help/add-an-a-record-19238">
              here
            </GenericExternalLink>{" "}
            on how to add an <b>A record</b>.
          </p>
          <ul>
            <li>When following those instructions, please use:</li>
            <ol className="mt-2">
              <li>
                <code>{collectorSubdomain}</code> as the <b>Host</b>
                <ul className="mb-2">
                  <li>
                    If that is taken please get in touch with us so we can help
                    you pick a different subdomain name.
                  </li>
                </ul>
              </li>
              <li>
                <code>{ipAddress}</code> as the <b>Points to</b>.
              </li>
            </ol>
          </ul>
          <img
            className="img-fluid mt-2 mb-4"
            src={goDaddy}
            alt="GoDaddy DNS setup screencap"
          />
        </>
      );
      break;
    case "google":
      display = (
        <>
          <h3 className="mt-4 mb-3">
            <b>Google Domains</b>
          </h3>
          <p>
            Google Domains has good instructions{" "}
            <GenericExternalLink href="https://support.google.com/domains/answer/9211383?hl=en">
              here
            </GenericExternalLink>{" "}
            for adding an <b>A record</b>. We&apos;ve replicated the important
            parts here:
          </p>
          <ol>
            <li>
              Sign in to{" "}
              <GenericExternalLink href="http://domains.google.com/registrar">
                Google Domains
              </GenericExternalLink>
              .
            </li>
            <li>Click the domain you want to update.</li>
            <li>
              Open the menu{" "}
              <img className="img-fluid" src={menuIcon} alt="Menu icon" />.
            </li>
            <li>
              Click the DNS icon{" "}
              <img className="img-fluid" src={dnsIcon} alt="DNS icon" />.
            </li>
            <li>
              In the <b>Custom resource records</b> section, add a new record.
              (See the image below for reference)
              <ul>
                <li>
                  Enter <code>{collectorSubdomain}</code> in the first field.
                  <ul>
                    <li>
                      If there is already an entry with <b>Name</b> set to{" "}
                      <code>{collectorSubdomain}</code> in the{" "}
                      <b>Custom resource records</b> list, please get in touch
                      with us so we can help you pick a different subdomain
                      name.
                    </li>
                  </ul>
                </li>
                <li>
                  In the dropdown selector, select <b>A</b>.
                </li>
                <li>
                  Set the <b>TTL</b> to <b>5m</b>.
                </li>
                <li>
                  Enter <code>{ipAddress}</code> in the last field.
                </li>
                <li>
                  Click <b>Add</b>.
                </li>
              </ul>
            </li>
          </ol>
          <img
            className="img-fluid"
            src={googleDomains}
            alt="Google Domains screencap"
          />
        </>
      );
      break;
  }
  return (
    <>
      {(loading || fetchSubdomainQueryLoading) && <p>Please wait...</p>}
      {!loading && !fetchSubdomainQueryLoading && ipAddress && (
        <>
          <div className="row">
            <div className="col-8">
              <p>
                The following instructions walk you through adding an{" "}
                <b>A record</b> in your DNS that points to{" "}
                <code>{ipAddress}</code>.
              </p>
              <Select
                options={options}
                onChange={(choice) =>
                  setSelectedProvider(
                    (choice as { value: string; label: string }).value,
                  )
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-8">{display}</div>
          </div>
        </>
      )}
      {!loading && !fetchSubdomainQueryLoading && !ipAddress && (
        <p>
          Unfortunately, we are currently at maximum IP Address capacity. Please
          check back in a couple of hours.
        </p>
      )}
    </>
  );
}

export const ASSIGN_COLLECTOR_IP_ADDRESS_IF_NOT_YET_ASSIGNED = gql`
  mutation AssignCollectorIpAddressIfNotYetAssigned {
    assignCollectorIpAddressIfNotYetAssigned
  }
`;

export const FETCH_COLLECTOR_SUBDOMAIN = gql`
  query FetchCollectorSubdomain {
    me {
      collectorSubdomain
    }
  }
`;
