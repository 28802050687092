import { useUser } from "@components/user-context";
import {
  ConnectionStatusType,
  LoginComponentWithFrontendIntegrationProps,
} from "@pages/settings/connection-status-manager";
import { extractError } from "@utils/index";
import { launchOAuthPopup } from "@utils/oauth2-handler";
import React, { useState } from "react";
import Select from "react-select";
import { serializeError } from "serialize-error";

const regionToCountryDict: any = {
  "North America": ["Canada", "US", "Mexico", "Brazil"],
  Europe: [
    "Spain",
    "UK",
    "France",
    "Netherlands",
    "Germany",
    "Italy",
    "Sweden",
    "Belgium",
    "Poland",
    "Egypt",
    "Turkey",
    "Saudi Arabia",
    "U.A.E",
    "India",
  ],
  "Far East": ["Singapore", "Australia", "Japan"],
};

const defaultRegionCountry: any = {
  "North America": "US",
  Europe: "UK",
  "Far East": "Japan",
};

const regionNameToRegionAbbrev: any = {
  "North America": "na",
  Europe: "eu",
  "Far East": "fe",
};

export const AmazonSellerLoginRegionalComponent = ({
  setStatus,
  accounts,
}: LoginComponentWithFrontendIntegrationProps) => {
  const { user } = useUser();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [regionCountryDict, setRegionCountryDict] =
    useState(defaultRegionCountry);

  const onConnectClicked = React.useCallback(
    async (country: string, region: string) => {
      try {
        setButtonDisabled(true);

        const tokenPayload = await openRegionalAmazonSellerPopup(
          user.displayUserId,
          country,
        );

        const redirectURI = `${window.location.origin}/callbacks/amazon-seller`;

        const result = await user.callApi({
          method: "post",
          url: "/api/callbacks/amazon-seller",
          data: {
            tokenPayload,
            redirectURI,
            region,
          },
        });

        if (result.error) {
          setStatus({
            type: ConnectionStatusType.HAS_ERROR,
            errorMessage: `An error occurred: ${serializeError(
              extractError(result.error),
            )}`,
          });
        } else {
          setStatus({
            type: ConnectionStatusType.UPSERTING_ACCOUNTS,
            accountList: result.accounts,
          });
        }
      } catch (e) {
        setStatus({
          type: ConnectionStatusType.HAS_ERROR,
          errorMessage: `An error occurred: ${serializeError(extractError(e))}`,
        });
      }

      setButtonDisabled(false);
    },
    [setStatus, setButtonDisabled, user],
  );

  return (
    <>
      <table className="table" style={{ tableLayout: "fixed" }}>
        <thead>
          <tr className="tr border-none">
            <th className="border-none w-1/12"></th>
            <th className="border-none w-1/4"></th>
            <th className="border-none"></th>
            <th className="border-none w-1/5"></th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(regionToCountryDict).map((region) => {
            const hasRegionLogin = accounts.some(
              ({ name }) => name.split("- ")[1] === region,
            );
            if (hasRegionLogin) {
              // hide region login if customer's already logged in for that region
              return null;
            }

            const selectDropDownCountries = regionToCountryDict[region].map(
              (country: string) => {
                return {
                  value: country,
                  label: country,
                };
              },
            );

            const index = selectDropDownCountries.findIndex(
              (country: any) => country.value === regionCountryDict[region],
            );

            return (
              <tr key={region} className="tr text-small">
                <td></td>
                <td>{region}</td>
                <td className="text-xs break-words">
                  <Select
                    isClearable={false}
                    isSearchable={false}
                    value={selectDropDownCountries[index]}
                    options={selectDropDownCountries}
                    onChange={(object) => {
                      setRegionCountryDict({
                        ...regionCountryDict,
                        [region]: object.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <button
                    disabled={isButtonDisabled}
                    className="btn btn-primary"
                    onClick={() => {
                      onConnectClicked(
                        selectDropDownCountries[index].value,
                        regionNameToRegionAbbrev[region],
                      );
                    }}
                  >
                    {isButtonDisabled ? "Please Wait..." : "Connect"}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

async function openRegionalAmazonSellerPopup(
  clientId: string,
  country: string,
) {
  const countryToSellerCentralDict: any = {
    US: "https://sellercentral.amazon.com",
    CANADA: "https://sellercentral.amazon.ca",
    MEXICO: "https://sellercentral.amazon.com.mx",
    BRAZIL: "https://sellercentral.amazon.com.br",
    SPAIN: "https://sellercentral-europe.amazon.com",
    UK: "https://sellercentral-europe.amazon.com",
    FRANCE: "https://sellercentral-europe.amazon.com",
    GERMANY: "https://sellercentral-europe.amazon.com",
    ITALY: "https://sellercentral-europe.amazon.com",
    NETHERLANDS: "https://sellercentral.amazon.nl",
    SWEDEN: "https://sellercentral.amazon.se",
    POLAND: "https://sellercentral.amazon.pl",
    EGYPT: "https://sellercentral.amazon.eg",
    TURKEY: "https://sellercentral.amazon.com.tr",
    "SAUDI ARABIA": "https://sellercentral.amazon.sa",
    "U.A.E": "https://sellercentral.amazon.ae",
    INDIA: "https://sellercentral.amazon.in",
    BELGIUM: "https://sellercentral.amazon.com.be",
    SINGAPORE: "https://sellercentral.amazon.sg",
    AUSTRALIA: "https://sellercentral.amazon.com.au",
    JAPAN: "https://sellercentral.amazon.co.jp",
  };
  const params = new URLSearchParams();
  params.append("state", clientId);
  params.append("response_type", "code");
  params.append("refreshable", "true");
  params.append("version", "beta");
  params.append(
    "redirect_uri",
    `${window.location.origin}/callbacks/amazon-seller`,
  );
  params.append(
    "application_id",
    "amzn1.sp.solution.0df5705f-128b-4804-a46f-585700c4a64a",
  );

  const baseUrl: string = countryToSellerCentralDict[country.toUpperCase()];
  return launchOAuthPopup(
    `${baseUrl}/apps/authorize/consent?${params.toString()}`,
  );
}
