"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flattenResultRow = exports.flattenAlarmCondition = void 0;
var alerts_1 = require("../protocols/alerts");
var core_1 = require("./core");
var tree_1 = require("./tree");
function flattenAlarmCondition(ac) {
    return (0, tree_1.treeFlatMap)(ac, function (v) { return ((0, alerts_1.isSimpleAlarmCondition)(v) ? v : null); }).filter(core_1.notEmpty);
}
exports.flattenAlarmCondition = flattenAlarmCondition;
function flattenResultRow(rr) {
    return (0, tree_1.treeFlatMap)(rr, function (v) { return ((0, tree_1.isLeafNode)(v) ? v : null); }).filter(core_1.notEmpty);
}
exports.flattenResultRow = flattenResultRow;
