import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import moment from "moment";
import React from "react";
import { MetricFormat } from "@north-beam/nb-common";
import { TimeSeries } from "@north-beam/nb-common";
import {
  formatNumberApproximate,
  formatNumberExact,
} from "@north-beam/nb-common";
import { LineWithLine } from "./line-with-line-chart";
import { customTooltip, makeDatasets } from "./helpers";

interface AlertChartProps {
  format: MetricFormat;
  serieses: TimeSeriesWithColor[];
  height: number;
}

export interface TimeSeriesWithColor {
  series: TimeSeries;
  color: string;
}

export const AlertChart = (props: AlertChartProps) => {
  const { serieses, format, height } = props;

  const datasets = serieses.flatMap((series) =>
    makeDatasets(series.series, series.color, {}),
  );

  const view: ChartData<any> = {
    datasets,
  };

  const options: ChartOptions = {
    animation: undefined,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
        mode: "index",
        intersect: false,
        external: customTooltip,
        itemSort: (a: TooltipItem<"line">, b: TooltipItem<"line">) =>
          b.label.localeCompare(a.label),
        callbacks: {
          label: ({ raw }: { raw: any }) =>
            formatNumberExact(parseFloat(raw.y), format),
          title: (vs: TooltipItem<"line">[]) => {
            return vs.map(({ label }) => {
              const labelMoment = moment(label);
              if (!labelMoment.isValid()) {
                return "N/A";
              }
              return labelMoment.format("ddd, MMM D");
            })[0];
          },
          beforeLabel: (v: any) => datasets[v.datasetIndex!].label,
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        position: "right",
        grid: {
          tickLength: 5,
        },
        ticks: {
          padding: 5,
          callback: (value) =>
            formatNumberApproximate(
              typeof value === "string" ? parseFloat(value) : value,
              format,
            ),
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
          drawTicks: true,
          tickLength: 5,
        },
        ticks: {
          padding: 5,
          maxRotation: 0,
          autoSkip: true,
          autoSkipPadding: 75,
        },
        type: "timeseries",
        time: { unit: "day", parser: "YYYY-MM-DD" },
      },
    },
  };

  return <LineWithLine data={view} options={options} height={height} />;
};
