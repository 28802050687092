import _ from "lodash";
import { TimeSeriesWithColor } from "@components/reports/charts";
import {
  SimpleAlarmConditionResultRow,
  SimpleAlarmConditionV2,
} from "@north-beam/nb-common";
import { Metric as FullMetric } from "@north-beam/nb-common";
import { TimeSeries } from "@north-beam/nb-common";
import { jsonHash } from "@north-beam/nb-common";

type Metric = Pick<FullMetric, "format" | "name">;

export function makeSeriesBySubjectItem(
  subjectItemToColor: Record<string, string>,
  resultRows: SimpleAlarmConditionResultRow[],
  metric: Metric,
  ac: SimpleAlarmConditionV2,
): Record<string, TimeSeriesWithColor> {
  const keyToSerieses = _.chain(resultRows)
    .groupBy((v) => jsonHash(v.subjectItem))
    .mapValues((resultRow) => {
      const series: TimeSeries = {
        name: `${resultRow[0].subjectItemName} / ${metric.name}`,
        points: _.chain(resultRow)
          .map(({ runAt, currentValueComputed }) => ({
            date: runAt,
            value: currentValueComputed,
          }))
          .sortBy("date")
          .value(),
      };

      if (ac.anomalyCondition.type === "OutsidePercentageOfReferenceValue") {
        // TODO Fixme -- bounds need to be properly chosen
        const low = _.chain(resultRows)
          .map((resultRow) => ({
            date: resultRow.runAt,
            value: Number(resultRow.normalityBounds[0]),
          }))
          .sortBy("date")
          .value();
        const high = _.chain(resultRows)
          .map((resultRow) => ({
            date: resultRow.runAt,
            value: Number(resultRow.normalityBounds[1]),
          }))
          .sortBy("date")
          .value();

        series.bandPoints = [low, high];
      } else {
        const index =
          ac.anomalyCondition.type === "AboveReferenceValue" ? 1 : 0;
        series.comparePoints = _.chain(resultRows)
          .map((resultRow) => ({
            date: resultRow.runAt,
            value: Number(resultRow.normalityBounds[index]),
          }))
          .sortBy("date")
          .value();
      }
      return series;
    })
    .value();

  const orderedKeys = Object.keys(keyToSerieses).sort();
  const allSerieses = _.chain(orderedKeys)
    .map(
      (v) =>
        ({
          series: keyToSerieses[v],
          color: subjectItemToColor[v],
        } as TimeSeriesWithColor),
    )
    .zip(orderedKeys)
    .map(([s, key]) => [key, s])
    .fromPairs()
    .value();

  return allSerieses;
}
