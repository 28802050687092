import { getDateOnly } from "@utils/datetime";
import { Chart, ChartMeta, ChartEvent, Scale, PointElement } from "chart.js";
import { eachDayOfInterval } from "date-fns";
import { chunk, countBy, groupBy, sumBy, intersection } from "lodash";
import { AnnotationDataset } from "../hooks/use-build-annotation-dataset";
import { buildMessageSvg } from "./annotation-svgs";

export const CUSTOM_ANNOTATION_ID = "annotation-plugin";

const MAX_ALLOWED_DATE_RANGE_FOR_ADDING_ANNOTATIONS = 30;
export const getIsAtMaxDateRange = (annotationData: AnnotationData[]) => {
  const dateRange = annotationData.length;
  return dateRange > MAX_ALLOWED_DATE_RANGE_FOR_ADDING_ANNOTATIONS;
};

export const getTimeSeries = (chart: Chart): Scale | undefined => {
  const timeSeries = (chart.boxes as Scale[]).find(({ type }) =>
    ["timeseries", "time"].includes(type),
  );

  return timeSeries;
};

export const getAnnotationPoints = (
  chart: Chart & { _metasets?: (ChartMeta & { data: PointElement[] })[] },
) => {
  const annotationMetaset =
    chart._metasets?.find(({ label }) => label === CUSTOM_ANNOTATION_ID)
      ?.data ?? [];
  return annotationMetaset;
};

export type AnnotationData = {
  date: string;
  showAnnotations?: () => void;
  createAnnotation?: () => void;
  point: PointElement;
  clusteredDates?: string[];
  clusteredAnnotationCount?: number;
};

export const buildClusteredDates = (timeSeries: Scale): string[][] => {
  const dates = eachDayOfInterval({
    start: getDateOnly(timeSeries.min),
    end: getDateOnly(timeSeries.max),
  }).map((date) => date.toISOString().split("T")[0]);
  const dateTicks = getDateTicks(timeSeries);
  const chunkNumber = Math.round(dates.length / dateTicks.length);

  const firstCluster = dates.splice(0, Math.round(chunkNumber / 2));
  const clusteredDates = [firstCluster, ...chunk(dates, chunkNumber)];
  if (clusteredDates.length > dateTicks.length) {
    const lastClusteredPoint = clusteredDates.pop();
    if (lastClusteredPoint && clusteredDates[clusteredDates.length - 1]) {
      clusteredDates[clusteredDates.length - 1].push(...lastClusteredPoint);
    }
  }

  return clusteredDates;
};

const getDateTicks = (timeSeries: Scale) => {
  const annotationPoints = getAnnotationPoints(timeSeries.chart);
  const dateTicks = timeSeries.ticks.filter(({ value }) => {
    return annotationPoints.find(
      ({ $context }: any) => value === $context.parsed.x,
    );
  });

  return dateTicks.map(
    ({ value }) => new Date(value).toISOString().split("T")[0],
  );
};

export const getHasDateInCluster = (
  clusteredDate: string[],
  annotatedDates: string[],
) => {
  const overlap = intersection(clusteredDate, annotatedDates);
  return overlap.length > 0;
};

export const getAnnotationData = (chart: Chart): AnnotationData[] => {
  const timeSeries = getTimeSeries(chart);
  const annotationDataset = chart.data.datasets?.find(
    (meta) => meta.label === CUSTOM_ANNOTATION_ID,
  ) as AnnotationDataset | undefined;

  if (annotationDataset?.metadata && timeSeries) {
    const chartData: AnnotationData[] = [];
    const { showAnnotations, createAnnotation, annotatedDates } =
      annotationDataset.metadata;
    const annotatedDatesCount = countBy(annotatedDates);
    const annotationPoints = getAnnotationPoints(chart);
    const groupedAnnotationPoints = groupBy(
      annotationPoints,
      (point: any) => point.$context.raw.date,
    );
    const clusteredDates = buildClusteredDates(timeSeries);
    const dateTicks = getDateTicks(timeSeries);

    dateTicks.forEach((tickDate: string, index: number) => {
      const hasDate = getHasDateInCluster(
        clusteredDates[index],
        annotatedDates,
      );

      clusteredDates[index]?.forEach((date: string) => {
        if (hasDate && date === tickDate) {
          const clusteredAnnotationCount = sumBy(
            clusteredDates[index],
            (date) => annotatedDatesCount[date] ?? 0,
          );
          chartData.push({
            date,
            showAnnotations: () =>
              showAnnotations({
                startDate: clusteredDates[index][0],
                endDate:
                  clusteredDates[index][clusteredDates[index].length - 1],
              }),
            point: groupedAnnotationPoints[date]?.[0] ?? ({} as PointElement),
            clusteredAnnotationCount,
            clusteredDates: intersection(annotatedDates, clusteredDates[index]),
          });
        } else {
          chartData.push({
            date,
            createAnnotation: () => createAnnotation(date),
            point: groupedAnnotationPoints[date]?.[0] ?? ({} as PointElement),
          });
        }
      });
    });

    return chartData;
  }

  return [];
};

export type IconPosition = {
  width: number;
  height: number;
  x: number;
  y: number;
  svg: string;
  raw?: AnnotationDataset["data"];
};

export const checkIfOnIcon = (position: IconPosition, event: ChartEvent) => {
  const { x, y, height, width } = position;
  if (event.x && event.y) {
    return (
      event.x >= x &&
      event.x <= x + width &&
      event.y >= y &&
      event.y <= y + height
    );
  }
  return false;
};

export const drawIcon = (
  { ctx }: Chart,
  { x, y, height, width, svg }: IconPosition,
) => {
  ctx.save();
  const img = new Image();
  img.src = svg;
  ctx.drawImage(img, x, y, width, height);
  ctx.restore();
};

export const drawAnnotationIcons = (chart: Chart) => {
  const { chartArea } = chart;
  getAnnotationData(chart).forEach((data: AnnotationData) => {
    if ("showAnnotations" in data) {
      const iconPosition = buildMessageSvg(
        data.point,
        chartArea.bottom,
        data.clusteredAnnotationCount ?? 0,
      );
      drawIcon(chart, iconPosition);
    }
  });
};
