import React, { useState } from "react";

interface Props {
  text: string;
}

export const ClickableCopyIcon = ({ text }: Props) => {
  const [isClickingCopyIcon, setIsClickingCopyIcon] = useState<boolean>(false);

  const copy = () => {
    window.navigator.clipboard.writeText(text);
  };

  return (
    <i
      className={isClickingCopyIcon ? "fa-regular fa-copy" : "far fa-copy"}
      role="button"
      onMouseDown={() => setIsClickingCopyIcon(true)}
      onMouseUp={() => setIsClickingCopyIcon(false)}
      onClick={copy}
    />
  );
};
