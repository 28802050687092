type SearchableParams = URLSearchParams | string | Record<string, string>;

export function mergeParams(
  existing: SearchableParams,
  ...other: SearchableParams[]
): URLSearchParams {
  const allSearchParams = [existing, ...other].map((value) => {
    if (value instanceof URLSearchParams) {
      return value;
    }
    return new URLSearchParams(value);
  });

  const searchParamHash = allSearchParams.reduce((acc, params) => {
    return { ...acc, ...Object.fromEntries(params) };
  }, {});

  return new URLSearchParams(searchParamHash);
}
