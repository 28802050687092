import React from "react";
import { UrlParamPair } from "@pages/settings/utm-guide/index";
import { CopyButton as LibraryCopyButton } from "@lib/buttons";
import { FormControl } from "@lib/forms";

interface CopyButtonProps {
  urlParamPairs: UrlParamPair[];
  hideQuestionMark?: boolean;
}

export function CopyButton({
  urlParamPairs,
  hideQuestionMark,
}: CopyButtonProps) {
  const urlParamString = urlParamPairs
    .map(({ key, value }) => `${key}=${value}`)
    .join("&");

  return (
    <div>
      <LibraryCopyButton
        valueToCopy={urlParamString}
        noOutline
        className="mb-3 mt-2"
      >
        Copy parameters
      </LibraryCopyButton>

      <FormControl
        as="textarea"
        rows={4}
        className="mb-2"
        style={{ wordBreak: "break-all" }}
        value={`${hideQuestionMark ? "" : "?"}${urlParamString}`}
        readOnly
      />
    </div>
  );
}
