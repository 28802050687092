import classNames from "classnames";
import React, { ReactNode } from "react";
import ReactModal, { Props } from "react-modal";

// This is needed so screen readers don't see main content when modal is opened
ReactModal.setAppElement("#root");

interface ModalProps extends Props {
  isOpen: boolean;
  title?: string;
  description?: string;
  children: ReactNode;
  height?: string;
  width?: string;
  maxHeight?: string;
  maxWidth?: string;
  shouldCloseOnEsc?: boolean;
}

const DEFAULT_HEIGHT = "fit-content";
const DEFAULT_MAX_HEIGHT = "90%";
const DEFAULT_WIDTH = "fit-content";

export const Modal = ({
  title,
  description,
  height,
  maxHeight,
  width,
  maxWidth,
  children,
  isOpen,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = false,
  ...restProps
}: ModalProps) => {
  return (
    <ReactModal
      {...restProps}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpen}
      style={{
        content: {
          top: "50%",
          left: "50%",
          // Offset for the top navbar
          marginTop: "32px",
          height: height || DEFAULT_HEIGHT,
          maxHeight: maxHeight || DEFAULT_MAX_HEIGHT,
          width: width || DEFAULT_WIDTH,
          maxWidth: maxWidth || DEFAULT_WIDTH,
          transform: "translate(-50%, -50%)",
          padding: "24px",
          borderRadius: "8px",
          overflow: "hidden",
        },
        overlay: {
          zIndex: 2001,
          backgroundColor: "rgba(30, 30, 30, 0.47)",
        },
      }}
    >
      {title && (
        <h2
          className={classNames(
            "font-semibold text-xl",
            description ? "mb-2" : "mb-4",
          )}
        >
          {title}
        </h2>
      )}
      {description && (
        <p className="text-sm mb-4 text-[#717680]">{description}</p>
      )}
      {children}
    </ReactModal>
  );
};
