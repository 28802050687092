import { ParseResult } from "papaparse";
import React, { useEffect, useState } from "react";
import { CSVReader } from "react-papaparse";
import { useUser } from "@components/user-context";
import { FetchHistoricalEnquireData } from "@nb-api-graphql-generated/FetchHistoricalEnquireData";
import {
  SetEnquireAttributionQuestionIds,
  SetEnquireAttributionQuestionIdsVariables,
} from "@nb-api-graphql-generated/SetEnquireAttributionQuestionIds";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import {
  FETCH_ATTRIBUTION_QUESTION_IDS,
  FETCH_HISTORICAL_ENQUIRE_DATA,
  SET_ENQUIRE_ATTRIBUTION_QUESTION_IDS,
} from "../queries";

export const HistoricalDataCSVUploader = () => {
  const { user } = useUser();
  const [uploadFileError, setUploadFileError] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);

  const { data } = useNorthbeamQuery<FetchHistoricalEnquireData>(
    FETCH_HISTORICAL_ENQUIRE_DATA,
    {},
  );

  const [setEnquireAttributionQuestionId] = useNorthbeamMutation<
    SetEnquireAttributionQuestionIds,
    SetEnquireAttributionQuestionIdsVariables
  >(SET_ENQUIRE_ATTRIBUTION_QUESTION_IDS, {
    refetchQueries: [{ query: FETCH_ATTRIBUTION_QUESTION_IDS }],
  });

  useEffect(() => {
    const fileNames = data?.me?.historicalEnquireDataFileNames;
    if (fileNames && fileNames.length !== 0) {
      setUploadedFiles(fileNames);
    }
  }, [data?.me?.historicalEnquireDataFileNames]);

  const validateFileAndUpload = async (
    results: ParseResult<any>[],
    file: File,
  ) => {
    setUploadFileError("");

    if (results.length < 2) {
      setUploadFileError(
        "Not enough historical data. Please set the attribution question ID manually.",
      );
      return;
    }

    const headers = results[0];
    const firstLine = results[1];

    if (headers.errors.length !== 0 || firstLine.errors.length !== 0) {
      setUploadFileError("Error parsing CSV");
      return;
    }

    const questionIdLocation = headers.data.indexOf("question_id");

    if (questionIdLocation === -1) {
      setUploadFileError("File does not include question ID");
      return;
    }

    if (firstLine.data.length < questionIdLocation - 1) {
      setUploadFileError("Error parsing CSV");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    await user
      .callApi({
        url: "/api/uploads/historicalEnquireData",
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch(() => {
        setUploadFileError(
          "Internal server error, please contact Northbeam support.",
        );
      });

    await setEnquireAttributionQuestionId({
      variables: {
        attributionQuestionIds: [firstLine.data[questionIdLocation]],
        withReplacement: false,
      },
    });

    setUploadedFiles([...uploadedFiles, file.name]);
  };

  return (
    <>
      <CSVReader
        onDrop={(data, file) => validateFileAndUpload(data, file)}
        onError={setUploadFileError}
        style={{}}
        config={{}}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
      {uploadFileError && <p style={{ color: "red" }}> {uploadFileError} </p>}

      <div className="mt-3">
        {uploadedFiles.length !== 0 && <p>You have uploaded these files:</p>}
        {uploadedFiles?.map((fileName) => (
          <p key={fileName} className="mb-1">
            {fileName.split("/").pop()}
          </p>
        ))}
      </div>
    </>
  );
};
