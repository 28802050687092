import { PrettyBenchmarkAttributionWindowNames } from "@/constants/benchmarking";
import { GenericTooltip } from "@components/tooltip-container";
import { FetchBenchmarkSettings_me_benchmarks_benchmarkSettings_customBenchmarks } from "@nb-api-graphql-generated/FetchBenchmarkSettings";
import { FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks } from "@nb-api-graphql-generated/FetchPlatformBenchmarks";
import {
  BenchmarkAttributionWindowEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";
import { AdIcon } from "@pages/objects/utils";
import { getPrettyBenchmark } from "@utils/benchmarking";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { NoDataNotification } from "./notification";

export type BenchmarkCardProps = {
  title: string;
  platform: string;
  cac:
    | FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks["cac"]
    | FetchBenchmarkSettings_me_benchmarks_benchmarkSettings_customBenchmarks["cac"];
  roas:
    | FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks["roas"]
    | FetchBenchmarkSettings_me_benchmarks_benchmarkSettings_customBenchmarks["roas"];
  loading: boolean;
  ignoreNoData?: boolean;
  showNbCalculatedBenchmarks?: boolean;
  nbCalculatedBenchmarks?: FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks;
  target: string;
  benchmarkType: BenchmarkTypeEnum;
  attributionWindow: BenchmarkAttributionWindowEnum;
};

const TableCell = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={classNames("table-cell text-center", className)}>
    {children}
  </div>
);
const HeaderTableCell = ({ children }: PropsWithChildren<unknown>) => (
  <TableCell className="font-semibold py-3 rounded-lg">{children}</TableCell>
);
const BodyTableCell = ({ children }: PropsWithChildren<unknown>) => (
  <TableCell className="py-3">{children}</TableCell>
);

const BenchmarkTableCell = ({
  benchmarkType,
  customerType,
  benchmark,
  showCustomBenchmarks,
  nbCalculatedBenchmarks,
}: {
  customerType: "firstTime" | "blended" | "returning";
  showCustomBenchmarks: boolean;
  benchmarkType: BenchmarkTypeEnum;
  benchmark: number | null | undefined;
  nbCalculatedBenchmarks?: FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks;
}) => {
  return (
    <BodyTableCell>
      <div>{getPrettyBenchmark(benchmarkType, benchmark)}</div>
      {showCustomBenchmarks && nbCalculatedBenchmarks && (
        <div className="text-xs font-light text-subtitle">
          (
          {getPrettyBenchmark(
            benchmarkType,
            nbCalculatedBenchmarks?.[
              benchmarkType === "CAC" ? "cac" : "roas"
            ]?.[customerType],
          )}
          )
        </div>
      )}
    </BodyTableCell>
  );
};
export const BenchmarkCard = ({
  title,
  platform,
  cac,
  roas,
  loading,
  ignoreNoData,
  nbCalculatedBenchmarks,
  showNbCalculatedBenchmarks = false,
  target,
  benchmarkType,
  attributionWindow,
}: BenchmarkCardProps) => {
  const dataUnavailable =
    !loading &&
    !cac?.blended &&
    !cac?.returning &&
    !cac?.firstTime &&
    !roas?.blended &&
    !roas?.returning &&
    !roas?.firstTime;
  const isGreyedOut = loading || dataUnavailable;
  return (
    <div className="p-7 border rounded-xl w-full relative">
      {!ignoreNoData && dataUnavailable && (
        <NoDataNotification className="z-[1]" />
      )}
      <div className={classNames("flex", `${isGreyedOut && "opacity-25"}`)}>
        <AdIcon
          sizeInEM={1.5}
          platform={platform}
          style={{ marginTop: "0.05rem" }}
        />
        <div className="font-bold mb-3">
          {title}
          <div className="text-sm text-subtitle font-light">
            {PrettyBenchmarkAttributionWindowNames[attributionWindow]}{" "}
            {benchmarkType} Target: {target}
          </div>
        </div>
      </div>

      <div className="border rounded-lg">
        <div
          className={classNames(
            `table border-collapse w-full ${isGreyedOut && "opacity-25"} mb-0`,
          )}
        >
          <div className="table-header-group bg-[#FCFCFF]  rounded-lg border-b-[1px] ">
            <div className="table-row  ">
              <HeaderTableCell>
                Metric{" "}
                <GenericTooltip content="The unit for measuring your performance over time" />
              </HeaderTableCell>
              <HeaderTableCell>
                Blended{" "}
                <GenericTooltip content="Weighted average of first-time and returning attribution" />
              </HeaderTableCell>
              <HeaderTableCell>
                First Time{" "}
                <GenericTooltip content="Measurement for first-time customer attribution" />
              </HeaderTableCell>
              <HeaderTableCell>
                Returning{" "}
                <GenericTooltip content="Measurement for returning customer attribution" />
              </HeaderTableCell>
            </div>
          </div>
          <div className="table-row-group border-b-[1px]">
            <BodyTableCell>
              <div>CAC</div>
              {showNbCalculatedBenchmarks && (
                <div className="text-xs font-light text-subtitle">
                  (NB Benchmark CAC)
                </div>
              )}
            </BodyTableCell>
            <BenchmarkTableCell
              benchmark={cac?.blended}
              customerType={"blended"}
              showCustomBenchmarks={showNbCalculatedBenchmarks}
              benchmarkType={BenchmarkTypeEnum.CAC}
              nbCalculatedBenchmarks={nbCalculatedBenchmarks}
            />
            <BenchmarkTableCell
              benchmark={cac?.firstTime}
              customerType={"firstTime"}
              showCustomBenchmarks={showNbCalculatedBenchmarks}
              benchmarkType={BenchmarkTypeEnum.CAC}
              nbCalculatedBenchmarks={nbCalculatedBenchmarks}
            />
            <BenchmarkTableCell
              benchmark={cac?.returning}
              customerType={"returning"}
              showCustomBenchmarks={showNbCalculatedBenchmarks}
              benchmarkType={BenchmarkTypeEnum.CAC}
              nbCalculatedBenchmarks={nbCalculatedBenchmarks}
            />
          </div>
          <div className="table-row-group">
            <BodyTableCell>
              <div>ROAS</div>
              {showNbCalculatedBenchmarks && (
                <div className="text-xs font-light text-subtitle">
                  (NB Benchmark ROAS)
                </div>
              )}
            </BodyTableCell>
            <BenchmarkTableCell
              benchmark={roas?.blended}
              customerType={"blended"}
              showCustomBenchmarks={showNbCalculatedBenchmarks}
              benchmarkType={BenchmarkTypeEnum.ROAS}
              nbCalculatedBenchmarks={nbCalculatedBenchmarks}
            />
            <BenchmarkTableCell
              benchmark={roas?.firstTime}
              customerType={"firstTime"}
              showCustomBenchmarks={showNbCalculatedBenchmarks}
              benchmarkType={BenchmarkTypeEnum.ROAS}
              nbCalculatedBenchmarks={nbCalculatedBenchmarks}
            />
            <BenchmarkTableCell
              benchmark={roas?.returning}
              customerType={"returning"}
              showCustomBenchmarks={showNbCalculatedBenchmarks}
              benchmarkType={BenchmarkTypeEnum.ROAS}
              nbCalculatedBenchmarks={nbCalculatedBenchmarks}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
