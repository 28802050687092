import React from "react";
import { Annotation } from "@features/custom-annotations";
import { AnnotationEdit } from "./annotation-edit";
import { AnnotationMetadata } from "./annotation-metadata";

interface AnnotationCardProps {
  annotation: Annotation;
  editAnnotation: (annotation: Annotation) => void;
}

export const AnnotationCard = ({
  annotation,
  editAnnotation,
}: AnnotationCardProps) => (
  <div>
    <AnnotationMetadata
      annotatedDate={annotation.annotatedDate}
      updatedAt={annotation.updatedAt}
      user={annotation.user}
    />
    <div className="bg-[#F8F9F8] py-3 px-4 rounded-md relative group/edit">
      {annotation.description}
      <AnnotationEdit annotation={annotation} editAnnotation={editAnnotation} />
    </div>
  </div>
);
