import classNames from "classnames";
import Interweave from "interweave";
import React from "react";
import { HTMLTooltip, InfoCircle } from "./tooltip-container";

interface MetricHeaderProps {
  name: string;
  value: string;
  breakOnSpace?: boolean;
  children?: React.ReactNode | React.ReactNodeArray | null;
  align?: "left" | "center" | "right";
  height?: number;
  descriptionHTML?: string;
  className?: string;
  titleClassName?: string;
  showInfoIcon?: boolean;
}

export const MetricHeader = ({
  name,
  value,
  breakOnSpace,
  children,
  align,
  height,
  descriptionHTML,
  className,
  titleClassName,
  showInfoIcon = true,
}: MetricHeaderProps) => {
  let inner = breakOnSpace ? (
    <Interweave content={name.replace(" ", "<br />")} />
  ) : (
    <Interweave content={name} />
  );

  inner = (
    <div className={className}>
      <h6 style={{ textAlign: align, height: `${height ?? 2}rem` }}>
        <div className={classNames("text-[#999A9A] text-xs", titleClassName)}>
          {inner} {showInfoIcon && descriptionHTML && <InfoCircle />}
        </div>
      </h6>
      {value && <h6 className="my-0 text-sm">{value}</h6>}
      {children}
    </div>
  );

  if (descriptionHTML) {
    inner = (
      <HTMLTooltip html={descriptionHTML} noInfoCircle>
        {inner}
      </HTMLTooltip>
    );
  }

  return inner;
};
