import React from "react";
import { Checkbox } from "@shared/checkbox";

interface Props<T> {
  title: string;
  options: {
    id: T;
    label: string;
    isSelected: boolean;
    isDisabled: boolean;
  }[];
  onChange(id: T, value: boolean): void;
  onChangeAll(value: boolean): void;
}

export function Section<T>({
  title,
  options,
  onChange,
  onChangeAll,
}: Props<T>) {
  return (
    <div>
      <h4
        className="sticky top-0 text-lg font-normal text-sm m-0 pb-1.5 pt-2
           border-b-2 border-gray-100 bg-white"
      >
        {title}
      </h4>

      <div className="text-[10px] mt-5 mb-5">
        <button
          className="mr-8 text-blue-500 hover:text-blue-600"
          onClick={() => onChangeAll(true)}
        >
          Select All
        </button>
        <button
          className="text-blue-500 hover:text-blue-600"
          onClick={() => onChangeAll(false)}
        >
          Clear All
        </button>
      </div>

      <div className="text-[12px] font-light space-y-4 pb-9">
        {options.map((option) => (
          <div key={String(option.id)}>
            <Checkbox
              inputClassName="w-3 h-3 mr-0.5"
              isDisabled={option.isDisabled}
              value={option.isSelected}
              label={option.label}
              onChange={(val) => onChange(option.id, val)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
