// eslint-disable-next-line @typescript-eslint/ban-types
export type RowWithChildren<RowType extends {}> = RowType & {
  children: RowWithChildren<RowType>[];
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function walk<RowType extends {}>(
  root: RowWithChildren<RowType>,
  func: (row: RowWithChildren<RowType>) => void,
) {
  func(root);
  for (const row of root.children) {
    walk(row, func);
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function leaves<RowType extends {}>(
  root: RowWithChildren<RowType>,
): RowWithChildren<RowType>[] {
  const rv: RowWithChildren<RowType>[] = [];
  walk(root, (node) => {
    if (node.children.length === 0) {
      rv.push(node);
    }
  });
  return rv;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function map<A extends {}, B extends {}>(
  root: RowWithChildren<A>,
  func: (a: RowWithChildren<A>, level: number) => B,
): RowWithChildren<B> {
  return mapInner(root, func, 0);
}

// eslint-disable-next-line @typescript-eslint/ban-types
function mapInner<A extends {}, B extends {}>(
  root: RowWithChildren<A>,
  func: (a: RowWithChildren<A>, level: number) => B,
  level: number,
): RowWithChildren<B> {
  const rv: RowWithChildren<B> = {
    ...func(root, level),
    children: [],
  };

  for (const row of root.children) {
    rv.children.push(mapInner(row, func, level + 1));
  }

  return rv;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function flatten<A extends {}>(root: RowWithChildren<A>): A[] {
  const rv: A[] = [];
  walk(root, (node) => {
    const copy: any = { ...node };
    delete copy.children;
    rv.push(copy);
  });
  return rv;
}
