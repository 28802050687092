export enum MetricCategory {
  NORTHBEAM = "Northbeam",
  FORECASTED_NORTHBEAM = "Forecasted Northbeam",
  LTV = "LTV",

  SUBSCRIPTION = "Subscription metrics",

  FACEBOOK = "Facebook reported",
  TIK_TOK = "TikTok reported",
  GOOGLE = "Google reported",
  YOU_TUBE = "YouTube",
}

export interface MetricConfig {
  name: string;
  visible: boolean;
}

export interface ColumnConfig extends MetricConfig {
  width: number;
}

export type CategoryGroupedConfig<T extends MetricConfig> = Record<
  MetricCategory,
  T[]
>;

// TODO: Figure out a better way to create categories
const metricCategoryFilter: Record<
  MetricCategory,
  (config: MetricConfig) => boolean
> = {
  [MetricCategory.NORTHBEAM]: ({ name }) =>
    !(
      name.toLowerCase().includes("ltv") ||
      name.toLowerCase().includes("forecasted") ||
      name.toLowerCase().includes("fb ") ||
      name.toLowerCase().includes("google ") ||
      name.toLowerCase().includes("tiktok ") ||
      name.toLowerCase().includes("subscription")
    ),
  [MetricCategory.FORECASTED_NORTHBEAM]: ({ name }) =>
    name.toLowerCase().includes("forecasted"),
  [MetricCategory.LTV]: ({ name }) => name.toLowerCase().includes("ltv"),
  [MetricCategory.SUBSCRIPTION]: ({ name }) =>
    name.toLowerCase().includes("subscription"),

  [MetricCategory.FACEBOOK]: ({ name }) => name.toLowerCase().includes("fb "),
  [MetricCategory.TIK_TOK]: ({ name }) =>
    name.toLowerCase().includes("tiktok "),
  [MetricCategory.GOOGLE]: ({ name }) => name.toLowerCase().includes("google "),
  [MetricCategory.YOU_TUBE]: () => false,
};

export const createMetricCategories = <T extends MetricConfig>(
  configs: T[],
) => {
  return Object.values(MetricCategory).reduce<CategoryGroupedConfig<T>>(
    (acc: CategoryGroupedConfig<T>, category: string) => {
      acc[category as MetricCategory] = configs.filter(
        metricCategoryFilter[category as MetricCategory],
      );
      return acc;
    },
    {} as CategoryGroupedConfig<T>,
  );
};
