import { VIRTUAL_PAGE_SIZE } from "./constant";
import { OrderTableProps } from "./orders-table";

export type OrderTableState = {
  rows: OrderTableProps["orders"];
  skip: number;
  requestedSkip: number;
  take: number;
  totalCount: number;
  loading: boolean;
  lastQuery: string;
};
export const initialState: OrderTableState = {
  rows: [],
  skip: 0,
  requestedSkip: 0,
  take: VIRTUAL_PAGE_SIZE * 2,
  totalCount: 0,
  loading: false,
  lastQuery: "",
};

type UpdateRowAction = {
  type: "UPDATE_ROWS";
  payload: Pick<OrderTableState, "skip" | "rows" | "totalCount">;
};
type StartLoadingAction = {
  type: "START_LOADING";
  payload: Pick<OrderTableState, "requestedSkip" | "take">;
};

type RequestErrorAction = {
  type: "REQUEST_ERROR";
};

type FetchInitAction = {
  type: "FETCH_INIT";
};
type UpdateQueryAction = {
  type: "UPDATE_QUERY";
  payload: Pick<OrderTableState, "lastQuery">;
};

type ResetAction = {
  type: "RESET";
};
export type OrderAction =
  | UpdateRowAction
  | StartLoadingAction
  | RequestErrorAction
  | FetchInitAction
  | UpdateQueryAction
  | ResetAction;

export function reducer(
  state: OrderTableState,
  action: OrderAction,
): OrderTableState {
  switch (action.type) {
    case "UPDATE_ROWS":
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case "START_LOADING":
      return {
        ...state,
        requestedSkip: action.payload.requestedSkip,
        take: action.payload.take,
      };
    case "REQUEST_ERROR":
      return {
        ...state,
        loading: false,
      };
    case "FETCH_INIT":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_QUERY":
      return {
        ...state,
        lastQuery: action.payload.lastQuery,
      };
    case "RESET":
      return { ...initialState };
    default:
      return state;
  }
}
