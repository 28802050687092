import {
  CREATE_CUSTOM_ANNOTATION,
  DELETE_CUSTOM_ANNOTATION,
  GET_CUSTOM_ANNOTATIONS,
  UPDATE_CUSTOM_ANNOTATION,
} from "@gql/custom-annotations";
import {
  DeleteCustomAnnotation,
  DeleteCustomAnnotationVariables,
} from "@nb-api-graphql-generated/DeleteCustomAnnotation";
import {
  CreateCustomAnnotation,
  CreateCustomAnnotationVariables,
} from "@nb-api-graphql-generated/CreateCustomAnnotation";
import { useNorthbeamLazyQuery, useNorthbeamMutation } from "@utils/hooks";
import { useCallback } from "react";
import {
  UpdateCustomAnnotation,
  UpdateCustomAnnotationVariables,
} from "@nb-api-graphql-generated/UpdateCustomAnnotation";
import {
  GetClientCustomCustomAnnotations,
  GetClientCustomCustomAnnotationsVariables,
} from "@nb-api-graphql-generated/GetClientCustomCustomAnnotations";

export const useAnnotations = () => {
  const {
    loadData: fetchAnnotations,
    data: { loading: isAnnotationsLoading, data },
  } = useNorthbeamLazyQuery<
    GetClientCustomCustomAnnotations,
    GetClientCustomCustomAnnotationsVariables
  >(GET_CUSTOM_ANNOTATIONS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "no-cache",
  });

  const [createAnnotationMutation, { loading: isCreateAnnotationLoading }] =
    useNorthbeamMutation<
      CreateCustomAnnotation,
      CreateCustomAnnotationVariables
    >(CREATE_CUSTOM_ANNOTATION);

  const createAnnotation = useCallback(
    async (description: string, annotatedDate: string) => {
      await createAnnotationMutation({
        variables: {
          description,
          annotatedDate,
        },
      });
    },
    [createAnnotationMutation],
  );

  const [updateAnnotationMutation, { loading: isUpdateAnnotationLoading }] =
    useNorthbeamMutation<
      UpdateCustomAnnotation,
      UpdateCustomAnnotationVariables
    >(UPDATE_CUSTOM_ANNOTATION);

  const updateAnnotation = useCallback(
    async (id: string, description: string) => {
      await updateAnnotationMutation({
        variables: {
          id,
          description,
        },
      });
    },
    [updateAnnotationMutation],
  );

  const [deleteAnnotationMutation, { loading: isDeleteAnnotationLoading }] =
    useNorthbeamMutation<
      DeleteCustomAnnotation,
      DeleteCustomAnnotationVariables
    >(DELETE_CUSTOM_ANNOTATION);

  const deleteAnnotation = useCallback(
    async (id: string) => {
      await deleteAnnotationMutation({
        variables: { id },
      });
    },
    [deleteAnnotationMutation],
  );

  return {
    annotations: data?.me?.clientCustomAnnotations || [],
    isAnnotationsLoading:
      isAnnotationsLoading ||
      isCreateAnnotationLoading ||
      isUpdateAnnotationLoading ||
      isDeleteAnnotationLoading,
    fetchAnnotations,
    createAnnotation,
    deleteAnnotation,
    updateAnnotation,
  };
};
