import React from "react";
import BaseErrorPage from "./base-error-page";

export default function ErrorScreen() {
  return (
    <BaseErrorPage
      pageName="ErrorScreen"
      pageBody={
        <>
          <h4 className="mt-4 text-center">
            <b>We&apos;ve run into an issue processing your request.</b>{" "}
            <p className="mt-4 text-center">What to do: </p>
            <div className="mt-4 text-left">
              <ol>
                <li className="mt-2">
                  Click <b>refresh</b> below.
                </li>
                <li className="mt-2">
                  If that does not work, try again in <b>2 minutes.</b>
                </li>
                <li className="mt-2">
                  If the page is still not working, please contact us at your
                  convenience. We will be in touch ASAP.
                </li>
              </ol>
            </div>
          </h4>
          <div>
            <button
              tabIndex={40}
              className="btn btn-primary mt-4 mb-2"
              onClick={() => window.location.reload()}
            >
              Refresh
            </button>
          </div>
        </>
      }
    />
  );
}
