import React, { useEffect, useState } from "react";

import {
  defaultOrderPageSorting,
  useAttributedAtom,
  useCustomerTagsAtom,
  useDiscountCodesAtom,
  useNumberOfOrderItemsAtom,
  useNumberOfTouchpointsAtom,
  useOrderIdAtom,
  useOrderPageSortingAtom,
  useOrderTagsAtom,
  useOrderTypesAtom,
  useOrderValueAtom,
  useProductsPurchasedAtom,
  useSubscriptionsAtom,
} from "@/atoms/order-page-atoms";
import { useOrderQueriesLoading } from "@hooks/use-order-page-loading";
import {
  useOrdersPageFilters,
  useOrdersQueryParams,
} from "@hooks/use-orders-filters";
import { Badge } from "@shared/badge";
import { PrimaryButton, SecondaryButton } from "@shared/buttons";
import { LogOnMount, prettyLogEvent } from "@utils/analytics";
import { compact, isEqual as _isEqual, isNil } from "lodash";
import { useToasts } from "react-toast-notifications";
import { OrdersChart } from "./orders-chart";
import { OrdersControl } from "./orders-control";
import { OrdersTable } from "./orders-table/orders-table";
import {
  ValueFloatComparisonInput,
  ValueIntComparisonInput,
} from "@nb-api-graphql-generated/global-types";
import classNames from "classnames";
import { useNorthbeamMutation } from "@utils/hooks";
import { EXPORT_ORDER_DATA } from "@pages/settings/queries";
import {
  ExportedOrderData,
  ExportedOrderDataVariables,
} from "@nb-api-graphql-generated/ExportedOrderData";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { AnnotationsFlyout } from "@features/custom-annotations";
import { usePageTitle } from "@/atoms/page-title-atom";

function isEqual<T>(a: T | null | undefined, b: T | null | undefined) {
  if (isNil(a) && isNil(b)) return true;
  return _isEqual(a, b);
}

function getOrderValue(orderValue: string | null | undefined) {
  return isNil(orderValue) || orderValue.trim() === ""
    ? undefined
    : Number(orderValue);
}

function comparisonInputIsValid<
  T extends ValueIntComparisonInput | ValueFloatComparisonInput,
>(input: Partial<T> | null | undefined): input is T | null {
  if (isNil(input?.comparisonOperator) && !isNil(input?.value)) return false;
  if (!isNil(input?.comparisonOperator) && isNil(input?.value)) return false;
  return true;
}

const useQueryStateEqualsInternalQueryState = () => {
  const [orderTags] = useOrderTagsAtom();
  const [customerTags] = useCustomerTagsAtom();
  const [subscriptions] = useSubscriptionsAtom();
  const [orderTypes] = useOrderTypesAtom();
  const [products] = useProductsPurchasedAtom();
  const [discountCodes] = useDiscountCodesAtom();
  const [orderId] = useOrderIdAtom();
  const [numberOfOriginalItems] = useNumberOfOrderItemsAtom();
  const [numberOfTouchpoints] = useNumberOfTouchpointsAtom();
  const [attributed] = useAttributedAtom();
  const [orderValue] = useOrderValueAtom();

  const [state] = useOrdersQueryParams();

  if (!isEqual(state.attributed, attributed)) return false;
  if (!isEqual(state.orderTags, orderTags)) return false;
  if (!isEqual(state.customerTags, customerTags)) return false;
  if (!isEqual(state.subscriptions, subscriptions)) return false;
  if (!isEqual(state.discountCodes, discountCodes)) return false;
  if (!isEqual(state.orderTypes, orderTypes)) return false;
  if (!isEqual(state.products, products)) return false;
  if (!isEqual(state.orderId, orderId)) return false;
  if (!isEqual(state.numberOfOriginalItems, numberOfOriginalItems))
    return false;
  if (!isEqual(state.numberOfTouchpoints, numberOfTouchpoints)) return false;

  if (
    !isEqual(
      state.orderValue?.comparisonOperator,
      orderValue?.comparisonOperator,
    ) ||
    !isEqual(state.orderValue?.value, getOrderValue(orderValue?.value))
  )
    return false;

  return true;
};

function ApplyFiltersButton() {
  const { addToast } = useToasts();
  const [orderTags, setOrderTags] = useOrderTagsAtom();
  const [customerTags, setCustomerTags] = useCustomerTagsAtom();
  const [subscriptions, setSubscriptions] = useSubscriptionsAtom();
  const [orderTypes, setOrderTypes] = useOrderTypesAtom();
  const [products, setProducts] = useProductsPurchasedAtom();
  const [discountCodes, setDiscountCodes] = useDiscountCodesAtom();
  const [orderId, setOrderId] = useOrderIdAtom();
  const [numberOfOriginalItems, setNumberOfOriginalItems] =
    useNumberOfOrderItemsAtom();
  const [numberOfTouchpoints, setNumberOfTouchpoints] =
    useNumberOfTouchpointsAtom();
  const [attributed, setAttributed] = useAttributedAtom();
  const [orderValue, setOrderValue] = useOrderValueAtom();
  const [, setSorting] = useOrderPageSortingAtom();

  const [state, setQuery] = useOrdersQueryParams();
  const isQueryStateClean = useQueryStateEqualsInternalQueryState();
  const orderQueriesLoading = useOrderQueriesLoading();

  useEffect(() => {
    if (state.subscriptions) setSubscriptions(compact(state.subscriptions));
    if (state.orderTags) setOrderTags(compact(state.orderTags));
    if (state.customerTags) setCustomerTags(compact(state.customerTags));
    if (state.orderTypes) setOrderTypes(compact(state.orderTypes));
    if (state.products) setProducts(compact(state.products));
    if (state.attributed) setAttributed(compact(state.attributed));
    if (state.orderId) setOrderId(state.orderId);
    if (!isNil(state.numberOfOriginalItems))
      setNumberOfOriginalItems({
        value: Number(state.numberOfOriginalItems.value),
        comparisonOperator: state.numberOfOriginalItems.comparisonOperator,
      });
    if (!isNil(state.numberOfTouchpoints))
      setNumberOfTouchpoints({
        value: Number(state.numberOfTouchpoints.value),
        comparisonOperator: state.numberOfTouchpoints.comparisonOperator,
      });

    if (state.orderValue) {
      setOrderValue({
        value: String(state.orderValue.value),
        comparisonOperator: state.orderValue.comparisonOperator,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setSubscriptions,
    setOrderTags,
    setCustomerTags,
    setOrderTypes,
    setProducts,
    setDiscountCodes,
    setAttributed,
    setOrderId,
    setNumberOfOriginalItems,
    setNumberOfTouchpoints,
    setOrderValue,
  ]);

  return (
    <div className="flex">
      <div className="flex relative">
        {!isQueryStateClean && (
          <Badge
            badgeColor="#f08484"
            className="absolute right-0 -mr-[0.4rem]"
            size="md"
          />
        )}
        <SecondaryButton
          className="btn-sm py-2 px-3 my-2 hover:text-[#fff] hover:bg-[#007bff]"
          disabled={orderQueriesLoading}
          onClick={() => {
            const verifiedOrderValue = getOrderValue(orderValue?.value);

            if (!isNil(verifiedOrderValue) && isNaN(verifiedOrderValue)) {
              addToast("Sorry! Your Order value is not a number", {
                appearance: "error",
              });
              return;
            }

            if (!comparisonInputIsValid(numberOfOriginalItems)) {
              addToast(
                "Sorry! Number of Original Items must have both a value and comparison operator",
                {
                  appearance: "error",
                },
              );
              return;
            }

            const potentialOrderValue =
              !verifiedOrderValue && !orderValue?.comparisonOperator
                ? null
                : {
                    value: verifiedOrderValue,
                    comparisonOperator: orderValue?.comparisonOperator,
                  };

            if (!comparisonInputIsValid(potentialOrderValue)) {
              addToast(
                "Sorry! Order Value must have both a value and comparison operator",
                {
                  appearance: "error",
                },
              );
              return;
            }
            if (!comparisonInputIsValid(numberOfTouchpoints)) {
              addToast(
                "Sorry! Number of Touchpoints must have both a value and comparison operator",
                {
                  appearance: "error",
                },
              );
              return;
            }

            const query: Parameters<typeof setQuery>[0] = {
              discountCodes,
              customerTags,
              orderTags,
              subscriptions,
              orderTypes,
              products,
              attributed,
              orderId,
              numberOfOriginalItems,
              numberOfTouchpoints,
              orderValue: potentialOrderValue,
            };

            prettyLogEvent({
              page: "Orders",
              action: "Apply Filters",
              data: query,
            });
            return setQuery(query);
          }}
        >
          Apply Filters and Search
        </SecondaryButton>
      </div>
      <SecondaryButton
        className="btn-sm ml-2 py-2 px-3 my-2 hover:text-[#fff] hover:bg-[#007bff]"
        disabled={orderQueriesLoading}
        onClick={() => {
          prettyLogEvent({
            page: "Orders",
            action: "Clear Filters",
          });
          setQuery({
            discountCodes: undefined,
            subscriptions: undefined,
            orderTags: undefined,
            customerTags: undefined,
            orderTypes: undefined,
            products: undefined,
            attributed: undefined,
            orderId: undefined,
            numberOfOriginalItems: undefined,
            numberOfTouchpoints: undefined,
            orderValue: undefined,
          });
          setDiscountCodes(null);
          setOrderTags(null);
          setCustomerTags(null);
          setSubscriptions(null);
          setOrderTypes(null);
          setProducts(null);
          setOrderId(null);
          setNumberOfOriginalItems(null);
          setNumberOfTouchpoints(null);
          setAttributed(null);
          setOrderValue(null);
          setSorting(defaultOrderPageSorting);
        }}
      >
        Clear all
      </SecondaryButton>
    </div>
  );
}

export function ExportData({ className }: { className?: string }) {
  const filterOptions = useOrdersPageFilters();

  const { addToast } = useToasts();
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [exportData, { loading }] = useNorthbeamMutation<
    ExportedOrderData,
    ExportedOrderDataVariables
  >(EXPORT_ORDER_DATA);

  return (
    <>
      <ReactModal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",

            transform: "translate(-50%, -50%)",
            borderRadius: "25px",
            width: "fit-content",
            height: "fit-content",
            padding: 0,
          },

          overlay: {
            zIndex: 2001,
            backgroundColor: "rgba(30, 30, 30, 0.47)",
          },
        }}
        contentLabel="Export Order Data"
      >
        <div className="w-[30rem] h-[22rem] relative">
          <button
            onClick={() => setIsModalOpen(false)}
            className="absolute top-5 right-5 z-[1]"
          >
            <i className="fa-regular fa-xmark " style={{ fontSize: "1.5em" }} />
          </button>
          <div className="flex w-full h-full">
            <div className="px-5 pr-10 pt-10 flex-grow flex flex-col relative">
              <div className="text-lg font-semibold">
                <i
                  className="fa-regular fa-download mr-5"
                  style={{ fontSize: "1.5em" }}
                />
                Export your Order Data
              </div>
              <div className="mt-4">
                Your order data export will be based on the filters that you
                have applied on the order page.
              </div>
              <div className="mt-4">
                Your export may take up to 1 hour to complete depending on the
                size of the export.
              </div>
              <div className="mt-4">
                Exports can be found in the{" "}
                <Link
                  to={"../customers/documents"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Documents page.
                </Link>
              </div>
              <PrimaryButton
                className={"h-[2.5rem] w-[7rem] px-5 absolute bottom-8 right-8"}
                isDisabled={loading}
                loading={loading}
                onClick={async () => {
                  const name = `order_data-${new Date().getTime()}`;
                  await exportData({
                    variables: {
                      filterOptions,
                      name,
                    },
                  });

                  addToast(
                    <div>
                      <div>Your order export has started!</div>
                      <div className="mt-3">
                        When you export is done it will live in the Documents
                        page under the name:
                      </div>
                      <div className="mt-3">{name}.csv</div>
                    </div>,
                    { appearance: "success", autoDismiss: false },
                  );
                  prettyLogEvent({
                    page: "Orders",
                    action: "onSubmit",
                    additionalActionInfo: "order-export",
                    data: { filterOptions, name },
                  });
                  setIsModalOpen(false);
                }}
              >
                Export
              </PrimaryButton>
            </div>
          </div>
        </div>
      </ReactModal>
      <SecondaryButton
        className={classNames(className, "h-[2.5rem]")}
        isDisabled={loading}
        onClick={async () => {
          setIsModalOpen(true);
        }}
      >
        Export data
      </SecondaryButton>
    </>
  );
}

export function Orders(): JSX.Element {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Orders");
  }, [setPageTitle]);

  useEffect(() => {
    prettyLogEvent({
      page: "Orders",
      action: "Visited",
    });
  }, []);

  return (
    <div className="bg-background">
      <LogOnMount name="Orders page" />
      <AnnotationsFlyout />
      <OrdersControl isLoading={false} />
      <div className="mx-4">
        <OrdersChart />
        <div className="flex justify-between items-center">
          <ApplyFiltersButton />
          <ExportData />
        </div>
        <OrdersTable />
      </div>
    </div>
  );
}
