import clsx from "clsx";
import React from "react";

const getSizeClassname = (size?: "md" | "lg" | "sm") => {
  if (size === "lg")
    return "min-w-[1rem] max-w-[1rem] min-h-[1rem] max-h-[1rem]" as const;
  if (size === "md")
    return "min-w-[0.75rem] max-w-[0.75rem] min-h-[0.75rem] max-h-[0.75rem]" as const;
  return "min-w-[0.5rem] max-w-[0.5rem] min-h-[0.5rem] max-h-[0.5rem]" as const;
};
export function Badge({
  text,
  textClassname,
  badgeColor,
  className,
  reversedOrder,
  size,
}: {
  textClassname?: string;
  text?: string;
  badgeColor: string;
  className?: string;
  reversedOrder?: boolean;
  size?: "md" | "lg" | "sm";
}) {
  return (
    <div className={clsx("flex items-center font-semibold ", className)}>
      {reversedOrder && (
        <div className={clsx("truncate", textClassname)}>{text}</div>
      )}
      <div
        className={clsx(
          `inline-flex justify-center items-center text-xs font-bold  rounded-full `,
          reversedOrder ? "ml-[0.15rem]" : "mr-[0.15rem]",
          getSizeClassname(size),
        )}
        style={{ background: badgeColor }}
      />
      {!reversedOrder && (
        <div className={clsx("truncate", textClassname)}>{text}</div>
      )}
    </div>
  );
}
