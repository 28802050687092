import northbeamLogo from "@assets/northbeam-error-screen-logo.svg";

import React from "react";

export const SalesAPIErrorBoundary = ({ refetch }: { refetch: () => void }) => {
  return (
    <div className="w-full h-full flex justify-content-center items-center">
      <div className="w-50 text-center">
        <img
          className="text-center mb-4 img-fluid mx-auto"
          src={northbeamLogo}
          alt={"Northbeam logo"}
        />
        <h4 className="mt-4 text-center">
          <b>Your data is currently refreshing.</b>{" "}
          <p className="mt-4 text-center">What to do: </p>
          <div className="mt-4 text-left">
            <ol>
              <li className="mt-2">
                Please check back in <b>10 minutes</b>
              </li>
              <li className="mt-2">
                If the page is still not working, please contact us at your
                convenience. We will be in touch ASAP.
              </li>
            </ol>
          </div>
        </h4>
        <div>
          <button
            tabIndex={40}
            className="btn btn-primary mt-4 mb-2"
            onClick={refetch}
          >
            Refresh
          </button>
        </div>
        <a
          tabIndex={70}
          href="mailto:success@northbeam.io"
          className="btn btn-outline-primary mt-2"
        >
          Contact us
        </a>
      </div>
    </div>
  );
};
