export const sortByAnotherArray = <
  T extends Record<string, any>,
  K extends string,
>(
  itemsArray: T[],
  sortingArr: K[],
  getSortingKey: (item: T) => K,
) => {
  return itemsArray
    .slice()
    .sort(
      (a, b) =>
        sortingArr.indexOf(getSortingKey(a)) -
        sortingArr.indexOf(getSortingKey(b)),
    );
};
