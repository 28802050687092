import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";
import { ConstructDestinationUrlComponent } from "./shared/construct-destination-url-component";

export function Criteo({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to all <b>destination URLs</b> for
        new and existing ads.
      </p>
      <ol>
        <ConstructDestinationUrlComponent urlParamPairs={urlParamPairs} />
        <li>
          Follow the instructions{" "}
          <GenericExternalLink href="https://support.criteo.com/s/article?article=About-Ad-Tracking&language=en_US">
            here
          </GenericExternalLink>{" "}
          to edit or create a new ad with Northbeam&apos;s URL parameters.
        </li>
      </ol>
    </>
  );
}
