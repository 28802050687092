import React, { useMemo } from "react";

import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { LoadingSlide, NoDataView } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import { H1 } from "@components/utilities";
import { LogOnMount } from "@utils/analytics";
import {
  CustomGoalRow,
  makeFirstTimeVsReturningRows,
  makeOverviewRows,
  makeTrafficRows,
  SalesPageMetric,
} from "@utils/metrics";
import { ChartSection } from "./chart-section/chart";
import { NewSalesExperienceSwitch } from "./new-sales-experience-switch";
import {
  ReportBodyControl,
  ReportBodyState,
  useReportBodyState,
} from "./report-body-control";
import { SalesAPIErrorBoundary } from "./sales-page-error-container";
import { TableSection } from "./table-section";
import { useSalesReport } from "./use-sales-report";
import { INBAccountingMode } from "@utils/constants";

export const SalesPageMain = () => {
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const { user } = useUser();

  const {
    state,
    dateRange,
    compareDateRange,
    attributionModel,
    attributionWindow,
    nbAccountingMode,
    granularity,
    timeGranularity,
  } = useReportBodyState();

  const { data, loading, error, refetch } = useSalesReport({
    dateRange,
    compareDateRange,
    attributionMethod: attributionModel,
    attributionWindow,
    granularity,
    nbAccountingMode,
    timeGranularity,
  });

  const salesReport = data?.me?.salesReport;

  const metricsArray = useMemo(
    () =>
      getMetricsForState(
        state,
        salesReport?.hasV1Forecasts,
        salesReport?.customGoals,
        user,
      ),
    [salesReport, state, user],
  );

  if (error) {
    return <SalesAPIErrorBoundary refetch={() => refetch?.()} />;
  }

  if (loading) {
    return <LoadingSlide />;
  }

  if (!salesReport || salesReport.rows.length === 0) {
    return <NoDataView />;
  }

  return (
    <>
      <LogOnMount name="Visit Sales page" />
      <ReportBodyControl isLoading={loading} />
      <div className="px-4">
        <div className="flex justify-between">
          <div>
            <H1 className="mt-3">Sales and Marketing</H1>
            <p>Here&apos;s how your ads have been performing.</p>
          </div>
          <NewSalesExperienceSwitch />
        </div>
        <ChartSection rows={salesReport.rows} metricsArray={metricsArray} />
        <TableSection
          rows={salesReport.rows}
          breakdownConfigs={breakdownConfigs}
          metricsArray={metricsArray}
        />
      </div>
    </>
  );
};

const getMetricsForState = (
  reportBodyState: ReportBodyState,
  includeV1Forecasts = false,
  customGoals: CustomGoalRow[] = [],
  user: any,
): SalesPageMetric[] => {
  const am: any = reportBodyState.attributionModel;

  if (reportBodyState.columnSet === "overview") {
    return makeOverviewRows(
      am,
      (reportBodyState.attributionWindow as any) || "1",
      includeV1Forecasts,
      reportBodyState.nbAccountingMode,
      customGoals,
      reportBodyState.timeGranularity,
      user,
    );
  }

  if (reportBodyState.columnSet === "traffic") {
    return makeTrafficRows(
      am,
      (reportBodyState.attributionWindow as any) || "1",
      reportBodyState.nbAccountingMode as INBAccountingMode,
      user,
      reportBodyState.timeGranularity,
    );
  }

  if (reportBodyState.columnSet === "first_time_vs_returning") {
    return makeFirstTimeVsReturningRows(
      am,
      (reportBodyState.attributionWindow as any) || "1",
      reportBodyState.nbAccountingMode,
      reportBodyState.timeGranularity,
      user.attributionMethodEnum,
    );
  }

  return [];
};
