import {
  AugmentedFactColumn,
  VirtualizedTable,
} from "@components/reports/virtualized-table";
import { useUser } from "@components/user-context";
import { Breakdown, CategoricalBreakdownConfig } from "@north-beam/nb-common";
import { SalesReportRow, Table } from "@north-beam/nb-common";
import { DateInterval, PartialBy } from "@north-beam/nb-common";
import { flatten } from "@utils/row-with-children";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ColumnOrderModal } from "./column-order-modal";
import { SalesPageMetric } from "@utils/metrics";
import { IGranularity } from "@/utils/constants";
import { ExportToCsvModal } from "./export-to-csv-modal";
import {
  useColumnOrdering,
  COLUMN_ORDERING_STRING_FOR_SALES_PAGE_PRIMARY_TABLE,
  HACK_columnMatching,
} from "./use-column-ordering";
import { createDimensionColumns, createFactColumns } from "./column-builder";
import { TableSearchFilter } from "./table-search-filter";
import {
  preprocessForTable,
  createDailyDataRoot,
  createNativeTableRoot,
} from "./table-utils";
import { useReportBodyState } from "../report-body-control";

interface TableSectionProps {
  rows: SalesReportRow[];
  breakdownConfigs: CategoricalBreakdownConfig[];
  metricsArray: SalesPageMetric[];

  // Because this table is being used in other places
  breakdowns?: Breakdown[];
  dateRange?: DateInterval;
  compareDateRange?: DateInterval;
  granularity?: IGranularity;
}

export const TableSection = (props: PartialBy<TableSectionProps, "rows">) => {
  const { rows = [], breakdownConfigs, metricsArray } = props;
  const [textFilter, setTextFilter] = useState("");
  const {
    breakdowns: breakdownsState,
    dateRange: dateRangeState,
    compareDateRange: compareDateRangeState,
    granularity: granularityState,
  } = useReportBodyState();

  const breakdowns = props.breakdowns || breakdownsState;
  const dateRange = props.dateRange || dateRangeState;
  const compareDateRange = props.compareDateRange || compareDateRangeState;
  const granularity = props.granularity || granularityState;

  const [data, dailyData] = useMemo(
    () =>
      preprocessForTable(
        rows,
        breakdownConfigs,
        breakdowns,
        dateRange,
        compareDateRange,
        textFilter,
      ) ?? null,
    [
      rows,
      breakdownConfigs,
      breakdowns,
      dateRange,
      compareDateRange,
      textFilter,
    ],
  );
  const { user } = useUser();
  const { search } = useLocation();
  const { columnOrdering, columnOrderingLoading, setColumnOrdering } =
    useColumnOrdering();

  const [columnOrderModalOpen, setColumnModelOpen] = useState<boolean>(false);
  const [exportToCsvModalOpen, setExportToCsvModalOpen] = useState(false);
  const extraExportDimensionColumns: Set<string> = useMemo(() => new Set(), []);

  const dailyDataRoot = useMemo(
    () =>
      createDailyDataRoot(dailyData, extraExportDimensionColumns, metricsArray),
    [dailyData, metricsArray, extraExportDimensionColumns],
  );

  const nativeTableRoot = useMemo(
    () =>
      createNativeTableRoot(
        data,
        extraExportDimensionColumns,
        metricsArray,
        search,
      ),
    [data, metricsArray, search, extraExportDimensionColumns],
  );

  const dimensionColumn: AugmentedFactColumn = useMemo(
    () => createDimensionColumns(user, breakdowns, granularity),
    [user, breakdowns, granularity],
  );

  const factColumns = useMemo(
    () => createFactColumns(metricsArray),
    [metricsArray],
  );

  const root = useMemo(() => {
    if (!nativeTableRoot) {
      return null;
    }

    const rv = { ...nativeTableRoot };
    rv.children = [];
    return rv;
  }, [nativeTableRoot]);

  const preppedRows = useMemo(
    () => nativeTableRoot && [...nativeTableRoot.children],
    [nativeTableRoot],
  );

  const table: Table | null = useMemo(() => {
    if (!nativeTableRoot) {
      return null;
    }
    return {
      title: "",
      dimensionColumns: [
        ...breakdowns.map((v) => ({ name: v.key, key: v.key })),
        dimensionColumn,
      ],
      factColumns,
      rows: flatten(nativeTableRoot),
    };
  }, [breakdowns, dimensionColumn, factColumns, nativeTableRoot]);

  const formattedExtraExportDimensionsColumns = [
    ...extraExportDimensionColumns,
  ].map((key) => ({ name: key, key }));

  const dailyDataTable = useMemo(() => {
    if (!dailyDataRoot) {
      return null;
    }

    return {
      title: "",
      dimensionColumns: [
        { name: "Date", key: "Date" },
        ...breakdowns.map((v) => ({ name: v.key, key: v.key })),
        dimensionColumn,
        ...formattedExtraExportDimensionsColumns,
      ],
      factColumns,
      rows: dailyDataRoot,
    };
  }, [
    breakdowns,
    dimensionColumn,
    factColumns,
    dailyDataRoot,
    formattedExtraExportDimensionsColumns,
  ]);

  return (
    <div
      className={classNames("card", {
        "waiting-interstitial": columnOrderingLoading,
      })}
    >
      <div className="card-body row">
        <TableSearchFilter
          textFilter={textFilter}
          setTextFilter={setTextFilter}
        />
        <div className="col-6 text-right">
          <ColumnOrderModal
            setColumnModelOpen={setColumnModelOpen}
            columnOrderModalOpen={columnOrderModalOpen}
            columnOrdering={columnOrdering}
            columns={factColumns}
            setColumnOrdering={(columnOrdering) => {
              setColumnOrdering({
                variables: {
                  columnOrdering,
                  tableName:
                    COLUMN_ORDERING_STRING_FOR_SALES_PAGE_PRIMARY_TABLE,
                },
              });
            }}
          />
          <ExportToCsvModal
            table={{
              ...table,
              dimensionColumns: [
                ...(table?.dimensionColumns ?? []),
                ...formattedExtraExportDimensionsColumns,
              ],
            }}
            dailyDataTable={dailyDataTable}
            exportToCsvModalOpen={exportToCsvModalOpen}
            setExportToCsvModalOpen={setExportToCsvModalOpen}
          />
        </div>
      </div>
      <div
        className="row mt-2 col"
        style={{ paddingBottom: "300px" }}
        data-test="sales-table"
      >
        {data && root ? (
          <>
            <VirtualizedTable
              rowHeight={50}
              columnWidth={110}
              frozenRows={[root]}
              rows={preppedRows}
              dimensionColumn={dimensionColumn}
              factColumns={factColumns}
              allowSortingByLastDimensionColumn={true}
              columnOrdering={columnOrdering}
              HACK_columnMatching={HACK_columnMatching}
            />
          </>
        ) : (
          <div className="card">
            <div className="card-body">
              No performance data for this report.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
