import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { jsonHash } from "@north-beam/nb-common";
import { AdIcon } from "@pages/objects/utils";
import { descriptorToPath } from "@utils/metrics";
import classnames from "classnames";
import React from "react";
import { ColumnDef } from "../table-utils";

const DimensionDataFormatter = ({
  row,
  column,
}: DataTypeProvider.ValueFormatterProps) => {
  const columnOptions = (column as ColumnDef)?.options;

  const isPlatformView = columnOptions?.isPlatformView;
  const path = descriptorToPath(row.key);
  let isBreakdown = false;
  let isAd = false;
  try {
    const lastIndex = path.length - 1;
    isAd =
      path.length !== 0 && JSON.parse(path[lastIndex])?.nbt?.ad !== undefined;
  } catch (e) {
    // Breakdowns are strings and non JSON serializable
    isBreakdown = true;
  }

  const isLinkable = isPlatformView || (!isBreakdown && !isAd);

  return (
    <>
      <div
        className={classnames(
          "overflow-hidden font-semibold text-ellipsis",
          isLinkable && "hover:underline text-[#007bff]",
          (isLinkable || isBreakdown) && "cursor-pointer",
        )}
        onClick={() => {
          const selectionLength = window.getSelection()?.toString().length ?? 0;
          if (selectionLength <= 0) {
            if (isLinkable) {
              columnOptions?.onRowLinkClick?.(row);
            } else if (isBreakdown) {
              columnOptions?.onBreakdownClick?.(row);
            }
          }
        }}
      >
        {row?.rowMetadata?.platformId && (
          <div className="inline-block">
            <AdIcon sizeInEM={1} platform={row?.rowMetadata.platformId} />
          </div>
        )}
        {row?.display.Name}
      </div>
      {row?.rowMetadata?.extraDetails?.map(({ name, value }: any) => (
        <small
          className="text-xs font-medium text-[#44515C] block -mt-0.5"
          key={jsonHash({ name, value })}
        >
          {`${name}:  ${value}`}
        </small>
      ))}
    </>
  );
};

export const DimensionTypeProvider = (props: any) => (
  <DataTypeProvider formatterComponent={DimensionDataFormatter} {...props} />
);
