export const PlatformFriendlyNames: Record<string, string> = {
  adwords: "Adwords",
  fb: "Facebook",
  microsoft: "Microsoft",
  snapchat: "Snapchat",
  pinterest: "Pinterest",
  tiktok: "TikTok",
  linkedin: "LinkedIn",
  facebook: "Facebook",
  amazon_marketplace_aggregated: "Amazon Marketplace",
  bliss_point_media: "Bliss Point Media",
  cm360: "Campain Manager 360",
  mntn: "Mountain Digital",
  bing: "Bing",
  mediamath: "Media Math",
  criteo: "Criteo",
  xandr_hivewyre: "Xandr Hivewyre",
  adnet: "Adnet",
  dv360_ve: "Display and Video 360",
  zeta: "Zeta",
  tatari: "Tatari",
  partnership: "Partnership",
  nativeemail: "Native Email",
  affiliate: "Affiliate",
  adwordsi: "Adwords",
  "adwo rds": "Adwords",
  twitter: "Twitter",
  pebblepost: "Pebblepost",
  adelphic: "Adelphic",
};

export const EcomPlatformFriendlyNames: Record<string, string> = {
  "Server to Server": "Server to Server",
  shopify: "Shopify",
  amazon: "Amazon",
};
