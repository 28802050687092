import { useOrderSummaryGraphKPILoading } from "@/atoms/order-page-atoms";
import { useOrderGraphFilters } from "@hooks/use-orders-filters";
import {
  FetchOrderSummaryGraphKPI,
  FetchOrderSummaryGraphKPIVariables,
} from "@nb-api-graphql-generated/FetchOrderSummaryGraphKPI";
import { ChartLegend } from "@pages/sales/chart-section/chart-legend";
import { FETCH_ORDER_SUMMARY_GRAPH_KPI } from "@pages/settings/queries";
import { useNorthbeamQuery } from "@utils/hooks";
import { MetricFormats } from "@utils/metrics";
import React, { useEffect } from "react";

export function OrdersMetrics() {
  const { dateRange, granularity, compareDateRange, ...restOfFilters } =
    useOrderGraphFilters();

  const [, setOrderQueryLoading] = useOrderSummaryGraphKPILoading();

  const { data: graphResponse, loading } = useNorthbeamQuery<
    FetchOrderSummaryGraphKPI,
    FetchOrderSummaryGraphKPIVariables
  >(FETCH_ORDER_SUMMARY_GRAPH_KPI, {
    variables: {
      filterOptions: restOfFilters,
      dateRange,
      comparedDateRange: compareDateRange,
    },
  });

  const currentKPIs = graphResponse?.me?.orderSummaryGraphKPI.currentKPIs;
  const comparisonKPIs = graphResponse?.me?.orderSummaryGraphKPI.comparisonKPIs;

  useEffect(() => {
    setOrderQueryLoading(loading);
  }, [loading, setOrderQueryLoading]);

  return (
    <ChartLegend
      legendMetrics={[
        // Revenue metric
        {
          loading,
          currentValue: currentKPIs?.orderRevenue || 0,
          compareValue: comparisonKPIs?.orderRevenue || 0,
          name: "Revenue",
          isPositiveChangeGood: true,
          formatApproximate: MetricFormats.dollars.approximate,
          hideComparisonFromTabDisplay: false,
        },
        // Revenue count metric
        {
          loading,
          currentValue: currentKPIs?.orderCount || 0,
          compareValue: comparisonKPIs?.orderCount || 0,
          name: "Order",
          isPositiveChangeGood: true,
          formatApproximate: MetricFormats.integer.exact,
          hideComparisonFromTabDisplay: false,
        },
      ]}
      selectMetric={(metric) => metric}
      selectedMetricIndices={[0, 1]}
    />
  );
}
