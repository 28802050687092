import { Modal } from "@components/shared/modals";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { PrimaryButton, TertiaryButton } from "@shared/buttons";
import React, { useEffect, useState } from "react";
import { HACK_columnMatching } from "./use-column-ordering";

interface ColumnOrderModalProps {
  setColumnModelOpen: (isOpen: boolean) => void;
  columnOrderModalOpen: boolean;
  columnOrdering: string[];
  columns: any;
  setColumnOrdering: (arg0: string[]) => void;
}

export const ColumnOrderModal = ({
  columnOrderModalOpen,
  setColumnModelOpen,
  columnOrdering,
  columns,
  setColumnOrdering,
}: ColumnOrderModalProps) => {
  const [tempOrdering, setTempOrdering] = useState<string[]>(columnOrdering);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    const indices = columnOrdering.map((columnKey) =>
      columns.findIndex((column: any) =>
        HACK_columnMatching(column.key, columnKey),
      ),
    );

    if (
      indices.find((el) => el === -1) ||
      columnOrdering.length !== columns.length
    ) {
      setTempOrdering(columns.map((column: any) => column.key));
    } else {
      setTempOrdering(columnOrdering);
    }
  }, [columnOrdering, setTempOrdering, columns]);

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = tempOrdering.findIndex((columnKey) =>
        HACK_columnMatching(columnKey, active.id as string),
      );
      const newIndex = tempOrdering.findIndex((columnKey) =>
        HACK_columnMatching(columnKey, over?.id as string),
      );

      const newOrdering = arrayMove(tempOrdering, oldIndex, newIndex);
      setTempOrdering(newOrdering);
    }
  };

  const onSave = () => {
    setColumnModelOpen(false);
    setColumnOrdering(tempOrdering);
  };

  return (
    <>
      <PrimaryButton className="mr-3" onClick={() => setColumnModelOpen(true)}>
        Change Column Order
      </PrimaryButton>
      <Modal
        isOpen={columnOrderModalOpen}
        title="Change column order"
        contentLabel="Change sales page column ordering"
      >
        <div className="overflow-y-scroll w-80 max-h-[35vh] mb-2">
          <table className="table table-bordered">
            <tbody>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext items={tempOrdering}>
                  {tempOrdering.map((columnKey) => {
                    return (
                      <ColumnOrderRow
                        columnKey={columnKey}
                        columns={columns}
                        key={columnKey}
                      />
                    );
                  })}
                </SortableContext>
              </DndContext>
            </tbody>
          </table>
        </div>
        <PrimaryButton onClick={onSave}>Save</PrimaryButton>
        <TertiaryButton
          className="btn"
          onClick={() => setColumnModelOpen(false)}
        >
          Close
        </TertiaryButton>
      </Modal>
    </>
  );
};

const ColumnOrderRow = ({
  columnKey,
  columns,
}: {
  columnKey: string;
  columns: any[];
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: columnKey,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr id={columnKey} ref={setNodeRef} style={style}>
      <td {...attributes} {...listeners} className="text-center align-middle">
        <i className="fa-regular fa-grip-vertical" />
      </td>
      <td>
        {
          columns.find((column) => HACK_columnMatching(column.key, columnKey))
            .name
        }
      </td>
    </tr>
  );
};
