import { ScheduledTimeFrame } from "@features/data-export/types";
import { Checkbox } from "@shared/checkbox";
import { range } from "lodash";
import React from "react";
import ReactSelect, { components } from "react-select";
import { useTimeSelectOptions } from "./hooks";
import { TimeSelect } from "./time-select";
import { TimezoneDisclaimer } from "./timezone-disclaimer";
import { ordinal } from "./utils";

interface Props {
  timeFrame: ScheduledTimeFrame;
  onChange(timeFrame: ScheduledTimeFrame): void;
}

const DAYS_SELECT_OPTIONS = range(1, 31).map((e) => ({
  label: ordinal(e),
  value: e.toString(),
}));

export function MonthlyConfigurator({ timeFrame, onChange }: Props) {
  const selectOptions = useTimeSelectOptions();

  return (
    <>
      <div className="flex-initial text-[0.65rem] leading-4 text-zinc-500 font-light px-7 pt-7">
        <TimezoneDisclaimer />

        <h3 className="text-black text-sm font-semibold m-0 mb-10">
          Select the day of the month you would like to export your report
        </h3>
      </div>

      <div className="flex-initial px-7 overflow-auto space-y-6 pb-5">
        <div>
          <div className="text-[0.7rem] font-medium mb-1">Day of the month</div>
          <div className="flex items-center">
            <div className="w-[30%]">
              <ReactSelect
                components={{
                  DropdownIndicator: (props) => (
                    <components.DropdownIndicator {...props}>
                      <i className="fa-solid fa-chevron-down text-[0.6rem] text-gray-500 px-2" />
                    </components.DropdownIndicator>
                  ),
                }}
                classNames={{
                  control: () =>
                    "shadow-none rounded-md text-[0.6rem] min-h-[30px]",
                  indicatorSeparator: () => "hidden",
                  dropdownIndicator: () => "p-0",
                  input: () => "text-gray-500 leading-3",
                  menu: () => "text-gray-500 text-[0.6rem] leading-1",
                  menuPortal: () => "z-[9999]",
                }}
                menuPortalTarget={document.body}
                isSearchable={false}
                isDisabled={timeFrame.day === "-1"}
                value={DAYS_SELECT_OPTIONS.find(
                  (e) =>
                    e.value === (timeFrame.day === "-1" ? "1" : timeFrame.day),
                )}
                options={DAYS_SELECT_OPTIONS}
                onChange={(val) => {
                  if (val) {
                    onChange({ ...timeFrame, day: val.value });
                  }
                }}
              />
            </div>
            <div className="w-1/2 min-w-[10rem] text-[12px] font-light ml-8">
              <Checkbox
                inputClassName="w-3 h-3 mr-0.5"
                value={timeFrame.day === "-1"}
                label="Last day of the month"
                onChange={(val) => {
                  if (val) {
                    onChange({ ...timeFrame, day: "-1" });
                  } else {
                    onChange({ ...timeFrame, day: "1" });
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="text-[0.7rem] font-medium mb-1">Time of the day</div>
          <div className="flex items-center">
            <div className="w-[50%]">
              <TimeSelect
                options={selectOptions}
                value={selectOptions.find((e) => e.value === timeFrame.time)!}
                onChange={(val) => {
                  onChange({ ...timeFrame, time: val.value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
