import classNames from "classnames";
import React from "react";
import { Blanket } from "./utilities";

export interface GenericDropdownChoice {
  value: string;
  label: string;
}

interface RenderButtonProps {
  toggle: () => void;
}

interface RenderListProps {
  isOpen: boolean;
  toggle: () => void;
}

export interface GenericDropdownProps {
  renderButton: (
    props: RenderButtonProps,
  ) => Exclude<React.ReactNode, undefined>;
  renderList: (props: RenderListProps) => Exclude<React.ReactNode, undefined>;
}

export function GenericDropdown({
  renderButton,
  renderList,
}: GenericDropdownProps) {
  const [open, setOpen] = React.useState(false);
  const toggle = React.useCallback(() => setOpen(!open), [open]);

  return (
    <div className={classNames({ dropdown: true, show: open })}>
      {renderButton({ toggle })}
      {open && <Blanket onClick={toggle} />}
      {renderList({ isOpen: open, toggle })}
    </div>
  );
}
