import { gql } from "@apollo/client";

export const FETCH_PAGINATED_ORDER_TAGS = gql`
  query FetchPaginatedOrderTags(
    $limit: Int!
    $offset: Int!
    $search: String
    $exclusionList: [String]
  ) {
    me {
      paginatedOrderTags(
        limit: $limit
        offset: $offset
        search: $search
        exclusionList: $exclusionList
      ) {
        orderTags {
          tag
          count
        }
        totalCount
      }
    }
  }
`;

export const FETCH_ORDER_TAG_SUBSCRIPTION_ASSOCIATIONS = gql`
  query FetchOrderTagSubscriptionAssociations {
    me {
      orderTagSubscriptionAssociations {
        orderTag
        subscriptionType
      }
    }
  }
`;

export const UPSERT_ORDER_TAG_SUBSCRIPTION_ASSOCIATIONS = gql`
  mutation UpsertOrderTagSubscriptionAssociations(
    $associations: [OrderTagSubscriptionAssociationInput!]!
  ) {
    upsertOrderTagSubscriptionAssociations(associations: $associations) {
      successMessage
    }
  }
`;
