import config from "@/environment";
import { gql } from "@apollo/client";
import { LoadingSlide } from "@components/title-slide-view";
import {
  AddNewStripePaymentMethod,
  AddNewStripePaymentMethodVariables,
} from "@nb-api-graphql-generated/AddNewStripePaymentMethod";
import { Billing as BillingQuery } from "@nb-api-graphql-generated/Billing";
import {
  UpsertBrandBillingInfo as UpsertBrandBillingInfoQuery,
  UpsertBrandBillingInfoVariables,
} from "@nb-api-graphql-generated/UpsertBrandBillingInfo";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentMethod, StripeCardElementOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import Interweave from "interweave";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ADD_NEW_STRIPE_PAYMENT_METHOD } from "./queries";

function AddPaymentMethodForm({ onSuccess }: { onSuccess?: () => void }) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [stripeLoading, setStripeLoading] = React.useState(false);
  const [billingName, setBillingName] = React.useState("");
  const [billingEmail, setBillingEmail] = React.useState("");

  const [
    addNewStripePaymentMethod,
    { loading: addNewStripePaymentMethodLoading },
  ] = useNorthbeamMutation<
    AddNewStripePaymentMethod,
    AddNewStripePaymentMethodVariables
  >(ADD_NEW_STRIPE_PAYMENT_METHOD);

  const [upsertBillingInfo, { loading: upsertBillingInfoLoading }] =
    useNorthbeamMutation<
      UpsertBrandBillingInfoQuery,
      UpsertBrandBillingInfoVariables
    >(UPSERT_BILLING_INFO, {
      refetchQueries: [{ query: BILLING_QUERY }],
    });

  const cardOptions: StripeCardElementOptions = React.useMemo(
    () => ({
      iconStyle: "solid",
      style: {
        base: {
          iconColor: "#08212b",
          color: "#212529",
          fontWeight: "500",
          fontFamily:
            "Commons Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883",
          },
          "::placeholder": {
            color: "#6c757d",
          },
        },
        invalid: {
          iconColor: "#dc3545",
          color: "#dc3545",
        },
      },
    }),
    [],
  );

  const handleSubmit = React.useCallback(
    async (event) => {
      // Block native form submission.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        return;
      }

      setStripeLoading(true);
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      await upsertBillingInfo({
        variables: {
          billingName: billingName,
          billingEmail: billingEmail,
        },
      });
      setStripeLoading(false);

      if (error) {
        setErrorMessage(error.message || "Unknown Stripe error.");
      } else {
        const result = await addNewStripePaymentMethod({
          variables: { paymentMethodId: paymentMethod!.id },
        });

        if (result.errors) {
          setErrorMessage("Could not attach payment method to user.");
        } else {
          onSuccess && onSuccess();
        }
      }
    },
    [
      stripe,
      elements,
      addNewStripePaymentMethod,
      setStripeLoading,
      billingName,
      billingEmail,
      upsertBillingInfo,
      setErrorMessage,
      onSuccess,
    ],
  );

  const loading =
    stripeLoading ||
    addNewStripePaymentMethodLoading ||
    upsertBillingInfoLoading;

  return (
    <div className="bg-white p-3 mt-4 rounded">
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="billing-name">Billing name</label>
          <input
            className="form-control"
            id="billing-name"
            type="text"
            placeholder="Jane Doe"
            required={true}
            autoComplete="name"
            value={billingName}
            onChange={(e) => setBillingName(e.target.value)}
          />
          <label htmlFor="billing-email">Billing email</label>
          <input
            className="form-control"
            id="billing-email"
            type="email"
            placeholder="jane.doe@gmail.com"
            required={true}
            autoComplete="email"
            value={billingEmail}
            onChange={(e) => setBillingEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Credit card</label>
          <div className="border rounded p-2">
            <CardElement options={cardOptions} />
          </div>
        </div>
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
        <button
          className="btn btn-primary"
          type="submit"
          disabled={loading || !billingName || !billingEmail}
        >
          {loading ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Please wait...
            </>
          ) : (
            "Add credit card"
          )}
        </button>
      </form>
    </div>
  );
}

export function BillingCard({ onSuccess }: { onSuccess?: () => void }) {
  const [inner, setInner] = useState<
    React.ReactElement | React.ReactElement[] | null
  >(null);
  const { data, loading } = useNorthbeamQuery<BillingQuery>(BILLING_QUERY);

  useEffect(() => {
    const billingDetails = data?.me?.billingDetails;
    if (!billingDetails) {
      setInner(<LoadingSlide />);
    } else if (billingDetails.billingUserId) {
      setInner(
        <div>
          Billing handled in a different account.{" "}
          <a href={`/${billingDetails.billingUserId}/settings`}>
            Click here to switch accounts
          </a>
        </div>,
      );
    } else if (!billingDetails.stripePaymentMethod) {
      const text = "Please add a credit card below.";
      setInner(
        <div>
          <div className="pt-3 px-3">
            <Interweave content={text} />
          </div>
          <AddPaymentMethodForm onSuccess={onSuccess} />
        </div>,
      );
    } else {
      setInner(
        <div>
          <p>
            Your payment information is on file with us:{" "}
            <b>{formatCard(billingDetails.stripePaymentMethod)}</b>
          </p>

          <div className="mt-4">
            You can use this form to replace your credit card on file.
            <AddPaymentMethodForm onSuccess={onSuccess} />
          </div>
        </div>,
      );
    }
  }, [data, onSuccess]);

  let stripePromise = undefined;
  try {
    stripePromise = loadStripe(config.stripePublishableKey);
  } catch (e) {
    // ignore error
  }

  if (!stripePromise) {
    return null;
  }

  if (loading) {
    return <LoadingSlide />;
  }

  return (
    <Elements options={ELEMENTS_OPTIONS} stripe={stripePromise}>
      <p>
        We encourage ACH payments - If you would like to set up ACH, please
        email us at{" "}
        <a href="mailto:finance@northbeam.io">finance@northbeam.io</a>.
      </p>
      <div className="card rounded">
        <div className="card-body">{inner}</div>
      </div>
    </Elements>
  );
}

function formatCard(paymentMethod: PaymentMethod) {
  const { card } = paymentMethod;
  if (!card) {
    return "Unknown payment method";
  }

  return `${_.capitalize(card.brand)} ending in ${card.last4} (expires ${
    card.exp_month
  }/${card.exp_year})`;
}

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://rsms.me/inter/inter.css",
    },
    {
      cssSrc:
        "https://fonts.googleapis.com/css?family=Open+Sans:98,400,500,600&display=swap",
    },
  ],
};

const BILLING_QUERY = gql`
  query Billing {
    me {
      billingDetails
    }
  }
`;

const UPSERT_BILLING_INFO = gql`
  mutation UpsertBrandBillingInfo(
    $billingName: String!
    $billingEmail: String!
  ) {
    upsertBrandBillingInfo(
      billingName: $billingName
      billingEmail: $billingEmail
    )
  }
`;
