import classNames from "classnames";
import React from "react";

interface SwitchProps {
  size?: "large" | "small" | "medium";
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  id: string;
}

export const Switch = ({
  value,
  onChange,
  id,
  disabled = false,
  size = "medium",
}: SwitchProps) => {
  const htmlId = `check--${id}`;

  return (
    <label className={`custom-switch-${size}`} htmlFor={htmlId}>
      <input
        disabled={disabled}
        type="checkbox"
        checked={value}
        onChange={({ target }) => onChange(target.checked)}
        id={htmlId}
      />
      <span className={classNames("slider", disabled && "disabled")}></span>
    </label>
  );
};
