import React from "react";

export default function ImageAd(props: {
  imageUrl: string;
  pixelWidth: number;
  pixelHeight: number;
}) {
  const { imageUrl, pixelWidth, pixelHeight } = props;
  return (
    <img
      src={imageUrl}
      width={pixelWidth}
      height={pixelHeight}
      alt="Google ad preview"
    />
  );
}
