import { useEffect, useState } from "react";

export function useFillHeight(ref: React.RefObject<HTMLDivElement>) {
  const [availableHeight, setAvailableHeight] = useState(0);

  useEffect(() => {
    const updateAvailableHeight = () => {
      const rect = ref.current?.getBoundingClientRect();
      const height = window.innerHeight - (rect?.top || 0);
      setAvailableHeight(height);
    };

    updateAvailableHeight();

    window.addEventListener("resize", updateAvailableHeight);

    return () => window.removeEventListener("resize", updateAvailableHeight);
  }, [ref]);

  return availableHeight;
}
