"use strict";
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __await = (this && this.__await) || function (v) { return this instanceof __await ? (this.v = v, this) : new __await(v); }
var __asyncGenerator = (this && this.__asyncGenerator) || function (thisArg, _arguments, generator) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var g = generator.apply(thisArg, _arguments || []), i, q = [];
    return i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i;
    function verb(n) { if (g[n]) i[n] = function (v) { return new Promise(function (a, b) { q.push([n, v, a, b]) > 1 || resume(n, v); }); }; }
    function resume(n, v) { try { step(g[n](v)); } catch (e) { settle(q[0][3], e); } }
    function step(r) { r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r); }
    function fulfill(value) { resume("next", value); }
    function reject(value) { resume("throw", value); }
    function settle(f, v) { if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]); }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.notEmpty = exports.deslugify = exports.slugify = exports.jsonHash = exports.pick = exports.nestedGet = exports.fillArray = exports.toAsyncIterable = exports.b64Decode = exports.b64Encode = void 0;
//eslint-disable-next-line
var stringify = require("fast-stable-stringify");
function b64Encode(a) {
    var encoded = Buffer.from(a || "", "utf8").toString("base64");
    return encoded.replace("+", "-").replace("/", "_").replace(/=+$/, "");
}
exports.b64Encode = b64Encode;
function b64Decode(encoded) {
    encoded = encoded.replace("-", "+").replace("_", "/");
    while (encoded.length % 4)
        encoded += "=";
    return Buffer.from(encoded, "base64").toString("utf8");
}
exports.b64Decode = b64Decode;
function toAsyncIterable() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return __asyncGenerator(this, arguments, function toAsyncIterable_1() {
        var args_1, args_1_1, arg, e_1_1;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 6, 7, 8]);
                    args_1 = __values(args), args_1_1 = args_1.next();
                    _b.label = 1;
                case 1:
                    if (!!args_1_1.done) return [3 /*break*/, 5];
                    arg = args_1_1.value;
                    return [4 /*yield*/, __await(arg)];
                case 2: return [4 /*yield*/, _b.sent()];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4:
                    args_1_1 = args_1.next();
                    return [3 /*break*/, 1];
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_1_1 = _b.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 8];
                case 7:
                    try {
                        if (args_1_1 && !args_1_1.done && (_a = args_1.return)) _a.call(args_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    });
}
exports.toAsyncIterable = toAsyncIterable;
function fillArray(size, value) {
    var rv = new Array(size);
    for (var i = 0; i < size; ++i) {
        rv[i] = value();
    }
    return rv;
}
exports.fillArray = fillArray;
function nestedGet(obj, path, defaultValue) {
    var e_2, _a;
    var object = obj;
    try {
        for (var path_1 = __values(path), path_1_1 = path_1.next(); !path_1_1.done; path_1_1 = path_1.next()) {
            var pathElement = path_1_1.value;
            if (!(pathElement in obj)) {
                if (typeof defaultValue === "undefined") {
                    throw new Error("Could not find path ".concat(JSON.stringify(path), " within object ").concat(JSON.stringify(object)));
                }
                return defaultValue;
            }
            obj = obj[pathElement];
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (path_1_1 && !path_1_1.done && (_a = path_1.return)) _a.call(path_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return obj;
}
exports.nestedGet = nestedGet;
function pick(obj) {
    var keys = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        keys[_i - 1] = arguments[_i];
    }
    var ret = {};
    keys.forEach(function (key) {
        ret[key] = obj[key];
    });
    return ret;
}
exports.pick = pick;
exports.jsonHash = stringify;
function slugify(a) {
    return b64Encode((0, exports.jsonHash)(a));
}
exports.slugify = slugify;
function deslugify(b) {
    return JSON.parse(b64Decode(b));
}
exports.deslugify = deslugify;
function notEmpty(value) {
    return value !== null && value !== undefined;
}
exports.notEmpty = notEmpty;
