import { ControlBar, ControlBarElement } from "@components/control-bar";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { formatDateRangeText } from "@components/date-range-picker";
import { GenericDropdown } from "@components/dropdown";
import { ExpandableBreakdownSelector } from "@components/reports/breakdown-selector";
import { StuckToTop } from "@components/stuck-to-top";
import { useHasFeatureFlag } from "@hooks/use-feature-flag";
import {
  AttributionModelSelect,
  CompareDateRangeSelect,
  DateRangeSelect,
  GranularitySelect,
  TimeGranularitySelect,
} from "@shared/selects";
import {
  CompareDateRangeEnum,
  DateRangeChoiceEnum,
  TimeGranularity,
} from "@utils/constants";
import classNames from "classnames";
import React from "react";
import SavedViews from "../saved-views";
import { TabView } from "../tab-table-section/tab-table-section";
import { AttributionWindowSelect } from "./attribution-window-select";
import { ColumnSetSelect } from "./column-set-select";
import { NbAccountingModeSelect } from "./nb-accounting-mode-select";
import { ReportBodyState, useReportBodyState } from "./use-report-body-state";

export interface ReportBodyControlProps {
  isLoading: boolean;
}

export const ReportBodyControl = ({ isLoading }: ReportBodyControlProps) => {
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const hasFeatureFlag = useHasFeatureFlag();
  const {
    state,

    attributionModel,
    setAttributionModel,

    attributionWindow,
    setAttributionWindow,

    nbAccountingMode,
    setNbAccountingMode,

    columnSet,
    setColumnSet,

    granularity,
    setGranularity,

    breakdowns,
    setBreakdowns,

    timeGranularity,
    setTimeGranularity,

    setDateRange,
    setCompareDateRange,
  } = useReportBodyState();

  let maxLookbackDays = 20 * 365;
  let maxLookbackDaysCompare = 20 * 365;

  if (timeGranularity === "hourly") {
    maxLookbackDays = 8;
    maxLookbackDaysCompare = 15;
  }

  const dateRangeCurrent =
    typeof state.dateRange === "string"
      ? DateRangeChoiceEnum.toReactSelect(state.dateRange)
      : state.dateRange;

  const compareDateRangeCurrent =
    typeof state.compareDateRange === "string"
      ? CompareDateRangeEnum.toReactSelect(state.compareDateRange)
      : state.compareDateRange;

  const isAttributionWindowVisible =
    ((columnSet === "overview" || columnSet === "first_time_vs_returning") &&
      nbAccountingMode === "accrual") ||
    (!hasFeatureFlag("doNotShowNewSalesPage") &&
      nbAccountingMode === "accrual");

  return (
    <StuckToTop>
      {() => (
        <>
          <SavedViews />
          <ControlBar>
            <div
              className="flex flex-row items-center"
              style={{ flex: "3 3 auto", maxWidth: 1280 }}
            >
              <ControlBarElement
                title="Attribution model"
                parentClassName="nb-control-bar-element"
                style={{ flex: "3 3 0%" }}
              >
                <AttributionModelSelect
                  isLoading={isLoading}
                  attributionModel={attributionModel}
                  onChange={setAttributionModel}
                />
              </ControlBarElement>
              {isAttributionWindowVisible && (
                <ControlBarElement
                  title="Attribution window"
                  parentClassName="pl-1.5 nb-control-bar-element"
                  style={{ flex: "2 2 0%" }}
                >
                  <AttributionWindowSelect
                    isLoading={isLoading}
                    attributionModel={attributionModel}
                    attributionWindow={attributionWindow}
                    timeGranularity={timeGranularity}
                    onChange={setAttributionWindow}
                  />
                </ControlBarElement>
              )}
              <ControlBarElement
                title="Accounting mode"
                parentClassName="pl-1.5 nb-control-bar-element"
                style={{ flex: "3 3 0%" }}
              >
                <NbAccountingModeSelect
                  isLoading={isLoading}
                  nbAccountingMode={nbAccountingMode}
                  onChange={setNbAccountingMode}
                />
              </ControlBarElement>
              <ControlBarElement
                parentClassName="pl-1.5 nb-control-bar-element"
                style={{ flex: "3 3 0%" }}
                title="Breakdown by"
              >
                <ExpandableBreakdownSelector
                  disabled={isLoading}
                  current={breakdowns}
                  available={breakdownConfigs}
                  allConfigs={breakdownConfigs}
                  onUpdate={(arg) => {
                    setBreakdowns(arg);
                    if (granularity === TabView.PLATFORM) {
                      setGranularity({ value: TabView.CAMPAIGN });
                    }
                  }}
                />
              </ControlBarElement>
              {hasFeatureFlag("doNotShowNewSalesPage") && (
                <>
                  <ControlBarElement
                    title="Column set"
                    parentClassName="pl-1.5 nb-control-bar-element"
                    style={{ flex: "2 2 0%" }}
                  >
                    <ColumnSetSelect
                      isLoading={isLoading}
                      columnSet={columnSet}
                      onChange={setColumnSet}
                    />
                  </ControlBarElement>
                  <ControlBarElement
                    title="Granularity"
                    parentClassName="pl-1.5 nb-control-bar-element"
                    style={{ flex: "2 2 0%" }}
                  >
                    <GranularitySelect
                      isLoading={isLoading}
                      granularity={granularity}
                      onChange={setGranularity}
                    />
                  </ControlBarElement>
                </>
              )}
            </div>
            <div
              className="flex flex-row items-center justify-content-end pl-5"
              style={{ flex: "0" }}
            >
              <GenericDropdown
                renderButton={({ toggle }) => (
                  <button
                    className="dropdown-btn btn btn-outline-secondary dropdown-toggle custom-dropdown-toggle flex items-center"
                    style={{ height: 61 }}
                    disabled={isLoading}
                    type="button"
                    onClick={toggle}
                  >
                    <div className="flex flex-column align-items-start mr-2">
                      <div style={{ marginBottom: -4, fontSize: 14 }}>
                        {formatDateRangeText(dateRangeCurrent)},{" "}
                        {TimeGranularity.getLabel(timeGranularity)}
                      </div>
                      <small className="text-muted mt-1 text-small">
                        Compared to{" "}
                        {formatDateRangeText(compareDateRangeCurrent)}
                      </small>
                    </div>
                  </button>
                )}
                renderList={({ isOpen }) => (
                  <div
                    className={classNames(
                      "dropdown-menu dropdown-menu-right rounded",
                      { show: isOpen },
                    )}
                    style={{
                      position: "absolute",
                      minWidth: "20rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    <ControlBarElement
                      title="Time granularity"
                      parentClassName="mt-2 mb-3 px-2.5 nb-control-bar-element"
                    >
                      <TimeGranularitySelect
                        isLoading={isLoading}
                        timeGranularity={timeGranularity}
                        onChange={setTimeGranularity}
                      />
                    </ControlBarElement>
                    <ControlBarElement
                      title="Date range"
                      parentClassName="mb-3 px-2.5 nb-control-bar-element"
                    >
                      <DateRangeSelect
                        isLoading={isLoading}
                        maxLookbackDays={maxLookbackDays}
                        dateRange={state.dateRange}
                        onUpdate={setDateRange}
                        convertDateRangeToFixed={setDateRange}
                      />
                    </ControlBarElement>
                    <ControlBarElement
                      title="Compare to"
                      parentClassName="mb-2 px-2.5 nb-control-bar-element"
                    >
                      <CompareDateRangeSelect
                        isLoading={isLoading}
                        dateRange={state.dateRange}
                        compareDateRange={state.compareDateRange}
                        onUpdate={setCompareDateRange}
                        convertDateRangeToFixed={setCompareDateRange}
                        maxLookbackDays={maxLookbackDaysCompare}
                      />
                    </ControlBarElement>
                  </div>
                )}
              />
            </div>
          </ControlBar>
        </>
      )}
    </StuckToTop>
  );
};

export function makeReportBodyStateQuery(
  state: ReportBodyState,
): URLSearchParams {
  const search = new URLSearchParams();
  search.set("attributionModel", state.attributionModel);
  search.set("attributionWindow", state.attributionWindow);
  search.set("nbAccountingMode", state.nbAccountingMode);
  search.set("columnSet", state.columnSet);
  search.set("breakdowns", JSON.stringify(state.breakdowns));
  search.set("granularity", state.granularity);
  search.set("timeGranularity", state.timeGranularity);
  search.set("dateRange", JSON.stringify(state.dateRange));
  search.set("compareDateRange", JSON.stringify(state.compareDateRange));
  return search;
}
