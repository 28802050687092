import { usePageTitle } from "@/atoms/page-title-atom";
import { gql } from "@apollo/client";
import ErrorScreen from "@components/error-pages/error-screen";
import { useCachedQuery } from "@components/hooks";
import { StuckToTop } from "@components/stuck-to-top";
import { LoadingSlide, NoDataView } from "@components/title-slide-view";
import { H1 } from "@components/utilities";
import {
  GetModelComparisonReport,
  GetModelComparisonReportVariables,
} from "@nb-api-graphql-generated/GetModelComparisonReport";
import { todayLocalAsISODate } from "@north-beam/nb-common";
import { prettyLogEvent } from "@utils/analytics";
import { CommonGQLFragments } from "@utils/common-gql-fragments";
import { useNorthbeamQuery } from "@utils/hooks";
import { parseDateRangeArgument } from "@utils/index";
import React, { useEffect } from "react";
import { ModelComparisonTableSection } from "./model-comparison-section";
import { ReportBodyControl } from "./report-body-control";
import { useReportBodyState } from "./use-report-body-control";

export function ModelComparisonPage(): JSX.Element {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Model Comparison");
  }, [setPageTitle]);

  const { state, breakdowns, granularity } = useReportBodyState();
  const anchor = todayLocalAsISODate();
  const dateRange = parseDateRangeArgument(state.dateRange, anchor);

  const {
    data: _data,
    loading,
    error,
  } = useNorthbeamQuery<
    GetModelComparisonReport,
    GetModelComparisonReportVariables
  >(GET_MODEL_COMPARISON_REPORT, {
    variables: { dateRange, granularity },
  });

  const data = useCachedQuery(_data);

  const paramsForLogging = React.useMemo(
    () => ({
      "Date Range Type":
        typeof state.dateRange === "string"
          ? state.dateRange
          : state.dateRange.type,
      Breakdowns: breakdowns.map((v) => v.key),
      Granularity: granularity,
      "Model 1": state.attributionModel1,
      "Model 2": state.attributionModel2,
    }),
    [
      breakdowns,
      state.dateRange,
      granularity,
      state.attributionModel1,
      state.attributionModel2,
    ],
  );

  const response = data?.me?.salesReport;
  React.useEffect(() => {
    if (response) {
      prettyLogEvent({
        page: "Model Comparison",
        action: "Visited",
        data: {
          ...paramsForLogging,
          ...state,
        },
      });
    }
  }, [response, paramsForLogging, state]);

  if (loading) {
    return <LoadingSlide />;
  }

  if (response?.rows.length === 0) {
    return <NoDataView />;
  }

  if (error || !response) {
    return <ErrorScreen />;
  }

  return (
    <>
      <StuckToTop>{() => <ReportBodyControl isLoading={loading} />}</StuckToTop>
      <div className="px-4">
        <div className="row">
          <div className="col">
            <H1 className="mt-3">Model Comparison</H1>
            <p>
              Use this to identify the differences in how your attribution
              models assign credit to touchpoints. Data is reported on a{" "}
              <strong>Infinity-Day</strong> window using <strong>Cash</strong>{" "}
              accounting.
            </p>
          </div>
        </div>
        <ModelComparisonTableSection isLoading={loading} response={response} />
      </div>
    </>
  );
}

const GET_MODEL_COMPARISON_REPORT = gql`
  query GetModelComparisonReport(
    $dateRange: JSONObject!
    $granularity: String!
  ) {
    me {
      id
      salesReport(
        dateRange: $dateRange
        compareDateRange: $dateRange
        granularity: $granularity
        attributionMethod: "model_comparison"
        attributionWindow: "infinity"
        nbAccountingMode: "cash"
        timeGranularity: "daily"
      ) {
        rows {
          ...SalesReportRowAll
        }
        startDate
        endDate
        hasV1Forecasts
        customGoals {
          id
          displayName
        }
      }
    }
  }

  ${CommonGQLFragments.salesReportRowAll}
`;
