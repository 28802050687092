import React from "react";
import BaseErrorPage from "./base-error-page";

export default function DatasetDoesNotExist() {
  return (
    <BaseErrorPage
      pageName="DatasetDoesNotExist"
      pageBody={
        <h4 className="mt-4 text-center">
          <b>We&apos;ve begun processing your data.</b>{" "}
          <div className="mt-3">
            This may take up to 24 hours to populate after setting up your
            account. If you&apos;re still not seeing any data 24 hours after
            completing your onboarding, please reach out to your customer
            success representative.
          </div>
        </h4>
      }
    />
  );
}
