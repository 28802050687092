import classNames from "classnames";
import React from "react";
import { useToasts } from "react-toast-notifications";

export interface TrackingScriptCardProps {
  displayName: string;
  scriptCode: string;
  descriptionHTML: React.ReactElement;
  header: string;
  startExpanded?: boolean;
}

export function TrackingScriptCard({
  header,
  descriptionHTML,
  displayName,
  scriptCode,
  startExpanded,
}: TrackingScriptCardProps) {
  const [expanded, setExpanded] = React.useState(startExpanded || false);
  const { addToast } = useToasts();

  const copyToClipboard = React.useCallback(() => {
    navigator.clipboard.writeText(scriptCode);
    addToast("Copied!", { appearance: "success" });
  }, [addToast, scriptCode]);

  return (
    <div className="mt-5">
      <p className="secondary-font-purple">
        <b>{header}</b>
      </p>

      <div className="card my-3 rounded">
        <div
          className="card-header"
          style={{ cursor: "pointer" }}
          onClick={() => setExpanded(!expanded)}
        >
          <b>{displayName}</b>&nbsp;(click to {expanded ? "hide" : "show"})
        </div>
        <div className={classNames({ "card-body": true, "d-none": !expanded })}>
          <p className="card-text">
            <textarea
              style={{ height: "10em", fontFamily: "monospace" }}
              className="form-control"
              readOnly
              value={scriptCode}
            />
          </p>
          <div className="card-text my-3">
            <button
              className="btn btn-sm btn-primary"
              onClick={copyToClipboard}
            >
              Copy to clipboard
            </button>
          </div>
          <div className="card-text my-3 ">{descriptionHTML}</div>
          {expanded ? (
            <button
              className="btn btn-link p-0 mt-3"
              onClick={() => setExpanded(!expanded)}
            >
              Click to hide
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
