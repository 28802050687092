const DEMO_MODE_LS_KEY = "io.northbeam.DEMO_MODE";
const USE_DEVELOPMENT_ANALYTICS_DATA_LS_KEY =
  "io.northbeam.USE_DEVELOPMENT_ANALYTICS_DATA";

export const prefs = {
  setDemoMode(value: boolean) {
    localStorage.setItem(DEMO_MODE_LS_KEY, JSON.stringify(value));
  },

  isDemoMode(): boolean {
    const stringValue = localStorage.getItem(DEMO_MODE_LS_KEY);
    if (!stringValue) {
      return false;
    }

    try {
      return !!JSON.parse(stringValue);
    } catch (e) {
      return false;
    }
  },

  setUseDevelopmentAnalyticsData(value: boolean) {
    localStorage.setItem(
      USE_DEVELOPMENT_ANALYTICS_DATA_LS_KEY,
      JSON.stringify(value),
    );
  },

  useDevelopmentAnalyticsData(): boolean {
    const stringValue = localStorage.getItem(
      USE_DEVELOPMENT_ANALYTICS_DATA_LS_KEY,
    );
    if (!stringValue) {
      return false;
    }

    try {
      return !!JSON.parse(stringValue);
    } catch (e) {
      return false;
    }
  },
};
