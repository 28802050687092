import { usePageTitle } from "@/atoms/page-title-atom";
import { Balloon } from "@components/floating-balloons";
import { LoadingSlide, TitleSlide } from "@components/title-slide-view";
import { H1 } from "@components/utilities";
import {
  GetAccountMaintenanceItems,
  GetAccountMaintenanceItems_me_accountMaintenanceItems,
} from "@nb-api-graphql-generated/GetAccountMaintenanceItems";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect } from "react";
import { CollapsibleMaintenanceItems } from "./components/account-maintenance-items-views";
import { GET_ACCOUNT_MAINTENANCE_ITEMS } from "./queries";

export function AccountMaintenanceItemsListPage() {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Account Maintenance");
  }, [setPageTitle]);

  return (
    <div className="container pt-4">
      <LogOnMount name="Visit account maintenance page" />
      <div className="row">
        <div className="col">
          <H1>Account maintenance</H1>
          <p>
            Items below require your attention to ensure Northbeam can
            effectively measure the performance of your brand.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">{Inner()}</div>
      </div>
    </div>
  );
}

function Inner() {
  const { data, loading } = useNorthbeamQuery<GetAccountMaintenanceItems>(
    GET_ACCOUNT_MAINTENANCE_ITEMS,
  );

  if (loading) {
    return <LoadingSlide />;
  }

  const accountMaintenanceItems: GetAccountMaintenanceItems_me_accountMaintenanceItems[] =
    data!.me.accountMaintenanceItems;
  if (accountMaintenanceItems.length === 0) {
    return <NoOutstandingAccountMaintenanceItemsView />;
  }

  return (
    <CollapsibleMaintenanceItems
      accountMaintenanceItems={accountMaintenanceItems}
    />
  );
}

function NoOutstandingAccountMaintenanceItemsView() {
  return (
    <TitleSlide>
      <div>
        <Balloon color="hsl(215,50%,65%)" animationDuration="3.5s" />
        <Balloon color="hsl(139,50%,60%)" animationDuration="3s" />
        <Balloon color="hsl(23,55%,57%)" animationDuration="4.5s" />
      </div>
      <h3>
        All maintenance items taken care of.{" "}
        <span role="img" aria-label="hooray">
          🙌
        </span>
      </h3>
      <div style={{ height: "100px" }}></div>
    </TitleSlide>
  );
}
