import React from "react";
import { InstallationScriptDetails } from "@north-beam/nb-common";
import { TrackingScriptCard } from "./tracking-script-card";

interface TrackingScriptTagsProps {
  installationScripts: InstallationScriptDetails;
  startExpanded?: boolean;
}
export const LegacyRechargeTag = ({
  installationScripts,
  startExpanded,
}: TrackingScriptTagsProps): React.ReactElement => (
  <TrackingScriptCard
    displayName="NB conversion tag for Legacy Recharge Checkout (connected to Shopify stores)"
    header="If you use Legacy Recharge, please follow the instructions below to add this pixel."
    scriptCode={installationScripts.legacy_recharge_pixel}
    startExpanded={startExpanded}
    descriptionHTML={
      <>
        <p>
          <b>Note</b>: you only need this conversion tag if your Recharge
          checkout takes place on a <code>checkout.recharge.com</code> page.
        </p>
        <p>
          Newer versions of Recharge automatically track subscription
          information and thus do not need this pixel.
        </p>
        <p>
          Please follow{" "}
          <a
            href="https://docs.google.com/document/d/1ZBujboOxnRx-eibT5ZMdx3-sy7XzUQmREJgx2FgzRzE/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            these instructions
          </a>{" "}
          to install the ReCharge conversion tag.
        </p>
      </>
    }
  />
);
