import { useUser } from "@components/user-context";
import { useCallback, useState } from "react";
import { RestApiOptions, RestApiResponse } from "./use-rest-api";

type LazyRestCall = (url: string, options: RestApiOptions) => Promise<void>;

export type RestApiLazyResponse<T> = RestApiResponse<T> & {
  call: LazyRestCall;
};

export const useLazyRestApi = <T>(): RestApiLazyResponse<T> => {
  const { user } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<any>(null);

  const call: LazyRestCall = useCallback(
    async (url, options) => {
      setLoading(true);
      try {
        const res: T = await user.callApi({
          url,
          ...options,
        });
        setData(res);
        setLoading(false);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [user],
  );

  return {
    data,
    loading,
    error,
    call,
  };
};
