import { gql } from "@apollo/client";
import { SALES_BREAKDOWN_QUERY } from "@components/data/sales-breakdown-configs";
import {
  BulkUpsertLabelRulesV2,
  BulkUpsertLabelRulesV2Variables,
} from "@nb-api-graphql-generated/BulkUpsertLabelRulesV2";
import {
  CreateLabelRuleV2,
  CreateLabelRuleV2Variables,
} from "@nb-api-graphql-generated/CreateLabelRuleV2";
import {
  DeleteLabelRuleV2,
  DeleteLabelRuleV2Variables,
} from "@nb-api-graphql-generated/DeleteLabelRuleV2";
import {
  GetLabelRulesV2,
  GetLabelRulesV2_me_labelRulesV2,
} from "@nb-api-graphql-generated/GetLabelRulesV2";
import {
  UpdateLabelRuleV2,
  UpdateLabelRuleV2Variables,
} from "@nb-api-graphql-generated/UpdateLabelRuleV2";
import { useNorthbeamMutationPromise, useNorthbeamQuery } from "@utils/hooks";
import { omit } from "lodash";

export type LabelRule = Omit<GetLabelRulesV2_me_labelRulesV2, "__typename"> & {
  originalName?: string;
};

export const useLabelRules = () => {
  const { data: labelRulesData, loading: labelRulesLoading } =
    useNorthbeamQuery<GetLabelRulesV2>(GET_LABEL_RULES_V2);

  const [createLabelRule, { loading: createLabelRuleLoading }] =
    useNorthbeamMutationPromise<CreateLabelRuleV2, CreateLabelRuleV2Variables>(
      CREATE_LABEL_RULE_V2,
      {
        refetchQueries: [
          { query: GET_LABEL_RULES_V2 },
          { query: SALES_BREAKDOWN_QUERY },
        ],
        doNotRaiseErrorOnFailure: true,
      },
    );

  const [updateLabelRule, { loading: updateLabelRuleLoading }] =
    useNorthbeamMutationPromise<UpdateLabelRuleV2, UpdateLabelRuleV2Variables>(
      UPDATE_LABEL_RULE_V2,
      {
        refetchQueries: [
          { query: GET_LABEL_RULES_V2 },
          { query: SALES_BREAKDOWN_QUERY },
        ],
        doNotRaiseErrorOnFailure: true,
      },
    );

  const [bulkUpsertLabelRules, { loading: bulkUpsertLabelRulesLoading }] =
    useNorthbeamMutationPromise<
      BulkUpsertLabelRulesV2,
      BulkUpsertLabelRulesV2Variables
    >(BULK_UPSERT_LABEL_RULES_V2, {
      refetchQueries: [
        { query: GET_LABEL_RULES_V2 },
        { query: SALES_BREAKDOWN_QUERY },
      ],
      doNotRaiseErrorOnFailure: true,
    });

  const [deleteLabelRule, { loading: deleteLabelRuleLoading }] =
    useNorthbeamMutationPromise<DeleteLabelRuleV2, DeleteLabelRuleV2Variables>(
      DELETE_LABEL_RULE_V2,
      {
        refetchQueries: [
          { query: GET_LABEL_RULES_V2 },
          { query: SALES_BREAKDOWN_QUERY },
        ],
        doNotRaiseErrorOnFailure: true,
      },
    );

  const labelRules: LabelRule[] =
    labelRulesData?.me.labelRulesV2.map((rule: LabelRule) => {
      const ruleCopy = { ...rule };
      ruleCopy.originalName = rule.name;
      return omit(ruleCopy, "__typename");
    }) ?? [];

  return {
    labelRules,
    labelRulesLoading:
      labelRulesLoading ||
      createLabelRuleLoading ||
      updateLabelRuleLoading ||
      bulkUpsertLabelRulesLoading ||
      deleteLabelRuleLoading,
    updateLabelRule,
    createLabelRule,
    bulkUpsertLabelRules,
    deleteLabelRule,
  };
};

const GET_LABEL_RULES_V2 = gql`
  query GetLabelRulesV2 {
    me {
      id
      labelRulesV2 {
        name
        options
      }
    }
  }
`;

const UPDATE_LABEL_RULE_V2 = gql`
  mutation UpdateLabelRuleV2(
    $originalName: String!
    $name: String!
    $options: [String!]!
  ) {
    updateLabelRuleV2(
      originalName: $originalName
      name: $name
      options: $options
    ) {
      name
      options
    }
  }
`;

const CREATE_LABEL_RULE_V2 = gql`
  mutation CreateLabelRuleV2($name: String!, $options: [String!]!) {
    createLabelRuleV2(name: $name, options: $options) {
      name
      options
    }
  }
`;

const BULK_UPSERT_LABEL_RULES_V2 = gql`
  mutation BulkUpsertLabelRulesV2($labelRules: [LabelRuleV2Input!]!) {
    bulkUpsertLabelRulesV2(labelRules: $labelRules) {
      name
      options
    }
  }
`;

const DELETE_LABEL_RULE_V2 = gql`
  mutation DeleteLabelRuleV2($originalName: String!) {
    deleteLabelRuleV2(originalName: $originalName) {
      name
    }
  }
`;
