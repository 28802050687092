import React from "react";
import { UtmGuidePlatformProps } from "..";
import { ConstructDestinationUrlComponent } from "./shared/construct-destination-url-component";

export function ShareASale({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to all <b>destination URLs</b> for
        new and existing ads and campaigns.
      </p>
      <ol>
        <ConstructDestinationUrlComponent urlParamPairs={urlParamPairs} />
        <li>
          Use the copied URL when creating an ad or campaign with{" "}
          <b>ShareASale</b>.
        </li>
      </ol>
    </>
  );
}
