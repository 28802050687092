import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { UrlParamPair } from "@pages/settings/utm-guide/index";

interface ConstructDestinationUrlComponentProps {
  urlParamPairs: UrlParamPair[];
}

export function ConstructDestinationUrlComponent({
  urlParamPairs,
}: ConstructDestinationUrlComponentProps) {
  const { addToast } = useToasts();
  const [destinationUrl, setDestinationUrl] = useState<string>("");
  const [constructedUrl, setConstructedUrl] = useState<string>("");
  const urlParamString = urlParamPairs
    .map(({ key, value }) => `${key}=${value}`)
    .join("&");

  const constructAndCopyUrl = React.useCallback(() => {
    setConstructedUrl("");
    try {
      const url = new URL(destinationUrl);
      const existingSearchParams = url.search;

      let newConstructedUrl = "";

      let baseUrl = destinationUrl;

      if (destinationUrl.endsWith("/")) {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1);
      }

      if (!existingSearchParams) {
        newConstructedUrl = baseUrl + "/?" + urlParamString;
      } else {
        newConstructedUrl = baseUrl + "&" + urlParamString;
      }

      setConstructedUrl(newConstructedUrl);

      window.navigator.clipboard.writeText(newConstructedUrl);

      addToast("Copied to clipboard!", { appearance: "success" });
    } catch (err) {
      addToast("Invalid URL", { appearance: "error" });
    }
  }, [urlParamString, destinationUrl, addToast]);

  return (
    <>
      <div className="input-group">
        <textarea
          rows={4}
          className="form-control mb-2"
          style={{ wordBreak: "break-all" }}
          readOnly
          value={`?${urlParamString}`}
        />
      </div>
      <li>
        <div className="control-label mb-2" data-testid="destination-url-label">
          Paste the destination URL for your ad here. Make sure to include{" "}
          <code>https://</code> in the URL:
        </div>
        <input
          type="text"
          className="form-control mb-3"
          data-testid="destination-url-input"
          value={destinationUrl}
          onChange={(event) => setDestinationUrl(event.target.value)}
        />
      </li>
      <li>
        Construct and copy the new URL with Northbeam&apos;s URL parameters.
      </li>
      <button
        className="btn btn-primary mb-3 mt-2"
        type="button"
        onClick={constructAndCopyUrl}
      >
        Construct and copy
      </button>
      <div className="input-group">
        <textarea
          rows={4}
          className="form-control mb-2"
          readOnly
          value={constructedUrl}
        />
      </div>
    </>
  );
}
