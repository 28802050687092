import { useHasFeatureFlag } from "@hooks/use-feature-flag";
import { IconButton } from "@shared/buttons";
import { logEvent } from "@utils/analytics";
import React, { useState } from "react";
import { NewSalesExperienceSwitch } from "../new-sales-experience-switch";
import { useSalesViews } from "../sales-views-context";
import { ManageSalesViewModal } from "./manage-sales-view-modal/manage-sales-view-modal";
import { MaxViewsModal } from "./max-views-modal";
import { SaveNewSalesViewModal } from "./save-new-sales-view-modal";
import { SavedViewsSelect } from "./saved-views-select";

export const MAX_SAVED_VIEWS = 25 as const;

export const SavedViews = () => {
  const { hasSalesViewChanges, salesViews } = useSalesViews();
  const hasFeatureFlag = useHasFeatureFlag();
  const [isSaveSalesViewsOpen, setIsSaveSalesViewsOpen] = useState(false);
  const [isManageSalesViewsOpen, setIsManageSalesViewsOpen] = useState(false);
  const [isMaxViewsModalOpen, setIsMaxViewsModalOpen] = useState(false);

  if (hasFeatureFlag("doNotShowNewSalesPage")) {
    return null;
  }

  const openSaveNewSalesViewModal = () => {
    logEvent("Saved Views: User clicked on Save new sales view button");
    if (salesViews.length < MAX_SAVED_VIEWS) {
      setIsSaveSalesViewsOpen(true);
    } else {
      logEvent("Saved Views: User reached max saved views");
      setIsMaxViewsModalOpen(true);
    }
  };

  const openManageSalesViewsModal = () => {
    logEvent("Saved Views: User clicked on Manage sales views button");
    setIsManageSalesViewsOpen(true);
  };

  return (
    <>
      <div
        data-testid="saved-views"
        className="flex justify-between pt-2 px-4 bg-white py-1"
      >
        <div className="flex items-center space-x-2">
          <SavedViewsSelect />
          <IconButton
            variant="tertiary"
            onClick={openSaveNewSalesViewModal}
            rightIcon={<i className="fa-solid fa-plus text-primary" />}
            isDisabled={!hasSalesViewChanges}
            tooltip={
              !hasSalesViewChanges
                ? "Make adjustments to the page to save a new view"
                : "Save all filters and page selections as a new view"
            }
          >
            Save
          </IconButton>
          <IconButton
            variant="tertiary"
            onClick={openManageSalesViewsModal}
            rightIcon={<i className="fa-solid fa-gear text-primary" />}
          >
            Manage
          </IconButton>
        </div>
        <NewSalesExperienceSwitch />
      </div>
      <SaveNewSalesViewModal
        isOpen={isSaveSalesViewsOpen}
        close={() => setIsSaveSalesViewsOpen(false)}
      />
      <ManageSalesViewModal
        isOpen={isManageSalesViewsOpen}
        close={() => setIsManageSalesViewsOpen(false)}
      />
      <MaxViewsModal
        isOpen={isMaxViewsModalOpen}
        close={() => setIsMaxViewsModalOpen(false)}
      />
    </>
  );
};
