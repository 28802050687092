import React, { FC } from "react";
import FaqWrapper from "react-faq-component";
import { StepWizardChildProps } from "react-step-wizard";
import { ONE_CLICK_PLATFORM_INTEGRATIONS } from ".";
import { StepContainer } from "../step-container";

interface ConnectionCardWrapperProps {
  children: JSX.Element;
  integrations: string[];
  goToNextStep: () => void;
  goToMajorStep: (step: string) => void;
  hideFaq?: boolean;
}

export const ConnectionCardWrapper: FC<
  Partial<StepWizardChildProps> & ConnectionCardWrapperProps
> = ({
  goToNamedStep,
  stepName,
  integrations,
  children,
  goToNextStep,
  goToMajorStep,
  hideFaq,
}) => {
  if (!stepName || !goToNamedStep) {
    return <></>;
  }

  const styles = {
    bgColor: "white",
    rowContentTextSize: "16px",
    rowContentPaddingTop: "10px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "20px",
    rowContentPaddingRight: "20px",
    arrowColor: "black",
  };

  const config = {
    tabFocus: true,
  };

  const rows = [
    {
      title: "What if I don't have access to this platform?",
      content: (
        <>
          Feel free to{" "}
          <button
            className="btn btn-link p-0"
            onClick={() => goToMajorStep("account-managers")}
          >
            add an account manager
          </button>{" "}
          who does have access, and then have them return to this step. The
          account manager will be able to connect to this platform once they
          create a Northbeam profile.
        </>
      ),
    },
    {
      title: "What permissions does Northbeam need?",
      content:
        "Please give Northbeam the maximum permissions allowed when connecting your account. That will ensure that we have the tools we need to efficiently accurately track your ads.",
    },
  ];

  const faqData = {
    rows,
  };

  const connectablePlatforms = integrations.filter((platform) =>
    Object.keys(ONE_CLICK_PLATFORM_INTEGRATIONS).includes(platform),
  );

  const currentIndex = connectablePlatforms.indexOf(stepName);

  const goBack = () => {
    if (currentIndex !== 0) {
      goToNamedStep(connectablePlatforms[currentIndex - 1]);
    } else {
      goToNamedStep("collection");
    }
  };

  const goForward = () => {
    if (currentIndex !== connectablePlatforms.length - 1) {
      goToNamedStep(connectablePlatforms[currentIndex + 1]);
    } else {
      goToNextStep();
    }
  };

  return (
    <StepContainer
      onMoveBackwardButtonClicked={goBack}
      onMoveForwardButtonClicked={goForward}
      onSkipLinkClicked={goForward}
    >
      {children}
      <>
        {!hideFaq && (
          <FaqWrapper data={faqData} styles={styles} config={config} />
        )}
      </>
    </StepContainer>
  );
};
