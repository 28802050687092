import deepEqual from "deep-equal";
import _ from "lodash";
import React from "react";
import Select, { components } from "react-select";
import { MonthIntervalPicker } from "@components/month-interval-picker";
import { pickConfig } from "@components/reports/breakdown-selector";
import {
  ControlCenterWrapper,
  ControlWrapper,
} from "@components/reports/control-center-utilities";
import { Breakdown, BreakdownConfig } from "@north-beam/nb-common";
import { MonthInterval } from "@north-beam/nb-common";
import { parseBreakdown, parseMonthInterval } from "@utils/constants";

export interface CustomerReportParams {
  readonly breakdown: Breakdown;
  readonly firstAcquiredMonthRange: MonthInterval;
}

interface CustomerReportState {
  breakdown?: Breakdown; // not actually readonly
  readonly firstAcquiredMonthRange: MonthInterval;
}

interface UpdateBreakdown {
  type: "UpdateBreakdown";
  value: Breakdown | undefined;
}

interface UpdateFirstAcquiredMonthRange {
  type: "UpdateFirstAcquiredMonthRange";
  value: MonthInterval;
}

interface RefreshState {
  type: "RefreshState";
  value: CustomerReportState;
}

type ReportAction =
  | UpdateBreakdown
  | UpdateFirstAcquiredMonthRange
  | RefreshState;

function ReportStateReducer(
  state: CustomerReportState,
  action: ReportAction,
): CustomerReportState {
  switch (action.type) {
    case "UpdateFirstAcquiredMonthRange":
      return satisfyConstraints({
        ...state,
        firstAcquiredMonthRange: action.value,
      });
    case "UpdateBreakdown":
      return { ...state, breakdown: action.value };
    case "RefreshState":
      return action.value;
  }
}

function satisfyConstraints(state: CustomerReportState): CustomerReportState {
  const rv = { ...state };
  const mi = state.firstAcquiredMonthRange;
  const clip = (bd: Breakdown) => clipMonthBreakdown(bd, mi);
  if (rv.breakdown) {
    rv.breakdown = clip(rv.breakdown);
  }
  return state;
}

function clipMonthBreakdown(
  breakdown: Breakdown,
  mi: MonthInterval,
): Breakdown {
  if (breakdown.key !== "month") {
    return breakdown;
  }

  const { startMonth, endMonth } = mi;
  return {
    ...breakdown,
    values: breakdown.values.filter((v) => v >= startMonth && v <= endMonth),
  };
}

function clipMonthBreakdownConfig(
  config: BreakdownConfig,
  mi: MonthInterval,
): BreakdownConfig {
  if (config.type !== "month") {
    return config;
  }

  const { startMonth, endMonth } = mi;
  return {
    ...config,
    minMonth: _.max([startMonth, config.minMonth])!,
    maxMonth: _.min([endMonth, config.maxMonth])!,
  };
}

export interface CustomerControlCenterProps {
  onReportStateUpdated: (state: CustomerReportParams) => void;
  disabled: boolean;
  initialState: CustomerReportState;
  firstAcquiredBounds: MonthInterval;
  breakdownConfigs: BreakdownConfig[];
}

export function CustomerControlCenter(props: CustomerControlCenterProps) {
  const {
    initialState,
    breakdownConfigs: originalBreakdownConfigs,
    disabled,
    onReportStateUpdated,
    firstAcquiredBounds,
  } = props;

  const [proposedState, dispatch] = React.useReducer(
    ReportStateReducer,
    initialState,
  );

  React.useEffect(
    () => dispatch({ type: "RefreshState", value: initialState }),
    [initialState],
  );

  const updateBreakdown = React.useCallback(
    (value: BreakdownConfig) => {
      dispatch({
        type: "UpdateBreakdown",
        value: { key: value.key, values: [] },
      });
    },
    [dispatch],
  );

  const { firstAcquiredMonthRange, breakdown } = proposedState;

  const breakdownConfigs = React.useMemo(() => {
    return originalBreakdownConfigs.map((v) =>
      clipMonthBreakdownConfig(v, firstAcquiredMonthRange),
    );
  }, [originalBreakdownConfigs, firstAcquiredMonthRange]);

  const actualAvailableBreakdowns = breakdownConfigs;

  const submit = isReadyForSubmit(proposedState)
    ? () => onReportStateUpdated(proposedState)
    : // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {};

  const blockSubmitButton =
    disabled ||
    deepEqual(initialState, proposedState) ||
    !isReadyForSubmit(proposedState);

  return (
    <ControlCenterWrapper>
      <ControlWrapper title="Customers first acquired between...">
        <MonthIntervalPicker
          disabled={disabled}
          bounds={firstAcquiredBounds}
          value={firstAcquiredMonthRange}
          onUpdate={(mi) =>
            dispatch({
              type: "UpdateFirstAcquiredMonthRange",
              value: mi,
            })
          }
        />
      </ControlWrapper>
      <ControlWrapper title="By">
        <Select<any>
          isDisabled={disabled}
          isClearable={false}
          isSearchable={false}
          onChange={updateBreakdown}
          value={
            breakdown && pickConfig(breakdown.key, originalBreakdownConfigs)
          }
          getOptionLabel={(v) => v.name}
          getOptionValue={(v) => v.key}
          options={actualAvailableBreakdowns}
          placeholder="Add breakdown..."
          components={{
            DropdownIndicator: (props: any) => (
              <components.DropdownIndicator {...props}>
                <i className="fas fa-chart-pie"></i>
              </components.DropdownIndicator>
            ),
          }}
        />
      </ControlWrapper>
      <ControlWrapper>
        <button
          className="btn btn-block btn-primary"
          disabled={blockSubmitButton}
          onClick={submit}
        >
          Update report
        </button>
      </ControlWrapper>
    </ControlCenterWrapper>
  );
}

export function isReadyForSubmit(
  state: CustomerReportState,
): state is CustomerReportParams {
  return !!state.breakdown;
}

export function makeReportStateQuery(state: CustomerReportState) {
  const search = new URLSearchParams();
  search.set(
    "firstAcquiredMonthRange",
    JSON.stringify(state.firstAcquiredMonthRange),
  );
  if (state.breakdown) {
    search.set("breakdown", JSON.stringify(state.breakdown));
  }
  return search;
}

export function parseReportState(
  query: string,
  firstAcquiredBounds: MonthInterval,
): CustomerReportState {
  const params = new URLSearchParams(query);
  const firstAcquiredMonthRange =
    parseMonthInterval(params.get("firstAcquiredMonthRange")) ??
    firstAcquiredBounds;
  const breakdown = parseBreakdown(params.get("breakdown")) ?? undefined;

  return satisfyConstraints({
    firstAcquiredMonthRange,
    breakdown,
  });
}
