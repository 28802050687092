import React, { ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";

const menuPositionClasses = {
  "top-left": "right-0 bottom-0 mt-2",
  "top-right": "left-0 bottom-0 mt-2",
  "bottom-left": "right-0 top-0 mb-2",
  "bottom-right": "left-0 top-0 mb-2",
} as const;

interface ContextMenuProps {
  menuButton: ReactNode;
  className?: string;
  menuClassName?: string;
  children: ReactNode;
  menuPosition?: keyof typeof menuPositionClasses;
}

export const ContextMenu = ({
  className,
  menuButton,
  menuClassName,
  children,
  menuPosition = "bottom-left",
}: ContextMenuProps) => {
  return (
    <Menu as="div" className={clsx(className, "relative")}>
      {menuButton}
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items
          className={clsx(
            menuClassName,
            "absolute rounded-md bg-white text-left text-sm elevation-1",
            menuPositionClasses[menuPosition],
          )}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

ContextMenu.Item = Menu.Item;
ContextMenu.Button = Menu.Button;
