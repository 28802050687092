import { GenericTooltip } from "@components/tooltip-container";
import { DeleteIconButton, TertiaryButton } from "@shared/buttons";
import { TextInput } from "@shared/text-input";
import {
  substringFromListWithSelf,
  uniqueFromListWithSelf,
} from "@shared/validators";
import React from "react";
import { UseFormRegister } from "react-hook-form";
import { LabelBuilderData } from "./label-builder";

interface LabelOptionValueProps {
  className?: string;
  categoryIndex: number;
  optionIndex: number;
  errors: any;
  register: UseFormRegister<LabelBuilderData>;
  removeOption: (index: number) => void;
  existingOptions: string[];
  simulateTrafficLabelMatches: (categoryIndex: number) => void;
}

export const LabelOptionValue = ({
  className,
  categoryIndex,
  optionIndex,
  errors,
  register,
  removeOption,
  existingOptions,
  simulateTrafficLabelMatches,
}: LabelOptionValueProps) => {
  return (
    <div className={className}>
      <i className="fa-regular fa-turn-down-right mr-2" />
      <TextInput
        label={
          <GenericTooltip
            content={
              <>
                This will be the text in your campaign, ad set, or ad name you
                wish to seperate within your indicated breakdown. <br />{" "}
                <i>Example:</i> If you have 2 campaigns <b>US_Alpha</b> and{" "}
                <b>INTL_Beta</b> your segment options would be &ldquo;US&rdquo;
                and &ldquo;INTL&rdquo;, or &ldquo;Alpha&rdquo; and
                &ldquo;Beta&rdquo;.
              </>
            }
            overlayClass=""
            captureEventString="HoverOverBreakdownName"
          >
            Campaign, Ad Set, Ad Segment
          </GenericTooltip>
        }
        width="230px"
        errors={errors}
        {...register(`data.${categoryIndex}.options.${optionIndex}`, {
          required: "Option is required",
          validate: {
            unique: uniqueFromListWithSelf({
              compareValues: existingOptions,
              errorMessage: "Option must be unique",
            }),
            substring: substringFromListWithSelf({
              compareValues: existingOptions,
              errorMessage: "Option must not be a substring",
            }),
          },
        })}
      />
      <TertiaryButton
        className="mx-1"
        onClick={() => simulateTrafficLabelMatches(categoryIndex)}
      >
        Simulate
      </TertiaryButton>
      <DeleteIconButton onClick={() => removeOption(optionIndex)} />
    </div>
  );
};
