import { GenericExternalLink } from "@components/generic-external-link";
import { useUser } from "@components/user-context";
import {
  AttemptToAddAccountManager,
  AttemptToAddAccountManagerVariables,
} from "@nb-api-graphql-generated/AttemptToAddAccountManager";
import {
  AttemptToRemoveAccountManager,
  AttemptToRemoveAccountManagerVariables,
} from "@nb-api-graphql-generated/AttemptToRemoveAccountManager";
import { FetchSettingsDetails_me_managerUsers } from "@nb-api-graphql-generated/FetchSettingsDetails";
import { AttemptToAddAccountManagerStatus } from "@nb-api-graphql-generated/global-types";
import { ATTEMPT_TO_ADD_ACCOUNT_MANAGER_STATUS } from "@north-beam/nb-common";
import { useNorthbeamMutation } from "@utils/hooks";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import AccountManagerTable from "./account-manager-table";
import {
  ATTEMPT_TO_ADD_ACCOUNT_MANAGER,
  ATTEMPT_TO_REMOVE_ACCOUNT_MANAGER,
  FETCH_SETTINGS_DETAILS,
} from "./queries";

interface AccountManagerProps {
  managerUsers: FetchSettingsDetails_me_managerUsers[];
}
export function AccountManagerSection(props: AccountManagerProps) {
  const { user } = useUser();

  const [managerUsers, setManagerUsers] = useState<
    FetchSettingsDetails_me_managerUsers[]
  >(props.managerUsers);

  const [managersEmailsVerified, setManagersEmailsVerified] = useState<any>({});
  const [newManagerEmail, setNewManagerEmail] = useState<string>("");

  useEffect(() => {
    const getManagerEmailVerificationStatus = async () => {
      props.managerUsers.forEach(
        async (managerUser: FetchSettingsDetails_me_managerUsers) => {
          if (managerUser.email === user.email) {
            setManagersEmailsVerified((prevState: any) => {
              const newState = { ...prevState };
              newState[managerUser.email] = {
                loading: false,
                verified: true,
                error: false,
              };
              return newState;
            });
          } else {
            setManagersEmailsVerified((prevState: any) => {
              const newState = { ...prevState };
              newState[managerUser.email] = {
                loading: true,
              };
              return newState;
            });
            try {
              const emailVerificationResult = await user.callApi({
                method: "GET",
                url: `/api/user/email-verified-status/${managerUser.id}`,
              });
              setManagersEmailsVerified((prevState: any) => {
                const newState = { ...prevState };
                newState[managerUser.email] = {
                  loading: false,
                  verified: emailVerificationResult.emailVerified,
                  error: false,
                };
                return newState;
              });
            } catch (err) {
              setManagersEmailsVerified((prevState: any) => {
                const newState = { ...prevState };
                newState[managerUser.email] = {
                  loading: false,
                  verified: false,
                  error: true,
                };
                return newState;
              });
            }
          }
        },
      );
    };

    setManagerUsers(props.managerUsers);
    getManagerEmailVerificationStatus();
  }, [props.managerUsers, user]);

  const [submittedNewManagerEmail, setSubmittedNewManagerEmail] = useState("");
  const [status, setStatus] =
    useState<ATTEMPT_TO_ADD_ACCOUNT_MANAGER_STATUS | null>(null);

  const [
    attemptToAddAccountManager,
    { loading: attemptToAddAccountManagerLoading },
  ] = useNorthbeamMutation<
    AttemptToAddAccountManager,
    AttemptToAddAccountManagerVariables
  >(ATTEMPT_TO_ADD_ACCOUNT_MANAGER, {
    refetchQueries: [{ query: FETCH_SETTINGS_DETAILS }],
  });

  const [
    attemptToRemoveAccountManager,
    { loading: attemptToRemoveAccountManagerLoading },
  ] = useNorthbeamMutation<
    AttemptToRemoveAccountManager,
    AttemptToRemoveAccountManagerVariables
  >(ATTEMPT_TO_REMOVE_ACCOUNT_MANAGER, {
    refetchQueries: [{ query: FETCH_SETTINGS_DETAILS }],
  });

  const addAccountManager = useCallback(
    async (managerToAdd) => {
      setSubmittedNewManagerEmail(managerToAdd);
      setStatus(null);

      try {
        const result = await attemptToAddAccountManager({
          variables: { email: managerToAdd },
        });
        if (result?.data?.attemptToAddAccountManager) {
          setStatus(result.data.attemptToAddAccountManager);
        } else {
          setStatus(AttemptToAddAccountManagerStatus.HasError);
        }
      } catch (e) {
        setStatus(AttemptToAddAccountManagerStatus.HasError);
      }
    },
    [attemptToAddAccountManager],
  );

  const onAddManagerClicked = () => {
    addAccountManager(newManagerEmail);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    onAddManagerClicked();
  };

  const onRemoveManagerClicked = useCallback(
    async (id: string) => {
      setManagerUsers(managerUsers.filter((user) => user.id !== id));
      await attemptToRemoveAccountManager({
        variables: { managerId: id },
      });
    },
    [attemptToRemoveAccountManager, managerUsers],
  );

  const statusSection = () => {
    if (status === AttemptToAddAccountManagerStatus.HasError) {
      return (
        <div className="alert alert-danger mb-3" role="alert">
          An error occurred while attempting to add a manager to your account.
          Are you sure you inputted the email correctly? If the problem
          persisits, please reach out to{" "}
          <a href="mailto:success@northbeam.io">success@northbeam.io</a>
        </div>
      );
    } else if (status === AttemptToAddAccountManagerStatus.AlreadyManager) {
      return (
        <div className="alert alert-success mb-3" role="alert">
          {submittedNewManagerEmail} is already a manager on your account.
          Please let the owner of this email know that they have received an
          invite in their inbox to setup a Northbeam profile.
        </div>
      );
    } else if (status === AttemptToAddAccountManagerStatus.SuccessfullyAdded) {
      return (
        <div className="alert alert-success mb-3" role="alert">
          {submittedNewManagerEmail} has been added as a manager to your
          account!
        </div>
      );
    } else if (status === AttemptToAddAccountManagerStatus.InvitationSent) {
      return (
        <div className="alert alert-warning mb-3" role="alert">
          We&apos;ve added <b>{submittedNewManagerEmail}</b> as a manager to
          your account. However, they have not yet set up a Northbeam profile.
          Please let the owner of this email know that they have received an
          invite in their inbox to setup a Northbeam profile.
        </div>
      );
    } else {
      return null;
    }
  };

  const isAccountOwner = user.isAdmin || !user.impersonatedUser;

  return (
    <>
      {!attemptToRemoveAccountManagerLoading && (
        <AccountManagerTable
          managerUsers={managerUsers}
          managersEmailsVerified={managersEmailsVerified}
          onRemoveManager={onRemoveManagerClicked}
          isAccountOwner={isAccountOwner}
          userEmail={user.email}
          addAccountManager={addAccountManager}
        />
      )}
      {attemptToRemoveAccountManagerLoading && <>Please wait...</>}
      <p className="mt-4">
        If you would like to invite a user, please add their email below and
        click <b>Add Manager</b>.
      </p>
      <p>
        Note that Northbeam does not put a limit on the number of users in your
        account. Feel free to add as many people as you would like.
      </p>
      <p style={{ fontSize: 18 }}>
        If the user does not receive a email,{" "}
        <b>please check the spam folder</b>. If they still do not receive an
        email, they can generate a password through the <i>password reset</i>{" "}
        flow on{" "}
        <GenericExternalLink href="https://auth.northbeam.io/u/reset-password/request/Username-Password-Authentication?state=hKFo2SBfbzJWTjJDZjdjRktBS2V5ZGlManhZZHhOQjhpTE9MdKFur3VuaXZlcnNhbC1sb2dpbqN0aWTZIE84RFNvaGF0Q25aOUdqaGlzVDlHWXUwMkpMeU9IaHQto2NpZNkgU0F3em5GYjJaUG1DaWR1T3YwbEtxWjU1bzUxNTVjRDg">
          dashboard.northbeam.io
        </GenericExternalLink>
        .
      </p>
      {statusSection()}
      <div className="mb-4 mt-3">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-auto">
              <label className="sr-only" htmlFor="newManagerEmail">
                Email
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={newManagerEmail}
                  placeholder="Email"
                  id="newManagerEmail"
                  onChange={(event) =>
                    setNewManagerEmail(
                      event.target.value?.toLocaleLowerCase().trim(),
                    )
                  }
                />
              </div>
            </div>
            <div className="col-auto">
              <button
                disabled={attemptToAddAccountManagerLoading || !newManagerEmail}
                className="btn btn-primary"
                type="submit"
              >
                {attemptToAddAccountManagerLoading
                  ? "Please Wait..."
                  : "Add manager"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
