import { AccountMetadata } from "@north-beam/nb-common";
import { PrimaryButton } from "@shared/buttons";
import { isAxiosError } from "@utils/index";
import Interweave from "interweave";
import React from "react";
import { serializeError } from "serialize-error";

export interface AccountMetadataWithDescription extends AccountMetadata {
  descriptionHTML?: string;
}

export interface AccountSelectorProps {
  tag: string;
  accountList: AccountMetadataWithDescription[];

  isSaving: boolean;
  onSave: (selectedId: AccountMetadata) => Promise<any>;
}

export default function AccountSelector({
  tag,
  accountList,
  isSaving,
  onSave,
}: AccountSelectorProps) {
  const [selected, setSelected] =
    React.useState<AccountMetadataWithDescription>();
  const [error, setError] = React.useState<string>("");
  const isMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const save = React.useCallback(async () => {
    if (!selected) {
      setError("Please select an account to link.");
      return;
    }

    try {
      await onSave(selected);
      setError("");
    } catch (e) {
      if (!isMounted.current) {
        return;
      }

      if (isAxiosError(e)) {
        setError(e.response?.data?.toString() || "Unknown error");
      } else {
        setError(JSON.stringify(serializeError(e)));
      }
    }
  }, [selected, onSave]);

  return (
    <>
      {error && (
        <div className="alert alert-danger" role="alert">
          <b>An error occurred!</b> {error}
        </div>
      )}
      <div className="mb-3">
        <p>Select an account to connect:</p>
        {accountList.map((adAccount) => (
          <div key={adAccount.id} className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id={`adAccount_${tag}_${adAccount.id}`}
              checked={adAccount.id === selected?.id}
              onChange={() => setSelected(adAccount)}
            />
            <label htmlFor={`adAccount_${tag}_${adAccount.id}`}>
              {`${adAccount.name} (id: ${adAccount.id})`}
              {adAccount.descriptionHTML && (
                <>
                  {" "}
                  &emsp;(
                  <Interweave content={adAccount.descriptionHTML} />)
                </>
              )}
            </label>
          </div>
        ))}
        <PrimaryButton
          onClick={save}
          loading={isSaving}
          isDisabled={isSaving}
        >
          Save
        </PrimaryButton>
      </div>
    </>
  );
}
