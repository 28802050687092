import { User, useUser } from "@components/user-context";
import { DELETE_DOCUMENTS, FETCH_DOCUMENTS } from "@gql/documents";
import { FormControl, FormGroup } from "@lib/forms";
import { useObjectState } from "@lib/hooks";
import {
  DeleteDocument,
  DeleteDocumentVariables,
} from "@nb-api-graphql-generated/DeleteDocument";
import { Documents_me_documents } from "@nb-api-graphql-generated/Documents";
import { PrimaryButton } from "@shared/buttons";
import { Modal } from "@shared/modals/modalV2";
import { useNorthbeamMutation } from "@utils/hooks";
import React, { FormEvent, useState } from "react";
import { useToasts } from "react-toast-notifications";

export function AdminDeleteModal({
  onClose,
  document,
}: {
  onClose: () => void;
  document: Documents_me_documents;
}) {
  const [deleteDocumentMutation, { loading }] = useNorthbeamMutation<
    DeleteDocument,
    DeleteDocumentVariables
  >(DELETE_DOCUMENTS, {
    refetchQueries: [{ query: FETCH_DOCUMENTS }],
  });

  const { addToast } = useToasts();

  return (
    <Modal
      isOpen={true}
      widthClassname={"w-[30rem]"}
      heightClassname={"h-[20rem]"}
      closeButton
      closeModal={onClose}
    >
      <div className="py-10 px-10">
        <div className="text-lg font-semibold flex items-center">
          <i
            className="fa-regular fa-trash mr-5"
            style={{ fontSize: "1.5em" }}
          />
          Delete a Document
        </div>
        <div className="mt-4">
          <span className="font-semibold">Document:</span> {document.name}
        </div>
        <div className="mt-4">
          Deleting this document will mean it will no longer be available to
          you.
        </div>
        <div className="mt-4">This is not a recoverable action.</div>
        <PrimaryButton
          className={
            "h-[2.5rem] w-[7rem] px-5 absolute bottom-8 right-8 bg-danger"
          }
          isDisabled={loading}
          loading={loading}
          onClick={async () => {
            await deleteDocumentMutation({
              variables: {
                documentName: document.name,
              },
            });

            addToast(`The document, ${document.name}, has been deleted`, {
              appearance: "success",
              autoDismiss: true,
            });
            onClose();
          }}
        >
          Delete
        </PrimaryButton>
      </div>
    </Modal>
  );
}

export function AdminUploadModal({
  isOpen = false,
  onClose,
  onUpload,
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpload: () => void;
}) {
  const { user } = useUser();
  const [form, setFormData] = useObjectState<UploadFormData>({
    name: "",
    fileName: "",
    file: null,
  });
  const [errors, setErrors] = useState<Errors>({});

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={onClose}
      closeModal={onClose}
      widthClassname="w-[30rem]"
      heightClassname="h-[30rem]"
      closeButton
    >
      <form
        className="py-10 px-10"
        onSubmit={async (event) => {
          const document = await onSubmit(event, user, setErrors, setFormData);
          if (document) onUpload();
        }}
      >
        <FormGroup label="Name">
          <FormControl
            name="name"
            value={form.name}
            className={errors.name && "is-invalid"}
            onChange={(name) => setFormData({ name })}
            helpText={errors.name}
          />
        </FormGroup>
        <FormGroup label="File">
          <FormControl
            name="file"
            type="file"
            className={errors.file && "is-invalid"}
            value={form.fileName}
            onChange={(fileName, event) => {
              const target = event.target as HTMLInputElement | null;
              const files = target?.files || [];
              const file = files[0];

              setFormData({ fileName, file });
            }}
            helpText={errors.file}
          />
        </FormGroup>
        <div>
          <PrimaryButton type="submit" className="mr-2">
            Upload
          </PrimaryButton>
          <small className="text-danger">
            {Object.keys(errors).length > 0 && "Please fix the errors above"}
          </small>
        </div>
      </form>
    </Modal>
  );
}

async function onSubmit(
  event: FormEvent,
  user: User,
  setErrors: (errors: Errors) => void,
  setFormData: (formData: UploadFormData) => void,
) {
  const form = event.target as HTMLFormElement;
  const formData = new FormData(form);
  event.preventDefault();

  const errors: Errors = {};
  if ((formData.get("name") as string).length < 5) {
    errors.name = "Please provide a name at least 5 characters long";
  }

  const file = formData.get("file") as File;
  if (file.size === 0 || !file.name) {
    errors.file = "Please please include a file";
  }

  setErrors(errors);
  if (Object.keys(errors).length !== 0) return;

  try {
    const { document } = await user.callApi({
      url: "/api/uploads/documents",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setFormData({ name: "", fileName: "", file: null });
    return document;
  } catch (error: any) {
    if (!error.response.data.errors) return null;
    setErrors(error.response.data.errors);
  }
}

interface UploadFormData {
  name: string;
  fileName: string;
  file: File | null;
}

interface Errors {
  name?: string;
  file?: string;
  auth?: string;
}
