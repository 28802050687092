import { gql } from "@apollo/client";
import { GenericTooltip } from "@components/tooltip-container";
import { H1, LightSwitch } from "@components/utilities";
import { Profile as ProfileQuery } from "@nb-api-graphql-generated/Profile";
import {
  UpdateUserDetails,
  UpdateUserDetailsVariables,
} from "@nb-api-graphql-generated/UpdateUserDetails";
import { verifyDomain } from "@utils/domain-verification";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import TimezoneSelect from "react-timezone-select";
import { UPDATE_USER_DETAILS } from "./queries";

interface ProfileProps {
  afterSuccessCallback?: () => void;
  isOnboardingFlow?: boolean;
}

export function Profile({
  afterSuccessCallback,
  isOnboardingFlow,
}: ProfileProps) {
  const { loading, data } = useNorthbeamQuery<ProfileQuery>(PROFILE_QUERY);

  const [updateUserDetails, { loading: updateUserDetailsLoading }] =
    useNorthbeamMutation<UpdateUserDetails, UpdateUserDetailsVariables>(
      UPDATE_USER_DETAILS,
      { refetchQueries: [{ query: PROFILE_QUERY }] },
    );

  const COMMON_COUNTRIES = ["US"];

  const [companyName, setCompanyName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [domainName, setDomainName] = useState<string>("");
  const [canChangeDomain, setCanChangeDomain] = useState<boolean>(false);
  const [domainError, setDomainError] = useState<string>("");
  const [timezone, setTimezone] = useState<string>("");
  const [isSubscriptionService, setIsSubscriptionService] =
    useState<boolean>(false);

  useEffect(() => {
    const userData = data?.me;
    if (userData) {
      setCompanyName(userData.companyName || "");
      setFirstName(userData.firstName || "");
      setLastName(userData.lastName || "");
      setDomainName(userData.domainName || "");
      setTimezone(userData.timezone || "");
      setCountry(userData.country || "");
      setRegion(userData.region || "");
      setPostalCode(userData.postalCode || "");
      setIsSubscriptionService(userData.isSubscriptionService || false);

      if (!!userData.domainName && !verifyDomain(userData.domainName)) {
        setCanChangeDomain(false);
      } else {
        setCanChangeDomain(true);
      }
    }
  }, [data]);

  const onSubmitClicked = React.useCallback(async () => {
    const error = verifyDomain(domainName);
    if (error) {
      setDomainError(error);
    } else {
      setDomainError("");
      const result = await updateUserDetails({
        variables: {
          companyName,
          firstName,
          lastName,
          domainName,
          timezone,
          country,
          region,
          postalCode,
          isSubscriptionService,
        },
      });

      setCanChangeDomain(false);

      setDomainName(result.data?.updateUserDetails.domainName || domainName);

      afterSuccessCallback && afterSuccessCallback();
    }
  }, [
    companyName,
    firstName,
    lastName,
    domainName,
    timezone,
    country,
    region,
    postalCode,
    isSubscriptionService,
    updateUserDetails,
    afterSuccessCallback,
  ]);

  const handlePostalCodeChange = (postalCode: string) => {
    setPostalCode(postalCode);
  };

  return (
    <>
      {isOnboardingFlow ? <h3>Enter the basics</h3> : <H1>Profile</H1>}
      <hr />
      {loading && (
        <div className="row col-8">
          <div className="py-2 my-2">Please wait...</div>
        </div>
      )}
      {!loading && (
        <>
          <div className="row col-8">
            <div className="py-2 my-2">
              <div className="control-label">Company name</div>
              <input
                type="text"
                disabled={updateUserDetailsLoading}
                className="form-control"
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              />
            </div>
          </div>
          <div className="row col-8">
            <div className="py-2 my-2">
              <div className="control-label">First name</div>
              <input
                type="text"
                disabled={updateUserDetailsLoading}
                className="form-control"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>
          </div>
          <div className="row col-8">
            <div className="py-2 my-2">
              <div className="control-label">Last name</div>
              <input
                type="text"
                disabled={updateUserDetailsLoading}
                className="form-control"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
          </div>
          <div className="row col-8">
            <div className="py-2 my-2">
              <div className="control-label">Country</div>
              <CountryDropdown
                //@ts-expect-error - component doesn't accept a style prop
                style={{ width: "300px" }}
                className="form-control"
                disabled={updateUserDetailsLoading}
                value={country}
                priorityOptions={COMMON_COUNTRIES}
                onChange={(country) => setCountry(country)}
              />
            </div>
          </div>
          <div className="row col-8">
            <div className="py-2 my-2">
              <div className="control-label">State or Region</div>
              <RegionDropdown
                //@ts-expect-error - component doesn't accept a style prop
                style={{ width: "300px" }}
                className="form-control"
                disabled={updateUserDetailsLoading}
                disableWhenEmpty={true}
                country={country}
                value={region}
                onChange={(region) => setRegion(region)}
              />
            </div>
          </div>
          <div className="row col-8">
            <div className="py-2 my-2">
              <div className="control-label">Postal Code</div>
              <input
                type="text"
                disabled={updateUserDetailsLoading}
                className="form-control"
                value={postalCode}
                onChange={(event) => handlePostalCodeChange(event.target.value)}
              />

              {!postalCode && !isOnboardingFlow && (
                <span style={{ color: "red" }}>please add a postal code</span>
              )}
            </div>
          </div>
          <div className="row col-8">
            <div className="py-2 my-2">
              <div className="control-label" style={{ width: "300px" }}>
                Timezone
              </div>
              <TimezoneSelect
                value={timezone}
                onChange={(timezoneObject) => setTimezone(timezoneObject.value)}
              />
            </div>
          </div>
          <div className="row col-8">
            <div className="py-2 my-2">
              Do you offer a subscription service?
              <div className="flex mt-1">
                <LightSwitch
                  size="small"
                  isSet={isSubscriptionService}
                  disabled={false}
                  id="admin-demo-mode-switch"
                  onChange={() =>
                    setIsSubscriptionService(!isSubscriptionService)
                  }
                />
              </div>
            </div>
          </div>

          {!isOnboardingFlow && (
            <div className="row col-8 mb-3">
              <div className="py-2 my-2">
                <div className="control-label">
                  <GenericTooltip content="Your domain will look something like northbeam.io, google.com, or bbc.co.uk">
                    Domain name
                  </GenericTooltip>
                </div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">https://</div>
                  </div>
                  <input
                    type="text"
                    disabled={!canChangeDomain || updateUserDetailsLoading}
                    className="form-control"
                    value={domainName}
                    onChange={(event) =>
                      setDomainName(event.target.value.toLowerCase())
                    }
                  />
                </div>
                <p style={{ color: "red" }}>{domainError}</p>
              </div>
            </div>
          )}
          <div className="row col-8">
            <div className="py-2 my-2">
              <button
                disabled={
                  updateUserDetailsLoading ||
                  !timezone ||
                  !domainName ||
                  !firstName ||
                  !lastName ||
                  !companyName ||
                  !postalCode
                }
                tabIndex={70}
                className="btn btn-primary btn-block"
                onClick={onSubmitClicked}
              >
                {isOnboardingFlow ? (
                  <>
                    Continue <i className="fa-regular fa-arrow-right"></i>
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export const PROFILE_QUERY = gql`
  query Profile {
    me {
      id
      firstName
      lastName
      companyName
      domainName
      timezone
      country
      region
      postalCode
      isSubscriptionService
    }
  }
`;
