import { GenericExternalLink } from "@components/generic-external-link";
import { H1 } from "@components/utilities";
import React, { useState } from "react";
import { LabelBuilder } from "./label-builder";
import { SimulatedLabelResults } from "./simulated-label-results";
import { useLabelSimulate } from "./use-label-simulate";

export const BreakdownEditor = () => {
  const { simulate, loading, simulatedData } = useLabelSimulate();
  const [simulatedTitle, setSimulatedTitle] = useState<string>("");

  return (
    <div className="mr-4">
      <div className="flex justify-content-between align-items-center">
        <H1>Breakdown Manager</H1>
        <GenericExternalLink
          href="https://www.loom.com/share/627c6fcbdbe7444fb239697634ca69dd"
          className="btn btn-outline-info mr-2"
        >
          Show me how this works
        </GenericExternalLink>
      </div>
      <div className="elevation-1 grid grid-cols-2 gap-2 h-[calc(100vh-200px)]">
        <LabelBuilder
          simulate={simulate}
          setSimulatedTitle={setSimulatedTitle}
        />
        <SimulatedLabelResults
          simulatedTitle={simulatedTitle}
          simulatedData={simulatedData}
          isLoading={loading}
        />
      </div>
    </div>
  );
};
