import React from "react";

export const Toggle = ({
  checked,
  toggle,
}: {
  checked: boolean;
  toggle: () => void;
}) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer m-0">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        onChange={toggle}
      />
      <div className="w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:none peer-focus:none dark:peer-focus:none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
    </label>
  );
};
