import { SecondaryButton } from "@shared/buttons";
import React, { Fragment, useMemo, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useSelectedMetricsAtom } from "../atoms";

export function CreativeAnalyticsTopBar() {
  return (
    <div className="h-20 flex flex-row items-center">
      <CreativeAnalyticsMetrics />
    </div>
  );
}

function CreativeAnalyticsMetrics() {
  const [selectedMetrics, setSelectedMetrics] = useSelectedMetricsAtom();
  return (
    <div className="flex flex-row ml-5 items-center">
      <CreativeAnalyticsMetricManager />
      {[...selectedMetrics].map((metric) => {
        return (
          <Metric
            key={metric}
            name={metric}
            onRemove={() => {
              const newSet = new Set(selectedMetrics);
              newSet.delete(metric);
              setSelectedMetrics(newSet);
            }}
          />
        );
      })}
    </div>
  );
}

function CreativeAnalyticsMetricManager() {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button>
            <SecondaryButton className="h-[2.5rem] rounded-lg mr-2 flex flex-row items-center border-[#C1C5CD] hover:bg-[#F3F5FF]">
              <i className="mr-3 text-lg fa-regular fa-circle-plus text-cosmic"></i>
              <div className="text-[#0E2347]">ADD METRIC</div>
            </SecondaryButton>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute w-[20rem] h-[30rem] z-10 mt-3  bg-white rounded-lg elevation-1 flex flex-col px-8 py-3">
              <div>Select up to 4 metrics at a time</div>
              <CreativeAnalyticsMetricsOptionsSelector />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

function CreativeAnalyticsMetricsOptionsSelector() {
  const [searchText, setSearchText] = useState<string>();
  const [selectedMetrics, setSelectedMetrics] = useSelectedMetricsAtom();

  const options = useMemo(
    () => [
      "CMP",
      "CTR",
      "CVR",
      "Thumpstop",
      "AOV (1st time)",
      "AOV (Returning)",
      "Metric 5",
      "Metric 6",
      "Metric 7",
      "Metric 8",
      "Metric 9",
      "Metric 10",
    ],
    [],
  );
  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.toLowerCase().includes(searchText?.toLowerCase() ?? ""),
    );
  }, [options, searchText]);
  return (
    <div className="relative">
      <Input
        className="mt-5 w-[15rem]"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search metrics..."
        id="search-metrics"
        iconClassname="fa-magnifying-glass"
      />
      <div className="relative overflow-y-auto h-[22rem] mt-2">
        {filteredOptions.map((option) => {
          return (
            <div key={option}>
              <label className="flex flex-row items-center mt-4 ml-3 accent-cosmic">
                <input
                  type="checkbox"
                  checked={selectedMetrics.has(option)}
                  onChange={() => {
                    const newSet = new Set(selectedMetrics);
                    if (selectedMetrics.has(option)) {
                      newSet.delete(option);
                    } else {
                      newSet.add(option);
                    }
                    setSelectedMetrics(newSet);
                  }}
                  className="mr-3"
                />
                {option}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function Input({
  id,
  onChange,
  value,
  className,
  placeholder,
  iconClassname,
}: {
  iconClassname?: string;
  id: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | undefined;
  placeholder: string;
}) {
  return (
    <label htmlFor={id} className={clsx("relative ", className)}>
      {!value && iconClassname && (
        <i
          className={clsx(
            "fa-solid pointer-events-none  absolute transform translate-y-1/2 right-3 top-1 text-[#dbdddf]",
            iconClassname,
          )}
        />
      )}

      <input
        className={clsx(
          "text-sm font-normal rounded-md w-full py-2 px-3 border focus:outline-none focus:border-cosmic focus:ring-1 focus:ring-cosmic",
        )}
        id={id}
        style={{ borderColor: "hsl(0deg 0% 80%)" }}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </label>
  );
}

function Metric({ name, onRemove }: { name: string; onRemove: () => void }) {
  return (
    <div
      className="ml-3 text-interstellar rounded-3xl h-[2.5rem] px-5 flex items-center border-[#7081FF] border-[1px]"
      style={{
        background: "#D8DCFF",
      }}
    >
      <div>{name}</div>
      <i
        className="ml-3 fa-regular fa-xmark cursor-pointer text-[#021330] text-lg"
        onClick={onRemove}
      />
    </div>
  );
}
