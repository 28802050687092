import { Radio } from "@shared/radio-group";
import React from "react";
import { Accordion, AccordionProps } from "./accordion";

interface Props<T> extends AccordionProps {
  options: {
    id: T;
    label: string;
    isSelected: boolean;
  }[];
  onChange(value: T): void;
}

export function AccordionWithRadios<T>({
  title,
  options,
  isExpandedByDefault,
  onChange,
}: Props<T>) {
  return (
    <Accordion title={title} isExpandedByDefault={isExpandedByDefault}>
      <div>
        <div className="flex flex-wrap text-[12px] font-light gap-y-4 mb-4">
          {options.map((e) => (
            <div key={String(e.id)} className="flex-1 min-w-[13rem]">
              <Radio
                label={e.label}
                value={String(e.id)}
                checked={e.isSelected}
                onChange={(val) => onChange(val as T)}
                labelClassName="cursor-pointer"
                inputClassName="cursor-pointer h-3 w-3"
              />
            </div>
          ))}
        </div>
      </div>
    </Accordion>
  );
}
