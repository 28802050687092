import { DateRangeQueryNames } from "@/constants/date-range-params";
import { useDateRangeOnlyQueryState } from "@hooks/use-date-range-query-select";
import { FixedDateRange, todayLocalAsISODate } from "@north-beam/nb-common";
import { DateRangeChoiceEnum } from "@utils/constants";
import { parseDateRangeArgument } from "@utils/index";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useReportBodyState = () => {
  const location = useLocation();
  const [state, setState] = useDateRangeOnlyQueryState();

  useEffect(() => {
    if (location.search === "") {
      setState(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateRange = useMemo(
    () =>
      parseDateRangeArgument(
        state[DateRangeQueryNames.DATE_RANGE],
        todayLocalAsISODate(),
      ),
    [state],
  );

  return {
    state,

    dateRange,
    setDateRange: useCallback(
      (
        dateRange:
          | FixedDateRange
          | (typeof DateRangeChoiceEnum.reactSelectOptions)[0],
      ) =>
        setState({
          [DateRangeQueryNames.DATE_RANGE]:
            "value" in dateRange ? dateRange.value : dateRange,
        }),
      [setState],
    ),
  };
};
