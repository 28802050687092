import gmailAd from "@assets/gmail-ads/ad.png";
import gmailAdInfo from "@assets/gmail-ads/ad_info.png";
import React from "react";

export default function GmailAd({
  teaser: { headline, description, businessName },
}: {
  teaser: {
    headline: string;
    description: string;
    businessName: string;
  };
}) {
  return (
    <div className="adwords-gmail-ad">
      <div className="section-frame mobile-section teaser-section">
        <div className="teaser-frame">
          <div className="logo-container">
            <img id="logo" alt="Teaser logo" src="//via.placeholder.com/150" />
          </div>
          <div className="mobile-teaser-line ad-advertiser">
            {businessName} (Gmail Ad)
          </div>
          <div className="mobile-teaser-line">
            <img className="ad-tag" src={gmailAd} alt="ad tag" />{" "}
            <img className="ad-info" src={gmailAdInfo} alt="ad info" />
            <span className="ad-subject">{headline}</span>
          </div>
          <div className="mobile-teaser-line ad-description">{description}</div>
        </div>
      </div>
    </div>
  );
}
