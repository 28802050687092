import _ from "lodash";
import moment from "moment-timezone";
import { Range } from "rc-slider";
import React from "react";
import {
  indexToMonth,
  MonthInterval,
  monthToIndex,
} from "@north-beam/nb-common";

export interface MonthIntervalPickerProps {
  disabled: boolean;
  bounds: MonthInterval;
  value: MonthInterval;
  onUpdate: (mi: MonthInterval) => void;
}

export function MonthIntervalPicker(props: MonthIntervalPickerProps) {
  const { disabled, value, bounds, onUpdate } = props;

  const min = monthToIndex(bounds.startMonth);
  const max = monthToIndex(bounds.endMonth);

  const lo = monthToIndex(value.startMonth);
  const hi = monthToIndex(value.endMonth);

  const onChange = React.useCallback(
    (value: number[]) => {
      const [lo, hi] = _.sortBy(value);
      onUpdate({
        startMonth: indexToMonth(lo),
        endMonth: indexToMonth(hi),
      });
    },
    [onUpdate],
  );

  return (
    <div className="nb-interval-picker">
      <Range
        min={min}
        max={max}
        disabled={disabled}
        value={[lo, hi]}
        onChange={onChange}
      />
      <div className="text-center">
        {renderMonth(value.startMonth) + " — " + renderMonth(value.endMonth)}
      </div>
    </div>
  );
}

function renderMonth(month: string) {
  return moment(month, "YYYY-MM", true).format("MMM YYYY");
}
