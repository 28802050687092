import classNames from "classnames";
import React from "react";
import uuid from "react-awesome-query-builder/lib/utils/uuid";

export default props => {
  const { value, setValue, config, labelYes, labelNo, readonly } = props;
  const onCheckboxChange = e => setValue(e.target.checked);
  const onRadioChange = e => setValue(e.target.value == "true");
  const id = uuid(),
    id2 = uuid();

  // return <>
  //     <input key={id}  type="checkbox" id={id} checked={!!value} disabled={readonly} onChange={onCheckboxChange} />
  //     <label style={{display: "inline"}} key={id+"label"}  htmlFor={id}>{value ? labelYes : labelNo}</label>
  // </>;

  return (
    <div className="btn-group btn-group-toggle" data-toggle="buttons">
      <label
        key={id + "label"}
        htmlFor={id}
        className={classNames({
          btn: true,
          "btn-outline-primary": true,
          active: !!value,
        })}
      >
        <input
          key={id}
          type="radio"
          className="form-check-input"
          id={id}
          value={true}
          checked={!!value}
          disabled={readonly}
          onChange={onRadioChange}
        />
        {labelYes}
      </label>
      <label
        key={id2 + "label"}
        htmlFor={id2}
        className={classNames({
          btn: true,
          "btn-outline-primary": true,
          active: !value,
        })}
      >
        {labelNo}
        <input
          key={id2}
          type="radio"
          className="form-check-input"
          id={id2}
          value={false}
          checked={!value}
          disabled={readonly}
          onChange={onRadioChange}
        />
      </label>
    </div>
  );
};
