import React from "react";
import BaseErrorPage from "./base-error-page";

export default function MaintenanceScreen() {
  return (
    <BaseErrorPage
      pageName="MaintenanceScreen"
      pageBody={
        <h4 className="mt-4 text-center">
          <b>Northbeam is undergoing scheduled maintenance.</b>{" "}
          <div className="mt-3">
            We&apos;ll be back online shortly. If there&apos;s an emergency,
            please contact us at your convenience.
          </div>
        </h4>
      }
    />
  );
}
