import { useNorthbeamMutation } from "@utils/hooks";
import {
  UpdateUserFeatureFlag as UpdateUserFeatureFlagQuery,
  UpdateUserFeatureFlagVariables,
} from "@nb-api-graphql-generated/UpdateUserFeatureFlag";
import { UPDATE_FEATURE_FLAG } from "@pages/settings/northbeam-labs";
import { useUser } from "@components/user-context";
import { FETCH_SETTINGS_DETAILS } from "@pages/settings/queries";

export const useHasFeatureFlag = () => {
  const { user } = useUser();

  const hasFeatureFlag = (featureFlagName: string): boolean => {
    const featureFlags = user?.featureFlags ?? null;
    if (featureFlags?.[featureFlagName]) {
      return true;
    }

    return false;
  };

  return hasFeatureFlag;
};

export const useUpdateUserFeatureFlag = () => {
  const [updateUserFeatureFlagMutation] = useNorthbeamMutation<
    UpdateUserFeatureFlagQuery,
    UpdateUserFeatureFlagVariables
  >(UPDATE_FEATURE_FLAG, {
    refetchQueries: [{ query: FETCH_SETTINGS_DETAILS }],
  });

  const updateUserFeatureFlag = async (
    featureFlagName: string,
    isSet: boolean,
  ) => {
    const result = await updateUserFeatureFlagMutation({
      variables: {
        featureFlagName,
        isSet,
      },
    });

    return result;
  };

  return updateUserFeatureFlag;
};
