import React from "react";
import { TabView } from "../tab-table-section";
import classNames from "classnames";
import { capitalize } from "@utils/strings";

interface TableTabsProps {
  granularity: TabView;
  onTabClick: (value: TabView) => void;
  selection: any[];
  salesTabTableSelectedCount: Partial<Record<TabView, number>>;
  clearLevelFilter: (level: TabView) => void;
  clearSelectedRows: () => void;
}

export const TableTabs = ({
  granularity,
  onTabClick,
  selection,
  salesTabTableSelectedCount,
  clearLevelFilter,
  clearSelectedRows,
}: TableTabsProps) => {
  const tabClasses = `flex items-center justify-between
    rounded-t-md border border-[#C4C4C4]
    text-left text-sm color-[#0075FF] tracking-wide
    w-64 cursor-pointer py-2.5 pl-3 pr-1`;

  const activeTabClasses =
    "border-2 border-[#0075FF] bg-white font-bold text-[#0075FF]";

  return (
    <ul data-test="tab-table" className="flex mb-0">
      {Object.values(TabView).map((view) => (
        <li
          key={view}
          className={classNames(
            tabClasses,
            granularity === view && activeTabClasses,
          )}
          onClick={() => onTabClick(view as TabView)}
        >
          <span>{capitalize(view)}</span>
          {view !== TabView.AD &&
            granularity === view &&
            selection.length > 0 &&
            salesTabTableSelectedCount[view] === 0 && (
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  clearSelectedRows();
                }}
                className="ml-1 text-sm font-medium rounded-full bg-[#0075FF] text-white px-2.5"
              >
                {selection.length} selected
                <i className="fa-regular fa-xmark ml-3"></i>
              </span>
            )}
          {view !== TabView.AD && salesTabTableSelectedCount[view]! > 0 && (
            <span
              onClick={(event) => {
                event.stopPropagation();
                clearLevelFilter(view);
              }}
              className="ml-1 text-sm font-medium rounded-full bg-[#0075FF] text-white px-2.5"
            >
              {salesTabTableSelectedCount[view]} selected
              <i className="fa-regular fa-xmark ml-3"></i>
            </span>
          )}
        </li>
      ))}
    </ul>
  );
};
