import React from "react";
import { UtmGuidePlatformProps } from "..";
import pinterest from "../images/pinterest.png";
import { ConstructDestinationUrlComponent } from "./shared/construct-destination-url-component";

export function Pinterest({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to each Pinterest{" "}
        <code>ad_link</code> or <code>link</code>.
      </p>
      <ol>
        <ConstructDestinationUrlComponent urlParamPairs={urlParamPairs} />
        <li>
          If you are not using <b>Catalog Sales</b> as your campaign objective,
          you can set the UTMs in the location depicted by the picture below.
        </li>
        <img className="img-fluid mb-3" src={pinterest} alt="" />
      </ol>
    </>
  );
}
