import config from "@/environment";
import { gql } from "@apollo/client";
import northbeamHeaderLogo from "@assets/northbeam-header-logo.svg";
import DatasetDoesNotExist from "@components/error-pages/dataset-does-not-exist";
import { Navbar as NavbarQuery } from "@nb-api-graphql-generated/Navbar";
import { logEvent, prettyLogEvent } from "@utils/analytics";
import { prefs } from "@utils/client-side-preferences";
import { useNorthbeamQuery } from "@utils/hooks";
import "animate.css";
import { default as classNames } from "classnames";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import { useAppVersion } from "../data/app-version";
import { GenericExternalLink } from "../generic-external-link";
import { LoadingSlide } from "../title-slide-view";
import { User, useUser } from "../user-context";
import { LightSwitch } from "../utilities";
import { DataFreshnessTooltips } from "./data-freshness-tooltips";

export interface UserItem {
  id: string;
  email: string;
  isAdmin: boolean;
  companyName: string | null;
}

const ReloadPageToast = () => (
  <div>
    Northbeam has been updated.{" "}
    <button
      className="btn btn-primary btn-sm"
      onClick={() => {
        logEvent("App reload due to server version change");
        window.location.reload();
      }}
    >
      Reload
    </button>
  </div>
);

export const Navbar = ({
  onLogout,
  children,
}: {
  onLogout(): void;
  children: ReactElement | ReactElement[];
}) => {
  const { data, loading } = useNorthbeamQuery<NavbarQuery>(NAVBAR_QUERY, {
    pollInterval: 600_000,
    doNotRaiseErrorOnFailure: true,
  });

  const { appVersion } = useAppVersion();
  const { user } = useUser();
  const { addToast } = useToasts();

  const [usersList, setUsersList] = useState<UserItem[] | null>(
    data?.users || null,
  );

  const [numIncidents, setNumIncidents] = useState<number>(
    data?.me?.activeIncidentCount ?? 0,
  );

  const [numMaintenanceItems, setNumMaintenanceItems] = useState<number>(
    data?.me?.activeAccountMaintenanceItemCount ?? 0,
  );

  const [currentAppVersion, setCurrentAppVersion] = useState<string | null>(
    appVersion,
  );

  const [currentServerVersion, setCurrentServerVersion] = useState<
    string | undefined
  >(data?.currentServerVersion);

  const [activePage, setActivePage] = useState<string>("");

  useEffect(() => {
    // HACK HACK HACK
    // This attempts to find the first path argument after the client ID in the URL bar
    const splitPathName = window.location.pathname.split("/");
    if (splitPathName.length >= 3) {
      setActivePage(splitPathName[2]);
    }

    // eslint-disable-next-line
  }, [window.location.pathname]);

  const [datasetExists, setDatasetExists] = useState<boolean>(false);

  const [useDevelopmentAnalyticsColors, setUseDevelopmentAnalyticsColors] =
    useState<boolean>(false);

  // Reload page if app or server version changes
  useEffect(() => {
    if (!currentAppVersion) {
      setCurrentAppVersion(appVersion);
    }

    if (!currentServerVersion) {
      setCurrentServerVersion(data?.currentServerVersion);
    }

    if (
      (appVersion && currentAppVersion && currentAppVersion !== appVersion) ||
      (data?.currentServerVersion &&
        currentServerVersion &&
        currentServerVersion !== data.currentServerVersion)
    ) {
      addToast(<ReloadPageToast />, {
        appearance: "info",
        autoDismiss: false,
      });
      return;
    }
  }, [
    appVersion,
    currentAppVersion,
    setCurrentAppVersion,
    currentServerVersion,
    data?.currentServerVersion,
    addToast,
  ]);

  useEffect(() => {
    const users = data?.users || null;
    setDatasetExists(!!data?.me?.datasetExists);
    if (users && users.length > 1) {
      setUsersList(users);
    }

    setNumIncidents(data?.me?.activeIncidentCount ?? 0);
    setNumMaintenanceItems(data?.me?.activeAccountMaintenanceItemCount ?? 0);
  }, [data]);

  useEffect(() => {
    setUseDevelopmentAnalyticsColors(
      config.isDevelopmentEnvironment || prefs.useDevelopmentAnalyticsData(),
    );
  }, [setUseDevelopmentAnalyticsColors]);

  if (loading || !data) {
    return (
      <div className="w-full">
        <LoadingSlide />
        <div className="hidden">{children}</div>
      </div>
    );
  }

  return (
    <>
      <nav>
        <VerticalNavbar
          useDevelopmentAnalyticsColors={useDevelopmentAnalyticsColors}
        />
        <div
          className={classNames(
            "w-full horizontal-navbar position-fixed flex justify-content-between",
            {
              "navbar-dark": !useDevelopmentAnalyticsColors,
              "navbar-light": useDevelopmentAnalyticsColors,
            },
          )}
        >
          <Link to="overview">
            <img
              src={northbeamHeaderLogo}
              alt="Northbeam header"
              className="northbeam-header-logo"
            />
          </Link>
          <div className="right-section flex">
            <DataFreshnessTooltips
              dataBlessingTimes={data.me.dataBlessingTimes}
              timezone={data.me.timezone}
            />
            {usersList && usersList.length > 0 && (
              <ImpersonateDropdown user={user} usersList={usersList!} />
            )}
            <AccountAlertDropdown
              numIncidents={numIncidents}
              numMaintenanceItems={numMaintenanceItems}
            />
            <SandwichMenuDropdown onLogout={onLogout} />
          </div>
        </div>
      </nav>

      {datasetExists ||
      activePage === "settings" ||
      process.env.REACT_APP_NB_ENV === "dev-local" ? (
        children
      ) : (
        <DatasetDoesNotExist />
      )}
    </>
  );
};

function VerticalNavbar({
  useDevelopmentAnalyticsColors,
}: {
  useDevelopmentAnalyticsColors: boolean;
}) {
  const { user } = useUser();

  return (
    <div
      className={classNames(
        "h-full flex flex-col justify-between text-lg text-center vertical-navbar",
        {
          "navbar-dark": !useDevelopmentAnalyticsColors,
          "navbar-light": useDevelopmentAnalyticsColors,
        },
      )}
    >
      <div className="mt-[62px] nav-table">
        <NavLink
          to="overview"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          <i className="fa-solid fa-grid-2" />
          Overview
        </NavLink>
        <NavLink
          to="sales"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          <i className="fas fa-chart-line" />
          Sales
        </NavLink>
        {user.featureFlags.showFlatTableSalesPage && (
          <NavLink
            to="sales-fast"
            className={({ isActive }) => (isActive ? "active" : undefined)}
          >
            Fast sales
          </NavLink>
        )}
        <NavLink
          to="customers"
          className={({ isActive }) => (isActive ? "active" : undefined)}
          end
        >
          <i className="fa-solid fa-chart-column" />
          LTV
        </NavLink>
        <NavLink
          to="orders"
          className={({ isActive }) => (isActive ? "active" : undefined)}
          onClick={() => {
            prettyLogEvent({
              page: "Orders",
              action: "Navigate to page",
            });
          }}
        >
          <i className="fa-solid fa-cart-shopping" />
          Orders
        </NavLink>
        {user.featureFlags.enableCreativeAnalytics && (
          <NavLink
            to="creative-analytics"
            className={({ isActive }) => (isActive ? "active" : undefined)}
          >
            Creative Analytics
          </NavLink>
        )}

        {user.displayUserId === "dd62a9c5-ca2d-4231-a4bf-0cb5acc56c49" &&
          user.featureFlags.showFarmstandPurchases && (
            <NavLink
              to="farmstand-purchases"
              className={({ isActive }) => (isActive ? "active" : undefined)}
            >
              <i className="fas fa-store" />
              Farmstand Purchases
            </NavLink>
          )}
      </div>

      <div className="nav-table">
        {/* note: the AdminMenu will return null if we shouldn't be rendering admin stuff */}
        <AdminMenu />
        <a
          href="https://info.northbeam.io/knowledge"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-circle-question" />
          Help
        </a>
        <NavLink
          to="settings"
          className={({ isActive }) => (isActive ? "active" : undefined)}
        >
          <i className="fas fa-cog" />
          Settings
        </NavLink>
      </div>
    </div>
  );
}

const SandwichMenuDropdown = ({ onLogout }: { onLogout(): void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    if (!isOpen) {
      logEvent("Open Hamburger Menu");
    }
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <i
        className="my-auto ml-4 mr-4 fas fa-bars icon-large cursor-pointer"
        onClick={toggleDrawer}
      />

      <Drawer
        className="navbar-drawer navbar-sandwich"
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        overlayOpacity={0.1}
        size="220px"
      >
        <Link
          to="model-comparison"
          onClick={(e) => {
            toggleDrawer();
            logEvent("Open Model Comparison from Hamburger Menu");
          }}
        >
          Model comparison
        </Link>
        <Link
          to="objects"
          onClick={(e) => {
            toggleDrawer();
            logEvent("Open Breakdown Labels from Hamburger Menu");
          }}
        >
          Breakdown labels
        </Link>
        <Link
          to="rules"
          onClick={(e) => {
            toggleDrawer();
            logEvent("Open Automatic Label Rules from Hamburger Menu");
          }}
        >
          Automatic label rules
        </Link>
        <Link
          to="guides"
          onClick={(e) => {
            toggleDrawer();
            logEvent("Open Guides from Hamburger Menu");
          }}
        >
          Guides
        </Link>
        <Link
          to="customers/documents"
          onClick={(e) => {
            toggleDrawer();
            logEvent("Open documents from Hamburger Menu");
          }}
        >
          Documents
        </Link>
        <Link
          to="customers/paths"
          onClick={(e) => {
            toggleDrawer();
            logEvent("Open Customer Paths from Hamburger Menu");
          }}
        >
          Customer paths
        </Link>
        <span onClick={onLogout}>Logout</span>
      </Drawer>
    </>
  );
};

const AccountAlertDropdown = ({
  numIncidents,
  numMaintenanceItems,
}: {
  numIncidents: number;
  numMaintenanceItems: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <i
        className={`my-auto ml-4 fas fa-bell cursor-pointer ${
          numIncidents + numMaintenanceItems > 0
            ? "animate__animated animate__shakeX alarm-bell "
            : ""
        }`}
        onClick={toggleDrawer}
      />
      <Drawer
        className="navbar-drawer navbar-alarm-bell"
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        overlayOpacity={0.1}
        size="240px"
      >
        <Link to="monitor/incidents" onClick={toggleDrawer}>
          Incidents{" "}
          <span className="badge badge-secondary">{numIncidents}</span>
        </Link>
        <Link to="monitor/maintenance-items" onClick={toggleDrawer}>
          Maintenance items{" "}
          <span className="badge badge-secondary">{numMaintenanceItems}</span>
        </Link>
        <Link to="monitor" onClick={toggleDrawer}>
          Configure alerts
        </Link>
      </Drawer>
    </>
  );
};

export const ImpersonateDropdown = ({
  user,
  usersList,
}: {
  user: User;
  usersList: UserItem[];
}) => {
  const navigate = useNavigate();
  const options = usersList.map((item) => {
    return {
      value: item.id,
      label: item.companyName ?? item.email,
    };
  });
  const customStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      color: "#08212b",
      backgroundColor: "white",
      zIndex: 99,
    }),
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      zIndex: 99,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#08212b",
      borderColor: "white",
      "&:hover": {
        borderColor: "#acd9f8",
        cursor: "pointer",
      },
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: "white",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      color: "white",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: "white",
      "&:hover": {
        color: "#acd9f8",
      },
      zIndex: 99,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: "#08212b",
      backgroundColor: state.isFocused ? "#acd9f8" : "white",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: "white",
    }),
  };

  const label = () => {
    if (prefs.isDemoMode()) {
      return "Widgets Co";
    }
    if (user.impersonatedUser) {
      return user.impersonatedUser.companyName ?? user.impersonatedUser.email;
    }
    return user.companyName ?? user.email;
  };

  const location = useLocation();

  return (
    <Select
      menuPortalTarget={document.body}
      menuPosition="fixed"
      options={options}
      styles={customStyles}
      className="impersonate-user-dropdown ml-4"
      defaultValue={{
        value: user.impersonatedUser
          ? user.impersonatedUser.id
          : user.displayUserId,
        label: label(),
      }}
      onChange={(value, action) => {
        if (!value || !(value as { value: string; label: string }).value) {
          return;
        }
        if (action.action === "select-option") {
          const pathname = location.pathname.slice(1);
          const excludingUserIdPath = pathname.substring(
            pathname.indexOf("/") + 1,
          );
          navigate(
            `/${(value as { value: string; label: string }).value}${
              excludingUserIdPath ? `/${excludingUserIdPath}` : ""
            }`,
          );
          window.location.reload();
        }
      }}
    />
  );
};

const AdminMenu = ({ expanded = true }: { expanded?: boolean }) => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const [demoMode, _setDemoMode] = useState(false);
  const [useDevelopmentAnalyticsData, _setUseDevelopmentAnalyticsData] =
    useState(false);

  useEffect(() => {
    _setDemoMode(prefs.isDemoMode());
    _setUseDevelopmentAnalyticsData(prefs.useDevelopmentAnalyticsData());
  }, []);

  const setDemoModeLocal = useCallback(() => {
    prefs.setDemoMode(!demoMode);
    _setDemoMode(!demoMode);
    window.location.reload();
  }, [demoMode, _setDemoMode]);

  const setUseDevelopmentAnalyticsDataLocal = useCallback(() => {
    prefs.setUseDevelopmentAnalyticsData(!useDevelopmentAnalyticsData);
    _setUseDevelopmentAnalyticsData(!useDevelopmentAnalyticsData);
    window.location.reload();
  }, [useDevelopmentAnalyticsData, _setUseDevelopmentAnalyticsData]);

  const shouldShowAnonymizationSwitch =
    user.isAdmin || user.featureFlags.showAnonymizationSwitch;
  const shouldShowDevelopmentAnalyticsTableSwitch =
    user.isAdmin || user.featureFlags.showDevelopmentAnalyticsTableSwitch;
  const shouldShowRetoolLink = user.isAdmin;

  const shouldShowAdminMenu =
    shouldShowAnonymizationSwitch ||
    shouldShowDevelopmentAnalyticsTableSwitch ||
    shouldShowRetoolLink;

  if (!shouldShowAdminMenu) {
    return null;
  }

  return (
    <>
      <span onClick={toggleDrawer}>
        {expanded ? (
          <>
            <i className="fas fa-user" />
            Admin
          </>
        ) : (
          <i className="fas fa-user my-1" />
        )}
      </span>
      <Drawer
        className="navbar-drawer navbar-admin"
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        overlayOpacity={0.1}
        size="220px"
        duration={0}
      >
        {shouldShowAnonymizationSwitch && (
          <span className="text-dark flex items-center cursor-auto">
            <LightSwitch
              size="small"
              isSet={demoMode}
              disabled={false}
              id="admin-demo-mode-switch"
              onChange={() => {
                setDemoModeLocal();
                toggleDrawer();
              }}
            />{" "}
            Toggle anonymization
          </span>
        )}

        {shouldShowDevelopmentAnalyticsTableSwitch && (
          <span className="text-dark flex items-center cursor-auto">
            <LightSwitch
              size="small"
              isSet={useDevelopmentAnalyticsData}
              disabled={false}
              id="use-development-table-switch"
              onChange={() => {
                setUseDevelopmentAnalyticsDataLocal();
                toggleDrawer();
              }}
            />{" "}
            Use development analytics table
          </span>
        )}

        {shouldShowRetoolLink && (
          <GenericExternalLink
            className="text-[#3366CC]"
            href={`https://northbeam.retool.com/apps/fbf147e0-c253-11eb-8912-ef20e112236b/Northbeam#userId=${user.displayUserId}`}
            onClick={toggleDrawer}
          >
            Retool link
          </GenericExternalLink>
        )}
      </Drawer>
    </>
  );
};

export const NAVBAR_QUERY = gql`
  query Navbar {
    me {
      id
      email
      companyName
      isAdmin
      datasetExists
      activeIncidentCount
      activeAccountMaintenanceItemCount
      dataBlessingTimes
      timezone
      accountMaintenanceItems(
        activeOnly: true
        type: "ConnectionIssue"
        maxUrgency: 100
      ) {
        id
      }
    }
    users {
      id
      isAdmin
      email
      companyName
    }
    currentServerVersion
  }
`;
