import amazonFilledIcon from "@assets/platform-icons/amazon-filled-icon.svg";
import blisspointFilledIcon from "@assets/platform-icons/blisspoint-filled-icon.svg";
import facebookFilledIcon from "@assets/platform-icons/facebook-filled-icon.svg";
import genericFilledIcon from "@assets/platform-icons/generic-filled-icon.svg";
import googleFilledIcon from "@assets/platform-icons/google-filled-icon.svg";
import instagramFilledIcon from "@assets/platform-icons/instagram-filled-icon.svg";
import mediamathFilledIcon from "@assets/platform-icons/mediamath-filled-icon.svg";
import microsoftFilledIcon from "@assets/platform-icons/microsoft-filled-icon.svg";
import mntnFilledIcon from "@assets/platform-icons/mntn-filled-icon.svg";
import northbeamFilledIcon from "@assets/platform-icons/northbeam-filled-icon.svg";
import pinterestFilledIcon from "@assets/platform-icons/pinterest-filled-icon.svg";
import s2sFilledIcon from "@assets/platform-icons/s2s-filled-icon.svg";
import shopifyFilledIcon from "@assets/platform-icons/shopify-filled-icon.svg";
import snapchatFilledIcon from "@assets/platform-icons/snapchat-filled-icon.svg";
import spotifyFilledIcon from "@assets/platform-icons/spotify-filled-icon.svg";
import tiktokFilledIcon from "@assets/platform-icons/tiktok-filled-icon.svg";
import veFilledIcon from "@assets/platform-icons/ve-filled-icon.svg";
import youtubeFilledIcon from "@assets/platform-icons/youtube-filled-icon.svg";

import { useUser } from "@components/user-context";
import { DataBlessingTimes } from "@north-beam/nb-common";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { GenericTooltip } from "../../tooltip-container";

const KEY_ICON_MAP: { [key: string]: any } = {
  spend_impressions__adwords: googleFilledIcon,
  spend_impressions__microsoft: microsoftFilledIcon,
  spend_impressions__fb: facebookFilledIcon,
  spend_impressions__snapchat: snapchatFilledIcon,
  spend_impressions__pinterest: pinterestFilledIcon,
  spend_impressions__tiktok: tiktokFilledIcon,
  spend_impressions__ve: veFilledIcon,
  spend_impressions__dv360_ve: veFilledIcon,
  spend_impressions__bliss_point_media: blisspointFilledIcon,
  spend_impressions__bliss_point_media_linear: blisspointFilledIcon,
  spend_impressions__bliss_point_media_audio: blisspointFilledIcon,
  spend_impressions__amazon_marketplace_aggregated: amazonFilledIcon,
  "spend_impressions__partnership-instagram": instagramFilledIcon,
  "spend_impressions__partnership-podcast": spotifyFilledIcon,
  "spend_impressions__partnership-youtube": youtubeFilledIcon,
  spend_impressions__mediamath: mediamathFilledIcon,
  spend_impressions__mntn: mntnFilledIcon,
  northbeam_pixel: northbeamFilledIcon,
  shopify: shopifyFilledIcon,
  orders_shopify: shopifyFilledIcon,
  orders_custom: s2sFilledIcon,
  orders_dummy: genericFilledIcon,
  orders_amazon: amazonFilledIcon,
};

const KEY_CONNECTION_MAP: { [key: string]: string[] } = {
  spend_impressions__adwords: ["integration_google_adwords"],
  spend_impressions__microsoft: ["integration_microsoft"],
  spend_impressions__fb: ["integration_facebook_ads"],
  spend_impressions__snapchat: ["integration_snapchat"],
  spend_impressions__pinterest: ["integration_pinterest"],
  spend_impressions__tiktok: ["integration_tiktok"],
  spend_impressions__bliss_point_media: ["bliss-point-media"],
  spend_impressions__bliss_point_media_linear: ["bliss-point-media"],
  spend_impressions__bliss_point_media_audio: ["bliss-point-media"],
  spend_impressions__amazon_marketplace_aggregated: [
    "integration_amazon",
    "integration_amazon_seller",
  ],
  northbeam_pixel: ["Northbeam pixel"],
  shopify: ["integration_shopify"],
  orders_shopify: ["integration_shopify"],
  orders_amazon: ["integration_amazon", "integration_amazon_seller"],
};

const SPEND_KEY_TITLE_MAP = {
  spend_impressions__adwords: "Google Ads",
  spend_impressions__microsoft: "Microsoft Ads",
  spend_impressions__fb: "Facebook Ads",
  spend_impressions__snapchat: "Snapchat Ads",
  spend_impressions__pinterest: "Pinterest Ads",
  spend_impressions__tiktok: "TikTok Ads",
  spend_impressions__ve: "VE",
  spend_impressions__dv360_ve: "DV360 VE",
  spend_impressions__bliss_point_media: "Bliss Point Media",
  spend_impressions__bliss_point_media_linear: "Bliss Point Media Linear",
  spend_impressions__bliss_point_media_audio: "Bliss Point Media Audio",
  spend_impressions__amazon_marketplace_aggregated:
    "Amazon Marketplace Aggregated",
  "spend_impressions__partnership-instagram": "Partnership Instagram",
  "spend_impressions__partnership-podcast": "Partnership Podcast",
  "spend_impressions__partnership-youtube": "Partnership Youtube",
  spend_impressions__mediamath: "Mediamath",
  spend_impressions__mntn: "MNTN",
};

const REVENUE_KEY_TITLE_MAP = {
  northbeam_pixel: "Northbeam pixel",
  shopify: "Shopify",
  orders_shopify: "Shopify",
  orders_custom: "Custom (S2S, WooCommerce and Magento)",
  orders_dummy: "Dummmy",
  orders_amazon: "Amazon",
};

export const TopBarDataFreshnessTooltip = ({
  dataBlessingTimes,
  timezone,
  brokenConnections,
}: {
  dataBlessingTimes: DataBlessingTimes;
  timezone: string;
  brokenConnections: string[];
}) => {
  const { user } = useUser();
  const dataBlessingData = [
    { keyTitleMap: SPEND_KEY_TITLE_MAP, name: "Spend" },
    { keyTitleMap: REVENUE_KEY_TITLE_MAP, name: "Revenue" },
  ].map(({ keyTitleMap, name }) => {
    // Start by letting these be invalid moment strings, so we can test in the for loop below
    let maxTime = moment("INVALID");

    const data = Object.keys(dataBlessingTimes)
      .sort((a, b) =>
        moment(dataBlessingTimes[a as keyof DataBlessingTimes]).diff(
          moment(dataBlessingTimes[b as keyof DataBlessingTimes]),
        ),
      )
      .map((key: string) => {
        const icon = KEY_ICON_MAP[key] ?? genericFilledIcon;

        const dataBlessingTime = moment(
          dataBlessingTimes[key as keyof DataBlessingTimes],
        );

        const time = dataBlessingTime.tz(timezone).format("MMM D [@] HH:mm z");
        const relativeLastUpdate = dataBlessingTime
          .fromNow()
          .replace("minutes", "mins");

        const connectionKey: string[] = KEY_CONNECTION_MAP[key];
        const brokenConnection = connectionKey?.filter((key) =>
          brokenConnections.includes(key),
        );

        const needsReconnect = brokenConnection && brokenConnection.length > 0;

        if (Object.keys(keyTitleMap).includes(key)) {
          const title: string =
            keyTitleMap[key as keyof typeof keyTitleMap] || key;

          if (dataBlessingTime.isValid()) {
            if (!maxTime.isValid() || maxTime < dataBlessingTime) {
              maxTime = dataBlessingTime;
            }
            return {
              title,
              time,
              relativeLastUpdate,
              needsReconnect,
              icon,
            };
          } else {
            return {
              title,
              time: null,
              relativeLastUpdate: null,
              needsReconnect,
              icon,
            };
          }
        }
        return undefined;
      })
      .filter(Boolean);

    const humanReadable = maxTime.isValid()
      ? maxTime.fromNow().replaceAll("minutes", "mins")
      : "Unknown";

    return { key: name, data, humanReadable };
  });

  return (
    <GenericTooltip
      content={
        <div>
          {dataBlessingData.map((obj) => {
            if (obj.data.length === 0) {
              return null;
            }

            return (
              <div className="w-[600px] font-bold p-4" key={obj.key}>
                <div className="text-xl text-interstellar+2 font-bold">
                  {obj.key}
                </div>
                <div className="grid grid-cols-2 mt-2">
                  <div className="text-sm">Platform</div>
                  <div className="text-sm pl-3">Last updated</div>
                </div>
                {obj.data.map((item) => (
                  <div
                    key={item?.title}
                    className="border-b border-gray-3 py-2"
                  >
                    <div className="grid grid-cols-2 items-center">
                      <div className="text-subtitle text-xs flex items-center">
                        <img
                          src={item?.icon}
                          className="mx-2 float-left"
                          alt={`${item?.title} logo`}
                          style={{
                            height: "1.5rem",
                            width: "auto",
                          }}
                        />
                        {item?.title}
                      </div>
                      <div className="text-subtitle text-xs">
                        {item?.needsReconnect ? (
                          <div className="grid grid-cols-[48%_4%_48%]">
                            <span className="pl-3 text-danger pr-1">
                              <i className="fa-sharp fa-light fa-circle-exclamation"></i>{" "}
                              {item?.relativeLastUpdate}
                            </span>
                            <div />
                            <span className=" pl-1">
                              <Link
                                to={user.pathFromRoot("settings/connections")}
                                className="text-cosmic hover:underline"
                              >
                                Reconnect
                              </Link>
                            </span>
                          </div>
                        ) : (
                          <div className="grid grid-cols-[40%_5%_55%]">
                            <span className="pl-3 text-nebula+1 pr-1">
                              {item?.relativeLastUpdate}
                            </span>
                            <span>•</span>
                            <span className="text-subtitle pl-1">
                              {item?.time}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      }
      overlayClass=""
      noInfoCircle
      captureEventString={`HoverOverUpdatedAt`}
    >
      <div className="flex flex-col space-y-0.5 cursor-pointer hover:bg-cosmic-3 transform transition-colors ease-in rounded-lg py-1 px-3">
        <span className="text-interstellar-1 font-semibold text-xxxs">
          LAST UPDATE
        </span>
        {dataBlessingData.map((obj) => {
          return (
            <div className="text-xxs leading-3 tracking-[-0.3px]" key={obj.key}>
              <span>{obj.key}</span>
              <span className="text-cosmic ml-1">{obj.humanReadable}</span>
            </div>
          );
        })}
      </div>
    </GenericTooltip>
  );
};
