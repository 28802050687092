import classNames from "classnames";
import React from "react";

export default function Spinner({
  size = "sm",
  colorClassname = "text-blue-400",
}: {
  size?: "sm" | "md" | "lg";
  colorClassname?: string;
}) {
  return (
    <span
      className={classNames(
        `spinner-border spinner-border-${size}`,
        colorClassname,
      )}
      role="status"
      aria-hidden="true"
    />
  );
}
