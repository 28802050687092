import config from "@/environment";
import { H1 } from "@components/utilities";
import React from "react";
import { AmazonConnectionCard } from "./amazon";
import { AmazonSellerConnectionCard } from "./amazon-seller";
import { FBConnectionCard } from "./facebook";
import { AdwordsConnectionCard } from "./google-ads";
import { KlaviyoConnectionCard } from "./klaviyo";
import { MicrosoftConnectionCard } from "./microsoft";
import { PinterestConnectionCard } from "./pinterest";
import { ShopifyConnectionCard } from "./shopify";
import { SnapchatConnectionCard } from "./snapchat";
import { TikTokConnectionCard } from "./tiktok";

interface ConnectionsProps {
  integrationDetails: any;
}

export const Connections = ({ integrationDetails }: ConnectionsProps) => (
  <>
    <H1>Connections</H1>
    <p>
      Please connect your platform accounts to Northbeam. If prompted, please
      give Northbeam the maximum number of requested permissions for each
      platform. These connections are critical for providing a holistic view of
      your advertising performance.
    </p>
    <hr />
    <>
      {config.isNBInternal ? (
        <p>
          (Note: some of these connections don&apos;t work on canary or
          staging.)
        </p>
      ) : (
        <></>
      )}
    </>
    <FBConnectionCard integrationDetails={integrationDetails} />
    <AdwordsConnectionCard integrationDetails={integrationDetails} />
    <ShopifyConnectionCard integrationDetails={integrationDetails} />
    <MicrosoftConnectionCard integrationDetails={integrationDetails} />
    <SnapchatConnectionCard integrationDetails={integrationDetails} />
    {/* <LinkedInConnectionCard integrationDetails={integrationDetails} /> */}
    <PinterestConnectionCard integrationDetails={integrationDetails} />
    <TikTokConnectionCard integrationDetails={integrationDetails} />
    <AmazonConnectionCard integrationDetails={integrationDetails} />
    <KlaviyoConnectionCard integrationDetails={integrationDetails} />
    <AmazonSellerConnectionCard integrationDetails={integrationDetails} />
  </>
);
