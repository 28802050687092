import React from "react";
import { UtmGuidePlatformProps } from "..";
import reSci from "../images/reSci.png";

export function RetentionScience({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        For each of the following parameter pairs, input them into{" "}
        <b>Retention Science</b> on the <b>UTM Settings</b> page.
      </p>
      <img className="img-fluid mb-3" src={reSci} alt="" />
      <table className="table table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {urlParamPairs.map(({ key, value }) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
