import shopifyLogo from "@assets/shopify-logo.png";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { ConnectionCard } from "@pages/settings/connection-card";
import { CardProps2 } from "@pages/settings/core";
import React from "react";

const type = ConnectionType.integration_shopify;

export function ShopifyConnectionCard(props: CardProps2) {
  const renderChild = () => {
    return () => <ShopifyLoginComponent />;
  };

  const successfulConnectionExtraText = (
    <>
      <p className="card-text">
        <em>
          Note: To connect another shopify account, log into your other shopify
          account, go to apps.shopify.com/north-beam, and install the app
        </em>
      </p>
    </>
  );

  return (
    <ConnectionCard
      type={type}
      connectionTypeName="Shopify"
      connectionCallToAction={`
        Integrate your Shopify account to start receiving insights about
        sales and LTV.
      `}
      successfulConnectionExtraText={() => successfulConnectionExtraText}
      logoUrl={shopifyLogo}
      logoAltText="Shopify Logo"
    >
      {renderChild()}
    </ConnectionCard>
  );
}

function ShopifyLoginComponent() {
  const SHOPIFY_LOGIN_LINK = "https://shopify.com/login";
  const SHOPIFY_DEV_INSTALL_LINK =
    "https://partners.shopify.com/1189787/apps/3609999/test";
  const SHOPIFY_PROD_STORE_LINK = "https://apps.shopify.com/north-beam";

  const getShopifyInstallLink = () => {
    const isLocalDev = process.env.REACT_APP_NB_ENV === "dev-local";
    if (isLocalDev) {
      return SHOPIFY_DEV_INSTALL_LINK;
    } else {
      return SHOPIFY_PROD_STORE_LINK;
    }
  };

  return (
    <div>
      <p>
        Please{" "}
        <a href={SHOPIFY_LOGIN_LINK} target="_blank" rel="noreferrer">
          login to your shopify account
        </a>{" "}
        and download the Northbeam app from the Shopify app store:
        <div className="mt-4">
          <a className="btn btn-primary" href={getShopifyInstallLink()}>
            Connect
          </a>
        </div>
      </p>
    </div>
  );
}
