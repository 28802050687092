import { SalesViewTemplate } from "../../sales-views-context";

export const subscriptionsCash: SalesViewTemplate = {
  id: "subscriptions_cash",
  name: "Subscriptions: Cash",
  isTemplate: true,
  data: {
    filters: {
      attributionModel: "northbeam_custom",
      attributionWindow: "1",
      nbAccountingMode: "cash",
      granularity: "campaign",
      timeGranularity: "daily",
      dateRange: "last7Days",
      compareDateRange: "lastPeriod",
      breakdowns: [
        {
          key: "Platform (Northbeam)",
          values: [],
        },
      ],
    },
    columnConfigurations: [
      {
        name: "Name",
        width: 450,
        visible: true,
      },
      {
        name: "Spend",
        width: 180,
        visible: true,
      },
      {
        name: "Rev",
        width: 182,
        visible: true,
      },
      {
        name: "Subscription revenue",
        width: 200,
        visible: true,
      },
      {
        name: "Rev (1st time)",
        width: 182,
        visible: true,
      },
      {
        name: "Subscription revenue (1st time)",
        width: 200,
        visible: true,
      },
      {
        name: "CAC",
        width: 120,
        visible: true,
      },
      {
        name: "Subscription CAC",
        width: 182,
        visible: true,
      },
      {
        name: "CAC (1st time)",
        width: 130,
        visible: true,
      },
      {
        name: "Subscription CAC (1st time)",
        width: 182,
        visible: true,
      },
      {
        name: "Transactions",
        width: 140,
        visible: true,
      },
      {
        name: "Subscription transactions",
        width: 200,
        visible: true,
      },
      {
        name: "Transactions (1st time)",
        width: 140,
        visible: true,
      },
      {
        name: "Subscription transactions (1st time)",
        width: 200,
        visible: true,
      },
      {
        name: "Rev (Returning)",
        width: 182,
        visible: true,
      },
      {
        name: "Subscription revenue (Recurring)",
        width: 240,
        visible: true,
      },
      {
        name: "Subscription revenue (Other)",
        width: 240,
        visible: true,
      },
      {
        name: "CAC (Returning)",
        width: 120,
        visible: true,
      },
      {
        name: "Subscription CAC (Recurring)",
        width: 182,
        visible: true,
      },
      {
        name: "Subscription CAC (Other)",
        width: 182,
        visible: true,
      },
      {
        name: "Transactions (Returning)",
        width: 140,
        visible: true,
      },
      {
        name: "Subscription transactions (Recurring)",
        width: 240,
        visible: true,
      },
      {
        name: "Subscription transactions (Other)",
        width: 240,
        visible: true,
      },
    ],
    selectedMetricBoxes: [
      "Spend",
      "Subscription revenue",
      "Subscription transactions",
      "Subscription CAC",
    ],
  },
};
