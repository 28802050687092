import { FormattedDateRange } from "@components/date-range-picker";
import { GenericExternalLink } from "@components/generic-external-link";
import { NoDataView } from "@components/title-slide-view";
import { GenericTooltip } from "@components/tooltip-container";
import { useUser } from "@components/user-context";
import { H1 } from "@components/utilities";
import { GetOverviewPerformance_me_overviewPage } from "@nb-api-graphql-generated/GetOverviewPerformance";
import { todayLocalAsISODate } from "@north-beam/nb-common";
import { differenceInDays } from "@utils/datetime";
import { parseDateRangeArgument } from "@utils/index";
import React from "react";
import { BreakdownView } from "./breakdown-view";
import { MetricCharts } from "./metric-charts";
import { FetchReportParams } from "./overview";

export const AccrualPerformance = ({
  response: { performanceMetrics, performanceBreakdownTableRows },
  bodyState,
  breakdown,
  isLoading,
}: FetchReportParams<GetOverviewPerformance_me_overviewPage>) => {
  const { user } = useUser();
  const { attributionModel, dateRange: dr } = bodyState;
  const anchor = todayLocalAsISODate();
  const dateRange = parseDateRangeArgument(dr, anchor);
  const { startDate, endDate } = dateRange;
  const daysDiff = differenceInDays(startDate, endDate);

  let lowerSection = <NoDataView />;

  if (performanceMetrics && performanceBreakdownTableRows) {
    lowerSection = (
      <>
        <div className="row px-4 my-3 nb-print-block bg-white enableDesignRefresh-overview-charts">
          <div className="col" data-test="performance-chart">
            <MetricCharts
              metricsOverview={performanceMetrics}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="row px-4 my-3 nb-print-block nb-breakdown-table">
          <div className="col p-0" data-test="performance-breakdown">
            <BreakdownView
              breakdownTitle={"Breakdown (Accrual performance)"}
              breakdownSubtitle={`This table uses an infinite attribution window, and aggregates data over the last ${daysDiff} day(s)`}
              rows={performanceBreakdownTableRows}
              isLoading={isLoading}
              attributionWindow={"infinity"}
              attributionModel={attributionModel}
              breakdown={breakdown}
            />
          </div>
        </div>
      </>
    );
  }

  const amEnum = user.attributionMethodEnum;

  return (
    <>
      <div className="px-4 row nb-print-block">
        <div className="col p-0">
          <H1 className="mt-2">
            Accrual performance{" "}
            <small>
              (<FormattedDateRange dateRange={dateRange} />)
            </small>
          </H1>
          <p className="mt-3 mb-4 nb-accounting-methods-short-explanation">
            <GenericTooltip
              content={
                <>
                  Your current model is{" "}
                  <b>{amEnum.toReactSelect(attributionModel).label}</b>. To
                  learn more about attribution models and windows, check out our{" "}
                  <GenericExternalLink href="https://info.northbeam.io/knowledge/understanding-northbeam-accounting-cash-vs-accrual-performance">
                    FAQs.
                  </GenericExternalLink>
                </>
              }
            >
              This model attributes conversions on the{" "}
              <strong>
                day(s) that contributory marketing events took place
              </strong>
              , not the day that the conversion occurred. If multiple marketing
              events lead to a conversion, credit is divided among all
              contributing events, according to the attribution model selected.
            </GenericTooltip>
          </p>
          <p className="nb-accounting-methods-read-more">
            <small>
              <GenericExternalLink href="https://info.northbeam.io/knowledge/understanding-northbeams-attribution-models">
                Read more about Northbeam accounting methods.
              </GenericExternalLink>
            </small>
          </p>
        </div>
      </div>
      {lowerSection}
    </>
  );
};
