import { User } from "@components/user-context";
import { GetSalesReportV2_me_salesReport_customGoals } from "@nb-api-graphql-generated/GetSalesReportV2";
import {
  Breakdown,
  CategoricalBreakdownConfig,
  DbConversionsDateRow,
  DbForecastV1DateRow,
  formatNumberApproximate,
  formatNumberExact,
  MetricFormat,
  SalesReportRow,
} from "@north-beam/nb-common";
import {
  IMCAttributionWindowDays,
  INBAccountingMode,
  ITimeGranularity,
  MCAttributionWindowDays,
  NBAccountingMode,
} from "@utils/constants";
import { RowWithChildren } from "@utils/row-with-children";
import { chain, uniqBy } from "lodash";
import pluralize from "pluralize";
import { FunctionComponent } from "react";

export interface ChartableResponseRow {
  description: { key: string; value: string }[];
  series: MetricSeries;
}

export type ChartableResponseRowWithChildren =
  RowWithChildren<ChartableResponseRow>;

export const preprocessForChart = (
  rows: SalesReportRow[],
  breakdownConfigs: CategoricalBreakdownConfig[],
  breakdowns: Breakdown[],
): ChartableResponseRowWithChildren => {
  function generateDescriptors(dimensions: Record<string, string>): string[] {
    const paths: string[][] = [[]];
    for (const breakdown of breakdowns) {
      const dimension = dimensions[breakdown.key] || "";
      paths.push([...paths[paths.length - 1], dimension]);
    }

    return paths.map(pathToDescriptor);
  }

  const keyToConfig = chain(breakdownConfigs)
    .groupBy("key")
    .mapValues((v) => v[0])
    .value();

  const allAggregationPaths: string[][] = [[]];
  for (const breakdown of breakdowns) {
    const currentPaths = [...allAggregationPaths];
    const newPaths: string[][] = [];
    for (const value of breakdown.values) {
      for (const descriptor of currentPaths) {
        newPaths.push([...descriptor, value]);
      }
    }

    allAggregationPaths.push(...newPaths);
  }
  const allAggregationDescriptors = allAggregationPaths.map(pathToDescriptor);

  const descriptorToData: Record<string, MetricSeries> = chain(
    allAggregationDescriptors,
  )
    .map((v) => [v, {}])
    .fromPairs()
    .value();

  for (const row of rows) {
    const { dimensions, dailyMetrics, forecastV1Daily } = row;
    if (!dailyMetrics) {
      continue;
    }

    const dateToForecastRow: Record<string, DbForecastV1DateRow> = {};
    if (forecastV1Daily) {
      for (const forecastRow of forecastV1Daily) {
        dateToForecastRow[forecastRow.date] = forecastRow;
      }
    }

    const descriptors = generateDescriptors(dimensions);
    const rowPassesFilter = descriptors.every((d) => !!descriptorToData[d]);

    if (!rowPassesFilter) {
      continue;
    }

    for (const dailyMetricPoint of dailyMetrics) {
      const metricPoint = makeMetricPoint(
        dailyMetricPoint,
        dateToForecastRow[dailyMetricPoint.date],
      );
      for (const descriptor of descriptors) {
        const data = descriptorToData[descriptor];
        addToMetricSeries(data, dailyMetricPoint.date, metricPoint);
      }
    }
  }

  const descriptorToRow: Record<string, ChartableResponseRowWithChildren> =
    chain(descriptorToData)
      .pickBy((value) => Object.keys(value).length > 0)
      .mapValues((value, key) => {
        const path = descriptorToPath(key);
        const description: { key: string; value: string }[] = [];
        for (let i = 0; i < path.length; ++i) {
          const breakdownKey = breakdowns[i].key;
          const choices = keyToConfig[breakdownKey]?.choices || [];
          const name =
            choices.find((v) => v.value === path[i])?.label ?? path[i];
          description.push({ key: breakdownKey, value: name });
        }
        return {
          description,
          series: value!,
          children: [],
        };
      })
      .value();

  for (const descriptor in descriptorToRow) {
    const path = descriptorToPath(descriptor);
    if (path.length === 0) {
      continue;
    }

    const parentPath = [...path];
    parentPath.pop();

    const parentDescriptor = pathToDescriptor(parentPath);
    descriptorToRow[parentDescriptor].children.push(
      descriptorToRow[descriptor],
    );
  }

  return descriptorToRow[pathToDescriptor([])];
};

export interface ForecastMetric {
  lowerBound: number;
  pointEstimate: number;
  upperBound: number;
}

export type MetricPoint = Omit<DbConversionsDateRow, "date" | "adKey"> & {
  extraRev_to_30: number;
  extraRev_to_60: number;
  extraRev_to_90: number;

  extraTxns_to_30: number;
  extraTxns_to_60: number;
  extraTxns_to_90: number;
};

export interface MetricSeries {
  [key: string]: MetricPoint;
}

export function makeMetricPoint(
  row: DbConversionsDateRow,
  forecastDailyRow: DbForecastV1DateRow | null,
): MetricPoint {
  const { date, adKey, ...rest } = row;

  const rv: MetricPoint = {
    ...rest,
    extraRev_to_30: 0,
    extraRev_to_60: 0,
    extraRev_to_90: 0,

    extraTxns_to_30: 0,
    extraTxns_to_60: 0,
    extraTxns_to_90: 0,
  };

  if (forecastDailyRow) {
    rv.extraRev_to_30 = forecastDailyRow.forecast_rev__30;
    rv.extraRev_to_60 = forecastDailyRow.forecast_rev__60;
    rv.extraRev_to_90 = forecastDailyRow.forecast_rev__90;

    rv.extraTxns_to_30 = forecastDailyRow.forecast_txns__30;
    rv.extraTxns_to_60 = forecastDailyRow.forecast_txns__60;
    rv.extraTxns_to_90 = forecastDailyRow.forecast_txns__90;
  }

  return rv;
}

export function newMetricPoint(): MetricPoint {
  return {
    spend: 0,
    imprs: 0,
    pRev: 0,
    pTxns: 0,
    pVisits: 0,

    visits: 0,
    newVisits: 0,
    convs: [],
    goals: [],

    extraRev_to_30: 0,
    extraRev_to_60: 0,
    extraRev_to_90: 0,

    extraTxns_to_30: 0,
    extraTxns_to_60: 0,
    extraTxns_to_90: 0,

    transactionsOrderSubscription: 0,
    transactionsOrderSubscriptionFirstTime: 0,
    transactionsOrderSubscriptionRecurring: 0,
    transactionsOrderSubscriptionOther: 0,
    revenueOrderSubscription: 0,
    revenueOrderSubscriptionFirstTime: 0,
    revenueOrderSubscriptionRecurring: 0,
    revenueOrderSubscriptionOther: 0,

    metaTotalPurchases1DClick: 0,
    metaTotalPurchases7DClick: 0,
    metaTotalPurchases7DClick1DView: 0,
    metaShopPurchases1DClick: 0,
    metaShopPurchases7DClick: 0,
    metaShopPurchases7DClick1DView: 0,
    metaOffsitePixelPurchases1DClick: 0,
    metaOffsitePixelPurchases7DClick: 0,
    metaOffsitePixelPurchases7DClick1DView: 0,
    metaTotalRevenue1DClick: 0,
    metaTotalRevenue7DClick: 0,
    metaTotalRevenue7DClick1DView: 0,
    metaShopRevenue1DClick: 0,
    metaShopRevenue7DClick: 0,
    metaShopRevenue7DClick1DView: 0,
    metaOffsitePixelRevenue1DClick: 0,
    metaOffsitePixelRevenue7DClick: 0,
    metaOffsitePixelRevenue7DClick1DView: 0,
    metaImprs: 0,
    metaVideoPlayActions7DClick1DView: 0,
    metaVideoPlayActions1DView: 0,
    metaVideoThruplayWatchedActions7DClick1DView: 0,
    metaVideoThruplayWatchedActions1DView: 0,
    metaOfflineRevenue1DClick: 0,
    metaOfflineRevenue7DClick: 0,
    metaOfflineRevenue7DClick1DView: 0,
    metaMobileAppRevenue1DClick: 0,
    metaMobileAppRevenue7DClick: 0,
    metaMobileAppRevenue7DClick1DView: 0,
    metaOfflinePurchases1DClick: 0,
    metaOfflinePurchases7DClick: 0,
    metaOfflinePurchases7DClick1DView: 0,
    metaMobileAppPurchases1DClick: 0,
    metaMobileAppPurchases7DClick: 0,
    metaMobileAppPurchases7DClick1DView: 0,

    googleTotalPurchases: 0,
    googleTotalRevenue: 0,
    googleVideoViews: 0,
    googleViewThroughConversions: 0,

    tiktokVideoViews: 0,
    tiktokVideoViews2s: 0,
    tiktokVideoViews6s: 0,
    tiktokVideoViewsP100: 0,
    tiktokVideoViewsP75: 0,
    tiktokVideoViewsP50: 0,
    tiktokVideoViewsP25: 0,
    tiktokImprs: 0,
    tiktokLikes: 0,
    tiktokClicks: 0,
    tiktokCompletePayment: 0,
    tiktokTotalCompletePayment: 0,
    meta3SVideoViews1DClick: 0,
    meta3SVideoViews7DClick1DView: 0,
    meta3SVideoViews7DClick: 0,
  };
}

export function addToMetricSeries(
  target: MetricSeries,
  key: string,
  source: MetricPoint,
) {
  if (!target[key]) {
    target[key] = newMetricPoint();
  }
  addToMetricPoint(target[key], source);
}

export function addToMetricPoint(target: MetricPoint, source: MetricPoint) {
  target.spend += source.spend ?? 0;
  target.imprs += source.imprs ?? 0;

  target.pVisits += source.pVisits ?? 0;
  target.pTxns += source.pTxns ?? 0;
  target.pRev += source.pRev ?? 0;

  target.visits += source.visits ?? 0;
  target.newVisits += source.newVisits ?? 0;

  target.extraRev_to_30 += source.extraRev_to_30 ?? 0;
  target.extraRev_to_60 += source.extraRev_to_60 ?? 0;
  target.extraRev_to_90 += source.extraRev_to_90 ?? 0;

  target.extraTxns_to_30 += source.extraTxns_to_30 ?? 0;
  target.extraTxns_to_60 += source.extraTxns_to_60 ?? 0;
  target.extraTxns_to_90 += source.extraTxns_to_90 ?? 0;

  target.transactionsOrderSubscription +=
    source.transactionsOrderSubscription ?? 0;
  target.transactionsOrderSubscriptionFirstTime +=
    source.transactionsOrderSubscriptionFirstTime ?? 0;
  target.transactionsOrderSubscriptionRecurring +=
    source.transactionsOrderSubscriptionRecurring ?? 0;
  target.transactionsOrderSubscriptionOther +=
    source.transactionsOrderSubscriptionOther ?? 0;

  target.revenueOrderSubscription += source.revenueOrderSubscription ?? 0;
  target.revenueOrderSubscriptionFirstTime +=
    source.revenueOrderSubscriptionFirstTime ?? 0;
  target.revenueOrderSubscriptionRecurring +=
    source.revenueOrderSubscriptionRecurring ?? 0;
  target.revenueOrderSubscriptionOther +=
    source.revenueOrderSubscriptionOther ?? 0;

  target.metaTotalPurchases1DClick += source.metaTotalPurchases1DClick ?? 0;
  target.metaTotalPurchases7DClick += source.metaTotalPurchases7DClick ?? 0;
  target.metaTotalPurchases7DClick1DView +=
    source.metaTotalPurchases7DClick1DView ?? 0;
  target.metaShopPurchases1DClick += source.metaShopPurchases1DClick ?? 0;
  target.metaShopPurchases7DClick += source.metaShopPurchases7DClick ?? 0;
  target.metaShopPurchases7DClick1DView +=
    source.metaShopPurchases7DClick1DView ?? 0;
  target.metaOffsitePixelPurchases1DClick +=
    source.metaOffsitePixelPurchases1DClick ?? 0;
  target.metaOffsitePixelPurchases7DClick +=
    source.metaOffsitePixelPurchases7DClick ?? 0;
  target.metaOffsitePixelPurchases7DClick1DView +=
    source.metaOffsitePixelPurchases7DClick1DView ?? 0;
  target.metaTotalRevenue1DClick += source.metaTotalRevenue1DClick ?? 0;
  target.metaTotalRevenue7DClick += source.metaTotalRevenue7DClick ?? 0;
  target.metaTotalRevenue7DClick1DView +=
    source.metaTotalRevenue7DClick1DView ?? 0;
  target.metaShopRevenue1DClick += source.metaShopRevenue1DClick ?? 0;
  target.metaShopRevenue7DClick += source.metaShopRevenue7DClick ?? 0;
  target.metaShopRevenue7DClick1DView +=
    source.metaShopRevenue7DClick1DView ?? 0;
  target.metaOffsitePixelRevenue1DClick +=
    source.metaOffsitePixelRevenue1DClick ?? 0;
  target.metaOffsitePixelRevenue7DClick +=
    source.metaOffsitePixelRevenue7DClick ?? 0;
  target.metaOffsitePixelRevenue7DClick1DView +=
    source.metaOffsitePixelRevenue7DClick1DView ?? 0;
  target.metaImprs += source.metaImprs ?? 0;
  target.metaVideoPlayActions7DClick1DView +=
    source.metaVideoPlayActions7DClick1DView ?? 0;
  target.metaVideoPlayActions1DView += source.metaVideoPlayActions1DView ?? 0;
  target.metaVideoThruplayWatchedActions7DClick1DView +=
    source.metaVideoThruplayWatchedActions7DClick1DView ?? 0;
  target.metaVideoThruplayWatchedActions1DView +=
    source.metaVideoThruplayWatchedActions1DView ?? 0;

  target.googleTotalPurchases += source.googleTotalPurchases ?? 0;
  target.googleTotalRevenue += source.googleTotalRevenue ?? 0;
  target.googleVideoViews += source.googleVideoViews ?? 0;
  target.googleViewThroughConversions +=
    source.googleViewThroughConversions ?? 0;

  target.tiktokVideoViews += source.tiktokVideoViews ?? 0;
  target.tiktokVideoViews2s += source.tiktokVideoViews2s ?? 0;
  target.tiktokVideoViews6s += source.tiktokVideoViews6s ?? 0;
  target.tiktokVideoViewsP100 += source.tiktokVideoViewsP100 ?? 0;
  target.tiktokVideoViewsP75 += source.tiktokVideoViewsP75 ?? 0;
  target.tiktokVideoViewsP50 += source.tiktokVideoViewsP50 ?? 0;
  target.tiktokVideoViewsP25 += source.tiktokVideoViewsP25 ?? 0;
  target.tiktokImprs += source.tiktokImprs ?? 0;
  target.tiktokLikes += source.tiktokLikes ?? 0;
  target.tiktokClicks += source.tiktokClicks ?? 0;
  target.tiktokTotalCompletePayment += source.tiktokTotalCompletePayment ?? 0;
  target.tiktokCompletePayment += source.tiktokCompletePayment ?? 0;

  target.meta3SVideoViews1DClick += source.meta3SVideoViews1DClick ?? 0;
  target.meta3SVideoViews7DClick1DView +=
    source.meta3SVideoViews7DClick1DView ?? 0;
  target.meta3SVideoViews7DClick += source.meta3SVideoViews7DClick ?? 0;

  target.metaOfflineRevenue1DClick += source.metaOfflineRevenue1DClick ?? 0;
  target.metaOfflineRevenue7DClick += source.metaOfflineRevenue7DClick ?? 0;
  target.metaOfflineRevenue7DClick1DView +=
    source.metaOfflineRevenue7DClick1DView ?? 0;
  target.metaMobileAppRevenue1DClick += source.metaMobileAppRevenue1DClick ?? 0;
  target.metaMobileAppRevenue7DClick += source.metaMobileAppRevenue7DClick ?? 0;
  target.metaMobileAppRevenue7DClick1DView +=
    source.metaMobileAppRevenue7DClick1DView ?? 0;
  target.metaOfflinePurchases1DClick += source.metaOfflinePurchases1DClick ?? 0;
  target.metaOfflinePurchases7DClick += source.metaOfflinePurchases7DClick ?? 0;
  target.metaOfflinePurchases7DClick1DView +=
    source.metaOfflinePurchases7DClick1DView ?? 0;
  target.metaMobileAppPurchases1DClick +=
    source.metaMobileAppPurchases1DClick ?? 0;
  target.metaMobileAppPurchases7DClick +=
    source.metaMobileAppPurchases7DClick ?? 0;
  target.metaMobileAppPurchases7DClick1DView +=
    source.metaMobileAppPurchases7DClick1DView ?? 0;

  for (const conv of source.convs ?? []) {
    const { am, aw } = conv;
    const targetConv = target.convs.find((v) => v.am === am && v.aw === aw);
    if (targetConv) {
      targetConv.txns += conv.txns;
      targetConv.rev += conv.rev;
      targetConv.ftTxns += conv.ftTxns;
      targetConv.ftRev += conv.ftRev;
      targetConv.rtnTxns += conv.rtnTxns;
      targetConv.rtnRev += conv.rtnRev;
    } else {
      target.convs.push({ ...conv });
    }
  }

  for (const goal of source.goals ?? []) {
    const { am, aw, gt } = goal;
    const targetConv = target.goals.find(
      (v) => v.am === am && v.aw === aw && v.gt === gt,
    );
    if (targetConv) {
      targetConv.gc += goal.gc;
    } else {
      target.goals.push({ ...goal });
    }
  }
}

type FormatFunction = (v: number) => string;

export const MetricFormats: Record<
  MetricFormat | "dollarsRatio",
  Record<"exact" | "approximate", FormatFunction>
> = {
  integer: {
    exact: (v) => formatNumberExact(v, "integer"),
    approximate: (v) => formatNumberApproximate(v, "integer"),
  },
  decimal: {
    exact: (v) => formatNumberExact(v, "decimal"),
    approximate: (v) => formatNumberApproximate(v, "decimal"),
  },
  percentage: {
    exact: (v) => formatNumberExact(v, "percentage"),
    approximate: (v) => formatNumberApproximate(v, "percentage"),
  },
  multiplier: {
    exact: (v) => formatNumberExact(v, "multiplier"),
    approximate: (v) => formatNumberApproximate(v, "multiplier"),
  },
  dollars: {
    exact: (v) => formatNumberExact(v, "dollars"),
    approximate: (v) => formatNumberApproximate(v, "dollars"),
  },
  dollarsRatio: {
    exact: (v) => formatNumberExact(v, "dollars", 1e10),
    approximate: (v) => formatNumberApproximate(v, "dollars", 1e10),
  },
};

export interface SalesPageMetricSpec {
  id: string;
  group: "default" | "subscriptions";
  name: string;
  modifiers: {
    attributionModel: "none" | "all" | "northbeam_custom";
    attributionWindows: IMCAttributionWindowDays[];
    accountingModes: INBAccountingMode[];
  };
}

export interface SalesPageMetric {
  id: string;
  name: string;
  descriptionHTML: string;
  format: MetricFormat; // deprecated -- phasing this out in favor of funcs
  formatExact: FormatFunction;
  formatApproximate: FormatFunction;
  isPositiveChangeGood: boolean;
  isRate: boolean;
  calculate: (v: MetricPoint) => number;
  calculateBand?: (v: MetricPoint) => [number, number];
  calculateCompare?: (v: MetricPoint) => number;

  changeFractionAreaTextForTable?: string;
  changeFractionAreaTextForTabDisplay?: string;
  chartCompareSuffix?: string;
  chartBandSuffix?: [string, string];
  displayCumulativeForChart?: boolean;
  hideFromChartDisplay?: boolean;
  hideFromTableDisplay?: boolean;
  hideComparisonFromTableCell?: boolean;
  hideComparisonFromTabDisplay?: boolean;

  metadata?: {
    metricId?: "rev" | "txns" | "roas" | "ecr" | "cac" | "aov";
    isGoalRelated?: boolean;
  };

  spec?: SalesPageMetricSpec;

  TableCell?: FunctionComponent<{ point: MetricPoint }>;
}

const SpendMetric: SalesPageMetric = {
  id: "spend",
  name: "Spend",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.spend,
  descriptionHTML:
    "How much money was spent on this set of ads over the given time period.",
  spec: {
    id: "spend",
    group: "default",
    name: "Spend",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const ImpressionsMetric: SalesPageMetric = {
  id: "impressions",
  name: "Imprs",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.imprs,
  descriptionHTML:
    "How many times your ads were served, as determined by the platform.",
  spec: {
    id: "impressions",
    group: "default",
    name: "Imprs",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const CPMMetric: SalesPageMetric = {
  id: "cpm",
  name: "CPM",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => (1000 * v.spend) / v.imprs,
  descriptionHTML: "Cost Per Mille - computed as 1,000 × Spend ÷ Impressions.",
  spec: {
    id: "cpm",
    group: "default",
    name: "CPM",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const MetaTotalPurchases1DClick: SalesPageMetric = {
  id: "metaTotalPurchases1DClick",
  name: "FB Purchases (1D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaTotalPurchases1DClick,
  descriptionHTML:
    "Facebook reported total number of conversions across all channels (i.e. website, mobile app, offline, and on-facebook) attributed to your ads.",
};

const MetaTotalPurchases7DClick: SalesPageMetric = {
  id: "metaTotalPurchases7DClick",
  name: "FB Purchases (7D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaTotalPurchases7DClick,
  descriptionHTML:
    "Facebook reported total number of conversions across all channels (i.e. website, mobile app, offline, and on-facebook) attributed to your ads.",
};

const MetaTotalPurchases7DClick1DView: SalesPageMetric = {
  id: "metaTotalPurchases7DClick1DView",
  name: "FB Purchases (7D Click, 1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaTotalPurchases7DClick1DView,
  descriptionHTML:
    "Facebook reported total number of conversions across all channels (i.e. website, mobile app, offline, and on-facebook) attributed to your ads.",
};

const MetaShopPurchases1DClick: SalesPageMetric = {
  id: "metaShopPurchases1DClick",
  name: "FB Meta shop purchases (1D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaShopPurchases1DClick,
  descriptionHTML:
    "Facebook reported number of conversions from Facebook Meta shop attributed to your ads.",
};

const MetaShopPurchases7DClick: SalesPageMetric = {
  id: "metaShopPurchases7DClick",
  name: "FB Meta shop purchases (7D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaShopPurchases7DClick,
  descriptionHTML:
    "Facebook reported number of conversions from Facebook Meta shop attributed to your ads.",
};

const MetaShopPurchases7DClick1DView: SalesPageMetric = {
  id: "metaShopPurchases7DClick1DView",
  name: "FB Meta shop purchases (7D Click, 1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaShopPurchases7DClick1DView,
  descriptionHTML:
    "Facebook reported number of conversions from Facebook Meta shop attributed to your ads.",
};

const MetaOffsitePixelPurchases1DClick: SalesPageMetric = {
  id: "metaOffsitePixelPurchases1DClick",
  name: "FB Website purchases (1D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOffsitePixelPurchases1DClick,
  descriptionHTML:
    "Facebook reported number of conversions from your brand site attributed to your ads.",
};

const MetaOffsitePixelPurchases7DClick: SalesPageMetric = {
  id: "metaOffsitePixelPurchases7DClick",
  name: "FB Website purchases (7D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOffsitePixelPurchases7DClick,
  descriptionHTML:
    "Facebook reported number of conversions from your brand site attributed to your ads.",
};

const MetaOffsitePixelPurchases7DClick1DView: SalesPageMetric = {
  id: "metaOffsitePixelPurchases7DClick1DView",
  name: "FB Website purchases (7D Click, 1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOffsitePixelPurchases7DClick1DView,
  descriptionHTML:
    "Facebook reported number of conversions from your brand site attributed to your ads.",
};

const MetaTotalRevenue1DClick: SalesPageMetric = {
  id: "metaTotalRevenue1DClick",
  name: "FB Purchases conversion value (1D Click)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaTotalRevenue1DClick,
  descriptionHTML:
    "Facebook reported total revenue from conversions across all channels (i.e. website, mobile app, offline, and on-facebook) attributed to your ads.",
};

const MetaTotalRevenue7DClick: SalesPageMetric = {
  id: "metaTotalRevenue7DClick",
  name: "FB Purchases conversion value (7D Click)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaTotalRevenue7DClick,
  descriptionHTML:
    "Facebook reported total revenue from conversions across all channels (i.e. website, mobile app, offline, and on-facebook) attributed to your ads.",
};

const MetaTotalRevenue7DClick1DView: SalesPageMetric = {
  id: "metaTotalRevenue7DClick1DView",
  name: "FB Purchases conversion value (7D Click, 1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaTotalRevenue7DClick1DView,
  descriptionHTML:
    "Facebook reported total revenue from conversions across all channels (i.e. website, mobile app, offline, and on-facebook) attributed to your ads.",
};

const MetaShopRevenue1DClick: SalesPageMetric = {
  id: "metaShopRevenue1DClick",
  name: "FB Meta shop purchases conversion value (1D Click)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaShopRevenue1DClick,
  descriptionHTML:
    "Facebook reported revenue from conversions from Facebook Meta shop attributed to your ads.",
};

const MetaShopRevenue7DClick: SalesPageMetric = {
  id: "metaShopRevenue7DClick",
  name: "FB Meta shop purchases conversion value (7D Click)",
  format: "integer",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaShopRevenue7DClick,
  descriptionHTML:
    "Facebook reported revenue from conversions from Facebook Meta shop attributed to your ads.",
};

const MetaShopRevenue7DClick1DView: SalesPageMetric = {
  id: "metaShopRevenue7DClick1DView",
  name: "FB Meta shop purchases conversion value (7D Click, 1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaShopRevenue7DClick1DView,
  descriptionHTML:
    "Facebook reported revenue from conversions from Facebook Meta shop attributed to your ads.",
};

const MetaOffsitePixelRevenue1DClick: SalesPageMetric = {
  id: "metaOffsitePixelRevenue1DClick",
  name: "FB Website purchases conversion value (1D Click)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOffsitePixelRevenue1DClick,
  descriptionHTML:
    "Facebook reported revenue from conversions from your brand site attributed to your ads.",
};

const MetaOffsitePixelRevenue7DClick: SalesPageMetric = {
  id: "metaOffsitePixelRevenue7DClick",
  name: "FB Website purchases conversion value (7D Click)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOffsitePixelRevenue7DClick,
  descriptionHTML:
    "Facebook reported revenue from conversions from your brand site attributed to your ads.",
};

const MetaOffsitePixelRevenue7DClick1DView: SalesPageMetric = {
  id: "metaOffsitePixelRevenue7DClick1DView",
  name: "FB Website purchases conversion value (7D Click, 1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOffsitePixelRevenue7DClick1DView,
  descriptionHTML:
    "Facebook reported revenue from conversions from your brand site attributed to your ads.",
};

const MetaThumbstopRate1DView: SalesPageMetric = {
  id: "metaThumbstopRate1DView",
  name: "FB thumbstop rate (1D View)",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.meta3SVideoViews1DClick && v.imprs) {
      return v.meta3SVideoViews1DClick / v.imprs;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported thumbstop rate, calculated using: 3-second video views / impressions.",
};

const MetaThumbstopRate7DClick: SalesPageMetric = {
  id: "metaThumbstopRate7DClick",
  name: "FB thumbstop rate (7D Click)",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.meta3SVideoViews7DClick && v.imprs) {
      return v.meta3SVideoViews7DClick / v.imprs;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported thumbstop rate, calculated using: 3-second video views / impressions.",
};

const MetaThumbstopRate7DClick1DView: SalesPageMetric = {
  id: "metaThumbstopRate7DClick1DView",
  name: "FB thumbstop rate (7D Click, 1D View)",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.meta3SVideoViews7DClick1DView && v.imprs) {
      return v.meta3SVideoViews7DClick1DView / v.imprs;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported thumbstop rate, calculated using: 3-second video views / impressions.",
};

const MetaHoldRate1DView: SalesPageMetric = {
  id: "metaHoldRate1DView",
  name: "FB hold ratio (1D View)",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.metaVideoThruplayWatchedActions1DView && v.imprs) {
      return v.metaVideoThruplayWatchedActions1DView / v.imprs;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported hold ratio, calculated using: thruplays / impressions.",
};

const MetaHoldRate7DClick1DView: SalesPageMetric = {
  id: "metaHoldRate7DClick1DView",
  name: "FB hold ratio (7D Click, 1D View)",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.metaVideoThruplayWatchedActions7DClick1DView && v.imprs) {
      return v.metaVideoThruplayWatchedActions7DClick1DView / v.imprs;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported hold ratio, calculated using: thruplays / impressions.",
};

const MetaVideoPlayActions1DView: SalesPageMetric = {
  id: "metaVideoPlayActions1DView",
  name: "FB video play actions (1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaVideoPlayActions1DView,
  descriptionHTML: "Facebook reported video video play actions.",
};

const MetaVideoPlayActions7DClick1DView: SalesPageMetric = {
  id: "metaVideoPlayActions7DClick1DView",
  name: "FB video play actions (7D Click, 1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaVideoPlayActions7DClick1DView,
  descriptionHTML: "Facebook reported video play actions.",
};

const MetaCostPerView1DView: SalesPageMetric = {
  id: "metaCostPerView1DView",
  name: "FB cost per view (1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => {
    if (v.metaVideoPlayActions1DView) {
      return v.spend / v.metaVideoPlayActions1DView;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported cost per view, calculated using: spend / video play actions.",
};

const MetaCostPerView7DClick1DView: SalesPageMetric = {
  id: "metaCostPerView7DClick1DView",
  name: "FB cost per view (7D Click, 1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => {
    if (v.metaVideoPlayActions7DClick1DView) {
      return v.spend / v.metaVideoPlayActions7DClick1DView;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported cost per view, calculated using: spend / video play actions.",
};

const MetaVideoThruplayWatchedActions1DView: SalesPageMetric = {
  id: "metaVideoThruplayWatchedActions1DView",
  name: "FB thruplay (1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaVideoThruplayWatchedActions1DView,
  descriptionHTML: "Facebook reported video thruplay.",
};

const MetaVideoThruplayWatchedActions7DClick1DView: SalesPageMetric = {
  id: "metaVideoThruplayWatchedActions7DClick1DView",
  name: "FB thruplay (7D Click, 1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaVideoThruplayWatchedActions7DClick1DView,
  descriptionHTML: "Facebook reported thruplay.",
};

const MetaThruplayRate1DView: SalesPageMetric = {
  id: "metaThruplayRate1DView",
  name: "FB thruplay rate (1D View)",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (
      v.metaVideoThruplayWatchedActions1DView &&
      v.metaVideoPlayActions1DView
    ) {
      return (
        v.metaVideoThruplayWatchedActions1DView / v.metaVideoPlayActions1DView
      );
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported thruplay rate, calculated using: thruplay / video play actions.",
};

const MetaThruplayRate7DClick1DView: SalesPageMetric = {
  id: "metaThruplayRate7DClick1DView",
  name: "FB thruplay rate (7D Click, 1D View)",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (
      v.metaVideoThruplayWatchedActions7DClick1DView &&
      v.metaVideoPlayActions7DClick1DView
    ) {
      return (
        v.metaVideoThruplayWatchedActions7DClick1DView /
        v.metaVideoPlayActions7DClick1DView
      );
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported thruplay rate, calculated using: thruplay / video play actions.",
};

const MetaCostPerThruplay1DView: SalesPageMetric = {
  id: "metaCostPerThruplay1DView",
  name: "FB cost per thruplay (1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => {
    if (v.metaVideoThruplayWatchedActions1DView) {
      return v.spend / v.metaVideoThruplayWatchedActions1DView;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported cost per view, calculated using: spend / video play actions.",
};

const MetaCostPerThruplay7DClick1DView: SalesPageMetric = {
  id: "metaCostPerThruplay7DClick1DView",
  name: "FB cost per thruplay (7D Click, 1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => {
    if (v.metaVideoThruplayWatchedActions7DClick1DView) {
      return v.spend / v.metaVideoThruplayWatchedActions7DClick1DView;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported cost per view, calculated using: spend / video play actions.",
};

const MetaROAS1DClick: SalesPageMetric = {
  id: "metaROAS1DClick",
  name: "FB ROAS (1D Click)",
  format: "multiplier",
  formatExact: MetricFormats.multiplier.exact,
  formatApproximate: MetricFormats.multiplier.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.metaTotalRevenue1DClick) {
      return v.metaTotalRevenue1DClick / v.spend;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported ROAS, this is calculated by taking the total 1D click revenue reported by Facebook and dividing by spend.",
};

const MetaROAS7DClick: SalesPageMetric = {
  id: "metaROAS7DClick",
  name: "FB ROAS (7D Click)",
  format: "multiplier",
  formatExact: MetricFormats.multiplier.exact,
  formatApproximate: MetricFormats.multiplier.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.metaTotalRevenue7DClick) {
      return v.metaTotalRevenue7DClick / v.spend;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported ROAS, this is calculated by taking the total 7D click revenue reported by Facebook and dividing by spend.",
};

const MetaROAS7DClick1DView: SalesPageMetric = {
  id: "metaROAS7DClick1DView",
  name: "FB ROAS (7D Click, 1D View)",
  format: "multiplier",
  formatExact: MetricFormats.multiplier.exact,
  formatApproximate: MetricFormats.multiplier.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.metaTotalRevenue7DClick) {
      return v.metaTotalRevenue7DClick1DView / v.spend;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Facebook reported ROAS, this is calculated by taking the total 7D click, 1D view revenue reported by Facebook and dividing by spend.",
};

const GoogleTotalPurchases: SalesPageMetric = {
  id: "googleTotalPurchases",
  name: "Google total purchases",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.googleTotalPurchases,
  descriptionHTML: "Google reported total purchases.",
};

const GoogleTotalRevenue: SalesPageMetric = {
  id: "googleTotalRevenue",
  name: "Google total revenue",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.googleTotalRevenue,
  descriptionHTML: "Google reported total revenue.",
};

const GoogleVideoViews: SalesPageMetric = {
  id: "googleVideoViews",
  name: "Google video views",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.googleVideoViews,
  descriptionHTML: "Google reported video views.",
};

const GoogleViewThroughConversions: SalesPageMetric = {
  id: "googleViewThroughConversions",
  name: "Google view-through conversions",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.googleViewThroughConversions,
  descriptionHTML: "Google reported view-through conversions.",
};

const GoogleROAS: SalesPageMetric = {
  id: "googleROAS",
  name: "Google ROAS",
  format: "multiplier",
  formatExact: MetricFormats.multiplier.exact,
  formatApproximate: MetricFormats.multiplier.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.googleTotalRevenue) {
      return v.googleTotalRevenue / v.spend;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Google reported ROAS, this is calculated by taking the total revenue reported by Google and dividing by spend.",
};

const GoogleCostPerPurchase: SalesPageMetric = {
  id: "googleCostPerPurchase",
  name: "Google cost per purchase",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => {
    if (v.googleTotalPurchases) {
      return v.spend / v.googleTotalPurchases;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "Google reported cost per purchase, this is calculated by taking spend and dividing by the number of total purchases reported by Google.",
};

const TikTokVideoViews: SalesPageMetric = {
  id: "tiktokVideoViews",
  name: "TikTok video views",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokVideoViews,
  descriptionHTML: "TikTok reported video views",
};

const TikTokVideoViews2s: SalesPageMetric = {
  id: "tiktokVideoViews2s",
  name: "TikTok two-second video views",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokVideoViews2s,
  descriptionHTML: "TikTok reported two-second video views",
};

const TikTokVideoViews6s: SalesPageMetric = {
  id: "tiktokVideoViews6s",
  name: "TikTok six-second video views",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokVideoViews6s,
  descriptionHTML: "TikTok reported six-second video views",
};

const TiktokVideoViewsP100: SalesPageMetric = {
  id: "tiktokVideoViewsP100",
  name: "TikTok P100 Video Views",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokVideoViewsP100,
  descriptionHTML: "TikTok P100 Video Views",
};

const TiktokVideoViewsP75: SalesPageMetric = {
  id: "tiktokVideoViewsP75",
  name: "TikTok P75 Video Views",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokVideoViewsP75,
  descriptionHTML: "TikTok P75 Video Views",
};

const TiktokVideoViewsP50: SalesPageMetric = {
  id: "tiktokVideoViewsP50",
  name: "TikTok P50 Video Views",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokVideoViewsP50,
  descriptionHTML: "TikTok P50 Video Views",
};

const TiktokVideoViewsP25: SalesPageMetric = {
  id: "tiktokVideoViewsP25",
  name: "TikTok P25 Video Views",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokVideoViewsP25,
  descriptionHTML: "TikTok P25 Video Views",
};
const TikTokImpressions: SalesPageMetric = {
  id: "tiktokImpressions",
  name: "TikTok impressions",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokImprs,
  descriptionHTML: "TikTok reported impressions",
};

const TikTokClicks: SalesPageMetric = {
  id: "tiktokClicks",
  name: "TikTok clicks",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokClicks,
  descriptionHTML: "TikTok reported clicks",
};

const TikTokLikes: SalesPageMetric = {
  id: "tiktokLikes",
  name: "TikTok likes",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokLikes,
  descriptionHTML: "TikTok reported likes",
};

const TikTokCompletePayment: SalesPageMetric = {
  id: "tiktokCompletePayment",
  name: "TikTok complete payments",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokCompletePayment,
  descriptionHTML: "TikTok reported complete payments",
};

const TikTokTotalCompletePayment: SalesPageMetric = {
  id: "tiktokTotalCompletePayment",
  name: "TikTok complete payment revenue",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.tiktokTotalCompletePayment,
  descriptionHTML: "TikTok reported complete payment revenue",
};

const TikTokCPM: SalesPageMetric = {
  id: "tiktokCPM",
  name: "TikTok CPM",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => {
    if (v.tiktokImprs) {
      return (v.spend * 1000) / v.tiktokImprs;
    }
    return 0;
  },
  descriptionHTML: "TikTok reported CPM",
};

const TikTokCTR: SalesPageMetric = {
  id: "tiktokCTR",
  name: "TikTok CTR",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.tiktokImprs && v.tiktokClicks) {
      return v.tiktokClicks / v.tiktokImprs;
    }
    return 0;
  },
  descriptionHTML: "TikTok CTR",
};

const TikTokCPC: SalesPageMetric = {
  id: "tiktokCPM",
  name: "TikTok CPM",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => {
    if (v.tiktokClicks) {
      return v.spend / v.tiktokClicks;
    }
    return 0;
  },
  descriptionHTML: "TikTok CPM",
};

const TikTokROAS: SalesPageMetric = {
  id: "tiktokROAS",
  name: "TikTok ROAS",
  format: "multiplier",
  formatExact: MetricFormats.multiplier.exact,
  formatApproximate: MetricFormats.multiplier.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => {
    if (v.tiktokTotalCompletePayment) {
      return v.tiktokTotalCompletePayment / v.spend;
    }
    return 0;
  },
  descriptionHTML: "TikTok ROAS",
};

const TikTokThumbstopRate: SalesPageMetric = {
  id: "tiktokThumbstopRate",
  name: "TikTok thumbstop rate",
  format: "percentage",
  formatExact: MetricFormats.percentage.exact,
  formatApproximate: MetricFormats.percentage.approximate,
  isPositiveChangeGood: true,
  isRate: true,
  calculate: (v) => {
    if (v.tiktokVideoViews2s && v.tiktokVideoViews) {
      return v.tiktokVideoViews2s / v.tiktokVideoViews;
    } else {
      return 0;
    }
  },
  descriptionHTML:
    "TikTok reported thumbstop rate, calculated using: 2-second video views / video play actions.",
};

const TransactionsOrderSubscription: SalesPageMetric = {
  id: "transactionsOrderSubscription",
  name: "Subscription transactions",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.transactionsOrderSubscription,
  descriptionHTML: "Includes all subscription categories",
  spec: {
    id: "transactionsOrderSubscription",
    group: "subscriptions",
    name: "Subscription transactions",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const TransactionsOrderSubscriptionFirstTime: SalesPageMetric = {
  id: "transactionsOrderSubscriptionFirstTime",
  name: "Subscription transactions (1st time)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.transactionsOrderSubscriptionFirstTime,
  descriptionHTML:
    "Includes order tags you've mapped to the 'first time' category",
  spec: {
    id: "transactionsOrderSubscriptionFirstTime",
    group: "subscriptions",
    name: "Subscription transactions (1st time)",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};
const TransactionsOrderSubscriptionRecurring: SalesPageMetric = {
  id: "transactionsOrderSubscriptionRecurring",
  name: "Subscription transactions (Recurring)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.transactionsOrderSubscriptionRecurring,
  descriptionHTML:
    "Includes order tags you've mapped to the 'recurring' category",
  spec: {
    id: "transactionsOrderSubscriptionRecurring",
    group: "subscriptions",
    name: "Subscription transactions (Recurring)",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};
const TransactionsOrderSubscriptionOther: SalesPageMetric = {
  id: "transactionsOrderSubscriptionOther",
  name: "Subscription transactions (Other)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.transactionsOrderSubscriptionOther,
  descriptionHTML:
    "Includes all order tags that were mapped to 'other', or could not be mapped to 'recurring' or 'first time'",
  spec: {
    id: "transactionsOrderSubscriptionOther",
    group: "subscriptions",
    name: "Subscription transactions (Other)",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const RevenueOrderSubscription = (accountingMode: INBAccountingMode) => {
  const name = `Subscription ${
    accountingMode === "accrual" ? "attributed " : ""
  }revenue`;

  return {
    id: "revenueOrderSubscription",
    name,
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    calculate: (v) => v.revenueOrderSubscription,
    descriptionHTML: "Includes all subscription categories",
    spec: {
      id: `revenueOrderSubscription${
        accountingMode === "accrual" ? "Attributed" : ""
      }`,
      group: "subscriptions",
      name,
      modifiers: {
        attributionModel: "none",
        attributionWindows:
          accountingMode === "cash"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes: [accountingMode],
      },
    },
  } as SalesPageMetric;
};

const RevenueOrderSubscriptionFirstTime = (
  accountingMode: INBAccountingMode,
) => {
  const name = `Subscription ${
    accountingMode === "accrual" ? "attributed " : ""
  }revenue (1st time)`;

  return {
    id: "revenueOrderSubscriptionFirstTime",
    name,
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    calculate: (v) => v.revenueOrderSubscriptionFirstTime,
    descriptionHTML:
      "Includes order tags you've mapped to the 'first time' category",

    spec: {
      id: `revenueOrderSubscriptionFirstTime${
        accountingMode === "accrual" ? "Attributed" : ""
      }`,
      group: "subscriptions",
      name,
      modifiers: {
        attributionModel: "none",
        attributionWindows:
          accountingMode === "cash"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes: [accountingMode],
      },
    },
  } as SalesPageMetric;
};

const RevenueOrderSubscriptionRecurring = (
  accountingMode: INBAccountingMode,
) => {
  const name = `Subscription ${
    accountingMode === "accrual" ? "attributed " : ""
  }revenue (Recurring)`;

  return {
    id: "revenueOrderSubscriptionRecurring",
    name,
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    calculate: (v) => v.revenueOrderSubscriptionRecurring,
    descriptionHTML:
      "Includes order tags you've mapped to the 'recurring' category",
    spec: {
      id: `revenueOrderSubscriptionRecurring${
        accountingMode === "accrual" ? "Attributed" : ""
      }`,
      group: "subscriptions",
      name,
      modifiers: {
        attributionModel: "none",
        attributionWindows:
          accountingMode === "cash"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes: [accountingMode],
      },
    },
  } as SalesPageMetric;
};

const RevenueOrderSubscriptionOther = (accountingMode: INBAccountingMode) => {
  const name = `Subscription ${
    accountingMode === "accrual" ? "attributed " : ""
  }revenue (Other)`;

  return {
    id: "revenueOrderSubscriptionOther",
    name,
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    calculate: (v) => v.revenueOrderSubscriptionOther,
    descriptionHTML:
      "Includes all order tags that were mapped to 'other', or could not be mapped to 'recurring' or 'first time'",
    spec: {
      id: `revenueOrderSubscriptionOther${
        accountingMode === "accrual" ? "Attributed" : ""
      }`,
      group: "subscriptions",
      name,
      modifiers: {
        attributionModel: "none",
        attributionWindows:
          accountingMode === "cash"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes: [accountingMode],
      },
    },
  } as SalesPageMetric;
};

const CACOrderSubscription: SalesPageMetric = {
  id: "cacOrderSubscription",
  name: "Subscription CAC",
  format: "dollars",
  formatExact: MetricFormats.dollarsRatio.exact,
  formatApproximate: MetricFormats.dollarsRatio.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => v.spend / v.transactionsOrderSubscription,
  descriptionHTML: "Includes all subscription categories",
  spec: {
    id: "cacOrderSubscription",
    group: "subscriptions",
    name: "Subscription CAC",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const CACOrderSubscriptionFirstTime: SalesPageMetric = {
  id: "cacOrderSubscriptionFirstTime",
  name: "Subscription CAC (1st time)",
  format: "dollars",
  formatExact: MetricFormats.dollarsRatio.exact,
  formatApproximate: MetricFormats.dollarsRatio.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => v.spend / v.transactionsOrderSubscriptionFirstTime,
  descriptionHTML:
    "Includes order tags you've mapped to the 'first time' category",
  spec: {
    id: "cacOrderSubscriptionFirstTime",
    group: "subscriptions",
    name: "Subscription CAC (1st time)",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const CACOrderSubscriptionRecurring: SalesPageMetric = {
  id: "cacOrderSubscriptionRecurring",
  name: "Subscription CAC (Recurring)",
  format: "dollars",
  formatExact: MetricFormats.dollarsRatio.exact,
  formatApproximate: MetricFormats.dollarsRatio.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => v.spend / v.transactionsOrderSubscriptionRecurring,
  descriptionHTML:
    "Includes order tags you've mapped to the 'recurring' category",
  spec: {
    id: "cacOrderSubscriptionRecurring",
    group: "subscriptions",
    name: "Subscription CAC (Recurring)",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const CACOrderSubscriptionOther: SalesPageMetric = {
  id: "cacOrderSubscriptionOther",
  name: "Subscription CAC (Other)",
  format: "dollars",
  formatExact: MetricFormats.dollarsRatio.exact,
  formatApproximate: MetricFormats.dollarsRatio.approximate,
  isPositiveChangeGood: false,
  isRate: true,
  calculate: (v) => v.spend / v.transactionsOrderSubscriptionOther,
  descriptionHTML:
    "Includes all order tags that were mapped to 'other', or could not be mapped to 'recurring' or 'first time'",
  spec: {
    id: "cacOrderSubscriptionOther",
    group: "subscriptions",
    name: "Subscription CAC (Other)",
    modifiers: {
      attributionModel: "none",
      attributionWindows: [],
      accountingModes: [],
    },
  },
};

const MetaOfflineRevenue1DClick: SalesPageMetric = {
  id: "metaOfflineRevenue1DClick",
  name: "FB Offline purchases conversion value (1D Click)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOfflineRevenue1DClick,
  descriptionHTML:
    "Facebook reported revenue from conversions from offline Facebook attributed to your ads.",
};

const MetaOfflineRevenue7DClick: SalesPageMetric = {
  id: "metaOfflineRevenue7DClick",
  name: "FB Offline purchases conversion value (7D Click)",
  format: "integer",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOfflineRevenue7DClick,
  descriptionHTML:
    "Facebook reported revenue from conversions from offline Facebook attributed to your ads.",
};

const MetaOfflineRevenue7DClick1DView: SalesPageMetric = {
  id: "metaOfflineRevenue7DClick1DView",
  name: "FB Offline purchases conversion value (7D Click, 1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOfflineRevenue7DClick1DView,
  descriptionHTML:
    "Facebook reported revenue from conversions from offline Facebook attributed to your ads.",
};

const MetaMobileAppRevenue1DClick: SalesPageMetric = {
  id: "metaMobileAppRevenue1DClick",
  name: "FB Mobile app purchases conversion value (1D Click)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaMobileAppRevenue1DClick,
  descriptionHTML:
    "Facebook reported revenue from conversions from your mobile app attributed to your ads.",
};

const MetaMobileAppRevenue7DClick: SalesPageMetric = {
  id: "metaMobileAppRevenue7DClick",
  name: "FB Mobile app purchases conversion value (7D Click)",
  format: "integer",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaMobileAppRevenue7DClick,
  descriptionHTML:
    "Facebook reported revenue from conversions from your mobile app attributed to your ads.",
};

const MetaMobileAppRevenue7DClick1DView: SalesPageMetric = {
  id: "metaMobileAppRevenue7DClick1DView",
  name: "FB Mobile app purchases conversion value (7D Click, 1D View)",
  format: "dollars",
  formatExact: MetricFormats.dollars.exact,
  formatApproximate: MetricFormats.dollars.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaMobileAppRevenue7DClick1DView,
  descriptionHTML:
    "Facebook reported revenue from conversions from your mobile app attributed to your ads.",
};

const MetaOfflinePurchases1DClick: SalesPageMetric = {
  id: "metaOfflinePurchases1DClick",
  name: "FB Offline purchases (1D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOfflinePurchases1DClick,
  descriptionHTML:
    "Facebook reported number of conversions from offline Facebook attributed to your ads.",
};

const MetaOfflinePurchases7DClick: SalesPageMetric = {
  id: "metaOfflinePurchases7DClick",
  name: "FB Offline purchases (7D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOfflinePurchases7DClick,
  descriptionHTML:
    "Facebook reported number of conversions from offline Facebook attributed to your ads.",
};

const MetaOfflinePurchases7DClick1DView: SalesPageMetric = {
  id: "metaOfflinePurchases7DClick1DView",
  name: "FB Offline purchases (7D Click, 1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaOfflinePurchases7DClick1DView,
  descriptionHTML:
    "Facebook reported number of conversions from offline Facebook attributed to your ads.",
};

const MetaMobileAppPurchases1DClick: SalesPageMetric = {
  id: "metaMobileAppPurchases1DClick",
  name: "FB Mobile app purchases (1D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaMobileAppPurchases1DClick,
  descriptionHTML:
    "Facebook reported number of conversions from youra mobile app attributed to your ads.",
};

const MetaMobileAppPurchases7DClick: SalesPageMetric = {
  id: "metaMobileAppPurchases7DClick",
  name: "FB Mobile app purchases (7D Click)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaMobileAppPurchases7DClick,
  descriptionHTML:
    "Facebook reported number of conversions from youra mobile app attributed to your ads.",
};

const MetaMobileAppPurchases7DClick1DView: SalesPageMetric = {
  id: "metaMobileAppPurchases7DClick1DView",
  name: "FB Mobile app purchases (7D Click, 1D View)",
  format: "integer",
  formatExact: MetricFormats.integer.exact,
  formatApproximate: MetricFormats.integer.approximate,
  isPositiveChangeGood: true,
  isRate: false,
  calculate: (v) => v.metaMobileAppPurchases7DClick1DView,
  descriptionHTML:
    "Facebook reported number of conversions from youra mobile app attributed to your ads.",
};

function findConv(
  v: MetricPoint,
  am: string,
  aw: IMCAttributionWindowDays | "",
  metric: Exclude<keyof MetricPoint["convs"][0], "aw" | "am">,
) {
  return v.convs.find((w) => w.am === am && w.aw === aw)?.[metric] ?? 0;
}

function findGoal(
  v: MetricPoint,
  am: string,
  aw: IMCAttributionWindowDays | "",
  gt: string,
  metric: Exclude<keyof MetricPoint["goals"][0], "aw" | "am" | "gt">,
) {
  return (
    v.goals.find((w) => w.am === am && w.aw === aw && w.gt === gt)?.[metric] ??
    0
  );
}

function makeMetricName(
  base: string,
  am: string,
  aw: IMCAttributionWindowDays | "",
  type: "ft" | "rtn" | "",
  accountingMode: INBAccountingMode,
) {
  if (accountingMode === "cash") {
    if (base === "Attributed Rev") {
      base = "Rev";
    }

    if (base === "Attributed Txns") {
      base = "Txns";
    }

    if (base === "ROAS") {
      base = "MER";
    }
  }

  const parts: string[] = [];
  if (aw === "infinity") {
    if (accountingMode === "accrual") {
      base = `LTV ${base}`;
    }
  } else {
    parts.push(`${aw}d`);
  }

  if (type) {
    parts.push({ ft: "1st time", rtn: "Returning" }[type]);
  }

  let partsSuffix = "";
  if (parts.length > 0) {
    partsSuffix = ` (${parts.join(", ")})`;
  }
  return `${base}${partsSuffix}`;
}

function makeMetricSpecName(
  base: string,
  type: "ft" | "rtn" | "",
  attributionWindow: IMCAttributionWindowDays | "",
  accountingMode: INBAccountingMode,
) {
  if (accountingMode === "cash") {
    if (base === "Attributed Rev") {
      base = "Rev";
    }

    if (base === "Attributed Transactions") {
      base = "Transactions";
    }

    if (base === "ROAS") {
      base = "MER";
    }
  }

  if (accountingMode === "accrual" && attributionWindow === "infinity") {
    base = `LTV ${base}`;
  }

  let partsSuffix = "";

  if (type) {
    const txt = { ft: "1st time", rtn: "Returning" }[type];
    partsSuffix = ` (${txt})`;
  }

  return `${base}${partsSuffix}`;
}

function makeMetricId(
  base: string,
  am: string,
  aw: IMCAttributionWindowDays | "",
  type: "ft" | "rtn" | "",
  accountingMode: INBAccountingMode,
) {
  return `${base}__${am}__${aw || "none"}__${type}__${accountingMode}`;
}

function makeAttributionWindowDescription(aw: IMCAttributionWindowDays) {
  if (aw === "infinity") {
    return `This LTV metric is reported with an <b>infinity-day attribution window</b>.
    Touchpoints receive credit for both delayed transactions and repeat
    transactions.`;
  }

  return `This metric is reported with a <b>${aw}-day attribution window</b>.
    Touchpoints only receive credit for transactions that happen within ${aw}
    ${aw === "1" ? "day" : "days"} of the touchpoint.`;
}

function makeRoas(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  type: "ft" | "rtn" | "",
  accountingMode: INBAccountingMode,
): SalesPageMetric {
  const common = {
    id: makeMetricId("ROAS", am, aw, type, accountingMode),
    name: makeMetricName("ROAS", am, aw, type, accountingMode),
    format: "multiplier",
    formatExact: MetricFormats.multiplier.exact,
    formatApproximate: MetricFormats.multiplier.approximate,
    isPositiveChangeGood: true,
    isRate: true,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTableCell:
      aw === "infinity" && accountingMode === "accrual",
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      metricId: "roas",
    },
  } as const;

  let descriptionHTML = "Media efficiency ratio - computed as Rev ÷ Spend.";

  if (accountingMode === "accrual") {
    descriptionHTML = "Return on Ad Spend - computed as Rev ÷ Spend.";
    if (awDescriptionHTML) {
      descriptionHTML += `<hr />${awDescriptionHTML}`;
    }
  }

  const metricKey = (
    {
      ft: "ftRev",
      rtn: "rtnRev",
      "": "rev",
    } as const
  )[type];

  const idType = (
    {
      ft: "Ft",
      rtn: "Rtn",
      "": "",
    } as const
  )[type];

  const id = accountingMode === "accrual" ? "roas" : "mer";
  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) => findConv(v, am, aw, metricKey) / v.spend,
    descriptionHTML,
    ...common,
    spec: {
      id: `${id}${idType}${ltv}`,
      group: "default",
      name: makeMetricSpecName("ROAS", type, aw, accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes: [accountingMode],
      },
    },
  };
}

function makeAOV(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  type: "ft" | "rtn" | "",
  accountingMode: INBAccountingMode,
): SalesPageMetric {
  const common = {
    id: makeMetricId("AOV", am, aw, type, accountingMode),
    name: makeMetricName("AOV", am, aw, type, accountingMode),
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: true,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTableCell:
      aw === "infinity" && accountingMode === "accrual",
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      metricId: "aov",
    },
  } as const;

  let descriptionHTML =
    "Average Order Value - computed as Revenue / Transactions.";

  if (accountingMode === "accrual" && awDescriptionHTML) {
    descriptionHTML += `<hr />${awDescriptionHTML}`;
  }

  const revMetricKey = (
    {
      ft: "ftRev",
      rtn: "rtnRev",
      "": "rev",
    } as const
  )[type];

  const txnsMetricKey = (
    {
      ft: "ftTxns",
      rtn: "rtnTxns",
      "": "txns",
    } as const
  )[type];

  const idType = (
    {
      ft: "Ft",
      rtn: "Rtn",
      "": "",
    } as const
  )[type];

  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) =>
      findConv(v, am, aw, revMetricKey) / findConv(v, am, aw, txnsMetricKey),
    descriptionHTML,
    ...common,
    spec: {
      id: `aov${idType}${ltv}`,
      group: "default",
      name: makeMetricSpecName("AOV", type, aw, accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes:
          accountingMode === "accrual" && aw === "infinity"
            ? ["accrual"]
            : ["cash", "accrual"],
      },
    },
  };
}

function makeCustomGoal(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  accountingMode: INBAccountingMode,
  customGoalId: string,
  customGoalNameSignular: string,
  attributionMethodEnum: any,
): SalesPageMetric {
  const pluralGoalName = pluralize(customGoalNameSignular);
  const common = {
    id: makeMetricId(pluralGoalName, am, aw, "", accountingMode),
    name: makeMetricName(pluralGoalName, am, aw, "", accountingMode),
    format: "decimal",
    formatExact: MetricFormats.decimal.exact,
    formatApproximate: MetricFormats.decimal.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTableCell:
      aw === "infinity" && accountingMode === "accrual",
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      isGoalRelated: true,
    },
  } as const;

  let descriptionHTML = `${pluralGoalName} attributable to this ad using the ${attributionMethodEnum.getLabel(
    am,
  )} attribution model.`;
  if (awDescriptionHTML) {
    descriptionHTML += `<hr />${awDescriptionHTML}`;
  }

  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) => findGoal(v, am, aw, customGoalId, "gc"),
    descriptionHTML,
    ...common,
    spec: {
      id: `customGoal${ltv}:${customGoalId}`,
      group: "default",
      name: makeMetricName(pluralGoalName, am, aw, "", accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes:
          accountingMode === "accrual" && aw === "infinity"
            ? ["accrual"]
            : ["cash", "accrual"],
      },
    },
  };
}

function makeCustomGoalCost(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  accountingMode: INBAccountingMode,
  customGoalId: string,
  customGoalNameSignular: string,
): SalesPageMetric {
  const name = `Cost per ${customGoalNameSignular}`;

  const common = {
    id: makeMetricId(name, am, aw, "", accountingMode),
    name: makeMetricName(name, am, aw, "", accountingMode),
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTableCell:
      aw === "infinity" && accountingMode === "accrual",
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      isGoalRelated: true,
    },
  } as const;

  let descriptionHTML = `Average cost of new visits that end in an ${customGoalNameSignular}.`;
  if (awDescriptionHTML) {
    descriptionHTML += `<hr />${awDescriptionHTML}`;
  }

  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) => v.spend / findGoal(v, am, aw, customGoalId, "gc"),
    descriptionHTML,
    ...common,
    spec: {
      id: `customGoalCoast${ltv}:${customGoalId}`,
      group: "default",
      name: makeMetricName(name, am, aw, "", accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes:
          accountingMode === "accrual" && aw === "infinity"
            ? ["accrual"]
            : ["cash", "accrual"],
      },
    },
  };
}

function makeCustomGoalRate(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  accountingMode: INBAccountingMode,
  customGoalId: string,
  customGoalNameSignular: string,
): SalesPageMetric {
  const name = `${customGoalNameSignular} Rate`;

  const common = {
    id: makeMetricId(name, am, aw, "", accountingMode),
    name: makeMetricName(name, am, aw, "", accountingMode),
    format: "percentage",
    formatExact: MetricFormats.percentage.exact,
    formatApproximate: MetricFormats.percentage.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTableCell:
      aw === "infinity" && accountingMode === "accrual",
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      isGoalRelated: true,
    },
  } as const;

  let descriptionHTML = `Percentage of new visits that end in an ${customGoalNameSignular}.`;
  if (awDescriptionHTML) {
    descriptionHTML += `<hr />${awDescriptionHTML}`;
  }

  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) => findGoal(v, am, aw, customGoalId, "gc") / v.newVisits,
    descriptionHTML,
    ...common,
    spec: {
      id: `customGoalRate${ltv}:${customGoalId}`,
      group: "default",
      name: makeMetricName(name, am, aw, "", accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes:
          accountingMode === "accrual" && aw === "infinity"
            ? ["accrual"]
            : ["cash", "accrual"],
      },
    },
  };
}

function makeTxns(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  type: "ft" | "rtn" | "",
  accountingMode: INBAccountingMode,
  attributionMethodEnum: any,
): SalesPageMetric {
  const common = {
    id: makeMetricId("Txns", am, aw, type, accountingMode),
    name: makeMetricName("Txns", am, aw, type, accountingMode),
    format: "decimal",
    formatExact: MetricFormats.decimal.exact,
    formatApproximate: MetricFormats.decimal.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTableCell:
      aw === "infinity" && accountingMode === "accrual",
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      metricId: "txns",
    },
  } as const;

  let descriptionHTML = "Transactions during the given date range.";

  if (accountingMode === "accrual") {
    descriptionHTML = `Transactions attributable to this ad using the ${attributionMethodEnum.getLabel(
      am,
    )} attribution model.`;
    if (awDescriptionHTML) {
      descriptionHTML += `<hr />${awDescriptionHTML}`;
    }
  }

  const metricKey = (
    {
      ft: "ftTxns",
      rtn: "rtnTxns",
      "": "txns",
    } as const
  )[type];

  const idType = (
    {
      ft: "Ft",
      rtn: "Rtn",
      "": "",
    } as const
  )[type];

  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) => findConv(v, am, aw, metricKey),
    descriptionHTML,
    ...common,
    spec: {
      id: `txns${idType}${ltv}`,
      group: "default",
      name: makeMetricSpecName("Transactions", type, aw, accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes:
          accountingMode === "accrual" && aw === "infinity"
            ? ["accrual"]
            : ["cash", "accrual"],
      },
    },
  };
}

function MetaShopRevenuePlusNBAttributedRevenue1DClick(
  am: string,
  aw: IMCAttributionWindowDays | "",
): SalesPageMetric {
  return {
    id: "metaShopRevenuePlusNBAttributedRevenue1DClick",
    name: "FB Meta shops revenue (1D Click) + NB attributed revenue",
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    calculate: (v) => {
      if (v.metaShopRevenue1DClick) {
        return v.metaShopRevenue1DClick + findConv(v, am, aw, "rev");
      }
      return 0;
    },
    descriptionHTML: "Facebook Meta shop revenue + NB attributed revenue",
  };
}

function MetaShopRevenuePlusNBAttributedRevenue7DClick(
  am: string,
  aw: IMCAttributionWindowDays | "",
): SalesPageMetric {
  return {
    id: "metaShopRevenuePlusNBAttributedRevenue7DClick",
    name: "FB Meta shops revenue (7D Click) + NB attributed revenue",
    format: "integer",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    calculate: (v) => {
      if (v.metaShopRevenue7DClick) {
        return v.metaShopRevenue7DClick + findConv(v, am, aw, "rev");
      }
      return 0;
    },
    descriptionHTML: "Facebook Meta shop revenue + NB attributed revenue",
  };
}

function MetaShopRevenuePlusNBAttributedRevenue7DClick1DView(
  am: string,
  aw: IMCAttributionWindowDays | "",
): SalesPageMetric {
  return {
    id: "metaShopRevenuePlusNBAttributedRevenue7DClick1DView",
    name: "FB Meta shops revenue (7D Click, 1D View) + NB attributed revenue",
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    calculate: (v) => {
      if (v.metaShopRevenue7DClick1DView) {
        return v.metaShopRevenue7DClick1DView + findConv(v, am, aw, "rev");
      }
      return 0;
    },
    descriptionHTML: "Facebook Meta shop revenue + NB attributed revenue",
  };
}

function makeRev(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  type: "ft" | "rtn" | "",
  accountingMode: INBAccountingMode,
  attributionMethodEnum: any,
): SalesPageMetric {
  const common = {
    id: makeMetricId("Attributed Rev", am, aw, type, accountingMode),
    name: makeMetricName("Attributed Rev", am, aw, type, accountingMode),
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTableCell:
      aw === "infinity" && accountingMode === "accrual",
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      metricId: "rev",
    },
  } as const;

  let descriptionHTML = "Total revenue during this time period.";

  if (accountingMode === "accrual") {
    descriptionHTML = `Revenue attributable to this ad using the ${attributionMethodEnum.getLabel(
      am,
    )} attribution model.`;
    if (awDescriptionHTML) {
      descriptionHTML += `<hr />${awDescriptionHTML}`;
    }
  }

  const metricKey = (
    {
      ft: "ftRev",
      rtn: "rtnRev",
      "": "rev",
    } as const
  )[type];

  const idType = (
    {
      ft: "Ft",
      rtn: "Rtn",
      "": "",
    } as const
  )[type];

  const id = accountingMode === "cash" ? "rev" : "revAttributed";

  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) => findConv(v, am, aw, metricKey),
    descriptionHTML,
    ...common,
    spec: {
      id: `${id}${idType}${ltv}`,
      group: "default",
      name: makeMetricSpecName("Attributed Rev", type, aw, accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes: [accountingMode],
      },
    },
  };
}

function makeForecastedRev(
  am: Exclude<string, "platform">,
  days: "30" | "60" | "90",
  attributionMethodEnum: any,
): SalesPageMetric {
  const name = `Forecasted Attributed Rev (${days}d)`;

  const common = {
    id: makeMetricId("Forecasted Attributed Rev", am, days, "", "accrual"),
    name,
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    changeFractionAreaTextForTable: `${days} day forecast`,
    changeFractionAreaTextForTabDisplay: "Forecasted",
    hideComparisonFromTabDisplay: true,
    hideComparisonFromTableCell: true,
  } as const;

  let descriptionHTML = `Forecasted revenue attributable to this ad using the ${attributionMethodEnum.getLabel(
    am,
  )} attribution model, within ${days} days.`;
  descriptionHTML += `<hr />
    This is a <b>forecasted metric</b>.
    Using your historical data, we predict the future value of today's sales and marketing efforts.
    `;

  const key = (
    {
      30: "extraRev_to_30",
      60: "extraRev_to_60",
      90: "extraRev_to_90",
    } as const
  )[days];

  return {
    calculate: (v) => findConv(v, am, "infinity", "rev") + v[key],
    descriptionHTML,
    ...common,
    spec: {
      id: `forecastedRev${days}`,
      group: "default",
      name,
      modifiers: {
        attributionModel: "northbeam_custom",
        attributionWindows: [],
        accountingModes: ["accrual"],
      },
    },
  };
}

function makeForecastedTxns(
  am: Exclude<string, "platform">,
  days: "30" | "60" | "90",
  attributionMethodEnum: any,
): SalesPageMetric {
  const name = `Forecasted Attributed Txns (${days}d)`;

  const common = {
    id: makeMetricId("Forecasted Attributed Txns", am, days, "", "accrual"),
    name,
    format: "decimal",
    formatExact: MetricFormats.decimal.exact,
    formatApproximate: MetricFormats.decimal.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    changeFractionAreaTextForTable: `${days} day forecast`,
    changeFractionAreaTextForTabDisplay: "Forecasted",
    hideComparisonFromTabDisplay: true,
    hideComparisonFromTableCell: true,
  } as const;

  let descriptionHTML = `Forecasted transactions attributable to this ad using the ${attributionMethodEnum.getLabel(
    am,
  )} attribution model, within ${days} days.`;
  descriptionHTML += `<hr />
    This is a <b>forecasted metric</b>.
    Using your historical data, we predict the future value of today's sales and marketing efforts.
    `;

  const key = (
    {
      30: "extraTxns_to_30",
      60: "extraTxns_to_60",
      90: "extraTxns_to_90",
    } as const
  )[days];

  return {
    calculate: (v) => findConv(v, am, "infinity", "txns") + v[key],
    descriptionHTML,
    ...common,
    spec: {
      id: `forecastedTxns${days}`,
      group: "default",
      name: `Forecasted Attributed Transactions (${days}d)`,
      modifiers: {
        attributionModel: "northbeam_custom",
        attributionWindows: [],
        accountingModes: ["accrual"],
      },
    },
  };
}

function makeForecastedRoas(
  am: Exclude<string, "platform">,
  days: "30" | "60" | "90",
): SalesPageMetric {
  const name = `Forecasted ROAS (${days}d)`;

  const common = {
    id: makeMetricId("Forecasted ROAS", am, days, "", "accrual"),
    name,
    format: "multiplier",
    formatExact: MetricFormats.multiplier.exact,
    formatApproximate: MetricFormats.multiplier.approximate,
    isPositiveChangeGood: true,
    isRate: true,
    changeFractionAreaTextForTable: `${days} day forecast`,
    changeFractionAreaTextForTabDisplay: "Forecasted",
    hideComparisonFromTabDisplay: true,
    hideComparisonFromTableCell: true,
  } as const;

  let descriptionHTML = `Forecasted Return on Ad Spend within ${days} days.`;
  descriptionHTML += `<hr />
    This is a <b>forecasted metric</b>.
    Using your historical data, we predict the future value of today's sales and marketing efforts.
    `;

  const key = (
    {
      30: "extraRev_to_30",
      60: "extraRev_to_60",
      90: "extraRev_to_90",
    } as const
  )[days];

  return {
    calculate: (v) => (findConv(v, am, "infinity", "rev") + v[key]) / v.spend,
    descriptionHTML,
    ...common,
    spec: {
      id: `forecastedRoas${days}`,
      group: "default",
      name,
      modifiers: {
        attributionModel: "northbeam_custom",
        attributionWindows: [],
        accountingModes: ["accrual"],
      },
    },
  };
}

function makeEcr(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  type: "ft" | "rtn" | "",
  accountingMode: INBAccountingMode,
): SalesPageMetric {
  const common = {
    id: makeMetricId("ECR", am, aw, type, accountingMode),
    name: makeMetricName("ECR", am, aw, type, accountingMode),
    format: "percentage",
    formatExact: MetricFormats.percentage.exact,
    formatApproximate: MetricFormats.percentage.approximate,
    isPositiveChangeGood: true,
    isRate: true,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      metricId: "ecr",
    },
  } as const;

  let descriptionHTML =
    "Ecommerce conversion rate - calculated as Txns ÷ Visits.";
  if (awDescriptionHTML && accountingMode === "accrual") {
    descriptionHTML += `<hr />${awDescriptionHTML}`;
  }

  const metricKey = (
    {
      ft: "ftTxns",
      rtn: "rtnTxns",
      "": "txns",
    } as const
  )[type];

  const idType = (
    {
      ft: "Ft",
      rtn: "Rtn",
      "": "",
    } as const
  )[type];

  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) => findConv(v, am, aw, metricKey) / v.visits,
    descriptionHTML,
    ...common,
    spec: {
      id: `ecr${idType}${ltv}`,
      group: "default",
      name: makeMetricSpecName("ECR", type, aw, accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes:
          accountingMode === "accrual" && aw === "infinity"
            ? ["accrual"]
            : ["cash", "accrual"],
      },
    },
  };
}

function makeCac(
  am: string,
  aw: IMCAttributionWindowDays | "",
  awDescriptionHTML: string,
  type: "ft" | "rtn" | "",
  accountingMode: INBAccountingMode,
): SalesPageMetric {
  const common = {
    id: makeMetricId("CAC", am, aw, type, accountingMode),
    name: makeMetricName("CAC", am, aw, type, accountingMode),
    format: "dollars",
    formatExact: MetricFormats.dollarsRatio.exact,
    formatApproximate: MetricFormats.dollarsRatio.approximate,
    isPositiveChangeGood: false,
    isRate: true,
    changeFractionAreaTextForTable: aw === "infinity" ? "Realized" : undefined,
    changeFractionAreaTextForTabDisplay:
      aw === "infinity" ? "Realized" : undefined,
    hideComparisonFromTabDisplay:
      aw === "infinity" && accountingMode === "accrual",
    metadata: {
      metricId: "cac",
    },
  } as const;

  let descriptionHTML =
    "Cost to acquire a customer - calculated as Spend ÷ Txns.";
  if (awDescriptionHTML && accountingMode === "accrual") {
    descriptionHTML += `<hr />${awDescriptionHTML}`;
  }

  const metricKey = (
    {
      ft: "ftTxns",
      rtn: "rtnTxns",
      "": "txns",
    } as const
  )[type];

  const idType = (
    {
      ft: "Ft",
      rtn: "Rtn",
      "": "",
    } as const
  )[type];

  const ltv = accountingMode === "accrual" && aw === "infinity" ? "Ltv" : "";

  return {
    calculate: (v) => v.spend / findConv(v, am, aw, metricKey),
    descriptionHTML,
    ...common,
    spec: {
      id: `cac${idType}${ltv}`,
      group: "default",
      name: makeMetricSpecName("CAC", type, aw, accountingMode),
      modifiers: {
        attributionModel: "all",
        attributionWindows:
          accountingMode === "accrual" && aw === "infinity"
            ? ["infinity"]
            : ["1", "3", "7", "14", "30", "60", "90"],
        accountingModes:
          accountingMode === "accrual" && aw === "infinity"
            ? ["accrual"]
            : ["cash", "accrual"],
      },
    },
  };
}

function makeNewVisits(): SalesPageMetric {
  return {
    id: "newVisits",
    name: "New Visits",
    calculate: (v) => v.newVisits,
    format: "integer",
    formatExact: MetricFormats.integer.exact,
    formatApproximate: MetricFormats.integer.approximate,
    isPositiveChangeGood: true,
    isRate: false,
    descriptionHTML:
      "Visits to your website from new visitors, as determined by the Northbeam Pixel.",
    spec: {
      id: "newVisits",
      group: "default",
      name: "New Visits",
      modifiers: {
        attributionModel: "none",
        attributionWindows: [],
        accountingModes: [],
      },
    },
  };
}

function makeNewVisitorPercentage(): SalesPageMetric {
  return {
    id: "percentageNewVisits",
    name: "% New Visits",
    calculate: (v) => v.newVisits / v.visits,
    format: "percentage",
    formatExact: MetricFormats.percentage.exact,
    formatApproximate: MetricFormats.percentage.approximate,
    isPositiveChangeGood: true,
    isRate: true,
    descriptionHTML:
      "Percentage of visits to your website that are from new visitors, as determined by the Northbeam Pixel.",
    spec: {
      id: "newVisitsPercentage",
      group: "default",
      name: "% New Visits",
      modifiers: {
        attributionModel: "none",
        attributionWindows: [],
        accountingModes: [],
      },
    },
  };
}

function makeVisits(am: string): SalesPageMetric {
  const common = {
    format: "integer",
    formatExact: MetricFormats.integer.exact,
    formatApproximate: MetricFormats.integer.approximate,
    isPositiveChangeGood: true,
    isRate: false,
  } as const;

  return {
    id: `${am}_visits`,
    name: "Visits",
    calculate: (v) => v.visits,
    descriptionHTML: "Visits to your website.",
    ...common,
    spec: {
      id: "visits",
      group: "default",
      name: "Visits",
      modifiers: {
        attributionModel: "none",
        attributionWindows: [],
        accountingModes: [],
      },
    },
  };
}

function makeCtr(am: string): SalesPageMetric {
  const common = {
    format: "percentage",
    formatExact: MetricFormats.percentage.exact,
    formatApproximate: MetricFormats.percentage.approximate,
    isPositiveChangeGood: true,
    isRate: false,
  } as const;

  return {
    id: `${am}_ctr`,
    name: "CTR",
    calculate: (v) => v.visits / v.imprs,
    descriptionHTML: "Click through rate - calculated as Visits ÷ Imprs.",
    ...common,
    spec: {
      id: "ctr",
      group: "default",
      name: "CTR",
      modifiers: {
        attributionModel: "none",
        attributionWindows: [],
        accountingModes: [],
      },
    },
  };
}

function makeEcpc(am: string): SalesPageMetric {
  const common = {
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: false,
    isRate: true,
  } as const;

  return {
    id: `${am}_ecpc`,
    name: "eCPC",
    calculate: (v) => v.spend / v.visits,
    descriptionHTML: "Effective cost per click - calculated as Spend ÷ Visits.",
    ...common,
    spec: {
      id: "ecpc",
      group: "default",
      name: "eCPC",
      modifiers: {
        attributionModel: "none",
        attributionWindows: [],
        accountingModes: [],
      },
    },
  };
}

function makeEcpnv(am: string): SalesPageMetric {
  const common = {
    format: "dollars",
    formatExact: MetricFormats.dollars.exact,
    formatApproximate: MetricFormats.dollars.approximate,
    isPositiveChangeGood: false,
    isRate: true,
  } as const;

  return {
    id: `${am}_ecpnv`,
    name: "eCPNV",
    calculate: (v) => v.spend / v.newVisits,
    descriptionHTML:
      "Effective cost per new visit - calculated as Spend ÷ New Visits.",
    ...common,
    spec: {
      id: "ecpnv",
      group: "default",
      name: "eCPNV",
      modifiers: {
        attributionModel: "none",
        attributionWindows: [],
        accountingModes: [],
      },
    },
  };
}

export function makeTrafficRows(
  am: string,
  aw: IMCAttributionWindowDays,
  accountingMode: INBAccountingMode,
  user: User,
  timeGranularity: ITimeGranularity,
): SalesPageMetric[] {
  const fbColumns = [
    MetaTotalPurchases1DClick,
    MetaTotalPurchases7DClick,
    MetaTotalPurchases7DClick1DView,
    MetaShopPurchases1DClick,
    MetaShopPurchases7DClick,
    MetaShopPurchases7DClick1DView,
    MetaOffsitePixelPurchases1DClick,
    MetaOffsitePixelPurchases7DClick,
    MetaOffsitePixelPurchases7DClick1DView,
    MetaTotalRevenue1DClick,
    MetaTotalRevenue7DClick,
    MetaTotalRevenue7DClick1DView,
    MetaShopRevenue1DClick,
    MetaShopRevenue7DClick,
    MetaShopRevenue7DClick1DView,
    MetaOffsitePixelRevenue1DClick,
    MetaOffsitePixelRevenue7DClick,
    MetaOffsitePixelRevenue7DClick1DView,
    MetaROAS1DClick,
    MetaROAS7DClick,
    MetaROAS7DClick1DView,
  ];

  const fbDailyOnlyColumns = [
    MetaThumbstopRate1DView,
    MetaThumbstopRate7DClick,
    MetaThumbstopRate7DClick1DView,
    MetaHoldRate1DView,
    MetaHoldRate7DClick1DView,
    MetaVideoPlayActions1DView,
    MetaVideoPlayActions7DClick1DView,
    MetaCostPerView1DView,
    MetaCostPerView7DClick1DView,
    MetaVideoThruplayWatchedActions1DView,
    MetaVideoThruplayWatchedActions7DClick1DView,
    MetaThruplayRate1DView,
    MetaThruplayRate7DClick1DView,
    MetaCostPerThruplay1DView,
    MetaCostPerThruplay7DClick1DView,
  ];

  const fbCustomCalculationMetrics = [
    MetaShopRevenuePlusNBAttributedRevenue1DClick(am, aw),
    MetaShopRevenuePlusNBAttributedRevenue7DClick(am, aw),
    MetaShopRevenuePlusNBAttributedRevenue7DClick1DView(am, aw),
  ];

  const googleColumns = [
    GoogleTotalPurchases,
    GoogleTotalRevenue,
    GoogleVideoViews,
    GoogleViewThroughConversions,
    GoogleROAS,
    GoogleCostPerPurchase,
  ];

  const tiktokColumns = [
    TiktokVideoViewsP100,
    TiktokVideoViewsP50,
    TiktokVideoViewsP75,
    TiktokVideoViewsP25,
    TikTokVideoViews,
    TikTokVideoViews2s,
    TikTokVideoViews6s,
    TikTokImpressions,
    TikTokClicks,
    TikTokLikes,
    TikTokCompletePayment,
    TikTokTotalCompletePayment,
    TikTokCPC,
    TikTokCPM,
    TikTokCTR,
    TikTokThumbstopRate,
    TikTokROAS,
  ];

  const fbOfflineAndMobileAppColumns = [
    MetaOfflineRevenue1DClick,
    MetaOfflineRevenue7DClick,
    MetaOfflineRevenue7DClick1DView,
    MetaMobileAppRevenue1DClick,
    MetaMobileAppRevenue7DClick,
    MetaMobileAppRevenue7DClick1DView,
    MetaOfflinePurchases1DClick,
    MetaOfflinePurchases7DClick,
    MetaOfflinePurchases7DClick1DView,
    MetaMobileAppPurchases1DClick,
    MetaMobileAppPurchases7DClick,
    MetaMobileAppPurchases7DClick1DView,
  ];

  const subscriptionColumns = (accountingMode: INBAccountingMode) => [
    TransactionsOrderSubscription,
    TransactionsOrderSubscriptionFirstTime,
    TransactionsOrderSubscriptionRecurring,
    TransactionsOrderSubscriptionOther,
    RevenueOrderSubscription(accountingMode),
    RevenueOrderSubscriptionFirstTime(accountingMode),
    RevenueOrderSubscriptionRecurring(accountingMode),
    RevenueOrderSubscriptionOther(accountingMode),
    CACOrderSubscription,
    CACOrderSubscriptionFirstTime,
    CACOrderSubscriptionRecurring,
    CACOrderSubscriptionOther,
  ];

  const otherColumns = [
    SpendMetric,
    ImpressionsMetric,
    makeCtr(am),
    makeVisits(am),
    makeNewVisits(),
    makeNewVisitorPercentage(),
    makeEcpc(am),
    makeEcpnv(am),
    CPMMetric,
  ];

  let allColumns = otherColumns;

  // Only show FB and Google platform metrics iff:
  // 1. enableFlatTableAlpha feature flag is on
  // 2. We are using the new sales view
  // 3. (Google only) enableGooglePlatformMetrics feature flag is on
  // 4. (TikTok only) enableTikTokPlatformMetrics feature flag is on
  // 5. (FB only) append additional columns if we are in daily mode
  if (
    user.featureFlags.enableFlatTableAlpha &&
    !user.featureFlags.doNotShowNewSalesPage
  ) {
    if (!user.featureFlags.disableFBPlatformPerformanceMetrics) {
      allColumns = allColumns.concat(fbColumns);
    }

    if (user.featureFlags.enableFBOfflineAndMobileAppColumns) {
      allColumns = allColumns.concat(fbOfflineAndMobileAppColumns);
    }

    if (
      timeGranularity === "daily" &&
      !user.featureFlags.disableFBPlatformVideoMetrics
    ) {
      allColumns = allColumns.concat(fbDailyOnlyColumns);
    }

    if (!user.featureFlags.disableFBCustomCalculationMetrics) {
      allColumns = allColumns.concat(fbCustomCalculationMetrics);
    }

    if (user.featureFlags.enableGooglePlatformMetrics) {
      allColumns = allColumns.concat(googleColumns);
    }

    if (user.featureFlags.enableTikTokPlatformMetrics) {
      allColumns = allColumns.concat(tiktokColumns);
    }

    if (user.featureFlags.showSubscriptionOrderTags) {
      allColumns = allColumns.concat(subscriptionColumns(accountingMode));
    }
  }

  return allColumns;
}

export interface CustomGoalRow {
  id: string;
  displayName: string;
}

export function makeOverviewRows(
  am: string,
  aw: IMCAttributionWindowDays,
  includeV1Forecasts: boolean,
  accountingMode: INBAccountingMode,
  customGoalRows: CustomGoalRow[],
  timeGranularity: ITimeGranularity,
  user: User,
): SalesPageMetric[] {
  const awDesc = makeAttributionWindowDescription(aw);
  const awDescInfinity = makeAttributionWindowDescription("infinity");

  const { revCols, roasCols, ecrCols, cacCols, txnCols, aovCols } =
    makeConversionCols(
      am,
      aw,
      accountingMode,
      includeV1Forecasts,
      "",
      timeGranularity,
      user.attributionMethodEnum,
    );

  const fbColumns = [
    MetaTotalPurchases1DClick,
    MetaTotalPurchases7DClick,
    MetaTotalPurchases7DClick1DView,
    MetaShopPurchases1DClick,
    MetaShopPurchases7DClick,
    MetaShopPurchases7DClick1DView,
    MetaOffsitePixelPurchases1DClick,
    MetaOffsitePixelPurchases7DClick,
    MetaOffsitePixelPurchases7DClick1DView,
    MetaTotalRevenue1DClick,
    MetaTotalRevenue7DClick,
    MetaTotalRevenue7DClick1DView,
    MetaShopRevenue1DClick,
    MetaShopRevenue7DClick,
    MetaShopRevenue7DClick1DView,
    MetaOffsitePixelRevenue1DClick,
    MetaOffsitePixelRevenue7DClick,
    MetaOffsitePixelRevenue7DClick1DView,
    MetaROAS1DClick,
    MetaROAS7DClick,
    MetaROAS7DClick1DView,
  ];

  const fbCustomCalculationMetrics = [
    MetaShopRevenuePlusNBAttributedRevenue1DClick(am, aw),
    MetaShopRevenuePlusNBAttributedRevenue7DClick(am, aw),
    MetaShopRevenuePlusNBAttributedRevenue7DClick1DView(am, aw),
  ];

  const fbDailyOnlyColumns = [
    MetaThumbstopRate1DView,
    MetaThumbstopRate7DClick,
    MetaThumbstopRate7DClick1DView,
    MetaHoldRate1DView,
    MetaHoldRate7DClick1DView,
    MetaVideoPlayActions1DView,
    MetaVideoPlayActions7DClick1DView,
    MetaCostPerView1DView,
    MetaCostPerView7DClick1DView,
    MetaVideoThruplayWatchedActions1DView,
    MetaVideoThruplayWatchedActions7DClick1DView,
    MetaThruplayRate1DView,
    MetaThruplayRate7DClick1DView,
    MetaCostPerThruplay1DView,
    MetaCostPerThruplay7DClick1DView,
  ];

  const fbOfflineAndMobileAppColumns = [
    MetaOfflineRevenue1DClick,
    MetaOfflineRevenue7DClick,
    MetaOfflineRevenue7DClick1DView,
    MetaMobileAppRevenue1DClick,
    MetaMobileAppRevenue7DClick,
    MetaMobileAppRevenue7DClick1DView,
    MetaOfflinePurchases1DClick,
    MetaOfflinePurchases7DClick,
    MetaOfflinePurchases7DClick1DView,
    MetaMobileAppPurchases1DClick,
    MetaMobileAppPurchases7DClick,
    MetaMobileAppPurchases7DClick1DView,
  ];

  const googleColumns = [
    GoogleTotalPurchases,
    GoogleTotalRevenue,
    GoogleVideoViews,
    GoogleViewThroughConversions,
    GoogleROAS,
    GoogleCostPerPurchase,
  ];

  const tiktokColumns = [
    TikTokVideoViews,
    TikTokVideoViews2s,
    TikTokVideoViews6s,
    TikTokImpressions,
    TikTokClicks,
    TikTokLikes,
    TikTokCompletePayment,
    TikTokTotalCompletePayment,
  ];

  const otherColumns = [
    SpendMetric,
    ...revCols,
    ...roasCols,
    ImpressionsMetric,
    makeCtr(am),
    makeVisits(am),
    makeNewVisits(),
    makeEcpc(am),
    makeEcpnv(am),
    CPMMetric,
    ...ecrCols,
    ...cacCols,
    ...txnCols,
    ...aovCols,
  ];

  let allColumns = otherColumns;

  // Only show FB and Google platform metrics iff:
  // 1. enableFlatTableAlpha feature flag is on
  // 2. We are using the new sales view
  // 3. (Google only) enableGooglePlatformMetrics feature flag is on
  // 4. (TikTok only) enableTikTokPlatformMetrics feature flag is on
  // 5. (FB only) append additional columns if we are in daily mode
  if (
    user.featureFlags.enableFlatTableAlpha &&
    !user.featureFlags.doNotShowNewSalesPage
  ) {
    if (!user.featureFlags.disableFBPlatformPerformanceMetrics) {
      allColumns = allColumns.concat(fbColumns);
    }

    if (user.featureFlags.enableFBOfflineAndMobileAppColumns) {
      allColumns = allColumns.concat(fbOfflineAndMobileAppColumns);
    }

    if (
      timeGranularity === "daily" &&
      !user.featureFlags.disableFBPlatformVideoMetrics
    ) {
      allColumns = allColumns.concat(fbDailyOnlyColumns);
    }

    if (!user.featureFlags.disableFBCustomCalculationMetrics) {
      allColumns = allColumns.concat(fbCustomCalculationMetrics);
    }

    if (user.featureFlags.enableGooglePlatformMetrics) {
      allColumns = allColumns.concat(googleColumns);
    }

    if (user.featureFlags.enableTikTokPlatformMetrics) {
      allColumns = allColumns.concat(tiktokColumns);
    }
  }

  if (accountingMode === "accrual") {
    for (const { id, displayName } of customGoalRows) {
      allColumns.push(
        makeCustomGoal(
          am,
          aw,
          awDesc,
          accountingMode,
          id,
          displayName,
          user.attributionMethodEnum,
        ),
      );

      if (timeGranularity !== "hourly")
        allColumns.push(
          makeCustomGoal(
            am,
            "infinity",
            awDescInfinity,
            accountingMode,
            id,
            displayName,
            user.attributionMethodEnum,
          ),
        );

      allColumns.push(
        makeCustomGoalRate(am, aw, awDesc, accountingMode, id, displayName),
      );
      if (timeGranularity !== "hourly")
        allColumns.push(
          makeCustomGoalRate(
            am,
            "infinity",
            awDescInfinity,
            accountingMode,
            id,
            displayName,
          ),
        );
      allColumns.push(
        makeCustomGoalCost(am, aw, awDesc, accountingMode, id, displayName),
      );
      if (timeGranularity !== "hourly")
        allColumns.push(
          makeCustomGoalCost(
            am,
            "infinity",
            awDescInfinity,
            accountingMode,
            id,
            displayName,
          ),
        );
    }
  } else {
    for (const { id, displayName } of customGoalRows) {
      allColumns.push(
        makeCustomGoal(
          am,
          timeGranularity === "hourly" ? aw : "infinity",
          timeGranularity === "hourly" ? awDesc : awDescInfinity,
          accountingMode,
          id,
          displayName,
          user.attributionMethodEnum,
        ),
        makeCustomGoalRate(
          am,
          timeGranularity === "hourly" ? aw : "infinity",
          timeGranularity === "hourly" ? awDesc : awDescInfinity,
          accountingMode,
          id,
          displayName,
        ),
        makeCustomGoalCost(
          am,
          timeGranularity === "hourly" ? aw : "infinity",
          timeGranularity === "hourly" ? awDesc : awDescInfinity,
          accountingMode,
          id,
          displayName,
        ),
      );
    }
  }

  return allColumns;
}

function makeConversionCols(
  am: Exclude<string, "platform">,
  aw: IMCAttributionWindowDays,
  accountingMode: INBAccountingMode,
  includeV1Forecasts: boolean,
  type: "ft" | "rtn" | "",
  timeGranularity: ITimeGranularity,
  attributionMethodEnum: any,
) {
  const longAttributionWindow = timeGranularity === "daily" ? "infinity" : "1";
  const awDesc = makeAttributionWindowDescription(aw);
  const awDescLong = makeAttributionWindowDescription(longAttributionWindow);

  const revCols = [
    makeRev(
      am,
      longAttributionWindow,
      awDescLong,
      type,
      accountingMode,
      attributionMethodEnum,
    ),
  ];
  const roasCols = [
    makeRoas(am, longAttributionWindow, awDescLong, type, accountingMode),
  ];
  const aovCols = [
    makeAOV(am, longAttributionWindow, awDescLong, type, accountingMode),
  ];
  const ecrCols = [
    makeEcr(am, longAttributionWindow, awDescLong, type, accountingMode),
  ];
  const cacCols = [
    makeCac(am, longAttributionWindow, awDescLong, type, accountingMode),
  ];
  const txnCols = [
    makeTxns(
      am,
      longAttributionWindow,
      awDescLong,
      type,
      accountingMode,
      attributionMethodEnum,
    ),
  ];

  if (accountingMode === "accrual" && aw !== longAttributionWindow) {
    revCols.unshift(
      makeRev(am, aw, awDesc, type, accountingMode, attributionMethodEnum),
    );
    roasCols.unshift(makeRoas(am, aw, awDesc, type, accountingMode));
    ecrCols.unshift(makeEcr(am, aw, awDesc, type, accountingMode));
    cacCols.unshift(makeCac(am, aw, awDesc, type, accountingMode));
    txnCols.unshift(
      makeTxns(am, aw, awDesc, type, accountingMode, attributionMethodEnum),
    );
    aovCols.unshift(makeAOV(am, aw, awDesc, type, accountingMode));
  }

  if (
    includeV1Forecasts &&
    accountingMode === "accrual" &&
    timeGranularity !== "hourly"
  ) {
    if (aw !== "30" && aw !== "60" && aw !== "90") {
      revCols.push(makeForecastedRev(am, "30", attributionMethodEnum));
      txnCols.push(makeForecastedTxns(am, "30", attributionMethodEnum));
      roasCols.push(makeForecastedRoas(am, "30"));
    }

    if (aw !== "60" && aw !== "90") {
      revCols.push(makeForecastedRev(am, "60", attributionMethodEnum));
      txnCols.push(makeForecastedTxns(am, "60", attributionMethodEnum));
      roasCols.push(makeForecastedRoas(am, "60"));
    }

    if (aw !== "90") {
      revCols.push(makeForecastedRev(am, "90", attributionMethodEnum));
      txnCols.push(makeForecastedTxns(am, "90", attributionMethodEnum));
      roasCols.push(makeForecastedRoas(am, "90"));
    }
  }

  return {
    revCols,
    roasCols,
    ecrCols,
    cacCols,
    txnCols,
    aovCols,
  };
}

export function makeFirstTimeVsReturningRows(
  am: Exclude<string, "platform">,
  aw: IMCAttributionWindowDays,
  accountingMode: INBAccountingMode,
  timeGranularity: ITimeGranularity,
  attributionMethodEnum: any,
) {
  const rv = [SpendMetric];
  let { revCols, roasCols, ecrCols, cacCols, txnCols, aovCols } =
    makeConversionCols(
      am,
      aw,
      accountingMode,
      false,
      "ft",
      timeGranularity,
      attributionMethodEnum,
    );

  rv.push(
    ...revCols,
    ...roasCols,
    ...ecrCols,
    ...cacCols,
    ...txnCols,
    ...aovCols,
  );

  ({ revCols, roasCols, ecrCols, cacCols, txnCols, aovCols } =
    makeConversionCols(
      am,
      aw,
      accountingMode,
      false,
      "rtn",
      timeGranularity,
      attributionMethodEnum,
    ));

  rv.push(
    ...revCols,
    ...roasCols,
    ...ecrCols,
    ...cacCols,
    ...txnCols,
    ...aovCols,
  );

  return rv;
}

export function pathToDescriptor(path: string[]): string {
  // Adding root to the beginning allows prefix matches with [].
  // Adding a newline at the end prevents spurious matches with value="".
  return ["__root__", ...path, ""].join("\n");
}

export function descriptorToPath(descriptor: string): string[] {
  const path = descriptor.split("\n");
  path.pop();
  path.shift();
  return path;
}

export function getSalesPageMetricSpecs(
  user: User,
  hasV1Forecasts: boolean,
  customGoals: GetSalesReportV2_me_salesReport_customGoals[],
) {
  const allSpecs: SalesPageMetricSpec[] = [];

  for (const attributionModel of user.attributionModels) {
    for (const attributionWindow of Object.keys(MCAttributionWindowDays.enum)) {
      for (const accountingMode of Object.keys(NBAccountingMode.enum)) {
        const metrics = [
          ...makeOverviewRows(
            attributionModel.id,
            attributionWindow as IMCAttributionWindowDays,
            hasV1Forecasts,
            accountingMode as INBAccountingMode,
            customGoals,
            "daily",
            user,
          ),
          ...makeTrafficRows(
            attributionModel.id,
            attributionWindow as IMCAttributionWindowDays,
            accountingMode as INBAccountingMode,
            user,
            "daily",
          ),
          ...makeFirstTimeVsReturningRows(
            attributionModel.id,
            attributionWindow as IMCAttributionWindowDays,
            accountingMode as INBAccountingMode,
            "daily",
            user.attributionMethodEnum,
          ),
        ];

        for (const metric of metrics) {
          if (!metric.spec) continue;
          allSpecs.push(metric.spec);
        }
      }
    }
  }

  return uniqBy(allSpecs, "id");
}
