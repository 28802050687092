import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import {
  CUSTOM_ECOMMERCE_SETUP,
  GLOBAL_CONNECTABLE_POINT_OF_SALE_PLATFORMS,
} from ".";
import { StepContainer } from "../step-container";

interface PixelPlacementCardWrapperProps {
  children: JSX.Element;
  selectedPointOfSalePlatforms: string[];
}

export const PixelPlacementCardWrapper: FC<
  Partial<StepWizardChildProps> & PixelPlacementCardWrapperProps
> = ({ goToNamedStep, stepName, selectedPointOfSalePlatforms, children }) => {
  if (!stepName || !goToNamedStep) {
    return null;
  }

  const pointOfSalePlatformsToConnect = selectedPointOfSalePlatforms.filter(
    (platform) =>
      Object.keys(GLOBAL_CONNECTABLE_POINT_OF_SALE_PLATFORMS).includes(
        platform,
      ),
  );

  if (
    selectedPointOfSalePlatforms.length > pointOfSalePlatformsToConnect.length
  ) {
    pointOfSalePlatformsToConnect.push(CUSTOM_ECOMMERCE_SETUP);
  }

  const currentIndex = pointOfSalePlatformsToConnect.indexOf(stepName);

  const goBack = () => {
    if (currentIndex !== 0) {
      goToNamedStep(pointOfSalePlatformsToConnect[currentIndex - 1]);
    } else {
      goToNamedStep("point-of-sale-collection");
    }
  };

  const goForward = () => {
    if (currentIndex !== pointOfSalePlatformsToConnect.length - 1) {
      goToNamedStep(pointOfSalePlatformsToConnect[currentIndex + 1]);
    } else {
      goToNamedStep("tracking-script-tags");
    }
  };

  return (
    <StepContainer
      onMoveBackwardButtonClicked={goBack}
      onMoveForwardButtonClicked={goForward}
      onSkipLinkClicked={goForward}
    >
      {children}
    </StepContainer>
  );
};
