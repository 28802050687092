import React from "react";
import { Button, ButtonProps } from "./button";

export const SecondaryButton = ({
  className,
  children,
  onClick,
  isDisabled,
  size,
  loading,
}: ButtonProps) => {
  return (
    <Button
      className={className}
      variant="secondary"
      isDisabled={isDisabled}
      onClick={onClick}
      size={size}
      loading={loading}
    >
      {children}
    </Button>
  );
};
