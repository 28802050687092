import { usePageTitle } from "@/atoms/page-title-atom";
import { gql } from "@apollo/client";
import { PageNavigator } from "@components/page-navigator";
import { HelpQuery } from "@nb-api-graphql-generated/HelpQuery";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { NamingManual } from "../naming-manual";
import { RedirectUrls } from "../redirect-urls";
import { EducationalVideos } from "./educational-videos";
import { GenericUrlBuilder } from "./generic_url_builder";

export const GuideRoutes = () => (
  <Routes>
    <Route element={<GuideLayout />}>
      <Route path="/" element={<Navigate to="redirect-urls" replace />} />
      <Route path="educational-videos" element={<EducationalVideos />} />
      <Route path="redirect-urls" element={<RedirectUrls />} />
      <Route path="generic-url-builder" element={<GenericUrlBuilder />} />
      <Route path="naming-conventions-manual" element={<NamingManual />} />
    </Route>
  </Routes>
);

const GuideLayout = () => {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Guides");
  }, [setPageTitle]);

  const [unwatchedEducationalVideos, setUnwatchedEducationalVideos] =
    useState<boolean>(false);

  const { loading, data } = useNorthbeamQuery<HelpQuery>(HELP_QUERY);

  useEffect(() => {
    setUnwatchedEducationalVideos(
      data?.me?.educationalVideos?.filter((video) => !video.last_viewed_at)
        ?.length !== 0,
    );
  }, [data]);

  const guidesOrderedLabelRoutes = [
    { label: "Redirect URLs guide", route: "redirect-urls" },
    { label: "Generic URL builder", route: "generic-url-builder" },
    {
      label: "Naming conventions manual",
      route: "naming-conventions-manual",
    },
    {
      label: (
        <div className="inline">
          Educational videos{" "}
          {unwatchedEducationalVideos && (
            <div className="ml-2 alert-dot d-inline" />
          )}
        </div>
      ),
      route: "educational-videos",
    },
  ];

  if (loading) {
    return null;
  }

  return (
    <section className="container-xl p-4">
      <LogOnMount name="Visit Help Page" />
      <section className="row">
        <section className="col-3">
          <PageNavigator
            orderedLabelRoutes={guidesOrderedLabelRoutes}
            title="Guides"
          />
        </section>
        <section className="col-9">
          <Outlet />
        </section>
      </section>
    </section>
  );
};

export const HELP_QUERY = gql`
  query HelpQuery {
    me {
      educationalVideos
    }
  }
`;
