import { FetchResult } from "@apollo/client";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import {
  UpsertConnection,
  UpsertConnectionVariables,
} from "@nb-api-graphql-generated/UpsertConnection";
import { AccountMetadata, AccountValidationInfo } from "@north-beam/nb-common";
import { useNorthbeamMutation } from "@utils/hooks";
import { extractError } from "@utils/index";
import React from "react";
import { serializeError } from "serialize-error";
import AccountSelector, {
  AccountMetadataWithDescription,
} from "./account-selector";
import { FETCH_SANITIZED_CONNECTIONS_BY_TYPE, UPSERT_CONNECTION } from "./queries";

export enum ConnectionStatusType {
  NOT_CONNECTED,
  FETCHING_AVAILABLE_ACCOUNTS,
  SELECTING_ACCOUNT,
  DONE,
  HAS_ERROR,
  UPSERTING_ACCOUNTS,
}

interface NotConnected {
  type: ConnectionStatusType.NOT_CONNECTED;
}
interface FetchingAvailableAccounts {
  type: ConnectionStatusType.FETCHING_AVAILABLE_ACCOUNTS;
}
interface SelectingAccount {
  type: ConnectionStatusType.SELECTING_ACCOUNT;
  accountList: AccountMetadataWithDescription[];
}

interface UpsertingAccounts {
  type: ConnectionStatusType.UPSERTING_ACCOUNTS;
  accountList: AccountMetadataWithDescription[];
}
interface Done {
  type: ConnectionStatusType.DONE;
}

interface HasError {
  type: ConnectionStatusType.HAS_ERROR;
  errorMessage: string;
}

export type ConnectionStatus =
  | NotConnected
  | FetchingAvailableAccounts
  | HasError
  | SelectingAccount
  | UpsertingAccounts
  | Done;

export interface LoginComponentChildrenProps {
  setStatus: React.Dispatch<React.SetStateAction<ConnectionStatus>>;
  accounts: AccountValidationInfo[];
  upsertConnection: (
    data: any,
  ) => Promise<
    | FetchResult<UpsertConnection, Record<string, any>, Record<string, any>>
    | undefined
  >;
  isLoading: boolean;
}

export interface LoginComponentProps extends LoginComponentChildrenProps {
  integrationDetails: any;
}

export type LoginComponentWithFrontendIntegrationProps = Omit<
  LoginComponentProps,
  "upsertConnection" | "isLoading"
>;

export interface ConnectionStatusManagerProps {
  id: string | null;
  type: ConnectionType;
  accounts: AccountValidationInfo[];
  children: (props: LoginComponentChildrenProps) => React.ReactNode;
}

interface useUpsertConnectionReturn {
  upsertConnection: (
    data: any,
  ) => Promise<
    | FetchResult<UpsertConnection, Record<string, any>, Record<string, any>>
    | undefined
  >;
  isLoading: boolean;
  status: ConnectionStatus;
  setStatus: React.Dispatch<React.SetStateAction<ConnectionStatus>>;
}

function useUpsertConnection(
  id: string | null,
  type: ConnectionType,
): useUpsertConnectionReturn {
  const [upsertConnectionMutation, { loading }] = useNorthbeamMutation<
    UpsertConnection,
    UpsertConnectionVariables
  >(UPSERT_CONNECTION, {
    refetchQueries: [
      {
        query: FETCH_SANITIZED_CONNECTIONS_BY_TYPE,
        variables: { type },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [status, setStatus] = React.useState<ConnectionStatus>({
    type: ConnectionStatusType.NOT_CONNECTED,
  });

  async function upsertConnection(data: any) {
    try {
      const result = await upsertConnectionMutation({
        variables: {
          id,
          type,
          data,
        },
      });
      if (result.errors) {
        setStatus({
          type: ConnectionStatusType.HAS_ERROR,
          errorMessage: JSON.stringify(serializeError(result.errors)),
        });
      } else if (result.data?.upsertConnection?.errorMessage) {
        setStatus({
          type: ConnectionStatusType.HAS_ERROR,
          errorMessage: result.data?.upsertConnection?.errorMessage,
        });
      } else {
        setStatus({
          type: ConnectionStatusType.DONE,
        });
      }
      return result;
    } catch (e) {
      setStatus({
        type: ConnectionStatusType.HAS_ERROR,
        errorMessage: `An error occurred: ${serializeError(extractError(e))}`,
      });
    }
  }

  return { upsertConnection, isLoading: loading, status, setStatus };
}

export default function ConnectionStatusManager({
  id,
  type,
  accounts,
  children,
}: ConnectionStatusManagerProps) {
  const { upsertConnection, isLoading, status, setStatus } =
    useUpsertConnection(id, type);

  const onSave = React.useCallback(
    async (selected: AccountMetadata) => {
      return await upsertConnection(selected.data);
    },
    [upsertConnection],
  );

  switch (status.type) {
    case ConnectionStatusType.NOT_CONNECTED:
      return (
        <div
          className={
            type === ConnectionType.integration_amazon_seller ? "" : "mb-3"
          }
        >
          {children({
            accounts,
            setStatus,
            upsertConnection,
            isLoading,
          })}
        </div>
      );
    case ConnectionStatusType.FETCHING_AVAILABLE_ACCOUNTS:
      return <div className="mb-3">Please wait...</div>;
    case ConnectionStatusType.DONE:
      return <div className="mb-3">Done!</div>;
    case ConnectionStatusType.HAS_ERROR:
      return (
        <>
          <div className="alert alert-danger mb-3" role="alert">
            An error occurred: {status.errorMessage}
          </div>
          <div className="mb-3">
            {children({
              accounts,
              setStatus,
              upsertConnection,
              isLoading,
            })}
          </div>
        </>
      );
    case ConnectionStatusType.UPSERTING_ACCOUNTS:
      status.accountList.forEach(async (account) => {
        await onSave(account);
      });
      return <div className="mb-3">Done!</div>;
    default:
      return (
        <>
          <hr />
          <AccountSelector
            tag={type}
            accountList={status.accountList}
            isSaving={isLoading}
            onSave={onSave}
          />
        </>
      );
  }
}
