import React, { useEffect, useMemo, useState } from "react";

import Spinner from "@shared/spinner";
import classNames from "classnames";
import bullseyeIcon from "@assets/bullseye.svg";
import { PrimaryButton } from "@shared/buttons";
import {
  BenchmarkAttributionModelEnum,
  BenchmarkAttributionWindowEnum,
  BenchmarkPlatformEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";
import { useNorthbeamLazyQuery } from "@utils/hooks";
import { FETCH_CURRENT_BENCHMARKS } from "@gql/benchmarks";
import {
  BenchmarkSubstepWithInput,
  BenchmarkSubstepWithOptions,
} from "./benchmark-choose-and-set-substep";
import {
  BenchmarkAttributionWindowOptions,
  BenchmarkTypeOptions,
  PrettyBenchmarkAttributionModelNames,
  PrettyBenchmarkAttributionWindowNames,
  BenchmarkPlatformFriendlyNames,
} from "@/constants/benchmarking";

import { getPrettyBenchmark } from "@utils/benchmarking";

import { BenchmarkHelpFooter } from "./benchmark-help-modal";
import { useUsableBenchmarkWindowsAtom } from "@/atoms/benchmarking-atom";
import { prettyLogEvent } from "@utils/analytics";
import {
  FetchCurrentBenchmarks,
  FetchCurrentBenchmarksVariables,
} from "@nb-api-graphql-generated/FetchCurrentBenchmarks";
import { useGetBenchmarkPlatformsToUse } from "@hooks/use-platform-benchmarks";
import { useForm, useFormState } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { isEmpty, keyBy } from "lodash";

export type ChooseAndSetValidationTargets = {
  [platform in BenchmarkPlatformEnum]: number;
};

const useFetchChooseAndSetData = ({
  attributionModel,
  attributionWindow,
  benchmarkType,
}: {
  attributionModel: BenchmarkAttributionModelEnum;
  attributionWindow?: BenchmarkAttributionWindowEnum;
  benchmarkType?: BenchmarkTypeEnum;
}) => {
  const platforms = useGetBenchmarkPlatformsToUse();
  const { loadData, data } = useNorthbeamLazyQuery<
    FetchCurrentBenchmarks,
    FetchCurrentBenchmarksVariables
  >(FETCH_CURRENT_BENCHMARKS);

  useEffect(() => {
    if (attributionWindow && benchmarkType) {
      loadData({
        variables: {
          platforms: platforms,
          benchmarkType,
          attributionModel,
          attributionWindow,
        },
      });
    }
  }, [attributionModel, attributionWindow, benchmarkType, loadData, platforms]);
  return data;
};

type BenchmarkChooseAndSetProps = {
  onNext: (args: {
    benchmarkType: BenchmarkTypeEnum;
    attributionWindow: BenchmarkAttributionWindowEnum;
    targetsByPlatform: ChooseAndSetValidationTargets;
  }) => void;
  currentBenchmarkType: BenchmarkTypeEnum | null;
  currentBenchmarkAttributionWindow: BenchmarkAttributionWindowEnum | null;
  currentBenchmarkTargets?: ChooseAndSetValidationTargets;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  attributionModel: BenchmarkAttributionModelEnum;
};

export const BenchmarkChooseAndSet = ({
  onNext,
  currentBenchmarkAttributionWindow,
  currentBenchmarkTargets,
  currentBenchmarkType,
  setHasUnsavedChanges,
  attributionModel,
}: BenchmarkChooseAndSetProps) => {
  const [usableBenchmarkAttrWindows] = useUsableBenchmarkWindowsAtom();

  const [benchmarkTypeOption, setBenchmarkTypeOption] = useState<
    (typeof BenchmarkTypeOptions)[number] | null
  >(
    BenchmarkTypeOptions.find(
      (option) => option.value === currentBenchmarkType,
    ) || null,
  );
  const [attributionWindowOption, setAttributionWindowOption] = useState<
    (typeof BenchmarkAttributionWindowOptions)[number] | null
  >(
    BenchmarkAttributionWindowOptions.find(
      (option) => option.value === currentBenchmarkAttributionWindow,
    ) || null,
  );

  const platformsToUse = useGetBenchmarkPlatformsToUse();

  const validationSchema = useMemo(() => {
    const numberStringSchema = z.coerce.number().gt(0);
    const schema = platformsToUse.reduce<{
      [platform in BenchmarkPlatformEnum]: typeof numberStringSchema;
    }>(
      (acc, platform) => {
        if (!acc[platform]) {
          acc[platform] = numberStringSchema;
        }
        return acc;
      },
      {} as {
        [platform in BenchmarkPlatformEnum]: typeof numberStringSchema;
      },
    );

    return z.object({ ...schema });
  }, [platformsToUse]);

  const emptyValuesByPlatform = platformsToUse.reduce<{
    [platform in BenchmarkPlatformEnum]: string;
  }>(
    (acc, curr) => {
      if (!acc[curr]) {
        acc[curr] = "";
      }
      return acc;
    },
    {} as {
      [platform in BenchmarkPlatformEnum]: string;
    },
  );

  const {
    handleSubmit,
    control,
    register,
    reset: resetFormTargets,
  } = useForm<ChooseAndSetValidationTargets>({
    defaultValues: currentBenchmarkTargets,
    resolver: zodResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    // we register everything beforehand to ensure that they are always in even if the user never
    // navigates to the platform
    platformsToUse.forEach((platform) => {
      register(`${platform}`);
    });
  }, [register, platformsToUse]);

  const { data, loading } = useFetchChooseAndSetData({
    attributionWindow: attributionWindowOption?.value,
    benchmarkType: benchmarkTypeOption?.value,
    attributionModel,
  });

  const benchmarkSettingsByPlatform = keyBy(
    data?.me.benchmarks.currentBenchmarks || [],
    "platform",
  );

  const { errors } = useFormState({ control });
  const allSelected = Boolean(benchmarkTypeOption && attributionWindowOption);

  return (
    <div
      className={classNames(
        `relative flex-grow ${loading && "opacity-25"} flex flex-col`,
      )}
    >
      {loading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spinner size="lg" />
        </div>
      )}
      <div className="flex items-center">
        <div className="text-lg font-semibold">
          Setup your 1-day benchmark for the{" "}
          <span className="text-primary">
            {PrettyBenchmarkAttributionModelNames[
              attributionModel
            ].toLowerCase()}
          </span>{" "}
          attribution model
        </div>
        <img
          src={bullseyeIcon}
          alt={"bullseye"}
          className="ml-3 mb-[0.3rem]"
          style={{
            height: "2rem",
            width: "auto",
          }}
        />
      </div>

      <div className="font-light text-subtitle mt-2 mb-10 w-[42rem]">
        Create 1-day CAC and ROAS benchmarks for the &apos;Clicks Only&apos;
        and/or &apos;Clicks and Views&apos; attribution models based on your
        longer-term team targets.
      </div>

      <div className="flex flex-col 2xlh:h-[35rem] h-[27.5rem] overflow-auto">
        <BenchmarkSubstepWithOptions
          testId="metric-selector"
          placeholder="Select metric..."
          onChange={(val) => {
            setBenchmarkTypeOption(val);
            setHasUnsavedChanges(true);
            // casting it here because i need to put add defaults
            resetFormTargets(
              emptyValuesByPlatform as unknown as ChooseAndSetValidationTargets,
            );
          }}
          title="Select benchmark metric:"
          subHeader="A benchmark metric is a unit for measuring your performance over time"
          stepNumber={1}
          options={BenchmarkTypeOptions}
          tooltip={
            <div>
              ROAS is a strong indicator of short-term ad efficiency, whereas
              CAC tracks the ongoing cost of building your customer transaction
              base.
              <div className="">
                MER is a measure of profitability and is calculated by taking
                the total spend divided by the total revenue of the business
              </div>
            </div>
          }
          currentValue={benchmarkTypeOption}
          disable={loading}
        />
        <div className="m-[1rem] " />
        <BenchmarkSubstepWithOptions
          testId="timeframe-selector"
          placeholder="Select timeframe..."
          onChange={(val) => {
            setAttributionWindowOption(val);
            setHasUnsavedChanges(true);
            resetFormTargets(
              emptyValuesByPlatform as unknown as ChooseAndSetValidationTargets,
            );
          }}
          title="Select the time frame used to plan your target:"
          subHeader="The time frame is the period through which benchmarks will be measured. 30 days is a suitable attribution window for most use cases."
          stepNumber={2}
          options={BenchmarkAttributionWindowOptions.map((option) => {
            const isDisabled = !usableBenchmarkAttrWindows?.has(option.value);
            if (isDisabled) {
              return {
                ...option,
                label: `${option.label} - Not enough data to use`,
              };
            }
            return option;
          })}
          isOptionDisabled={(option) => {
            return Boolean(!usableBenchmarkAttrWindows?.has(option.value));
          }}
          tooltip={
            "Time frames for benchmarks should align with the time frames over which you determine and measure your wider business goals."
          }
          currentValue={attributionWindowOption}
          disable={loading}
        />
        <div className="m-[1rem]" />

        {attributionWindowOption &&
          benchmarkTypeOption &&
          platformsToUse.map((platform) => {
            return (
              <div key={platform} className="mt-[0.5rem] flex flex-row">
                <BenchmarkSubstepWithInput
                  loading={loading}
                  title={`Enter your target ${
                    BenchmarkPlatformFriendlyNames[platform]
                  } ${
                    PrettyBenchmarkAttributionWindowNames[
                      attributionWindowOption.value
                    ]
                  } ${benchmarkTypeOption.value}`}
                  control={control}
                  register={register}
                  subHeader={`Your target is the ${benchmarkTypeOption.value} goal your business needs to meet.`}
                  tooltip={`Your ${benchmarkTypeOption.value} target must be close to your historical, statistically significant ${benchmarkTypeOption.value} results`}
                  benchmarkType={benchmarkTypeOption.value}
                  stepNumber={`3 - ${BenchmarkPlatformFriendlyNames[platform]}`}
                  disabled={loading}
                  placeholder={"Benchmark target"}
                  cellRegistration={platform}
                />
                <BenchmarkCalculatedRate
                  benchmarkType={benchmarkTypeOption.value}
                  currentBenchmark={benchmarkSettingsByPlatform[platform]}
                  loading={loading}
                  attributionModel={attributionModel}
                  platform={platform}
                />
              </div>
            );
          })}
      </div>
      <BenchmarkHelpFooter className="absolute bottom-[2.5rem]" />

      <PrimaryButton
        className="btn-sm ml-2 py-2 px-3 my-2 absolute bottom-5 right-5"
        onClick={handleSubmit((validTargets) => {
          if (!benchmarkTypeOption || !attributionWindowOption) {
            return;
          }

          onNext({
            benchmarkType: benchmarkTypeOption.value,
            attributionWindow: attributionWindowOption.value,
            targetsByPlatform: validTargets,
          });

          prettyLogEvent({
            page: "Sales",
            domain: "Benchmarking - Modal",
            action: "onSubmit",
            additionalActionInfo: "choose-and-set-modal",
            data: {
              attributionModel,
              attributionWindow: attributionWindowOption.value,
              benchmark: benchmarkTypeOption.value,
              targets: validTargets,
            },
          });
        })}
        isDisabled={!allSelected || !isEmpty(errors)}
      >
        Continue
      </PrimaryButton>
    </div>
  );
};

const BenchmarkCalculatedRate = ({
  benchmarkType,
  currentBenchmark,
  loading,
  attributionModel,
  platform,
}: {
  platform: BenchmarkPlatformEnum;
  benchmarkType: BenchmarkTypeEnum;
  attributionModel: BenchmarkAttributionModelEnum;
  currentBenchmark?: FetchCurrentBenchmarks["me"]["benchmarks"]["currentBenchmarks"][number];
  loading: boolean;
}) => {
  return (
    <div className="relative ml-2 mb-1 mt-10 flex flex-col  items-center">
      <div className="w-[22rem] h-[7rem] flex flex-col bg-[#F5F7FD] rounded-2xl relative ">
        <div
          className="absolute top-[50%] left-[50%] w-max"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <div className="text-sm">
            Your current {BenchmarkPlatformFriendlyNames[platform]}{" "}
            <span className="font-bold text-primary">
              {PrettyBenchmarkAttributionModelNames[
                attributionModel
              ].toLowerCase()}
            </span>{" "}
            {benchmarkType} is:
          </div>
          <div className="text-2xl font-bold">
            {getPrettyBenchmark(
              benchmarkType,
              loading ? undefined : currentBenchmark?.benchmark,
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
