import { MetricCustomizationModal } from "@shared/metric-customization-modal";
import { ColumnConfig } from "@shared/metric-customization-modal/metric-category-builder";
import React from "react";
import { filterUnchangeableColumns } from "../../use-customize-table";

interface CustomizeTableModalProps {
  columnConfigs: ColumnConfig[];
  saveColumnConfigs: (columnConfigsCopy: ColumnConfig[]) => void;
  updateColumnOrder: (arg0: string[]) => void;
  updateHiddenColumns: (arg0: string[]) => void;
}

export const CustomizeTableModal = ({
  columnConfigs,
  saveColumnConfigs,
  updateColumnOrder,
  updateHiddenColumns,
}: CustomizeTableModalProps) => {
  const onSave = (updatedColumnConfigs: ColumnConfig[]) => {
    updateHiddenColumns(
      updatedColumnConfigs
        .filter(({ visible }) => !visible)
        .map(({ name }) => name),
    );
    updateColumnOrder(updatedColumnConfigs.map(({ name }) => name));
    saveColumnConfigs(updatedColumnConfigs);
  };

  const filteredColumnConfigs = filterUnchangeableColumns(columnConfigs);

  return (
    <MetricCustomizationModal
      title="Table columns"
      metrics={filteredColumnConfigs}
      onSave={onSave}
    />
  );
};
