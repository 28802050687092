import { ContextMenu } from "@shared/context-menu";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

const menuItemClasses =
  "flex items-center space-x-2 py-2 px-4 text-inherit no-underline font-normal hover:bg-cosmic-3";

export const NotificationBellWithMenu = ({
  numIncidents,
  numMaintenanceItems,
}: {
  numIncidents: number;
  numMaintenanceItems: number;
}) => {
  const hasAlerts = numIncidents + numMaintenanceItems > 0;

  return (
    <ContextMenu
      menuClassName="w-56"
      menuPosition="bottom-left"
      menuButton={
        <ContextMenu.Button
          className={clsx(
            "relative",
            hasAlerts && "animate__animated animate__swing animate__repeat-3",
          )}
        >
          <i className="fa-sharp fa-regular fa-bell text-cosmic+2 cursor-pointer transform transition-colors ease-in hover:bg-cosmic-3 ui-open:bg-cosmic-3 rounded-lg p-2" />
          {hasAlerts && (
            <span className="w-2 h-2 absolute bg-cosmic rounded-full top-1.5 right-1.5" />
          )}
        </ContextMenu.Button>
      }
    >
      <ContextMenu.Item
        className={menuItemClasses}
        as={Link}
        to="monitor/incidents"
      >
        <span>Incidents</span>
        <span className="flex-center rounded-md bg-cosmic py-0.5 px-1 text-xxs text-white">
          {numIncidents}
        </span>
      </ContextMenu.Item>
      <ContextMenu.Item
        className={menuItemClasses}
        as={Link}
        to="monitor/maintenance-items"
      >
        <span>Maintenance items</span>
        <span className="flex-center rounded-md bg-cosmic py-0.5 px-1 text-xxs text-white">
          {numMaintenanceItems}
        </span>
      </ContextMenu.Item>
      <ContextMenu.Item className={menuItemClasses} as={Link} to="monitor">
        Configure alerts
      </ContextMenu.Item>
    </ContextMenu>
  );
};
