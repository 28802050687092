import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";
import { CopyButton } from "./shared/copy-button";

export function Partnerize({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <ol>
        <li>
          To automatically have parameters added to creative links in the
          program, click on the <b>Generic Link</b> in the creative drop down
          menu of the program interface.
        </li>
        <li>
          Add the text below to the <b>Destination URL Parameters</b> box then
          click <b>save</b>.
        </li>
        <li>
          For more information, please check out <b>Partnerize</b> official{" "}
          <GenericExternalLink href="https://support.ascendpartner.com/s/article/Destination-URL-Parameters">
            documentation.
          </GenericExternalLink>
        </li>
        <CopyButton urlParamPairs={urlParamPairs} hideQuestionMark />
      </ol>
    </>
  );
}
