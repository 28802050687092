import { logEvent } from "@utils/analytics";
import { debounce } from "lodash";
import React, { useMemo } from "react";

interface TableSearchFilterProps {
  textFilter: string;
  setTextFilter: (arg0: string) => void;
}

export const TableSearchFilter = ({
  textFilter,
  setTextFilter,
}: TableSearchFilterProps) => {
  const debouncedFn = useMemo(
    () =>
      debounce((text: string) => {
        setTextFilter(text);
        logEvent("Used Text Filter", { text });
      }, 500),
    [setTextFilter],
  );

  const updateText = (text: string) => {
    setTextFilter(text);
    debouncedFn(text);
  };

  return (
    <div className="col-6 flex">
      <input
        placeholder="Filter rows by name..."
        className="form-control mr-2"
        value={textFilter}
        onChange={(e) => updateText(e.target.value)}
      />
      <button className="btn btn-secondary" onClick={() => updateText("")}>
        Clear&nbsp;Filter
      </button>
    </div>
  );
};
