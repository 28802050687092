import classNames from "classnames";
import React, { ReactNode } from "react";

interface WrapperProps {
  children?: React.ReactNode | React.ReactNodeArray | null;
}
interface ControlCenterWrapperProps {
  header?: React.ReactNode;
}

export function ControlCenterWrapper({
  header: header_,
  children,
}: WrapperProps & ControlCenterWrapperProps) {
  const context = useCtx();
  const width = context?.width ?? 350;
  const expanded: boolean = context?.expanded ?? true;
  const disableCollapse: boolean = context?.disableCollapse ?? true;
  const setExpanded: any = React.useMemo(
    () => context?.setExpanded ?? (() => void 0),
    [context],
  );

  const header = React.useMemo(
    () =>
      header_ ?? (
        <h5 className="d-inline-block my-0" style={{ fontWeight: 600 }}>
          Customize
        </h5>
      ),
    [header_],
  );

  const innerContent = React.useMemo(() => {
    if (expanded || disableCollapse) {
      return (
        <div className="my-3 px-4">
          <div className="row">
            <div className="col flex mt-5">
              <div className="w-100" style={{ fontWeight: 600 }}>
                {header}
              </div>
              {!disableCollapse && (
                <button
                  type="button"
                  aria-label="Collapse"
                  className="ml-3 btn btn-link text-muted d-inline-block p-0"
                  style={{
                    float: "right",
                  }}
                  onClick={() => setExpanded(false)}
                >
                  <span aria-hidden="true">
                    <i className="fas fa-chevron-left"></i>
                  </span>
                </button>
              )}
            </div>
          </div>
          {children}
        </div>
      );
    }
    return (
      <div className="my-2">
        <button
          type="button"
          aria-label="Expand"
          className="btn btn-link text-muted"
          onClick={() => setExpanded(true)}
        >
          <span aria-hidden="true">
            <i className="fas fa-sliders-h"></i>
          </span>
        </button>
      </div>
    );
  }, [header, expanded, setExpanded, children, disableCollapse]);

  return (
    <div
      className="nb-control-center border-right"
      style={{
        flex: expanded ? `0 0 ${width}px` : "0 0 2.5em",
      }}
    >
      {innerContent}
    </div>
  );
}

export function CompanionWrapper({ children }: WrapperProps) {
  return <div className="nb-control-center-companion-content">{children}</div>;
}

export function ControlWrapper(props: WrapperProps & { title?: ReactNode }) {
  const { title, children } = props;
  return (
    <div className="py-2 my-2">
      {title && <div className="control-label text-muted mb-2">{title}</div>}
      {children}
    </div>
  );
}

interface ScreenWithControlCenterProps {
  width?: number;
  disableCollapse?: boolean;
  children?: React.ReactNode | React.ReactNodeArray | null;
}

export function ScreenWithControlCenter({
  children,
  width,
  disableCollapse,
}: ScreenWithControlCenterProps) {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <Context.Provider
      value={{
        width,
        disableCollapse: !!disableCollapse,
        expanded,
        setExpanded,
      }}
    >
      <div
        className={classNames({
          "nb-has-control-center": 1,
          expanded: expanded,
        })}
      >
        {children}
      </div>
    </Context.Provider>
  );
}

const Context = React.createContext<{
  width?: number;
  expanded: boolean;
  disableCollapse: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}>(null!);

const useCtx = () => React.useContext(Context);
