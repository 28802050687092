import { gql } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { DomainCollectionQuery } from "@nb-api-graphql-generated/DomainCollectionQuery";
import {
  SetDomainIfNotTaken,
  SetDomainIfNotTakenVariables,
} from "@nb-api-graphql-generated/SetDomainIfNotTaken";
import { logEvent } from "@utils/analytics";
import { verifyDomain } from "@utils/domain-verification";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import { PROFILE_QUERY } from "@pages/settings/profile";
import { StepContainer } from "./step-container";

export const DomainCollection: FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
}) => {
  const { loading, data } = useNorthbeamQuery<DomainCollectionQuery>(
    DOMAIN_COLLECTION_QUERY,
  );

  const [setDomain, { loading: setDomainLoading }] = useNorthbeamMutation<
    SetDomainIfNotTaken,
    SetDomainIfNotTakenVariables
  >(SET_DOMAIN_IF_NOT_TAKEN, {
    refetchQueries: [
      { query: DOMAIN_COLLECTION_QUERY },
      { query: PROFILE_QUERY },
    ],
  });

  const [domainName, setDomainName] = useState<string>("");
  const [canChangeDomain, setCanChangeDomain] = useState<boolean>(false);
  const [domainError, setDomainError] = useState<string>("");

  useEffect(() => {
    setDomainName(data?.me?.domainName || "");
    setCanChangeDomain(!data?.me?.domainName);
  }, [data]);

  const onSubmitClicked = React.useCallback(async () => {
    if (!canChangeDomain) {
      nextStep && nextStep();
    }
    const error = verifyDomain(domainName);

    if (error) {
      setDomainError(error);
    } else {
      setDomainError("");
      const result = await setDomain({
        variables: {
          domainName,
        },
      });

      if (!result?.data?.setDomainIfNotTaken) {
        logEvent("DomainTaken", { domainName });
        setDomainError(
          "This domain is already taken. If you are trying to join an existing account, please reach out to your account owner.",
        );
      } else {
        nextStep && nextStep();
      }
    }
  }, [domainName, nextStep, setDomain, canChangeDomain]);

  return (
    <StepContainer
      onMoveForwardButtonClicked={onSubmitClicked}
      moveForwardButtonDisabled={!domainName || setDomainLoading}
      onMoveBackwardButtonClicked={() => previousStep && previousStep()}
    >
      <>
        {!loading && (
          <>
            <h3>Welcome to Northbeam</h3>
            <hr />
            <p>
              Let&apos;s start by collecting your top-level domain. It will will
              look something like <b>northbeam.io</b>, <b>google.com</b>, or{" "}
              <b>bbc.co.uk</b>.
            </p>
            <div className="row col-8">
              <div className="py-2">
                <div className="control-label">Domain name:</div>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">https://</div>
                  </div>
                  <input
                    type="text"
                    disabled={!canChangeDomain || loading || setDomainLoading}
                    className="form-control"
                    value={domainName}
                    onChange={(event) => {
                      setDomainName(event.target.value.toLowerCase());
                    }}
                  />
                </div>
                <p style={{ color: "red" }}>{domainError}</p>
              </div>
            </div>
          </>
        )}
      </>
    </StepContainer>
  );
};

const DOMAIN_COLLECTION_QUERY = gql`
  query DomainCollectionQuery {
    me {
      id
      domainName
    }
  }
`;

export const SET_DOMAIN_IF_NOT_TAKEN = gql`
  mutation SetDomainIfNotTaken($domainName: String!) {
    setDomainIfNotTaken(domainName: $domainName)
  }
`;
