import classNames from "classnames";
import { get } from "lodash";
import React, { forwardRef, Ref } from "react";
import ReactSelect, { Props } from "react-select";

export type OptionType<Value extends string = string> = {
  label: string;
  value: Value;
};

type ReactSelectProps = Omit<Props<OptionType, false>, "value" | "onChange">;

interface SelectProps extends ReactSelectProps {
  name?: string;
  label?: string | React.ReactElement;
  value: string | null;
  className?: string;
  onChange: (value: string | null) => void;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  options?: OptionType[];
  width?: string;
  maxWidth?: string;
  errorMessage?: string;
  errors?: any;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      name,
      label,
      className,
      value,
      onChange,
      components,
      options = [],
      isLoading = false,
      isClearable = false,
      isSearchable = false,
      isDisabled = false,
      width,
      maxWidth,
      errorMessage,
      errors,
      styles,
      ...props
    },
    ref: Ref<any>,
  ) => {
    const matchedValue = options?.find(
      (option: OptionType) => option?.value === value,
    );
    // Access the error message from react-hook-form
    const formErrorMessage = get(errors, `${name}.message`);
    const hasError = errorMessage || formErrorMessage;

    return (
      <div
        className={classNames(
          "nb-input-container",
          label && "has-label",
          errors && "has-error",
          className,
        )}
        style={{ width, maxWidth }}
      >
        {label && <label className="label">{label}</label>}

        <ReactSelect
          {...props}
          ref={ref}
          className={className}
          components={components}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              height: "32px",
              minHeight: "32px",
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              height: "32px",
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              height: "32px",
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              overflow: "unset",
            }),
            menuPortal: (baseStyles) => ({
              ...baseStyles,
              zIndex: 600,
            }),
            ...styles,
          }}
          isDisabled={isLoading || isDisabled}
          isClearable={isClearable}
          isSearchable={isSearchable}
          value={matchedValue}
          options={options}
          onChange={(option: OptionType | null) => {
            if (option) {
              const value = option?.value || null;
              onChange(value);
            }
          }}
        />

        {hasError && (
          <div className="error-message">
            {errorMessage ?? formErrorMessage}
          </div>
        )}
      </div>
    );
  },
);
