import loadingScreen from "@assets/loading-screen-no-text.gif";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { useUser } from "@components/user-context";
import { GetSalesReportFast_me_salesReportTable_rows } from "@nb-api-graphql-generated/GetSalesReportFast";
import { PartialBy } from "@north-beam/nb-common";
import { useReportBodyState } from "@pages/sales/report-body-control";
import { Table } from "@pages/sales/tab-table-section/table";
import { TableTabs } from "@pages/sales/tab-table-section/table/table-tabs";
import {
  createMetricColumns,
  createNativeTableRoot,
} from "@pages/sales/tab-table-section/table/table-utils";
import { SalesPageMetric, SalesPageMetricSpec } from "@utils/metrics";
import { differenceBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { preprocessForTable } from "../utils/table-utils";

export enum TabView {
  PLATFORM = "platform",
  CAMPAIGN = "campaign",
  ADSET = "adset",
  AD = "ad",
}

interface TabTableSectionProps {
  rows: GetSalesReportFast_me_salesReportTable_rows[];
  metricsArray: SalesPageMetric[];
  selectPlatformBreakdown: () => void;
  unselectPlatformBreakdown: () => void;
  setObjectIdsToAdLevelFilters: (
    selectedRows: string[],
    value: TabView,
  ) => void;
  salesTabTableSelectedCount: Partial<Record<TabView, number>>;
  clearLevelFilter: (level: TabView) => void;
  salesPageMetricSpecs: SalesPageMetricSpec[];
}

export const TabTableSection = ({
  rows = [],
  metricsArray,
  salesTabTableSelectedCount,
  clearLevelFilter,
  selectPlatformBreakdown,
  unselectPlatformBreakdown,
  setObjectIdsToAdLevelFilters,
  salesPageMetricSpecs,
}: PartialBy<TabTableSectionProps, "rows">) => {
  const { user } = useUser();
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const {
    breakdowns,
    dateRange,
    compareDateRange,
    granularity,
    setGranularity,
  } = useReportBodyState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetTable, setResetTable] = useState<boolean>(false);
  const [currentMetricsArray, setCurrentMetricsArray] =
    useState<SalesPageMetric[]>(metricsArray);
  const [searchValue, setSearchValue] = useState<string>("");

  const [grandTotalData] = useMemo(
    () =>
      preprocessForTable(
        rows,
        breakdownConfigs,
        breakdowns,
        dateRange,
        compareDateRange,
        searchValue,
        granularity as TabView,
      ) ?? null,
    [
      rows,
      breakdownConfigs,
      breakdowns,
      dateRange,
      compareDateRange,
      searchValue,
      granularity,
    ],
  );
  const { search } = useLocation();
  const extraExportDimensionColumns: Set<string> = useMemo(() => new Set(), []);

  useEffect(() => {
    const hasColumnsChanged =
      differenceBy(currentMetricsArray, metricsArray, "id").length > 0;
    if (hasColumnsChanged) {
      setResetTable(true);
      setTimeout(() => {
        setResetTable(false);
      }, 1000);
    }
    setCurrentMetricsArray(metricsArray);
  }, [currentMetricsArray, metricsArray]);

  const tableRoot = useMemo(
    () =>
      createNativeTableRoot(
        grandTotalData,
        extraExportDimensionColumns,
        metricsArray,
        search,
        granularity as TabView,
        user,
      ),
    [
      grandTotalData,
      metricsArray,
      search,
      extraExportDimensionColumns,
      granularity,
      user,
    ],
  );

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const clearSelectedRows = () => setSelectedRows([]);

  const metricColumns = useMemo(
    () => createMetricColumns(metricsArray),
    [metricsArray],
  );

  const changeGranularity = useCallback(
    (value: TabView, rows = selectedRows) => {
      setIsLoading(true);
      if (value === TabView.PLATFORM) {
        selectPlatformBreakdown();
      } else if (granularity === TabView.PLATFORM) {
        unselectPlatformBreakdown();
      }
      setGranularity({ value });
      // Hack that allows the loading panel to render before the main thread gets blocked
      setTimeout(() => {
        setObjectIdsToAdLevelFilters(rows, value);
        clearSelectedRows();
        setIsLoading(false);
      }, 100);
    },
    [
      granularity,
      setObjectIdsToAdLevelFilters,
      setGranularity,
      selectedRows,
      selectPlatformBreakdown,
      unselectPlatformBreakdown,
    ],
  );

  const onRowLinkClick = useCallback(
    (row: any) => {
      let value = TabView.PLATFORM;
      if (granularity === TabView.PLATFORM) {
        value = TabView.CAMPAIGN;
      } else if (granularity === TabView.CAMPAIGN) {
        value = TabView.ADSET;
      } else if (granularity === TabView.ADSET) {
        value = TabView.AD;
      }
      changeGranularity(value, [row.key]);
    },
    [granularity, changeGranularity],
  );

  if (resetTable) {
    return (
      <div className="p-4 mb-9 bg-white rounded-md elevation-1">
        <div className="flex-center w-full top-0 bg-[rgba(200,200,200,0.5)] z-10 h-[780px]">
          <img
            src={loadingScreen}
            alt="Loading screen gif"
            className="w-3/4 max-w-xl"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 mb-9 bg-white rounded-md elevation-1">
      <Table
        tableTabs={
          <TableTabs
            granularity={granularity as TabView}
            onTabClick={changeGranularity}
            selection={selectedRows}
            salesTabTableSelectedCount={salesTabTableSelectedCount}
            clearLevelFilter={clearLevelFilter}
            clearSelectedRows={clearSelectedRows}
          />
        }
        showSelectionColumn={granularity !== TabView.AD}
        hideDetailsColumn={granularity === TabView.PLATFORM}
        isPlatformView={granularity === TabView.PLATFORM}
        onTabClick={changeGranularity}
        tableSummary={tableRoot}
        rows={tableRoot.children}
        onRowLinkClick={onRowLinkClick}
        metricColumnDefs={metricColumns}
        isLoading={isLoading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        salesPageMetricSpecs={salesPageMetricSpecs}
      />
    </div>
  );
};
