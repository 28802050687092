import { usePageTitle } from "@/atoms/page-title-atom";
import { PageNavigator } from "@components/page-navigator";
import { LoadingSlide, TitleSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import { H1 } from "@components/utilities";
import {
  FetchSanitizedConnectionsByType,
  FetchSanitizedConnectionsByTypeVariables,
  FetchSanitizedConnectionsByType_me_sanitizedConnections,
} from "@nb-api-graphql-generated/FetchSanitizedConnectionsByType";
import { FetchSettingsDetails } from "@nb-api-graphql-generated/FetchSettingsDetails";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { SubscriptionOrderTags } from "@pages/subscriptions-order-tags/subscription-order-tags";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { DataImportSpreadsheets } from "../data-spreadsheets";
import { DiscountCodeMappingsContainer } from "../discount-code-mappings";
import { AccountManagerSection } from "./account-manager";
import { AdditionalManagedDomainsInput } from "./additional-managed-domains-input";
import { ApiKeys } from "./api-keys";
import { BillingCard } from "./billing";
import { Connections } from "./connections";
import { Currency } from "./currency";
import { CustomAnnotations } from "./custom-annotations";
import DnsEntry from "./dns-entry";
import { EnquireLabsIntegration } from "./enquire-labs-integration";
import { ExcludeOrdersByTagContainer } from "./excluded-orders";
import { GoogleAnalyticsParameterExclusions } from "./google-analytics-parameter-exclusions";
import { NorthbeamLabs } from "./northbeam-labs";
import { NotificationSettingsContainer } from "./notifications";
import { Profile } from "./profile";
import {
  FETCH_SANITIZED_CONNECTIONS_BY_TYPE,
  FETCH_SETTINGS_DETAILS,
} from "./queries";
import { SubscriptionInformation } from "./subscription-information";
import { TrackingScriptTags } from "./tracking-script-tags";
import { UtmGuide } from "./utm-guide";

export const SettingsRoutes = () => {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Settings");
  }, [setPageTitle]);

  const { user } = useUser();

  const { data, loading } = useNorthbeamQuery<FetchSettingsDetails>(
    FETCH_SETTINGS_DETAILS,
    {},
  );

  if (loading) {
    return <LoadingSlide />;
  }

  if (!data) {
    return (
      <TitleSlide>
        <p className="text-center">No data :(</p>
      </TitleSlide>
    );
  }
  const {
    me: { managerUsers, featureFlags, installationScripts },
  } = data;

  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route path="/" element={<Navigate to="profile" replace />} />
        <Route path="profile" element={<Profile />} />
        <Route
          path="billing"
          element={
            <>
              <H1>Billing</H1>
              <p>
                If you are managing the billing details for multiple accounts,
                please do not input your details here. Instead, reach out to{" "}
                <a href="mailto:success@northbeam.io">
                  our customer support team
                </a>
                .
              </p>
              <hr />
              <BillingCard />
              <SubscriptionInformation />
            </>
          }
        />
        <Route
          path="account-managers"
          element={
            <>
              <H1>Account managers</H1>
              <hr />
              <AccountManagerSection managerUsers={managerUsers} />
            </>
          }
        />
        <Route
          path="email-notifications"
          element={
            <>
              <H1>Email notification settings</H1>
              <hr />
              <NotificationSettingsContainer />
            </>
          }
        />
        <Route
          path="currency"
          element={
            <>
              <H1>Currency</H1>
              <hr />
              <Currency />
            </>
          }
        />
        <Route
          path="northbeam-labs"
          element={
            <>
              <H1>Northbeam Labs</H1>
              <hr />
              <NorthbeamLabs />
            </>
          }
        />
        <Route
          path="excluded-orders"
          element={<ExcludeOrdersByTagContainer />}
        />
        <Route path="data-spreadsheets" element={<DataImportSpreadsheets />} />
        {user.featureFlags.showSubscriptionOrderTags && (
          <Route
            path="subscription-order-tags"
            element={<SubscriptionOrderTags />}
          />
        )}
        <Route path="custom-annotations" element={<CustomAnnotations />} />
        <Route
          path="tracking-script-tags"
          element={
            <>
              <H1>Tracking script tags</H1>
              <p>
                Please proceed through each of the following instructions to add
                Northbeam&apos;s tracking script tags to the appropriate
                locations.
              </p>
              <hr />
              <TrackingScriptTags installationScripts={installationScripts} />
            </>
          }
        />
        <Route
          path="utm-guide"
          element={
            <>
              <H1>UTM Guide</H1>
              <UtmGuide />
            </>
          }
        />
        <Route
          path="google-analytics-parameter-exclusions"
          element={
            <>
              <H1>Google Analytics parameter exclusions</H1>
              <p>
                Please add the following UTM parameters to <b>each View</b> for
                your brand on Google Analytics.
              </p>
              <hr />
              <GoogleAnalyticsParameterExclusions />
            </>
          }
        />
        <Route
          path="dns-entry"
          element={
            <>
              <H1>Setup DNS Entry</H1>
              <p>
                Setting up a DNS entry improves our ability to track your
                customers when they return to your website after their first
                visit.
              </p>
              <hr />
              <DnsEntry />
            </>
          }
        />
        <Route
          path="connections"
          element={<Connections integrationDetails={data.integrationDetails} />}
        />
        <Route
          path="additional-managed-domains"
          element={<AdditionalManagedDomainsInput />}
        />
        <Route
          path="api-keys"
          element={
            <>
              <H1>Server-to-server API keys</H1>
              <hr />
              <ApiKeys />
            </>
          }
        />
        {featureFlags.enquireLabsIntegration && (
          <Route
            path="enquire-labs-integration"
            element={<EnquireLabsIntegration />}
          />
        )}
        {featureFlags.showDiscountCodeMappings && (
          <Route
            path="discount-code-mappings"
            element={<DiscountCodeMappingsContainer />}
          />
        )}
      </Route>
    </Routes>
  );
};

interface LabelRoute {
  label: string;
  route: string;
  adminOnly?: boolean;
}

const SettingsLayout = () => {
  const [connection, setConnection] =
    useState<FetchSanitizedConnectionsByType_me_sanitizedConnections | null>(
      null,
    );
  const { data } = useNorthbeamQuery<
    FetchSanitizedConnectionsByType,
    FetchSanitizedConnectionsByTypeVariables
  >(FETCH_SANITIZED_CONNECTIONS_BY_TYPE, {
    variables: { type: ConnectionType.integration_shopify },
  });
  const { user } = useUser();
  const { featureFlags } = user;

  useEffect(() => {
    setConnection(data?.me.sanitizedConnections[0] ?? null);
  }, [data?.me.sanitizedConnections]);

  const accountSettingsOrderedLabelRoutes: LabelRoute[] = [
    { label: "Profile", route: "profile" },
    { label: "Billing", route: "billing" },
    { label: "Account managers", route: "account-managers" },
    { label: "Email notifications", route: "email-notifications" },
    { label: "Currency", route: "currency" },
  ];

  const dataMappingOrderedLabelRoutes: LabelRoute[] = [
    {
      label: "Data Spreadsheets",
      route: "data-spreadsheets",
    },
    ...(user.featureFlags.showSubscriptionOrderTags
      ? [
          {
            label: "Subscription order tags",
            route: "subscription-order-tags",
          },
        ]
      : []),
    { label: "Exclude orders by tag", route: "excluded-orders" },
    { label: "API Keys", route: "api-keys" },
    { label: "Custom Annotations", route: "custom-annotations" },
  ];

  const onboardingOrderedLabelRoutes: LabelRoute[] = [
    { label: "Connections", route: "connections" },
    { label: "UTM guide", route: "utm-guide" },
    { label: "Tracking script tags", route: "tracking-script-tags" },
    {
      label: "Google Analytics parameter exclusions",
      route: "google-analytics-parameter-exclusions",
    },
    { label: "Setup DNS Entry", route: "dns-entry" },
    {
      label: "Additional managed domains",
      route: "additional-managed-domains",
    },
  ];

  if (
    featureFlags.enquireLabsIntegration &&
    connection &&
    connection.connectionValidation
  ) {
    dataMappingOrderedLabelRoutes.push({
      label: "Integrate Enquire Labs",
      route: "enquire-labs-integration",
    });
  }

  if (featureFlags.showDiscountCodeMappings) {
    dataMappingOrderedLabelRoutes.push({
      label: "Discount code mappings",
      route: "discount-code-mappings",
    });
  }

  if (user.isAdmin) {
    accountSettingsOrderedLabelRoutes.push({
      label: "Northbeam Labs",
      route: "northbeam-labs",
      adminOnly: user.isAdmin,
    });
  }

  return (
    <div className="p-4 row">
      <LogOnMount name="Visit Settings Page" />
      <div className="col-3">
        <PageNavigator
          orderedLabelRoutes={accountSettingsOrderedLabelRoutes}
          title="Account settings"
        />
        <br />
        <PageNavigator
          orderedLabelRoutes={dataMappingOrderedLabelRoutes}
          title="Data mapping"
        />
        <br />
        <PageNavigator
          orderedLabelRoutes={onboardingOrderedLabelRoutes}
          title="Onboarding tasks"
        />
      </div>
      <div className="col-9">
        <Outlet />
      </div>
    </div>
  );
};
