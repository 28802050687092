import React, { Ref } from "react";
import classNames from "classnames";
import { TableSelection } from "@devexpress/dx-react-grid-bootstrap4";
import { Checkbox } from "@shared/checkbox";
import { isEmptyObject } from "@utils/object";

export const TableSelectCell = ({
  selected,
  onToggle,
  row,
  tableRow,
  tableColumn,
  forwardedRef,
  ...restProps
}: TableSelection.CellProps & { forwardedRef?: Ref<any> }) => {
  const isBreakdown = isEmptyObject(row?.link);
  const isPlatformView = row?.rowMetadata?.isPlatformView;
  const isSelectable = isPlatformView || !isBreakdown;

  return (
    <td
      className={classNames("text-center align-middle")}
      ref={forwardedRef}
      {...restProps}
      onClick={() => isSelectable && onToggle()}
    >
      {isSelectable && (
        <Checkbox
          className="inline-block dx-g-bs4-cursor-pointer"
          value={selected}
        />
      )}
    </td>
  );
};
