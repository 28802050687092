import { sentenceCase } from "change-case";
import Interweave from "interweave";
import _ from "lodash";
import React from "react";
import Select, { components } from "react-select";
import DebugCodeBlock from "@components/DebugCodeBlock";
import { Bubble } from "@components/utilities";

export function TargetingSettingsBlock(props: { targetingSettings: any }) {
  const { results } = props.targetingSettings;
  const [result, setResult] = React.useState(results && results[0]);

  if (results.length === 0) {
    return (
      <div className="card">
        <div className="card-body">No targeting settings.</div>
      </div>
    );
  }

  let resultDetails = <DebugCodeBlock data={result} />;
  if (result.fb_adset_info) {
    resultDetails = (
      <FacebookTargetingSentenceLines
        targetingsentencelines={result.fb_adset_info.targetingsentencelines}
      />
    );
  } else if (result.google_campaign_info) {
    resultDetails = (
      <GoogleTargetingCriteria criteria={result.google_campaign_info} />
    );
  } else if (result.google_adgroup_info) {
    resultDetails = (
      <GoogleTargetingCriteria criteria={result.google_adgroup_info} />
    );
  }

  return (
    <div>
      <div className="mb-3">
        <Select
          options={results}
          getOptionLabel={(v: any) =>
            `<b>${v.type}</b>: ${v.name} (${v.status})`
          }
          getOptionValue={(v: any) => JSON.stringify(v.ad_key)}
          onChange={(v: any) => setResult(v)}
          value={result}
          components={{
            Option: ({ label, ...props }) => (
              <components.Option {...props} label={label}>
                <Interweave content={label} />
              </components.Option>
            ),
            SingleValue: ({ children, ...props }) => (
              <components.SingleValue {...props}>
                <Interweave content={children as any} />
              </components.SingleValue>
            ),
          }}
        />
      </div>
      {resultDetails}
    </div>
  );
}

interface GoogleTargetingCriterion {
  negative: boolean;
  key: string;
  component?: React.ReactNode;
  metadata?: any;
}

function TargetingCriterionView(props: { text: string }) {
  return (
    <Bubble color="#434343" title={props.text} borderRadius="0.25rem">
      {props.text}
    </Bubble>
  );
}

function GoogleTargetingCriteria(props: { criteria: any[] }) {
  const { criteria } = props;
  const criterionNameToValues = React.useMemo(() => {
    const map: Record<string, GoogleTargetingCriterion[]> = {};
    for (const criterion of criteria) {
      const { type, negative, status } = criterion;
      if (status !== "ENABLED") {
        continue;
      }

      if (!map[type]) {
        map[type] = [];
      }

      if (type === "LOCATION") {
        const name =
          criterion.location?._nb?.geo_target_constant?.name ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "DEVICE") {
        const name = sentenceCase(criterion.device?.type ?? "Unknown");
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "PARENTAL_STATUS") {
        const name = sentenceCase(criterion.parentalStatus?.type ?? "Unknown");
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "LANGUAGE") {
        const name =
          criterion.language?._nb?.language_constant?.name ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "USER_LIST") {
        const name = criterion.userList?._nb?.user_list?.name ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "USER_INTEREST") {
        const name =
          criterion.userInterest?._nb?.user_interest?.name ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "CONTENT_LABEL") {
        const name = sentenceCase(criterion.contentLabel?.type ?? "Unknown");
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "WEBPAGE") {
        const name = criterion.webpage?.criterionName ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "PLACEMENT") {
        const name = criterion.placement?.url ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "MOBILE_APP_CATEGORY") {
        const name =
          criterion.mobileAppCategory?._nb?.mobile_app_category_constant
            ?.name ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "MOBILE_APPLICATION") {
        const name = criterion.mobileApplication?.name ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "YOUTUBE_CHANNEL") {
        const name = criterion.youtubeChannel?.channelId ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "CUSTOM_INTENT") {
        const name =
          criterion.customIntent?._nb?.custom_intent?.name ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "INCOME_RANGE") {
        const name = criterion.incomeRange?.type ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "AGE_RANGE") {
        const name = criterion.ageRange?.type ?? "Unknown";
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "GENDER") {
        const name = sentenceCase(criterion.gender?.type ?? "Unknown");
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "AD_SCHEDULE") {
        const adSchedule = criterion.adSchedule;
        let name = "Unknown";
        if (adSchedule) {
          const { endHour, startHour, dayOfWeek, endMinute, startMinute } =
            adSchedule;
          const endMinuteDisplay =
            (
              {
                ZERO: "00",
                FIFTEEN: "15",
                THIRTY: "30",
                FORTY_FIVE: "45",
              } as any
            )[endMinute] ?? "UNKNOWN";
          const startMinuteDisplay =
            (
              {
                ZERO: "00",
                FIFTEEN: "15",
                THIRTY: "30",
                FORTY_FIVE: "45",
              } as any
            )[startMinute] ?? "UNKNOWN";
          name = `${sentenceCase(
            dayOfWeek,
          )} ${startHour}:${startMinuteDisplay} - ${endHour}:${endMinuteDisplay}`;
        }
        map[type].push({
          negative,
          key: name,
          component: <TargetingCriterionView text={name} />,
        });
      } else if (type === "KEYWORD") {
        const { matchType } = criterion.keyword;
        let { text } = criterion.keyword;
        if (matchType === "PHRASE") {
          text = `"${text}"`;
        } else if (matchType === "EXACT") {
          text = `[${text}]`;
        }

        map[type].push({
          negative,
          key: text,
          component: <TargetingCriterionView text={text} />,
        });
        // } else if (type === "PLACEMENT") {
        //   map[type].push({ negative, name: criterion.placement.url });
      } else {
        map[type].push({
          negative,
          key: JSON.stringify(criterion),
          metadata: criterion,
        });
      }
    }

    const rv: {
      type: string;
      values: GoogleTargetingCriterion[];
    }[] = _.chain(map)
      .toPairs()
      .map(([type, values]) => ({ type, values }))
      .value();
    return rv;
  }, [criteria]);

  return (
    <div>
      {criterionNameToValues.map(({ type, values }) => {
        const hasMetadata = values.some((v) => !!v.metadata);
        if (hasMetadata) {
          return (
            <>
              <div>
                <strong>{sentenceCase(type)}</strong>
              </div>
              <DebugCodeBlock data={values.map((v) => v.metadata)} />
            </>
          );
        }

        const positive = values.filter((v) => !v.negative);
        const negative = values.filter((v) => v.negative);

        return (
          <div key={type}>
            <strong>{sentenceCase(type)}</strong>:&nbsp;
            <ul>
              {positive.length > 0 && (
                <li>
                  Positive:
                  {positive.map((value) => (
                    <React.Fragment key={value.key}>
                      {value.component}
                    </React.Fragment>
                  ))}
                </li>
              )}
              {negative.length > 0 && (
                <li>
                  Negative:
                  {negative.map((value, i) => (
                    <React.Fragment key={value.key}>
                      {value.component}
                    </React.Fragment>
                  ))}
                </li>
              )}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

function FacebookTargetingSentenceLines(props: {
  targetingsentencelines: { content: string; children: string[] }[];
}) {
  const tsl = props.targetingsentencelines;
  return (
    <React.Fragment>
      {tsl.map(({ content, children }) => (
        <div key={content}>
          <strong>{content}</strong>
          <ul>
            {children.map((v) => (
              <li key={v}>{v}</li>
            ))}
          </ul>
        </div>
      ))}
    </React.Fragment>
  );
}
