import { gql } from "@apollo/client";

export const EXPORT_ORDER_DATA = gql`
  mutation ExportedOrderData(
    $name: String!
    $filterOptions: OrdersFilterOptionsInput!
  ) {
    exportedOrderData(name: $name, filterOptions: $filterOptions) {
      successMessage
    }
  }
`;

export const FETCH_ORDER_SUMMARY_GRAPH = gql`
  query FetchOrderSummaryGraph(
    $dateRange: DateRangeInput!
    $granularity: String!
    $filterOptions: OrdersGraphFilterOptionsInput
  ) {
    me {
      orderSummaryGraph(
        dateRange: $dateRange
        granularity: $granularity
        filterOptions: $filterOptions
      ) {
        data {
          orderRevenue
          orderCount
          datetime
        }
      }
    }
  }
`;

export const FETCH_ORDER_SUMMARY_GRAPH_KPI = gql`
  query FetchOrderSummaryGraphKPI(
    $dateRange: DateRangeInput!
    $comparedDateRange: DateRangeInput!
    $filterOptions: OrdersGraphFilterOptionsInput
  ) {
    me {
      orderSummaryGraphKPI(
        dateRange: $dateRange
        comparedDateRange: $comparedDateRange
        filterOptions: $filterOptions
      ) {
        currentKPIs {
          orderRevenue
          orderCount
        }
        comparisonKPIs {
          orderRevenue
          orderCount
        }
      }
    }
  }
`;

export const FETCH_ORDER_SUMMARY = gql`
  query FetchOrderSummary(
    $filterOptions: OrdersFilterOptionsInput!
    $sorting: OrdersSortingInput
    $offset: Int
    $limit: Int
  ) {
    me {
      orders(
        filterOptions: $filterOptions
        sorting: $sorting
        offset: $offset
        limit: $limit
      ) {
        data {
          conversionEventId
          occurredAt
          orderType
          orderNumber
          revenueInDollars
          attributed
          orderTags
          customerTags
          discountCodes
          customerEmail
          refundAmountInDollars
          numberOfTouchpoints
          subscriptionType
          products {
            title
            quantity
          }
        }
        totalCount
      }
    }
  }
`;

export const FETCH_ORDER_TAG_OPTIONS = gql`
  query FetchOrderTagOptions($dateRange: DateRangeInput!) {
    me {
      orderTags(dateRange: $dateRange)
    }
  }
`;
export const FETCH_CUSTOMER_TAG_OPTIONS = gql`
  query FetchCustomerTagOptions($dateRange: DateRangeInput!) {
    me {
      customerTags(dateRange: $dateRange)
    }
  }
`;
export const FETCH_DISCOUNT_CODES_OPTIONS = gql`
  query FetchOrderDiscountCodeOptions($dateRange: DateRangeInput!) {
    me {
      discountCodes(dateRange: $dateRange)
    }
  }
`;
export const FETCH_ORDER_PLATFORMS_AND_ECOMMERCE_OPTIONS = gql`
  query FetchOrderPlatformAndEcommerceOptions {
    me {
      adPlatforms
      ecommercePlatforms
    }
  }
`;
export const FETCH_ORDER_TYPE_OPTIONS = gql`
  query FetchOrderTypeOptions($dateRange: DateRangeInput!) {
    me {
      orderTypes(dateRange: $dateRange)
    }
  }
`;
export const FETCH_ORDER_PRODUCT_OPTIONS = gql`
  query FetchOrderProductOptions($dateRange: DateRangeInput!) {
    me {
      products(dateRange: $dateRange)
    }
  }
`;

export const FETCH_ORDER = gql`
  query FetchOrder($orderNumber: String!, $showPriorNonConversions: Boolean) {
    me {
      order(
        orderNumber: $orderNumber
        showPriorNonConversions: $showPriorNonConversions
      ) {
        conversionEventId
        occurredAt
        orderType
        orderNumber
        customerEmail
        customerFirstName
        products {
          title
          priceInDollars
          quantity
        }
        subscriptionType
        discountCodes
        revenueInDollars
        refundAmountInDollars
        priorNonConversions
        shippingAddress1
        shippingAddress2
        shippingCity
        shippingProvince
        shippingProvinceCode
        shippingCountry
        shippingZip
        customerPhone
        priorNonConversionsKeyNameMapping {
          campaignMap
          adsetMap
          adMap
        }
      }
    }
  }
`;

export const FETCH_SANITIZED_CONNECTIONS_BY_TYPE = gql`
  query FetchSanitizedConnectionsByType($type: ConnectionType) {
    me {
      id
      sanitizedConnections(type: $type) {
        id
        clientId
        type
        data
        connectionValidation
        allProfiles
      }
    }
  }
`;

export const UPSERT_CONNECTION = gql`
  mutation UpsertConnection(
    $id: ID
    $type: ConnectionType!
    $data: JSONObject!
  ) {
    upsertConnection(data: $data, type: $type, id: $id) {
      errorMessage
      connection {
        id
        clientId
        type
        data
        connectionValidation
      }
    }
  }
`;

export const DELETE_CONNECTION = gql`
  mutation DeleteConnection($id: ID!, $type: ConnectionType!) {
    deleteConnection(id: $id, type: $type) {
      errorMessage
      connection {
        id
        clientId
        type
        data
        connectionValidation
      }
    }
  }
`;

export const ADD_NEW_STRIPE_PAYMENT_METHOD = gql`
  mutation AddNewStripePaymentMethod($paymentMethodId: ID!) {
    addNewStripePaymentMethod(paymentMethodId: $paymentMethodId)
  }
`;

export const ATTEMPT_TO_ADD_ACCOUNT_MANAGER = gql`
  mutation AttemptToAddAccountManager($email: String!) {
    attemptToAddAccountManager(email: $email)
  }
`;

export const ATTEMPT_TO_REMOVE_ACCOUNT_MANAGER = gql`
  mutation AttemptToRemoveAccountManager($managerId: String!) {
    attemptToRemoveAccountManager(managerId: $managerId)
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation UpdateUserDetails(
    $companyName: String!
    $firstName: String!
    $lastName: String!
    $domainName: String!
    $timezone: String!
    $country: String!
    $region: String!
    $postalCode: String!
    $isSubscriptionService: Boolean!
  ) {
    updateUserDetails(
      companyName: $companyName
      domainName: $domainName
      firstName: $firstName
      lastName: $lastName
      timezone: $timezone
      country: $country
      region: $region
      postalCode: $postalCode
      isSubscriptionService: $isSubscriptionService
    ) {
      domainName
    }
  }
`;

export const FETCH_SETTINGS_DETAILS = gql`
  query FetchSettingsDetails {
    me {
      id
      installationScripts
      featureFlags
      managerUsers {
        id
        email
      }
    }
    integrationDetails
  }
`;

export const FETCH_HISTORICAL_ENQUIRE_DATA = gql`
  query FetchHistoricalEnquireData {
    me {
      historicalEnquireDataFileNames
    }
  }
`;

export const UPDATE_ENQUIRE_REALTIME_DATA_SHEET_URL = gql`
  mutation UpdateEnquireRealtimeDataSheetUrl(
    $enquireRealtimeDataSheetUrl: String!
  ) {
    updateEnquireRealtimeDataSheetUrl(
      enquireRealtimeDataSheetUrl: $enquireRealtimeDataSheetUrl
    ) {
      enquireRealtimeDataSheetUrl
    }
  }
`;

export const SET_ENQUIRE_ATTRIBUTION_QUESTION_IDS = gql`
  mutation SetEnquireAttributionQuestionIds(
    $attributionQuestionIds: [String!]!
    $withReplacement: Boolean!
  ) {
    setEnquireAttributionQuestionIds(
      attributionQuestionIds: $attributionQuestionIds
      withReplacement: $withReplacement
    )
  }
`;

export const FETCH_ATTRIBUTION_QUESTION_IDS = gql`
  query FetchAttributionQuestionIds {
    me {
      attributionQuestionIds
    }
  }
`;
