import { TableColumnWidthInfo } from "@devexpress/dx-react-grid";
import {
  ColumnConfig,
  MetricConfig,
} from "@shared/metric-customization-modal/metric-category-builder";
import { cloneDeep, find, isEqual, pick } from "lodash";
import { useEffect, useMemo, useReducer } from "react";

import { useSalesViews } from "../sales-views-context";
import {
  adDetailsColumnDef,
  AD_DETAILS_COLUMN_NAME,
  dimensionColumnDef,
  DIMENSION_COLUMN_NAME,
  extraDetailsColumnDef,
  EXTRA_DETAILS_COLUMN_NAME,
} from "./fixed-column-configs";
import { ColumnDef } from "./table/table-utils";

type Action =
  | {
      type: "setColumnConfigs";
      columnConfigs: ColumnConfig[];
    }
  | { type: "updateHiddenColumns"; hiddenColumnNames: string[] }
  | { type: "updateColumnOrder"; columnOrder: string[] }
  | {
      type: "updateColumnWidth";
      columnWidths: TableColumnWidthInfo[];
    };

const getColumnOrder = (columnOrder: string[]) => {
  const fixedCols = [DIMENSION_COLUMN_NAME, AD_DETAILS_COLUMN_NAME];
  columnOrder = columnOrder.filter((col) => !fixedCols.includes(col));
  // Needs to go after the dimension column
  return [...fixedCols, ...columnOrder];
};

export const toSaveableColumnConfig = (
  columnConfig: ColumnConfig | ColumnDef,
): ColumnConfig => pick(columnConfig, ["name", "width", "visible"]);

const mergeColumns = (
  columnSet1: (ColumnConfig | ColumnDef)[],
  columnSet2: (ColumnConfig | ColumnDef)[],
) => {
  const mergedColumns = columnSet1.map((column1) => {
    const column2 = columnSet2.find(({ name }) => name === column1.name);

    if (column2) {
      return {
        ...column1,
        ...column2,
      };
    }
    return column1;
  });

  mergedColumns.sort((a, b) => {
    const aIndex = columnSet2.findIndex(({ name }) => name === a.name) ?? 0;
    const bIndex = columnSet2.findIndex(({ name }) => name === b.name) ?? 0;
    return aIndex - bIndex;
  });

  return mergedColumns;
};

export const filterUnchangeableColumns = <T extends MetricConfig>(
  columnConfigs: T[],
) =>
  columnConfigs.filter(
    (columnConfig) =>
      ![
        DIMENSION_COLUMN_NAME,
        AD_DETAILS_COLUMN_NAME,
        EXTRA_DETAILS_COLUMN_NAME,
      ].includes(columnConfig.name),
  );

const checkHasColumnConfigsChanged = (
  currentColumnConfigs: ColumnConfig[],
  compareColumnConfigs: ColumnConfig[],
) => {
  const hasOrderChanged = !isEqual(
    currentColumnConfigs.map(({ name }) => name),
    compareColumnConfigs.map(({ name }) => name),
  );

  const hasAnyWidthChanged = currentColumnConfigs.some(({ name, width }) => {
    const compareColumn = compareColumnConfigs.find((c) => c.name === name);
    return compareColumn?.width !== width;
  });

  const hasAnyVisibilityChanged = currentColumnConfigs.some(
    ({ name, visible }) => {
      const compareColumn = compareColumnConfigs.find((c) => c.name === name);
      return compareColumn?.visible !== visible;
    },
  );

  return hasOrderChanged || hasAnyWidthChanged || hasAnyVisibilityChanged;
};

const reducer = (state: ColumnConfig[], action: Action) => {
  let newState = cloneDeep(state);
  switch (action.type) {
    case "setColumnConfigs": {
      newState = action.columnConfigs;
      break;
    }
    case "updateHiddenColumns": {
      newState.forEach((column) => {
        column.visible = true;
      });
      newState.push(extraDetailsColumnDef);
      action.hiddenColumnNames.forEach((columnName) => {
        const columnDef = newState.find((c) => c.name === columnName);
        if (columnDef) {
          columnDef.visible = false;
        }
      });
      break;
    }
    case "updateColumnWidth": {
      action.columnWidths.forEach(({ columnName, width }) => {
        const columnDef = newState.find((c) => c.name === columnName);
        if (columnDef && columnName !== AD_DETAILS_COLUMN_NAME) {
          columnDef.width = Number(width);
        }
      });
      break;
    }
    case "updateColumnOrder": {
      const newColumnOrder: ColumnConfig[] = [dimensionColumnDef];
      action.columnOrder.forEach((columnName) => {
        const columnDef = newState.find((c) => c.name === columnName);
        if (columnDef) {
          newColumnOrder.push(columnDef);
        }
      });
      newState = newColumnOrder;
      break;
    }
    default:
      throw new Error();
  }
  return newState;
};

export const useCustomizeTable = (
  metricColumnDefs: ColumnDef[],
  {
    hideDetailsColumn,
    dimensionColumnOptions,
  }: {
    hideDetailsColumn: boolean;
    dimensionColumnOptions: {
      onRowLinkClick: (row: any) => void;
      isPlatformView: boolean;
      onBreakdownClick: (row: any) => void;
    };
  },
) => {
  const [currentColumnConfigs, dispatch] = useReducer(reducer, []);
  const { currentSalesViewEdit, saveSalesViewsColumnConfigurations } =
    useSalesViews();

  const savedColumnConfigurations = useMemo(
    () => currentSalesViewEdit?.data?.columnConfigurations ?? [],
    [currentSalesViewEdit?.data?.columnConfigurations],
  );

  const columnConfigs = useMemo(() => {
    const dimensionColumn = Object.assign(dimensionColumnDef, {
      options: dimensionColumnOptions,
    });
    const currentDimensionColumn = find(currentColumnConfigs, {
      name: DIMENSION_COLUMN_NAME,
    });
    if (currentDimensionColumn) {
      dimensionColumn.width = currentDimensionColumn.width;
    }

    const reconciledColumnConfigs = mergeColumns(
      [
        dimensionColumn,
        adDetailsColumnDef,
        ...filterUnchangeableColumns(currentColumnConfigs),
        extraDetailsColumnDef,
      ],
      savedColumnConfigurations,
    );
    return reconciledColumnConfigs;
  }, [savedColumnConfigurations, currentColumnConfigs, dimensionColumnOptions]);

  useEffect(() => {
    const reconciledColumnConfigs = mergeColumns(
      [dimensionColumnDef, ...metricColumnDefs],
      savedColumnConfigurations,
    );

    const hasColumnConfigsChanged = checkHasColumnConfigsChanged(
      currentColumnConfigs,
      reconciledColumnConfigs,
    );

    if (hasColumnConfigsChanged) {
      dispatch({
        type: "setColumnConfigs",
        columnConfigs: reconciledColumnConfigs,
      });
    }
  }, [
    saveSalesViewsColumnConfigurations,
    currentColumnConfigs,
    savedColumnConfigurations,
    metricColumnDefs,
  ]);

  const saveColumnConfigs = (updatedColumnConfigs: ColumnConfig[]) => {
    const savedColumnConfigurationsCopy = updatedColumnConfigs
      .map(toSaveableColumnConfig)
      .filter(
        ({ name }) =>
          ![AD_DETAILS_COLUMN_NAME, EXTRA_DETAILS_COLUMN_NAME].includes(name),
      );

    saveSalesViewsColumnConfigurations(savedColumnConfigurationsCopy);
  };

  const hiddenColumns = useMemo(
    () =>
      columnConfigs.filter(({ visible }) => !visible).map(({ name }) => name),
    [columnConfigs],
  );
  const updateHiddenColumns = (hiddenColumnNames: string[]) =>
    dispatch({ type: "updateHiddenColumns", hiddenColumnNames });

  const columnOrder = useMemo(
    () => columnConfigs.map(({ name }) => name),
    [columnConfigs],
  );
  const updateColumnOrder = (columnOrder: string[]) => {
    const newOrderColumnConfigs: string[] = [];
    columnOrder.forEach((columnName) => {
      const columnConfig = savedColumnConfigurations.find(
        ({ name }: ColumnConfig) => name === columnName,
      );
      if (columnConfig) {
        newOrderColumnConfigs.push(columnConfig.name);
      }
    });

    // Removed and added back at the end in the order they are in the table
    const savedColumnConfigurationsCopy = [...savedColumnConfigurations].sort(
      (a, b) => {
        const aIndex =
          newOrderColumnConfigs.findIndex(
            (columnName) => columnName === a.name,
          ) ?? 0;
        const bIndex =
          newOrderColumnConfigs.findIndex(
            (columnName) => columnName === b.name,
          ) ?? 0;
        return aIndex - bIndex;
      },
    );
    saveSalesViewsColumnConfigurations(
      savedColumnConfigurationsCopy.map(toSaveableColumnConfig),
    );

    dispatch({ type: "updateColumnOrder", columnOrder });
  };

  const columnWidths = useMemo<TableColumnWidthInfo[]>(
    () =>
      columnConfigs.map(({ name, width }) => {
        if (name === AD_DETAILS_COLUMN_NAME) {
          return { columnName: name, width, minWidth: width, maxWidth: width };
        }
        return { columnName: name, width };
      }),
    [columnConfigs],
  );
  const updateColumnWidth = (columnWidths: TableColumnWidthInfo[]) => {
    const updatedColumnConfigurations: ColumnConfig[] =
      savedColumnConfigurations.map((config: ColumnConfig) => {
        const columnWidth = columnWidths.find(
          ({ columnName }) => columnName === config.name,
        );
        if (columnWidth) {
          return { ...config, width: Number(columnWidth.width) };
        }
        return config;
      });
    saveSalesViewsColumnConfigurations(updatedColumnConfigurations);
    dispatch({ type: "updateColumnWidth", columnWidths });
  };

  const initialSortByColumn =
    filterUnchangeableColumns(columnConfigs).filter(
      ({ visible }) => visible,
    )?.[0]?.name ?? DIMENSION_COLUMN_NAME;

  return {
    columnConfigs,

    hiddenColumns: hideDetailsColumn
      ? hiddenColumns.concat(AD_DETAILS_COLUMN_NAME)
      : hiddenColumns,
    updateHiddenColumns,

    // The details column needs to be reinserted as the first column,
    // because it doesn't get saved in the column config
    columnOrder: getColumnOrder(columnOrder),
    updateColumnOrder,

    columnWidths,
    updateColumnWidth,
    saveColumnConfigs,

    initialSortByColumn,
  };
};
