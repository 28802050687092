import { gql } from "@apollo/client";
import { H1 } from "@components/utilities";
import { FetchSettingsDetails } from "@nb-api-graphql-generated/FetchSettingsDetails";
import { GetOrCreateStripeCustomerId } from "@nb-api-graphql-generated/GetOrCreateStripeCustomerId";
import { AccountManagerSection } from "@pages/settings/account-manager";
import { DataWrapper } from "@pages/settings/data-wrapper";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { FC, useState } from "react";
import Modal from "react-modal";
import { StepWizardChildProps } from "react-step-wizard";
import { StepContainer } from "./step-container";

export const IntroductoryVideo: FC<Partial<StepWizardChildProps>> = ({
  nextStep,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // This query will create a stripe profile if there isn't one
  // Meaning new users who enter this flow will have that profile created.
  // We need the profile at this stage in case they want to setup ACH payments
  useNorthbeamQuery<GetOrCreateStripeCustomerId>(
    GET_OR_CREATE_STRIPE_CUSTOMER_ID_QUERY,
  );

  return (
    <DataWrapper>
      {(data: FetchSettingsDetails) => {
        return (
          <StepContainer
            onMoveForwardButtonClicked={() => nextStep && nextStep()}
            moveForwardButtonCopy="Get started"
          >
            <h3 className="mb-3">Welcome to Northbeam </h3>
            {/* video will not play when in chrome incognito due to CORS (untrusted url) */}
            <iframe
              src="https://drive.google.com/file/d/1fe6tVCt_AcbaBTe37PGdTnzIskGVIsHC/preview"
              width="833"
              height="469"
              allow="autoplay"
              frameBorder="0"
              className="mb-3"
              title="Welcome to Northbeam video"
            />
            <p>
              <b>Here&apos;s what you&apos;ll need:</b>
            </p>
            <ol>
              <li className="mb-2">Payment information (credit card)</li>
              <li className="mb-2">
                Login credentials for connected platforms (i.e. Facebook,
                Klaviyo, TikTok, etc.)
              </li>
              <li className="mb-2">
                Access to your domain provider (i.e. GoDaddy, Google Domains,
                BlueHost, Squarespace, etc.)
              </li>
              <li className="mb-2">
                If you have Shopify: Admin access to Shopify site including head
                tags and other scripts
              </li>
              <li className="mb-2">
                If you do not have Shopify: An individual who can help install
                our pixel and server API wherever your data is tracked (i.e.
                WooCommerce, BigCommerce, Custom database)
              </li>
            </ol>
            <button
              tabIndex={70}
              className="btn btn-outline-info ml-4 mt-4"
              onClick={() => setModalOpen(true)}
            >
              Add other users to help with onboarding
            </button>
            <Modal
              isOpen={modalOpen}
              style={{
                content: {
                  position: "fixed",
                  top: "25vh",
                  left: "25vw",
                  width: "70vw",
                  height: "60vh",
                },
              }}
              shouldCloseOnEsc
            >
              <H1>Account managers</H1>
              <AccountManagerSection managerUsers={data.me.managerUsers} />

              <button
                tabIndex={70}
                className="btn btn-outline-primary mb-0 mt-3"
                onClick={() => setModalOpen(false)}
              >
                Save & Exit
              </button>
            </Modal>
          </StepContainer>
        );
      }}
    </DataWrapper>
  );
};

export const GET_OR_CREATE_STRIPE_CUSTOMER_ID_QUERY = gql`
  query GetOrCreateStripeCustomerId {
    me {
      getOrCreateStripeCustomerId
    }
  }
`;
