import React, { useEffect, useRef, useState } from "react";

import { ScreenWithControlCenter } from "@components/reports/control-center-utilities";
import { CreativeAnalyticsProvider } from "@features/creative-analytics/atoms";
import { CreativeAnalyticsCardSleeve } from "@features/creative-analytics/components/ca-card-sleeve";
import { CreativeAnalyticsCharts } from "@features/creative-analytics/components/ca-charts";
import { CreativeAnalyticsControl } from "@features/creative-analytics/components/ca-control";
import { CreativeAnalyticsTypeBar } from "@features/creative-analytics/components/ca-creative-type-bar";
import { CreativeAnalyticsTopBar } from "@features/creative-analytics/components/ca-top-bar";

import { useFillHeight } from "@features/creative-analytics/hooks/use-fill-height";
import classNames from "classnames";
import { LoadingSlide } from "@components/title-slide-view";

const useLoading = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1);
  });
  return loading;
};

export function CreativeAnalyticsPage() {
  const loading = useLoading();
  return loading ? <LoadingSlide /> : <CreativeAnalyticsPageInner />;
}

function CreativeAnalyticsPageInner() {
  const ref = useRef<HTMLDivElement>(null);

  const availableHeight = useFillHeight(ref);

  return (
    <CreativeAnalyticsProvider>
      <ScreenWithControlCenter>
        <div className="grow relative">
          <CreativeAnalyticsControl />
          <div
            style={{
              height: availableHeight - 10,
            }}
            className={classNames(
              "bg-white rounded-md mx-4 elevation-1 mt-5 relative",
            )}
            ref={ref}
          >
            <CreativeAnalyticsTopBar />
            <div className="border-t-[1.5px] border-#CCCCCC" />
            <CreativeAnalyticsTypeBar />
            <CreativeAnalyticsCardSleeve />
          </div>
          <CreativeAnalyticsCharts />
        </div>
      </ScreenWithControlCenter>
    </CreativeAnalyticsProvider>
  );
}
