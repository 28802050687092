import React from "react";

export default function TextAd(props: {
  headline: string;
  description1: string;
  description2?: string;
  displayUrl: string;
}) {
  const { headline, description1, description2, displayUrl } = props;
  return (
    <div className="adwords-text-ad">
      <button className="btn btn-link headline">{headline}</button>
      <div className="url-line">
        <span className="ad-tag">Ad</span>
        <span className="display-url">{displayUrl}</span>{" "}
        <span className="fa-regular fa-caret-down"></span>
      </div>
      <div>
        <span className="description">{description1}</span>{" "}
        {description2 && <span className="description">{description2}</span>}
      </div>
    </div>
  );
}
