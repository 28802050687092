import { User } from "@components/user-context";
import { motion } from "framer-motion";
import { prefs } from "@utils/client-side-preferences";
import clsx from "clsx";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { UserItem } from "./top-bar";

export const ImpersonateClientSelect = ({
  user,
  usersList,
}: {
  user: User;
  usersList: UserItem[];
}) => {
  const navigate = useNavigate();
  const options = usersList.map((item) => {
    return {
      value: item.id,
      label: item.companyName ?? item.email,
    };
  });
  const customClassNames = {
    menu: () => "z-[99] bg-white",
    control: ({ isFocused }: any) =>
      clsx(
        "border border-white hover:cursor-pointer hover:bg-cosmic-3 shadow-none w-44 rounded-lg",
        isFocused ? "bg-cosmic-3 text-cosmic+1" : "bg-white",
      ),
    singleValue: ({ isFocused }: any) =>
      clsx("text-sm", isFocused ? "text-cosmic+1" : "text-cosmic"),
    indicatorSeparator: () => "hidden",
    option: ({ isFocused }: any) =>
      clsx(
        "text-interstellar hover:cursor-pointer text-sm",
        isFocused ? "bg-cosmic-3" : "bg-white",
      ),
    input: ({ isFocused }: any) =>
      clsx("text-sm", isFocused ? "text-cosmic+1" : "text-cosmic"),
  };

  const label = () => {
    if (prefs.isDemoMode()) {
      return "Widgets Co";
    }
    if (user.impersonatedUser) {
      return user.impersonatedUser.companyName ?? user.impersonatedUser.email;
    }
    return user.companyName ?? user.email;
  };

  const location = useLocation();

  return (
    <Select
      menuPosition="fixed"
      options={options}
      classNames={customClassNames}
      defaultValue={{
        value: user.impersonatedUser
          ? user.impersonatedUser.id
          : user.displayUserId,
        label: label(),
      }}
      components={{
        DropdownIndicator: ({ isFocused }) => (
          <motion.i
            initial={false}
            animate={{ rotate: isFocused ? 0 : 180 }}
            transition={{ duration: 0.2 }}
            className={clsx(
              "px-2 text-sm fa-sharp fa-regular fa-chevron-up",
              isFocused ? "up text-cosmic+1" : "down text-cosmic",
            )}
          ></motion.i>
        ),
      }}
      onChange={(value, action) => {
        if (!value || !(value as { value: string; label: string }).value) {
          return;
        }
        if (action.action === "select-option") {
          const pathname = location.pathname.slice(1);
          const excludingUserIdPath = pathname.substring(
            pathname.indexOf("/") + 1,
          );
          navigate(
            `/${(value as { value: string; label: string }).value}${
              excludingUserIdPath ? `/${excludingUserIdPath}` : ""
            }`,
          );
          window.location.reload();
        }
      }}
    />
  );
};
