import { User } from "@components/user-context";
import { Sorting } from "@devexpress/dx-react-grid";
import {
  Breakdown,
  CategoricalBreakdownConfig,
  DateInterval,
  DbForecastV1DateRow,
  isWithinDateInterval,
  SalesReportRow,
  SalesReportRowExtraDetail,
} from "@north-beam/nb-common";
import { PLATFORM_NORTHBEAM } from "@utils/constants";
import {
  addToMetricPoint,
  descriptorToPath,
  makeMetricPoint,
  MetricPoint,
  newMetricPoint,
  pathToDescriptor,
  SalesPageMetric,
} from "@utils/metrics";
import { map, RowWithChildren } from "@utils/row-with-children";
import { keyBy } from "lodash";
import { TabView } from "../tab-table-section";

export interface ColumnDef {
  title: string;
  name: string;
  id: string;
  metric: SalesPageMetric;
  getCellValue?: (row: any, columnName: string) => any;
  tooltip?: string;
  comparison?: any;
  width: number;
  visible: boolean;
  options?: Record<string, any>;
}

const getDefaultCellWidth = (title: string) => {
  if (title === "Spend") {
    return 180;
  }
  return Math.round(Math.max(title.length * 9.6, 120));
};

// Removes the attribution window like (1d) or (3d, 1st time) or (14d, Returning) from the name
export const normalizeMetricKey = (metricName: string) => {
  const name = metricName.replaceAll("Txn", "Transaction");
  if (name.startsWith("Forecasted")) {
    return name;
  }
  if (name.endsWith(", 1st time)") || name.endsWith(", Returning)")) {
    return name.replace(/\(\d{1,2}d, /, "(");
  }
  return name.replace(/ \(\d{1,2}d\)$/, "");
};

export const createMetricColumns = (metricsArray: SalesPageMetric[]) =>
  metricsArray
    .filter((v) => !v.hideFromTableDisplay)
    .map((metric) => {
      const metricName = metric.name.replaceAll("Txn", "Transaction");
      const columnDef: ColumnDef & { metric?: any } = {
        title: metricName,
        // IMPORTANT: This is used to key the like columns across different filters
        name: normalizeMetricKey(metricName),
        id: metric.id,
        getCellValue: (row: any) => row?.export?.[metric.id] ?? row?.value,
        tooltip: metric.descriptionHTML,
        visible: false,
        width: getDefaultCellWidth(metricName),
        metric,
      };

      if (!metric.hideComparisonFromTableCell) {
        columnDef.comparison = {
          isPositiveChangeGood: metric.isPositiveChangeGood,
        };
      }

      return columnDef;
    });

export const generateCellHoverHTML = (
  node: RowWithChildren<TableableResponseRow>,
  metricsArray: SalesPageMetric[],
  user: User,
): Record<string, string> => {
  const columnIdToCellHoverHTML: Record<string, string> = {};

  for (const metric of metricsArray) {
    const metadata = metric.metadata ?? {};
    const isMetricDerivedFromConversionAttribution =
      metadata.metricId &&
      ["rev", "txns", "roas", "cac", "ecr"].includes(metadata.metricId);

    if (isMetricDerivedFromConversionAttribution || metadata.isGoalRelated) {
      if (node?.cellAnnotations?.visitAttributionInTraining) {
        columnIdToCellHoverHTML[metric.id] = `
          <p><b>In Training</b></p>
          <p class="mb-0">
            We are still collecting First-Party Data and developing this model.
            Values are subject to fluctuation and should be discounted.
          </p>`;
      } else if (node?.cellAnnotations?.awarenessCampaignAttributionTurnedOff) {
        columnIdToCellHoverHTML[metric.id] = `
          <p><b>Update</b></p>
          <p>
            We are making a temporary update to our view attribution model that
            will disable view-through credit to Awareness campaigns.
          </p>
          <p class="mb-0">
            Please reach out to your customer education team member if you have
            any questions.
          </p>`;
      } else if (
        (node?.name?.toLowerCase()?.includes("mntn") ||
          node?.platformId?.toLocaleLowerCase()?.includes("mntn")) &&
        user.featureFlags.enableMNTNHoverTooltip
      ) {
        columnIdToCellHoverHTML[metric.id] = `
          <p><b>Update</b></p>
          <p>
          Please use our MMM offering for attribution information.
          </p>
          <p class="mb-0">
          Reach out to your customer education team member if you have any questions.
          </p>`;
      }
    }
  }
  return columnIdToCellHoverHTML;
};

interface DailyData {
  current: MetricPoint;
  name: string;
  date: string | null;
  extraDetails: SalesReportRowExtraDetail[];
  description: { key: string; value: string }[];
}

export interface TableableResponseRow {
  id: string;
  platformId: string | null;
  parentId: string | null;
  description: { key: string; value: string }[];
  link?: string;
  name: string;
  extraDetails: SalesReportRowExtraDetail[];
  cellAnnotations: Record<string, boolean>;
  current: MetricPoint;
  compare: MetricPoint | null;
}

export type TableableResponseRowWithChildren =
  RowWithChildren<TableableResponseRow>;

const getDataForDescriptor = (descriptorToData: any, descriptor: string) => {
  if (!descriptorToData[descriptor]) {
    descriptorToData[descriptor] = {
      current: newMetricPoint(),
      compare: null,
      name: "",
      link: "",
      platformId: null,
      extraDetails: [],
      cellAnnotations: {},
      description: [],
      children: [],
      parentId: null,
      key: "",
    };
  }
  return descriptorToData[descriptor];
};

const getDailyDataForDescriptor = (
  descriptorToDailyData: any,
  descriptor: string,
  date: string,
) => {
  const descriptorWithDate = descriptor + "\n" + date;
  if (!descriptorToDailyData[descriptorWithDate]) {
    descriptorToDailyData[descriptorWithDate] = {
      current: newMetricPoint(),
      name: "",
      date,
      extraDetails: [],
      description: [],
    };
  }
  return descriptorToDailyData[descriptorWithDate];
};

export const preprocessForTable = (
  rows: SalesReportRow[],
  breakdownConfigs: CategoricalBreakdownConfig[],
  breakdowns: Breakdown[],
  dateRange: DateInterval,
  compareDateRange: DateInterval,
  searchValue: string,
  granularity: TabView,
): [TableableResponseRowWithChildren, Record<string, DailyData>] => {
  const generateDescriptors = ({
    dimensions,
    objectId,
  }: SalesReportRow): string[] => {
    const paths: string[][] = [[]];
    for (const breakdown of breakdowns) {
      const dimension = dimensions[breakdown.key] || "";
      paths.push([...paths[paths.length - 1], dimension]);
    }

    paths.push([...paths[paths.length - 1], objectId]);

    return paths.map(pathToDescriptor);
  };

  const generateDailyDescriptorsForExport = (row: SalesReportRow): string[] => {
    const { dimensions, objectId } = row;

    const breakdownStrings = breakdowns.map(
      (breakdown) => dimensions[breakdown.key] || "",
    );

    return [pathToDescriptor([...breakdownStrings, objectId])];
  };

  const keyToConfig = keyBy(breakdownConfigs, "key");

  const descriptorToData: Record<string, TableableResponseRowWithChildren> = {};
  const descriptorToDailyData: Record<string, DailyData> = {};

  for (const row of rows) {
    const { dailyMetrics, forecastV1Daily, dimensions, searchableStrings } =
      row;

    const rowPassesFilter = breakdowns.every((b) =>
      b.values.includes(dimensions[b.key] ?? ""),
    );
    if (!rowPassesFilter) {
      continue;
    }

    if (searchValue) {
      let searchable = "";
      if (granularity !== TabView.PLATFORM) {
        searchable = (Object.values(searchableStrings) as string[])
          .map((val) => val.toLowerCase())
          .join(" ");
      } else {
        searchable = row.dimensions[PLATFORM_NORTHBEAM]?.toLowerCase() ?? "";
      }

      if (!searchable.includes(searchValue.toLowerCase())) {
        continue;
      }
    }

    const dateToForecastRow: Record<string, DbForecastV1DateRow> = keyBy(
      forecastV1Daily ?? [],
      "date",
    );

    const descriptors = generateDescriptors(row);
    const dailyDescriptors = generateDailyDescriptorsForExport(row);
    descriptors.forEach((descriptor, i) => {
      const descriptorData = getDataForDescriptor(descriptorToData, descriptor);
      if (i === 0) {
        descriptorData.name = "Grand Total";
      } else if (i < descriptors.length - 1) {
        const path = descriptorToPath(descriptor);

        const value = path[path.length - 1];
        const choices =
          keyToConfig[breakdowns[path.length - 1].key]?.choices || [];
        const name = choices.find((v) => v.value === value)?.label ?? value;
        descriptorData.name = name + " (total blended)";
      } else {
        descriptorData.name = row.name;
        descriptorData.link = row.link;
        descriptorData.platformId = row.nbtPlatformID || null;
        descriptorData.extraDetails = row.extraDetails ?? [];
        descriptorData.cellAnnotations = row.cellAnnotations ?? {};
      }
      descriptorData.id = descriptor;
      descriptorData.key = descriptor;
    });

    for (const dailyRow of dailyMetrics ?? []) {
      const rowDate = dailyRow.date.substring(0, 10);
      const isWithinDateRange = isWithinDateInterval(rowDate, dateRange);
      if (isWithinDateRange) {
        for (const descriptor of descriptors) {
          const path = descriptorToPath(descriptor);
          const description: { key: string; value: string }[] = [];
          for (let i = 0; i < path.length && i < breakdowns.length; ++i) {
            const breakdownKey = breakdowns[i].key;
            const choices = keyToConfig[breakdownKey]?.choices || [];
            const name =
              choices.find((v) => v.value === path[i])?.label ?? path[i];
            description.push({ key: breakdownKey, value: name });
          }

          let parentId: string | null = null;
          if (path.length > 0) {
            path.pop();
            parentId = pathToDescriptor(path);
          }

          const descriptorData = getDataForDescriptor(
            descriptorToData,
            descriptor,
          );
          descriptorData.description = description;
          descriptorData.parentId = parentId;
          descriptorData.id = descriptor;
          const metricPoint = makeMetricPoint(
            dailyRow,
            dateToForecastRow[dailyRow.date],
          );
          addToMetricPoint(descriptorData.current, metricPoint);

          if (dailyDescriptors.includes(descriptor)) {
            const descriptorDailyData = getDailyDataForDescriptor(
              descriptorToDailyData,
              descriptor,
              dailyRow.date,
            );
            descriptorDailyData.current = metricPoint;
            descriptorDailyData.name = row.name;
            descriptorDailyData.extraDetails = row.extraDetails ?? [];
            descriptorDailyData.description = description;
          }
        }
      }

      const isWithinCompareDateRange = isWithinDateInterval(
        rowDate,
        compareDateRange,
      );
      if (isWithinCompareDateRange) {
        for (const descriptor of descriptors) {
          const data =
            getDataForDescriptor(descriptorToData, descriptor).compare ??
            newMetricPoint();
          const metricPoint = makeMetricPoint(dailyRow, null);
          addToMetricPoint(data, metricPoint);
          getDataForDescriptor(descriptorToData, descriptor).compare = data;
        }
      }
    }
  }

  for (const descriptor in descriptorToData) {
    const path = descriptorToPath(descriptor);
    if (path.length === 0) continue;

    const parentPath = [...path];
    parentPath.pop();

    const parentDescriptor = pathToDescriptor(parentPath);
    descriptorToData[parentDescriptor].children.push(
      descriptorToData[descriptor],
    );
  }

  return [descriptorToData[pathToDescriptor([])], descriptorToDailyData];
};

export const createDailyDataRoot = (
  dailyData: Record<string, DailyData>,
  extraExportDimensionColumns: Set<string>,
  metricsArray: SalesPageMetric[],
) =>
  Object.values(dailyData).map((row: DailyData) => {
    const exportData: Record<string, any> = {};

    exportData.Name = row.name;
    exportData.Date = row.date;

    for (const { name, value } of row.extraDetails) {
      exportData[name] = value;
      extraExportDimensionColumns.add(name);
    }

    for (const { key, value } of row.description) {
      exportData[key] = value;
    }

    for (const metric of metricsArray) {
      exportData[metric.id] = metric.calculate(row.current);
    }

    return {
      export: exportData,
      link: {},
      display: {},
      changeFractions: {},
      rowMetadata: {},
      cellHoverHTML: {},
    };
  });

export const createNativeTableRoot = (
  data: TableableResponseRowWithChildren,
  extraExportDimensionColumns: Set<string>,
  metricsArray: SalesPageMetric[],
  search: string,
  granularity: TabView,
  user: User,
) => {
  if (!data) {
    return { children: [] };
  }

  const tableRoot = map(data, (node, level) => {
    const link: Record<string, any> = {};
    const exportData: Record<string, any> = {};
    const display: Record<string, any> = {};
    const changeFractions: Record<string, number> = {};
    const rowMetadata: Record<string, any> = {};
    const cellHoverHTML: Record<string, string> = generateCellHoverHTML(
      node,
      metricsArray,
      user,
    );

    rowMetadata.level = level;
    rowMetadata.point = node.current;
    rowMetadata.platformId = node.platformId;
    rowMetadata.extraDetails = node.extraDetails;
    rowMetadata.isPlatformView = granularity === TabView.PLATFORM;

    display.Name = node.name;
    exportData.Name = node.name;
    if (node.link) {
      link.Name = node.link + search;
    }

    for (const { name, value } of node.extraDetails) {
      exportData[name] = value;
      extraExportDimensionColumns.add(name);
    }

    for (const { key, value } of node.description) {
      display[key] = value;
      exportData[key] = value;
    }

    for (const metric of metricsArray) {
      const lowerCaseNamed = metric.name.toLowerCase();

      // If this is a platform metric, show "N/A" in the Grand Total Row
      if (
        (lowerCaseNamed.includes("fb ") ||
          lowerCaseNamed.includes("google ") ||
          lowerCaseNamed.includes("tiktok ")) &&
        display.Name === "Grand Total"
      ) {
        exportData[metric.id] = "N/A";
        display[metric.id] = "N/A";
        changeFractions[metric.id] = 0;
      } else {
        const current = metric.calculate(node.current);
        let changeFraction: number | null = null;
        if (node.compare) {
          const compare = metric.calculate(node.compare);
          changeFraction = (current - compare) / compare;
        }

        exportData[metric.id] = current;
        display[metric.id] = metric.formatExact(current);
        changeFractions[metric.id] = changeFraction as any;
      }
    }

    return {
      key: node.id ?? node.link,
      parentId: node.parentId,
      link,
      export: exportData,
      display,
      changeFractions,
      rowMetadata,
      cellHoverHTML,
    };
  });

  // Disable tree view when on platform
  if (granularity === TabView.PLATFORM) {
    tableRoot.children.forEach((row: any) => {
      row.children = [];
    });
  }

  return tableRoot;
};

const sortRows = (
  rows: any[],
  columnId: string,
  direction: Sorting["direction"],
) => {
  return rows.sort((a: any, b: any) => {
    const aValue = a.export[columnId];
    const bValue = b.export[columnId];

    if (aValue === bValue) return 0;

    if (aValue === undefined || isNaN(aValue)) return 1;
    if (bValue === undefined || isNaN(bValue)) return -1;
    if (aValue === Infinity) return 1;
    if (bValue === Infinity) return -1;

    if (aValue < bValue) return direction === "asc" ? -1 : 1;
    if (aValue > bValue) return direction === "asc" ? 1 : -1;

    return 0;
  });
};

export const recursiveSort = (
  rows: any[],
  columnId: string,
  direction: Sorting["direction"],
) =>
  sortRows(rows, columnId, direction).map((row) => {
    if (row.children) {
      row.children = recursiveSort(row.children, columnId, direction);
    }

    return row;
  });

export const getChildRows = (row: any | null, rootRows: any[] | null) => {
  const hasChildren = row?.children?.length > 0;
  if (hasChildren) {
    return row.children;
  }
  // If has row but no children, then row is leaf
  if (row) {
    return null;
  }
  return rootRows;
};

export const filter0SpendAnd0TransactionRows = (rows: any[]) => {
  return rows.filter((row) => {
    const hasChildren = row.children?.length > 0;
    if (hasChildren) {
      row.children = filter0SpendAnd0TransactionRows(row.children);
      return row.children.length > 0;
    }

    const txnKeys = Object.keys(row.export).filter((key) => {
      const lowerCaseKey = key.toLowerCase();
      return lowerCaseKey.startsWith("txns");
    });
    if (txnKeys.length && row.export.spend !== undefined) {
      const numOfTxns = txnKeys.reduce((acc, key) => acc + row.export[key], 0);
      const is0SpendAnd0Txns = row.export.spend === 0 && numOfTxns === 0;
      return !is0SpendAnd0Txns;
    }

    return true;
  });
};
