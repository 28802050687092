import React, { Fragment, useState } from "react";

import { StuckToTop } from "@components/stuck-to-top";
import { DateRangePicker } from "@components/date-range-picker";
import { DateRangeChoiceEnum, DateRangeChoiceV2 } from "@utils/constants";

import { useUser } from "@components/user-context";
import { useReportBodyState } from "../hooks/ca-control-state";

import { SavedViewsControl } from "./saved-views-control";
import { SecondaryButton } from "@shared/buttons";
import { useToasts } from "react-toast-notifications";
import copy from "copy-to-clipboard";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { FBIcon, InstagramIcon, SnapchatIcon, TikTokIcon } from "./icons";
import { Selector } from "./selector";

export const CreativeAnalyticsControl = () => {
  const toast = useToasts();

  return (
    <StuckToTop>
      {() => (
        <div className="flex flex-row h-[5rem] mr-0 items-end">
          <SavedViewsControl />
          <DateRangSelector />
          <PlatformSelector />
          <AttributionModel />
          <AttributionWindow />

          <SecondaryButton
            className="ml-auto mr-5 h-[2.5rem] rounded-xl flex flex-row items-center  border-cosmic hover:bg-[#F3F5FF]"
            onClick={() => {
              toast.addToast("LINK COPIED TO CLIPBOARD", {
                appearance: "success",
              });
              copy(window.location.href);
            }}
          >
            <i className="mr-2 text-lg fa-sharp fa-regular fa-link text-cosmic"></i>
            <div className="text-[#0E2347]">SHARE</div>
          </SecondaryButton>
        </div>
      )}
    </StuckToTop>
  );
};

function DateRangSelector() {
  const {
    state,
    setDateRange,
    dateRange: parsedDateRange,
  } = useReportBodyState();
  const { dateRange } = state;
  const { user } = useUser();

  console.log(parsedDateRange);

  const dateRangeCurrent =
    typeof dateRange === "string"
      ? DateRangeChoiceEnum.toReactSelect(dateRange)
      : dateRange;
  return (
    <div className="flex flex-col ml-10 w-[12rem]">
      <div className="font-normal text-xs text-semibold text-text mb-1">
        DATE RANGE
      </div>
      <DateRangePicker<DateRangeChoiceV2>
        dropdownIconClassName="fa-calendar text-gray-500 text-xs leading-5"
        disabled={false}
        nonCustomChoices={DateRangeChoiceEnum.reactSelectOptions}
        current={dateRangeCurrent}
        onUpdate={(dateRange) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          setDateRange(dateRange);
        }}
        timezone={user.timezone}
        maxLookbackDays={20 * 365}
      />
    </div>
  );
}

const options = ["Facebook", "Instagram", "Snapchat", "TikTok"];

function PlatformSelector() {
  const [selectedPlatforms, setSelectedPlatforms] = useState<typeof options>([
    options[0],
  ]);
  const moveIcon = (index: number) => ({
    "left-[1.75rem]": index === 1,
    "left-[3rem]": index === 2,
    "left-[4.25rem]": index === 3,
  });
  return (
    <div className="flex flex-col w-[12rem] ml-5">
      <div className="font-normal text-xs text-semibold text-text mb-1">
        PLATFORM
      </div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={clsx(
                "h-[2.4rem] bg-white border-[1px] rounded-lg border-[#C1C5CD] px-2 mb-0.5 w-full text-left flex flex-row items-center relative",
                {
                  "border  border-cosmic ring-1 ring-cosmic": open,
                },
              )}
            >
              <div>
                {selectedPlatforms.map((platform, index) => {
                  if (platform === "Facebook")
                    return (
                      <FBIcon
                        key={platform}
                        className={clsx(
                          "absolute top-1 border-[1px] border-white",
                          moveIcon(index),
                        )}
                      />
                    );
                  if (platform === "Instagram")
                    return (
                      <InstagramIcon
                        key={platform}
                        className={clsx(
                          "absolute top-1 border-[1px] border-white",
                          moveIcon(index),
                        )}
                      />
                    );
                  if (platform === "Snapchat")
                    return (
                      <SnapchatIcon
                        key={platform}
                        className={clsx(
                          "absolute top-1 border-[1px] border-white",
                          moveIcon(index),
                        )}
                      />
                    );
                  return (
                    <TikTokIcon
                      key={platform}
                      className={clsx(
                        "absolute top-1 border-[1px] border-white",
                        moveIcon(index),
                      )}
                    />
                  );
                })}
              </div>
              <i
                className={clsx(
                  "font-semibold fa-solid fa-sharp cursor-pointer text-[#000000] text-sm ml-auto ",
                  {
                    "fa-chevron-down": !open,
                    "fa-chevron-up": open,
                  },
                )}
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute w-[15rem] h-[14rem] z-10 mt-3  bg-white rounded-lg elevation-1 flex flex-col px-8 py-5">
                <div>Select Platform(s)</div>
                <div className="mt-4">
                  {options.map((option) => {
                    return (
                      <div key={option}>
                        <label className="flex flex-row items-center m-2 ml-3 accent-cosmic">
                          <input
                            type="checkbox"
                            checked={selectedPlatforms.includes(option)}
                            onChange={() => {
                              const newSet = new Set(selectedPlatforms);
                              if (newSet.has(option)) {
                                newSet.delete(option);
                              } else {
                                newSet.add(option);
                              }
                              setSelectedPlatforms([...newSet]);
                            }}
                            className="mr-3"
                          />
                          {option}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

function AttributionModel() {
  const optionNames = ["clicks", "click_only"];

  const options = optionNames.map((o) => ({
    value: o,
    label: o,
  }));
  const [attributionModelOption, setAttributionModelOption] = useState(
    options[0],
  );
  return (
    <div className="flex flex-col w-[12rem]">
      <Selector
        value={attributionModelOption}
        onChange={setAttributionModelOption}
        options={options}
        label="ATTRIBUTION MODEL"
      />
    </div>
  );
}

function AttributionWindow() {
  const optionNames = ["7d", "14d"];
  const options = optionNames.map((o) => ({
    value: o,
    label: o,
  }));
  const [attributionWindowOption, setAttributionWindowOption] = useState(
    options[0],
  );
  return (
    <div className="flex flex-col w-[12rem] ml-5">
      <Selector
        value={attributionWindowOption}
        onChange={setAttributionWindowOption}
        options={options}
        label="ATTRIBUTION WINDOW"
      />
    </div>
  );
}
