import { IconButton } from "@shared/buttons";
import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { isTemplateView, SalesView } from "../../sales-views-context";
import { ConfirmDeleteSalesViewRow } from "./confirm-delete-sales-view-row";

interface SalesViewProps {
  salesView: SalesView;
  index: number;
  moveView: (dragIndex: number, hoverIndex: number) => void;
  deleteView: () => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const SalesViewRow = ({
  salesView,
  index,
  moveView,
  deleteView,
}: SalesViewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [needConfirmDelete, setNeedConfirmDelete] = useState(false);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: any }>({
    accept: "view",
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveView(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "view",
    item: () => ({ id: salesView.id, index }),
    collect: (monitor: any) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  if (needConfirmDelete) {
    return (
      <ConfirmDeleteSalesViewRow
        deleteView={deleteView}
        cancelConfirmDelete={() => setNeedConfirmDelete(false)}
      />
    );
  }

  return (
    <div
      className="bg-[#F8F8F9] py-2 px-3 rounded-md flex items-center justify-between text-sm"
      ref={preview}
      style={{ opacity }}
    >
      <span>
        <i
          ref={ref}
          className="fa-solid fa-grip-dots text-primary mr-2.5 cursor-move"
          data-handler-id={handlerId}
        />
        {salesView.name}
        {index === 0 && <span> (default)</span>}
      </span>
      {isTemplateView(salesView) ? null : (
        <IconButton onClick={() => setNeedConfirmDelete(true)}>
          <i className="fa-solid fa-trash cursor-pointer text-danger" />
        </IconButton>
      )}
    </div>
  );
};
