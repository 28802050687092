import React from "react";
import {
  DateRangeDisplay,
  DateRangePicker,
} from "@components/date-range-picker";
import { useUser } from "@components/user-context";
import { FixedDateRange, todayLocalAsISODate } from "@north-beam/nb-common";
import {
  CompareDateRangeEnum,
  DateRangeChoiceV2,
  ICompareDateRangeEnum,
  resolveCompareDateRange,
} from "@utils/constants";
import { parseDateRangeArgument } from "@utils/index";

interface CompareDateRangeSelectProps {
  isLoading: boolean;
  dateRange: FixedDateRange | DateRangeChoiceV2;
  compareDateRange: FixedDateRange | ICompareDateRangeEnum;
  onUpdate: (opt: any) => void;
  convertDateRangeToFixed: (compareDateRange: FixedDateRange) => void;
  maxLookbackDays?: number;
}

export const CompareDateRangeSelect = ({
  isLoading,
  dateRange,
  compareDateRange,
  onUpdate,
  convertDateRangeToFixed,
  maxLookbackDays,
}: CompareDateRangeSelectProps) => {
  const { user } = useUser();
  const anchor = todayLocalAsISODate();
  const dr = parseDateRangeArgument(dateRange, anchor);
  const cdr = resolveCompareDateRange(dr, compareDateRange);

  const dateRangeCurrent =
    typeof compareDateRange === "string"
      ? CompareDateRangeEnum.toReactSelect(compareDateRange)
      : compareDateRange;

  return (
    <>
      <DateRangePicker
        disabled={isLoading}
        nonCustomChoices={CompareDateRangeEnum.reactSelectOptions}
        current={dateRangeCurrent}
        onUpdate={onUpdate}
        timezone={user.timezone}
        maxLookbackDays={maxLookbackDays}
      />
      <small className="text-muted mt-1 text-small d-block">
        <DateRangeDisplay
          dateRange={{ type: "fixed", ...cdr }}
          onConversionToFixed={convertDateRangeToFixed}
        />
      </small>
    </>
  );
};
