import classNames from "classnames";
import React from "react";

export default ({
  id,
  not,
  setNot,
  conjunctionOptions,
  setConjunction,
  disabled,
  readonly,
  config,
  showNot,
  notLabel,
}) => {
  const conjsCount = Object.keys(conjunctionOptions).length;
  const lessThenTwo = disabled;

  const renderOptions = () => (
    <div className="btn-group btn-group-toggle" data-toggle="buttons">
      {Object.keys(conjunctionOptions).map(key => {
        const { id, name, label, checked } = conjunctionOptions[key];
        let postfix = setConjunction.isDummyFn ? "__dummy" : "";
        return (
          <label
            key={id + postfix + "label"}
            htmlFor={id + postfix}
            className={classNames({
              btn: true,
              "btn-outline-primary": true,
              active: checked,
            })}
          >
            <input
              key={id + postfix}
              type="radio"
              id={id + postfix}
              name={name + postfix}
              checked={checked}
              disabled={readonly}
              value={key}
              onChange={onChange}
              className="form-check-input"
            />
            {label}
          </label>
        );
      })}
    </div>
  );

  const renderNot = () => {
    return (
      <div className="form-check form-check-inline">
        <input
          key={id}
          type="checkbox"
          id={id + "__not"}
          checked={not}
          disabled={readonly}
          onChange={onNotChange}
          className="form-check-input"
        />
        <label
          key={id + "label"}
          htmlFor={id + "__not"}
          className="form-check-label"
        >
          {notLabel || "NOT"}
        </label>
      </div>
    );
  };

  const onChange = e => setConjunction(e.target.value);

  const onNotChange = e => setNot(e.target.checked);

  return [
    showNot && renderNot(),
    conjsCount > 1 && !lessThenTwo && renderOptions(),
  ];
};
