import DataExportCalendar from "@assets/data-export-calendar.svg";
import DataExportGcs from "@assets/data-export-gcs.svg";
import DataExportLogo from "@assets/data-export-logo.svg";
import { useUser } from "@components/user-context";
import { CREATE_DATA_EXPORT_PERFORMANCE_METRICS_CONFIG } from "@gql/data-export";
import {
  CreateDataExportPerformanceMetricsConfig,
  CreateDataExportPerformanceMetricsConfigVariables,
} from "@nb-api-graphql-generated/CreateDataExportPerformanceMetricsConfig";
import {
  DataExportFormat,
  DataExportFrequency,
  DataExportPerformanceMetricsConfigInput,
  DataExportType,
} from "@nb-api-graphql-generated/global-types";
import { PrimaryButton, SecondaryButton } from "@shared/buttons";
import { Button } from "@shared/buttons/button";
import { ColumnConfig } from "@shared/metric-customization-modal/metric-category-builder";
import { Modal } from "@shared/modals/modalV2";
import { notifySuccess } from "@shared/notify";
import { usePrettyLogEventPage } from "@utils/analytics";
import { useNorthbeamMutation } from "@utils/hooks";
import { SalesPageMetricSpec } from "@utils/metrics";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SalesMetricsConfig, ScheduleConfig, Step } from "../types";
import { ExportMethodSelector } from "./export-method";
import { GCSConfigurator } from "./export-method/gcs-configurator";
import { SalesMetricsConfigurator } from "./sales-metrics-configurator";
import { ScheduleConfigurator } from "./schedule-configurator";

interface Props {
  isOpen: boolean;
  salesPageMetricSpecs: SalesPageMetricSpec[];
  columnConfigs: ColumnConfig[];
  onClose(): void;
}

export function DataExportBuilderModal({
  isOpen,
  onClose,
  salesPageMetricSpecs,
  columnConfigs,
}: Props) {
  const { user } = useUser();
  const prettyLogEventPage = usePrettyLogEventPage();

  const [step, setStep] = useState<Step>("metrics-configurator");
  const [isScheduleConfiguratorVisible, setScheduleConfiguratorVisible] =
    useState(false);

  const [exportConfig, setExportConfig] = useState<SalesMetricsConfig>({
    metrics: [],
    exportAggregation: "breakdown",
    removeZeroSpend: false,
    level: "platform",
    timeGranularity: "daily",
    breakdowns: [],
    defaultAttributionModels: [],
    defaultAttributionWindows: [],
    defaultAccountingModes: [],
    timeFrame: "last7Days",
  });

  const [scheduleConfig, setScheduleConfig] = useState<ScheduleConfig>({
    frequency: DataExportFrequency.DAILY,
    timeFrames: [],
    recipients: [],
  });

  const [createDataExportPerformanceMetricsConfig, { loading }] =
    useNorthbeamMutation<
      CreateDataExportPerformanceMetricsConfig,
      CreateDataExportPerformanceMetricsConfigVariables
    >(CREATE_DATA_EXPORT_PERFORMANCE_METRICS_CONFIG);

  const createExport = (type: DataExportType) => {
    let timeFrameType: string;
    let timeFrameStartDate: string | null | undefined;
    let timeFrameEndDate: string | null | undefined;

    if (typeof exportConfig.timeFrame === "string") {
      timeFrameType = exportConfig.timeFrame;
    } else {
      timeFrameType = exportConfig.timeFrame.type;
      timeFrameStartDate = exportConfig.timeFrame.startDate;
      timeFrameEndDate = exportConfig.timeFrame.endDate;
    }

    const data: DataExportPerformanceMetricsConfigInput = {
      formats: [DataExportFormat.CSV],
      exportType: type,
      exportAggregation: exportConfig.exportAggregation,
      removeZeroSpend: exportConfig.removeZeroSpend,
      level: exportConfig.level,
      timeGranularity: exportConfig.timeGranularity,
      breakdowns: exportConfig.breakdowns.map((e, index) => ({
        position: index,
        key: e.key,
        values: e.values,
      })),
      defaultAttributionModels: exportConfig.defaultAttributionModels,
      defaultAttributionWindows: exportConfig.defaultAttributionWindows,
      defaultAccountingModes: exportConfig.defaultAccountingModes,
      timeFrameType,
      timeFrameStartDate,
      timeFrameEndDate,
      metrics: exportConfig.metrics.map((e, index) => ({
        id: e.id,
        position: index,
        label: e.label,
        attributionModels: Array.from(e.attributionModels),
        attributionWindows: Array.from(e.attributionWindows),
        accountingModes: Array.from(e.accountingModes),
      })),
    };

    if (type === DataExportType.SCHEDULED) {
      data.frequency = scheduleConfig.frequency;
      data.scheduleTimeFrames = scheduleConfig.timeFrames;
      data.recipients = scheduleConfig.recipients;
    }

    return createDataExportPerformanceMetricsConfig({ variables: { data } });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      closeButton={true}
      withOverflow={false}
      widthClassname="xl:w-[70rem] lg:w-[80vw] lg:min-w-[58rem]
                      md:w-[90vw] md:min-w-[45rem] w-[90vw] min-w-[35rem]"
      heightClassname="xl:h-[50rem] lg:h-[45rem] md:h-[35rem] h-[35rem]"
    >
      <div className="h-full grid grid-rows-[5.5rem_auto_4.5rem] overflow-hidden">
        <div className="border-b border-gray-100 flex justify-center items-center">
          {step === "metrics-configurator" &&
            !isScheduleConfiguratorVisible &&
            user.featureFlags.enableDataExportSchedule && (
              <div className="absolute right-7 top-[52px]">
                <SecondaryButton
                  className="font-normal text-xs pt-1 pb-1 pl-3 pr-3"
                  onClick={() => {
                    prettyLogEventPage({
                      domain: "DataExport",
                      action: "onClick",
                      additionalActionInfo: "Schedule Configurator opened",
                    });

                    setScheduleConfig({
                      frequency: DataExportFrequency.DAILY,
                      timeFrames: [{ day: "*", time: "08:00" }],
                      recipients: [],
                    });
                    setScheduleConfiguratorVisible(true);
                  }}
                >
                  Schedule Export
                  <i className="fa-solid fa-calendar-clock text-blue-500 text-[9px] ml-1.5"></i>
                </SecondaryButton>
              </div>
            )}

          {step === "method-selector" && (
            <div className="flex flex-col items-center">
              <h2 className="text-lg font-semibold m-0 mb-1 flex">
                Data export: Report builder
                <img
                  className="w-[25px] ml-3 mb-2"
                  alt="data-export-logo"
                  src={DataExportLogo}
                />
              </h2>
              <p className="text-xs text-zinc-500 font-light m-0">
                Schedule, export and share your data via CSV or Google Cloud
                Storage
              </p>
            </div>
          )}

          {step === "gcs-configurator" && (
            <div className="flex flex-col items-center">
              <h2 className="text-lg font-semibold m-0 mb-1 flex">
                Google Cloud Storage
                <img
                  className="w-[24px] ml-3"
                  alt="data-export-logo"
                  src={DataExportGcs}
                />
              </h2>
              <p className="text-xs text-zinc-500 font-light m-0">
                Schedule, export and share your data to Google Cloud Storage
              </p>
            </div>
          )}

          {step === "metrics-configurator" &&
            !isScheduleConfiguratorVisible && (
              <div className="flex flex-col items-center">
                <h2 className="text-lg font-semibold m-0 mb-1 flex">
                  Data export: Report builder
                  <img
                    className="w-[25px] ml-3 mb-2"
                    alt="data-export-logo"
                    src={DataExportLogo}
                  />
                </h2>
                <p className="text-xs text-zinc-500 font-light m-0">
                  Schedule, export and share your data via CSV
                </p>
              </div>
            )}

          {isScheduleConfiguratorVisible && (
            <div className="flex flex-col items-center">
              <h2 className="text-lg font-semibold m-0 mb-1 flex">
                Schedule export
                <img
                  className="w-[24px] ml-3"
                  alt="data-export-logo"
                  src={DataExportCalendar}
                />
              </h2>
              <p className="text-xs text-zinc-500 font-light m-0">
                Schedule and export your up to date data
              </p>
            </div>
          )}
        </div>
        <div className="relative overflow-hidden">
          {isScheduleConfiguratorVisible && (
            <ScheduleConfigurator
              scheduleConfig={scheduleConfig}
              onChangeSchedule={setScheduleConfig}
              onClose={() => {
                prettyLogEventPage({
                  domain: "DataExport",
                  action: "onClose",
                  additionalActionInfo: "Schedule Configurator closed",
                });

                setScheduleConfiguratorVisible(false);
              }}
            />
          )}

          {step === "method-selector" && (
            <ExportMethodSelector onSelect={setStep} />
          )}
          {step === "gcs-configurator" && <GCSConfigurator />}
          {step === "metrics-configurator" && (
            <SalesMetricsConfigurator
              columnConfigs={columnConfigs}
              salesPageMetricSpecs={salesPageMetricSpecs}
              onChange={setExportConfig}
            />
          )}
        </div>
        <div className="border-t border-gray-100 flex flex-row items-center">
          <div className="flex-1 flex flex-col justify-center">
            <p className="text-[0.6rem] font-light m-0 ml-7 flex items-center leading-none">
              <span
                className="mr-2 border-2 border-gray-500 rounded-full text-gray-500
                           pt-[1px] font-semibold w-[22px] h-[22px] inline-block text-[0.9rem]
                           flex items-center justify-center"
              >
                ?
              </span>
              <span>
                <span className="font-semibold">
                  Need help exporting your report?
                </span>{" "}
                Schedule a call with our customer success team{" "}
                <a
                  href="https://meetings.hubspot.com/northbeam/in-dashboard-data-review-request"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </span>
            </p>
          </div>

          <div className="pr-7 space-x-4">
            {step === "gcs-configurator" && (
              <PrimaryButton
                className="font-light text-xs pt-1.5 pb-1.5 pl-4 pr-4 border border-[#367BF3]"
                isDisabled={false}
                onClick={() => setStep("metrics-configurator")}
              >
                Next
              </PrimaryButton>
            )}
            {step === "metrics-configurator" &&
              !isScheduleConfiguratorVisible && (
                <>
                  {/* <SecondaryButton
                    className="font-normal text-xs pt-1.5 pb-1.5 pl-4 pr-4"
                    isDisabled={true}
                  >
                    Save
                  </SecondaryButton> */}
                  <Button
                    variant="primary-v2"
                    isDisabled={loading}
                    loading={loading}
                    onClick={async () => {
                      prettyLogEventPage({
                        domain: "DataExport",
                        action: "onClick",
                        data: {
                          exportType: DataExportType.ONE_OFF,
                        },
                        additionalActionInfo: "Create Data Export button",
                      });

                      await createExport(DataExportType.ONE_OFF);

                      notifySuccess({
                        message: "Your export has started!",
                        caption: (
                          <>
                            It may take up to a few minutes to complete
                            depending on the size of the export. When your
                            export is done it will live in the{" "}
                            <Link
                              to={"./customers/documents"}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Documents page.
                            </Link>
                          </>
                        ),
                      });

                      onClose();
                    }}
                  >
                    Export
                  </Button>
                </>
              )}

            {isScheduleConfiguratorVisible && (
              <Button
                variant="primary-v2"
                isDisabled={
                  loading ||
                  scheduleConfig.recipients.length === 0 ||
                  scheduleConfig.timeFrames.length === 0
                }
                loading={loading}
                onClick={async () => {
                  prettyLogEventPage({
                    domain: "DataExport",
                    action: "onClick",
                    data: {
                      exportType: DataExportType.SCHEDULED,
                    },
                    additionalActionInfo: "Create Data Export button",
                  });

                  await createExport(DataExportType.SCHEDULED);

                  notifySuccess({
                    message: "Your export has been scheduled!",
                    caption: (
                      <>
                        When your export is done it will live in the{" "}
                        <Link
                          to={"./customers/documents"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Documents page.
                        </Link>
                      </>
                    ),
                  });

                  onClose();
                }}
              >
                Schedule export
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
