import { GenericExternalLink } from "@components/generic-external-link";
import { User, useUser } from "@components/user-context";
import {
  DismissAccountMaintenanceItem,
  DismissAccountMaintenanceItemVariables,
} from "@nb-api-graphql-generated/DismissAccountMaintenanceItem";
import { GetAccountMaintenanceItems_me_accountMaintenanceItems as AccountMaintenanceItem } from "@nb-api-graphql-generated/GetAccountMaintenanceItems";
import {
  formatNumberExact,
  getConnectionName,
  jsonHash,
} from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import { useNorthbeamMutation } from "@utils/hooks";
import classNames from "classnames";
import Interweave from "interweave";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {
  DISMISS_ACCOUNT_MAINTENANCE_ITEM,
  GET_ACCOUNT_MAINTENANCE_ITEMS,
} from "../queries";

export function CollapsibleMaintenanceItems({
  accountMaintenanceItems,
}: {
  accountMaintenanceItems: AccountMaintenanceItem[];
}) {
  const { user } = useUser();
  const [
    dismissAccountMaintenanceItem,
    { loading: dismissAccountMaintenanceItemLoading },
  ] = useNorthbeamMutation<
    DismissAccountMaintenanceItem,
    DismissAccountMaintenanceItemVariables
  >(DISMISS_ACCOUNT_MAINTENANCE_ITEM, {
    refetchQueries: [{ query: GET_ACCOUNT_MAINTENANCE_ITEMS }],
  });

  const groupedAccountMaintenanceItems = React.useMemo(() => {
    return _.chain(accountMaintenanceItems)
      .groupBy("type")
      .toPairs()
      .map(([type, items]) => ({ type, items }))
      .sortBy("type")
      .value();
  }, [accountMaintenanceItems]);

  return (
    <>
      {groupedAccountMaintenanceItems.map(({ type, items }) => {
        const Renderer = AccountMaintenanceItemTypeToRenderer[type];
        if (Renderer) {
          return (
            <Renderer
              key={type}
              items={items}
              dismissAccountMaintenanceItem={dismissAccountMaintenanceItem}
              dismissAccountMaintenanceItemLoading={
                dismissAccountMaintenanceItemLoading
              }
              user={user}
            />
          );
        }
        return null;
      })}
    </>
  );
}

interface GroupWrapperProps {
  title: React.ReactNode;
  lastUpdatedString: string;
  children: React.ReactNode | React.ReactNodeArray;
}

function GroupWrapper({
  title,
  lastUpdatedString,
  children,
}: GroupWrapperProps) {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className="nb-incident-object border rounded my-3">
      <div
        className={classNames("nb-incident-row nb-incident-top-row", {
          "border-bottom": expanded,
        })}
        onClick={() => {
          logEvent("Account Maintenance Item group expansion click", {});
          setExpanded(!expanded);
        }}
      >
        <div className="nb-icon-section">
          <i
            className={classNames({
              fas: true,
              "fa-angle-up": expanded,
              "fa-angle-down": !expanded,
            })}
          ></i>
        </div>
        <div className="nb-title-section">
          <div className="nb-title-section">
            <div className="flex items-center flex-grow-1">{title}</div>

            <div className="text-muted">Last updated: {lastUpdatedString}</div>
          </div>
        </div>
      </div>
      <div className={classNames("nb-incident-row", { "d-none": !expanded })}>
        {children}
      </div>
    </div>
  );
}

interface AccountMaintenanceItemGroupProps {
  dismissAccountMaintenanceItem: any;
  dismissAccountMaintenanceItemLoading: boolean;
  items: AccountMaintenanceItem[];
  user: User;
}

function NBPixelNotInstalled({
  items,
  dismissAccountMaintenanceItem,
  dismissAccountMaintenanceItemLoading,
}: AccountMaintenanceItemGroupProps) {
  const { user } = useUser();
  return (
    <GroupWrapper
      lastUpdatedString={lastUpdated(items)}
      title={
        <>
          🚨 <b>Northbeam Pixel not installed on Ads landing pages</b>
        </>
      }
    >
      <div className="nb-icon-section"></div>
      <div className="nb-details-section">
        <p>
          We noticed that there are ads driving traffic to these landing pages,
          but they don&apos;t have the Northbeam Pixel installed.
        </p>
        <p>
          In order to have the most accurate tracking, we&apos;ll want to have
          the Pixel installed on these pages. Without the Pixel installed, we
          will not be able to completely measure the performance of ads driving
          traffic to these pages.
        </p>
        <p>
          Please get in touch with Northbeam Customer Success at{" "}
          <a href="mailto:success@northbeam.io">success@northbeam.io</a> and we
          can help you get the Northbeam Pixel installed.
        </p>
        <table
          className="table table-sm table-bordered "
          style={{ fontSize: "14px" }}
        >
          <thead className="thead-light">
            <tr>
              <th scope="col">Landing page</th>
              <th scope="col">Associated Ads</th>
              <th scope="col">Spend to landing page in last three days</th>
              <th style={{ textAlign: "center" }} scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(items, (v) => -v.details.spendAmount.value).map((item) =>
              NBPixelNotInstalled.MaintenanceItemRow(
                item,
                dismissAccountMaintenanceItem,
                dismissAccountMaintenanceItemLoading,
                user,
              ),
            )}
          </tbody>
        </table>
      </div>
    </GroupWrapper>
  );
}

NBPixelNotInstalled.MaintenanceItemRow = function (
  item: AccountMaintenanceItem,
  dismissAccountMaintenanceItems: any,
  loading: boolean,
  user: User,
) {
  const { id: url } = JSON.parse(item.id);
  const adObjectDetails = item.details.adObjectDetails ?? [];
  return (
    <tr key={item.type + "--" + item.id}>
      <td>
        <div className="text-truncate" style={{ maxWidth: 300 }} title={url}>
          <GenericExternalLink href={url}>{url}</GenericExternalLink>
        </div>
      </td>
      <td>
        {adObjectDetails.length === 0 ? (
          <span className="text-muted">(none)</span>
        ) : (
          <ul>
            {adObjectDetails.map(({ name, adPlatformDeepLink, adKey }: any) => (
              <li key={jsonHash(adKey)}>
                {adPlatformDeepLink ? (
                  <GenericExternalLink href={adPlatformDeepLink}>
                    {name}
                  </GenericExternalLink>
                ) : (
                  name
                )}
              </li>
            ))}
          </ul>
        )}
      </td>
      <td>{formatNumberExact(item.details.spendAmount.value, "dollars")}</td>
      <td style={{ textAlign: "center" }}>
        <button
          className="btn btn-secondary btn-sm"
          disabled={loading}
          onClick={() =>
            dismissAccountMaintenanceItems({
              variables: {
                id: url,
                type: item.type,
                daysToDismiss: 14,
              },
            })
          }
        >
          Dismiss
        </button>
        {user.isAdmin && (
          <>
            <br />
            <button
              className="btn btn-warning btn-sm mt-3 mb-3"
              disabled={loading}
              onClick={() =>
                dismissAccountMaintenanceItems({
                  variables: {
                    id: url,
                    type: item.type,
                    daysToDismiss: 2000,
                  },
                })
              }
            >
              Dismiss forever
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

function AdObjectMissingNorthbeamTrackingParameter({
  items,
  dismissAccountMaintenanceItem,
  dismissAccountMaintenanceItemLoading,
}: AccountMaintenanceItemGroupProps) {
  const { user } = useUser();
  const groups = React.useMemo(
    () =>
      _.chain(items)
        .groupBy((v) => v.details.currentStatusSubcode)
        .toPairs()
        .map(([subcode, items]) => ({ subcode, items }))
        .value(),
    [items],
  );

  return (
    <>
      {groups.map(({ subcode, items }) => (
        <AdObjectMissingNorthbeamTrackingParameterSubsection
          items={items}
          subcode={subcode}
          dismissAccountMaintenanceItem={dismissAccountMaintenanceItem}
          dismissAccountMaintenanceItemLoading={
            dismissAccountMaintenanceItemLoading
          }
          key={subcode}
          user={user}
        />
      ))}
    </>
  );
}

function AdObjectMissingNorthbeamTrackingParameterSubsection({
  items,
  subcode,
  dismissAccountMaintenanceItem,
  dismissAccountMaintenanceItemLoading,
}: AccountMaintenanceItemGroupProps & { subcode: string }) {
  const { user } = useUser();

  let title = "Ads missing Northbeam tracking parameter";
  let body = (
    <>
      <p>
        We noticed that some of your ads don&apos;t have the Northbeam tracking
        parameter set.
      </p>
      <p>
        In order to have the most accurate tracking, we&apos;ll want to have the
        have tracking parameters set for these ads. Without it, we will not be
        able to completely measure the performance these ads.
      </p>
      <p>
        Please get in touch with Northbeam Customer Success at{" "}
        <a href="mailto:success@northbeam.io">success@northbeam.io</a> and we
        can help you add tracking parameters to these ads.
      </p>
    </>
  );

  if (subcode === "google_video_ad") {
    title = "YouTube Ads missing Northbeam tracking parameter";
    body = (
      <>
        <p>
          We noticed that some of your YouTube ads don&apos;t have the Northbeam
          tracking parameter set.
        </p>
        <p>
          In order to have the most accurate tracking, we&apos;ll want to have
          the have tracking parameters set for these ads. Without it, we will
          not be able to completely measure the performance these ads.
        </p>
        <p>
          To fix this, please add the Northbeam tracking parameter to the
          Account-level Final URL Suffix in your Google Ads account.
          Instructions are{" "}
          <a href="https://www.loom.com/share/62739ccc2bfe4989a8e0a4b14ffca5b8">
            here
          </a>
          .
        </p>
      </>
    );
  } else if (
    subcode === "fb_conflicting_parameter" ||
    subcode === "fb_missing_nbt"
  ) {
    title = "Facebook ads without Northbeam tracking parameter";
    body = (
      <>
        <p>
          We noticed that some of your Facebook ads don&apos;t have the
          Northbeam tracking parameter set.
        </p>
        <p>
          In order to have the most accurate tracking, we&apos;ll want to have
          the have tracking parameters set for these ads. Without it, we will
          not be able to completely measure the performance these ads.
        </p>
        <p>
          To fix this, please add the Northbeam tracking parameter to the
          following Facebook Ads. Instructions are{" "}
          <Link to={user.pathFromRoot("/url-builder")}>here</Link>.
        </p>
      </>
    );
  }

  return (
    <GroupWrapper title={<b>{title}</b>} lastUpdatedString={lastUpdated(items)}>
      <div className="nb-icon-section"></div>
      <div className="nb-details-section">
        {body}
        <table
          className="table table-sm table-bordered"
          style={{ fontSize: "14px" }}
        >
          <thead className="thead-light">
            <tr>
              <th scope="col">Ad name</th>
              <th scope="col">Current parameters</th>
              <th scope="col">Spend for last three days</th>
              <th style={{ textAlign: "center" }} scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(items, (v) => -v.details.spendAmount.value).map((item) =>
              AdObjectMissingNorthbeamTrackingParameterSubsection.MaintenanceItemRow(
                item,
                dismissAccountMaintenanceItem,
                dismissAccountMaintenanceItemLoading,
                user,
              ),
            )}
          </tbody>
        </table>
      </div>
    </GroupWrapper>
  );
}

AdObjectMissingNorthbeamTrackingParameterSubsection.MaintenanceItemRow =
  function (
    item: AccountMaintenanceItem,
    dismissAccountMaintenanceItems: any,
    loading: boolean,
    user: User,
  ) {
    const { name, spendAmount, inPlatformURL, currentParams } = item.details;

    let displayName = (
      <div className="text-truncate" style={{ maxWidth: 300 }} title={name}>
        {name}
      </div>
    );
    if (inPlatformURL) {
      displayName = (
        <div className="text-truncate" style={{ maxWidth: 300 }} title={name}>
          <GenericExternalLink href={inPlatformURL}>{name}</GenericExternalLink>
        </div>
      );
    }
    try {
      const jsonObject = JSON.parse(name);
      if (
        jsonObject.platform === "adwords" &&
        "account_id" in jsonObject &&
        !("campaign" in jsonObject)
      ) {
        displayName = (
          <>
            <div>
              We noticed that our tracking parameters are missing from the{" "}
              <b>Final Suffix URL</b> at the <b>Account Level</b> in your Google
              Ads Account.
            </div>
            <div className="mt-2">
              In order to have the most accurate tracking:
              <ul className="list-disc">
                <li>Insert the parameters in the Final Suffix URL</li>
                <li>Ensure your Tracking Template is empty </li>
              </ul>
              If there are existing parameters in your Tracking Template, please
              move them to the Final Suffix URL.
            </div>
            <div className="mt-2">
              To find out the difference between Tracking Templates and Final
              Suffix URL click{" "}
              <GenericExternalLink
                href={
                  "https://www.loom.com/share/fb50233d706046aa9a60c884c2c802a6"
                }
              >
                here
              </GenericExternalLink>
            </div>
            <div className="mt-2">
              Use this{" "}
              <GenericExternalLink
                href={
                  "https://www.loom.com/share/62739ccc2bfe4989a8e0a4b14ffca5b8"
                }
              >
                link
              </GenericExternalLink>{" "}
              to discover how to set tracking parameters at the{" "}
              <b>Account Level</b>
            </div>
          </>
        );
      }
    } catch (e) {
      // no op
    }
    return (
      <tr key={item.type + "--" + item.id}>
        <td>
          {displayName}
          {item.details.adKey.ad && (
            <div className="text-muted">ID: {item.details.adKey.ad}</div>
          )}
        </td>
        <td>
          <div style={{ maxWidth: 400 }} title={name}>
            <code>{currentParams}</code>
          </div>
        </td>
        <td>{formatNumberExact(spendAmount.value, "dollars")}</td>
        <td style={{ textAlign: "center" }}>
          <button
            className="btn btn-secondary btn-sm"
            disabled={loading}
            onClick={() =>
              dismissAccountMaintenanceItems({
                variables: {
                  id: JSON.parse(item.id).id,
                  type: item.type,
                  daysToDismiss: 14,
                },
              })
            }
          >
            Dismiss
          </button>
          {user.isAdmin && (
            <>
              <br />
              <button
                className="btn btn-warning btn-sm mt-3 mb-3"
                disabled={loading}
                onClick={() =>
                  dismissAccountMaintenanceItems({
                    variables: {
                      id: JSON.parse(item.id).id,
                      type: item.type,
                      daysToDismiss: 2000,
                    },
                  })
                }
              >
                Dismiss forever
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

function DNS({ items }: AccountMaintenanceItemGroupProps) {
  const { user } = useUser();
  const item = items[0];
  const domain = item.details.firstPartySubdomainName;

  let text =
    "We noticed you don't have a first party subdomain set up for Northbeam.";
  if (domain) {
    text = `We noticed you don't have <code>${domain}</code> set up to point to Northbeam.`;
  }

  return (
    <GroupWrapper
      title={
        <>
          <b>🚨 First party subdomain not set up</b>
        </>
      }
      lastUpdatedString={lastUpdated(items)}
    >
      <div className="nb-icon-section"></div>
      <div className="nb-details-section">
        <p>
          <Interweave content={text} />
        </p>
        <p>
          In order to have the most robust tracking, we&apos;ll want to have one
          of your subdomains pointed to Northbeam.
        </p>
        <p>
          Please follow{" "}
          <Link to={user.pathFromRoot("/settings/dns-entry")}>
            these instructions
          </Link>{" "}
          to have your subdomain pointed to Northbeam.
        </p>
      </div>
    </GroupWrapper>
  );
}

function ConnectionIssue({ items }: AccountMaintenanceItemGroupProps) {
  return (
    <>
      {items.map((item) => {
        const type = JSON.parse(item.id).id;
        const name = getConnectionName(type);
        const title = `${name} connection has issues`;

        return (
          <GroupWrapper
            key={type}
            title={
              <>
                <b>🚨 {title}</b>
              </>
            }
            lastUpdatedString={lastUpdated([item])}
          >
            <div className="nb-icon-section"></div>
            <div className="nb-details-section">
              <p>We noticed that your connection to {name} has issues.</p>
              <p>Please go to the Settings page to see more.</p>
            </div>
          </GroupWrapper>
        );
      })}
    </>
  );
}

function ShopifyPurchaseEventsTracked({
  items,
}: AccountMaintenanceItemGroupProps) {
  const { user } = useUser();
  const item = items[0];
  return (
    <>
      <GroupWrapper
        title={
          <>
            <b>🚨 Shopify purchase events aren&apos;t being tracked</b>
          </>
        }
        lastUpdatedString={lastUpdated([item])}
      >
        <div className="nb-icon-section"></div>
        <div className="nb-details-section">
          We&apos;ve noticed that your Shopify purchased events aren&apos;t
          being tracked. To resolve this issue, please add the{" "}
          <b>Shopify: order status page</b> tracking tag from{" "}
          <Link to={user.pathFromRoot("/settings/tracking-script-tags")}>
            these instructions
          </Link>
          .
        </div>
      </GroupWrapper>
    </>
  );
}

function KlaviyoEventsTracked({ items }: AccountMaintenanceItemGroupProps) {
  const { user } = useUser();
  const item = items[0];
  return (
    <>
      <GroupWrapper
        title={
          <>
            <b>🚨 Klaviyo events aren&apos;t being tracked</b>
          </>
        }
        lastUpdatedString={lastUpdated([item])}
      >
        <div className="nb-icon-section"></div>
        <div className="nb-details-section">
          We&apos;ve noticed that your Klaviyo events aren&apos;t being tracked.
          To resolve this issue, please add the Northbeam UTMs to Klaviyo. You
          can find these instructions in{" "}
          <Link to={user.pathFromRoot("/settings/utm-guide")}>
            the UTM Guide
          </Link>
          .
        </div>
      </GroupWrapper>
    </>
  );
}

function TestUrlsPixelNotInstalled({
  items,
}: AccountMaintenanceItemGroupProps) {
  const { user } = useUser();
  const item = items[0];
  return (
    <>
      <GroupWrapper
        title={
          <>
            <b>🚨 NB pixel not installed on landing pages</b>
          </>
        }
        lastUpdatedString={lastUpdated([item])}
      >
        <div className="nb-icon-section"></div>
        <div className="nb-details-section">
          <p>
            We&apos;ve noticed that the Northbeam pixel isn&apos;t installed on
            some of your brand&apos;s landing pages. To install the pixel,
            please review our{" "}
            <Link to={user.pathFromRoot("/settings/tracking-script-tags")}>
              documentation
            </Link>
            .
          </p>
          <div>
            URLs without the NB pixel installed:
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <GenericExternalLink
                    href={JSON.parse(item.id).id}
                    key={JSON.parse(item.id).id}
                  >
                    {JSON.parse(item.id).id}
                  </GenericExternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </GroupWrapper>
    </>
  );
}

function InfluencerSheetProcessingError({
  items,
}: AccountMaintenanceItemGroupProps) {
  return (
    <GroupWrapper
      title={
        <>
          <b>🚨 Influencer Spend spreadsheet has issues</b>
        </>
      }
      lastUpdatedString={lastUpdated([items[0]])}
    >
      <div className="nb-icon-section"></div>
      <div className="nb-details-section">
        <p>
          We&apos;ve noticed that errors occurred while processing one or more
          of your Influencers spreadsheets.
        </p>
        {items.map((item, index) => (
          <>
            <ul>
              <li>
                <GenericExternalLink href={item.details.sheet_url}>
                  Spreadsheet #{index + 1}
                </GenericExternalLink>
                :
              </li>
              <ul>
                {item.details.issues.map((issue: string) => (
                  <li key={issue}> {issue} </li>
                ))}
              </ul>
            </ul>
          </>
        ))}
      </div>
    </GroupWrapper>
  );
}

function ConversionPixelNotInstalled({
  items,
}: AccountMaintenanceItemGroupProps) {
  const { user } = useUser();
  const item = items[0];
  return (
    <>
      <GroupWrapper
        title={
          <>
            <b>🚨 Conversion pixel not installed</b>
          </>
        }
        lastUpdatedString={lastUpdated([item])}
      >
        <div className="nb-icon-section"></div>
        <div className="nb-details-section">
          <p>
            Northbeam has not tracked an order in the last 24 hours. If these is
            expected, you can ignore this alert. Otherwise, if you are on
            Shopify, please review our{" "}
            <Link to={user.pathFromRoot("/settings/tracking-script-tags")}>
              documentation
            </Link>
            . If you are not on Shopify, you will need to implement the{" "}
            <code>firePurchaseEvent</code>, which can be found{" "}
            <GenericExternalLink href="https://docs.google.com/document/d/1rJfqCOquxe_h1tZghdbmFiLFJywJhzOgfqbExH8eih0/edit">
              here
            </GenericExternalLink>
            .
          </p>
        </div>
      </GroupWrapper>
    </>
  );
}

function OrdersNotSynced({ items }: AccountMaintenanceItemGroupProps) {
  const { user } = useUser();
  const item = items[0];
  return (
    <>
      <GroupWrapper
        title={
          <>
            <b>🚨 Missing order details</b>
          </>
        }
        lastUpdatedString={lastUpdated([item])}
      >
        <div className="nb-icon-section"></div>
        <div className="nb-details-section">
          <p>
            We&apos;ve noticed that we are not receiving any order information
            for your brand. If you are on Shopify, please connect your account{" "}
            <Link to={user.pathFromRoot("/settings/connections")}>here</Link>.
            If you are not on Shopify, you will need to implement the server API{" "}
            <GenericExternalLink href="https://northbeam-orders-api.readme.io/docs">
              here
            </GenericExternalLink>
            .
          </p>
        </div>
      </GroupWrapper>
    </>
  );
}

function UTMsStrippedByWebsite({ items }: AccountMaintenanceItemGroupProps) {
  const item = items[0];
  return (
    <>
      <GroupWrapper
        title={
          <>
            <b>🚨 UTMs stripped by landing pages</b>
          </>
        }
        lastUpdatedString={lastUpdated([item])}
      >
        <div className="nb-icon-section"></div>
        <div className="nb-details-section">
          <p>
            We&apos;ve noticed that the Northbeam UTMs are being stripped by the
            following landing pages. This will negatively impact
            Northbeam&apos;s ability to track conversions that begin on these
            pages.
          </p>
          <div>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <GenericExternalLink
                    href={JSON.parse(item.id).id}
                    key={JSON.parse(item.id).id}
                  >
                    {JSON.parse(item.id).id}
                  </GenericExternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </GroupWrapper>
    </>
  );
}

function ConnectionIssueExternalFileProcessor({
  items,
}: AccountMaintenanceItemGroupProps) {
  return (
    <>
      {items.map((item) => {
        const type = _.startCase(JSON.parse(item.id).id.replace("-", " "));
        const title = `Connection ${type} has issues`;

        return (
          <GroupWrapper
            key={type}
            title={
              <>
                <b>🚨 {title}</b>
              </>
            }
            lastUpdatedString={lastUpdated([item])}
          >
            <div className="nb-icon-section"></div>
            <div className="nb-details-section">
              <p>We noticed that your connection to {type} has issues.</p>
              <p>
                Please get in touch with Northbeam Customer Success at{" "}
                <a href="mailto:success@northbeam.io">success@northbeam.io</a>{" "}
                and we can help you get the connection fixed.
              </p>
            </div>
          </GroupWrapper>
        );
      })}
    </>
  );
}

function lastUpdated(items: AccountMaintenanceItem[]) {
  return _.chain(items)
    .map((v) => moment(v.updatedAt))
    .min()
    .value()
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .fromNow();
}

const AccountMaintenanceItemTypeToRenderer: Record<
  string,
  React.FunctionComponent<AccountMaintenanceItemGroupProps>
> = {
  NBPixelNotInstalled,
  AdObjectMissingNorthbeamTrackingParameter,
  DNS,
  ConnectionIssue,
  ShopifyPurchaseEventsTracked,
  KlaviyoEventsTracked,
  TestUrlsPixelNotInstalled,
  InfluencerSheetProcessingError,
  ConversionPixelNotInstalled,
  OrdersNotSynced,
  UTMsStrippedByWebsite,
  ConnectionIssueExternalFileProcessor,
};
