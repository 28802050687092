import "animate.css";
import React from "react";
import StepWizard from "react-step-wizard";
import { AccountManagerStep } from "./account-manager-step";
import { BillingStep } from "./billing-step";
import { CompletedStep } from "./completed-step";
import { Connections } from "./connections";
import { StepName } from "./definitions";
import { DnsEntryStep } from "./dns-entry-step";
import { DomainCollection } from "./domain-collection";
import { GoogleAnalyticsParameterExclusionsStep } from "./google-analytics-parameter-exclusions-step";
import { IntroductoryVideo } from "./introductory-video";
import { LinkTrackingStep } from "./link-tracking-step";
import { OnboardingNav } from "./onboarding-nav";
import { PixelPlacementSteps } from "./pixel-placement";
import { UserProfileCollectionStep } from "./user-profile-collection-step";

export const Onboarding = ({
  onLogout,
  loading,
}: {
  onLogout: () => void;
  loading: boolean;
}) => {
  if (loading) {
    return <></>;
  }

  return (
    <StepWizard
      isHashEnabled
      isLazyMount
      nav={<OnboardingNav onLogout={onLogout} />}
      transitions={{
        enterRight: "",
        enterLeft: "",
        exitRight: "",
        exitLeft: "",
      }}
      className="flex h-screen w-screen onboarding-wizard-container items-center"
    >
      <IntroductoryVideo
        stepName={StepName.INTRO_VIDEO}
        hashKey={StepName.INTRO_VIDEO}
      />
      <DomainCollection
        stepName={StepName.INTRODUCTION}
        hashKey={StepName.INTRODUCTION}
      />
      <UserProfileCollectionStep
        stepName={StepName.BASIC_DETAILS}
        hashKey={StepName.BASIC_DETAILS}
      />
      <PixelPlacementSteps
        stepName={StepName.PIXEL_PLACEMENT}
        hashKey={StepName.PIXEL_PLACEMENT}
      />
      <DnsEntryStep
        stepName={StepName.DNS_SETUP}
        hashKey={StepName.DNS_SETUP}
      />
      <GoogleAnalyticsParameterExclusionsStep
        stepName={StepName.GOOGLE_ANALYTICS}
        hashKey={StepName.GOOGLE_ANALYTICS}
      />
      <Connections
        stepName={StepName.INTEGRATIONS}
        hashKey={StepName.INTEGRATIONS}
      />
      <LinkTrackingStep stepName={StepName.UTMS} hashKey={StepName.UTMS} />
      <BillingStep stepName={StepName.BILLING} hashKey={StepName.BILLING} />
      <AccountManagerStep
        stepName={StepName.ACCOUNT_MANAGERS}
        hashKey={StepName.ACCOUNT_MANAGERS}
      />
      <CompletedStep stepName={StepName.COMPLETE} hashKey={StepName.COMPLETE} />
    </StepWizard>
  );
};
