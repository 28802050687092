import { gql } from "@apollo/client";
import { LoadingSlide } from "@components/title-slide-view";
import { GenericTooltip } from "@components/tooltip-container";
import { H1 } from "@components/utilities";
import { AdditionalManagedDomains as AdditionalManagedDomainsQuery } from "@nb-api-graphql-generated/AdditionalManagedDomains";
import {
  UpdateAdditionalManagedDomains,
  UpdateAdditionalManagedDomainsVariables,
} from "@nb-api-graphql-generated/UpdateAdditionalManagedDomains";
import { verifyDomain } from "@utils/domain-verification";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

interface domainNameAndErrorObject {
  domainName: string;
  error: string;
}

export function AdditionalManagedDomainsInput() {
  const { addToast } = useToasts();
  const [additionalManagedDomains, setAdditionalManagedDomains] = useState<
    domainNameAndErrorObject[]
  >([]);

  const { loading, data } = useNorthbeamQuery<AdditionalManagedDomainsQuery>(
    ADDITIONAL_MANAGED_DOMAINS_QUERY,
  );

  useEffect(() => {
    setAdditionalManagedDomains(
      (data?.me?.additionalManagedDomains || []).map((domainName: string) => {
        return { domainName, error: "" };
      }),
    );
  }, [setAdditionalManagedDomains, data]);

  const [
    updateAdditionalManagedDomains,
    { loading: updateAdditionalManagedDomainsLoading },
  ] = useNorthbeamMutation<
    UpdateAdditionalManagedDomains,
    UpdateAdditionalManagedDomainsVariables
  >(UPDATE_ADDITIONAL_MANAGED_DOMAINS, {
    refetchQueries: [{ query: ADDITIONAL_MANAGED_DOMAINS_QUERY }],
  });

  const onSubmitClicked = async () => {
    const newDomains = additionalManagedDomains.map((domainObject) => {
      const error = verifyDomain(domainObject.domainName, {
        exclude: ["myshopify.com"],
      });

      if (error) {
        return {
          domainName: domainObject.domainName,
          error: error,
        };
      } else {
        return { domainName: domainObject.domainName, error: "" };
      }
    });

    setAdditionalManagedDomains(newDomains);

    if (newDomains.filter((obj) => obj.error !== "").length === 0) {
      await updateAdditionalManagedDomains({
        variables: {
          additionalManagedDomains: newDomains.map(
            (domainObject) => domainObject.domainName,
          ),
        },
      });
      addToast("Succesfully updated managed domains", {
        appearance: "success",
      });
    }
  };

  const onAddNew = () => {
    const copyOfAdditionalManagedDomain = [...additionalManagedDomains];
    copyOfAdditionalManagedDomain.push({ domainName: "", error: "" });
    setAdditionalManagedDomains(copyOfAdditionalManagedDomain);
  };

  const setDomainNameForIndex = (domainName: string, index: number) => {
    const copyOfAdditionalManagedDomain = [...additionalManagedDomains];
    copyOfAdditionalManagedDomain[index] = { domainName, error: "" };
    setAdditionalManagedDomains(copyOfAdditionalManagedDomain);
  };

  return (
    <>
      <H1>Additional managed domains</H1>
      <hr />
      {loading && <LoadingSlide />}
      {!loading && (
        <>
          {additionalManagedDomains.map(
            (domainObject: domainNameAndErrorObject, index: number) => (
              <div className="row col-8 mb-3" key={index.toString()}>
                <div className="py-2 my-2">
                  <div className="control-label">
                    <GenericTooltip content="Your domain will look something like northbeam.io, google.com, or bbc.co.uk">
                      Domain name
                    </GenericTooltip>
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">https://</div>
                    </div>
                    <input
                      type="text"
                      disabled={updateAdditionalManagedDomainsLoading}
                      className="form-control"
                      value={domainObject.domainName}
                      onChange={(event) =>
                        setDomainNameForIndex(
                          event.target.value.toLowerCase(),
                          index,
                        )
                      }
                    />
                  </div>
                  <p style={{ color: "red" }}>{domainObject.error}</p>
                </div>
              </div>
            ),
          )}

          <div className="row col-8 justify-content-center">
            <div className="py-2 my-2">
              <button
                disabled={updateAdditionalManagedDomainsLoading}
                tabIndex={70}
                className="btn btn-secondary btn-block"
                onClick={onAddNew}
              >
                Add new domain
              </button>
            </div>
          </div>
          <div className="row col-8">
            <div className="py-2 my-2">
              <button
                disabled={updateAdditionalManagedDomainsLoading}
                tabIndex={70}
                className="btn btn-primary btn-block"
                onClick={onSubmitClicked}
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const ADDITIONAL_MANAGED_DOMAINS_QUERY = gql`
  query AdditionalManagedDomains {
    me {
      additionalManagedDomains
    }
  }
`;

const UPDATE_ADDITIONAL_MANAGED_DOMAINS = gql`
  mutation UpdateAdditionalManagedDomains(
    $additionalManagedDomains: [String!]!
  ) {
    updateAdditionalManagedDomains(
      additionalManagedDomains: $additionalManagedDomains
    )
  }
`;
