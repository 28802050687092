import { AdIcon, IconContainer } from "@pages/objects/utils";
import { PlatformFriendlyNames } from "@/constants/platform-friendly-names";
import moment from "moment";
import React from "react";
import { MetricFormats } from "@utils/metrics";
import { reasonableGuessAtNaming } from "@utils/pretty-names";

type AdPlatformTouchpoint = {
  type: "ad-platform";
  date: Date;

  platform: string;
  campaignName: string;
  adsetName: string;
  adName: string;
};

type EmailTouchpoint = {
  type: "email";
  date: Date;

  campaignName: string;
};

type ReferralTouchpoint = {
  type: "referral";
  date: Date;

  source: string;
};

type InfluencerTouchpoint = {
  type: "influencer";
  date: Date;

  handle: string;
  platform: string | undefined;
};

type UTMTouchpoint = {
  type: "utm";
  date: Date;

  platform: string;
  source: string;
  campaignName: string;
  medium: string;
  content: string;
};

type KlaviyoTouchpoint = {
  type: "klaviyo";
  date: Date;
};

type DirectVisitTouchpoint = {
  type: "direct-visit";
  date: Date;
};

type PurchaseTouchpoint = {
  type: "purchase";
  date: Date;

  amountInDollars: number;
  items: string[];
};

export type CustomerTouchpoint =
  | AdPlatformTouchpoint
  | DirectVisitTouchpoint
  | PurchaseTouchpoint
  | EmailTouchpoint
  | ReferralTouchpoint
  | InfluencerTouchpoint
  | UTMTouchpoint
  | KlaviyoTouchpoint;

export type CustomerJourneyProps = {
  touchpoints: CustomerTouchpoint[];
};

export function formatDate(date: Date) {
  return moment(date).format("MM/DD/YYYY | h:mm A z");
}

export function JourneyCard({
  touchpoint,
}: {
  touchpoint: CustomerTouchpoint;
}) {
  const journeyCardClassname = "w-full rounded-md p-4 break-all";
  const detailsClassname = "grid gap-1 grid-cols-card auto-rows-auto ml-10";
  const detailHeading = "mr-4 font-semibold	";

  if (touchpoint.type === "referral") {
    return (
      <div
        className={journeyCardClassname}
        style={{
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F3BB1C",
        }}
      >
        <div className="flex flex-row mb-3 ">
          <IconContainer sizeInEM={1.5}>
            <i className="fa-regular fa-arrows-left-right" />
          </IconContainer>

          <div className="ml-[0.20rem] font-semibold">Referral</div>
          <div className="ml-auto">{formatDate(touchpoint.date)}</div>
        </div>
        <div className={detailsClassname}>
          <div className={detailHeading}>Source:</div>
          <div>{touchpoint.source}</div>
        </div>
      </div>
    );
  }

  if (touchpoint.type === "email") {
    return (
      <div
        className={journeyCardClassname}
        style={{
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F3BB1C",
        }}
      >
        <div className="flex flex-row mb-3 ">
          <IconContainer sizeInEM={1.5}>
            <i className="fa-light fa-envelope" />
          </IconContainer>

          <div className="ml-[0.20rem] font-semibold">Email</div>
          <div className="ml-auto">{formatDate(touchpoint.date)}</div>
        </div>
        <div className={detailsClassname}>
          <div className={detailHeading}>Campaign:</div>
          <div>{touchpoint.campaignName}</div>
        </div>
      </div>
    );
  }

  if (touchpoint.type === "klaviyo") {
    return (
      <div
        className={journeyCardClassname}
        style={{
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F3BB1C",
        }}
      >
        <div className="flex flex-row mb-3 ">
          <IconContainer sizeInEM={1.5}>
            <i
              className="fa-solid fa-wifi "
              style={{
                color: "#92C84E",
              }}
            />
          </IconContainer>

          <div className="ml-[0.20rem] font-semibold">Klaviyo</div>
          <div className="ml-auto">{formatDate(touchpoint.date)}</div>
        </div>
      </div>
    );
  }

  if (touchpoint.type === "utm") {
    return (
      <div
        className={journeyCardClassname}
        style={{
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #34A3E1",
        }}
      >
        <div className="flex flex-row mb-3 ">
          <IconContainer sizeInEM={1.5}>
            <i className="fa-regular fa-link-simple" />
          </IconContainer>

          <div className="ml-[0.20rem] font-semibold">
            {PlatformFriendlyNames[touchpoint.platform] ||
              reasonableGuessAtNaming(touchpoint.platform || touchpoint.source)}
          </div>
          <div className="ml-auto">{formatDate(touchpoint.date)}</div>
        </div>
        <div className={detailsClassname}>
          <div className={detailHeading}>Campaign:</div>
          <div>{touchpoint.campaignName}</div>

          <div className={detailHeading}>Medium:</div>
          <div>{touchpoint.medium}</div>

          <div className={detailHeading}>Content:</div>
          <div>{touchpoint.content}</div>
        </div>
      </div>
    );
  }

  if (touchpoint.type === "influencer") {
    return (
      <div
        className={journeyCardClassname}
        style={{
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3CC13B",
        }}
      >
        <div className="flex flex-row mb-3 ">
          <IconContainer sizeInEM={1.5}>
            <i className="fa-regular fa-link-simple" />
          </IconContainer>

          <div className="ml-[0.20rem] font-semibold">
            Influencer: @{touchpoint.handle}
            {touchpoint.platform
              ? ` on ${
                  PlatformFriendlyNames[touchpoint.platform] ||
                  reasonableGuessAtNaming(touchpoint.platform)
                }`
              : ""}
          </div>
          <div className="ml-auto">{formatDate(touchpoint.date)}</div>
        </div>
      </div>
    );
  }

  if (touchpoint.type === "ad-platform") {
    return (
      <div
        className={journeyCardClassname}
        style={{
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F3BB1C",
        }}
      >
        <div className="flex flex-row mb-3 ">
          <AdIcon
            platform={touchpoint.platform}
            sizeInEM={1.5}
            defaultIcon={<i className="fa-light fa-layer-group"></i>}
          />
          <div className="ml-[0.20rem] font-semibold">
            {PlatformFriendlyNames[
              touchpoint.platform
                .toLowerCase()
                .trim() as keyof typeof PlatformFriendlyNames
            ] ?? reasonableGuessAtNaming(touchpoint.platform)}
            : Ad clicked
          </div>
          <div className="ml-auto">{formatDate(touchpoint.date)}</div>
        </div>
        <div className={detailsClassname}>
          <div className={detailHeading}>Campaign:</div>
          <div>{touchpoint.campaignName}</div>

          <div className={detailHeading}>Ad set:</div>
          <div>{touchpoint.adsetName}</div>

          <div className={detailHeading}>Ad:</div>
          <div>{touchpoint.adName}</div>
        </div>
      </div>
    );
  }

  if (touchpoint.type === "purchase") {
    return (
      <div
        className={journeyCardClassname}
        style={{
          background: "#F2F3F4",
        }}
      >
        <div className="flex flex-row mb-3 ">
          <div className="ml-10 font-semibold">Purchase</div>
          <div className="ml-auto">{formatDate(touchpoint.date)}</div>
        </div>
        <div className={detailsClassname}>
          <div className={detailHeading}>Amount:</div>
          <div>{MetricFormats.dollars.exact(touchpoint.amountInDollars)}</div>

          <div className={detailHeading}>Items</div>
          <div>
            {touchpoint.items.map((item, itemNumber) => (
              <div key={`${itemNumber}`}>{item}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  // otherwise it is a direct visit
  return (
    <div
      className={journeyCardClassname}
      style={{
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F03738",
      }}
    >
      <div className="flex flex-row mb-3 ">
        <i className="fa-solid fa-arrow-right-long-to-line mr-4 self-center" />
        <div className="ml-[0.20rem] font-semibold">Direct Visit</div>
        <div className="ml-auto">{formatDate(touchpoint.date)}</div>
      </div>
    </div>
  );
}
