import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { relativeToRoot, resolvePath } from ".";
import { logEvent } from "./analytics";

interface RedirectLoggingProps {
  to: (params: any) => string;
  eventName: string;
  paramsToData?: (params: any) => any;
}

const RedirectWithLogging = ({
  eventName,
  to,
  paramsToData,
}: RedirectLoggingProps) => {
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    const data = {};
    if (paramsToData) {
      Object.assign(data, paramsToData(params));
    } else {
      Object.assign(data, params);
    }
    Object.assign(data, {
      search: searchToObject(location.search),
    });
    logEvent(eventName, data);
  }, [params, paramsToData, eventName, location.search]);

  return <Navigate to={to(params)} />;
};

export const Redirects = () => {
  const { pathname } = useLocation();
  return (
    <Routes>
      <Route
        path="incidents"
        element={
          <RedirectWithLogging
            to={() => relativeToRoot(`/monitor/incidents`)}
            eventName="Redirect Link to Incidents page"
          />
        }
      />
      <Route
        path="incident/:id/:subjectItem/:runAt"
        element={
          <RedirectWithLogging
            to={({ id, runAt, subjectItem }) =>
              relativeToRoot(
                `/monitor/view/${id}/incidents/${subjectItem}/${runAt}`,
              )
            }
            eventName="Redirect Link to Incident page"
            paramsToData={({ id, runAt, subjectItem }) => {
              let si = null;
              try {
                si = JSON.parse(atob(subjectItem));
              } catch {
                si = null;
              }
              return {
                "Alert ID": id,
                "Subject Item": si,
                "Incident Start At": runAt,
              };
            }}
          />
        }
      />
      <Route
        path="monitor/:id/:runAt"
        element={
          <RedirectWithLogging
            to={({ id, runAt }) =>
              resolvePath(`${pathname}/../monitor/view/${id}/runs/${runAt}`)
            }
            eventName="Redirect Link to Alert Run"
            paramsToData={({ id, runAt }) => ({
              "Alert ID": id,
              "Alert Run At": runAt,
            })}
          />
        }
      />
      <Route
        path="objects/:id"
        element={
          <RedirectWithLogging
            to={({ id }) => resolvePath(`${pathname}/../objects/${id}`)}
            eventName="Redirect Link to Object"
          />
        }
      />
    </Routes>
  );
};

const searchToObject = (search: string) => {
  const usp = new URLSearchParams(search);
  const rv: Record<string, string> = {};
  for (const [key, value] of usp) {
    rv[key] = value;
  }
  return rv;
};
