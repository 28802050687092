import classNames from "classnames";
import React from "react";
import { Navigate, NavLink, Outlet, Route, Routes } from "react-router-dom";
import { IncidentPage } from "../incident-page";
import { AlertHistoryPane } from "./alert-history-pane";
import {
  AlertIncidentsPane,
  AlertIncidentsPaneProps,
} from "./alert-incidents-pane";
import {
  AlertSimulatePane,
  AlertSimulatePaneProps,
} from "./alert-simulate-pane";

type Mode = "view" | "edit";

export type ContentPaneProps = AlertSimulatePaneProps &
  AlertIncidentsPaneProps & {
    mode: Mode;
  };

export const ContentPane = ({ id, mode, preview }: ContentPaneProps) => (
  <Routes>
    <Route element={<ContentLayout mode={mode} />}>
      <Route path="/" element={<Navigate to="incidents" replace />} />
      <Route path="incidents" element={<AlertIncidentsPane id={id} />} />
      <Route
        path="simulate"
        element={<AlertSimulatePane preview={preview} id={id} />}
      />
      <Route
        path="incidents/:subjectItemSlug/:startAt"
        element={<IncidentPage alertConfigId={id!} />}
      />
      <Route path="history" element={<AlertHistoryPane id={id} />} />
    </Route>
  </Routes>
);

const ContentLayout = ({ mode }: { mode: Mode }) => {
  const availableTabs =
    mode === "view" ? ["Incidents", "History", "Simulate"] : ["Simulate"];

  return (
    <div
      className="nb-control-center-companion-content"
      style={{ overflowY: "unset", overflowX: "unset" }}
    >
      <nav className="navbar py-2 border-bottom">
        <ul className="nav nav-pills">
          {availableTabs.map((tab) => {
            const to = tab.toLowerCase();
            return (
              <li className="nav-item" key={to}>
                <NavLink
                  replace
                  className={({ isActive }) =>
                    classNames({
                      "tab-link": 1,
                      "mx-1": 1,
                      active: isActive,
                    })
                  }
                  to={to}
                >
                  {tab}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <Outlet />
    </div>
  );
};
