import { GenericTooltip } from "@components/tooltip-container";
import { DeleteIconButton } from "@shared/buttons";
import { TextInput } from "@shared/text-input";
import { uniqueFromList, uniqueFromListWithSelf } from "@shared/validators";
import React from "react";
import { UseFormRegister } from "react-hook-form";
import { LabelBuilderData } from "./label-builder";

const NORTHBEAM_EXISTING_LABELS = [
  "Platform (Northbeam)",
  "Targeting (Northbeam)",
  "Category (Northbeam)",
];

interface LabelNameProps {
  register: UseFormRegister<LabelBuilderData>;
  categoryIndex: number;
  errors: any;
  deleteLabel: () => void;
  existingLabelNames: string[];
}

export const LabelName = ({
  register,
  categoryIndex,
  errors,
  deleteLabel,
  existingLabelNames,
}: LabelNameProps) => {
  return (
    <>
      <TextInput
        {...register(`data.${categoryIndex}.name`, {
          required: "Name is required",
          validate: {
            uniqueFromNb: uniqueFromList({
              compareValues: NORTHBEAM_EXISTING_LABELS,
              errorMessage:
                "Cannot overwrite existing Northbeam breakdown labels",
            }),
            unique: uniqueFromListWithSelf({
              compareValues: existingLabelNames,
              errorMessage: "Label name must be unique",
            }),
            atLeastOneOption: () => {
              // Custom error coming from the LabelOptions component (useFieldArray)
              const errorMessage =
                errors?.data?.[categoryIndex]?.options?.root?.message;
              return errorMessage;
            },
          },
        })}
        errors={errors}
        label={
          <GenericTooltip
            content={
              <>
                This will be the breakdown shown in your &ldquo;Breakdown
                by&rdquo; dropdown on the Sales page. <br /> <i>Example:</i>{" "}
                Name the breakdown &ldquo;Location&rdquo; if you wish to compare
                ad sets which target different locations.
              </>
            }
            overlayClass=""
            captureEventString="HoverOverBreakdownName"
          >
            Breakdown Name
          </GenericTooltip>
        }
        width="50%"
        maxWidth="500px"
      />
      <DeleteIconButton
        onClick={deleteLabel}
        data-test={`category.${categoryIndex}.deleteButton`}
        className="ml-2"
      />
    </>
  );
};
