import fbLogo from "@assets/fb-logo.svg";
import gaLogo from "@assets/ga-logo.svg";
import tiktokLogo from "@assets/tiktok-logo.svg";

import { HTMLTooltip } from "@components/tooltip-container";
import { TableHeaderRow } from "@devexpress/dx-react-grid-bootstrap4";
import React from "react";

// This implementation is a copy of Devextreme's TableHeaderRow.Content with HTMLTooltip

export const TableHeaderRowContent = ({
  column,
  children,
  ...restProps
}: any) => (
  <TableHeaderRow.Content
    className="w-full"
    column={column as any}
    {...restProps}
  >
    {column.name.toLowerCase().includes("fb ") && (
      <img src={fbLogo} width="20px" className="mr-2" alt="Meta Logo" />
    )}
    {column.name.toLowerCase().includes("google ") && (
      <img src={gaLogo} width="20px" className="mr-2" alt="Google Logo" />
    )}
    {column.name.toLowerCase().includes("tiktok ") && (
      <img src={tiktokLogo} width="20px" className="mr-2" alt="TikTok Logo" />
    )}
    {column.tooltip ? (
      <HTMLTooltip
        className="overflow-hidden text-ellipsis w-full"
        html={column.tooltip}
        noInfoCircle
      >
        {children}
      </HTMLTooltip>
    ) : (
      children
    )}
  </TableHeaderRow.Content>
);
