import DataExportGcs from "@assets/data-export-gcs.svg";
import NorthbeamLogo from "@assets/northbeam-error-screen-logo.svg";
import { PrimaryButton } from "@shared/buttons";
import React from "react";

export function GCSConfigurator() {
  return (
    <div className="h-full grid grid-cols-[14rem_auto] overflow-hidden relative">
      <div className="bg-[#F5F7FDB2]"></div>

      <div className="px-20 py-10 pr-[100px] lg:pr-[200px] xl:pr-[400px] overflow-scroll">
        <h3 className="text-base font-semibold m-0 mb-6 flex items-center">
          <img
            className="w-[120px] ml-10"
            alt="northbeam-logo"
            src={NorthbeamLogo}
          />

          <i className="fa-solid fa-plus text-[0.7rem] text-gray-500 mx-4"></i>

          <img className="w-[30px]" alt="gcs-logo" src={DataExportGcs} />
        </h3>
        <p className="text-xs text-zinc-500 font-light m-0 mb-10">
          Connect your data export to automatically sync to your Google Cloud
          bucket in two easy steps:{" "}
          <a className="font-light text-blue-500" href="/todo">
            Access our full instruction guide here
          </a>
        </p>

        <h4 className="text-[0.8rem] font-semibold mb-10">
          Access your Google Cloud bucket and do the following:
        </h4>

        <div className="border-l border-gray-200 pl-6 pb-7 mb-8 ml-[9px] relative">
          <div
            className="absolute left-[-19px] top-[-11px] w-[38px] h-[38px] text-[0.6rem]
                       text-white bg-blue-500 rounded-full flex flex-col
                       border-[10px] border-white
                       items-center justify-center"
          >
            1
          </div>

          <h4 className="text-[0.8rem] font-semibold mb-4">
            Grant access to Northbeam
          </h4>

          <p className="text-xs text-zinc-500 font-light m-0 mb-5">
            Type the following email listed below in the &apos;Add
            principals&apos; area.
          </p>
          <p className="text-xs text-zinc-500 font-light underline m-0">
            northbeam_service@nortbeam.io
          </p>
        </div>

        <div className="border-l border-gray-200 pl-6 ml-[9px] relative">
          <div
            className="absolute left-[-19px] top-[-11px] w-[38px] h-[38px] text-[0.6rem]
                       text-white bg-blue-500 rounded-full flex flex-col
                       border-[10px] border-white
                       items-center justify-center"
          >
            2
          </div>

          <h4 className="text-[0.8rem] font-semibold mb-4">Add your bucket</h4>

          <p className="text-xs text-zinc-500 font-light m-0 mb-4">
            Add your bucket name you would like to export the data into, then
            click validate.
          </p>

          <div className="mb-8">
            <input
              className="w-[70%] text-xs font-light shadow-none border border-gray-300
                       focus:border-blue-500 rounded-md px-3 py-2 outline-0"
              type="text"
            />
          </div>

          <PrimaryButton
            className="font-light text-xs pt-1.5 pb-1.5 pl-4 pr-4 border border-[#367BF3]"
            isDisabled={false}
          >
            Validate
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
