import { BreakdownSelector } from "@components/reports/breakdown-selector";
import styled from "@emotion/styled";
import {
  AlarmConditionV2,
  IncidentTemplates,
  Subject,
} from "@north-beam/nb-common";
import { BreakdownConfig } from "@north-beam/nb-common";
import { MetricFragment } from "@north-beam/nb-common";
import { toReactSelectLabelMulti, UnreachableCaseError } from "@utils/index";
import React from "react";
import CreatableSelect from "react-select/creatable";
import {
  AlarmConditionsSelectorRoot,
  EditorContext,
  EditorContextType,
  makeDefaultAlarmCondition,
  useCtx,
} from "./alarm-condition-selector";

export interface AlertConfigState {
  id?: string;
  subject: Subject;
  alarmCondition: AlarmConditionV2;
  extraEmailRecipients: string[];
  excludedEmailRecipients: string[];
  name: string;
  incidentTemplates: IncidentTemplates;
}

interface UpdateSubject {
  type: "UpdateSubject";
  value: Subject;
}

interface UpdateAlarmCondition {
  type: "UpdateAlarmCondition";
  value: AlarmConditionV2;
}

interface UpdateExtraEmailRecipients {
  type: "UpdateExtraEmailRecipients";
  value: string[];
}

interface UpdateExcludedEmailRecipients {
  type: "UpdateExcludedEmailRecipients";
  value: string[];
}

interface UpdateName {
  type: "UpdateName";
  value: string;
}

interface UpdateIncidentTemplates {
  type: "UpdateIncidentTemplates";
  value: IncidentTemplates;
}

type UpdateAction =
  | UpdateSubject
  | UpdateName
  | UpdateIncidentTemplates
  | UpdateAlarmCondition
  | UpdateExtraEmailRecipients
  | UpdateExcludedEmailRecipients;

function AlertConfigReducer(
  state: AlertConfigState,
  action: UpdateAction,
): AlertConfigState {
  const { type } = action;
  const value = action.value as any;
  switch (type) {
    case "UpdateSubject":
      return { ...state, subject: value };
    case "UpdateAlarmCondition":
      return { ...state, alarmCondition: value };
    case "UpdateExtraEmailRecipients": {
      return { ...state, extraEmailRecipients: value };
    }
    case "UpdateExcludedEmailRecipients": {
      return { ...state, excludedEmailRecipients: value };
    }
    case "UpdateName": {
      return { ...state, name: value };
    }
    case "UpdateIncidentTemplates": {
      return { ...state, incidentTemplates: value };
    }
    default:
      throw new UnreachableCaseError(type);
  }
}

export interface AlertConfigEditorProps {
  isSaving: boolean;
  initialState?: AlertConfigState;
  metrics: MetricFragment[];
  breakdowns: BreakdownConfig[];
  onSubmit: (update: AlertConfigState) => void;
  onPartialUpdate?: (update: AlertConfigState) => void;
}

export function makeDefaultState(metrics: MetricFragment[]): AlertConfigState {
  return {
    subject: { type: "all" },
    name: "",
    extraEmailRecipients: [],
    excludedEmailRecipients: [],
    alarmCondition: makeDefaultAlarmCondition(metrics),
    incidentTemplates: {},
  };
}

export function AlertConfigEditor({
  isSaving,
  initialState,
  breakdowns,
  metrics,
  onSubmit,
  onPartialUpdate,
}: AlertConfigEditorProps) {
  const [state, dispatch] = React.useReducer(
    AlertConfigReducer,
    initialState ?? makeDefaultState(metrics),
  );

  const context: EditorContextType = {
    isSaving,
    isDisabled: isSaving,
    metrics,
    breakdowns,
  };

  React.useEffect(() => {
    if (onPartialUpdate) {
      onPartialUpdate(state);
    }
  }, [state, onPartialUpdate]);

  const {
    name,
    subject,
    alarmCondition,
    incidentTemplates,
    excludedEmailRecipients,
  } = state;

  const updateName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      dispatch({ type: "UpdateName", value });
    },
    [dispatch],
  );

  const updateExcludedEmailRecipients = React.useCallback(
    (value: string[]) => {
      dispatch({ type: "UpdateExcludedEmailRecipients", value });
    },
    [dispatch],
  );

  const updateIncidentTemplates = React.useCallback(
    (value: IncidentTemplates) => {
      dispatch({ type: "UpdateIncidentTemplates", value });
    },
    [dispatch],
  );

  return (
    <EditorContext.Provider value={context}>
      <Style>
        <div className="my-3">
          <SubjectSelector
            commitValue={(value) => dispatch({ type: "UpdateSubject", value })}
            value={subject}
          />
        </div>
        <div className="my-3">
          <div className="card">
            <div className="card-header">
              <strong>Alarm conditions</strong>
            </div>
            <div className="card-body">
              <AlarmConditionsSelectorRoot
                setSelf={(value) =>
                  dispatch({ type: "UpdateAlarmCondition", value })
                }
                value={alarmCondition}
                metrics={metrics}
              />
            </div>
          </div>
        </div>
        <div className="my-3">
          <div className="card">
            <div className="card-header">
              <strong>Incident templates (optional)</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col flex flex-col">
                  <div className="nb-side-label text-muted">
                    <small>Description</small>
                  </div>
                  <div className="flex-fill">
                    <textarea
                      tabIndex={60}
                      disabled={context.isDisabled}
                      className="form-control"
                      value={incidentTemplates.description ?? ""}
                      onChange={(e) =>
                        updateIncidentTemplates({
                          ...incidentTemplates,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col flex flex-col">
                  <div className="nb-side-label text-muted">
                    <small>Click-to-expand section</small>
                  </div>
                  <div className="flex-fill">
                    <textarea
                      tabIndex={60}
                      disabled={context.isDisabled}
                      className="form-control"
                      value={incidentTemplates.clickToExpandSection ?? ""}
                      onChange={(e) =>
                        updateIncidentTemplates({
                          ...incidentTemplates,
                          clickToExpandSection: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3">
          <div className="card">
            <div className="card-header">
              <strong>Other details</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col flex flex-col">
                  <div className="nb-side-label text-muted">
                    <small>Name</small>
                  </div>
                  <div className="flex-fill">
                    <input
                      tabIndex={50}
                      disabled={context.isDisabled}
                      className="form-control"
                      type="text"
                      value={name}
                      onChange={updateName}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col flex flex-col">
                  <div className="nb-side-label text-muted">
                    <small>Exclude emails from notifications</small>
                  </div>
                  <div className="flex-fill">
                    <CreatableSelect
                      tabIndex={60}
                      isMulti
                      onChange={(vs: any) => {
                        if (!vs || vs.length === 0) {
                          updateExcludedEmailRecipients([]);
                          return;
                        }
                        updateExcludedEmailRecipients(
                          vs
                            .map((v: any) => v.value)
                            .filter((v: any) => emailRegex.test(v)),
                        );
                      }}
                      value={toReactSelectLabelMulti(
                        excludedEmailRecipients ?? [],
                      )}
                      options={[]}
                      placeholder="Enter email addresses"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col">
            <button
              tabIndex={70}
              disabled={context.isDisabled || !name}
              className="btn btn-success btn-block"
              onClick={() => onSubmit(state)}
            >
              Save alert
            </button>
          </div>
        </div>
      </Style>
    </EditorContext.Provider>
  );
}

interface DrawerProps<T> {
  value: T;
  commitValue: (value: T) => void;
}

function SubjectSelector({ value, commitValue }: DrawerProps<Subject>) {
  const { isDisabled, breakdowns: options } = useCtx();

  const updateType = React.useCallback(
    (type: Subject["type"]) => {
      if (value.type === type) {
        return;
      }

      if (type === "all") {
        commitValue({ type: "all" });
      }

      if (type === "groupedBy" || type === "individualsMatching") {
        commitValue({ type, param: [] });
      }
    },
    [value, commitValue],
  );

  let subSelector: JSX.Element | null = null;
  if (value.type === "groupedBy" || value.type === "individualsMatching") {
    subSelector = (
      <BreakdownSelector
        disabled={isDisabled}
        current={value.param}
        available={options}
        allConfigs={options}
        onUpdate={(v: any) => {
          commitValue({ ...value, param: v });
        }}
        tabIndex="21"
      />
    );
  } else if (value.type === "individuals") {
    subSelector = <p>TODO implement individual selector</p>;
  }

  return (
    <div className="card">
      <div className="card-header">
        <strong>Trigger for...</strong>
      </div>
      <div className="card-body">
        <div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioInline1"
              name="type"
              className="custom-control-input"
              value="all"
              checked={value.type === "all"}
              onChange={(event) => updateType(event.target.value as any)}
            />
            <label
              className="custom-control-label"
              htmlFor="customRadioInline1"
            >
              all campaigns (blended)
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioInline2"
              name="type"
              className="custom-control-input"
              value="individualsMatching"
              checked={value.type === "individualsMatching"}
              onChange={(event) => updateType(event.target.value as any)}
            />
            <label
              className="custom-control-label"
              htmlFor="customRadioInline2"
            >
              active individual campaigns matching...
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioInline3"
              name="type"
              className="custom-control-input"
              value="groupedBy"
              checked={value.type === "groupedBy"}
              onChange={(event) => updateType(event.target.value as any)}
            />
            <label
              className="custom-control-label"
              htmlFor="customRadioInline3"
            >
              campaigns grouped by (blended)...
            </label>
          </div>
        </div>
        <hr />
        {subSelector}
      </div>
    </div>
  );
}

// eslint-disable-next-line
const emailRegex = /^\S+@\S+\.\S+$/;

const Style = styled.div`
  .nb-side-label {
    width: 5em;
    min-width: 5em;
    max-width: 5em;
    line-height: 1em;
    margin-right: 0.5em;
  }
`;
