import _ from "lodash";
import React from "react";
import { useToasts } from "react-toast-notifications";
import googleAnalytics from "./images/googleAnalytics.png";
import { utmGuidePlatformConfigs } from "./utm-guide/index";
import { GenericExternalLink } from "@components/generic-external-link";

export function GoogleAnalyticsParameterExclusions() {
  const { addToast } = useToasts();

  const paramsToExcludeFromGA = _.chain([...utmGuidePlatformConfigs])
    .flatMap((v) => v.urlParamPairs)
    .map((v) => v.key)
    .filter((v) => !v.startsWith("utm_"))
    .uniq()
    .sortBy()
    .join(",")
    .value();

  const copy = React.useCallback(() => {
    navigator.clipboard.writeText(paramsToExcludeFromGA);
    addToast("Copied to clipboard!", { appearance: "success" });
  }, [addToast, paramsToExcludeFromGA]);

  return (
    <>
      <textarea
        className="form-control"
        value={paramsToExcludeFromGA}
        rows={6}
        readOnly
      />
      <button className="mt-3 btn btn-primary" onClick={copy}>
        Copy
      </button>
      <h4 className="mt-4">
        <b>Please add them here:</b>
      </h4>
      <img
        className="img-fluid"
        src={googleAnalytics}
        alt="Google Analytics screen cap"
      />
      <h6 className="mt-4">
        <b>Note:</b> For <b>Google Analytics 4</b>, follow the instructions in{" "}
        <GenericExternalLink href="https://www.analyticsmania.com/post/exclude-url-query-parameters-in-google-analytics-4/">
          this article
        </GenericExternalLink>{" "}
        to add UTM parameter exclusions.
      </h6>
    </>
  );
}
