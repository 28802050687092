import React from "react";
import StepWizard from "react-step-wizard";
import { H1 } from "@components/utilities";
import { SetAttributionQuestionId } from "./set-attribution-question-id";
import { CreateShopifyFlow } from "./setup-shopify-flow";
import { UploadHistoricalData } from "./upload-historical-data";

export function EnquireLabsIntegration() {
  return (
    <>
      <H1>Integrate with Enquire Labs</H1>
      <hr />
      <div className="mb-5">
        <StepWizard isHashEnabled={true}>
          <CreateShopifyFlow hashKey={"shopify"} />
          <UploadHistoricalData hashKey={"historical"} />
          <SetAttributionQuestionId hashKey={"attribution-question"} />
        </StepWizard>
      </div>
    </>
  );
}
