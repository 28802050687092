import env, { apiAxios } from "@/environment";
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import useAllowedNonprefixedPage from "@lib/hooks/use-allowed-nonprefixed-page";
import { AttributionModel } from "@north-beam/nb-common";
import { BaseUserResponse, UserResponse } from "@north-beam/nb-common";
import { init, logEvent } from "@utils/analytics";
import { prefs } from "@utils/client-side-preferences";
import { EnumType, enumType } from "@utils/enum-type";
import { formatAttributionModel } from "@utils/strings";
import { AxiosRequestConfig } from "axios";
import _ from "lodash";
import Mustache from "mustache";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "./auth0-wrapper";
import NoUserScreen from "./error-pages/no-user-screen";
import { LoadingSlide } from "./title-slide-view";

/**
 * Some terminology:
 *
 *   authedUser:
 *      The user whose Auth0 credentials we are using to currently
 *      serve Northbeam.
 *
 *   displayUser:
 *      The current user to display, regardless of whether the authedUser
 *      has the right access to view. This is determined by looking at
 *      the URL path. If the URL path is empty, then it is determined by
 *      the matrix below. Otherwise, it is the user whose ID is referenced
 *      in the URL path.
 *
 *   defaultDisplayUser:
 *      Probably better named "redirectUser"; The current page will redirect
 *      to this user if the displayUser isn't activated.
 *
 *      It is the user associated with the `default_display_user_id` field
 *      for the displayUser, if it exists.
 *
 *      Otherwise, it is null.
 *
 *
 * This handy matrix describes how we handle a displayUser.
 *
 *                            |     Activated | Not Activated |
 *    ------------------------+---------------+---------------+
 *     Has defaultDisplayUser |   No redirect |  REDIRECT (*) |
 *    ------------------------+---------------+---------------+
 *     No  defaultDisplayUser |   No redirect |   No redirect |
 *    ------------------------+---------------+---------------+
 *
 * (*) Redirect only takes place if the defaultDisplayUser != displayUser.
 *
 * The redirect will update the URL path to include the ID of the
 * defaultDisplayUser, thus effectively changing the displayUser.
 *
 * After the redirect, we'll use the matrix above (with the new displayUser) to
 * determine whether we need to redirect a second time.
 */
export class User {
  private _apolloClient: ApolloClient<NormalizedCacheObject> | null = null;
  private _heavyApolloClient: ApolloClient<NormalizedCacheObject> | null = null;

  private displayUserResponse: BaseUserResponse;

  static async create(
    email: string,
    getTokenSilently: () => Promise<string | undefined>,
    impUserId: string,
  ): Promise<User | null> {
    try {
      const response = await saveAndFetch(email, impUserId, getTokenSilently);
      return new User(email, getTokenSilently, response);
    } catch (e) {
      return null;
    }
  }

  private constructor(
    public readonly email: string,
    private readonly getTokenSilently: () => Promise<string | undefined>,
    private readonly authedUserResponse: UserResponse,
  ) {
    this.displayUserResponse =
      authedUserResponse.impersonatedUser ?? authedUserResponse;
  }

  pathFromRoot(target: string): string {
    const prefix = `/${this.displayUserId}`;
    if (target.startsWith("/")) {
      return prefix + target;
    }
    return prefix + "/" + target;
  }

  get displayUserId(): string {
    return this.displayUserResponse.id;
  }

  get activatedAt(): string | null {
    return this.displayUserResponse.activatedAt;
  }

  get timezone(): string {
    return this.displayUserResponse.timezone;
  }

  get attributionModels(): AttributionModel[] {
    return _.cloneDeep(this.displayUserResponse.attributionModels);
  }

  get firstName() {
    return this.displayUserResponse.firstName;
  }

  get lastName() {
    return this.displayUserResponse.lastName;
  }

  get clientSettings() {
    return this.displayUserResponse.clientSettings;
  }

  getDefaultAttributionModel(
    value: string | null | undefined,
    amEnum: EnumType<string, any> | undefined = undefined,
  ) {
    amEnum = amEnum ?? this.attributionMethodEnum;

    let dv =
      amEnum.fromString(value) ??
      this.clientSettings.defaultDisplayAttributionModel ??
      "northbeam_custom";
    const keyOrder: string[] = amEnum.keyOrder;

    if (!keyOrder.includes(dv)) {
      dv = "last_touch";
    }

    return dv;
  }

  get attributionMethodEnum() {
    const enumObject = _.chain(this.attributionModels)
      .map(({ id }) => [id, id])
      .fromPairs()
      .value();
    const labels = _.chain(this.attributionModels)
      .map(({ id, name, isAdminOnly }) => {
        const modelName = formatAttributionModel(name);
        const adminInfo = isAdminOnly ? " - Admin Only" : "";
        return [id, `${modelName}${adminInfo}`];
      })
      .fromPairs()
      .value();
    const keyOrder = this.attributionModels.map((v) => v.id);

    return enumType({
      enumObject,
      labels,
      keyOrder,
    });
  }

  // Only used in the model-comparison page
  get attributionMethodDropdownEnum() {
    const companyName = prefs.isDemoMode() ? "Widgets Co" : this.companyName;
    const choices: { id: string; name: string }[] = [
      ...this.attributionModels,
      { id: "model_comparison", name: "Model Comparison" },
    ];

    const labels = _.chain(choices)
      .map(({ id, name }) => [id, Mustache.render(name, { companyName })])
      .fromPairs()
      .value();
    const enumObject = _.chain(choices)
      .map(({ id }) => [id, id])
      .fromPairs()
      .value();
    const keyOrder = choices.map((v) => v.id);

    return enumType({
      enumObject,
      labels,
      keyOrder,
    });
  }

  get featureFlags(): Record<string, any> {
    return _.cloneDeep(this.authedUserResponse.featureFlags);
  }

  get companyName(): string | null {
    if (prefs.isDemoMode()) {
      return "Widgets Co";
    }
    return this.displayUserResponse.companyName ?? null;
  }

  get authedUserId(): string {
    return this.authedUserResponse.id;
  }

  get defaultDisplayUserId(): string | null {
    return this.displayUserResponse.defaultDisplayUserId ?? null;
  }

  get isAdmin(): boolean {
    return this.authedUserResponse.isAdmin;
  }

  get impersonatedUser(): BaseUserResponse | undefined {
    return this.authedUserResponse.impersonatedUser;
  }

  get apolloClient(): ApolloClient<NormalizedCacheObject> {
    if (!this._apolloClient) {
      this._apolloClient = this.createApolloClient();
    }
    return this._apolloClient!;
  }

  get heavyApolloClient(): ApolloClient<NormalizedCacheObject> {
    if (!this._heavyApolloClient) {
      this._heavyApolloClient = this.createApolloClient(true);
    }
    return this._heavyApolloClient!;
  }

  private createApolloClient = (
    heavy = false,
  ): ApolloClient<NormalizedCacheObject> => {
    const abortController = new AbortController();
    const setHeaders: ApolloLink = setContext(async () => {
      const headers = await makeHeaders(
        this.getTokenSilently,
        this.impersonatedUser?.id || "",
      );
      return { headers };
    });

    const errorLink = onError(({ graphQLErrors, operation, forward }) => {
      if (graphQLErrors) {
        for (const def of operation.query.definitions) {
          if (!("operation" in def) || def.operation === "mutation") {
            return;
          }
        }
        return forward(operation);
      }
    });

    return new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          OverviewPage: {
            keyFields: [],
          },
          User: {
            keyFields: [],
          },
          benchmarks: {
            keyFields: [],
          },
        },
      }),
      link: from([
        setHeaders,
        new RetryLink(),
        errorLink,
        new HttpLink({
          uri: `${
            heavy && env.heavyApiRoot ? env.heavyApiRoot : env.apiRoot
          }api/graphql`,
          fetchOptions: {
            mode: "cors",
            signal: abortController.signal,
          },
        }),
      ]),
    });
  };

  callApi = async (config: AxiosRequestConfig) =>
    callApi(config, this.getTokenSilently, this.impersonatedUser?.id || "");
}

async function makeHeaders(
  getTokenSilently: () => Promise<string | undefined>,
  impersonateUserId: string,
) {
  const auth0Token = await getTokenSilently();
  const headers: Record<string, string> = {
    "content-type": "application/json",
    authorization: `Bearer ${auth0Token}`,
  };
  if (impersonateUserId) {
    headers["X-NB-Impersonate-User"] = impersonateUserId;
  }
  if (prefs.useDevelopmentAnalyticsData()) {
    headers["x-use-development-analytics-data"] = "true";
  }
  if (prefs.isDemoMode()) {
    headers["x-anonymize-data"] = "true";
  }
  return headers;
}

async function callApi(
  config: AxiosRequestConfig,
  getTokenSilently: () => Promise<string | undefined>,
  impersonateUserId: string,
) {
  const headers = await makeHeaders(getTokenSilently, impersonateUserId);
  config.headers = Object.assign({}, config.headers || {}, headers);
  const result = await apiAxios(config);
  return result.data;
}

async function saveAndFetch(
  email: string,
  impersonateUserId: string,
  getTokenSilently: () => Promise<string | undefined>,
): Promise<UserResponse> {
  return callApi(
    {
      url: "/api/user",
      method: "POST",
      data: { email, impersonateUserId },
    },
    getTokenSilently,
    "",
  );
}

interface IUserContext {
  user: User;
}

interface UserOptions {
  urlUserId: string;
  children: React.ReactElement | React.ReactElement[];
}

export const UserContext = React.createContext<IUserContext | null>(null);
export const useUser = () => React.useContext(UserContext)!;
export const UserProvider = ({ urlUserId, children }: UserOptions) => {
  const {
    loading,
    user: auth0User,
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
  } = useAuth0();

  const [userLoading, setUserLoading] = React.useState(true);
  const [user, setUser] = React.useState<User | null>();

  React.useEffect(() => {
    if (!loading) {
      if (!isAuthenticated) {
        // If not logged in, log in, and figure it out from there.
        // This action refreshes the page.
        (async () => {
          await loginWithRedirect();
        })();
      } else {
        // Wait for user to (eventually) become available.
        if (!auth0User) {
          return;
        }

        // Make a call to the internal API to fetch user state.
        (async () => {
          try {
            if (!user || user.displayUserId !== urlUserId) {
              const email = auth0User.name;
              // setUserLoading here prevents anything from being rendered while the
              // user object is being switched.
              setUserLoading(true);

              // Wait 20s to make sure splash page is working
              // await new Promise(r => setTimeout(r, 20000));
              const userToSet = await User.create(
                email,
                getTokenSilently,
                urlUserId,
              );

              if (
                userToSet &&
                urlUserId !== "" &&
                (!userToSet.defaultDisplayUserId ||
                  userToSet.authedUserId !== urlUserId)
              ) {
                // This only happens when:
                // 1. The page is refreshed
                // 2. The user is switched from the dropdown
                // 3. A user logs in
                init(userToSet);
                logEvent("Initialize new user");
              }
              setUser(userToSet);
            }
          } finally {
            setUserLoading(false);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    isAuthenticated,
    loginWithRedirect,
    auth0User,
    getTokenSilently,
    urlUserId,
  ]);

  const isAllowedNonPrefixedPage = useAllowedNonprefixedPage();

  if (userLoading) {
    return <LoadingSlide />;
  }

  if (!user) {
    return <NoUserScreen />;
  }

  if (!urlUserId && !isAllowedNonPrefixedPage) {
    // Directing straight to overview/onboarding saves us a page load
    if (user.activatedAt) {
      return <Navigate to={`${user.displayUserId}/overview`} />;
    } else {
      return <Navigate to={`${user.displayUserId}/onboarding`} />;
    }
  }

  return (
    <UserContext.Provider value={{ user: user }}>
      {children}
    </UserContext.Provider>
  );
};
