import classNames from "classnames";
import React from "react";
import { GenericDropdown } from "@components/dropdown";
import { GetOverviewPerformance_me_overviewPage_performanceMetrics as OverviewPageMetric } from "@nb-api-graphql-generated/GetOverviewPerformance";

interface MetricSelectProps {
  selectedMetric: OverviewPageMetric;
  metrics: OverviewPageMetric[];
  selectMetricIndex: (index: number) => void;
}

export const MetricSelect = ({
  selectedMetric,
  metrics,
  selectMetricIndex,
}: MetricSelectProps) => {
  if (metrics.length === 1) {
    return <strong>{selectedMetric.name}</strong>;
  }

  return (
    <GenericDropdown
      renderButton={({ toggle }) => (
        <strong
          style={{ cursor: "pointer", color: "var(--blue)" }}
          onClick={toggle}
        >
          {selectedMetric.name} <i className="fas fa-caret-down"></i>
        </strong>
      )}
      renderList={({ isOpen, toggle }) => (
        <div
          className={classNames({
            "dropdown-menu": true,
            show: isOpen,
          })}
        >
          {metrics.map((metric, index) => (
            <button
              className={classNames({
                "dropdown-item": true,
                active: metric.name === selectedMetric.name,
              })}
              type="button"
              key={metric.name}
              onClick={() => {
                selectMetricIndex(index);
                toggle();
              }}
            >
              {metric.name}
            </button>
          ))}
        </div>
      )}
    />
  );
};
