import styled from "@emotion/styled";
import classNames from "classnames";
import _ from "lodash";
import Tooltip from "rc-tooltip";
import React from "react";
import { Link } from "react-router-dom";
import {
  CellProps,
  Column,
  HeaderProps,
  IdType,
  Row,
  RowPropGetter,
  TableBodyPropGetter,
  TableBodyProps,
  useBlockLayout,
  useExpanded,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { ColumnDef, Table as TableType, TableRow } from "@north-beam/nb-common";
import { jsonHash } from "@north-beam/nb-common";
import ChangeFractionDisplay from "../change-fraction";

// eslint-disable-next-line @typescript-eslint/ban-types
type RowWithChildren<RowType extends {}> = RowType & {
  subRows: RowWithChildren<RowType>[];
};

type TableRowWithChildren = RowWithChildren<TableRow>;

const Styles = styled.div`
  .sticky {
    overflow: scroll;
    .thead,
    .tfoot {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .tbody {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 1px 0px 0px #ccc;
    }
  }

  .tr.highlighted-row .td {
    background-color: cornsilk;
  }
`;

const PaginationStyles = styled.div`
  .page-link {
    border: 1px solid #dee2e6;
  }

  .btn.page-link {
    border-radius: 0px;
  }

  .page-item:first-of-type .btn.page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .page-item:last-child .btn.page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

interface TableProps {
  table: TableType;
  groupByJSONArray?: string;
  paginate?: boolean;
  columnWidth?: number;
  highlightRowFunction?: (row: any) => boolean;
}

interface NativeTablePreGroupedProps {
  preppedRows: TableRowWithChildren[];
  dimensionColumns: ColumnDef[];
  factColumns: ColumnDef[];
  groupByJSONArray?: string;
  paginate?: boolean;
  columnWidth?: number;
  highlightRowFunction?: (row: any) => boolean;
}

function _NativeTable(props: TableProps, ref: React.Ref<NativeTableRef>) {
  const { table, ...rest } = props;

  const preppedRows: TableRowWithChildren[] = React.useMemo(() => {
    const rowsWithId = table.rows.map((row) => {
      const _rowId: any = {};
      for (let i = 0; i < row.rowMetadata.level; ++i) {
        const dimensionCol = table.dimensionColumns[i];
        _rowId[dimensionCol.key] = row.export[dimensionCol.key];
      }
      const _rowIdHash = jsonHash(_rowId);
      // HACK: we don't want the Grand total column to expand, otherwise this would be
      // if (level > 0)
      let _parentIdHash: string | null = null;
      if (row.rowMetadata.level > 1) {
        const parentId: any = {};
        for (let i = 0; i < row.rowMetadata.level - 1; ++i) {
          const dimensionCol = table.dimensionColumns[i];
          parentId[dimensionCol.key] = row.export[dimensionCol.key];
        }
        _parentIdHash = jsonHash(parentId);
      }
      return { ...row, _rowId, _rowIdHash, _parentIdHash };
    });

    const rv: TableRowWithChildren[] = [];
    const rowIdHashToOutputRow: Record<string, TableRowWithChildren> = {};
    const inputRows = _.sortBy(rowsWithId, (v) => v.rowMetadata.level);
    for (const row of inputRows) {
      const outputRow: TableRowWithChildren = { ...row, subRows: [] };
      rowIdHashToOutputRow[row._rowIdHash] = outputRow;
      if (!row._parentIdHash) {
        rv.push(outputRow);
        continue;
      }

      const parent = rowIdHashToOutputRow[row._parentIdHash];
      parent.subRows.push(outputRow);
    }
    return rv;
  }, [table.rows, table.dimensionColumns]);

  const underlyingProps = {
    preppedRows,
    dimensionColumns: table.dimensionColumns,
    factColumns: table.factColumns,
    ...rest,
  };
  return _NativeTablePreGrouped(underlyingProps, ref);
}

function _NativeTablePreGrouped(
  props: NativeTablePreGroupedProps,
  ref: React.Ref<NativeTableRef>,
) {
  const {
    preppedRows,
    dimensionColumns,
    factColumns,
    groupByJSONArray,
    columnWidth,
    paginate,
    highlightRowFunction,
  } = props;

  const hasComparison = preppedRows.some(
    (row: TableRow) => Object.keys(row.changeFractions || {}).length > 0,
  );

  const groupBy: string[] = React.useMemo(() => {
    if (!groupByJSONArray) {
      return [];
    }
    return JSON.parse(groupByJSONArray);
  }, [groupByJSONArray]);

  const defaultColumn: Column<TableRow> = React.useMemo(() => {
    const columnDefsByKey: Record<string, ColumnDef> = _.chain([
      ...dimensionColumns,
      ...factColumns,
    ])
      .map((c) => [c.key, c])
      .fromPairs()
      .value();

    const width = columnWidth ?? (hasComparison ? 200 : 150);

    return {
      width,
      Cell: ({ cell, row, column }: CellProps<TableRow>) => {
        let inner: React.ReactElement = cell.value;

        const link = row.original?.link?.[column.id];
        if (link) {
          inner = <Link to={link}>{inner}</Link>;
        }

        inner = (
          <div
            className={classNames(
              "d-inline-block",
              column.sticky === "left" ? "float-left" : "float-right",
            )}
          >
            <div
              className="text-truncate"
              title={cell.value}
              style={{
                maxWidth: `calc(${column.width}px - 1.6rem)`,
              }}
            >
              {inner}
            </div>
          </div>
        );

        const comparison = columnDefsByKey[column.id].comparison;
        const changeFraction = row.original?.changeFractions?.[column.id];
        if (comparison && typeof changeFraction !== "undefined") {
          inner = (
            <div>
              <div className="d-inline-block float-left">
                &nbsp;
                <ChangeFractionDisplay
                  changeFraction={changeFraction}
                  {...comparison}
                />
              </div>
              {inner}
            </div>
          );
        }

        if (cell.column.sticky) {
          return (
            <Tooltip
              placement="top"
              overlay={cell.value}
              mouseLeaveDelay={0}
              mouseEnterDelay={0}
              destroyTooltipOnHide={true}
            >
              {inner}
            </Tooltip>
          );
        }
        return inner;
      },
      Header: ({ column }: HeaderProps<TableRow>) => {
        let name: string = groupBy
          .map((v) => columnDefsByKey[v].name)
          .join(" / ");
        let headerTooltip: string | undefined;

        const columnDef = columnDefsByKey[column.id];
        if (columnDef) {
          name = columnDef.name;
          headerTooltip = columnDef.headerTooltip;
        }

        let component: React.ReactNode = name;
        if (headerTooltip) {
          component = (
            <Tooltip placement="top" overlay={headerTooltip}>
              <span>
                {component} &nbsp;
                <i className="fas fa-info-circle"></i>
              </span>
            </Tooltip>
          );
        }

        return (
          <div className="text-truncate">
            {column.canSort && (
              <>
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <i className="fas fa-sort-down"></i>
                    ) : (
                      <i className="fas fa-sort-up"></i>
                    )
                  ) : (
                    <i className="fas fa-sort"></i>
                  )}
                </span>
                &nbsp;&nbsp;
              </>
            )}
            {component}
          </div>
        );
      },
    } as Column<TableRow>;
  }, [hasComparison, dimensionColumns, factColumns, groupBy, columnWidth]);

  const expanderColumn: Column<TableRowWithChildren> = React.useMemo(() => {
    return {
      id: "expander",
      sticky: "left",
      show: true,
      width: 200,
      defaultCanSort: false,
      disableSortBy: true,
      Cell: ({ row }: CellProps<TableRowWithChildren>) => {
        if (row.canExpand) {
          return (
            <div
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
              className="text-truncate"
            >
              <small className="text-muted">
                {row.isExpanded ? (
                  <i className="fas fa-chevron-down"></i>
                ) : (
                  <i className="fas fa-chevron-right"></i>
                )}
              </small>
              &nbsp; &nbsp;
              {
                row.original.display[
                  groupBy[row.original.rowMetadata.level - 1]
                ]
              }
            </div>
          );
        }

        return null;
      },
    };
  }, [groupBy]);

  const columns: Column<TableRowWithChildren>[] = React.useMemo(() => {
    const rv: Column<TableRowWithChildren>[] = [
      ...(groupBy && groupBy.length > 0 ? [expanderColumn] : []),
    ];

    function getSortRow(row: Row<TableRowWithChildren>) {
      if (row.isGrouped) {
        return _.minBy(row.leafRows, (v) => v.original.rowMetadata.level)!;
      }
      return row;
    }

    for (const col of dimensionColumns) {
      const isGroupedColumn = groupBy.includes(col.key);
      if (!isGroupedColumn) {
        rv.push({
          id: col.key,
          width: 200,
          sticky: "left",
          show: true,
          defaultCanSort: false,
          disableSortBy: true,
          accessor: (v: TableRowWithChildren) => v.display[col.key],
        } as Column<TableRowWithChildren>);
      }
    }

    rv.push(
      ...factColumns.map(
        (col: ColumnDef) =>
          ({
            id: col.key,
            disableGroupBy: true,
            defaultCanSort: true,
            sortDescFirst: true,
            show: true,
            accessor: (v: TableRowWithChildren) => v.display[col.key],
            sortType: (
              rowA: Row<TableRowWithChildren>,
              rowB: Row<TableRowWithChildren>,
              _columnId: IdType<TableRowWithChildren>,
              desc?: boolean,
            ) => {
              rowA = getSortRow(rowA);
              rowB = getSortRow(rowB);
              // grand total column is always first
              if (rowA.original?.rowMetadata?.level === 0) {
                return desc ? 1 : -1;
              }
              if (rowB.original?.rowMetadata?.level === 0) {
                return desc ? -1 : 1;
              }

              const aVal = rowA.original?.export?.[col.key];
              const bVal = rowB.original?.export?.[col.key];

              if (typeof bVal === "undefined" || bVal === null) {
                return 1;
              }
              if (typeof aVal === "undefined" || aVal === null) {
                return -1;
              }
              return aVal - bVal;
            },
          } as Column<TableRowWithChildren>),
      ),
    );
    return rv;
  }, [dimensionColumns, factColumns, groupBy, expanderColumn]);

  const expanded = React.useMemo(() => {
    const rv: Record<string, boolean> = {};
    if (groupBy.length > 0) {
      for (let rowIndex = 0; rowIndex < preppedRows.length; ++rowIndex) {
        const row = preppedRows[rowIndex];
        rv[`${rowIndex}`] = true;

        for (
          let subRowIndex = 0;
          subRowIndex < row.subRows.length;
          ++subRowIndex
        ) {
          rv[`${rowIndex}.${subRowIndex}`] = true;
        }
      }
    }
    return rv;
  }, [preppedRows, groupBy]);

  const plugins = [
    // useGroupBy,
    useSortBy,
    useBlockLayout,
    useExpanded,
    useSticky,
  ];

  if (paginate) {
    plugins.push(usePagination);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    toggleSortBy,
    state: { pageIndex },
  } = useTable(
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore -TODO: fix
      columns,
      defaultColumn,
      data: preppedRows,
      initialState: { expanded, pageSize: 10 },
    },
    ...plugins,
  );

  const fnRef =
    React.useRef<
      (
        columnId: IdType<TableRowWithChildren>,
        descending: boolean,
        isMulti: boolean,
      ) => void
    >();
  React.useEffect(() => {
    fnRef.current = toggleSortBy;
  }, [toggleSortBy]);

  React.useImperativeHandle(ref, () => ({
    toggleSortBy: (
      columnId: IdType<TableRowWithChildren>,
      descending: boolean,
      isMulti: boolean,
    ) => {
      if (fnRef.current) {
        fnRef.current(columnId, descending, isMulti);
      }
    },
  }));

  const RenderRow = React.useCallback(
    (row: Row<TableRowWithChildren>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore - TODO: fix typing
      prepareRow(row);
      let propGetter: RowPropGetter<TableRowWithChildren> | undefined;
      const rep: Row<TableRowWithChildren> | null = null;

      const rowClassName = classNames({
        tr: 1,
        "highlighted-row":
          highlightRowFunction && highlightRowFunction(row.original),
      });

      return (
        <div {...row.getRowProps(propGetter)} className={rowClassName}>
          {row.cells.map((cell, i) => {
            if (cell.column.id !== "expander" && rep) {
              // Replace cell with the corresponding cell from representative
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              cell = rep.cells.find((v) => v.column.id === cell.column.id)!;
            }

            if (cell.column.show) {
              return (
                <div
                  {...cell.getCellProps()}
                  className="td"
                  key={`table-cell-${i}`}
                >
                  {cell.render("Cell")}
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    },
    [prepareRow, highlightRowFunction],
  );

  const RenderBody = React.useCallback(
    (
      getTableBodyProps: (
        propGetter?: TableBodyPropGetter<TableRowWithChildren>,
      ) => TableBodyProps,
    ) => {
      if (!page) {
        return (
          <div {...getTableBodyProps()} className="tbody">
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore - TODO: fix typing
              rows.map((row) => RenderRow(row))
            }
          </div>
        );
      } else {
        return (
          <>
            <div {...getTableBodyProps()} className="tbody">
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore - TODO: fix typing
                page.map((row) => RenderRow(row))
              }
            </div>
          </>
        );
      }
    },
    [rows, RenderRow, page],
  );

  const paginationBar = React.useMemo(() => {
    const props = {
      gotoPage,
      previousPage,
      canPreviousPage,
      nextPage,
      canNextPage,
      pageCount,
      pageIndex,
    };
    return <PaginationBar {...props} />;
  }, [
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageCount,
    pageIndex,
  ]);

  return (
    <Styles>
      <div
        {...getTableProps()}
        className="table table-sm nb-virtual-table nb-data-table sticky"
      >
        <div className="thead-light thead">
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column, i) => {
                if (column.show) {
                  return (
                    <div
                      {...column.getHeaderProps(
                        column.canSort
                          ? column.getSortByToggleProps()
                          : undefined,
                      )}
                      className="th"
                      key={`header-${i}`}
                    >
                      {column.render("Header")}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ))}
        </div>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore - TODO: fix typing
          RenderBody(getTableBodyProps)
        }
      </div>
      {page && paginationBar}
    </Styles>
  );
}

export interface NativeTableRef {
  toggleSortBy: (
    columnId: IdType<TableRow>,
    descending: boolean,
    isMulti: boolean,
  ) => void;
}

export const NativeTable = React.forwardRef<NativeTableRef, TableProps>(
  _NativeTable,
);

export const NativeTablePreGrouped = React.forwardRef<
  NativeTableRef,
  NativeTablePreGroupedProps
>(_NativeTablePreGrouped);

export function PaginationBar({
  gotoPage,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
}: {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  previousPage: () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  pageCount: number;
  pageIndex: number;
}) {
  if (!gotoPage) {
    return null;
  }

  const pageIndexes: number[] = [pageIndex];

  for (
    let offset = 1;
    pageIndex - offset >= 0 || pageIndex + offset < pageCount;
    ++offset
  ) {
    if (pageIndexes.length >= 5) {
      break;
    }

    const lo = pageIndex - offset;
    const hi = pageIndex + offset;

    if (lo >= 0) {
      pageIndexes.unshift(lo);
      if (pageIndexes.length >= 5) {
        break;
      }
    }

    if (hi < pageCount) {
      pageIndexes.push(hi);
      if (pageIndexes.length >= 5) {
        break;
      }
    }
  }

  return (
    <PaginationStyles>
      <nav aria-label="Pagination">
        <ul className="pagination">
          <li
            className={classNames("page-item", !canPreviousPage && "disabled")}
          >
            <button
              className="btn btn-link page-link"
              aria-label="First page"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">First page</span>
            </button>
          </li>
          <li
            className={classNames("page-item", !canPreviousPage && "disabled")}
          >
            <button
              className="btn btn-link page-link"
              aria-label="Previous page"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <span aria-hidden="true">&lsaquo;</span>
              <span className="sr-only">Previous page</span>
            </button>
          </li>
          {pageIndexes.map((index) => {
            const pageNum = index + 1;
            const isCurrent = index === pageIndex;
            return (
              <li
                key={pageNum}
                className={classNames("page-item", isCurrent && "active")}
              >
                <button
                  className="btn btn-link page-link"
                  aria-label={`Page ${pageNum}`}
                  onClick={() => gotoPage(index)}
                  disabled={isCurrent}
                >
                  {pageNum}
                </button>
              </li>
            );
          })}
          <li className={classNames("page-item", !canNextPage && "disabled")}>
            <button
              className="btn btn-link page-link"
              aria-label="Next page"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <span aria-hidden="true">&rsaquo;</span>
              <span className="sr-only">Next page</span>
            </button>
          </li>
          <li className={classNames("page-item", !canNextPage && "disabled")}>
            <button
              className="btn btn-link page-link"
              aria-label="Last page"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Last page</span>
            </button>
          </li>
        </ul>
      </nav>
    </PaginationStyles>
  );
}
