import React from "react";

import {
  useBack,
  useCurrentBenchmarkRouteAtom,
} from "@/atoms/benchmarking-atom";
import { SecondaryButton } from "@shared/buttons";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { useUser } from "@components/user-context";
import classNames from "classnames";
import { prettyLogEvent } from "@utils/analytics";

export const BenchmarkHelpModal = () => {
  const goBack = useBack();
  const { user } = useUser();

  useCalendlyEventListener({
    onEventScheduled: (e) =>
      prettyLogEvent({
        data: e.data.payload,
        page: "Sales",
        domain: "Benchmarking - Help Modal",
        action: "onSubmit",
        additionalActionInfo: "help-request",
      }),
  });

  return (
    <div className="relative w-full h-full">
      <div className="text-lg font-semibold">Need help?</div>
      <div className="text-subtitle mt-4 mb-5">
        Schedule time with our customer success team below:
      </div>

      <div className="2xlh:h-[41rem] h-[31rem]">
        <InlineWidget
          url="https://meetings.hubspot.com/kovach/benchmarking-specific-calendly-link?hide_gdpr_banner=1"
          prefill={{
            email: user.email,
            firstName: user.firstName ?? undefined,
            lastName: user.lastName ?? undefined,
          }}
          styles={{ height: "100%" }}
        />
      </div>

      <div className="absolute bottom-5 right-5">
        <SecondaryButton
          className="btn-sm ml-2 py-2 px-3 my-2"
          onClick={goBack}
        >
          Back
        </SecondaryButton>
      </div>
    </div>
  );
};

export function BenchmarksLearnMore({ className }: { className?: string }) {
  return (
    <div className={className}>
      Want to learn more? Click{" "}
      <a
        href="https://info.northbeam.io/knowledge/what-is-northbeam-benchmarks"
        target={"_blank"}
        rel="noreferrer"
        className="text-primary cursor-pointer underline underline-offset-2"
      >
        here
      </a>{" "}
      for the Benchmarks Knowledge Base
    </div>
  );
}
export const BenchmarkHelpWidget = ({
  className,
  size = "sm",
}: {
  className?: string;
  size?: "sm" | "xs";
}) => {
  const [, navigateTo] = useCurrentBenchmarkRouteAtom();
  return (
    <div
      className={classNames(className, size === "sm" ? "text-sm" : "text-xs")}
    >
      <span className="font-semibold">Need help creating your benchmarks?</span>
      <span>
        {" "}
        Schedule a call without customer success team{" "}
        <span
          className="text-primary cursor-pointer underline underline-offset-2"
          onClick={() => {
            navigateTo("help-me");
            prettyLogEvent({
              page: "Sales",
              domain: "Benchmarking - Help Modal",
              action: "Navigate to page",
            });
          }}
        >
          here
        </span>
      </span>
      <BenchmarksLearnMore />
    </div>
  );
};

export const BenchmarkHelpFooter = ({ className }: { className: string }) => {
  return (
    <div className={classNames(className, "w-full")}>
      <div className="mb-[2rem] border-t-[1px] " />
      <BenchmarkHelpWidget />
    </div>
  );
};
