import { UrlParamPair } from "@pages/settings/utm-guide/index";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

interface CheckURLValidityComponentProps {
  isAdmin?: boolean;
  urlParamPairs: UrlParamPair[];
}

export function CheckUrlValidity({
  isAdmin,
  urlParamPairs,
}: CheckURLValidityComponentProps) {
  const { addToast } = useToasts();
  const [inputtedUrl, setInputtedUrl] = useState<string>("");

  const validateInputtedUrl = () => {
    let url = null;
    let urlParams: URLSearchParams = new URLSearchParams();

    try {
      url = new URL(inputtedUrl);
      urlParams = new URLSearchParams(url.search);
    } catch (e) {
      addToast(
        <p>
          URL is malformed and cannot be parsed. Did you forget to add{" "}
          <code>https://</code>?
        </p>,
        {
          appearance: "warning",
        },
      );
      return;
    }

    const duplicatedOrMissingParams: string[] = [];

    urlParamPairs.forEach((paramPair) => {
      const values = urlParams.getAll(paramPair.key);
      if (values.length !== 1) {
        duplicatedOrMissingParams.push(paramPair.key);
      } else {
        if (values[0] !== decodeURIComponent(paramPair.value)) {
          duplicatedOrMissingParams.push(paramPair.key);
        }
      }
    });

    if (duplicatedOrMissingParams.length > 0) {
      addToast(
        <p>
          The following param keys were not correctly added{" "}
          {duplicatedOrMissingParams.join(", ")}
        </p>,
        {
          appearance: "warning",
        },
      );
    } else {
      addToast(<p>The URL appears to be correct.</p>, {
        appearance: "success",
      });
    }
  };

  return (
    <>
      <hr />
      <div className="control-label mb-2" data-testid="url-label">
        {isAdmin && (
          <span>
            <i className="fa-solid fa-user"></i> &nbsp;
          </span>
        )}{" "}
        Check your URL by pasting the full URL here with all your existing query
        params. Please include <code>https://</code>.
      </div>
      <input
        type="text"
        className="form-control mb-3"
        data-testid="url-input"
        value={inputtedUrl}
        onChange={(event) => setInputtedUrl(event.target.value)}
      />
      <button
        className="btn btn-primary mb-3 mt-2"
        type="button"
        onClick={validateInputtedUrl}
      >
        Check Validity
      </button>
    </>
  );
}
