import React from "react";

export default props => {
  const {
    value,
    setValue,
    config,
    readonly,
    min,
    max,
    step,
    placeholder,
  } = props;
  const onChange = e => {
    let val = e.target.value;
    if (val === "" || val === null) val = undefined;
    else val = Number(val);
    setValue(val);
  };
  const numberValue = value == undefined ? "" : value;
  return (
    <div className="form-inline">
      <div class="form-group mr-2">
        <input
          key={"number"}
          type="number"
          className="form-control"
          value={numberValue}
          placeholder={placeholder}
          disabled={readonly}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
        />
      </div>
      <div class="form-group">
        <input
          key={"range"}
          type="range"
          value={numberValue}
          disabled={readonly}
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          className="form-control-range"
        />
      </div>
    </div>
  );
};
