import {
  LoadingSlide,
  Page404,
  TitleSlide,
} from "@components/title-slide-view";
import { GenericTooltip } from "@components/tooltip-container";
import { H1 } from "@components/utilities";
import {
  GetAlertRunPoints,
  GetAlertRunPointsVariables,
  GetAlertRunPoints_me_alertConfig_points,
} from "@nb-api-graphql-generated/GetAlertRunPoints";
import { AlertConfigStatus } from "@nb-api-graphql-generated/global-types";
import { AlertPreviewRowV2 } from "@north-beam/nb-common";
import {
  DateInterval,
  formatDateFull,
  formatNumberExact,
  iterateOverInterval,
  jsonHash,
  shiftISODate,
  todayLocalAsISODate,
} from "@north-beam/nb-common";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import _ from "lodash";
import React from "react";
import { GET_ALERT_RUN_POINTS } from "../queries";
import { translateSkipReason } from "./alert-run-table";
import { flattenResultRows } from "./utils";

interface AlertHistoryPaneProps {
  id?: string;
}

export function AlertHistoryPane({ id }: AlertHistoryPaneProps) {
  if (!id) {
    return <Page404 />;
  }

  return <Inner configId={id} />;
}

interface HistoryPoint {
  subjectItemHash: string;
  runAt: string;
  status: "ok" | "alarm" | "missing" | "skipped";
  skipReason: string | null;
  wasAlertConfigInactive: boolean;
  wasAlertConfigSnoozed: boolean;
  previewRow: AlertPreviewRowV2;
}

interface SubjectItemHistory {
  points: HistoryPoint[];
  name: string;
  id: string;
}

function Inner({ configId }: { configId: string }) {
  const today = todayLocalAsISODate();
  const startingFrom = shiftISODate(today, -13);
  const { loading, data } = useNorthbeamQuery<
    GetAlertRunPoints,
    GetAlertRunPointsVariables
  >(GET_ALERT_RUN_POINTS, {
    variables: {
      alertConfigId: configId,
      startingFrom,
    },
  });

  if (loading) {
    return <LoadingSlide />;
  }

  if (!data?.me.alertConfig) {
    return (
      <TitleSlide>
        <p className="text-center">No data :(</p>
      </TitleSlide>
    );
  }

  return (
    <>
      <LogOnMount
        name="View incident details"
        data={{
          alertConfigId: configId,
        }}
      />
      <HistoryPoints
        title="History"
        points={data.me.alertConfig.points}
        dateRange={{ startDate: startingFrom, endDate: today }}
      />
    </>
  );
}

export interface HistoryPointsProps {
  points: GetAlertRunPoints_me_alertConfig_points[];
  title: string;
  dateRange: DateInterval;
}

export function HistoryPoints({
  points,
  title,
  dateRange,
}: HistoryPointsProps) {
  const subjectItemToHistory = React.useMemo(() => {
    const subjectItemHashToName: Record<string, string> = {};
    const subjectItemAndRunAtToHistoryPoint: Record<string, HistoryPoint> = {};

    for (const point of points) {
      const subjectItemHash = jsonHash(point.subjectItem.key);
      const { runAt, previewRow: _pr, alertConfigStatus } = point;
      subjectItemHashToName[subjectItemHash] = point.subjectItem.name;

      const previewRow: AlertPreviewRowV2 = _pr;

      subjectItemAndRunAtToHistoryPoint[jsonHash({ subjectItemHash, runAt })] =
        {
          subjectItemHash,
          runAt,
          status: previewRow.skipReason
            ? "skipped"
            : previewRow.conditionTriggered
            ? "alarm"
            : "ok",
          skipReason: previewRow.skipReason,
          wasAlertConfigInactive:
            alertConfigStatus !== AlertConfigStatus.active,
          wasAlertConfigSnoozed: false,
          previewRow,
        };
    }

    const rv: Record<string, SubjectItemHistory> = {};
    for (const subjectItemHash in subjectItemHashToName) {
      const name = subjectItemHashToName[subjectItemHash];
      const points: HistoryPoint[] = [];
      for (const runAt of iterateOverInterval(dateRange)) {
        const key = jsonHash({ subjectItemHash, runAt });
        const historyPoint = subjectItemAndRunAtToHistoryPoint[key] ?? {
          subjectItemHash,
          runAt,
          status: "missing",
          wasAlertConfigInactive: false,
          wasAlertConfigSnoozed: false,
        };
        points.push(historyPoint);
      }

      rv[subjectItemHash] = { name, points, id: subjectItemHash };
    }

    return rv;
  }, [points, dateRange]);

  const historyPoints = _.chain(subjectItemToHistory)
    .values()
    .sortBy(
      (v) => v.points[v.points.length - 1].previewRow?.averageDailySpend ?? 0,
      (v) => v.points.filter((v) => v.status !== "skipped").length,
    )
    .reverse()
    .value();

  return (
    <div className="container my-3">
      <div className="row">
        <div className="col">
          <H1>{title}</H1>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-2 text-right">
              <small className="text-muted">Daily spend</small>
            </div>
            <div className="col-4">
              <small className="text-muted">Subject</small>
            </div>
          </div>
          {historyPoints.map(({ id, name, points }) => (
            <div className="row" key={id}>
              <div className="col-6 flex items-center">
                <div className="nb-alert-run-point-array flex-grow-1">
                  {points.map(({ status, runAt, previewRow, skipReason }) => {
                    if (status === "missing") {
                      return (
                        <GenericTooltip
                          content={
                            <div>
                              <p>
                                {formatDateFull(runAt)}: {status.toUpperCase()}
                              </p>
                            </div>
                          }
                          key={runAt}
                          noInfoCircle={true}
                          Wrapper={({ children }) => (
                            <div
                              className={`nb-alert-run-point-element status-${status}`}
                            >
                              {children}
                            </div>
                          )}
                        >
                          <div className="d-none"></div>
                        </GenericTooltip>
                      );
                    }

                    const results = flattenResultRows(previewRow.resultRow);
                    return (
                      <GenericTooltip
                        content={
                          <div>
                            <p>
                              {formatDateFull(runAt)}: {status.toUpperCase()}
                            </p>
                            {skipReason && (
                              <p>
                                (Skipped because:{" "}
                                {translateSkipReason(skipReason)})
                              </p>
                            )}
                            <div>Metrics for this day:</div>
                            <ul>
                              {results.map((result) => (
                                <li key={jsonHash(result)}>
                                  <b>{result.metric.name}</b>:{" "}
                                  {formatNumberExact(
                                    Number(result.currentValueComputed),
                                    result.metric.format,
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        }
                        key={runAt}
                        noInfoCircle={true}
                        Wrapper={({ children }) => (
                          <div
                            className={`nb-alert-run-point-element status-${status}`}
                          >
                            {children}
                          </div>
                        )}
                      >
                        <div className="d-none"></div>
                      </GenericTooltip>
                    );
                  })}
                </div>
              </div>
              <div className="col-2 flex text-right items-center">
                <div className="flex-grow-1">
                  {formatNumberExact(
                    points[points.length - 1].previewRow?.averageDailySpend,
                    "dollars",
                  )}
                </div>
              </div>
              <div className="col-4 flex items-center">
                <div className="text-truncate">{name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
