import React, { useMemo } from "react";
import {
  BenchmarkAttributionModelEnum,
  BenchmarkAttributionWindowEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";
import bullseyeIcon from "@assets/bullseye.svg";
import {
  PrettyBenchmarkAttributionModelNames,
  PrettyBenchmarkAttributionModelOptionNames,
  PrettyBenchmarkAttributionWindowNames,
} from "@/constants/benchmarking";
import classNames from "classnames";
import { PrimaryButton, SecondaryButton } from "@shared/buttons";
import { BenchmarkPlatformMetricsGrid } from "./benchmark-platform-metrics-grid";
import {
  BenchmarksByPlatform,
  PlatformBenchmarks,
} from "./custom-benchmarks-modal";
import {
  useGetBenchmarkPlatformsToUse,
  usePlatformBenchmarks,
} from "@hooks/use-platform-benchmarks";
import {
  useCurrentBenchmarkRouteAtom,
  useCustomBenchmarksByPlatform,
  useNBCalculatedBenchmarksByPlatform,
} from "@/atoms/benchmarking-atom";
import { BenchmarkHelpFooter } from "./benchmark-help-modal";
import { GenericTooltip } from "@components/tooltip-container";
import { prettyLogEvent } from "@utils/analytics";
import { ChooseAndSetValidationTargets } from "./benchmark-choose-and-set-modal";

export const BenchmarkReview = ({
  benchmarkType,
  attributionWindow,
  benchmarkTargetsByPlatform,
  closeModal,
  loadingSubmission,
  onSubmit,
  attributionModel,
}: {
  attributionModel: BenchmarkAttributionModelEnum;
  benchmarkType: BenchmarkTypeEnum;
  benchmarkTargetsByPlatform: ChooseAndSetValidationTargets;
  attributionWindow: BenchmarkAttributionWindowEnum;
  closeModal: () => void;
  loadingSubmission: boolean;
  onSubmit: (
    customBenchmarksByPlatform?: BenchmarksByPlatform,
  ) => Promise<void>;
}) => {
  const platformsToUse = useGetBenchmarkPlatformsToUse();

  const [, setNBBenchmarksByPlatform] = useNBCalculatedBenchmarksByPlatform();
  const [, setCustomBenchmarksByPlatform] = useCustomBenchmarksByPlatform();
  const [, navigateTo] = useCurrentBenchmarkRouteAtom();

  const { orderedData, loading: loadingBenchmarks } = usePlatformBenchmarks({
    benchmarkTargets: platformsToUse.map((platform) => ({
      attributionModels: [attributionModel],
      benchmarkType,
      target: benchmarkTargetsByPlatform[platform],
      attributionWindow,
      platform,
    })),
  });

  const benchmarksByPlatform = useMemo(
    () =>
      orderedData.reduce<BenchmarksByPlatform>((acc, curr) => {
        if (!acc[curr.platform]) acc[curr.platform] = [];
        acc[curr.platform].push(curr as PlatformBenchmarks);
        return acc;
      }, {} as BenchmarksByPlatform),
    [orderedData],
  );
  const benchmarksToUse = useMemo(() => {
    return orderedData.filter(
      (item) => attributionModel === item.attributionModel,
    );
  }, [attributionModel, orderedData]);

  const loading = loadingSubmission || loadingBenchmarks;

  return (
    <div className="relative flex-grow">
      <div className="flex items-center">
        <div className="text-lg font-semibold">
          Review your 1-day benchmarks for the{" "}
          <span className="text-primary">
            {PrettyBenchmarkAttributionModelNames[
              attributionModel
            ].toLowerCase()}{" "}
          </span>
          benchmark{" "}
        </div>
        <img
          src={bullseyeIcon}
          alt={"bullseye"}
          className="ml-3 mb-[0.3rem]"
          style={{
            height: "2rem",
            width: "auto",
          }}
        />
      </div>

      <div className="font-light text-subtitle mt-2 2xlh:mb-10 mb-6">
        With your {PrettyBenchmarkAttributionWindowNames[attributionWindow]}{" "}
        {benchmarkType} goals, here are your 1-day buying benchmarks:
      </div>

      <div className="flex 2xlh:mb-5 mb-4">
        <div
          className={classNames(
            `cursor-pointer mr-5 p-3 rounded-2xl elevation-1 border border-primary`,
          )}
        >
          {PrettyBenchmarkAttributionModelOptionNames[attributionModel]}
        </div>
      </div>

      <BenchmarkPlatformMetricsGrid
        benchmarkTargetsByPlatform={benchmarkTargetsByPlatform}
        benchmarkType={benchmarkType}
        showLabels={false}
        orderedData={benchmarksToUse}
        loading={loading}
        compact={false}
      />

      <div className="2xlh:mt-[2rem] xl:mt-[0rem]">
        <div
          className={classNames(
            "font-semibold flex cursor-pointer 2xlh:mt-4 mt-[0rem] items-center",
            "text-primary",
          )}
          onClick={() => {
            if (!loading) {
              setNBBenchmarksByPlatform(benchmarksByPlatform);
              navigateTo("review-with-custom-benchmarks");
              prettyLogEvent({
                page: "Sales",
                domain: "Benchmarking - Modal",
                action: "Navigate to",
                additionalActionInfo: "custom-benchmarks",
                data: {
                  benchmarkTargetsByPlatform,
                  benchmarkType,
                  attributionModel,
                  attributionWindow,
                },
              });
            }
          }}
        >
          <span className={classNames("underline underline-offset-4")}>
            I would like to set my own 1 day target goals per each platform
          </span>

          <div className="ml-1 text-[1.1rem]">
            <i className="fa-solid fa-angle-right" />
          </div>
          <GenericTooltip content="Set your own benchmarks. We recommend this option if you have predefined goals for ad efficiency not reflected in past performance." />
        </div>
      </div>
      <BenchmarkHelpFooter className="absolute bottom-[2.5rem]" />
      <div className="absolute bottom-5 right-5">
        <SecondaryButton
          className="btn-sm ml-2 py-2 px-3 my-2"
          onClick={() => {
            navigateTo("choose-and-set");
            setCustomBenchmarksByPlatform(null);
            prettyLogEvent({
              page: "Sales",
              domain: "Benchmarking - Modal",
              additionalActionInfo: "to-choose-and-set",
              action: "Go Back",
              data: {
                benchmarkTargetsByPlatform,
                benchmarkType,
                attributionModel,
                attributionWindow,
              },
            });
          }}
          isDisabled={loading}
        >
          Back
        </SecondaryButton>
        <PrimaryButton
          className="btn-sm ml-2 py-2 px-3 my-2"
          onClick={() => {
            onSubmit();
          }}
          isDisabled={loading}
        >
          Apply
        </PrimaryButton>
      </div>
    </div>
  );
};
