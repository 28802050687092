import { SalesViewTemplate } from "../../sales-views-context";

export const detailed: SalesViewTemplate = {
  id: "detailed",
  name: "Detailed",
  isTemplate: true,
  data: {
    filters: {
      attributionModel: "northbeam_custom",
      attributionWindow: "1",
      nbAccountingMode: "accrual",
      granularity: "platform",
      timeGranularity: "daily",
      dateRange: "last7Days",
      compareDateRange: "lastPeriod",
      breakdowns: [
        {
          key: "Platform (Northbeam)",
          values: [],
        },
      ],
    },
    columnConfigurations: [
      {
        name: "Name",
        width: 450,
        visible: true,
      },
      {
        name: "Spend",
        width: 180,
        visible: true,
      },
      {
        name: "Attributed Rev",
        width: 182,
        visible: true,
      },
      {
        name: "ROAS",
        width: 120,
        visible: true,
      },
      {
        name: "LTV ROAS",
        width: 120,
        visible: true,
      },
      {
        name: "LTV CAC",
        width: 120,
        visible: true,
      },
      {
        name: "CTR",
        width: 120,
        visible: true,
      },
      {
        name: "CPM",
        width: 120,
        visible: true,
      },
      {
        name: "eCPC",
        width: 120,
        visible: true,
      },
      {
        name: "eCPNV",
        width: 120,
        visible: true,
      },
      { name: "LTV Attributed Rev", visible: true, width: 173 },
      { name: "Forecasted Attributed Rev (30d)", visible: true, width: 298 },
      { name: "Forecasted Attributed Rev (60d)", visible: true, width: 298 },
      { name: "Forecasted Attributed Rev (90d)", visible: true, width: 298 },
      { name: "Forecasted ROAS (30d)", visible: true, width: 202 },
      { name: "Forecasted ROAS (60d)", visible: true, width: 202 },
      { name: "Forecasted ROAS (90d)", visible: true, width: 202 },
      { name: "Imprs", visible: true, width: 120 },
      { name: "Visits", visible: true, width: 120 },
      { name: "New Visits", visible: true, width: 120 },
      { name: "ECR", visible: true, width: 120 },
      { name: "LTV ECR", visible: true, width: 120 },
      { name: "LTV Transactions", visible: true, width: 154 },
      {
        name: "Forecasted Attributed Transactions (30d)",
        visible: true,
        width: 384,
      },
      {
        name: "Forecasted Attributed Transactions (60d)",
        visible: true,
        width: 384,
      },
      {
        name: "Forecasted Attributed Transactions (90d)",
        visible: true,
        width: 384,
      },
      { name: "Email Signups", visible: true, width: 173 },
      { name: "LTV Email Signups", visible: true, width: 163 },
      { name: "Email Signup Rate", visible: true, width: 211 },
      { name: "LTV Email Signup Rate", visible: true, width: 202 },
      { name: "Cost per Email Signup", visible: true, width: 250 },
      { name: "LTV Cost per Email Signup", visible: true, width: 240 },
      { name: "% New Visits", visible: true, width: 120 },
      { name: "Attributed Rev (1st time)", visible: true, width: 278 },
      { name: "LTV Attributed Rev (1st time)", visible: true, width: 278 },
      { name: "ROAS (1st time)", visible: true, width: 182 },
      { name: "LTV ROAS (1st time)", visible: true, width: 182 },
      { name: "ECR (1st time)", visible: true, width: 173 },
      { name: "LTV ECR (1st time)", visible: true, width: 173 },
      { name: "CAC (1st time)", visible: true, width: 173 },
      { name: "LTV CAC (1st time)", visible: true, width: 173 },
      { name: "Transactions (1st time)", visible: true, width: 259 },
      { name: "LTV Transactions (1st time)", visible: true, width: 259 },
      { name: "AOV (1st time)", visible: true, width: 259 },
      { name: "Attributed Rev (Returning)", visible: true, width: 288 },
      { name: "LTV Attributed Rev (Returning)", visible: true, width: 288 },
      { name: "ROAS (Returning)", visible: true, width: 192 },
      { name: "LTV ROAS (Returning)", visible: true, width: 192 },
      { name: "ECR (Returning)", visible: true, width: 182 },
      { name: "LTV ECR (Returning)", visible: true, width: 182 },
      { name: "CAC (Returning)", visible: true, width: 182 },
      { name: "LTV CAC (Returning)", visible: true, width: 182 },
      { name: "Transactions (Returning)", visible: true, width: 269 },
      { name: "LTV Transactions (Returning)", visible: true, width: 269 },
      { name: "AOV (Returning)", visible: true, width: 269 },
      { name: "Transactions", visible: true, width: 163 },
      { name: "CAC", visible: true, width: 120 },
      { name: "AOV", visible: true, width: 120 },
    ],
    selectedMetricBoxes: [
      "Spend",
      "Attributed Rev",
      "ROAS",
      "CAC",
      "LTV CAC",
      "LTV ROAS",
    ],
  },
};
