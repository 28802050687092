import { FixedDateRange } from "@north-beam/nb-common";
import {
  DateRangeChoiceV2,
  ICompareDateRangeEnum,
  ITimeGranularity,
  TimeGranularity,
} from "@utils/constants";
import { isEqual } from "lodash";
import {
  createEnumParam,
  decodeJson,
  encodeJson,
  QueryParamConfig,
} from "serialize-query-params";

export const TimeGranularityParam = createEnumParam<ITimeGranularity>(
  TimeGranularity.keyOrder,
);

export type DateRangeType<T> = T | FixedDateRange;
export const CustomJsonParam = <T>(): QueryParamConfig<T> => ({
  encode: (value) => encodeJson(value),
  decode: (value) => decodeJson(value),
  equals: (a, b) => isEqual(a, b),
});
export const DateRangeParam =
  CustomJsonParam<DateRangeType<DateRangeChoiceV2>>();
export const CompareDateRangeParam =
  CustomJsonParam<DateRangeType<ICompareDateRangeEnum>>();

export enum DateRangeQueryNames {
  GRANULARITY = "granularity",
  TIME_GRANULARITY = "timeGranularity",
  DATE_RANGE = "dateRange",
  COMPARE_DATE_RANGE = "compareDateRange",
}
