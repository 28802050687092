/* eslint-disable react-hooks/exhaustive-deps */
import { useUser } from "@components/user-context";
import { Breakdown } from "@north-beam/nb-common";
import { FixedDateRange, todayLocalAsISODate } from "@north-beam/nb-common";
import { DateRangeChoiceEnum, DateRangeChoiceV2 } from "@utils/constants";
import { enumType } from "@utils/enum-type";
import { parseDateRangeArgument } from "@utils/index";
import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import {
  createEnumParam,
  decodeJson,
  encodeJson,
  QueryParamConfig,
  withDefault,
} from "serialize-query-params";
import { StringParam, useQueryParams } from "use-query-params";

export const Granularity = enumType({
  enumObject: {
    campaign: "Campaign",
    adset: "Adset",
    ad: "Ad",
  } as const,
  keyOrder: ["campaign", "adset", "ad"],
});

type IGranularity = keyof typeof Granularity.enum;

const GranularityParam = createEnumParam<IGranularity>(Granularity.keyOrder);

type DateRangeType<T> = T | FixedDateRange;
const CustomJsonParam = <T>(): QueryParamConfig<T> => ({
  encode: (value) => encodeJson(value),
  decode: (value) => decodeJson(value),
  equals: (a, b) => isEqual(a, b),
});
const DateRangeParam = CustomJsonParam<DateRangeType<DateRangeChoiceV2>>();
const BreakdownParam = CustomJsonParam<Breakdown[]>();

enum ReportBody {
  GRANULARITY = "granularity",
  DATE_RANGE = "dateRange",
  BREAKDOWNS = "breakdowns",
  ATTRIBUTION_MODEL_1 = "attributionModel1",
  ATTRIBUTION_MODEL_2 = "attributionModel2",
}

export interface ReportBodyState {
  readonly dateRange: DateRangeType<DateRangeChoiceV2> | FixedDateRange;
  readonly breakdowns: Breakdown[];
  readonly granularity: IGranularity;
  readonly attributionModel1: string;
  readonly attributionModel2: string;
}

export const useReportBodyState = () => {
  const { user } = useUser();
  const [state, setState] = useQueryParams({
    [ReportBody.GRANULARITY]: useMemo(
      () => withDefault(GranularityParam, "campaign" as const),
      [],
    ),
    [ReportBody.DATE_RANGE]: useMemo(
      () => withDefault(DateRangeParam, "last28Days" as const),
      [],
    ),
    [ReportBody.BREAKDOWNS]: useMemo(() => withDefault(BreakdownParam, []), []),
    [ReportBody.ATTRIBUTION_MODEL_1]: useMemo(
      () => withDefault(StringParam, "first_touch"),
      [user],
    ),
    [ReportBody.ATTRIBUTION_MODEL_2]: useMemo(
      () => withDefault(StringParam, "last_touch"),
      [user],
    ),
  });

  const dateRange = useMemo(
    () =>
      parseDateRangeArgument(
        state[ReportBody.DATE_RANGE],
        todayLocalAsISODate(),
      ),
    [state[ReportBody.DATE_RANGE]],
  );

  return {
    state,
    setState,

    attributionModel1: state[ReportBody.ATTRIBUTION_MODEL_1],
    setAttributionModel1: useCallback(
      ({ value }: { value: string }) => {
        setState({ [ReportBody.ATTRIBUTION_MODEL_1]: value });
      },
      [setState],
    ),

    attributionModel2: state[ReportBody.ATTRIBUTION_MODEL_2],
    setAttributionModel2: useCallback(
      ({ value }: { value: string }) => {
        setState({ [ReportBody.ATTRIBUTION_MODEL_2]: value });
      },
      [setState],
    ),

    granularity: state[ReportBody.GRANULARITY],
    setGranularity: useCallback(
      ({ value }: { value: IGranularity }) =>
        setState({ [ReportBody.GRANULARITY]: value }),
      [setState],
    ),

    breakdowns: state[ReportBody.BREAKDOWNS],
    setBreakdowns: useCallback(
      (value: Breakdown[]) => {
        setState({ [ReportBody.BREAKDOWNS]: value });
      },
      [setState],
    ),

    dateRange,
    setDateRange: useCallback(
      (
        dateRange:
          | FixedDateRange
          | (typeof DateRangeChoiceEnum.reactSelectOptions)[0],
      ) => {
        setState({
          [ReportBody.DATE_RANGE]:
            "value" in dateRange ? dateRange.value : dateRange,
        });
      },
      [setState],
    ),
  };
};
