import Bugsnag, { Event } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import amplitude from "amplitude-js";
import React from "react";
import { User } from "@components/user-context";
import env from "@/environment";
import { matchPath, useLocation } from "react-router-dom";

function _init(id?: string) {
  amplitude.getInstance().init(env.amplitudeApiKey, id);
}

_init();

export function init(user: User) {
  _init(user.authedUserId);

  const identify = new amplitude.Identify()
    .set("Email", user.email)
    .set("Display user ID", user.displayUserId)
    .set("Authed user ID", user.authedUserId)
    .set("isAdmin", user.isAdmin)
    .set(
      "Screen resolution",
      window.screen.width + "px x " + window.screen.height + "px",
    )
    .set("Viewport", window.innerWidth + "px x " + window.innerHeight + "px");
  amplitude.getInstance().identify(identify);
}

export function logEvent(
  name: string,
  data?: any,
  callback?: amplitude.Callback,
): amplitude.LogReturn {
  return amplitude.getInstance().logEvent(name, data, callback);
}

type Page = "Orders" | "Order Details" | "Model Comparison" | "Sales";

const knownPages: Record<string, Page> = {
  "/:userId/orders": "Orders",
  "/:userId/orders/:orderId": "Order Details",
  "/:userId/sales": "Sales",
} as const;

type PrettyLogEventPageArg = Omit<Parameters<typeof prettyLogEvent>[0], "page">;
export const usePrettyLogEventPage = () => {
  const location = useLocation();

  const foundPage = Object.keys(knownPages).find((path) => {
    return matchPath({ path }, location.pathname);
  });

  if (foundPage) {
    const page = knownPages[foundPage];
    return (args: PrettyLogEventPageArg) => prettyLogEvent({ ...args, page });
  } else {
    console.error(
      `No page found for ${location.pathname}. To use "usePrettyLogEventPage" you must define the path in "knownPages".`,
    );
    return () => null;
  }
};

export const prettyLogEvent = ({
  page,
  domain,
  action,
  data,
  additionalActionInfo,
}: {
  page: Page;
  // Add to this as required
  domain?: string;
  action:
    | "onExit"
    | "Navigate to"
    | "Navigate to page"
    | "onClick"
    | "onClose"
    | "onSubmit"
    | "onMount"
    | "Other"
    | "Visited"
    | "onSelect"
    | "Apply Filters"
    | "Clear Filters"
    | "Go Back"
    | "onNext";

  additionalActionInfo?: string;
  data?: any;
}) => {
  return logEvent(
    `${page} Page ->${domain ? ` ${domain} ->` : ""} ${action}${
      additionalActionInfo ? `:${additionalActionInfo}` : ""
    }`,
    data,
  );
};

// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
/*
  It's originating from datadog node_modules/@datadog/browser-core/src/tools/instrumentMethod.ts
  and this error doesn't seem to be causing any problems, so we're going to ignore it for now.
*/
export const checkIsResizeObserverError = (event: Event) => {
  let isResizeObserverError = false;
  event.errors.forEach((error) => {
    if (error.errorMessage.includes("ResizeObserver loop limit exceeded")) {
      isResizeObserverError = true;
    } else if (
      error.errorMessage.includes(
        "ResizeObserver loop completed with undelivered notifications",
      )
    ) {
      isResizeObserverError = true;
    }
  });

  return isResizeObserverError;
};

Bugsnag.start({
  apiKey: env.bugsnagApiKey,
  plugins: [new BugsnagPluginReact(React)],
  onError: (event) => {
    const isResizeObserverError = checkIsResizeObserverError(event);
    if (isResizeObserverError) {
      // Return false to discard event
      return false;
    }
  },
});

export const bugsnagClient = Bugsnag;
export const ErrorBoundary =
  Bugsnag.getPlugin("react")!.createErrorBoundary(React);

export function LogOnChange(props: {
  name: string;
  data?: any;
  value: any;
  children?: React.ReactNode | React.ReactNodeArray;
}) {
  const { name, data, value, children } = props;

  React.useEffect(() => {
    logEvent(name, data);
    // eslint-disable-next-line
  }, [value]);

  return children || (null as any);
}

export function LogOnMount(props: {
  name: string;
  data?: any;
  children?: React.ReactNode | React.ReactNodeArray;
}) {
  const { children, ...rest } = props;

  return (
    <LogOnChange {...rest} value={null}>
      {children}
    </LogOnChange>
  );
}
