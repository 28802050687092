import { CampaignSearchBox } from "@components/campaign-search-box";
import { BreakdownSelector } from "@components/reports/breakdown-selector";
import { CampaignSearch_me_campaigns as CampaignSearchResult } from "@nb-api-graphql-generated/CampaignSearch";
import { CustomerPathFilterStage } from "@nb-api-graphql-generated/global-types";
import { Breakdown, BreakdownConfig } from "@north-beam/nb-common";
import React from "react";

export interface PathElementFilterComponentProps {
  fetchCampaigns(query: string): Promise<CampaignSearchResult[]>;
  breakdownConfigs: BreakdownConfig[];
  current: CustomerPathFilterStage[];
  maxStages: number;
  onUpdate: (current: CustomerPathFilterStage[]) => void;
  disabled: boolean;
}

export function PathElementFilterComponent({
  fetchCampaigns,
  breakdownConfigs,
  maxStages,
  current,
  onUpdate,
  disabled,
}: PathElementFilterComponentProps) {
  const addCampaignLabelStage = React.useCallback(() => {
    onUpdate([...current, { campaignLabelFilters: [] }]);
  }, [onUpdate, current]);

  const removeStage = React.useCallback(
    (index: number) => {
      const newValue = [...current];
      newValue.splice(index, 1);
      onUpdate(newValue);
    },
    [onUpdate, current],
  );

  const updateStage = React.useCallback(
    (index: number, data: CustomerPathFilterStage) => {
      const newValue = [...current];
      newValue[index] = data;
      onUpdate(newValue);
    },
    [onUpdate, current],
  );

  const canAddMore = current.length < maxStages;

  return (
    <div className="ml-x">
      {current.map((stage, index) => {
        let component: React.ReactElement;
        if (stage.campaignLabelFilters) {
          component = (
            <>
              <small className="text-muted">Campaigns by label</small>
              <CampaignLabelFilterComponent
                disabled={disabled}
                breakdownConfigs={breakdownConfigs}
                data={stage}
                onUpdate={(data) => updateStage(index, data)}
              />
            </>
          );
        } else {
          component = (
            <>
              <small className="text-muted">Individual campaign</small>
              <IndividualCampaignFilterComponent
                disabled={disabled}
                fetchCampaigns={fetchCampaigns}
                data={stage}
                onUpdate={(data) => updateStage(index, data)}
              />
            </>
          );
        }
        return (
          <div key={JSON.stringify([index, stage])}>
            {index === 0 ? "Matches" : "then matches"}
            <div className="flex">
              <div className="mx-2" style={{ borderRight: "1px solid #aaa" }} />
              <div className="my-3 p-2 border rounded flex-grow-1">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  disabled={disabled}
                  onClick={() => removeStage(index)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="mr-4">{component}</div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="d-inline-block">
        <button
          className="btn btn-primary btn-sm"
          onClick={addCampaignLabelStage}
          disabled={!canAddMore || disabled}
        >
          Add label filter
        </button>
      </div>
    </div>
  );
}

interface CampaignLabelFilterComponentProps {
  disabled: boolean;
  breakdownConfigs: BreakdownConfig[];
  data: CustomerPathFilterStage;
  onUpdate: (stage: CustomerPathFilterStage) => void;
}

function CampaignLabelFilterComponent({
  disabled,
  breakdownConfigs,
  data,
  onUpdate,
}: CampaignLabelFilterComponentProps) {
  const breakdowns: Breakdown[] = data.campaignLabelFilters!;
  return (
    <BreakdownSelector
      disabled={disabled}
      current={breakdowns}
      available={breakdownConfigs}
      allConfigs={breakdownConfigs}
      onUpdate={(newBreakdowns: Breakdown[]) => {
        onUpdate({ ...data, campaignLabelFilters: newBreakdowns });
      }}
    />
  );
}

interface IndividualCampaignFilterComponentProps {
  disabled: boolean;
  fetchCampaigns(query: string): Promise<CampaignSearchResult[]>;
  data: CustomerPathFilterStage;
  onUpdate: (stage: CustomerPathFilterStage) => void;
}

function IndividualCampaignFilterComponent({
  fetchCampaigns,
  data,
  onUpdate,
  disabled,
}: IndividualCampaignFilterComponentProps) {
  const hasCampaignFilledIn = (data.individualCampaignFilters?.length ?? 0) > 0;

  if (!hasCampaignFilledIn) {
    return (
      <CampaignSearchBox
        disabled={disabled}
        debounceMillis={500}
        onResultSelected={(result) =>
          onUpdate({
            individualCampaignFilters: [
              {
                adKey: result.adKey,
                name: result.name,
              },
            ],
          })
        }
        searchForCampaigns={fetchCampaigns}
      />
    );
  }

  return (
    <div>
      {data?.individualCampaignFilters?.[0]?.name}
      <button
        className="btn btn-link btn-small"
        disabled={disabled}
        onClick={() => onUpdate({ individualCampaignFilters: [] })}
      >
        Edit
      </button>
    </div>
  );
}
