import React, { useRef } from "react";

import { useFillHeight } from "../hooks/use-fill-height";
import { CreativeAnalyticsCard } from "./ca-card";
import clsx from "clsx";

export function CreativeAnalyticsCardSleeve() {
  const ref = useRef<HTMLDivElement>(null);

  const availableHeight = useFillHeight(ref);
  return (
    <div
      className={clsx(
        "flex flex-col overflow-auto pt-2 px-5",
        `grid gap-4 4k:grid-cols-6 2xl:grid-cols-4 grid-cols-3`,
      )}
      style={{
        height: availableHeight - 60,
      }}
      ref={ref}
    >
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
      <CreativeAnalyticsCard />
    </div>
  );
}
