import { useOrderSummaryGraphLoading } from "@/atoms/order-page-atoms";
import {
  useBuildAnnotationDataset,
  annotationPlugin,
} from "@features/custom-annotations";
import { LoadingSlideNoText } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import {
  APIGranularity,
  useOrderGraphFilters,
} from "@hooks/use-orders-filters";
import {
  FetchOrderSummaryGraph,
  FetchOrderSummaryGraphVariables,
  FetchOrderSummaryGraph_me_orderSummaryGraph_data,
} from "@nb-api-graphql-generated/FetchOrderSummaryGraph";
import { FETCH_ORDER_SUMMARY_GRAPH } from "@pages/settings/queries";
import { Badge } from "@shared/badge";
import { useNorthbeamQuery } from "@utils/hooks";
import { MetricFormats } from "@utils/metrics";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScatterDataPoint,
  TimeUnit,
  Title,
  Tooltip,
} from "chart.js";
import { omit } from "lodash";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { AnnotationDataset } from "@features/custom-annotations";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

function granularityToUnit(granularity: APIGranularity): TimeUnit {
  switch (granularity) {
    case APIGranularity.DAY:
      return "day";
    case APIGranularity.HOUR:
      return "hour";
  }
}

const buildDataset = (
  graphData?: FetchOrderSummaryGraph_me_orderSummaryGraph_data[],
  annotationDataset?: ReturnType<typeof useBuildAnnotationDataset>,
): ChartData<"line", number[] | AnnotationDataset["data"]> => {
  if (!graphData) return { datasets: [] };

  const ordersDataSet: ChartDataset<"line", number[]>["data"] = [];
  const revenueDataSet: ChartDataset<"line", number[]>["data"] = [];

  const dateLabels: ChartData<"line", ScatterDataPoint[], string>["labels"] =
    [];

  graphData.forEach(({ datetime, orderCount, orderRevenue }) => {
    ordersDataSet.push(orderCount);
    revenueDataSet.push(orderRevenue);
    dateLabels.push(datetime);
  });

  const datasets: (
    | ChartDataset<"line", number[]>
    | ReturnType<typeof useBuildAnnotationDataset>
  )[] = [
    {
      label: "Orders",
      data: ordersDataSet,
      borderColor: "#B6B4F7",
      backgroundColor: "rgba(183, 181, 247, 0.36)",
      yAxisID: "ordersY",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - it is allowed, but API doesn't show it
      type: "bar",
      maxBarThickness: 30,
    },
    {
      label: "Revenue",
      data: revenueDataSet,
      borderColor: "#514FB2",
      backgroundColor: "#514FB2",
      yAxisID: "revenueY",
      tension: 0.3,
    },
  ];

  if (annotationDataset) {
    datasets.push(annotationDataset);
  }

  return {
    labels: dateLabels,
    datasets,
  };
};

export function OrderSummaryGraph() {
  const { dateRange, granularity, ...restOfFilters } = useOrderGraphFilters();

  const annotationDataset = useBuildAnnotationDataset(
    dateRange.start.toISOString().split("T")[0],
    dateRange.end.toISOString().split("T")[0],
  );

  const { data: graphResponse, loading } = useNorthbeamQuery<
    FetchOrderSummaryGraph,
    FetchOrderSummaryGraphVariables
  >(FETCH_ORDER_SUMMARY_GRAPH, {
    variables: {
      filterOptions: omit(restOfFilters, "compareDateRange"),
      dateRange,
      granularity,
    },
  });
  const [, setOrderQueryLoading] = useOrderSummaryGraphLoading();

  const { datasets, labels } = buildDataset(
    graphResponse?.me.orderSummaryGraph.data,
    annotationDataset,
  );
  const { user } = useUser();

  useEffect(() => {
    setOrderQueryLoading(loading);
  }, [loading, setOrderQueryLoading]);

  return (
    <div className="relative m-auto w-[99%] h-[20rem]">
      {loading && (
        <div className="absolute w-36 left-[50%] top-[35%]">
          <LoadingSlideNoText className="w-auto" />
        </div>
      )}
      <div className="flex -mb-6 text-sm w-full">
        <Badge text="Orders" badgeColor="#B6B4F7" />
        <Badge
          text="Revenue"
          badgeColor="#514FB2"
          className="ml-auto"
          reversedOrder
        />
      </div>
      <Line
        options={{
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          plugins: {
            title: {
              display: true,
              align: "start",
              font: {
                size: 18,
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  if (tooltipItem.dataset.label === "Revenue")
                    return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`;
                  return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
                },
              },
            },
          },
          scales: {
            xAxis: {
              type: "time",
              time: {
                tooltipFormat: `ddd D MMM ${
                  granularity === "HOUR" ? "h:mma" : ""
                }`,
                unit: granularityToUnit(granularity),
              },
              ticks: {
                font: { size: 13 },
                maxRotation: 20,
                callback(tickValue, index, ticks) {
                  if (granularity === APIGranularity.DAY)
                    return moment(ticks[index].value)
                      .tz(user.timezone)
                      .format("MMM D");
                  return moment(ticks[index].value)
                    .tz(user.timezone)
                    .format("MMM D, ha");
                },
              },
              grid: {
                drawOnChartArea: false,
                drawTicks: true,
              },
            },
            revenueY: {
              ticks: {
                callback(tickValue, index, ticks) {
                  return MetricFormats.dollars.approximate(Number(tickValue));
                },
              },
              beginAtZero: true,
              type: "linear",
              display: true,
              position: "right",
              grid: {
                drawOnChartArea: false,
              },
            },
            ordersY: {
              type: "linear",
              display: true,
              position: "left",
            },
          },
          layout: {
            padding: {
              bottom: 36,
            },
          },
        }}
        plugins={[annotationPlugin]}
        data={{
          labels,
          datasets,
        }}
      />
    </div>
  );
}
