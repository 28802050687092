import { isEqual, merge } from "lodash";
import { useState } from "react";

type MutableObject = Record<string, any>;

export function useObjectState<T = MutableObject>(
  defaultState: T,
): [T, (change: Partial<T>) => void] {
  const [state, setState] = useState(defaultState);
  return [
    state,
    (change: Partial<T> | ((previous: Partial<T>) => Partial<T>)) =>
      setState((previous) => {
        const evaluatedChange =
          typeof change === "function" ? change(previous) : change;
        const newState = merge({}, previous, evaluatedChange);
        if (isEqual(newState, previous)) return previous;
        return newState;
      }),
  ];
}
