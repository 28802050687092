import { gql } from "@apollo/client";

export const CommonGQLFragments = {
  salesReportRowAll: gql`
    fragment SalesReportRowAll on SalesReportRow {
      objectId
      nbtPlatformID
      name
      dimensions
      link
      extraDetails
      searchableStrings
      cellAnnotations
      dailyMetrics
      modelComparisonPoints
      forecastV1Daily
    }
  `,
};
