import React, { AnchorHTMLAttributes, forwardRef } from "react";

interface GenericExternalLinksProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  children: any;
}

export const GenericExternalLink = forwardRef<
  HTMLAnchorElement,
  GenericExternalLinksProps
>(({ href, children, ...rest }, ref) => {
  let finalHref = href;
  if (!href.startsWith("https://") && !href.startsWith("http://")) {
    finalHref = "https://" + href;
  }
  return (
    <a
      ref={ref}
      href={finalHref}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </a>
  );
});
