import pinterestLogo from "@assets/pinterest-logo.svg";
import { useUser } from "@components/user-context";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { ConnectionCard } from "@pages/settings/connection-card";
import {
  ConnectionStatusType,
  LoginComponentChildrenProps,
  LoginComponentWithFrontendIntegrationProps,
} from "@pages/settings/connection-status-manager";
import { CardProps2 } from "@pages/settings/core";
import { extractError } from "@utils/index";
import { launchOAuthPopup } from "@utils/oauth2-handler";
import React from "react";
import { serializeError } from "serialize-error";

export function PinterestConnectionCard(props: CardProps2) {
  return (
    <ConnectionCard
      type={type}
      connectionTypeName="Pinterest Ads"
      connectionCallToAction={`
        Integrate your Pinterest Ads data to start receiving insights about
        your Pinterest ad spend.
      `}
      logoUrl={pinterestLogo}
      logoAltText="Pinterest Ads Logo"
    >
      {({ setStatus, accounts }: LoginComponentChildrenProps) => (
        <PinterestLoginComponent
          setStatus={setStatus}
          accounts={accounts}
          integrationDetails={props.integrationDetails}
        />
      )}
    </ConnectionCard>
  );
}

function PinterestLoginComponent({
  setStatus,
  integrationDetails,
}: LoginComponentWithFrontendIntegrationProps) {
  const { user } = useUser();
  const [isButtonDisabled, setButtonDisabled] = React.useState(false);

  const { pinterestAuthBlob } = integrationDetails;

  const onConnectClicked = React.useCallback(async () => {
    try {
      setButtonDisabled(true);

      const tokenPayload = await openPinterestPopup(
        pinterestAuthBlob.client_id,
        pinterestAuthBlob.scope,
      );

      const result = await user.callApi({
        method: "post",
        url: "/api/callbacks/pinterest",
        data: {
          tokenPayload,
          redirectURI: `${window.location.origin}/callbacks/pinterest`,
        },
      });

      if (result.error) {
        setStatus({
          type: ConnectionStatusType.HAS_ERROR,
          errorMessage: `An error occurred: ${serializeError(
            extractError(result.error),
          )}`,
        });
      } else {
        setStatus({
          type: ConnectionStatusType.SELECTING_ACCOUNT,
          accountList: result.accounts,
        });
      }
    } catch (e) {
      setStatus({
        type: ConnectionStatusType.HAS_ERROR,
        errorMessage: `An error occurred: ${serializeError(extractError(e))}`,
      });
    }

    setButtonDisabled(false);
  }, [setStatus, setButtonDisabled, user, pinterestAuthBlob]);

  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-auto">
          <button
            disabled={isButtonDisabled}
            className="btn btn-primary"
            onClick={onConnectClicked}
          >
            {isButtonDisabled ? "Please Wait..." : "Connect"}
          </button>
        </div>
      </div>
    </div>
  );
}

async function openPinterestPopup(oauthClientId: string, oauthScopes: string) {
  const params = new URLSearchParams();

  params.append("consumer_id", oauthClientId);
  params.append(
    "redirect_uri",
    `${window.location.origin}/callbacks/pinterest`,
  );
  params.append("response_type", "code");
  params.append("refreshable", "true");
  params.append("scope", oauthScopes);

  return launchOAuthPopup(
    `https://www.pinterest.com/oauth/?${params.toString()}`,
  );
}

const type = ConnectionType.integration_pinterest;

/*
  const params = [
    `consumer_id=${oauthClientId}`,
    `redirect_uri=${window.location.origin}/callbacks/pinterest`,
    "response_type=code",
    "refreshable=true",
    `scope=${oauthScopes}`,
  ].join("&");
  */
