import {
  ScheduleConfig,
  ScheduledTimeFrame,
} from "@features/data-export/types";
import { DataExportFrequency } from "@nb-api-graphql-generated/global-types";
import { Radio } from "@shared/radio-group";
import { usePrettyLogEventPage } from "@utils/analytics";
import { motion } from "framer-motion";
import React, { KeyboardEventHandler, useState } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { DailyConfigurator } from "./daily-configurator";
import { MonthlyConfigurator } from "./monthly-configurator";
import { WeeklyConfigurator } from "./weekly-configurator";

interface Props {
  scheduleConfig: ScheduleConfig;
  onChangeSchedule(config: ScheduleConfig): void;
  onClose(): void;
}

export function ScheduleConfigurator({
  scheduleConfig,
  onChangeSchedule,
  onClose,
}: Props) {
  const prettyLogEventPage = usePrettyLogEventPage();

  const [recipientsInputValue, setRecipientsInputValue] = useState("");

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!recipientsInputValue) return;

    if (event.key === "Enter" || event.key === "Tab") {
      const isEmailLike = recipientsInputValue.includes("@");
      const alreadyExists =
        scheduleConfig.recipients.includes(recipientsInputValue);

      if (isEmailLike && !alreadyExists) {
        prettyLogEventPage({
          domain: "DataExport",
          action: "onClick",
          additionalActionInfo: "Data export recipient added",
        });

        onChangeSchedule({
          ...scheduleConfig,
          recipients: [...scheduleConfig.recipients, recipientsInputValue],
        });

        setRecipientsInputValue("");
      }

      event.preventDefault();
    }
  };

  const addTimeFrame = (timeFrame: ScheduledTimeFrame) => {
    prettyLogEventPage({
      domain: "DataExport",
      action: "onClick",
      additionalActionInfo: "Data export time frame added",
    });

    onChangeSchedule({
      ...scheduleConfig,
      timeFrames: [...scheduleConfig.timeFrames, timeFrame],
    });
  };

  const removeTimeFrame = (timeFrame: ScheduledTimeFrame) => {
    prettyLogEventPage({
      domain: "DataExport",
      action: "onClick",
      additionalActionInfo: "Data export time frame removed",
    });

    onChangeSchedule({
      ...scheduleConfig,
      timeFrames: scheduleConfig.timeFrames.filter((e) => e !== timeFrame),
    });
  };

  const changeTimeFrames = (timeFrames: ScheduledTimeFrame[]) => {
    prettyLogEventPage({
      domain: "DataExport",
      action: "onClick",
      additionalActionInfo: "Data export time frame changed",
    });

    onChangeSchedule({ ...scheduleConfig, timeFrames });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className="absolute h-full w-full right-0 z-50 bg-[#F5F7FDB2]/90
                 grid grid-cols-[auto_minmax(0,34rem)_22rem] overflow-hidden"
    >
      <motion.div
        animate={{ translateX: 0 }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        style={{ translateX: 200 }}
        className="col-start-2 bg-white border-l border-gray-100
                   grid grid-rows-[fit-content(100%)_auto] overflow-hidden"
      >
        <div className="py-5 px-8">
          <button
            className="text-blue-500 text-xs font-normal"
            onClick={() => onClose()}
          >
            <i className="fa-solid fa-arrow-left text-[0.6rem] cursort-pointer mr-1.5"></i>
            Back
          </button>
        </div>

        <div className="px-8 overflow-auto">
          <div className="px-0 py-2 xl:px-10">
            <h3 className="text-base font-semibold m-0 mb-2">
              Select report frequency
            </h3>
            <p className="text-xs text-zinc-500 font-light m-0 mb-7">
              This is the number of days your report will be exported and
              automatically sent
            </p>

            <div className="text-[12px] font-light space-y-3 xl:space-y-4">
              <div>
                <Radio
                  label="Daily"
                  value="daily"
                  checked={
                    scheduleConfig.frequency === DataExportFrequency.DAILY
                  }
                  onChange={(val) => {
                    prettyLogEventPage({
                      domain: "DataExport",
                      action: "onSelect",
                      data: {
                        frequency: "daily",
                      },
                      additionalActionInfo: "Data export schedule frequency",
                    });

                    onChangeSchedule({
                      ...scheduleConfig,
                      frequency: DataExportFrequency.DAILY,
                      timeFrames: [{ day: "*", time: "08:00" }],
                    });
                  }}
                  labelClassName="cursor-pointer"
                  inputClassName="cursor-pointer h-3 w-3"
                />
              </div>
              <div>
                <Radio
                  label="Weekly"
                  value="weekly"
                  checked={
                    scheduleConfig.frequency === DataExportFrequency.WEEKLY
                  }
                  onChange={(val) => {
                    prettyLogEventPage({
                      domain: "DataExport",
                      action: "onSelect",
                      data: {
                        frequency: "weekly",
                      },
                      additionalActionInfo: "Data export schedule frequency",
                    });

                    onChangeSchedule({
                      ...scheduleConfig,
                      frequency: DataExportFrequency.WEEKLY,
                      timeFrames: [{ day: "1", time: "08:00" }],
                    });
                  }}
                  labelClassName="cursor-pointer"
                  inputClassName="cursor-pointer h-3 w-3"
                />
              </div>
              <div>
                <Radio
                  label="Monthly"
                  value="monthly"
                  checked={
                    scheduleConfig.frequency === DataExportFrequency.MONTHLY
                  }
                  onChange={(val) => {
                    prettyLogEventPage({
                      domain: "DataExport",
                      action: "onSelect",
                      data: {
                        frequency: "monthly",
                      },
                      additionalActionInfo: "Data export schedule frequency",
                    });

                    onChangeSchedule({
                      ...scheduleConfig,
                      frequency: DataExportFrequency.MONTHLY,
                      timeFrames: [{ day: "1", time: "08:00" }],
                    });
                  }}
                  labelClassName="cursor-pointer"
                  inputClassName="cursor-pointer h-3 w-3"
                />
              </div>
            </div>
          </div>

          <div className="px-0 py-5 xl:px-10 xl:py-7">
            <h3 className="text-base font-semibold m-0 mb-2">
              Enter email recipient(s)
            </h3>
            <p className="text-xs text-zinc-500 font-light m-0 mb-6">
              Your exports will be sent to the email addresses entered below
              with the timeframe(s) selected above
            </p>

            <CreatableSelect
              components={{
                DropdownIndicator: null,
                ClearIndicator: undefined,
                MultiValueRemove: (props) => (
                  <components.MultiValueRemove {...props}>
                    <i className="fa-regular fa-xmark text-[0.8rem] text-gray-700 pt-0.5" />
                  </components.MultiValueRemove>
                ),
              }}
              classNames={{
                control: () =>
                  "max-h-[120px] overflow-y-auto shadow-none rounded-md",
                input: () => "text-xs font-light text-blue-500",
                multiValue: () =>
                  "bg-[#F5F8FE] rounded-2xl text-xs font-light px-1.5 items-center",
                multiValueLabel: () => "text-blue-500",
                multiValueRemove: () => "bg-transparent text-black ml-0.5",
              }}
              inputValue={recipientsInputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              placeholder=""
              onChange={(newValue) => {
                onChangeSchedule({
                  ...scheduleConfig,
                  recipients: newValue.map((e) => e.value),
                });
              }}
              onInputChange={(newValue) => setRecipientsInputValue(newValue)}
              onKeyDown={handleKeyDown}
              value={scheduleConfig.recipients.map((e) => ({
                label: e,
                value: e,
              }))}
            />
          </div>
        </div>
      </motion.div>

      <motion.div
        animate={{ translateX: 0 }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        style={{ translateX: 200 }}
        className="h-full bg-gray-50 flex flex-col overflow-hidden"
      >
        {scheduleConfig.frequency === DataExportFrequency.DAILY && (
          <DailyConfigurator
            timeFrames={scheduleConfig.timeFrames}
            onChange={changeTimeFrames}
            onAdd={addTimeFrame}
            onRemove={removeTimeFrame}
          />
        )}
        {scheduleConfig.frequency === DataExportFrequency.WEEKLY && (
          <WeeklyConfigurator
            timeFrames={scheduleConfig.timeFrames}
            onChange={changeTimeFrames}
            onAdd={addTimeFrame}
            onRemove={removeTimeFrame}
          />
        )}
        {scheduleConfig.frequency === DataExportFrequency.MONTHLY && (
          <MonthlyConfigurator
            timeFrame={scheduleConfig.timeFrames[0]}
            onChange={(value) => changeTimeFrames([value])}
          />
        )}
      </motion.div>
    </motion.div>
  );
}
