import { BenchmarkAttributionWindowValues } from "@/constants/benchmarking";
import { emptyText } from "@/constants/empty-text";
import {
  BenchmarkAttributionWindowEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";
import { isNil } from "lodash";
import moment from "moment";

export const getDollarValue = (value: number | null | undefined) => {
  return !isNil(value) ? `$${value.toFixed(2)}` : emptyText;
};

export const getDecimalValue = (value: number | null | undefined) => {
  return !isNil(value) ? `${value.toFixed(2)}` : emptyText;
};

export const getPrettyBenchmark = (
  benchmarkType: BenchmarkTypeEnum,
  value: number | null | undefined,
) => {
  if (benchmarkType === BenchmarkTypeEnum.CAC) return getDollarValue(value);
  return `${getDecimalValue(value)}`;
};

export const attributionWindowIsEnabled = (
  attributionWindow: BenchmarkAttributionWindowEnum,
  userActivatedAt: string | null,
) => {
  if (!userActivatedAt) return false;

  return (
    moment(userActivatedAt) <
    moment().subtract(BenchmarkAttributionWindowValues[attributionWindow])
  );
};
