import bigcommerceLogo from "@assets/bigcommerce-logo.svg";
import genericShopLogo from "@assets/generic-shop-logo.svg";
import magentoLogo from "@assets/magento-logo.svg";
import shopifyLogo from "@assets/shopify-mini-logo.png";
import wixLogo from "@assets/wix-logo.svg";
import woocommerceLogo from "@assets/woocommerce-logo.svg";

import { FetchSettingsDetails } from "@nb-api-graphql-generated/FetchSettingsDetails";
import { ShopifyConnectionCard } from "@pages/settings/connections/shopify";
import { DataWrapper } from "@pages/settings/data-wrapper";
import React, { FC, useState } from "react";
import StepWizard, { StepWizardChildProps } from "react-step-wizard";
import { CustomEcommerceIntegration } from "../connections/custom-ecommerce-integration";
import { MagentoConnectionFlow } from "./magento-connection-flow";
import { PixelPlacementCardWrapper } from "./pixel-placement-card-wrapper";
import { PointOfSalePlatformCollectionStep } from "./point-of-sale-platform-collection-step";
import { TrackingScriptTagsStep } from "./tracking-script-tags-step";
import { WooCommerceConnectionFlow } from "./woocommerce-connection-flow";

export enum PLATFORM {
  SHOPIFY = "Shopify",
  WOO_COMMERCE = "WooCommerce",
  MAGENTO = "Magento",
  BIG_COMMERCE = "BigCommerce",
  WIX = "Wix",
  OTHER = "Other",
}

export const CUSTOM_ECOMMERCE_SETUP = "CustomEcommerce";

type PlatformLogoMapper = Partial<Record<PLATFORM, string>>;

export const GLOBAL_CONNECTABLE_POINT_OF_SALE_PLATFORMS = {
  // NOTE: IF YOU ARE ALTERING THIS, ENSURE TO, IN THE SAME PR:
  // 1. Alter the GLOBAL_OTHER_POINT_OF_SALE_PLATFORMS
  // 2. Remove existing FFs for the user-specific getter if transitioning
  // from beta to launch
  [PLATFORM.SHOPIFY]: shopifyLogo,
  [PLATFORM.WOO_COMMERCE]: woocommerceLogo,
  [PLATFORM.MAGENTO]: magentoLogo,
};

const GLOBAL_OTHER_POINT_OF_SALE_PLATFORMS = {
  // NOTE: IF YOU ARE ALTERING THIS, ENSURE TO, IN THE SAME PR:
  // 1. Alter the GLOBAL_CONNECTABLE_POINT_OF_SALE_PLATFORMS
  // 2. Remove existing FFs for the user-specific getter if transitioning from
  // beta to launch
  [PLATFORM.MAGENTO]: magentoLogo,
  [PLATFORM.BIG_COMMERCE]: bigcommerceLogo,
  [PLATFORM.WIX]: wixLogo,
  [PLATFORM.OTHER]: genericShopLogo,
};

export const GET_OTHER_POINT_OF_SALE_PLATFORMS = (): PlatformLogoMapper => {
  const filteredOtherPlatforms = {
    ...GLOBAL_OTHER_POINT_OF_SALE_PLATFORMS,
  } as Record<string, string>;

  delete filteredOtherPlatforms[PLATFORM.MAGENTO];
  return filteredOtherPlatforms as Omit<
    typeof GLOBAL_OTHER_POINT_OF_SALE_PLATFORMS,
    PLATFORM.MAGENTO
  >;
};

export const PixelPlacementSteps: FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
}) => {
  const [selectedPointOfSalePlatforms, setSelectedPointOfSalePlatforms] =
    useState<string[]>([]);

  const platformNeedToBeConnected = (platform: string) => {
    return selectedPointOfSalePlatforms.indexOf(platform) !== -1;
  };

  const toggleSelectedPointOfSalePlatforms = (platform: string) => {
    let newPlatforms = selectedPointOfSalePlatforms;
    if (platformNeedToBeConnected(platform)) {
      newPlatforms = selectedPointOfSalePlatforms.filter(
        (existingPlatform) => existingPlatform !== platform,
      );
    } else {
      newPlatforms = [...selectedPointOfSalePlatforms, platform];
    }
    setSelectedPointOfSalePlatforms(newPlatforms);
  };

  if (!nextStep || !previousStep) {
    return <></>;
  }

  return (
    <DataWrapper>
      {(data: FetchSettingsDetails) => {
        const { integrationDetails } = data;

        return (
          <StepWizard
            isLazyMount
            transitions={{
              enterRight: "",
              enterLeft: "",
              exitRight: "",
              exitLeft: "",
            }}
          >
            <PointOfSalePlatformCollectionStep
              stepName="point-of-sale-collection"
              outerPreviousStep={previousStep}
              outerNextStep={nextStep}
              selectedPointOfSalePlatforms={selectedPointOfSalePlatforms}
              toggleSelectedPointOfSalePlatforms={
                toggleSelectedPointOfSalePlatforms
              }
            />

            <PixelPlacementCardWrapper
              selectedPointOfSalePlatforms={selectedPointOfSalePlatforms}
              stepName={PLATFORM.SHOPIFY}
            >
              <ShopifyConnectionCard integrationDetails={integrationDetails} />
            </PixelPlacementCardWrapper>

            <PixelPlacementCardWrapper
              selectedPointOfSalePlatforms={selectedPointOfSalePlatforms}
              stepName={PLATFORM.WOO_COMMERCE}
            >
              <WooCommerceConnectionFlow />
            </PixelPlacementCardWrapper>

            <PixelPlacementCardWrapper
              selectedPointOfSalePlatforms={selectedPointOfSalePlatforms}
              stepName={PLATFORM.MAGENTO}
            >
              <MagentoConnectionFlow />
            </PixelPlacementCardWrapper>

            <PixelPlacementCardWrapper
              selectedPointOfSalePlatforms={selectedPointOfSalePlatforms}
              stepName={CUSTOM_ECOMMERCE_SETUP}
            >
              <CustomEcommerceIntegration />
            </PixelPlacementCardWrapper>

            <TrackingScriptTagsStep
              outerNextStep={nextStep}
              stepName="tracking-script-tags"
              selectedPointOfSalePlatforms={selectedPointOfSalePlatforms}
            />
          </StepWizard>
        );
      }}
    </DataWrapper>
  );
};
