import { PLATFORMS } from "@/constants/benchmarking";
import {
  FETCH_BENCHMARK_SETTINGS,
  FETCH_PLATFORM_BENCHMARKS,
} from "@gql/benchmarks";
import {
  FetchBenchmarkSettings,
  FetchBenchmarkSettingsVariables,
} from "@nb-api-graphql-generated/FetchBenchmarkSettings";
import {
  FetchPlatformBenchmarks,
  FetchPlatformBenchmarksVariables,
  FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks,
} from "@nb-api-graphql-generated/FetchPlatformBenchmarks";
import {
  BenchmarkAttributionModelEnum,
  BenchmarkAttributionWindowEnum,
  BenchmarkPlatformEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";

import { useNorthbeamLazyQuery, useNorthbeamQuery } from "@utils/hooks";
import { sortByAnotherArray } from "@utils/sorting";
import { compact, flatten, groupBy, uniq } from "lodash";
import { useEffect, useMemo } from "react";

// In the future this will allow for custom ordering
export const useGetBenchmarkPlatformsToUse = () => PLATFORMS;

const makeDefaultBenchmarks = ({
  attributionModels,
  platforms,
}: {
  attributionModels: BenchmarkAttributionModelEnum[];
  platforms: BenchmarkPlatformEnum[];
}): FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks[] => {
  return flatten(
    platforms.map((platform) =>
      attributionModels.map((attributionModel) => ({
        __typename: "PlatformCACRoasBenchmarks",
        target: 0,
        cac: null,
        roas: null,
        platform,
        attributionModel,
        benchmarkType: BenchmarkTypeEnum.CAC,
      })),
    ),
  );
};

const useOrderedData = ({
  data,
  attributionModels,
  platforms,
}: {
  attributionModels: BenchmarkAttributionModelEnum[];
  platforms: BenchmarkPlatformEnum[];
  data: FetchPlatformBenchmarks | undefined;
}) => {
  return useMemo(() => {
    if (!data) return makeDefaultBenchmarks({ attributionModels, platforms });

    const groupedByPlatform = groupBy(
      data?.me.benchmarks.platformBenchmarks,
      "platform",
    );

    // go through the ordered platform list, and order the attribution models, based on the ordering given for attribution models
    return flatten(
      platforms.map((p) =>
        sortByAnotherArray(
          groupedByPlatform[p] || [],
          attributionModels,
          (item) => item.attributionModel,
        ),
      ),
    );
  }, [attributionModels, data, platforms]);
};

export const usePlatformBenchmarks = ({
  benchmarkTargets,
}: FetchPlatformBenchmarksVariables): {
  orderedData: FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks[];
  loading: boolean;
} => {
  const { loading, data } = useNorthbeamQuery<
    FetchPlatformBenchmarks,
    FetchPlatformBenchmarksVariables
  >(FETCH_PLATFORM_BENCHMARKS, {
    variables: {
      benchmarkTargets,
    },
  });

  const attributionModels = uniq(
    flatten(benchmarkTargets.map((target) => target.attributionModels)),
  );
  const platforms = uniq(benchmarkTargets.map((target) => target.platform));
  const orderedData = useOrderedData({ data, attributionModels, platforms });

  return { orderedData, loading };
};

export const useBenchmarks = (
  attributionModel: BenchmarkAttributionModelEnum,
  attributionWindow: BenchmarkAttributionWindowEnum,
  platforms: BenchmarkPlatformEnum[],
  attributionModels: BenchmarkAttributionModelEnum[],
) => {
  const { data: currentBenchmarkSettings, loading: benchmarkSettingsLoading } =
    useNorthbeamQuery<FetchBenchmarkSettings, FetchBenchmarkSettingsVariables>(
      FETCH_BENCHMARK_SETTINGS,
      {
        variables: { attributionWindow, attributionModel },
      },
    );

  const {
    loadData: loadPlatformBenchmarks,
    data: { loading: platformBenchmarksLoading, data: platformBenchmarks },
  } = useNorthbeamLazyQuery<
    FetchPlatformBenchmarks,
    FetchPlatformBenchmarksVariables
  >(FETCH_PLATFORM_BENCHMARKS);

  useEffect(() => {
    if (!currentBenchmarkSettings?.me.benchmarks.benchmarkSettings) return;

    const benchmarkSettings =
      currentBenchmarkSettings.me.benchmarks.benchmarkSettings;

    const benchmarkTargetsInput: FetchPlatformBenchmarksVariables["benchmarkTargets"] =
      benchmarkSettings.map((setting) => ({
        attributionModels,
        attributionWindow: setting.attributionWindow,
        benchmarkType: setting.benchmark,
        platform: setting.platform,
        target: setting.target,
      }));

    loadPlatformBenchmarks({
      variables: {
        benchmarkTargets: benchmarkTargetsInput,
      },
    });
  }, [
    attributionModels,
    platforms,
    currentBenchmarkSettings,
    loadPlatformBenchmarks,
  ]);

  const orderedData = useOrderedData({
    platforms,
    attributionModels,
    data: platformBenchmarks,
  });

  const customBenchmarks = useMemo(() => {
    const _benchmarks = compact(
      flatten(
        currentBenchmarkSettings?.me.benchmarks.benchmarkSettings?.map(
          (benchmarkSettings) => benchmarkSettings.customBenchmarks,
        ),
      ),
    );
    return _benchmarks.length ? _benchmarks : null;
  }, [currentBenchmarkSettings?.me.benchmarks.benchmarkSettings]);

  return {
    benchmarkSettings:
      currentBenchmarkSettings?.me.benchmarks.benchmarkSettings,
    benchmarkSettingsLoading,
    platformBenchmarksLoading,
    orderedData,
    customBenchmarks,
  };
};
