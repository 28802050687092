import { usePageTitle } from "@/atoms/page-title-atom";
import { gql } from "@apollo/client";
import { ControlBar, ControlBarElement } from "@components/control-bar";
import { useCustomerBreakdownConfigs } from "@components/data/customer-breakdown-configs";
import {
  CompanionWrapper,
  ScreenWithControlCenter,
} from "@components/reports/control-center-utilities";
import {
  AugmentedFactColumn,
  AugmentedTableRow,
  RenderCellProps,
  RenderHeaderProps,
  VirtualizedTable,
} from "@components/reports/virtualized-table";
import {
  LoadingSlide,
  NoDataView,
  TitleSlide,
} from "@components/title-slide-view";
import { HTMLTooltip } from "@components/tooltip-container";
import { H1 } from "@components/utilities";
import {
  GetCustomerReport,
  GetCustomerReportVariables,
  GetCustomerReport_me_customerReport as CustomerReportResponse,
  GetCustomerReport_me_customerReport_reports_metric as Metric,
} from "@nb-api-graphql-generated/GetCustomerReport";
import { CategoricalBreakdownConfig } from "@north-beam/nb-common";
import { MetricFormat } from "@north-beam/nb-common";
import { Table, TableRow } from "@north-beam/nb-common";
import { formatNumberExact } from "@north-beam/nb-common";
import { logEvent, LogOnMount } from "@utils/analytics";
import { downloadCsv } from "@utils/csv";
import { useNorthbeamQuery } from "@utils/hooks";
import { toOption } from "@utils/utils";
import chroma from "chroma-js";
import classNames from "classnames";
import { chain, mapValues, max, min } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { ColumnShape } from "react-base-table";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  CustomerControlCenter,
  CustomerReportParams,
  isReadyForSubmit,
  makeReportStateQuery,
  parseReportState,
} from "./control-center";
import {
  defaultSumMode,
  SumMode,
  SumModeEnum,
  SumModeSelect,
} from "./sum-mode-select";

interface CustomerReportRow {
  link?: string;
  dimensions: Record<string, string>;
  count: number;
  firstValue: number;
  marginalValues: Record<string, number>;
}

interface CustomerReportTable {
  rows: CustomerReportRow[];
  dimensionHeaders: string[];
  marginalValueHeaders: string[];
}

interface FetchReportParams {
  response: CustomerReportResponse | undefined;
  isLoading: boolean;
  isIncomplete: boolean;
}

function ReportDynamicArea({
  response,
  isLoading,
  isIncomplete,
}: FetchReportParams) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const breakdownMode = params.get("breakdown") ?? undefined;

  const [selectedTableIndex, setSelectedTableIndex] = React.useState(0);
  const [rollupMode, setRollupMode] = React.useState<RollupMode>(
    ROLLUP_MODES[1],
  );
  const [sumMode, setSumMode] = React.useState<SumMode>(defaultSumMode);
  const [filterValue, setFilterValue] = React.useState("");

  const tableBundle = React.useMemo(() => {
    if (!response) {
      return undefined;
    }

    const { reports } = response;

    if (!reports || reports.length === 0) {
      return undefined;
    }

    const { metric, table: table_ } = reports[selectedTableIndex] || reports[0];
    const table: CustomerReportTable = table_;
    const filteredRows = table.rows.filter(
      (v) =>
        v.dimensions[table.dimensionHeaders[0]]
          .toLowerCase()
          .indexOf(filterValue.toLowerCase()) >= 0,
    );
    const newTable = { ...table, rows: filteredRows };
    return transformTable(newTable, metric, rollupMode, sumMode, breakdownMode);
  }, [
    response,
    selectedTableIndex,
    rollupMode,
    sumMode,
    filterValue,
    breakdownMode,
  ]);

  if (isIncomplete) {
    return (
      <TitleSlide>
        <p>Customize your report on the left-hand side.</p>
      </TitleSlide>
    );
  }

  if (response) {
    if (!tableBundle) {
      return <NoDataView />;
    }

    const [table, totalRow] = tableBundle;

    const correctedIndex = Math.min(
      selectedTableIndex,
      response.reports.length,
    );
    const selectedMetricOption = toOption(
      correctedIndex.toString(),
      response.reports[correctedIndex].metric.name,
    );

    let disclaimer = null;

    if (
      !(
        breakdownMode?.match("first_order_month") ||
        breakdownMode?.match("first_order_week_monday")
      )
    ) {
      disclaimer = <p>Only up to top 100 records are displayed.</p>;
    }

    return (
      <div className="px-4">
        <div className="row">
          <div className="col">
            <H1>LTV</H1>
            <p>Understand what drives your customers.</p>
          </div>
        </div>
        <ControlBar>
          <ControlBarElement title="Metric">
            <Select
              isDisabled={isLoading}
              isClearable={false}
              value={selectedMetricOption}
              options={chain(response.reports)
                .map(({ metric }, index) => ({
                  value: index.toString(),
                  label: metric.name,
                }))
                .value()}
              onChange={(v: any) => setSelectedTableIndex(parseInt(v.value))}
            />
          </ControlBarElement>
          <ControlBarElement title="Rollup mode">
            <Select
              isDisabled={isLoading}
              isSearchable={false}
              value={toOption(rollupMode)}
              options={ROLLUP_MODES.map((v) => toOption(v))}
              onChange={(v: any) => setRollupMode(v.value)}
            />
          </ControlBarElement>
          <ControlBarElement title="Average vs total">
            <SumModeSelect
              isLoading={isLoading}
              sumMode={sumMode}
              setSumMode={setSumMode}
            />
          </ControlBarElement>
          <ControlBarElement title="Search">
            <input
              type="text"
              className="form-control"
              style={{ maxWidth: "fit-content" }}
              value={filterValue}
              onChange={(event) => setFilterValue(event.target.value)}
            />
          </ControlBarElement>
          <ControlBarElement
            title="Export"
            parentClassName="px-2 nb-control-bar-element"
          >
            <button
              className="btn btn-small mr-auto btn-primary"
              onClick={() =>
                downloadCsv(
                  "Customers",
                  "north-beam-customers-report.csv",
                  table,
                )
              }
            >
              Export to CSV
            </button>
          </ControlBarElement>
        </ControlBar>
        {disclaimer}
        <div className="row my-3">
          <div className="col nb-font-feature-settings-tnum">
            <div className={(isLoading && "waiting-interstitial") || ""}>
              <VirtualizedTable
                allowSortingByLastDimensionColumn={true}
                defaultSortBy={table.dimensionColumns[0].key}
                frozenRows={[
                  {
                    ...totalRow,
                    id: totalRow.export[table.dimensionColumns[0].key],
                    children: [],
                  },
                ]}
                rows={table.rows.map((v) => ({
                  ...v,
                  id: v.export[table.dimensionColumns[0].key],
                  children: [],
                }))}
                dimensionColumn={table.dimensionColumns[0]}
                factColumns={table.factColumns}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <LoadingSlide />;
}

const ReportInner = (props: {
  breakdownConfigs: CategoricalBreakdownConfig[];
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const monthConfig = props.breakdownConfigs.find(
    (v) => v.key === "first_order_month",
  ) as CategoricalBreakdownConfig | undefined;

  const firstAcquiredBounds = monthConfig
    ? {
        startMonth: JSON.parse(
          min(monthConfig.choices.map(({ value }) => value))!,
        ),
        endMonth: JSON.parse(
          max(monthConfig.choices.map(({ value }) => value))!,
        ),
      }
    : DEFAULT_BOUNDS;

  const state = parseReportState(search, firstAcquiredBounds);
  const skip = !isReadyForSubmit(state);
  const { loading, data } = useNorthbeamQuery<
    GetCustomerReport,
    GetCustomerReportVariables
  >(GET_CUSTOMER_REPORT, {
    variables: state as any,
    skip,
  });

  React.useEffect(() => {
    if (loading) {
      logEvent("Request Customers Report", {
        Breakdowns: [state.breakdown?.key].filter((v) => !!v),
      });
    }
  }, [loading, state.breakdown]);

  // Cache report response
  const [response, setResponse] = React.useState<
    CustomerReportResponse | undefined
  >();
  const cr = data?.me?.customerReport;
  React.useEffect(() => {
    if (cr) {
      setResponse(cr);
    }
  }, [cr]);

  // Tricky chain here:
  // 1. URL is updated with new slug
  // 2. URL slug determines report parameters in child components
  const updateReport = React.useCallback(
    (newReportState: CustomerReportParams) =>
      navigate({ search: makeReportStateQuery(newReportState).toString() }),
    [navigate],
  );

  return (
    <ScreenWithControlCenter>
      <LogOnMount name="Visit LTV page" />
      <CustomerControlCenter
        initialState={state}
        onReportStateUpdated={updateReport}
        disabled={loading}
        breakdownConfigs={props.breakdownConfigs}
        firstAcquiredBounds={firstAcquiredBounds}
      />
      <CompanionWrapper>
        <ReportDynamicArea
          isLoading={loading}
          response={response}
          isIncomplete={skip}
        />
      </CompanionWrapper>
    </ScreenWithControlCenter>
  );
};

export const Report = (): JSX.Element => {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Customer LTV");
  }, [setPageTitle]);
  const { value: breakdownConfigs } = useCustomerBreakdownConfigs();

  if (!breakdownConfigs) {
    return <LoadingSlide />;
  }

  return <ReportInner breakdownConfigs={breakdownConfigs} />;
};

const ROLLUP_MODES = [
  "Marginal",
  "Cumulative",
  "Cumulative excluding Day 1",
] as const;
type RollupMode = (typeof ROLLUP_MODES)[number];

const transformTable = (
  table: CustomerReportTable,
  metric: Metric,
  rollupMode: RollupMode,
  sumMode: SumMode,
  breakdownMode: string | undefined,
): [Table, TableRow] => {
  const breakdownKey = JSON.parse(breakdownMode!).key;
  const { dimensionHeaders, marginalValueHeaders, rows: originalRows } = table;
  const title = `${rollupMode} ${metric.name} (${sumMode})`;
  const dimensionColumns = dimensionHeaders.map((h) => ({ name: h, key: h }));

  const rows: TableRow[] = originalRows.map((row) => {
    return createTableRow(
      row,
      marginalValueHeaders,
      rollupMode,
      sumMode,
      dimensionHeaders,
      metric,
    );
  });

  // Used for calculating color intensity
  const tableMinMax: { [prop: string]: any } = {};
  rows.forEach((row) => {
    Object.entries(row.export).forEach(([key, value]) => {
      // Ignore the dimension column
      if (key !== dimensionColumns[0].key) {
        if (tableMinMax[key] === undefined) {
          tableMinMax[key] = {};
        }
        if (
          tableMinMax[key]["max"] === undefined ||
          value > tableMinMax[key]["max"]
        ) {
          tableMinMax[key]["max"] = value;
        }
        if (
          tableMinMax[key]["min"] === undefined ||
          value < tableMinMax[key]["min"]
        ) {
          tableMinMax[key]["min"] = value;
        }
      }
    });
  });

  const factColumns: AugmentedFactColumn[] = [
    {
      name: "Customers",
      key: "Customers",
      renderHeader: ({ column }: RenderHeaderProps) => {
        return createHeader(
          "Customers",
          "Total number of customers in this cohort.",
        );
      },
      renderCell: ({ row, column }: RenderCellProps) => {
        return createCell(
          row,
          column,
          tableMinMax[column.key]?.min,
          tableMinMax[column.key]?.max,
          breakdownKey,
        );
      },
    },
    {
      name: "Day 1",
      key: "Day 1",
      renderHeader: ({ column }: RenderHeaderProps) => {
        return createHeader("Day 1", "Activity in the first day.");
      },
      renderCell: ({ row, column }: RenderCellProps) => {
        return createCell(
          row,
          column,
          tableMinMax[column.key]?.min,
          tableMinMax[column.key]?.max,
          breakdownKey,
        );
      },
    },
  ];

  if (rollupMode === "Marginal") {
    factColumns.push(
      ...marginalValueHeaders.map((header, index) => {
        const leftName =
          index === 0
            ? "2"
            : String(parseInt(marginalValueHeaders[index - 1]) + 1);
        const leftWords =
          index === 0
            ? "day 2"
            : `day ${parseInt(marginalValueHeaders[index - 1]) + 1}`;
        return {
          name: `Day ${leftName}—${header}`,
          key: header,
          renderHeader: ({ column }: RenderHeaderProps) => {
            return createHeader(
              `Day ${leftName}—${header}`,
              `Activity between ${leftWords} and day ${header}.`,
            );
          },
          renderCell: ({ row, column }: RenderCellProps) => {
            return createCell(
              row,
              column,
              tableMinMax[column.key]?.min,
              tableMinMax[column.key]?.max,
              breakdownKey,
            );
          },
        };
      }),
    );
  } else if (rollupMode === "Cumulative") {
    factColumns.push(
      ...marginalValueHeaders.map((header) => {
        return {
          name: `Day 1—${header}`,
          key: header,
          renderHeader: ({ column }: RenderHeaderProps) => {
            return createHeader(
              `Day 1—${header}`,
              `Activity up until day ${header}.`,
            );
          },
          renderCell: ({ row, column }: RenderCellProps) => {
            return createCell(
              row,
              column,
              tableMinMax[column.key]?.min,
              tableMinMax[column.key]?.max,
              breakdownKey,
            );
          },
        };
      }),
    );
  } else {
    factColumns.push(
      ...marginalValueHeaders.map((header) => {
        return {
          name: `Day 2—${header}`,
          key: header,
          renderHeader: ({ column }: RenderHeaderProps) => {
            return createHeader(
              `Day 2—${header}`,
              `Activity between day 2 and day ${header}.`,
            );
          },
          renderCell: ({ row, column }: RenderCellProps) => {
            return createCell(
              row,
              column,
              tableMinMax[column.key]?.min,
              tableMinMax[column.key]?.max,
              breakdownKey,
            );
          },
        };
      }),
    );
  }

  const outputTable = { title, dimensionColumns, factColumns, rows };
  return [
    outputTable,
    createTableRow(
      createTotalRow(originalRows, dimensionColumns[0].key),
      marginalValueHeaders,
      rollupMode,
      sumMode,
      dimensionHeaders,
      metric,
    ),
  ];
};

const createHeader = (name: string, tooltip: string) => {
  let component: React.ReactNode = name;
  component = (
    <HTMLTooltip html={tooltip} noInfoCircle={true}>
      {component}
    </HTMLTooltip>
  );

  return (
    <div className="flex nb-fact-column-header-parent items-center justify-content-center w-100">
      <div className="text-wrap">{component}</div>
    </div>
  );
};

const getCellIntensityColor = (value: number, min: number, max: number) => {
  const scale = chroma.scale(["#e6eef8", "#4797ff"]);
  const intensityPercent = ((value - min) * 100) / (max - min);
  return scale((intensityPercent || 0) / 100).hex();
};

const createCell = (
  row: AugmentedTableRow,
  column: ColumnShape<AugmentedTableRow>,
  colMin: number | undefined,
  colMax: number | undefined,
  breakdownKey: string,
) => {
  // Calculate if a cell is baked in or not
  let cellIsDisabled = false;
  if (
    breakdownKey === "first_order_month" ||
    breakdownKey === "first_order_week_monday"
  ) {
    const rowStartDate =
      breakdownKey === "first_order_month"
        ? moment(row.id)
        : moment(row.id.slice(0, 10));
    const today = moment();
    const daysUntilToday = today.diff(rowStartDate, "day");
    if (
      +column.key > daysUntilToday &&
      column.key !== "Customers" &&
      column.key !== "Day 1"
    ) {
      cellIsDisabled = true;
    }
  }

  return (
    <div
      className={classNames(
        "flex",
        "w-100",
        "h-100",
        "justify-content-center",
        "items-center",
        cellIsDisabled && "table-cell-disabled",
      )}
      style={{
        backgroundColor:
          row.id !== "Grand Total"
            ? getCellIntensityColor(
                row.export[column.key],
                colMin || 0,
                colMax || 0,
              )
            : "transparent",
      }}
    >
      <div className="d-inline-block float-right">
        {cellIsDisabled ? "" : row.display[column.key]}
      </div>
    </div>
  );
};

const createTableRow = (
  row: CustomerReportRow,
  marginalValueHeaders: string[],
  rollupMode: string,
  sumMode: SumMode,
  dimensionHeaders: string[],
  metric: Metric,
): TableRow => {
  const evs: Record<string, number> = {
    "Day 1": parseFloat(row.firstValue.toString()),
  };
  for (let i = 0; i < marginalValueHeaders.length; ++i) {
    const header = marginalValueHeaders[i];
    evs[header] = parseFloat(row?.marginalValues[header]?.toString() || "0");

    if (
      rollupMode === "Cumulative" ||
      rollupMode === "Cumulative excluding Day 1"
    ) {
      if (i === 0) {
        if (rollupMode === "Cumulative") {
          evs[header] += evs["Day 1"];
        }
      } else {
        evs[header] += evs[marginalValueHeaders[i - 1]];
      }
    }
  }

  if (SumModeEnum[sumMode] === SumModeEnum.TotalOverAllCustomers) {
    for (const header in evs) {
      evs[header] *= row.count;
    }
  }

  const dimensions = chain(dimensionHeaders)
    .map((h) => [h, row.dimensions[h]])
    .fromPairs()
    .value();

  // Hack to deal with averages
  let format: MetricFormat = metric.format;
  if (
    SumModeEnum[sumMode] === SumModeEnum.AveragedPerCustomer &&
    format === "integer"
  ) {
    format = "decimal";
  }

  const display: Record<string, string> = mapValues(evs, (v) =>
    formatNumberExact(v, format),
  );

  evs.Customers = row.count;
  display.Customers = row.count.toString();

  const link: Record<string, string> = {};

  return {
    export: { ...evs, ...dimensions },
    display: { ...display, ...dimensions },
    link,
    changeFractions: {},
    rowMetadata: {},
    cellHoverHTML: {},
  };
};

const createTotalRow = (
  rows: CustomerReportRow[],
  dimensionKey: string,
): CustomerReportRow => {
  let count = 0;
  let firstValue = 0;
  const marginalValues: Record<string, number> = {};

  for (const row of rows) {
    count += row.count;
    firstValue += row.firstValue * row.count;
    for (const key in row.marginalValues) {
      marginalValues[key] =
        (marginalValues[key] ?? 0) + row.marginalValues[key] * row.count;
    }
  }

  firstValue /= count;

  for (const key in marginalValues) {
    marginalValues[key] /= count;
  }

  return {
    count,
    firstValue,
    marginalValues,
    dimensions: { [dimensionKey]: "Grand Total" },
  };
};

const DEFAULT_BOUNDS = {
  startMonth: "2017-01",
  endMonth: moment().format("YYYY-MM"),
};

const GET_CUSTOMER_REPORT = gql`
  query GetCustomerReport(
    $firstAcquiredMonthRange: JSONObject!
    $breakdown: JSONObject!
  ) {
    me {
      id
      customerReport(
        firstAcquiredMonthRange: $firstAcquiredMonthRange
        breakdown: $breakdown
        filters: []
      ) {
        reports {
          metric {
            id
            name
            format
          }
          table
        }
      }
    }
  }
`;
