import { gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Subscription as SubscriptionQuery } from "@nb-api-graphql-generated/Subscription";
import { useNorthbeamQuery } from "@utils/hooks";

export function SubscriptionInformation() {
  const { loading, data } =
    useNorthbeamQuery<SubscriptionQuery>(SUBSCRIPTION_QUERY);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);

  useEffect(() => {
    setSubscriptions(data?.me?.subscriptions || []);
  }, [data]);

  return (
    <>
      {!loading && subscriptions.length > 0 && (
        <>
          <h3 className="mt-4">
            <b>Your Northbeam subscriptions</b>
          </h3>
          <hr />
          {subscriptions.map((subscription) => (
            <div key={subscription}>
              {subscription.productNames.map((productName: string) => (
                <p key={productName}>
                  Your subscription for <b>{productName}</b>{" "}
                  {subscription.discount && (
                    <>
                      has a discount of{" "}
                      <b>{subscription.discount.coupon.name}</b> and is
                      currently active.
                    </>
                  )}
                  {!subscription.discount && <>is currently active.</>}
                </p>
              ))}
            </div>
          ))}
        </>
      )}
    </>
  );
}

const SUBSCRIPTION_QUERY = gql`
  query Subscription {
    me {
      subscriptions
    }
  }
`;
