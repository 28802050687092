import { useEffect, useReducer } from "react";
import { useUser, User } from "@components/user-context";
import { Breakdown, CategoricalBreakdownConfig } from "@north-beam/nb-common";
import { FixedDateRange } from "@north-beam/nb-common";
import {
  DateRangeChoiceEnum,
  DateRangeChoiceV2,
  parseBreakdownList,
  parseDateRange,
  PLATFORM_NORTHBEAM,
} from "@utils/constants";
import { enumType } from "@utils/enum-type";
import { useNavigate, useLocation } from "react-router-dom";
import { mergeLocationSearchParams } from "@utils/index";

enum ReportBody {
  filters = "filters",
  attributionModel = "attributionModel",
  breakdown = "breakdown",
  dateRange = "dateRange",
  windowSizeDays = "windowSizeDays",
}

export type ReportBodyState = {
  readonly filters: Breakdown[];
  readonly attributionModel: string;
  readonly dateRange: DateRangeChoiceV2 | FixedDateRange;
  readonly windowSizeDays: IWindowSizeDays;
  readonly breakdown: string;
};

export const useReportBodyState = (
  breakdownConfigs?: CategoricalBreakdownConfig[] | null,
) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { user } = useUser();
  const [state, dispatch] = useReducer(
    reportBodyStateReducer,
    getReportBodyStateFromUrlParams(user, search, breakdownConfigs),
  );

  const setFilters = (filters: Breakdown[]) =>
    dispatch({ type: ReportBody.filters, value: filters });

  const setAttributionModel = ({ value }: { value: string }) =>
    dispatch({ type: ReportBody.attributionModel, value });

  const setDateRange = (
    dateRange:
      | FixedDateRange
      | (typeof DateRangeChoiceEnum.reactSelectOptions)[0],
  ) =>
    dispatch({
      type: ReportBody.dateRange,
      value: "value" in dateRange ? dateRange.value : dateRange,
    });

  const setWindowSizeDays = ({ value }: { value: IWindowSizeDays }) =>
    dispatch({ type: ReportBody.windowSizeDays, value });

  const setBreakdown = ({ value }: { value: string }) =>
    dispatch({ type: ReportBody.breakdown, value });

  useEffect(() => {
    const newParams = createReportBodyStateUrlParams(state);
    const merged = mergeLocationSearchParams(search, newParams);
    navigate({ search: merged });
  }, [state, navigate, search]);

  return {
    state,
    setFilters,
    setAttributionModel,
    setDateRange,
    setWindowSizeDays,
    setBreakdown,
  };
};

const getReportBodyStateFromUrlParams = (
  user: User,
  search: string,
  breakdownConfigs?: CategoricalBreakdownConfig[] | null,
): ReportBodyState => {
  const params = new URLSearchParams(search);

  const attributionModel = user.getDefaultAttributionModel(
    params.get(ReportBody.attributionModel),
  );
  const filters = parseBreakdownList(params.get(ReportBody.filters)) ?? [];
  const dateRange =
    parseDateRange(params.get(ReportBody.dateRange)) ?? "last7Days";
  const windowSizeDays =
    WindowSizeDays.fromString(params.get(ReportBody.windowSizeDays)) ?? "1";

  let breakdown = params.get(ReportBody.breakdown) ?? "";
  if (!breakdown) {
    if (breakdownConfigs?.find((v) => v.key === PLATFORM_NORTHBEAM)) {
      breakdown = PLATFORM_NORTHBEAM;
    } else {
      breakdown = breakdownConfigs?.[0]?.key ?? "";
    }
  }
  return { attributionModel, breakdown, filters, dateRange, windowSizeDays };
};

interface UpdateFilters {
  type: ReportBody.filters;
  value: Breakdown[];
}

interface UpdateAttributionModel {
  type: ReportBody.attributionModel;
  value: string;
}

interface UpdateBreakdown {
  type: ReportBody.breakdown;
  value: string;
}

interface UpdateDateRange {
  type: ReportBody.dateRange;
  value: DateRangeChoiceV2 | FixedDateRange;
}

interface UpdateWindowSizeDays {
  type: ReportBody.windowSizeDays;
  value: IWindowSizeDays;
}

export type ReportBodyStateAction =
  | UpdateFilters
  | UpdateBreakdown
  | UpdateAttributionModel
  | UpdateDateRange
  | UpdateWindowSizeDays;

const reportBodyStateReducer = (
  state: ReportBodyState,
  action: ReportBodyStateAction,
): ReportBodyState => {
  switch (action.type) {
    case ReportBody.filters: {
      return { ...state, filters: action.value };
    }
    case ReportBody.attributionModel: {
      return { ...state, attributionModel: action.value };
    }
    case ReportBody.breakdown: {
      return { ...state, breakdown: action.value };
    }
    case ReportBody.dateRange: {
      return { ...state, dateRange: action.value };
    }
    case ReportBody.windowSizeDays: {
      return { ...state, windowSizeDays: action.value };
    }
  }
};

const createReportBodyStateUrlParams = (state: ReportBodyState) => {
  const current = new URLSearchParams();
  current.set(ReportBody.attributionModel, state.attributionModel);
  current.set(ReportBody.filters, JSON.stringify(state.filters));
  current.set(ReportBody.breakdown, state.breakdown);
  current.set(ReportBody.dateRange, JSON.stringify(state.dateRange));
  current.set(ReportBody.windowSizeDays, String(state.windowSizeDays));
  return current;
};

export const WindowSizeDays = enumType({
  enumObject: {
    "1": "Day",
    "7": "Week",
    "28": "28 Days",
  },
  keyOrder: ["1", "7", "28"],
});
export type IWindowSizeDays = keyof typeof WindowSizeDays.enum;
