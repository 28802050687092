import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

interface AnalyticsTool {
  name: string;
  supportedPlatforms: Platform[];
}

interface Platform {
  name: string;
  search: string;
}

const analyticsTools: AnalyticsTool[] = [
  {
    name: "WickedReports",
    supportedPlatforms: [
      {
        name: "Facebook",
        search: "WickedSource=Facebook&WickedID=$AD_ID",
      },
    ],
  },
  {
    name: "Hyros",
    supportedPlatforms: [
      {
        name: "Facebook",
        search: "fbc_id=$CAMPAIGN_ID&h_ad_id=$AD_ID",
      },
    ],
  },
  {
    name: "Rockerbox",
    supportedPlatforms: [
      {
        name: "Facebook",
        search: "fb_campaignid=$CAMPAIGN_ID&fb_adsetid=$ADSET_ID&fb_adid$AD_ID",
      },
    ],
  },
  {
    name: "Triple Whale",
    supportedPlatforms: [
      {
        name: "Facebook",
        search: "utm_source=facebook&utm_medium=paid&fbadid=$AD_ID",
      },
      {
        name: "TikTok",
        search: "utm_source=tiktok&utm_medium=paid&ttadid=$AD_ID",
      },
    ],
  },
];

function LegacyUTMs({ analyticsTools }: { analyticsTools: AnalyticsTool[] }) {
  return (
    <>
      <h4>Legacy UTMs</h4>
      <p>
        We ingest some legacy UTMs.&nbsp;
        <strong>
          If you have previously placed the UTMs listed below, we can use them.
        </strong>
        <br />
        You don&apos;t have to update your historic ads if they have these UTMs
        in place.
      </p>
      <Accordion allowZeroExpanded allowMultipleExpanded>
        {analyticsTools.map(({ name, supportedPlatforms }) => (
          <Tool
            key={name}
            name={name}
            supportedPlatforms={supportedPlatforms}
          />
        ))}
      </Accordion>
      <div className="pt-2 mb-[28px]">
        <small>
          For any ads currently running with these UTMs, Northbeam will
          automatically track them.
          <br />
          <strong>Please note</strong>, any new ads launched should use
          Northbeam’s UTMs.
        </small>
      </div>
    </>
  );
}

function Tool({ name, supportedPlatforms }: AnalyticsTool) {
  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton className="accordion__button bg-white">
          {name}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {supportedPlatforms.map(({ name: platformName, search }) => (
          <dl key={platformName}>
            <dt>{platformName}</dt>
            <dd>
              <pre>
                <code>{search}</code>
              </pre>
            </dd>
          </dl>
        ))}
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export default LegacyUTMs;
export { analyticsTools };
