import "@fortawesome/fontawesome-pro";
import Interweave from "interweave";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import App from "./app";
import { Auth0Provider } from "./components/auth0-wrapper";
import ErrorScreen from "./components/error-pages/error-screen";
import { UserProvider } from "./components/user-context";
import config from "./environment";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";
import { ErrorBoundary } from "./utils/analytics";
import { OAuth2Handler } from "./utils/oauth2-handler";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { Provider as JotaiProvider } from "jotai";

import MaintenanceScreen from "@components/error-pages/maintenance-screen";
import { ALLOWED_NON_PREFIXED_PATHS } from "@lib/hooks/use-allowed-nonprefixed-page";
import OAuthFinalizer from "@utils/oauth-finalizer";
import {
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
  TimeSeriesScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-moment";

Chart.register(
  LinearScale,
  CategoryScale,
  TimeSeriesScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
);
Chart.defaults.font.family = "Inter";

// Even in development, this prints many many many messages. If you would like to re-enable, just uncomment.
// if (process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render");
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const HomeHandler = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const allowedNonPrefixedPaths = ALLOWED_NON_PREFIXED_PATHS.map(
    (path) => path.substring(1).split("/")[0],
  );
  const pathParams = pathname.split("/");
  let urlUserId = "";
  if (
    pathParams.length > 1 &&
    !allowedNonPrefixedPaths.includes(pathParams[1])
  ) {
    urlUserId = pathParams[1];
  }
  return (
    <main className="flex h-screen w-screen">
      <JotaiProvider>
        <Auth0Provider
          domain={config.auth0Domain}
          client_id={config.auth0ClientId}
          audience={config.auth0Audience}
          redirect_uri={window.location.origin}
          onRedirectCallback={(appState: any) => {
            const path =
              (appState && appState.targetUrl) || window.location.pathname;
            navigate(path);
          }}
        >
          <UserProvider urlUserId={urlUserId}>
            <Routes>
              <Route
                path="/callbacksV2/:integration"
                element={<OAuthFinalizer />}
              />
              <Route path="/:userId/*" element={<App />} />
            </Routes>
          </UserProvider>
        </Auth0Provider>
      </JotaiProvider>
    </main>
  );
};

const Main = () => (
  <ErrorBoundary FallbackComponent={ErrorScreen}>
    <ToastProvider placement="bottom-center" autoDismiss={true}>
      <MaintenanceBoundary>
        <BrowserRouter>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              enableBatching: true,
              updateType: "replaceIn",
            }}
          >
            <Routes>
              <Route path="/callbacks/*" element={<OAuth2Handler />} />
              <Route
                path="/shopify-login-callback"
                element={<OAuth2Handler />}
              />
              <Route path="/*" element={<HomeHandler />} />
            </Routes>
          </QueryParamProvider>
        </BrowserRouter>
      </MaintenanceBoundary>
    </ToastProvider>
  </ErrorBoundary>
);
interface MaintenanceBoundaryProps {
  children: ReactElement | ReactElement[];
}

function MaintenanceBoundary({ children }: MaintenanceBoundaryProps) {
  const [canLogin, setCanLogin] = useState<boolean>(true);
  const { addToast } = useToasts();
  const [cookies, setCookie] = useCookies(["bannerDismissAt"]);

  const onBannerDismiss = useCallback(() => {
    setCookie("bannerDismissAt", new Date().getTime());
  }, [setCookie]);

  useEffect(() => {
    fetch(
      "https://storage.googleapis.com/storage/v1/b/static.northbeam.io/o/loading_page_app_status_notice.jsonc?alt=media",
    )
      .then((res) => res.json())
      .then((siteStatus) => {
        let displayBanner = false;
        const ONE_HOUR = 60 * 60 * 1000;
        const currentTime = new Date().getTime();
        const difference = currentTime - (cookies.bannerDismissAt || 0);

        if (difference > ONE_HOUR) {
          displayBanner = true;
        }

        if (displayBanner && siteStatus?.errorMessage) {
          addToast(
            <div>
              <Interweave content={siteStatus.errorMessage} />
            </div>,
            {
              appearance: "warning",
              autoDismiss: false,
              onDismiss: onBannerDismiss,
            },
          );
        }
        if (displayBanner && siteStatus?.infoMessage) {
          addToast(
            <div>
              <Interweave content={siteStatus.infoMessage} />
            </div>,
            {
              appearance: "info",
              autoDismiss: false,
              onDismiss: onBannerDismiss,
            },
          );
        }
        if (siteStatus?.blockLogin) {
          setCanLogin(false);
        }
      })
      .catch((e) => {
        // Do nothing
      });
  }, [addToast, cookies.bannerDismissAt, onBannerDismiss]);

  return (
    <>
      {canLogin && children}
      {!canLogin && <MaintenanceScreen />}
    </>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
