import ChangeFractionDisplay from "@components/change-fraction";
import { MetricHeader } from "@components/metric-header";
import Spinner from "@shared/spinner";
import classNames from "classnames";
import React from "react";
import { LegendMetric } from "./chart";
import { useReportBodyState } from "../report-body-control";
import { AnnotationsFlyoutButton } from "@features/custom-annotations";
import { useHasFeatureFlag } from "@hooks/use-feature-flag";

interface ChartLegendProps {
  legendMetrics: LegendMetric[];
  selectMetric: (index: number) => void;
  selectedMetricIndices: number[];
  customizationModal?: React.ReactElement;
}

export const ChartLegend = ({
  legendMetrics,
  selectMetric,
  selectedMetricIndices,
  customizationModal,
}: ChartLegendProps) => {
  const { dateRange } = useReportBodyState();
  const hasFeatureFlag = useHasFeatureFlag();
  const isOnOldSalesPage = hasFeatureFlag("doNotShowNewSalesPage");

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div data-test="sales-chart-legend-v2" className="flex flex-wrap">
          {legendMetrics.map((metric, index) => {
            const active = selectedMetricIndices.includes(index);
            const {
              currentValue,
              compareValue,
              hideComparisonFromTabDisplay,
              changeFractionAreaText,
            } = metric;

            const delta = hideComparisonFromTabDisplay
              ? undefined
              : {
                  changeFraction: (currentValue - compareValue) / compareValue,
                  isPositiveChangeGood: metric.isPositiveChangeGood,
                };

            return (
              <button
                key={metric.name}
                className={classNames(
                  active && "border-2 border-primary",
                  "bg-white rounded-md elevation-2 text-left",
                  "mb-2 mr-2 py-1.5 px-2",
                )}
                onClick={() => selectMetric(index)}
              >
                <MetricHeader
                  breakOnSpace={false}
                  showInfoIcon={false}
                  name={metric?.title ?? metric.name}
                  value=""
                  descriptionHTML={metric.description}
                  titleClassName="font-bold"
                  height={0.75}
                >
                  {metric.loading ? (
                    <div className="flex justify-center	">
                      <Spinner />
                    </div>
                  ) : (
                    <div className="flex items-end">
                      <div className="text-sm font-bold mr-2">
                        {metric.formatApproximate(currentValue)}
                      </div>

                      <div className="text-xs">
                        {changeFractionAreaText ? (
                          <small className="text-muted">
                            {changeFractionAreaText}
                          </small>
                        ) : delta ? (
                          <ChangeFractionDisplay {...delta} />
                        ) : (
                          <small>&nbsp; </small>
                        )}
                      </div>
                    </div>
                  )}
                </MetricHeader>
              </button>
            );
          })}
        </div>
        <div className="flex items-center space-x-2">
          {!isOnOldSalesPage && (
            <>
              {customizationModal}
              <AnnotationsFlyoutButton dateRange={dateRange} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
