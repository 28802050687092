import { GenericExternalLink } from "@components/generic-external-link";
import "animate.css";
import React from "react";

interface StepContainerProps {
  children: JSX.Element | JSX.Element[];
  onMoveForwardButtonClicked?: () => void;
  onMoveBackwardButtonClicked?: () => void;
  onSkipLinkClicked?: () => void;
  skipLinkCopy?: string;
  moveForwardButtonCopy?: string;
  moveBackwardButtonCopy?: string;
  moveForwardButtonDisabled?: boolean;
  videoDemoLink?: string;
  stepByStepLink?: string;
  secondaryControlBar?: React.ReactElement;
}

export const StepContainer = ({
  children,
  onMoveForwardButtonClicked,
  moveForwardButtonCopy,
  onMoveBackwardButtonClicked,
  moveBackwardButtonCopy,
  onSkipLinkClicked,
  skipLinkCopy,
  moveForwardButtonDisabled,
  videoDemoLink,
  stepByStepLink,
  secondaryControlBar,
}: StepContainerProps) => {
  return (
    <>
      <div
        className="flex justify-content-center"
        style={{ marginBottom: 100 }}
      >
        <div className="col-10 onboarding-step animate__animated animate__fadeInRight">
          {children}
        </div>
      </div>

      <div className="onboarding-instruction-links flex justify-content-end">
        {videoDemoLink && (
          <GenericExternalLink
            href={videoDemoLink}
            className="btn btn-outline-info ml-2"
          >
            Video demo
          </GenericExternalLink>
        )}
        {stepByStepLink && (
          <GenericExternalLink
            href={stepByStepLink}
            className="btn btn-outline-info ml-2"
          >
            Step-by-step instructions
          </GenericExternalLink>
        )}
      </div>

      {secondaryControlBar && (
        <div className="onboarding-controls-secondary flex items-center border-bottom border-top">
          {secondaryControlBar}
        </div>
      )}
      <div className="onboarding-controls flex items-center">
        {onMoveBackwardButtonClicked && (
          <button
            tabIndex={70}
            className="btn btn-outline-primary ml-2"
            onClick={onMoveBackwardButtonClicked}
          >
            {moveBackwardButtonCopy || "Back"}
          </button>
        )}
        {onMoveForwardButtonClicked && (
          <button
            tabIndex={70}
            className="btn btn-primary ml-3"
            onClick={onMoveForwardButtonClicked}
            disabled={moveForwardButtonDisabled}
          >
            {moveForwardButtonCopy || "Continue"}
          </button>
        )}
        {onSkipLinkClicked && (
          <button
            tabIndex={70}
            className="btn btn-link ml-2"
            onClick={onSkipLinkClicked}
          >
            {skipLinkCopy || "Skip for now"}
          </button>
        )}
      </div>
    </>
  );
};
