import classNames from "classnames";
import React from "react";

export interface ChangeFractionProps {
  changeFraction: number;
  isPositiveChangeGood: boolean;
  muted?: boolean;
}

export default function ChangeFractionDisplay({
  changeFraction,
  isPositiveChangeGood,
  muted,
}: ChangeFractionProps) {
  let inner: React.ReactNode = "—%";

  const classes: Record<string, boolean> = {
    "opacity-80": !!muted,
  };

  if (changeFraction && !Number.isNaN(changeFraction)) {
    const positiveChange = changeFraction >= 0;
    if (positiveChange) {
      classes["text-green-600"] = isPositiveChangeGood;
      classes["text-red-600"] = !isPositiveChangeGood;
    } else {
      classes["text-red-600"] = isPositiveChangeGood;
      classes["text-green-600"] = !isPositiveChangeGood;
    }

    const absValue =
      Math.abs(changeFraction) >= 1e9 // 1 billion
        ? "∞"
        : (Math.abs(changeFraction) * 100).toFixed(1);
    let value = `${absValue}%`;
    if (!positiveChange) {
      value = `(−${value})`;
    } else {
      value = `+${value}`;
    }
    inner = value;
  }

  return <small className={classNames(classes, "text-xs")}>{inner}</small>;
}
