import React from "react";
import { Route, Routes } from "react-router-dom";
import { Page404 } from "@components/title-slide-view";
import { AdObjectEditor } from "./ad-object-editor";
import { AdObjectList } from "./ad-object-list";

export const ObjectEditor = () => (
  <Routes>
    <Route index element={<AdObjectList />} />
    <Route path="/:id" element={<AdObjectEditor />} />
    <Route path="/*" element={<Page404 />} />
  </Routes>
);
