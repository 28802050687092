import { usePageTitle } from "@/atoms/page-title-atom";
import { gql } from "@apollo/client";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { MetricHeader } from "@components/metric-header";
import {
  PathElementList,
  safeApproximateFormat,
  safeExactFormat,
} from "@components/path-element";
import {
  CompanionWrapper,
  ScreenWithControlCenter,
} from "@components/reports/control-center-utilities";
import { LoadingSlide, NoDataView } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import styled from "@emotion/styled";
import {
  GetCustomerPathReport,
  GetCustomerPathReportVariables,
} from "@nb-api-graphql-generated/GetCustomerPathReport";
import {
  CustomerPathReportSorting,
  SortDirection,
} from "@nb-api-graphql-generated/global-types";
import {
  formatNumberExact,
  jsonHash,
  todayLocalAsISODate,
} from "@north-beam/nb-common";
import { makeReportStateQuery } from "@pages/objects/report-state";
import { logEvent } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import { parseDateRangeArgument } from "@utils/index";
import classNames from "classnames";
import Tooltip from "rc-tooltip";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ControlCenter } from "./control-center";
import {
  makeReportStateParams,
  parseReportState,
  ReportState,
} from "./report-state";

const GET_CONVERSION_PATH_REPORT = gql`
  query GetCustomerPathReport(
    $dateRange: JSONObject!
    $pathDateRangeFilterType: PathDateRangeFilterType!
    $pathLengthMinimum: Int
    $pathLengthMaximum: Int
    $showConvertingPathsOnly: Boolean!
    $sortBy: CustomerPathReportSorting!
    $sortByDirection: SortDirection!
    $pathFilterStages: [CustomerPathFilterStage!]!
    $limit: Int!
  ) {
    me {
      id
      customerPathReport(
        dateRange: $dateRange
        pathDateRangeFilterType: $pathDateRangeFilterType
        pathLengthMinimum: $pathLengthMinimum
        pathLengthMaximum: $pathLengthMaximum
        limit: $limit
        sortBy: $sortBy
        sortByDirection: $sortByDirection
        showConvertingPathsOnly: $showConvertingPathsOnly
        pathFilterStages: $pathFilterStages
      ) {
        totalCount
        totalTransactions
        totalRevenue
        totalShopifyOrdersInTimePeriod
        numberShopifyOrdersCapturedInTimePeriod
        rows {
          count
          transactions
          revenue
          pathElements {
            elementId
            name
            objectId
            nbtPlatformID
            count
          }
        }
      }
    }
  }
`;

export const PathsPage = () => {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Customer Paths");
  }, [setPageTitle]);

  const { value: breakdownConfigs } = useSalesBreakdownConfigs();

  const [isLoading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const state = parseReportState(search);

  const { dateRange: dr, ...rest } = state;

  const anchor = todayLocalAsISODate();
  const dateRange = parseDateRangeArgument(dr, anchor);

  const variables: Omit<
    GetCustomerPathReportVariables,
    "sortBy" | "sortByDirection"
  > = {
    dateRange,
    ...rest,
  };

  // Tricky chain here:
  // 1. URL is updated with new slug
  // 2. URL slug determines report parameters in child components
  const updateReport = React.useCallback(
    (newReportState: ReportState) =>
      navigate({
        search: makeReportStateParams(newReportState).toString(),
      }),
    [navigate],
  );

  return (
    <ScreenWithControlCenter>
      <ControlCenter
        breakdownConfigs={breakdownConfigs}
        initialState={state}
        onReportStateUpdated={updateReport}
        isLoading={isLoading}
      />
      <CompanionWrapper>
        <ReportDynamicArea variables={variables} setLoading={setLoading} />
      </CompanionWrapper>
    </ScreenWithControlCenter>
  );
};

interface FetchReportParams {
  variables: Omit<
    GetCustomerPathReportVariables,
    "sortBy" | "sortByDirection" | "showPaidPathsOnly"
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function ReportDynamicArea({
  variables: baseVariables,
  setLoading,
}: FetchReportParams) {
  const { search } = useLocation();
  const { user } = useUser();
  const state = parseReportState(search);

  const [sortBy, setSortBy] = React.useState<CustomerPathReportSorting>(
    CustomerPathReportSorting.REVENUE,
  );
  const [sortByDirection, setSortByDirection] = React.useState<SortDirection>(
    SortDirection.DESCENDING,
  );

  const variables = {
    ...baseVariables,
    sortBy,
    sortByDirection,
  };

  const { data, loading } = useNorthbeamQuery<
    GetCustomerPathReport,
    GetCustomerPathReportVariables
  >(GET_CONVERSION_PATH_REPORT, { variables });

  const variablesHash = jsonHash(variables);

  React.useEffect(() => setLoading(loading), [loading, setLoading]);
  React.useEffect(() => {
    if (loading) {
      logEvent("Request Customer Path Report", JSON.parse(variablesHash));
    }
  }, [loading, variablesHash]);

  const [response, setResponse] = React.useState(data?.me?.customerPathReport);
  React.useEffect(() => {
    if (data?.me?.customerPathReport) {
      setResponse(data.me.customerPathReport);
    }
  }, [data]);

  if (response) {
    if (response.rows.length === 0) {
      return <NoDataView />;
    }

    const {
      rows,
      totalCount,
      // totalEstimatedCost,
      // totalCostPerAcquisition,
      // totalReturnOnAdSpend,
      totalRevenue,
      totalTransactions,
      totalShopifyOrdersInTimePeriod,
      numberShopifyOrdersCapturedInTimePeriod,
    } = response;

    const adObjectReportParams = makeReportStateQuery({
      attributionModel: user.getDefaultAttributionModel(undefined),
      dateRange: state.dateRange,
      nbAccountingMode: "accrual",
      attributionWindow: "1",
      compareDateRange: "lastPeriod",
      timeGranularity: "daily",
    }).toString();

    const caretDirection =
      sortByDirection === SortDirection.ASCENDING ? "up" : "down";
    const caretSort = <i className={`fas fa-arrow-${caretDirection}`}></i>;

    const columns = [
      {
        name: "Paths",
        totalValue: totalCount,
        format: "integer",
        sortKey: CustomerPathReportSorting.COUNT,
        rowKey: "count",
      },
      // {
      //   name: "Cost (est.)",
      //   totalValue: totalEstimatedCost,
      //   format: "dollars",
      //   sortKey: CustomerPathReportSorting.ESTIMATED_COST,
      //   rowKey: "estimatedCost",
      // },
      // {
      //   name: "ROAS",
      //   totalValue: totalReturnOnAdSpend,
      //   format: "multiplier",
      //   sortKey: CustomerPathReportSorting.RETURN_ON_AD_SPEND,
      //   rowKey: "returnOnAdSpend",
      // },
      // {
      //   name: "CPA",
      //   totalValue: totalCostPerAcquisition,
      //   format: "dollars",
      //   sortKey: CustomerPathReportSorting.COST_PER_ACQUISITION,
      //   rowKey: "costPerAcquisition",
      // },
      {
        name: "Txns",
        totalValue: totalTransactions,
        format: "integer",
        sortKey: CustomerPathReportSorting.TRANSACTIONS,
        rowKey: "transactions",
      },
      {
        name: "Rev",
        totalValue: totalRevenue,
        format: "dollars",
        sortKey: CustomerPathReportSorting.REVENUE,
        rowKey: "revenue",
      },
    ] as const;

    let pctOrdersCaptured: string | null = null;
    if (
      numberShopifyOrdersCapturedInTimePeriod &&
      totalShopifyOrdersInTimePeriod
    ) {
      pctOrdersCaptured = formatNumberExact(
        numberShopifyOrdersCapturedInTimePeriod /
          totalShopifyOrdersInTimePeriod,
        "percentage",
      );
    }

    const headers = columns.map(({ name, totalValue, format, sortKey }) => (
      <div className="td nb-col-metric" key={sortKey}>
        <div
          className="nb-col-metric-header"
          onClick={() => {
            if (sortKey !== sortBy) {
              setSortBy(sortKey);
              setSortByDirection(SortDirection.DESCENDING);
            } else {
              setSortByDirection(
                sortByDirection === SortDirection.DESCENDING
                  ? SortDirection.ASCENDING
                  : SortDirection.DESCENDING,
              );
            }
          }}
        >
          <div className="d-inline-block flex-grow-1"></div>
          <div className="nb-sort">{sortBy === sortKey && caretSort}</div>
          <div className="header">
            <MetricHeader
              name={name}
              value={safeApproximateFormat(totalValue, format)}
              align="right"
              height={1}
            />
            <div
              className="small text-muted"
              style={{
                height: "2em",
                lineHeight: "1.1em",
                marginTop: "0.5em",
              }}
            >
              {name === "Txns" && pctOrdersCaptured !== null ? (
                <Tooltip
                  placement="top"
                  overlay={
                    "Percentage of total orders (reported by Shopify) in the selected time period in this report."
                  }
                  mouseLeaveDelay={0}
                  mouseEnterDelay={0}
                  destroyTooltipOnHide={true}
                >
                  <span>
                    <i className="fas fa-info-circle"></i> {pctOrdersCaptured}{" "}
                    of total
                  </span>
                </Tooltip>
              ) : (
                <React.Fragment>&nbsp;</React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    ));

    return (
      <div className="px-4">
        <div className="row">
          <div className="col">
            <h4 className="my-5">
              <b>Customer Paths</b>
            </h4>
            <Style>
              <div>
                <div className="thead">
                  <div className="tr">
                    <div className="td nb-col-rank"></div>
                    <div className="td nb-col-item">
                      <MetricHeader
                        name={"Path Elements"}
                        value={""}
                        height={1}
                      />
                    </div>
                    {headers}
                  </div>
                </div>
                <div
                  className={classNames(
                    "tbody",
                    (loading && "waiting-interstitial") || "",
                  )}
                >
                  {rows.map((row, index) => {
                    return (
                      <div className="tr" key={jsonHash(row.pathElements)}>
                        <div className="td nb-col-rank">{index + 1}</div>
                        <div className="td nb-col-item">
                          <PathElementList
                            pathElements={row.pathElements}
                            adObjectReportParams={adObjectReportParams}
                          />
                        </div>
                        {columns.map(({ rowKey, format }) => (
                          <div className="td nb-col-metric" key={rowKey}>
                            {safeExactFormat(row[rowKey], format)}
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Style>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <LoadingSlide />;
  }

  return <NoDataView />;
}

const Style = styled.div`
  .thead {
    margin-bottom: 0.5rem;
  }

  .tr {
    display: flex;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .tbody {
    font-size: 12px;
    .tr {
      border-top: 1px solid #aaaaaa;
    }
    .tr:last-child {
      border-bottom: 1px solid #aaaaaa;
    }
  }

  .td {
    padding-left: 0.75rem;
  }

  .nb-col-rank {
    flex: 0.25;
    text-align: right;
  }

  .nb-col-item {
    flex: 5;
  }

  .nb-col-metric {
    flex: 1.5;
    text-align: right;

    .nb-col-metric-header {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      text-align: right;

      .header {
        text-align: right;
      }

      .nb-sort {
        margin-left: 0.5rem;
        margin-right: 0.25rem;
        max-width: 1em;
        width: 1em;
        font-size: 80%;
      }
    }
  }
`;
