import tiktokLogo from "@assets/tiktok-logo.svg";
import { useUser } from "@components/user-context";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { ConnectionCard } from "@pages/settings/connection-card";
import {
  ConnectionStatusType,
  LoginComponentChildrenProps,
  LoginComponentWithFrontendIntegrationProps,
} from "@pages/settings/connection-status-manager";
import { CardProps2 } from "@pages/settings/core";
import { extractError } from "@utils/index";
import { launchOAuthPopup } from "@utils/oauth2-handler";
import React from "react";
import { serializeError } from "serialize-error";

export function TikTokConnectionCard(props: CardProps2) {
  return (
    <ConnectionCard
      type={type}
      connectionTypeName="TikTok Ads"
      connectionCallToAction={`
        Integrate your TikTok Ads data to start receiving insights about
        your TikTok ad spend.
      `}
      logoUrl={tiktokLogo}
      logoAltText="TikTok Ads Logo"
    >
      {({ setStatus, accounts }: LoginComponentChildrenProps) => (
        <TikTokLoginComponent
          setStatus={setStatus}
          accounts={accounts}
          integrationDetails={props.integrationDetails}
        />
      )}
    </ConnectionCard>
  );
}

function TikTokLoginComponent({
  setStatus,
  integrationDetails,
}: LoginComponentWithFrontendIntegrationProps) {
  const { user } = useUser();
  const [isButtonDisabled, setButtonDisabled] = React.useState(false);

  const { tiktokAuthBlob } = integrationDetails;

  const onConnectClicked = React.useCallback(async () => {
    try {
      setButtonDisabled(true);

      const tokenPayload = await openTikTokPopup(tiktokAuthBlob.client_id);

      const result = await user.callApi({
        method: "post",
        url: "/api/callbacks/tiktok",
        data: {
          tokenPayload,
          redirectURI: `${window.location.origin}/callbacks/tiktok`,
        },
      });

      if (result.error) {
        setStatus({
          type: ConnectionStatusType.HAS_ERROR,
          errorMessage: `An error occurred: ${serializeError(
            extractError(result.error),
          )}`,
        });
      } else {
        setStatus({
          type: ConnectionStatusType.SELECTING_ACCOUNT,
          accountList: result.accounts,
        });
      }
    } catch (e) {
      setStatus({
        type: ConnectionStatusType.HAS_ERROR,
        errorMessage: `An error occurred: ${serializeError(extractError(e))}`,
      });
    }

    setButtonDisabled(false);
  }, [setStatus, setButtonDisabled, user, tiktokAuthBlob]);

  return (
    <div className="mb-3">
      <div className="row">
        <div className="col-auto">
          <button
            disabled={isButtonDisabled}
            className="btn btn-primary"
            onClick={onConnectClicked}
          >
            {isButtonDisabled ? "Please Wait..." : "Connect"}
          </button>
        </div>
      </div>
    </div>
  );
}

async function openTikTokPopup(oauthClientId: string) {
  const params = new URLSearchParams();

  params.append("app_id", oauthClientId);
  params.append("redirect_uri", `${window.location.origin}/callbacks/tiktok`);

  return launchOAuthPopup(
    `https://ads.tiktok.com/marketing_api/auth/?${params.toString()}`,
  );
}

const type = ConnectionType.integration_tiktok;
