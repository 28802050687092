"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countryToRegionDict = exports.REGION_ABBREV = exports.REGION = exports.AMAZON_SELLER_NORTH_AMERICA = exports.AMAZON_SELLER_FAR_EAST = exports.AMAZON_SELLER_EUROPE = void 0;
exports.AMAZON_SELLER_EUROPE = "Amazon Seller - Europe";
exports.AMAZON_SELLER_FAR_EAST = "Amazon Seller - Far East";
exports.AMAZON_SELLER_NORTH_AMERICA = "Amazon Seller - North America";
var REGION;
(function (REGION) {
    REGION["EUROPE"] = "EUROPE";
    REGION["FAR_EAST"] = "FAR_EAST";
    REGION["NORTH_AMERICA"] = "NORTH_AMERICA";
})(REGION = exports.REGION || (exports.REGION = {}));
var REGION_ABBREV;
(function (REGION_ABBREV) {
    REGION_ABBREV["EUROPE"] = "eu";
    REGION_ABBREV["FAR_EAST"] = "fe";
    REGION_ABBREV["NORTH_AMERICA"] = "na";
})(REGION_ABBREV = exports.REGION_ABBREV || (exports.REGION_ABBREV = {}));
exports.countryToRegionDict = {
    US: REGION_ABBREV.NORTH_AMERICA,
    CA: REGION_ABBREV.NORTH_AMERICA,
    BR: REGION_ABBREV.NORTH_AMERICA,
    MX: REGION_ABBREV.NORTH_AMERICA,
    JP: REGION_ABBREV.FAR_EAST,
    AU: REGION_ABBREV.FAR_EAST,
    SG: REGION_ABBREV.FAR_EAST,
    GB: REGION_ABBREV.EUROPE,
    ES: REGION_ABBREV.EUROPE,
    FR: REGION_ABBREV.EUROPE,
    NL: REGION_ABBREV.EUROPE,
    DE: REGION_ABBREV.EUROPE,
    IT: REGION_ABBREV.EUROPE,
    SE: REGION_ABBREV.EUROPE,
    PL: REGION_ABBREV.EUROPE,
    BE: REGION_ABBREV.EUROPE,
    EG: REGION_ABBREV.EUROPE,
    TR: REGION_ABBREV.EUROPE,
    SA: REGION_ABBREV.EUROPE,
    AE: REGION_ABBREV.EUROPE,
    IN: REGION_ABBREV.EUROPE, // India
};
