import { useUser } from "@components/user-context";
import React from "react";
import { formatTimeZone } from "./utils";

export function TimezoneDisclaimer() {
  const { user } = useUser();

  return (
    <>
      <p>
        Your exports are set to run on your current (selected) time zone of (
        {formatTimeZone(user.timezone)}).
      </p>
      <p className="mb-10">
        If you would like to update this. Please update it in your Northbeam
        account settings
      </p>
    </>
  );
}
