import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import ReactModal, { Props } from "react-modal";

// This is needed so screen readers don't see main content when modal is opened
ReactModal.setAppElement("#root");

type ModalProps = PropsWithChildren<{
  widthClassname?: string;
  heightClassname?: string;
  withOverflow?: boolean;
  closeModal?: () => void | Promise<void>;
  closeButton?: boolean;
}> &
  Props;

export const Modal = ({
  isOpen,
  widthClassname,
  heightClassname,
  children,
  closeModal,
  closeButton,
  withOverflow = true,
  ...restOfProps
}: ModalProps) => {
  return (
    <ReactModal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",

          ...(!withOverflow && { overflow: "unset" }),

          transform: "translate(-50%, -50%)",
          borderRadius: "25px",
          width: "fit-content",
          height: "fit-content",
          padding: 0,
        },

        overlay: {
          zIndex: 2001,
          backgroundColor: "rgba(30, 30, 30, 0.47)",
        },
      }}
      {...restOfProps}
    >
      {/* Note: Setting height: 100% breaks the modal on safari for some reason */}
      <div className={classNames("relative", widthClassname, heightClassname)}>
        {closeButton && (
          <button
            onClick={closeModal}
            className="absolute top-5 right-6 transition-colors
                       duration-200 hover:text-blue-500 "
          >
            <i className="fa-regular fa-xmark text-[1.4em] cursor-pointer" />
          </button>
        )}
        {children}
      </div>
    </ReactModal>
  );
};
