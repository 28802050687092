import { useUser } from "@components/user-context";
import { CreateAPIKey } from "@nb-api-graphql-generated/CreateAPIKey";
import { FetchApiKeys as FetchApiKeysQuery } from "@nb-api-graphql-generated/FetchApiKeys";
import { CREATE_API_KEY, FETCH_API_KEYS } from "@pages/settings/api-keys";
import Spinner from "@shared/spinner";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import React, { FC, useEffect, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";

export const MagentoConnectionFlow: FC<Partial<StepWizardChildProps>> = () => {
  const { user } = useUser();

  const [apiKeys, setApiKeys] = useState<string[]>([]);

  const { loading: fetchApiKeysLoading, data: fetchApiKeysData } =
    useNorthbeamQuery<FetchApiKeysQuery>(FETCH_API_KEYS);

  const [
    createNewApiKey,
    { loading: createNewApiKeyLoading, error: createApiKeyError },
  ] = useNorthbeamMutation<CreateAPIKey>(CREATE_API_KEY, {
    refetchQueries: [{ query: FETCH_API_KEYS }],
    awaitRefetchQueries: true,
  });

  const loading = fetchApiKeysLoading || createNewApiKeyLoading;

  useEffect(() => {
    if (createApiKeyError) {
      return;
    }

    // Note: If the data IS loaded BUT the result is an empty array
    if (
      fetchApiKeysData?.me?.apiKeys &&
      fetchApiKeysData.me.apiKeys.length === 0
    ) {
      createNewApiKey();
    } else if (fetchApiKeysData?.me.apiKeys.length) {
      setApiKeys(fetchApiKeysData.me.apiKeys);
    }
  }, [fetchApiKeysData, createNewApiKey, createApiKeyError]);

  if (createApiKeyError) {
    return (
      <p className="text-danger">
        We were unable to generate API keys, please contact Northbeam Support
      </p>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <>
      <h3>Let&apos;s get Magento integrated.</h3>
      <p>
        Follow the instructions below to setup on Magento. Click
        &ldquo;Continue&rdquo; once you&apos;ve completed the directions.
      </p>
      <hr />

      <ol className="list-decimal">
        <li className="mb-3">
          <div className="mb-2">
            Follow the instructions below. Use the following Client ID and API
            Key when prompted by the instructions.
          </div>
          <div className="mb-2">
            <b>Client ID:</b> {user.displayUserId}{" "}
          </div>
          <div className="mb-4">
            <b>API Key:</b> {apiKeys[0]}
          </div>
        </li>
      </ol>
      <iframe
        src="https://scribehow.com/page-embed/Magento_Setup__4J7f2lwXStWFI2ReXIm2Ag"
        width="100%"
        height="500"
        frameBorder="0"
        title="Magento Integration Flow"
      />
    </>
  );
};
