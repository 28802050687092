"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdName = exports.isCustomSpend = exports.isAdKeyObjectKey = exports.shouldShowLabels = exports.level = exports.getId = exports.isAdset = exports.isAd = exports.isCampaign = void 0;
var utils_1 = require("./utils");
var isCampaign = function (adKey) {
    return typeof adKey.campaign === "string" && typeof adKey.adset !== "string";
};
exports.isCampaign = isCampaign;
var isAd = function (adKey) { return typeof adKey.ad === "string"; };
exports.isAd = isAd;
var isAdset = function (adKey) {
    return typeof adKey.adset === "string" && typeof adKey.ad !== "string";
};
exports.isAdset = isAdset;
var getId = function (adKey) { var _a, _b; return (_b = (_a = adKey.ad) !== null && _a !== void 0 ? _a : adKey.adset) !== null && _b !== void 0 ? _b : adKey.campaign; };
exports.getId = getId;
var level = function (adKey) {
    if ((0, exports.isCampaign)(adKey)) {
        return "campaign";
    }
    if ((0, exports.isAdset)(adKey)) {
        return "adset";
    }
    // isAd(adKey)
    return "ad";
};
exports.level = level;
var shouldShowLabels = function (adKey) {
    return true;
};
exports.shouldShowLabels = shouldShowLabels;
var isAdKeyObjectKey = function (key) {
    return key === "kind" ||
        key === "platform" ||
        key === "campaign" ||
        key === "adset" ||
        key === "ad";
};
exports.isAdKeyObjectKey = isAdKeyObjectKey;
var isCustomSpend = function (adKey) { return adKey.kind === "custom_spend"; };
exports.isCustomSpend = isCustomSpend;
var getAdNameFromAdKey = function (adKey) {
    var formatIdHelper = function (id) {
        if (id) {
            return id;
        }
        else {
            return "N/A";
        }
    };
    var humanReadableAdName = [];
    if ((0, exports.isAd)(adKey)) {
        humanReadableAdName.push("Ad ID: " + formatIdHelper(adKey.ad));
    }
    if ((0, exports.isAdset)(adKey) || (0, exports.isAd)(adKey)) {
        humanReadableAdName.push("Adset ID: " + formatIdHelper(adKey.adset));
    }
    humanReadableAdName.push("Campaign ID: " + formatIdHelper(adKey.campaign));
    return humanReadableAdName.join(", ");
};
var getAdName = function (isAnon, adName, adKey) {
    if (adName instanceof Error || !adName) {
        var key = getAdNameFromAdKey(adKey);
        return isAnon ? (0, utils_1.b64Encode)(key) : key;
    }
    // Influencers fallback name
    if (adName.includes("partnership-") && adName.split("-").length === 2) {
        var platform = {
            youtube: "YouTube",
            instagram: "Instagram",
            tiktok: "TikTok",
            podcast: "Podcast",
            twitter: "Twitter",
            twitch: "Twitch",
            lp: "Other",
        }[adKey.platform.split("-")[1]] || null;
        if (platform != null) {
            var partner = adKey.campaign.toUpperCase();
            var potentiallyAnonedPartner = isAnon ? (0, utils_1.b64Encode)(partner) : partner;
            return "".concat(potentiallyAnonedPartner, " - ").concat(platform);
        }
    }
    return isAnon ? (0, utils_1.b64Encode)(adName) : adName;
};
exports.getAdName = getAdName;
