import { gql } from "@apollo/client";

const Fragments = {
  metricsAll: gql`
    fragment MetricsAll on Query {
      metrics {
        id
        name
        format
      }
    }
  `,
  subjectItemAll: gql`
    fragment SubjectItemAll on AlertSubjectItem {
      id
      key
      name
    }
  `,
};

export const GET_ALERT_CONFIG = gql`
  query GetAlertConfig($id: ID, $today: String!) {
    me {
      id
      alertConfig(id: $id) {
        id
        name
        createdAt
        updatedAt
        excludedSubjectItems(exclusionEndsAfter: $today) {
          subjectItem {
            id
            key
            name
          }
          excludedUntil
        }
        status
        runFrequency
        triggerConditions
        notificationSettings
        incidentTemplates
      }
    }
    ...MetricsAll
  }
  ${Fragments.metricsAll}
`;

export const UPSERT_CUSTOM_ALERT = gql`
  mutation UpsertCustomAlert($id: ID, $alert: CreateAlertConfig!) {
    upsertCustomAlertConfig(id: $id, alert: $alert) {
      id
      name
      createdAt
      updatedAt
      status
      runFrequency
      triggerConditions
      notificationSettings
      incidentTemplates
    }
  }
`;

export const GET_ALL_ALERT_CONFIGS = gql`
  query GetAllAlertConfigs {
    me {
      id
      alertConfigs {
        id
        alertType
        createdAt
        updatedAt
        runFrequency
        triggerConditions
        notificationSettings
        incidentTemplates
        status
        name
      }
    }
    ...MetricsAll
  }
  ${Fragments.metricsAll}
`;

export const TOGGLE_ALERT_CONFIG = gql`
  mutation ToggleAlertConfig($id: ID!, $status: AlertConfigStatus) {
    toggleAlertConfig(id: $id, alert: { status: $status }) {
      id
      status
      updatedAt
    }
  }
`;

export const UNEXCLUDE_SUBJECT_ITEM_FROM_ALERT = gql`
  mutation UnexcludeSubjectItemFromAlert(
    $configId: ID!
    $subjectItem: JSONObject!
    $today: String!
  ) {
    unexcludeSubjectItemFromAlertConfig(
      configId: $configId
      subjectItem: $subjectItem
    ) {
      id
      status
      updatedAt
      excludedSubjectItems(exclusionEndsAfter: $today) {
        subjectItem {
          id
          key
          name
        }
        excludedUntil
      }
    }
  }
`;

export const ALERT_CONFIG_HISTORY = gql`
  query AlertConfigHistory($id: ID!, $runAt_after: String!) {
    me {
      id
      alertConfig(id: $id) {
        id
        incidents(startingFrom: $runAt_after) {
          id
          subjectItem {
            id
            key
            name
          }
          status
          startAt
          endAt
          ignoredUntil
          description
          actionItemsConfig
          config {
            id
            name
            triggerConditions
          }
          latestPoint {
            id
            runAt
            previewRow
            alertConfigStatus
            alarmCondition
          }
        }
      }
    }
  }
`;

export const PREVIEW = gql`
  query Preview(
    $triggerConditions: JSONObject!
    $date: String!
    $days: Int!
    $configId: ID
  ) {
    me {
      id
      previewCustomAlertConfig(
        triggerConditions: $triggerConditions
        date: $date
        days: $days
        configId: $configId
      ) {
        previews
      }
    }
  }
`;

export const GET_ALERT_RUN_POINTS = gql`
  query GetAlertRunPoints($alertConfigId: ID!, $startingFrom: String!) {
    me {
      id
      alertConfig(id: $alertConfigId) {
        id
        points(startingFrom: $startingFrom) {
          id
          subjectItem {
            id
            key
            name
          }
          previewRow
          alertConfigStatus
          runAt
        }
      }
    }
  }
`;

export const GET_ACTIVE_INCIDENTS = gql`
  query GetActiveIncidents {
    me {
      id
      incidents(activeOnly: true) {
        id
        subjectItem {
          id
          key
          name
        }
        status
        startAt
        endAt
        ignoredUntil
        description
        actionItemsConfig
        config {
          id
          name
          triggerConditions
        }
        latestPoint {
          id
          runAt
          previewRow
          alertConfigStatus
          alarmCondition
        }
      }
    }
  }
`;

export const GET_INCIDENT = gql`
  query GetIncident(
    $alertConfigId: ID!
    $subjectItem: JSONObject!
    $startAt: String!
  ) {
    me {
      id
      alertConfig(id: $alertConfigId) {
        id
        incident(subjectItem: $subjectItem, startAt: $startAt) {
          id
          subjectItem {
            id
            key
            name
          }
          status
          startAt
          endAt
          ignoredUntil
          description
          actionItemsConfig
          config {
            id
            name
            triggerConditions
          }
          latestPoint {
            id
            alarmCondition
            previewRow
          }
          adObject {
            id
            bidSettings
            creativesData
            targetingSettings
            statusLine
            hierarchy
            platform
            metadata
            hasCommonTouchpoints
          }
          chartPoints
        }
      }
    }
  }
`;

export const SNOOZE_INCIDENT = gql`
  mutation SnoozeIncident(
    $alertConfigId: ID!
    $subjectItem: JSONObject!
    $startAt: String!
    $snoozeUntil: String!
  ) {
    snoozeIncident(
      alertConfigId: $alertConfigId
      snoozeUntil: $snoozeUntil
      startAt: $startAt
      subjectItem: $subjectItem
    ) {
      id
      status
      ignoredUntil
    }
  }
`;

export const DISMISS_INCIDENT = gql`
  mutation DismissIncident(
    $alertConfigId: ID!
    $subjectItem: JSONObject!
    $startAt: String!
    $resolutionStatus: String!
  ) {
    dismissIncident(
      alertConfigId: $alertConfigId
      resolutionStatus: $resolutionStatus
      startAt: $startAt
      subjectItem: $subjectItem
    ) {
      id
      status
      ignoredUntil
    }
  }
`;

export const GET_ACCOUNT_MAINTENANCE_ITEMS = gql`
  query GetAccountMaintenanceItems {
    me {
      id
      accountMaintenanceItems(activeOnly: true, maxUrgency: 0) {
        id
        type
        details
        updatedAt
      }
    }
  }
`;

export const DISMISS_ACCOUNT_MAINTENANCE_ITEM = gql`
  mutation DismissAccountMaintenanceItem(
    $type: String!
    $id: String!
    $daysToDismiss: Int!
  ) {
    dismissAccountMaintenanceItem(
      type: $type
      id: $id
      daysToDismiss: $daysToDismiss
    ) {
      id
    }
  }
`;
