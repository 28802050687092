import { gql } from "@apollo/client";
import ErrorScreen from "@components/error-pages/error-screen";
import { GenericExternalLink } from "@components/generic-external-link";
import { LoadingSlide } from "@components/title-slide-view";
import { H1 } from "@components/utilities";
import { FetchSpreadsheets } from "@nb-api-graphql-generated/FetchSpreadsheets";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import { default as React } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const AccordionPanel = ({ spreadSheetLink }: { spreadSheetLink: string }) => {
  return (
    <>
      <div className="row col-8">
        <div>
          View this spreadsheet{" "}
          <GenericExternalLink href={spreadSheetLink}>
            here.
          </GenericExternalLink>
        </div>
      </div>
      <div className="row">
        <div className="py-1 mb-5 col-12">
          <iframe
            src={spreadSheetLink}
            title="Spreadsheet"
            width="100%"
            height="500"
            frameBorder="0"
          />
        </div>
      </div>
    </>
  );
};

interface AccordionWrapperProps {
  title: string;
  spreadsheetLinks: string[];
}

const AccordionWrapper = ({
  title,
  spreadsheetLinks,
}: AccordionWrapperProps) => {
  return (
    <>
      <div className="container pt-4">
        <h3>
          <b>{title}</b>
        </h3>
        <hr />
        {spreadsheetLinks && spreadsheetLinks.length > 0 ? (
          <Accordion allowZeroExpanded>
            {spreadsheetLinks.map((spreadsheetLink, index) => (
              <AccordionItem uuid={index} key={`item-${index}`}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Spreadsheet {index + 1}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <AccordionPanel spreadSheetLink={spreadsheetLink} />
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <div className="col">
            It looks like you don&apos;t have any of these spreadsheets. Reach
            out to{" "}
            <a href="mailto:success@northbeam.io">our customer support team</a>{" "}
            if you would like to set one up.
          </div>
        )}
      </div>
    </>
  );
};

export const DataImportSpreadsheets = () => {
  const { data, loading, error } = useNorthbeamQuery<FetchSpreadsheets>(
    FETCH_SPREADSHEETS,
    {},
  );

  if (loading || !data) {
    return <LoadingSlide />;
  }

  if (error) {
    return <ErrorScreen />;
  }

  return (
    <div className="container pt-4">
      <LogOnMount name="Visit Data Import Spreadsheets Page" />
      <H1>Data Import Spreadsheets</H1>
      <AccordionWrapper
        title="Custom spend spreadsheets"
        spreadsheetLinks={data.me.customSpendSheets}
      />
      <br />
      <AccordionWrapper
        title="Custom influencer spreadsheets"
        spreadsheetLinks={data.me.customInfluencerSheets}
      />
      <br />
    </div>
  );
};

export const FETCH_SPREADSHEETS = gql`
  query FetchSpreadsheets {
    me {
      customSpendSheets
      customInfluencerSheets
    }
  }
`;
