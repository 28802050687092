import ChangeFractionDisplay from "@components/change-fraction";
import {
  AugmentedFactColumn,
  AugmentedTableRow,
  VirtualizedTable,
} from "@components/reports/virtualized-table";
import { formatNumberExact, MetricFormat, Table } from "@north-beam/nb-common";
import { IMCAttributionWindowDays } from "@utils/constants";
import { downloadCsv } from "@utils/csv";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";

export const BreakdownView = ({
  breakdownTitle,
  breakdownSubtitle,
  rows,
  isLoading,
  breakdown,
  attributionModel,
  attributionWindow,
  snapshotSection,
}: {
  breakdownTitle: string;
  breakdownSubtitle?: string;
  rows: any[];
  isLoading: boolean;
  breakdown: string;
  attributionModel: string;
  attributionWindow: IMCAttributionWindowDays;
  snapshotSection?: boolean;
}) => {
  const metricDefs = useMemo(() => {
    const metrics = [
      "spend",
      "revenue",
      snapshotSection ? "mer" : "roas",
      "canc",
      "ecr",
      "ecpnv",
      "newVisits",
      "visits",
      "transactions",
    ] as const;

    return metrics.map((v) =>
      makeMetricDef(v, attributionModel, attributionWindow),
    );
  }, [attributionModel, attributionWindow, snapshotSection]);

  const mapRow = useCallback(
    ({ labelValue, current, compare }) => {
      const link: Record<string, any> = {};
      const export_: Record<string, any> = {};
      const display: Record<string, any> = {};
      const changeFractions: Record<string, number> = {};
      const rowMetadata: Record<string, any> = {};

      display[breakdown] = labelValue || "(not set)";
      export_[breakdown] = labelValue || "(not set)";

      for (const metricDef of metricDefs) {
        const currentValue = metricDef.calculate(current);
        const compareValue = metricDef.calculate(compare);
        const changeFraction = (currentValue - compareValue) / compareValue;

        export_[metricDef.name] = currentValue;
        display[metricDef.name] = formatNumberExact(
          currentValue,
          metricDef.format,
        );
        changeFractions[metricDef.name] = changeFraction;
      }

      return {
        id: labelValue,
        children: [],
        link,
        display,
        changeFractions,
        rowMetadata,
        cellHoverHTML: {},
        export: export_,
      } as AugmentedTableRow;
    },
    [breakdown, metricDefs],
  );

  const totalRow = useMemo(() => {
    const labelValue = "Grand Total";
    const current = {
      spend: 0,
      visits: 0,
      newVisits: 0,
      newCustomers: 0,
      transactions: 0,
      revenue: 0,
    } as any;
    const compare = {
      spend: 0,
      visits: 0,
      newVisits: 0,
      newCustomers: 0,
      transactions: 0,
      revenue: 0,
    } as any;

    for (const { current: aCurrent, compare: aCompare } of rows) {
      for (const key in current) {
        current[key] += aCurrent[key];
        compare[key] += aCompare[key];
      }
    }

    return mapRow({ labelValue, current, compare });
  }, [rows, mapRow]);

  const tableRows: AugmentedTableRow[] = useMemo(() => {
    return rows.map(mapRow);
  }, [rows, mapRow]);

  const dimensionColumn: AugmentedFactColumn = useMemo(
    () => ({
      name: breakdown,
      key: breakdown,
    }),
    [breakdown],
  );

  const factColumns: AugmentedFactColumn[] = useMemo(() => {
    return metricDefs.map(
      (metricDef) =>
        ({
          name: metricDef.name,
          key: metricDef.name,
          headerTooltip: metricDef.descriptionHTML,
          comparison: {
            isPositiveChangeGood: metricDef.isPositiveChangeGood,
          },
          renderCell: (props) => {
            return (
              <div>
                <div>{props.row.display[metricDef.name]}</div>
                <div>
                  <ChangeFractionDisplay
                    changeFraction={props.row.changeFractions[metricDef.name]}
                    isPositiveChangeGood={metricDef.isPositiveChangeGood}
                  />
                </div>
              </div>
            );
          },
        } as AugmentedFactColumn),
    );
  }, [metricDefs]);

  const table: Table = useMemo(() => {
    return {
      title: "",
      dimensionColumns: [dimensionColumn],
      factColumns,
      rows: tableRows,
    };
  }, [dimensionColumn, factColumns, tableRows]);

  const cardClasses = classNames({
    card: true,
    rounded: true,
    "waiting-interstitial": isLoading,
    "elevation-1": true,
  });

  return (
    <div className={cardClasses}>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h4 className="mb-3">
              <strong>{breakdownTitle}</strong>
            </h4>
            <p>{breakdownSubtitle}</p>
          </div>
          <div className="text-right mr-4">
            <button
              className="btn btn-sm mr-auto btn-primary"
              onClick={() =>
                downloadCsv("Sales", "north-beam-sales-report.csv", table)
              }
            >
              Export to CSV
            </button>
          </div>
        </div>
        <div className="row mt-3 nb-overview-page-table">
          <div className="col">
            <VirtualizedTable
              frozenRows={[totalRow]}
              rows={tableRows}
              dimensionColumn={dimensionColumn}
              factColumns={factColumns}
              rowHeight={60}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function makeMetricDef(
  base:
    | "spend"
    | "visits"
    | "newVisits"
    | "newCustomers"
    | "transactions"
    | "revenue"
    | "roas"
    | "ecpnv"
    | "canc"
    | "ecr"
    | "mer",
  am: string,
  aw: IMCAttributionWindowDays,
): MetricDef {
  const isPositiveChangeGood = base !== "canc" && base !== "ecpnv";
  const format: MetricFormat = (
    {
      spend: "dollars",
      visits: "integer",
      newVisits: "integer",
      newCustomers: "decimal",
      transactions: "decimal",
      revenue: "dollars",
      roas: "multiplier",
      mer: "multiplier",
      ecpnv: "dollars",
      canc: "dollars",
      ecr: "percentage",
    } as const
  )[base];

  let calculate: (pt: MetricPoint) => number;
  if (base === "roas" || base === "mer") {
    calculate = (pt) => pt.revenue / pt.spend;
  } else if (base === "ecpnv") {
    calculate = (pt) => pt.spend / pt.newVisits;
  } else if (base === "canc") {
    calculate = (pt) => pt.spend / pt.newCustomers;
  } else if (base === "ecr") {
    calculate = (pt) => pt.transactions / pt.visits;
  } else {
    calculate = (pt) => pt[base];
  }

  const baseName = (
    {
      spend: "Spend",
      visits: "Visits",
      newVisits: "New Visits",
      newCustomers: "New Customers",
      transactions: "Txns",
      revenue: "Rev",
      roas: "ROAS",
      ecpnv: "eCPNV",
      canc: "TrueCAC",
      ecr: "ECR",
      mer: "MER",
    } as const
  )[base];

  const name =
    base === "spend" || base === "visits" || base === "newVisits"
      ? baseName
      : makeMetricName(baseName, am, aw);

  let descriptionHTML = (
    {
      spend:
        "How much money was spent on this set of ads over the given time period.",
      visits: "Visits to your website.",
      newVisits:
        "Visits to your website from new visitors, as determined by the Northbeam Pixel.",
      newCustomers:
        "Attributed transactions from new customers on your online store.",
      transactions: `Attributed transactions from any customer on your online store.`,
      revenue: "Attributed revenue from orders on your online store.",
      roas: `Return on Ad Spend - computed as Rev ÷ Spend.`,
      mer: `Media Efficiency Ratio - computed as Rev ÷ Spend.`,
      ecpnv: "Effective cost per new visit - calculated as Spend ÷ New Visits.",
      canc: "Cost to acquire a new customer - calculated as Spend ÷ New Customers.",
      ecr: "Ecommerce conversion rate - calculated as Txns ÷ Visits.",
    } as const
  )[base];

  descriptionHTML += `<hr /> ${makeAttributionWindowDescription(aw)}`;

  return {
    name,
    descriptionHTML,
    isPositiveChangeGood,
    format,
    calculate,
  };
}

function makeMetricName(
  base: string,
  am: string,
  aw: IMCAttributionWindowDays,
) {
  const symbol = aw === "infinity" ? "∞" : aw;
  return `${base} (${symbol}d)`;
}

function makeAttributionWindowDescription(aw: IMCAttributionWindowDays) {
  if (aw === "infinity") {
    return `This metric is reported with an <b>infinity-day attribution window</b>.
    Touchpoints receive credit for both delayed transactions and repeat
    transactions.`;
  }

  return `This metric is reported with a <b>${aw}-day attribution window</b>.
    Touchpoints only receive credit for transactions that happen within ${aw}
    ${aw === "1" ? "day" : "days"} of the touchpoint.`;
}

// TODO: break out into its own file
interface MetricPoint {
  spend: number;
  visits: number;
  newVisits: number;
  newCustomers: number;
  transactions: number;
  revenue: number;
}

interface MetricDef {
  name: string;
  format: MetricFormat;
  isPositiveChangeGood: boolean;
  calculate: (pt: MetricPoint) => number;
  descriptionHTML: string;
}
