import { useUser } from "@components/user-context";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useMemo } from "react";

const pageTitleAtom = atomWithStorage<string>("v-page-title", "Northbeam");

export const usePageTitle = () => {
  const { user } = useUser();
  const [_pageTitle, setPageTitle] = useAtom(pageTitleAtom);
  const pageTitle = useMemo(() => {
    if (!user.featureFlags.enableDesignRefresh || !_pageTitle) {
      return "Northbeam";
    }
    document.title = `${_pageTitle} | Northbeam`;
    return _pageTitle;
  }, [_pageTitle, user.featureFlags.enableDesignRefresh]);

  return [pageTitle, setPageTitle] as const;
};
