import { gql } from "@apollo/client";

export const FETCH_CURRENT_BENCHMARKS = gql`
  query FetchCurrentBenchmarks(
    $platforms: [BenchmarkPlatformEnum!]!
    $attributionModel: BenchmarkAttributionModelEnum!
    $attributionWindow: BenchmarkAttributionWindowEnum!
    $benchmarkType: BenchmarkTypeEnum!
  ) {
    me {
      benchmarks {
        currentBenchmarks(
          platforms: $platforms
          attributionModel: $attributionModel
          attributionWindow: $attributionWindow
          benchmarkType: $benchmarkType
        ) {
          benchmark
          platform
        }
      }
    }
  }
`;

export const FETCH_PLATFORM_BENCHMARKS = gql`
  query FetchPlatformBenchmarks(
    $benchmarkTargets: [PlatformBenchmarkTargetInput!]!
  ) {
    me {
      benchmarks {
        platformBenchmarks(benchmarkTargets: $benchmarkTargets) {
          attributionModel
          cac {
            blended
            firstTime
            returning
          }
          target
          benchmarkType
          platform
          roas {
            blended
            firstTime
            returning
          }
        }
      }
    }
  }
`;

export const FETCH_BENCHMARK_SETTINGS = gql`
  query FetchBenchmarkSettings(
    $attributionWindow: BenchmarkAttributionWindowEnum!
    $attributionModel: BenchmarkAttributionModelEnum!
  ) {
    me {
      benchmarks {
        benchmarkSettings(
          attributionWindow: $attributionWindow
          attributionModel: $attributionModel
        ) {
          platform
          benchmark
          target
          attributionWindow
          customBenchmarks {
            attributionModel
            cac {
              blended
              firstTime
              returning
            }
            target
            benchmarkType
            platform
            roas {
              blended
              firstTime
              returning
            }
          }
        }
      }
    }
  }
`;

export const SET_BENCHMARK_TARGET = gql`
  mutation UpsertBenchmarkTargets($benchmarkTargets: [BenchmarkTarget!]!) {
    upsertBenchmarkTargets(benchmarkTargets: $benchmarkTargets) {
      successMessage
    }
  }
`;

export const FETCH_USABLE_BENCHMARK_ATTRIBUTION_WINDOWS = gql`
  query FetchUsableBenchmarkAttributionWindows(
    $attributionModel: BenchmarkAttributionModelEnum!
  ) {
    me {
      benchmarks {
        usableAttributionWindows(attributionModel: $attributionModel) {
          attributionWindow
          hasCompleteDataset
        }
      }
    }
  }
`;
