import { useUser } from "@components/user-context";
import {
  formatNumberApproximate,
  formatNumberExact,
  MetricFormat,
  TimeSeries,
  TimeSeriesPoint,
} from "@north-beam/nb-common";
import { colorScheme2, spaceColorScheme } from "@pages/objects/label-colors";
import { ChartData, ChartOptions, Plugin, Scale, TooltipItem } from "chart.js";
import chroma from "chroma-js";
import { sortBy } from "lodash";
import moment from "moment";
import React from "react";
import { customTooltip } from "./helpers";
import { LineWithLine } from "./line-with-line-chart";

const fontFamily = "Inter";

export interface TimeSeriesChartProps {
  format: MetricFormat;
  yLabel: string;
  xAxisFormat: "date" | "days";
  serieses: TimeSeries[];
  compareLabel?: string;
  compareSuffix?: string;
  bandSuffix?: [string, string];
  noLegend?: boolean;
  height?: number;
  plugins?: Plugin[];
  additionalDatasets?: any[];
}

export const TimeSeriesChart = ({
  serieses,
  format,
  compareSuffix,
  bandSuffix,
  compareLabel,
  noLegend,
  xAxisFormat,
  height,
  plugins,
  additionalDatasets = [],
}: TimeSeriesChartProps) => {
  const { user } = useUser();

  if (serieses.length === 0) {
    return <span>No data. :(</span>;
  }

  const colors = user.featureFlags.enableDesignRefresh
    ? spaceColorScheme(serieses.length)
    : colorScheme2(serieses.length);

  const datasets = serieses.flatMap((ts, i) =>
    makeDatasets(ts, colors[i], {
      compareLabel,
      compareSuffix,
      bandSuffix,
    }),
  );

  let data = datasets.filter(
    (dataset) => !dataset.data.every((point: any) => point.y === 0),
  );

  if (data.length === 0) {
    data = datasets;
  }

  const options: ChartOptions<any> = {
    animation: undefined,
    plugins: {
      tooltip: {
        enabled: false,
        mode: "index",
        intersect: false,
        external: customTooltip,
        bodyFamily: {
          family: fontFamily,
        },
        titleFamily: {
          family: fontFamily,
        },
        itemSort: (a: TooltipItem<"line">, b: TooltipItem<"line">) => {
          const aValue: number = (a.raw as any).y;
          const bValue: number = (b.raw as any).y;
          return bValue - aValue;
        },
        callbacks: {
          label: ({ raw }: TooltipItem<"line">) => {
            return formatNumberExact((raw as any).y, format);
          },
          title: (vs: TooltipItem<"line">[]) => {
            return vs.map(({ label }) => {
              const labelMoment = moment(label, "MMM D, YYYY, hh:mm:00 a");
              if (!labelMoment.isValid()) {
                return "N/A";
              }

              let textLabel = labelMoment.format("ddd, MMM D");
              if (label?.includes("T")) {
                textLabel += `, ${labelMoment.format("hA")}-${labelMoment
                  .add(1, "hour")
                  .format("hA")}`;
              }
              return textLabel;
            })[0];
          },
          beforeLabel: (v: TooltipItem<"line">) => data[v.datasetIndex!].label,
        },
      },
      legend: {
        display: !noLegend,
        position: "right",
        align: "start",
      },
    },
    scales: {
      y: {
        position: "right",
        beginAtZero: true,
        grid: {
          tickLength: 5,
        },
        ticks: {
          maxTicksLimit: 5,
          padding: 5,
          font: {
            family: fontFamily,
          },
          callback: (value: string | number) =>
            formatNumberApproximate(Number(value), format),
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          padding: 5,
          maxRotation: 0,
          autoSkip: true,
          autoSkipPadding: 75,
          font: {
            family: fontFamily,
            weight: "bold",
            size: 14,
          },
          major: {
            enabled: true,
          },
        },
        type: "timeseries",
        time: {
          unit: "hour",
        },
      },
    },
    layout: {
      padding: {
        bottom: plugins?.length ? 36 : 10,
      },
    },
  };

  if (xAxisFormat === "days") {
    const ticks = serieses[0].points.map((v) => ({ value: parseInt(v.date) }));
    if (options?.scales?.x) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete options.scales?.x?.time;
      options.scales.x = {
        ...options?.scales?.x,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type: "linear",
        ticks: {
          autoSkip: false,
        },
        min: ticks[0].value,
        max: ticks[ticks.length - 1].value,
        title: {
          display: true,
          text: "Days since first purchase",
        },
        afterBuildTicks: (_chart: any, { scale }: { scale: Scale }) =>
          (scale.ticks = ticks),
      };
    }
  }

  data.push(...additionalDatasets);

  return (
    <LineWithLine
      data={{ datasets: data }}
      options={options}
      height={height ?? 100}
      plugins={plugins}
    />
  );
};

function makePoint(point: TimeSeriesPoint) {
  const { date: x, value: y, ...rest } = point;

  // Sanity check. We should not be showing extra large numbers on the sales page.
  // Treat any extra large number as "Infinity"
  let yValue = y === "Infinity" ? NaN : y ?? 0;
  if (yValue >= 1e10) {
    yValue = Infinity;
  }

  return {
    x,
    y: yValue,
    ...rest,
  };
}

function makeDatasets(
  ts: TimeSeries,
  color: string,
  options: {
    compareLabel?: string;
    compareSuffix?: string;
    bandSuffix?: [string, string];
  },
): ChartData<any>["datasets"][number][] {
  const { name, bandPoints } = ts;
  let { points, comparePoints } = ts;
  if (points) {
    points = sortBy(points, "date");
  }

  if (comparePoints) {
    comparePoints = sortBy(comparePoints, "date");
  }

  if (bandPoints) {
    bandPoints[0] = sortBy(bandPoints[0], "date");
    bandPoints[1] = sortBy(bandPoints[1], "date");
  }

  const data = points.map(makePoint);

  const base = {
    label: name,
    data,
    lineTension: 0,
    fill: false,
    backgroundColor: color,
    borderColor: color,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    borderWidth: 2.5,
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointBorderWidth: 0,
    pointHoverRadius: 3.5,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointHoverBorderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 0,
    spanGaps: false,
  };

  const rv: ChartData<any>["datasets"][number][] = [base];

  if (bandPoints) {
    const [low, high] = bandPoints;

    const compLow = JSON.parse(JSON.stringify(base));
    compLow.label = `${name}${options.bandSuffix?.[0] ?? " (lower bound)"}`;
    compLow.data = low.map(makePoint);
    compLow.borderDash = [5, 5];
    rv.push(compLow);

    const compHigh = JSON.parse(JSON.stringify(base));
    compHigh.label = `${name}${options.bandSuffix?.[1] ?? " (upper bound)"}`;
    compHigh.data = high.map(makePoint);
    compHigh.borderDash = [5, 5];
    compHigh.fill = "-1";
    compHigh.backgroundColor = chroma(compHigh.backgroundColor)
      .alpha(0.3)
      .hex("rgba");
    rv.push(compHigh);
  }

  if (comparePoints) {
    const comp = JSON.parse(JSON.stringify(base));
    const label =
      options.compareLabel ??
      `${name}${options.compareSuffix ?? " (comparison)"}`;
    comp.label = label;
    comp.data = comparePoints.map(makePoint);
    comp.borderDash = [5, 5];
    rv.push(comp);
  }

  return rv;
}
