import { AttributionModel } from "@north-beam/nb-common";
import { flowRight, capitalize } from "lodash";

export { capitalize };

export const capitalizeSnakeCaseString = (string: string) => {
  const list = string
    .split("_")
    .map((text) => capitalize(text.toLowerCase()))
    .join(" ");
  return list;
};

export const capitalizeStringWithSpace = (string: string) => {
  const list = string
    .split(" ")
    .map((text) => capitalize(text.toLowerCase()))
    .join(" ");
  return list;
};

export const formatStringToReadableText = (string: string) => {
  // if url then return as is
  if (string.includes(".com")) {
    return string;
  }

  // if snake case, then use formatSnakeCaseText
  if (string.includes("_")) {
    return capitalizeSnakeCaseString(string);
  }

  // if words with space then break up the space
  if (string.includes(" ")) {
    return capitalizeStringWithSpace(string);
  }

  return capitalize(string.toLocaleLowerCase());
};

/*
  This is ONLY used for the attribution model name because we don't want to show the
  company name in the UI, but the name of the attribution model is stored in the database
  with the company name in it as {{{ companyName }}} in the `custom_attribution_models` table.
*/
export const formatAttributionModel = (name: AttributionModel["name"]) => {
  const companyNameMustacheRegex = /{{{\s?companyName\s?}}}/g;
  if (!companyNameMustacheRegex.test(name)) {
    return name;
  }

  const removeCompanyNameMustaches = (value: AttributionModel["name"]) =>
    value.replace(companyNameMustacheRegex, "").trim();
  const removeParentheses = (value: AttributionModel["name"]) =>
    value.replace(/([()])/g, "").trim();

  const sanitizeAttributionModel = flowRight(
    removeCompanyNameMustaches,
    removeParentheses,
  );

  return sanitizeAttributionModel(name);
};
