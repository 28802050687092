import { GenericExternalLink } from "@components/generic-external-link";
import styled from "@emotion/styled";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

interface HierarchyTree {
  id: string;
  name: string;
  isTagged: boolean;
  fbLandingPageUrl: string;
  status: string;
  adsets?: HierarchyTree[];
  ads?: HierarchyTree[];
}

const Style = styled.div`
  ul.nb-hierarchy {
    list-style-type: "\\21b3\\00a0";
    padding-inline-start: 2rem;
  }
`;

export function Hierarchy(props: { hierarchy: any }) {
  const root = props.hierarchy;

  return (
    <Style>
      <HierarchyItem root={root} />
    </Style>
  );
}

function HierarchyItem(props: { root: HierarchyTree }) {
  const { id: slug } = useParams<{ id: string }>();
  const { search } = useLocation();
  const root = props.root;
  const type = root.adsets ? "Campaign" : root.ads ? "Adset" : "Ad";
  const children = root.adsets || root.ads || [];

  const element =
    root.id === slug ? (
      <span className="text-muted">{root.name}</span>
    ) : (
      <Link to={{ pathname: root.id, search }}>{root.name}</Link>
    );

  const statusBadge =
    type === "Ad" ? (
      <span className="badge badge-pill badge-secondary">{root.status}</span>
    ) : null;

  const fbLandingPageUrl =
    type === "Ad" && root.fbLandingPageUrl ? (
      <ul>
        <li>
          <div className="mb-2 flex">
            <div className="d-inline-block">Landing page:&nbsp;</div>
            <div
              className="d-inline-block text-truncate"
              style={{ width: "30em" }}
            >
              <GenericExternalLink href={root.fbLandingPageUrl}>
                {root.fbLandingPageUrl}
              </GenericExternalLink>
            </div>
          </div>
        </li>
      </ul>
    ) : null;

  return (
    <React.Fragment>
      <p>
        {statusBadge} {type}: {element}{" "}
        {root.isTagged && (
          <span className="badge badge-pill badge-success">Tracked</span>
        )}
      </p>
      {fbLandingPageUrl}
      {children.length > 0 && (
        <ul className="nb-hierarchy">
          {children.map((child) => (
            <li key={child.id}>
              <HierarchyItem root={child} />
            </li>
          ))}
        </ul>
      )}
    </React.Fragment>
  );
}
