import { User } from "@components/user-context";
import {
  DateInterval,
  FixedDateRange,
  parseDateRange as nbCommonParseDateRange,
} from "@north-beam/nb-common";
import {
  dateRangeChoiceToValue,
  DateRangeChoiceV2,
  ICompareDateRangeEnum,
  parseCompareDateRange,
  parseDateRange,
  resolveCompareDateRange,
  IMCAttributionWindowDays,
  INBAccountingMode,
  ITimeGranularity,
  MCAttributionWindowDays,
  NBAccountingMode,
  TimeGranularity,
} from "@utils/constants";

export interface ReportState {
  readonly dateRange: DateRangeChoiceV2 | FixedDateRange;
  readonly compareDateRange: ICompareDateRangeEnum | FixedDateRange;
  readonly attributionModel: string;
  readonly nbAccountingMode: INBAccountingMode;
  readonly attributionWindow: IMCAttributionWindowDays;
  readonly timeGranularity: ITimeGranularity;
}

export function parseState(user: User, query: string): ReportState {
  const params = new URLSearchParams(query);

  const attributionModel = user.getDefaultAttributionModel(
    params.get("attributionModel"),
  );
  const dateRange = parseDateRange(params.get("dateRange")) ?? "last28Days";
  const compareDateRange =
    parseCompareDateRange(params.get("compareDateRange")) ?? "lastPeriod";
  const attributionWindow =
    MCAttributionWindowDays.fromString(params.get("attributionWindow")) ?? "1";
  const nbAccountingMode =
    NBAccountingMode.fromString(params.get("nbAccountingMode")) ?? "accrual";
  const timeGranularity =
    TimeGranularity.fromString(params.get("timeGranularity")) ?? "daily";

  return {
    attributionModel,
    dateRange,
    compareDateRange,
    nbAccountingMode,
    attributionWindow,
    timeGranularity,
  };
}

export function makeReportStateQuery(state: ReportState): URLSearchParams {
  const search = new URLSearchParams();
  search.set("attributionModel", state.attributionModel);
  search.set("dateRange", JSON.stringify(state.dateRange));
  search.set("compareDateRange", JSON.stringify(state.compareDateRange));
  search.set("nbAccountingMode", state.nbAccountingMode);
  search.set("attributionWindow", state.attributionWindow);
  search.set("timeGranularity", state.timeGranularity);
  return search;
}

export interface ReportGQLVariables {
  dateRange: DateInterval;
  attributionMethod: any;
  nbAccountingMode: "accrual" | "cash";
  attributionWindows: string[];
  compareDateRange: DateInterval;
  timeGranularity: string;
}

export function reportStateToGQLVariables(
  state: ReportState,
  anchor: string,
): ReportGQLVariables {
  const asRange =
    typeof state.dateRange === "string"
      ? dateRangeChoiceToValue(state.dateRange)
      : state.dateRange;
  const dateRange = nbCommonParseDateRange(asRange, anchor);
  const compareDateRange = resolveCompareDateRange(
    dateRange,
    state.compareDateRange,
  );
  const attributionMethod = state.attributionModel ?? "northbeam_custom";
  const timeGranularity = state.timeGranularity ?? "daily";

  const attributionWindows = [state.attributionWindow ?? "1"];
  if (timeGranularity === "daily") {
    attributionWindows.push("infinity");
  }

  return {
    dateRange,
    attributionMethod,
    nbAccountingMode: state.nbAccountingMode,
    attributionWindows,
    compareDateRange,
    timeGranularity,
  };
}
