import { ScheduledTimeFrame } from "@features/data-export/types";
import { SecondaryButton } from "@shared/buttons";
import React, { useMemo, useRef } from "react";
import { TimeSelect } from "./time-select";
import { useTimeSelectOptions } from "./hooks";
import { TimezoneDisclaimer } from "./timezone-disclaimer";

interface Props {
  timeFrames: ScheduledTimeFrame[];
  onChange(timeFrames: ScheduledTimeFrame[]): void;
  onAdd(timeFrame: ScheduledTimeFrame): void;
  onRemove(timeFrame: ScheduledTimeFrame): void;
}

export function DailyConfigurator({
  timeFrames,
  onChange,
  onAdd,
  onRemove,
}: Props) {
  const selectedTimesContainer = useRef<HTMLDivElement>(null);
  const selectOptions = useTimeSelectOptions();

  const timeFrameValues = useMemo(
    () => timeFrames.map((e) => e.time),
    [timeFrames],
  );

  return (
    <>
      <div className="flex-initial text-[0.65rem] leading-4 text-zinc-500 font-light px-7 pt-7">
        <TimezoneDisclaimer />

        <h3 className="text-black text-sm font-semibold m-0 mb-1">
          Add daily time frames to export
        </h3>
        <p className="text-[0.65rem] leading-4 text-zinc-500 font-light mb-6">
          Manage your individual daily time frames below:
        </p>
      </div>

      <div
        ref={selectedTimesContainer}
        className="flex-initial px-7 overflow-y-auto"
      >
        {timeFrames.map((timeFrame, idx) => (
          <div key={timeFrame.time} className="mb-6">
            <div className="text-[0.7rem] font-medium mb-1">
              Daily export {idx + 1}
            </div>
            <div className="flex items-center">
              <div className="w-[50%]">
                <TimeSelect
                  options={selectOptions.map((e) => ({
                    ...e,
                    isDisabled:
                      timeFrameValues.includes(e.value) &&
                      e.value !== timeFrame.time,
                  }))}
                  value={selectOptions.find((e) => e.value === timeFrame.time)!}
                  onChange={(val) => {
                    const newTimeFrames = timeFrames.map((e, i) => {
                      if (i === idx) {
                        return { ...e, time: val.value };
                      } else {
                        return e;
                      }
                    });

                    onChange(newTimeFrames);
                  }}
                />
              </div>
              {timeFrames.length > 1 && (
                <button
                  className="ml-3 text-gray-400 hover:text-gray-500"
                  onClick={() => {
                    onRemove(timeFrame);
                  }}
                >
                  <i className="fa-solid fa-trash text-[0.7rem]" />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      {timeFrames.length < 12 && (
        <div className="flex-[1_0_auto] px-7 pt-2 pb-5">
          <SecondaryButton
            className="font-normal text-[0.6rem] pt-1 pb-1 pl-2 pr-2"
            onClick={() => {
              const option = selectOptions.find(
                (e) => !timeFrameValues.includes(e.value),
              );

              if (option) {
                onAdd({ day: "*", time: option.value });

                setTimeout(() => {
                  if (selectedTimesContainer.current) {
                    selectedTimesContainer.current.scroll(
                      0,
                      selectedTimesContainer.current.scrollHeight,
                    );
                  }
                }, 10);
              }
            }}
          >
            <i className="fa-solid fa-plus text-[0.6rem] mr-1.5" />
            Add Time
          </SecondaryButton>
        </div>
      )}
    </>
  );
}
