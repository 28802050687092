import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { LoadingSlide, TitleSlide } from "@components/title-slide-view";
import { H1 } from "@components/utilities";
import {
  GetAlertConfig,
  GetAlertConfigVariables,
} from "@nb-api-graphql-generated/GetAlertConfig";
import { GetAllAlertConfigs } from "@nb-api-graphql-generated/GetAllAlertConfigs";
import {
  AlertType,
  CreateAlertConfig,
} from "@nb-api-graphql-generated/global-types";
import {
  UpsertCustomAlert,
  UpsertCustomAlertVariables,
} from "@nb-api-graphql-generated/UpsertCustomAlert";
import {
  SimpleAlarmConditionV2,
  TriggerConditionsV2,
} from "@north-beam/nb-common";
import { todayLocalAsISODate } from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import { resolvePath } from "@utils/index";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  AlertConfigEditor,
  AlertConfigState,
  makeDefaultState,
} from "./components/alert-config-editor";
import { AlertSimulatePane } from "./components/alert-simulate-pane";
import {
  GET_ALERT_CONFIG,
  GET_ALL_ALERT_CONFIGS,
  UPSERT_CUSTOM_ALERT,
} from "./queries";

export function EditorPage() {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();

  const { addToast } = useToasts();
  const { data } = useNorthbeamQuery<GetAlertConfig, GetAlertConfigVariables>(
    GET_ALERT_CONFIG,
    { variables: { id, today: todayLocalAsISODate() } },
  );

  const alertConfig = data?.me.alertConfig;
  const metrics = data?.metrics;

  const [upsertAlert, { loading: isSaving }] = useNorthbeamMutation<
    UpsertCustomAlert,
    UpsertCustomAlertVariables
  >(UPSERT_CUSTOM_ALERT, {
    update(cache, { data }) {
      const config = data?.upsertCustomAlertConfig;
      if (config) {
        let data = null;
        try {
          data = cache.readQuery({ query: GET_ALL_ALERT_CONFIGS }) as any;
        } catch (e) {
          return;
        }
        if (data) {
          data = { ...data };
          data.me = { ...data.me };
          const cachedConfigs = data.me.alertConfigs;
          data.me.alertConfigs = [];
          let found = false;
          for (const cachedConfig of cachedConfigs) {
            if (cachedConfig.id === config.id) {
              found = true;
              data.me.alertConfigs.push({ ...cachedConfig, ...config });
            } else {
              data.me.alertConfigs.push(cachedConfig);
            }
          }
          if (!found) {
            data.me.alertConfigs.push(config);
          }

          cache.writeQuery<GetAllAlertConfigs>({
            query: GET_ALL_ALERT_CONFIGS,
            data,
          });
        }
      }
    },
  });

  const onSubmit = React.useCallback(
    async (state: AlertConfigState) => {
      const {
        name,
        extraEmailRecipients,
        excludedEmailRecipients,
        subject,
        alarmCondition,
        incidentTemplates,
      } = state;

      const triggerConditions: TriggerConditionsV2 = {
        version: "v2",
        subject,
        alarmCondition,
      };

      const update: CreateAlertConfig = {
        triggerConditions,
        notificationSettings: { extraEmailRecipients, excludedEmailRecipients },
        alertType: AlertType.custom,
        name: name,
        incidentTemplates: incidentTemplates,
      };

      const variables: UpsertCustomAlertVariables = { alert: update };
      if (id) {
        variables.id = id;
      }

      const { data, errors } = await upsertAlert({ variables });
      if (errors) {
        addToast(
          "Errors occurred while updating!\n\n" +
            errors.map((e) => e.message).join("\n"),
          { appearance: "error" },
        );
      } else {
        if (data) {
          const serverResponseAlertId = data.upsertCustomAlertConfig.id;
          const eventData: any = {
            Name: name,
            "Alert Type": update.alertType,
            "Alert ID": serverResponseAlertId,
          };

          logEvent(id ? "Edit Alert" : "Create Alert", eventData);
          const suffix = id
            ? `../../view/${serverResponseAlertId}`
            : `../view/${serverResponseAlertId}`;
          navigate(resolvePath(`${pathname}/${suffix}`).replace(/\/$/, ""));
        } else {
          addToast("An error occurred. Please contact Northbeam support!", {
            appearance: "error",
          });
          navigate(resolvePath(`${pathname}/../..`).replace(/\/$/, ""));
        }
      }
    },
    [upsertAlert, addToast, navigate, id, pathname],
  );

  const initialState: AlertConfigState | undefined = React.useMemo(() => {
    if (alertConfig) {
      const { name, id, notificationSettings, incidentTemplates } = alertConfig;
      const triggerConditions: TriggerConditionsV2 =
        alertConfig.triggerConditions;
      const { subject, alarmCondition } = triggerConditions;

      return {
        id,
        name,
        subject,
        // TODO: build out alerts editor so that it works for multiple alarm conditions
        alarmCondition: alarmCondition as SimpleAlarmConditionV2,
        extraEmailRecipients: notificationSettings?.extraEmailRecipients ?? [],
        excludedEmailRecipients:
          notificationSettings?.excludedEmailRecipients ?? [],
        incidentTemplates,
      };
    }

    if (metrics) {
      return makeDefaultState(metrics);
    }

    return undefined;
  }, [alertConfig, metrics]);

  const [preview, setPreview] = React.useState<AlertConfigState | undefined>(
    initialState,
  );

  const header = React.useMemo(() => {
    if (id) {
      return <H1>Edit Alert</H1>;
    }
    return <H1>Create Alert</H1>;
  }, [id]);

  if (!data) {
    return <LoadingSlide />;
  }

  if (id && !alertConfig) {
    return (
      <TitleSlide>
        <p>Could not find alert to edit. :(</p>
      </TitleSlide>
    );
  }

  return (
    <div className="nb-has-control-center">
      <div
        className="border-right nb-control-center"
        style={{ flex: "0 0 450px" }}
      >
        <div className="container-xl pb-3">
          <div className="row">
            <div className="col">{header}</div>
          </div>
          <div className="row">
            <div className="col">
              <AlertConfigEditor
                isSaving={isSaving}
                initialState={initialState}
                metrics={metrics || []}
                breakdowns={breakdownConfigs}
                onSubmit={onSubmit}
                onPartialUpdate={setPreview}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="nb-control-center-companion-content">
        <AlertSimulatePane preview={preview} id={id} />
      </div>
    </div>
  );
}
