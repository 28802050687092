import React, { useState } from "react";

import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import classNames from "classnames";
import { MetricFormats } from "@utils/metrics";
import { CustomerTypeFriendlyNames } from "@/constants/customer-type-friendly-names";
import { b64Encode, formatDateTime } from "@north-beam/nb-common";
import { Link, useLocation } from "react-router-dom";
import { uniq } from "lodash";
import {
  useAttributedAtom,
  useCustomerTagsAtom,
  useDiscountCodesAtom,
  useOrderTagsAtom,
  useOrderTypesAtom,
  useProductsPurchasedAtom,
  useSubscriptionsAtom,
} from "@/atoms/order-page-atoms";
import {
  ORDER_TOUCHPOINT_LIMIT,
  ORDER_UNKNOWN_TOUCHPOINTS_QUERY_PARAM,
} from "@/constants/order-page";
import { generateAnonBase64Hash } from "@utils/anonymous";
import { prefs } from "@utils/client-side-preferences";
import {
  SubscriptionLabelToValueMapping,
  SubscriptionValueToLabelMapping,
} from "@pages/subscriptions-order-tags/subscription-order-tags";
import { SubscriptionTypeEnum } from "@nb-api-graphql-generated/global-types";

const DateFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) =>
  formatDateTime(value.toISOString());

const DateEditor = () => {
  return null;
};

export const DateTypeProvider = (props: any) => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    editorComponent={DateEditor}
    {...props}
  />
);
export const dateComparator = (a: Date, b: Date) => {
  const aTime = a.getTime();
  const bTime = b.getTime();
  return aTime - bTime;
};

const SingleBadgeDataButton = ({
  className,
  value,
  onClick,
}: {
  value: any;
  className?: string;
  onClick?: (value: any) => void;
}) => (
  <button
    className={classNames(
      "rounded-[3rem] bg-[#F5F8FE] text-primary m-1 py-1 px-2 w-fit hover:bg-[#e7eefd] font-normal",
      className,
    )}
    onClick={onClick}
  >
    {value}
  </button>
);

export const SingleBadgeDataFormatter = ({
  value,
  className,
  onClick,
  shouldAnon,
  type,
}: {
  value: any;
  className?: string;
  onClick?: (value: any) => void;
  shouldAnon: boolean;
  type: string;
}) => {
  if (!value) return null;
  if (!shouldAnon) {
    return (
      <SingleBadgeDataButton
        className={className}
        onClick={() => onClick?.(value)}
        value={value}
      />
    );
  }
  const doAnon = prefs.isDemoMode();

  const potentiallyAnoned = generateAnonBase64Hash({
    value,
    doAnon,
    prefix: type,
  });

  return (
    <SingleBadgeDataButton
      className={className}
      onClick={() => onClick?.(potentiallyAnoned)}
      value={potentiallyAnoned}
    />
  );
};

export const AttributedDataFormatter = ({
  value,
}: {
  value: any;
  className?: string;
}) => {
  const [filter, setFilter] = useAttributedAtom();
  return (
    <SingleBadgeDataFormatter
      value={value}
      onClick={(v) => setFilter(filter?.length ? uniq([...filter, v]) : [v])}
      shouldAnon={false}
      type="attributed"
    />
  );
};

export const CustomerTypeDataFormatter = ({
  value,
  className,
}: {
  value: any;
  className?: string;
}) => {
  const [filter, setFilter] = useOrderTypesAtom();

  console.log("what the ", value);
  const friendlyName = CustomerTypeFriendlyNames[value];
  return (
    <button
      className={classNames(
        "rounded-[3rem] bg-[#F5F8FE] text-primary m-1 py-1 px-2 w-fit hover:bg-[#e7eefd] font-normal",
        className,
      )}
      onClick={() =>
        setFilter(
          filter?.length ? uniq([...filter, friendlyName]) : [friendlyName],
        )
      }
    >
      {friendlyName}
    </button>
  );
};

export const OrderIdDataFormatter = ({
  value,
  row,
}: DataTypeProvider.ValueFormatterProps) => {
  const location = useLocation();

  const unknownTouchpointsParam =
    row.numberOfTouchpoints > ORDER_TOUCHPOINT_LIMIT
      ? `&${ORDER_UNKNOWN_TOUCHPOINTS_QUERY_PARAM}=true`
      : "";

  return (
    <Link
      to={{
        pathname: encodeURIComponent(`${b64Encode(value)}`),
        search: `${location.search}${unknownTouchpointsParam}`,
      }}
      target={"_blank"}
      relative="path"
      className="text-md"
      rel="noreferrer"
    >
      {row.orderNumber}
    </Link>
  );
};

export const ValueDataFormatter = ({
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  return <div>{value}</div>;
};

export const OrderTouchpointsFormatter = ({
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  return <div>{value > ORDER_TOUCHPOINT_LIMIT ? "Not available" : value}</div>;
};

export const MultiBadgeDataFormatter = ({
  value,
  onClick,
  shouldAnon,
  type,
}: {
  type: string;
  shouldAnon: boolean;
  value: any;
  onClick: (value: any) => void;
}) => {
  const [showAll, setShowAll] = useState(false);
  const distinctTags = uniq<string>(value);

  return (
    <div className="flex flex-wrap">
      {distinctTags.slice(0, showAll ? undefined : 2).map((v: string) => (
        <SingleBadgeDataFormatter
          value={v}
          key={v}
          onClick={onClick}
          shouldAnon={shouldAnon}
          type={type}
        />
      ))}
      {!showAll && distinctTags.length > 2 && (
        <SingleBadgeDataFormatter
          shouldAnon={false}
          value={`+${distinctTags.length - 2} more`}
          key={"more-available"}
          className="hover:bg-[#f2f2f2] bg-[#f2f2f2] text-[#2b2b2b]"
          onClick={() => setShowAll(true)}
          type={type}
        />
      )}
    </div>
  );
};

export const TagsDataFormatter = ({
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  const [filter, setFilter] = useOrderTagsAtom();

  return (
    <MultiBadgeDataFormatter
      value={value}
      onClick={(v) => setFilter(filter?.length ? uniq([...filter, v]) : [v])}
      shouldAnon={true}
      type="tag"
    />
  );
};

export const CustomerTagsDataFormatter = ({
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  const [filter, setFilter] = useCustomerTagsAtom();
  console.log("asdasd", value);

  return (
    <MultiBadgeDataFormatter
      value={value}
      onClick={(v) => setFilter(filter?.length ? uniq([...filter, v]) : [v])}
      shouldAnon={true}
      type="customerTag"
    />
  );
};

export const DiscountCodesDataFormatter = ({
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  const [filter, setFilter] = useDiscountCodesAtom();

  return (
    <MultiBadgeDataFormatter
      value={value}
      onClick={(v) => setFilter(filter?.length ? uniq([...filter, v]) : [v])}
      shouldAnon={true}
      type="discount"
    />
  );
};

export const ProductsPurchasedDataFormatter = ({
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  const [filter, setFilter] = useProductsPurchasedAtom();

  return (
    <MultiBadgeDataFormatter
      value={value}
      onClick={(v) => setFilter(filter?.length ? uniq([...filter, v]) : [v])}
      shouldAnon={true}
      type="product"
    />
  );
};

export const SubscriptionsDataFormatter = ({
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  const [filter, setFilter] = useSubscriptionsAtom();

  return (
    <SingleBadgeDataFormatter
      value={SubscriptionValueToLabelMapping[value as SubscriptionTypeEnum]}
      onClick={(v) => {
        const mappedValue = SubscriptionLabelToValueMapping[
          v as keyof typeof SubscriptionLabelToValueMapping
        ] as SubscriptionTypeEnum;

        setFilter(
          filter?.length ? uniq([...filter, mappedValue]) : [mappedValue],
        );
      }}
      shouldAnon={false}
      type="subscription"
    />
  );
};

export const CurrencyDataFormatter = ({
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  return <div>{MetricFormats.dollars.exact(value)}</div>;
};
