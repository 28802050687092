import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import {
  TimeSeriesChart,
  TimeSeriesChartProps,
} from "@components/reports/charts";
import { NoDataView } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import {
  annotationPlugin,
  useBuildAnnotationDataset,
} from "@features/custom-annotations";
import { useHasFeatureFlag } from "@hooks/use-feature-flag";
import { GetSalesReportV2_me_salesReport_rows } from "@nb-api-graphql-generated/GetSalesReportV2";
import {
  ColumnDef,
  isWithinDateInterval,
  iterateHourlyOverInterval,
  iterateHourlyOverIntervalWithAdditionalAccuracy,
  iterateOverInterval,
  jsonHash,
  Table,
  TableRow,
  TimeSeries,
  TimeSeriesPoint,
} from "@north-beam/nb-common";
import { ExportToCsv } from "@shared/buttons";
import { logEvent } from "@utils/analytics";
import {
  addToMetricPoint,
  ChartableResponseRowWithChildren,
  MetricFormats,
  newMetricPoint,
  preprocessForChart,
  SalesPageMetric,
} from "@utils/metrics";
import { leaves } from "@utils/row-with-children";
import { chain } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useReportBodyState } from "../report-body-control";
import { useSalesViews } from "../sales-views-context";
import { normalizeMetricKey } from "../tab-table-section/table/table-utils";
import { ChartLegend } from "./chart-legend";
import { CustomizeChartMetricsModal } from "./customize-chart-metrics-modal";

interface ChartProps {
  chartableData: any;
  metricsArray: SalesPageMetric[];
}

interface ChartSectionProps {
  rows: GetSalesReportV2_me_salesReport_rows[];
  metricsArray: SalesPageMetric[];
}

export interface LegendMetric {
  loading?: boolean;
  currentValue: number;
  compareValue: number;
  name: string;
  title?: string;
  isPositiveChangeGood: boolean;
  formatApproximate: (v: number) => string;
  description?: string;
  hideComparisonFromTabDisplay: boolean;
  changeFractionAreaText?: string;
}

export const ChartSection = ({ rows, metricsArray }: ChartSectionProps) => {
  const { breakdowns } = useReportBodyState();
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const chartableData = useMemo(
    () => preprocessForChart(rows, breakdownConfigs, breakdowns),
    [rows, breakdowns, breakdownConfigs],
  );

  if (!chartableData) {
    return <NoDataView />;
  }

  return <Chart chartableData={chartableData} metricsArray={metricsArray} />;
};

export const Chart = ({ chartableData, metricsArray }: ChartProps) => {
  const { currentSalesViewEdit } = useSalesViews();
  const { user } = useUser();

  const { dateRange, compareDateRange, timeGranularity } = useReportBodyState();
  const [selectedMetricIndices, setSelectedMetricIndices] = useState([0]);
  const [graphMetricBoxes, setGraphMetricBoxes] = useState<string[]>([]);

  const annotationDataset = useBuildAnnotationDataset(
    dateRange.startDate,
    dateRange.endDate,
  );

  const hasFeatureFlag = useHasFeatureFlag();
  const isOnOldSalesPage = hasFeatureFlag("doNotShowNewSalesPage");

  useEffect(() => {
    setGraphMetricBoxes(currentSalesViewEdit?.data.selectedMetricBoxes ?? []);
  }, [currentSalesViewEdit?.data?.selectedMetricBoxes]);

  const baseMetricLegends = useMemo(() => {
    const currentTotal = newMetricPoint();
    const compareTotal = newMetricPoint();
    for (const date in chartableData.series) {
      const point = chartableData.series[date];
      if (isWithinDateInterval(date.substring(0, 10), dateRange)) {
        addToMetricPoint(currentTotal, point);
      }
      if (isWithinDateInterval(date.substring(0, 10), compareDateRange)) {
        addToMetricPoint(compareTotal, point);
      }
    }
    return { currentTotal, compareTotal };
  }, [chartableData, dateRange, compareDateRange]);

  const customizableMetricsArray = useMemo(
    // Remove metrics that are explicitly hidden and forecasted metrics
    () => {
      const filteredMetrics = metricsArray.filter(
        (metric) =>
          !metric.hideFromChartDisplay &&
          !metric.name.toLocaleLowerCase().includes("forecast"),
      );

      return filteredMetrics.map(({ name, ...rest }) => ({
        ...rest,
        title: name,
        name: normalizeMetricKey(name),
      }));
    },
    [metricsArray],
  );

  const filteredMetricsArray = useMemo(
    // Remove metrics that aren't in the customization modal.
    () => {
      let graphMetricsToFilterOn = customizableMetricsArray.map(
        (metric) => metric.name,
      );

      // old sales page flow
      if (isOnOldSalesPage) {
        return customizableMetricsArray;
      }

      if (graphMetricBoxes.length === 0) {
        setGraphMetricBoxes(graphMetricsToFilterOn);
      } else {
        graphMetricsToFilterOn = graphMetricBoxes;
      }

      const metricsSet = new Set(graphMetricsToFilterOn);
      // Filters out metrics that doesn't exist on cash or accrual model

      const filteredMetrics = customizableMetricsArray.filter(({ name }) => {
        return metricsSet.has(name);
      });

      return filteredMetrics.sort(
        (a, b) =>
          graphMetricBoxes.indexOf(a.name) - graphMetricBoxes.indexOf(b.name),
      );
    },
    [
      customizableMetricsArray,
      graphMetricBoxes,
      setGraphMetricBoxes,
      isOnOldSalesPage,
    ],
  );

  const legendMetrics: LegendMetric[] = useMemo(() => {
    const { currentTotal, compareTotal } = baseMetricLegends;
    const rv: LegendMetric[] = [];
    for (const metric of filteredMetricsArray) {
      const currentValue = metric.calculate(currentTotal);
      const compareValue = metric.calculate(compareTotal);
      const {
        name,
        title,
        isPositiveChangeGood,
        formatApproximate,
        hideComparisonFromTabDisplay,
        changeFractionAreaTextForTabDisplay,
      } = metric;
      rv.push({
        currentValue,
        compareValue,
        name,
        title,
        isPositiveChangeGood,
        formatApproximate,
        description: metric.descriptionHTML,
        hideComparisonFromTabDisplay: hideComparisonFromTabDisplay ?? false,
        changeFractionAreaText: changeFractionAreaTextForTabDisplay,
      });
    }
    return rv;
  }, [filteredMetricsArray, baseMetricLegends]);

  const legendMetricsSeries = useMemo(() => {
    const leafRows: ChartableResponseRowWithChildren[] = leaves(chartableData);
    const rv: TimeSeriesChartProps[] = [];

    for (const metric of filteredMetricsArray) {
      const format = metric.format;
      const yLabel = metric.name;
      const xAxisFormat = "date";
      const serieses: TimeSeries[] = [];
      for (const leafRow of leafRows) {
        const overallName =
          selectedMetricIndices.length > 1 ? metric.name : "All";

        const name =
          leafRow.description.length === 0
            ? overallName
            : leafRow.description
                .map((v) => v.value || "(not set)")
                .join(" / ");

        let dates: string[] = [];
        if (timeGranularity === "daily") {
          dates = Array.from(iterateOverInterval(dateRange));
        } else if (timeGranularity === "hourly") {
          // HACK HACK HACK
          // The data for the new tables have a _slightly_ different shape
          // So we need to add an additional suffix to these dates
          if (user.featureFlags.enableFlatTableAlpha) {
            dates = Array.from(
              iterateHourlyOverIntervalWithAdditionalAccuracy(dateRange),
            );
          } else {
            dates = Array.from(iterateHourlyOverInterval(dateRange));
          }
        }

        let value = 0;
        const points: TimeSeriesPoint[] = dates.map((date) => {
          const metricPoint = leafRow.series[date];
          const calc = metricPoint ? metric.calculate(metricPoint) : 0.0;
          if (metric.displayCumulativeForChart) {
            value += calc;
          } else {
            value = calc;
          }
          return { date, value };
        });

        const chartSeries: TimeSeries = { name, points };

        // Compare value
        if (metric.calculateCompare) {
          let compareValue = 0;
          chartSeries.comparePoints = dates.map((date) => {
            const metricPoint = leafRow.series[date];
            const compareValue_ = metricPoint
              ? metric.calculateCompare?.(metricPoint) ?? 0.0
              : 0.0;
            if (metric.displayCumulativeForChart) {
              compareValue += compareValue_;
            } else {
              compareValue = compareValue_;
            }
            return { date, value: compareValue };
          });
        }

        // Band values
        if (metric.calculateBand) {
          chartSeries.bandPoints = [[], []];
          let lo = 0;
          let hi = 0;
          for (const date of dates) {
            if (!isWithinDateInterval(date, dateRange)) {
              continue;
            }
            const metricPoint = leafRow.series[date];
            const [lo_, hi_] = metricPoint
              ? metric.calculateBand(metricPoint)
              : [0, 0];
            if (metric.displayCumulativeForChart) {
              lo += lo_;
              hi += hi_;
            } else {
              lo = lo_;
              hi = hi_;
            }
            chartSeries.bandPoints[0].push({
              date,
              value: isNaN(lo) ? "Infinity" : lo,
            });
            chartSeries.bandPoints[1].push({
              date,
              value: isNaN(hi) ? "Infinity" : hi,
            });
          }
        }

        serieses.push(chartSeries);
      }
      const chartProps: TimeSeriesChartProps = {
        format,
        yLabel,
        xAxisFormat,
        serieses,
        noLegend: true,
      };
      if (metric.chartCompareSuffix) {
        chartProps.compareSuffix = metric.chartCompareSuffix;
      }
      if (metric.chartBandSuffix) {
        chartProps.bandSuffix = metric.chartBandSuffix;
      }
      rv.push(chartProps);
    }

    return rv;
  }, [
    filteredMetricsArray,
    chartableData,
    dateRange,
    timeGranularity,
    selectedMetricIndices.length,
    user.featureFlags.enableFlatTableAlpha,
  ]);

  const selectMetric = (index: number) => {
    const metric = legendMetrics[index];
    logEvent("Selects Top Level Metric", { Metric: metric.name });

    if (selectedMetricIndices.includes(index)) {
      setSelectedMetricIndices(
        selectedMetricIndices.filter((i) => i !== index),
      );
    } else {
      if (selectedMetricIndices.length <= 1) {
        setSelectedMetricIndices([...selectedMetricIndices, index]);
      } else {
        setSelectedMetricIndices([selectedMetricIndices[1], index]);
      }
    }
  };

  const selectedChartPropsArray = useMemo(() => {
    if (
      selectedMetricIndices.every((index) => index < legendMetricsSeries.length)
    ) {
      return selectedMetricIndices.map(
        (selectedIndex) => legendMetricsSeries[selectedIndex],
      );
    } else {
      setSelectedMetricIndices([0]);
      return [legendMetricsSeries[0]];
    }
  }, [selectedMetricIndices, legendMetricsSeries]);

  const selectedTableForExport: Table = useMemo(() => {
    const dimensionColumns: ColumnDef[] = [
      {
        key: "category",
        name: "Category",
      },
      {
        key: "date",
        name: "Date",
      },
    ];

    const factColumns: ColumnDef[] = legendMetricsSeries.map(({ yLabel }) => ({
      key: yLabel,
      name: yLabel,
    }));

    const keyToMetrics: Record<string, Record<string, any>> = {};
    for (const { serieses, yLabel: metric } of legendMetricsSeries) {
      for (const series of serieses) {
        const category = series.name;
        for (const { date, value } of series.points) {
          const key = jsonHash({ category, date });
          if (!keyToMetrics[key]) {
            keyToMetrics[key] = {};
          }
          keyToMetrics[key][metric] = value;
        }
      }
    }

    const values = chain(keyToMetrics)
      .toPairs()
      .map(([key, metrics]) => ({ ...metrics, ...JSON.parse(key) }))
      .sortBy(["date", "category"])
      .value();
    const rows: TableRow[] = values.map((values) => ({
      link: {},
      rowMetadata: {},
      export: values,
      display: values,
      changeFractions: {},
      cellHoverHTML: {},
    }));

    return {
      dimensionColumns,
      factColumns,
      rows,
      title: "",
    };
  }, [legendMetricsSeries]);

  return (
    <div className="bg-white rounded-md elevation-1 mb-4">
      <ChartLegend
        legendMetrics={
          currentSalesViewEdit || isOnOldSalesPage
            ? legendMetrics
            : // default loading metrics view
              [
                {
                  loading: true,
                  currentValue: 0,
                  compareValue: 0,
                  name: "Loading Metrics",
                  isPositiveChangeGood: true,
                  formatApproximate: MetricFormats.dollars.approximate,
                  hideComparisonFromTabDisplay: false,
                },
              ]
        }
        selectMetric={selectMetric}
        selectedMetricIndices={selectedMetricIndices}
        customizationModal={
          <CustomizeChartMetricsModal
            graphMetricBoxes={graphMetricBoxes}
            setGraphMetricBoxes={setGraphMetricBoxes}
            customizableMetricsArray={customizableMetricsArray}
            setSelectedMetricIndices={setSelectedMetricIndices}
          />
        }
      />

      <div data-test="sales-charts">
        {selectedChartPropsArray.map((selectedChartProps) => (
          <div className="px-8 pb-8" key={selectedChartProps.yLabel}>
            <div className="flex items-center justify-content-between mb-4">
              <h4>{selectedChartProps.yLabel}</h4>
              <ExportToCsv
                reportType="Sales Timeseries"
                fileName="north-beam-sales-report-ts.csv"
                data={selectedTableForExport}
              />
            </div>
            <TimeSeriesChart
              {...selectedChartProps}
              plugins={!isOnOldSalesPage ? [annotationPlugin] : undefined}
              additionalDatasets={
                !isOnOldSalesPage ? [annotationDataset] : undefined
              }
              height={50}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
