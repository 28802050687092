"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.METRIC_FORMATS = void 0;
exports.METRIC_FORMATS = [
    "decimal",
    "integer",
    "percentage",
    "multiplier",
    "dollars",
];
