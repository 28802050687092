import { gql } from "@apollo/client";
import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { SendProspectingEmail } from "@nb-api-graphql-generated/SendProspectingEmail";
import { useNorthbeamMutation } from "@utils/hooks";
import { Profile } from "@pages/settings/profile";
import { StepContainer } from "./step-container";

export const UserProfileCollectionStep: FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
}) => {
  const [sendProspectingEmail] = useNorthbeamMutation<SendProspectingEmail>(
    SEND_PROSPECTING_EMAIL,
  );

  return (
    <StepContainer
      onMoveBackwardButtonClicked={() => {
        previousStep && previousStep();
      }}
      onSkipLinkClicked={() => {
        nextStep && nextStep();
      }}
    >
      <Profile
        isOnboardingFlow
        afterSuccessCallback={() => {
          nextStep && nextStep();
          sendProspectingEmail();
        }}
      />
    </StepContainer>
  );
};

const SEND_PROSPECTING_EMAIL = gql`
  mutation SendProspectingEmail {
    sendProspectingEmail
  }
`;
