import React from "react";

export default ({ type, label, onClick, config }) => {
  const typeToLabel = {
    addRuleGroup: "+",
    addRuleGroupExt: "+",
    delGroup: "×",
    delRuleGroup: "×",
    delRule: "×",
  };
  const btnLabel = label || typeToLabel[type];
  return (
    <button onClick={onClick} type="button" className="btn btn-secondary">
      {btnLabel}
    </button>
  );
};
