/* eslint-disable react-hooks/exhaustive-deps */
import { useUser } from "@components/user-context";
import { Breakdown } from "@north-beam/nb-common";
import { FixedDateRange, todayLocalAsISODate } from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import {
  ColumnSet,
  CompareDateRangeEnum,
  DateRangeChoiceEnum,
  DateRangeChoiceV2,
  Granularity,
  IColumnSet,
  ICompareDateRangeEnum,
  IGranularity,
  IMCAttributionWindowDays,
  INBAccountingMode,
  ITimeGranularity,
  MCAttributionWindowDays,
  NBAccountingMode,
  resolveCompareDateRange,
  TimeGranularity,
} from "@utils/constants";
import { parseDateRangeArgument } from "@utils/index";
import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import {
  createEnumParam,
  decodeJson,
  encodeJson,
  QueryParamConfig,
  withDefault,
} from "serialize-query-params";
import { StringParam, useQueryParams } from "use-query-params";

const AttributionWindowParam = createEnumParam<IMCAttributionWindowDays>(
  MCAttributionWindowDays.keyOrder,
);
const AccountingModeParam = createEnumParam<INBAccountingMode>(
  NBAccountingMode.keyOrder,
);
const ColumnSetParam = createEnumParam<IColumnSet>(ColumnSet.keyOrder);
const GranularityParam = createEnumParam<IGranularity>(Granularity.keyOrder);
const TimeGranularityParam = createEnumParam<ITimeGranularity>(
  TimeGranularity.keyOrder,
);

const CustomJsonParam = <T>(): QueryParamConfig<T> => ({
  encode: (value) => encodeJson(value),
  decode: (value) => decodeJson(value),
  equals: (a, b) => isEqual(a, b),
});
type DateRangeType<T> = T | FixedDateRange;
const DateRangeParam = CustomJsonParam<DateRangeType<DateRangeChoiceV2>>();
const CompareDateRangeParam =
  CustomJsonParam<DateRangeType<ICompareDateRangeEnum>>();
const BreakdownParam = CustomJsonParam<Breakdown[]>();

enum ReportBody {
  ATTRIBUTION_MODEL = "attributionModel",
  ATTRIBUTION_WINDOW = "attributionWindow",
  NB_ACCOUNTING_MODE = "nbAccountingMode",
  COLUMN_SET = "columnSet",
  GRANULARITY = "granularity",
  BREAKDOWNS = "breakdowns",
  TIME_GRANULARITY = "timeGranularity",
  DATE_RANGE = "dateRange",
  COMPARE_DATE_RANGE = "compareDateRange",
}

export interface ReportBodyState {
  readonly attributionModel: string;
  readonly attributionWindow: IMCAttributionWindowDays;
  readonly nbAccountingMode: INBAccountingMode;
  readonly columnSet: IColumnSet;
  readonly granularity: IGranularity;
  readonly timeGranularity: ITimeGranularity;
  readonly dateRange: DateRangeType<DateRangeChoiceV2>;
  readonly compareDateRange: DateRangeType<ICompareDateRangeEnum>;
  readonly breakdowns: Breakdown[];
}

export type NewReportBodyState = Omit<ReportBodyState, "columnSet">;

export const useReportBodyState = () => {
  const { user } = useUser();
  const [state, setState] = useQueryParams({
    [ReportBody.ATTRIBUTION_MODEL]: useMemo(
      () => withDefault(StringParam, user.getDefaultAttributionModel("")),
      [user],
    ),
    [ReportBody.ATTRIBUTION_WINDOW]: useMemo(
      () => withDefault(AttributionWindowParam, "1" as const),
      [],
    ),
    [ReportBody.NB_ACCOUNTING_MODE]: useMemo(
      () => withDefault(AccountingModeParam, "accrual" as const),
      [],
    ),
    [ReportBody.COLUMN_SET]: useMemo(
      () => withDefault(ColumnSetParam, "overview" as const),
      [],
    ),
    [ReportBody.GRANULARITY]: useMemo(
      () => withDefault(GranularityParam, "platform" as const),
      [],
    ),
    [ReportBody.TIME_GRANULARITY]: useMemo(
      () => withDefault(TimeGranularityParam, "daily" as const),
      [],
    ),
    [ReportBody.DATE_RANGE]: useMemo(
      () => withDefault(DateRangeParam, "last7Days" as const),
      [],
    ),
    [ReportBody.COMPARE_DATE_RANGE]: useMemo(
      () => withDefault(CompareDateRangeParam, "lastPeriod" as const),
      [],
    ),
    [ReportBody.BREAKDOWNS]: useMemo(() => withDefault(BreakdownParam, []), []),
  });

  const dateRange = useMemo(
    () =>
      parseDateRangeArgument(
        state[ReportBody.DATE_RANGE],
        todayLocalAsISODate(),
      ),
    [state[ReportBody.DATE_RANGE]],
  );

  return {
    state,
    setState,

    attributionModel: state[ReportBody.ATTRIBUTION_MODEL],
    setAttributionModel: useCallback(
      ({ value }: { value: string }) => {
        logEvent(
          `Saved Views: User changed Attribution Model from ${
            state[ReportBody.ATTRIBUTION_MODEL]
          } to ${value}`,
          state,
        );
        setState({ [ReportBody.ATTRIBUTION_MODEL]: value });
      },
      [setState],
    ),

    attributionWindow: state[ReportBody.ATTRIBUTION_WINDOW],
    setAttributionWindow: useCallback(
      ({ value }: { value: IMCAttributionWindowDays }) => {
        logEvent(
          `Saved Views: User changed Attribution Window from ${
            state[ReportBody.ATTRIBUTION_WINDOW]
          } to ${value}`,
          state,
        );
        setState({ [ReportBody.ATTRIBUTION_WINDOW]: value });
      },
      [setState],
    ),

    nbAccountingMode: state[ReportBody.NB_ACCOUNTING_MODE],
    setNbAccountingMode: useCallback(
      ({ value }: { value: INBAccountingMode }) => {
        logEvent(
          `Saved Views: User changed Accounting Mode from ${
            state[ReportBody.NB_ACCOUNTING_MODE]
          } to ${value}`,
          state,
        );
        setState({ [ReportBody.NB_ACCOUNTING_MODE]: value });
      },
      [setState],
    ),

    columnSet: state[ReportBody.COLUMN_SET],
    setColumnSet: useCallback(
      ({ value }: { value: IColumnSet }) => {
        logEvent(
          `Saved Views: User changed Column Set from ${
            state[ReportBody.COLUMN_SET]
          } to ${value}`,
          state,
        );
        setState({ [ReportBody.COLUMN_SET]: value });
      },
      [setState],
    ),

    granularity: state[ReportBody.GRANULARITY],
    setGranularity: useCallback(
      ({ value }: { value: IGranularity }) =>
        setState({ [ReportBody.GRANULARITY]: value }),
      [setState],
    ),

    breakdowns: state[ReportBody.BREAKDOWNS],
    setBreakdowns: useCallback(
      (value: Breakdown[]) => {
        logEvent("Saved Views: User changed Breakdowns", state);
        setState({ [ReportBody.BREAKDOWNS]: value });
      },
      [setState],
    ),

    timeGranularity: state[ReportBody.TIME_GRANULARITY],
    setTimeGranularity: useCallback(
      ({ value }: { value: ITimeGranularity }) => {
        logEvent(
          `Saved Views: User changed Time Granularity from ${
            state[ReportBody.TIME_GRANULARITY]
          } to ${value}`,
          state,
        );
        setState({ [ReportBody.TIME_GRANULARITY]: value });
      },
      [setState],
    ),

    dateRange,
    unparsedDateRange: state[ReportBody.DATE_RANGE],
    setDateRange: useCallback(
      (
        dateRange:
          | FixedDateRange
          | (typeof DateRangeChoiceEnum.reactSelectOptions)[0],
      ) => {
        logEvent(
          `Saved Views: User changed Date Range from ${
            state[ReportBody.DATE_RANGE]
          } to ${dateRange}`,
          state,
        );
        setState({
          [ReportBody.DATE_RANGE]:
            "value" in dateRange ? dateRange.value : dateRange,
        });
      },
      [setState],
    ),

    compareDateRange: useMemo(
      () => resolveCompareDateRange(dateRange, state.compareDateRange),
      [dateRange, state.compareDateRange],
    ),
    setCompareDateRange: useCallback(
      (
        dateRange:
          | FixedDateRange
          | (typeof CompareDateRangeEnum.reactSelectOptions)[0],
      ) =>
        setState({
          [ReportBody.COMPARE_DATE_RANGE]:
            "value" in dateRange ? dateRange.value : dateRange,
        }),
      [setState],
    ),
  };
};
