import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { ApiKeys } from "@pages/settings/api-keys";

export const CustomEcommerceIntegration: FC<
  Partial<StepWizardChildProps>
> = () => {
  return (
    <>
      <h3>Northbeam Server Orders Sync</h3>
      <hr />
      <ApiKeys />
    </>
  );
};
