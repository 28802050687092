import { useUser } from "@components/user-context";
import { CopyButton } from "@lib/buttons";
import { FormControl, FormGroup, InputGroup } from "@lib/forms";
import { useObjectState } from "@lib/hooks/";
import { mergeParams } from "@utils/url-search-params";
import classNames from "classnames";
import React, { useContext, useMemo } from "react";
import { Context } from "../context";
import { useRedirectURL } from "../hooks";

export function Klaviyo() {
  const { domainName, collectorSubdomain } = useContext(Context);
  const { user } = useUser();
  const { url, urlParts, setURLParts, error } = Klaviyo.useKlaviyoURL();

  const websiteURL = useRedirectURL({
    collectorSubdomain,
    domainName,
    displayURL: url,
    displayId: user.displayUserId,
    platform: "klaviyo",
    property: "__utm_only__",
  });

  return (
    <>
      <div>
        <p>
          Fill out the form below and copy the resulting Final Destination URL
          into Klaviyo:
        </p>
      </div>
      <FormGroup label="Destination URL" className="font-weight-bold">
        <FormControl
          name="klaviyo-final-url"
          value={urlParts.base}
          onChange={(base) => setURLParts({ base })}
          helpText={
            <>
              <strong>*Required</strong>, please ensure that this URL starts
              with <code>https://</code>
              <br />
              Paste the URL you want customers to eventually land on here.
            </>
          }
        />
      </FormGroup>

      <FormGroup label="UTM Source (utm_source)" className="font-weight-bold">
        <FormControl
          name="utm-source"
          value={urlParts.utmSource}
          onChange={(utmSource) => setURLParts({ utmSource })}
          helpText="* Required"
        />
      </FormGroup>

      <div className="row">
        <div className="col">
          <FormGroup
            label="UTM Medium (utm_medium)"
            className="font-weight-bold"
          >
            <FormControl
              name="utm-medium"
              value={urlParts.utmMedium}
              onChange={(utmMedium) => setURLParts({ utmMedium })}
              helpText="* Required"
            />
          </FormGroup>
        </div>
        <div className="col">
          <FormGroup
            label="UTM Campaign (utm_campaign)"
            className="font-weight-bold"
          >
            <FormControl
              name="utm-campaign"
              value={urlParts.utmCampaign}
              onChange={(utmCampaign) => setURLParts({ utmCampaign })}
            />
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <FormGroup label="UTM Term (utm_term)" className="font-weight-bold">
            <FormControl
              name="utm-term"
              value={urlParts.utmTerm}
              onChange={(utmTerm) => setURLParts({ utmTerm })}
            />
          </FormGroup>
        </div>
        <div className="col">
          <FormGroup
            label="UTM Content (utm_content)"
            className="font-weight-bold"
          >
            <FormControl
              name="utm-content"
              value={urlParts.utmContent}
              onChange={(utmContent) => setURLParts({ utmContent })}
            />
          </FormGroup>
        </div>
      </div>

      <FormGroup label="Final Destination URL" className="font-weight-bold">
        <InputGroup
          prepend={
            <CopyButton disabled={!websiteURL} valueToCopy={websiteURL || ""} />
          }
        >
          <FormControl
            name="klaviyo-website-url"
            value={websiteURL || ""}
            style={{ wordBreak: "break-all" }}
            className={classNames(error && "is-invalid")}
            readOnly
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </InputGroup>
      </FormGroup>
    </>
  );
}

const useKlaviyoURL = () => {
  const [urlParts, setURLParts] = useObjectState<{
    base: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmTerm: string;
    utmContent: string;
  }>({
    base: "",
    utmSource: "klaviyo",
    utmMedium: "email",
    utmCampaign: "",
    utmTerm: "",
    utmContent: "",
  });

  const error = useMemo(() => {
    if (!urlParts.base) return;

    try {
      new URL(urlParts.base);
    } catch (error) {
      return "Invalid Destination URL.";
    }

    if (!urlParts.utmSource) {
      return "UTM Source is required.";
    }

    if (!urlParts.utmMedium) {
      return "UTM Medium is required.";
    }

    return null;
  }, [urlParts]);

  const url = useMemo(() => {
    if (error) return "";

    try {
      const builtURL = new URL(urlParts.base);
      const search = mergeParams(builtURL.search, {
        utm_source: urlParts.utmSource,
        utm_medium: urlParts.utmMedium,
        ...(urlParts.utmCampaign && { utm_campaign: urlParts.utmCampaign }),
        ...(urlParts.utmTerm && { utm_term: urlParts.utmTerm }),
        ...(urlParts.utmContent && { utm_content: urlParts.utmContent }),
      });
      builtURL.search = search.toString();
      return builtURL.toString();
    } catch (error) {
      return "";
    }
  }, [error, urlParts]);

  return { url, urlParts, setURLParts, error };
};

Klaviyo.useKlaviyoURL = useKlaviyoURL;
