import React from "react";

interface LoadingButtonProps {
  className?: string;
  text: string;
  onClick(): void;
  isLoading: boolean;
}

export default function LoadingButton({
  className,
  text,
  onClick,
  isLoading,
}: LoadingButtonProps) {
  const buttonClassName = className || "btn btn-sm button-primary";

  const renderButtonContent = () => {
    return isLoading ? (
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    ) : (
      text
    );
  };

  return (
    <button className={buttonClassName} onClick={onClick} disabled={isLoading}>
      {renderButtonContent()}
    </button>
  );
}
