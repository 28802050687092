import { LoadingSlide, TitleSlide } from "@components/title-slide-view";
import { FetchSettingsDetails } from "@nb-api-graphql-generated/FetchSettingsDetails";
import { useNorthbeamQuery } from "@utils/hooks";
import React from "react";
import { FETCH_SETTINGS_DETAILS } from "./queries";

interface DataWrapperProps {
  children: (data: FetchSettingsDetails) => JSX.Element | JSX.Element[];
}

export function DataWrapper({ children }: DataWrapperProps) {
  const { data, loading } = useNorthbeamQuery<FetchSettingsDetails>(
    FETCH_SETTINGS_DETAILS,
    {},
  );

  if (loading) {
    return <LoadingSlide />;
  }

  if (!data) {
    return (
      <TitleSlide>
        <p className="text-center">No data :(</p>
      </TitleSlide>
    );
  }
  return <>{children(data)}</>;
}
