import { useUser } from "@components/user-context";
import React, { ComponentType } from "react";

/*
  Example usage:

  import { PrimaryButton as OldPrimaryButton } from "./primary-button";
  import { PrimaryButton as NewPrimaryButton } from "./_primary-button";
  const PrimaryButton = withFeatureFlagComponentSwitch(
    "enableDesignRefresh",
    NewPrimaryButton,
    OldPrimaryButton,
  );
  export { PrimaryButton };
*/

export const withFeatureFlagComponentSwitch = <TProps extends object>(
  featureFlag: string,
  NewComponent: ComponentType<TProps>,
  DefaultComponent: ComponentType<TProps>,
) => {
  return (props: TProps) => {
    const { user } = useUser();
    if (user.featureFlags[featureFlag]) {
      return <NewComponent {...props} />;
    }

    return <DefaultComponent {...props} />;
  };
};
