import { PointElement } from "chart.js";
import { getInterNumberSvg } from "./annotation-count-svgs";

export const buildMessageSvg = (
  point: PointElement,
  bottom: number,
  count: number,
  isHover = false,
) => {
  const svg = `data:image/svg+xml;base64,${btoa(
    `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="6" fill="${
        isHover ? "#F5F8FE" : "#FFFFFF"
      }"/>
      <path d="M11.5 10.5C10.6562 10.5 10 11.1797 10 12V18.75C10 19.5938 10.6562 20.25 11.5 20.25H13.75V22.125C13.75 22.2891 13.8203 22.4062 13.9375 22.4766C14.0781 22.5234 14.2188 22.5234 14.3359 22.4297L17.2422 20.25H20.5C21.3203 20.25 22 19.5938 22 18.75V12C22 11.1797 21.3203 10.5 20.5 10.5H11.5Z" fill="${
        isHover ? "#285CB6" : "#367BF3"
      }"/>
      <circle cx="22.333" cy="9" r="7" fill="#F3BB1C"/>
      <circle cx="22.333" cy="9" r="7" fill="white" fill-opacity="0.5"/>
      ${getInterNumberSvg(count)}
    </svg>`,
  )}`;

  return {
    x: point.x - 16,
    y: bottom + 36,
    width: 32,
    height: 32,
    svg,
  };
};

export const buildAddSvg = (point: PointElement, bottom: number) => {
  const svg = `data:image/svg+xml;base64,${btoa(
    `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="6" fill="#F5F8FE"/>
      <g x="10" y="10" transform="scale(1.3)">
        <path d="M12.5 8.375C12.5 7.97656 12.1484 7.625 11.75 7.625C11.3281 7.625 11 7.97656 11 8.375V11.75H7.625C7.20312 11.75 6.875 12.1016 6.875 12.5C6.875 12.9219 7.20312 13.25 7.625 13.25H11V16.625C11 17.0469 11.3281 17.375 11.75 17.375C12.1484 17.375 12.5 17.0469 12.5 16.625V13.25H15.875C16.2734 13.25 16.625 12.9219 16.625 12.5C16.625 12.1016 16.2734 11.75 15.875 11.75H12.5V8.375Z" fill="#367BF3"/>
      </g>
    </svg>`,
  )}`;

  return {
    x: point.x - 16,
    y: bottom + 36,
    width: 32,
    height: 32,
    svg,
  };
};

export const buildClearAddSvg = (point: PointElement, bottom: number) => {
  const svg = `data:image/svg+xml;base64,${btoa(
    `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="32" height="32" style="fill-opacity:1;stroke:none;" fill="#FFFFFF" />
    </svg>`,
  )}`;

  return {
    x: point.x - 16,
    y: bottom + 36,
    width: 32,
    height: 32,
    svg,
  };
};
