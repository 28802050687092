import GoogleAd from "@components/creatives/GoogleAd";
import MicrosoftAd from "@components/creatives/MicrosoftAd";
import DebugCodeBlock from "@components/DebugCodeBlock";
import { GenericExternalLink } from "@components/generic-external-link";
import React, { useEffect, useRef } from "react";
import "./index.css";

// HACK this type is copied at modules/nb-api/src/endpoints/graphql/schema/AdObject.ts ~line 347
export type CreativeAdType = {
  googleAd?: {
    googleMetadata: Record<string, any>;
    nbMetadata: Record<string, any>;
  };
  htmls?: any[];
  tiktokAd?: { html: string };
  microsoftAd?: any;
  snapchatAd?: string;
  previewUrl?: string;
  raw?: any;
};

export const Creatives = (props: {
  creatives: CreativeAdType[];
  numCreatives: number;
  height?: number;
}) => {
  const { creatives, numCreatives } = props;

  const plural = creatives.length === 1 ? "creative" : "creatives";
  let text = `Showing ${creatives.length} ${plural}`;
  if (numCreatives !== creatives.length) {
    text += ` (of ${numCreatives})`;
  }

  if ((creatives || []).length === 0 && numCreatives > 0) {
    return (
      <div className="h-[670px] text-black text-center flex-1">
        (no preview available)
      </div>
    );
  }

  return (
    <>
      <p>{text}</p>
      <div
        style={{
          height: "670px",
          position: "relative",
          display: "flex",
          gap: "10px",
          overflow: "auto",
          flexWrap: "wrap",
        }}
      >
        {(creatives || []).map((creative: any, index: number) => (
          <Creative ad={creative} key={index} />
        ))}
      </div>
    </>
  );
};

export const Creative = (props: { ad: CreativeAdType }) => {
  const { ad } = props;

  const itemsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    itemsRef?.current?.forEach((domEl: any) => {
      const iframe = domEl?.querySelector("iframe");
      if (iframe) {
        iframe.style.width = "100%";
        iframe.style.height = "100%";
        iframe.setAttribute("scrolling", "no");
      }
    });
  }, [ad.htmls]);

  // If Facebook / Instagram
  if (ad.htmls) {
    const htmls: string[] = Array.from(new Set(ad.htmls));

    return (
      <>
        {htmls.map((html: string, i: number) => {
          if (itemsRef && itemsRef.current) {
            return (
              <div
                key={i}
                ref={(el) => {
                  if (el) itemsRef.current[i] = el;
                }}
                dangerouslySetInnerHTML={{ __html: html }}
                className={`nb-fb-ad-preview h-[680px] w-[350px] mt-5`}
              />
            );
          }
          return null;
        })}
      </>
    );
  }

  if (ad.tiktokAd) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: ad.tiktokAd.html }}
        className={"nb-tiktok-ad-preview h-[480px] w-[320px]"}
      />
    );
  }

  if (ad.googleAd) {
    return <GoogleAd googleAd={ad.googleAd} />;
  }

  if (ad.microsoftAd) {
    return <MicrosoftAd microsoftAd={ad.microsoftAd} />;
  }

  if (ad.snapchatAd) {
    if (ad.snapchatAd.includes("image_preview")) {
      return (
        <img
          src={ad.snapchatAd}
          style={{
            width: "250px",
            height: "450px",
          }}
          alt="snapchat-ad"
          className={"object-contain"}
        />
      );
    }

    return (
      <iframe
        title={`Snapchat video`}
        width="250"
        height="445"
        src={ad.snapchatAd}
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }

  if (ad.previewUrl) {
    return (
      <p className="text-white">
        Click{" "}
        <GenericExternalLink href={ad.previewUrl}>here</GenericExternalLink> to
        open the preview in a new window.
      </p>
    );
  }

  return <DebugCodeBlock data={ad} style={{ minWidth: "480px" }} />;
};
