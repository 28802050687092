import React, { useEffect, useRef, useState } from "react";

import { motion, PanInfo } from "framer-motion";
import clsx from "clsx";

function Border() {
  return (
    <div className={clsx("flex-1 pointer-events-none transition-colors")} />
  );
}

function graphSizeConstaint(innerHeight: number) {
  if (innerHeight > 900) return 800;
  if (innerHeight > 850) return 740;
  if (innerHeight > 800) return 690;
  if (innerHeight > 700) return 500;
  if (innerHeight > 600) return 500;
  if (innerHeight > 500) return 500;
  if (innerHeight > 400) return 400;
  return 300;
}

export function ResizableComponent({
  height,
  children,
}: {
  height: number;
  children: ({ currentHeight }: { currentHeight: number }) => JSX.Element;
}) {
  const [resizeY, setResizeY] = useState(0);
  const constraintsRef = useRef(null);

  const handleDrag = (
    e: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) => {
    const newResizeY = resizeY - info.delta.y;

    const heightConstraint = graphSizeConstaint(window.innerHeight);
    if (newResizeY >= 0) {
      setResizeY(newResizeY > heightConstraint ? heightConstraint : newResizeY);
    }
  };

  const currentHeight = height + resizeY;

  useEffect(() => {
    const maxHeight = graphSizeConstaint(window.innerHeight);
    if (currentHeight > maxHeight) {
      setResizeY(maxHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerHeight]);

  return (
    <motion.div
      className="flex flex-col absolute bottom-0 w-full z-10"
      ref={constraintsRef}
      style={{ height: currentHeight }}
    >
      <div className="relative w-full flex">
        <motion.div
          className={clsx(
            "rounded-t-[20px] text-center w-[12rem] h-[1.8rem] flex cursor-pointer",
            "absolute -top-[1.52rem] right-1/2 translate-x-1/2 transform ",
            "transition-colors items-center justify-center",
            "bg-[#7081FF]",
          )}
          style={{
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          }}
          dragMomentum={false}
          drag="y"
          dragElastic={false}
          dragConstraints={{ top: 0, bottom: 0 }}
          onDrag={handleDrag}
        >
          <div className="border-t-4 rounded-md border-[#ECEAEA] w-[3rem]" />
        </motion.div>
        <Border />
        <div className="w-[14rem]" />
        <Border />
      </div>
      <div className={clsx("grow w-full h-full -mb-[0.575rem]")}>
        {children({ currentHeight })}
      </div>
    </motion.div>
  );
}
