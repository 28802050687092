import React, { CSSProperties } from "react";
import { useInView } from "react-intersection-observer";

export interface StuckToTopProps {
  children: (v: { isStuck: boolean }) => JSX.Element;
  className?: string;
}

export function StuckToTop({ children: render, className }: StuckToTopProps) {
  const { ref, entry } = useInView({
    rootMargin: "-1px 0px 0px 0px",
    threshold: [1],
  });

  const isStuck = entry ? entry.intersectionRatio < 1 : false;

  const divStyle: CSSProperties = React.useMemo(
    () => ({
      top: -1,
      zIndex: 4,
      position: "sticky",
      transition: "0.1s",
      // fontSize: isStuck ? "10px" : undefined,
      overflowAnchor: "auto",
    }),
    [],
  );

  return (
    <div ref={ref} style={divStyle} className={className}>
      {render({ isStuck })}
    </div>
  );
}
