import { gql } from "@apollo/client";
import { ImpersonateDropdown, UserItem } from "@components/Navbar";
import { useUser } from "@components/user-context";
import { OnboardingUserQuery } from "@nb-api-graphql-generated/OnboardingUserQuery";
import {
  UpdateCurrentStep,
  UpdateCurrentStepVariables,
} from "@nb-api-graphql-generated/UpdateCurrentStep";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import { relativeToRoot } from "@utils/index";
import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { StepWizardChildProps } from "react-step-wizard";
import accountManagersActive from "./assets/account-managers-active.svg";
import accountManagersInactive from "./assets/account-managers-inactive.svg";
import basicDetailsActive from "./assets/basic-details-active.svg";
import basicDetailsInactive from "./assets/basic-details-inactive.svg";
import billingActive from "./assets/contract-and-billing-active.svg";
import billingInactive from "./assets/contract-and-billing-inactive.svg";
import dnsSetupActive from "./assets/dns-setup-active.svg";
import dnsSetupInactive from "./assets/dns-setup-inactive.svg";
import googleAnalyticsActive from "./assets/google-analytics-active.svg";
import googleAnalyticsInactive from "./assets/google-analytics-inactive.svg";
import integrationsActive from "./assets/integrations-active.svg";
import integrationsInactive from "./assets/integrations-inactive.svg";
import introductionActive from "./assets/introduction-active.svg";
import introductionInactive from "./assets/introduction-inactive.svg";
import pixelPlacementActive from "./assets/pixel-placement-active.svg";
import pixelPlacementInactive from "./assets/pixel-placement-inactive.svg";
import setupLogo from "./assets/setup-logo.svg";
import utmsActive from "./assets/utms-active.svg";
import utmsInactive from "./assets/utms-inactive.svg";
import { StepName } from "./definitions";

const steps = [
  {
    activeImage: introductionActive,
    inactiveImage: introductionInactive,
    stepName: StepName.INTRODUCTION,
  },
  {
    activeImage: basicDetailsActive,
    inactiveImage: basicDetailsInactive,
    stepName: StepName.BASIC_DETAILS,
  },
  {
    activeImage: pixelPlacementActive,
    inactiveImage: pixelPlacementInactive,
    stepName: StepName.PIXEL_PLACEMENT,
  },
  {
    activeImage: dnsSetupActive,
    inactiveImage: dnsSetupInactive,
    stepName: StepName.DNS_SETUP,
  },
  {
    activeImage: googleAnalyticsActive,
    inactiveImage: googleAnalyticsInactive,
    stepName: StepName.GOOGLE_ANALYTICS,
  },
  {
    activeImage: integrationsActive,
    inactiveImage: integrationsInactive,
    stepName: StepName.INTEGRATIONS,
  },
  {
    activeImage: utmsActive,
    inactiveImage: utmsInactive,
    stepName: StepName.UTMS,
  },
  {
    activeImage: billingActive,
    inactiveImage: billingInactive,
    stepName: StepName.BILLING,
  },
  {
    activeImage: accountManagersActive,
    inactiveImage: accountManagersInactive,
    stepName: StepName.ACCOUNT_MANAGERS,
  },
];

const getStepNameFromHash = (hash: string): StepName | null => {
  if (hash === StepName.COMPLETE) {
    return StepName.COMPLETE;
  }

  if (hash === "") {
    return StepName.INTRO_VIDEO;
  }

  const step = steps.find((step) => step.stepName === hash);
  if (step) {
    return step.stepName;
  } else {
    return null;
  }
};

const getLatestStep = (stepA: StepName | null, stepB: StepName): StepName => {
  if ([stepA, stepB].includes(StepName.COMPLETE)) {
    return StepName.COMPLETE;
  }

  const stepAIndex = steps.findIndex((step) => step.stepName === stepA);
  const stepBIndex = steps.findIndex((step) => step.stepName === stepB);

  if (!stepA || stepAIndex === -1 || stepAIndex < stepBIndex) {
    return stepB;
  } else {
    return stepA;
  }
};
interface OnboardingNavProps {
  onLogout: () => void;
}

export const OnboardingNav: FC<
  Partial<StepWizardChildProps> & OnboardingNavProps
> = ({ currentStep, nextStep, previousStep, onLogout, goToNamedStep }) => {
  const { user } = useUser();

  const [updateCurrentStep] = useNorthbeamMutation<
    UpdateCurrentStep,
    UpdateCurrentStepVariables
  >(UPDATE_CURRENT_STEP);

  const { data } = useNorthbeamQuery<OnboardingUserQuery>(
    ONBOARDING_USER_QUERY,
  );
  const [usersList, setUsersList] = React.useState<UserItem[] | null>(
    data?.users || null,
  );

  React.useEffect(() => {
    const users = data?.users || null;
    if (users && users.length > 1) {
      setUsersList(users);
    }
  }, [data]);

  const activeStep = getStepNameFromHash(window.location.hash.replace("#", ""));

  useEffect(() => {
    const currentLatestStep: StepName = data?.me?.onboardingDetails
      ?.latestStep as StepName;
    if (activeStep) {
      const newLatestMinorStep = getLatestStep(
        activeStep,
        currentLatestStep || StepName.INTRODUCTION,
      );

      updateCurrentStep({
        variables: {
          currentStep: activeStep,
          latestStep: newLatestMinorStep,
        },
      });
    }
  }, [activeStep, updateCurrentStep, data]);

  if (!currentStep || !goToNamedStep || !nextStep || !previousStep) {
    return <>System error.</>;
  }

  return (
    <div className="p-0 h-100 onboarding-nav">
      <div className="flex h-100">
        {/* Header and footer */}
        <Link className="ml-4 mt-4 fixed-top col-2" to={relativeToRoot("")}>
          <img src={setupLogo} alt="Setup Northbeam" />
        </Link>
        <div className="fixed-top onboarding-impersonate-dropdown">
          {usersList && usersList.length > 0 && (
            <ImpersonateDropdown user={user} usersList={usersList!} />
          )}
        </div>
        <button
          className="mt-4 mr-4 fixed-top btn right-aligned"
          onClick={onLogout}
        >
          Logout
        </button>
        {/* End header and footer */}

        <div className="flex flex-column v-nav-bar 100-vh">
          {steps.map((step) => (
            <div
              key={step.stepName}
              onClick={() => goToNamedStep(step.stepName)}
              className="mb-1"
            >
              {activeStep === step.stepName ? (
                <img
                  src={step.activeImage}
                  className="nav-image-element"
                  alt={step.stepName}
                />
              ) : (
                <img
                  src={step.inactiveImage}
                  className="nav-image-element"
                  alt={step.stepName}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const UPDATE_CURRENT_STEP = gql`
  mutation UpdateCurrentStep($currentStep: String!, $latestStep: String!) {
    updateCurrentStep(currentStep: $currentStep, latestStep: $latestStep) {
      currentStep
    }
  }
`;

const ONBOARDING_USER_QUERY = gql`
  query OnboardingUserQuery {
    me {
      onboardingDetails {
        latestStep
      }
    }
    users {
      id
      isAdmin
      email
      companyName
    }
  }
`;
