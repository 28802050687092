import { Breakdown, CategoricalBreakdownConfig } from "@north-beam/nb-common";
import {
  DbForecastV1DateRow,
  SalesReportRow,
  SalesReportRowExtraDetail,
} from "@north-beam/nb-common";
import { DateInterval, isWithinDateInterval } from "@north-beam/nb-common";
import {
  addToMetricPoint,
  descriptorToPath,
  makeMetricPoint,
  MetricPoint,
  newMetricPoint,
  pathToDescriptor,
  SalesPageMetric,
} from "@utils/metrics";
import { map, RowWithChildren } from "@utils/row-with-children";
import { chain } from "lodash";

export const generateCellHoverHTML = (
  node: RowWithChildren<TableableResponseRow>,
  metricsArray: SalesPageMetric[],
): Record<string, string> => {
  const columnIdToCellHoverHTML: Record<string, string> = {};
  for (const metric of metricsArray) {
    const metadata = metric.metadata ?? {};
    const isMetricDerivedFromConversionAttribution =
      metadata.metricId &&
      ["rev", "txns", "roas", "cac", "ecr"].includes(metadata.metricId);

    if (isMetricDerivedFromConversionAttribution || metadata.isGoalRelated) {
      if (node.cellAnnotations.visitAttributionInTraining) {
        columnIdToCellHoverHTML[metric.id] = `
          <p><b>In Training</b></p>
          <p class="mb-0">
            We are still collecting First-Party Data and developing this model.
            Values are subject to fluctuation and should be discounted.
          </p>`;
      } else if (node.cellAnnotations.awarenessCampaignAttributionTurnedOff) {
        columnIdToCellHoverHTML[metric.id] = `
          <p><b>Update</b></p>
          <p>
            We are making a temporary update to our view attribution model that
            will disable view-through credit to Awareness campaigns.
          </p>
          <p class="mb-0">
            Please reach out to your customer education team member if you have
            any questions.
          </p>`;
      } else if (node.platformId === "amazon_marketplace_aggregated") {
        columnIdToCellHoverHTML[metric.id] = `
            <p><b>Disclaimer</b></p>
            <p>
              Data is derived from Amazon Seller Central APIs and Reports.
              Please note that reports in the Seller Central website may show different figures.
            </p>
            <p class="mb-0">
              If you have any questions, please check out our <a href="https://docs.google.com/document/d/1GhdURLIzlbi6skw9EDh88T-CyP-FbnoBbauDgDJwOFY" target="_blank">Amazon Integration FAQ</a> document for more information.
            </p>
            `;
      }
    }
  }
  return columnIdToCellHoverHTML;
};

interface DailyData {
  current: MetricPoint;
  name: string;
  date: string | null;
  extraDetails: SalesReportRowExtraDetail[];
  description: { key: string; value: string }[];
}

export interface TableableResponseRow {
  id: string;
  platformId: string | null;
  parentId: string | null;
  description: { key: string; value: string }[];
  link?: string;
  name: string;
  extraDetails: SalesReportRowExtraDetail[];
  cellAnnotations: Record<string, boolean | Record<string, never>>;
  current: MetricPoint;
  compare: MetricPoint | null;
}

export type TableableResponseRowWithChildren =
  RowWithChildren<TableableResponseRow>;

export const preprocessForTable = (
  rows: SalesReportRow[],
  breakdownConfigs: CategoricalBreakdownConfig[],
  breakdowns: Breakdown[],
  dateRange: DateInterval,
  compareDateRange: DateInterval,
  textFilter: string,
): [TableableResponseRowWithChildren, Record<string, DailyData>] => {
  const generateDescriptors = (row: SalesReportRow): string[] => {
    const { dimensions, objectId } = row;
    const paths: string[][] = [[]];
    for (const breakdown of breakdowns) {
      const dimension = dimensions[breakdown.key] || "";
      paths.push([...paths[paths.length - 1], dimension]);
    }

    paths.push([...paths[paths.length - 1], objectId]);

    return paths.map(pathToDescriptor);
  };

  const generateDailyDescriptorsForExport = (row: SalesReportRow): string[] => {
    const { dimensions, objectId } = row;

    const breakdownStrings = breakdowns.map(
      (breakdown) => dimensions[breakdown.key] || "",
    );

    return [pathToDescriptor([...breakdownStrings, objectId])];
  };

  const textFilterLower = textFilter.toLowerCase();
  if (textFilter) {
    rows = rows.filter((row) =>
      Object.values(row.searchableStrings).some((v) =>
        v.toLowerCase().includes(textFilterLower),
      ),
    );
  }

  const keyToConfig = chain(breakdownConfigs)
    .groupBy("key")
    .mapValues((v) => v[0])
    .value();

  const descriptorToData: Record<string, TableableResponseRowWithChildren> = {};
  const getDataForDescriptor = (descriptor: string) => {
    if (!descriptorToData[descriptor]) {
      descriptorToData[descriptor] = {
        current: newMetricPoint(),
        compare: null,
        name: "",
        link: "",
        platformId: null,
        extraDetails: [],
        cellAnnotations: {},
        description: [],
        children: [],
        parentId: null,
        id: "",
      };
    }
    return descriptorToData[descriptor];
  };

  const descriptorToDailyData: Record<string, DailyData> = {};
  const getDailyDataForDescriptor = (descriptor: string, date: string) => {
    const descriptorWithDate = descriptor + "\n" + date;
    if (!descriptorToDailyData[descriptorWithDate]) {
      descriptorToDailyData[descriptorWithDate] = {
        current: newMetricPoint(),
        name: "",
        date,
        extraDetails: [],
        description: [],
      };
    }
    return descriptorToDailyData[descriptorWithDate];
  };
  for (const row of rows) {
    const { dimensions, dailyMetrics, forecastV1Daily } = row;

    const dateToForecastRow: Record<string, DbForecastV1DateRow> = {};
    if (forecastV1Daily) {
      for (const forecastRow of forecastV1Daily) {
        dateToForecastRow[forecastRow.date] = forecastRow;
      }
    }

    const rowPassesFilter = breakdowns.every((b) =>
      b.values.includes(dimensions[b.key] ?? ""),
    );
    if (!rowPassesFilter) {
      continue;
    }

    const descriptors = generateDescriptors(row);
    const dailyDescriptors = generateDailyDescriptorsForExport(row);

    for (let i = 0; i < descriptors.length; ++i) {
      const descriptor = descriptors[i];
      if (i === 0) {
        getDataForDescriptor(descriptor).name = "Grand Total";
      } else if (i < descriptors.length - 1) {
        const path = descriptorToPath(descriptor);

        const value = path[path.length - 1];
        const choices =
          keyToConfig[breakdowns[path.length - 1].key]?.choices || [];
        const name = choices.find((v) => v.value === value)?.label ?? value;
        getDataForDescriptor(descriptor).name = name + " (total blended)";
      } else {
        getDataForDescriptor(descriptor).name = row.name;
        getDataForDescriptor(descriptor).link = row.link;
        getDataForDescriptor(descriptor).platformId = row.nbtPlatformID || null;
        getDataForDescriptor(descriptor).extraDetails = row.extraDetails ?? [];
        getDataForDescriptor(descriptor).cellAnnotations =
          row.cellAnnotations ?? {};
      }
      getDataForDescriptor(descriptor).id = descriptor;
    }

    for (const dailyRow of dailyMetrics ?? []) {
      if (isWithinDateInterval(dailyRow.date.substring(0, 10), dateRange)) {
        for (const descriptor of descriptors) {
          const path = descriptorToPath(descriptor);

          const description: { key: string; value: string }[] = [];
          for (let i = 0; i < path.length && i < breakdowns.length; ++i) {
            const breakdownKey = breakdowns[i].key;
            const choices = keyToConfig[breakdownKey]?.choices || [];
            const name =
              choices.find((v) => v.value === path[i])?.label ?? path[i];
            description.push({ key: breakdownKey, value: name });
          }

          const metricPoint = makeMetricPoint(
            dailyRow,
            dateToForecastRow[dailyRow.date],
          );

          let parentId: string | null = null;
          if (path.length > 0) {
            path.pop();
            parentId = pathToDescriptor(path);
          }

          const data = getDataForDescriptor(descriptor).current;
          addToMetricPoint(data, metricPoint);
          getDataForDescriptor(descriptor).current = data;
          getDataForDescriptor(descriptor).description = description;
          getDataForDescriptor(descriptor).parentId = parentId;
          getDataForDescriptor(descriptor).id = descriptor;

          if (dailyDescriptors.includes(descriptor)) {
            getDailyDataForDescriptor(descriptor, dailyRow.date).current =
              metricPoint;
            getDailyDataForDescriptor(descriptor, dailyRow.date).name =
              row.name;
            getDailyDataForDescriptor(descriptor, dailyRow.date).extraDetails =
              row.extraDetails ?? [];
            getDailyDataForDescriptor(descriptor, dailyRow.date).description =
              description;
          }
        }
      }

      if (
        isWithinDateInterval(dailyRow.date.substring(0, 10), compareDateRange)
      ) {
        for (const descriptor of descriptors) {
          const data =
            getDataForDescriptor(descriptor).compare ?? newMetricPoint();
          const metricPoint = makeMetricPoint(dailyRow, null);
          addToMetricPoint(data, metricPoint);
          getDataForDescriptor(descriptor).compare = data;
        }
      }
    }
  }

  const currentDescriptors = Object.keys(descriptorToData);
  const descriptorToRow: Record<string, TableableResponseRowWithChildren> = {};
  currentDescriptors.forEach(
    (descriptor) =>
      (descriptorToRow[descriptor] = getDataForDescriptor(descriptor)),
  );

  for (const descriptor in descriptorToRow) {
    const path = descriptorToPath(descriptor);
    if (path.length === 0) continue;

    const parentPath = [...path];
    parentPath.pop();

    const parentDescriptor = pathToDescriptor(parentPath);
    descriptorToRow[parentDescriptor].children.push(
      descriptorToRow[descriptor],
    );
  }

  return [descriptorToRow[pathToDescriptor([])], descriptorToDailyData];
};

export const createDailyDataRoot = (
  dailyData: Record<string, DailyData>,
  extraExportDimensionColumns: Set<string>,
  metricsArray: SalesPageMetric[],
) =>
  Object.values(dailyData).map((row: DailyData) => {
    const export_: Record<string, any> = {};

    export_.Name = row.name;
    export_.Date = row.date;

    for (const { name, value } of row.extraDetails) {
      export_[name] = value;
      extraExportDimensionColumns.add(name);
    }

    for (const { key, value } of row.description) {
      export_[key] = value;
    }

    for (const metric of metricsArray) {
      export_[metric.id] = metric.calculate(row.current);
    }

    return {
      export: export_,
      link: {},
      display: {},
      changeFractions: {},
      rowMetadata: {},
      cellHoverHTML: {},
    };
  });

export const createNativeTableRoot = (
  data: TableableResponseRowWithChildren,
  extraExportDimensionColumns: Set<string>,
  metricsArray: SalesPageMetric[],
  search: string,
) =>
  data &&
  map(data, (node, level) => {
    const link: Record<string, any> = {};
    const export_: Record<string, any> = {};
    const display: Record<string, any> = {};
    const changeFractions: Record<string, number> = {};
    const rowMetadata: Record<string, any> = {};
    const cellHoverHTML: Record<string, string> = generateCellHoverHTML(
      node,
      metricsArray,
    );

    rowMetadata.level = level;
    rowMetadata.isLeafRow = node.children.length === 0;
    rowMetadata.point = node.current;
    rowMetadata.platformId = node.platformId;
    rowMetadata.extraDetails = node.extraDetails;

    display.Name = node.name;
    export_.Name = node.name;
    if (node.link) {
      link.Name = node.link + search;
    }

    for (const { name, value } of node.extraDetails) {
      export_[name] = value;
      extraExportDimensionColumns.add(name);
    }

    for (const { key, value } of node.description) {
      display[key] = value;
      export_[key] = value;
    }

    for (const metric of metricsArray) {
      const current = metric.calculate(node.current);
      let changeFraction: number | null = null;
      if (node.compare) {
        const compare = metric.calculate(node.compare);
        changeFraction = (current - compare) / compare;
      }

      export_[metric.id] = current;
      display[metric.id] = metric.formatExact(current);
      changeFractions[metric.id] = changeFraction as any;
    }

    return {
      id: node.id,
      parentId: node.parentId,
      link,
      export: export_,
      display,
      changeFractions,
      rowMetadata,
      cellHoverHTML,
    };
  });
