import { gql } from "@apollo/client";
import { useLocationHashScroll } from "@lib/hooks/";
import { RedirectUrls as RedirectUrlsQuery } from "@nb-api-graphql-generated/RedirectUrls";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import { snakeCase } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "./context";
import { Attentive, Facebook, Klaviyo, Pinterest } from "./integrations";
export function RedirectUrls() {
  const { data } = useNorthbeamQuery<RedirectUrlsQuery>(REDIRECT_URLS_QUERY);
  const [tableOfContents, setContents] = useState({} as Record<string, string>);
  const addItemToTOC = useCallback((key, value) => {
    return setContents((previousTOC) => ({
      ...previousTOC,
      [key]: value,
    }));
  }, []);

  return (
    <div className="container pt-4">
      <LogOnMount name="Visit Redirect URLs Page" />
      <div className="row" style={{ marginBottom: 500 }}>
        <div className="col-10">
          <header className="border-bottom">
            <h1>Redirect URLs Guide</h1>
            <p>
              This guide is for tracking ads that direct to landing pages where
              installing the Northbeam pixel is not possible (i.e. external
              webpages).
            </p>
            <p>
              Only use redirects for pages where you have not installed the
              Northbeam Pixel.{" "}
              <strong>Visits will be double counted otherwise!</strong>
            </p>
          </header>

          <nav className="mt-3 border-bottom">
            <h5>Table of Contents</h5>
            <ul>
              {Object.entries(tableOfContents).map(([key, value]) => {
                return (
                  <li key={key}>
                    <a href={`#${key}`}>{value}</a>
                  </li>
                );
              })}
            </ul>
          </nav>

          <Context.Provider
            value={{
              domainName: data?.me?.domainName,
              collectorSubdomain: data?.me?.collectorSubdomain,
              tableOfContents,
              addItemToTOC,
            }}
          >
            <main>
              <RedirectUrls.Article title="Facebook">
                <Facebook />
              </RedirectUrls.Article>

              <RedirectUrls.Article title="Pinterest">
                <Pinterest />
              </RedirectUrls.Article>

              <RedirectUrls.Article title="Klaviyo">
                <Klaviyo />
              </RedirectUrls.Article>

              <RedirectUrls.Article title="Attentive">
                <Attentive />
              </RedirectUrls.Article>
            </main>
          </Context.Provider>
        </div>
      </div>
    </div>
  );
}

const Article = ({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode;
}) => {
  const { addItemToTOC } = useContext(Context);
  const id = encodeURIComponent(snakeCase(title));

  useEffect(() => {
    if (!addItemToTOC) return;

    addItemToTOC(id, title);
  }, [id, title, addItemToTOC]);

  const ref = useLocationHashScroll(id);

  return (
    <article className="mt-3 mb-3">
      <header>
        <h3 id={id} className="pt-3" ref={ref}>
          {title}
        </h3>
      </header>
      <main className="border-bottom">{children}</main>
    </article>
  );
};

RedirectUrls.Article = Article;

const REDIRECT_URLS_QUERY = gql`
  query RedirectUrls {
    me {
      domainName
      collectorSubdomain
    }
  }
`;
