import { LoadingSlideNoText } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import {
  GetCampaign,
  GetCampaignVariables,
} from "@nb-api-graphql-generated/GetCampaign";
import { SalesReportRow } from "@north-beam/nb-common";
import { todayLocalAsISODate } from "@north-beam/nb-common";
import { GET_CAMPAIGN } from "@pages/objects/ad-object-editor";
import { reportStateToGQLVariables } from "@pages/objects/report-state";
import { ReportBodyState } from "@pages/sales/report-body-control";
import { Flyout } from "@shared/flyouts";
import { logEvent } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GRANULARITY } from "../use-sales-report";
import { AdDetail } from "./ad-detail";
import { AdsetDetail } from "./adset-detail";
import { CampaignDetail } from "./campaign-detail";

interface FlyoutSectionProps {
  state: ReportBodyState;
  rows: SalesReportRow[];
}

export const SUPPORTED_PLATFORMS = ["fb", "adwords", "tiktok", "pinterest"];
export const FlyoutSection = ({ state, rows }: FlyoutSectionProps) => {
  const location = useLocation();
  const [flyoutPath, setFlyoutPath] = useState<string>("");
  const [flyoutOpen, setFlyoutOpen] = useState<boolean>(false);
  const [flyoutWidth, setFlyoutWidth] = useState<number>(500);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const path = params.get("flyoutPath") ?? "";
    if (path !== flyoutPath) {
      setFlyoutPath(path);
      if (path.length) {
        setFlyoutOpen(true);
      }
    }
  }, [location, flyoutPath]);
  const userData = useUser();

  const navigate = useNavigate();
  const fetchData = (data: any) => {
    const url = new URL(window.location.href);
    url.searchParams.set("flyoutPath", data.value);
    navigate(url.pathname + url.search, { replace: true });
  };

  const onCloseFlyout = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("flyoutPath");
    navigate(url.pathname + url.search, { replace: true });
    setFlyoutOpen(false);
  };

  return (
    <Flyout
      open={flyoutOpen}
      direction="right"
      className={`overflow-allowed col px-4 ${
        userData?.user.featureFlags.enableDesignRefresh ? "mt-[56px]" : "mt-[64px]"
      } ${flyoutWidth}`}
      onClose={onCloseFlyout}
      drawerWidth={flyoutWidth}
    >
      <div
        className="py-4 cursor-pointer text-[#097CB0]"
        onClick={onCloseFlyout}
      >
        Close
      </div>
      {flyoutPath && (
        <FlyoutDetail
          rows={rows}
          path={flyoutPath}
          state={state}
          fetchData={fetchData}
          setFlyoutWidth={setFlyoutWidth}
        />
      )}
    </Flyout>
  );
};

interface FlyoutDetailProps {
  rows: SalesReportRow[];
  state: ReportBodyState;
  path: string;
  fetchData: (data: any) => void;
  setFlyoutWidth: (width: number) => void;
}

const FlyoutDetail = ({
  rows,
  path,
  state,
  fetchData,
  setFlyoutWidth,
}: FlyoutDetailProps) => {
  useEffect(() => {
    if (state.granularity !== GRANULARITY.CAMPAIGN) {
      setFlyoutWidth(500);
    }
  }, [setFlyoutWidth, state.granularity]);

  const anchor = todayLocalAsISODate();
  const gqlVariables = React.useMemo(() => {
    return reportStateToGQLVariables(state, anchor);
  }, [state, anchor]);

  const { loading, data } = useNorthbeamQuery<
    GetCampaign,
    GetCampaignVariables
  >(GET_CAMPAIGN, {
    variables: {
      id: path,
      ...gqlVariables,
    },
  });

  if (loading || !data || !data.me.adObject) {
    return (
      <div data-test="flyout">
        <LoadingSlideNoText />
      </div>
    );
  }

  return (
    <>
      <div className="mb-3">
        {data.me.adObject.id && (
          <Link
            to={`objects/${data.me.adObject.id}`}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              logEvent(`Clicked ad object page for ${state.granularity}`);
            }}
          >
            Link to ad object page
          </Link>
        )}
      </div>
      <div>
        {state.granularity === GRANULARITY.CAMPAIGN && (
          <CampaignDetail
            rows={rows}
            adObject={data.me.adObject}
            refetchData={fetchData}
            setFlyoutWidth={setFlyoutWidth}
            state={state}
          />
        )}
        {state.granularity === GRANULARITY.ADSET && (
          <AdsetDetail adObject={data.me.adObject} refetchData={fetchData} />
        )}
        {state.granularity === GRANULARITY.AD && (
          <AdDetail adObject={data.me.adObject} refetchData={fetchData} />
        )}
      </div>
    </>
  );
};
