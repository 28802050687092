import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";

export function Postscript({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will only need to setup your global UTMs once for <b>Postcript</b>.
        Follow the instructions{" "}
        <GenericExternalLink href="https://help.postscript.io/hc/en-us/articles/4403539152539-New-Feature-Adjust-Global-UTM-Parameters#About-Global-UTM-Parameters">
          here
        </GenericExternalLink>
        to set global UTM parameters.
      </p>
      <ol>
        <li className="mb-2">
          Navigate to the <b>Campaign UTM parameters</b> using the instructions
          above.
        </li>
        <li className="mb-2">
          Leave the <b>Source</b> field as <b>Static Value</b> with the value
          set to <code>postscript</code>.
        </li>
        <li className="mb-2">
          Set the <b>Campaign</b> field to <b>Dynamic Value</b> and choose{" "}
          <b>Campaign Name</b> from the dropdown.
        </li>
        <li className="mb-2">
          Finally, add a custom parameter with the following structure:
          <p className="mb-0">
            <b>Key: </b>
            <code>utm_medium</code>
          </p>
          <p>
            <b>Value:</b> <code>sms</code>
          </p>
        </li>
      </ol>
    </>
  );
}
