import { LoadingSlide } from "@components/title-slide-view";
import { ScreenHeading } from "@components/utilities";
import {
  FetchOrder,
  FetchOrderVariables,
} from "@nb-api-graphql-generated/FetchOrder";
import React, { useEffect } from "react";

import { ORDER_UNKNOWN_TOUCHPOINTS_QUERY_PARAM } from "@/constants/order-page";
import { b64Decode } from "@north-beam/nb-common";
import { FETCH_ORDER } from "@pages/settings/queries";
import { LogOnMount, usePrettyLogEventPage } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import { Link, useLocation, useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { CustomerDetails } from "./CustomerDetails";
import { CustomerJourney } from "./CustomerJourney";
import { generateTouchpoints } from "./generate-touchpoints";
import { OrderDetails } from "./OrderDetails";
import { usePageTitle } from "@/atoms/page-title-atom";

const useCleanSearchParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete(ORDER_UNKNOWN_TOUCHPOINTS_QUERY_PARAM);
  return `?${searchParams.toString()}`;
};

export function NotFoundOrder() {
  const cleanParams = useCleanSearchParams();

  return (
    <div className="h-full w-full ml-3 mt-5 bg-background overflow-clip	">
      <Link
        to={{ pathname: "..", search: cleanParams }}
        relative="path"
        className="text-md"
      >
        Back to Orders
      </Link>
      <div className="flex flex-grow justify-center align-middle w-full h-full items-center -mt-[5rem]	">
        <div>Sorry, but this order does not exist!</div>
      </div>
    </div>
  );
}

export function OrderDetail({
  orderIdJsonString,
}: {
  orderIdJsonString: string;
}) {
  const [unknownOrder] = useQueryParam(
    ORDER_UNKNOWN_TOUCHPOINTS_QUERY_PARAM,
    StringParam,
  );
  const isUnknownTouchpoints = unknownOrder === "true";
  const prettyPrintLogEventPage = usePrettyLogEventPage();

  const { loading, data } = useNorthbeamQuery<FetchOrder, FetchOrderVariables>(
    FETCH_ORDER,
    {
      variables: {
        orderNumber: orderIdJsonString,
        showPriorNonConversions: isUnknownTouchpoints ? false : true,
      },
    },
  );
  const cleanParams = useCleanSearchParams();

  if (loading) return <LoadingSlide />;
  if (!data?.me?.order) return <NotFoundOrder />;

  const order = data.me.order;
  return (
    <div className="h-full p-6 bg-background">
      <Link
        to={{ pathname: "..", search: cleanParams }}
        relative="path"
        className="text-md"
        onClick={() => {
          prettyPrintLogEventPage({
            action: "Go Back",
            data: {
              orderId: orderIdJsonString,
            },
          });
        }}
      >
        Back to Orders
      </Link>
      <LogOnMount name="Order page" />
      <ScreenHeading>Order #{order.orderNumber}</ScreenHeading>
      <div className="flex">
        <div className="flex flex-col">
          <CustomerDetails order={order} />
          <OrderDetails order={order} />
        </div>
        <CustomerJourney
          touchpoints={generateTouchpoints(order)}
          isUnknownTouchpoints={isUnknownTouchpoints}
        />
      </div>
    </div>
  );
}

const getDecoded = (encodedOrderId: string) => {
  try {
    return decodeURIComponent(b64Decode(encodedOrderId));
  } catch (e) {
    return null;
  }
};

export function Order(): JSX.Element {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Order Details");
  }, [setPageTitle]);

  const { orderId: encodedOrderId = "" } = useParams<{ orderId: string }>();
  const orderIdJsonString = getDecoded(encodedOrderId);
  const prettyLogEventPage = usePrettyLogEventPage();

  useEffect(() => {
    prettyLogEventPage({
      action: "Visited",
      data: { orderId: orderIdJsonString },
    });
  }, [orderIdJsonString, prettyLogEventPage]);

  if (!orderIdJsonString) {
    return <NotFoundOrder />;
  }
  return <OrderDetail orderIdJsonString={orderIdJsonString} />;
}
