import { ConnectionType } from "@nb-api-graphql-generated/global-types";

// The below helpers are being used to support graphql mutations
// and their responses while managing different integration types.

// This function is used to know which key to use when changing the "primary" account type
// of an integration. For example, as of now additional account types are saved in the
// "metadata" field of the many integration tables that exist. If, however, we want to delete
// a connection that corresponds to the "main" account and not an "additional" account, one of the
// accounts that live in the metadata under "additionalAccountIds" has to replace the "main" account
// associated with the connection row in the database. While additional accounts are formatted the same
// between google adwords and facebook (eg: additionalAccountIds: [someId1, someId2]), the "main" (ie first
// account created) id is called "client_customer_id" in the Google Adwords table, and "account_id" in the
// facebook table. In the case we want to delete this "main" account, if additional accounts exist, we want to
// replace it with one of these accounts. This requires making a graphql query that knows what
// column to reference when replacing the "main" account based on which table we are querying.
export function getAccountIdNameFromConnectionType(
  connectionType: ConnectionType,
): string {
  switch (connectionType) {
    case ConnectionType.integration_google_adwords:
      return "client_customer_id";
    case ConnectionType.integration_microsoft:
      return "client_customer_id";
    case ConnectionType.integration_facebook_ads:
      return "account_id";
    case ConnectionType.integration_amazon:
      return "amazon_ads_profile_id";
    default:
      throw new Error(
        `Unexpected account type ${connectionType} when managing multiple accounts`,
      );
  }
}

// As of now, when multiple accounts exist for a specific connection,
// the data under the  "connectionValidation" key returned from the graphql connections query
// is used by connection-card.tsx to list out all connected accounts. The way it is set up now,
// it appears that only google adwords ever has the data under the key "accountIdentifier" be any
// non-string object.

export function getConnectionValidationKeysFromConnectionType(
  connectionType: ConnectionType,
): string {
  switch (connectionType) {
    case ConnectionType.integration_google_adwords:
      return "clientCustomerId";
    default:
      throw new Error(`Unexpected account type ${connectionType}`);
  }
}
