import {
  CompareDateRangeParam,
  DateRangeParam,
  DateRangeQueryNames,
  TimeGranularityParam,
} from "@/constants/date-range-params";

import { useMemo } from "react";
import { useQueryParams, withDefault } from "use-query-params";

export const useDateRangeQueryState = () => {
  return useQueryParams({
    [DateRangeQueryNames.TIME_GRANULARITY]: useMemo(
      () => withDefault(TimeGranularityParam, "daily" as const),
      [],
    ),
    [DateRangeQueryNames.DATE_RANGE]: useMemo(
      () => withDefault(DateRangeParam, "last7Days" as const),
      [],
    ),
    [DateRangeQueryNames.COMPARE_DATE_RANGE]: useMemo(
      () => withDefault(CompareDateRangeParam, "lastPeriod" as const),
      [],
    ),
  });
};

export const useDateRangeOnlyQueryState = () => {
  return useQueryParams({
    [DateRangeQueryNames.DATE_RANGE]: useMemo(
      () => withDefault(DateRangeParam, "last7Days" as const),
      [],
    ),
  });
};
