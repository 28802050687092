import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";

export function Snapchat(props: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        Add a custom parameter following the instructions{" "}
        <GenericExternalLink href="https://businesshelp.snapchat.com/s/article/url-parameters?language=en_US&hi=%27yp%2C%20%27">
          here
        </GenericExternalLink>
        .
      </p>
      <p>
        <b>Key: </b>
        <code>nbt</code>
      </p>
      <p>
        <b>Value:</b>{" "}
        <code>
          {
            "nb:snapchat:{{site_source_name}}:{{campaign.id}}:{{adSet.id}}:{{ad.id}}"
          }
        </code>
      </p>
    </>
  );
}
