import { DataBlessingTimes } from "@north-beam/nb-common";
import moment from "moment";
import React from "react";
import { GenericTooltip } from "../tooltip-container";

const SPEND_KEY_TITLE_MAP = {
  spend_impressions__adwords: "Google Ads",
  spend_impressions__microsoft: "Microsoft Ads",
  spend_impressions__fb: "Facebook Ads",
  spend_impressions__snapchat: "Snapchat Ads",
  spend_impressions__pinterest: "Pinterest Ads",
  spend_impressions__tiktok: "TikTok Ads",
  spend_impressions__ve: "VE",
  spend_impressions__dv360_ve: "DV360 VE",
  spend_impressions__bliss_point_media: "Bliss Point Media",
  spend_impressions__bliss_point_media_linear: "Bliss Point Media Linear",
  spend_impressions__bliss_point_media_audio: "Bliss Point Media Audio",
  spend_impressions__amazon_marketplace_aggregated:
    "Amazon Marketplace Aggregated",
  "spend_impressions__partnership-instagram": "Partnership Instagram",
  "spend_impressions__partnership-podcast": "Partnership Podcast",
  "spend_impressions__partnership-youtube": "Partnership Youtube",
  spend_impressions__mediamath: "Mediamath",
  spend_impressions__mntn: "MNTN",
};

const REVENUE_KEY_TITLE_MAP = {
  northbeam_pixel: "Northbeam pixel",
  shopify: "Shopify",
  orders_shopify: "Shopify",
  orders_custom: "Custom (S2S, WooCommerce and Magento)",
  orders_dummy: "Dummmy",
  orders_amazon: "Amazon",
};

export const DataFreshnessTooltips = ({
  dataBlessingTimes,
  timezone,
}: {
  dataBlessingTimes: DataBlessingTimes;
  timezone: string;
}) => {
  if (Object.keys(dataBlessingTimes).length === 0) {
    return null;
  }

  const [spendFreshnessComponent, revenueFreshnessComponent] = [
    { keyTitleMap: SPEND_KEY_TITLE_MAP, name: "Spend" },
    { keyTitleMap: REVENUE_KEY_TITLE_MAP, name: "Revenue" },
  ].map(({ keyTitleMap, name }) => {
    const tableRows: React.ReactElement[] = [];

    // Start by letting these be invalid moment strings, so we can test in the for loop below
    let maxTime = moment("INVALID");

    Object.keys(dataBlessingTimes)
      .sort((a, b) =>
        moment(dataBlessingTimes[a as keyof DataBlessingTimes]).diff(
          moment(dataBlessingTimes[b as keyof DataBlessingTimes]),
        ),
      )
      .forEach((key: string) => {
        const dataBlessingTime = moment(
          dataBlessingTimes[key as keyof DataBlessingTimes],
        );

        const time = dataBlessingTime
          .tz(timezone)
          .format("MMM D, YYYY [at] HH:mm z");

        const parenthetical = dataBlessingTime.fromNow();

        if (Object.keys(keyTitleMap).includes(key)) {
          const title = keyTitleMap[key as keyof typeof keyTitleMap] || key;

          if (dataBlessingTime.isValid()) {
            if (!maxTime.isValid() || maxTime < dataBlessingTime) {
              maxTime = dataBlessingTime;
            }
            tableRows.push(
              <tr key={key}>
                <td>{title}</td>
                <td>
                  {time} ({parenthetical})
                </td>
              </tr>,
            );
          } else {
            tableRows.push(
              <tr key={key}>
                <td>{title}</td>
                <td>No data</td>
              </tr>,
            );
          }
        }
      });

    const humanReadable = maxTime.isValid() ? maxTime.fromNow() : "Unknown";
    const text = `${name} updated: ${humanReadable}`;

    return (
      <div className="my-auto" key={name}>
        <GenericTooltip
          content={
            <table
              className="mt-3 table table-sm table-striped table-bordered mb-0"
              style={{ whiteSpace: "nowrap" }}
            >
              <thead>
                <tr>
                  <th>System</th>
                  <th>Last updated</th>
                </tr>
              </thead>
              <tbody>{tableRows}</tbody>
            </table>
          }
          overlayClass=""
          noInfoCircle
          captureEventString={`HoverOver${name}UpdatedAt`}
        >
          <span className="cursor-pointer" style={{ fontSize: 14 }}>
            <u>{text}</u>
          </span>
        </GenericTooltip>
      </div>
    );
  });

  return (
    <>
      {spendFreshnessComponent}
      <div className="ml-4" />
      {revenueFreshnessComponent}
    </>
  );
};
