import amazonLogo from "@assets/amazon-logo.svg";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { ConnectionCard } from "@pages/settings/connection-card";
import { LoginComponentChildrenProps } from "@pages/settings/connection-status-manager";
import { CardProps2 } from "@pages/settings/core";
import React from "react";
import { AmazonSellerLoginRegionalComponent } from "./seller-regional-login";

export function AmazonSellerConnectionCard(props: CardProps2) {
  return (
    <ConnectionCard
      type={type}
      connectionTypeName="Amazon Seller"
      connectionCallToAction={`
        Integrate your Amazon Seller data here.
      `}
      logoUrl={amazonLogo}
      logoAltText="Amazon Seller Logo"
    >
      {({ setStatus, accounts }: LoginComponentChildrenProps) => (
        <AmazonSellerLoginRegionalComponent
          setStatus={setStatus}
          accounts={accounts}
          integrationDetails={props.integrationDetails}
        />
      )}
    </ConnectionCard>
  );
}

const type = ConnectionType.integration_amazon_seller;
