import bingLogo from "@assets/bing-ads-logo.svg";
import fbLogo from "@assets/fb-logo.svg";
import googleAdsLogo from "@assets/ga-logo.svg";
import pinterestLogo from "@assets/pinterest-logo-icon.svg";
import snapchatLogo from "@assets/snapchat-logo.svg";
import tiktokIcon from "@assets/tiktok-icon.svg";
import classNames from "classnames";
import React, { CSSProperties, PropsWithChildren } from "react";

export function IconContainer({
  sizeInEM,
  children,
  style,
}: PropsWithChildren<{ sizeInEM: number; style?: CSSProperties }>) {
  return (
    <div
      className="flex"
      style={{
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        width: `${sizeInEM}em`,
        height: `${sizeInEM}em`,
        minWidth: `${sizeInEM}em`,
        minHeight: `${sizeInEM}em`,
        marginRight: `${sizeInEM / 2}em`,
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export function AdIcon({
  sizeInEM,
  platform,
  defaultIcon = <i className="fas fa-map-marker"></i>,
  style,
}: {
  sizeInEM: number;
  platform: string;
  defaultIcon?: React.ReactNode;
  style?: CSSProperties;
}) {
  let inner = defaultIcon;
  if (platform === "youtube") {
    inner = (
      <i
        className="fa-brands fa-youtube"
        style={{ color: "red", fontSize: `${sizeInEM}em` }}
      />
    );
  } else if (platform === "adwords") {
    inner = (
      <img className="img-fluid" src={googleAdsLogo} alt={"Google ads logo"} />
    );
  } else if (platform === "fb" || platform === "facebook") {
    inner = <img className="img-fluid" src={fbLogo} alt={"Facebook logo"} />;
  } else if (platform === "microsoft") {
    inner = <img className="img-fluid" src={bingLogo} alt={"Bing logo"} />;
  } else if (platform === "snapchat") {
    inner = (
      <img className="img-fluid" src={snapchatLogo} alt={"Snapchat logo"} />
    );
  } else if (platform === "pinterest") {
    inner = (
      <img className="img-fluid" src={pinterestLogo} alt={"Pinterest logo"} />
    );
  } else if (platform === "tiktok") {
    inner = <img className="img-fluid" src={tiktokIcon} alt={"TikTok logo"} />;
  } else if (platform === "linkedin") {
    inner = <i className="fab fa-linkedin"></i>;
  } else if (platform === "nativeemail") {
    inner = <i className={classNames("fas fa-envelope-open-text")}></i>;
  } else if (platform === "partnership") {
    inner = <i className="far fa-handshake"></i>;
  } else if (platform === NB_MONITOR_SUBJECTITEM_GROUPED) {
    inner = <i className="fas fa-chart-pie"></i>;
  } else if (platform === NB_MONITOR_SUBJECTITEM_ALL) {
    inner = <i className="fas fa-circle"></i>;
  }

  return (
    <IconContainer sizeInEM={sizeInEM} style={style}>
      {inner}
    </IconContainer>
  );
}

export const NB_MONITOR_SUBJECTITEM_GROUPED = "_nb_monitor_subjectItem_grouped";
export const NB_MONITOR_SUBJECTITEM_ALL = "_nb_monitor_subjectItem_all";
