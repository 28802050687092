import { gql } from "@apollo/client";
import { LoadingSlide } from "@components/title-slide-view";
import { FetchFarmstandPurchaseAnalytics } from "@nb-api-graphql-generated/FetchFarmstandPurchaseAnalytics";
import { formatNumberExact } from "@north-beam/nb-common";
import { useNorthbeamQuery } from "@utils/hooks";
import React from "react";
import { PathRouteProps } from "react-router-dom";

export function FarmstandPurchasesTable(props: PathRouteProps) {
  const { data, loading } = useNorthbeamQuery<FetchFarmstandPurchaseAnalytics>(
    FETCH_FARMSTAND_PURCHASE_ANALYTICS,
    {},
  );
  if (loading) {
    return <LoadingSlide />;
  }

  return (
    <div className="container pt-4">
      <div className="row mt-5">
        <h1>Farmstand Purchases</h1>
      </div>
      <div className="row">
        <div className="row col mt-3">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th className="text-center th-sm" scope="col">
                  Date
                </th>
                <th scope="col" className="text-center th-sm">
                  Count
                </th>
                <th scope="col" className="text-center th-sm">
                  Spend
                </th>
                <th scope="col" className="text-center th-sm">
                  Overall Blended Cost per Unit
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.me?.farmstandPurchaseAnalytics?.map((analytics) => (
                <tr key={analytics.date}>
                  <td>{analytics.date}</td>
                  <td>{analytics.count}</td>
                  <td>{formatNumberExact(analytics.spend, "dollars")}</td>
                  <td>
                    {formatNumberExact(
                      analytics.spend / analytics.count,
                      "dollars",
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export const FETCH_FARMSTAND_PURCHASE_ANALYTICS = gql`
  query FetchFarmstandPurchaseAnalytics {
    me {
      id
      farmstandPurchaseAnalytics
    }
  }
`;
