import CreativeAsset from "@assets/creative-asset.svg";
import { Creative, CreativeAdType } from "@components/creatives";
import { GetCampaign_me_adObject } from "@nb-api-graphql-generated/GetCampaign";
import { useDetectAdBlock } from "adblock-detect-react";
import React from "react";

interface CreativeAssetSectionInterface {
  adObject: GetCampaign_me_adObject;
}
export const CreativeAssetSection = ({
  adObject,
}: CreativeAssetSectionInterface) => {
  const adBlockDetected = useDetectAdBlock();
  const creatives: undefined | CreativeAdType[] =
    adObject?.creativesData?.creatives;

  if (!creatives || !creatives.length) {
    return null;
  }

  return (
    <div className="py-2">
      <div className="flex items-center">
        <div className="mr-2">
          <img className="w-4" alt="Creative Asset" src={CreativeAsset} />
        </div>
        <div className="text-lg text-gray-900">Creative Asset</div>
      </div>
      {adBlockDetected && (
        <small>
          <i>
            If your creative is not displaying, try turning off your adblocker.
          </i>
        </small>
      )}

      {creatives.map((creative: any, index: number) => {
        return <Creative ad={creative} key={index} />;
      })}
    </div>
  );
};
