import { useNavbarExpanded } from "@/atoms/navbar-atom";
import { Checkbox } from "@shared/checkbox";
import { ContextMenu } from "@shared/context-menu";
import { prettyLogEvent } from "@utils/analytics";
import { prefs } from "@utils/client-side-preferences";
import "animate.css";
import clsx from "clsx";
import { motion, useAnimation } from "framer-motion";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import "react-modern-drawer/dist/index.css";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { GenericExternalLink } from "../../generic-external-link";
import { useUser } from "../../user-context";

export function CollapsibleSidebar() {
  const { user } = useUser();
  const controls = useAnimation();

  const [expanded, setExpanded] = useNavbarExpanded();
  const debouncedExpanded = useDebounce<boolean>(expanded, 250);
  useEffect(() => {
    if (debouncedExpanded) {
      controls.start("expanded");
    } else {
      controls.start("collapsed");
    }
  }, [controls, debouncedExpanded]);

  return (
    <motion.div
      animate={controls}
      initial="collapsed"
      className="h-[calc(100%-56px)] flex flex-col justify-between mt-14 text-white fixed z-[11] bg-interstellar"
      transition={{ duration: 0.2 }}
      variants={{
        expanded: { maxWidth: "206px", minWidth: "206px" },
        collapsed: { maxWidth: "56px", minWidth: "56px" },
      }}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <div className="flex flex-col space-y-4 my-4">
        <SidebarItem
          to="overview"
          icon={<i className="fa-sharp fa-regular fa-house" />}
          expanded={debouncedExpanded}
        >
          Overview
        </SidebarItem>
        <SidebarItem
          to="sales"
          icon={<i className="fa-sharp fa-regular fa-chart-line" />}
          expanded={debouncedExpanded}
        >
          Sales
        </SidebarItem>
        <SidebarItem
          to="customers"
          end
          icon={<i className="fa-sharp fa-regular fa-chart-bar" />}
          expanded={debouncedExpanded}
        >
          Customer LTV
        </SidebarItem>

        {/* Show the orders tab if the featureFlag has not been set or the user has set the feature flag to true*/}
        <SidebarItem
          to="orders"
          onClick={() => {
            prettyLogEvent({
              page: "Orders",
              action: "Navigate to page",
            });
          }}
          icon={<i className="fa-sharp fa-regular fa-cart-shopping" />}
          expanded={debouncedExpanded}
        >
          Orders
        </SidebarItem>
        <SidebarItem
          isHidden={!user.featureFlags.enableCreativeAnalytics}
          to="creative-analytics"
          icon={<i className="fa-sharp fa-regular fa-image" />}
          expanded={debouncedExpanded}
        >
          Creative Analytics
        </SidebarItem>

        <SidebarItem
          isHidden={
            !(
              user.displayUserId === "dd62a9c5-ca2d-4231-a4bf-0cb5acc56c49" &&
              user.featureFlags.showFarmstandPurchases
            )
          }
          to="farmstand-purchases"
          icon={<i className="fas fa-store" />}
          expanded={debouncedExpanded}
        >
          Farmstand Purchases
        </SidebarItem>
      </div>

      <div className="flex flex-col space-y-4 my-4">
        {/* note: the AdminMenu will return null if we shouldn't be rendering admin stuff */}
        <AdminMenu expanded={debouncedExpanded} />
        <a
          href="https://info.northbeam.io/knowledge"
          target="_blank"
          rel="noopener noreferrer"
          className={sidebarItemClasses}
        >
          <span className={sidebarIconClasses}>
            <i className="fa-regular fa-sharp fa-circle-question" />
          </span>
          {debouncedExpanded && (
            <span className={sidebarItemTextClasses}>Help</span>
          )}
        </a>
        <SidebarItem
          to="settings"
          icon={<i className="fa-regular fa-sharp fa-cog" />}
          expanded={debouncedExpanded}
        >
          Settings
        </SidebarItem>
      </div>
    </motion.div>
  );
}

const sidebarItemClasses =
  "grid grid-cols-[48px_auto] items-center rounded-full whitespace-nowrap cursor-pointer mx-2 z-10 group/item";
const sidebarIconClasses =
  "group-hover/item:bg-cosmic+2 transform transition-colors ease-in h-10 w-10 flex-center rounded-full";
const sidebarItemTextClasses =
  "animate__animated animate__fadeIn animate__delay-500ms justify-self-start";

const SidebarItem = ({
  isHidden = false,
  expanded,
  icon,
  children,
  ...props
}: NavLinkProps & {
  isHidden?: boolean;
  icon: ReactNode;
  expanded: boolean;
}) => {
  if (isHidden) {
    return null;
  }

  return (
    <NavLink className={sidebarItemClasses} {...props}>
      {({ isActive }) => (
        <>
          <span className={clsx(isActive && "bg-cosmic", sidebarIconClasses)}>
            {icon}
          </span>
          {expanded && (
            <span className={sidebarItemTextClasses}>{children}</span>
          )}
        </>
      )}
    </NavLink>
  );
};

const AdminMenu = ({ expanded = true }: { expanded?: boolean }) => {
  const { user } = useUser();
  const [demoMode, _setDemoMode] = useState(false);
  const [useDevelopmentAnalyticsData, _setUseDevelopmentAnalyticsData] =
    useState(false);

  useEffect(() => {
    _setDemoMode(prefs.isDemoMode());
    _setUseDevelopmentAnalyticsData(prefs.useDevelopmentAnalyticsData());
  }, []);

  const setDemoModeLocal = useCallback(() => {
    prefs.setDemoMode(!demoMode);
    _setDemoMode(!demoMode);
    window.location.reload();
  }, [demoMode, _setDemoMode]);

  const setUseDevelopmentAnalyticsDataLocal = useCallback(() => {
    prefs.setUseDevelopmentAnalyticsData(!useDevelopmentAnalyticsData);
    _setUseDevelopmentAnalyticsData(!useDevelopmentAnalyticsData);
    window.location.reload();
  }, [useDevelopmentAnalyticsData, _setUseDevelopmentAnalyticsData]);

  const shouldShowAnonymizationSwitch =
    user.isAdmin || user.featureFlags.showAnonymizationSwitch;
  const shouldShowDevelopmentAnalyticsTableSwitch =
    user.isAdmin || user.featureFlags.showDevelopmentAnalyticsTableSwitch;
  const shouldShowRetoolLink = user.isAdmin;

  const shouldShowAdminMenu =
    shouldShowAnonymizationSwitch ||
    shouldShowDevelopmentAnalyticsTableSwitch ||
    shouldShowRetoolLink;

  if (!shouldShowAdminMenu) {
    return null;
  }

  return (
    <ContextMenu
      className="z-20"
      menuClassName="w-64"
      menuPosition="bottom-right"
      menuButton={
        <ContextMenu.Button className={sidebarItemClasses}>
          <span className={sidebarIconClasses}>
            <i className="fa-regular fa-sharp fa-user" />
          </span>
          {expanded && (
            <span className={sidebarItemTextClasses}>Admin tools</span>
          )}
        </ContextMenu.Button>
      }
    >
      {expanded && (
        <>
          {shouldShowAnonymizationSwitch && (
            <ContextMenu.Item
              as="div"
              className="flex items-center space-x-2 py-3 px-4 text-interstellar"
            >
              <Checkbox
                className="mr-3"
                onChange={() => {
                  setDemoModeLocal();
                }}
                value={demoMode}
              />
              <span>Anonymize data</span>
            </ContextMenu.Item>
          )}

          {shouldShowDevelopmentAnalyticsTableSwitch && (
            <ContextMenu.Item
              as="div"
              className="flex items-center space-x-2 py-2 px-4 text-interstellar"
            >
              <Checkbox
                className="mr-3"
                onChange={() => {
                  setUseDevelopmentAnalyticsDataLocal();
                }}
                value={useDevelopmentAnalyticsData}
              />
              <span>Use development data</span>
            </ContextMenu.Item>
          )}

          {shouldShowRetoolLink && (
            <ContextMenu.Item as="div" className="text-cosmic py-2 px-4">
              <GenericExternalLink
                href={`https://northbeam.retool.com/apps/fbf147e0-c253-11eb-8912-ef20e112236b/Northbeam#userId=${user.displayUserId}`}
              >
                Open Retool
              </GenericExternalLink>
            </ContextMenu.Item>
          )}
        </>
      )}
    </ContextMenu>
  );
};
