import { GetSalesReportFast_me_salesReportTable } from "@nb-api-graphql-generated/GetSalesReportFast";
import { TabView } from "@pages/sales/tab-table-section/tab-table-section";
import { PLATFORM_NORTHBEAM } from "@utils/constants";
import { useCallback, useMemo, useState } from "react";

export const useSalesReportDataFilter = (
  salesReport: GetSalesReportFast_me_salesReportTable | undefined,
  granularity: TabView,
) => {
  /**
   * Since we're filtering programmatically we have two sets of filters, the
   * generated{level}Filters which gets reset every time and the user{level}Filters
   * made by the user which persists until the user clears it or clicks on a parent
   * level which clears the  children level.
   */
  const [prevLevel, setPrevLevel] = useState<TabView>(granularity);

  const [userPlatformFilters, setUserPlatformFilters] = useState<string[]>([]);
  const [generatedPlatformFilters, setGeneratedPlatformFilters] = useState<
    string[]
  >([]);
  const platformFilters = useMemo(
    () =>
      Array.from(
        new Set([...userPlatformFilters, ...generatedPlatformFilters]),
      ),
    [userPlatformFilters, generatedPlatformFilters],
  );

  const [userCampaignFilters, setUserCampaignFilters] = useState<string[]>([]);
  const [generatedCampaignFilters, setGeneratedCampaignFilters] = useState<
    string[]
  >([]);
  const campaignFilters = useMemo(
    () =>
      Array.from(
        new Set([...userCampaignFilters, ...generatedCampaignFilters]),
      ),
    [userCampaignFilters, generatedCampaignFilters],
  );

  const [userAdsetFilters, setUserAdsetFilters] = useState<string[]>([]);
  const [generatedAdsetFilters, setGeneratedAdsetFilters] = useState<string[]>(
    [],
  );
  const adsetFilters = useMemo(
    () => Array.from(new Set([...userAdsetFilters, ...generatedAdsetFilters])),
    [userAdsetFilters, generatedAdsetFilters],
  );

  const filteredRows = useMemo(() => {
    let rows = salesReport?.rows ?? [];
    const hasLevelAnyFilters = !(
      platformFilters.length === 0 &&
      campaignFilters.length === 0 &&
      adsetFilters.length === 0
    );
    const isPlatformTab = granularity === TabView.PLATFORM;

    if (isPlatformTab) {
      return rows;
    }

    if (hasLevelAnyFilters || !isPlatformTab) {
      // Filters rows by level
      rows = rows.filter(({ objectId, dimensions }) => {
        try {
          const adKey = JSON.parse(objectId);

          // If there are no platform filters, this is vacuously matched
          let hasPlatformMatch = true;
          if (platformFilters.length) {
            // If there are platform filters, and the ad key includes that platform
            // there is a match
            if (platformFilters.includes(dimensions[PLATFORM_NORTHBEAM])) {
              hasPlatformMatch = true;

              // If (not set) has been chosen, indicated by a '' platformFilter
              // and there are no dimensions
              // there is a match
            } else if (
              platformFilters.includes("") &&
              dimensions[PLATFORM_NORTHBEAM] === undefined
            ) {
              hasPlatformMatch = true;

              // Otherwise, no match
            } else {
              hasPlatformMatch = false;
            }
          }
          const hasCampaignMatch = campaignFilters.length
            ? campaignFilters.includes(adKey.nbt.campaign)
            : true;

          let hasAdsetMatch = false;
          if (adsetFilters.length === 0) {
            hasAdsetMatch = true;
          } else {
            // If adset has no name then fallback to objectId
            if (adKey.nbt.adset === "") {
              delete adKey.nbt.ad;
              const generatedObjectId = JSON.stringify(adKey.nbt);
              hasAdsetMatch = adsetFilters.includes(generatedObjectId);
            } else {
              hasAdsetMatch = adsetFilters.includes(adKey.nbt.adset);
            }
          }

          if (granularity === TabView.CAMPAIGN && hasPlatformMatch) {
            return true;
          }

          if (
            granularity === TabView.ADSET &&
            hasCampaignMatch &&
            hasPlatformMatch
          ) {
            return true;
          }

          if (
            granularity === TabView.AD &&
            hasCampaignMatch &&
            hasPlatformMatch &&
            hasAdsetMatch
          ) {
            return true;
          }

          return false;
        } catch (error) {
          console.error(error);
          console.log(objectId);
          return true;
        }
      });
    }

    return rows;
  }, [
    platformFilters,
    campaignFilters,
    adsetFilters,
    granularity,
    salesReport,
  ]);

  const clearLevelFilter = useCallback((level: TabView) => {
    switch (level) {
      case TabView.PLATFORM:
        setUserPlatformFilters([]);
        setGeneratedPlatformFilters([]);

        setUserCampaignFilters([]);
        setGeneratedCampaignFilters([]);

        setUserAdsetFilters([]);
        setGeneratedAdsetFilters([]);
        break;
      case TabView.CAMPAIGN:
        setUserCampaignFilters([]);
        setGeneratedCampaignFilters([]);

        setUserAdsetFilters([]);
        setGeneratedAdsetFilters([]);
        break;
      case TabView.ADSET:
        setUserAdsetFilters([]);
        setGeneratedAdsetFilters([]);
        break;
    }
  }, []);

  /**
   * selectedRows is an array of objectIds gets converted to an object to extract
   * the ad level names used to filter the table
   */
  const setObjectIdsToAdLevelFilters = useCallback(
    (selectedRows, level: TabView) => {
      const platformSet = new Set<string>(platformFilters);
      const campaignSet = new Set<string>(campaignFilters);
      const adSetSet = new Set<string>([]);

      setGeneratedPlatformFilters([]);
      setGeneratedCampaignFilters([]);
      setGeneratedAdsetFilters([]);

      switch (level) {
        case TabView.PLATFORM:
          platformSet.clear();
          setUserCampaignFilters([]);

          campaignSet.clear();
          setUserPlatformFilters([]);

          adSetSet.clear();
          setUserAdsetFilters([]);
          break;
        case TabView.CAMPAIGN:
          campaignSet.clear();
          setUserCampaignFilters([]);
          break;
        case TabView.ADSET:
          adSetSet.clear();
          setUserAdsetFilters([]);
          break;
      }

      selectedRows.forEach((rowKey: string) => {
        try {
          const descriptor = rowKey.split("\n");
          // Very naive check for now
          const isJsonParseableString = (str: string) =>
            str.startsWith("{") && str.endsWith("}");
          const objectId = descriptor.find(isJsonParseableString);
          if (objectId) {
            const parsedObjectId = JSON.parse(objectId);
            Object.entries(parsedObjectId.nbt).forEach(
              ([level, value]: any) => {
                switch (level) {
                  case TabView.CAMPAIGN:
                    campaignSet.add(value);
                    break;
                  case TabView.ADSET:
                    if (value === "") {
                      adSetSet.add(JSON.stringify(parsedObjectId.nbt));
                    } else {
                      adSetSet.add(value);
                    }
                    break;
                }
              },
            );
          } else {
            // This should be the platform after __root__ if it's not JSON serializable
            platformSet.add(descriptor[1]);
          }
        } catch (error) {
          console.error(error);
        }
      });

      switch (prevLevel) {
        case TabView.PLATFORM:
          setUserPlatformFilters(Array.from(platformSet));
          break;
        case TabView.CAMPAIGN:
          setUserCampaignFilters(Array.from(campaignSet));
          break;
        case TabView.ADSET:
        case TabView.AD:
          setUserAdsetFilters(Array.from(adSetSet));
          break;
      }

      setPrevLevel(level);
    },
    [platformFilters, campaignFilters, prevLevel],
  );

  return {
    filteredRows,
    clearLevelFilter,
    setObjectIdsToAdLevelFilters,
    salesTabTableSelectedCount: {
      platform: userPlatformFilters.length,
      campaign: userCampaignFilters.length,
      adset: userAdsetFilters.length,
    },
  };
};
