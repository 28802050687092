import { PrimaryButton, SecondaryButton } from "@shared/buttons";
import { Modal } from "@shared/modals";
import React, { cloneElement, useState } from "react";

interface ConfirmActionButtonProps {
  title: string;
  description?: string;
  onConfirm: () => void;
  actionButton: JSX.Element;
  onCancel?: () => void;
  dataTestId?: string;
}

export const ConfirmActionButton = ({
  title,
  description,
  onConfirm,
  actionButton,
  onCancel,
  dataTestId,
}: ConfirmActionButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const confirmButton = cloneElement(actionButton, {
    onClick: () => setIsOpen(true),
  });

  return (
    <>
      {confirmButton}
      <Modal isOpen={isOpen} title={title} description={description}>
        <div className="flex space-x-3" data-testid={dataTestId}>
          <PrimaryButton
            onClick={() => {
              onConfirm();
              setIsOpen(false);
            }}
          >
            Confirm
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              setIsOpen(false);
              onCancel?.();
            }}
          >
            Cancel
          </SecondaryButton>
        </div>
      </Modal>
    </>
  );
};
