import { useUser } from "@components/user-context";
import { CreateAPIKey } from "@nb-api-graphql-generated/CreateAPIKey";
import { FetchApiKeys as FetchApiKeysQuery } from "@nb-api-graphql-generated/FetchApiKeys";
import { CREATE_API_KEY, FETCH_API_KEYS } from "@pages/settings/api-keys";
import { SecondaryButton } from "@shared/buttons";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { StepWizardChildProps } from "react-step-wizard";

export const WooCommerceConnectionFlow: FC<
  Partial<StepWizardChildProps>
> = () => {
  const { user } = useUser();

  const [apiKeys, setApiKeys] = useState<string[]>([]);

  const { loading, data } =
    useNorthbeamQuery<FetchApiKeysQuery>(FETCH_API_KEYS);

  const [createNewApiKey, { loading: createNewApiKeyLoading }] =
    useNorthbeamMutation<CreateAPIKey>(CREATE_API_KEY, {
      refetchQueries: [{ query: FETCH_API_KEYS }],
      awaitRefetchQueries: true,
    });

  useEffect(() => {
    if (data?.me?.apiKeys && data?.me?.apiKeys.length === 0) {
      createNewApiKey();
    } else {
      setApiKeys(data?.me?.apiKeys || []);
    }
  }, [data, createNewApiKey]);

  return (
    <>
      {(createNewApiKeyLoading || loading) && <>Loading...</>}
      {!createNewApiKeyLoading && !loading && (
        <>
          <h3>Let&apos;s get WooCommerce integrated.</h3>
          <p>
            Follow the instructions below to upload our pixel file and setup on
            WooCommerce. Click &ldquo;Continue&rdquo; once you&apos;ve completed
            the directions.
          </p>
          <hr />

          <ol className="list-decimal">
            <li className="mb-3">
              Start by downloading the Northbeam pixel file:{" "}
              <Link
                className="secondary-button ml-2 button md"
                to="/files/northbeam-1.0.2.zip"
                target="_blank"
                download
              >
                <SecondaryButton size="md">
                  <i className="fa-light fa-download mr-2" /> Download Pixel
                  File
                </SecondaryButton>
              </Link>
            </li>
            <li className="mb-3">
              <div className="mb-2">
                Follow the instructions below. Use the following Client ID and
                API Key when prompted by the instructions.
              </div>
              <div className="mb-2">
                <b>Client ID:</b> {user.displayUserId}{" "}
              </div>
              <div className="mb-4">
                <b>API Key:</b> {apiKeys[0]}
              </div>
            </li>
          </ol>

          <iframe
            src="https://scribehow.com/page-embed/WooCommerce_Pixel_Setup__3k9hAu8PRjOjI-mnSuLy9g"
            width="100%"
            height="500"
            frameBorder="0"
            title="WooCommerce Integration Flow"
          />
        </>
      )}
    </>
  );
};
