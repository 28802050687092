import { PrimaryButton } from "@shared/buttons";
import { Modal } from "@shared/modals";
import React from "react";
import { MAX_SAVED_VIEWS } from "./saved-views";

interface MaxViewsModalProps {
  isOpen: boolean;
  close: () => void;
}

export const MaxViewsModal = ({ isOpen, close }: MaxViewsModalProps) => {
  return (
    <Modal
      title="Maximum number of saved views reached"
      description={`You can create up to ${MAX_SAVED_VIEWS} saved views. You will need to delete a previously saved view in order to save this one.`}
      isOpen={isOpen}
    >
      <PrimaryButton className="mt-24" onClick={close}>
        OK
      </PrimaryButton>
    </Modal>
  );
};
