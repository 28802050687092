import { Select } from "@shared/selects";
import { components, OptionProps } from "react-select";
import React, { MouseEvent } from "react";
import { useSalesViews } from "../sales-views-context";

const Option = (props: OptionProps<any>) => {
  const onClick = (event: MouseEvent) => {
    event.persist();

    if (event.ctrlKey || event.metaKey) {
      event.stopPropagation();

      const savedViewsUrl = new URL(window.location.href);
      savedViewsUrl.searchParams.set("savedView", props.data.value);
      window.open(savedViewsUrl.href, "_blank");
    }
  };

  return (
    <components.Option {...props}>
      <div onClick={onClick}>{props.label}</div>
    </components.Option>
  );
};

export const SavedViewsSelect = () => {
  const { salesViews, selectSalesView, selectedSalesViewId } = useSalesViews();

  const salesViewsOptions = salesViews.map((view) => ({
    value: view.id,
    label: view.name,
  }));

  return (
    <Select
      className="w-72"
      components={{ Option }}
      options={salesViewsOptions}
      value={selectedSalesViewId}
      onChange={selectSalesView}
    />
  );
};
