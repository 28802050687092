import { format } from "date-fns-tz";

export const HOUR_SELECT_OPTIONS = [
  { label: "12 am", value: "00:00" },
  { label: "1 am", value: "01:00" },
  { label: "2 am", value: "02:00" },
  { label: "3 am", value: "03:00" },
  { label: "4 am", value: "04:00" },
  { label: "5 am", value: "05:00" },
  { label: "6 am", value: "06:00" },
  { label: "7 am", value: "07:00" },
  { label: "8 am", value: "08:00" },
  { label: "9 am", value: "09:00" },
  { label: "10 am", value: "10:00" },
  { label: "11 am", value: "11:00" },
  { label: "12 pm", value: "12:00" },
  { label: "1 pm", value: "13:00" },
  { label: "2 pm", value: "14:00" },
  { label: "3 pm", value: "15:00" },
  { label: "4 pm", value: "16:00" },
  { label: "5 pm", value: "17:00" },
  { label: "6 pm", value: "18:00" },
  { label: "7 pm", value: "19:00" },
  { label: "8 pm", value: "20:00" },
  { label: "9 pm", value: "21:00" },
  { label: "10 pm", value: "22:00" },
  { label: "11 pm", value: "23:00" },
];

export function formatTimeZone(timeZone: string) {
  try {
    return format(new Date(), "zzzz", { timeZone });
  } catch {
    return timeZone;
  }
}

export function formatHourWithTimeZone(hour: string, timeZone: string) {
  try {
    const tz = format(new Date(), "zzzz", { timeZone });
    const tzShort = tz
      .split(" ")
      .map((e) => e[0])
      .join("");

    return `${hour} (${tzShort})`;
  } catch {
    return hour;
  }
}

export function ordinal(n: number) {
  const i = n % 10;
  const j = n % 100;

  if (i === 1 && j !== 11) {
    return n + "st";
  }

  if (i === 2 && j !== 12) {
    return n + "nd";
  }

  if (i === 3 && j !== 13) {
    return n + "rd";
  }

  return n + "th";
}
