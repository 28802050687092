import React from "react";
import Select from "react-select";
import { IGranularity, Granularity } from "@utils/constants";
import { HierarchyDropdown } from "@shared/selects/dropdown-indicators";

interface GranularitySelectProps {
  className?: string;
  isLoading: boolean;
  granularity: IGranularity;
  onChange: (obj: any) => void;
}

export const GranularitySelect = ({
  className,
  isLoading,
  granularity,
  onChange,
}: GranularitySelectProps) => {
  return (
    <Select
      className={className}
      isDisabled={isLoading}
      isClearable={false}
      isSearchable={false}
      defaultValue={Granularity.toReactSelect(granularity)}
      value={Granularity.toReactSelect(granularity)}
      options={Granularity.reactSelectOptions}
      components={{ DropdownIndicator: HierarchyDropdown }}
      onChange={onChange}
    />
  );
};
