import React from "react";
import { UtmGuidePlatformProps } from "..";
import { useUser } from "@components/user-context";

export function AvantLink({ urlParamPairs }: UtmGuidePlatformProps) {
  const { user } = useUser();

  const emailBody = (
    <>
      <p>Hello,</p>
      <p>Please add the following UTM parameters to our ads:</p>
      <p>
        <b>
          {urlParamPairs.map(({ key, value }) => `${key}=${value}`).join("&")}
        </b>
      </p>
      <p>Thank you!</p>
    </>
  );

  return (
    <>
      <p>
        You need to email <b>AvantLink&apos;s</b> customer support at{" "}
        <a href="mailto:support@avantlink.com">support@avantlink.com</a>. You
        can copy the below text for our suggested email message.
      </p>
      <div className="card">
        <div className="card-body">
          <p className="card-title">
            <b>Subject:</b> Please add UTM tracking parameters to ads from{" "}
            {user.companyName}.
          </p>
          <hr />
          <div className="card-text">{emailBody}</div>
        </div>
      </div>
    </>
  );
}
