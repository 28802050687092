import { MetricFormat } from "@north-beam/nb-common";
import classNames from "classnames";
import React from "react";
import Modal from "react-modal";
import NumericInput from "react-numeric-input";
import { Theme } from "react-select";
export interface ProviderWrapperProps {
  children: React.ReactElement | React.ReactElement[];
}

export const Blanket = (props: any) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: props.zIndex ?? 1,
    }}
    {...props}
  />
);

export const H1 = ({
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>) => (
  <h1 className={classNames(rest.className, "mb-3")} {...rest}>
    {children}
  </h1>
);

export const ScreenHeading = ({
  children,
  ...rest
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>) => (
  <h1 className={classNames(rest.className, "mb-3 text-screen")} {...rest}>
    {children}
  </h1>
);

export function LightSwitch(props: {
  size?: "large" | "small" | "medium";
  isSet: boolean;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
}) {
  const { isSet, onChange, id, disabled, size } = props;
  const htmlId = `check--${id}`;

  const sliderClasses = ["slider"];
  if (disabled) {
    sliderClasses.push("disabled");
  }

  return (
    <label className={`custom-switch-${size || "large"}`} htmlFor={htmlId}>
      <input
        disabled={disabled}
        type="checkbox"
        checked={isSet}
        onChange={onChange}
        id={htmlId}
      />
      <span className={classNames(sliderClasses)}></span>
    </label>
  );
}

export function InterleaveSpans(props: {
  text: React.ReactNode[];
  sep: React.ReactNode;
  Wrapper: any;
}) {
  const { text, sep, Wrapper } = props;
  const rv: React.ReactNodeArray = [];
  for (let i = 0; i < text.length; ++i) {
    rv.push(<Wrapper key={`text-${i}`}>{text[i]}</Wrapper>);
    if (i < text.length - 1) {
      rv.push(sep);
    }
  }
  return <React.Fragment>{rv}</React.Fragment>;
}

export type ChangeOnBlurInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onBlur"
>;

export function ChangeOnBlurInput({
  onChange,
  onKeyPress,
  value,
  ...rest
}: ChangeOnBlurInputProps) {
  if (value === null || typeof value === "undefined") {
    value = "";
  }

  const [tempValue, setTempValue] =
    React.useState<React.InputHTMLAttributes<HTMLInputElement>["value"]>(value);

  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    setTempValue(value);
    if (ref.current) {
      ref.current.focus();
    }
  }, [value]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTempValue(event.target.value);
    },
    [],
  );

  return (
    <input
      {...rest}
      ref={ref}
      value={tempValue}
      onChange={handleChange}
      onBlur={onChange}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          if (ref.current) {
            ref.current.blur();
            e.preventDefault();
          }
        } else {
          if (onKeyPress) {
            onKeyPress(e);
          }
        }
      }}
    />
  );
}

export function NumberInput({
  onValueChange,
  value,
  format,
  ...rest
}: {
  format: MetricFormat;
  onValueChange: (value: number | undefined) => void;
  value: number | undefined;
} & Omit<ChangeOnBlurInputProps, "onChange" | "value">) {
  const [current, setCurrent] = React.useState(value);

  const onChange = React.useCallback(
    (num: number | null) => {
      if (num === null) {
        setCurrent(undefined);
        return;
      }

      if (format === "percentage") {
        num /= 100;
      }

      setCurrent(num);
    },
    [format, setCurrent],
  );

  const commit = React.useCallback(
    () => onValueChange(current),
    [onValueChange, current],
  );

  let pre: React.ReactNode = null;
  let post: React.ReactNode = null;
  let displayValue = current;
  if (format === "dollars") {
    pre = (
      <div className="input-group-prepend">
        <div className="input-group-text">$</div>
      </div>
    );
  } else if (format === "multiplier") {
    post = (
      <div className="input-group-append">
        <div className="input-group-text">×</div>
      </div>
    );
  } else if (format === "percentage") {
    post = (
      <div className="input-group-append">
        <div className="input-group-text">%</div>
      </div>
    );
    displayValue =
      typeof displayValue === "undefined" ? undefined : displayValue * 100;
  }
  const stringValue =
    typeof displayValue === "undefined" ? "" : String(displayValue);

  return (
    <div className="input-group">
      {pre}
      <NumericInput
        value={stringValue}
        className="form-control"
        onChange={onChange}
        onBlur={commit}
        /* eslint-disable react/style-prop-object */
        style={false}
        /* eslint-disable react/style-prop-object */
      />
      {post}
    </div>
  );
}

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function Bubble(props: {
  color: string;
  title?: string;
  children: React.ReactNode | React.ReactNodeArray;
  borderRadius?: string;
}) {
  return (
    <div
      title={props.title}
      style={{
        fontSize: "12px",
        backgroundColor: props.color,
        color: "white",
        padding: "0px 8px",
        margin: "4px 4px",
        borderRadius: props.borderRadius ?? "0.75rem",
        display: "inline-block",
        lineHeight: "1.5rem",
      }}
    >
      {props.children}
    </div>
  );
}

export function reactSelectSmallTheme(theme: Theme): Theme {
  return {
    ...theme,
    borderRadius: 4,
    spacing: {
      baseUnit: 2,
      controlHeight: 30,
      menuGutter: 8,
    },
  };
}

export interface ButtonWithInterceptModalProps {
  modalDisabled?: boolean;
  modalTitle: string;
  modalBody: string;
  modalOnConfirmed: () => void;
}

// Unsure if this actually works.
export function ButtonWithInterceptModal({
  modalDisabled,
  modalTitle,
  modalBody,
  modalOnConfirmed,
  ...props
}: ButtonWithInterceptModalProps &
  Exclude<JSX.IntrinsicElements["button"], "onClick">) {
  const [isOpen, setOpen] = React.useState(false);
  const open = React.useCallback(() => setOpen(true), [setOpen]);
  const close = React.useCallback(() => setOpen(false), [setOpen]);

  const initialClick = React.useCallback(() => {
    if (modalDisabled) {
      modalOnConfirmed();
    } else {
      open();
    }
  }, [modalDisabled, modalOnConfirmed, open]);

  const cancel = close;
  const confirm = React.useCallback(() => {
    close();
    modalOnConfirmed();
  }, [close, modalOnConfirmed]);

  return (
    <React.Fragment>
      <button {...props} onClick={initialClick} />
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={cancel}
        style={{ overlay: { backgroundColor: "#00000080" } }}
        contentElement={(_props, children) => (
          <div
            style={{
              border: "none",
              background: "transparent",
              maxHeight: "100vh",
              overflowY: "auto",
            }}
            className="p-5"
            onClick={cancel}
          >
            <div onClick={(e) => e.stopPropagation()}>{children}</div>
          </div>
        )}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{modalTitle}</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={cancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {modalBody && <div className="modal-body">{modalBody}</div>}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirm}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
