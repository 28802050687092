import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { BreakdownEditor } from "@pages/breakdown-editor";
import { CreativeAnalyticsPage } from "@pages/creative-analytics";
import { Order } from "@pages/order";
import { Orders } from "@pages/orders";
import { SalesPage } from "@pages/sales";
import { SalesPageMain } from "@pages/sales/sales-main";
import { NotificationManager } from "@shared/notify";
import clsx from "clsx";
import LogRocket from "logrocket";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { useAuth0 } from "./components/auth0-wrapper";
import { AppVersionProvider } from "./components/data/app-version";
import { CustomerBreakdownConfigProvider } from "./components/data/customer-breakdown-configs";
import { SalesBreakdownConfigsProvider } from "./components/data/sales-breakdown-configs";
import { Navbar } from "./components/Navbar";
import { Page404 } from "./components/title-slide-view";
import { useUser } from "./components/user-context";
import config, { envName } from "./environment";
import { Customers } from "./pages/customers";
import { ProgressPage } from "./pages/demo/progress-report";
import { FarmstandPurchasesTable } from "./pages/farmstand-purchases";
import { GuideRoutes } from "./pages/guides";
import { ModelComparisonPage } from "./pages/model-comparison";
import { Monitor } from "./pages/monitor";
import { ObjectEditor } from "./pages/objects";
import { OverviewPage } from "./pages/overview";
import { OverviewPagePrintMode } from "./pages/overview/print-view";
import { Rules } from "./pages/rules";
import { SettingsRoutes } from "./pages/settings";
import { PreActivationContent } from "./pre-activation";
import { bugsnagClient, LogOnMount } from "./utils/analytics";
import { Redirects } from "./utils/redirects";
import { FastSalesPage } from "@pages/sales-v2";

declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

if (config.datadogApplicationId && config.datadogClientToken) {
  datadogRum.init({
    applicationId: config.datadogApplicationId,
    clientToken: config.datadogClientToken,
    site: "us5.datadoghq.com",
    service: "nb-frontend",
    env: envName,
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    // Remove log rocket from activity URLs
    excludedActivityUrls: [/.*r\.lr-in-prod\.com.*/],
  });

  datadogRum.startSessionReplayRecording();
}

export default function App() {
  LogRocket.init("8bdmkg/northbeam");

  const { hash, pathname } = useLocation();
  const { user } = useUser();
  const { logout } = useAuth0();
  const { userId = "" } = useParams();
  // Temp HACK for design refresh
  useEffect(() => {
    if (user.featureFlags.enableDesignRefresh) {
      document.querySelector("body")?.classList.add("enableDesignRefresh");

      (function HACK_updateFontAwesomeIcons() {
        const observer = new MutationObserver(function (mutations) {
          const icons = document.querySelectorAll(
            "i:not(.fa-sharp):not(.fa-brands)",
          );

          if (icons.length) {
            icons.forEach((icon) => {
              icon.classList.remove(
                "fa-solid",
                "fa-regular",
                "fas",
                "fa-light",
                "fa-duotone",
              );
              icon.classList.add("fa-sharp", "fa-regular");
            });
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });
      })();
    }
  }, [user.featureFlags.enableDesignRefresh]);

  const onLogout = () => logout({ returnTo: window.location.origin });

  bugsnagClient.setUser(user.authedUserId, user.email);

  LogRocket.identify(user.authedUserId, {
    email: user.email,
    companyName: user.companyName ?? "",
    displayUserId: user.displayUserId,
  });

  datadogRum.setUser({
    id: user.authedUserId,
    name: user.email,
    email: user.email,
    companyName: user.companyName ?? "",
    displayUserId: user.displayUserId,
    displayUserName: `${user.firstName} ${user.lastName}`,
    isAdmin: user.isAdmin,
  });

  return (
    <CookiesProvider>
      <ApolloProvider client={user.apolloClient}>
        <SalesBreakdownConfigsProvider>
          <CustomerBreakdownConfigProvider>
            <AppVersionProvider>
              <DndProvider backend={HTML5Backend}>
                <PreActivationContent onLogout={onLogout}>
                  <LogOnMount
                    name="App Load"
                    data={{ Impersonating: user.impersonatedUser?.id || "" }}
                  />
                  <Navbar onLogout={onLogout}>
                    <NotificationManager />

                    {/* Sad hack to get bg colouring in orders page */}
                    <div
                      className={clsx(
                        `col overflow-allowed enableDesignRefresh-main`,
                        user.featureFlags.enableDesignRefresh
                          ? "bg-cosmic-3 ml-[56px] mt-[56px]"
                          : "ml-[90px] mt-[64px]",
                        pathname.includes("/creative-analytics") ||
                          pathname.includes("/orders") ||
                          (pathname.includes("customers/documents") && {
                            "bg-background": true,
                          }),
                      )}
                    >
                      <Routes>
                        <Route path="/" element={<Navigate to="overview" />} />
                        <Route path="settings/*" element={<SettingsRoutes />} />

                        {user.featureFlags.enableCreativeAnalytics && (
                          <Route
                            path="creative-analytics"
                            element={<CreativeAnalyticsPage />}
                          />
                        )}
                        <Route path="orders" element={<Orders />} />
                        <Route path="orders/:orderId/*" element={<Order />} />
                        <Route path="guides/*" element={<GuideRoutes />} />
                        {user.displayUserId ===
                          "dd62a9c5-ca2d-4231-a4bf-0cb5acc56c49" &&
                          user.featureFlags.showFarmstandPurchases && (
                            <Route
                              path="farmstand-purchases"
                              element={<FarmstandPurchasesTable />}
                            />
                          )}
                        <Route path="overview" element={<OverviewPage />} />
                        <Route path="rules/*" element={<Rules />} />
                        <Route
                          path="overview-print"
                          element={<OverviewPagePrintMode />}
                        />
                        <Route
                          path="sales/objects/*"
                          element={<ObjectEditor />}
                        />
                        <Route path="objects/*" element={<ObjectEditor />} />
                        {!user.featureFlags.doNotShowNewSalesPage ? (
                          <Route path="sales" element={<SalesPage />} />
                        ) : (
                          <Route path="sales" element={<SalesPageMain />} />
                        )}

                        {user.featureFlags.showFlatTableSalesPage && (
                          <Route
                            path="sales-fast"
                            element={<FastSalesPage />}
                          />
                        )}
                        <Route
                          path="breakdown-editor"
                          element={<BreakdownEditor />}
                        />
                        <Route
                          path="model-comparison"
                          element={<ModelComparisonPage />}
                        />
                        <Route
                          path="model-comparison/objects/*"
                          element={<ObjectEditor />}
                        />
                        <Route path="customers/*" element={<Customers />} />
                        <Route path="monitor/*" element={<Monitor />} />
                        <Route path="redirects/*" element={<Redirects />} />
                        <Route
                          path="onboarding"
                          element={
                            <Navigate
                              to={{
                                pathname: `/${userId}/settings/${hash.replace(
                                  "#",
                                  "",
                                )}`,
                              }}
                            />
                          }
                        />
                        {/* demo endpoint */}
                        <Route path="progress" element={<ProgressPage />} />

                        <Route path="/*" element={<Page404 />} />
                      </Routes>
                    </div>
                  </Navbar>
                </PreActivationContent>
              </DndProvider>
            </AppVersionProvider>
          </CustomerBreakdownConfigProvider>
        </SalesBreakdownConfigsProvider>
      </ApolloProvider>
    </CookiesProvider>
  );
}
