import { GetSalesReportFast_me_salesReportTable_rows } from "@nb-api-graphql-generated/GetSalesReportFast";
import {
  Breakdown,
  CategoricalBreakdownConfig,
  DateInterval,
} from "@north-beam/nb-common";
import { TabView } from "../components/tab-table-section";
import { TableableResponseRowWithChildren } from "@pages/sales/tab-table-section/table/table-utils";
import {
  addToMetricPoint,
  descriptorToPath,
  makeMetricPoint,
  newMetricPoint,
  pathToDescriptor,
} from "@utils/metrics";
import { keyBy } from "lodash";
import { PLATFORM_NORTHBEAM } from "@utils/constants";

const getDataForDescriptor = (descriptorToData: any, descriptor: string) => {
  if (!descriptorToData[descriptor]) {
    descriptorToData[descriptor] = {
      current: newMetricPoint(),
      compare: null,
      name: "",
      link: "",
      platformId: null,
      extraDetails: [],
      cellAnnotations: {},
      description: [],
      children: [],
      parentId: null,
      key: "",
    };
  }
  return descriptorToData[descriptor];
};

export const preprocessForTable = (
  rows: GetSalesReportFast_me_salesReportTable_rows[],
  breakdownConfigs: CategoricalBreakdownConfig[],
  breakdowns: Breakdown[],
  dateRange: DateInterval,
  compareDateRange: DateInterval,
  searchValue: string,
  granularity: TabView,
): [TableableResponseRowWithChildren] => {
  const generateDescriptors = ({
    dimensions,
    objectId,
  }: GetSalesReportFast_me_salesReportTable_rows): string[] => {
    const paths: string[][] = [[]];
    for (const breakdown of breakdowns) {
      const dimension = dimensions[breakdown.key] || "";
      paths.push([...paths[paths.length - 1], dimension]);
    }

    paths.push([...paths[paths.length - 1], objectId]);

    return paths.map(pathToDescriptor);
  };

  const keyToConfig = keyBy(breakdownConfigs, "key");

  const descriptorToData: Record<string, TableableResponseRowWithChildren> = {};

  for (const row of rows) {
    const { metrics, forecastV1, dimensions, searchableStrings } = row;

    const rowPassesFilter = breakdowns.every((b) =>
      b.values.includes(dimensions[b.key] ?? ""),
    );
    if (!rowPassesFilter) {
      continue;
    }

    if (searchValue) {
      let searchable = "";
      if (granularity !== TabView.PLATFORM) {
        searchable = (Object.values(searchableStrings) as string[])
          .map((val) => val.toLowerCase())
          .join(" ");
      } else {
        searchable = row.dimensions[PLATFORM_NORTHBEAM]?.toLowerCase() ?? "";
      }

      if (!searchable.includes(searchValue.toLowerCase())) {
        continue;
      }
    }

    const descriptors = generateDescriptors(row);

    descriptors.forEach((descriptor, i) => {
      const descriptorData = getDataForDescriptor(descriptorToData, descriptor);
      if (i === 0) {
        descriptorData.name = "Grand Total";
      } else if (i < descriptors.length - 1) {
        const path = descriptorToPath(descriptor);

        const value = path[path.length - 1];
        const choices =
          keyToConfig[breakdowns[path.length - 1].key]?.choices || [];
        const name = choices.find((v) => v.value === value)?.label ?? value;
        descriptorData.name = name + " (total blended)";
      } else {
        descriptorData.name = row.name;
        descriptorData.link = row.link;
        descriptorData.platformId = row.nbtPlatformID || null;
        descriptorData.extraDetails = row.extraDetails ?? [];
        descriptorData.cellAnnotations = row.cellAnnotations ?? {};
      }
      descriptorData.id = descriptor;
      descriptorData.key = descriptor;
    });

    // metric stuff
    for (const descriptor of descriptors) {
      const path = descriptorToPath(descriptor);
      const description: { key: string; value: string }[] = [];
      for (let i = 0; i < path.length && i < breakdowns.length; ++i) {
        const breakdownKey = breakdowns[i].key;
        const choices = keyToConfig[breakdownKey]?.choices || [];
        const name = choices.find((v) => v.value === path[i])?.label ?? path[i];
        description.push({ key: breakdownKey, value: name });
      }

      let parentId: string | null = null;
      if (path.length > 0) {
        path.pop();
        parentId = pathToDescriptor(path);
      }

      const descriptorData = getDataForDescriptor(descriptorToData, descriptor);
      descriptorData.description = description;
      descriptorData.parentId = parentId;
      descriptorData.id = descriptor;
      const metricPoint = makeMetricPoint(metrics.current, forecastV1);
      addToMetricPoint(descriptorData.current, metricPoint);
    }
    if (metrics.comparison) {
      for (const descriptor of descriptors) {
        const data =
          getDataForDescriptor(descriptorToData, descriptor).compare ??
          newMetricPoint();
        const metricPoint = makeMetricPoint(metrics.comparison, null);
        addToMetricPoint(data, metricPoint);
        getDataForDescriptor(descriptorToData, descriptor).compare = data;
      }
    }
  }

  for (const descriptor in descriptorToData) {
    const path = descriptorToPath(descriptor);
    if (path.length === 0) continue;

    const parentPath = [...path];
    parentPath.pop();

    const parentDescriptor = pathToDescriptor(parentPath);
    descriptorToData[parentDescriptor].children.push(
      descriptorToData[descriptor],
    );
  }

  return [descriptorToData[pathToDescriptor([])]];
};
