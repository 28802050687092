import { Switch } from "@components/shared/switch";
import {
  useHasFeatureFlag,
  useUpdateUserFeatureFlag,
} from "@hooks/use-feature-flag";
import { logEvent } from "@utils/analytics";
import React from "react";

export const NewSalesExperienceSwitch = () => {
  const hasFeatureFlag = useHasFeatureFlag();
  const updateUserFeatureFlag = useUpdateUserFeatureFlag();

  const onNewSalesPageExperience = !hasFeatureFlag("doNotShowNewSalesPage");

  const toggleNewSalesPageExperience = async (value: boolean) => {
    if (value === false) {
      logEvent("Turn off new sales page experience");
    }

    await updateUserFeatureFlag("doNotShowNewSalesPage", !value);
    window.location.reload();
  };

  return (
    <div className="flex items-center">
      <div className="text-xs text-[#717680] mr-2 mr-">
        Switch to {onNewSalesPageExperience ? "old" : "new"} Sales page
        experience
      </div>
      <Switch
        size="small"
        value={onNewSalesPageExperience}
        onChange={toggleNewSalesPageExperience}
        id="feature-flag-tabs-and-flyout-switch"
      />
    </div>
  );
};
