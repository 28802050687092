import { PrimaryButton } from "@shared/buttons";
import React from "react";
import Modal from "react-modal";

export const CancelConfirmationModal = ({
  open,
  goBack,
  onConfirmCancel,
}: {
  open: boolean;
  goBack: () => void;
  onConfirmCancel: () => void;
}) => {
  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",

          transform: "translate(-50%, -50%)",
          borderRadius: "25px",
          width: "fit-content",
          height: "fit-content",
          padding: 0,
        },

        overlay: {
          zIndex: 2999,
          backgroundColor: "rgba(30, 30, 30, 0.47)",
        },
      }}
      contentLabel="Benchmark Settings Modal"
    >
      <div className="relative w-[30rem] h-[20rem] pt-10 pb-10 pl-20 pr-20">
        <div className="pb-5 font-bold text-center">Unsaved Changes</div>
        <div className="border"></div>
        <div className="text-[#717680] pt-5">
          <div className="pt-1 pb-1">
            Your changes will be lost if you proceed without saving.
          </div>
          <br />
          <div className="pt-1 pb-1">Are you sure you want to continue?</div>
        </div>
        <div className="absolute bottom-5 right-10">
          <span
            onClick={goBack}
            className="cursor-pointer btn-sm ml-2 py-2 px-3 my-2"
          >
            Back
          </span>
          <PrimaryButton
            className="btn-sm ml-2 py-2 px-3 my-2"
            onClick={onConfirmCancel}
          >
            Cancel Changes
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
