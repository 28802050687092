import React from "react";

export function OAuth2Handler() {
  React.useEffect(() => {
    if (window.location.hash && window.location.hash.substr(1)) {
      window.localStorage.setItem(
        LOCAL_STORAGE_KEY,
        window.location.hash.substr(1),
      );
    } else {
      let search = window.location.search;
      if (search.startsWith("?")) {
        search = search.substr(1);
      }
      window.localStorage.setItem(LOCAL_STORAGE_KEY, search);
    }
  }, []);

  return null;
}

const LOCAL_STORAGE_KEY = "__nb_oauth2_response_search";

export async function launchOAuthPopup(url: string) {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  const popup = window.open(url);

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const encoded = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (encoded) {
      if (popup) {
        popup.close();
      }
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return encoded;
    }
    await time(100);
  }
}

function time(milliseconds: number) {
  return new Promise<"TIMER">((resolve) =>
    window.setTimeout(() => resolve("TIMER"), milliseconds),
  );
}
