import React from "react";
import { Route, Routes } from "react-router-dom";
import { Page404 } from "@components/title-slide-view";
import { AccountMaintenanceItemsListPage } from "./account-maintenance-items-list-page";
import AlertConfigList from "./alert-config-list-page";
import { EditorPage } from "./edit-alert-config-page";
import { IncidentsListPage } from "./incidents-list-page";
import { ViewPage } from "./view-alert-config-page";

export const Monitor = () => (
  <Routes>
    <Route index element={<AlertConfigList />} />
    <Route path="incidents" element={<IncidentsListPage />} />
    <Route
      path="maintenance-items"
      element={<AccountMaintenanceItemsListPage />}
    />
    <Route path="new" element={<EditorPage />} />
    <Route path="edit/:id/*" element={<EditorPage />} />
    <Route path="view/:id/*" element={<ViewPage />} />
    <Route element={<Page404 />} />
  </Routes>
);
