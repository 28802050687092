import React from "react";
import { UtmGuidePlatformProps } from "..";
import { ConstructDestinationUrlComponent } from "./shared/construct-destination-url-component";

export function StackAdapt({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>You will need to add UTM parameters to all new and existing URLs.</p>
      <ol>
        <ConstructDestinationUrlComponent urlParamPairs={urlParamPairs} />
      </ol>
    </>
  );
}
