import { gql } from "@apollo/client";
import { useCachedQuery } from "@components/hooks";
import {
  GetOverviewPerformance,
  GetOverviewPerformanceVariables,
} from "@nb-api-graphql-generated/GetOverviewPerformance";
import { useNorthbeamQuery } from "@utils/hooks";

export function useAccrualPerformance(variables: any) {
  const { loading: performanceLoading, data: _performanceData } =
    useNorthbeamQuery<GetOverviewPerformance, GetOverviewPerformanceVariables>(
      GET_OVERVIEW_PERFORMANCE,
      { variables },
    );

  const performanceData = useCachedQuery(_performanceData);

  return { performanceLoading, performanceData };
}

const GET_OVERVIEW_PERFORMANCE = gql`
  query GetOverviewPerformance(
    $dateRange: JSONObject!
    $windowSizeDays: Int!
    $attributionModel: String!
    $breakdown: String!
    $filters: [JSONObject!]!
  ) {
    me {
      id
      overviewPage {
        performanceMetrics(
          attributionModel: $attributionModel
          dateRange: $dateRange
          filters: $filters
          windowSizeDays: $windowSizeDays
        ) {
          name
          groupName
          currentValue
          currentValueDescription
          isPositiveChangeGood
          format
          timeSeriesPointData
          timeSeriesPointLabels
          timeSeriesPointDateRanges
          timeSeriesDisplayMode
          windowSizeDays
        }
        performanceBreakdownTableRows(
          attributionModel: $attributionModel
          dateRange: $dateRange
          filters: $filters
          breakdown: $breakdown
        )
      }
    }
  }
`;
