import React from "react";
import { VirtualTable } from "@devexpress/dx-react-grid-bootstrap4";
import classNames from "classnames";
import {
  AD_DETAILS_COLUMN_NAME,
  DIMENSION_COLUMN_NAME,
} from "../../fixed-column-configs";

export const VirtualTableCell = (props: any) => {
  const style: any = {};
  if (props.column.name === DIMENSION_COLUMN_NAME) {
    style.left = `var(--${DIMENSION_COLUMN_NAME}-offset)`;
  } else if (props.column.name === AD_DETAILS_COLUMN_NAME) {
    style.left = `var(--${AD_DETAILS_COLUMN_NAME}-offset)`;
  }

  return (
    <VirtualTable.Cell
      {...props}
      className={classNames(props?.column?.name, props.className)}
      style={style}
    />
  );
};
