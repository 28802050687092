import { ContextMenu } from "@shared/context-menu";
import { logEvent } from "@utils/analytics";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const menuItemClasses =
  "block py-2 px-4 text-inherit no-underline font-normal hover:bg-cosmic-3";

export const HeaderHamburgerMenu = ({ onLogout }: { onLogout(): void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    if (!isOpen) {
      logEvent("Open Hamburger Menu");
    }
    setIsOpen((prevState) => !prevState);
  };

  return (
    <ContextMenu
      menuClassName="w-48"
      menuPosition="bottom-left"
      menuButton={
        <ContextMenu.Button
          className="fa-sharp fa-regular fa-bars cursor-pointer text-cosmic+2 hover:bg-cosmic-3 transform transition-colors ease-in ui-open:bg-cosmic-3 rounded-lg p-2"
          onClick={toggleDrawer}
        />
      }
    >
      <ContextMenu.Item
        className={menuItemClasses}
        as={Link}
        to="model-comparison"
        onClick={(e) => {
          toggleDrawer();
          logEvent("Open Model Comparison from Hamburger Menu");
        }}
      >
        Model comparison
      </ContextMenu.Item>
      <ContextMenu.Item
        className={menuItemClasses}
        as={Link}
        to="objects"
        onClick={(e) => {
          toggleDrawer();
          logEvent("Open Breakdown Labels from Hamburger Menu");
        }}
      >
        Breakdown labels
      </ContextMenu.Item>
      <ContextMenu.Item
        className={menuItemClasses}
        as={Link}
        to="rules"
        onClick={(e) => {
          toggleDrawer();
          logEvent("Open Automatic Label Rules from Hamburger Menu");
        }}
      >
        Automatic label rules
      </ContextMenu.Item>
      <ContextMenu.Item
        className={menuItemClasses}
        as={Link}
        to="guides"
        onClick={(e) => {
          toggleDrawer();
          logEvent("Open Guides from Hamburger Menu");
        }}
      >
        Guides
      </ContextMenu.Item>
      <ContextMenu.Item
        className={menuItemClasses}
        as={Link}
        to="customers/documents"
        onClick={(e) => {
          toggleDrawer();
          logEvent("Open documents from Hamburger Menu");
        }}
      >
        Documents
      </ContextMenu.Item>
      <ContextMenu.Item
        className={menuItemClasses}
        as={Link}
        to="customers/paths"
        onClick={(e) => {
          toggleDrawer();
          logEvent("Open Customer Paths from Hamburger Menu");
        }}
      >
        Customer paths
      </ContextMenu.Item>
      <span className={menuItemClasses} onClick={onLogout}>
        Logout
      </span>
    </ContextMenu>
  );
};
