/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AlertConfigStatus {
  active = "active",
  archived = "archived",
  inactive = "inactive",
}

export enum AlertRunResultStatus {
  acked = "acked",
  flagged = "flagged",
  new = "new",
}

export enum AlertType {
  ad_fatigue = "ad_fatigue",
  anomaly_detection = "anomaly_detection",
  custom = "custom",
}

export enum AttemptToAddAccountManagerStatus {
  AlreadyManager = "AlreadyManager",
  HasError = "HasError",
  InvitationSent = "InvitationSent",
  SuccessfullyAdded = "SuccessfullyAdded",
}

export enum BenchmarkAttributionModelEnum {
  CLICKS_AND_VIEWS = "CLICKS_AND_VIEWS",
  CLICKS_ONLY = "CLICKS_ONLY",
}

export enum BenchmarkAttributionWindowEnum {
  NINETY_DAYS = "NINETY_DAYS",
  SIXTY_DAYS = "SIXTY_DAYS",
  THIRTY_DAYS = "THIRTY_DAYS",
}

export enum BenchmarkPlatformEnum {
  adwords = "adwords",
  facebook = "facebook",
  youtube = "youtube",
}

export enum BenchmarkTypeEnum {
  CAC = "CAC",
  ROAS = "ROAS",
}

export enum ConnectionType {
  integration_amazon = "integration_amazon",
  integration_amazon_seller = "integration_amazon_seller",
  integration_facebook_ads = "integration_facebook_ads",
  integration_google_adwords = "integration_google_adwords",
  integration_google_analytics = "integration_google_analytics",
  integration_klaviyo = "integration_klaviyo",
  integration_linkedin = "integration_linkedin",
  integration_microsoft = "integration_microsoft",
  integration_pinterest = "integration_pinterest",
  integration_refersion = "integration_refersion",
  integration_shopify = "integration_shopify",
  integration_snapchat = "integration_snapchat",
  integration_tiktok = "integration_tiktok",
}

export enum CustomerPathReportSorting {
  COUNT = "COUNT",
  REVENUE = "REVENUE",
  TRANSACTIONS = "TRANSACTIONS",
}

export enum DataExportFormat {
  CSV = "CSV",
}

export enum DataExportFrequency {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export enum DataExportType {
  ONE_OFF = "ONE_OFF",
  SCHEDULED = "SCHEDULED",
}

export enum MetricFormat {
  decimal = "decimal",
  dollars = "dollars",
  integer = "integer",
  multiplier = "multiplier",
  percentage = "percentage",
}

export enum OrdersSortingField {
  date = "date",
  numberOfOriginalItems = "numberOfOriginalItems",
  numberOfTouchpoints = "numberOfTouchpoints",
  orderValue = "orderValue",
}

export enum OrdersSortingOrder {
  asc = "asc",
  desc = "desc",
}

export enum PathDateRangeFilterType {
  ENDED_WITHIN_DATE_RANGE = "ENDED_WITHIN_DATE_RANGE",
  INTERSECTS_DATE_RANGE = "INTERSECTS_DATE_RANGE",
  STARTED_AND_ENDED_WITHIN_DATE_RANGE = "STARTED_AND_ENDED_WITHIN_DATE_RANGE",
  STARTED_WITHIN_DATE_RANGE = "STARTED_WITHIN_DATE_RANGE",
}

export enum SortDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum SubscriptionTypeEnum {
  FIRST_TIME = "FIRST_TIME",
  NONE = "NONE",
  OTHER = "OTHER",
  RETURNING = "RETURNING",
}

export enum ValueComparisonType {
  equalTo = "equalTo",
  greaterThan = "greaterThan",
  greaterThanOrEqualTo = "greaterThanOrEqualTo",
  lessThan = "lessThan",
  lessThanOrEqual = "lessThanOrEqual",
}

export interface BenchmarkTarget {
  attributionModel: BenchmarkAttributionModelEnum;
  attributionWindow: BenchmarkAttributionWindowEnum;
  benchmarkType: BenchmarkTypeEnum;
  customBenchmarks?: PlatformCACRoasBenchmarksInput[] | null;
  platform: BenchmarkPlatformEnum;
  target: number;
}

export interface CreateAlertConfig {
  alertType: AlertType;
  description?: string | null;
  incidentTemplates: any;
  name: string;
  notificationSettings: any;
  runFrequency?: string | null;
  triggerConditions: any;
}

export interface CustomerPathFilterStage {
  campaignLabelFilters?: any[] | null;
  individualCampaignFilters?: IndividualCampaignFilterElement[] | null;
}

export interface DataExportBreakdownInput {
  key: string;
  position: number;
  values: string[];
}

export interface DataExportPerformanceMetricInput {
  accountingModes: string[];
  attributionModels: string[];
  attributionWindows: string[];
  id: string;
  label: string;
  position: number;
}

export interface DataExportPerformanceMetricsConfigInput {
  breakdowns: DataExportBreakdownInput[];
  defaultAccountingModes: string[];
  defaultAttributionModels: string[];
  defaultAttributionWindows: string[];
  exportAggregation: string;
  exportType: DataExportType;
  formats: DataExportFormat[];
  frequency?: DataExportFrequency | null;
  level: string;
  metrics: DataExportPerformanceMetricInput[];
  recipients?: string[] | null;
  removeZeroSpend: boolean;
  scheduleTimeFrames?: DataExportScheduleTimeFrameInput[] | null;
  timeFrameEndDate?: string | null;
  timeFrameStartDate?: string | null;
  timeFrameType: string;
  timeGranularity: string;
}

export interface DataExportScheduleTimeFrameInput {
  day: string;
  time: string;
}

export interface DateRangeInput {
  end: any;
  start: any;
}

export interface IndividualCampaignFilterElement {
  adKey: any;
  name: string;
}

export interface LabelRuleV2Input {
  name: string;
  options: string[];
  originalName?: string | null;
}

export interface OrderTagSubscriptionAssociationInput {
  orderTag: string;
  subscriptionType: SubscriptionTypeEnum;
}

export interface OrdersFilterOptionsInput {
  adPlatforms?: string[] | null;
  adjustForReturns?: boolean | null;
  attributed?: string[] | null;
  customerTags?: string[] | null;
  dateRange: DateRangeInput;
  discountCodes?: string[] | null;
  ecommercePlatforms?: string[] | null;
  numberOfOriginalItems?: ValueIntComparisonInput | null;
  numberOfTouchpoints?: ValueIntComparisonInput | null;
  orderId?: string | null;
  orderTags?: string[] | null;
  orderTypes?: string[] | null;
  orderValue?: ValueFloatComparisonInput | null;
  products?: string[] | null;
  subscriptions?: SubscriptionTypeEnum[] | null;
}

export interface OrdersGraphFilterOptionsInput {
  adPlatforms?: string[] | null;
  adjustForReturns?: boolean | null;
  attributed?: string[] | null;
  customerTags?: string[] | null;
  discountCodes?: string[] | null;
  ecommercePlatforms?: string[] | null;
  numberOfOriginalItems?: ValueIntComparisonInput | null;
  numberOfTouchpoints?: ValueIntComparisonInput | null;
  orderId?: string | null;
  orderTags?: string[] | null;
  orderTypes?: string[] | null;
  orderValue?: ValueFloatComparisonInput | null;
  products?: string[] | null;
  subscriptions?: SubscriptionTypeEnum[] | null;
}

export interface OrdersSortingInput {
  sortingField: OrdersSortingField;
  sortingOrder: OrdersSortingOrder;
}

export interface PlatformBenchmarkTargetInput {
  attributionModels: BenchmarkAttributionModelEnum[];
  attributionWindow: BenchmarkAttributionWindowEnum;
  benchmarkType: BenchmarkTypeEnum;
  platform: BenchmarkPlatformEnum;
  target: number;
}

export interface PlatformBenchmarksInput {
  blended: number;
  firstTime: number;
  returning: number;
}

export interface PlatformCACRoasBenchmarksInput {
  attributionModel: BenchmarkAttributionModelEnum;
  benchmarkType: BenchmarkTypeEnum;
  cac: PlatformBenchmarksInput;
  platform: BenchmarkPlatformEnum;
  roas: PlatformBenchmarksInput;
  target: number;
}

export interface ValueFloatComparisonInput {
  comparisonOperator: ValueComparisonType;
  value: number;
}

export interface ValueIntComparisonInput {
  comparisonOperator: ValueComparisonType;
  value: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
