import { GetCampaign_me_adObject } from "@nb-api-graphql-generated/GetCampaign";
import { SalesReportRow } from "@north-beam/nb-common";
import { ReportBodyState } from "@pages/sales/report-body-control";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SUPPORTED_PLATFORMS } from "./flyout";
import { BidBudgetSection } from "./shared/bid-budget";
import { CommonTouchPoints } from "./shared/common-touch-points";
import { RawJsonSection } from "./shared/raw-json";
import { TargetingSettingsSection } from "./shared/targeting-settings";

interface CampaignDetailProps {
  state: ReportBodyState;
  adObject: GetCampaign_me_adObject;
  rows: SalesReportRow[];
  refetchData: (id: any) => void;
  setFlyoutWidth: (width: number) => void;
}

const tabClasses = "rounded-md text-center flex-center p-2 m-1 font-bold";
const activeTabClasses = "bg-[#E6F2F7]";
enum CampaignTab {
  DETAILS = "Details",
  COMMON_TOUCH_TOUCHPOINTS = "Common Click Touchpoints",
}

export const CampaignDetail = ({
  adObject,
  state,
  rows,
  refetchData,
  setFlyoutWidth,
}: CampaignDetailProps) => {
  let currentCampaignIndex = 0;
  const [tabView, setTabView] = useState<string>(CampaignTab.DETAILS);
  const campaignOptions = rows.map((row, index: number) => {
    const id = row.link.split("/")[1];
    if (id === adObject.id) {
      currentCampaignIndex = index;
    }
    return {
      value: id,
      label: `Campaign: ${row.name}`,
    };
  });

  useEffect(() => {
    if (tabView === CampaignTab.COMMON_TOUCH_TOUCHPOINTS) {
      setFlyoutWidth(Math.min(1500, window.innerWidth - 120));
    } else {
      setFlyoutWidth(500);
    }
  }, [setFlyoutWidth, tabView]);

  return (
    <>
      <Select
        options={campaignOptions}
        value={campaignOptions[currentCampaignIndex]}
        onChange={refetchData}
      />
      <ul
        data-test="campaign-tabs"
        className="flex my-4 py-2 w-[340px] cursor-pointer"
      >
        <li
          data-test="detail"
          onClick={() => {
            setTabView(CampaignTab.DETAILS);
          }}
          className={classNames(
            tabClasses,
            tabView === CampaignTab.DETAILS && activeTabClasses,
          )}
        >
          {CampaignTab.DETAILS}
        </li>
        <li
          data-test="touchpoints"
          onClick={() => {
            setTabView(CampaignTab.COMMON_TOUCH_TOUCHPOINTS);
          }}
          className={classNames(
            "cursor-pointer",
            tabClasses,
            tabView === CampaignTab.COMMON_TOUCH_TOUCHPOINTS &&
              activeTabClasses,
          )}
        >
          {CampaignTab.COMMON_TOUCH_TOUCHPOINTS}
        </li>
      </ul>
      {tabView === CampaignTab.DETAILS && (
        <>
          <BidBudgetSection statusLine={adObject?.statusLine} />
          {adObject.nbtPlatformID === "adwords" && (
            <TargetingSettingsSection adObject={adObject} />
          )}
          {!SUPPORTED_PLATFORMS.includes(adObject.nbtPlatformID) && (
            <RawJsonSection metaData={adObject.metadata} />
          )}
        </>
      )}
      {tabView === CampaignTab.COMMON_TOUCH_TOUCHPOINTS && (
        <>
          <CommonTouchPoints adObject={adObject} state={state} />
        </>
      )}
    </>
  );
};
