import { useEffect, useState } from "react";

export const useRedirectURL = ({
  collectorSubdomain,
  domainName,
  displayURL,
  encodeDisplayURL = true,
  displayId,
  platform,
  property,
}: {
  collectorSubdomain: string | null | undefined;
  domainName: string | null | undefined;
  displayURL: string | null | undefined;
  encodeDisplayURL?: boolean;
  displayId: string | null | undefined;
  platform: string | null | undefined;
  property?: string | null | undefined;
}): string | null => {
  const [redirectURL, setRedirectURL] = useState<string | null>(null);

  useEffect(() => {
    if (
      !collectorSubdomain ||
      !domainName ||
      !displayURL ||
      !displayId ||
      !platform
    ) {
      setRedirectURL(null);
      return;
    }

    let queryString = new URLSearchParams({
      ...(encodeDisplayURL && { u: displayURL }),
      aid: displayId,
      e: "se",
      se_ca: "northbeam",
      se_ac: "redirect",
      se_la: platform,
    }).toString();

    if (!encodeDisplayURL) {
      queryString += `&u=${displayURL}`;
    }

    if (property) {
      queryString += `&se_pr=${property}`;
    }

    // NOTE: the redirect URL must comply with these standards:
    // https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/snowplow-tracker-protocol/#:~:text=Custom%20structured%20event%20tracking
    setRedirectURL(
      `https://${collectorSubdomain}.${domainName}/r/tp2?${queryString}`,
    );
  }, [
    collectorSubdomain,
    domainName,
    displayURL,
    encodeDisplayURL,
    displayId,
    platform,
    property,
  ]);

  return redirectURL;
};
