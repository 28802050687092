import { useUser } from "@components/user-context";
import { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";

export const useUserBasedSetting = <T>(name: string, initialValue: T) => {
  const { user } = useUser();

  const [data, setter] = useLocalStorage(`${name}-${user.displayUserId}`, {
    setting: initialValue,
    displayUserId: user.displayUserId,
  });

  const easySetter = useCallback(
    (value: T) => {
      setter({ setting: value, displayUserId: user.displayUserId });
    },
    [setter, user.displayUserId],
  );

  return [data.setting, easySetter] as const;
};
