import React from "react";
import { TertiaryButton } from "@shared/buttons";
import { TextAreaInput } from "@shared/textarea-input";
import { useForm } from "react-hook-form";
import { AnnotationMetadata } from "../annotation-list";
import { AnnotationDatePicker } from "./annotation-date-picker";
import { AnnotationFormControl } from "./annotation-form-control";
import { useAnnotations } from "../hooks/use-annotations";
import { useAnnotationParam } from "../hooks/use-annotation-param";
import { maxCharacterLength, notEmpty } from "@shared/validators";
import { Annotation, NewAnnotation } from "@features/custom-annotations";
import { usePrettyLogEventPage } from "@utils/analytics";

interface AnnotationFormProps {
  currentAnnotation: Annotation | NewAnnotation;
  clearCurrentAnnotation: () => void;
}

export const AnnotationForm = ({
  currentAnnotation,
  clearCurrentAnnotation,
}: AnnotationFormProps) => {
  const { createAnnotation, updateAnnotation } = useAnnotations();
  const { annotationParam } = useAnnotationParam();
  const prettyLogEventPage = usePrettyLogEventPage();
  const isEdit = "id" in currentAnnotation;
  const title = isEdit ? "Edit Note" : "New Note";

  let annotatedDate = currentAnnotation.annotatedDate;
  const isSameDate =
    annotationParam && annotationParam?.startDate === annotationParam?.endDate;
  if (!isEdit && isSameDate) {
    annotatedDate = annotationParam.startDate;
  }
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<NewAnnotation>({
    defaultValues: {
      ...currentAnnotation,
      annotatedDate,
    },
  });

  const onSubmit = async (data: NewAnnotation) => {
    const { description, annotatedDate } = data;
    if ("id" in currentAnnotation) {
      await updateAnnotation(currentAnnotation.id, description);
      prettyLogEventPage({
        domain: "Annotations",
        action: "onSubmit",
        additionalActionInfo: "customer edits an annotation",
        data: {
          id: currentAnnotation.id,
          annotatedDate,
        },
      });
    } else {
      await createAnnotation(description, annotatedDate);
      prettyLogEventPage({
        domain: "Annotations",
        action: "onSubmit",
        additionalActionInfo: "customer creates a new annotation",
        data: { annotatedDate },
      });
    }
    clearCurrentAnnotation();
  };

  return (
    <div>
      <div className="h-14 flex items-center">
        <TertiaryButton className="px-0" onClick={clearCurrentAnnotation}>
          Back to Notes
        </TertiaryButton>
      </div>
      <h2 className="font-bold text-2xl">{title}</h2>
      <form
        className="flex flex-col mt-5 mb-1"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="annotation-form"
      >
        <AnnotationDatePicker
          currentAnnotation={currentAnnotation}
          control={control}
          errors={errors}
        />
        <TextAreaInput
          {...register("description", {
            required: "Description is required",
            validate: {
              notEmpty: notEmpty(),
              maxCharacterLength: maxCharacterLength({ maxLength: 300 }),
            },
          })}
          defaultValue={currentAnnotation.description}
          errors={errors}
          label="Note"
          placeholder="Type your note here"
        />
        {"id" in currentAnnotation && (
          <AnnotationMetadata
            updatedAt={currentAnnotation.updatedAt}
            user={currentAnnotation.user}
          />
        )}
        <AnnotationFormControl
          className="mt-1"
          isSubmitting={isSubmitting}
          currentAnnotation={currentAnnotation}
          clearCurrentAnnotation={clearCurrentAnnotation}
        />
      </form>
    </div>
  );
};
