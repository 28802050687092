import loadingScreen from "@assets/loading-screen-no-text.gif";
import { PrimaryButton, TertiaryButton } from "@shared/buttons";
import { Modal } from "@shared/modals";
import { notifyError, notifySuccess } from "@shared/notify";
import { logEvent } from "@utils/analytics";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { SalesView, useSalesViews } from "../../sales-views-context";
import { MAX_SAVED_VIEWS } from "../saved-views";
import { SalesViewRow } from "./sales-view-row";

interface ManageSalesViewModalProps {
  isOpen: boolean;
  close: () => void;
}

export const ManageSalesViewModal = ({
  isOpen,
  close,
}: ManageSalesViewModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { salesViews, deleteSalesView, upsertSalesViewsOrdering } =
    useSalesViews();

  const [salesViewsCopy, setSalesViewsCopy] = useState(salesViews);
  useEffect(() => {
    setSalesViewsCopy((prevSalesViews) => {
      if (isEqual(prevSalesViews, salesViews)) {
        return prevSalesViews;
      }

      return salesViews;
    });
  }, [setSalesViewsCopy, salesViews]);

  const moveView = useCallback((dragIndex: number, hoverIndex: number) => {
    setSalesViewsCopy((prevSalesViews) => {
      const prevCardsCopy = [...prevSalesViews];
      prevCardsCopy.splice(
        hoverIndex,
        0,
        prevCardsCopy.splice(dragIndex, 1)[0],
      );
      return prevCardsCopy;
    });
  }, []);

  const saveSalesViewsOrdering = async () => {
    if (salesViews?.[0].id !== salesViewsCopy?.[0].id) {
      logEvent(
        `Saved Views: User changed the default saved view (${salesViewsCopy[0].name})`,
      );
    }
    try {
      setIsLoading(true);
      const ids = salesViewsCopy.map((view) => view.id);
      await upsertSalesViewsOrdering(ids);
      close();
      notifySuccess({ message: "Saved views order successfully" });
    } catch (error) {
      console.error(error);
      notifyError({ message: "Failed to save views order" });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteView = (salesView: SalesView) => async () => {
    logEvent(`Saved Views: User deleted a saved view (${salesView.name})`);
    try {
      setIsLoading(true);
      await deleteSalesView(salesView.id);
      notifySuccess({ message: "Saved view deleted successfully" });
    } catch (error) {
      console.error(error);
      notifyError({ message: "Failed to delete saved view" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Manage saved views"
      description="Saved views are accessible by all users on your Northbeam account. Share, rename, or arrange the order these views appear in the menu. The view sorted to the top will become the default view."
      isOpen={isOpen}
    >
      <div className="max-h-[440px] overflow-y-auto mb-6 border border-[#DADDDE] p-2 rounded-md flex flex-col gap-1.5">
        {salesViewsCopy.map((view, index) => (
          <SalesViewRow
            key={view.id}
            salesView={view}
            index={index}
            moveView={moveView}
            deleteView={deleteView(view)}
          />
        ))}
      </div>
      {isLoading && (
        <div className="absolute flex-center h-full w-full top-0 left-0 bg-[rgba(200,200,200,0.5)]">
          <img
            src={loadingScreen}
            alt="Loading screen gif"
            className="w-3/4 max-w-xl"
          />
        </div>
      )}
      <div className="absolute text-xs font-medium opacity-90 text-subtitle right-0 bottom-[60px] mr-8">
        {salesViewsCopy.length}/{MAX_SAVED_VIEWS}
      </div>
      <div className="flex space-x-4">
        <PrimaryButton isDisabled={isLoading} onClick={saveSalesViewsOrdering}>
          Save
        </PrimaryButton>
        <TertiaryButton isDisabled={isLoading} onClick={close}>
          Cancel
        </TertiaryButton>
      </div>
    </Modal>
  );
};
