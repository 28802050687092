import { gql } from "@apollo/client";
import { FetchIntegrationOptionsQuery } from "@nb-api-graphql-generated/FetchIntegrationOptionsQuery";
import {
  UpdateIntegrations,
  UpdateIntegrationsVariables,
} from "@nb-api-graphql-generated/UpdateIntegrations";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import React, { FC, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { StepWizardChildProps } from "react-step-wizard";
import { ONE_CLICK_PLATFORM_INTEGRATIONS } from ".";
import { StepContainer } from "../step-container";

interface ConnectionsCollectionStepProps {
  toggleIntegrations: (arg0: string) => void;
  integrations: string[];
  outerPreviousStep: () => void;
  outerNextStep: () => void;
}

export const ConnectionsCollectionStep: FC<
  Partial<StepWizardChildProps> & ConnectionsCollectionStepProps
> = ({
  goToNamedStep,
  toggleIntegrations,
  integrations,
  outerPreviousStep,
  outerNextStep,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<string[]>([]);
  const [additionalIntegrations, setAdditonalIntegrations] = useState<string[]>(
    integrations.filter(
      (platform) =>
        !Object.keys(ONE_CLICK_PLATFORM_INTEGRATIONS).includes(platform),
    ),
  );

  const { data, startPolling, stopPolling } =
    useNorthbeamQuery<FetchIntegrationOptionsQuery>(FETCH_INTEGRATION_OPTIONS, {
      variables: { inputValue },
    });

  useEffect(() => {
    if (data?.me?.fetchIntegrationOptions) {
      setOptions(data.me.fetchIntegrationOptions);
    }
  }, [data?.me?.fetchIntegrationOptions]);

  const [updateIntegrations] = useNorthbeamMutation<
    UpdateIntegrations,
    UpdateIntegrationsVariables
  >(UPDATE_INTEGRATIONS);

  return (
    <StepContainer
      onMoveBackwardButtonClicked={outerPreviousStep}
      onMoveForwardButtonClicked={() => {
        updateIntegrations({
          variables: {
            additionalIntegrations,
            integrations,
          },
        });

        const connectablePlatforms = integrations.filter((platform) =>
          Object.keys(ONE_CLICK_PLATFORM_INTEGRATIONS).includes(platform),
        );
        if (connectablePlatforms.length > 0 && goToNamedStep) {
          goToNamedStep(connectablePlatforms[0]);
        } else {
          outerNextStep && outerNextStep();
        }
      }}
      onSkipLinkClicked={outerNextStep}
      stepByStepLink="https://scribehow.com/shared/Integrating_Connections__2oHACCgeRAS5IGKpqrvYlQ"
      videoDemoLink="https://drive.google.com/file/d/1f47LoMKQLozQ6eaB0iTF9-wNR11v4H05/view?usp=sharing"
    >
      <h3>Integrations</h3>
      <hr />
      <h5 className="secondary-font-purple">
        Select the services you use and click “Continue”
      </h5>
      <p>
        Setting up each integration is necessary to properly track each
        platform&apos;s data.
      </p>
      <>
        {Object.keys(ONE_CLICK_PLATFORM_INTEGRATIONS)
          .sort()
          .map((name: string) => (
            <div className="form-check col-8 mb-3" key={name}>
              <input
                className="form-check-input align-middle position-relative mr-1"
                type="checkbox"
                checked={integrations.includes(name)}
                onChange={() => toggleIntegrations(name)}
              />
              <label className="form-check-label">
                <img
                  src={
                    ONE_CLICK_PLATFORM_INTEGRATIONS[
                      name as keyof typeof ONE_CLICK_PLATFORM_INTEGRATIONS
                    ]
                  }
                  className="mx-2 float-left"
                  alt="Platform logo"
                  style={{
                    height: "1.5rem",
                    width: "auto",
                  }}
                />
                {name}
              </label>
            </div>
          ))}
      </>
      <div className="row col-8 mb-3">
        <div className="py-2 my-2">
          <div className="control-label" style={{ width: "500px" }}></div>
          <CreatableSelect
            isMulti
            isClearable
            placeholder="Input any additional services your brand uses..."
            formatCreateLabel={(value) => value}
            options={options.map((option) => ({
              value: option,
              label: option,
            }))}
            onInputChange={(newValue) => {
              if (newValue !== "") {
                startPolling(100);
                setInputValue(newValue);
              } else {
                stopPolling();
              }
            }}
            filterOption={() => true}
            onChange={(objects) => {
              objects = objects as { label: string; value: string }[];
              if (objects) {
                setAdditonalIntegrations(objects.map((object) => object.value));
              }
            }}
            defaultValue={
              additionalIntegrations.map((integration) => ({
                value: integration,
                label: integration,
              })) || []
            }
            menuPlacement="top"
            allowCreateWhileLoading={false}
          />
        </div>
      </div>
    </StepContainer>
  );
};

const FETCH_INTEGRATION_OPTIONS = gql`
  query FetchIntegrationOptionsQuery($inputValue: String!) {
    me {
      fetchIntegrationOptions(inputValue: $inputValue)
    }
  }
`;

const UPDATE_INTEGRATIONS = gql`
  mutation UpdateIntegrations(
    $additionalIntegrations: [String!]!
    $integrations: [String!]!
  ) {
    updateIntegrations(
      additionalIntegrations: $additionalIntegrations
      integrations: $integrations
    )
  }
`;
