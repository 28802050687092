import { gql } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { ClickableCopyIcon } from "@components/clickable-copy-icon";
import { GenericExternalLink } from "@components/generic-external-link";
import { FetchEnquireRealtimeDataSheetUrl } from "@nb-api-graphql-generated/FetchEnquireRealtimeDataSheetUrl";
import {
  UpdateEnquireRealtimeDataSheetUrl,
  UpdateEnquireRealtimeDataSheetUrlVariables,
} from "@nb-api-graphql-generated/UpdateEnquireRealtimeDataSheetUrl";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import { UPDATE_ENQUIRE_REALTIME_DATA_SHEET_URL } from "../queries";
import enquireLabsIntegration from "./images/enquireLabsIntegration.png";

export const CreateShopifyFlow: FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  isActive,
}) => {
  const { data, loading: loadingEnquireRealtimeDataSheetUrl } =
    useNorthbeamQuery<FetchEnquireRealtimeDataSheetUrl>(
      FETCH_ENQUIRE_REALTIME_DATA_SHEET_URL,
      {},
    );

  const [googleSheetUrl, setGoogleSheetUrl] = useState<string>("");

  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [uploadProcessing, setUploadProcessing] = useState<boolean>(false);

  useEffect(() => {
    setGoogleSheetUrl(data?.me?.enquireRealtimeDataSheetUrl || "");
    setCanContinue(!!data?.me?.enquireRealtimeDataSheetUrl);
  }, [data?.me?.enquireRealtimeDataSheetUrl]);

  const [updateEnquireRealtimeDataSheetUrl, { loading }] = useNorthbeamMutation<
    UpdateEnquireRealtimeDataSheetUrl,
    UpdateEnquireRealtimeDataSheetUrlVariables
  >(UPDATE_ENQUIRE_REALTIME_DATA_SHEET_URL);

  const saveGoogleSheet = async () => {
    setUploadProcessing(true);
    await updateEnquireRealtimeDataSheetUrl({
      variables: {
        enquireRealtimeDataSheetUrl: googleSheetUrl,
      },
    });
    setCanContinue(true);
    setUploadProcessing(false);
  };

  const email = "nb-offline-airflow@north-beam-io.iam.gserviceaccount.com";

  return (
    <div className={(!isActive && "d-none") || ""}>
      {!loadingEnquireRealtimeDataSheetUrl && (
        <>
          <h3>
            <b>Step {currentStep}: Setup Shopify Flow</b>
          </h3>
          <p>
            You will need to be set up with Enquire Post-Purchase surveys to go
            through this flow. You can sign up for <b>EnquireLabs</b>{" "}
            <a href="https://enquirelabs.com/" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </p>
          <ol>
            <li>
              Make a copy of{" "}
              <GenericExternalLink href="https://docs.google.com/spreadsheets/d/1AVmxumzixu4Qdur6c3-o0v14WCvEWHyqo88VI1GwJJw/edit?usp=sharing">
                this Google Sheet
              </GenericExternalLink>
              , and be sure to share it with the same people. In particular,
              make sure it is shared (Viewer access) with:
              <p>
                <code>
                  {email} <ClickableCopyIcon text={email} />
                </code>
              </p>
            </li>
            <li>
              <b>Turn on</b> Shopify Flow on the EnquireLabs Integrations page.
              You may need to first <b>Update Permissions</b> and/or{" "}
              <b>Connect</b>.
            </li>
            <img
              className="img-fluid mb-4"
              src={enquireLabsIntegration}
              alt="EnquireLabs integrations page screencap"
            />
            <li>
              For each Shopify store for your brand, use the Google Sheet you
              created above and follow the{" "}
              <GenericExternalLink
                href="https://docs.enquirelabs.com/docs/add-responses-to-google-sheets-
using-shopify-flow"
              >
                instructions on EnquireLabs
              </GenericExternalLink>{" "}
              to create Shopify Flows. Please only use a single Google Sheet, no
              matter how many Flows you create! You can use the Sheet you
              created earlier for multiple Flows.
            </li>
            <li className="mt-3">
              Copy the URL for the Google Sheet you created and submit it here.
              <div className="mt-2">
                <input
                  type="text"
                  className="form-control"
                  value={googleSheetUrl}
                  onChange={(event) => setGoogleSheetUrl(event.target.value)}
                ></input>
              </div>
              {!uploadProcessing && (
                <button
                  disabled={!googleSheetUrl || loading}
                  className="btn btn-info mt-2"
                  onClick={saveGoogleSheet}
                >
                  {canContinue ? "Edit" : "Submit"} Google Sheet URL
                </button>
              )}
              {uploadProcessing && (
                <div
                  className="spinner-border"
                  role="status"
                  style={{ height: "1rem", width: "1rem" }}
                />
              )}
              {canContinue && !uploadProcessing && (
                <p style={{ color: "green" }}>
                  Looks good! You can continue to the next step.
                </p>
              )}
            </li>
          </ol>
          <p className="mt-5">
            When you are done setting up the Shopify flow, please continue to
            uploading your historical data.
          </p>
          <button
            className="btn btn-primary"
            disabled={!canContinue}
            onClick={nextStep}
          >
            Continue to uploading historical data
          </button>
        </>
      )}
    </div>
  );
};

export const FETCH_ENQUIRE_REALTIME_DATA_SHEET_URL = gql`
  query FetchEnquireRealtimeDataSheetUrl {
    me {
      enquireRealtimeDataSheetUrl
    }
  }
`;
