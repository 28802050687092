import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";

export function Revcontent({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        For each of the following parameter pairs, input them into{" "}
        <b>Revcontent</b> for each campaign in the <b>UTM tracking</b> section.
        For more information, you can look at <b>Revcontent</b>&apos;s{" "}
        <GenericExternalLink href="https://help.revcontent.com/knowledge/campaign-tracking-variables">
          documentation
        </GenericExternalLink>
        .
      </p>
      <table className="table table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {urlParamPairs.map(({ key, value }) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
