import { SalesPageMetricSpec } from "@utils/metrics";
import { IMCAttributionWindowDays, INBAccountingMode } from "@utils/constants";

import { DataExportSalesMetric } from "./types";
import { ColumnConfig } from "@shared/metric-customization-modal/metric-category-builder";

export function createDataExportSalesMetric(
  availableAttributionModels: string[],
  spec: SalesPageMetricSpec,
  attributionModels: Set<string>,
  attributionWindows: Set<IMCAttributionWindowDays>,
  accountingModes: Set<INBAccountingMode>,
): DataExportSalesMetric {
  const allowedAttributionModels = new Set<string>();

  if (spec.modifiers.attributionModel === "all") {
    attributionModels.forEach((e) => allowedAttributionModels.add(e));

    if (allowedAttributionModels.size === 0) {
      allowedAttributionModels.add(availableAttributionModels[0]);
    }
  } else if (spec.modifiers.attributionModel !== "none") {
    allowedAttributionModels.add(spec.modifiers.attributionModel);
  }

  const allowedAttributionWindows = new Set<IMCAttributionWindowDays>();

  if (spec.modifiers.attributionWindows.length === 1) {
    allowedAttributionWindows.add(spec.modifiers.attributionWindows[0]);
  } else if (spec.modifiers.attributionWindows.length > 1) {
    spec.modifiers.attributionWindows
      .filter((e) => attributionWindows.has(e))
      .forEach((e) => allowedAttributionWindows.add(e));

    if (allowedAttributionWindows.size === 0) {
      allowedAttributionWindows.add(spec.modifiers.attributionWindows[0]);
    }
  }

  const allowedAccountingModes = new Set<INBAccountingMode>();

  if (spec.modifiers.accountingModes.length === 1) {
    allowedAccountingModes.add(spec.modifiers.accountingModes[0]);
  } else if (spec.modifiers.accountingModes.length > 1) {
    spec.modifiers.accountingModes
      .filter((e) => accountingModes.has(e))
      .forEach((e) => allowedAccountingModes.add(e));

    if (allowedAccountingModes.size === 0) {
      allowedAccountingModes.add(spec.modifiers.accountingModes[0]);
    }
  }

  return {
    id: spec.id,
    label: spec.name,
    attributionModels: allowedAttributionModels,
    attributionWindows: allowedAttributionWindows,
    accountingModes: allowedAccountingModes,
  };
}

export function createInitialMetrics(
  availableAttributionModels: string[],
  salesPageMetricSpecs: SalesPageMetricSpec[],
  columnConfigs: ColumnConfig[],
  initialAttributionModelIds: string[],
  initialAttributionWindowIds: IMCAttributionWindowDays[],
  initialAccountingModeIds: INBAccountingMode[],
) {
  const specsByName = new Map(salesPageMetricSpecs.map((e) => [e.name, e]));
  const metrics: DataExportSalesMetric[] = [];

  for (const config of columnConfigs) {
    if (!config.visible) {
      continue;
    }

    const spec = specsByName.get(config.name);

    if (spec) {
      metrics.push(
        createDataExportSalesMetric(
          availableAttributionModels,
          spec,
          new Set(initialAttributionModelIds),
          new Set(initialAttributionWindowIds),
          new Set(initialAccountingModeIds),
        ),
      );
    }
  }

  return metrics;
}
