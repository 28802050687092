import React from "react";
import BaseErrorPage from "./base-error-page";

export default function NoUserScreen() {
  return (
    <BaseErrorPage
      pageName="NoUserScreen"
      pageBody={
        <h4 className="mt-4 text-center">
          <b>
            We can&apos;t find this page. It&apos;s possible you&apos;re not
            logged in to the correct account.
          </b>
          <div className="mt-4">
            If this is an unexpected result, please contact us at your
            convenience.
          </div>
        </h4>
      }
    />
  );
}
