import { LoadingSlide } from "@components/title-slide-view";
import { PrimaryButton, SecondaryButton } from "@shared/buttons";
import { notifyError, notifySuccess } from "@shared/notify";
import { notifyLabelsCache } from "@shared/notify-labels-cache";
import { LogOnMount } from "@utils/analytics";
import { get, isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLabelRules } from "../use-label-rules";
import { LabelName } from "./label-name";
import { LabelOptions } from "./label-options";

export interface TrafficSource {
  name: string;
  originalName?: string;
  options: string[];
}

export interface LabelBuilderData {
  data: TrafficSource[];
}

interface LabelBuilderProps {
  simulate: (options: string[]) => void;
  setSimulatedTitle: (title: string) => void;
}

export const LabelBuilder = ({
  simulate,
  setSimulatedTitle,
}: LabelBuilderProps) => {
  const {
    labelRules,
    labelRulesLoading,
    deleteLabelRule,
    bulkUpsertLabelRules,
  } = useLabelRules();

  // Intermediary state to check for changes in labelRules and setting it to the form
  // Had to do it this way because the GQL and form hooks don't play well together
  const [, setInitializedLabelRules] = useState(labelRules);
  const [existingLabelNames, setExistingLabelNames] = useState<string[]>([]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    trigger,
    reset,
  } = useForm<LabelBuilderData>({
    defaultValues: { data: labelRules },
  });

  useEffect(() => {
    setInitializedLabelRules((currentLabelRules) => {
      if (!isEqual(currentLabelRules, labelRules)) {
        reset({ data: labelRules });
        return labelRules;
      }
      return currentLabelRules;
    });
  }, [labelRules, reset]);

  const {
    fields: labelList,
    append: appendLabel,
    remove: removeLabel,
    // TODO: Fix this react-hook-form issue, it blocks build when not using ts-ignore
    // eslint-disable-next-line
    // @ts-ignore
  } = useFieldArray({
    control,
    name: "data",
  });

  useEffect(() => {
    const subscription = watch((value) => {
      const data = get(value, "data");
      if (data) {
        setExistingLabelNames(
          data
            .filter((label) => label?.name)
            .map(({ name }: any) => name.trim()),
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const simulateTrafficLabelMatches = (categoryIndex: number) => {
    // Triggers validations in case there is no value
    trigger();
    const category = getValues(`data.${categoryIndex}`);
    if (category.options) {
      setSimulatedTitle(`${category.name}`);
      simulate(category.options);
    }
  };

  const updateTrafficSourceLabels = async ({ data }: LabelBuilderData) => {
    try {
      await bulkUpsertLabelRules({ labelRules: data });

      notifySuccess({
        message: "Breakdowns Saved",
      });

      notifyLabelsCache();
    } catch (error) {
      console.error(error);
      notifyError({
        message: "Breakdown Error",
        caption: "Saving error, please try again",
      });
    }
  };

  const deleteLabel = (categoryIndex: number) => async () => {
    try {
      const originalCategoryName = getValues(
        `data.${categoryIndex}.originalName`,
      );
      if (originalCategoryName) {
        await deleteLabelRule({ originalName: originalCategoryName });
      }
      removeLabel(categoryIndex);
      notifySuccess({
        message: "Breakdown Deleted",
        caption: "This breakdown no longer exists",
      });

      notifyLabelsCache();
    } catch (error) {
      console.error(error);
      notifyError({
        message: "Breakdown Error",
        caption: "Deleting error, please try again",
      });
    }
  };

  return (
    <div className=" p-4 overflow-y-auto relative">
      {labelRulesLoading && (
        <div className="absolute-center w-full h-full bg-[rgba(0,0,0,0.2)] z-10">
          <LoadingSlide />
        </div>
      )}

      <h3 className="font-semibold mb-3.5 -ml-1">Breakdown Setup</h3>
      <LogOnMount name="BreakdownLabelManager" />

      <form onSubmit={handleSubmit(updateTrafficSourceLabels)}>
        {labelList.map((trafficSourceLabel, categoryIndex) => (
          <div key={trafficSourceLabel.id} className="mb-2">
            <LabelName
              register={register}
              categoryIndex={categoryIndex}
              errors={errors}
              deleteLabel={deleteLabel(categoryIndex)}
              existingLabelNames={existingLabelNames}
            />
            <LabelOptions
              categoryIndex={categoryIndex}
              errors={errors}
              control={control}
              register={register}
              watch={watch}
              simulateTrafficLabelMatches={simulateTrafficLabelMatches}
            />
          </div>
        ))}

        <SecondaryButton onClick={() => appendLabel({ name: "", options: [] })}>
          + Add Breakdown
        </SecondaryButton>
        <PrimaryButton className="mt-5 block" type="submit">
          Save
        </PrimaryButton>
      </form>
    </div>
  );
};
