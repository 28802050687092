import React from "react";
import { AnnotationCard } from "./annotation-card";
import { useAnnotationParam } from "../hooks/use-annotation-param";
import { Annotation } from "@features/custom-annotations";
import { getSort, SortValue } from "./annotation-sort-select";
import { getDateOnly } from "@utils/datetime";

interface AnnotationsProps {
  annotations: Annotation[];
  currentSort: SortValue | null;
  editAnnotation: (annotation: Annotation) => void;
}

export const Annotations = ({
  annotations,
  currentSort,
  editAnnotation,
}: AnnotationsProps) => {
  const { filteredAnnotationDateRangeParam } = useAnnotationParam();

  const filteredAnnotations = annotations.filter((annotation) => {
    if (filteredAnnotationDateRangeParam) {
      const start = getDateOnly(filteredAnnotationDateRangeParam.startDate);
      const end = getDateOnly(filteredAnnotationDateRangeParam.endDate);
      const date = getDateOnly(annotation.annotatedDate);
      return start <= date && date <= end;
    }
    return true;
  });

  const sortedAnnotations = getSort(currentSort)(filteredAnnotations);

  return (
    <div
      className="mt-6 flex flex-col gap-4 overflow-y-auto h-[calc(100vh-350px)]"
      data-test="annotation-list"
    >
      {sortedAnnotations.length === 0 ? (
        <div className="self-center mt-4">No notes found</div>
      ) : (
        sortedAnnotations.map((annotation, index) => (
          <AnnotationCard
            key={index}
            editAnnotation={editAnnotation}
            annotation={annotation}
          />
        ))
      )}
    </div>
  );
};
