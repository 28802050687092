import { useCachedQuery, useValueCache } from "@components/hooks";
import { LoadingSlide } from "@components/title-slide-view";
import { CategoricalBreakdownConfig } from "@north-beam/nb-common";
import { todayLocalAsISODate } from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import { parseDateRangeArgument } from "@utils/index";
import React, { useEffect, useMemo } from "react";
import { AccrualPerformance } from "./accrual-performance";
import { CashSnapshot } from "./cash-snapshot";
import { useAccrualPerformance } from "./hooks/use-accrual-performance";
import { useCashSnapshot } from "./hooks/use-cash-snapshot";
import {
  ReportBodyControl,
  ReportBodyState,
  useReportBodyState,
} from "./report-body-control";

export interface FetchReportParams<ResponseType> {
  response: ResponseType;
  isLoading: boolean;
  bodyState: ReportBodyState;
  breakdown: string;
}

interface OverviewProps {
  breakdownConfigs: CategoricalBreakdownConfig[];
  reportEventName: string;
  onLoadingStatusChange?: (isLoading: boolean) => void;
}

export function Overview({
  breakdownConfigs,
  reportEventName,
  onLoadingStatusChange,
}: OverviewProps) {
  const {
    state,
    setFilters,
    setAttributionModel,
    setDateRange,
    setWindowSizeDays,
    setBreakdown,
  } = useReportBodyState(breakdownConfigs);
  const {
    dateRange: dr,
    windowSizeDays,
    attributionModel,
    breakdown,
    ...bodyRest
  } = state;
  const anchor = todayLocalAsISODate();
  const dateRange = parseDateRangeArgument(dr, anchor);

  const variables = useValueCache({
    dateRange,
    attributionModel,
    breakdown,
    windowSizeDays: Number(windowSizeDays),
    ...bodyRest,
  });
  const { snapshotLoading, snapshotData: _snapshotData } =
    useCashSnapshot(variables);

  const { performanceLoading, performanceData: _performanceData } =
    useAccrualPerformance(variables);

  const isLoading = useMemo(
    () => snapshotLoading || performanceLoading,
    [snapshotLoading, performanceLoading],
  );

  useEffect(() => {
    onLoadingStatusChange?.(isLoading);
  }, [isLoading, onLoadingStatusChange]);

  useEffect(() => {
    if (isLoading) {
      logEvent(reportEventName, {
        Breakdown: breakdown,
        Filters: state.filters.map((v) => v.key),
        AttributionModel: attributionModel,
      });
    }
  }, [reportEventName, isLoading, breakdown, state.filters, attributionModel]);

  const snapshotData = useCachedQuery(_snapshotData);
  const performanceData = useCachedQuery(_performanceData);

  if (!snapshotData?.me?.overviewPage || !performanceData?.me?.overviewPage) {
    return <LoadingSlide />;
  }

  return (
    <>
      <ReportBodyControl
        isLoading={isLoading}
        state={state}
        breakdownConfigs={breakdownConfigs}
        setFilters={setFilters}
        setAttributionModel={setAttributionModel}
        setDateRange={setDateRange}
        setWindowSizeDays={setWindowSizeDays}
        setBreakdown={setBreakdown}
      />
      <div className="px-4">
        <CashSnapshot
          isLoading={snapshotLoading}
          response={snapshotData.me.overviewPage}
          bodyState={state}
          breakdown={breakdown}
        />
        <div className="row border-top nb-divider" />
        <AccrualPerformance
          isLoading={performanceLoading}
          response={performanceData.me.overviewPage}
          bodyState={state}
          breakdown={breakdown}
        />
      </div>
    </>
  );
}
