import { gql } from "@apollo/client";
import { LoadingSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import {
  GetSubLevelBreakdown,
  GetSubLevelBreakdownVariables,
} from "@nb-api-graphql-generated/GetSubLevelBreakdown";
import {
  AdKey,
  deslugify,
  isCampaign,
  todayLocalAsISODate,
} from "@north-beam/nb-common";
import { TableSection } from "@pages/sales/table-section";
import { CommonGQLFragments } from "@utils/common-gql-fragments";
import {
  Granularity,
  IGranularity,
  IMCAttributionWindowDays,
} from "@utils/constants";
import { useNorthbeamQuery } from "@utils/hooks";
import { makeOverviewRows } from "@utils/metrics";
import React from "react";
import Select from "react-select";
import {
  ReportGQLVariables,
  ReportState,
  reportStateToGQLVariables,
} from "./report-state";

const GET_SUB_LEVEL_BREAKDOWN = gql`
  query GetSubLevelBreakdown(
    $id: ID!
    $dateRange: JSONObject!
    $compareDateRange: JSONObject!
    $attributionMethod: String!
    $attributionWindows: [String!]!
    $nbAccountingMode: String!
    $granularity: String!
  ) {
    me {
      id
      adObject(id: $id) {
        id
        subLevelBreakdown(
          dateRange: $dateRange
          compareDateRange: $compareDateRange
          attributionMethod: $attributionMethod
          attributionWindows: $attributionWindows
          nbAccountingMode: $nbAccountingMode
          granularity: $granularity
        ) {
          endDate
          startDate
          rows {
            ...SalesReportRowAll
          }
          hasV1Forecasts
          customGoals {
            id
            displayName
          }
        }
      }
    }
  }
  ${CommonGQLFragments.salesReportRowAll}
`;

interface SubLevelBreakdownPageProps {
  id: string;
  state: ReportState;
}

export function SubLevelBreakdownPage({
  id,
  state,
}: SubLevelBreakdownPageProps) {
  const anchor = todayLocalAsISODate();
  const gqlVariables = React.useMemo(() => {
    return reportStateToGQLVariables(state, anchor);
  }, [state, anchor]);

  const {
    dateRange,
    compareDateRange,
    attributionWindows,
    attributionMethod,
    nbAccountingMode,
  } = gqlVariables;

  const adKey = React.useMemo(() => {
    return deslugify(id);
  }, [id]);

  const granularityChoices = React.useMemo(() => {
    return getGranularityChoices(adKey);
  }, [adKey]);

  const [granularity, setGranularity] = React.useState(granularityChoices[0]);

  const { data, loading } = useNorthbeamQuery<
    GetSubLevelBreakdown,
    GetSubLevelBreakdownVariables
  >(GET_SUB_LEVEL_BREAKDOWN, {
    variables: {
      id,
      granularity,
      dateRange,
      compareDateRange,
      attributionWindows,
      attributionMethod,
      nbAccountingMode,
    },
  });

  let inner: React.ReactElement;

  if (loading) {
    inner = <LoadingSlide />;
  } else {
    const subLevelBreakdown = data?.me.adObject?.subLevelBreakdown;
    if (!subLevelBreakdown || subLevelBreakdown.rows.length === 0) {
      inner = <span>No data for this time range.</span>;
    } else {
      inner = (
        <Inner
          response={subLevelBreakdown}
          gqlVariables={gqlVariables}
          granularity={granularity}
        />
      );
    }
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col">
          Granularity:{" "}
          <Select
            isDisabled={loading}
            isClearable={false}
            value={Granularity.toReactSelectUnsafe(granularity)}
            options={Granularity.reactSelectOptions.filter((v) =>
              granularityChoices.includes(v.value),
            )}
            onChange={(v: any) => setGranularity(v.value)}
          />
        </div>
      </div>
      {inner}
    </>
  );
}

function Inner({
  response,
  granularity,
  gqlVariables,
}: {
  response: any;
  granularity: IGranularity;
  gqlVariables: ReportGQLVariables;
}) {
  const {
    attributionMethod,
    attributionWindows,
    compareDateRange,
    dateRange,
    nbAccountingMode,
    timeGranularity,
  } = gqlVariables;

  const { user } = useUser();

  const metricsArray = React.useMemo(
    () =>
      makeOverviewRows(
        attributionMethod,
        attributionWindows[0] as IMCAttributionWindowDays,
        false,
        nbAccountingMode,
        response.customGoals,
        timeGranularity as any,
        user,
      ),
    [
      response,
      attributionMethod,
      attributionWindows,
      nbAccountingMode,
      timeGranularity,
      user,
    ],
  );

  return (
    <TableSection
      rows={response.rows}
      breakdownConfigs={[]}
      dateRange={dateRange}
      compareDateRange={compareDateRange}
      breakdowns={[]}
      granularity={granularity}
      metricsArray={metricsArray}
    />
  );
}

function getGranularityChoices(adKey: AdKey): IGranularity[] {
  if (isCampaign(adKey)) {
    return ["adset", "ad"];
  }
  return ["ad"];
}
