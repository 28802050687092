import _ from "lodash";
import React from "react";
import { jsonHash } from "@north-beam/nb-common";

export function useDebouncer<A>(
  value: A,
  opts: {
    waitMillis: number;
  },
) {
  const [state, setState] = React.useState(value);
  const debouncedSetState = React.useMemo(
    () => _.debounce(setState, opts.waitMillis),
    [setState, opts],
  );
  React.useEffect(() => {
    debouncedSetState(value);
    return () => debouncedSetState.cancel();
  }, [debouncedSetState, value]);

  return state;
}

export function useCachedQuery<A>(value: A | undefined) {
  const [state, setState] = React.useState(value);

  React.useEffect(() => {
    if (value) {
      setState(value);
    }
  }, [value, setState]);

  return state;
}

export function useValueCache<A>(value: A) {
  const [state, setState] = React.useState(value);

  React.useEffect(() => {
    setState(value);
    // eslint-disable-next-line
  }, [jsonHash(value), setState]);

  return state;
}
