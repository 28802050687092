import React from "react";
import { Table } from "@north-beam/nb-common";
import { downloadCsv, ReportType } from "@utils/csv";

interface ExportToCsvProps {
  reportType: ReportType;
  fileName: string;
  data: Table;
}

export const ExportToCsv = ({
  reportType,
  fileName,
  data,
}: ExportToCsvProps) => (
  <button
    className="btn btn-outline-primary btn-sm"
    onClick={() => downloadCsv(reportType, fileName, data)}
  >
    Export to CSV
  </button>
);
