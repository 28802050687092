import React from "react";
import Select from "react-select";
import { useUser } from "@components/user-context";

interface AttributionModelSelectProps {
  className?: string;
  isLoading: boolean;
  attributionModel: string;
  onChange: (obj: any) => void;
}

export function AttributionModelSelect({
  className,
  isLoading,
  attributionModel,
  onChange,
}: AttributionModelSelectProps) {
  const { user } = useUser();
  const amEnum = user.attributionMethodEnum;
  return (
    <Select
      className={className}
      isDisabled={isLoading}
      isClearable={false}
      isSearchable={false}
      value={amEnum.toReactSelect(attributionModel)}
      options={amEnum.reactSelectOptions}
      onChange={onChange}
    />
  );
}
