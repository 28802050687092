import moment from "moment";
import { Breakdown } from "@north-beam/nb-common";
import {
  DateInterval,
  DateRange,
  dateRangeLength,
  FixedDateRange,
  isISODateFormat,
  MonthInterval,
  parseISODateFormat,
  shiftISODate,
  UnreachableCaseError,
  yesterdayLocalAsISODate,
} from "@north-beam/nb-common";
import { enumType } from "./enum-type";

export const PLATFORM_NORTHBEAM = "Platform (Northbeam)";

enum DateRangeChoice_ {
  monthToDate = "Month to date",
  today = "Today",
  yesterday = "Yesterday",
  yesterdayAndToday = "Yesterday and today",
  last3Days = "Last 3 days",
  lastMonth = "Last month",
  last7Days = "Last 7 days",
  last14Days = "Last 14 days",
  last28Days = "Last 28 days",
  last60Days = "Last 60 days",
}

export const DateRangeChoiceEnum = enumType({
  enumObject: DateRangeChoice_,
  keyOrder: [
    "today",
    "yesterday",
    "yesterdayAndToday",
    "last3Days",
    "last7Days",
    "last14Days",
    "last28Days",
    "last60Days",
    "monthToDate",
    "lastMonth",
  ],
});

export const DailyReactSelectChoices =
  DateRangeChoiceEnum.reactSelectOptions.filter(
    (v) =>
      !!(
        {
          today: true,
          yesterday: true,
          last3Days: true,
          last7Days: true,
          last14Days: true,
          last28Days: true,
          last60Days: true,
          monthToDate: true,
          lastMonth: true,
        } as any
      )[v.value],
  );

export const HourlyReactSelectChoices =
  DateRangeChoiceEnum.reactSelectOptions.filter(
    (v) =>
      !!(
        {
          today: true,
          yesterday: true,
          yesterdayAndToday: true,
          last3Days: true,
        } as any
      )[v.value],
  );

export type DateRangeChoiceV2 = keyof typeof DateRangeChoice_;

enum CompareDateRange_ {
  lastPeriod = "Last period",
  last3Days = "Last 3 days",
  last7Days = "Last 7 days",
  last14Days = "Last 14 Days",
  last28Days = "Last 28 Days",
  last60Days = "Last 60 Days",
}

export const CompareDateRangeEnum = enumType({
  enumObject: CompareDateRange_,
  keyOrder: [
    "lastPeriod",
    "last3Days",
    "last7Days",
    "last14Days",
    "last28Days",
    "last60Days",
  ],
});

export const HourlyCompareReactSelectChoices =
  CompareDateRangeEnum.reactSelectOptions.filter(
    (v) => v.value === "lastPeriod",
  );

export type ICompareDateRangeEnum = keyof typeof CompareDateRange_;

export function dateRangeChoiceToValue(
  dateRangeChoice: DateRangeChoiceV2,
): DateRange {
  if (dateRangeChoice === "today") {
    return {
      type: "relative",
      windowStartDays: 0,
      windowEndDays: 0,
    };
  } else if (dateRangeChoice === "yesterday") {
    return {
      type: "relative",
      windowStartDays: 1,
      windowEndDays: 1,
    };
  } else if (dateRangeChoice === "yesterdayAndToday") {
    return {
      type: "relative",
      windowStartDays: 0,
      windowEndDays: 1,
    };
  } else if (dateRangeChoice === "last3Days") {
    return {
      type: "relative",
      windowStartDays: 1,
      windowEndDays: 3,
    };
  } else if (dateRangeChoice === "last7Days") {
    return {
      type: "relative",
      windowStartDays: 1,
      windowEndDays: 7,
    };
  } else if (dateRangeChoice === "last14Days") {
    return {
      type: "relative",
      windowStartDays: 1,
      windowEndDays: 14,
    };
  } else if (dateRangeChoice === "last28Days") {
    return {
      type: "relative",
      windowStartDays: 1,
      windowEndDays: 28,
    };
  } else if (dateRangeChoice === "last60Days") {
    return {
      type: "relative",
      windowStartDays: 1,
      windowEndDays: 60,
    };
  } else if (dateRangeChoice === "monthToDate") {
    const anchor = yesterdayLocalAsISODate();
    const startOfMonth = anchor.substr(0, 8) + "01";
    return {
      type: "fixed",
      startDate: startOfMonth,
      endDate: anchor,
    };
  } else if (dateRangeChoice === "lastMonth") {
    const anchor = yesterdayLocalAsISODate();
    const lastMonthEnd = moment(anchor, "YYYY-MM-DD")
      .startOf("month")
      .subtract(1, "d")
      .format("YYYY-MM-DD");
    const lastMonthStart = moment(lastMonthEnd, "YYYY-MM-DD")
      .startOf("month")
      .format("YYYY-MM-DD");
    return {
      type: "fixed",
      startDate: lastMonthStart,
      endDate: lastMonthEnd,
    };
    // YYYY-MM-DD
  } else if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateRangeChoice)) {
    return {
      type: "fixed",
      startDate: dateRangeChoice,
      endDate: dateRangeChoice,
    };
  } else {
    throw new UnreachableCaseError(dateRangeChoice);
  }
}

export function parseDateRange(
  str: string | null,
): DateRangeChoiceV2 | FixedDateRange | null {
  if (!str) {
    return null;
  }

  try {
    const rv = JSON.parse(decodeURIComponent(str));
    if (typeof rv === "string") {
      return DateRangeChoiceEnum.fromString(rv);
    }

    if (typeof rv === "object") {
      if (!isFixedDateRange(rv)) {
        return null;
      }
      return rv as FixedDateRange;
    }

    return null;
  } catch (e) {
    return null;
  }
}

export function parseCompareDateRange(
  str: string | null,
): ICompareDateRangeEnum | FixedDateRange | null {
  if (!str) {
    return null;
  }

  try {
    const rv = JSON.parse(decodeURIComponent(str));
    if (typeof rv === "string") {
      return CompareDateRangeEnum.fromString(rv);
    }

    if (typeof rv === "object") {
      if (!isFixedDateRange(rv)) {
        return null;
      }
      return rv as FixedDateRange;
    }

    return null;
  } catch (e) {
    return null;
  }
}

export function resolveCompareDateRange(
  dateRange: DateInterval,
  compareDateRange: ICompareDateRangeEnum | FixedDateRange,
): DateInterval {
  if (isFixedDateRange(compareDateRange)) {
    const { startDate, endDate } = compareDateRange;
    return { startDate, endDate };
  }

  let daysToShift = 60;
  if (compareDateRange === "lastPeriod") {
    daysToShift = dateRangeLength(dateRange);
  } else if (compareDateRange === "last3Days") {
    daysToShift = 3;
  } else if (compareDateRange === "last7Days") {
    daysToShift = 7;
  } else if (compareDateRange === "last14Days") {
    daysToShift = 14;
  } else if (compareDateRange === "last28Days") {
    daysToShift = 28;
  } else if (compareDateRange === "last60Days") {
    daysToShift = 60;
  }

  const startDate = shiftISODate(dateRange.startDate, -daysToShift);
  const endDate = shiftISODate(dateRange.endDate, -daysToShift);

  return { startDate, endDate };
}

export function isFixedDateRange(date: any): date is FixedDateRange {
  if (!date) {
    return false;
  }
  const { type, startDate, endDate } = date;

  if (type !== "fixed") {
    return false;
  }

  if (typeof startDate !== "string" || !isISODateFormat(startDate)) {
    return false;
  }

  if (typeof endDate !== "string" || !isISODateFormat(endDate)) {
    return false;
  }

  const startDateMoment = parseISODateFormat(startDate);
  const endDateMoment = parseISODateFormat(endDate);

  if (startDateMoment.isAfter(endDateMoment)) {
    return false;
  }

  return true;
}

export function parseMonthInterval(arg: string | null): MonthInterval | null {
  if (!arg) {
    return null;
  }

  try {
    const rv = JSON.parse(decodeURIComponent(arg));
    if (!isMonthInterval(rv)) {
      return null;
    }
    return rv;
  } catch (e) {
    return null;
  }
}

function isMonthInterval(arg: any): arg is MonthInterval {
  if (!arg.startMonth || !isISOMonth(arg.startMonth)) {
    return false;
  }

  if (!arg.endMonth || !isISOMonth(arg.endMonth)) {
    return false;
  }

  if (arg.endMonth < arg.startMonth) {
    return false;
  }

  return true;
}

function isISOMonth(arg: string) {
  return moment(arg, "YYYY-MM", true).isValid();
}

export function parseBreakdownList(arg: string | null): Breakdown[] | null {
  if (!arg) {
    return null;
  }

  try {
    const rv = JSON.parse(arg);
    if (!isBreakdownList(rv)) {
      return null;
    }
    return rv;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function parseBreakdown(arg: string | null): Breakdown | null {
  if (!arg) {
    return null;
  }

  try {
    const rv = JSON.parse(arg);
    if (!isBreakdown(rv)) {
      return null;
    }
    return rv;
  } catch (e) {
    return null;
  }
}

function isBreakdown(element: any): element is Breakdown {
  if (typeof element !== "object") {
    return false;
  }

  if (typeof element.key !== "string") {
    return false;
  }

  if (!Array.isArray(element.values)) {
    return false;
  }

  return true;
}

function isBreakdownList(obj: any): obj is Breakdown[] {
  if (!Array.isArray(obj)) {
    return false;
  }

  for (const element of obj) {
    if (!isBreakdown(element)) {
      return false;
    }
  }

  return true;
}

export function parseInteger(value: string | null): number | null {
  if (value === null) {
    return null;
  }

  const rv = parseInt(value);
  if (isNaN(rv)) {
    return null;
  }

  return rv;
}

const MCAttributionWindowDaysEnum = {
  "1": "1 day",
  "3": "3 day",
  "7": "7 day",
  "14": "14 day",
  "30": "30 day",
  "60": "60 day",
  "90": "90 day",
  infinity: "Infinite",
} as const;

export const MCAttributionWindowDays = enumType({
  enumObject: MCAttributionWindowDaysEnum,
  keyOrder: ["infinity", "90", "60", "30", "14", "7", "3", "1"],
});

export type IMCAttributionWindowDays = keyof typeof MCAttributionWindowDaysEnum;

export const MCAttributionWindowDaysChoicesDaily =
  MCAttributionWindowDays.reactSelectOptions
    .slice()
    .filter((v) => v.value !== "infinity")
    .reverse();

export const MCAttributionWindowDaysChoicesHourly =
  MCAttributionWindowDays.reactSelectOptions
    .slice()
    .filter((v) => v.value === "1")
    .reverse();

export enum ColumnSetEnum {
  overview = "Overview",
  traffic = "Traffic",
  first_time_vs_returning = "First-time vs Returning",
}

export const ColumnSet = enumType({
  enumObject: ColumnSetEnum,
  keyOrder: ["overview", "traffic", "first_time_vs_returning"],
});

export type IColumnSet = keyof typeof ColumnSet.enum;

export const Granularity = enumType({
  enumObject: {
    platform: "Platform",
    campaign: "Campaign",
    adset: "Adset",
    ad: "Ad",
  } as const,
  keyOrder: ["platform", "campaign", "adset", "ad"],
});

export type IGranularity = keyof typeof Granularity.enum;

export const TimeGranularity = enumType({
  enumObject: {
    daily: "Daily",
    hourly: "Hourly",
  } as const,
  keyOrder: ["daily", "hourly"],
});

export type ITimeGranularity = keyof typeof TimeGranularity.enum;

export const NBAccountingMode = enumType({
  enumObject: {
    cash: "Cash snapshot",
    accrual: "Accrual performance",
  } as const,
  keyOrder: ["accrual", "cash"],
});

export type INBAccountingMode = keyof typeof NBAccountingMode.enum;
