import React, { createContext, useCallback, useContext } from "react";
import { atom, useAtom, createStore } from "jotai";
import { PropsWithChildren } from "react";
import { BenchmarksByPlatform } from "@pages/sales/benchmark-setting-section/custom-benchmarks-modal";
import { BenchmarkAttributionWindowEnum } from "@nb-api-graphql-generated/global-types";
import { Writable } from "ts-essentials";

export const usableBenchmarkWindowsAtom =
  atom<Set<BenchmarkAttributionWindowEnum> | null>(null);
export const useUsableBenchmarkWindowsAtom = () =>
  useAtom(usableBenchmarkWindowsAtom);

export type HasCompleteDatasetByAttrWindow = Writable<{
  [attributionWindow in keyof typeof BenchmarkAttributionWindowEnum]?: boolean;
}>;
export const hasCompleteDatasetByAttrWindowAtom =
  atom<HasCompleteDatasetByAttrWindow>({});
export const useHasCompleteDatasetByAttrWindowAtom = () =>
  useAtom(hasCompleteDatasetByAttrWindowAtom);

const BenchmarkingContext = createContext<ReturnType<
  typeof createStore
> | null>(null);
const useBenchmarkingContext = () => {
  const context = useContext(BenchmarkingContext);
  if (!context) {
    throw new Error("Benchmarking context not available");
  }
  return context;
};
export const BenchmarkModalProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const benchmarkStore = createStore();
  return (
    <BenchmarkingContext.Provider value={benchmarkStore}>
      {children}
    </BenchmarkingContext.Provider>
  );
};

export type BenchmarkModalNavigationRoute =
  | "choose-and-set"
  | "review-with-nb-benchmarks"
  | "review-with-custom-benchmarks"
  | "help-me";

const backStateAtom = atom<BenchmarkModalNavigationRoute | null>(null);
export const useBackRouteAtom = () => {
  const store = useBenchmarkingContext();
  return useAtom(backStateAtom, { store });
};

const benchmarkRouteAtom =
  atom<BenchmarkModalNavigationRoute>("choose-and-set");

const currentBenchmarkRouteAtom = atom(
  (get) => get(benchmarkRouteAtom),
  (get, set, newValue) => {
    const prevState = get(benchmarkRouteAtom);
    // set previous state to the back
    set(backStateAtom, prevState);
    set(benchmarkRouteAtom, newValue as BenchmarkModalNavigationRoute);
  },
);
export const useCurrentBenchmarkRouteAtom = () => {
  const store = useBenchmarkingContext();
  return useAtom(currentBenchmarkRouteAtom, { store });
};

export const useBack = () => {
  const [backRoute] = useBackRouteAtom();
  const [, navigateTo] = useCurrentBenchmarkRouteAtom();
  return useCallback(() => {
    backRoute && navigateTo(backRoute);
  }, [backRoute, navigateTo]);
};

const nbCalculatedBenchmarksByPlatformAtom = atom<BenchmarksByPlatform | null>(
  null,
);
export const useNBCalculatedBenchmarksByPlatform = () => {
  const store = useBenchmarkingContext();
  return useAtom(nbCalculatedBenchmarksByPlatformAtom, { store });
};

const customBenchmarksByPlatformAtom = atom<BenchmarksByPlatform | null>(null);
export const useCustomBenchmarksByPlatform = () => {
  const store = useBenchmarkingContext();
  return useAtom(customBenchmarksByPlatformAtom, { store });
};

const hasUnsavedChangesAtom = atom(false);
export const useHasUnsavedChangesAtom = () => {
  const store = useBenchmarkingContext();
  return useAtom(hasUnsavedChangesAtom, { store });
};
