import React from "react";

export default function DebugCodeBlock(props: {
  data: any;
  style?: React.CSSProperties;
}) {
  const { data, style } = props;
  const code = typeof data === "string" ? data : JSON.stringify(data, null, 2);
  return (
    <pre
      className="bg-dark text-white p-3"
      style={{
        height: "20rem",
        textAlign: "initial",
        overflow: "auto",
        overflowWrap: "normal",
        whiteSpace: "pre-wrap",
        ...style,
      }}
    >
      {code}
    </pre>
  );
}
