import {
  CompanionWrapper,
  ControlCenterWrapper,
  ScreenWithControlCenter,
} from "@components/reports/control-center-utilities";
import { LoadingSlide } from "@components/title-slide-view";
import { User, useUser } from "@components/user-context";
import { LightSwitch } from "@components/utilities";
import {
  GetAlertConfig,
  GetAlertConfigVariables,
} from "@nb-api-graphql-generated/GetAlertConfig";
import { AlertConfigStatus } from "@nb-api-graphql-generated/global-types";
import {
  ToggleAlertConfig,
  ToggleAlertConfigVariables,
} from "@nb-api-graphql-generated/ToggleAlertConfig";
import {
  UnexcludeSubjectItemFromAlert,
  UnexcludeSubjectItemFromAlertVariables,
} from "@nb-api-graphql-generated/UnexcludeSubjectItemFromAlert";
import {
  AlarmConditionV2,
  SimpleAlarmConditionV2,
  Subject,
  TriggerConditionsV2,
} from "@north-beam/nb-common";
import { formatDateFull, todayLocalAsISODate } from "@north-beam/nb-common";
import { flattenAlarmCondition } from "@north-beam/nb-common";
import {
  makeReportBodyStateQuery,
  ReportBodyState,
} from "@pages/sales/report-body-control";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import { resolvePath } from "@utils/index";
import _ from "lodash";
import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { AlertConfigState } from "./components/alert-config-editor";
import { ContentPane } from "./components/content-pane";
import {
  FormatAlarmCondition,
  FormatSubject,
} from "./components/humanize-fields";
import { formAttributionMethodFromMetricIds } from "./components/incident-views";
import {
  GET_ALERT_CONFIG,
  TOGGLE_ALERT_CONFIG,
  UNEXCLUDE_SUBJECT_ITEM_FROM_ALERT,
} from "./queries";

export function ViewPage() {
  const { id = "" } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const { user } = useUser();

  const today = todayLocalAsISODate();
  const variables = React.useMemo(() => ({ id, today }), [id, today]);

  const { data, loading: getAlertConfigLoading } = useNorthbeamQuery<
    GetAlertConfig,
    GetAlertConfigVariables
  >(GET_ALERT_CONFIG, { variables });

  const [toggleAlertConfig, { loading: toggleLoading }] = useNorthbeamMutation<
    ToggleAlertConfig,
    ToggleAlertConfigVariables
  >(TOGGLE_ALERT_CONFIG);

  const [unexcludeSubjectItem, { loading: unexcludeLoading }] =
    useNorthbeamMutation<
      UnexcludeSubjectItemFromAlert,
      UnexcludeSubjectItemFromAlertVariables
    >(UNEXCLUDE_SUBJECT_ITEM_FROM_ALERT);

  // eslint-disable-next-line
  const alert = data?.me.alertConfig!;

  if (getAlertConfigLoading) {
    return <LoadingSlide />;
  }

  const {
    status,
    name,
    excludedSubjectItems,
    notificationSettings,
    incidentTemplates,
    id: alertId,
  } = alert;
  const triggerConditions: TriggerConditionsV2 = alert.triggerConditions;
  const { subject, alarmCondition } = triggerConditions;
  const extraEmailRecipients = notificationSettings.extraEmailRecipients ?? [];
  const excludedEmailRecipients =
    notificationSettings.excludedEmailRecipients ?? [];

  const salesReportURL = constructSalesReportURL(user, subject, alarmCondition);

  const preview: AlertConfigState = {
    alarmCondition: alarmCondition as SimpleAlarmConditionV2,
    subject,
    extraEmailRecipients,
    excludedEmailRecipients,
    name,
    id: alertId,
    incidentTemplates,
  };

  const showLightSwitch = status === "active" || status === "inactive";
  let exclusionLink: React.ReactNode;

  if (excludedSubjectItems.length > 0) {
    exclusionLink = (
      <table className="table table-sm table-bordered" style={{ fontSize: 12 }}>
        <thead className="thead-light">
          <tr>
            <th>Item name</th>
            <th>Excluded until</th>
            <th>Undo</th>
          </tr>
        </thead>
        <tbody>
          {excludedSubjectItems.map((item) => (
            <tr key={item.subjectItem.id}>
              <td>
                <div
                  className="text-truncate"
                  style={{ maxWidth: 150 }}
                  title={item.subjectItem.name}
                >
                  {item.subjectItem.name}
                </div>
              </td>
              <td>{formatDateFull(item.excludedUntil)}</td>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    unexcludeSubjectItem({
                      variables: {
                        configId: alertId,
                        subjectItem: item.subjectItem.key,
                        today: todayLocalAsISODate(),
                      },
                    });
                  }}
                >
                  <i className="fas fa-undo"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    exclusionLink = <span>No excluded subject items</span>;
  }

  return (
    <ScreenWithControlCenter width={450}>
      <LogOnMount
        name="View Alert"
        data={{
          "Alert ID": alertId,
          Name: name,
        }}
      />
      <ControlCenterWrapper
        header={
          <h1>
            View Alert{" "}
            <small>
              <Link to={resolvePath(`${pathname}/../../edit/${id}`)}>
                (edit)
              </Link>
            </small>
          </h1>
        }
      >
        <div className="px-4 pb-3">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <div className="flex flex-row items-center">
                    <div>{name}</div>
                    <p className="flex-grow-1"></p>
                    {showLightSwitch && (
                      <LightSwitch
                        size="medium"
                        isSet={status === AlertConfigStatus.active}
                        onChange={() =>
                          toggleAlertConfig({
                            variables: {
                              id,
                              status:
                                status === AlertConfigStatus.active
                                  ? AlertConfigStatus.inactive
                                  : AlertConfigStatus.active,
                            },
                          })
                        }
                        disabled={toggleLoading || unexcludeLoading}
                        id="test"
                      />
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <p className="text-muted">Status</p>
                  <p>{_.capitalize(status)}</p>
                  <hr />

                  <p className="text-muted">Subjects</p>
                  <FormatSubject subject={subject} />
                  {salesReportURL && (
                    <Link to={salesReportURL}>View report</Link>
                  )}
                  <hr />

                  <p className="text-muted">Alarm Conditions</p>
                  <FormatAlarmCondition alarmCondition={alarmCondition} />
                  <hr />
                  <p className="text-muted">Excluded Subject Items</p>
                  {exclusionLink}
                  {extraEmailRecipients.length > 0 && (
                    <div>
                      <hr />
                      <p className="text-muted">Extra email recipients</p>
                      <ul>
                        {extraEmailRecipients.map((email: any) => (
                          <li key={email}>{email}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ControlCenterWrapper>
      <CompanionWrapper>
        <ContentPane preview={preview} mode={"view"} id={id} />
      </CompanionWrapper>
    </ScreenWithControlCenter>
  );
}

function constructSalesReportURL(
  user: User,
  subject: Subject,
  alarmCondition: AlarmConditionV2,
): string | null {
  let breakdowns: ReportBodyState["breakdowns"] = [];
  const dateRange: ReportBodyState["dateRange"] = "last7Days";

  const conds = flattenAlarmCondition(alarmCondition);
  const metricIds = conds.map((cond) => cond.metric.id);
  const attributionModel = formAttributionMethodFromMetricIds(metricIds);

  if (subject.type === "individuals") {
    return null;
  }

  if (subject.type === "groupedBy" || subject.type === "individualsMatching") {
    breakdowns = subject.param;
  }
  const search = makeReportBodyStateQuery({
    attributionModel,
    attributionWindow: "1",
    columnSet: "overview",
    breakdowns,
    dateRange,
    compareDateRange: "lastPeriod",
    granularity: "campaign",
    timeGranularity: "daily",
    nbAccountingMode: "accrual",
  });

  return user.pathFromRoot(`/sales?${search.toString()}`);
}
