import React from "react";
import { Link } from "react-router-dom";
import { UtmGuidePlatformProps } from "..";

export function Klaviyo({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        Once you&apos;ve Connected to{" "}
        <Link to="connections">Klaviyo&apos;s API</Link> you&apos;re ready to
        add Northbeam&apos;s UTM Parameters. For Klaviyo this happens in two
        distinct parts.
      </p>
      <ol>
        <li>Add UTMs for all new campaigns and flows.</li>
        <li>
          Add UTMs to historical Flows and automations that are still active.
        </li>
      </ol>
      <p>
        Completing the first part should take one minute. The second part may
        take you 10-15 minutes if you have lots of active Flows.
      </p>
      <div>
        <h3>
          <b>Part 1:</b>
        </h3>
        <ul>
          <li>Login to Klaviyo</li>
          <li>Click on Account</li>
          <li>Click on UTM Tracking</li>
          <li>
            Click <b>Add a custom UTM Parameters</b>
          </li>
          <li>Then fill in the following information for each field:</li>
          <ul>
            <li>
              <b>UTM Parameter</b>: <code>nb_klid</code> Please do NOT copy and
              paste that nb_klid slug, for some reason, that causes a bug.
              Manually type it out.
            </li>
            <li>
              <b>Campaign Email Value</b>: Klaviyo Profile ID
            </li>
            <li>
              <b>Flow Email Value</b>: Klaviyo Profile ID
            </li>
            <li>
              <b>
                Turn on &ldquo;Automatically add UTM to parameters to
                links.&rdquo;
              </b>
            </li>
            <li>
              <b>Click</b> &rdquo;Update Tracking Settings.&rdquo;
            </li>
          </ul>
        </ul>
        <iframe
          src="https://www.loom.com/embed/c011958e596d4f2191b3de7636b6bcdd?t=10"
          title="Klaviyo UTM tracking description - Part 1"
          width="100%"
          height="500"
          frameBorder="0"
          className="mt-2"
        />
      </div>
      <div className="mt-3">
        <h3>
          <b>Part 2:</b>
        </h3>
        <ul>
          <li>
            Inside of Klaviyo, navigate to your automation and click Edit Flow.
            Duplicate the existing Flow if necessary.
          </li>
          <li>
            Select your Email &#x2192; Click UTM Tracking &#x2192; Click
            Configure under UTM Tracking &#x2192; Toggle &ldquo;No&rdquo;
            &#x2192; Click &ldquo;Add a custom UTM parameter&rdquo; &#x2192;
            Type in <code>nb_klid</code> &#x2192; Value select Klaviyo profile.
          </li>
          <li>Click Save.</li>
          <li>Then repeat for all your emails in this Flow.</li>
          <li>Click &ldquo;Review and Turn On.&rdquo;</li>
          <li>Then repeat for all of your relevant Flows.</li>
        </ul>
        <iframe
          src="https://www.loom.com/embed/ac98f16bb584457fb17519303c3711a5"
          title="Klaviyo UTM tracking description - Part 2"
          width="100%"
          height="500"
          frameBorder="0"
          className="mt-2"
        />
      </div>
      <p className="mt-3">
        That&apos;s it! Any maintenance alert should resolve itself within 24
        hours. If not, please contact us.
      </p>
    </>
  );
}
