import React, { createContext, useContext } from "react";
import { atom, createStore, useAtom } from "jotai";
import { PropsWithChildren } from "react";

export const creativeAnalyticsScope = "creative-analytics";

const CreativeAnalyticsContext = createContext<ReturnType<
  typeof createStore
> | null>(null);
const useCreativeAnalyticsContext = () => {
  const context = useContext(CreativeAnalyticsContext);
  if (!context) {
    throw new Error("Creative analytics context not available");
  }
  return context;
};
export const CreativeAnalyticsProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const store = createStore();
  return (
    <CreativeAnalyticsContext.Provider value={store}>
      {children}
    </CreativeAnalyticsContext.Provider>
  );
};

export const activeCreativeTypeAtom = atom<string | null>(null);
export const useActiveCreativeTypeAtom = () => {
  const store = useCreativeAnalyticsContext();
  return useAtom(activeCreativeTypeAtom, { store });
};

const selectedMetricsAtom = atom(new Set<string>([]));
export const useSelectedMetricsAtom = () => {
  const store = useCreativeAnalyticsContext();
  return useAtom(selectedMetricsAtom, { store });
};

const graphTypeMetricsAtom = atom<"bar" | "line">("bar");
export const useGraphTypeAtom = () => {
  const store = useCreativeAnalyticsContext();
  return useAtom(graphTypeMetricsAtom, { store });
};

const savedViewsAtom = atom([
  { title: "Top Creative (ROAS)", isDefault: true, id: "1" },
  { title: "Top Creative (CAC)", isDefault: true, id: "2" },
  { title: "Saved view 2", isDefault: false, id: "3" },
]);
export const useSavedViewsAtom = () => {
  const store = useCreativeAnalyticsContext();
  return useAtom(savedViewsAtom, { store });
};
