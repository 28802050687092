import React, { FC } from "react";
import FaqWrapper from "react-faq-component";
import { StepWizardChildProps } from "react-step-wizard";
import { FetchSettingsDetails } from "@nb-api-graphql-generated/FetchSettingsDetails";
import { AccountManagerSection } from "@pages/settings/account-manager";
import { DataWrapper } from "@pages/settings/data-wrapper";
import { StepContainer } from "./step-container";

export const AccountManagerStep: FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
}) => {
  const styles = {
    bgColor: "white",
    rowContentTextSize: "16px",
    rowContentPaddingTop: "10px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "20px",
    rowContentPaddingRight: "20px",
    arrowColor: "black",
  };

  const config = {
    tabFocus: true,
  };

  const faqData = {
    rows: [
      {
        title: "What permissions do account managers have?",
        content:
          "Account managers are functionally admins. They will be able to add other managers, view all data, view and set connections, and perform every action that the account owner can perform.",
      },
      {
        title: "Who should I add as an account manager?",
        content:
          "We recommend adding any stakeholders who are interested in Northbeam data. This may include ad buyers/managers, executives, and agency partners.",
      },
    ],
  };
  return (
    <DataWrapper>
      {(data: FetchSettingsDetails) => {
        const { managerUsers } = data.me;

        return (
          <StepContainer
            onMoveBackwardButtonClicked={() => {
              previousStep && previousStep();
            }}
            onMoveForwardButtonClicked={() => {
              nextStep && nextStep();
            }}
            moveForwardButtonCopy="Verify & Finish"
          >
            <h3>Add users to your account</h3>
            <AccountManagerSection managerUsers={managerUsers} />
            <FaqWrapper data={faqData} styles={styles} config={config} />
          </StepContainer>
        );
      }}
    </DataWrapper>
  );
};
