import { gql } from "@apollo/client";

export const FETCH_DOCUMENTS = gql`
  query Documents {
    me {
      documents {
        isAdminOnly
        id
        name
        metadata {
          updatedAt
          createdAt
          contentType
        }
        link {
          authenticatedURL
          expiresAt
        }
      }
      documentContentTypes
    }
  }
`;

export const DELETE_DOCUMENTS = gql`
  mutation DeleteDocument($documentName: String!) {
    deleteDocument(name: $documentName)
  }
`;
