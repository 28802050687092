import { FormattedDateRange } from "@components/date-range-picker";
import { LoadingSlideNoText } from "@components/title-slide-view";
import { PrimaryButton, TertiaryButton } from "@shared/buttons";
import React, { useEffect, useState } from "react";
import { useAnnotationParam } from "../hooks/use-annotation-param";
import { useAnnotations } from "../hooks/use-annotations";
import { Annotation } from "@features/custom-annotations";
import {
  AnnotationSortSelect,
  INITIAL_SORT,
  SortValue,
} from "./annotation-sort-select";
import { AnnotationFilterNotification } from "./annotation-filter-notification";
import { Annotations } from "./annotations";

interface AnnotationListProps {
  newAnnotation: () => void;
  editAnnotation: (annotation: Annotation) => void;
}

export const AnnotationList = ({
  newAnnotation,
  editAnnotation,
}: AnnotationListProps) => {
  const { annotations, isAnnotationsLoading, fetchAnnotations } =
    useAnnotations();
  const { closeFlyout, annotationParam } = useAnnotationParam();
  const [currentSort, setCurrentSort] = useState<SortValue | null>(
    INITIAL_SORT,
  );

  useEffect(() => {
    if (annotationParam) {
      fetchAnnotations({ variables: annotationParam });
    }
  }, [annotationParam, fetchAnnotations]);

  return (
    <div>
      <div className="h-14 flex items-center">
        <TertiaryButton
          className="-ml-3"
          onClick={closeFlyout}
          data-testid="close-button"
        >
          Close
        </TertiaryButton>
      </div>
      <h2 className="font-bold text-2xl">Notes</h2>
      <p className="text-[#717680] text-sm">
        Displaying notes for{" "}
        {annotationParam && <FormattedDateRange dateRange={annotationParam} />}
      </p>
      <div className="flex justify-between items-end">
        <AnnotationSortSelect value={currentSort} onChange={setCurrentSort} />
        <PrimaryButton
          isDisabled={isAnnotationsLoading}
          onClick={newAnnotation}
          className="h-8"
        >
          New Note
        </PrimaryButton>
      </div>
      <AnnotationFilterNotification />
      {isAnnotationsLoading ? (
        <LoadingSlideNoText />
      ) : (
        <Annotations
          annotations={annotations}
          currentSort={currentSort}
          editAnnotation={editAnnotation}
        />
      )}
    </div>
  );
};
