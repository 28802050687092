import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { LoadingSlide, Page404 } from "@components/title-slide-view";
import { GetLabelRule } from "@nb-api-graphql-generated/GetLabelRule";
import { GetLabelRules } from "@nb-api-graphql-generated/GetLabelRules";
import {
  SetLabelRuleActiveState,
  SetLabelRuleActiveStateVariables,
} from "@nb-api-graphql-generated/SetLabelRuleActiveState";
import {
  SetLabelRuleNotificationSettings,
  SetLabelRuleNotificationSettingsVariables,
} from "@nb-api-graphql-generated/SetLabelRuleNotificationSettings";
import {
  UpdateLabelRule,
  UpdateLabelRuleVariables,
} from "@nb-api-graphql-generated/UpdateLabelRule";
import {
  LabelRule,
  LabelRuleNotificationSettings,
} from "@north-beam/nb-common";
import { notifyLabelsCache } from "@shared/notify-labels-cache";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import React from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { LabelRuleGroupEditor } from "./label-rule-group-editor";
import { LabelRuleGroupListing } from "./label-rule-group-listing";
import {
  GET_LABEL_RULE,
  GET_LABEL_RULES,
  SET_LABEL_RULE_ACTIVE_STATE,
  SET_LABEL_RULE_NOTIFICATION_SETTINGS,
  UPDATE_LABEL_RULE,
} from "./queries";

export const Rules = () => (
  <Routes>
    <Route index element={<LabelRuleGroupListingPage />} />
    <Route path="/new" element={<LabelRuleGroupEditorPage />} />
    <Route path="/:level/:labelKey" element={<LabelRuleGroupEditorPage />} />
    <Route path="/*" element={<Page404 />} />
  </Routes>
);

function LabelRuleGroupListingPage() {
  const { pathname } = useLocation();

  const { data, loading: labelRulesLoading } =
    useNorthbeamQuery<GetLabelRules>(GET_LABEL_RULES);

  const [setLabelRuleActiveState, { loading: activeStateLoading }] =
    useNorthbeamMutation<
      SetLabelRuleActiveState,
      SetLabelRuleActiveStateVariables
    >(SET_LABEL_RULE_ACTIVE_STATE);

  const [
    setLabelRuleNotificationSettings,
    { loading: notificationSettingsLoading },
  ] = useNorthbeamMutation<
    SetLabelRuleNotificationSettings,
    SetLabelRuleNotificationSettingsVariables
  >(SET_LABEL_RULE_NOTIFICATION_SETTINGS);

  const onRuleGroupActiveStatusChange = React.useCallback(
    async ({ level, labelKey }: any, newValue: boolean) => {
      await setLabelRuleActiveState({
        variables: {
          level,
          labelKey,
          activeState: newValue,
        },
      });

      notifyLabelsCache();
    },
    [setLabelRuleActiveState],
  );

  const onRuleGroupNotificationSettingsChange = React.useCallback(
    ({ level, labelKey }: any, newValue: LabelRuleNotificationSettings) => {
      setLabelRuleNotificationSettings({
        variables: {
          level,
          labelKey,
          notificationSettings: newValue,
        },
      });
    },
    [setLabelRuleNotificationSettings],
  );

  if (labelRulesLoading) {
    return <LoadingSlide />;
  }

  const labelRules = data?.me.labelRules ?? [];

  return (
    <LabelRuleGroupListing
      pagePrefix={pathname}
      newRulePageSuffix="new"
      ruleGroups={labelRules}
      anyLoading={activeStateLoading || notificationSettingsLoading}
      onRuleGroupActiveStatusChange={onRuleGroupActiveStatusChange}
      onRuleGroupNotificationSettingsChange={
        onRuleGroupNotificationSettingsChange
      }
    />
  );
}

function LabelRuleGroupEditorPage() {
  const navigate = useNavigate();
  const { level, labelKey = "" } = useParams();
  const isCreatingNew = !level || !labelKey;
  const { refresh } = useSalesBreakdownConfigs();

  const [updateLabelRule, { loading: updateLabelRuleLoading }] =
    useNorthbeamMutation<UpdateLabelRule, UpdateLabelRuleVariables>(
      UPDATE_LABEL_RULE,
      { refetchQueries: [{ query: GET_LABEL_RULES }] },
    );

  const { data, loading } = useNorthbeamQuery<GetLabelRule>(GET_LABEL_RULE, {
    skip: isCreatingNew,
    variables: { level, labelKey: decodeURIComponent(labelKey) },
  });

  const goBack = React.useCallback(() => navigate(-1), [navigate]);

  const onLabelRulesSave = React.useCallback(
    async ({ level, labelKey }: any, labelRules: LabelRule[]) => {
      await updateLabelRule({
        variables: {
          level,
          labelKey,
          labelRules,
        },
      });

      notifyLabelsCache();

      await refresh();

      goBack();
    },
    [updateLabelRule, goBack, refresh],
  );

  if (loading) {
    return <LoadingSlide />;
  }

  const labelRuleGroup = data?.me.labelRule;

  if (!isCreatingNew && !labelRuleGroup) {
    return <Page404 />;
  }

  return (
    <LabelRuleGroupEditor
      labelRuleGroup={labelRuleGroup ?? null}
      onLabelRulesSave={onLabelRulesSave}
      onDiscardChanges={goBack}
      isLoading={updateLabelRuleLoading}
    />
  );
}
