import { usePageTitle } from "@/atoms/page-title-atom";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import {
  CompanionWrapper,
  ScreenWithControlCenter,
} from "@components/reports/control-center-utilities";
import { LogOnMount } from "@utils/analytics";
import React, { useEffect } from "react";
import { Overview } from "./overview";

export function OverviewPage(): JSX.Element {
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Overview");
  }, [setPageTitle]);

  return (
    <ScreenWithControlCenter>
      <LogOnMount name="Visit overview page" />
      <CompanionWrapper>
        <Overview
          breakdownConfigs={breakdownConfigs}
          reportEventName="Request Overview Report"
        />
      </CompanionWrapper>
    </ScreenWithControlCenter>
  );
}
