import northbeamLogo from "@assets/northbeam-error-screen-logo.svg";
import { LogOnMount } from "@utils/analytics";
import React from "react";

interface BaseErrorPageProps {
  pageName: string;
  pageBody: React.ReactElement;
}

export default function BaseErrorPage({
  pageName,
  pageBody,
}: BaseErrorPageProps) {
  return (
    <div className="min-vw-100 min-vh-100 flex justify-content-center items-center">
      <LogOnMount name={pageName} />
      <div className="w-50 text-center">
        <img
          className="text-center mb-4 img-fluid mx-auto"
          src={northbeamLogo}
          alt={"Northbeam logo"}
        />
        {pageBody}
        <a
          tabIndex={70}
          href="mailto:success@northbeam.io"
          className="btn btn-outline-primary mt-2"
        >
          Contact us
        </a>
      </div>
    </div>
  );
}
