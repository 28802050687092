import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { GoogleAnalyticsParameterExclusions } from "@pages/settings/google-analytics-parameter-exclusions";
import { StepContainer } from "./step-container";

export const GoogleAnalyticsParameterExclusionsStep: FC<
  Partial<StepWizardChildProps>
> = ({ nextStep, previousStep }) => {
  return (
    <StepContainer
      onMoveBackwardButtonClicked={() => {
        previousStep && previousStep();
      }}
      onMoveForwardButtonClicked={() => {
        nextStep && nextStep();
      }}
    >
      <h3>Google Analytics parameter exclusions</h3>
      <hr />
      <p>
        Please add the following UTM parameters to <b>each View</b> for your
        brand on Google Analytics.
      </p>

      <GoogleAnalyticsParameterExclusions />
    </StepContainer>
  );
};
