import React from "react";
import ReactSelect, { components } from "react-select";

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  options: SelectOption[];
  value: SelectOption;
  onChange(value: SelectOption): void;
}

export function TimeSelect({ options, value, onChange }: Props) {
  return (
    <ReactSelect<SelectOption>
      components={{
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            <i className="fa-solid fa-chevron-down text-[0.6rem] text-gray-500 px-2" />
          </components.DropdownIndicator>
        ),
      }}
      classNames={{
        control: () => "shadow-none rounded-md text-[0.6rem] min-h-[30px]",
        indicatorSeparator: () => "hidden",
        dropdownIndicator: () => "p-0",
        input: () => "text-gray-500 leading-3",
        menu: () => "text-gray-500 text-[0.6rem] leading-1",
        menuPortal: () => "z-[9999]",
      }}
      isSearchable={false}
      menuPortalTarget={document.body}
      value={value}
      options={options}
      onChange={(value) => value && onChange(value)}
    />
  );
}
