"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONNECTION_TYPES = exports.getConnectionName = exports.isIntegrationValid = void 0;
function isIntegrationValid(a) {
    return !("error" in a);
}
exports.isIntegrationValid = isIntegrationValid;
var connectionDetails = [
    { id: "integration_microsoft", name: "Microsoft Ads" },
    { id: "integration_shopify", name: "Shopify" },
    { id: "integration_facebook_ads", name: "Facebook Ads" },
    { id: "integration_google_adwords", name: "Google Ads" },
    { id: "integration_google_analytics", name: "Google Analytics" },
    { id: "integration_snapchat", name: "Snapchat Ads" },
    { id: "integration_refersion", name: "Refersion" },
    { id: "integration_linkedin", name: "LinkedIn Ads" },
    { id: "integration_pinterest", name: "Pinterest Ads" },
    { id: "integration_tiktok", name: "TikTok Ads" },
    { id: "integration_amazon", name: "Amazon Ads" },
    { id: "integration_klaviyo", name: "Klaviyo" },
    { id: "integration_amazon_seller", name: "Amazon Seller" },
];
function getConnectionName(connectionType) {
    var _a, _b;
    return ((_b = (_a = connectionDetails.find(function (v) { return v.id === connectionType; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "Unknown");
}
exports.getConnectionName = getConnectionName;
exports.CONNECTION_TYPES = connectionDetails.map(function (v) { return v.id; });
