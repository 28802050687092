"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.specs = exports.spend = exports.METRIC_IDS = exports.FACEBOOK_ATTRIBUTION_WINDOWS = exports.BUCKETING_MODES = exports.BUCKETING_MODE = exports.ATTRIBUTION_WINDOWS = exports.ATTRIBUTION_WINDOW = exports.ACCOUNTING_MODES = exports.ACCOUNTING_MODE = void 0;
var ACCOUNTING_MODE;
(function (ACCOUNTING_MODE) {
    ACCOUNTING_MODE["ACCRUAL"] = "accrual";
    ACCOUNTING_MODE["CASH"] = "cash";
})(ACCOUNTING_MODE = exports.ACCOUNTING_MODE || (exports.ACCOUNTING_MODE = {}));
exports.ACCOUNTING_MODES = [
    ACCOUNTING_MODE.ACCRUAL,
    ACCOUNTING_MODE.CASH,
];
var ATTRIBUTION_WINDOW;
(function (ATTRIBUTION_WINDOW) {
    ATTRIBUTION_WINDOW["ONE_DAY"] = "1";
    ATTRIBUTION_WINDOW["THREE_DAYS"] = "3";
    ATTRIBUTION_WINDOW["SEVEN_DAYS"] = "7";
    ATTRIBUTION_WINDOW["FOURTEEN_DAYS"] = "14";
    ATTRIBUTION_WINDOW["THIRTY_DAYS"] = "30";
    ATTRIBUTION_WINDOW["SIXTY_DAYS"] = "60";
    ATTRIBUTION_WINDOW["NINETY_DAYS"] = "90";
    ATTRIBUTION_WINDOW["INFINITE"] = "infinity";
})(ATTRIBUTION_WINDOW = exports.ATTRIBUTION_WINDOW || (exports.ATTRIBUTION_WINDOW = {}));
exports.ATTRIBUTION_WINDOWS = [
    ATTRIBUTION_WINDOW.ONE_DAY,
    ATTRIBUTION_WINDOW.THREE_DAYS,
    ATTRIBUTION_WINDOW.SEVEN_DAYS,
    ATTRIBUTION_WINDOW.FOURTEEN_DAYS,
    ATTRIBUTION_WINDOW.THIRTY_DAYS,
    ATTRIBUTION_WINDOW.SIXTY_DAYS,
    ATTRIBUTION_WINDOW.NINETY_DAYS,
    ATTRIBUTION_WINDOW.INFINITE,
];
var BUCKETING_MODE;
(function (BUCKETING_MODE) {
    BUCKETING_MODE["PLATFORM"] = "platform";
    BUCKETING_MODE["STANDARD"] = "standard";
})(BUCKETING_MODE = exports.BUCKETING_MODE || (exports.BUCKETING_MODE = {}));
exports.BUCKETING_MODES = [
    BUCKETING_MODE.PLATFORM,
    BUCKETING_MODE.STANDARD,
];
exports.FACEBOOK_ATTRIBUTION_WINDOWS = [
    "28d_click",
    "7d_click",
    "1d_click",
    "28d_click_or_1d_view",
    "28d_view",
    "7d_view",
    "1d_view",
];
var METRIC_IDS;
(function (METRIC_IDS) {
    METRIC_IDS["SPEND"] = "spend";
    METRIC_IDS["IMPRESSIONS"] = "imprs";
    METRIC_IDS["IMPRESSIONS_MILLE"] = "imprs_mille";
    METRIC_IDS["CLICKS"] = "clicks";
    METRIC_IDS["VISITS"] = "visits";
    METRIC_IDS["NEW_VISITS"] = "new_visits";
    METRIC_IDS["TRANSACTIONS"] = "txns";
    METRIC_IDS["REVENUE"] = "rev";
    METRIC_IDS["FIRST_TIME_REVENUE"] = "rev:first_time";
    METRIC_IDS["FIRST_TIME_TRANSACTIONS"] = "txns:first_time";
    METRIC_IDS["RETURNING_REVENUE"] = "rev:returning";
    METRIC_IDS["RETURNING_TRANSACTIONS"] = "txns:returning";
    METRIC_IDS["GOAL_COMPLETION"] = "goal_completions";
    METRIC_IDS["REACH"] = "reach";
    METRIC_IDS["GOOGLE_SEARCH_ESTIMATED_ELIGIBLE_CLICKS"] = "searchEstimatedEligibleClicks";
    METRIC_IDS["GOOGLE_SEARCH_ESTIMATED_ELIGIBLE_IMPRESSIONS"] = "searchEstimatedEligibleImpressions";
})(METRIC_IDS = exports.METRIC_IDS || (exports.METRIC_IDS = {}));
var spend = function (bucketingMode) { return ({
    metricId: METRIC_IDS.SPEND,
    source: "platform",
    bucketingMode: bucketingMode,
}); };
exports.spend = spend;
var imprs = function (bucketingMode) { return ({
    metricId: METRIC_IDS.IMPRESSIONS,
    source: "platform",
    bucketingMode: bucketingMode,
}); };
var imprsMille = function (bucketingMode) { return ({
    metricId: METRIC_IDS.IMPRESSIONS_MILLE,
    source: "platform",
    bucketingMode: bucketingMode,
}); };
var platformClicks = {
    metricId: METRIC_IDS.CLICKS,
    source: "platform",
};
var nbVisits = function (bucketingMode) { return ({
    metricId: METRIC_IDS.VISITS,
    source: "northbeam",
    bucketingMode: bucketingMode,
}); };
var nbNewVisits = function (bucketingMode) { return ({
    metricId: METRIC_IDS.NEW_VISITS,
    source: "northbeam",
    bucketingMode: bucketingMode,
}); };
var platformTxns = function (facebookAttributionWindow) { return ({
    metricId: METRIC_IDS.TRANSACTIONS,
    source: "platform",
    facebookAttributionWindow: facebookAttributionWindow,
}); };
var platformRev = function (facebookAttributionWindow) { return ({
    metricId: METRIC_IDS.REVENUE,
    source: "platform",
    facebookAttributionWindow: facebookAttributionWindow,
}); };
var nbTxns = function (attributionModel, attributionWindowDays, accountingMode, bucketingMode) { return ({
    metricId: METRIC_IDS.TRANSACTIONS,
    source: "northbeam",
    attributionModel: attributionModel,
    attributionWindowDays: attributionWindowDays,
    accountingMode: accountingMode,
    bucketingMode: bucketingMode,
}); };
var nbGoalCompletions = function (goalType, attributionModel, attributionWindowDays, accountingMode) { return ({
    metricId: METRIC_IDS.GOAL_COMPLETION,
    goalType: goalType,
    source: "northbeam",
    attributionModel: attributionModel,
    attributionWindowDays: attributionWindowDays,
    accountingMode: accountingMode,
    bucketingMode: BUCKETING_MODE.STANDARD,
}); };
var nbRev = function (attributionModel, attributionWindowDays, accountingMode, bucketingMode) { return ({
    metricId: METRIC_IDS.REVENUE,
    source: "northbeam",
    attributionModel: attributionModel,
    attributionWindowDays: attributionWindowDays,
    accountingMode: accountingMode,
    bucketingMode: bucketingMode,
}); };
var nbFirstTimeTxns = function (attributionModel, attributionWindowDays, accountingMode, bucketingMode) { return ({
    metricId: METRIC_IDS.FIRST_TIME_TRANSACTIONS,
    source: "northbeam",
    attributionModel: attributionModel,
    attributionWindowDays: attributionWindowDays,
    accountingMode: accountingMode,
    bucketingMode: bucketingMode,
}); };
var nbFirstTimeRev = function (attributionModel, attributionWindowDays, accountingMode, bucketingMode) { return ({
    metricId: METRIC_IDS.FIRST_TIME_REVENUE,
    source: "northbeam",
    attributionModel: attributionModel,
    attributionWindowDays: attributionWindowDays,
    accountingMode: accountingMode,
    bucketingMode: bucketingMode,
}); };
var nbReturningTxns = function (attributionModel, attributionWindowDays, accountingMode, bucketingMode) { return ({
    metricId: METRIC_IDS.RETURNING_TRANSACTIONS,
    source: "northbeam",
    attributionModel: attributionModel,
    attributionWindowDays: attributionWindowDays,
    accountingMode: accountingMode,
    bucketingMode: bucketingMode,
}); };
var nbReturningRev = function (attributionModel, attributionWindowDays, accountingMode, bucketingMode) { return ({
    metricId: METRIC_IDS.RETURNING_REVENUE,
    source: "northbeam",
    attributionModel: attributionModel,
    attributionWindowDays: attributionWindowDays,
    accountingMode: accountingMode,
    bucketingMode: bucketingMode,
}); };
var fbReach = {
    metricId: METRIC_IDS.REACH,
    source: "fb",
};
var googleSearchEstimatedEligibleClicks = {
    metricId: METRIC_IDS.GOOGLE_SEARCH_ESTIMATED_ELIGIBLE_CLICKS,
    source: "adwords",
};
var googleSearchEstimatedEligibleImpressions = {
    metricId: METRIC_IDS.GOOGLE_SEARCH_ESTIMATED_ELIGIBLE_IMPRESSIONS,
    source: "adwords",
};
exports.specs = {
    spend: exports.spend,
    imprs: imprs,
    imprsMille: imprsMille,
    platformClicks: platformClicks,
    nbVisits: nbVisits,
    nbNewVisits: nbNewVisits,
    platformTxns: platformTxns,
    platformRev: platformRev,
    nbTxns: nbTxns,
    nbRev: nbRev,
    nbFirstTimeTxns: nbFirstTimeTxns,
    nbFirstTimeRev: nbFirstTimeRev,
    nbReturningTxns: nbReturningTxns,
    nbReturningRev: nbReturningRev,
    nbGoalCompletions: nbGoalCompletions,
    fbReach: fbReach,
    googleSearchEstimatedEligibleImpressions: googleSearchEstimatedEligibleImpressions,
    googleSearchEstimatedEligibleClicks: googleSearchEstimatedEligibleClicks,
};
