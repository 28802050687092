import { useUser } from "@components/user-context";
import classNames from "classnames";
import clsx from "clsx";
import React from "react";
import {
  AD_DETAILS_COLUMN_NAME,
  DIMENSION_COLUMN_NAME,
} from "../../fixed-column-configs";
import { MetricDataFormatter } from "../data-type-providers/metric-type-provider";

const headerStyle = { backgroundColor: "#F1F1F2" };

export const CustomSalesTableHeadWithFixedRow =
  (tableSummary: any) =>
  ({ isFixed, className, style, children }: any) => {
    const columnsInView = children[0].props.cells;

    return (
      <thead
        className={classNames(
          {
            "dx-g-bs4-fixed-header": isFixed,
            "dx-g-bs4-table-sticky": isFixed,
            "bg-white": isFixed,
          },
          className,
        )}
        style={style}
      >
        {children}
        <tr>
          {columnsInView.map(({ column }: any, index: number) => (
            <ColumnHead
              key={index}
              column={column}
              tableSummary={tableSummary}
            />
          ))}
        </tr>
      </thead>
    );
  };

const ColumnHead = ({ tableSummary, column }: any) => {
  const { user } = useUser();

  // Not sure why but there's s stub header column
  const columnType = column?.type?.toString();
  if (["Symbol(stub)", "Symbol(flex)"].includes(columnType)) {
    return (
      <th className="p-0" style={headerStyle} scope="col" colSpan={1}></th>
    );
  }

  if (columnType === "Symbol(select)") {
    return (
      <th
        className="left-0 p-0 dx-g-bs4-fixed-cell position-sticky"
        style={headerStyle}
        scope="col"
        colSpan={1}
      ></th>
    );
  }

  // Only a known data column would have a column property
  if (!column?.column) {
    return null;
  }
  if (!column.column.visible) {
    return null;
  }

  if (column.column.name === DIMENSION_COLUMN_NAME) {
    return (
      <th
        className="font-medium relative dx-g-bs4-header-cell text-nowrap dx-g-bs4-fixed-cell position-sticky align-middle"
        style={{
          ...headerStyle,
          left: `var(--${DIMENSION_COLUMN_NAME}-offset)`,
        }}
        scope="col"
        colSpan={1}
      >
        <div className="font-semibold">Grand Total</div>
      </th>
    );
  }

  if (column.column.name === AD_DETAILS_COLUMN_NAME) {
    return (
      <th
        className="font-medium relative dx-g-bs4-header-cell text-nowrap dx-g-bs4-fixed-cell position-sticky"
        style={{
          ...headerStyle,
          left: `var(--${AD_DETAILS_COLUMN_NAME}-offset)`,
        }}
        scope="col"
        colSpan={1}
      ></th>
    );
  }

  return (
    <th
      key={column.column.id}
      className={clsx(
        "font-medium relative dx-g-bs4-header-cell text-nowrap",
        user.featureFlags.enableDesignRefresh ? "font-mono" : "",
      )}
      style={headerStyle}
      scope="col"
      colSpan={1}
    >
      <MetricDataFormatter
        row={tableSummary}
        column={column.column}
        value={null}
      />
    </th>
  );
};
