import DebugCodeBlock from "@components/DebugCodeBlock";
import React from "react";
import ExpandedTextAd from "../ExpandedTextAd";
import ImageAd from "../ImageAd";
import PrettyDisplayAttributes from "../PrettyDisplayAttributes";
import TextAd from "../TextAd";
import GmailAd from "./GmailAd";
import ResponsiveSearchAd from "./ResponsiveSearchAd";
import ResponsiveDisplayAd from "./ResponsiveDisplayAd";

function getAdAndNbMetada(ad: any) {
  let nbMetadata: any = null;
  if (ad.googleMetadata && ad.nbMetadata) {
    nbMetadata = ad.nbMetadata;
    ad = ad.googleMetadata;
  }

  ad = ad.ad;
  return { ad, nbMetadata };
}

export default function GoogleAd({ googleAd }: { googleAd: any }) {
  const { ad, nbMetadata } = getAdAndNbMetada(googleAd);

  const ytID = nbMetadata?.youtube_video_id;
  if (ytID) {
    return (
      <div>
        <iframe
          title={`YouTube video: ${ytID}`}
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${ytID}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  } else if (ad.type.startsWith("VIDEO_")) {
    return (
      <div className="text-black text-center flex-1">
        YouTube Ad <br />
        (no preview available)
      </div>
    );
  }

  if (ad.type === "TEXT_AD") {
    return <TextAd {...ad.textAd} displayUrl={ad.displayUrl} />;
  } else if (ad.type === "EXPANDED_TEXT_AD") {
    return <ExpandedTextAd {...ad.expandedTextAd} />;
  } else if (ad.type === "IMAGE_AD") {
    return <ImageAd {...ad.imageAd} />;
  } else if (
    ad.type === "PRODUCT_AD" ||
    ad.type === "GOAL_OPTIMIZED_SHOPPING_AD"
  ) {
    return (
      <PrettyDisplayAttributes
        attributes={[
          { key: "Type", value: ad["Ad.Type"] },
          { key: "ID", value: ad.id },
        ]}
      />
    );
  } else if (ad.type === "TEMPLATE_AD") {
    return (
      <PrettyDisplayAttributes
        attributes={[
          { key: "Type", value: ad["Ad.Type"] },
          { key: "Name", value: ad.name },
          { key: "Display URL", value: ad.displayUrl },
          { key: "Final URL", value: JSON.stringify(ad.finalUrls) },
          { key: "ID", value: ad.id },
        ]}
      />
    );
  } else if (ad.type === "DYNAMIC_SEARCH_AD") {
    return (
      <TextAd
        {...ad}
        headline="[Dynamically generated headline]"
        description={ad.description1}
      />
    );
  } else if (ad.type === "EXPANDED_DYNAMIC_SEARCH_AD") {
    return (
      <TextAd
        {...ad}
        headline="[Dynamically generated headline]"
        displayUrl="[Dynamically generated display URL]"
        description={ad.description1}
      />
    );
  } else if (ad.type === "GMAIL_AD") {
    return <GmailAd {...ad.gmailAd} />;
  } else if (ad.type === "RESPONSIVE_DISPLAY_AD") {
    return <ResponsiveDisplayAd {...ad.responsiveDisplayAd} />;
  } else if (ad.type === "RESPONSIVE_SEARCH_AD") {
    return <ResponsiveSearchAd {...ad} />;
  }
  return <DebugCodeBlock data={ad} style={{ minWidth: "480px" }} />;
}
