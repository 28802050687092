import axios from "axios";
import React from "react";
import { useInterval } from "@components/utilities";

export interface AppVersionContextType {
  appVersion: string | null;
}

const AppVersionContext = React.createContext<AppVersionContextType>({
  appVersion: null,
});

export const useAppVersion = () => React.useContext(AppVersionContext);

export interface AppVersionProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

export function AppVersionProvider(props: AppVersionProviderProps) {
  const [appVersion, setAppVersion] = React.useState<string | null>(null);

  const func = React.useCallback(async () => {
    try {
      const response = await axios("/version.txt");
      setAppVersion(response.data.trim());
    } catch (e) {
      // continue regardless of error
    }
  }, [setAppVersion]);

  useInterval(func, 600_000);

  return <AppVersionContext.Provider {...props} value={{ appVersion }} />;
}
