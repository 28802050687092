import React from "react";
import { Button, ButtonProps } from "./button";

export const TertiaryButton = ({
  className,
  children,
  onClick,
  isDisabled,
  size,
  ...restProps
}: ButtonProps) => {
  return (
    <Button
      className={className}
      variant="tertiary"
      isDisabled={isDisabled}
      onClick={onClick}
      size={size}
      {...restProps}
    >
      {children}
    </Button>
  );
};
