import { Badge } from "@shared/badge";
import React from "react";
import northbeamLogo from "@assets/northbeam-logo.svg";
import miniGraph from "@assets/mini-graph.svg";
import { FBIcon } from "./icons";

export function CreativeAnalyticsCard() {
  return (
    <div className="flex flex-col w-[18rem] rounded-2xl bg-white border-[#dbdddf]  border-[1px]">
      <div className="relative">
        <div className="absolute top-3 left-3 rounded-2xl px-3 py-1 bg-[#f7f7f7] flex flex-row items-center ">
          <input
            type="checkbox"
            // checked={false}
            onChange={() => {
              console.log("checked");
            }}
            className="mr-2 accent-cosmic w-3 h-3 "
          />
          <div className="text-xs">Show in graph</div>
        </div>
        <FBIcon className="absolute top-3 right-3" />

        <img
          className="h-[14rem] w-[18rem] object-cover	rounded-t-2xl"
          src="http://cdn.shopify.com/s/files/1/2496/2470/articles/spring_shoot_2021_24_1.jpg?v=1620326130"
          alt="fake-img"
        />
      </div>

      <div className="h-[18.5rem] mx-4 mt-4 text-sm">
        <Badge
          textClassname="ml-1"
          text="Leather Platform Esapadrille adas da sd as da d asd"
          badgeColor="#68BE56"
          size="md"
        />
        <PlatformNBMetricComparator />
        <SpendToRevComparator />
        <Metrics />
      </div>
    </div>
  );
}

function Metrics() {
  return (
    <div className="mt-3 w-full grid grid-cols-2 gap-2.5 text-xs ">
      <div className="flex flex-row justify-between w-full h-[2.75rem] border-[#dbdddf] border-[1px] rounded-md items-center px-2 bg-[#EDF8EE]">
        <div className="font-semibold">CPM</div>
        <div>34%</div>
      </div>
      <div className="flex flex-row justify-between w-full h-[2.75rem] border-[#dbdddf] border-[1px] rounded-md items-center px-2 bg-[#FFFBEF]">
        <div className="font-semibold">CTR</div>
        <div>34%</div>
      </div>
      <div className="flex flex-row justify-between w-full h-[2.75rem] border-[#dbdddf] border-[1px] rounded-md items-center px-2 bg-[#FFFBEF]">
        <div className="font-semibold">Thumpstop</div>
        <div>34%</div>
      </div>
      <div className="flex flex-row justify-between w-full h-[2.75rem] border-[#dbdddf] border-[1px] rounded-md items-center px-2 bg-[#FEEFEB]">
        <div className="font-semibold">CVR</div>
        <div>34%</div>
      </div>
    </div>
  );
}

function PlatformNBMetricComparator() {
  return (
    <div className="border-[#dbdddf] border-[1px] h-[4.5rem] w-full rounded-md mt-4 p-2">
      <div className="font-semibold">ROAS</div>
      <div className="flex flex-row items-center mt-1">
        <div className="w-7 h-7 bg-[#F6F7FA] rounded-full justify-center flex items-center">
          <img className="w-4" alt="northbeam-logo" src={northbeamLogo} />
        </div>
        <div className="ml-2">2.3x</div>
        <div>
          <img className="w-10 ml-2" alt="mini-graph" src={miniGraph} />
        </div>
        <div className="h-[1rem] mx-2 border-l-[1.5px] border-#C1C5CD" />
        {/* fb */}
        <FBIcon />
        <div className="ml-2">2.3x</div>
        <div>
          <img className="w-10 ml-2" alt="mini-graph" src={miniGraph} />
        </div>
      </div>
    </div>
  );
}

function SpendToRevComparator() {
  return (
    <div className="border-[#dbdddf] border-[1px] h-[3rem] w-full rounded-md mt-3 p-2 flex flex-row items-center justify-between	text-xs">
      <div className="flex flex-row">
        <div className="font-semibold">Spend</div>
        <div className="ml-2">$3,000</div>
      </div>
      <i className="fa-light fa-arrow-right"></i>
      <div className="flex flex-row">
        <div className="font-semibold">Rev(1d)</div>
        <div className="ml-2">$3,000</div>{" "}
      </div>
    </div>
  );
}
