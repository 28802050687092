import { logEvent } from "@utils/analytics";
import classNames from "classnames";
import Interweave from "interweave";
import Tooltip from "rc-tooltip";
import React, { ReactNode, useState } from "react";

interface TooltipProps {
  overlayOverCircleOnly?: boolean;
  noInfoCircle?: boolean;
  overlayClass?: string;
  Wrapper?: (props: {
    children?: React.ReactNode | React.ReactNodeArray | undefined;
  }) => JSX.Element;
  mouseLeaveDelay?: number;
  mouseEnterDelay?: number;
  className?: string;
}

export function HTMLTooltip({
  html,
  children,
  ...props
}: {
  html: string;
  children?: React.ReactNode | React.ReactNodeArray;
} & TooltipProps) {
  return (
    <GenericTooltip
      content={<Interweave content={html} />}
      // eslint-disable-next-line react/no-children-prop
      children={children}
      {...props}
    />
  );
}

export function GenericTooltip({
  content,
  children,
  Wrapper,
  overlayClass,
  noInfoCircle,
  mouseLeaveDelay,
  mouseEnterDelay,
  captureEventString,
  className,
}: {
  content: ReactNode;
  children?: React.ReactNode | React.ReactNodeArray;
  captureEventString?: string;
} & TooltipProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const overlay = (
    <div className={overlayClass ?? "nb-tooltip-inner"}>{content}</div>
  );
  let inner = children;
  if (!noInfoCircle) {
    inner = (
      <>
        {children}&nbsp;
        <InfoCircle />
      </>
    );
  }

  let body = (
    <span className={classNames(className, "nb-tooltip-children")}>
      {inner}
    </span>
  );

  if (Wrapper) {
    body = Wrapper({ children: inner });
  }

  return (
    <Tooltip
      placement={"top"}
      arrowContent={null}
      mouseLeaveDelay={mouseLeaveDelay ?? 0.1}
      mouseEnterDelay={mouseEnterDelay ?? 0}
      overlay={overlay}
      onVisibleChange={() => {
        if (!isHovering && captureEventString) {
          logEvent(captureEventString);
        }
        setIsHovering(!isHovering);
      }}
    >
      {body}
    </Tooltip>
  );
}

export function InfoCircle() {
  return (
    <i className="fas fa-info-circle text-muted" style={{ zIndex: 2 }}></i>
  );
}
