import { usePageTitle } from "@/atoms/page-title-atom";
import { Balloon } from "@components/floating-balloons";
import { LoadingSlide, TitleSlide } from "@components/title-slide-view";
import { H1 } from "@components/utilities";
import {
  GetActiveIncidents,
  GetActiveIncidents_me_incidents,
} from "@nb-api-graphql-generated/GetActiveIncidents";
import { formatDateFull, todayLocalAsISODate } from "@north-beam/nb-common";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect } from "react";
import { CollapsibleIncidentPreview } from "./components/incident-views";
import { GET_ACTIVE_INCIDENTS } from "./queries";

export function IncidentsListPage() {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Incidents");
  }, [setPageTitle]);

  return (
    <div className="container pt-4">
      <LogOnMount name="Visit incident list page" />
      <div className="row">
        <div className="col">
          <H1>Monitoring summary</H1>
          <p>{formatDateFull(todayLocalAsISODate())}</p>
        </div>
      </div>
      <div className="row">
        <div className="col">{Inner()}</div>
      </div>
    </div>
  );
}

function Inner() {
  const { data, loading } = useNorthbeamQuery<GetActiveIncidents>(
    GET_ACTIVE_INCIDENTS,
    {},
  );

  if (loading) {
    return <LoadingSlide />;
  }

  const incidents: GetActiveIncidents_me_incidents[] = data!.me.incidents;
  if (incidents.length === 0) {
    return (
      <>
        <h3 className="mt-3">
          All alerts taken care of.{" "}
          <span role="img" aria-label="hooray">
            🙌
          </span>
        </h3>
        <TitleSlide>
          <div>
            <Balloon color="hsl(215,50%,65%)" animationDuration="3.5s" />
            <Balloon color="hsl(139,50%,60%)" animationDuration="3s" />
            <Balloon color="hsl(23,55%,57%)" animationDuration="4.5s" />
          </div>
        </TitleSlide>
      </>
    );
  }

  return incidents.map((v) => (
    <CollapsibleIncidentPreview data={v} key={v.id} />
  ));
}
