import React, { Fragment, useEffect, useState } from "react";

import clsx from "clsx";
import { Menu, Transition } from "@headlessui/react";

import {
  Link,
  Element as ScrollElement,
  Events,
  scrollSpy,
} from "react-scroll";

import { CreativeAnalyticsLink } from "./ca-creative-type-bar";
import { ResizableComponent } from "./ca-resizeable-container";
import { Bar, Line } from "react-chartjs-2";
import { MetricFormats } from "@utils/metrics";
import { ChartData, ChartDataset, ScatterDataPoint } from "chart.js";
import { subDays } from "date-fns";
import { useGraphTypeAtom } from "../atoms";

type Metrics = {
  id: string;
  name: string;
}[];

export function CreativeAnalyticsCharts() {
  const metrics = useFetchMetrics();

  useEffect(() => {
    scrollSpy.update();
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <ResizableComponent height={50}>
      {({ currentHeight }) => (
        <>
          <div className="absolute top-5 right-1/2 translate-x-1/2 transform font-semibold z-[1]">
            Graphs
          </div>
          <div
            className="h-full flex flex-col bg-white items-center relative overflow-y-auto"
            style={{
              boxShadow: "0px -3px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="mt-[0.5rem]" />
            {currentHeight > 80 && (
              <>
                <GraphControl metrics={metrics} />
                <div className="border-t-[1.5px] w-full mt-[1rem] mb-5 border-#CCCCCC" />
                <div
                  className="w-full overflow-y-auto items-center flex flex-col"
                  id="graphsContainer"
                >
                  {metrics.map((metric, indx) => {
                    return (
                      <CreativeAnalyticsGraph
                        key={metric.id}
                        metric={metric}
                        className={clsx(indx > 0 ? "mt-5" : "", {
                          "border-b-[1px] border-[D8DBDF]":
                            indx < metrics.length - 1,
                        })}
                      />
                    );
                  })}
                  <div className="min-h-[1rem] max-h-[1rem]" />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </ResizableComponent>
  );
}

// Graphs Subcomponents
function useFetchMetrics() {
  return [
    { name: "ROAS", id: "roas" },
    { name: "CAC", id: "cac" },
    { name: "Spend", id: "spend" },
    { name: "Thumpstop", id: "thumpstop" },
    { name: "CTR", id: "ctr" },
    { name: "CPM", id: "cpm" },
  ];
}

const MAX_GRAPH_NAV_ITEMS = 4;

function GraphControl({ metrics }: { metrics: Metrics }) {
  const [graphType, setGraphType] = useGraphTypeAtom();
  return (
    <div className="flex flex-row self-start w-full items-center">
      <div className="h-[3rem] flex ml-4 mb-2 flex-row ">
        {metrics.slice(0, MAX_GRAPH_NAV_ITEMS).map((metric) => {
          return <MetricControl key={metric.id} metric={metric} />;
        })}
        {metrics.length > 4 && (
          <Menu as="div" className="ml-2 mt-2 relative">
            <Menu.Button>
              <i
                className={clsx(
                  "font-semibold fa-solid cursor-pointer text-[#717680] text-lg absolute top-2",
                  {
                    "fa-chevron-down": true,
                  },
                )}
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform ease-out duration-100"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {metrics.slice(MAX_GRAPH_NAV_ITEMS).map((metric) => {
                    return (
                      <Menu.Item key={metric.id}>
                        <DropdownMetricControl
                          key={metric.id}
                          metric={metric}
                        />
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
      <div className="ml-auto mr-10 flex flex-row items-center">
        <div className="flex flex-row items-center">
          Chart display:
          <div
            className={clsx(
              "cursor-pointer border border-[#C1C5CD] w-[2rem] h-[2.1rem] flex items-center justify-center rounded-lg ml-3",
              {
                "border-[#7081FF]": graphType === "line",
              },
            )}
            onClick={() => setGraphType("line")}
          >
            <i className="fa-sharp fa-solid fa-chart-line"></i>
          </div>
          <div
            className={clsx(
              "cursor-pointer border border-[#C1C5CD] w-[2rem] h-[2.1rem] flex items-center justify-center rounded-lg ml-3",
              {
                "border-[#7081FF]": graphType === "bar",
              },
            )}
            onClick={() => setGraphType("bar")}
          >
            <i className="fa-sharp fa-regular fa-chart-simple"></i>
          </div>
        </div>
        <div className="h-[2rem] border-l-[1.5px] border-#C1C5CD mx-4" />
        <Ads />
      </div>
    </div>
  );
}

function Ads() {
  return (
    <div className="flex flex-row items-center">
      <Ad name={"Ad 1"} color={"#7081FF"} />
      <Ad name={"Ad 2"} color={"#C825A8"} />
      <Ad name={"Ad 3"} color={"#F3971A"} />
    </div>
  );
}
function Ad({ name, color }: { name: string; color: string }) {
  return (
    <div className="flex flex-row items-center">
      <div
        className={`h-[1rem] w-[1rem] rounded-sm mr-1 ml-3`}
        style={{ background: color }}
      />
      {name}
    </div>
  );
}

function DropdownMetricControl({ metric }: { metric: Metrics[number] }) {
  const [isActive, setActive] = useState(false);

  return (
    <Link
      key={metric.id}
      to={metric.id}
      spy={true}
      smooth={true}
      containerId="graphsContainer"
      className={clsx({
        "no-underline": true,
      })}
      offset={-10}
      onSetActive={() => setActive(true)}
      onSetInactive={() => setActive(false)}
      duration={200}
    >
      <div
        className={clsx("cursor-pointer my-4 mx-5", {
          "text-primary": isActive,
          "text-subtitle": !isActive,
        })}
      >
        {metric.name}
      </div>
    </Link>
  );
}

function MetricControl({ metric }: { metric: Metrics[number] }) {
  const [isActive, setActive] = useState(false);

  return (
    <Link
      key={metric.id}
      to={metric.id}
      spy={true}
      smooth={true}
      containerId="graphsContainer"
      className={clsx({
        "no-underline": true,
      })}
      offset={-10}
      onSetActive={() => setActive(true)}
      onSetInactive={() => setActive(false)}
      duration={200}
    >
      <CreativeAnalyticsLink
        isActive={isActive}
        key={metric.id}
        text={metric.name}
      />
    </Link>
  );
}

const Toggle = ({
  checked,
  toggle,
}: {
  checked: boolean;
  toggle: () => void;
}) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer m-0">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        onChange={toggle}
      />
      <div className="w-[2.7rem] h-6 bg-gray-200 peer-focus:outline-none peer-focus:none peer-focus:none dark:peer-focus:none rounded-full peer dark:bg-[#E6EAEF] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2.5px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[1.2rem] after:w-[1.2rem] after:transition-all dark:border-gray-600 peer-checked:bg-primaryPurple"></div>
    </label>
  );
};

function CreativeAnalyticsGraph({
  metric,
  className,
}: {
  metric: Metrics[number];
  className?: string;
}) {
  const [showPlatformMetrics, setShowPlatformMetrics] = useState(false);
  return (
    <ScrollElement
      className={clsx(
        "w-[-webkit-fill-available] px-15 py-2 min-h-[25rem] max-h-[30rem] bg-white",
        className,
      )}
      name={metric.id}
      id={metric.id}
    >
      <div className="flex flex-row mx-10">
        <div className="font-semibold">{metric.name}</div>
        <div className="ml-auto flex flex-row items-center">
          <div>Show in Platform Metrics</div>
          <div className="ml-5 flex items-center">
            <Toggle
              checked={showPlatformMetrics}
              toggle={() => {
                setShowPlatformMetrics(!showPlatformMetrics);
              }}
            />
          </div>
        </div>
      </div>
      <div className="mx-10 h-full">
        <Graph />
      </div>
    </ScrollElement>
  );
}

function Graph() {
  const [graphType] = useGraphTypeAtom();
  const dateLabels: ChartData<"line", ScatterDataPoint[], string>["labels"] = [
    subDays(new Date(), 8).toISOString(),
    subDays(new Date(), 6).toISOString(),
    subDays(new Date(), 5).toISOString(),
    subDays(new Date(), 4).toISOString(),
    subDays(new Date(), 3).toISOString(),
    subDays(new Date(), 2).toISOString(),
    subDays(new Date(), 1).toISOString(),
    new Date().toISOString(),
  ];

  const data = [
    {
      label: "Ad1",
      data: [100, 200, 300, 400, 100, 400],
      borderColor: "#B6B4F7",
      backgroundColor: "#B6B4F7",
      pointBackgroundColor: "#514FB2",
      pointBorderColor: "#514FB2",
      yAxisID: "metricY",
    },
    {
      label: "Ad2",
      data: [400, 300, 1300, 400, 300],
      borderColor: "#E9A8DC",
      backgroundColor: "#E9A8DC",
      pointBackgroundColor: "#A1077F",
      pointBorderColor: "#A1077F",
      yAxisID: "metricY",
      tension: 0.3,
    },
    {
      label: "Ad3",
      data: [1400, 300, 100, 40, 300],
      borderColor: "#FFEAAB",
      backgroundColor: "#FFEAAB",
      pointBackgroundColor: "#F3971A",
      pointBorderColor: "#F3971A",
      yAxisID: "metricY",
      tension: 0.3,
    },
    {
      label: "Ad4",
      data: [200, 100, 100, 400, 300, 200, 100, 1000],
      borderColor: "#DADAFB",
      backgroundColor: "#DADAFB",
      pointBackgroundColor: "#514FB2",
      pointBorderColor: "#514FB2",
      yAxisID: "metricY",
      tension: 0.3,
    },
  ];
  const lineDataset: ChartDataset<"line", number[]>[] = [...data];

  const barDataset: ChartDataset<"bar", number[]>[] = [...data].map((d) => ({
    ...d,
    maxBarThickness: 30,
  }));

  if (graphType === "bar") {
    return (
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: "index",
            intersect: false,
          },
          plugins: {
            title: {
              display: true,
              align: "start",
              font: {
                size: 18,
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  if (tooltipItem.dataset.label === "Revenue")
                    return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`;
                  return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
                },
              },
            },
          },
          scales: {
            xAxis: {
              type: "time",
              time: {
                tooltipFormat: `ddd D MMM`,
                unit: "day",
              },
              ticks: {
                font: { size: 13 },
                maxRotation: 20,
              },
              grid: {
                drawOnChartArea: false,
                drawTicks: true,
              },
            },
            metricY: {
              ticks: {
                callback(tickValue, index, ticks) {
                  return MetricFormats.dollars.approximate(Number(tickValue));
                },
              },
              beginAtZero: true,
              type: "linear",
              display: true,
              position: "left",
              grid: {
                drawOnChartArea: true,
              },
            },
          },
          layout: {
            padding: {
              bottom: 36,
            },
          },
        }}
        data={{
          labels: dateLabels,
          datasets: barDataset,
        }}
      />
    );
  }
  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: "index",
          intersect: false,
        },
        plugins: {
          title: {
            display: true,
            align: "start",
            font: {
              size: 18,
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                if (tooltipItem.dataset.label === "Revenue")
                  return `${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`;
                return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
              },
            },
          },
        },
        scales: {
          xAxis: {
            type: "time",
            time: {
              tooltipFormat: `ddd D MMM`,
              unit: "day",
            },
            ticks: {
              font: { size: 13 },
              maxRotation: 20,
            },
            grid: {
              drawOnChartArea: false,
              drawTicks: true,
            },
          },
          metricY: {
            ticks: {
              callback(tickValue, index, ticks) {
                return MetricFormats.dollars.approximate(Number(tickValue));
              },
            },
            beginAtZero: true,
            type: "linear",
            display: true,
            position: "left",
            grid: {
              drawOnChartArea: true,
            },
          },
        },
        layout: {
          padding: {
            bottom: 36,
          },
        },
      }}
      data={{
        labels: dateLabels,
        datasets: lineDataset,
      }}
    />
  );
}
