import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "@components/shared/buttons";
import { Modal } from "@components/shared/modals";
import { Breakdown } from "@north-beam/nb-common";
import { Checkbox } from "@shared/checkbox";
import RadioGroup from "@shared/radio-group";
import { downloadCsvV2 } from "@utils/csv";
import { SalesPageMetric } from "@utils/metrics";
import { flatten } from "@utils/row-with-children";
import { compact } from "lodash";
import React, { useState } from "react";
import { dimensionColumnDef } from "./fixed-column-configs";
import {
  ColumnDef,
  createDailyDataRoot,
  filter0SpendAnd0TransactionRows,
} from "./table/table-utils";

const AGGREGATED_DATA = "aggregated_data" as const;
const DAILY_DATA = "daily_data" as const;

type ExportType = typeof AGGREGATED_DATA | typeof DAILY_DATA;

interface ExportToCsvModalProps {
  dailyData: any;
  metricsArray: SalesPageMetric[];
  extraExportDimensionColumns: Set<string>;
  tableRoot: any;
  breakdowns: Breakdown[];
  metricColumns: (ColumnDef & {
    metric?: any;
  })[];
  columnOrder: string[];
  hiddenColumns: string[];
  setExportToCsvModalOpen: (arg0: boolean) => void;
  exportToCsvModalOpen: boolean;
}

export const ExportToCsvModal = ({
  dailyData,
  metricsArray,
  extraExportDimensionColumns,
  tableRoot,
  breakdowns,
  metricColumns,
  columnOrder,
  hiddenColumns,
  setExportToCsvModalOpen,
  exportToCsvModalOpen,
}: ExportToCsvModalProps) => {
  const [shouldRemove0Spend, setShouldRemove0Spend] = useState(true);
  const [exportType, setExportType] = useState<ExportType>(AGGREGATED_DATA);

  const handleExport = () => {
    const hiddenColumnsSet = new Set<string>(hiddenColumns);
    const metricColumnsMap = new Map<string, (typeof metricColumns)[number]>(
      metricColumns.map((e) => [e.name, e]),
    );

    const orderedMetricColumns = compact(
      columnOrder.map((name) => metricColumnsMap.get(name)),
    );

    const exportData: any = {
      title: "",
      dimensionColumns: [
        ...breakdowns.map((v) => ({ title: v.key, id: v.key })),
        dimensionColumnDef,
        ...Array.from(extraExportDimensionColumns).map((id) => ({
          title: id,
          id,
        })),
      ],
      metricColumns: orderedMetricColumns.filter(
        ({ name }) => !hiddenColumnsSet.has(name),
      ),
    };

    if (exportType === AGGREGATED_DATA) {
      exportData.rows = flatten(tableRoot);
      if (shouldRemove0Spend) {
        exportData.rows = filter0SpendAnd0TransactionRows(exportData.rows);
      }
      downloadCsvV2("Sales", "north-beam-sales-report.csv", exportData);
    } else if (exportType === DAILY_DATA) {
      const dailyDataRoot = createDailyDataRoot(
        dailyData,
        extraExportDimensionColumns,
        metricsArray,
      );
      exportData.dimensionColumns.unshift({ title: "Date", id: "Date" });
      if (shouldRemove0Spend) {
        exportData.rows = filter0SpendAnd0TransactionRows(dailyDataRoot);
      }
      exportData.rows = dailyDataRoot;
      downloadCsvV2(
        "DailySalesData",
        "north-beam-sales-daily-report.csv",
        exportData,
      );
    }
  };

  return (
    <>
      <SecondaryButton onClick={() => setExportToCsvModalOpen(true)}>
        Export
      </SecondaryButton>
      <Modal isOpen={exportToCsvModalOpen} title="Export Data">
        <div className="flex flex-col space-y-8">
          <RadioGroup
            options={[
              { label: "Export Aggregated Data", value: AGGREGATED_DATA },
              { label: "Export Data Broken Down by Day", value: DAILY_DATA },
            ]}
            onChange={setExportType}
            value={exportType}
          />
          <Checkbox
            label="Remove $0 spend / 0 transactions items from CSV"
            value={shouldRemove0Spend}
            onChange={setShouldRemove0Spend}
          />
          <div className="flex justify-start space-x-4">
            <PrimaryButton onClick={handleExport}>Export</PrimaryButton>
            <TertiaryButton onClick={() => setExportToCsvModalOpen(false)}>
              Close
            </TertiaryButton>
          </div>
        </div>
      </Modal>
    </>
  );
};
