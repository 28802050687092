import { useUser } from "@components/user-context";
import { useMemo } from "react";
import { formatHourWithTimeZone, HOUR_SELECT_OPTIONS } from "./utils";

export function useTimeSelectOptions() {
  const { user } = useUser();

  return useMemo(() => {
    return HOUR_SELECT_OPTIONS.map(({ label, value }) => ({
      label: formatHourWithTimeZone(label, user.timezone),
      value,
    }));
  }, [user.timezone]);
}
