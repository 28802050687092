import React, { PropsWithChildren } from "react";
import {
  BenchmarkPlatformEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";

import { PrettyBenchmarkAttributionModelNames } from "@/constants/benchmarking";
import {
  BenchmarkReviewCard,
  EditableBenchmarkReviewCard,
} from "./benchmark-review-card";
import { LoadingSpinner } from "@components/title-slide-view";
import classNames from "classnames";

import { Control, UseFormRegister } from "react-hook-form";
import {
  BenchmarksByPlatform,
  PlatformBenchmarks,
} from "./custom-benchmarks-modal";
import { ChooseAndSetValidationTargets } from "./benchmark-choose-and-set-modal";

const LoadingPlatformMetrics = () => {
  return (
    <div
      className={classNames(
        "xl:w-[45rem] lg:w-[40rem] m:w-[35rem] relative",
        "xl:h-[23rem]",
      )}
    >
      <LoadingSpinner />
      <BenchmarkReviewCard
        platform={BenchmarkPlatformEnum.adwords}
        cac={null}
        roas={null}
        label={null}
        loading={false}
        ignoreNotEnoughData
        benchmarkTarget={null}
        benchmarkType={BenchmarkTypeEnum.CAC}
      />
      <BenchmarkReviewCard
        platform={BenchmarkPlatformEnum.facebook}
        cac={null}
        roas={null}
        label={null}
        loading={false}
        ignoreNotEnoughData
        benchmarkTarget={null}
        benchmarkType={BenchmarkTypeEnum.CAC}
      />
      <BenchmarkReviewCard
        platform={BenchmarkPlatformEnum.youtube}
        cac={null}
        roas={null}
        label={null}
        loading={false}
        ignoreNotEnoughData
        benchmarkTarget={null}
        benchmarkType={BenchmarkTypeEnum.CAC}
      />
    </div>
  );
};

export const BenchmarkPlatformMetricsGridSkeleton = ({
  children,
  loading,
  notScrollable,
  compact = true,
}: PropsWithChildren<{
  loading?: boolean;
  notScrollable?: boolean;
  compact?: boolean;
}>) => {
  if (loading) return <LoadingPlatformMetrics />;

  return (
    <div
      className={classNames(
        "xl:w-[45rem] lg:w-[40rem] m:w-[35rem] relative",
        compact ? "2xlh:h-[23rem] h-[20rem]" : "h-[23rem]",
        notScrollable ? "" : "overflow-y-auto",
      )}
    >
      {children}
    </div>
  );
};

export const BenchmarkPlatformMetricsGrid = ({
  orderedData,
  showLabels,
  loading,
  notScrollable,
  compact = true,
  benchmarkTargetsByPlatform,
  benchmarkType,
}: {
  orderedData: PlatformBenchmarks[];
  showLabels: boolean;
  loading?: boolean;
  notScrollable?: boolean;
  compact?: boolean;
  benchmarkType: BenchmarkTypeEnum;
  benchmarkTargetsByPlatform: ChooseAndSetValidationTargets;
}) => {
  return (
    <BenchmarkPlatformMetricsGridSkeleton
      notScrollable={notScrollable}
      compact={compact}
      loading={loading}
    >
      {orderedData.map(({ cac, roas, platform, attributionModel }) => {
        return (
          <BenchmarkReviewCard
            key={`${platform}-${attributionModel}`}
            platform={platform}
            cac={cac}
            roas={roas}
            label={
              showLabels
                ? PrettyBenchmarkAttributionModelNames[attributionModel]
                : null
            }
            loading={!!loading}
            benchmarkTarget={benchmarkTargetsByPlatform[platform]}
            benchmarkType={benchmarkType}
          />
        );
      })}
    </BenchmarkPlatformMetricsGridSkeleton>
  );
};

export const EditableBenchmarkPlatformMetricsGrid = ({
  orderedData,
  showLabels,
  loading,
  control,
  register,
  notScrollable,
  benchmarkTargetsByPlatform,
  benchmarkType,
}: {
  orderedData: PlatformBenchmarks[];
  showLabels: boolean;
  loading?: boolean;
  notScrollable?: boolean;
  control: Control<BenchmarksByPlatform>;
  register: UseFormRegister<BenchmarksByPlatform>;
  benchmarkType: BenchmarkTypeEnum;
  benchmarkTargetsByPlatform: ChooseAndSetValidationTargets;
}) => {
  return (
    <BenchmarkPlatformMetricsGridSkeleton
      notScrollable={notScrollable}
      loading={loading}
    >
      {orderedData.map(({ cac, roas, platform, attributionModel }, i) => {
        return (
          <EditableBenchmarkReviewCard
            key={`${platform}-${i}`}
            platform={platform}
            cac={cac}
            roas={roas}
            label={
              showLabels
                ? PrettyBenchmarkAttributionModelNames[attributionModel]
                : null
            }
            index={i}
            loading={!!loading}
            control={control}
            register={register}
            neverGreyOut
            ignoreNotEnoughData
            benchmarkTarget={benchmarkTargetsByPlatform[platform]}
            benchmarkType={benchmarkType}
          />
        );
      })}
    </BenchmarkPlatformMetricsGridSkeleton>
  );
};
