import { Button } from "@/lib/buttons";
import { Modal } from "@components/shared/modals";
import { downloadCsv } from "@utils/csv";
import React from "react";

interface ExportToCsvModalProps {
  table: any;
  dailyDataTable: any;
  setExportToCsvModalOpen: (arg0: boolean) => void;
  exportToCsvModalOpen: boolean;
}

export const ExportToCsvModal = ({
  table,
  dailyDataTable,
  setExportToCsvModalOpen,
  exportToCsvModalOpen,
}: ExportToCsvModalProps) => {
  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={() => {
          setExportToCsvModalOpen(true);
        }}
      >
        Export to CSV
      </button>
      <Modal isOpen={exportToCsvModalOpen}>
        <Button
          className="my-2"
          outline
          kind="info"
          onClick={() =>
            downloadCsv("Sales", "north-beam-sales-report.csv", table)
          }
        >
          Export Aggregated Data
        </Button>

        <br />

        <Button
          outline
          className="mb-3"
          kind="info"
          onClick={() =>
            downloadCsv(
              "DailySalesData",
              "north-beam-sales-daily-report.csv",
              dailyDataTable,
            )
          }
        >
          Export Data Broken Down by Day
        </Button>

        <br />

        <Button kind="link" onClick={() => setExportToCsvModalOpen(false)}>
          Close
        </Button>
      </Modal>
    </>
  );
};
