import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import React from "react";

interface SelectedMetricProps {
  savedView: { id: string; title: string; isDefault: boolean };
}

export function SelectedSavedView({ savedView }: SelectedMetricProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id: savedView.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        "m-2 rounded-md  bg-[#F5F8FE] hover:bg-[#F8F8FE]",
        "hover:border-[#F8F8FE] text-[12px] font-light pl-2 pr-2 py-3 ",
        "flex items-center",
      )}
      style={style}
    >
      <i
        ref={setActivatorNodeRef}
        className="fa-solid fa-grip-dots text-cosmic cursor-move mr-2"
        {...attributes}
        {...listeners}
      ></i>
      <span className="text-black">{savedView.title}</span>
      {savedView.isDefault && (
        <span className="font-bold ml-2 text-cosmic">default</span>
      )}
      {!savedView.isDefault && (
        <i className="fa-regular fa-trash ml-auto cursor-pointer mr-2" />
      )}
    </div>
  );
}
