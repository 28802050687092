import {
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  SearchPanel,
  TableColumnResizing,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { LoadingPanel } from "@pages/sales/tab-table-section/table/plugins";
import {
  TableHeaderRowContent,
  TableHeaderRowSortLabel,
} from "@pages/sales/tab-table-section/table/table-components";
import classNames from "classnames";
import React, { useMemo } from "react";

interface SimulatedLabelResultsProps {
  simulatedTitle: string;
  simulatedData: any[];
  isLoading: boolean;
}

export const SimulatedLabelResults = ({
  simulatedTitle,
  simulatedData,
  isLoading,
}: SimulatedLabelResultsProps) => {
  const columnConfigs = useMemo(() => {
    return [
      {
        title: "Name",
        name: "name",
        getCellValue: (row: any) => {
          return row?.metadata?.name || "(None)";
        },
      },
      {
        title: "Matched",
        name: "matched",
        getCellValue: (row: any) => row.matchResult.outputValue || "(No match)",
      },
    ];
  }, []);

  const defaultColumnWidths = useMemo(
    () => [
      { columnName: "name", width: 500 },
      { columnName: "matched", width: 150 },
    ],
    [],
  );

  return (
    <div className="p-4 overflow-y-auto h-full">
      {simulatedTitle && (
        <>
          <h3 className="font-semibold mb-3.5">
            Breakdown Simulation: {simulatedTitle}
          </h3>

          <Grid rows={simulatedData} columns={columnConfigs}>
            <SearchState />
            <SortingState
              defaultSorting={[{ columnName: "matched", direction: "desc" }]}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <VirtualTable
              height="calc(100vh - 375px)"
              rowComponent={TableRow}
            />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow
              showSortingControls
              contentComponent={TableHeaderRowContent}
              sortLabelComponent={TableHeaderRowSortLabel}
            />
            <Toolbar />
            <SearchPanel />
            <LoadingPanel isLoading={isLoading} />
          </Grid>
        </>
      )}
    </div>
  );
};

const TableRow = ({ row, ...restProps }: any) => {
  const isMatched = !!row.matchResult.outputValue;

  return (
    <VirtualTable.Row
      {...restProps}
      data-test={isMatched ? "matched" : undefined}
      className={classNames({ "bg-green-100": isMatched })}
    />
  );
};
