import React from "react";
import { IconButton } from "./icon-button";

interface DeleteIconButtonProps {
  className?: string;
  onClick?: () => void;
}

export const DeleteIconButton = ({
  className,
  onClick,
  ...restProps
}: DeleteIconButtonProps) => {
  return (
    <IconButton className={className} onClick={onClick} {...restProps}>
      <i className="fa-regular fa-trash-can text-primary" />
    </IconButton>
  );
};
