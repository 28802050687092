import React from "react";
import Select from "react-select";
import { ColumnSet, IColumnSet } from "@utils/constants";

interface ColumnSetSelectProps {
  className?: string;
  isLoading: boolean;
  columnSet: IColumnSet;
  onChange: (obj: any) => void;
}

export const ColumnSetSelect = ({
  className,
  isLoading,
  columnSet,
  onChange,
}: ColumnSetSelectProps) => (
  <Select
    className={className}
    isDisabled={isLoading}
    isClearable={false}
    isSearchable={false}
    value={ColumnSet.toReactSelectUnsafe(columnSet)}
    options={ColumnSet.reactSelectOptions}
    onChange={onChange}
  />
);
