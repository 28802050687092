import React from "react";

interface CardProps {
  title: string;
  logo: React.ReactNode;
  onClick(): void;
}

export function Card({ title, logo, onClick }: CardProps) {
  return (
    <button
      onClick={(ev) => {
        ev.preventDefault();
        onClick();
      }}
      className="display-block hover:cursor-pointer w-[250px] h-[200px] border-3
                 border-[#EEF1FB] hover:border-[#C6D8F9] rounded-2xl no-underline
                 text-xs text-zinc-500 font-light grid grid-rows-[auto_2.5rem]"
    >
      <span className="flex justify-center self-stretch mt-7">{logo}</span>
      <span className="text-center">{title}</span>
    </button>
  );
}
