import { MetricCustomizationModal } from "@shared/metric-customization-modal";
import { MetricConfig } from "@shared/metric-customization-modal/metric-category-builder";
import { SalesPageMetric } from "@utils/metrics";
import React, { useMemo } from "react";
import { useSalesViews } from "../sales-views-context";

interface CustomizeChartMetricsModalProps {
  graphMetricBoxes: string[];
  setGraphMetricBoxes: (arg0: string[]) => void;
  customizableMetricsArray: SalesPageMetric[];
  setSelectedMetricIndices: (arg0: number[]) => void;
}

export const CustomizeChartMetricsModal = ({
  graphMetricBoxes,
  setGraphMetricBoxes,
  customizableMetricsArray,
  setSelectedMetricIndices,
}: CustomizeChartMetricsModalProps) => {
  const { saveSalesViewsSelectedMetricBoxes } = useSalesViews();

  const onSave = (newMetrics: MetricConfig[]) => {
    const metricsToDisplay = newMetrics
      .filter((metric) => metric.visible)
      .map((metric) => metric.name);
    setSelectedMetricIndices([0]);
    setGraphMetricBoxes(metricsToDisplay);
    saveSalesViewsSelectedMetricBoxes(metricsToDisplay);
  };

  const metrics = useMemo(
    () =>
      customizableMetricsArray.map((metric) => ({
        name: metric.name,
        visible: graphMetricBoxes.includes(metric.name),
      })),
    [graphMetricBoxes, customizableMetricsArray],
  );

  return (
    <MetricCustomizationModal
      title="Chart metrics"
      metrics={metrics}
      onSave={onSave}
    />
  );
};
