import { useUser } from "@components/user-context";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useMemo } from "react";

const navbarExpandedAtom = atomWithStorage<boolean>("v-navbar", false);

export const useNavbarExpanded = () => {
  const { user } = useUser();
  const [_expanded, setExpanded] = useAtom(navbarExpandedAtom);
  const expanded = useMemo(() => {
    if (!user.featureFlags.enableDesignRefresh) {
      return true;
    }
    return _expanded;
  }, [_expanded, user.featureFlags.enableDesignRefresh]);
  return [expanded, setExpanded] as const;
};
