import DataExportFile from "@assets/data-export-file.svg";
import DataExportGcs from "@assets/data-export-gcs.svg";
import React from "react";
import { Step } from "../../types";
import { Card } from "./card";

interface Props {
  onSelect(step: Step): void;
}

export function ExportMethodSelector({ onSelect }: Props) {
  return (
    <div className="h-full grid grid-rows-[25%_auto] overflow-auto">
      <div className="flex flex-col items-center justify-center mt-5">
        <h3 className="text-[1rem] font-semibold m-0 mb-1">
          Select your preferred method of export
        </h3>
        <p className="text-xs text-zinc-500 font-light m-0">
          (You can always update this method later)
        </p>
      </div>
      <div
        className="flex flex-wrap justify-center content-start
                   gap-14 mt-5 pl-20 pr-20"
      >
        <Card
          onClick={() => onSelect("gcs-configurator")}
          title="Google Cloud Storage"
          logo={
            <img
              className="w-[80px]"
              alt="data-export-logo"
              src={DataExportGcs}
            />
          }
        />
        <Card
          onClick={() => onSelect("metrics-configurator")}
          title="CSV export"
          logo={
            <img
              className="w-[60px]"
              alt="data-export-logo"
              src={DataExportFile}
            />
          }
        />
      </div>
    </div>
  );
}
