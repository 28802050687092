import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";

export function Rakuten({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to create custom and pre-defined utm parameters for{" "}
        <b>Rakuten</b>. For more guidance along the way, please refer to their
        official{" "}
        <GenericExternalLink href="https://drive.google.com/file/d/1f9AYCRX115mHrl3FbUCRgwIrua3xsPqY/view?usp=sharing">
          documentation
        </GenericExternalLink>
        . You will be using the <b>Link parameter tool</b>, which can be found
        under the Links drop-down menu in the advertiser dashboard.
      </p>
      <p>
        First, add an <b>Advertiser</b> parameter with name{" "}
        <code>nb_platform</code> and value <code>rakuten</code>.
      </p>
      <p>
        Then, add the following <b>LinkShare</b> parameters:
      </p>
      <table className="table table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code>nb_publisher_name</code>
            </td>
            <td>Publisher Name</td>
          </tr>
          <tr>
            <td>
              <code>nb_publisher_sid</code>
            </td>
            <td>Publisher SID</td>
          </tr>
          <tr>
            <td>
              <code>nb_creative_id</code>
            </td>
            <td>Creative ID</td>
          </tr>
          <tr>
            <td>
              <code>nb_creative_type</code>
            </td>
            <td>Creative Type</td>
          </tr>
          <tr>
            <td>
              <code>nb_offer_id</code>
            </td>
            <td>Offer ID</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
