import { customTooltip, LineWithLine } from "@components/reports/charts";
import { H1, NumberInput } from "@components/utilities";
import styled from "@emotion/styled";
import {
  formatDate,
  formatDateFull,
  formatNumberApproximate,
  formatNumberExact,
  shiftISODate,
} from "@north-beam/nb-common";
import { ChartDataset, ChartOptions, TooltipItem } from "chart.js";
import chroma from "chroma-js";
import _ from "lodash";
import moment from "moment";
import Papa from "papaparse";
import React from "react";
import { CellProps, Column, Row, useTable } from "react-table";

const BREAKDOWN_DATA = `
platform	spend	rev	depth	cap
Google Ads	191998.2646	660403.962	5326.825851	84667.17462
Facebook Ads	186159.4657	136721.1008	8450.368854	26292.51938
Microsoft Ads	14663.28	51147.89835	1390.926855	19672.2686
Tatari	3051.12	5665.2	584.7927569	4357.846154
Pinterest	2105.12	5304.9024	81.40389719	887.1074247
`;

// 2020-12-30	10623.33624	49009.44146
// 2020-12-31	11246.46234	54385.37474
const TIMESERIES_DATA = `
date\tspend\trevenue
2021-01-01\t20277.47594\t73372.5036
2021-01-02\t25511.55276\t72493.57862
2021-01-03\t36662.07114\t66187.2245
2021-01-04\t20365.67825\t59273.70376
2021-01-05\t15436.11627\t64197.06544
2021-01-06\t14048.64958\t54928.90827
2021-01-07\t16577.08513\t60389.26993
2021-01-08\t13968.51317\t57216.67593
2021-01-09\t14960.87569\t57171.54465
2021-01-10\t15040.57577\t50197.92425
2021-01-11\t12071.55402\t48203.95027
2021-01-12\t13470.48520\t51205.75171
2021-01-13\t12756.13717\t46619.34198
2021-01-14\t15229.76572\t58642.10204
2021-01-15\t9319.456526\t50792.11066
2021-01-16\t14951.12073\t53879.04381
2021-01-17\t15077.86769\t55035.43485
2021-01-18\t10896.92390\t49492.78867
2021-01-19\t11839.68906\t46155.35201
2021-01-20\t13061.41434\t54038.70104
2021-01-21\t11179.73430\t55402.97543
2021-01-22\t10367.54685\t56234.93533
2021-01-23\t14126.27817\t54153.07788
2021-01-24\t12787.94675\t53112.37666
2021-01-25\t11590.03386\t50066.17527
2021-01-26\t10519.32158\t54743.16113
`;

const START_OF_QUARTER = "2021-01-01";
const END_OF_QUARTER = "2021-04-01";
const fontFamily = "Inter";
const baseFudgeFactor = 0.2;
const fudgeFactor = baseFudgeFactor / 60;
const revenueColor = "#27a744";

export function ProgressPage() {
  const breakdownData: any[] = parseTSV(BREAKDOWN_DATA).data;
  const chartData: any[] = parseTSV(TIMESERIES_DATA).data;
  const totalChartSpend = _.chain(chartData).map("spend").sum().value();
  const totalBreakdownSpend = _.chain(breakdownData).map("spend").sum().value();
  const totalChartRevenue = _.chain(chartData).map("revenue").sum().value();
  const totalBreakdownRevenue = _.chain(breakdownData).map("rev").sum().value();

  const rescaledChartData: any[] = chartData.map(
    ({ spend, revenue, date }) => ({
      date,
      spend: (spend / totalChartSpend) * totalBreakdownSpend,
      revenue: (revenue / totalChartRevenue) * totalBreakdownRevenue,
    }),
  );

  const [spendOverrides, setSpendOverrides] = React.useState<any>({});
  const updateSpendOverrides = React.useCallback(
    (updates: Record<string, number | null>) => {
      const rv = { ...spendOverrides };
      for (const key in updates) {
        const value = updates[key];
        if (value === null) {
          delete rv[key];
        } else {
          rv[key] = value;
        }
      }
      setSpendOverrides(rv);
    },
    [spendOverrides, setSpendOverrides],
  );

  const today = chartData[chartData.length - 1].date;

  const cumulativeTs = cumulativeSumTimeseries(
    rescaledChartData,
    breakdownData,
    spendOverrides,
  );

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col">
          <div
            className="btn-group btn-group-toggle mt-5"
            role="group"
            aria-label="Basic example"
          >
            <label className="btn btn-outline-primary">
              <input type="radio" name="options" id="option1" /> Monthly
            </label>
            <label className="btn btn-outline-primary active">
              <input type="radio" name="options" id="option1" checked />{" "}
              Quarterly
            </label>
            <label className="btn btn-outline-primary">
              <input type="radio" name="options" id="option1" /> Yearly
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <H1>Quarterly progress</H1>
          <div className="flex items-center" style={{ marginBottom: "1rem" }}>
            <button className="btn btn-link btn-sm" disabled>
              <i className="fas fa-chevron-left"></i>
            </button>
            <div>
              Q1 ({formatDateFull(START_OF_QUARTER)} –{" "}
              {formatDateFull(shiftISODate(END_OF_QUARTER, -1))})
            </div>
            <button className="btn btn-link btn-sm">
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ProgressChart data={cumulativeTs} today={today} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ProgressBreakdown
            today={today}
            data={breakdownData}
            spendOverrides={spendOverrides}
            updateSpendOverrides={updateSpendOverrides}
          />
        </div>
      </div>
    </div>
  );
}

function ProgressChart({ data, today }: { data: any[]; today: any }) {
  const color1 = "#007bff";
  const color2 = revenueColor;

  const spend = data.map((v) => ({ x: v.date, y: v.spend }));
  const revenue = data.map((v) => ({ x: v.date, y: v.revenue }));

  const exactSpend = spend.map((v) => ({
    ...v,
    y: v.x <= today ? v.y : undefined,
  }));
  const exactRevenue = revenue.map((v) => ({
    ...v,
    y: v.x <= today ? v.y : undefined,
  }));

  const projectedSpend = spend.map((v) => ({
    ...v,
    y: v.x > today ? v.y : undefined,
  }));
  const projectedRevenue = revenue.map((v) => ({
    ...v,
    y: v.x > today ? v.y : undefined,
  }));

  const datasets: ChartDataset<any>[] = [
    makeDataset(
      {
        label: "Spend",
        data: exactSpend,
      },
      color1,
    ),
    makeDataset(
      {
        label: "Revenue",
        data: exactRevenue,
      },
      color2,
    ),
    makeDataset(
      {
        label: "Spend (est)",
        data: projectedSpend,
        borderDash: [5, 5],
      },
      color1,
    ),
    makeDataset(
      {
        label: "Revenue (forecast)",
        data: projectedRevenue,
        borderDash: [0, 5],
        pointHoverRadius: 0,
      },
      color2,
    ),
    makeDataset(
      {
        label: "[HIDE] Revenue (Forecast, Lower)",
        data: projectedRevenue.map((v) => ({
          ...v,
          y: v.y * (1 - diffISODates(v.x, today) * fudgeFactor),
        })),
        backgroundColor: chroma(color2).alpha(0.3).hex("rgba"),
        borderDash: [5, 5],
      },
      color2,
    ),
    makeDataset(
      {
        label: "[HIDE] Revenue (Forecast, Upper)",
        data: projectedRevenue.map((v) => ({
          ...v,
          y: v.y * (1 + diffISODates(v.x, today) * fudgeFactor),
        })),
        backgroundColor: chroma(color2).alpha(0.3).hex("rgba"),
        fill: "-1",
        borderDash: [5, 5],
      },
      color2,
    ),
  ];

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "start",
        labels: {
          filter: function (item: any) {
            return (
              !item.text?.startsWith("[HIDE]") && !item.text?.includes("(")
            );
          },
        },
      },
      tooltip: {
        enabled: false,
        mode: "index",
        intersect: false,
        external: customTooltip,
        bodyFont: {
          family: fontFamily,
        },
        titleFont: {
          family: fontFamily,
        },
        itemSort: (a: TooltipItem<"line">, b: TooltipItem<"line">) =>
          b.label.localeCompare(a.label),
        callbacks: {
          label: ({ datasetIndex, dataIndex, raw }: TooltipItem<"line">) => {
            if (datasetIndex < 2) {
              return formatNumberExact(parseFloat((raw as any).y), "dollars");
            }

            if (datasetIndex === 3) {
              const hi = formatNumberApproximate(
                datasets[4].data[dataIndex].y,
                "dollars",
              );
              const lo = formatNumberApproximate(
                datasets[5].data[dataIndex].y,
                "dollars",
              );
              return `${hi} – ${lo}`;
            }

            return formatNumberApproximate(
              parseFloat((raw as any).y),
              "dollars",
            );
          },
          title: (vs: any) =>
            vs.map((v: any) => (v.label && formatDate(v.label)) || "N/A")[0],
          beforeLabel: ((v: any) => datasets[v.datasetIndex!].label) as any,
        },
      },
    },
    scales: {
      y: {
        position: "right",
        grid: {
          tickLength: 0,
        },
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 4,
          padding: 10,
          callback: (value: any) =>
            formatNumberApproximate(Number(value), "dollars"),
          font: {
            family: fontFamily,
          },
        },
        afterFit: function (scaleInstance) {
          scaleInstance.width = 60;
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
          display: false,
        },
        ticks: {
          padding: 5,
          autoSkip: true,
          maxRotation: 0,
          maxTicksLimit: 5,
          font: {
            family: fontFamily,
          },
        },
        type: "timeseries",
        time: { unit: "day", parser: "YYYY-MM-DD" },
      },
    },
  };

  return <LineWithLine data={{ datasets }} options={options} height={60} />;
}

interface ProgressBreakdownProps {
  data: any;
  spendOverrides: any;
  updateSpendOverrides: any;
  today: string;
}

function ProgressBreakdown({
  data,
  spendOverrides,
  updateSpendOverrides,
  today,
}: ProgressBreakdownProps) {
  interface MappedRow {
    platform: string;
    spend: number;
    revenue: number;
    dailySpend: number;
    totalSpend: number;
    forecastRevLower: number;
    forecastRevPoint: number;
    forecastRevUpper: number;
  }

  const mappedRows = React.useMemo(() => {
    const rv: MappedRow[] = [];
    for (const row of data) {
      const { platform, spend, rev } = row;
      const dailySpend =
        spendOverrides[platform] ??
        spend / diffISODates(today, START_OF_QUARTER);
      const rows = cumulativeSumTimeseries(
        [{ date: today, spend, revenue: rev }],
        [row],
        spendOverrides,
      );
      const lastRow = rows[rows.length - 1];
      rv.push({
        platform,
        spend,
        revenue: rev,
        dailySpend,
        totalSpend: lastRow.spend,
        forecastRevPoint: lastRow.revenue,
        forecastRevLower:
          lastRow.revenue *
          (1 - fudgeFactor * diffISODates(END_OF_QUARTER, today)),
        forecastRevUpper:
          lastRow.revenue *
          (1 + fudgeFactor * diffISODates(END_OF_QUARTER, today)),
      });
    }
    return rv;
  }, [data, spendOverrides, today]);

  const totalRow: MappedRow = {
    platform: "Total",
    spend: _.chain(mappedRows).map("spend").sum().value(),
    revenue: _.chain(mappedRows).map("revenue").sum().value(),
    dailySpend: _.chain(mappedRows).map("dailySpend").sum().value(),
    totalSpend: _.chain(mappedRows).map("totalSpend").sum().value(),
    forecastRevLower: _.chain(mappedRows).map("forecastRevLower").sum().value(),
    forecastRevPoint: _.chain(mappedRows).map("forecastRevPoint").sum().value(),
    forecastRevUpper: _.chain(mappedRows).map("forecastRevUpper").sum().value(),
  };

  const columns: any[] = React.useMemo(
    () =>
      [
        {
          accessor: "platform",
          Header: "Platform",
        },
        {
          accessor: "spend",
          Header: "Q1 Spend (to date)",
          className: "text-right",
          Cell: (props: CellProps<MappedRow>) => (
            <div>{formatNumberExact(props.value, "dollars")}</div>
          ),
        },
        {
          accessor: "revenue",
          Header: "Q1 Revenue (to date)",
          className: "text-right",
          Cell: (props: CellProps<MappedRow>) => {
            const revenue = formatNumberExact(props.value, "dollars");
            const roas = formatNumberApproximate(
              props.value / props.row.original.spend,
              "multiplier",
            );
            return (
              <div>
                <div className="text-right">{revenue}</div>
                <div className="text-right mt-2">
                  <small>({roas})</small>
                </div>
              </div>
            );
          },
        },
        {
          accessor: "dailySpend",
          Header: "Q1 Daily spend (est)",
          className: "text-right",
          Cell: (props: CellProps<MappedRow>) => {
            const { platform, dailySpend, totalSpend } = props.row.original;
            let value = dailySpend;
            value = Math.floor(value);

            return (
              <div>
                <div className="d-inline-block" style={{ width: "12em" }}>
                  <div className="input-group text-right">
                    <NumberInput
                      format="dollars"
                      disabled={platform === "Total"}
                      value={value}
                      onValueChange={(newValue) => {
                        if (typeof newValue === "number") {
                          updateSpendOverrides({
                            [platform]: newValue ?? null,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <small>
                    ({formatNumberApproximate(totalSpend, "dollars")} total this
                    quarter)
                  </small>
                </div>
              </div>
            );
          },
        },
        {
          id: "forecastRev",
          Header: "Q1 Revenue (forecast)",
          className: "text-right",
          Cell: (props: CellProps<MappedRow>) => {
            const { forecastRevLower, forecastRevUpper, totalSpend } =
              props.row.original;

            const lowerRev = formatNumberApproximate(
              forecastRevLower,
              "dollars",
            );
            const upperRev = formatNumberApproximate(
              forecastRevUpper,
              "dollars",
            );

            const lowerRoas = formatNumberApproximate(
              forecastRevLower / totalSpend,
              "multiplier",
            );
            const upperRoas = formatNumberApproximate(
              forecastRevUpper / totalSpend,
              "multiplier",
            );

            const style = { flex: "1 0 0" };

            return (
              <div className="flex">
                <div style={style}>
                  <div className="text-right">{lowerRev}</div>
                  <div className="text-right mt-2">
                    <small>({lowerRoas})</small>
                  </div>
                </div>
                <div className="items-center text-center">
                  &nbsp;&ndash;&nbsp;
                </div>
                <div>
                  <div className="text-right">{upperRev}</div>
                  <div className="text-right mt-2">
                    <small>({upperRoas})</small>
                  </div>
                </div>
              </div>
            );
          },
        },
      ] as (Column<MappedRow> | { className: string })[],
    [updateSpendOverrides],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<MappedRow>({
      columns,
      data: [totalRow, ...mappedRows],
      autoResetGlobalFilter: false,
      initialState: { pageSize: 20 },
    });

  const RenderRow = React.useCallback(
    (row: Row<any>) => {
      prepareRow(row);
      const style = (row as any).style;
      return (
        <tr {...row.getRowProps({ style })}>
          {row.cells.map((cell) => (
            // eslint-disable-next-line
            <td
              {...cell.getCellProps({
                className: (cell.column as any).className,
              })}
            >
              {cell.render("Cell")}
            </td>
          ))}
        </tr>
      );
    },
    [prepareRow],
  );

  return (
    <TableStyle>
      <table
        {...getTableProps({ style: { fontFeatureSettings: "tnum" } })}
        className="table table-bordered my-5"
      >
        <thead style={{ backgroundColor: "#FBFAFA", fontWeight: 500 }}>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line
                <th
                  {...column.getHeaderProps({
                    className: (column as any).className,
                  })}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>{rows.map(RenderRow)}</tbody>
      </table>
    </TableStyle>
  );
}

function makeDataset(
  config: Partial<ChartDataset<any>>,
  color: string,
): ChartDataset<any> {
  return {
    lineTension: 0,
    fill: false,
    backgroundColor: color,
    borderColor: color,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    borderWidth: 2,
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointBorderWidth: 0,
    pointHoverRadius: 2.5,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointHoverBorderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 0,
    spanGaps: false,
    ...config,
  };
}

function parseTSV(data: string) {
  return Papa.parse(data, {
    skipEmptyLines: true,
    header: true,
    dynamicTyping: true,
    delimiter: "\t",
  });
}

function diffISODates(d1: string, d2: string) {
  return moment(d1).diff(moment(d2), "days");
}

function cumulativeSumTimeseries(
  timeSeries: any,
  breakdownData: any,
  spendOverrides: any,
) {
  const rv: any[] = [];
  timeSeries = _.sortBy(timeSeries, "date");
  for (let row of timeSeries) {
    row = { ...row };
    if (rv.length > 0) {
      row.spend += rv[rv.length - 1].spend;
      row.revenue += rv[rv.length - 1].revenue;
    }
    rv.push(row);
  }

  const today = rv[rv.length - 1].date;

  while (rv[rv.length - 1].date < END_OF_QUARTER) {
    let { date, revenue, spend } = rv[rv.length - 1];
    date = shiftISODate(date, 1);
    for (const row of breakdownData) {
      let spend_ = spendOverrides[row.platform];
      if (typeof spend_ !== "number") {
        spend_ = row.spend / diffISODates(today, START_OF_QUARTER);
      }

      const cap = row.cap;
      const depth = row.depth;
      const revenue_ = cap * (1 / (1 + Math.exp(-spend_ / depth)) - 0.5);

      spend += spend_;
      revenue += revenue_;
    }

    rv.push({ date, spend, revenue });
  }

  return rv;
}

const TableStyle = styled.div`
  th {
    font-weight: 500;
  }

  tbody tr:first-child {
    background-color: --light;
    border-bottom: 3px solid #dee2e6 !important;
  }
`;
