import { gql } from "@apollo/client";
import {
  ColumnOrdering as ColumnOrderingQuery,
  ColumnOrderingVariables as ColumnOrderingQueryVariables,
} from "@nb-api-graphql-generated/ColumnOrdering";
import {
  SetColumnOrdering,
  SetColumnOrderingVariables,
} from "@nb-api-graphql-generated/SetColumnOrdering";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";

export const COLUMN_ORDERING_STRING_FOR_SALES_PAGE_PRIMARY_TABLE =
  "NorthbeamSalesPagePrimaryTable";

export const useColumnOrdering = () => {
  const { data, loading: columnOrderingLoading } = useNorthbeamQuery<
    ColumnOrderingQuery,
    ColumnOrderingQueryVariables
  >(COLUMN_ORDERING_QUERY, {
    variables: {
      tableName: COLUMN_ORDERING_STRING_FOR_SALES_PAGE_PRIMARY_TABLE,
    },
  });

  const [setColumnOrdering] = useNorthbeamMutation<
    SetColumnOrdering,
    SetColumnOrderingVariables
  >(SET_COLUMN_ORDERING, {
    refetchQueries: [
      {
        query: COLUMN_ORDERING_QUERY,
        variables: {
          tableName: COLUMN_ORDERING_STRING_FOR_SALES_PAGE_PRIMARY_TABLE,
        },
      },
    ],
  });

  return {
    columnOrdering: data?.me?.columnOrdering || [],
    columnOrderingLoading,
    setColumnOrdering,
  };
};

const HACK_COLUMNS_TO_MATCH = [
  "Attributed Rev",
  "ROAS",
  "ECR",
  "CAC",
  "Txns",
  "Email Signups",
  "Email Signup Rate",
  "Cost per Email Signup",
];

export const HACK_columnMatching = (
  columnKey: string,
  columnKey2?: string,
): boolean => {
  const matchedNameIndex = HACK_COLUMNS_TO_MATCH.findIndex(
    (name) => columnKey.startsWith(name) && columnKey2?.startsWith(name),
  );
  const isMatching = matchedNameIndex !== -1;
  // To ignore the attribution window in the column name
  if (
    columnKey.endsWith("accrual") &&
    columnKey2?.endsWith("accrual") &&
    // infinity is forecasted so we don't want to rename it
    !columnKey.includes("infinity") &&
    !columnKey2?.includes("infinity") &&
    isMatching
  ) {
    return true;
  }

  return columnKey === columnKey2;
};

export const COLUMN_ORDERING_QUERY = gql`
  query ColumnOrdering($tableName: String!) {
    me {
      id
      columnOrdering(tableName: $tableName)
    }
  }
`;

export const SET_COLUMN_ORDERING = gql`
  mutation SetColumnOrdering($columnOrdering: [String!]!, $tableName: String!) {
    setColumnOrdering(columnOrdering: $columnOrdering, tableName: $tableName)
  }
`;
