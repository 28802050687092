import styled from "@emotion/styled";
import { MetricFormat } from "@north-beam/nb-common";
import {
  formatNumberApproximate,
  formatNumberExact,
} from "@north-beam/nb-common";
import { pickColorExtraLight } from "@pages/objects/label-colors";
import { AdIcon } from "@pages/objects/utils";
import chroma from "chroma-js";
import Tooltip from "rc-tooltip";
import React, { SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import { useUser } from "./user-context";

interface PathElement {
  name: string;
  objectId: string | null;
  nbtPlatformID: string | null;
  count?: number;
  referencesObjectIdInQuestion?: boolean;
  creditFraction?: number;
}

export function PathElementList({
  pathElements,
  adObjectReportParams,
  pillWidthEM,
}: {
  pathElements: PathElement[];
  adObjectReportParams: string;
  pillWidthEM?: number;
}) {
  const rv: React.ReactNodeArray = [];
  for (let i = 0; i < pathElements.length; ++i) {
    const element = pathElements[i];
    rv.push(
      <PathElementComponent
        key={JSON.stringify([i, element])}
        element={element}
        pillWidthEM={pillWidthEM}
        adObjectReportParams={adObjectReportParams}
      />,
    );
  }
  return <div className="nb-path-elements">{rv}</div>;
}

export function PathElementListUnordered({
  pathElements,
  adObjectReportParams,
  pillWidthEM,
}: {
  pathElements: PathElement[];
  adObjectReportParams: string;
  pillWidthEM?: number;
}) {
  const [isExpanded, setExpanded] = React.useState(false);
  const expand = React.useCallback(
    (e: SyntheticEvent) => {
      setExpanded(true);
      e.stopPropagation();
    },
    [setExpanded],
  );
  const collapse = React.useCallback(
    (e: SyntheticEvent) => {
      setExpanded(false);
      e.stopPropagation();
    },
    [setExpanded],
  );

  let elements: (PathElement | null)[] = pathElements;
  if (pathElements.length > 3 && !isExpanded) {
    elements = [elements[0], elements[1], null];
  }

  const rv: React.ReactNodeArray = [];
  for (let i = 0; i < elements.length; ++i) {
    const element = elements[i];
    if (element) {
      rv.push(
        <PathElementComponent
          key={JSON.stringify([i, element])}
          element={element}
          pillWidthEM={pillWidthEM}
          adObjectReportParams={adObjectReportParams}
        />,
      );
    } else {
      rv.push(
        <div
          key={`expander-${i}`}
          className="nb-path-element nb-path-element-expander"
          onClick={expand}
        >
          <Pill color="white" title="Click to expand">
            &hellip;
          </Pill>
        </div>,
      );
    }
  }

  return (
    <div className="nb-path-elements-unordered" onClick={collapse}>
      {rv}
    </div>
  );
}

export function PathElementComponent({
  element,
  adObjectReportParams,
  pillWidthEM,
}: {
  element: PathElement;
  adObjectReportParams: string;
  pillWidthEM?: number;
}) {
  const {
    name,
    objectId,
    nbtPlatformID,
    count,
    referencesObjectIdInQuestion,
    creditFraction,
  } = element;
  const { user } = useUser();
  const color = pickColorExtraLight(name);

  const multiplier = count && count > 1 ? <b>&nbsp;{`(${count}×)`}</b> : "";

  const nameComponent = (
    <Tooltip
      placement="top"
      mouseLeaveDelay={0}
      mouseEnterDelay={0}
      destroyTooltipOnHide={true}
      overlay={name}
    >
      <span>{name}</span>
    </Tooltip>
  );

  let inside = objectId ? (
    <Link
      className="text-truncate"
      to={
        user.pathFromRoot(`/objects/${objectId}`) + `?${adObjectReportParams}`
      }
    >
      {nameComponent}
    </Link>
  ) : (
    <span className="text-truncate">{nameComponent}</span>
  );

  let percentageDisplay: React.ReactElement | null = null;
  if (referencesObjectIdInQuestion && typeof creditFraction !== "undefined") {
    percentageDisplay = (
      <div
        style={{
          borderLeft: "1px solid #999",
          marginLeft: "0.5em",
          paddingLeft: "0.5em",
          fontWeight: 700,
        }}
      >
        {formatNumberApproximate(creditFraction, "percentage")}
      </div>
    );
  }

  inside = nbtPlatformID ? (
    <div className="flex items-center">
      <AdIcon platform={nbtPlatformID} sizeInEM={1} /> {inside} {multiplier}{" "}
      {percentageDisplay}
    </div>
  ) : (
    <div className="flex items-center">
      {inside} {multiplier} {percentageDisplay}
    </div>
  );

  return (
    <div
      className="nb-path-element"
      style={{
        opacity: referencesObjectIdInQuestion === false ? 0.5 : 1,
      }}
    >
      <Pill color={color} title={name} widthEM={pillWidthEM}>
        {inside}
      </Pill>
    </div>
  );
}

export function safeDivide(a: number | null, b: number | null) {
  if (a === null || b === null || Math.abs(b) < 1e-5) {
    return null;
  }

  return a / b;
}

export function safeExactFormat(num: number | null, format: MetricFormat) {
  if (num === null) {
    return "—";
  }
  return formatNumberExact(num, format);
}

export function safeApproximateFormat(
  num: number | null,
  format: MetricFormat,
) {
  if (num === null) {
    return "—";
  }
  return formatNumberApproximate(num, format);
}

function Pill(props: {
  color: string;
  title?: string;
  children: React.ReactNode | React.ReactNodeArray;
  borderRadius?: string;
  widthEM?: number;
}) {
  const color = chroma(props.color);
  return (
    <div
      title={props.title}
      style={{
        backgroundColor: color.css(),
        color: "black",
        padding: "0em 0.5em",
        borderRadius: "0.25rem",
        margin: "0.125em 0em",
        border: `solid ${color.darken(1).css()}`,
        borderWidth: "1px",
        display: "inline-block",
        lineHeight: "2em",
        maxWidth: `${props.widthEM ?? 20}em`,
      }}
    >
      {props.children}
    </div>
  );
}

export const TableStyle = styled.div`
  .thead {
    margin-bottom: 0.5rem;
  }

  .tr {
    display: flex;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .tbody {
    font-size: 12px;
    .tr {
      border-top: 1px solid #aaaaaa;
    }
    .tr:last-child {
      border-bottom: 1px solid #aaaaaa;
    }
  }

  .thead {
    .tr.nb-tr-0 {
      .td {
        text-align: right;
        display: flex;
        align-items: center;
      }
    }
  }

  .td {
    padding-left: 0.75rem;
  }

  .nb-col-rank.nb-first-in-group {
    margin-left: 2rem;
  }

  .nb-col-rank {
    text-align: right;
  }

  .nb-col-item {
  }

  .nb-col-metric {
    text-align: right;

    .nb-col-metric-header {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      text-align: right;

      .header {
        text-align: right;
      }

      .nb-sort {
        margin-left: 0.5rem;
        margin-right: 0.25rem;
        max-width: 1em;
        width: 1em;
        font-size: 80%;
      }
    }
  }
`;
