import { usePageTitle } from "@/atoms/page-title-atom";
import { LoadingSlide } from "@components/title-slide-view";
import { H1, LightSwitch } from "@components/utilities";
import {
  GetAllAlertConfigs,
  GetAllAlertConfigs_me_alertConfigs as AlertConfigView,
} from "@nb-api-graphql-generated/GetAllAlertConfigs";
import { AlertConfigStatus } from "@nb-api-graphql-generated/global-types";
import {
  ToggleAlertConfig,
  ToggleAlertConfigVariables,
} from "@nb-api-graphql-generated/ToggleAlertConfig";
import { TriggerConditionsV2 } from "@north-beam/nb-common";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import { UnreachableCaseError } from "@utils/index";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FormatAlarmCondition,
  FormatSubject,
} from "./components/humanize-fields";
import { GET_ALL_ALERT_CONFIGS, TOGGLE_ALERT_CONFIG } from "./queries";

export default function AlertConfigList() {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Monitor");
  }, [setPageTitle]);

  return (
    <div className="container pt-4">
      <LogOnMount name="Visit Alerts List Page" />
      <div className="row">
        <div className="col">
          <H1>Monitor</H1>
          <p>
            We&apos;ll keep a watch on your performance, so you don&apos;t have
            to.
          </p>
        </div>
      </div>
      <ListViewWithFilter />
    </div>
  );
}

function ListViewWithFilter() {
  const { pathname } = useLocation();
  const { loading, data } = useNorthbeamQuery<GetAllAlertConfigs>(
    GET_ALL_ALERT_CONFIGS,
    {},
  );
  const [tab, setTab] = React.useState<Tab>(TABS[0]);

  if (loading) {
    return <LoadingSlide />;
  }

  const allAlerts = data?.me.alertConfigs ?? [];

  const sortedAlerts = _.sortBy([...allAlerts], "createdAt").reverse();

  const alerts = sortedAlerts.filter((a) => {
    if (tab === "Active") {
      return a.status === "active";
    } else if (tab === "Inactive") {
      return a.status === "inactive";
    } else if (tab === "Active + Inactive") {
      return a.status === "active" || a.status === "inactive";
    } else if (tab === "Archived") {
      return a.status === "archived";
    } else if (tab === "All") {
      return true;
    } else {
      throw new UnreachableCaseError(tab);
    }
  });

  _.sortBy(alerts, "createdAt");

  return (
    <div className="row" style={{ fontSize: "14px" }}>
      <div className="col">
        <nav className="navbar border-top border-left border-right">
          <ul className="nav nav-pills">
            {TABS.map((t) => (
              <li className="nav-item" key={t}>
                <button
                  className={classNames({
                    btn: 1,
                    "btn-link": 1,
                    "nav-link": 1,
                    "mx-1": 1,
                    active: t === tab,
                  })}
                  onClick={() => setTab(t)}
                >
                  {t}
                </button>
              </li>
            ))}
          </ul>
          <ul className="nav nav-right">
            <li className="nav-item">
              <Link className="btn btn-success" to={`${pathname}/new`}>
                Add Alert
              </Link>
            </li>
          </ul>
        </nav>
        <ListView alerts={alerts}>
          {(alert: AlertConfigView) => (
            <AlertConfigRow key={alert.id} pathname={pathname} alert={alert} />
          )}
        </ListView>
      </div>
    </div>
  );
}

interface TableViewProps {
  alerts: AlertConfigView[];
  children: (alert: AlertConfigView) => React.ReactNode;
}

const TABS = [
  "Active",
  "Active + Inactive",
  "Inactive",
  "Archived",
  "All",
] as const;
type Tab = (typeof TABS)[number];

function ListView(props: TableViewProps) {
  const { alerts, children } = props;

  return (
    <table className="table table-bordered">
      <thead className="thead-light">
        <tr>
          <th style={{ textAlign: "center" }} scope="col">
            Active?
          </th>
          <th scope="col">Name</th>
          <th scope="col">Created</th>
          <th scope="col">Subjects</th>
          <th scope="col">Alarm Conditions</th>
          <th style={{ textAlign: "center" }} scope="col">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {alerts.length === 0 ? (
          <tr>
            <td colSpan={7} align="center">
              No alerts. Click the green button to make one!
            </td>
          </tr>
        ) : (
          alerts.map(children)
        )}
      </tbody>
    </table>
  );
}

interface AlertConfigRowProps {
  pathname: string;
  alert: AlertConfigView;
}

function AlertConfigRow({ pathname, alert }: AlertConfigRowProps) {
  const { id, status, createdAt, name } = alert;
  const triggerConditions: TriggerConditionsV2 = alert.triggerConditions;
  const { subject, alarmCondition } = triggerConditions;

  const [toggleAlertConfig] = useNorthbeamMutation<
    ToggleAlertConfig,
    ToggleAlertConfigVariables
  >(TOGGLE_ALERT_CONFIG, {
    refetchQueries: [{ query: GET_ALL_ALERT_CONFIGS }],
  });

  const alertToggle = React.useCallback(() => {
    const newStatus =
      status === AlertConfigStatus.inactive
        ? AlertConfigStatus.active
        : status === AlertConfigStatus.active
        ? AlertConfigStatus.inactive
        : AlertConfigStatus.inactive;
    toggleAlertConfig({
      variables: {
        id: id,
        status: newStatus,
      },
    });
  }, [toggleAlertConfig, id, status]);

  const archiveAlert = React.useCallback(() => {
    toggleAlertConfig({
      variables: {
        id: id,
        status: AlertConfigStatus.archived,
      },
    });
  }, [toggleAlertConfig, id]);

  const nameElement =
    status === "archived" ? (
      <span>{name}</span>
    ) : (
      <Link to={`${pathname}/view/${id}`}>{name}</Link>
    );

  return (
    <tr key={id}>
      <td align="center">
        <div className="flex justify-content-center">
          <LightSwitch
            size="medium"
            disabled={status === "archived"}
            isSet={status === "active"}
            onChange={alertToggle}
            id={id}
          />
        </div>
      </td>
      <td>{nameElement}</td>
      <td>{moment(createdAt).local().format("MMM D, YYYY")}</td>
      <td>
        <FormatSubject subject={subject} />
      </td>
      <td>
        <FormatAlarmCondition alarmCondition={alarmCondition} />
      </td>
      <td align="center">
        <div className="flex justify-content-center">
          {status !== "archived" ? (
            <button className="btn btn-secondary" onClick={archiveAlert}>
              <i className="fas fa-archive" title="Archive"></i>
            </button>
          ) : (
            <button className="btn btn-secondary" onClick={alertToggle}>
              <i
                className="fas fa-trash-restore"
                title="Restore from archive"
              ></i>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
}
