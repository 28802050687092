import React from "react";

export default function ExpandedTextAd(props: {
  path1: string | null;
  path2: string | null;
  headlinePart1: string | null;
  headlinePart2: string | null;
  headlinePart3: string | null;
  description: string;
  description2: string | null;
  domain?: string;
}) {
  const {
    path1,
    path2,
    headlinePart1,
    headlinePart2,
    headlinePart3,
    description,
    description2,
    domain,
  } = props;

  const headline = [headlinePart1, headlinePart2, headlinePart3]
    .filter((v) => v)
    .join(" | ");
  const displayUrl = [domain ?? "www.mydomain.com", path1, path2]
    .filter((v) => v)
    .join("/");
  const finalDescription = [description, description2]
    .filter((v) => v)
    .join(" ");

  return (
    <div className="adwords-text-ad">
      <button className="btn btn-link headline">{headline}</button>
      <div className="url-line">
        <span className="ad-tag">Ad</span>
        <span className="display-url">{displayUrl}</span>{" "}
        <span className="fa-regular fa-caret-down"></span>
      </div>
      <div>
        <span className="description">{finalDescription}</span>
      </div>
    </div>
  );
}
