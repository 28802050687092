import { gql } from "@apollo/client";
import { useCachedQuery } from "@components/hooks";
import {
  GetOverviewSnapshot,
  GetOverviewSnapshotVariables,
} from "@nb-api-graphql-generated/GetOverviewSnapshot";
import { useNorthbeamQuery } from "@utils/hooks";

export const useCashSnapshot = (variables: any) => {
  const { loading: snapshotLoading, data: _snapshotData } = useNorthbeamQuery<
    GetOverviewSnapshot,
    GetOverviewSnapshotVariables
  >(GET_OVERVIEW_SNAPSHOT, { variables });

  const snapshotData = useCachedQuery(_snapshotData);

  return { snapshotData, snapshotLoading };
};

const GET_OVERVIEW_SNAPSHOT = gql`
  query GetOverviewSnapshot(
    $dateRange: JSONObject!
    $windowSizeDays: Int!
    $breakdown: String!
    $attributionModel: String!
    $filters: [JSONObject!]!
  ) {
    me {
      id
      overviewPage {
        snapshotMetrics(
          dateRange: $dateRange
          windowSizeDays: $windowSizeDays
          attributionModel: $attributionModel
          filters: $filters
        ) {
          name
          groupName
          currentValue
          currentValueDescription
          isPositiveChangeGood
          format
          timeSeriesPointData
          timeSeriesPointLabels
          timeSeriesPointDateRanges
          timeSeriesDisplayMode
          windowSizeDays
        }

        snapshotBreakdownTableRows(
          dateRange: $dateRange
          breakdown: $breakdown
          attributionModel: $attributionModel
          filters: $filters
        )
      }
    }
  }
`;
