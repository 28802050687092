import { User, useUser } from "@components/user-context";
import { IconButton } from "@shared/buttons";
import classNames from "classnames";
import React from "react";
import { Annotation } from "@features/custom-annotations";

interface AnnotationEditProps {
  annotation: Annotation;
  editAnnotation: (annotation: Annotation) => void;
}

const ownedByUserOrAdmin = (user: User, annotation: Annotation) => {
  return user.authedUserId === annotation.userId || user.isAdmin;
};

export const AnnotationEdit = ({
  annotation,
  editAnnotation,
}: AnnotationEditProps) => {
  const { user } = useUser();

  if (!ownedByUserOrAdmin(user, annotation)) {
    return null;
  }

  return (
    <IconButton
      className={classNames(
        "absolute top-1 right-1 bg-white",
        "opacity-0 group-hover/edit:opacity-100",
      )}
      data-testid="edit-annotation"
      onClick={() => editAnnotation(annotation)}
    >
      <i className="fa-solid fa-sharp fa-pen text-primary" />
    </IconButton>
  );
};
