import { gql } from "@apollo/client";
import { H1, LightSwitch } from "@components/utilities";
import { FetchTagBasedOrderExclusions as FetchTagBasedOrderExclusionsQuery } from "@nb-api-graphql-generated/FetchTagBasedOrderExclusions";
import {
  UpdateTagBasedOrderExclusions,
  UpdateTagBasedOrderExclusionsVariables,
} from "@nb-api-graphql-generated/UpdateTagBasedOrderExclusions";
import { TagBasedOrderExclusion } from "@north-beam/nb-common";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Select from "react-select";

interface TagBasedOrderExclusionsForUpsert {
  tagName: string;
  active: boolean;
  tagType: string;
}

const TagBasedOrderExclusionRow = ({
  exclusion,
  loading,
  setTagBasedOrderExclusion,
}: {
  loading: boolean;
  setTagBasedOrderExclusion: (arg0: TagBasedOrderExclusionsForUpsert) => void;
  exclusion: TagBasedOrderExclusionsForUpsert;
}) => {
  const tagTypes = [
    { value: "order", label: "Order" },
    { value: "customer", label: "Customer" },
  ];

  return (
    <tr>
      <td>
        <Select
          isClearable={false}
          isSearchable={false}
          isDisabled={loading}
          onChange={(v) => {
            if (v) {
              setTagBasedOrderExclusion({ ...exclusion, tagType: v.value });
            }
          }}
          value={{
            value: exclusion.tagType,
            label:
              exclusion.tagType.charAt(0).toUpperCase() +
                exclusion.tagType.slice(1) || "Please select...",
          }}
          options={tagTypes}
          placeholder={"Please select..."}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          value={exclusion.tagName}
          disabled={loading}
          onChange={(event) =>
            setTagBasedOrderExclusion({
              ...exclusion,
              tagName: event.target.value,
            })
          }
        />
      </td>
      <td>
        <div className="flex justify-content-center">
          <LightSwitch
            size="medium"
            isSet={exclusion.active}
            disabled={loading || !exclusion.tagName || !exclusion.tagType}
            onChange={() =>
              setTagBasedOrderExclusion({
                ...exclusion,
                active: !exclusion.active,
              })
            }
            id={"light-switch" + exclusion.tagName + Math.random()}
          />
        </div>
      </td>
    </tr>
  );
};

export function ExcludeOrdersByTagContainer() {
  const [synced, setSynced] = useState<boolean>(true);

  const [tagBasedOrderExclusions, setTagBasedOrderExclusions] = useState<
    TagBasedOrderExclusionsForUpsert[]
  >([]);

  const { data, loading } =
    useNorthbeamQuery<FetchTagBasedOrderExclusionsQuery>(
      FETCH_NOTIFICATION_SETTINGS,
    );

  const [UpdateTagBasedOrderExclusions] = useNorthbeamMutation<
    UpdateTagBasedOrderExclusions,
    UpdateTagBasedOrderExclusionsVariables
  >(UPDATE_TAG_BASED_ORDER_EXCLUSIONS);

  useEffect(() => {
    if (data?.me?.tagBasedOrderExclusions) {
      setTagBasedOrderExclusions(
        data.me.tagBasedOrderExclusions.map(
          (exclusion: TagBasedOrderExclusion) => {
            let exclusionTagType, exclusionTag;
            if (exclusion.pattern.includes("@")) {
              [exclusionTagType, exclusionTag] = exclusion.pattern.split("@");

              exclusionTagType = exclusionTagType.substring(
                3,
                exclusionTagType.length - 2,
              );
              exclusionTag = exclusionTag.replace(/\$$/, "");
            } else {
              exclusionTagType = "";
              exclusionTag = exclusion.pattern;
            }

            return {
              tagName: exclusionTag,
              tagType: exclusionTagType,
              active: exclusion.active,
            };
          },
        ),
      );
    }
  }, [data?.me?.tagBasedOrderExclusions]);

  const debouncedOnUpdate = React.useMemo(
    () =>
      _.debounce((newData: TagBasedOrderExclusion[]) => {
        UpdateTagBasedOrderExclusions({
          variables: { tagBasedOrderExclusions: newData },
          refetchQueries: [{ query: FETCH_NOTIFICATION_SETTINGS }],
        });
        setSynced(true);
      }, 500),
    [UpdateTagBasedOrderExclusions, setSynced],
  );

  const setDataWithSync = React.useCallback(
    (newData) => {
      setTagBasedOrderExclusions(newData);
      setSynced(false);
      debouncedOnUpdate(newData);
    },
    [setTagBasedOrderExclusions, debouncedOnUpdate, setSynced],
  );

  const newExclusion: TagBasedOrderExclusionsForUpsert = {
    tagName: "",
    tagType: "",
    active: false,
  };

  return (
    <div className="container pt-4">
      <>
        <H1>Exclude orders by tag</H1>
        <p>
          You can use order tags to exclude certain purchases from attribution.
          You might want to do this if you have wholesale orders, for example.
        </p>
        <hr />
        {!loading && (
          <>
            <div className="row col">
              <div>
                Sync status:{" "}
                {synced ? <i className="fa-regular fa-check" /> : "..."}
              </div>
            </div>
            <div className="row col mt-3">
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th className="text-center th-sm" scope="col">
                      Tag type
                    </th>
                    <th scope="col" className="th-lg">
                      Tag name
                    </th>
                    <th scope="col" className="th-lg">
                      Active
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tagBasedOrderExclusions.map((exclusion, index) => (
                    <TagBasedOrderExclusionRow
                      exclusion={exclusion}
                      loading={loading}
                      key={`Exclusion-${index}`}
                      setTagBasedOrderExclusion={(
                        tagBasedOrderExclusionForUpsert,
                      ) => {
                        const copy = _.cloneDeep(tagBasedOrderExclusions);
                        copy[index] = tagBasedOrderExclusionForUpsert;
                        setDataWithSync(copy);
                      }}
                    />
                  ))}
                  <tr>
                    <td colSpan={4} align="center" valign="middle">
                      <button
                        className="btn btn-link"
                        onClick={() =>
                          setTagBasedOrderExclusions([
                            ...tagBasedOrderExclusions,
                            newExclusion,
                          ])
                        }
                      >
                        Create new exclusion rule
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {loading && <>Please wait...</>}
      </>
    </div>
  );
}

export const FETCH_NOTIFICATION_SETTINGS = gql`
  query FetchTagBasedOrderExclusions {
    me {
      tagBasedOrderExclusions
    }
  }
`;

export const UPDATE_TAG_BASED_ORDER_EXCLUSIONS = gql`
  mutation UpdateTagBasedOrderExclusions(
    $tagBasedOrderExclusions: [JSONObject!]!
  ) {
    updateTagBasedOrderExclusions(
      tagBasedOrderExclusions: $tagBasedOrderExclusions
    )
  }
`;
