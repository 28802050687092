import { useLazyQuery } from "@apollo/client";
import { useValueCache } from "@components/hooks";
import { RegexTips } from "@components/regex-tips";
import { PaginationBar } from "@components/reports/native-table";
import { GenericTooltip, HTMLTooltip } from "@components/tooltip-container";
import { useUser } from "@components/user-context";
import {
  ButtonWithInterceptModal,
  H1,
  InterleaveSpans,
  LightSwitch,
} from "@components/utilities";
import {
  SimulateLabelRuleGroup,
  SimulateLabelRuleGroupVariables,
} from "@nb-api-graphql-generated/SimulateLabelRuleGroup";
import {
  compileRegex,
  extractRegexCaptures,
  LabelRule,
  LabelRuleGroup,
  LabelRuleSimulationResult,
  validateOutputTemplate,
} from "@north-beam/nb-common";
import { jsonHash } from "@north-beam/nb-common";
import { GlobalFilter } from "@pages/objects/ad-object-list";
import { AdIcon } from "@pages/objects/utils";
import { bugsnagClient } from "@utils/analytics";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import classNames from "classnames";
import Interweave from "interweave";
import { RulesLogic } from "json-logic-js";
import { JsonEditor as Editor } from "jsoneditor-react";
import _ from "lodash";
import React from "react";
import { Builder, ImmutableTree, Query } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import { Modal } from "@shared/modals";
import { useLocation } from "react-router-dom";
import {
  CellProps,
  Column,
  Row,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  defaultMatcher,
  jsonLogicToTree,
  MATCHER_CONFIG,
  treeToJsonLogic,
} from "./matcher-config";
import { SIMULATE_LABEL_RULE_GROUP } from "./queries";

export interface LabelRuleGroupEditorProps {
  labelRuleGroup: LabelRuleGroup | null;
  onLabelRulesSave: (
    id: {
      level: string;
      labelKey: string;
    },
    labelRules: LabelRule[],
  ) => void;
  onDiscardChanges: () => void;
  isLoading: boolean;
}

export function LabelRuleGroupEditor({
  labelRuleGroup,
  onLabelRulesSave,
  onDiscardChanges,
  isLoading,
}: LabelRuleGroupEditorProps) {
  const { user } = useUser();
  const { state } = useLocation();
  const isEditing = !!labelRuleGroup;

  // `state` is filled => we are cloning
  labelRuleGroup = labelRuleGroup ?? (state as any) ?? null;

  const [tempLabelKey, setTempLabelKey] = React.useState(
    labelRuleGroup?.labelKey ?? "",
  );
  const [tempLevel, setTempLevel] = React.useState(
    labelRuleGroup?.level ?? "campaign",
  );
  const [tempLabelRules, setTempLabelRules] = React.useState(
    labelRuleGroup?.labelRules ?? [],
  );

  const [rawMode, setRawMode] = React.useState(false);

  const hasUnsavedWork = React.useMemo(
    () =>
      labelRuleGroup &&
      (labelRuleGroup.level !== tempLevel ||
        jsonHash(labelRuleGroup.labelRules) !== jsonHash(tempLabelRules)),
    [tempLevel, labelRuleGroup, tempLabelRules],
  );

  const updateLabelRuleAtIndex = React.useCallback(
    (index: number, updateFunc: (labelRule: LabelRule) => LabelRule) => {
      const newValue = [...tempLabelRules];
      // HACK: In JS, if you assign outside the bounds of the array, it just creates the
      // HACK: new array element.
      // HACK: The upshot here is that this code works for both updates and appends.
      newValue[index] = updateFunc(newValue[index]);

      setTempLabelRules(_.sortBy(newValue, "priority", "createdAt"));
    },
    [tempLabelRules, setTempLabelRules],
  );

  const removeLabelRuleAtIndex = React.useCallback(
    (index: number) => {
      const newValue = [...tempLabelRules];
      newValue.splice(index, 1);
      setTempLabelRules(_.sortBy(newValue, "priority", "createdAt"));
    },
    [tempLabelRules, setTempLabelRules],
  );

  const updateLabelRulesFromRaw = React.useCallback(
    (rules: LabelRule[]) => {
      setTempLabelRules(_.sortBy(rules, "priority", "createdAt"));
    },
    [setTempLabelRules],
  );

  const tableBody = React.useMemo(() => {
    const rv = tempLabelRules.map((value, index) => (
      <LabelRuleGroupEditorTableRow
        key={jsonHash({ index, value })}
        labelKey={tempLabelKey}
        labelRule={value}
        index={index}
        numRules={tempLabelRules.length}
        onUpdate={updateLabelRuleAtIndex}
        onRemove={removeLabelRuleAtIndex}
      />
    ));
    rv.push(
      <LabelRuleGroupEditorTableRow
        key={"__NEW__"}
        labelKey={tempLabelKey}
        labelRule={null}
        index={tempLabelRules.length}
        numRules={tempLabelRules.length}
        onUpdate={updateLabelRuleAtIndex}
        onRemove={removeLabelRuleAtIndex}
      />,
    );
    return rv;
  }, [
    tempLabelKey,
    tempLabelRules,
    updateLabelRuleAtIndex,
    removeLabelRuleAtIndex,
  ]);

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col">
          <H1>Rules</H1>
          <p>
            Configure rules to automatically categorize your Traffic Sources.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {hasUnsavedWork && (
            <div className="alert alert-warning">
              You have unsaved changes. Press the save button below to save this
              rule.
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="flex">
            <button
              className="btn btn-primary mr-3"
              onClick={() =>
                onLabelRulesSave(
                  { level: tempLevel, labelKey: tempLabelKey },
                  tempLabelRules,
                )
              }
              disabled={
                !tempLabelKey ||
                !tempLevel ||
                tempLabelRules.length === 0 ||
                isLoading
              }
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Saving...
                </>
              ) : (
                "Save"
              )}
            </button>

            <ButtonWithInterceptModal
              className="btn btn-secondary"
              modalOnConfirmed={onDiscardChanges}
              modalTitle="Discard changes?"
              modalDisabled={!hasUnsavedWork}
              modalBody="You may lose any unsaved work."
            >
              Discard changes
            </ButtonWithInterceptModal>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h3 className="font-weight-bold mt-3">Properties</h3>
          <div className="form-group row items-center">
            <label
              className="col-2 col-form-label text-right font-weight-bold"
              htmlFor="labelKey_input"
            >
              Label key
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                readOnly={isEditing}
                className={
                  isEditing ? "form-control-plaintext" : "form-control"
                }
                id="labelKey_input"
                value={tempLabelKey}
                onChange={(e) => setTempLabelKey(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group row items-center">
            <label className="col-2 col-form-label text-right font-weight-bold">
              Level
            </label>
            <div className="col-sm-10">
              {isEditing ? (
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="labelKey_input"
                  value={tempLevel}
                  onChange={(e) => setTempLabelKey(e.target.value)}
                />
              ) : (
                <select
                  className="custom-select"
                  value={tempLevel}
                  onChange={(e) => setTempLevel(e.target.value)}
                >
                  <option value="campaign">Campaign</option>
                  <option value="adset">Adset</option>
                  <option value="ad">Ad</option>
                </select>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h3 className="font-weight-bold mt-3">Label rules</h3>
          {user.isAdmin && (
            <div className="my-3 alert alert-secondary">
              <b>Admin tools: </b>
              <button
                className="btn btn-primary"
                onClick={() => setRawMode(!rawMode)}
              >
                {rawMode && "View Structured"}
                {!rawMode && "View Raw"}
              </button>
            </div>
          )}
          {rawMode && (
            <Editor
              value={tempLabelRules}
              onChange={updateLabelRulesFromRaw}
              mode="code"
              ace={ace}
              htmlElementProps={{ style: { height: 600 } }}
            />
          )}
          {!rawMode && (
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Active?
                  </th>
                  <th scope="col">Rank</th>
                  <th scope="col">Name</th>
                  <th scope="col">Matcher</th>
                  <th scope="col">Output label value</th>
                  <th style={{ textAlign: "center" }} scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
              <SimulatorProvider labelRules={tempLabelRules} level={tempLevel}>
                <tbody>{tableBody}</tbody>
              </SimulatorProvider>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

interface LabelRuleGroupEditorTableRowProps {
  index: number;
  numRules: number;
  labelKey: string;
  labelRule: LabelRule | null;
  onUpdate: (index: number, func: (lr: LabelRule) => LabelRule) => void;
  onRemove: (index: number) => void;
}

function LabelRuleGroupEditorTableRow({
  index,
  numRules,
  labelKey,
  labelRule,
  onUpdate,
  onRemove,
}: LabelRuleGroupEditorTableRowProps) {
  const [isModalShowing, setModalShowing] = React.useState(false);
  const [isCopyModalShowing, setCopyModalShowing] = React.useState(false);
  const { clear } = useSimulator();

  const showModal = React.useCallback(
    () => setModalShowing(true),
    [setModalShowing],
  );
  const hideModal = React.useCallback(() => {
    setModalShowing(false);
    clear();
  }, [setModalShowing, clear]);
  const saveAndHideModal = React.useCallback(
    (newValue: LabelRule) => {
      onUpdate(index, () => newValue);
      hideModal();
    },
    [hideModal, onUpdate, index],
  );

  const showCopyModal = React.useCallback(
    () => setCopyModalShowing(true),
    [setCopyModalShowing],
  );
  const hideCopyModal = React.useCallback(() => {
    setCopyModalShowing(false);
    clear();
  }, [setCopyModalShowing, clear]);
  const saveAndHideCopyModal = React.useCallback(
    (newValue: LabelRule) => {
      onUpdate(numRules, () => newValue);
      hideCopyModal();
    },
    [hideCopyModal, onUpdate, numRules],
  );

  const rowBody = React.useMemo(() => {
    if (labelRule) {
      const { active, priority, matcher, output, name } = labelRule;
      const updateActive = (lr: LabelRule) => ({ ...lr, active: !active });
      return (
        <>
          <td align="center">
            <div className="flex justify-content-center">
              <LightSwitch
                size="medium"
                isSet={active}
                disabled={false}
                onChange={() => onUpdate(index, updateActive)}
                id={
                  "lr_" +
                  index +
                  "_" +
                  name.replaceAll(" ", "_").replaceAll("-", "_") +
                  "____active"
                }
              />
            </div>
          </td>
          <td>{priority}</td>
          <td>{name}</td>
          <td>
            <div
              style={{
                maxWidth: "30em",
                maxHeight: "10em",
                overflowY: "scroll",
              }}
            >
              <code>{JSON.stringify(matcher)}</code>
            </div>
          </td>
          <td>
            <code>{output}</code>
          </td>
          <td align="center">
            <div className="flex flex-column">
              <button
                className="w-100 btn btn-primary mb-2"
                onClick={showModal}
              >
                Edit
              </button>
              <button
                className="w-100 btn btn-primary mb-2"
                onClick={showCopyModal}
              >
                Copy
              </button>
              <ButtonWithInterceptModal
                className="w-100 btn btn-secondary"
                modalOnConfirmed={() => onRemove(index)}
                modalTitle="Delete rule?"
                modalBody="You may not be able to recover this rule!"
              >
                Delete
              </ButtonWithInterceptModal>
            </div>
          </td>
          <LabelRuleEditorModal
            currentIndex={numRules}
            labelKey={labelKey}
            labelRule={labelRule}
            isShowing={isCopyModalShowing}
            cancel={hideCopyModal}
            save={saveAndHideCopyModal}
          />
        </>
      );
    } else {
      return (
        <td colSpan={6} align="center" valign="middle">
          <button className="btn btn-link" onClick={showModal}>
            Create {index > 0 ? "another" : ""} rule
          </button>
        </td>
      );
    }
  }, [
    labelKey,
    showCopyModal,
    labelRule,
    showModal,
    onUpdate,
    index,
    onRemove,
    numRules,
    isCopyModalShowing,
    hideCopyModal,
    saveAndHideCopyModal,
  ]);

  return (
    <tr>
      {rowBody}
      <LabelRuleEditorModal
        currentIndex={index}
        labelKey={labelKey}
        labelRule={labelRule}
        isShowing={isModalShowing}
        cancel={hideModal}
        save={saveAndHideModal}
      />
    </tr>
  );
}

interface LabelRuleEditorModalProps {
  currentIndex: number;
  labelKey: string;
  isShowing: boolean;
  labelRule: LabelRule | null;
  cancel: () => void;
  save: (newValue: LabelRule) => void;
}

function LabelRuleEditorModal({
  currentIndex,
  labelKey,
  isShowing,
  labelRule,
  cancel,
  save,
}: LabelRuleEditorModalProps) {
  const isCreating = !labelRule;

  const [matcher, setMatcher] = React.useState(
    labelRule?.matcher ?? defaultMatcher(),
  );
  const [output, setOutput] = React.useState(labelRule?.output ?? "");
  const [name, setName] = React.useState(labelRule?.name ?? "New label rule");
  const [priority, setPriority] = React.useState(labelRule?.priority ?? 10);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isShowing}
      onRequestClose={cancel}
      shouldCloseOnOverlayClick
      title={`${isCreating ? "Creating" : "Editing"} label rule for key:`}
      description={labelKey}
      width="90%"
      maxWidth="90%"
    >
      <div className="overflow-y-scroll max-h-[80vh]">
        <h5 className="font-bold">Properties</h5>
        <div className="my-3">
          <label>Name</label>
          <input
            className="form-control inline w-[unset] ml-2 mr-4"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label>Rank</label>
          <input
            className="form-control inline w-[unset] ml-2 mr-4"
            type="number"
            value={priority}
            onChange={(e) => setPriority(parseFloat(e.target.value))}
          />
        </div>
        <h5 className="my-3 font-bold">Matcher</h5>
        <MatcherEditor matcher={matcher} onUpdate={setMatcher} />
        <h5 className="my-3 font-bold">Output label value</h5>
        <OutputValueEditor
          matcher={matcher}
          output={output}
          onUpdate={setOutput}
        />
        <h5 className="my-3 font-bold">Simulation</h5>
        <LabelRuleSimulator
          currentIndex={currentIndex}
          labelKey={labelKey ? labelKey : "New Label Rule"}
          matcher={matcher}
          name={name}
          priority={priority}
          output={output}
        />
      </div>
      <div className="flex justify-end">
        <button className="btn btn-secondary mr-2" onClick={cancel}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            save({
              name,
              priority,
              createdAt: labelRule?.createdAt ?? new Date().toISOString(),
              active: true,
              matcher,
              _regexCaptures: extractRegexCaptures(matcher),
              output,
            })
          }
          disabled={
            !output || typeof priority !== "number" || priority < 0 || !name
          }
        >
          Save changes
        </button>
      </div>
    </Modal>
  );
}

interface MatcherEditorProps {
  matcher: RulesLogic;
  onUpdate: (value: RulesLogic) => void;
}

function MatcherEditor({ matcher, onUpdate }: MatcherEditorProps) {
  const { user } = useUser();
  const [tree, setTree] = React.useState(jsonLogicToTree(matcher));
  const [synced, setSynced] = React.useState(true);
  const [rawMode, setRawMode] = React.useState(false);

  // Custom logic to only sync state with parent every 500ms
  const debouncedOnUpdate = React.useMemo(
    () =>
      _.debounce((tree: ImmutableTree) => {
        onUpdate(treeToJsonLogic(tree));
        setSynced(true);
      }, 500),
    [onUpdate, setSynced],
  );

  const setTreeWithSync = React.useCallback(
    (tree: ImmutableTree) => {
      setTree(tree);
      setSynced(false);
      debouncedOnUpdate(tree);
    },
    [setTree, debouncedOnUpdate, setSynced],
  );

  const debouncedOnUpdateRaw = React.useMemo(
    () =>
      _.debounce((value: any) => {
        try {
          const tree = jsonLogicToTree(value);
          setTree(tree);
        } catch (e) {
          bugsnagClient.notify(e as any);
          return;
        }
        onUpdate(value);
        setSynced(true);
      }, 500),
    [onUpdate, setSynced],
  );

  const onChangeWithSync = React.useCallback(
    (value: any) => {
      setSynced(false);
      debouncedOnUpdateRaw(value);
    },
    [debouncedOnUpdateRaw, setSynced],
  );

  const renderBuilder = React.useMemo(
    () => (props: any) =>
      (
        <div>
          <div className="query-builder-container">
            <div className="query-builder qb-lite">
              <Builder {...props} />
            </div>
          </div>
        </div>
      ),
    [],
  );

  return (
    <div>
      <div className="m-3">
        Sync status: {synced ? <i className="fa-regular fa-check"></i> : "..."}
      </div>
      {user.isAdmin && (
        <div className="my-3 alert alert-secondary">
          <b>Admin tools: </b>
          <button
            className="btn btn-primary"
            onClick={() => setRawMode(!rawMode)}
          >
            {rawMode && "View Structured"}
            {!rawMode && "View Raw"}
          </button>
        </div>
      )}
      {rawMode && (
        <Editor
          value={matcher}
          onChange={onChangeWithSync}
          mode="code"
          ace={ace}
          htmlElementProps={{ style: { height: 600 } }}
        />
      )}
      {!rawMode && (
        <Query
          {...MATCHER_CONFIG}
          value={tree}
          onChange={(tree) => setTreeWithSync(tree)}
          renderBuilder={renderBuilder}
        />
      )}
      <div className="m-3">
        <RegexTips />
      </div>
    </div>
  );
}

interface OutputValueEditorProps {
  output: string;
  matcher: RulesLogic;
  onUpdate: (value: string) => void;
}

function OutputValueEditor({
  output,
  matcher,
  onUpdate,
}: OutputValueEditorProps) {
  const templateDetails = React.useMemo(() => {
    const rv: { description: string; templates: string[] }[] = [];
    rv.push({
      description: "NBT params",
      templates: ["data.nbt.platform", "data.nbt.campaignName"],
    });
    rv.push({
      description: "UTM params",
      templates: [
        "data.utm.source",
        "data.utm.medium",
        "data.utm.campaign",
        "data.utm.term",
        "data.utm.content",
      ],
    });
    rv.push({
      description: "Referral params",
      templates: ["data.referral.domain"],
    });

    const regexps = extractRegexCaptures(matcher);
    for (let i = 0; i < regexps.length; ++i) {
      const { pattern } = regexps[i];
      const description = `Regex pattern: <code>${pattern}</code>`;
      try {
        const xre = compileRegex(pattern);
        const templates = [
          "__root",
          ...((xre as any).xregexp?.captureNames ?? []),
        ].map((v) => `matches.${i}.${v}`);
        rv.push({ description, templates });
      } catch (e) {
        rv.push({ description, templates: [] });
      }
    }
    return rv;
  }, [matcher]);

  const validTemplateParams = React.useMemo(
    () => templateDetails.flatMap((v) => v.templates),
    [templateDetails],
  );

  const [currentOutput, setCurrentOutput] = React.useState(output);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isMatcherTemplateOptionsShowing, setMatcherTemplateOptionsShowing] =
    React.useState(false);

  const outputTextFieldChanged: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        const value = e.target.value;
        setCurrentOutput(value);

        const errMesg = validateOutputTemplate(value, validTemplateParams);
        setErrorMessage(errMesg);
        if (errMesg) {
          onUpdate("");
        } else {
          onUpdate(value);
        }
      },
      [setCurrentOutput, validTemplateParams, onUpdate],
    );

  return (
    <>
      <div className="form-inline">
        <label className="my-1 mr-2">Output value</label>
        <input
          className="form-control mr-4 text-monospace w-50"
          spellCheck={false}
          type="text"
          value={currentOutput}
          onChange={outputTextFieldChanged}
        />
        <div
          className={classNames({
            "d-none": !errorMessage,
            "text-danger": true,
          })}
        >
          {errorMessage}
        </div>
      </div>
      <p className="mt-3">
        You may use the following templates in the output value:{" "}
        <button
          className="btn btn-link p-0"
          onClick={() =>
            setMatcherTemplateOptionsShowing(!isMatcherTemplateOptionsShowing)
          }
        >
          click to {isMatcherTemplateOptionsShowing ? "hide" : "show"}
        </button>
      </p>
      {isMatcherTemplateOptionsShowing && (
        <table className="table table-bordered table-sm">
          <thead className="thead-light">
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Template values</th>
            </tr>
          </thead>
          <tbody>
            {templateDetails.map(({ description, templates }, index) => (
              <tr key={jsonHash({ description, index })}>
                <td>
                  <Interweave content={description} />
                </td>
                <td>
                  {templates.length > 0 ? (
                    <InterleaveSpans
                      text={templates.map((v) => `{{${v}}}`)}
                      sep=", "
                      Wrapper={(props: any) => <code {...props} />}
                    />
                  ) : (
                    <span className="text-muted">(none)</span>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td>Functions</td>
              <td>
                <p>
                  Lowercasing:
                  <code>
                    {"{{#functions.lower}}Howdy{{/functions.lower}}"}
                  </code>{" "}
                  → <code>howdy</code>{" "}
                </p>
                <p>
                  Uppercasing:
                  <code>
                    {"{{#functions.upper}}Hello{{/functions.upper}}"}
                  </code>{" "}
                  → <code>HELLO</code>{" "}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
}

interface LabelRuleSimulatorProps {
  labelKey: string;

  // current label rule props
  currentIndex: number;
  output: string;
  name: string;
  priority: number;
  matcher: RulesLogic;
}

function LabelRuleSimulator({
  labelKey,
  currentIndex,
  output,
  name,
  priority,
  matcher,
}: LabelRuleSimulatorProps) {
  const { simulate, data, isLoading, isError } = useSimulator();

  /**
   * PSA: for react-table to not go berserk with the "maximum recursion depth exceeded"
   * error, both `data` and `columns` must be memoized before passing into `useTable`
   */
  const dataForReactTable = useValueCache(data ?? []);

  const simulateButtonPressed = React.useCallback(() => {
    const newLabelRule: LabelRule = {
      active: true,
      createdAt: "2000-01-01T00:00:00Z",
      output,
      name,
      priority,
      matcher,
      _regexCaptures: extractRegexCaptures(matcher),
    };

    simulate(currentIndex, newLabelRule);
  }, [simulate, output, name, priority, matcher, currentIndex]);

  const columns: Column<LabelRuleSimulationResult>[] = React.useMemo(
    () =>
      [
        {
          accessor: "visitsLast30d",
          Header: "Visits, last 30d",
          Cell: ({ row }: CellProps<LabelRuleSimulationResult>) => {
            return (
              <div className="text-right">{row.original.visitsLast30d}</div>
            );
          },
        },
        {
          Header: "Traffic Source",
          accessor: "name",
          Cell: ({ row }: CellProps<LabelRuleSimulationResult>) => {
            const { name, metadata } = row.original;
            return (
              <div className="flex items-center">
                <AdIcon platform={metadata.iconType} sizeInEM={1} />
                <div
                  style={{ maxWidth: "15em" }}
                  className="text-truncate"
                  title={name}
                >
                  {name || <span className="text-muted">(no name)</span>}{" "}
                </div>
              </div>
            );
          },
        },
        {
          accessor: "identificationMethod",
          Header: "ID method",
        },
        {
          accessor: (v) => v.matchResult.outputValue,
          Header: labelKey,
          Cell: ({ row }: CellProps<LabelRuleSimulationResult>) => {
            return (
              row.original.matchResult.outputValue || (
                <span className="text-muted">(none)</span>
              )
            );
          },
        },
        {
          id: "matchedRules",
          Header: "Primary matched rule",
          Cell: ({ row }: CellProps<LabelRuleSimulationResult>) => {
            const matchResult = row.original.matchResult;
            const firstMatch = matchResult.priorityOrderedMatchedRules[0];
            const matchedRule = firstMatch ? (
              `${firstMatch.name} (rank: ${firstMatch.priority})`
            ) : (
              <span className="text-muted">(no match)</span>
            );
            return (
              <>
                {matchedRule}{" "}
                <GenericTooltip
                  content={
                    <>
                      <p>All matched rules:</p>
                      <table className="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" style={{ textAlign: "right" }}>
                              Rank
                            </th>
                            <th scope="col">Name</th>
                            <th scope="col">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {matchResult.priorityOrderedMatchedRules.map(
                            (v, idx) => (
                              <tr
                                key={jsonHash(v)}
                                className={idx === 0 ? "table-success" : ""}
                              >
                                <td align="right">{v.priority}</td>
                                <td>{v.name}</td>
                                <td>{v.value}</td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </>
                  }
                >
                  {""}
                </GenericTooltip>
              </>
            );
          },
        },
        {
          id: "metadata",
          Header: "Metadata",
          Cell: ({ row }: CellProps<LabelRuleSimulationResult>) => {
            return (
              <HTMLTooltip
                html={`<pre>${JSON.stringify(
                  row.original.metadata,
                  null,
                  2,
                )}</pre>`}
              >
                {""}
              </HTMLTooltip>
            );
          },
        },
      ] as Column<LabelRuleSimulationResult>[],
    [labelKey],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { globalFilter, pageIndex },
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable<LabelRuleSimulationResult>(
    {
      columns,
      data: dataForReactTable,
      autoResetGlobalFilter: false,
      initialState: { pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
  };

  const RenderRow = React.useCallback(
    (row: Row<LabelRuleSimulationResult>) => {
      prepareRow(row);
      const style = (row as any).style;
      const firstMatch =
        row.original.matchResult.priorityOrderedMatchedRules[0];
      const isCurrentRule =
        firstMatch &&
        firstMatch.name === name &&
        firstMatch.priority === priority;
      return (
        <tr
          {...row.getRowProps({ style })}
          className={isCurrentRule ? "table-success" : ""}
        >
          {row.cells.map((cell) => (
            // eslint-disable-next-line
            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
          ))}
        </tr>
      );
    },
    [name, priority, prepareRow],
  );

  const tableSection = React.useMemo(() => {
    if (!data) {
      return (
        <tr>
          <td colSpan={6} align="center">
            Press the simulate button to simulate.
          </td>
        </tr>
      );
    }

    if (isLoading) {
      return (
        <tr>
          <td colSpan={6} align="center">
            Please wait...
          </td>
        </tr>
      );
    }

    if (isError) {
      return (
        <tr>
          <td colSpan={6} align="center">
            Whoops! an error occurred.
          </td>
        </tr>
      );
    }

    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={6} align="center">
            No rows to show.
          </td>
        </tr>
      );
    }

    return page.map(RenderRow);
  }, [data, isLoading, isError, page, RenderRow]);

  return (
    <>
      <PaginationBar {...paginationProps} />
      <nav className="navbar border-top border-left border-right">
        <ul className="nav nav-pills">
          <button
            className="btn btn-secondary"
            onClick={simulateButtonPressed}
            disabled={isLoading}
          >
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <i className="fa-regular fa-dice"></i>
            )}{" "}
            Simulate
          </button>
        </ul>
        <ul className="nav nav-right">
          <li className="nav-item">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </li>
        </ul>
      </nav>
      <div
        className={classNames(
          isLoading && "waiting-interstitial",
          "table-wrap",
        )}
      >
        <table {...getTableProps()} className="table table-bordered table-sm">
          <thead className="thead-light">
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.canSort && (
                      <>
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fas fa-sort-down"></i>
                            ) : (
                              <i className="fas fa-sort-up"></i>
                            )
                          ) : (
                            <i className="fas fa-sort"></i>
                          )}
                        </span>
                        &nbsp;
                      </>
                    )}
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>{tableSection}</tbody>
        </table>
      </div>
    </>
  );
}

// --------------------------- Simulator context boilerplate code

interface SimulatorContextType {
  simulate: (index: number, rule: LabelRule) => void;
  clear: () => void;
  data: LabelRuleSimulationResult[] | undefined;
  isLoading: boolean;
  isError: boolean;
}

const SimulatorContext = React.createContext<SimulatorContextType>({
  simulate: () => void 0,
  clear: () => void 0,
  data: [],
  isLoading: false,
  isError: false,
});

interface SimulatorProviderProps {
  level: string;
  labelRules: LabelRule[];
  children: JSX.Element;
}

const useSimulator = () => React.useContext(SimulatorContext);

function SimulatorProvider({
  labelRules,
  level,
  children,
}: SimulatorProviderProps) {
  const [func, { data: _data, loading, error }] = useLazyQuery<
    SimulateLabelRuleGroup,
    SimulateLabelRuleGroupVariables
  >(SIMULATE_LABEL_RULE_GROUP, {
    fetchPolicy: "no-cache",
  });

  const [newPage, setNewPage] = React.useState(true);

  const clear = React.useCallback(() => setNewPage(true), [setNewPage]);

  const simulate = React.useCallback(
    (index: number, rule: LabelRule) => {
      const seed = Math.floor(Math.random() >> 16);
      let newLabelRules = [...labelRules];
      newLabelRules[index] = rule;
      newLabelRules = _.sortBy(newLabelRules, "priority");

      func({
        variables: {
          seed,
          level,
          labelRules: newLabelRules,
        },
      });

      if (newPage) {
        setNewPage(false);
      }
    },
    [level, labelRules, func, setNewPage, newPage],
  );

  const data = newPage ? undefined : _data?.me.simulateLabelRuleGroup;
  const isLoading = loading;
  const isError = !!error;

  return (
    <SimulatorContext.Provider
      value={{ data, isLoading, isError, simulate, clear }}
    >
      {children}
    </SimulatorContext.Provider>
  );
}
