import { withFeatureFlagComponentSwitch } from "@hoc/with-feature-flag-component-switch";
import { Navbar as OldNavbar } from "./navbar";
import { TopBar as NewTopBar } from "./_navbar";
const Navbar = withFeatureFlagComponentSwitch(
  "enableDesignRefresh",
  NewTopBar,
  OldNavbar,
);
export { Navbar };

export { NAVBAR_QUERY, ImpersonateDropdown } from "./navbar";
export type { UserItem } from "./navbar";
