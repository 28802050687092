import React, { useMemo, ReactNode } from "react";
import classNames from "classnames";
import { snakeCase } from "lodash";

const buildId = (id: string | null, name: string | null) =>
  id || snakeCase(name || "");

// eslint-disable-next-line @typescript-eslint/no-empty-function
const EmptyHandler: ChangeHandler<any> = () => {};

const FormGroup = ({
  id = null,
  name = null,
  label = null,
  className = null,
  children = null,
}: {
  id?: string | null;
  name?: string | null;
  label?: ReactNode | null;
  className?: string | null;
  children?: ReactNode | null;
} = {}) => {
  const htmlFor = buildId(id, name);
  return (
    <div className={classNames("form-group", className)}>
      {label && (
        <label htmlFor={htmlFor} className="form-label">
          {label}
        </label>
      )}
      {children}
    </div>
  );
};

const FormControl = ({
  name: defaultName = null,
  id: defaultId = null,
  as = "default",
  type = "text",
  value,
  readOnly = false,
  onChange,
  className = null,
  helpText = null,
  ...props
}: {
  name?: string | null;
  value: string;
  id?: string | null;
  as?: InputAs;
  type?: DefaultInputType | CheckInputType;
  readOnly?: boolean;
  onChange?: ChangeHandler<any> | null;
  className?: string | null;
  helpText?: ReactNode | null;
  [key: string]: any;
}) => {
  const name = useMemo(() => defaultName ?? crypto.randomUUID(), [defaultName]);
  const id = buildId(defaultId, name);

  const Control = FormControl.Controls[as] as ControlType | undefined;
  if (!Control) {
    throw new TypeError("Expected a valid form Control type");
  }

  return (
    <>
      <Control
        {...props}
        id={id}
        name={name}
        className={className}
        type={type}
        onChange={onChange ?? EmptyHandler}
        readOnly={readOnly}
        value={value}
      />
      {helpText && <small className="form-text text-muted">{helpText}</small>}
    </>
  );
};

const Controls = {
  default: ({
    id,
    name,
    type,
    className,
    onChange,
    readOnly,
    value,
    ...props
  }: { type: DefaultInputType } & FormControlProps) => {
    return (
      <input
        {...props}
        id={id}
        name={name}
        type={type}
        className={classNames("form-control", className)}
        readOnly={readOnly}
        onChange={(event) => onChange(event.target.value, event)}
        value={value}
      />
    );
  },

  check: ({
    id,
    name,
    type,
    label,
    className,
    onChange: defaultOnChange,
    readOnly,
    value,
    ...props
  }: {
    type: CheckInputType;
    label: string | null;
  } & FormControlProps<boolean>) => {
    const onChange = readOnly ? EmptyHandler : defaultOnChange;
    return (
      <div className="form-check">
        <input
          {...props}
          id={id}
          name={name}
          type={type}
          className={classNames("form-check-input", className)}
          onChange={(event) => onChange(event.target.checked, event)}
          checked={value}
        />
        {label && (
          <label className="form-check-label" htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    );
  },

  textarea: ({
    id,
    name,
    className,
    onChange,
    readOnly,
    value,
    ...props
  }: FormControlProps) => {
    return (
      <textarea
        {...props}
        id={id}
        name={name}
        className={classNames("form-control", className)}
        value={value}
        readOnly={readOnly}
        onChange={(event) => onChange(event.target.value, event)}
      />
    );
  },
};

FormControl.Controls = Controls;

export const InputGroup = ({
  className = null,
  append,
  prepend,
  children,
}: {
  className?: string | null;
  append?: ReactNode;
  prepend?: ReactNode;
  children?: ReactNode;
}) => {
  return (
    <div className={classNames("input-group", className)}>
      {prepend && (
        <div className="input-group-prepend">
          {typeof prepend === "string" ? (
            <div className="input-group-text">{prepend}</div>
          ) : (
            prepend
          )}
        </div>
      )}
      {children}
      {append && (
        <div className="input-group-append">
          {typeof append === "string" ? (
            <div className="input-group-text">{append}</div>
          ) : (
            append
          )}
        </div>
      )}
    </div>
  );
};

type ChangeHandler<T> = (value: T, event: Event | React.SyntheticEvent) => void;
type ControlType = ({
  id,
  name,
  className,
  onChange,
  value,
  ...controlProps
}: FormControlProps & Record<string, any>) => JSX.Element;

interface FormControlProps<T = string> {
  id: string;
  name: string;
  className: string | null;
  value: T;
  readOnly: boolean;
  onChange: ChangeHandler<T>;
}

type InputAs = keyof typeof Controls;

type DefaultInputType = "text" | "email" | "hidden" | "file";

type CheckInputType = "checkbox" | "radio";

export { FormGroup, FormControl };
