import { gql } from "@apollo/client";
import { FetchNotificationSettings as FetchNotificationSettingsQuery } from "@nb-api-graphql-generated/FetchNotificationSettings";
import {
  UpdateNotificationSettings,
  UpdateNotificationSettingsVariables,
} from "@nb-api-graphql-generated/UpdateNotificationSettings";
import {
  GlobalNotificationSettings,
  ProductNotificationSettings,
} from "@north-beam/nb-common";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import _ from "lodash";
import React, { useEffect, useState } from "react";

function NotificationObjectEditor({
  descriptiveName,
  notifyObject,
  setNotificationObject,
  accountOwnerEmail,
}: {
  descriptiveName: string;
  notifyObject: ProductNotificationSettings;
  setNotificationObject: (arg0: ProductNotificationSettings) => void;
  accountOwnerEmail?: string;
}) {
  const [newNotificationEmail, setNewNotificationEmail] = useState<string>("");

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      const emails = new Set(notifyObject.additionalRecipientEmails ?? []).add(
        newNotificationEmail,
      );

      setNotificationObject({
        ...notifyObject,
        additionalRecipientEmails: Array.from(emails),
      });
      setNewNotificationEmail("");
    }
  };

  const removeAccountOwner = () => {
    setNotificationObject({
      ...notifyObject,
      doNotNotifyAccountOwner: true,
    });
  };

  const removeEmail = (emailToRemove: string) => {
    const newEmails = (notifyObject.additionalRecipientEmails || []).filter(
      (email) => email !== emailToRemove,
    );
    setNotificationObject({
      ...notifyObject,
      additionalRecipientEmails: newEmails,
    });
  };

  const renderNoEmailRecipientsMessage = () => {
    const doNotNotifyAccountOwner = !!notifyObject.doNotNotifyAccountOwner;
    const noAdditionalRecipients =
      !notifyObject.additionalRecipientEmails ||
      notifyObject.additionalRecipientEmails.length === 0;

    if (doNotNotifyAccountOwner && noAdditionalRecipients) {
      return <p>No one from your organization receives these emails.</p>;
    }
    return null;
  };

  const shouldNotifyAccountOwner = (): boolean => {
    return !notifyObject.doNotNotifyAccountOwner;
  };

  const renderAdditonalEmailRecipients = () => {
    if (
      notifyObject.additionalRecipientEmails === undefined ||
      notifyObject.additionalRecipientEmails.length === 0
    ) {
      return null;
    }
    return notifyObject.additionalRecipientEmails.map((email) => (
      <li key={email}>
        {email}{" "}
        <i
          aria-label="Delete email"
          className="fa-regular fa-times"
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => removeEmail(email)}
        />
      </li>
    ));
  };

  return (
    <>
      <h3 className="mt-4">
        <b>{descriptiveName}</b>
      </h3>
      <ul>
        {shouldNotifyAccountOwner() && (
          <li>
            {accountOwnerEmail}{" "}
            <i
              aria-label="Delete account owner email"
              className="fa-regular fa-times"
              style={{ color: "red", cursor: "pointer" }}
              onClick={removeAccountOwner}
            />
          </li>
        )}
        {renderAdditonalEmailRecipients()}
        {renderNoEmailRecipientsMessage()}
      </ul>
      <div className="row col">
        <label>
          <b>Add a new email:</b>
        </label>
        <div className="input-group">
          <input
            type="text"
            className="form-control mb-4"
            value={newNotificationEmail}
            placeholder="Email"
            id="newNotificationEmail"
            onChange={(event) => setNewNotificationEmail(event.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>
    </>
  );
}

export function NotificationSettingsContainer() {
  const [synced, setSynced] = useState<boolean>(true);

  const [notificationSettings, setNotificationSettings] =
    useState<GlobalNotificationSettings>({});

  const { data, loading } = useNorthbeamQuery<FetchNotificationSettingsQuery>(
    FETCH_NOTIFICATION_SETTINGS,
  );

  const [UpdateNotificationSettings] = useNorthbeamMutation<
    UpdateNotificationSettings,
    UpdateNotificationSettingsVariables
  >(UPDATE_NOTIFICATION_SETTINGS);

  useEffect(() => {
    if (data?.me?.notificationSettings) {
      setNotificationSettings(data.me.notificationSettings);
    }
  }, [data?.me?.notificationSettings]);

  const debouncedOnUpdate = React.useMemo(
    () =>
      _.debounce((newData: GlobalNotificationSettings) => {
        UpdateNotificationSettings({
          variables: { notificationSettings: newData },
        });
        setSynced(true);
      }, 500),
    [UpdateNotificationSettings, setSynced],
  );

  const setDataWithSync = React.useCallback(
    (newData) => {
      setNotificationSettings(newData);
      setSynced(false);
      debouncedOnUpdate(newData);
    },
    [setNotificationSettings, debouncedOnUpdate, setSynced],
  );

  const renderRoutineDataForm = () => {
    return (
      <NotificationObjectEditor
        descriptiveName="Daily overview statistics email"
        accountOwnerEmail={data?.me?.email}
        notifyObject={notificationSettings.routineData || {}}
        setNotificationObject={(notifyObject) =>
          setDataWithSync({
            ...notificationSettings,
            routineData: notifyObject,
          })
        }
      />
    );
  };

  return (
    <div className="container pt-4">
      {!loading && (
        <>
          <div className="row col">
            <div>
              Sync status:{" "}
              {synced ? (
                <i
                  aria-label="Data is synced"
                  className="fa-regular fa-check"
                />
              ) : (
                "..."
              )}
            </div>
          </div>

          <NotificationObjectEditor
            descriptiveName="UTM tracking emails"
            accountOwnerEmail={data?.me?.email}
            notifyObject={notificationSettings.autoNBT || {}}
            setNotificationObject={(notifyObject) =>
              setDataWithSync({
                ...notificationSettings,
                autoNBT: notifyObject,
              })
            }
          />
          <NotificationObjectEditor
            descriptiveName="Alerts and monitoring emails"
            accountOwnerEmail={data?.me?.email}
            notifyObject={notificationSettings.nbMonitor || {}}
            setNotificationObject={(notifyObject) =>
              setDataWithSync({
                ...notificationSettings,
                nbMonitor: notifyObject,
              })
            }
          />
          {renderRoutineDataForm()}
        </>
      )}
      {loading && <>Please wait...</>}
    </div>
  );
}

export const FETCH_NOTIFICATION_SETTINGS = gql`
  query FetchNotificationSettings {
    me {
      notificationSettings
      email
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings($notificationSettings: JSONObject!) {
    updateNotificationSettings(notificationSettings: $notificationSettings)
  }
`;
