import { ScheduledTimeFrame } from "@features/data-export/types";
import classNames from "classnames";
import { sortBy } from "lodash";
import React, { useMemo } from "react";
import { useTimeSelectOptions } from "./hooks";
import { TimeSelect } from "./time-select";
import { TimezoneDisclaimer } from "./timezone-disclaimer";

interface Props {
  timeFrames: ScheduledTimeFrame[];
  onChange(timeFrames: ScheduledTimeFrame[]): void;
  onAdd(timeFrame: ScheduledTimeFrame): void;
  onRemove(timeFrame: ScheduledTimeFrame): void;
}

const DAY_OPTIONS = [
  { label: "S", value: "7" },
  { label: "M", value: "1" },
  { label: "T", value: "2" },
  { label: "W", value: "3" },
  { label: "T", value: "4" },
  { label: "F", value: "5" },
  { label: "S", value: "6" },
];

const NUM_TO_DAY_MAPPING: Record<string, string> = {
  "1": "Monday",
  "2": "Tuesday",
  "3": "Wednesday",
  "4": "Thursday",
  "5": "Friday",
  "6": "Saturday",
  "7": "Sunday",
};

export function WeeklyConfigurator({
  timeFrames,
  onChange,
  onAdd,
  onRemove,
}: Props) {
  const selectOptions = useTimeSelectOptions();

  const dayValues = useMemo(() => timeFrames.map((e) => e.day), [timeFrames]);

  return (
    <>
      <div className="flex-initial text-[0.65rem] leading-4 text-zinc-500 font-light px-7 pt-7">
        <TimezoneDisclaimer />

        <h3 className="text-black text-sm font-semibold m-0 mb-1">
          Add weekly time frames to export
        </h3>
        <p className="text-[0.65rem] leading-4 text-zinc-500 font-light mb-6">
          Select the days per week and time you would like to run your export
        </p>

        <div className="space-x-2.5 mb-8">
          {DAY_OPTIONS.map(({ label, value }) => (
            <button
              key={value}
              className={classNames(
                "font-light w-[26px] h-[26px] rounded-full pt-0.5",
                "hover:bg-gray-400 hover:text-white",
                {
                  "bg-gray-200 text-gray-400": !dayValues.includes(value),
                  "bg-blue-500 text-white hover:bg-blue-500":
                    dayValues.includes(value),
                },
              )}
              onClick={() => {
                const timeFrame = timeFrames.find((e) => e.day === value);

                if (timeFrame) {
                  if (timeFrames.length > 1) onRemove(timeFrame);
                } else {
                  onAdd({ day: value, time: "08:00" });
                }
              }}
            >
              {label}
            </button>
          ))}
        </div>
      </div>

      <div className="flex-initial px-7 overflow-auto space-y-6 pb-5">
        {sortBy(timeFrames, (e) => (e.day === "7" ? "0" : e.day)).map(
          (timeFrame) => (
            <div key={timeFrame.day}>
              <div className="text-[0.7rem] font-medium mb-1">
                {NUM_TO_DAY_MAPPING[timeFrame.day]} export time
              </div>
              <div className="flex items-center">
                <div className="w-[50%]">
                  <TimeSelect
                    options={selectOptions}
                    value={
                      selectOptions.find((e) => e.value === timeFrame.time)!
                    }
                    onChange={(val) => {
                      const newTimeFrames = timeFrames.map((e) => {
                        if (e.day === timeFrame.day) {
                          return { ...e, time: val.value };
                        } else {
                          return e;
                        }
                      });

                      onChange(newTimeFrames);
                    }}
                  />
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </>
  );
}
