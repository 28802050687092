import { gql } from "@apollo/client";
import { useCachedQuery } from "@components/hooks";
import {
  GetSalesReportFast,
  GetSalesReportFastVariables,
} from "@nb-api-graphql-generated/GetSalesReportFast";
import { DateInterval } from "@north-beam/nb-common";
import {
  IGranularity,
  IMCAttributionWindowDays,
  INBAccountingMode,
  ITimeGranularity,
} from "@utils/constants";
import { useNorthbeamLazyQuery } from "@utils/hooks";
import { useCallback } from "react";

export enum GRANULARITY {
  CAMPAIGN = "campaign",
  ADSET = "adset",
  AD = "ad",
}

export const useLazyLoadSalesReport = (variables: any) => {
  const {
    loadData,
    data: { data, loading, error, refetch },
  } = useNorthbeamLazyQuery<GetSalesReportFast, GetSalesReportFastVariables>(
    GET_SALES_REPORT,
    {
      variables,
      returnPartialData: true,
      doNotRaiseErrorOnFailure: true,
      notifyOnNetworkStatusChange: true,
    },
  );

  const cachedData = useCachedQuery(data);

  return { loadData, data: cachedData, loading, error, refetch };
};

interface SalesReportV2Props {
  dateRange: DateInterval;
  compareDateRange: DateInterval;
  attributionModel: string;
  attributionWindow: IMCAttributionWindowDays;
  nbAccountingMode: INBAccountingMode;
  timeGranularity: ITimeGranularity;
}

export const useSalesReport = (
  granularity: IGranularity,
  variables: SalesReportV2Props,
) => {
  const {
    dateRange,
    compareDateRange,
    attributionModel,
    attributionWindow,
    nbAccountingMode,
    timeGranularity,
  } = variables;

  const {
    loadData: loadCampaignData,
    data: campaignData,
    loading: campaignLoading,
    error: campaignError,
    refetch: campaignRefetch,
  } = useLazyLoadSalesReport({
    dateRange,
    compareDateRange,
    attributionMethod: attributionModel,
    attributionWindow,
    granularity: GRANULARITY.CAMPAIGN,
    nbAccountingMode,
    timeGranularity,
  });

  const {
    loadData: loadAdsetData,
    data: adsetData,
    loading: adsetLoading,
    error: adsetError,
    refetch: adsetRefetch,
  } = useLazyLoadSalesReport({
    dateRange,
    compareDateRange,
    attributionMethod: attributionModel,
    attributionWindow,
    granularity: GRANULARITY.ADSET,
    nbAccountingMode,
    timeGranularity,
  });

  const {
    loadData: loadAdData,
    data: adData,
    loading: adLoading,
    error: adError,
    refetch: adRefetch,
  } = useLazyLoadSalesReport({
    dateRange,
    compareDateRange,
    attributionMethod: attributionModel,
    attributionWindow,
    granularity: GRANULARITY.AD,
    nbAccountingMode,
    timeGranularity,
  });

  const loadAllData = useCallback(() => {
    loadCampaignData();
    loadAdsetData();
    loadAdData();
  }, [loadCampaignData, loadAdsetData, loadAdData]);

  let data = campaignData;
  let isLoading = campaignLoading;
  let error = campaignError;
  let refetch = campaignRefetch;
  if (granularity === GRANULARITY.ADSET) {
    data = adsetData;
    isLoading = adsetLoading;
    error = adsetError;
    refetch = adsetRefetch;
  } else if (granularity === GRANULARITY.AD) {
    data = adData;
    isLoading = adLoading;
    error = adError;
    refetch = adRefetch;
  }

  return {
    loadAllData,
    data,
    isLoading,
    error,
    refetch,
  };
};

const GET_SALES_REPORT = gql`
  query GetSalesReportFast(
    $dateRange: JSONObject!
    $attributionMethod: String!
    $attributionWindow: String!
    $compareDateRange: JSONObject
    $granularity: String
    $nbAccountingMode: String
    $timeGranularity: String
    $attributionWindows: [String!]
  ) {
    me {
      salesReportTable(
        dateRange: $dateRange
        attributionMethod: $attributionMethod
        attributionWindow: $attributionWindow
        compareDateRange: $compareDateRange
        granularity: $granularity
        nbAccountingMode: $nbAccountingMode
        timeGranularity: $timeGranularity
        attributionWindows: $attributionWindows
      ) {
        rows {
          objectId
          nbtPlatformID
          link
          name
          dimensions
          extraDetails
          searchableStrings
          cellAnnotations
          metrics
          forecastV1
        }
        startDate
        endDate
        hasV1Forecasts
        customGoals {
          displayName
          id
        }
      }
    }
  }
`;
