import { gql } from "@apollo/client";
import {
  SimulateLabelRuleGroupV2,
  SimulateLabelRuleGroupV2Variables,
} from "@nb-api-graphql-generated/SimulateLabelRuleGroupV2";
import { useNorthbeamLazyQuery } from "@utils/hooks";

export const useLabelSimulate = () => {
  const {
    loadData,
    data: { data, loading, error },
  } = useNorthbeamLazyQuery<
    SimulateLabelRuleGroupV2,
    SimulateLabelRuleGroupV2Variables
  >(SIMULATE_LABEL_RULE_GROUP, {
    fetchPolicy: "no-cache",
  });

  const simulate = (options: string[]) => {
    const seed = Math.floor(Math.random() >> 16);
    const labelRules = buildLabelQuery(options);

    loadData({
      variables: {
        seed,
        labelRules,
      },
    });
  };

  const simulatedData = data?.me.simulateLabelRuleGroup ?? [];

  return {
    simulate,
    simulatedData,
    loading,
    error,
  };
};

const SIMULATE_LABEL_RULE_GROUP = gql`
  query SimulateLabelRuleGroupV2($seed: Int!, $labelRules: [JSONObject!]!) {
    me {
      id
      simulateLabelRuleGroup(seed: $seed, labelRules: $labelRules)
    }
  }
`;

const escapeRegExp = (text: string) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

const labelBuilder = (value: string) => ({
  name: `${value}`,
  active: true,
  output: `${value}`,
  matcher: {
    or: [
      {
        "~": [
          {
            var: `name`,
          },
          `(?i)${escapeRegExp(value)}`,
        ],
      },
    ],
  },
  priority: 10,
  createdAt: new Date().toISOString(),
});

const buildLabelQuery = (options: string[]) => {
  return options.map((value) => {
    return labelBuilder(value);
  });
};
