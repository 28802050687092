import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment } from "react";

type Option<T> = {
  label: string;
  value: T;
};

export function Selector<T>({
  options,
  value,
  onChange,
  label,
  dropdownFormatter,
  menuClassname,
}: {
  label?: React.ReactNode;
  options: Option<T>[];
  value: Option<T> | null;
  onChange: (option: Option<T>) => void;
  dropdownFormatter?: ({ option }: { option: Option<T> }) => React.ReactNode;
  menuClassname?: string;
}) {
  return (
    <div className="flex flex-col w-[12rem] ml-5">
      {label && (
        <div className="font-normal text-xs text-semibold text-text mb-1">
          {label}
        </div>
      )}
      <Menu as="div" className="relative">
        {({ open }) => (
          <>
            <Menu.Button
              className={clsx(
                "h-[2.4rem] bg-white border-[1px] rounded-lg border-[#C1C5CD] px-2 mb-0.5 w-full text-left flex flex-row items-center relative",
                {
                  "border  border-cosmic ring-1 ring-cosmic": open,
                },
              )}
            >
              <div className="font-normal">{value?.label}</div>
              <i
                className={clsx(
                  "font-semibold fa-solid fa-sharp cursor-pointer text-[#000000] text-sm ml-auto ",
                  {
                    "fa-chevron-down": !open,
                    "fa-chevron-up": open,
                  },
                )}
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items
                className={clsx(
                  "absolute w-[15rem] max-h-[20rem] z-10 mt-3 bg-white rounded-lg elevation-1 flex flex-col",
                  menuClassname,
                )}
              >
                <div className="my-4">
                  {options.map((option, index) => {
                    return (
                      <Menu.Item key={index}>
                        {dropdownFormatter ? (
                          dropdownFormatter({ option })
                        ) : (
                          <div
                            key={index}
                            className="px-3 py-2 hover:bg-[#F3F5FF] cursor-pointer"
                            onClick={() => onChange(option)}
                          >
                            {option.label}
                          </div>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
