import googleAdsLogo from "@assets/ga-logo.svg";
import { useUser } from "@components/user-context";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { AdwordsCallbackResponse } from "@north-beam/nb-common";
import { ConnectionCard } from "@pages/settings/connection-card";
import {
  ConnectionStatusType,
  LoginComponentChildrenProps,
  LoginComponentProps
} from "@pages/settings/connection-status-manager";
import { CardProps2 } from "@pages/settings/core";
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { PrimaryButton } from "@shared/buttons";
import React from "react";

const type = ConnectionType.integration_google_adwords;

export function AdwordsConnectionCard(props: CardProps2) {
  return (
    <ConnectionCard
      type={type}
      connectionTypeName="Google Ads"
      connectionCallToAction={`
        Integrate your Google Ads data to start receiving insights about
        your Google ad spend.
      `}
      logoUrl={googleAdsLogo}
      logoAltText="Google Ads Logo"
      allowsMultipleAccounts
    >
      {({ setStatus, accounts }: LoginComponentChildrenProps) => (

        <GoogleOAuthProvider clientId={props.integrationDetails.googleAuthBlob.client_id} >
          <AdwordsLoginComponent
            setStatus={setStatus}
            accounts={accounts}
            integrationDetails={props.integrationDetails}
          />
        </GoogleOAuthProvider>
      )}
    </ConnectionCard>
  );
}

function AdwordsLoginComponent({
  setStatus,
  integrationDetails,
}: Omit<LoginComponentProps, "id" | "upsertConnection" | "isLoading">) {
  const { user } = useUser();
  const onFailure = React.useCallback(
    (response: any) => {
      let error = "";
      if (response.error === "idpiframe_initialization_failed") {
        error = "Please enable third party cookies on your browser.";
      } else if (response.error === "access_denied") {
        error = "Access denied.";
      } else if (response.error === "popup_closed_by_user") {
        error = "Popup closed prematurely. Please try again!";
      } else {
        error = `Unknown error: ${JSON.stringify(response)}`;
      }

      setStatus({
        type: ConnectionStatusType.HAS_ERROR,
        errorMessage: error,
      });
    },
    [setStatus],
  );

  const onSuccess = React.useCallback(
    async (response: any) => {
      setStatus({
        type: ConnectionStatusType.FETCHING_AVAILABLE_ACCOUNTS,
      });

      const params = {
        code: response.code,
        redirectUri: window.location.href,
      };

      await user
        .callApi({
          method: "post",
          url: "/api/callbacks/adwords",
          data: params,
        })
        .then(({ accounts }: AdwordsCallbackResponse) =>
          setStatus({
            type: ConnectionStatusType.SELECTING_ACCOUNT,
            accountList: accounts,
          }),
        )
        .catch((e) => {
          setStatus({
            type: ConnectionStatusType.HAS_ERROR,
            errorMessage: e.response.data,
          });
        });
    },
    [user, setStatus],
  );

  const login = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onFailure,
    flow: 'auth-code',
    scope: integrationDetails.googleAuthBlob.scope,
    redirect_uri: window.location.href
  })

  return (
      <PrimaryButton onClick={login}>Connect to Google Ads</PrimaryButton>
  );
}
