import React, { PropsWithChildren } from "react";

import { Option } from "@/types/react-select";
import { GenericTooltip } from "@components/tooltip-container";
import { BenchmarkTypeEnum } from "@nb-api-graphql-generated/global-types";
import classNames from "classnames";
import Select from "react-select";
import { BenchmarkHelpWidget } from "./benchmark-help-modal";
import { Control, UseFormRegister, useFormState } from "react-hook-form";
import { ChooseAndSetValidationTargets } from "./benchmark-choose-and-set-modal";
import { useHasUnsavedChangesAtom } from "@/atoms/benchmarking-atom";

export const BenchmarkSubStep = ({
  children,
  stepNumber,
  title,
  subHeader,
  tooltip,
}: PropsWithChildren<{
  stepNumber: number | string;
  title: string;
  subHeader?: string;
  tooltip?: React.ReactNode;
}>) => {
  return (
    <div className="w-[25rem] ml-1">
      <div className="text-xs font-semibold text-subtitle">
        STEP {stepNumber}
      </div>
      <div>
        <div className="font-semibold text-md">
          {title}
          {tooltip && <GenericTooltip content={tooltip} />}
        </div>
        {subHeader && (
          <div className="text-xs text-subtitle font-light">{subHeader}</div>
        )}
        <div className="mt-3">{children}</div>
      </div>
    </div>
  );
};

export const BenchmarkSubstepWithInput = ({
  stepNumber,
  title,
  subHeader,
  benchmarkType,
  disabled,
  placeholder,
  loading,
  tooltip,
  control,
  register,
  cellRegistration,
}: {
  tooltip: string;
  loading: boolean;
  stepNumber: string;
  title: string;
  benchmarkType: BenchmarkTypeEnum;
  subHeader?: string;
  disabled: boolean;
  placeholder: string;
  control: Control<ChooseAndSetValidationTargets>;
  register: UseFormRegister<ChooseAndSetValidationTargets>;
  cellRegistration: Parameters<
    UseFormRegister<ChooseAndSetValidationTargets>
  >[0];
}) => {
  const { errors } = useFormState({ control });
  const error = errors[cellRegistration];
  const [, setHasUnsavedChanges] = useHasUnsavedChangesAtom();

  const { onChange, ...rest } = register(cellRegistration);
  return (
    <BenchmarkSubStep
      title={title}
      stepNumber={stepNumber}
      tooltip={tooltip}
      subHeader={subHeader}
    >
      <div className="relative">
        {benchmarkType === "CAC" && (
          <div className="absolute text-subtitle top-[0.52rem] left-2 text-sm">
            $
          </div>
        )}
        <input
          {...rest}
          onChange={(e) => {
            onChange(e);
            setHasUnsavedChanges(true);
          }}
          className={classNames(
            "text-sm font-normal rounded w-[20rem] py-2 px-3 border focus:outline-none text-subtitle focus:border-[#2684FF] focus:ring-1 focus:ring-[#2684FF]",
            { "pl-5": benchmarkType === "CAC" },
          )}
          style={{
            borderColor: error ? "#ef4444" : "hsl(0deg 0% 80%)",
          }}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>

      {error && (
        <div
          className={`font-light text-xs text-subtitle mt-3 max-w-[28rem] border rounded-[0.25rem] border-danger bg-[#FDF2F0] p-3`}
        >
          {error.type === "too_small" && "Value must be greater than 0"}
          {error.type === "invalid_type" && "Invalid numeric value"}
          <BenchmarkHelpWidget className="mt-4" size="xs" />
        </div>
      )}
    </BenchmarkSubStep>
  );
};

const fontConfig = {
  fontWeight: 400,
  fontSize: "0.9rem",
};
export const BenchmarkSubstepWithOptions = <T,>({
  testId,
  stepNumber,
  title,
  options,
  currentValue,
  tooltip,
  disable,
  onChange,
  isOptionDisabled,
  subHeader,
  placeholder,
}: {
  testId?: string;
  placeholder: string;
  onChange: (option: Option<T> | null) => void;
  disable: boolean;
  stepNumber: number;
  title: string;
  options: Option<T>[];
  currentValue: Option<T> | null;
  tooltip?: React.ReactNode;
  subHeader?: string;
  isOptionDisabled?: (option: Option<T>) => boolean;
}) => {
  return (
    <BenchmarkSubStep
      title={title}
      stepNumber={stepNumber}
      tooltip={tooltip}
      subHeader={subHeader}
    >
      <Select
        id={testId}
        isOptionDisabled={isOptionDisabled}
        options={options}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - typing is crap
        onChange={onChange}
        isDisabled={disable}
        placeholder={placeholder}
        value={currentValue}
        components={{ IndicatorSeparator: () => null }}
        styles={{
          container: (styles) => ({
            ...styles,
            width: "20rem",
          }),
          singleValue: (styles, state) => ({
            ...styles,
            ...fontConfig,
            color: "#717680",
          }),
          option: (styles, state) => {
            return {
              ...styles,
              ...fontConfig,
              background: state.isDisabled ? "#e8e9eb" : "",
              color: state.isSelected ? "white" : "#717680",
            };
          },
        }}
      />
    </BenchmarkSubStep>
  );
};
