import axiosModule from "axios";
import { z } from "zod";
import environments from "./environments.json";

type Env = keyof typeof environments;
const Environment = z.object({
  isDemoEnvironment: z.boolean(),
  isDevelopmentEnvironment: z.boolean(),
  amplitudeApiKey: z.string(),
  bugsnagApiKey: z.string(),
  datadogApplicationId: z.string().nullable(),
  datadogClientToken: z.string().nullable(),
  apiRoot: z.string(),
  heavyApiRoot: z.string().optional(),
  auth0Domain: z.string(),
  auth0ClientId: z.string(),
  auth0Audience: z.string(),
  stripePublishableKey: z.string(),
  isNBInternal: z.boolean().optional(),
});

const nbEnv: Env = (process.env.REACT_APP_NB_ENV || "dev") as Env;
const config = Environment.parse(environments[nbEnv]);

export default config;
export const envName = nbEnv;
export const apiAxios = axiosModule.create({
  baseURL: config.apiRoot,
  withCredentials: true,
});
