import {
  DataTypeProvider,
  DataTypeProviderProps,
} from "@devexpress/dx-react-grid";
import React from "react";
import { useNavigate } from "react-router-dom";

const AdDetailssDataFormatter = ({
  row,
  column,
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  const navigate = useNavigate();
  const updatePath = () => {
    const url = new URL(window.location.href);
    const searchParams = row.link.Name.split("?")[0];
    const id = searchParams.split("/")[1];
    url.searchParams.set("flyoutPath", id);
    navigate(url.pathname + url.search, { replace: true });
  };

  if (row?.link.Name) {
    return (
      <i
        className="fa-regular fa-memo cursor-pointer text-xl flex justify-center text-[#007bff]"
        onClick={updatePath}
      ></i>
    );
  }

  return null;
};

export const AdDetailsTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={AdDetailssDataFormatter} {...props} />
);
