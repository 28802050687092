import { ColumnDef, TableRow } from "@north-beam/nb-common";
import { RowWithChildren, walk } from "@utils/row-with-children";
import _ from "lodash";
import React, { FunctionComponent } from "react";
import BaseTable, { Column, ColumnShape } from "react-base-table";
import { Link } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import ChangeFractionDisplay from "../change-fraction";
import { HTMLTooltip } from "../tooltip-container";

interface AugmentedTableRow_ extends TableRow {
  id: string;
}

export type AugmentedTableRow = RowWithChildren<AugmentedTableRow_>;

export type RenderCellProps = {
  row: AugmentedTableRow;
  column: ColumnShape<AugmentedTableRow>;
};

export type RenderHeaderProps = {
  column: ColumnShape<AugmentedTableRow>;
};

export interface AugmentedFactColumn extends ColumnDef {
  renderHeader?: FunctionComponent<RenderHeaderProps>;
  renderCell?: FunctionComponent<RenderCellProps>;
  width?: number;
  isExpandable?: boolean;
}

export interface VirtualizedTableProps {
  frozenRows: AugmentedTableRow[];
  rows: AugmentedTableRow[];
  dimensionColumn: AugmentedFactColumn;
  factColumns: AugmentedFactColumn[];
  columnWidth?: number;
  rowHeight?: number;
  allowSortingByLastDimensionColumn?: boolean;
  defaultSortBy?: string;
  columnOrdering?: string[];
  HACK_columnMatching?: (columnName: string, columnName2: string) => boolean;
}

const sortIndicatorStyle = {
  userSelect: "none",
  width: "16px",
  height: "16px",
  lineHeight: "16px",
  textAlign: "right",
  position: "absolute",
  right: "4px",
} as const;

interface SortBy {
  key: string | number;
  order: "asc" | "desc";
}

export function VirtualizedTable(props: VirtualizedTableProps) {
  const {
    frozenRows,
    rows,
    dimensionColumn,
    factColumns,
    columnWidth,
    rowHeight,
    allowSortingByLastDimensionColumn,
    defaultSortBy,
    columnOrdering,
    HACK_columnMatching,
  } = props;

  const lastDimColumn = dimensionColumn;

  const [sortBy, setSortBy] = React.useState<SortBy>({
    key: defaultSortBy ?? factColumns[0].key,
    order: "desc",
  });

  const actualSortBy: SortBy = React.useMemo(() => {
    if (factColumns.find((v) => v.key === sortBy.key)) {
      return sortBy;
    }
    if (allowSortingByLastDimensionColumn && lastDimColumn.key === sortBy.key) {
      return sortBy;
    }
    return { key: factColumns[0].key, order: "desc" };
  }, [sortBy, factColumns, allowSortingByLastDimensionColumn, lastDimColumn]);

  const preppedRows: typeof rows = React.useMemo(() => {
    const { key, order } = actualSortBy;
    const sortFunc = (v: AugmentedTableRow) => {
      const rv = v.export[key];
      if (typeof rv === "string") {
        return rv;
      }
      if (
        typeof rv === "undefined" ||
        rv === null ||
        isNaN(rv) ||
        rv === Infinity
      ) {
        // +Infinity, NaN => bottom of list
        return -Infinity;
      }
      return rv;
    };

    // TODO: don't clone if you want to preserve Infinity sorting
    let clone: typeof rows = _.sortBy(_.cloneDeep(rows), sortFunc);
    if (order === "desc") {
      clone = _.reverse(clone);
    }

    for (const row of clone) {
      walk(row, (node) => {
        node.children = _.sortBy(node.children, sortFunc);
        if (order === "desc") {
          node.children = _.reverse(node.children);
        }
      });
    }

    return clone;
  }, [actualSortBy, rows]);

  const onColumnSort = React.useCallback(
    (args: { key: React.ReactText; order: "asc" | "desc" }) => {
      const { key, order } = args;
      setSortBy({ key, order });
    },
    [setSortBy],
  );

  const hasComparison = preppedRows.some(
    (row: AugmentedTableRow) =>
      Object.keys(row.changeFractions || {}).length > 0,
  );
  const width = columnWidth ?? (hasComparison ? 200 : 150);

  const lastDimensionColumn = React.useMemo(() => {
    const col = dimensionColumn;
    return (
      <Column<AugmentedTableRow>
        key={col.key}
        title={col.name}
        width={250}
        frozen={Column.FrozenDirection.LEFT}
        resizable={true}
        sortable={allowSortingByLastDimensionColumn}
        headerRenderer={({ column }) => {
          const { name, headerTooltip, renderHeader } = col;
          if (renderHeader) {
            return renderHeader({ column });
          }

          let component: React.ReactNode = name;
          if (headerTooltip) {
            component = (
              <HTMLTooltip html={headerTooltip}>{component}</HTMLTooltip>
            );
          }

          return <div className="text-truncate">{component}</div>;
        }}
        cellRenderer={({ rowData, column }) => {
          if (col.renderCell) {
            return col.renderCell({ row: rowData, column });
          }

          const cellData = rowData.display[column.key];
          let inner: React.ReactElement = cellData;
          const link = rowData.link[column.key];
          if (link) {
            inner = <Link to={link}>{inner}</Link>;
          }
          const emsToShave = (rowData.rowMetadata?.level ?? 0) * 2;

          return (
            <div className="d-inline-block float-left">
              <div
                className="text-truncate"
                title={cellData}
                style={{
                  maxWidth: `calc(${column.width}px - ${emsToShave}em)`,
                }}
              >
                {inner}
              </div>
            </div>
          );
        }}
      />
    );
  }, [dimensionColumn, allowSortingByLastDimensionColumn]);

  const factColumnObjects = React.useMemo(
    () =>
      factColumns.map((col) => (
        <Column<AugmentedTableRow>
          key={col.key}
          title={col.name}
          resizable={true}
          sortable={true}
          width={col.width ?? width}
          align="right"
          cellRenderer={({ rowData, column }) => {
            if (col.renderCell) {
              return col.renderCell({ row: rowData, column });
            }

            const cellData = rowData.display[col.key];
            let rhs: React.ReactElement = cellData;

            const link = rowData.link[col.key];
            if (link) {
              rhs = <Link to={link}>{rhs}</Link>;
            }

            rhs = (
              <div
                className="text-truncate"
                title={cellData}
                style={{
                  maxWidth: `calc(${column.width}px - 1.6em)`,
                }}
              >
                {rhs}
              </div>
            );

            let lhs: React.ReactElement | null = null;

            const comparison = col.comparison;
            const changeFraction = rowData.changeFractions?.[col.key];
            if (comparison && typeof changeFraction !== "undefined") {
              lhs = (
                <ChangeFractionDisplay
                  changeFraction={changeFraction}
                  {...comparison}
                />
              );
            }

            return (
              <div className="d-block w-100 px-2">
                <div className="d-inline-block float-left">{lhs}</div>
                <div className="d-inline-block float-right">{rhs}</div>
              </div>
            );
          }}
          headerRenderer={({ column }) => {
            const { name, headerTooltip, renderHeader } = col;
            if (renderHeader) {
              return renderHeader({ column });
            }

            let component: React.ReactNode = name;
            if (headerTooltip) {
              component = (
                <HTMLTooltip html={headerTooltip} noInfoCircle={true}>
                  {component}
                </HTMLTooltip>
              );
            }

            return (
              <div className="flex nb-fact-column-header-parent pr-4">
                <div className="text-wrap">{component}</div>
                <div
                  className="nb-base-table-sort-placeholder"
                  style={sortIndicatorStyle}
                ></div>
              </div>
            );
          }}
        />
      )),
    [factColumns, width],
  );

  const columns = React.useMemo(() => {
    const rv: any[] = [];
    rv.push(lastDimensionColumn);
    rv.push(...factColumnObjects);

    if (columnOrdering) {
      return rv.sort(function (columnA, columnB) {
        const columnAIndex = columnOrdering.findIndex((el: string) =>
          HACK_columnMatching
            ? HACK_columnMatching(el, columnA.key)
            : el === columnA.key,
        );
        const columnBIndex = columnOrdering.findIndex((el: string) =>
          HACK_columnMatching
            ? HACK_columnMatching(el, columnB.key)
            : el === columnB.key,
        );
        return columnAIndex - columnBIndex;
      });
    } else {
      return rv;
    }
  }, [
    lastDimensionColumn,
    factColumnObjects,
    columnOrdering,
    HACK_columnMatching,
  ]);

  return (
    <AutoSizer defaultHeight={600} disableHeight={true}>
      {({ width }) => (
        <BaseTable<AugmentedTableRow>
          classPrefix="NorthbeamBaseTable"
          fixed={true}
          width={width}
          maxHeight={600}
          rowHeight={rowHeight ?? 40}
          headerHeight={rowHeight ?? 30}
          frozenData={frozenRows}
          data={preppedRows}
          sortBy={actualSortBy}
          overscanRowCount={10}
          rowKey="id"
          onColumnSort={onColumnSort}
          expandColumnKey={
            lastDimColumn.isExpandable ? lastDimColumn.key : undefined
          }
          components={{
            SortIndicator({ sortOrder }) {
              return (
                <div
                  className="NorthbeamBaseTable__sort-indicator"
                  style={sortIndicatorStyle}
                >
                  <i
                    className={`fas fa-caret-${
                      sortOrder === "asc" ? "up" : "down"
                    }`}
                  ></i>
                </div>
              );
            },
            // eslint-disable-next-line react/prop-types
            ExpandIcon({ depth, expanded, onExpand, expandable }) {
              const marginLeft = `${depth}rem`;
              const whiteSpace = "nowrap" as const;
              const color = expandable ? undefined : "transparent";
              const cursor = expandable ? "pointer" : undefined;
              const style = {
                marginLeft,
                whiteSpace,
                color,
                cursor,
                minWidth: "1rem",
              };

              return (
                <div style={style} onClick={() => onExpand(!expanded)}>
                  {expandable ? (
                    <>
                      <small className="text-muted">
                        {expanded ? (
                          <i className="fas fa-chevron-down"></i>
                        ) : (
                          <i className="fas fa-chevron-right"></i>
                        )}
                      </small>
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
              );
            },
          }}
        >
          {columns}
        </BaseTable>
      )}
    </AutoSizer>
  );
}
