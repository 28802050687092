import classNames from "classnames";
import React from "react";

export interface ControlBarProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
}

export function ControlBar(props: ControlBarProps) {
  return (
    <nav
      className={classNames(
        "p-4 navbar bg-white nb-control-bar",
        props.className,
      )}
    >
      {props.children}
    </nav>
  );
}

export interface ControlBarElementProps {
  title: string | React.ReactElement;
  children?: React.ReactElement | React.ReactElement[];
  parentClassName?: string;
  style?: React.CSSProperties;
}

export function ControlBarElement({
  title,
  children,
  parentClassName,
  style,
}: ControlBarElementProps) {
  parentClassName =
    parentClassName ?? "flex-grow-1 px-2 nb-control-bar-element";
  return (
    <div className={parentClassName} style={style}>
      <div className="mb-1 text-muted small nb-control-bar-element-title text-truncate">
        {title}
      </div>
      {children}
    </div>
  );
}
