import { H1 } from "@components/utilities";
import { FormControl, FormGroup } from "@lib/forms";
import { useObjectState } from "@lib/hooks/";
import { mergeParams } from "@utils/url-search-params";
import { pickBy } from "lodash";
import React from "react";
import { useToasts } from "react-toast-notifications";

export function GenericUrlBuilder() {
  const { addToast } = useToasts();

  const [url, setURL] = useObjectState({
    baseUrl: "",
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
    utmTerm: "",
    utmContent: "",
  });

  const error = React.useMemo(() => {
    if (!url.baseUrl) {
      return "Base URL not set.";
    }

    try {
      new URL(url.baseUrl);
    } catch (e) {
      return "Base URL is malformed.";
    }

    if (!url.utmSource) {
      return "utm_source is not set.";
    }

    if (!url.utmMedium) {
      return "utm_medium is not set.";
    }

    return "";
  }, [url]);

  const finalUrl = React.useMemo(() => {
    if (error) {
      return "";
    }

    const sources = pickBy(
      {
        utm_source: url.utmSource,
        utm_medium: url.utmMedium,
        utm_campaign: url.utmCampaign,
        utm_term: url.utmTerm,
        utm_content: url.utmContent,
      },
      (value) => Boolean(value),
    );
    const builtURL = new URL(url.baseUrl);
    const search = mergeParams(builtURL.search, sources);
    builtURL.search = search.toString();
    return builtURL.toString();
  }, [url, error]);

  const copy = React.useCallback(() => {
    navigator.clipboard.writeText(finalUrl);
    addToast("Copied to clipboard!", { appearance: "success" });
  }, [finalUrl, addToast]);

  return (
    <>
      <H1>Generic URL builder</H1>
      <div className="card rounded">
        <div className="card-header">Builder</div>
        <div className="card-body">
          <p>
            Fill out all of the fields below and the tracked URL will be
            generated for you.
          </p>
          <FormGroup label="Website URL (required)">
            <FormControl
              name="website-url"
              value={url.baseUrl}
              onChange={(baseUrl) => setURL({ baseUrl })}
              placeholder="Destination URL"
            />
          </FormGroup>
          <FormGroup label="UTM Source (required)">
            <FormControl
              name="utm-source"
              value={url.utmSource}
              onChange={(utmSource) => setURL({ utmSource })}
              placeholder="utm_source"
            />
          </FormGroup>
          <div className="row">
            <div className="col">
              <FormGroup label="UTM Medium (required)">
                <FormControl
                  name="utm-medium"
                  value={url.utmMedium}
                  onChange={(utmMedium) => setURL({ utmMedium })}
                  placeholder="utm_medium"
                />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup label="UTM Campaign">
                <FormControl
                  name="utm-campaign"
                  value={url.utmCampaign}
                  onChange={(utmCampaign) => setURL({ utmCampaign })}
                  placeholder="utm_campaign"
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <FormGroup label="UTM Term">
                <FormControl
                  name="utm-term"
                  value={url.utmTerm}
                  onChange={(utmTerm) => setURL({ utmTerm })}
                  placeholder="utm_term"
                />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup label="UTM Content">
                <FormControl
                  name="utm-content"
                  value={url.utmContent}
                  onChange={(utmContent) => setURL({ utmContent })}
                  placeholder="utm_content"
                />
              </FormGroup>
            </div>
          </div>

          <hr />
          {error && <div className="alert alert-danger">{error}</div>}
          {!error && (
            <div>
              Final URL:
              <input className="form-control mb-1" readOnly value={finalUrl} />
              <button className="btn btn-primary" onClick={copy}>
                Click to copy
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
