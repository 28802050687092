import React from "react";

export default function PrettyDisplayAttributes(props: {
  attributes: { key: string; value: string }[];
}) {
  const { attributes } = props;
  return (
    <dl className="bg-white p-2 my-0">
      {attributes.map(({ key, value }, index) => (
        <React.Fragment key={`${index}-kv`}>
          <dt>{key}</dt>
          <dd>{value}</dd>
        </React.Fragment>
      ))}
    </dl>
  );
}
