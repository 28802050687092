import React, { useState } from "react";
import BenchmarkMarketingLogo from "@assets/benchmarking-marketing-logo.svg";

import { PrimaryButton } from "@shared/buttons";
import { BenchmarksLearnMore } from "./benchmark-help-modal";

export const BenchmarkAvailabilityCard = ({
  available,
  onContinue,
}: {
  available: boolean;
  onContinue: () => void;
}) => {
  const [play, setPlay] = useState(false);
  return (
    <div className={"w-full py-5 flex items-center"}>
      <div className="w-[45%] relative">
        {!play ? (
          <>
            <i
              className="fa-solid fa-play absolute text-[5rem] left-[50%] top-[50%] z-[1] text-primary py-[1.5rem] px-[2rem] hover:bg-[#cfdffc] rounded-full cursor-pointer"
              style={{ transform: "translate(-50%, -50%)" }}
              onClick={() => setPlay(true)}
            ></i>
            <img
              className="w-full"
              alt="benchmark-marketing-logo"
              src={BenchmarkMarketingLogo}
            />
          </>
        ) : (
          <iframe
            width="100%"
            height="500px"
            src="https://www.youtube.com/embed/KuoiBEuhiro?&autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        )}
      </div>
      <div className="flex flex-col ml-10">
        <div className="text-sm text-primary font-semibold">
          {available ? "Now available" : "Not available"}
        </div>
        <div className="text-4xl font-bold mt-4">
          <div>To scale or cut?</div>
          <div>Set up your Benchmarks</div>
          <div>to make the decision easier.</div>
        </div>
        <div className="mt-4 font-light">
          Benchmarks take out the guesswork by showing how your are trending
          day-to-day against your baseline performance.
        </div>
        <div className="font-light">
          Measurements are calculated using your past performance data.
        </div>
        {available && (
          <PrimaryButton onClick={onContinue} className="w-[8rem] py-4 mt-5">
            Continue
          </PrimaryButton>
        )}

        {!available && <BenchmarksLearnMore className="mt-5 font-light" />}
      </div>
    </div>
  );
};
