import React from "react";
import { DatePicker } from "@shared/date-picker";
import { useUser } from "@components/user-context";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { Annotation, NewAnnotation } from "@features/custom-annotations";
import { usePrettyLogEventPage } from "@utils/analytics";

interface AnnotationDatePickerProps {
  currentAnnotation: Annotation | NewAnnotation;
  errors: FieldErrors<NewAnnotation>;
  control: Control<NewAnnotation>;
}

export const AnnotationDatePicker = ({
  currentAnnotation,
  errors,
  control,
}: AnnotationDatePickerProps) => {
  const { user } = useUser();
  const isEdit = "id" in currentAnnotation;
  const prettyLogEventPage = usePrettyLogEventPage();

  return (
    <Controller
      control={control}
      name="annotatedDate"
      rules={{ required: "Date is required" }}
      render={({ field }) => {
        const onChange = (date: string | null) => {
          field.onChange(date);
          if (!isEdit) {
            prettyLogEventPage({
              domain: "Annotations",
              action: "onClick",
              additionalActionInfo: "date selector on New Note flyout",
              data: {
                annotatedDate: date,
              },
            });
          }
        };
        return (
          <DatePicker
            {...field}
            onChange={onChange}
            name="annotatedDate"
            timezone={user.timezone}
            isDisabled={isEdit}
            errors={errors}
          />
        );
      }}
    />
  );
};
