import { FetchSettingsDetails } from "@nb-api-graphql-generated/FetchSettingsDetails";
import { DataWrapper } from "@pages/settings/data-wrapper";
import { LegacyRechargeTag } from "@pages/settings/tracking-script-tags/legacy-recharge-tag";
import { NorthbeamPixelTag } from "@pages/settings/tracking-script-tags/northbeam-pixel-tag";
import { ShopifyOrderStatusTag } from "@pages/settings/tracking-script-tags/shopify-order-status-tag";
import { ShopifyPostPurchaseUpsellTag } from "@pages/settings/tracking-script-tags/shopify-post-purchase-upsell-tag";
import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import {
  CUSTOM_ECOMMERCE_SETUP,
  GLOBAL_CONNECTABLE_POINT_OF_SALE_PLATFORMS,
} from ".";
import { StepContainer } from "../step-container";

interface TrackingScriptTagsStepProps {
  selectedPointOfSalePlatforms: string[];
  outerNextStep: () => void;
}

export const TrackingScriptTagsStep: FC<
  Partial<StepWizardChildProps> & TrackingScriptTagsStepProps
> = ({ outerNextStep, selectedPointOfSalePlatforms, goToNamedStep }) => {
  return (
    <StepContainer
      onMoveBackwardButtonClicked={() => {
        if (selectedPointOfSalePlatforms.length === 0) {
          goToNamedStep && goToNamedStep("point-of-sale-collection");
        }
        const pointOfSalePlatformsToConnect =
          selectedPointOfSalePlatforms.filter((platform) =>
            Object.keys(GLOBAL_CONNECTABLE_POINT_OF_SALE_PLATFORMS).includes(
              platform,
            ),
          );

        if (
          selectedPointOfSalePlatforms.length >
          pointOfSalePlatformsToConnect.length
        ) {
          goToNamedStep && goToNamedStep(CUSTOM_ECOMMERCE_SETUP);
        } else {
          goToNamedStep &&
            goToNamedStep(
              pointOfSalePlatformsToConnect[
                pointOfSalePlatformsToConnect.length - 1
              ],
            );
        }
      }}
      onSkipLinkClicked={() => {
        outerNextStep && outerNextStep();
      }}
      onMoveForwardButtonClicked={() => {
        outerNextStep && outerNextStep();
      }}
      stepByStepLink="https://scribehow.com/shared/Shopify_Pixel_Walk_Me_Through_It__oSZj3dqvReyHh0W9xfOUdg"
      videoDemoLink="https://drive.google.com/file/d/1OBxw6_jOg7KnZDeAWZldqnScH1ZVi01z/view?usp=sharing"
    >
      <DataWrapper>
        {(data: FetchSettingsDetails) => {
          const { installationScripts } = data.me;

          return (
            <>
              <h3>Let&apos;s setup the Northbeam pixel</h3>

              {/* We should always display the base pixel */}
              <NorthbeamPixelTag installationScripts={installationScripts} />

              {/* Shopify specific pixels */}
              {selectedPointOfSalePlatforms.includes("Shopify") && (
                <>
                  <ShopifyOrderStatusTag
                    installationScripts={installationScripts}
                  />
                  <ShopifyPostPurchaseUpsellTag
                    installationScripts={installationScripts}
                  />
                </>
              )}

              {/* Anybody could use recharge */}
              <LegacyRechargeTag installationScripts={installationScripts} />
            </>
          );
        }}
      </DataWrapper>
    </StepContainer>
  );
};
