import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  BenchmarkAttributionModelEnum,
  BenchmarkAttributionWindowEnum,
  BenchmarkPlatformEnum,
  BenchmarkTypeEnum,
  PlatformCACRoasBenchmarksInput,
} from "@nb-api-graphql-generated/global-types";

import classNames from "classnames";
import Modal from "react-modal";
import {
  BenchmarkChooseAndSet,
  ChooseAndSetValidationTargets,
} from "./benchmark-choose-and-set-modal";
import { BenchmarkReview } from "./benchmark-review-modal";
import { isNil, keyBy } from "lodash";
import { CancelConfirmationModal } from "./cancel-confirmation-modal";
import {
  BenchmarkModalNavigationRoute,
  useBackRouteAtom,
  useCurrentBenchmarkRouteAtom,
  useCustomBenchmarksByPlatform,
  useHasUnsavedChangesAtom,
  useNBCalculatedBenchmarksByPlatform,
} from "@/atoms/benchmarking-atom";
import {
  BenchmarksByPlatform,
  CustomBenchmarks,
  PlatformBenchmarks,
} from "./custom-benchmarks-modal";
import { useNorthbeamMutation } from "@utils/hooks";
import {
  UpsertBenchmarkTargets,
  UpsertBenchmarkTargetsVariables,
} from "@nb-api-graphql-generated/UpsertBenchmarkTargets";
import {
  FETCH_BENCHMARK_SETTINGS,
  SET_BENCHMARK_TARGET,
} from "@gql/benchmarks";
import { BenchmarkHelpModal } from "./benchmark-help-modal";

import {
  useBenchmarks,
  useGetBenchmarkPlatformsToUse,
} from "@hooks/use-platform-benchmarks";
import { notifySuccess } from "@shared/notify";
import { useBenchmarkAttributionWindow } from "@hooks/benchmarks";
import { prettyLogEvent } from "@utils/analytics";
import omitDeep from "omit-deep-lodash";

const BenchmarkStep = ({
  step,
  active,
  checked,
}: {
  step: BenchmarkModalNavigationRoute;
  active: boolean;
  checked: boolean;
}) => {
  return (
    <div
      className={classNames(
        "w-full pl-5 py-3 flex items-center",
        active ? "bg-[#E0EBFD]" : undefined,
      )}
    >
      {checked && (
        <i
          className="fa-solid fa-circle-check pr-2"
          style={{ fontSize: "1.2em", color: "#367BF3" }}
        />
      )}
      {!checked && (
        <i
          className="fa-regular fa-circle pr-2"
          style={{ fontSize: "1.2em" }}
        />
      )}
      {step === "choose-and-set" ? "Choose and set" : "Review"}
    </div>
  );
};

const BenchmarkModalSkeleton = ({
  children,
  closeModal,
}: PropsWithChildren<{ closeModal: () => void }>) => {
  const [prevRoute] = useBackRouteAtom();
  const [potentialCurrentStep, navigateTo] = useCurrentBenchmarkRouteAtom();
  const currentStep =
    potentialCurrentStep === "help-me" ? prevRoute : potentialCurrentStep;
  const currentStepIsChooseAndSet = currentStep === "choose-and-set";

  return (
    <Modal
      isOpen={true}
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",

          transform: "translate(-50%, -50%)",
          borderRadius: "25px",
          width: "fit-content",
          height: "fit-content",
          padding: 0,
        },

        overlay: {
          zIndex: 2001,
          backgroundColor: "rgba(30, 30, 30, 0.47)",
        },
      }}
      contentLabel="Benchmark Settings Modal"
    >
      <div className="2xlh:h-[55rem] xl:h-[45rem] lg:h-[40rem] md:h-[25rem] xl:w-[70rem] lg:w-[60rem] relative">
        <button onClick={closeModal} className="absolute top-5 right-5">
          <i
            className="fa-regular fa-xmark font"
            style={{ fontSize: "1.5em" }}
          />
        </button>
        <div className="flex w-full h-full">
          <div className="min-w-[13rem] pt-10 bg-[#F5F7FD] h-full relative">
            <div className="font-semibold pl-5">Setup a benchmark</div>
            <div
              className={classNames("pt-5", {
                "opacity-25": potentialCurrentStep === "help-me",
              })}
            >
              <BenchmarkStep
                active={currentStepIsChooseAndSet}
                step={"choose-and-set"}
                checked
              />
              <BenchmarkStep
                active={!currentStepIsChooseAndSet}
                // choose one of "review-with-nb-benchmarks" or "review-with-custom-benchmarks"
                // we treat them the same here
                step={"review-with-nb-benchmarks"}
                checked={currentStep !== "choose-and-set"}
              />
            </div>
            <div
              className="absolute bottom-5 left-5 cursor-pointer"
              onClick={() => {
                if (potentialCurrentStep !== "help-me") {
                  navigateTo("help-me");
                  prettyLogEvent({
                    page: "Sales",
                    domain: "Benchmarking - Help Modal",
                    action: "Navigate to page",
                  });
                }
              }}
            >
              <i className="fa-regular fa-circle-question text-[1.5rem] text-[#555860]" />
            </div>
          </div>
          <div className="px-5 pl-9 pr-10 pt-10 flex-grow flex flex-col">
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
};

type BenchmarkModalProps = {
  closeModal: () => void;
  attributionModel: BenchmarkAttributionModelEnum;
};

// This flow is specifically where you go straight to the Custom Benchmarks Modal
export const CustomBenchmarksFlowModal = ({
  closeModal,
  initialModalNavigation,
  setInitialModalNavigation,
  attributionModel,
}: BenchmarkModalProps & {
  initialModalNavigation: BenchmarkModalNavigationRoute;
  setInitialModalNavigation: (
    initalModalNav: BenchmarkModalNavigationRoute | null,
  ) => void;
}) => {
  const [hasUnsavedChanges] = useHasUnsavedChangesAtom();

  const platformsToUse = useGetBenchmarkPlatformsToUse();
  const [currentRoute, navigateTo] = useCurrentBenchmarkRouteAtom();
  const [, setCustomBenchmarksByPlatform] = useCustomBenchmarksByPlatform();
  useEffect(() => {
    if (initialModalNavigation) navigateTo(initialModalNavigation);
  }, [initialModalNavigation, navigateTo]);
  const memoizedAttributionModels = useMemo(() => {
    return [attributionModel];
  }, [attributionModel]);

  const [currentAttributionWindowOption] = useBenchmarkAttributionWindow();

  const {
    benchmarkSettings,
    benchmarkSettingsLoading,
    platformBenchmarksLoading,
    orderedData,
    customBenchmarks,
  } = useBenchmarks(
    attributionModel,
    currentAttributionWindowOption.value,
    platformsToUse,
    memoizedAttributionModels,
  );

  const benchmarksByPlatform = useMemo(() => {
    return orderedData?.reduce<BenchmarksByPlatform>((acc, curr) => {
      if (!acc[curr.platform]) acc[curr.platform] = [];
      acc[curr.platform].push(curr as PlatformBenchmarks);
      return acc;
    }, {} as BenchmarksByPlatform);
  }, [orderedData]);

  useEffect(() => {
    if (!customBenchmarks) {
      setCustomBenchmarksByPlatform(null);
      return;
    }

    setCustomBenchmarksByPlatform(
      omitDeep(
        customBenchmarks.reduce<BenchmarksByPlatform>((acc, curr) => {
          if (!acc[curr.platform]) acc[curr.platform] = [];
          acc[curr.platform].push(curr as PlatformBenchmarks);
          return acc;
        }, {} as BenchmarksByPlatform),
        "__typename",
      ) as BenchmarksByPlatform,
    );
  }, [customBenchmarks, setCustomBenchmarksByPlatform]);

  const [submitBenchmarkTarget, { loading: loadingSubmission }] =
    useNorthbeamMutation<
      UpsertBenchmarkTargets,
      UpsertBenchmarkTargetsVariables
    >(SET_BENCHMARK_TARGET, {
      refetchQueries: [
        {
          query: FETCH_BENCHMARK_SETTINGS,
          variables: {
            attributionWindow: currentAttributionWindowOption.value,
            attributionModel,
          },
        },
      ],
    });
  const loading =
    benchmarkSettingsLoading || platformBenchmarksLoading || loadingSubmission;

  // there should only ever be one benchmarkType
  const benchmarkType = useMemo(() => {
    return benchmarkSettings?.[0].benchmark;
  }, [benchmarkSettings]);

  const benchmarkTargetsByPlatform = useMemo(() => {
    const targetsByPlatform = {} as ChooseAndSetValidationTargets;
    benchmarkSettings?.forEach((setting) => {
      targetsByPlatform[setting.platform] = setting.target;
    });
    return targetsByPlatform;
  }, [benchmarkSettings]);

  const onSubmit = useCallback(
    async (submittedCustomBenchmarksByPlatform?: BenchmarksByPlatform) => {
      if (!benchmarkSettings) return;
      const benchmarkSettingsByPlatform = keyBy(benchmarkSettings, "platform");

      const benchmarkTargetsInput: UpsertBenchmarkTargetsVariables["benchmarkTargets"] =
        [];
      if (submittedCustomBenchmarksByPlatform) {
        Object.entries(submittedCustomBenchmarksByPlatform).forEach(
          ([platform, customBenchmarks]) => {
            const { attributionWindow, benchmark, target } =
              benchmarkSettingsByPlatform[platform];
            return benchmarkTargetsInput.push({
              attributionModel,
              attributionWindow,
              benchmarkType: benchmark,
              customBenchmarks:
                customBenchmarks as PlatformCACRoasBenchmarksInput[],
              platform: platform as BenchmarkPlatformEnum,
              target,
            });
          },
        );
      }

      await submitBenchmarkTarget({
        variables: {
          benchmarkTargets: benchmarkTargetsInput,
        },
      });
      notifySuccess({
        message:
          "Congratulations!  You have successfully set 1 day buying benchmarks.",
      });
      prettyLogEvent({
        page: "Sales",
        domain: "Benchmarking - Modal",
        additionalActionInfo: submittedCustomBenchmarksByPlatform
          ? "custom-benchmarks"
          : "nb-calculated-submission",
        action: "onSubmit",
        data: {
          benchmarkTargetsInput,
        },
      });
      closeModal();
    },
    [attributionModel, benchmarkSettings, closeModal, submitBenchmarkTarget],
  );
  const [cancelConfirmationModalOpen, setCancelConfirmationModal] =
    useState(false);
  const [startOver, setDoStartOver] = useState(false);

  return (
    <BenchmarkModalSkeleton
      closeModal={() => {
        if (hasUnsavedChanges) {
          prettyLogEvent({
            page: "Sales",
            domain: "Benchmarking - Modal",
            action: "onClick",
            additionalActionInfo: "close-attempt",
          });
          return setCancelConfirmationModal(true);
        }
        setInitialModalNavigation(null);
        prettyLogEvent({
          page: "Sales",
          domain: "Benchmarking - Modal",
          action: "onExit",
        });
        return closeModal();
      }}
    >
      <CancelConfirmationModal
        open={cancelConfirmationModalOpen}
        goBack={() => setCancelConfirmationModal(false)}
        onConfirmCancel={() => {
          if (startOver) {
            navigateTo("choose-and-set");
            setInitialModalNavigation(null);
            setCustomBenchmarksByPlatform(null);
            prettyLogEvent({
              page: "Sales",
              domain: "Benchmarking - Modal",
              action: "onClick",
              additionalActionInfo: "start-over",
            });
          } else {
            prettyLogEvent({
              page: "Sales",
              domain: "Benchmarking - Modal",
              action: "onExit",
            });
            closeModal();
          }
        }}
      />
      {currentRoute === "review-with-custom-benchmarks" &&
        benchmarksByPlatform &&
        benchmarkType && (
          <CustomBenchmarks
            benchmarkTargetsByPlatform={benchmarkTargetsByPlatform}
            benchmarkType={benchmarkType}
            nbBenchmarksByPlatform={benchmarksByPlatform}
            dataIsNullable={false}
            loading={loading}
            onSubmit={onSubmit}
            attributionModel={attributionModel}
            standalone={{
              startOver: () => {
                if (hasUnsavedChanges) {
                  setCancelConfirmationModal(true);
                  setDoStartOver(true);
                } else {
                  navigateTo("choose-and-set");
                  setInitialModalNavigation(null);
                  setCustomBenchmarksByPlatform(null);
                  prettyLogEvent({
                    page: "Sales",
                    domain: "Benchmarking - Modal",
                    action: "onClick",
                    additionalActionInfo: "start-over",
                  });
                }
              },
            }}
          />
        )}

      {currentRoute === "help-me" && <BenchmarkHelpModal />}
    </BenchmarkModalSkeleton>
  );
};

export const BenchmarkModal = ({
  closeModal,
  attributionModel,
}: BenchmarkModalProps) => {
  const [benchmarkType, setBenchmarkType] = useState<BenchmarkTypeEnum | null>(
    null,
  );
  const [benchmarkAttributionWindow, setBenchmarkAttributionWindow] =
    useState<BenchmarkAttributionWindowEnum | null>(null);
  const [benchmarkTargets, setBenchmarkTargets] =
    useState<ChooseAndSetValidationTargets>();

  const [cancelConfirmationModalOpen, setCancelConfirmationModal] =
    useState(false);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useHasUnsavedChangesAtom();
  const allSelected =
    benchmarkType && benchmarkAttributionWindow && !isNil(benchmarkTargets);

  const [currentRoute, navigateTo] = useCurrentBenchmarkRouteAtom();

  const [nbCalculatedBenchmarksByPlatform] =
    useNBCalculatedBenchmarksByPlatform();

  const [submitBenchmarkTarget, { loading: loadingSubmission }] =
    useNorthbeamMutation<
      UpsertBenchmarkTargets,
      UpsertBenchmarkTargetsVariables
    >(SET_BENCHMARK_TARGET, {
      refetchQueries: [
        {
          query: FETCH_BENCHMARK_SETTINGS,
          variables: {
            attributionWindow: benchmarkAttributionWindow,
            attributionModel,
          },
        },
      ],
    });

  const onSubmit = useCallback(
    async (submittedCustomBenchmarksByPlatform?: BenchmarksByPlatform) => {
      if (!allSelected) return;

      await submitBenchmarkTarget({
        variables: {
          benchmarkTargets: Object.entries(benchmarkTargets).map(
            ([platform, target]) => {
              const customBenchmarks = submittedCustomBenchmarksByPlatform?.[
                platform as BenchmarkPlatformEnum
              ] as PlatformCACRoasBenchmarksInput[];
              return {
                attributionModel,
                attributionWindow: benchmarkAttributionWindow,
                benchmarkType,
                platform: platform as BenchmarkPlatformEnum,
                target,
                customBenchmarks,
              };
            },
          ),
        },
      });
      prettyLogEvent({
        page: "Sales",
        domain: "Benchmarking - Modal",
        additionalActionInfo: submittedCustomBenchmarksByPlatform
          ? "custom-benchmarks"
          : "nb-calculated-submission",
        action: "onSubmit",
        data: {
          benchmarkTargets,
          benchmark: benchmarkType,
          attributionModel,
          attributionWindow: benchmarkAttributionWindow,
        },
      });
      closeModal();
      notifySuccess({
        message:
          "Congratulations!  You have successfully set 1 day buying benchmarks.",
      });
    },
    [
      allSelected,
      attributionModel,
      benchmarkAttributionWindow,
      benchmarkTargets,
      benchmarkType,
      closeModal,
      submitBenchmarkTarget,
    ],
  );

  return (
    <>
      <CancelConfirmationModal
        open={cancelConfirmationModalOpen}
        goBack={() => setCancelConfirmationModal(false)}
        onConfirmCancel={() => {
          setCancelConfirmationModal(false);
          closeModal();
          prettyLogEvent({
            page: "Sales",
            domain: "Benchmarking - Modal",
            action: "onExit",
          });
        }}
      />
      <BenchmarkModalSkeleton
        closeModal={() => {
          if (hasUnsavedChanges) return setCancelConfirmationModal(true);
          prettyLogEvent({
            page: "Sales",
            domain: "Benchmarking - Modal",
            action: "onExit",
          });
          return closeModal();
        }}
      >
        {currentRoute === "choose-and-set" && (
          <BenchmarkChooseAndSet
            onNext={({
              targetsByPlatform,
              benchmarkType,
              attributionWindow,
            }) => {
              setBenchmarkTargets(targetsByPlatform);
              setBenchmarkAttributionWindow(attributionWindow);
              setBenchmarkType(benchmarkType);
              navigateTo("review-with-nb-benchmarks");
            }}
            setHasUnsavedChanges={setHasUnsavedChanges}
            currentBenchmarkType={benchmarkType}
            currentBenchmarkAttributionWindow={benchmarkAttributionWindow}
            currentBenchmarkTargets={benchmarkTargets}
            attributionModel={attributionModel}
          />
        )}
        {currentRoute === "review-with-nb-benchmarks" && allSelected && (
          <BenchmarkReview
            benchmarkType={benchmarkType}
            attributionWindow={benchmarkAttributionWindow}
            benchmarkTargetsByPlatform={benchmarkTargets}
            closeModal={closeModal}
            loadingSubmission={loadingSubmission}
            onSubmit={onSubmit}
            attributionModel={attributionModel}
          />
        )}
        {currentRoute === "review-with-custom-benchmarks" &&
          allSelected &&
          nbCalculatedBenchmarksByPlatform && (
            <CustomBenchmarks
              nbBenchmarksByPlatform={nbCalculatedBenchmarksByPlatform}
              dataIsNullable={false}
              loading={loadingSubmission}
              onSubmit={onSubmit}
              attributionModel={attributionModel}
              benchmarkType={benchmarkType}
              benchmarkTargetsByPlatform={benchmarkTargets}
            />
          )}
        {currentRoute === "help-me" && <BenchmarkHelpModal />}
      </BenchmarkModalSkeleton>
    </>
  );
};
