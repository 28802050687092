import { customTooltip } from "@components/reports/charts";
import { useUser } from "@components/user-context";
import { GetOverviewPerformance_me_overviewPage_performanceMetrics as OverviewPageMetric } from "@nb-api-graphql-generated/GetOverviewPerformance";
import {
  dateRangeLength,
  formatNumberApproximate,
  formatNumberExact,
} from "@north-beam/nb-common";
import { colorScheme1, spaceColorScheme } from "@pages/objects/label-colors";
import { fmtDateInterval } from "@utils/index";
import { ChartDataset, ChartOptions, TooltipItem } from "chart.js";
import chroma from "chroma-js";
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const MetricBarChart = ({
  selectedMetric,
}: {
  selectedMetric: OverviewPageMetric;
}) => {
  const { user } = useUser();
  const dateLabels = useMemo(
    () => selectedMetric.timeSeriesPointDateRanges.map(fmtDateInterval),
    [selectedMetric],
  );

  const numDatasets = selectedMetric.timeSeriesPointLabels.length;

  const datasets: ChartDataset<"bar">[] = useMemo(() => {
    const iterateNumber = Math.min(
      numDatasets,
      selectedMetric.timeSeriesPointData.length,
    );
    const colors = user.featureFlags.enableDesignRefresh
      ? spaceColorScheme(iterateNumber)
      : colorScheme1(iterateNumber);
    const rv = [];
    const timeRangeBucketIsFull = selectedMetric.timeSeriesPointDateRanges.map(
      (v) => dateRangeLength(v) >= selectedMetric.windowSizeDays,
    );

    for (let i = 0; i < iterateNumber; ++i) {
      const label = selectedMetric.timeSeriesPointLabels[i];
      const data = selectedMetric.timeSeriesPointData[i].map(Number);
      const color = colors[i];
      const bgColor = chroma(color).alpha(1.0).hex("rgba");

      const dataset: ChartDataset<"bar"> = {
        label,
        data,
        borderWidth: 1,
        barPercentage: 0.75,
        categoryPercentage: 0.7,
        line: 0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        backgroundColor: timeRangeBucketIsFull.map((v) =>
          v ? bgColor : createDiagonalPattern(bgColor),
        ),
        borderColor: color,
        elements: {
          point: {
            borderColor: color,
            backgroundColor: color,
            borderWidth: 0,
            hoverRadius: 3.5,
            hoverBorderWidth: 0,
            radius: 0,
            hitRadius: 0,
          },
        },
      };

      rv.push(dataset);
    }
    return rv;
  }, [numDatasets, selectedMetric, user.featureFlags.enableDesignRefresh]);

  const options: ChartOptions<"bar"> = useMemo(() => {
    let label: (item: TooltipItem<"bar">) => string[];

    if (selectedMetric.timeSeriesDisplayMode === "stack") {
      label = ({ raw, datasetIndex, dataIndex }) => {
        return [
          formatNumberExact(Number(raw), selectedMetric.format),
          datasetIndex === 0
            ? "-"
            : `(${formatNumberExact(
                Math.round(
                  (Number(raw) - datasets[datasetIndex - 1].data?.[dataIndex] ??
                    0) * 100,
                ) / 100,
                selectedMetric.format,
              )})`,
        ];
      };
    } else {
      label = ({ raw }) => [
        formatNumberExact(Number(raw), selectedMetric.format),
      ];
    }
    return {
      responsive: true,
      maintainAspectRatio: false,
      resizeDelay: 400,
      transitions: {
        resize: {
          animation: {
            duration: 400,
          },
        },
      },
      layout: {
        padding: {
          right: -100,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          mode: "index",
          intersect: false,
          external: customTooltip,
          itemSort: (a: TooltipItem<"bar">, b: TooltipItem<"bar">) =>
            b.datasetIndex - a.datasetIndex,
          callbacks: {
            label,
            beforeLabel: (v: TooltipItem<"bar">) =>
              datasets[v.datasetIndex ?? 0].label ?? "",
          },
        },
      },
      scales: {
        y: {
          position: "left",
          grid: {
            tickLength: 0,
          },
          beginAtZero: true,
          ticks: {
            maxTicksLimit: 4,
            padding: 10,
            font: {
              family: "Inter",
            },
            callback: (value: string | number) =>
              formatNumberApproximate(Number(value), selectedMetric.format),
          },
          afterFit: (scaleInstance) => {
            scaleInstance.width = 60;
          },
          stacked: false,
        },
        x: {
          grid: {
            drawOnChartArea: false,
            drawTicks: false,
            drawBorder: false,
            display: false,
          },
          ticks: {
            padding: 5,
            autoSkip: true,
            maxRotation: 0,
            maxTicksLimit: 5,
            callback: (_val, index: number) => {
              const value = dateLabels[index];
              return value?.split(" — ")?.[1] ?? value;
            },
            font: {
              family: "Inter",
            },
          },
          stacked: selectedMetric.timeSeriesDisplayMode === "stack",
        },
      },
    };
  }, [selectedMetric, datasets, dateLabels]);

  return <Bar data={{ labels: dateLabels, datasets }} options={options} />;
};

const createDiagonalPattern = (color = "black") => {
  const shape = document.createElement("canvas");
  shape.width = 10;
  shape.height = 10;
  const c = shape.getContext("2d")!;
  c.strokeStyle = color;
  c.beginPath();
  c.moveTo(2, 0);
  c.lineTo(10, 8);
  c.stroke();
  c.beginPath();
  c.moveTo(0, 8);
  c.lineTo(2, 10);
  c.stroke();
  return c.createPattern(shape, "repeat");
};
