"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NB_PLATFORM = void 0;
var NB_PLATFORM;
(function (NB_PLATFORM) {
    NB_PLATFORM["FACEBOOK"] = "fb";
    NB_PLATFORM["GOOGLE"] = "adwords";
    NB_PLATFORM["TIKTOK"] = "tiktok";
    NB_PLATFORM["PINTEREST"] = "pinterest";
    NB_PLATFORM["MICROSOFT"] = "microsoft";
    NB_PLATFORM["SNAPCHAT"] = "snapchat";
    NB_PLATFORM["LINKEDIN"] = "linkedIn";
})(NB_PLATFORM = exports.NB_PLATFORM || (exports.NB_PLATFORM = {}));
