import { SalesViewTemplate } from "@pages/sales/sales-views-context";
import { customerTypeRevenueAndRoas } from "./customer-type-revenue-and-roas";
import { customerTypeTransactionsAndCac } from "./customer-type-transactions-and-cac";
import { detailed } from "./detailed";
import { overviewRevenueAndRoas } from "./overview-revenue-and-roas";
import { overviewTransactionsAndCac } from "./overview-transactions-and-cac";
import { siteTraffic } from "./site-traffic";
import { subscriptionsAccrual } from "./subscriptions-accrual";
import { subscriptionsCash } from "./subscriptions-cash";

export const salesViewsTemplates: SalesViewTemplate[] = [
  overviewTransactionsAndCac,
  overviewRevenueAndRoas,
  siteTraffic,
  customerTypeTransactionsAndCac,
  customerTypeRevenueAndRoas,
  detailed,
  subscriptionsAccrual,
  subscriptionsCash,
];
