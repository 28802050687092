import {
  Chart as ChartJs,
  FontSpec,
  LineController,
  TooltipModel,
} from "chart.js";
import { max } from "lodash";
import React from "react";
import { Chart, ChartProps } from "react-chartjs-2";

declare module "chart.js" {
  interface ChartTypeRegistry {
    LineWithLine: ChartTypeRegistry["line"];
  }
}

class LineWithLineChart extends LineController {
  draw() {
    super.draw();

    const { chart } = this;
    const { ctx } = chart;
    const meta = this.getMeta();

    const {
      pointRadius,
      pointBorderWidth,
      pointBackgroundColor,
      // eslint-disable-next-line
      // @ts-ignore
    } = meta.controller.options;

    for (const pt of meta.data) {
      // eslint-disable-next-line
      // @ts-ignore
      const data = pt.$context.raw;

      if (data.annotations) {
        pt.options.radius = 5;
        pt.options.borderWidth = 3;
        pt.options.backgroundColor = "#fff";
      }
    }

    // reset styles to default values
    for (const pt of meta.data) {
      pt.options.radius = pointRadius;
      pt.options.borderWidth = pointBorderWidth;
      pt.options.backgroundColor = pointBackgroundColor;
    }

    // eslint-disable-next-line
    // @ts-ignore
    if (chart.tooltip?._active.length) {
      // eslint-disable-next-line
      // @ts-ignore
      const activePoint = chart.tooltip._active[0];
      const { x } = activePoint.element;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;

      ctx.save();

      // draw line
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#dee2e6";
      ctx.stroke();

      // draw dot
      // eslint-disable-next-line
      // @ts-ignore
      for (const activePoint of chart.tooltip._active) {
        const { x, y, options } = activePoint.element;
        const { hoverRadius, backgroundColor } = options;
        ctx.beginPath();
        ctx.arc(x, y, hoverRadius, 0, 2 * Math.PI, false);
        ctx.fillStyle = backgroundColor;
        ctx.fill();
      }

      ctx.restore();
    }
  }
}
LineWithLineChart.id = "LineWithLine";
LineWithLineChart.defaults = LineController.defaults;
ChartJs.register(LineWithLineChart);

export const LineWithLine = (props: Omit<ChartProps, "type">) => {
  React.useEffect(() => {
    return () => {
      const $tooltip = document.getElementById("chartjs-tooltip");
      if ($tooltip) {
        $tooltip.style.opacity = "0";
      }
    };
  });
  return <Chart {...props} type="LineWithLine" />;
};

export function customTooltip({ tooltip }: { tooltip: TooltipModel<any> }) {
  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = "<div></div>";
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  function getBody(bodyItem: any): string[][] | null {
    if ((bodyItem?.before?.[0] ?? "").startsWith("[HIDE]")) {
      return null;
    }

    const rows: string[][] = [];

    const mainRow = [];
    for (let i = 0; i < bodyItem.lines.length; ++i) {
      mainRow.push(
        `<td align="right"><b>${bodyItem.before[i] ?? ""} &nbsp;</b></td>`,
      );
      mainRow.push(`<td align="right">${bodyItem.lines[i] ?? ""}</td>`);
    }
    rows.push(mainRow);

    const annotations: string[] = [];
    for (const element of bodyItem.after) {
      annotations.push(
        `<div class="chart-tooltip-annotation">${element}</div>`,
      );
    }
    if (annotations.length > 0) {
      const annotationsHTML = annotations.join("");
      rows.push([`<td colspan="${mainRow.length}">${annotationsHTML}</td>`]);
    }
    return rows;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines: any[] = tooltip.title;
    const bodyLines = tooltip.body.map(getBody);
    const numCols = max(bodyLines.map((line) => line?.[0]?.length ?? 0)) ?? 0;

    const innerHTML = [
      "<table>",
      "<thead>",
      ...titleLines.map(
        (line) => `<tr><th colspan=${numCols + 1}>${line}</th></tr>`,
      ),
      "</thead>",
      "<tbody>",
      ...bodyLines.map((body, i) => {
        if (!body) {
          return "";
        }

        const colors = tooltip.labelColors[i] as any;
        const icon = `<i class="fas fa-circle" style="color: ${colors.borderColor}"></i>`;

        const outputRows: string[][] = [];
        for (let rowNum = 0; rowNum < body.length; ++rowNum) {
          const tds = [...body[rowNum]];
          if (rowNum === 0) {
            tds.unshift(`<td>${icon} &nbsp;</td>`);
          } else {
            tds.unshift(`<td></td>`);
          }
          outputRows.push(tds);
        }

        return outputRows.map((vs) => `<tr>${vs.join("")}</tr>`).join("");
      }),
      "</tbody>",
      "</table>",
    ].join("");

    const table = document.createElement("div");
    table.classList.add("shadow", "rounded", "p-2", "chart-tooltip");

    const { bodyFont } = tooltip.options;
    Object.assign(table.style, {
      fontFamily: (bodyFont as FontSpec).family,
      fontSize: (bodyFont as FontSpec).size + "px",
      fontStyle: (bodyFont as FontSpec).style,
    } as CSSStyleDeclaration);
    table.innerHTML = innerHTML;

    tooltipEl.innerHTML = table.outerHTML;
  }

  // `this` will be the overall tooltip
  // eslint-disable-next-line
  // @ts-ignore
  const position = this._chart.canvas.getBoundingClientRect();
  const update: any = {
    opacity: "1",
    zIndex: "3000",
    position: "absolute",
    left: "",
    right: "",
    top: position.top + window.pageYOffset + tooltip.caretY + "px",
    pointerEvents: "none",
    margin: "10px",
    transform: `translateY(-100%)`,
  };

  // This will ensure that the tooltip doesn't get cut off by the window on the edges of the screen
  if (tooltip.caretX < window.innerWidth / 4) {
    update["left"] = position.left + window.pageXOffset + tooltip.caretX + "px";
  } else {
    update["right"] =
      window.innerWidth -
      position.right +
      -window.pageXOffset +
      (position.width - tooltip.caretX) +
      "px";
  }

  // Display, position, and set styles for font
  Object.assign(tooltipEl.style, update as CSSStyleDeclaration);
}
