import React from "react";
import { InstallationScriptDetails } from "@north-beam/nb-common";
import { TrackingScriptCard } from "./tracking-script-card";

interface TrackingScriptTagsProps {
  installationScripts: InstallationScriptDetails;
  startExpanded?: boolean;
}
export const ShopifyOrderStatusTag = ({
  installationScripts,
  startExpanded,
}: TrackingScriptTagsProps): React.ReactElement => (
  <TrackingScriptCard
    displayName="Shopify: order status page"
    header="If you use Shopify, please follow the instructions below to add the order status pixel."
    scriptCode={installationScripts.shopify_order_status_pixel}
    startExpanded={startExpanded}
    descriptionHTML={
      <>
        <p>
          <b>Installation instructions</b>
        </p>
        <p>
          This pixel is required for all clients, and helps us track
          conversions.
        </p>
        <ol>
          <li>
            Click the button above to copy this script code to your clipboard.
          </li>
          <li>Go to your Shopify admin panel.</li>
          <li>
            Click on <b>Settings</b> in the bottom left hand corner.
            <div style={{ maxWidth: 800 }}>
              <img
                src="//static.northbeam.io/images/shopify-navigate-to-settings.png"
                className="img-fluid p-3"
                alt="Shopify navigate to settings instructions"
              />
            </div>
          </li>
          <li>
            Click on <b>Checkout</b> once you are on the Settings page.
            <div style={{ maxWidth: 800 }}>
              <img
                src="//static.northbeam.io/images/shopify-navigate-to-checkout.png"
                className="img-fluid p-3"
                alt="Shopify navigate to checkout instructions"
              />
            </div>
          </li>
          <li>
            Scroll down to the <b>Order status page</b> section, and paste the
            code (from step 1) in the <b>Additional Scripts</b> section.
            <div style={{ maxWidth: 800 }}>
              <img
                src="https://storage.googleapis.com/static.northbeam.io/images/shopify-order-status-page-additional-scripts"
                className="img-fluid p-3"
                alt="Shopify additional script section highlight"
              />
            </div>
          </li>
        </ol>
      </>
    }
  />
);
