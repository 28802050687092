import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import clsx from "clsx";
import React from "react";
import { ModalPanel } from "../modals/modal-panel";
import { MetricConfig } from "./metric-category-builder";

interface ReorderPanelProps<T extends MetricConfig> {
  reorderVisibleMetrics: (ordering: T[]) => void;
  visibleMetrics: T[];
}

export const ReorderPanel = <T extends MetricConfig>({
  reorderVisibleMetrics,
  visibleMetrics,
}: ReorderPanelProps<T>) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = visibleMetrics.findIndex(
        ({ name }) => name === active.id,
      );
      const newIndex = visibleMetrics.findIndex(
        ({ name }) => name === over?.id,
      );

      const newOrdering = arrayMove(visibleMetrics, oldIndex, newIndex);
      reorderVisibleMetrics(newOrdering);
    }
  };

  return (
    <ModalPanel title="Displayed Metrics (prioritized)" data-testid="metric-reorder-panel">
      <div className="bg-[#E9EDF5] text-[#596577] px-2 py-1 font-semibold text-sm">
        {visibleMetrics.length} selected
      </div>
      <div className="p-2">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext items={visibleMetrics.map(({ name }) => name)}>
            {visibleMetrics.map(({ name, visible }) => (
              <div key={name} className={classNames(!visible && "hidden")}>
                <ColumnOrderRow columnName={name} />
              </div>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </ModalPanel>
  );
};

const ColumnOrderRow = ({ columnName }: { columnName: string }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: columnName,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={clsx(
        "flex items-center p-1.5 mb-2 bg-[#EFEFEF] rounded-md",
        "animate__animated animate__faster animate__fadeIn",
      )}
      style={style}
      {...attributes}
      {...listeners}
    >
      <i className="fa-regular fa-grip-dots-vertical pr-1.5" />
      <span className="text-[#596577] text-sm">{columnName}</span>
    </div>
  );
};
