import psl from "psl";

interface VerifyDomainOptions {
  exclude?: [string];
}

export const verifyDomain = (
  mixedCaseDomainName: string,
  options?: VerifyDomainOptions,
): string | null => {
  const domainName = mixedCaseDomainName.toLowerCase();
  if (domainName.includes("www.")) {
    return "Do not include www. in domain name.";
  }
  if (
    domainName.includes("shopify") &&
    !options?.exclude?.includes(domainName)
  ) {
    return "Do not include 'shopify' in domain name.";
  }
  const parsedDomainObject = psl.parse(domainName);
  if (parsedDomainObject.error) {
    return parsedDomainObject.error.message;
  } else if (parsedDomainObject.domain === null) {
    return "Invalid domain name";
  } else {
    return null;
  }
};
