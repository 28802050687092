import { gql } from "@apollo/client";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import {
  formatDateRange,
  FormattedDateRange,
} from "@components/date-range-picker";
import { LoadingSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import styled from "@emotion/styled";
import { FetchBrandLogoURL } from "@nb-api-graphql-generated/FetchBrandLogoURL";
import { todayLocalAsISODate } from "@north-beam/nb-common";
import { useNorthbeamQuery } from "@utils/hooks";
import { parseDateRangeArgument } from "@utils/index";
import html2pdf from "html2pdf.js";
import React, { useState } from "react";
import ImageUploader from "react-images-upload";
import { Overview } from "./overview";
import { useReportBodyState } from "./report-body-control";

export function OverviewPagePrintMode(): JSX.Element {
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const { user } = useUser();
  const [title, setTitle] = useState<string>(
    user.companyName || "Northbeam Data Report",
  );
  const { state } = useReportBodyState(breakdownConfigs);
  const dateRange = parseDateRangeArgument(
    state.dateRange,
    todayLocalAsISODate(),
  );
  const exportAreaRef = React.useRef<HTMLDivElement>(null);
  const [isLoading, setLoading] = React.useState(true);
  const [fileName, setFileName] = React.useState(
    `${user.companyName} (${formatDateRange(dateRange)})`,
  );
  const {
    refetch: logoRefetch,
    loading: logoLoading,
    data: logoData,
  } = useNorthbeamQuery<FetchBrandLogoURL>(FETCH_BRAND_LOGO_INFO);

  const exportAsPdf = React.useCallback(async () => {
    if (exportAreaRef.current) {
      html2pdf()
        .set({
          margin: [topMarginPx, marginPx, marginPx, marginPx],
          filename: `${fileName}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: {
            unit: "px",
            format: [pageWidthPx, Math.round(pageWidthPx * Math.SQRT2)],
            orientation: "portrait",
          },
        })
        .from(exportAreaRef.current)
        .save();
      // await exportToPdfRigged(exportAreaRef.current);
    }
  }, [exportAreaRef, fileName]);

  if (logoLoading) {
    return <LoadingSlide />;
  }

  const logoURL = logoData?.me.brandLogoURL ?? null;

  return (
    <>
      <div className="container my-3">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">PDF Export</div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div>
                      <p>
                        Click the button below to export the report as a PDF.
                      </p>
                      <div className="mb-3">
                        File name:{" "}
                        <div className="input-group text-right">
                          <input
                            className="form-control"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value as any)}
                          />
                          <div className="input-group-append">
                            <div className="input-group-text">.pdf</div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={exportAsPdf}
                        disabled={isLoading}
                      >
                        {isLoading ? "Report loading..." : "Export"}
                      </button>
                    </div>
                    <div className="control-label mb-2 mt-5">
                      Set report title:
                    </div>
                    <input
                      type="text"
                      disabled={isLoading}
                      className="form-control"
                      value={isLoading ? "Report loading..." : title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                  </div>
                  <div className="col-2"></div>
                  <div className="col">
                    <BrandLogoUploadSection
                      logoURL={logoURL}
                      onBrandLogoUpdated={logoRefetch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="mx-auto border border-dark"
        style={reportWrapperBoundaryStyles}
      >
        <PrintStyle style={reportBoundaryStyles} ref={exportAreaRef}>
          <div className="px-4">
            <div className="row">
              <div className="col">
                <div className="flex">
                  <div className="logo">NORTHBEAM</div>
                  <div className="flex-grow-1"></div>
                </div>
              </div>
              <div className="col text-center">
                <h1 className="company-name">{title}</h1>
                <p>
                  <FormattedDateRange dateRange={dateRange} />
                </p>
              </div>
              <div className="col">
                <div className="flex">
                  <div className="flex-grow-1"></div>
                  {logoURL && (
                    <div style={{ width: "200px", height: "54px" }}>
                      <img src={logoURL} className="img-fluid" alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Overview
            breakdownConfigs={breakdownConfigs}
            reportEventName="Request Overview Report Print View"
            onLoadingStatusChange={setLoading}
          />
        </PrintStyle>
      </div>
    </>
  );
}

interface BrandLogoUploadSectionProps {
  logoURL: string | null;
  onBrandLogoUpdated: () => void;
}

function BrandLogoUploadSection({
  logoURL,
  onBrandLogoUpdated,
}: BrandLogoUploadSectionProps) {
  const { user } = useUser();
  const [file, setFile] = React.useState<File | null>(null);

  const handleChange = React.useCallback(
    (files: File[], pictures: string[]) => {
      setFile(files[0]);
    },
    [setFile],
  );

  const handleUpdateClick = React.useCallback(async () => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    await user.callApi({
      url: "/api/uploads/brandLogo",
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    onBrandLogoUpdated();
    setFile(null);
  }, [file, onBrandLogoUpdated, setFile, user]);

  return (
    <>
      <p>
        Brand logo: ️<b>{logoURL ? "Uploaded" : "Not uploaded"}</b>
      </p>
      <ImageUploader
        withIcon={true}
        onChange={handleChange}
        buttonText="Choose new brand logo"
        withPreview={true}
        singleImage={true}
      />
      <div>
        <button
          className="btn btn-primary"
          disabled={!file}
          onClick={handleUpdateClick}
        >
          Update
        </button>
      </div>
    </>
  );
}

const PrintStyle = styled.div`
  .company-name {
    font-size: 3.5em;
  }
  .nb-accounting-methods-read-more,
  .nb-accounting-methods-short-explanation,
  .nb-divider,
  .nb-breakdown-table,
  .nb-report-body-control {
    display: none;
  }
`;

const pageWidthPx = 1440;
const marginPx = 42;
const topMarginPx = 63;

const reportWrapperBoundaryStyles = {
  width: `${pageWidthPx}px`,
  minWidth: `${pageWidthPx}px`,
  maxWidth: `${pageWidthPx}px`,
  padding: `${marginPx}px`,
  paddingTop: `${topMarginPx}px`,
} as const;

const reportBoundaryStyles = {
  width: `${pageWidthPx - 2 * marginPx}px`,
  minWidth: `${pageWidthPx - 2 * marginPx}px`,
  maxWidth: `${pageWidthPx - 2 * marginPx}px`,
} as const;

const FETCH_BRAND_LOGO_INFO = gql`
  query FetchBrandLogoURL {
    me {
      id
      brandLogoURL
    }
  }
`;
