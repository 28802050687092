import fbLogo from "@assets/fb-logo.svg";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import { formatDate, formatNumberApproximate } from "@north-beam/nb-common";
import { ConnectionCard } from "@pages/settings/connection-card";
import {
  ConnectionStatusType,
  LoginComponentChildrenProps,
  LoginComponentWithFrontendIntegrationProps,
} from "@pages/settings/connection-status-manager";
import axios from "axios";
import React from "react";
import FacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from "react-facebook-login";
import { serializeError } from "serialize-error";

const type = ConnectionType.integration_facebook_ads;

export function FBConnectionCard({
  integrationDetails,
}: {
  integrationDetails: any;
}) {
  const successfulConnectionExtraText = React.useCallback(() => {
    return (
      <>
        <p className="card-text">
          <em>Note: if you want to update these details, please contact us!</em>
        </p>
      </>
    );
  }, []);

  return (
    <ConnectionCard
      type={type}
      connectionTypeName="Facebook Ads"
      connectionCallToAction={`
        Integrate your Facebook Ads data to start receiving insights about
        your Facebook ad spend. Connecting your Facebook account will not reset
        any of your ads to the learning phase.
      `}
      logoUrl={fbLogo}
      logoAltText="Facebook Ads Logo"
      successfulConnectionExtraText={successfulConnectionExtraText}
      allowsMultipleAccounts
    >
      {({ setStatus, accounts }: LoginComponentChildrenProps) => (
        <FacebookLoginComponent
          setStatus={setStatus}
          accounts={accounts}
          integrationDetails={integrationDetails}
        />
      )}
    </ConnectionCard>
  );
}

function FacebookLoginComponent({
  setStatus,
  integrationDetails,
}: LoginComponentWithFrontendIntegrationProps) {
  const facebookScopes = integrationDetails.facebookScopes || {};

  const callback = React.useCallback(
    async (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
      if ("accessToken" in response) {
        const accessToken = response.accessToken;
        setStatus({ type: ConnectionStatusType.FETCHING_AVAILABLE_ACCOUNTS });
        try {
          const adAccounts = await fetchFacebookAdAccounts(accessToken);

          const accountList = adAccounts.map(({ name, id, insights }: any) => {
            let descriptionHTML = "";
            if (insights) {
              const insight = insights.data[0];
              const spend = formatNumberApproximate(insight.spend, "dollars");
              const impressions = formatNumberApproximate(
                insight.impressions,
                "integer",
              );
              const startDate = formatDate(insight.date_start);
              const endDate = formatDate(insight.date_end);
              descriptionHTML = `${spend} spent, ${impressions} impressions between ${startDate} and ${endDate}`;
            }
            return {
              name,
              id,
              descriptionHTML,
              data: {
                access_token: accessToken,
                account_id: id,
              },
            };
          });

          setStatus({
            type: ConnectionStatusType.SELECTING_ACCOUNT,
            accountList,
          });
        } catch (e) {
          setStatus({
            type: ConnectionStatusType.HAS_ERROR,
            errorMessage: JSON.stringify(serializeError(e)),
          });
        }
      } else {
        setStatus({
          type: ConnectionStatusType.HAS_ERROR,
          errorMessage: `Could not connect to the Facebook API. Error: ${response.status}`,
        });
      }
    },
    [setStatus],
  );

  return (
    <FacebookLogin
      appId={integrationDetails.facebookAppId}
      autoLoad={false}
      fields="name,email,picture"
      scope={Object.keys(facebookScopes).join(",")}
      cssClass="btn btn-primary btn-large"
      textButton="Connect to Facebook Ads"
      callback={callback}
    />
  );
}

async function fetchFacebookAdAccounts(accessToken: string) {
  const rv: any[] = [];

  let url =
    "https://graph.facebook.com/v14.0/me/adaccounts?fields=name,insights&limit=50";

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    // eslint-disable-next-line prefer-spread
    rv.push.apply(rv, response.data.data);

    if (response.data.paging?.next) {
      url = response.data.paging?.next;
    } else {
      break;
    }
  }

  return rv;
}
