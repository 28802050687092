import classNames from "classnames";
import React from "react";
import ChangeFractionDisplay from "@components/change-fraction";
import { TimeSeriesChart } from "@components/reports/charts";
import { MetricFormat } from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import { MetricHeader } from "../metric-header";

export interface TopLevelSummary {
  metrics: TopLevelSummaryMetric[];
}
export interface TopLevelSummaryMetric {
  name: string;
  currentValue: string;
  format: MetricFormat;
  comparison?: {
    changeFraction: number;
    isPositiveChangeGood: boolean;
  };
  timeSerieses: TimeSeries[];
  xAxisFormat: "date" | "days";
  description?: string;
  hideComparisonFromTabDisplay: boolean;
  changeFractionAreaText?: string;
}
export interface TimeSeries {
  name: string;
  points: TimeSeriesPoint[];
  comparePoints?: TimeSeriesPoint[];
  bandPoints?: [TimeSeriesPoint[], TimeSeriesPoint[]];
}
export interface TimeSeriesPoint {
  date: string;
  value: string | number;
  annotations?: string[];
}

export function ChartArea(props: {
  topLevel: TopLevelSummary;
  isLoading: boolean;
}) {
  const {
    topLevel: { metrics },
    isLoading,
  } = props;

  const [selectedIndices_, setSelectedIndices] = React.useState<number[]>([
    0, 1,
  ]);
  const selectedIndices: number[] = selectedIndices_.map((selectedIndex) =>
    Math.min(metrics.length - 1, selectedIndex),
  );

  const clickCallback = React.useCallback(
    (index: number) => {
      const metric = metrics[index];
      logEvent("Selects Top Level Metric", { Metric: metric.name });
      if (selectedIndices_.includes(index)) {
        setSelectedIndices(selectedIndices_.filter((i) => i !== index));
      } else {
        if (selectedIndices_.length <= 1) {
          setSelectedIndices([...selectedIndices_, index]);
        } else {
          setSelectedIndices([selectedIndices_[1], index]);
        }
      }
    },
    [metrics, setSelectedIndices, selectedIndices_],
  );

  const selectedMetricsArray = selectedIndices.map(
    (selectedIndex) => metrics[selectedIndex],
  );

  const Tab = ({ index }: { index: number }) => {
    const metric = metrics[index];
    const isSelected = selectedIndices.includes(index);
    const classes: Record<string, boolean> = {
      btn: true,
      "text-left": true,
      "nav-link": true,
      "btn-outline-muted": true,
      "bg-white": true,
    };

    if (isSelected) {
      classes["active"] = true;
    }

    return (
      <li className="nav-item">
        <button
          style={{
            margin: "0.25rem",
          }}
          className={classNames(classes)}
          onClick={() => clickCallback(index)}
        >
          <CardTab
            title={metric.name}
            value={metric.currentValue}
            delta={metric.comparison}
            changeFractionAreaText={
              metric.hideComparisonFromTabDisplay
                ? metric.changeFractionAreaText
                : undefined
            }
            descriptionHTML={metric.description}
          />
        </button>
      </li>
    );
  };

  return (
    <div className={classNames({ card: 1, "waiting-interstitial": isLoading })}>
      <div className="card-header px-1">
        <ul className="nav">
          {metrics.map((_, index) => {
            return <Tab index={index} key={index} />;
          })}
        </ul>
      </div>
      {selectedMetricsArray.map((selectedMetric) => (
        <div className="card-body" key={selectedMetric.name}>
          <h4>{selectedMetric.name}</h4>
          <TimeSeriesChart
            format={selectedMetric.format}
            yLabel={selectedMetric.name}
            serieses={selectedMetric.timeSerieses}
            xAxisFormat={selectedMetric.xAxisFormat}
            noLegend={true}
            height={50}
          />
        </div>
      ))}
    </div>
  );
}

interface CardTabProps {
  title: string;
  value: string;
  descriptionHTML?: string;
  changeFractionAreaText?: string;
  delta?: {
    changeFraction: number;
    isPositiveChangeGood: boolean;
  };
}

function CardTab(props: CardTabProps) {
  const { title, value, delta, descriptionHTML, changeFractionAreaText } =
    props;

  return (
    <MetricHeader
      name={title}
      value={value}
      breakOnSpace={true}
      descriptionHTML={descriptionHTML}
    >
      {changeFractionAreaText ? (
        <small className="text-muted">{changeFractionAreaText}</small>
      ) : delta ? (
        <ChangeFractionDisplay {...delta} />
      ) : (
        <small>&nbsp;</small>
      )}
    </MetricHeader>
  );
}
