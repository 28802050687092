import {
  useOrderSummaryGraphKPILoading,
  useOrderSummaryGraphLoading,
  useOrderTableLoading,
} from "@/atoms/order-page-atoms";

export const useOrderQueriesLoading = () => {
  const [orderTableLoading] = useOrderTableLoading();
  const [orderKPIsLoading] = useOrderSummaryGraphKPILoading();
  const [orderGraphLoading] = useOrderSummaryGraphLoading();
  return orderTableLoading || orderKPIsLoading || orderGraphLoading;
};
