import { Table } from "@north-beam/nb-common";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { logEvent } from "./analytics";

function constructCsvString(table: Table): string {
  const columns = [...table.dimensionColumns, ...table.factColumns];
  const fields = columns.map((v) => v.name);
  const maxLevel = table.rows.reduce(function (prev, current) {
    return (prev.rowMetadata?.level || -1) > (current.rowMetadata?.level || -1)
      ? prev
      : current;
  }).rowMetadata?.level;

  const data: any[] = [];
  for (const inputRow of table.rows) {
    if (typeof maxLevel === "number" && !Number.isNaN(maxLevel)) {
      if (inputRow.rowMetadata.level !== maxLevel) {
        continue;
      }
    }

    const outputRow: any = {};
    for (const column of columns) {
      outputRow[column.name] =
        inputRow.export[column.key] ?? inputRow.display[column.key];
    }
    data.push(outputRow);
  }
  return Papa.unparse({ fields, data });
}
export type ReportType =
  | "Sales"
  | "Sales Model Comparison"
  | "Customers"
  | "DailySalesData"
  | "Sales Timeseries";

export function downloadCsv(
  reportType: ReportType,
  fileName: string,
  table: Table | null,
) {
  if (table) {
    logEvent(`Exported ${reportType} Report to CSV`);

    const csvString = constructCsvString(table);
    const blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });
    saveAs(blob, fileName);
  }
}

const constructCsvStringV2 = (table: any): string => {
  const columns = [...table.dimensionColumns, ...table.metricColumns];
  const fields = columns.map((v) => v.title);
  const maxLevel = table.rows.reduce((prev: any, current: any) => {
    return (prev.rowMetadata?.level || -1) > (current.rowMetadata?.level || -1)
      ? prev
      : current;
  }).rowMetadata?.level;

  const data: any[] = [];
  for (const inputRow of table.rows) {
    if (typeof maxLevel === "number" && !Number.isNaN(maxLevel)) {
      if (inputRow.rowMetadata.level !== maxLevel) {
        continue;
      }
    }

    const outputRow: any = {};
    for (const column of columns) {
      outputRow[column.title] =
        inputRow.export[column.id] ?? inputRow.display[column.id];
    }
    data.push(outputRow);
  }
  return Papa.unparse({ fields, data });
};

export function downloadCsvV2(
  reportType: ReportType,
  fileName: string,
  table: Table | null,
) {
  if (table) {
    logEvent(`Exported ${reportType} Report to CSV`);

    const csvString = constructCsvStringV2(table);
    const blob = new Blob([csvString], { type: "text/plain;charset=utf-8" });
    saveAs(blob, fileName);
  }
}
