import { TertiaryButton } from "@shared/buttons";
import { get } from "lodash";
import React, { useEffect } from "react";
import {
  Control,
  useFieldArray,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { LabelOptionValue } from "./label-option-value";

interface LabelOptionsProps {
  categoryIndex: number;
  errors: any;
  control: Control<any>;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  simulateTrafficLabelMatches: (categoryIndex: number) => void;
}

const rules = { minLength: 1, required: "Must have at least one option" };

export const LabelOptions = ({
  categoryIndex,
  errors,
  control,
  register,
  watch,
  simulateTrafficLabelMatches,
}: LabelOptionsProps) => {
  const [existingOptions, setExistingOptions] = React.useState<string[]>([]);
  const {
    fields: labelOptions,
    append: appendOption,
    remove: removeOption,
    // TODO: Fix this react-hook-form issue, it blocks build when not using ts-ignore
    // eslint-disable-next-line
    // @ts-ignore
  } = useFieldArray({
    control,
    name: `data.${categoryIndex}.options`,
    rules,
  });

  useEffect(() => {
    const subscription = watch((value) => {
      const options = get(value, `data.${categoryIndex}.options`);
      if (options) {
        setExistingOptions(options.map((value: string) => value.trim()));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setExistingOptions, categoryIndex]);

  return (
    <>
      {labelOptions.map((option, optionIndex) => (
        <LabelOptionValue
          key={option.id}
          className="mb-2"
          errors={errors}
          register={register}
          categoryIndex={categoryIndex}
          optionIndex={optionIndex}
          removeOption={removeOption}
          existingOptions={existingOptions}
          simulateTrafficLabelMatches={simulateTrafficLabelMatches}
        />
      ))}

      <TertiaryButton
        data-test={`category.${categoryIndex}.addOption`}
        className="mb-4 block"
        onClick={() => appendOption("")}
      >
        + Add Segment
      </TertiaryButton>
    </>
  );
};
