import React from "react";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import {
  UpsertConnection,
  UpsertConnectionVariables,
} from "@nb-api-graphql-generated/UpsertConnection";
import {
  DeleteConnection,
  DeleteConnectionVariables,
} from "@nb-api-graphql-generated/DeleteConnection";
import { useNorthbeamMutation } from "@utils/hooks";
import {
  FETCH_SANITIZED_CONNECTIONS_BY_TYPE,
  UPSERT_CONNECTION,
  DELETE_CONNECTION,
} from "./queries";
import { ApolloError } from "@apollo/client";

interface MutationMetadata {
  loading: boolean;
  error?: ApolloError;
}

interface ManageExistingConnectionProps {
  connectionType: ConnectionType;
  connectionId: string;
  children: (
    updateConnection: any,
    updateInfo: MutationMetadata,
    destroyConnection: any,
    destroyInfo: MutationMetadata,
  ) => React.ReactNode;
}

export default function ManageExistingConnection({
  connectionType,
  connectionId,
  children,
}: ManageExistingConnectionProps) {
  const [upsertConnection, upsertInfo] = useNorthbeamMutation<
    UpsertConnection,
    UpsertConnectionVariables
  >(UPSERT_CONNECTION, {});

  const [deleteConnection, destroyInfo] = useNorthbeamMutation<
    DeleteConnection,
    DeleteConnectionVariables
  >(DELETE_CONNECTION, {
    update: (cache, { data }) => {
      const deletedConnection = data?.deleteConnection?.connection;
      if (!deletedConnection) return;
      const queryResult: any = cache.readQuery({
        query: FETCH_SANITIZED_CONNECTIONS_BY_TYPE,
        variables: {
          type: deletedConnection.type,
        },
      });

      const connections = queryResult?.me?.connections;
      if (!connections) return;
      // update cache for connection being "soft deleted"
      const newConnections = connections.map((connection: any) => {
        if (connection.id === deletedConnection.id) {
          return {
            ...connection,
            force_reconnect: true,
          };
        }
        return connection;
      });
      cache.writeQuery({
        query: FETCH_SANITIZED_CONNECTIONS_BY_TYPE,
        data: {
          ...queryResult,
          me: { ...queryResult.me, connections: newConnections },
        },
        variables: { type: deletedConnection.type },
      });
    },
  });

  const updateConnection = (newConnectionData: any) => {
    return upsertConnection({
      variables: {
        id: connectionId,
        type: connectionType,
        data: newConnectionData,
      },
    });
  };

  const destroyConnection = () => {
    return deleteConnection({
      variables: {
        id: connectionId,
        type: connectionType,
      },
    });
  };

  return (
    <>
      {children(updateConnection, upsertInfo, destroyConnection, destroyInfo)}
    </>
  );
}
