import { logEvent } from "@utils/analytics";
import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import {
  CUSTOM_ECOMMERCE_SETUP,
  GET_OTHER_POINT_OF_SALE_PLATFORMS,
  GLOBAL_CONNECTABLE_POINT_OF_SALE_PLATFORMS,
} from ".";
import { StepContainer } from "../step-container";

interface PointOfSalePlatformCollectionStepProps {
  toggleSelectedPointOfSalePlatforms: (arg0: string) => void;
  selectedPointOfSalePlatforms: string[];
  outerPreviousStep: () => void;
  outerNextStep: () => void;
}

export const PointOfSalePlatformCollectionStep: FC<
  Partial<StepWizardChildProps> & PointOfSalePlatformCollectionStepProps
> = ({
  goToNamedStep,
  toggleSelectedPointOfSalePlatforms,
  selectedPointOfSalePlatforms,
  outerPreviousStep,
  outerNextStep,
}) => {
  if (!goToNamedStep) {
    return null;
  }

  const userConnectablePointOfSalePlatforms =
    GLOBAL_CONNECTABLE_POINT_OF_SALE_PLATFORMS;
  const userOtherPointOfSalePlatforms = GET_OTHER_POINT_OF_SALE_PLATFORMS();

  return (
    <StepContainer
      onMoveBackwardButtonClicked={outerPreviousStep}
      onMoveForwardButtonClicked={() => {
        logEvent(
          "Pixel Placement: Proceeded with a/some platform(s) selected",
          {
            selectedPointOfSalePlatforms,
          },
        );
        const pointOfSalePlatformsToConnect =
          selectedPointOfSalePlatforms.filter((platform) =>
            Object.keys(userConnectablePointOfSalePlatforms).includes(platform),
          );

        if (pointOfSalePlatformsToConnect.length > 0) {
          goToNamedStep(pointOfSalePlatformsToConnect[0]);
        } else {
          goToNamedStep(CUSTOM_ECOMMERCE_SETUP);
        }
      }}
      moveForwardButtonDisabled={selectedPointOfSalePlatforms.length === 0}
      onSkipLinkClicked={() => {
        outerNextStep();
        logEvent("Pixel Placement: Skipped");
      }}
    >
      <h3>Pixel Placement</h3>
      <p>
        Please proceed through each of the following instructions to add
        Northbeam&apos;s tracking script tags to the appropriate locations.
      </p>
      <hr />
      <h5 className="secondary-font-purple">
        Which e-commerce platform(s) do you use for point-of-sale?
      </h5>
      <>
        {Object.keys({
          ...userConnectablePointOfSalePlatforms,
          ...userOtherPointOfSalePlatforms,
        }).map((name) => (
          <div className="form-check col-8 mb-3" key={name}>
            <input
              className="form-check-input align-middle position-relative mr-1"
              type="checkbox"
              checked={selectedPointOfSalePlatforms.includes(name)}
              onChange={() => toggleSelectedPointOfSalePlatforms(name)}
            />
            <label className="form-check-label">
              <img
                src={
                  {
                    ...userConnectablePointOfSalePlatforms,
                    ...userOtherPointOfSalePlatforms,
                  }[name]
                }
                className="mx-2 float-left"
                alt="Platform logo"
                style={{
                  height: "1.5rem",
                  width: "auto",
                }}
              />
              {name}
            </label>
          </div>
        ))}
      </>
    </StepContainer>
  );
};
