import moment from "moment";
import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";

export interface CalendarProps {
  onSelect(date: moment.Moment | null): void;
  date: moment.Moment | null;
}

export const Calendar = (props: CalendarProps) => {
  const { date, onSelect } = props;
  const [focused, setFocused] = useState<boolean | null>(false);

  return (
    <SingleDatePicker
      date={date}
      onDateChange={(date) => onSelect(date)}
      focused={focused}
      onFocusChange={({ focused }) => setFocused(focused)}
      id="idhere"
      isOutsideRange={(day) => day.isBefore(moment().subtract(1, "years"))}
      hideKeyboardShortcutsPanel
    />
  );
};
