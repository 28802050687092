import { gql } from "@apollo/client";

export const GET_CUSTOM_SALES_VIEWS = gql`
  query GetCustomSalesViews {
    me {
      id
      customSalesViews
    }
  }
`;

export const INSERT_CUSTOM_SALES_VIEW = gql`
  mutation InsertCustomSalesView($name: String!, $data: JSONObject!) {
    insertCustomSalesView(name: $name, data: $data)
  }
`;

export const DELETE_CUSTOM_SALES_VIEW = gql`
  mutation DeleteCustomSalesView($id: String!) {
    deleteCustomSalesView(id: $id)
  }
`;

export const GET_CUSTOM_SALES_VIEWS_ORDERING = gql`
  query GetCustomSalesViewsOrdering {
    me {
      id
      customSalesViewsOrdering
    }
  }
`;

export const UPSERT_CUSTOM_SALES_VIEWS_ORDERING = gql`
  mutation UpsertCustomSalesViewsOrdering($ids: [String!]!) {
    upsertCustomSalesViewsOrdering(ids: $ids)
  }
`;
