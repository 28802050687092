import loadingScreenNoText from "@assets/loading-screen-no-text.gif";
import loadingScreen from "@assets/loading-screen.gif";
import { LogOnMount } from "@utils/analytics";
import classNames from "classnames";
import clsx from "clsx";
import React from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "./user-context";

export interface TitleSlideProps {
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
  style?: React.CSSProperties;
}

export function TitleSlide(props: TitleSlideProps) {
  return (
    <div
      className={classNames(
        props.className,
        "flex items-center title-slide w-100 h-100",
      )}
      style={props.style}
    >
      <div className="mx-auto">{props.children}</div>
    </div>
  );
}

export const LoadingSlide = ({ className }: { className?: string }) => (
  <TitleSlide>
    <img
      src={loadingScreen}
      alt="Loading screen gif"
      className={classNames("w-1/2 m-auto", className)}
    />
  </TitleSlide>
);

export const LoadingSlideNoText = ({ className }: { className?: string }) => (
  <TitleSlide>
    <img
      src={loadingScreenNoText}
      alt="Loading screen no text gif"
      className={classNames("w-1/2 m-auto", className)}
    />
  </TitleSlide>
);

export const LoadingSpinner = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsx("absolute w-36 left-[50%] top-[50%] z-[1]", className)}
      style={{ transform: "translate(-50%, -50%)" }}
    >
      <LoadingSlideNoText className="w-auto" />
    </div>
  );
};

export function NoDataView(props: TitleSlideProps) {
  const { user } = useUser();

  if (user.isAdmin) {
    return <AdminView {...props} />;
  }

  return (
    <TitleSlide {...props}>
      <h3 className="text-center">
        No data.{" "}
        <span role="img" aria-label="frown">
          🙁
        </span>
      </h3>
      <p className="mt-2 text-center">
        Please add some integrations in the settings page and then get in touch
        with the Northbeam team!
      </p>
    </TitleSlide>
  );
}

export function AdminView(props: TitleSlideProps) {
  return (
    <TitleSlide {...props}>
      <h3 className="text-center">
        Hello admin!{" "}
        <span role="img" aria-label="cowboy">
          🤠
        </span>
      </h3>
      <p className="mt-2 text-center">
        Choose a client to look at from the navigation bar at the top of this
        page.
      </p>
    </TitleSlide>
  );
}

export function Page404(props: TitleSlideProps) {
  const location = useLocation();

  return (
    <TitleSlide {...props}>
      <LogOnMount name="Page not found" data={location} />
      <h3 className="text-center">
        Oops! Nothing to see here.{" "}
        <span role="img" aria-label="frown">
          🙁
        </span>
      </h3>
      <p className="mt-2 text-center">
        Please click the back button, or click the logo to go home.
      </p>
    </TitleSlide>
  );
}
