import React, { useState } from "react";
import AccordionWrapper from "react-faq-component";
import { serializeError } from "serialize-error";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";

export interface AdditionalAccountProps {
  extraAccountIds: Array<string>;
  updateConnection: any;
  availableAccounts: any[];
  connectedAccounts: any[];
  integrationType: ConnectionType | undefined;
}

interface ExtraAccountIds {
  extraAccountIds: Array<string>;
}

interface ExtraAccountIdsMetadata {
  metadata: ExtraAccountIds;
}

enum RequestStatus {
  NO_REQUEST,
  LOADING,
  SUCCESSFUL,
  HAS_ERROR,
}

export default function AddAdditionalAccount({
  extraAccountIds, // connected accounts, but not main account
  connectedAccounts,
  updateConnection,
  availableAccounts: rawAvailableAccounts,
  integrationType,
}: AdditionalAccountProps) {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.NO_REQUEST);
  const [error, setError] = useState<string>("");
  const [selectedAccount, setSelectedAccount] = useState<string>("");
  const connectedAccountIdentfiers = new Set(
    connectedAccounts.map((account) => {
      if (integrationType === ConnectionType.integration_google_adwords) {
        return account.accountIdentifier.loginCustomerId;
      } else {
        return account.accountIdentifier;
      }
    }),
  );

  const availableAccounts = rawAvailableAccounts[0] ?? [];
  const accounts = availableAccounts.reduce(
    (
      accumulator: Record<string, string>,
      account: { id: string; name: string },
    ) => {
      accumulator[account.name] = account.id;
      return accumulator;
    },
    {},
  );
  const renderAccountIdCheckboxes = () => {
    return (
      <div>
        {Object.entries(accounts).map(([accountName, accountId]) =>
          connectedAccountIdentfiers.has(accountId) ? null : (
            <label key={accountName} style={{ display: "block" }}>
              <input
                type="radio"
                checked={selectedAccount === accountName}
                onChange={() => setSelectedAccount(accountName)}
                style={{ marginRight: 5 }}
              />
              {accountName} | {accountId}
            </label>
          ),
        )}
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            onSave(getParamsForAdditionalAccount());
          }}
          disabled={status === RequestStatus.LOADING || selectedAccount === ""}
        >
          {renderButtonContent()}
        </button>
      </div>
    );
  };

  const onSave = async (newMetadata: ExtraAccountIdsMetadata) => {
    setStatus(RequestStatus.LOADING);
    const result = await updateConnection(newMetadata);
    if (result.errors) {
      setStatus(RequestStatus.HAS_ERROR);
      setError(JSON.stringify(serializeError(result.errors)));
    } else {
      setStatus(RequestStatus.SUCCESSFUL);
    }
  };

  const renderErrorStatus = () => {
    if (status === RequestStatus.HAS_ERROR) {
      return (
        <div className="alert alert-danger mb-3" role="alert">
          An error occurred: &ldquo;{`${JSON.stringify(error)}`}&rdquo;
        </div>
      );
    }
    return null;
  };

  const renderButtonContent = () => {
    return status === RequestStatus.LOADING ? (
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    ) : (
      "Save"
    );
  };

  const getParamsForAdditionalAccount = (): ExtraAccountIdsMetadata => {
    let updatedExtraAccountIds: Array<string>;
    const accountIdToAdd = accounts[selectedAccount];
    if (!extraAccountIds) {
      updatedExtraAccountIds = [accountIdToAdd];
    } else {
      updatedExtraAccountIds = [...extraAccountIds, accountIdToAdd].filter(
        (id) => id,
      );
    }

    return {
      metadata: {
        extraAccountIds: updatedExtraAccountIds,
      },
    };
  };

  return (
    <>
      {renderErrorStatus()}
      <AccordionWrapper
        data={{
          rows: [
            {
              title: "Add another account",
              content: renderAccountIdCheckboxes(),
            },
          ],
        }}
        styles={{
          bgColor: "white",
          rowContentTextSize: "16px",
          rowContentPaddingTop: "10px",
          rowContentPaddingBottom: "10px",
          rowContentPaddingRight: "20px",
          arrowColor: "black",
        }}
        config={{ tabFocus: true }}
      />
    </>
  );
}
