import React, { useEffect, useState } from "react";
import { GenericTooltip } from "@components/tooltip-container";
import { useUser } from "@components/user-context";
import { useContext } from "react";
import { Context } from "../context";
import { useRedirectURL } from "../hooks";
import { FormControl, FormGroup } from "@lib/forms";

export function Facebook() {
  const { domainName, collectorSubdomain } = useContext(Context);
  const { user } = useUser();
  const [finalDestinationUrl, setFinalDestinationUrl] = useState<string>("");
  const [displayUrl, setDisplayUrl] = useState<string>("");

  useEffect(() => {
    const endPosition = finalDestinationUrl.indexOf("/", "https://".length);

    setDisplayUrl(
      finalDestinationUrl.slice(
        0,
        endPosition === -1 ? finalDestinationUrl.length : endPosition,
      ),
    );
  }, [finalDestinationUrl]);

  const facebookWebsiteUrl = useRedirectURL({
    collectorSubdomain,
    domainName,
    displayURL: finalDestinationUrl,
    displayId: user.displayUserId,
    platform: "facebook",
  });

  return (
    <>
      <FormGroup
        label={
          <>
            Paste your Final Destination URL (i.e. the URL you want customers to
            eventually land on) here.
            <br />
            <strong>
              Please ensure that this URL starts with <code>https://</code>
            </strong>
          </>
        }
      >
        <FormControl
          name="facebook-final-url"
          value={finalDestinationUrl}
          onChange={setFinalDestinationUrl}
        />
      </FormGroup>
      <p>
        To test that the URL will work as expected, please paste the{" "}
        <strong>Website URL</strong> into your browser and check to make sure
        you land on the <strong>Destination URL</strong>. Then, paste the{" "}
        <strong>Website URL</strong>, <strong>Display URL</strong>, and{" "}
        <strong>URL parameters</strong> fields into the Ad Manager for the ad
        you want to apply click tracking to.
      </p>

      <FormGroup label="Display URL" className="font-weight-bold">
        <FormControl
          as="textarea"
          rows={1}
          name="facebook-display-url"
          value={displayUrl}
          readOnly
        />
      </FormGroup>

      <FormGroup label="Website URL" className="font-weight-bold">
        <FormControl
          as="textarea"
          rows={4}
          name="facebook-website-url"
          value={facebookWebsiteUrl || ""}
          style={{ wordBreak: "break-all" }}
          readOnly
        />
      </FormGroup>

      <FormGroup
        label={
          <GenericTooltip
            content="These URL parameters do not match the ones found in the UTM Guide."
            overlayClass=""
          >
            <strong>URL parameters</strong>
          </GenericTooltip>
        }
        className="font-weight-bold"
      >
        <FormControl
          as="textarea"
          rows={2}
          name="facebook-url-params"
          value="se_pr=nb%3Afb%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adset.id}}%3A{{ad.id}}"
          style={{ wordBreak: "break-all" }}
          readOnly
        />
      </FormGroup>
    </>
  );
}
