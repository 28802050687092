import { gql } from "@apollo/client";
import { useUser } from "@components/user-context";
import { CurrentStep as CurrentStepQuery } from "@nb-api-graphql-generated/CurrentStep";
import { Onboarding } from "@pages/onboarding";
import { StepName } from "@pages/onboarding/definitions";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

interface OnboardingContentProps {
  children: JSX.Element | JSX.Element[];
  onLogout: () => any;
}

export function PreActivationContent({
  children,
  onLogout,
}: OnboardingContentProps) {
  const { user } = useUser();
  const [currentStep, setCurrentStep] = useState<StepName | null>(null);

  const { loading, data } =
    useNorthbeamQuery<CurrentStepQuery>(CURRENT_STEP_QUERY);
  useEffect(() => {
    const onboardingDetails = data?.me?.onboardingDetails;
    if (onboardingDetails && !currentStep && !user.activatedAt) {
      setCurrentStep(
        (onboardingDetails.currentStep as StepName) || StepName.INTRODUCTION,
      );

      if (
        !window.location.pathname ||
        !window.location.pathname.includes("onboarding")
      ) {
        window.location.pathname = window.location.pathname.split("/")[1];
      }
      window.location.hash =
        onboardingDetails.currentStep || StepName.INTRODUCTION;
    }
  }, [data, currentStep, user.activatedAt]);

  // If the displayUser is activated, show the normal content.
  if (user.activatedAt) {
    return <>{children}</>;
  }

  // If the displayUser
  //   a. has a defaultDisplayUser,
  //   b. isn't activated,
  // Then the logged in user probably wants to see their `defaultDisplayUser`.
  // We'll redirect, but only if the defaultDisplayUser is not the displayUser.
  if (
    user.defaultDisplayUserId &&
    user.defaultDisplayUserId !== user.displayUserId
  ) {
    // HACK HACK HACK
    // It is possible for this to cause an infinite redirect by "chaining" default display user IDs
    // Remove this code if the below error code is uncommon.
    return <Navigate to={`/${user.defaultDisplayUserId}`} />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="onboarding" />} />
      <Route
        path="/onboarding"
        element={<Onboarding onLogout={onLogout} loading={loading} />}
      />
    </Routes>
  );
}

const CURRENT_STEP_QUERY = gql`
  query CurrentStep {
    me {
      onboardingDetails {
        currentStep
        latestStep
      }
    }
  }
`;
