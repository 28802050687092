import {
  AlarmConditionResultRow,
  AlarmConditionV2,
  CompositeAlarmConditionResultRow,
  CompositeAlarmConditionV2,
  isLeafNode,
  isSimpleAlarmCondition,
  Metric,
  notEmpty,
  SimpleAlarmConditionResultRow,
  SimpleAlarmConditionV2,
  treeFlatMap,
} from "@north-beam/nb-common";
import _ from "lodash";

export type MetricFragment = Pick<Metric, "id" | "name" | "format">;

export function subjectItemIdsToMap(
  subjectItems: { subjectItem: { id: string } }[],
): Record<string, true> {
  return _.chain(subjectItems)
    .map((v) => [v.subjectItem.id, true])
    .fromPairs()
    .value();
}

export function flattenAlarmCondition(
  ac: AlarmConditionV2,
): SimpleAlarmConditionV2[] {
  return treeFlatMap<
    SimpleAlarmConditionV2,
    CompositeAlarmConditionV2,
    SimpleAlarmConditionV2 | null
  >(ac, (v) => (isSimpleAlarmCondition(v) ? v : null)).filter(notEmpty);
}

export function flattenResultRows(
  ac: AlarmConditionResultRow,
): SimpleAlarmConditionResultRow[] {
  return treeFlatMap<
    SimpleAlarmConditionResultRow,
    CompositeAlarmConditionResultRow,
    SimpleAlarmConditionResultRow | null
  >(ac, (v) => (isLeafNode(v) ? v : null)).filter(notEmpty);
}

export function pickOneMetricFromAlarmCondition(
  ac: AlarmConditionV2,
): MetricFragment {
  return flattenAlarmCondition(ac)[0].metric;
}
