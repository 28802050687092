import React from "react";
import clsx from "clsx";

interface RadioProps<T> {
  label: string;
  value: T;
  onChange: (value: T) => void;
  checked?: boolean;
  isDisabled?: boolean;
  labelClassName?: string;
  inputClassName?: string;
}

export const Radio = <T extends string>({
  label,
  value,
  onChange,
  checked = false,
  isDisabled = false,
  labelClassName,
  inputClassName,
}: RadioProps<T>) => (
  <span className="flex items-center">
    <input
      id={value}
      className={clsx({ "cursor-pointer": !isDisabled }, inputClassName)}
      type="radio"
      disabled={isDisabled}
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      checked={checked}
    />
    <label
      className={clsx("ml-2 my-0.5 cursor-pointer", labelClassName)}
      htmlFor={value}
    >
      {label}
    </label>
  </span>
);
