import { BenchmarkPlatformFriendlyNames } from "@/constants/benchmarking";
import { useGetBenchmarkPlatformsToUse } from "@hooks/use-platform-benchmarks";
import {
  FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks,
  FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks_cac,
  FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks_roas,
} from "@nb-api-graphql-generated/FetchPlatformBenchmarks";
import {
  BenchmarkAttributionModelEnum,
  BenchmarkPlatformEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";

import { PrimaryButton, SecondaryButton } from "@shared/buttons";
import classNames from "classnames";
import { cloneDeep, isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Control, useForm, useFormState } from "react-hook-form";
import {
  BenchmarkPlatformMetricsGrid,
  EditableBenchmarkPlatformMetricsGrid,
} from "./benchmark-platform-metrics-grid";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import omitDeep from "omit-deep-lodash";
import type { DeepNonNullable, DeepOmit, Writable } from "ts-essentials";
import { LoadingSpinner } from "@components/title-slide-view";
import {
  useCurrentBenchmarkRouteAtom,
  useCustomBenchmarksByPlatform,
  useHasUnsavedChangesAtom,
} from "@/atoms/benchmarking-atom";
import { BenchmarkHelpFooter } from "./benchmark-help-modal";
import bullseyeIcon from "@assets/bullseye.svg";
import { ChooseAndSetValidationTargets } from "./benchmark-choose-and-set-modal";

export type PlatformBenchmarks = Omit<
  Omit<
    DeepNonNullable<FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks>,
    "__typename"
  >,
  "cac" | "roas"
> & {
  cac: Omit<
    FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks_cac,
    "__typename"
  > | null;
  roas: Omit<
    FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks_roas,
    "__typename"
  > | null;
};

export type NonNulledPlatformBenchmarks = DeepOmit<
  DeepNonNullable<FetchPlatformBenchmarks_me_benchmarks_platformBenchmarks>,
  { __typename: never; cac: { __typename: never }; roas: { __typename: never } }
>;

export type BenchmarksByPlatform = {
  [platform in keyof Writable<
    typeof BenchmarkPlatformEnum
  >]: PlatformBenchmarks[];
};

export const BenchmarkError = ({
  control,
}: {
  control: Control<BenchmarksByPlatform>;
}) => {
  const { errors } = useFormState({ control });
  const errorPlatforms = Object.keys(errors).map(
    (p) => BenchmarkPlatformFriendlyNames[p as BenchmarkPlatformEnum],
  );
  if (!errorPlatforms.length) return null;
  return (
    <div className="font-semibold text-danger">
      Custom benchmark errors in: {errorPlatforms.join(", ")}
    </div>
  );
};

const useClonedBenchmarksByPlatform = (
  startingBenchmarksByPlatform: BenchmarksByPlatform,
  customBenchmarksByPlatform: BenchmarksByPlatform | null,
) => {
  return useMemo(
    () =>
      customBenchmarksByPlatform ||
      (omitDeep(
        cloneDeep(startingBenchmarksByPlatform),
        "__typename",
      ) as BenchmarksByPlatform),
    [startingBenchmarksByPlatform, customBenchmarksByPlatform],
  );
};

export const CustomBenchmarks = ({
  nbBenchmarksByPlatform,
  dataIsNullable = false,
  onSubmit,
  loading,
  standalone,
  attributionModel,
  benchmarkTargetsByPlatform,
  benchmarkType,
}: {
  benchmarkType: BenchmarkTypeEnum;
  benchmarkTargetsByPlatform: ChooseAndSetValidationTargets;
  attributionModel: BenchmarkAttributionModelEnum;
  nbBenchmarksByPlatform: BenchmarksByPlatform;
  dataIsNullable?: boolean;
  onSubmit: (
    customBenchmarksByPlatform?: BenchmarksByPlatform,
  ) => Promise<void>;
  loading: boolean;
  standalone?: {
    startOver: () => void;
  };
}) => {
  const [, setHasUnsavedChanges] = useHasUnsavedChangesAtom();
  const [
    initalCustomBenchmarksByPlatformDefault,
    setCustomBenchmarksByPlatform,
  ] = useCustomBenchmarksByPlatform();
  const platformsToUse = useGetBenchmarkPlatformsToUse();
  const validationSchema = useMemo(() => {
    const schema = platformsToUse.reduce<any>((acc, platform) => {
      const numberStringSchema = dataIsNullable
        ? z.coerce.number()
        : z.coerce.number().gt(0);
      if (!acc[platform]) {
        acc[platform] = z.array(
          z
            .object({
              cac: z
                .object({
                  firstTime: numberStringSchema,
                  returning: numberStringSchema,
                  blended: numberStringSchema,
                })
                .passthrough()
                .nullable(),
              roas: z
                .object({
                  firstTime: numberStringSchema,
                  returning: numberStringSchema,
                  blended: numberStringSchema,
                })
                .passthrough()
                .nullable(),
            })
            .passthrough(),
        );
      }
      return acc;
    }, {});

    return z.object({ ...schema });
  }, [dataIsNullable, platformsToUse]);

  const [activePlatform, setActivePlatform] = useState(platformsToUse[0]);
  const clonedBenchmarksByPlatform = useClonedBenchmarksByPlatform(
    nbBenchmarksByPlatform,
    initalCustomBenchmarksByPlatformDefault,
  );

  const [alteredBenchmarksByPlatform, setAlteredBenchmarksByPlatform] =
    useState<BenchmarksByPlatform>(clonedBenchmarksByPlatform);

  useEffect(() => {
    setAlteredBenchmarksByPlatform(clonedBenchmarksByPlatform);
  }, [clonedBenchmarksByPlatform]);

  const {
    handleSubmit,
    control,
    register,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: alteredBenchmarksByPlatform,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore - TODO: fix validaiton Schema typing
    resolver: zodResolver(validationSchema),
  });

  useEffect(() => {
    if (isDirty) setHasUnsavedChanges(isDirty);
  }, [isDirty, setHasUnsavedChanges]);

  useEffect(() => {
    Object.entries(alteredBenchmarksByPlatform).forEach(([platform, rows]) => {
      rows.forEach((_, i) => {
        // we register everything beforehand to ensure that they are always in even if the user never
        // navigates to the platform
        register(`${platform as BenchmarkPlatformEnum}.${i}.cac.blended`);
        register(`${platform as BenchmarkPlatformEnum}.${i}.cac.returning`);
        register(`${platform as BenchmarkPlatformEnum}.${i}.cac.firstTime`);
        register(`${platform as BenchmarkPlatformEnum}.${i}.roas.blended`);
        register(`${platform as BenchmarkPlatformEnum}.${i}.roas.returning`);
        register(`${platform as BenchmarkPlatformEnum}.${i}.roas.firstTime`);
      });
    });
  }, [register, alteredBenchmarksByPlatform]);

  const [, navigateTo] = useCurrentBenchmarkRouteAtom();

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit(data))}
      className={"relative grow pr-5"}
    >
      <div className="flex items-center">
        <div className="text-lg font-semibold">
          Set custom benchmarks per platform
        </div>
        <img
          src={bullseyeIcon}
          alt={"bullseye"}
          className="ml-3 mb-[0.3rem]"
          style={{
            height: "2rem",
            width: "auto",
          }}
        />
      </div>
      <div className="text-md font-light mt-2">
        Northbeam calculates benchmarks for you based on your previous
        performance, but you can also set your own 1-day blended benchmarks on a
        per-platform basis.
      </div>

      <div className="flex 2xlh:mt-8 mt-2">
        {platformsToUse.map((platform, i) => {
          const isActive = isEqual(activePlatform, platform);
          return (
            <div
              key={platform}
              onClick={() => {
                setActivePlatform(platform);
              }}
              className={classNames(
                `cursor-pointer mr-5 p-3 rounded-2xl elevation-1`,

                isActive ? "border border-primary" : "",
              )}
            >
              {BenchmarkPlatformFriendlyNames[platform]}
            </div>
          );
        })}
      </div>
      <div className="w-full border-b-[1px] mt-6" />
      <div className="2xlh:mt-6 mt-2 text-lg font-semibold mb-3">
        Enter in your own 1-day benchmarks:
      </div>
      <div className="relative">
        {loading && <LoadingSpinner />}
        <div
          className={classNames("flex flex-col h-[23rem] overflow-y-auto", {
            "opacity-25": loading,
          })}
        >
          <EditableBenchmarkPlatformMetricsGrid
            showLabels
            orderedData={alteredBenchmarksByPlatform[activePlatform]}
            control={control}
            register={register}
            notScrollable
            benchmarkType={benchmarkType}
            benchmarkTargetsByPlatform={benchmarkTargetsByPlatform}
          />
          <div className="text-lg font-semibold mt-1">
            Northbeam calculated 1 day benchmarks:
          </div>
          <BenchmarkPlatformMetricsGrid
            showLabels
            orderedData={nbBenchmarksByPlatform[activePlatform]}
            notScrollable
            benchmarkType={benchmarkType}
            benchmarkTargetsByPlatform={benchmarkTargetsByPlatform}
          />
        </div>
      </div>
      <BenchmarkError control={control} />
      <BenchmarkHelpFooter className="absolute bottom-[2.5rem]" />

      <div className="absolute bottom-5 right-5">
        <SecondaryButton
          className="btn-sm ml-2 py-2 px-3 my-2"
          onClick={() => {
            if (standalone) {
              standalone.startOver();
              return;
            }
            navigateTo("review-with-nb-benchmarks");

            setCustomBenchmarksByPlatform(getValues());
          }}
          isDisabled={loading}
        >
          {standalone ? "Start over" : "Back"}
        </SecondaryButton>
        <PrimaryButton
          className="btn-sm ml-2 py-2 px-3 my-2"
          type="submit"
          isDisabled={loading}
        >
          Apply
        </PrimaryButton>
      </div>
    </form>
  );
};
