import Metadata from "@assets/metadata.svg";
import React from "react";

interface RawJsonInterface {
    metaData: any;
}
export const RawJsonSection = ({metaData} : RawJsonInterface) => {
    if (!metaData || Object.keys(metaData).length === 0) {
        return null;
    }
    const code = typeof metaData === "string" ? metaData : JSON.stringify(metaData, null, 2);
    return (
        <div className="py-2 mt-2">
            <div className="flex items-center">
                <div className="mr-2">
                    <img className="w-4" alt="Raw Metadata" src={Metadata} />
                </div>
                <div className="text-lg text-gray-900">Raw Metadata</div>
            </div>
            <pre className="bg-dark text-white p-3 h-80 whitespace-pre-wrap">
                {code}
            </pre>
        </div>
    )
}