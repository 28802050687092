import { User, useUser } from "@components/user-context";
import { useState } from "react";
import { Annotation, NewAnnotation } from "@features/custom-annotations";

const buildNewAnnotation = (
  user: User,
  annotatedDate: string,
): NewAnnotation => ({
  clientId: user.displayUserId,
  userId: user.authedUserId,
  description: "",
  annotatedDate: annotatedDate,
});

export const useAnnotationForm = () => {
  const { user } = useUser();
  const [currentAnnotation, setCurrentAnnotation] = useState<
    Annotation | NewAnnotation | null
  >(null);

  const newAnnotation = (annotatedDate = "") =>
    setCurrentAnnotation(buildNewAnnotation(user, annotatedDate));
  const editAnnotation = (annotation: Annotation) =>
    setCurrentAnnotation(annotation);

  return {
    currentAnnotation,
    newAnnotation,
    editAnnotation,
    clearCurrentAnnotation: () => setCurrentAnnotation(null),
  };
};
