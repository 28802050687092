import {
  DateRangeDisplay,
  DateRangePicker,
} from "@components/date-range-picker";
import { NumberRangePicker } from "@components/number-range-picker";
import {
  ControlCenterWrapper,
  ControlWrapper,
} from "@components/reports/control-center-utilities";
import { useUser } from "@components/user-context";
import { NumberInput } from "@components/utilities";
import {
  CampaignSearch,
  CampaignSearchVariables,
} from "@nb-api-graphql-generated/CampaignSearch";
import {
  CustomerPathFilterStage,
  PathDateRangeFilterType,
} from "@nb-api-graphql-generated/global-types";
import { BreakdownConfig } from "@north-beam/nb-common";
import { FixedDateRange } from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import { DateRangeChoiceEnum, DateRangeChoiceV2 } from "@utils/constants";
import { useNorthbeamQuery } from "@utils/hooks";
import deepEqual from "deep-equal";
import React from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { useToasts } from "react-toast-notifications";
import { PathElementFilterComponent } from "./path-element-filter";
import { CAMPAIGN_SEARCH } from "./queries";
import { PathDateRangeFilterType_, ReportState } from "./report-state";

export interface ControlCenterProps {
  breakdownConfigs: BreakdownConfig[];
  onReportStateUpdated: (state: ReportState) => void;
  isLoading: boolean;
  initialState: ReportState;
}

export function ControlCenter(props: ControlCenterProps) {
  const { breakdownConfigs, initialState, isLoading, onReportStateUpdated } =
    props;

  const { user } = useUser();
  const { addToast } = useToasts();
  const { refetch } = useNorthbeamQuery<
    CampaignSearch,
    CampaignSearchVariables
  >(CAMPAIGN_SEARCH, {
    variables: { query: "" },
    skip: true,
    fetchPolicy: "no-cache",
  });

  const fetchCampaigns = React.useCallback(
    async (query: string) => {
      const { data } = await refetch({ query });
      const campaigns = data?.me?.campaigns;
      if (!campaigns) {
        addToast(
          "Whoops! an error occurred while searcing. Please try again!",
          { appearance: "error" },
        );
      }
      return campaigns ?? [];
    },
    [refetch, addToast],
  );

  const [proposedState, setProposedState] = React.useState(initialState);

  React.useEffect(() => {
    setProposedState(initialState);
  }, [initialState]);

  const {
    pathLengthMaximum,
    pathLengthMinimum,
    dateRange,
    pathDateRangeFilterType,
    pathFilterStages,
    showConvertingPathsOnly,
    limit,
  } = proposedState;

  const dispatch = React.useCallback(
    (action: ReportAction) => {
      const newState = ReportStateReducer(proposedState, action);
      setProposedState(newState);
    },
    [proposedState],
  );

  const convertDateRangeToFixed = React.useCallback(
    (dr: FixedDateRange) => {
      dispatch({
        type: "UpdateDateRange",
        value: dr,
      });
    },
    [dispatch],
  );

  // Path Length
  const pathLenthBounds = { minimum: 1, maximum: 100 };
  const pathLength = {
    minimum: pathLengthMinimum ?? pathLenthBounds.minimum,
    maximum: pathLengthMaximum ?? pathLenthBounds.maximum,
  };

  return (
    <ControlCenterWrapper>
      <ControlWrapper>
        <Select
          isDisabled={isLoading}
          isClearable={false}
          isSearchable={false}
          onChange={(object) => {
            const value = (object as any)?.value;
            logEvent("Customer paths: Show me paths dropdown", { value });
            dispatch({
              type: "UpdateShowConvertingPathsOnly",
              value: value === "true",
            });
          }}
          components={{ DropdownIndicator: FilterDropdown }}
          value={
            showConvertingPathsOnly
              ? {
                  value: "true",
                  label: "Show me converting paths only",
                }
              : {
                  value: "false",
                  label: "Show me all paths",
                }
          }
          options={[
            {
              value: "true",
              label: "Show me converting paths only",
            },
            {
              value: "false",
              label: "Show me all paths",
            },
          ]}
        />
      </ControlWrapper>
      <ControlWrapper>
        <Select
          isDisabled={isLoading}
          isClearable={false}
          isSearchable={false}
          onChange={(object) => {
            const value = (object as any)?.value;
            logEvent("Customer paths: time intersection dropdown", { value });
            dispatch({
              type: "UpdatePathDateRangeFilterType",
              value,
            });
          }}
          components={{ DropdownIndicator: FilterDropdown }}
          value={PathDateRangeFilterType_.toReactSelect(
            pathDateRangeFilterType,
          )}
          options={PathDateRangeFilterType_.reactSelectOptions}
        />
      </ControlWrapper>
      <ControlWrapper title="date range">
        <DateRangePicker<DateRangeChoiceV2>
          disabled={isLoading}
          nonCustomChoices={DateRangeChoiceEnum.reactSelectOptions}
          current={
            (typeof dateRange === "string"
              ? DateRangeChoiceEnum.toReactSelect(dateRange)
              : dateRange)!
          }
          onUpdate={(dateRange) => {
            const value =
              "value" in dateRange
                ? (dateRange.value as DateRangeChoiceV2)
                : dateRange;
            logEvent("Customer paths: date range selector", { value });
            dispatch({
              type: "UpdateDateRange",
              value,
            });
          }}
          timezone={user.timezone}
        />
        <small className="text-muted mt-1 text-small">
          <DateRangeDisplay
            dateRange={dateRange}
            onConversionToFixed={convertDateRangeToFixed}
          />
        </small>
      </ControlWrapper>
      <ControlWrapper>
        <hr />
      </ControlWrapper>
      <ControlWrapper title="Path length">
        <NumberRangePicker
          disabled={isLoading}
          bounds={{ minimum: 1, maximum: 100 }}
          value={pathLength}
          noun="element"
          onUpdate={(range) =>
            dispatch({
              type: "UpdatePathLength",
              value: range,
            })
          }
        />
      </ControlWrapper>
      <div className="my-2">
        <ControlWrapper title="Path filter">
          <PathElementFilterComponent
            disabled={isLoading}
            maxStages={4}
            fetchCampaigns={fetchCampaigns}
            current={pathFilterStages}
            breakdownConfigs={breakdownConfigs}
            onUpdate={(value) =>
              dispatch({
                type: "UpdatePathFilterStages",
                value,
              })
            }
          />
        </ControlWrapper>
      </div>
      <ControlWrapper title="Number of paths to show">
        <NumberInput
          value={limit}
          format="integer"
          onValueChange={(value) => {
            if (typeof value === "number") {
              dispatch({
                type: "UpdateLimit",
                value: value,
              });
            }
          }}
        />
      </ControlWrapper>
      <ControlWrapper>
        <button
          className="btn btn-block btn-primary"
          disabled={isLoading || deepEqual(initialState, proposedState)}
          onClick={() => onReportStateUpdated(proposedState)}
        >
          Update report
        </button>
      </ControlWrapper>
    </ControlCenterWrapper>
  );
}

function FilterDropdown(props: DropdownIndicatorProps<any>) {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-filter"></i>
    </components.DropdownIndicator>
  );
}

interface UpdateDateRange {
  type: "UpdateDateRange";
  value: DateRangeChoiceV2 | FixedDateRange;
}

interface UpdateShowConvertingPathsOnly {
  type: "UpdateShowConvertingPathsOnly";
  value: boolean;
}

interface UpdatePathDateRangeFilterType {
  type: "UpdatePathDateRangeFilterType";
  value: PathDateRangeFilterType;
}

interface UpdatePathLength {
  type: "UpdatePathLength";
  value: { minimum: number; maximum: number };
}

interface UpdatePathFilterStages {
  type: "UpdatePathFilterStages";
  value: CustomerPathFilterStage[];
}

interface UpdateLimit {
  type: "UpdateLimit";
  value: number;
}

interface SetReport {
  type: "SetReport";
  value: ReportState | ((s: ReportState) => ReportState);
}

export type ReportAction =
  | UpdateDateRange
  | UpdateShowConvertingPathsOnly
  | UpdatePathDateRangeFilterType
  | UpdatePathLength
  | UpdatePathFilterStages
  | UpdateLimit
  | SetReport;

export function ReportStateReducer(
  state: ReportState,
  action: ReportAction,
): ReportState {
  switch (action.type) {
    case "UpdateDateRange":
      return { ...state, dateRange: action.value };
    case "UpdateShowConvertingPathsOnly":
      return { ...state, showConvertingPathsOnly: action.value };
    case "UpdatePathDateRangeFilterType":
      return { ...state, pathDateRangeFilterType: action.value };
    case "UpdatePathLength":
      return {
        ...state,
        pathLengthMinimum: action.value.minimum,
        pathLengthMaximum: action.value.maximum,
      };
    case "UpdatePathFilterStages":
      return { ...state, pathFilterStages: action.value };
    case "UpdateLimit":
      return { ...state, limit: action.value };
    case "SetReport":
      if (typeof action.value === "function") {
        return action.value(state);
      }
      return action.value;
  }
}
