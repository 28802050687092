import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "@components/user-context";

interface BidSettingsTree {
  data: {
    name: string;
    type: string;
    status: string;
    slug: string;
    properties: { key: string; value: string }[];
  };
  children: BidSettingsTree[];
}

export function BidSettingsBlock(props: { result: any }) {
  const root = props.result;

  return <BidSettingsItem root={root} />;
}

function BidSettingsItem(props: { root: BidSettingsTree }) {
  const { search } = useLocation();
  const { user } = useUser();
  const { root } = props;
  const { data, children } = root;
  const { name, type, status, slug, properties } = data;

  return (
    <>
      <div>
        <strong>{type}</strong>:{" "}
        <Link to={user.pathFromRoot(`/objects/${slug}${search}`)}>{name}</Link>{" "}
        {status && `(${status})`}
        {properties.map(({ key, value }) => (
          <div key={key}>
            <strong>{key}</strong>: {value}
          </div>
        ))}
      </div>
      {children.length > 0 && (
        <ul>
          {children.map((child) => (
            <li key={child.data.slug}>
              <BidSettingsItem root={child} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
