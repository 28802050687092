import React from "react";
import {
  ConfirmActionButton,
  PrimaryButton,
  TertiaryButton,
} from "@shared/buttons";
import { useAnnotations } from "../hooks/use-annotations";
import classNames from "classnames";
import { Annotation, NewAnnotation } from "@features/custom-annotations";

interface AnnotationFormControlProps {
  className?: string;
  currentAnnotation: Annotation | NewAnnotation;
  clearCurrentAnnotation: () => void;
  isSubmitting: boolean;
}

export const AnnotationFormControl = ({
  className,
  currentAnnotation,
  clearCurrentAnnotation,
  isSubmitting,
}: AnnotationFormControlProps) => {
  const { deleteAnnotation, isAnnotationsLoading } = useAnnotations();
  const isEdit = "id" in currentAnnotation;

  return (
    <div className={classNames(className, "flex space-x-2")}>
      <PrimaryButton
        isDisabled={isAnnotationsLoading || isSubmitting}
        type="submit"
      >
        Save
      </PrimaryButton>
      {isEdit && (
        <ConfirmActionButton
          title="Are you sure you want to delete this note?"
          onConfirm={async () => {
            if (!("id" in currentAnnotation)) return;

            await deleteAnnotation(currentAnnotation.id);
            clearCurrentAnnotation();
          }}
          actionButton={
            <TertiaryButton
              className="danger-button"
              isDisabled={isAnnotationsLoading}
            >
              Delete
            </TertiaryButton>
          }
        />
      )}
    </div>
  );
};
