import _ from "lodash";
import React from "react";
import Autosuggest, {
  OnSuggestionSelected,
  SuggestionSelectedEventData,
} from "react-autosuggest";
import { CampaignSearch_me_campaigns as CampaignSearchResult } from "@nb-api-graphql-generated/CampaignSearch";

export interface CampaignSearchBoxProps {
  disabled: boolean;
  debounceMillis: number;
  onResultSelected: (result: CampaignSearchResult) => void;
  searchForCampaigns: (query: string) => Promise<CampaignSearchResult[]>;
}

export function CampaignSearchBox({
  disabled,
  debounceMillis,
  onResultSelected,
  searchForCampaigns,
}: CampaignSearchBoxProps) {
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [suggestions, setSuggestions] = React.useState<CampaignSearchResult[]>(
    [],
  );

  const onChange = React.useCallback(
    (_e: React.ChangeEvent, { newValue }) => setValue(newValue),
    [setValue],
  );

  const query = value.trim().toLowerCase();

  const onSuggestionSelected = React.useCallback<
    OnSuggestionSelected<CampaignSearchResult>
  >(
    (
      _event: React.FormEvent<any>,
      data: SuggestionSelectedEventData<CampaignSearchResult>,
    ) => {
      setValue("");
      onResultSelected(data.suggestion);
    },
    [setValue, onResultSelected],
  );

  const fetchAndSetQuery = React.useCallback(
    async (query: string) => {
      setLoading(true);
      const results = await searchForCampaigns(query);
      setLoading(false);
      setSuggestions(results);
    },
    [searchForCampaigns, setSuggestions, setLoading],
  );

  const debouncedFetchAndSet = React.useMemo(
    () => _.debounce(fetchAndSetQuery, debounceMillis),
    [fetchAndSetQuery, debounceMillis],
  );

  const inputProps = {
    placeholder: "Campaign search...",
    value,
    onChange,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={() => debouncedFetchAndSet(query)}
      onSuggestionsClearRequested={() => setSuggestions([])}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={(s) => s.name}
      renderSuggestion={(s) => <span>{`${s.name}`}</span>}
      renderInputComponent={(inputProps: any) => (
        <div className="form-inline">
          <input {...inputProps} className="form-control" disabled={disabled} />
          <div className="d-inline-block mx-1">
            {loading ? (
              <div
                className="spinner-border text-info"
                role="status"
                style={{ height: "2rem", width: "2rem" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div style={{ height: "2rem", width: "2rem" }} />
            )}
          </div>
        </div>
      )}
      inputProps={inputProps}
    />
  );
}
