import React from "react";
import ShopifyPixel from "./connections/shopify/pixel";

type ConnectionIssue = {
  message: string;
  reconnectRecommended: boolean;
};

type ShopifyStatusLineProps = {
  connectionIssue?: ConnectionIssue | null;
  shopUrl: string;
};

export default function ShopifyStatusLine({
  connectionIssue,
  shopUrl,
}: ShopifyStatusLineProps) {
  const permissionsHaveUpdated =
    connectionIssue && !connectionIssue.reconnectRecommended;
  let emoji = connectionIssue ? "🚨" : "✔️";
  if (permissionsHaveUpdated) {
    emoji = "ⓘ";
  }

  const renderShopifyErrorNotificationMessage = () => {
    return (
      <div>
        <b>Note:</b>{" "}
        <i>
          {`If you are seeing this connection error and you have the northbeam app installed, please go to `}
          <a
            href={`https://${shopUrl}/admin`}
            target="_blank"
            rel="noreferrer"
          >{`${shopUrl}/admin`}</a>
          {`, 
                select "Apps" in the side navbar, and click on the "Northbeam" app from the dropdown. 
                This should put you back through the authorization flow and connect this app permanently.
                `}
        </i>
      </div>
    );
  };

  const renderConnectionIssue = () => {
    return (
      <tr>
        <td colSpan={4}>
          <div
            className="alert alert-warning mt-1"
            style={{ whiteSpace: "pre-line" }}
          >
            <b>Error</b>: <i>{connectionIssue?.message}</i>
            {renderShopifyErrorNotificationMessage()}
          </div>
        </td>
      </tr>
    );
  };

  const renderWebPixelPermissionsMessage = () => (
    <tr>
      <td colSpan={4}>
        <div
          className="bg-blue-50 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md"
          role="alert"
          style={{ whiteSpace: "pre-line" }}
        >
          <p className="font-bold">
            <span className="text-xl text-center mr-2">{emoji}</span>
            Shopify permissions need to be updated
          </p>
          <p className="text-sm">
            {`We've made improvements to Northbeam's Shopify app.`}
            {` To take advantage of the latest update, you'll need to grant Northbeam permission in Shopify.`}
            {` In the interim, your store will remain synced to Northbeam.`}
          </p>
          <p className="font-bold">To update Northbeam permissions:</p>
          <ol>
            <li>
              1. Go to{` `}
              <a
                href={`https://${shopUrl}/admin`}
                target="_blank"
                rel="noreferrer"
              >{`${shopUrl}/admin`}</a>
            </li>
            <li>2. Select &quot;Apps&quot; in the side navigation</li>
            <li>3. Click the &quot;Northbeam&quot; app in the dropdown</li>
          </ol>
        </div>
      </td>
    </tr>
  );

  const renderSuffix = () => {
    if (permissionsHaveUpdated) {
      return renderWebPixelPermissionsMessage();
    } else if (connectionIssue) {
      return renderConnectionIssue();
    }
    return (
      <tr>
        <td colSpan={4}>
          <ShopifyPixel shopUrl={shopUrl} />
        </td>
      </tr>
    );
  };

  return (
    <React.Fragment key={shopUrl}>
      <tr className="tr text-small">
        <td className="td">{emoji}</td>
        <td className="td">{shopUrl}</td>
        <td className="td text-xs break-words">{shopUrl}</td>
      </tr>
      {renderSuffix()}
    </React.Fragment>
  );
}
