import BidBudget from "@assets/bid-budget.svg";
import { capitalize } from "@utils/strings";
import React from "react";

interface BidBudgetSectionInterface {
    statusLine: any[];
}
export const BidBudgetSection = ({statusLine} : BidBudgetSectionInterface) => {
    return (
        <div className="py-2 mt-2">
            <div className="flex items-center">
                <div className="mr-2">
                    <img className="w-4" alt="Bid Budget" src={BidBudget} />
                </div>
                <div className="text-lg text-gray-900">Bid & Budget</div>
            </div>
            {statusLine.map((prop) => {
                return (
                    <div className="py-2" key={prop.key}>
                        {prop.key}: {capitalize(prop.value)}
                    </div>
                )
            })}
        </div>
    )
}