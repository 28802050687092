import { gql } from "@apollo/client";
import { GenericExternalLink } from "@components/generic-external-link";
import { NAVBAR_QUERY } from "@components/Navbar";
import { H1 } from "@components/utilities";
import { EducationalVideosHaveBeenViewed } from "@nb-api-graphql-generated/EducationalVideosHaveBeenViewed";
import { FetchEducationalVideos } from "@nb-api-graphql-generated/FetchEducationalVideos";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamMutation, useNorthbeamQuery } from "@utils/hooks";
import React from "react";
import { HELP_QUERY } from ".";

export interface EducationalVideo {
  title: string;
  description: string;
  link: string;
  last_viewed_at: string;
}

export function EducationalVideos() {
  const { data, loading, error } = useNorthbeamQuery<FetchEducationalVideos>(
    FETCH_EDUCATIONAL_VIDEOS,
    {},
  );

  const [educationalVideosHaveBeenViewed] =
    useNorthbeamMutation<EducationalVideosHaveBeenViewed>(
      EDUCATIONAL_VIDEOS_HAVE_BEEN_VIEWED,
      {
        refetchQueries: [{ query: NAVBAR_QUERY }, { query: HELP_QUERY }],
      },
    );

  educationalVideosHaveBeenViewed();

  return (
    <div className="container pt-4">
      <H1>Educational videos</H1>
      <LogOnMount name="View educational videos" />

      {!loading && !error && (
        <>
          {data &&
          data?.me?.educationalVideos &&
          data.me.educationalVideos.length > 0 ? (
            <>
              {data.me.educationalVideos.map((video: EducationalVideo) => (
                <>
                  <hr />
                  <h3>
                    <b>{video.title}</b>
                    <br />
                  </h3>
                  <p>{video.description}</p>
                  <p>
                    View this video{" "}
                    <GenericExternalLink href={video.link}>
                      here.
                    </GenericExternalLink>
                  </p>
                  <div className="py-1 mb-5 col-10">
                    <iframe
                      src={video.link}
                      title="Educational video"
                      width="100%"
                      height="500"
                      frameBorder="0"
                    />
                  </div>
                </>
              ))}
            </>
          ) : (
            <>
              <hr />
              <div className="col">
                It looks like you don&apos;t have any educational videos. Reach
                out to{" "}
                <a href="mailto:success@northbeam.io">
                  our customer support team
                </a>{" "}
                if you have questions you want answered here.
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

const FETCH_EDUCATIONAL_VIDEOS = gql`
  query FetchEducationalVideos {
    me {
      educationalVideos
    }
  }
`;

const EDUCATIONAL_VIDEOS_HAVE_BEEN_VIEWED = gql`
  mutation EducationalVideosHaveBeenViewed {
    educationalVideosHaveBeenViewed
  }
`;
