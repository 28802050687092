import React from "react";
import { UtmGuidePlatformProps } from "..";
import { CopyButton } from "./shared/copy-button";

export function Facebook({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to all <b>destination URLs</b> for
        new and existing ads.
      </p>
      <p>
        <b>Note:</b> Changing the tracking parameters for an existing ad will
        reset it to the learning phase. We recommend waiting for a low-traffic
        period to do so. Some of our clients will roll out UTM updated
        progressively, while others will &ldquo;rip off the bandaid&rdquo; and
        update all Facebook ads at once. If you decide to roll out updates
        progressively, please be aware that our reporting will be somewhat
        inaccurate until the process is complete.
      </p>
      <p>
        Note that Northbeam will attempt to place UTM parameters on all newly
        created Facebook ads, but you will still need to update your historic
        ads.
      </p>
      <ol>
        <li>Copy the Northbeam UTM parameters text.</li>
        <CopyButton urlParamPairs={urlParamPairs} hideQuestionMark />
        <li>
          <p>
            Paste the text you copied above into the location provided in these
            instructions:{" "}
          </p>
          <div>
            <iframe
              src="https://www.loom.com/embed/e45229d2783944e49507d2d0a5cabfca"
              title="Facebook UTM tracking description"
              width="100%"
              height="500"
              frameBorder="0"
            />
          </div>
        </li>
      </ol>
    </>
  );
}
