import { Option } from "@/types/react-select";
import {
  BenchmarkAttributionModelEnum,
  BenchmarkAttributionWindowEnum,
  BenchmarkPlatformEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";

export const PrettyBenchmarkAttributionModelNames = {
  [BenchmarkAttributionModelEnum.CLICKS_AND_VIEWS]: "Clicks and views",
  [BenchmarkAttributionModelEnum.CLICKS_ONLY]: "Clicks only",
} as const;

export const PLATFORMS: BenchmarkPlatformEnum[] = [
  BenchmarkPlatformEnum.adwords,
  BenchmarkPlatformEnum.facebook,
  BenchmarkPlatformEnum.youtube,
];

export const BenchmarkPlatformFriendlyNames = {
  [BenchmarkPlatformEnum.adwords]: "Google Ads",
  [BenchmarkPlatformEnum.facebook]: "Facebook Ads",
  [BenchmarkPlatformEnum.youtube]: "Youtube Ads",
} as const;

export const BenchmarkTypeOptions: Option<BenchmarkTypeEnum>[] = [
  { value: BenchmarkTypeEnum.CAC, label: "CAC (Customer acquisition cost)" },
  { value: BenchmarkTypeEnum.ROAS, label: "ROAS (Return on Ad Spend)" },
];

export const PrettyBenchmarkAttributionWindowNames = {
  [BenchmarkAttributionWindowEnum.THIRTY_DAYS]: "30-day",
  [BenchmarkAttributionWindowEnum.SIXTY_DAYS]: "60-day",
  [BenchmarkAttributionWindowEnum.NINETY_DAYS]: "90-day",
} as const;

export const BenchmarkAttributionWindowOptions: Option<BenchmarkAttributionWindowEnum>[] =
  [
    { value: BenchmarkAttributionWindowEnum.THIRTY_DAYS, label: "30 days" },
    { value: BenchmarkAttributionWindowEnum.SIXTY_DAYS, label: "60 days" },
    { value: BenchmarkAttributionWindowEnum.NINETY_DAYS, label: "90 days" },
  ];

export const BenchmarkAttributionWindowValues = {
  [BenchmarkAttributionWindowEnum.THIRTY_DAYS]: 30,
  [BenchmarkAttributionWindowEnum.SIXTY_DAYS]: 60,
  [BenchmarkAttributionWindowEnum.NINETY_DAYS]: 90,
} as const;

export const PrettyBenchmarkAttributionModelOptionNames = {
  [BenchmarkAttributionModelEnum.CLICKS_AND_VIEWS]: "Clicks + Views",
  [BenchmarkAttributionModelEnum.CLICKS_ONLY]: "Clicks only",
} as const;
