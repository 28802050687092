import { gql } from "@apollo/client";
import { LoadingSlide } from "@components/title-slide-view";

import { BillingDetailsQuery } from "@nb-api-graphql-generated/BillingDetailsQuery";
import { BillingDetails } from "@north-beam/nb-common";
import { BillingCard } from "@pages/settings/billing";
import { useNorthbeamQuery } from "@utils/hooks";
import React, { FC, useEffect, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { StepContainer } from "./step-container";

export const BillingStep: FC<Partial<StepWizardChildProps>> = ({
  previousStep,
  nextStep,
}) => {
  const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(
    null,
  );

  const { loading, data } = useNorthbeamQuery<BillingDetailsQuery>(
    BILLING_DETAILS_QUERY,
  );

  useEffect(() => {
    setBillingDetails(data?.me?.billingDetails || null);
  }, [data?.me?.billingDetails]);

  return (
    <>
      {loading && <LoadingSlide />}
      {!loading && (
        <StepContainer
          onMoveBackwardButtonClicked={() => {
            previousStep && previousStep();
          }}
          onMoveForwardButtonClicked={() => {
            nextStep && nextStep();
          }}
          moveForwardButtonDisabled={
            !billingDetails?.billingUserId &&
            !billingDetails?.stripePaymentMethod
          }
        >
          <h3 className="mb-3">Billing details</h3>
          <p>
            We encourage ACH payments - please skip this page if you would like
            to enroll in ACH and an ACH enabled invoice will be sent to you via
            Stripe.
          </p>
          <BillingCard
            onSuccess={() => {
              nextStep && nextStep();
            }}
          />
        </StepContainer>
      )}
    </>
  );
};

export const BILLING_DETAILS_QUERY = gql`
  query BillingDetailsQuery {
    me {
      billingDetails
    }
  }
`;
