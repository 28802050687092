import ChangeFractionDisplay from "@components/change-fraction";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import {
  AugmentedFactColumn,
  RenderCellProps,
  VirtualizedTable,
} from "@components/reports/virtualized-table";
import { useUser } from "@components/user-context";
import {
  GetModelComparisonReport_me_salesReport_rows as ReportResponseRow,
  GetModelComparisonReport_me_salesReport as ReportResponse,
} from "@nb-api-graphql-generated/GetModelComparisonReport";
import { Breakdown, CategoricalBreakdownConfig } from "@north-beam/nb-common";
import { Table } from "@north-beam/nb-common";
import { formatNumberExact } from "@north-beam/nb-common";
import { AdIcon } from "@pages/objects/utils";
import { downloadCsv } from "@utils/csv";
import { descriptorToPath, pathToDescriptor } from "@utils/metrics";
import { flatten, map, RowWithChildren } from "@utils/row-with-children";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useReportBodyState, ReportBodyState } from "./use-report-body-control";

interface ModelComparisonReportContentProps {
  isLoading: boolean;
  response: ReportResponse;
}

interface ModelComparisonPoint {
  txns: number;
  rev: number;

  ftCustTxns: number;
  ftCustRev: number;
  rtnCustTxns: number;
  rtnCustRev: number;
}

type ModelComparisonTableRowWithChildren = RowWithChildren<{
  id: string;
  platformId: string | null;
  parentId: string | null;
  description: { key: string; value: string }[];
  link?: string;
  name: string;
  modelPoint1: ModelComparisonPoint;
  modelPoint2: ModelComparisonPoint;
}>;

export function ModelComparisonTableSection({
  response,
  isLoading,
}: ModelComparisonReportContentProps) {
  const { search } = useLocation();
  const { user } = useUser();
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const { state, attributionModel1, attributionModel2, breakdowns } =
    useReportBodyState();

  const amEnum = React.useMemo(() => user.attributionMethodEnum, [user]);

  const rootRow = React.useMemo(() => {
    return preprocessForTable(
      response.rows,
      breakdownConfigs,
      breakdowns,
      state,
    );
  }, [response, breakdownConfigs, breakdowns, state]);

  const nativeTableRoot = React.useMemo(
    () =>
      map(rootRow, (node, level) => {
        const link: Record<string, any> = {};
        const export_: Record<string, any> = {};
        const display: Record<string, any> = {};
        const changeFractions: Record<string, number> = {};
        const rowMetadata: Record<string, any> = {};
        const cellHoverHTML: Record<string, string> = {};

        rowMetadata.level = level;
        rowMetadata.platformId = node.platformId;

        display.Campaign = node.name;
        export_.Campaign = node.name;
        if (node.link) {
          link.Campaign = node.link + search;
        }

        for (const { key, value } of node.description) {
          display[key] = value;
          export_[key] = value;
        }

        export_.model1Rev = node.modelPoint1.rev;
        export_.model2Rev = node.modelPoint2.rev;
        export_.diffRev = node.modelPoint1.rev - node.modelPoint2.rev;

        display.model1Rev = formatNumberExact(export_.model1Rev, "dollars");
        display.model2Rev = formatNumberExact(export_.model2Rev, "dollars");
        display.diffRev = formatNumberExact(export_.diffRev, "dollars");

        export_.model1Txns = node.modelPoint1.txns;
        export_.model2Txns = node.modelPoint2.txns;
        export_.diffTxns = node.modelPoint1.txns - node.modelPoint2.txns;

        display.model1Txns = formatNumberExact(export_.model1Txns, "decimal");
        display.model2Txns = formatNumberExact(export_.model2Txns, "decimal");
        display.pctDiffTxns = formatNumberExact(export_.diffTxns, "decimal");

        return {
          id: node.id,
          parentId: node.parentId,
          link,
          export: export_,
          display,
          changeFractions,
          rowMetadata,
          cellHoverHTML,
        };
      }),
    [rootRow, search],
  );

  const dimensionColumn: AugmentedFactColumn = React.useMemo(() => {
    return {
      name: "Campaign",
      key: "Campaign",
      isExpandable: breakdowns.length > 0,

      renderCell: ({ row, column }: RenderCellProps) => {
        let inner = (
          <div
            className="text-truncate"
            style={{
              maxWidth: `calc(${column.width}px - ${
                (row.rowMetadata?.level ?? 0) * 1 + 3
              }em)`,
            }}
          >
            {row.display.Campaign}
          </div>
        );
        if (row.rowMetadata.platformId) {
          inner = (
            <>
              <AdIcon sizeInEM={1} platform={row.rowMetadata.platformId} />
              {inner}
            </>
          );
        }

        inner = <div className="flex items-center">{inner}</div>;

        if (row.link.Campaign) {
          inner = <Link to={row.link.Campaign}>{inner}</Link>;
        }
        return <div title={row.display.Campaign}>{inner}</div>;
      },
    };
  }, [breakdowns]);

  const model1Name = amEnum.getLabelUnsafe(attributionModel1);
  const model2Name = amEnum.getLabelUnsafe(attributionModel2);

  const factColumns = React.useMemo(
    () =>
      [
        {
          name: `Rev (∞d, ${model1Name})`,
          key: "model1Rev",
          headerTooltip: `Revenue attributed under the ${model1Name} attribution model.`,
        },
        {
          name: `Rev (∞d, ${model2Name})`,
          key: "model2Rev",
          headerTooltip: `Revenue attributed under the ${model2Name} attribution model.`,
        },
        {
          name: `Δ Rev`,
          key: "diffRev",
          headerTooltip: `Revenue change between the two attribution models.`,
          renderCell: ({ row }) => {
            const diff = row.export.model1Rev - row.export.model2Rev;
            const changeFraction = diff / row.export.model2Rev;
            return (
              <div className="flex w-100 px-2">
                <div className="flex-grow-1 text-left">
                  <ChangeFractionDisplay
                    changeFraction={changeFraction}
                    isPositiveChangeGood={true}
                  />
                </div>
                <div className="flex-grow-1 text-right">
                  {formatNumberExact(diff, "dollars")}
                </div>
              </div>
            );
          },
        },
        {
          name: `Txns (∞d, ${model1Name})`,
          key: "model1Txns",
          headerTooltip: `Transactions attributed under the ${model1Name} attribution model.`,
        },
        {
          name: `Txns (∞d, ${model2Name})`,
          key: "model2Txns",
          headerTooltip: `Transactions attributed under the ${model2Name} attribution model.`,
        },
        {
          name: `Δ Txns`,
          key: "diffTxns",
          headerTooltip: `Transactions change between the two attribution models.`,
          renderCell: ({ row }) => {
            const diff = row.export.model1Txns - row.export.model2Txns;
            const changeFraction = diff / row.export.model2Txns;
            return (
              <div className="flex w-100 px-2">
                <div className="flex-grow-1 text-left">
                  <ChangeFractionDisplay
                    changeFraction={changeFraction}
                    isPositiveChangeGood={true}
                  />
                </div>
                <div className="flex-grow-1 text-right">
                  {formatNumberExact(diff, "decimal")}
                </div>
              </div>
            );
          },
        },
      ] as AugmentedFactColumn[],
    [model1Name, model2Name],
  );

  const root = React.useMemo(() => {
    const rv = { ...nativeTableRoot };
    rv.children = [];
    return rv;
  }, [nativeTableRoot]);

  const preppedRows = React.useMemo(
    () => [...nativeTableRoot.children],
    [nativeTableRoot],
  );

  const table: Table = React.useMemo(() => {
    return {
      title: "",
      dimensionColumns: [
        ...breakdowns.map((b) => ({
          name: b.key,
          key: b.key,
        })),
        dimensionColumn,
      ],
      factColumns,
      rows: flatten(nativeTableRoot),
    };
  }, [breakdowns, dimensionColumn, factColumns, nativeTableRoot]);

  const cardClasses = classNames({
    "waiting-interstitial": isLoading,
  });

  return (
    <div className="row my-3">
      <div className="col">
        <div className={cardClasses}>
          <div className="row mt-2">
            <div className="col">
              <VirtualizedTable
                columnWidth={200}
                frozenRows={[root]}
                rows={preppedRows}
                dimensionColumn={dimensionColumn}
                factColumns={factColumns}
              />
              <div style={{ position: "absolute", top: -100, right: 10 }}>
                <button
                  className="btn btn-outline-primary"
                  onClick={() =>
                    downloadCsv(
                      "Sales Model Comparison",
                      "north-beam-sales-model-comparison-report.csv",
                      table,
                    )
                  }
                >
                  Export to CSV
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function preprocessForTable(
  rows: ReportResponseRow[],
  breakdownConfigs: CategoricalBreakdownConfig[],
  breakdowns: Breakdown[],
  reportBodyState: ReportBodyState,
): ModelComparisonTableRowWithChildren {
  const { attributionModel1, attributionModel2 } = reportBodyState;

  function generateDescriptors(row: ReportResponseRow): string[] {
    const { dimensions, objectId } = row;
    const paths: string[][] = [[]];
    for (const breakdown of breakdowns) {
      const dimension = dimensions[breakdown.key] || "";
      paths.push([...paths[paths.length - 1], dimension]);
    }

    paths.push([...paths[paths.length - 1], objectId]);

    return paths.map(pathToDescriptor);
  }

  const keyToConfig = _.chain(breakdownConfigs)
    .groupBy("key")
    .mapValues((v) => v[0])
    .value();

  const descriptorToModelPoint1: Record<string, ModelComparisonPoint> = {};
  const descriptorToModelPoint2: Record<string, ModelComparisonPoint> = {};
  const descriptorToName: Record<string, string> = {};
  const descriptorToLink: Record<string, string> = {};
  const descriptorToPlatformId: Record<string, string | null> = {};

  for (const row of rows) {
    const { modelComparisonPoints, dimensions } = row;

    const rowPassesFilter = breakdowns.every((b) =>
      b.values.includes(dimensions[b.key] ?? ""),
    );
    if (!rowPassesFilter) {
      continue;
    }

    const descriptors = generateDescriptors(row);
    for (let i = 0; i < descriptors.length; ++i) {
      const descriptor = descriptors[i];
      if (i === 0) {
        descriptorToName[descriptor] = "Grand Total";
      } else if (i < descriptors.length - 1) {
        const path = descriptorToPath(descriptor);
        const value = path[path.length - 1];
        const choices = keyToConfig[breakdowns[path.length - 1].key].choices;
        const name = choices.find((v) => v.value === value)?.label ?? value;
        descriptorToName[descriptor] = name + " (total blended)";
      } else {
        descriptorToName[descriptor] = row.name;
        descriptorToLink[descriptor] = row.link;
        descriptorToPlatformId[descriptor] = row.nbtPlatformID;
      }
    }

    if (modelComparisonPoints) {
      for (const point of modelComparisonPoints) {
        if (point.attributionWindowDays !== "infinity") {
          continue;
        }

        if (attributionModel1 === point.attributionModel) {
          const target = descriptorToModelPoint1;
          for (const descriptor of descriptors) {
            const metricPoint = target[descriptor] ?? newMetricPoint();
            addToMetricPoint(point, metricPoint);
            target[descriptor] = metricPoint;
          }
        }

        if (attributionModel2 === point.attributionModel) {
          const target = descriptorToModelPoint2;
          for (const descriptor of descriptors) {
            const metricPoint = target[descriptor] ?? newMetricPoint();
            addToMetricPoint(point, metricPoint);
            target[descriptor] = metricPoint;
          }
        }
      }
    }
  }

  const currentDescriptors = Object.keys({
    ...descriptorToModelPoint1,
    ...descriptorToModelPoint2,
  });
  const descriptorToRow: Record<string, ModelComparisonTableRowWithChildren> =
    _.chain(currentDescriptors)
      .map((descriptor) => {
        const path = descriptorToPath(descriptor);
        const description: { key: string; value: string }[] = [];
        for (let i = 0; i < path.length && i < breakdowns.length; ++i) {
          const breakdownKey = breakdowns[i].key;
          const choices = keyToConfig[breakdownKey].choices;
          const name =
            choices.find((v) => v.value === path[i])?.label ?? path[i];
          description.push({ key: breakdownKey, value: name });
        }
        let parentId: string | null = null;
        if (path.length > 0) {
          path.pop();
          parentId = pathToDescriptor(path);
        }
        return [
          descriptor,
          {
            id: descriptor,
            platformId: descriptorToPlatformId[descriptor] ?? null,
            parentId,
            description,
            name: descriptorToName[descriptor],
            link: descriptorToLink[descriptor],
            modelPoint1:
              descriptorToModelPoint1[descriptor] ?? newMetricPoint(),
            modelPoint2:
              descriptorToModelPoint2[descriptor] ?? newMetricPoint(),
            children: [],
          } as ModelComparisonTableRowWithChildren,
        ];
      })
      .fromPairs()
      .value();

  for (const descriptor in descriptorToRow) {
    const path = descriptorToPath(descriptor);
    if (path.length === 0) {
      continue;
    }

    const parentPath = [...path];
    parentPath.pop();

    const parentDescriptor = pathToDescriptor(parentPath);
    descriptorToRow[parentDescriptor].children.push(
      descriptorToRow[descriptor],
    );
  }

  return descriptorToRow[pathToDescriptor([])];
}

function newMetricPoint(): ModelComparisonPoint {
  return {
    txns: 0,
    rev: 0,
    ftCustTxns: 0,
    ftCustRev: 0,
    rtnCustTxns: 0,
    rtnCustRev: 0,
  };
}

function addToMetricPoint(
  data: SalesReportModelComparisonRow,
  point: ModelComparisonPoint,
) {
  point.txns += data.txns;
  point.rev += data.rev;
  point.ftCustTxns += data.ftCustTxns;
  point.ftCustRev += data.ftCustRev;
  point.rtnCustTxns += data.rtnCustTxns;
  point.rtnCustRev += data.rtnCustRev;
}

interface SalesReportModelComparisonRow {
  adKey: Record<string, string>;
  attributionModel: string;

  txns: number;
  rev: number;

  ftCustTxns: number;
  ftCustRev: number;
  rtnCustTxns: number;
  rtnCustRev: number;
}
