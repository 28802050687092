import React from "react";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import { enumType } from "@utils/enum-type";

const SumModeType = enumType({
  enumObject: {
    AveragedPerCustomer: "Averaged per customer (Weighted)",
    TotalOverAllCustomers: "Total over all customers",
  },
  keyOrder: ["AveragedPerCustomer", "TotalOverAllCustomers"],
});
export const SumModeEnum = SumModeType.enum;
export type SumMode = keyof typeof SumModeEnum;
export const defaultSumMode = SumModeType.keyOrder[0];

const sumModeDescription = {
  AveragedPerCustomer:
    "Weighted by the number of customers and averaged over all customers",
  TotalOverAllCustomers: "The total sum over all customers",
};

interface SumModeSelectProps {
  sumMode: SumMode;
  isLoading: boolean;
  setSumMode: (sumMode: SumMode) => void;
}

export const SumModeSelect = ({
  isLoading,
  sumMode,
  setSumMode,
}: SumModeSelectProps) => {
  return (
    <Tooltip
      placement="top"
      overlay={sumModeDescription[sumMode]}
      destroyTooltipOnHide={true}
    >
      <div>
        <Select
          isDisabled={isLoading}
          isSearchable={false}
          onChange={(v: any) => setSumMode(v.value)}
          options={SumModeType.reactSelectOptions}
          value={SumModeType.toReactSelect(sumMode)}
        />
      </div>
    </Tooltip>
  );
};
