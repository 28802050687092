"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserialize = exports.serialize = void 0;
var core_1 = require("./core");
// export type JsonHashSerialized<T> = Brand<string, T, "__JsonHashSerialized__">;
function serialize(value) {
    return (0, core_1.jsonHash)(value);
}
exports.serialize = serialize;
function deserialize(value) {
    return JSON.parse(value);
}
exports.deserialize = deserialize;
