import React from "react";

import { emptyText } from "@/constants/empty-text";
import { CardDetails } from "@shared/card-details";
import { FetchOrder_me_order } from "@nb-api-graphql-generated/FetchOrder";
import { formatDateTime } from "@north-beam/nb-common";
import { MetricFormats } from "@utils/metrics";
import { prefs } from "@utils/client-side-preferences";
import { generateAnonBase64Hash } from "@utils/anonymous";
import { SubscriptionValueToLabelMapping } from "@pages/subscriptions-order-tags/subscription-order-tags";
import { useUser } from "@components/user-context";

export function OrderDetails({ order }: { order: FetchOrder_me_order }) {
  const { user } = useUser();
  const doAnon = prefs.isDemoMode();
  return (
    <CardDetails
      title="Order Details"
      className="mb-5"
      details={[
        {
          heading: "Date",
          items: [
            order.occurredAt?.value
              ? formatDateTime(order.occurredAt.value)
              : emptyText,
          ],
        },
        {
          heading: "Amount",
          items: [
            `${MetricFormats.dollars.exact(order.revenueInDollars)}`,
            `${MetricFormats.dollars.exact(
              order.products.reduce((acc, curr) => {
                const value =
                  Number(curr.priceInDollars) * Number(curr.quantity);
                return acc + value;
              }, 0),
            )} (gross merchandise value)`,
          ],
        },
        {
          heading: "Discount(s)",
          items: order.discountCodes.length
            ? order.discountCodes.map((item) =>
                generateAnonBase64Hash({
                  value: item,
                  prefix: "discount",
                  doAnon,
                }),
              )
            : ["--"],
        },
        ...(user.featureFlags.showSubscriptionOrderTags
          ? [
              {
                heading: "Subscription",
                items: order.subscriptionType
                  ? [SubscriptionValueToLabelMapping[order.subscriptionType]]
                  : ["--"],
              },
            ]
          : []),
        {
          heading: "Items",
          items: order.products.length
            ? order.products.map(
                (product) =>
                  `${generateAnonBase64Hash({
                    value: product.title,
                    prefix: "product",
                    doAnon,
                  })} (x${product.quantity})`,
              )
            : ["--"],
        },
      ]}
    />
  );
}
