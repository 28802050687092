type LabelsType<TEnumValue extends string> = {
  [key in TEnumValue]?: string;
};

export function enumType<
  TEnum extends string,
  TEnumValue extends string,
>(args: {
  enumObject: {
    [key in TEnum]: TEnumValue;
  };
  keyOrder: TEnum[];
  labels?: LabelsType<TEnumValue>;
}) {
  return new EnumType(args);
}

export class EnumType<TEnum extends string, TEnumValue extends string> {
  readonly enum: { [key in TEnum]: TEnumValue };
  readonly keyOrder: TEnum[];
  private readonly labels: LabelsType<TEnumValue>;

  constructor(args: {
    enumObject: {
      [key in TEnum]: TEnumValue;
    };
    keyOrder: TEnum[];
    labels?: LabelsType<TEnumValue>;
  }) {
    this.enum = args.enumObject;
    this.labels = args.labels ?? {};
    this.keyOrder = args.keyOrder;
  }

  get reactSelectOptions(): { value: TEnum; label: string }[] {
    return this.keyOrder.map(this.toReactSelect);
  }

  withLabels(labels: LabelsType<TEnumValue>) {
    return new EnumType<TEnum, TEnumValue>({
      enumObject: this.enum,
      keyOrder: this.keyOrder,
      labels: { ...this.labels, ...labels },
    });
  }

  getLabel = (value: TEnum) =>
    this.labels[this.enum[value]] ?? this.enum[value];

  getLabelUnsafe = (value: string) =>
    this.labels[this.enum[value as TEnum]] ??
    this.enum[value as TEnum] ??
    value;

  toReactSelect = (value: TEnum) => ({
    value,
    label: this.getLabel(value),
  });

  toReactSelectUnsafe = (value: string) => ({
    value,
    label: this.getLabel(value as TEnum),
  });

  fromString = (value: string | null | undefined): TEnum | null => {
    if (!value) {
      return null;
    }

    if (!(value in this.enum)) {
      return null;
    }
    return value as TEnum;
  };
}
