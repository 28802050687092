import React from "react";
import { UtmGuidePlatformProps } from "..";
import { GenericExternalLink } from "@components/generic-external-link";
import { CopyButton } from "./shared/copy-button";

export function Taboola({ urlParamPairs }: UtmGuidePlatformProps) {
  return (
    <>
      <p>
        You will need to add UTM parameters to all existing campaigns. To do so,
        navigate to the bottom of each <b>New Campaign/Edit Campaign</b> page,
        and paste the copied parameters into the <b>Tracking Code</b> section.
        For more information, you can review <b>Taboola</b>&apos;s{" "}
        <GenericExternalLink href="https://help.taboola.com/hc/en-us/articles/115006030688-Creating-and-Adding-URL-Parameters-for-Tracking">
          documentation
        </GenericExternalLink>
        .
      </p>
      <CopyButton urlParamPairs={urlParamPairs} />
      <p>
        Copy the Northbeam UTM parameters text and paste it into the{" "}
        <b>Tracking code</b> input field.
      </p>
    </>
  );
}
