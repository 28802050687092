import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { UtmGuide } from "@pages/settings/utm-guide";
import { StepContainer } from "./step-container";

export const LinkTrackingStep: FC<Partial<StepWizardChildProps>> = ({
  nextStep,
  previousStep,
}) => {
  return (
    <StepContainer
      onMoveBackwardButtonClicked={() => {
        previousStep && previousStep();
      }}
      onMoveForwardButtonClicked={() => {
        nextStep && nextStep();
      }}
      stepByStepLink="https://scribehow.com/shared/Appending_UTMs__hnrswZYgTsadxrEvaPO0CQ"
      videoDemoLink="https://drive.google.com/file/d/1oJaqOxwviLTpa2yAySIQxxbKoINT8bAc/view?usp=sharing"
    >
      <h3>Setup link tracking</h3>
      <UtmGuide />
    </StepContainer>
  );
};
