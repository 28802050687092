import { useUser } from "@components/user-context";
import { Blanket } from "@components/utilities";
import {
  Breakdown,
  BreakdownConfig,
  CategoricalBreakdownConfig,
} from "@north-beam/nb-common";
import { pick } from "@north-beam/nb-common";
import { PrimaryButton, TertiaryButton } from "@shared/buttons";
import { Modal } from "@shared/modals";
import { arrayMove } from "@utils/index";
import classNames from "classnames";
import _ from "lodash";
import React, { ComponentType, useEffect } from "react";
import { Link } from "react-router-dom";
import Select, {
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
} from "react-select";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

export interface BreakdownSelectorProps {
  className?: string;
  disabled: boolean;
  current: Breakdown[];
  available: BreakdownConfig[];
  allConfigs: BreakdownConfig[];
  onUpdate: (breakdowns: Breakdown[]) => void;
  includeOtherCheckbox?: boolean;
  maxBreakdowns?: number;
  placeholder?: string;
  DropdownIndicator?: ComponentType<DropdownIndicatorProps<any, false>>;
  tabIndex?: string;
  menuListStyles?: CSSObjectWithLabel;
}

export function BreakdownSelector(props: BreakdownSelectorProps) {
  const {
    current: unfilteredCurrent,
    available,
    onUpdate,
    disabled,
    includeOtherCheckbox,
    maxBreakdowns,
    allConfigs,
    placeholder,
    DropdownIndicator,
    tabIndex,
    menuListStyles,
  } = props;

  const current = React.useMemo(() => {
    const rv: Breakdown[] = [];
    for (const breakdown of unfilteredCurrent) {
      const config = allConfigs.find((c) => c.key === breakdown.key);
      if (!config) {
        continue;
      }
      let remainingValues: string[] = breakdown.values;

      if (config.type === "categorical") {
        remainingValues = [];
        for (const value of breakdown.values) {
          if (config.choices.some(({ value: v }) => value === v)) {
            remainingValues.push(value);
          }
        }
      }

      rv.push({ ...breakdown, values: remainingValues });
    }
    return rv;
  }, [allConfigs, unfilteredCurrent]);

  const stillAvailable = React.useMemo(() => {
    const keys = [...current.map((v) => v.key)];
    return available.filter((bdo) => !keys.includes(bdo.key));
  }, [current, available]);

  const onBreakdownUpdate = React.useCallback(
    (key: string, update: BreakdownUpdate | null) => {
      const rv = [...current];
      const bdIndex = current.findIndex((bd) => bd.key === key);
      if (bdIndex >= 0) {
        if (update === null) {
          rv.splice(bdIndex, 1);
        } else {
          rv[bdIndex] = { ...rv[bdIndex], ...update };
        }

        onUpdate(rv);
      }
    },
    [onUpdate, current],
  );

  const addBreakdown = React.useCallback(
    (config: any) => {
      onUpdate([...current, newBreakdownFromConfig(config)]);
    },
    [onUpdate, current],
  );

  const showAddBreakdownDropdown =
    stillAvailable.length > 0 &&
    (typeof maxBreakdowns === "undefined"
      ? true
      : current.length <= maxBreakdowns);

  return (
    <>
      <BreakdownList
        useDragHandle={true}
        distance={10}
        helperClass="nb-sortable-helper"
        breakdowns={current}
        breakdownDisabled={disabled}
        allConfigs={allConfigs}
        onBreakdownUpdate={onBreakdownUpdate}
        includeOtherCheckbox={includeOtherCheckbox || false}
        onSortEnd={({ oldIndex, newIndex }) =>
          onUpdate(arrayMove(current, oldIndex, newIndex))
        }
      />
      {showAddBreakdownDropdown && (
        <Select<any>
          className="cursor-pointer"
          styles={{
            control: (provided) => ({
              ...provided,
              cursor: "pointer",
            }),
            option: (provided) => ({
              ...provided,
              cursor: "pointer",
            }),
            menuList: (provided) => ({
              ...provided,
              ...menuListStyles,
            }),
          }}
          isDisabled={disabled}
          isClearable={false}
          isSearchable={false}
          onChange={addBreakdown}
          value={null}
          getOptionLabel={(v) => v.name}
          getOptionValue={(v) => v.key}
          options={stillAvailable}
          placeholder={placeholder || "Add breakdown..."}
          components={{
            DropdownIndicator: DropdownIndicator || DefaultDropdownIndicator,
          }}
          tabIndex={Number(tabIndex)}
        />
      )}
    </>
  );
}

type BreakdownUpdate = Omit<Breakdown, "key">;

interface BreakdownBoxProps {
  breakdownIndex: number;
  current: BreakdownUpdate;
  breakdownDisabled: boolean;
  config: BreakdownConfig;
  onUpdate: (key: string, changes: BreakdownUpdate | null) => void;
  includeOtherCheckbox: boolean;
}

const BreakdownBoxHandle = SortableHandle(() => (
  <span className="grippy"></span>
));

const BreakdownBox = SortableElement((props: BreakdownBoxProps) => {
  const {
    current,
    config,
    onUpdate,
    breakdownDisabled: disabled,
    breakdownIndex: index,
    includeOtherCheckbox,
  } = props;

  const containerClasses: any = {
    border: 1,
    "mb-3": 1,
    "pr-2": 1,
    "py-2": 1,
    "pl-0": 1,
    rounded: 1,
    "z-[2002]": 1,
  };

  if (!disabled) {
    containerClasses["bg-white"] = 1;
  } else {
    containerClasses["bg-light"] = 1;
  }

  return (
    <div className={classNames(containerClasses)}>
      <button
        type="button"
        className="close"
        aria-label="Close"
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            onUpdate(config.key, null);
          }
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <BreakdownBoxHandle />
      <div className="flex flex-column" style={{ minWidth: 0 }}>
        <p className="small font-weight-bold my-1">
          {index + 1}. {config.name}
        </p>
        {config.type === "categorical" ? (
          <CategoricalBreakdownSelector
            config={config}
            onUpdate={onUpdate}
            disabled={disabled}
            current={current}
            includeOtherCheckbox={includeOtherCheckbox}
          />
        ) : null}
      </div>
    </div>
  );
});

function CategoricalBreakdownSelector(props: {
  config: CategoricalBreakdownConfig;
  disabled: boolean;
  current: BreakdownUpdate;
  includeOtherCheckbox: boolean;
  onUpdate: (key: string, update: BreakdownUpdate | null) => void;
}) {
  const { config, disabled, current, onUpdate, includeOtherCheckbox } = props;
  const { values, groupUnselectedIntoOther } = current;
  const { key, choices: available } = config;
  return (
    <>
      <MultiSelectControl
        labelKey={key}
        disabled={disabled}
        availableChoices={available}
        values={values}
        onUpdate={(values) =>
          onUpdate(key, {
            ...current,
            values,
          })
        }
      />

      {includeOtherCheckbox && (
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id={`other_${key}`}
            checked={groupUnselectedIntoOther}
            onChange={() =>
              onUpdate(key, {
                ...current,
                groupUnselectedIntoOther: !groupUnselectedIntoOther,
              })
            }
          />
          <label htmlFor={`other_${key}`}>
            Group unselected values into &ldquo;Other&rdquo; category
          </label>
        </div>
      )}
    </>
  );
}

interface MultiSelectControlProps {
  labelKey: string;
  disabled: boolean;
  availableChoices: { label: string; value: string }[];
  values: string[];
  onUpdate: (newValue: string[]) => void;
}

function MultiSelectControl(props: MultiSelectControlProps) {
  const {
    labelKey,
    disabled,
    availableChoices,
    values,
    onUpdate: _onUpdate,
  } = props;
  const [isOpen, setOpen] = React.useState(false);
  const numValues = values.length;

  const onUpdate = React.useCallback(
    (values: string[]) => {
      _onUpdate(values);
      setOpen(false);
    },
    [_onUpdate],
  );

  return (
    <div>
      <Modal isOpen={isOpen} shouldCloseOnOverlayClick>
        <MultiSelectControlPopup
          labelKey={labelKey}
          disabled={disabled}
          availableChoices={availableChoices}
          values={values}
          onUpdate={onUpdate}
          closeDialog={() => setOpen(false)}
        />
      </Modal>
      <div
        className="alert alert-primary mb-2 flex"
        style={{
          padding: "0.25em 0.5em",
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        <div className="text-nowrap small">
          {numValues} values (click to edit)
        </div>
      </div>
    </div>
  );
}

function MultiSelectControlPopup({
  labelKey,
  disabled,
  availableChoices,
  values,
  onUpdate,
  closeDialog,
}: MultiSelectControlProps & { closeDialog: () => void }) {
  const initialState = React.useMemo(
    () =>
      _.chain(values)
        .map((v) => [v, true])
        .fromPairs()
        .value(),
    [values],
  );
  const [currentState, setCurrentState] = React.useState(initialState);
  const numSelected = React.useMemo(
    () => Object.keys(currentState).length,
    [currentState],
  );
  const canSave = numSelected > 0;
  const [filterText, setFilterText] = React.useState("");
  const ftLower = filterText.toLowerCase();

  const filteredChoices = React.useMemo(
    () =>
      availableChoices.filter(
        (v) =>
          v.label.toLowerCase().indexOf(ftLower) >= 0 ||
          v.value.toLowerCase().indexOf(ftLower) >= 0,
      ),
    [availableChoices, ftLower],
  );
  const allChoices = React.useMemo(
    () =>
      _.chain(filteredChoices)
        .map((v) => [v.value, true])
        .fromPairs()
        .value(),
    [filteredChoices],
  );

  const toggle = React.useCallback(
    (value: string) => {
      setCurrentState((state) => {
        const newState = { ...state };
        if (state[value]) {
          delete newState[value];
        } else {
          newState[value] = true;
        }
        return newState;
      });
    },
    [setCurrentState],
  );

  const selectAll = React.useCallback(
    () => setCurrentState(allChoices),
    [allChoices, setCurrentState],
  );

  const clear = React.useCallback(
    () =>
      setCurrentState((state) => {
        const newState = { ...state };
        for (const value in allChoices) {
          if (newState[value]) {
            delete newState[value];
          }
        }
        return newState;
      }),
    [allChoices, setCurrentState],
  );

  const save = React.useCallback(() => {
    onUpdate(_.sortBy(Object.keys(currentState)));
  }, [onUpdate, currentState]);

  const RowItem = React.useCallback(
    ({ index, style }: any) => {
      const { value } = filteredChoices[index];
      let { label } = filteredChoices[index];
      label = label.slice(0, 50);
      const id = `check-${btoa(encodeURIComponent(value))}`;
      return (
        <div style={style} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={currentState[value]}
            id={id}
            onChange={(e) => toggle(value)}
          />
          <label className="form-check-label" htmlFor={id}>
            {label}
          </label>
        </div>
      );
    },
    [filteredChoices, toggle, currentState],
  );

  return (
    <>
      <p>
        <strong>Select values for label key:</strong> {labelKey}
      </p>
      <hr />
      <input
        className="form-control form-control-sm mb-2"
        placeholder="Search..."
        value={filterText}
        onChange={(e) => setFilterText(e.target.value!)}
      />
      <div className="flex mb-1">
        <div className="flex-grow-1 text-muted">
          <small>{numSelected} selected</small>
        </div>
        <div>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={selectAll}
          >
            Select all
          </button>
          &nbsp;
          <button className="btn btn-sm btn-outline-primary" onClick={clear}>
            Clear
          </button>
        </div>
      </div>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            height={240}
            itemCount={filteredChoices.length}
            itemSize={30}
            width={width}
          >
            {RowItem}
          </List>
        )}
      </AutoSizer>
      <hr />
      <PrimaryButton onClick={save} disabled={!canSave || disabled}>
        Save
      </PrimaryButton>
      <TertiaryButton onClick={closeDialog}>Close</TertiaryButton>
    </>
  );
}

interface BreakdownListProps {
  breakdowns: Breakdown[];
  breakdownDisabled: boolean;
  allConfigs: BreakdownConfig[];
  onBreakdownUpdate: (key: string, update: BreakdownUpdate | null) => void;
  includeOtherCheckbox: boolean;
}

const BreakdownList = SortableContainer((props: BreakdownListProps) => {
  const {
    breakdowns,
    breakdownDisabled,
    allConfigs,
    onBreakdownUpdate,
    includeOtherCheckbox,
  } = props;
  return (
    <div>
      {breakdowns.map((breakdown: Breakdown, index: number) => (
        <BreakdownBox
          index={index}
          key={breakdown.key}
          breakdownIndex={index}
          current={pick(breakdown, "values", "groupUnselectedIntoOther")}
          breakdownDisabled={breakdownDisabled}
          disabled={breakdownDisabled}
          config={pickConfig(breakdown.key, allConfigs)}
          onUpdate={onBreakdownUpdate}
          includeOtherCheckbox={includeOtherCheckbox}
        />
      ))}
    </div>
  );
});

function newBreakdownFromConfig(config: BreakdownConfig): Breakdown {
  const { key } = config;
  if (config.type === "categorical") {
    return {
      key,
      values: [...config.choices.map(({ value }) => value)],
    };
  } else {
    return {
      key,
      values: [],
    };
  }
}

export function pickConfig(
  key: string,
  configs: BreakdownConfig[],
): BreakdownConfig {
  return _.chain(configs)
    .filter((v) => v.key === key)
    .first()
    .value();
}

const DefaultDropdownIndicator = (props: DropdownIndicatorProps<any>) => (
  <components.DropdownIndicator {...props}>
    <i className="fas fa-chart-pie"></i>
  </components.DropdownIndicator>
);

export function ExpandableBreakdownSelector(props: BreakdownSelectorProps) {
  const { user } = useUser();
  const { onUpdate, current, ...rest } = props;
  const [isExpanded, setExpanded] = React.useState(false);
  const [proposed, setProposed] = React.useState(current);

  useEffect(() => {
    setProposed(current);
  }, [current]);

  const applyBreakdowns = React.useCallback(() => {
    onUpdate(proposed);
    setExpanded(false);
  }, [onUpdate, proposed, setExpanded]);

  const breakdowns = current.map((v) => v.key);

  return (
    <div className="position-relative">
      <Block
        breakdowns={breakdowns}
        disabled={props.disabled}
        onClick={() => {
          if (!props.disabled) {
            setExpanded(true);
          }
        }}
      />
      {isExpanded && (
        <div
          className="mt-3"
          style={{ zIndex: 100, position: "absolute", right: 0 }}
        >
          <div
            className="bg-white p-3 rounded modal-dropshadow"
            style={{ width: "22em", position: "relative" }}
          >
            <BreakdownSelector
              {...rest}
              current={proposed}
              onUpdate={(v) => setProposed(v)}
            />
            <hr />
            <div className="w-100 flex justify-content-between">
              <button
                className="btn btn-sm btn-primary"
                onClick={applyBreakdowns}
              >
                Apply
              </button>
              <Link to={user.pathFromRoot("/breakdown-editor")}>
                Edit breakdowns
              </Link>
            </div>
          </div>
        </div>
      )}
      {isExpanded && <Blanket onClick={() => setExpanded(false)} />}
    </div>
  );
}

function Block(props: {
  breakdowns: string[];
  disabled: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      className={classNames("form-control text-truncate cursor-pointer", {
        "bg-light": props.disabled,
      })}
      onClick={props.onClick}
    >
      {props.breakdowns.join(", ") || (
        <span className="text-muted">(none)</span>
      )}
    </div>
  );
}
