import styled from "@emotion/styled";
import React from "react";

export interface ChangeFractionProps {
  changeFraction: number;
  isPositiveChangeGood: boolean;
  muted?: boolean;
}

export default function ChangeFractionDisplay(props: ChangeFractionProps) {
  let className = "neutral";
  let inner: React.ReactNode = "—%";

  if (
    typeof props.changeFraction !== "undefined" &&
    props.changeFraction !== null &&
    !isNaN(props.changeFraction)
  ) {
    const positiveChange = props.changeFraction >= 0;

    if (positiveChange) {
      className = props.isPositiveChangeGood
        ? "positive-color"
        : "negative-color";
    } else {
      className = props.isPositiveChangeGood
        ? "negative-color"
        : "positive-color";
    }

    const absValue =
      Math.abs(props.changeFraction) >= 1e12
        ? "∞"
        : (Math.abs(props.changeFraction) * 100).toFixed(1);
    let value = `${absValue}%`;
    if (!positiveChange) {
      value = `(−${value})`;
    }
    inner = <>{value}</>;
  }
  if (props.muted) {
    className = `${className} muted`;
  }

  return (
    <Style>
      <small className={className}>{inner}</small>
    </Style>
  );
}

const Style = styled.span`
  .positive-color {
    color: var(--green);
  }
  .negative-color {
    color: var(--red);
  }
  .neutral {
    color: var(--secondary);
  }

  .muted {
    // color: var(--secondary) !important;
    opacity: 0.8;
  }
`;
