import React from "react";
import classNames from "classnames";
import { TableTreeColumn } from "@devexpress/dx-react-grid";

// This implementation is a copy of Devextreme's TableTreeColumn.ExpandButton with the icons swapped out

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

const handleMouseDown = (e: any) => {
  e.target.style.outline = "none";
};

const handleBlur = (e: any) => {
  e.target.style.outline = "";
};

export const TableTreeColumnExpandButton = ({
  visible,
  expanded,
  onToggle,
  ...restProps
}: TableTreeColumn.ExpandButtonProps) => {
  if (!visible) {
    return null;
  }

  const fireToggle = () => onToggle();

  const handleClick = (e: any) => {
    e.stopPropagation();
    fireToggle();
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACE_KEY_CODE) {
      e.preventDefault();
      fireToggle();
    }
  };

  return (
    <i
      className={classNames(
        "mr-2 fa-solid p-2 text-center dx-g-bs4-toggle-button",
        {
          "fa-chevron-down": expanded,
          "fa-chevron-right": !expanded,
          "dx-g-bs4-toggle-button-hidden": !visible,
        },
      )}
      tabIndex={visible ? 0 : undefined}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      onBlur={handleBlur}
      onClick={handleClick}
      {...restProps}
    />
  );
};
