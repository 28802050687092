import { useEffect, useMemo } from "react";
import { ChartDataset } from "chart.js";
import { useAnnotationParam } from "./use-annotation-param";
import { useAnnotations } from "./use-annotations";
import { usePrettyLogEventPage } from "@utils/analytics";
import { DateInterval } from "@north-beam/nb-common";
import { eachDayOfInterval } from "date-fns";
import { CUSTOM_ANNOTATION_ID } from "../annotation-chart-plugin/annotation-plugin-helpers";
import { getDateOnly } from "@utils/datetime";

type AnnotationMetadata = {
  annotatedDates: string[];
  showAnnotations: (dateRange: DateInterval) => void;
  createAnnotation: (annotatedDate: string) => void;
};

export type AnnotationDataset = ChartDataset<"line", { date: string }[]> & {
  metadata: AnnotationMetadata;
};

export const useBuildAnnotationDataset = (
  startDate: string,
  endDate: string,
): AnnotationDataset => {
  const {
    showCreateAnnotationOnDate,
    listDateRangeAnnotations,
    listFilteredDateRangeAnnotations,
  } = useAnnotationParam();
  const { annotations, fetchAnnotations } = useAnnotations();
  const prettyLogEventPage = usePrettyLogEventPage();

  useEffect(() => {
    fetchAnnotations({ variables: { startDate, endDate } });
  }, [startDate, endDate, fetchAnnotations]);

  const dates = eachDayOfInterval({
    start: getDateOnly(startDate),
    end: getDateOnly(endDate),
  }).map((date) => ({
    date: date.toISOString().split("T")[0],
  }));

  const metadata = useMemo(() => {
    const annotatedDates = annotations.map(
      ({ annotatedDate }) => annotatedDate,
    );
    const showAnnotations = (dateRange: DateInterval) => {
      listDateRangeAnnotations({ startDate, endDate });
      listFilteredDateRangeAnnotations(dateRange);

      prettyLogEventPage({
        domain: "Annotations",
        action: "onClick",
        additionalActionInfo: "show annotations graph icon",
        data: { dateRange },
      });
    };
    const createAnnotation = (annotatedDate: string) => {
      listDateRangeAnnotations({ startDate, endDate });
      showCreateAnnotationOnDate(annotatedDate);

      prettyLogEventPage({
        domain: "Annotations",
        action: "onClick",
        additionalActionInfo: "add annotations graph icon",
        data: { annotatedDate },
      });
    };

    return {
      annotatedDates,
      showAnnotations,
      createAnnotation,
    };
  }, [
    listDateRangeAnnotations,
    prettyLogEventPage,
    annotations,
    showCreateAnnotationOnDate,
    listFilteredDateRangeAnnotations,
    startDate,
    endDate,
  ]);

  return {
    data: dates,
    hidden: true,
    parsing: { xAxisKey: "date" },
    label: CUSTOM_ANNOTATION_ID,
    metadata,
  };
};
