import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import Select, { ValueContainerProps, components } from "react-select";
import { CustomerTypeFriendlyNames } from "@/constants/customer-type-friendly-names";
import { useNorthbeamQuery } from "@utils/hooks";
import { FetchOrderProductOptions } from "@nb-api-graphql-generated/FetchOrderProductOptions";
import {
  FETCH_CUSTOMER_TAG_OPTIONS,
  FETCH_DISCOUNT_CODES_OPTIONS,
  FETCH_ORDER_PRODUCT_OPTIONS,
  FETCH_ORDER_TAG_OPTIONS,
  FETCH_ORDER_TYPE_OPTIONS,
} from "@pages/settings/queries";
import { DocumentNode } from "graphql";
import { FetchOrderTypeOptions } from "@nb-api-graphql-generated/FetchOrderTypeOptions";
import { FetchOrderTagOptions } from "@nb-api-graphql-generated/FetchOrderTagOptions";
import { useCachedQuery } from "@components/hooks";
import {
  useAttributedAtom,
  useCustomerTagsAtom,
  useDiscountCodesAtom,
  useNumberOfOrderItemsAtom,
  useNumberOfTouchpointsAtom,
  useOrderIdAtom,
  useOrderTagsAtom,
  useOrderTypesAtom,
  useOrderValueAtom,
  useProductsPurchasedAtom,
  useSubscriptionsAtom,
} from "@/atoms/order-page-atoms";

import { useOrderQueriesLoading } from "@hooks/use-order-page-loading";
import { ValueComparisonType } from "@nb-api-graphql-generated/global-types";
import { Option } from "@/types/react-select";
import { generateAnonBase64Hash } from "@utils/anonymous";
import { prefs } from "@utils/client-side-preferences";
import { FetchOrderDiscountCodeOptions } from "@nb-api-graphql-generated/FetchOrderDiscountCodeOptions";
import { useOrdersPageFilters } from "@hooks/use-orders-filters";
import {
  SubscriptionValueToLabelMapping,
  SUBSCRIPTION_TYPES,
} from "@pages/subscriptions-order-tags/subscription-order-tags";
import { FetchCustomerTagOptions } from "@nb-api-graphql-generated/FetchCustomerTagOptions";

export const MultiBadgeEditor = ({
  isLoading,
  options,
  onChange,
  value,
  disabled = false,
  type,
  shouldAnon,
}: {
  isLoading?: boolean;
  options: Option[];
  onChange: (option: Option[] | undefined) => void;
  value: Option[];
  disabled: boolean;
  type: string;
  shouldAnon: boolean;
}) => {
  const doAnon = prefs.isDemoMode();

  return (
    <Select
      isDisabled={disabled}
      value={value}
      onChange={(options) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return options?.length ? onChange(options) : onChange(null);
      }}
      isMulti
      isLoading={isLoading}
      styles={{
        control: (styles) => ({
          ...styles,
          fontSize: "0.9rem",
          fontWeight: 400,
        }),
        option: (styles) => {
          return { ...styles, fontSize: "0.9rem", fontWeight: 400 };
        },
      }}
      options={
        shouldAnon
          ? options.map((o) => {
              const hash = generateAnonBase64Hash({
                value: o.label,
                prefix: type,
                doAnon,
              });
              return {
                label: hash,
                value: hash,
              };
            })
          : options
      }
      className="w-full"
    />
  );
};

function useGetOptions<Query>(query: DocumentNode) {
  const filterOptions = useOrdersPageFilters();

  const { data, loading } = useNorthbeamQuery<
    Query,
    { dateRange: { start: Date; end: Date } }
  >(query, {
    variables: {
      dateRange: filterOptions.dateRange,
    },
  });
  const cachedData = useCachedQuery(data);
  return { loading, data: cachedData };
}

export const ProductEditor = () => {
  const { loading, data } = useGetOptions<FetchOrderProductOptions>(
    FETCH_ORDER_PRODUCT_OPTIONS,
  );
  const [products, setProducts] = useProductsPurchasedAtom();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <MultiBadgeEditor
      isLoading={loading}
      options={(data?.me.products || []).map((p) => ({ value: p, label: p }))}
      value={
        products?.map((product) => ({
          value: product,
          label: product,
        })) || []
      }
      type={"product"}
      shouldAnon
      disabled={orderQueriesLoading}
      onChange={(options) => setProducts(options?.map((o) => o.value) || null)}
    />
  );
};

export const OrderTypesEditor = () => {
  const { loading, data } = useGetOptions<FetchOrderTypeOptions>(
    FETCH_ORDER_TYPE_OPTIONS,
  );

  const [filter, setFilter] = useOrderTypesAtom();

  const orderQueriesLoading = useOrderQueriesLoading();
  return (
    <MultiBadgeEditor
      isLoading={loading}
      options={(data?.me.orderTypes || []).map((i) => ({
        value: CustomerTypeFriendlyNames[i] || i,
        label: CustomerTypeFriendlyNames[i] || i,
      }))}
      value={
        filter?.map((i) => ({
          value: i,
          label: i,
        })) || []
      }
      shouldAnon={false}
      type="customerTypes"
      disabled={orderQueriesLoading}
      onChange={(options) => setFilter(options?.map((o) => o.value) || null)}
    />
  );
};

export const OrderTagsEditor = () => {
  const { loading, data } = useGetOptions<FetchOrderTagOptions>(
    FETCH_ORDER_TAG_OPTIONS,
  );
  const [filter, setFilter] = useOrderTagsAtom();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <MultiBadgeEditor
      isLoading={loading}
      options={(data?.me.orderTags || []).map((i) => ({ value: i, label: i }))}
      value={
        filter?.map((i) => ({
          value: i,
          label: i,
        })) || []
      }
      shouldAnon
      type="tag"
      disabled={orderQueriesLoading}
      onChange={(options) => setFilter(options?.map((o) => o.value) || null)}
    />
  );
};

export const CustomerTagsEditor = () => {
  const { loading, data } = useGetOptions<FetchCustomerTagOptions>(
    FETCH_CUSTOMER_TAG_OPTIONS,
  );
  const [filter, setFilter] = useCustomerTagsAtom();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <MultiBadgeEditor
      isLoading={loading}
      options={(data?.me.customerTags || []).map((i) => ({
        value: i,
        label: i,
      }))}
      value={
        filter?.map((i) => ({
          value: i,
          label: i,
        })) || []
      }
      shouldAnon
      type="customerTag"
      disabled={orderQueriesLoading}
      onChange={(options) => setFilter(options?.map((o) => o.value) || null)}
    />
  );
};

export const SubscriptionsEditor = () => {
  const [filter, setFilter] = useSubscriptionsAtom();

  return (
    <MultiBadgeEditor
      isLoading={false}
      options={SUBSCRIPTION_TYPES}
      value={
        filter?.map((i) => ({
          value: i,
          label: SubscriptionValueToLabelMapping[i],
        })) || []
      }
      shouldAnon={false}
      type="subscription"
      disabled={false}
      onChange={(options) => setFilter(options?.map((o) => o.value) || null)}
    />
  );
};

export const DiscountCodesEditor = () => {
  const { loading, data } = useGetOptions<FetchOrderDiscountCodeOptions>(
    FETCH_DISCOUNT_CODES_OPTIONS,
  );
  const [filter, setFilter] = useDiscountCodesAtom();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <MultiBadgeEditor
      isLoading={loading}
      options={(data?.me.discountCodes || []).map((i) => ({
        value: i,
        label: i,
      }))}
      value={
        filter?.map((i) => ({
          value: i,
          label: i,
        })) || []
      }
      shouldAnon
      type="discount"
      disabled={orderQueriesLoading}
      onChange={(options) => setFilter(options?.map((o) => o.value) || null)}
    />
  );
};

export const AttributedEditor = (props: DataTypeProvider.ValueEditorProps) => {
  const [filter, setFilter] = useAttributedAtom();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <MultiBadgeEditor
      options={[
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ]}
      value={
        filter?.map((i) => ({
          value: i,
          label: i,
        })) || []
      }
      shouldAnon={false}
      type="attributed"
      disabled={orderQueriesLoading}
      onChange={(options) => setFilter(options?.map((o) => o.value) || null)}
    />
  );
};

const Input = ({
  onChange,
  value,
  id,
  disabled = false,
}: {
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  disabled: boolean;
}) => {
  return (
    <input
      className="text-sm font-normal rounded w-full py-2 px-3 border focus:outline-none focus:border-[#2684FF] focus:ring-1 focus:ring-[#2684FF]"
      id={id}
      style={{ borderColor: "hsl(0deg 0% 80%)" }}
      type="text"
      value={value}
      placeholder="Filter value..."
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export const OrderValueEditor = () => {
  const [filter, setFilter] = useOrderValueAtom();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <ComparisonSelector
      disabled={orderQueriesLoading}
      value={filter?.value ? String(filter.value) : ""}
      comparison={
        filter?.comparisonOperator === undefined
          ? null
          : filter?.comparisonOperator
      }
      onChangeComparison={function (option: ComparisonOption | null): void {
        if (!option?.value && !filter?.value) setFilter(null);
        else setFilter({ ...filter, comparisonOperator: option?.value });
      }}
      onChangeValue={function (value: string): void {
        if (!value && !filter?.comparisonOperator) {
          setFilter(null);
        } else {
          setFilter({ ...filter, value });
        }
      }}
    />
  );
};

const getNumberValue = (prevValue: number | undefined, nextValue: string) => {
  const numberedNewVal = Number(nextValue);
  if (nextValue.trim() === "") return undefined;
  if (isNaN(numberedNewVal)) return prevValue;
  return numberedNewVal;
};

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<Option>) => (
  <components.ValueContainer
    {...props}
    className={"flex justify-center items-center "}
  >
    {children}
  </components.ValueContainer>
);

export const COMPARISON_OPERATORS_MAPPING = {
  lessThanOrEqual: "<=",
  lessThan: "<",
  equalTo: "=",
  greaterThan: ">",
  greaterThanOrEqualTo: ">=",
};

export type ComparisonOption = {
  value: ValueComparisonType;
  label: string;
};

const ComparisonSelector = ({
  value,
  comparison,
  onChangeComparison,
  onChangeValue,
  disabled,
}: {
  disabled: boolean;
  value: string;
  comparison: ValueComparisonType | null;
  onChangeComparison: (option: ComparisonOption | null) => void;
  onChangeValue: (value: string) => void;
}) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(event.target.value);
  };

  const handleComparisonChange = (option: ComparisonOption | null) => {
    onChangeComparison(option);
  };

  const comparisonOptions: ComparisonOption[] = [
    { value: ValueComparisonType.equalTo, label: "=" },
    { value: ValueComparisonType.greaterThan, label: ">" },
    { value: ValueComparisonType.greaterThanOrEqualTo, label: ">=" },
    { value: ValueComparisonType.lessThan, label: "<" },
    { value: ValueComparisonType.lessThanOrEqual, label: "<=" },
  ];

  return (
    <div className="flex">
      <Input
        id={"comparison-input"}
        disabled={disabled}
        value={value}
        onChange={handleValueChange}
      />
      <Select
        isDisabled={disabled}
        isClearable={true}
        isSearchable={false}
        placeholder={<i className="fa-duotone fa-chevron-down self-center"></i>}
        className="min-w-[4.5rem] ml-2"
        value={
          comparison
            ? {
                value: comparison,
                label: COMPARISON_OPERATORS_MAPPING[comparison],
              }
            : null
        }
        onChange={(option) => handleComparisonChange(option as any)}
        options={comparisonOptions}
        components={{
          ClearIndicator: ({ children, ...props }) => (
            <components.ClearIndicator {...props} className={"pl-0"}>
              {children}
            </components.ClearIndicator>
          ),
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ...(!comparison && {
            ValueContainer,
          }),
        }}
      />
    </div>
  );
};

export const OrderTouchpointsEditor = () => {
  const [filter, setFilter] = useNumberOfTouchpointsAtom();

  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <ComparisonSelector
      disabled={orderQueriesLoading}
      value={filter?.value ? String(filter.value) : ""}
      comparison={
        filter?.comparisonOperator === undefined
          ? null
          : filter?.comparisonOperator
      }
      onChangeComparison={function (option: ComparisonOption | null): void {
        if (!option?.value && !filter?.value) setFilter(null);
        else setFilter({ ...filter, comparisonOperator: option?.value });
      }}
      onChangeValue={function (value: string): void {
        const newVal = getNumberValue(filter?.value, value);
        if (!newVal && !filter?.comparisonOperator) setFilter(null);
        else setFilter({ ...filter, value: newVal });
      }}
    />
  );
};

export const OrderNumberOfOriginalItemsEditor = () => {
  const [filter, setFilter] = useNumberOfOrderItemsAtom();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <ComparisonSelector
      disabled={orderQueriesLoading}
      value={filter?.value ? String(filter.value) : ""}
      comparison={
        filter?.comparisonOperator === undefined
          ? null
          : filter?.comparisonOperator
      }
      onChangeComparison={function (option: ComparisonOption | null): void {
        if (!option?.value && !filter?.value) setFilter(null);
        else setFilter({ ...filter, comparisonOperator: option?.value });
      }}
      onChangeValue={function (value: string): void {
        const newVal = getNumberValue(filter?.value, value);
        if (!newVal && !filter?.comparisonOperator) setFilter(null);
        else setFilter({ ...filter, value: newVal });
      }}
    />
  );
};

export const OrderIdEditor = () => {
  const [filter, setFilter] = useOrderIdAtom();
  const orderQueriesLoading = useOrderQueriesLoading();

  return (
    <Input
      id="orderId"
      value={filter || ""}
      onChange={(e) => setFilter(e.currentTarget.value)}
      disabled={orderQueriesLoading}
    />
  );
};
