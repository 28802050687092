import React, { CSSProperties, ReactNode } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

interface FlyoutProps {
  open: boolean;
  onClose?: () => void;
  direction: "left" | "right" | "top" | "bottom";
  children?: ReactNode;
  duration?: number;
  overlayOpacity?: number;
  overlayColor?: string;
  enableOverlay?: boolean;
  style?: CSSProperties;
  size?: number | string;
  className?: string | undefined;
  customIdSuffix?: string | undefined;
  drawerWidth?: number | undefined;
}

export const Flyout = ({
  open,
  onClose,
  direction,
  children,
  duration,
  overlayOpacity,
  overlayColor,
  enableOverlay,
  style,
  className,
  customIdSuffix,
  drawerWidth,
}: FlyoutProps) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      direction={direction}
      className={className}
      duration={duration}
      overlayOpacity={overlayOpacity}
      overlayColor={overlayColor}
      enableOverlay={enableOverlay}
      style={style}
      zIndex={502}
      customIdSuffix={customIdSuffix}
      size={drawerWidth}
    >
      {children}
    </Drawer>
  );
};
