import { Checkbox } from "@shared/checkbox";
import React, { useState } from "react";
import { Accordion, AccordionProps } from "./accordion";

interface Props<T> extends AccordionProps {
  options: {
    id: T;
    label: string;
    isSelected: boolean;
    isDisabled: boolean;
  }[];
  onChange(id: T, value: boolean): void;
  onChangeAll(value: boolean): void;
}

const SHOW_MORE_LIMIT = 20;

export function AccordionWithCheckboxes<T>({
  title,
  options,
  isExpandedByDefault,
  onChange,
  onChangeAll,
}: Props<T>) {
  const [isMoreVisible, setMoreVisible] = useState(false);

  return (
    <Accordion title={title} isExpandedByDefault={isExpandedByDefault}>
      <div>
        <div className="text-[10px] mb-5">
          <button
            className="mr-8 text-blue-500 hover:text-blue-600"
            onClick={() => onChangeAll(true)}
          >
            Select All
          </button>
          <button
            className="text-blue-500 hover:text-blue-600"
            onClick={() => onChangeAll(false)}
          >
            Clear All
          </button>
        </div>

        <div className="flex flex-wrap text-[12px] font-light gap-y-4 mb-4">
          {(isMoreVisible ? options : options.slice(0, SHOW_MORE_LIMIT)).map(
            (e) => (
              <div key={String(e.id)} className="flex-1 min-w-[13rem]">
                <Checkbox
                  inputClassName="w-3 h-3 mr-0.5"
                  isDisabled={e.isDisabled}
                  value={e.isSelected}
                  label={e.label}
                  onChange={(val) => onChange(e.id, val)}
                />
              </div>
            ),
          )}
        </div>

        {options.length > SHOW_MORE_LIMIT && (
          <div className="text-[10px] mb-4">
            <button
              className="text-blue-500 hover:text-blue-600"
              onClick={() => setMoreVisible(!isMoreVisible)}
            >
              {isMoreVisible ? "Show less" : "Show more"}
            </button>
          </div>
        )}
      </div>
    </Accordion>
  );
}
