import { chunk, groupBy } from "lodash";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GetOverviewPerformance_me_overviewPage_performanceMetrics as OverviewPageMetric } from "@nb-api-graphql-generated/GetOverviewPerformance";
import { MetricGroupBox } from "./metric-group-box";

export function MetricCharts({
  metricsOverview,
  isLoading,
}: {
  metricsOverview: OverviewPageMetric[];
  isLoading: boolean;
}) {
  const groupedMetrics = groupBy(metricsOverview, "groupName");
  const metricGroups = Object.entries(groupedMetrics).map(
    ([groupName, metrics]) => ({ groupName, metrics }),
  );

  const groupsOfFour = chunk(metricGroups, 4);
  if (groupsOfFour.length === 1) {
    return (
      <div className="row">
        {metricGroups.map(({ groupName, metrics }) => (
          <MetricGroupBox
            key={groupName}
            metrics={metrics}
            groupName={groupName}
            isLoading={isLoading}
          />
        ))}
      </div>
    );
  }

  return (
    <Carousel className="nb-metrics-carousel" showThumbs={false}>
      {groupsOfFour.map((vs) => (
        <div key={vs.map((v) => v.groupName).join("|")}>
          <div className="row">
            {vs.map(({ groupName, metrics }) => (
              <MetricGroupBox
                key={groupName}
                metrics={metrics}
                groupName={groupName}
                isLoading={isLoading}
              />
            ))}
          </div>
        </div>
      ))}
    </Carousel>
  );
}
