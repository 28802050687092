import GreenDot from "@assets/green-dot.svg";
import RedDot from "@assets/red-dot.svg";
import TargetingSetting from "@assets/targeting-setting.svg";
import { GetCampaign_me_adObject } from "@nb-api-graphql-generated/GetCampaign";
import { NB_PLATFORM } from "@north-beam/nb-common";
import { formatStringToReadableText } from "@utils/strings";
import React from "react";

interface TargetingSettingsSectionInterface {
  adObject: GetCampaign_me_adObject;
}

export const TargetingSettingsSection = ({
  adObject,
}: TargetingSettingsSectionInterface) => {
  const renderSettings = (settings: any) => {
    return (
      <div className="py-2 mt-2">
        <div className="flex items-center">
          <img
            className="w-4 mr-2"
            alt="Targeting Setting"
            src={TargetingSetting}
          />
          <div className="text-lg text-gray-900">Targeting Settings</div>
        </div>
        {Object.keys(settings).map((key) => {
          if (settings[key].length === 0) {
            return null;
          }

          const targetList: ISetting[] = settings[key];
          return (
            <TargetSetting targetList={targetList} targetName={key} key={key} />
          );
        })}
      </div>
    );
  };
  if (
    adObject.nbtPlatformID === NB_PLATFORM.GOOGLE &&
    adObject.formattedGoogleData?.targettingSetting
  ) {
    return renderSettings(adObject.formattedGoogleData.targettingSetting);
  }
  if (
    adObject.nbtPlatformID === NB_PLATFORM.FACEBOOK &&
    adObject.formattedFacebookData?.targettingSetting
  ) {
    return renderSettings(adObject.formattedFacebookData.targettingSetting);
  }

  if (
    adObject.nbtPlatformID === NB_PLATFORM.TIKTOK &&
    adObject.formattedTiktokData?.targettingSetting
  ) {
    return renderSettings(adObject.formattedTiktokData.targettingSetting);
  }

  if (
    adObject.nbtPlatformID === NB_PLATFORM.PINTEREST &&
    adObject.formattedPinterestData?.targettingSetting
  ) {
    return renderSettings(adObject.formattedPinterestData.targettingSetting);
  }

  return null;
};

interface ITargetSetting {
  targetList: ISetting[];
  targetName: string;
}

interface ISetting {
  name: string;
  negative: boolean;
  ignoreTextFormat: boolean;
}

export const TargetSetting = ({ targetList, targetName }: ITargetSetting) => {
  return (
    <div key={targetName} className="py-2">
      <div className="text-base text-gray-900">
        {formatStringToReadableText(targetName)}:
      </div>
      <div className="flex flex-wrap">
        {targetList.map((data: ISetting, index: number) => {
          return (
            <div className="flex py-2 pr-5" key={data.name + index}>
              {data.negative ? (
                <img className="w-3 mr-2" alt="Red Dot" src={RedDot} />
              ) : (
                <img className="w-3 mr-2" alt="Green Dot" src={GreenDot} />
              )}
              <span>
                {data.ignoreTextFormat
                  ? data.name
                  : formatStringToReadableText(data.name)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
