import React from "react";
import {
  DateRangeDisplay,
  DateRangePicker,
} from "@components/date-range-picker";
import { useUser } from "@components/user-context";
import { FixedDateRange } from "@north-beam/nb-common";
import { DateRangeChoiceEnum, DateRangeChoiceV2 } from "@utils/constants";

interface DateRangeSelectProps {
  isLoading: boolean;
  dateRange: FixedDateRange | DateRangeChoiceV2;
  onUpdate: (opt: any) => void;
  convertDateRangeToFixed: (dateRange: FixedDateRange) => void;
  maxLookbackDays?: number;
}

export function DateRangeSelect({
  isLoading,
  dateRange,
  onUpdate,
  convertDateRangeToFixed,
  maxLookbackDays,
}: DateRangeSelectProps) {
  const { user } = useUser();

  const dateRangeCurrent =
    typeof dateRange === "string"
      ? DateRangeChoiceEnum.toReactSelect(dateRange)
      : dateRange;

  return (
    <>
      <DateRangePicker
        disabled={isLoading}
        nonCustomChoices={DateRangeChoiceEnum.reactSelectOptions}
        current={dateRangeCurrent}
        onUpdate={onUpdate}
        timezone={user.timezone}
        maxLookbackDays={maxLookbackDays}
      />
      <small className="text-muted mt-1 d-block text-small">
        <DateRangeDisplay
          dateRange={dateRange}
          onConversionToFixed={convertDateRangeToFixed}
        />
      </small>
    </>
  );
}
