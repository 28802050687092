import { usePageTitle } from "@/atoms/page-title-atom";
import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { LoadingSlide, NoDataView } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import { H1 } from "@components/utilities";
import { AnnotationsFlyout } from "@features/custom-annotations";
import { logEvent, LogOnMount } from "@utils/analytics";
import { PLATFORM_NORTHBEAM } from "@utils/constants";
import {
  getSalesPageMetricSpecs,
  makeFirstTimeVsReturningRows,
  makeOverviewRows,
  makeTrafficRows,
} from "@utils/metrics";
import { uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useEffectOnce, useSessionStorage } from "usehooks-ts";
import { BenchmarkSettings } from "./benchmark-setting-section";
import { ChartSection } from "./chart-section/chart";
import { FlyoutSection } from "./flyout-section";
import { ReportBodyControl, useReportBodyState } from "./report-body-control";
import { SalesAPIErrorBoundary } from "./sales-page-error-container";
import { SalesViewsProvider } from "./sales-views-context";
import { TabView } from "./tab-table-section/tab-table-section";
import { TabTableSection } from "./table-section";
import { useCustomSalesViews } from "./use-custom-sales-views";
import { useSalesReportV2 } from "./use-sales-report";
import { useSalesReportDataFilter } from "./use-sales-report-data-filter";

export const SalesPage = () => {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Sales");
  }, [setPageTitle]);

  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const { user } = useUser();
  const { isCustomSalesViewsLoading } = useCustomSalesViews();

  // Resets because it should not persist across refreshes
  const [, setCurrentSalesViewEdit] = useSessionStorage<null>(
    "currentSalesViewEdit",
    null,
  );
  useEffectOnce(() => {
    setCurrentSalesViewEdit(null);
  });

  const {
    state,
    dateRange,
    compareDateRange,
    attributionModel,
    attributionWindow,
    nbAccountingMode,
    granularity,
    timeGranularity,

    setGranularity,
    setBreakdowns,
  } = useReportBodyState();

  const { loadAllData, data, isLoading, error, refetch } = useSalesReportV2(
    granularity,
    {
      dateRange,
      compareDateRange,
      attributionModel,
      attributionWindow,
      nbAccountingMode,
      timeGranularity,
    },
  );

  const salesReport = data?.me?.salesReport;

  const selectPlatformBreakdown = useCallback(() => {
    const platform = breakdownConfigs.find(
      ({ key }) => key === PLATFORM_NORTHBEAM,
    );
    if (platform) {
      const platformOption = {
        key: platform.key,
        values: platform.choices.map(({ value }) => value),
      };
      setBreakdowns([platformOption]);
      setGranularity({ value: TabView.PLATFORM });
    }
  }, [breakdownConfigs, setGranularity, setBreakdowns]);

  const unselectPlatformBreakdown = useCallback(() => {
    const breakdowns = state.breakdowns.filter(
      ({ key }) => key !== PLATFORM_NORTHBEAM,
    );
    setBreakdowns(breakdowns);
  }, [state.breakdowns, setBreakdowns]);

  const metricsArray = useMemo(() => {
    const metrics = [
      ...makeOverviewRows(
        attributionModel,
        attributionWindow,
        salesReport?.hasV1Forecasts ?? false,
        nbAccountingMode,
        salesReport?.customGoals ?? [],
        timeGranularity,
        user,
      ),
      ...makeTrafficRows(
        attributionModel,
        attributionWindow,
        nbAccountingMode,
        user,
        timeGranularity,
      ),
      ...makeFirstTimeVsReturningRows(
        attributionModel,
        attributionWindow,
        nbAccountingMode,
        timeGranularity,
        user.attributionMethodEnum,
      ),
    ];

    return uniqBy(metrics, "id");
  }, [
    salesReport,
    user,
    attributionModel,
    attributionWindow,
    nbAccountingMode,
    timeGranularity,
  ]);

  const metricSpecs = useMemo(() => {
    return getSalesPageMetricSpecs(
      user,
      salesReport?.hasV1Forecasts ?? false,
      salesReport?.customGoals ?? [],
    );
  }, [salesReport, user]);

  const {
    filteredRows,
    clearLevelFilter,
    setObjectIdsToAdLevelFilters,
    salesTabTableSelectedCount,
  } = useSalesReportDataFilter(salesReport, granularity as TabView);

  useEffect(() => {
    logEvent("Request Sales Report V2", state);
    loadAllData();
  }, [state, breakdownConfigs, loadAllData]);

  if (error) {
    return <SalesAPIErrorBoundary refetch={() => refetch?.()} />;
  }

  if (
    isCustomSalesViewsLoading ||
    isLoading ||
    (breakdownConfigs.length === 1 && breakdownConfigs[0].default)
  ) {
    return <LoadingSlide />;
  }

  if (!salesReport || salesReport.rows.length === 0) {
    return <NoDataView />;
  }

  return (
    <SalesViewsProvider>
      <LogOnMount name="Visit Sales page" />
      <ReportBodyControl isLoading={isLoading} />
      <div className="px-4">
        <div className="flex justify-between">
          <div>
            <H1 className="mt-3">Sales and Marketing</H1>
            <p>Here&apos;s how your ads have been performing.</p>
          </div>
        </div>
        <AnnotationsFlyout />
        <FlyoutSection state={state} rows={filteredRows} />
        <ChartSection rows={filteredRows} metricsArray={metricsArray} />
        {user.featureFlags.showBenchmarks && <BenchmarkSettings />}
        <TabTableSection
          rows={filteredRows}
          salesTabTableSelectedCount={salesTabTableSelectedCount}
          clearLevelFilter={clearLevelFilter}
          metricsArray={metricsArray}
          selectPlatformBreakdown={selectPlatformBreakdown}
          unselectPlatformBreakdown={unselectPlatformBreakdown}
          setObjectIdsToAdLevelFilters={setObjectIdsToAdLevelFilters}
          salesPageMetricSpecs={metricSpecs}
        />
      </div>
    </SalesViewsProvider>
  );
};
