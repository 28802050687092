import chroma from "chroma-js";
import { sha1 } from "hash.js";

const colorScaleDark = chroma
  .cubehelix()
  .rotations(1)
  .hue(2)
  .lightness(0.3)
  .scale();

const colorCacheDark: Record<string, string> = {};

export function pickColorDark(hash: string) {
  if (!colorCacheDark[hash]) {
    const digits = sha1().update(hash).digest("hex");
    const alpha = parseInt(`${digits.slice(-6)}`, 16) / parseInt("ffffff", 16);
    colorCacheDark[hash] = colorScaleDark(alpha).css();
  }
  return colorCacheDark[hash];
}

const colorScaleExtraLight = chroma
  .cubehelix()
  .rotations(1)
  .hue(1)
  .lightness(0.9)
  .scale();

const colorCacheExtraLight: Record<string, string> = {};

export function pickColorExtraLight(hash: string) {
  if (!colorCacheExtraLight[hash]) {
    const digits = sha1().update(hash).digest("hex");
    const alpha = parseInt(`${digits.slice(-6)}`, 16) / parseInt("ffffff", 16);
    colorCacheExtraLight[hash] = colorScaleExtraLight(alpha).css();
  }
  return colorCacheExtraLight[hash];
}

export function colorScheme1(numColors: number) {
  const rv = chroma
    .cubehelix()
    .start(211.06)
    .rotations(1)
    .hue(2)
    .lightness(0.7)
    .gamma(1)
    .scale()
    .colors(numColors + 1);
  rv.pop();
  return rv;
}

export const spaceColorScheme = (numColors: number) => {
  // Colors derived from this figma: https://www.figma.com/file/mdtRUuSmqkmRMUW3P8ZDKr/Northbeam-Style-Guide?node-id=2284%3A7037&t=flbk0UugHRhGj4Sh-0
  const basicColors: string[] = [
    "#7081FF",
    "#BCFBBA",
    "#C825A8",
    "#71D5D5",
    "#F05F33",
    "#FFD247",
    "#50BA58",
    "#F3971A",
    "#004F2D",
    "#681E06",
  ];
  const repeatCount = Math.ceil(numColors / basicColors.length);

  return Array(repeatCount).fill(basicColors).flat();
};

export function colorScheme2(numColors: number) {
  // Colors derived from this figma: https://www.figma.com/file/mdtRUuSmqkmRMUW3P8ZDKr/Northbeam-Style-Guide?node-id=2284%3A7037&t=flbk0UugHRhGj4Sh-0
  const basicColors: string[] = [
    "#6C69EE",
    "#98D8F9",
    "#A9B808",
    "#36E2A4",
    "#FFCD90",
    "#2A3E57",
    "#FE7DCF",
    "#D9B1FC",
    "#55994A",
    "#F05A2B",
    "#514FB2",
    "#4AC2FF",
    "#E8F08F",
    "#20B17D",
    "#FBA33C",
    "#81A8DA",
    "#BC2F89",
    "#C88FFA",
    "#A2E596",
    "#CB4115",
    "#B6B4F7",
    "#007DBD",
    "#D6E535",
    "#96D9C1",
    "#D47E18",
    "#708CB0",
    "#F9BCE2",
    "#8C64AF",
    "#7ADA69",
    "#FA997B",
  ];
  const repeatCount = Math.ceil(numColors / basicColors.length);

  return Array(repeatCount).fill(basicColors).flat();
}

export function colorGradient(numColors: number, startColor: string) {
  const rv = chroma.scale(["white", startColor]).colors(numColors + 2);
  rv.shift();
  rv.shift();
  return rv;
}
