import clsx from "clsx";
import React, { useEffect } from "react";
import { useActiveCreativeTypeAtom } from "../atoms";

export function CreativeAnalyticsTypeBar() {
  const creativeTypes = useFetchCreativeTypes();
  const [activeCreative, setActiveCreative] = useActiveCreativeTypeAtom();

  useEffect(() => {
    if (!activeCreative) {
      setActiveCreative(creativeTypes[0].id);
    }
  }, [activeCreative, creativeTypes, setActiveCreative]);

  return (
    <div className="flex flex-row h-[5rem] pl-2">
      {creativeTypes.map((caType) => {
        const isActive = activeCreative === caType.id;
        return (
          <CreativeAnalyticsLink
            isActive={isActive}
            key={caType.id}
            text={caType.name}
            onClick={() => setActiveCreative(caType.id)}
          />
        );
      })}
    </div>
  );
}

export function CreativeAnalyticsLink({
  isActive,
  onClick,
  text,
}: {
  text: string;
  isActive: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      className={clsx("px-4 cursor-pointer mt-5", {
        "text-subtitle": !isActive,
        "text-cosmic": isActive,
      })}
      onClick={onClick}
    >
      <div className="mx-2">{text}</div>
      {isActive && <div className="border-cosmic bottom-0 border-t-2 mt-2" />}
    </div>
  );
}

function useFetchCreativeTypes() {
  return [
    { name: "All", id: "all" },
    { name: "Image", id: "image" },
    { name: "Video", id: "video" },
    { name: "Carousel", id: "carousel" },
    { name: "Instant Experience", id: "instant-experience" },
    { name: "Collection", id: "collection" },
    { name: "Lead Ad", id: "lead-ad" },
  ];
}
