import React, { useContext } from "react";
import { useUser } from "@components/user-context";
import { Context } from "../context";
import { useRedirectURL } from "../hooks";

export function Pinterest() {
  const { domainName, collectorSubdomain } = useContext(Context);
  const { user } = useUser();

  const pinterestWebsiteUrl = useRedirectURL({
    collectorSubdomain,
    domainName,
    displayURL: "{lpurl}",
    encodeDisplayURL: false,
    displayId: user.displayUserId,
    platform: "pinterest",
    property:
      "nb%3Apinterest%3A%3A{campaignid}%3A{adgroupid}%3A{adid}%7C%3A%7C{keyword}%7C%3A%7C{device}%7C%3A%7C{product_name}%7C%3A%7C{promoted_product_group_id}%7C%3A%7C{product_id}%7C%3A%7C{product_partition_id}",
  });

  return (
    <>
      <ol>
        <li>
          <div>
            <div className="py-2 my-2">
              Set the <b>ad_link</b> column&apos;s landing page to the landing
              page you want customers to land on after clicking the ad.
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="control-label mb-2">
              Then, set the <b>Tracking template</b> to the below text:
            </div>
            <textarea
              rows={4}
              className="form-control mb-2"
              style={{ wordBreak: "break-all" }}
              readOnly
              value={pinterestWebsiteUrl || ""}
            />
          </div>
        </li>
      </ol>
    </>
  );
}
