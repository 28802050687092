import { Plugin, Chart } from "chart.js";
import {
  AnnotationData,
  checkIfOnIcon,
  CUSTOM_ANNOTATION_ID,
  drawAnnotationIcons,
  drawIcon,
  getAnnotationData,
  getIsAtMaxDateRange,
} from "./annotation-plugin-helpers";
import {
  buildAddSvg,
  buildClearAddSvg,
  buildMessageSvg,
} from "./annotation-svgs";
import {
  addAnnotationTooltip,
  removeAnnotationTooltip,
  showAnnotationsTooltip,
} from "./annotation-tooltip";

const getAnnotationType = (data: AnnotationData) => {
  const isShowAnnotation = "showAnnotations" in data;
  const isCreateAnnotation = "createAnnotation" in data;
  return { isShowAnnotation, isCreateAnnotation };
};

const handleIconHover = (
  chart: Chart,
  data: AnnotationData,
  canCreateAnnotation: boolean,
) => {
  const { isShowAnnotation, isCreateAnnotation } = getAnnotationType(data);

  if (isCreateAnnotation && canCreateAnnotation) {
    chart.canvas.style.cursor = "pointer";
    const addSvgPosition = buildAddSvg(data.point, chart.chartArea.bottom);
    drawIcon(chart, addSvgPosition);
    addAnnotationTooltip(chart, data);
  } else if (isShowAnnotation) {
    chart.canvas.style.cursor = "pointer";
    const iconPosition = buildMessageSvg(
      data.point,
      chart.chartArea.bottom,
      data.clusteredAnnotationCount ?? 0,
      true,
    );
    showAnnotationsTooltip(chart, data);
    drawIcon(chart, iconPosition);
  }
};

const handleIconClick = (
  data: AnnotationData,
  canCreateAnnotation: boolean,
) => {
  const { isShowAnnotation, isCreateAnnotation } = getAnnotationType(data);

  if (isShowAnnotation) {
    data.showAnnotations?.();
  } else if (isCreateAnnotation && canCreateAnnotation) {
    data.createAnnotation?.();
  }
};

const handleChartHover = (
  chart: Chart,
  data: AnnotationData,
  canCreateAnnotation: boolean,
) => {
  const { isShowAnnotation, isCreateAnnotation } = getAnnotationType(data);
  if (isCreateAnnotation && canCreateAnnotation) {
    const clearAddSvgPosition = buildClearAddSvg(
      data.point,
      chart.chartArea.bottom,
    );
    drawIcon(chart, clearAddSvgPosition);
  } else if (isShowAnnotation) {
    const iconPosition = buildMessageSvg(
      data.point,
      chart.chartArea.bottom,
      data.clusteredAnnotationCount ?? 0,
    );
    drawIcon(chart, iconPosition);
  }
};

export const annotationPlugin: Plugin = {
  id: CUSTOM_ANNOTATION_ID,
  afterDraw: (chart) => drawAnnotationIcons(chart),
  afterRender: (chart) => drawAnnotationIcons(chart),
  afterEvent(chart, { event }) {
    chart.canvas.style.cursor = "default";
    removeAnnotationTooltip();

    const annotationData = getAnnotationData(chart);
    const canCreateAnnotation = !getIsAtMaxDateRange(annotationData);

    annotationData.forEach((data: AnnotationData) => {
      // Show and add svg icon are the same size and position. Either could be used here
      const iconPosition = buildAddSvg(data.point, chart.chartArea.bottom);
      const isOnIcon = checkIfOnIcon(iconPosition, event);

      // Toggles the plus icon when the mouse is over it
      if (isOnIcon) {
        if (event.type === "mousemove") {
          handleIconHover(chart, data, canCreateAnnotation);
        } else if (event.type === "click") {
          handleIconClick(data, canCreateAnnotation);
        }
      } else {
        // This resets the add and show icon to the default state
        handleChartHover(chart, data, canCreateAnnotation);
      }
    });
  },
};
