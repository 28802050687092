import React from "react";
import Select from "react-select";
import { WindowSizeDays, IWindowSizeDays } from "./use-report-body-state";

interface WindowSizeDaysSelectProps {
  isLoading: boolean;
  windowSizeDays: IWindowSizeDays;
  onChange: (obj: any) => void;
}

export function WindowSizeDaysSelect({
  isLoading,
  windowSizeDays,
  onChange,
}: WindowSizeDaysSelectProps) {
  return (
    <Select
      isDisabled={isLoading}
      isClearable={false}
      isSearchable={false}
      value={WindowSizeDays.toReactSelect(windowSizeDays)}
      options={WindowSizeDays.reactSelectOptions}
      onChange={onChange}
    />
  );
}
