import { useSalesBreakdownConfigs } from "@components/data/sales-breakdown-configs";
import { ColumnConfig } from "@shared/metric-customization-modal/metric-category-builder";
import { logEvent } from "@utils/analytics";
import { cloneDeep, isEqual, omit } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { NewReportBodyState, useReportBodyState } from "./report-body-control";
import { TabView } from "./tab-table-section/tab-table-section";
import { useCustomSalesViews } from "./use-custom-sales-views";
import { useSessionStorage } from "usehooks-ts";

export interface SalesView {
  id: string;
  name: string;
  data: {
    filters: NewReportBodyState;
    columnConfigurations: ColumnConfig[];
    selectedMetricBoxes?: string[];
  };
}
export type SalesViewTemplate = SalesView & { isTemplate: boolean };
export type UnsavedSalesView = Omit<SalesView, "id">;
export const isTemplateView = (view: SalesView): view is SalesViewTemplate =>
  !!(view as SalesViewTemplate).isTemplate;

interface SalesViewContextType {
  currentSalesViewEdit: UnsavedSalesView | null;
  deleteSalesView: (id: string) => Promise<void>;
  salesViews: (SalesView | SalesViewTemplate)[];
  saveNewSalesView: (name: SalesView["name"]) => void;
  saveSalesViewsColumnConfigurations: (
    columnConfigurations: ColumnConfig[],
  ) => Promise<void>;
  saveSalesViewsSelectedMetricBoxes: (metricBoxes: string[]) => Promise<void>;
  selectedSalesViewId: string;
  selectSalesView: (id: string | null) => void;
  upsertSalesViewsOrdering: (ids: string[]) => Promise<void>;
}

const SalesViewContext = createContext<SalesViewContextType>(
  {} as SalesViewContextType,
);

export const SalesViewsProvider = ({ children }: { children: ReactNode }) => {
  const { state, setState } = useReportBodyState();
  const { value: breakdownConfigs } = useSalesBreakdownConfigs();
  const [currentSalesViewEdit, setCurrentSalesViewEdit] =
    useSessionStorage<UnsavedSalesView | null>("currentSalesViewEdit", null);

  const {
    salesViews,
    refetchCustomSalesViews,
    createCustomSalesView,
    deleteCustomSalesView,
    upsertSalesViewsOrdering,
    isCustomSalesViewsLoading,
  } = useCustomSalesViews();

  const saveNewSalesView = async (name: SalesView["name"]) => {
    if (!currentSalesViewEdit) {
      return;
    }

    const { data } = await createCustomSalesView(
      name,
      currentSalesViewEdit.data,
    );
    const createdView = data?.insertCustomSalesView;
    if (createdView) {
      await refetchCustomSalesViews();
      setCurrentSalesViewEdit(createdView);
      setSelectedSavedViewId(createdView.id, "pushIn");
    }
  };

  const [selectedSalesViewId, setSelectedSavedViewId] = useQueryParam(
    "savedView",
    withDefault(StringParam, ""),
  );

  const selectSalesView = useCallback(
    (savedViewId: string | null) => {
      const salesView = salesViews.find((view) => view.id === savedViewId);

      if (salesView) {
        logEvent(
          `Saved Views: User selected a ${
            isTemplateView(salesView) ? "template" : "custom"
          } view (${salesView.name})`,
        );
        // If is template view or on the platform tab and no values specified then select all values in breakdowns
        if (
          isTemplateView(salesView) ||
          salesView.data.filters.granularity === TabView.PLATFORM
        ) {
          const selectedBreakdowns = salesView.data.filters.breakdowns;
          const selectedBreakdownWithConfigs = selectedBreakdowns.map(
            (selectedBreakdown) => {
              const foundBreakdown = breakdownConfigs.find(
                (config) => config.key === selectedBreakdown.key,
              );
              if (foundBreakdown && selectedBreakdown.values.length === 0) {
                return {
                  key: foundBreakdown.key,
                  values: foundBreakdown.choices.map(({ value }) => value),
                };
              }
              return selectedBreakdown;
            },
          );

          const selectedSalesViewFilter = {
            ...salesView.data.filters,
            breakdowns: selectedBreakdownWithConfigs,
          };
          setState(selectedSalesViewFilter);
        } else {
          setState(salesView.data.filters);
        }
        setCurrentSalesViewEdit(salesView);
        setSelectedSavedViewId(savedViewId, "pushIn");
      }
    },
    [
      salesViews,
      setState,
      breakdownConfigs,
      setCurrentSalesViewEdit,
      setSelectedSavedViewId,
    ],
  );

  const saveSalesViewsColumnConfigurations = async (
    columnConfigurations: ColumnConfig[],
  ) => {
    setCurrentSalesViewEdit((prevNewSalesViewEdit) => {
      if (prevNewSalesViewEdit) {
        return {
          ...prevNewSalesViewEdit,
          data: {
            ...prevNewSalesViewEdit.data,
            columnConfigurations,
          },
        };
      }

      return {
        name: "",
        data: {
          columnConfigurations,
          metricBoxes: [],
          filters: state,
        },
      };
    });
  };

  const saveSalesViewsSelectedMetricBoxes = async (
    selectedMetricBoxes: string[],
  ) => {
    setCurrentSalesViewEdit((prevNewSalesViewEdit) => {
      if (prevNewSalesViewEdit) {
        return {
          ...prevNewSalesViewEdit,
          data: {
            ...prevNewSalesViewEdit.data,
            selectedMetricBoxes,
          },
        };
      }

      return {
        name: "",
        data: {
          columnConfigurations: [],
          selectedMetricBoxes,
          filters: state,
        },
      };
    });
  };

  // Defaults to the first saved view
  useEffect(() => {
    const foundSelectedSalesView = salesViews.find(
      ({ id }) => id === selectedSalesViewId,
    );
    if (!isCustomSalesViewsLoading) {
      if (!foundSelectedSalesView && salesViews.length > 0) {
        if (
          currentSalesViewEdit === null ||
          !isEqual(salesViews[0], currentSalesViewEdit)
        ) {
          selectSalesView(salesViews[0].id);
        }
      } else if (foundSelectedSalesView && !currentSalesViewEdit) {
        // HACK: There's a race condition with useSessionStorage and this useEffect
        setTimeout(() => setCurrentSalesViewEdit(foundSelectedSalesView), 1);
      }
    }
  }, [
    salesViews,
    setSelectedSavedViewId,
    selectedSalesViewId,
    selectSalesView,
    currentSalesViewEdit,
    isCustomSalesViewsLoading,
    setCurrentSalesViewEdit,
  ]);

  // Keeps the filters in sync with the current editing sales view
  useEffect(() => {
    setCurrentSalesViewEdit((prevCurrentSalesViewEdit) => {
      const prevCurrentSalesViewEditCopy = cloneDeep(prevCurrentSalesViewEdit);
      if (prevCurrentSalesViewEditCopy) {
        prevCurrentSalesViewEditCopy.data.filters = state;
      }
      return prevCurrentSalesViewEditCopy;
    });
  }, [state, setCurrentSalesViewEdit]);

  return (
    <SalesViewContext.Provider
      value={{
        currentSalesViewEdit,
        deleteSalesView: deleteCustomSalesView,
        salesViews,
        saveNewSalesView,
        saveSalesViewsColumnConfigurations,
        saveSalesViewsSelectedMetricBoxes,
        selectedSalesViewId,
        selectSalesView,
        upsertSalesViewsOrdering,
      }}
    >
      {children}
    </SalesViewContext.Provider>
  );
};

export const useSalesViews = () => {
  const context = useContext(SalesViewContext);
  if (context === undefined) {
    throw new Error("useSavedViews must be used within a SalesViewsProvider");
  }

  const { currentSalesViewEdit, salesViews, selectedSalesViewId } = context;

  const hasSalesViewChanges = useMemo(() => {
    const currentSelectedSalesView = salesViews?.find(
      ({ id }) => id === selectedSalesViewId,
    );

    // Compares breakdowns by iterating over breakdowns and finding a match by key.
    // If a matching breakdown is found it checks the values properties are equal or
    // if the values of the first object is empty, the function returns true, otherwise
    // it returns false. Empty means match all breakdowns
    const isBreakdownsEqual =
      currentSalesViewEdit?.data.filters.breakdowns.every((breakdownEdit) => {
        return currentSelectedSalesView?.data.filters.breakdowns.find(
          (breakdown) => {
            if (breakdown.key === breakdownEdit.key) {
              return (
                breakdown.values.length === 0 ||
                isEqual(breakdown.values, breakdownEdit.values)
              );
            }
            return false;
          },
        );
      });

    if (
      isBreakdownsEqual &&
      isEqual(
        omit(currentSelectedSalesView?.data.filters, [
          "breakdowns",
          "columnSet",
        ]),
        omit(currentSalesViewEdit?.data.filters, ["breakdowns", "columnSet"]),
      ) &&
      isEqual(
        currentSelectedSalesView?.data.columnConfigurations,
        currentSalesViewEdit?.data.columnConfigurations,
      ) &&
      isEqual(
        currentSelectedSalesView?.data.selectedMetricBoxes,
        currentSalesViewEdit?.data.selectedMetricBoxes,
      )
    ) {
      return false;
    }
    return true;
  }, [salesViews, selectedSalesViewId, currentSalesViewEdit]);

  return {
    ...context,
    hasSalesViewChanges,
    salesViews: salesViews || [],
  };
};
