import { useUser } from "@components/user-context";
import {
  CustomTreeData,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  IntegratedSummary,
  SelectionState,
  Sorting,
  SortingState,
  SummaryState,
  TreeDataState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
  TableTreeColumn,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { DataExportBuilderModal } from "@features/data-export";
import { SecondaryButton } from "@shared/buttons";
import { usePrettyLogEventPage } from "@utils/analytics";
import React, { useEffect, useMemo, useState } from "react";
import { ExportToCsvModal } from "../export-to-csv-modal";
import {
  AD_DETAILS_COLUMN_NAME,
  DIMENSION_COLUMN_NAME,
} from "../fixed-column-configs";
import { useCustomizeTable } from "../use-customize-table";
import { CustomizeTableModal } from "./customize-table-modal";
import {
  AdDetailsTypeProvider,
  DimensionTypeProvider,
  MetricTypeProvider,
} from "./data-type-providers";
import { LoadingPanel } from "./plugins";
import { summaryCalculator } from "./summary-calculator";
import {
  CustomSalesTableHeadWithFixedRow,
  TableHeaderRowCell,
  TableHeaderRowContent,
  TableHeaderRowSortLabel,
  TableSelectCell,
  TableTreeColumnCell,
  TableTreeColumnExpandButton,
  VirtualTableCell,
} from "./table-components";
import { TableSearchInput } from "./table-search-input";
import { ColumnDef, getChildRows, recursiveSort } from "./table-utils";

const DISABLED_SORTING = [
  { columnName: AD_DETAILS_COLUMN_NAME, sortingEnabled: false },
];

export const Table = ({
  tableTabs,
  rows,
  onRowLinkClick,
  metricColumnDefs,
  tableSummary,
  exportCsvModalProps,
  isLoading,
  selectedRows,
  setSelectedRows,
  showSelectionColumn,
  hideDetailsColumn,
  isPlatformView,
  searchValue,
  setSearchValue,
  salesPageMetricSpecs,
}: any) => {
  const prettyLogEventPage = usePrettyLogEventPage();
  const { user } = useUser();
  const [sortedRows, setSortedRows] = useState(rows);
  const [expandedRowIds, setExpandedRowIds] = useState<(string | number)[]>([]);
  const onBreakdownClick = (row: any) => {
    if (expandedRowIds.includes(row.key)) {
      setExpandedRowIds(expandedRowIds.filter((key) => key !== row.key));
    } else {
      setExpandedRowIds([...expandedRowIds, row.key]);
    }
  };
  const [isNewExportModalOpen, setNewExportModalOpen] = useState(false);

  const {
    columnConfigs,
    saveColumnConfigs,

    hiddenColumns,
    updateHiddenColumns,

    columnOrder,
    updateColumnOrder,

    columnWidths,
    updateColumnWidth,

    initialSortByColumn,
  } = useCustomizeTable(metricColumnDefs, {
    hideDetailsColumn,
    dimensionColumnOptions: {
      onRowLinkClick,
      isPlatformView,
      onBreakdownClick,
    },
  });

  const [sorting, setSorting] = useState<Sorting[]>([
    { columnName: initialSortByColumn, direction: "desc" },
  ]);
  useEffect(() => {
    setSorting([{ columnName: initialSortByColumn, direction: "desc" }]);
  }, [initialSortByColumn]);

  const columnNameToIdMap = useMemo(
    () =>
      metricColumnDefs.reduce((acc: any, columnDef: ColumnDef) => {
        acc[columnDef.name] = columnDef.id;
        return acc;
      }, {} as any),
    [metricColumnDefs],
  );

  useEffect(() => {
    const [{ columnName, direction }] = sorting;
    const sorted = recursiveSort(
      rows,
      columnNameToIdMap[columnName],
      direction,
    );
    setSortedRows(sorted);
  }, [sorting, columnNameToIdMap, rows, searchValue]);

  const totalSummaryItems = metricColumnDefs.map((column: ColumnDef) => ({
    columnName: column.name,
    type: column.name,
  }));

  const ToolbarRoot = ({ children }: Toolbar.RootProps) => (
    <div className="flex items-center">
      {tableTabs}
      {children}
    </div>
  );

  const FixedTopRow = useMemo(
    () => CustomSalesTableHeadWithFixedRow(tableSummary),
    [tableSummary],
  );

  const [forDimensionProvider] = useState([DIMENSION_COLUMN_NAME]);
  const [forMetricProvider] = useState(
    metricColumnDefs.map(({ name }: ColumnDef) => name),
  );
  const [forAdDetailsProvider] = useState([AD_DETAILS_COLUMN_NAME]);
  const [leftColumns] = useState([
    TableSelection.COLUMN_TYPE,
    DIMENSION_COLUMN_NAME,
    AD_DETAILS_COLUMN_NAME,
  ]);

  /**
   * TableHeaderRowCell, VirtualTableCell, CustomSalesTableHeadWithFixedRow sets a left offset CSS variable for the cells
   * belonging to the dimensionColumnDef and adDetailsColumnDef columns. The table as the parent dynamically calculates the offset
   * based on the width of the columns.
   */
  const HACK_FixedColumnFix = useMemo(() => {
    const FIXED_NAME_COLUMN_OFFSET_KEY = `--${DIMENSION_COLUMN_NAME}-offset`;
    const FIXED_AD_DETAILS_COLUMN_OFFSET_KEY = `--${AD_DETAILS_COLUMN_NAME}-offset`;

    // The default width of the name column is 450px inside use-customize-table.tsx
    let nameColumnWidth =
      columnWidths.find(
        ({ columnName }) => columnName === DIMENSION_COLUMN_NAME,
      )?.width ?? 450;
    if (typeof nameColumnWidth === "number") {
      nameColumnWidth = `${nameColumnWidth}px`;
    }

    // Selection column default width is 40px
    const offsetStart = showSelectionColumn ? 40 : 0;
    const detailsColumnOffset = `calc(${offsetStart}px + ${nameColumnWidth})`;

    return {
      [FIXED_NAME_COLUMN_OFFSET_KEY]: `${offsetStart}px`,
      [FIXED_AD_DETAILS_COLUMN_OFFSET_KEY]: `${detailsColumnOffset}`,
    } as any;
  }, [showSelectionColumn, columnWidths]);

  return (
    <div
      className="w-full z-[4]"
      style={HACK_FixedColumnFix}
      data-test="sales-table"
    >
      <div className="flex items-center justify-between">
        <TableSearchInput
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />

        <div className="flex space-x-3">
          <CustomizeTableModal
            columnConfigs={columnConfigs}
            saveColumnConfigs={saveColumnConfigs}
            updateColumnOrder={updateColumnOrder}
            updateHiddenColumns={updateHiddenColumns}
          />
          {user.featureFlags.enableDataExport && (
            <>
              <SecondaryButton
                onClick={() => {
                  prettyLogEventPage({
                    domain: "DataExport",
                    action: "onClick",
                    additionalActionInfo: "Data Export Modal opened",
                  });

                  setNewExportModalOpen(true);
                }}
              >
                Export
              </SecondaryButton>

              {isNewExportModalOpen && (
                <DataExportBuilderModal
                  isOpen={isNewExportModalOpen}
                  salesPageMetricSpecs={salesPageMetricSpecs}
                  columnConfigs={columnConfigs}
                  onClose={() => {
                    prettyLogEventPage({
                      domain: "DataExport",
                      action: "onClose",
                      additionalActionInfo: "Data Export Modal closed",
                    });

                    setNewExportModalOpen(false);
                  }}
                />
              )}
            </>
          )}
          {!user.featureFlags.enableDataExport && (
            <ExportToCsvModal
              {...exportCsvModalProps}
              columnOrder={columnOrder}
              hiddenColumns={hiddenColumns}
            />
          )}
        </div>
      </div>

      {/*
        Component ordering is important.
        https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/plugin-overview#plugin-order
      */}
      <Grid
        rows={sortedRows}
        columns={columnConfigs}
        getRowId={(row) => row.key}
      >
        <IntegratedFiltering />
        <SelectionState
          selection={selectedRows}
          onSelectionChange={setSelectedRows}
        />
        <SortingState
          sorting={sorting}
          columnExtensions={DISABLED_SORTING}
          onSortingChange={setSorting}
        />
        <IntegratedSorting />
        <TreeDataState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <CustomTreeData getChildRows={getChildRows} />
        <SummaryState totalItems={totalSummaryItems} />
        <IntegratedSelection />
        <IntegratedSummary calculator={summaryCalculator} />
        <VirtualTable
          height={650}
          headComponent={FixedTopRow}
          cellComponent={VirtualTableCell}
        />
        <TableColumnResizing
          columnWidths={columnWidths}
          columnExtensions={columnWidths}
          onColumnWidthsChange={updateColumnWidth}
        />
        <TableHeaderRow
          showSortingControls
          contentComponent={TableHeaderRowContent}
          sortLabelComponent={TableHeaderRowSortLabel}
          cellComponent={TableHeaderRowCell}
        />
        <TableSelection
          showSelectAll
          highlightRow
          cellComponent={TableSelectCell}
          selectionColumnWidth={showSelectionColumn ? 40 : 0}
        />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={updateColumnOrder}
        />
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumns}
          onHiddenColumnNamesChange={updateHiddenColumns}
        />
        {/* Custom column cells */}
        <DimensionTypeProvider for={forDimensionProvider} />
        <MetricTypeProvider for={forMetricProvider} />
        <AdDetailsTypeProvider for={forAdDetailsProvider} />

        <TableTreeColumn
          for={DIMENSION_COLUMN_NAME}
          expandButtonComponent={TableTreeColumnExpandButton}
          cellComponent={TableTreeColumnCell}
        />
        <TableFixedColumns leftColumns={leftColumns} />
        <Toolbar rootComponent={ToolbarRoot} />
        <LoadingPanel isLoading={isLoading} />
      </Grid>
    </div>
  );
};
