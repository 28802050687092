import { TertiaryButton } from "@shared/buttons";
import React from "react";

interface ConfirmDeleteSalesViewRowProps {
  deleteView: () => void;
  cancelConfirmDelete: () => void;
}

export const ConfirmDeleteSalesViewRow = ({
  deleteView,
  cancelConfirmDelete,
}: ConfirmDeleteSalesViewRowProps) => {
  return (
    <div className="bg-[#FFF4F5] p-3 rounded-md flex items-center justify-between text-sm">
      <span>Are you sure you want to delete?</span>
      <span>
        <TertiaryButton
          className="bg-transparent hover:bg-white text-[#F03738]"
          onClick={deleteView}
        >
          Yes, Delete
        </TertiaryButton>
        <TertiaryButton
          className="bg-transparent hover:bg-white text-primary"
          onClick={cancelConfirmDelete}
        >
          Cancel
        </TertiaryButton>
      </span>
    </div>
  );
};
