import styled from "@emotion/styled";

interface BalloonProps {
  color: string;
  animationDuration: string;
}

export const Balloon = styled("div")<BalloonProps>`
  display: inline-block;
  width: 120px;
  height: 145px;
  background: ${({ color }: BalloonProps) => color};
  border-radius: 80%;
  position: relative;
  box-shadow: inset -10px -10px 0 rgba(0, 0, 0, 0.07);
  margin: 20px 30px;
  margin-bottom: 200px;
  transition: transform 0.5s ease;
  z-index: 10;
  animation: balloons
    ${({ animationDuration }: BalloonProps) => animationDuration} ease-in-out
    infinite;
  transform-origin: bottom center;

  @keyframes balloons {
    0%,
    100% {
      transform: translateY(0) rotate(-4deg);
    }
    50% {
      transform: translateY(-25px) rotate(4deg);
    }
  }

  &:before {
    content: "▲";
    font-size: 20px;
    color: ${({ color }: BalloonProps) => color};
    display: block;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: -12px;
    z-index: -100;
  }

  &:after {
    display: inline-block;
    top: 149px;
    position: absolute;
    height: 150px;
    width: 1px;
    margin: 0 auto;
    content: "";
    background: rgba(0, 0, 0, 0.2);
    left: 60px;
  }
`;
