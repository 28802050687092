import { usePageTitle } from "@/atoms/page-title-atom";
import { H1, LightSwitch } from "@components/utilities";
import { LabelRuleGroup } from "@north-beam/nb-common";
import { LabelRuleNotificationSettings } from "@north-beam/nb-common";
import { jsonHash } from "@north-beam/nb-common";
import { LogOnMount } from "@utils/analytics";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export interface LabelRuleGroupListingProps {
  pagePrefix: string;
  newRulePageSuffix: string;
  ruleGroups: LabelRuleGroup[];

  // Callbacks
  onRuleGroupActiveStatusChange: (
    id: {
      level: string;
      labelKey: string;
    },
    newValue: boolean,
  ) => void;
  onRuleGroupNotificationSettingsChange: (
    id: {
      level: string;
      labelKey: string;
    },
    newValue: LabelRuleNotificationSettings,
  ) => void;

  // stateful
  anyLoading: boolean;
}

export function LabelRuleGroupListing({
  pagePrefix,
  newRulePageSuffix,
  ruleGroups,
  onRuleGroupActiveStatusChange,
  onRuleGroupNotificationSettingsChange,
  anyLoading,
}: LabelRuleGroupListingProps) {
  const [, setPageTitle] = usePageTitle();
  useEffect(() => {
    setPageTitle("Rules");
  }, [setPageTitle]);

  const tableBody =
    ruleGroups.length === 0 ? (
      <tr>
        <td colSpan={6} align="center">
          No label rule groups.{" "}
          <Link to={`${pagePrefix}/${newRulePageSuffix}`}>Create one.</Link>
        </td>
      </tr>
    ) : (
      [
        ...ruleGroups.map((ruleGroup) => {
          const {
            labelKey,
            active,
            labelRules,
            notificationSettings,
            level,
            updatedAt,
          } = ruleGroup;
          const clone: any = _.cloneDeep(ruleGroup);
          delete clone.active;
          delete clone.labelKey;

          const updatedAtReadable = moment(updatedAt).format("MMM D, YYYY");

          return (
            <tr key={jsonHash({ labelKey, level })}>
              <td align="center">
                <div className="flex justify-content-center">
                  <LightSwitch
                    size="medium"
                    disabled={anyLoading}
                    isSet={active}
                    onChange={() =>
                      onRuleGroupActiveStatusChange(
                        { labelKey, level },
                        !active,
                      )
                    }
                    id={`${level}__${labelKey}____active`}
                  />
                </div>
              </td>
              <td>
                <div>
                  <Link
                    to={`${pagePrefix}/${level}/${encodeURIComponent(
                      labelKey,
                    )}`}
                  >
                    {labelKey}
                  </Link>
                </div>
                <div>
                  <small className="text-muted">{level}</small>
                </div>
              </td>
              <td>{updatedAtReadable}</td>
              <td>{labelRules.length}</td>
              <td align="center">
                <div className="flex justify-content-center">
                  <LightSwitch
                    size="medium"
                    disabled={anyLoading}
                    isSet={notificationSettings.emailAboutMissingLabels}
                    onChange={() =>
                      onRuleGroupNotificationSettingsChange(
                        { level, labelKey },
                        {
                          ...notificationSettings,
                          emailAboutMissingLabels:
                            !notificationSettings.emailAboutMissingLabels,
                        },
                      )
                    }
                    id={labelKey + "____notification"}
                  />
                </div>
              </td>
              <td align="center">
                <Link to={`${pagePrefix}/${newRulePageSuffix}`} state={clone}>
                  <i className="fas fa-copy"></i>
                </Link>
              </td>
            </tr>
          );
        }),
        <tr key="__new__list">
          <td colSpan={6} align="center">
            <Link to={`${pagePrefix}/${newRulePageSuffix}`}>
              Create another rule
            </Link>
          </td>
        </tr>,
      ]
    );

  return (
    <div className="container pt-4">
      <LogOnMount name="Visit Automatic Label Rules Page" />
      <div className="row">
        <div className="col">
          <H1>Rules</H1>
          <p>
            Configure rules to automatically categorize your traffic sources.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th style={{ textAlign: "center" }} scope="col">
                  Active?
                </th>
                <th scope="col">Label key</th>
                <th scope="col">Last modified</th>
                <th scope="col">Number of rules</th>
                <th style={{ textAlign: "center" }} scope="col">
                  Check for 100% coverage?
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
