import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router-dom";
import { StuckToTop } from "@components/stuck-to-top";
import { ControlBar, ControlBarElement } from "@components/control-bar";
import { formatDateRangeText } from "@components/date-range-picker";
import { GenericDropdown } from "@components/dropdown";
import { ExpandableBreakdownSelector } from "@components/reports/breakdown-selector";
import { Breakdown, CategoricalBreakdownConfig } from "@north-beam/nb-common";
import { FixedDateRange } from "@north-beam/nb-common";
import { DateRangeChoiceEnum } from "@utils/constants";
import { AttributionModelSelect } from "@shared/selects";
import { BreakdownSelect } from "./breakdown-select";
import { WindowSizeDaysSelect } from "./window-size-days-select";
import { DateRangeSelect } from "@shared/selects/date-range-select";
import { PrintPageButton } from "./print-page-button";
import {
  IWindowSizeDays,
  ReportBodyState,
  WindowSizeDays,
} from "./use-report-body-state";

interface ReportBodyControlProps {
  isLoading: boolean;
  state: ReportBodyState;
  breakdownConfigs: CategoricalBreakdownConfig[];
  setFilters: (filters: Breakdown[]) => void;
  setAttributionModel: ({ value }: { value: string }) => void;
  setDateRange: (
    dateRange:
      | FixedDateRange
      | (typeof DateRangeChoiceEnum.reactSelectOptions)[0],
  ) => void;
  setWindowSizeDays: ({ value }: { value: IWindowSizeDays }) => void;
  setBreakdown: ({ value }: { value: string }) => void;
}

export function ReportBodyControl({
  isLoading,
  state,
  breakdownConfigs,
  setFilters,
  setAttributionModel,
  setDateRange,
  setWindowSizeDays,
  setBreakdown,
}: ReportBodyControlProps) {
  const { search } = useLocation();

  const { attributionModel, filters, dateRange, windowSizeDays, breakdown } =
    state;

  const dateRangeCurrent =
    typeof dateRange === "string"
      ? DateRangeChoiceEnum.toReactSelect(dateRange)
      : dateRange;

  return (
    <StuckToTop>
      {() => (
        <div className="nb-report-body-control">
          <ControlBar>
            <div className="row no-gutters container-fluid align-items-end">
              <ControlBarElement
                title="Attribution Model"
                parentClassName="col-lg col-3 mw-100 flex-grow-1 mr-2 nb-control-bar-element"
              >
                <AttributionModelSelect
                  isLoading={isLoading}
                  attributionModel={attributionModel}
                  onChange={setAttributionModel}
                />
              </ControlBarElement>
              <ControlBarElement
                title="Filters"
                parentClassName="col-lg col-3 mw-100 flex-grow-1 mr-2 nb-control-bar-element"
              >
                <ExpandableBreakdownSelector
                  current={filters}
                  onUpdate={setFilters}
                  allConfigs={breakdownConfigs}
                  available={breakdownConfigs}
                  disabled={isLoading}
                />
              </ControlBarElement>
              <ControlBarElement
                title="Breakdown"
                parentClassName="col-lg col-3 mw-100 flex-grow-1 mr-2 nb-control-bar-element"
              >
                <BreakdownSelect
                  isLoading={isLoading}
                  breakdown={breakdown}
                  breakdownConfigs={breakdownConfigs}
                  onChange={setBreakdown}
                />
              </ControlBarElement>
              <ControlBarElement
                title="Date Filter"
                parentClassName="col-lg col-3 mw-100 flex-grow-1 mr-2 nb-control-bar-element"
              >
                <GenericDropdown
                  renderButton={({ toggle }) => (
                    <button
                      className="dropdown-btn btn btn-outline-secondary dropdown-toggle h-auto"
                      disabled={isLoading}
                      type="button"
                      onClick={toggle}
                    >
                      {formatDateRangeText(dateRangeCurrent)}, by{" "}
                      {WindowSizeDays.getLabel(windowSizeDays)}
                    </button>
                  )}
                  renderList={({ isOpen }) => (
                    <div
                      className={classNames(
                        "dropdown-menu dropdown-menu-right rounded mt-2",
                        { show: isOpen },
                      )}
                      style={{
                        position: "absolute",
                        minWidth: "20rem",
                        left: 0,
                      }}
                    >
                      <ControlBarElement
                        title="Group by"
                        parentClassName="mt-2 mb-3 px-3 nb-control-bar-element"
                      >
                        <WindowSizeDaysSelect
                          isLoading={isLoading}
                          windowSizeDays={windowSizeDays}
                          onChange={setWindowSizeDays}
                        />
                      </ControlBarElement>
                      <ControlBarElement
                        title="Date range"
                        parentClassName="mb-2 px-3 nb-control-bar-element"
                      >
                        <DateRangeSelect
                          isLoading={isLoading}
                          dateRange={dateRange}
                          onUpdate={setDateRange}
                          convertDateRangeToFixed={setDateRange}
                        />
                      </ControlBarElement>
                    </div>
                  )}
                />
              </ControlBarElement>
              <PrintPageButton className="px-1" search={search} />
            </div>
          </ControlBar>
        </div>
      )}
    </StuckToTop>
  );
}
