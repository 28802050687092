import React from "react";
import northbeamHeaderLogo from "@assets/northbeam-header-logo-new.svg";
import northbeamHeaderText from "@assets/northbeam-header-text.svg";
import { useNavbarExpanded } from "@/atoms/navbar-atom";
import { usePageTitle } from "@/atoms/page-title-atom";
import { useDebounce } from "usehooks-ts";
import { Link } from "react-router-dom";
import { TopBarDivider } from "./top-bar";

export const TopBarLogoAndDynamicTitle = () => {
  const [expanded] = useNavbarExpanded();
  const [pageTitle] = usePageTitle();
  const debouncedExpanded = useDebounce(expanded, 250);

  return (
    <div className="flex items-center">
      <div className="flex-center cursor-pointer w-14 bg-white">
        <Link to="overview">
          <img src={northbeamHeaderLogo} alt="Northbeam logo" />
        </Link>
      </div>
      {debouncedExpanded || !pageTitle ? (
        <img
          className="ml-3 animate__animated animate__lightSpeedInLeft animate__faster"
          src={northbeamHeaderText}
          alt="Northbeam text"
        />
      ) : (
        <div className="animate__animated animate__fadeIn animate__fast flex items-center">
          <TopBarDivider className="mr-3" />
          <span className="text-lg">{pageTitle}</span>
        </div>
      )}
    </div>
  );
};
