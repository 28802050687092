import { useUser } from "@components/user-context";
import { DataExportSalesMetric } from "@features/data-export/types";
import {
  IMCAttributionWindowDays,
  INBAccountingMode,
  MCAttributionWindowDaysChoicesDaily,
  NBAccountingMode,
} from "@utils/constants";
import { SalesPageMetricSpec } from "@utils/metrics";
import { motion } from "framer-motion";
import { compact } from "lodash";
import React from "react";
import { Section } from "./section";

interface Props {
  metricSpec: SalesPageMetricSpec;
  metric: DataExportSalesMetric;
  onUpdateMetric(metric: DataExportSalesMetric): void;
  onClose(): void;
}

export function MetricEditor({
  metricSpec,
  metric,
  onUpdateMetric,
  onClose,
}: Props) {
  const { user } = useUser();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className="absolute h-full w-[calc(100%-18rem)] bg-white/90 flex
                 flex-col items-end z-30 overflow-hidden"
    >
      <motion.div
        animate={{ translateX: 0 }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        style={{ translateX: 100 }}
        className="h-full w-[18rem] bg-white border-l border-gray-100 overflow-hidden
                   grid grid-rows-[120px_auto]"
      >
        <button
          className="absolute top-4 right-5 z-30 transition-colors
                     duration-200 hover:text-blue-500"
          onClick={() => onClose()}
        >
          <i className="fa-regular fa-xmark text-[1.4em]" />
        </button>
        <div className="pl-7 pr-7 pt-5 relative">
          <h3 className="text-lg font-semibold m-0 mb-7">Metrics details</h3>
          <h4 className="text-lg font-light text-sm m-0">
            <span>Metric:</span>
            <span className="ml-1 text-gray-400">{metric.label}</span>
          </h4>
          {/*
            Safari fix: (Safari leaves 1px gap between grid rows,
            this absolutely position div mask this gap)
          */}
          <div className="absolute bottom-[-2px] h-[4px] w-full bg-white"></div>
        </div>
        <div className="pl-7 pr-7 overflow-auto">
          {metricSpec.modifiers.attributionModel === "all" && (
            <Section<string>
              title="Attribution model"
              options={compact(
                user.attributionMethodEnum.reactSelectOptions.map((e) => {
                  const isSelected = metric.attributionModels.has(e.value);

                  return {
                    id: e.value,
                    label: e.label,
                    isSelected,
                    isDisabled:
                      isSelected && metric.attributionModels.size === 1,
                  };
                }),
              )}
              onChange={(id, val) => {
                if (val) {
                  metric.attributionModels.add(id);
                } else {
                  metric.attributionModels.delete(id);
                }

                onUpdateMetric(metric);
              }}
              onChangeAll={(val) => {
                for (const { value } of [
                  ...user.attributionMethodEnum.reactSelectOptions,
                ].reverse()) {
                  if (val) {
                    metric.attributionModels.add(value);
                  } else if (metric.attributionModels.size > 1) {
                    metric.attributionModels.delete(value);
                  }
                }

                onUpdateMetric(metric);
              }}
            />
          )}

          {metricSpec.modifiers.attributionWindows.length > 1 && (
            <Section<IMCAttributionWindowDays>
              title="Attribution window"
              options={compact(
                metricSpec.modifiers.attributionWindows.map((e) => {
                  const option = MCAttributionWindowDaysChoicesDaily.find(
                    ({ value }) => value === e,
                  );

                  if (option) {
                    const isSelected = metric.attributionWindows.has(
                      option.value,
                    );

                    return {
                      id: option.value,
                      label: option.label,
                      isSelected,
                      isDisabled:
                        isSelected && metric.attributionWindows.size === 1,
                    };
                  } else {
                    return null;
                  }
                }),
              )}
              onChange={(id, val) => {
                if (val) {
                  metric.attributionWindows.add(id);
                } else {
                  metric.attributionWindows.delete(id);
                }

                onUpdateMetric(metric);
              }}
              onChangeAll={(val) => {
                for (const window of [
                  ...metricSpec.modifiers.attributionWindows,
                ].reverse()) {
                  if (val) {
                    metric.attributionWindows.add(window);
                  } else if (metric.attributionWindows.size > 1) {
                    metric.attributionWindows.delete(window);
                  }
                }

                onUpdateMetric(metric);
              }}
            />
          )}

          {metricSpec.modifiers.accountingModes.length > 1 && (
            <Section<INBAccountingMode>
              title="Accounting mode"
              options={compact(
                metricSpec.modifiers.accountingModes.map((e) => {
                  const option = NBAccountingMode.reactSelectOptions.find(
                    ({ value }) => value === e,
                  );

                  if (option) {
                    const isSelected = metric.accountingModes.has(option.value);

                    return {
                      id: option.value,
                      label: option.label,
                      isSelected,
                      isDisabled:
                        isSelected && metric.accountingModes.size === 1,
                    };
                  } else {
                    return null;
                  }
                }),
              )}
              onChange={(id, val) => {
                if (val) {
                  metric.accountingModes.add(id);
                } else {
                  metric.accountingModes.delete(id);
                }

                onUpdateMetric(metric);
              }}
              onChangeAll={(val) => {
                for (const mode of [
                  ...metricSpec.modifiers.accountingModes,
                ].reverse()) {
                  if (val) {
                    metric.accountingModes.add(mode);
                  } else if (metric.accountingModes.size > 1) {
                    metric.accountingModes.delete(mode);
                  }
                }

                onUpdateMetric(metric);
              }}
            />
          )}
        </div>
      </motion.div>
    </motion.div>
  );
}
