import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import React from "react";
import { DataExportSalesMetric } from "../../types";

interface SelectedMetricProps {
  metric: DataExportSalesMetric;
  isEdited: boolean;
}

export function SelectedMetric({ metric, isEdited }: SelectedMetricProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id: metric.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        "border rounded-md border-blue-300 bg-[#F5F8FE] hover:bg-[#9BBDF9]",
        "hover:border-[#9BBDF9] text-[12px] font-light pl-2 pr-2 pt-1.5 pb-1.5",
        "flex items-center",
        { "bg-[#9BBDF9] border-[#9BBDF9]": isEdited },
      )}
      style={style}
    >
      <i
        ref={setActivatorNodeRef}
        className="fa-solid fa-grip-dots text-blue-500 cursor-move mr-2"
        {...attributes}
        {...listeners}
      ></i>
      <span className="flex-1">{metric.label}</span>
    </div>
  );
}
