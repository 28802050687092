import clsx from "clsx";
import React from "react";

export function FBIcon({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        "w-7 h-7 bg-[#F3F5FF] rounded-full justify-center flex items-center",
        className,
      )}
    >
      <i className="fa-brands text-[#3B5998] text-sm fa-facebook-f"></i>
    </div>
  );
}

export function InstagramIcon({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        "w-7 h-7 bg-[#F3F5FF] rounded-full justify-center flex items-center",
        className,
      )}
    >
      <i className="fa-brands text-[#3B5998] text-sm fa-instagram"></i>
    </div>
  );
}

export function TikTokIcon({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        "w-7 h-7 bg-[#f6d1ef] rounded-full justify-center flex items-center",
        className,
      )}
    >
      <i className="fa-brands text-[#021230] text-sm fa-tiktok"></i>
    </div>
  );
}

export function SnapchatIcon({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        "w-7 h-7 bg-[#FFFBEF] rounded-full justify-center flex items-center",
        className,
      )}
    >
      <i className="fa-brands text-[#3B5998] text-sm fa-snapchat"></i>
    </div>
  );
}
