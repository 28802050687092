"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.safeSubtract = exports.safeDivide = exports.transpose = exports.vectorSum = void 0;
// This function at runtime only guarantees the required keys show up.
// It does not guarantee there are not extraneous keys.
function vectorSum(as) {
    var e_1, _a;
    var rv = {}; // TypeScript hack
    try {
        for (var as_1 = __values(as), as_1_1 = as_1.next(); !as_1_1.done; as_1_1 = as_1.next()) {
            var a = as_1_1.value;
            for (var key in a) {
                if (!(key in rv)) {
                    rv[key] = 0;
                }
                rv[key] += a[key];
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (as_1_1 && !as_1_1.done && (_a = as_1.return)) _a.call(as_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return rv;
}
exports.vectorSum = vectorSum;
function transpose(vs) {
    if (vs.length === 0) {
        return vs;
    }
    var dim1 = vs.length;
    var dim2 = vs[0].length;
    var rv = [];
    for (var i = 0; i < dim2; ++i) {
        rv.push(Array(dim1).fill(null));
    }
    for (var i = 0; i < dim2; ++i) {
        for (var j = 0; j < dim1; ++j) {
            rv[i][j] = vs[j][i];
        }
    }
    return rv;
}
exports.transpose = transpose;
function safeDivide(num, den, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.zeroOverZero, zeroOverZero = _c === void 0 ? 1 : _c, _d = _b.infOverInf, infOverInf = _d === void 0 ? 1 : _d;
    var quotient = num / den;
    if (Number.isNaN(quotient)) {
        if (num === 0 && den === 0) {
            return zeroOverZero;
        }
        if (num === Infinity && den === Infinity) {
            return infOverInf;
        }
        return 0;
    }
    return quotient;
}
exports.safeDivide = safeDivide;
function safeSubtract(lhs, rhs, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.infMinusInf, infMinusInf = _c === void 0 ? 0 : _c;
    var difference = lhs - rhs;
    if (Number.isNaN(difference)) {
        if (lhs === Infinity && rhs === Infinity) {
            return infMinusInf;
        }
        return 0;
    }
    return difference;
}
exports.safeSubtract = safeSubtract;
