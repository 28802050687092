import { TableHeaderRow } from "@devexpress/dx-react-grid-bootstrap4";
import classNames from "classnames";
import React from "react";

// This implementation is a copy of Devextreme's TableHeaderRow.SortLabel with the icons swapped out

const handleMouseDown = (e: any) => {
  e.currentTarget.style.outline = "none";
};
const handleBlur = (e: any) => {
  e.currentTarget.style.outline = "";
};

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

const onClick = (
  e: any,
  currentDirection: "asc" | "desc" | null,
  onSort: TableHeaderRow.SortLabelProps["onSort"],
) => {
  const isActionKeyDown =
    e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACE_KEY_CODE;
  const isMouseClick = e.keyCode === undefined;

  if (!(isActionKeyDown || isMouseClick)) return;

  const cancelSortingRelatedKey = e.metaKey || e.ctrlKey;
  const useDirection =
    (isMouseClick || isActionKeyDown) && cancelSortingRelatedKey ? false : true;
  const keepOther = e.shiftKey || cancelSortingRelatedKey;

  let direction: "asc" | "desc" | null = null;

  if (useDirection) {
    if (currentDirection === null || currentDirection === "asc") {
      direction = "desc";
    } else {
      direction = "asc";
    }
  }

  e.preventDefault();
  onSort({ direction, keepOther });
};

export const TableHeaderRowSortLabel = ({
  align,
  direction,
  disabled,
  children,
  onSort,
  getMessage,
  column,
  ...restProps
}: TableHeaderRow.SortLabelProps) => (
  <span
    className={classNames(
      "inline-flex flex-row items-center w-full max-w-full dx-g-bs4-user-select-none",
      "font-semibold text-sm text-[#687182]",
      {
        "flex-row-reverse": align === "right",
        "cursor-pointer": !disabled,
      },
    )}
    tabIndex={disabled ? -1 : 0}
    onMouseDown={handleMouseDown}
    onBlur={handleBlur}
    {...(!disabled
      ? {
          onKeyDown: (e) => onClick(e, direction, onSort),
          onClick: (e) => onClick(e, direction, onSort),
        }
      : null)}
    {...restProps}
  >
    {children}
    {!disabled && (
      <div className="pl-1.5 pr-1 flex flex-col">
        <i
          className={classNames(
            direction === "asc" ? "text-[#464F60]" : "text-[#A1A9B8]",
            "leading-[0px] fa-solid fa-sort-up",
          )}
        />
        <i
          className={classNames(
            direction === "desc" ? "text-[#464F60]" : "text-[#A1A9B8]",
            "leading-[0px] fa-solid fa-sort-down",
          )}
        />
      </div>
    )}
  </span>
);
