import config from "@/environment";
import northbeamHeaderLogo from "@assets/northbeam-header-logo.svg";
import Bugsnag from "@bugsnag/js";
import { Modal } from "@components/shared/modals";
import { LoadingSlide } from "@components/title-slide-view";
import { useUser } from "@components/user-context";
import { HTTP_METHOD, useLazyRestApi, useRestApi } from "@lib/hooks";
import { PrimaryButton } from "@shared/buttons";
import { Select } from "@shared/selects";
import { OptionType } from "@shared/selects/select";
import React, { useEffect, useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";

type FinalizeResponse = {
  status: string;
};

type BasicAccountInfo = {
  id: string;
  companyName: string;
};

type GetManagedAccountsResponse = {
  isAdmin: boolean;
  managedAccounts: BasicAccountInfo[];
};

const SUPPORTED_INTEGRATIONS = ["shopify"];

export default function OAuthFinalizer() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const sig = searchParams.get("sig");
  const { integration } = useParams();
  const { user } = useUser();
  const [flowComplete, setFlowComplete] = useState<boolean>(false);
  const [shouldShowAccountPicker, setShouldShowAccountPicker] =
    useState<boolean>(false);
  const [selectedCompanyForSelect, setSelectedCompanyForSelect] = useState<
    string | null
  >(null);

  const navbarColor = config.isDevelopmentEnvironment
    ? "navbar-light"
    : "navbar-dark";

  if (!integration || !SUPPORTED_INTEGRATIONS.includes(integration)) {
    throw new Error("Unsupported integration");
  }
  if (!sessionId || !sig) {
    throw new Error("Invalid session credentials");
  }

  const {
    loading: loadingExtraAccounts,
    error: extraAccountsError,
    data: extraAccountsData,
  } = useRestApi<GetManagedAccountsResponse>(`/api/user/managed-accounts`);
  const {
    loading: loadingFinalize,
    error: errorFinalize,
    data: dataFinalize,
    call: callFinalize,
  } = useLazyRestApi<FinalizeResponse>();

  useEffect(() => {
    if (!loadingExtraAccounts && !extraAccountsError && extraAccountsData) {
      if (extraAccountsData.managedAccounts.length === 0) {
        throw new Error("User does not have access to any accounts");
      }

      if (extraAccountsData.managedAccounts.length > 1) {
        setShouldShowAccountPicker(true);
      } else {
        // If only 1 account is managed, do not show account picker, send off the data with that information
        callFinalize(
          `/api/callbacks/finalize-oauth?integration=${integration}`,
          {
            method: HTTP_METHOD.POST,
            data: {
              userId: extraAccountsData.managedAccounts[0].id,
              sessionId,
              sig,
            },
          },
        );
      }
    }
  }, [
    loadingExtraAccounts,
    extraAccountsError,
    extraAccountsData,
    callFinalize,
    integration,
    sessionId,
    sig,
  ]);

  useEffect(() => {
    if (!loadingFinalize && !errorFinalize && dataFinalize) {
      setFlowComplete(true);
    }
  }, [loadingFinalize, errorFinalize, dataFinalize]);

  const finalizeOauth = () => {
    callFinalize(`/api/callbacks/finalize-oauth?integration=${integration}`, {
      method: HTTP_METHOD.POST,
      data: {
        userId: selectedCompanyForSelect,
        sessionId,
        sig,
      },
    });
  };

  const renderFinalizeRedirect = () => {
    if (user.activatedAt || user.defaultDisplayUserId) {
      return <Navigate to={`/${user.displayUserId}/settings/connections`} />;
    } else {
      return <Navigate to="/" />;
    }
  };

  const renderLoading = () => <LoadingSlide />;

  const handleError = () => {
    const error = extraAccountsError || errorFinalize || "Page State Error";
    Bugsnag.notify(`Unable to complete OAuth flow: ${error.toString()}`);
    throw error;
  };

  const getCompanyDataForSelect = () =>
    extraAccountsData?.managedAccounts.map(
      (account): OptionType => ({
        label: account.companyName,
        value: account.id,
      }),
    );

  if (extraAccountsError || errorFinalize) {
    handleError();
    return null;
  }

  if (loadingExtraAccounts || loadingFinalize) {
    return renderLoading();
  }

  if (flowComplete) {
    return renderFinalizeRedirect();
  }

  return (
    <>
      <div
        className={`col p-0 h-100 color-white flex flex-column justify-content-between vertical-navbar ${navbarColor} z-navbar`}
      ></div>
      <div
        className={`w-100 horizontal-navbar position-fixed flex justify-content-between ${navbarColor} z-navbar`}
      >
        <img
          src={northbeamHeaderLogo}
          alt="Northbeam header"
          className="northbeam-header-logo"
        />
      </div>
      <Modal
        isOpen={shouldShowAccountPicker}
        height="20rem"
        width="30rem"
        maxWidth="1200px"
      >
        <p className="font-bold text-lg mb-4">
          Select the company you&apos;re connecting
        </p>
        <Select
          isDisabled={false}
          isClearable={false}
          isSearchable={true}
          value={selectedCompanyForSelect}
          options={getCompanyDataForSelect()}
          onChange={setSelectedCompanyForSelect}
          width="100%"
          styles={{
            menuList: (baseStyles) => ({
              ...baseStyles,
              height: "180px",
            }),
          }}
        />
        <PrimaryButton className="mt-4" onClick={finalizeOauth}>
          Connect
        </PrimaryButton>
      </Modal>
    </>
  );
}
