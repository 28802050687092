import classNames from "classnames";
import React, { ReactNode } from "react";
import { Button, ButtonProps } from "./button";

interface IconButtonProps extends ButtonProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export const IconButton = ({
  className,
  children,
  onClick,
  isDisabled,
  size,
  leftIcon,
  rightIcon,
  ...restProps
}: IconButtonProps) => {
  return (
    <Button
      className={classNames("icon-button", className)}
      isDisabled={isDisabled}
      onClick={onClick}
      size={size}
      {...restProps}
    >
      {leftIcon && <span className="mr-1">{leftIcon}</span>}
      {children}
      {rightIcon && <span className="ml-1">{rightIcon}</span>}
    </Button>
  );
};
