import { useCallback, useState } from "react";

export const useDOMNode = (): [
  HTMLElement | null,
  (ref: HTMLElement | null) => void,
] => {
  const [domNode, setDOMNode] = useState<HTMLElement | null>(null);

  const callback = useCallback((ref) => {
    if (!ref) return;
    setDOMNode(ref);
  }, []);

  return [domNode, callback];
};
