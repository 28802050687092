import { GenericTooltip } from "@components/tooltip-container";
import Spinner from "@shared/spinner";
import classNames from "classnames";
import React, { ButtonHTMLAttributes } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<void> | void;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "primary-v2" | "secondary" | "tertiary";
  tooltip?: string;
  loading?: boolean;
}

export const Button = ({
  className,
  children,
  onClick,
  isDisabled,
  size = "md",
  type = "button",
  variant,
  tooltip,
  loading,
  ...restProps
}: ButtonProps) => {
  const buttonRender = (
    <button
      className={classNames(
        className,
        "button",
        {
          sm: size === "sm",
          md: size === "md",
          lg: size === "lg",
          "cursor-not-allowed": isDisabled,
          "opacity-50": isDisabled,
        },
        variant && `${variant}-button`,
      )}
      // This fixes a tooltip bug where the tooltip would not disappear if the button was disabled
      // https://github.com/react-component/tooltip/issues/18
      style={{ pointerEvents: isDisabled ? "none" : "auto" }}
      disabled={isDisabled || loading}
      onClick={onClick}
      type={type}
      {...restProps}
    >
      {variant === "primary-v2" && (
        <>
          {loading && (
            <Spinner colorClassname="text-white-400 w-[13px] h-[13px] mr-2" />
          )}
          {children}
        </>
      )}
      {variant !== "primary-v2" && (
        <>
          {loading && <Spinner colorClassname="text-white-400" />}
          {!loading && children}
        </>
      )}
    </button>
  );

  if (tooltip) {
    return (
      <GenericTooltip content={tooltip} noInfoCircle>
        {buttonRender}
      </GenericTooltip>
    );
  }

  return buttonRender;
};
