import React, { useMemo } from "react";
import Select, { components } from "react-select";
import { toReactSelectLabel } from "@utils/index";
import { CategoricalBreakdownConfig } from "@north-beam/nb-common";

interface BreakdownSelectProps {
  className?: string;
  isLoading: boolean;
  breakdown: string;
  breakdownConfigs: CategoricalBreakdownConfig[];
  onChange: (obj: any) => void;
}

export function BreakdownSelect({
  className,
  isLoading,
  breakdown,
  breakdownConfigs,
  onChange,
}: BreakdownSelectProps) {
  const breakdownOptions = useMemo(
    () =>
      breakdownConfigs.map((v) => ({
        value: v.key,
        label: v.name,
      })),
    [breakdownConfigs],
  );

  return (
    <Select
      className={className}
      isDisabled={isLoading}
      isClearable={false}
      isSearchable={false}
      value={toReactSelectLabel(breakdown)}
      options={breakdownOptions}
      placeholder="Add breakdown..."
      components={{
        DropdownIndicator: (props: any) => (
          <components.DropdownIndicator {...props}>
            <i className="fas fa-chart-pie"></i>
          </components.DropdownIndicator>
        ),
      }}
      onChange={onChange}
    />
  );
}
