import classNames from "classnames";
import React from "react";
import { Link, useParams } from "react-router-dom";

interface LabelRoute {
  label: string | React.ReactElement;
  route: string;
  adminOnly?: boolean;
}
interface PageNavigatorProps {
  title: string;
  orderedLabelRoutes: LabelRoute[];
}

export const PageNavigator = ({
  title,
  orderedLabelRoutes,
}: PageNavigatorProps) => {
  const params = useParams();
  const isSectionActive = (route: string) =>
    Object.values(params).includes(route);

  return (
    <ul className="list-group">
      <li className="list-group-item">
        <b>{title}</b>
      </li>
      {orderedLabelRoutes.map((labelRoute) => {
        return (
          <Link
            to={`${labelRoute.route}`}
            className="no-link-style"
            key={labelRoute.route}
          >
            <li
              className={classNames(
                "list-group-item list-group-item-action " +
                  (isSectionActive(labelRoute.route) && "active-section"),
              )}
            >
              {labelRoute.label}
              {labelRoute.adminOnly && (
                <span className="m-2">
                  <i className="fa-solid fa-user"></i>
                </span>
              )}
            </li>
          </Link>
        );
      })}
    </ul>
  );
};
