import { GenericTooltip } from "@components/tooltip-container";
import { IconContainer } from "@pages/objects/utils";
import classNames from "classnames";
import LockIcon from "@assets/lock.svg";
import NoDataIcon from "@assets/nodata-icon.svg";
import React from "react";

const Notification = ({
  text,
  icon,
  className,
  placement = "topRight",
  tooltip,
}: {
  text: string;
  icon: React.ReactNode;
  className?: string;
  placement?: "topLeft" | "topRight";
  tooltip: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(
        "absolute flex text-sm items-center top-[1rem]  font-semibold rounded-lg px-2",
        className,
        placement === "topRight" ? "right-[1.5rem]" : "left-[1.5rem]",
      )}
    >
      {icon}
      <span>{text}</span>
      <GenericTooltip
        className="mb-5 self-center"
        content={<div className="max-w-[25rem]">{tooltip}</div>}
      />
    </div>
  );
};
export const LockedNotification = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => {
  return (
    <Notification
      text={text}
      icon={
        <IconContainer sizeInEM={1.5} style={{ marginRight: "0.25em" }}>
          <img className="cursor-pointer" alt="lock icon" src={LockIcon} />
        </IconContainer>
      }
      tooltip={
        <>
          <p>
            You can set one-day media buying benchmarks once statistically
            significant is available.
          </p>
          <p>
            Statistically significant data is defined as consistent spending and
            revenue attribution for a given platform observed over a minimum of
            two cycles of full attribution.
          </p>
        </>
      }
      className={className}
    />
  );
};

export const NoDataNotification = ({
  placement,
  className,
}: {
  placement?: "topLeft" | "topRight";
  className?: string;
}) => {
  return (
    <Notification
      text={"Not enough data to show"}
      icon={
        <IconContainer sizeInEM={1.5} style={{ marginRight: "0.25em" }}>
          <img className="cursor-pointer" alt="no-data icon" src={NoDataIcon} />
        </IconContainer>
      }
      className={classNames("bg-[#F5F5F5] text-[#717680]", className)}
      placement={placement}
      tooltip={
        "Consistent spending and attributed revenue are required to calculate your 1-day benchmarks for this platform."
      }
    />
  );
};
