import { LoadingSlide, TitleSlide } from "@components/title-slide-view";
import { H1 } from "@components/utilities";
import {
  AlertConfigHistory,
  AlertConfigHistoryVariables,
} from "@nb-api-graphql-generated/AlertConfigHistory";
import { shiftISODate, todayLocalAsISODate } from "@north-beam/nb-common";
import { LogOnMount } from "@utils/analytics";
import { useNorthbeamQuery } from "@utils/hooks";
import React from "react";
import { ALERT_CONFIG_HISTORY } from "../queries";
import { CollapsibleIncidentPreview } from "./incident-views";

export type AlertIncidentsPaneProps = Partial<InnerProps>;

export function AlertIncidentsPane({ id }: AlertIncidentsPaneProps) {
  if (!id) {
    return <TitleSlide>No alert to inspect</TitleSlide>;
  }
  return <Inner id={id} />;
}

interface InnerProps {
  id: string;
}

function Inner({ id }: InnerProps) {
  const end = todayLocalAsISODate();
  const start = shiftISODate(end, -LOOKBACK_DAYS);

  const { loading, data } = useNorthbeamQuery<
    AlertConfigHistory,
    AlertConfigHistoryVariables
  >(ALERT_CONFIG_HISTORY, {
    variables: { id, runAt_after: start },
  });

  const config = data?.me?.alertConfig;

  if (loading) {
    return <LoadingSlide />;
  }

  if (!config) {
    return (
      <TitleSlide>
        <p className="text-center">Not found :(</p>
      </TitleSlide>
    );
  }

  const incidents = config.incidents;
  const openIncidents: typeof incidents = [];
  const closedIncidents: typeof incidents = [];
  const snoozedIncidents: typeof incidents = [];

  for (const incident of incidents) {
    let bucket = openIncidents;
    if (incident.status === "closed") {
      bucket = closedIncidents;
    } else if (incident.status === "snoozed") {
      bucket = snoozedIncidents;
    }
    bucket.push(incident);
  }

  return (
    <div className="container pt-3">
      <LogOnMount
        name="Views alert incidents tab"
        data={{
          alertConfigId: config.id,
        }}
      />
      <div className="row">
        <div className="col">
          <H1>Open</H1>
          {openIncidents.length === 0 ? (
            <p>
              No open incidents. Hooray!{" "}
              <span role="img" aria-label="hooray">
                🙌
              </span>
            </p>
          ) : (
            openIncidents.map((v) => (
              <CollapsibleIncidentPreview data={v} key={v.id} />
            ))
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <H1>Snoozed</H1>
          {snoozedIncidents.length === 0 ? (
            <p>No snoozed incidents.</p>
          ) : (
            snoozedIncidents.map((v) => (
              <CollapsibleIncidentPreview data={v} key={v.id} />
            ))
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <H1>Closed</H1>
          {closedIncidents.length === 0 ? (
            <p>
              No incidents that were closed in the last {LOOKBACK_DAYS} days.
            </p>
          ) : (
            closedIncidents.map((v) => (
              <CollapsibleIncidentPreview data={v} key={v.id} />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

const LOOKBACK_DAYS = 14;
