import { FormattedDateRange } from "@components/date-range-picker";
import { IconButton } from "@shared/buttons";
import React from "react";
import { useAnnotationParam } from "../hooks/use-annotation-param";

export const AnnotationFilterNotification = () => {
  const {
    filteredAnnotationDateRangeParam,
    removeFilteredDateRangeAnnotations,
  } = useAnnotationParam();

  if (!filteredAnnotationDateRangeParam) {
    return null;
  }

  return (
    <div className="bg-[#EBF6FD] h-8 font-semibold text-sm rounded-md flex items-center space-between mt-5">
      <div className="bg-[#34A3E1] h-8 w-7 rounded-bl-md rounded-tl-md mr-2 flex-center">
        <i className="fa-solid fa-circle-info text-white" />
      </div>
      <div className="flex-grow">
        Filtered by selected group:{" "}
        <FormattedDateRange dateRange={filteredAnnotationDateRangeParam} />
      </div>
      <IconButton className="mr-1" onClick={removeFilteredDateRangeAnnotations} data-testid="clear-date-filter">
        <i className="fa-solid fa-xmark" />
      </IconButton>
    </div>
  );
};
