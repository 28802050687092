import createAuth0Client, {
  Auth0ClientOptions,
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  IdToken,
  LogoutOptions,
  PopupLoginOptions,
  RedirectLoginResult,
} from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";

import React, { useContext, useEffect, useState } from "react";
import { bugsnagClient } from "@utils/analytics";

interface IAuth0Context {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup(options: PopupLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken | undefined>;
  loginWithRedirect(): Promise<void>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): void;
}
interface Auth0ProviderOptions {
  children: React.ReactElement;
  onRedirectCallback?(result: RedirectLoginResult): void;
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<IAuth0Context | null>(null);
export const useAuth0 = () => useContext(Auth0Context)!;
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (e) {
          setLoading(false);
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = React.useCallback(
    async (o: PopupLoginOptions) => {
      setPopupOpen(true);
      try {
        await auth0Client!.loginWithPopup(o);
      } catch (error) {
        bugsnagClient.notify(error as Error);
      } finally {
        setPopupOpen(false);
      }
      const user = await auth0Client!.getUser();
      setUser(user);
      setIsAuthenticated(true);
    },
    [auth0Client],
  );

  const handleRedirectCallback = React.useCallback(async () => {
    setLoading(true);
    const result = await auth0Client!.handleRedirectCallback();
    const user = await auth0Client!.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
    return result;
  }, [auth0Client]);

  const getIdTokenClaims = React.useCallback(
    (o: getIdTokenClaimsOptions | undefined) =>
      auth0Client!.getIdTokenClaims(o),
    [auth0Client],
  );
  const loginWithRedirect = React.useCallback(async () => {
    const currentPath = window.location.pathname;
    await auth0Client!.loginWithRedirect({
      appState: { targetUrl: currentPath },
    });
  }, [auth0Client]);
  const getTokenSilently = React.useCallback(
    (o: GetTokenSilentlyOptions | undefined) =>
      auth0Client!.getTokenSilently(o),
    [auth0Client],
  );
  const getTokenWithPopup = React.useCallback(
    (o: GetTokenWithPopupOptions | undefined) =>
      auth0Client!.getTokenWithPopup(o),
    [auth0Client],
  );
  const logout = React.useCallback(
    (o: LogoutOptions | undefined) => auth0Client!.logout(o),
    [auth0Client],
  );

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,

        getIdTokenClaims,
        loginWithRedirect,
        getTokenSilently,
        getTokenWithPopup,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
