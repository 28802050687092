import { gql } from "@apollo/client";

export const CAMPAIGN_SEARCH = gql`
  query CampaignSearch($query: String!) {
    me {
      id
      campaigns(autoCompletePrefix: $query) {
        id
        adKey
        name
        nbtPlatformID
      }
    }
  }
`;
