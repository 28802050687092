import { Granularity, IGranularity } from "@/utils/constants";
import ChangeFractionDisplay from "@components/change-fraction";
import {
  AugmentedFactColumn,
  RenderCellProps,
} from "@components/reports/virtualized-table";
import { HTMLTooltip } from "@components/tooltip-container";
import { User } from "@components/user-context";
import { Breakdown } from "@north-beam/nb-common";
import { jsonHash } from "@north-beam/nb-common";
import { AdIcon } from "@pages/objects/utils";
import { SalesPageMetric } from "@utils/metrics";
import React from "react";
import { Link } from "react-router-dom";

export const createDimensionColumns = (
  user: User,
  breakdowns: Breakdown[],
  granularity: IGranularity,
): AugmentedFactColumn => {
  const headerBase = Granularity.getLabel(granularity);
  return {
    // For some reason, updating the renderHeader function was not
    // enough to get the header to update when clicked, so we also
    // update the name of the column.
    name: headerBase,
    key: "Name",
    isExpandable: breakdowns.length > 0,
    renderCell: ({ row, column }: RenderCellProps) => {
      const maxWidth = `calc(${column.width}px - ${
        (row.rowMetadata?.level ?? 0) + 3
      }em)`;
      let inner = (
        <div
          className="text-truncate"
          style={{
            maxWidth,
          }}
        >
          {row.display.Name}
        </div>
      );
      if (row.rowMetadata.platformId) {
        inner = (
          <>
            <AdIcon sizeInEM={1} platform={row.rowMetadata.platformId} />
            {inner}
          </>
        );
      }

      inner = <div className="flex items-center">{inner}</div>;

      if (row.link.Name) {
        inner = <Link to={user.pathFromRoot(row.link.Name)}>{inner}</Link>;
      }

      if (row.rowMetadata.extraDetails?.length > 0) {
        inner = (
          <>
            <div>{inner}</div>
            <div className="text-muted text-truncate">
              {row.rowMetadata.extraDetails.map(({ name, value }: any) => (
                <small key={jsonHash({ name, value })}>
                  <b>{name}</b>: {value}
                  &nbsp;&nbsp;
                </small>
              ))}
            </div>
          </>
        );
      }
      return <div title={row.display.Name}>{inner}</div>;
    },
  };
};

export const createFactColumns = (metricsArray: SalesPageMetric[]) =>
  metricsArray
    .filter((v) => !v.hideFromTableDisplay)
    .map((metric) => {
      const rv: AugmentedFactColumn = {
        name: metric.name,
        key: metric.id,
        headerTooltip: metric.descriptionHTML,
        comparison: {
          isPositiveChangeGood: metric.isPositiveChangeGood,
        },
        renderCell: (props) => {
          let changeFractionArea = <small>&nbsp;</small>;
          if (
            metric.hideComparisonFromTableCell &&
            metric.changeFractionAreaTextForTable
          ) {
            changeFractionArea = (
              <small className="text-muted">
                {metric.changeFractionAreaTextForTable}
              </small>
            );
          } else if (!metric.hideComparisonFromTableCell) {
            changeFractionArea = (
              <ChangeFractionDisplay
                changeFraction={props.row.changeFractions[metric.id]}
                isPositiveChangeGood={metric.isPositiveChangeGood}
                muted={true}
              />
            );
          }

          let mainDisplay: any = props.row.display[metric.id];
          const cellHoverHTML = props.row.cellHoverHTML[props.column.key];
          if (cellHoverHTML) {
            mainDisplay = (
              <HTMLTooltip
                html={cellHoverHTML}
                noInfoCircle
                mouseEnterDelay={0.1}
              >
                <div className="nb-cell-hover">
                  <u>{mainDisplay}</u>
                </div>
              </HTMLTooltip>
            );
          }

          return (
            <div className="flex flex-column pr-2 pl-1">
              <div>{mainDisplay}</div>
              <div className="flex-grow-1">{changeFractionArea}</div>
            </div>
          );
        },
      };

      if (metric.TableCell) {
        const TableCell = metric.TableCell;
        rv.renderCell = ({ row }) => {
          return <TableCell point={row.rowMetadata.point} />;
        };
      }

      if (metric.hideComparisonFromTableCell) {
        delete rv.comparison;
      }

      return rv;
    });
