import {
  CustomerPathFilterStage,
  PathDateRangeFilterType,
} from "@nb-api-graphql-generated/global-types";
import { FixedDateRange } from "@north-beam/nb-common";
import { DateRangeChoiceV2, parseDateRange } from "@utils/constants";
import { enumType } from "@utils/enum-type";

export interface ReportState {
  readonly dateRange: DateRangeChoiceV2 | FixedDateRange;
  readonly showConvertingPathsOnly: boolean;
  readonly pathDateRangeFilterType: PathDateRangeFilterType;
  readonly pathLengthMinimum: number | null;
  readonly pathLengthMaximum: number | null;
  readonly pathFilterStages: CustomerPathFilterStage[];
  readonly limit: number;
}

export const PathDateRangeFilterType_ = enumType({
  enumObject: PathDateRangeFilterType,
  keyOrder: [
    PathDateRangeFilterType.INTERSECTS_DATE_RANGE,
    PathDateRangeFilterType.STARTED_WITHIN_DATE_RANGE,
    PathDateRangeFilterType.ENDED_WITHIN_DATE_RANGE,
    PathDateRangeFilterType.STARTED_AND_ENDED_WITHIN_DATE_RANGE,
  ],
  labels: {
    [PathDateRangeFilterType.INTERSECTS_DATE_RANGE]:
      "that intersect in time with...",
    [PathDateRangeFilterType.STARTED_WITHIN_DATE_RANGE]:
      "that start between...",
    [PathDateRangeFilterType.STARTED_AND_ENDED_WITHIN_DATE_RANGE]:
      "that start and end between...",
    [PathDateRangeFilterType.ENDED_WITHIN_DATE_RANGE]: "that end between...",
  },
});

export function parseReportState(query: string): ReportState {
  const params = new URLSearchParams(query);
  const pathFilterStages = params.get("pathFilterStages") ?? "[]";
  return {
    dateRange: parseDateRange(params.get("dateRange")) ?? "last28Days",
    showConvertingPathsOnly: params.get("showConvertingPathsOnly") === "true",
    pathDateRangeFilterType:
      PathDateRangeFilterType_.fromString(
        params.get("pathDateRangeFilterType"),
      ) ?? PathDateRangeFilterType.INTERSECTS_DATE_RANGE,
    pathLengthMaximum: parseInt(params.get("pathLengthMaximum") ?? "") || null,
    pathLengthMinimum: parseInt(params.get("pathLengthMinimum") ?? "") || null,
    pathFilterStages: JSON.parse(pathFilterStages),
    limit: parseInt(params.get("limit") ?? "100") || 100,
  };
}

export function makeReportStateParams(state: ReportState) {
  const params = new URLSearchParams();
  params.set("dateRange", JSON.stringify(state.dateRange));
  params.set("showConvertingPathsOnly", String(state.showConvertingPathsOnly));
  params.set("pathDateRangeFilterType", state.pathDateRangeFilterType);
  params.set(
    "pathLengthMaximum",
    state.pathLengthMaximum ? String(state.pathLengthMaximum) : "",
  );
  params.set(
    "pathLengthMinimum",
    state.pathLengthMinimum ? String(state.pathLengthMinimum) : "",
  );
  params.set("pathFilterStages", JSON.stringify(state.pathFilterStages));
  params.set("limit", String(state.limit));
  return params;
}
