import { CardDetails } from "@shared/card-details";
import { FetchOrder_me_order } from "@nb-api-graphql-generated/FetchOrder";
import { CustomerTypeFriendlyNames } from "@/constants/customer-type-friendly-names";
import React, { useMemo } from "react";
import { emptyText } from "@/constants/empty-text";
import { prefs } from "@utils/client-side-preferences";
import {
  generateAnonEmailIfNecessary,
  generateAnonBase64Hash,
} from "@utils/anonymous";
import { compact } from "lodash";

export function CustomerDetails({ order }: { order: FetchOrder_me_order }) {
  const isAnon = prefs.isDemoMode();

  const shippingInfo: string[] = useMemo(() => {
    return order.shippingAddress1
      ? compact([
          order.shippingAddress1,
          order.shippingAddress2,
          `${order.shippingCity}${
            order.shippingProvince ? `, ${order.shippingProvince}` : ""
          }${order.shippingZip ? ` ${order.shippingZip}` : ""}${
            order.shippingCountry ? `, ${order.shippingCountry}` : ""
          }`,
        ])
      : [emptyText];
  }, [order]);

  return (
    <CardDetails
      title="Customer Details"
      className="mb-5"
      details={[
        {
          heading: "Name",
          items: [
            generateAnonBase64Hash({
              value: order.customerFirstName?.trim(),
              prefix: "customer",
              doAnon: isAnon,
            }) ?? emptyText,
          ],
        },
        {
          heading: "Email",
          items: [
            generateAnonEmailIfNecessary(order.customerEmail?.trim(), isAnon) ??
              emptyText,
          ],
        },
        {
          heading: "Type",
          items: [
            order.orderType
              ? `${
                  CustomerTypeFriendlyNames[order.orderType.trim()] ||
                  order.orderType.trim()
                } purchase`
              : emptyText,
          ],
        },
        {
          heading: "Address",
          items: isAnon
            ? [
                generateAnonBase64Hash({
                  value: order.shippingAddress1,
                  prefix: "address",
                  doAnon: isAnon,
                }) || emptyText,
              ]
            : shippingInfo,
        },
        {
          heading: "Phone",
          items: isAnon
            ? [
                generateAnonBase64Hash({
                  value: order.customerPhone,
                  prefix: "phone",
                  doAnon: isAnon,
                }) || emptyText,
              ]
            : [order.customerPhone || emptyText],
        },
      ]}
    />
  );
}
