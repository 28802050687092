import Pen from "@assets/pen.svg";
import VectorDown from "@assets/vector-down.svg";
import VectorUp from "@assets/vector-up.svg";
import { SecondaryButton } from "@shared/buttons";
import classNames from "classnames";
import React, { PropsWithChildren, useMemo, useState } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import Select from "react-select";
import { BenchmarkModal, CustomBenchmarksFlowModal } from "./benchmark-modal";
import LockIcon from "@assets/lock.svg";
import UnlockIcon from "@assets/unlock.svg";

import {
  BenchmarkAttributionModelEnum,
  BenchmarkAttributionWindowEnum,
  BenchmarkPlatformEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";

import { BenchmarkCard } from "./benchmark-card";

import {
  BenchmarkAttributionWindowOptions,
  BenchmarkAttributionWindowValues,
  BenchmarkPlatformFriendlyNames,
  PrettyBenchmarkAttributionModelNames,
} from "@/constants/benchmarking";
import {
  useBenchmarks,
  useGetBenchmarkPlatformsToUse,
} from "@hooks/use-platform-benchmarks";
import { LoadingSpinner } from "@components/title-slide-view";
import { StringParam, useQueryParam } from "use-query-params";
import {
  BenchmarkModalNavigationRoute,
  BenchmarkModalProvider,
  HasCompleteDatasetByAttrWindow,
  useHasCompleteDatasetByAttrWindowAtom,
  useUsableBenchmarkWindowsAtom,
} from "@/atoms/benchmarking-atom";
import { Toggle } from "@shared/toggle";
import { Dictionary } from "ts-essentials";
import {
  FetchBenchmarkSettings,
  FetchBenchmarkSettingsVariables,
  FetchBenchmarkSettings_me_benchmarks_benchmarkSettings_customBenchmarks,
} from "@nb-api-graphql-generated/FetchBenchmarkSettings";
import { useNorthbeamQuery } from "@utils/hooks";
import {
  FETCH_BENCHMARK_SETTINGS,
  FETCH_USABLE_BENCHMARK_ATTRIBUTION_WINDOWS,
} from "@gql/benchmarks";
import { GenericTooltip } from "@components/tooltip-container";
import { IconContainer } from "@pages/objects/utils";
import { FetchUsableBenchmarkAttributionWindows } from "@nb-api-graphql-generated/FetchUsableBenchmarkAttributionWindows";
import { BenchmarkAvailabilityCard } from "./benchmark-availability-card";

import {
  useBenchmarkAttributionWindow,
  useShowBenchmarks,
  useShowNBCalculatedBenchmarks,
} from "@hooks/benchmarks";
import { prettyLogEvent } from "@utils/analytics";
import { compact, flatten, isEmpty } from "lodash";
import { emptyText } from "@/constants/empty-text";
import { getPrettyBenchmark } from "@utils/benchmarking";

const LoadingBenchmarks = () => {
  return (
    <div className="relative">
      <LoadingSpinner />
      <PlaceholderBenchmarks />
    </div>
  );
};

const PlaceholderBenchmarks = () => {
  return (
    <div className={classNames("mt-5 grid gap-4 grid-cols-3 w-full relative")}>
      <BenchmarkCard
        title={BenchmarkPlatformFriendlyNames[BenchmarkPlatformEnum.adwords]}
        platform={BenchmarkPlatformEnum.adwords}
        cac={null}
        roas={null}
        loading={false}
        ignoreNoData
        target={emptyText}
        benchmarkType={BenchmarkTypeEnum.CAC}
        attributionWindow={BenchmarkAttributionWindowEnum.THIRTY_DAYS}
      />
      <BenchmarkCard
        title={BenchmarkPlatformFriendlyNames[BenchmarkPlatformEnum.facebook]}
        platform={BenchmarkPlatformEnum.facebook}
        cac={null}
        roas={null}
        loading={false}
        ignoreNoData
        target={emptyText}
        benchmarkType={BenchmarkTypeEnum.CAC}
        attributionWindow={BenchmarkAttributionWindowEnum.THIRTY_DAYS}
      />
      <BenchmarkCard
        title={BenchmarkPlatformFriendlyNames[BenchmarkPlatformEnum.youtube]}
        platform={BenchmarkPlatformEnum.youtube}
        cac={null}
        roas={null}
        loading={false}
        ignoreNoData
        target={emptyText}
        benchmarkType={BenchmarkTypeEnum.CAC}
        attributionWindow={BenchmarkAttributionWindowEnum.THIRTY_DAYS}
      />
    </div>
  );
};

export const BenchmarkSettingSectionInnerWrapper = ({
  children,
}: PropsWithChildren<unknown>) => {
  return <div className={classNames("w-full")}>{children}</div>;
};

export const NoBenchmarkSettingsSection = ({
  openModal,
  currentAttributionWindowOption,
  setCurrentAttributionWindowOption,
  available,
}: {
  available: boolean;
  openModal: () => void;
  currentAttributionWindowOption: (typeof BenchmarkAttributionWindowOptions)[number];
  setCurrentAttributionWindowOption: (
    option: (typeof BenchmarkAttributionWindowOptions)[number],
  ) => void;
}) => {
  return (
    <BenchmarkSettingSectionInnerWrapper>
      <div className="flex items-center mt-2">
        No benchmark set for:
        <Select
          options={BenchmarkAttributionWindowOptions}
          value={currentAttributionWindowOption}
          isMulti={false}
          onChange={(option) => {
            if (option) {
              setCurrentAttributionWindowOption(option);
              prettyLogEvent({
                page: "Sales",
                domain: "Benchmarking",
                action: "onSelect",
                additionalActionInfo: "no-benchmarks-selector",
                data: { option: option.value },
              });
            }
          }}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            container: (styles) => ({
              ...styles,
              width: "8rem",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
            }),
          }}
        />
      </div>
      <BenchmarkAvailabilityCard available={available} onContinue={openModal} />
    </BenchmarkSettingSectionInnerWrapper>
  );
};

export const BenchmarkSettingSectionInner = ({
  attributionModel,
  openModal,
  currentAttributionWindowOption,
  loading: outerSectionLoading,
  setCurrentAttributionWindowOption,
}: {
  loading: boolean;
  attributionModel: BenchmarkAttributionModelEnum;
  openModal: () => void;
  currentAttributionWindowOption: (typeof BenchmarkAttributionWindowOptions)[number];
  setCurrentAttributionWindowOption: (
    attributionWindow: (typeof BenchmarkAttributionWindowOptions)[number],
  ) => void;
}) => {
  const [showNBCalculatedBenchmarks, setShowNBCalculatedBenchmarks] =
    useShowNBCalculatedBenchmarks();
  const platformsToUse = useGetBenchmarkPlatformsToUse();

  const memoizedAttributionModels = useMemo(() => {
    return [attributionModel];
  }, [attributionModel]);

  const [usabledAttributionWindows] = useUsableBenchmarkWindowsAtom();
  const [hasCompleteDatasetByAttrWindow] =
    useHasCompleteDatasetByAttrWindowAtom();
  const available = Boolean(
    usabledAttributionWindows?.has(currentAttributionWindowOption.value),
  );

  const {
    benchmarkSettings,
    benchmarkSettingsLoading,
    platformBenchmarksLoading,
    orderedData,
    customBenchmarks,
  } = useBenchmarks(
    attributionModel,
    currentAttributionWindowOption.value,
    platformsToUse,
    memoizedAttributionModels,
  );

  const customBenmarksByPlatform = useMemo(() => {
    if (!customBenchmarks) return undefined;
    const benchmarksByPlatform: Dictionary<FetchBenchmarkSettings_me_benchmarks_benchmarkSettings_customBenchmarks> =
      {};
    customBenchmarks.forEach((customBenchmark) => {
      if (customBenchmark.attributionModel === attributionModel) {
        benchmarksByPlatform[customBenchmark.platform] = customBenchmark;
      }
    });
    return benchmarksByPlatform;
  }, [attributionModel, customBenchmarks]);

  if (
    benchmarkSettingsLoading ||
    platformBenchmarksLoading ||
    outerSectionLoading
  )
    return <LoadingBenchmarks />;

  const isNotFullDataset =
    !hasCompleteDatasetByAttrWindow[currentAttributionWindowOption.value];
  if (!available || isEmpty(benchmarkSettings)) {
    return (
      <NoBenchmarkSettingsSection
        available={available}
        openModal={openModal}
        currentAttributionWindowOption={currentAttributionWindowOption}
        setCurrentAttributionWindowOption={setCurrentAttributionWindowOption}
      />
    );
  }

  return (
    <BenchmarkSettingSectionInnerWrapper>
      <div className="flex xl:items-center xl:flex-row lg:items-left lg:flex-col">
        <div className="flex items-center mt-2">
          <span className="whitespace-nowrap">
            I want to view my 1-day{" "}
            {PrettyBenchmarkAttributionModelNames[
              attributionModel
            ].toLowerCase()}{" "}
            CAC and ROAS goals based on my
          </span>

          <span>
            <Select
              options={BenchmarkAttributionWindowOptions}
              value={currentAttributionWindowOption}
              isMulti={false}
              onChange={(option) => {
                option && setCurrentAttributionWindowOption(option);
                prettyLogEvent({
                  page: "Sales",
                  domain: "Benchmarking",
                  action: "onSelect",
                  additionalActionInfo: "section-attributionWindow",
                  data: { attributionModel },
                });
              }}
              components={{ IndicatorSeparator: () => null }}
              styles={{
                container: (styles) => ({
                  ...styles,
                  width: "8rem",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                  zIndex: 2,
                }),
              }}
            />
          </span>
          <span className="whitespace-nowrap">
            platform targets{isNotFullDataset && "*"}
            <GenericTooltip
              content={
                <div className="w-[25rem]">
                  One-day benchmarks are calculated by taking the median
                  percentage lift in revenue from a one-day attribution window
                  to a thirty, sixty, or ninety-day attribution window.
                </div>
              }
              overlayClass=""
            />
          </span>
        </div>
      </div>

      <div className="flex flex-col mt-5">
        {customBenchmarks && (
          <div className="self-end text-sm flex items-center">
            <div className="mr-2">See Northbeam calculated benchmarks</div>
            <Toggle
              checked={showNBCalculatedBenchmarks}
              toggle={() => {
                setShowNBCalculatedBenchmarks(!showNBCalculatedBenchmarks);
              }}
            />
          </div>
        )}
        <div
          className={classNames("mt-2 grid gap-4 grid-cols-3 w-full relative")}
        >
          {orderedData.map((platformBenchmark) => {
            const {
              cac,
              roas,
              platform,
              attributionModel,
              target,
              benchmarkType,
            } = platformBenchmark;
            return (
              <BenchmarkCard
                attributionWindow={currentAttributionWindowOption.value}
                key={`${platform}-${attributionModel}`}
                title={`${
                  BenchmarkPlatformFriendlyNames[platform]
                } - 1-day ${PrettyBenchmarkAttributionModelNames[
                  attributionModel
                ].toLowerCase()} benchmarks`}
                platform={platform}
                target={getPrettyBenchmark(benchmarkType, target)}
                benchmarkType={benchmarkType}
                cac={customBenmarksByPlatform?.[platform].cac ?? cac}
                roas={customBenmarksByPlatform?.[platform].roas ?? roas}
                loading={platformBenchmarksLoading}
                nbCalculatedBenchmarks={platformBenchmark}
                showNbCalculatedBenchmarks={Boolean(
                  customBenchmarks && showNBCalculatedBenchmarks,
                )}
              />
            );
          })}
        </div>
        {isNotFullDataset && (
          <div className="font-light italic mt-3 text-sm ml-auto">
            * Missing complete dataset
          </div>
        )}
      </div>
    </BenchmarkSettingSectionInnerWrapper>
  );
};

const QueryParamAttributionWindowToAttrModelMapping: {
  [key: string]: BenchmarkAttributionModelEnum;
} = {
  northbeam_custom: BenchmarkAttributionModelEnum.CLICKS_ONLY,
  northbeam_custom__va: BenchmarkAttributionModelEnum.CLICKS_AND_VIEWS,
};

const BenchmarksHeader = ({
  attributionModel,
}: {
  attributionModel: BenchmarkAttributionModelEnum;
}) => (
  <span className="mr-3 font-bold">
    {PrettyBenchmarkAttributionModelNames[attributionModel]} 1 Day Media Buying
    Benchmark{" "}
    <GenericTooltip
      content={
        <div className="w-[25rem]">
          One-day media buying benchmarks are directional targets for daily
          optimization calculated by measuring the incremental lift in revenue
          associated with any single day&apos;s spend from a one-day attribution
          window to a thirty, sixty, or ninety day attribution window.
        </div>
      }
      overlayClass=""
    />
  </span>
);

const BenchmarksAvailable = ({
  onEdit,
  collapsed,
  setCollapsed,
  loading,
  attributionModel,
  currentAttributionWindowOption,
  hasBenchmarkSettings,
}: {
  hasBenchmarkSettings: boolean;
  onEdit: () => void;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  loading: boolean;
  attributionModel: BenchmarkAttributionModelEnum;
  currentAttributionWindowOption: (typeof BenchmarkAttributionWindowOptions)[number];
}) => {
  const [queryParamAttributionModel] = useQueryParam(
    "attributionModel",
    StringParam,
  );
  const [usabledAttributionWindows] = useUsableBenchmarkWindowsAtom();

  const available = Boolean(
    usabledAttributionWindows?.has(currentAttributionWindowOption.value),
  );
  return (
    <div className="flex wrap items-center">
      <BenchmarksHeader attributionModel={attributionModel} />

      <SecondaryButton
        className="flex wrap items-center"
        size="sm"
        onClick={() => {
          onEdit();
          prettyLogEvent({
            page: "Sales",
            domain: "Benchmarking",
            action: "onClick",
            additionalActionInfo: "openBenchmarkingModal",
            data: { attributionModel },
          });
        }}
        isDisabled={loading}
      >
        <img className="w-3 mr-1" alt="edit pen" src={Pen} />
        <span>
          Edit{" "}
          {
            PrettyBenchmarkAttributionModelNames[
              QueryParamAttributionWindowToAttrModelMapping[
                queryParamAttributionModel ?? ""
              ]
            ]
          }
        </span>
      </SecondaryButton>
      <div className="ml-auto flex items-center">
        {!hasBenchmarkSettings && !loading && (
          <div className="flex bg-[#E0EBFD] text-primary w-fit px-3 py-2 rounded-lg mr-2">
            <IconContainer sizeInEM={1.5} style={{ marginRight: "0.3em" }}>
              <img
                className="cursor-pointer"
                alt="available-icon"
                src={available ? UnlockIcon : LockIcon}
              />
            </IconContainer>
            <div>
              {available ? "Now available" : "Not available"}{" "}
              <GenericTooltip
                content={
                  <div className="w-[25rem]">
                    {available
                      ? `Click 'Edit ${PrettyBenchmarkAttributionModelNames[attributionModel]}' button to set up your benchmarks`
                      : `Benchmarks require statistically significant data in order to be accurately calculated. You will need consistent spending and revenue attribution for a given platform, which can typically be gathered from ${
                          BenchmarkAttributionWindowValues[
                            currentAttributionWindowOption.value
                          ] * 2
                        } days of use.`}
                  </div>
                }
                overlayClass=""
              />
            </div>
          </div>
        )}
        <img
          onClick={() => {
            setCollapsed(!collapsed);
            prettyLogEvent({
              page: "Sales",
              domain: "Benchmarking",
              action: "onClick",
              additionalActionInfo: !collapsed ? "opened" : "closed",
            });
          }}
          className="w-4 ml-3 mr-1 cursor-pointer"
          alt="edit pen"
          src={collapsed ? VectorUp : VectorDown}
        />
      </div>
    </div>
  );
};

const NoBenchmarksAvailable = ({
  attributionModel,
}: {
  attributionModel: BenchmarkAttributionModelEnum;
}) => {
  return (
    <div className="flex wrap items-center">
      <BenchmarksHeader attributionModel={attributionModel} />
      <span>
        is currently only supported in the attribution models: “Clicks” and
        “Clicks and Views”
      </span>
      <SecondaryButton className="ml-4">Learn more</SecondaryButton>
    </div>
  );
};

export const BenchmarkSettings = () => {
  const [queryParamAttributionModel] = useQueryParam(
    "attributionModel",
    StringParam,
  );
  const attributionModel =
    QueryParamAttributionWindowToAttrModelMapping[
      queryParamAttributionModel ?? ""
    ];
  if (!attributionModel) {
    return (
      <div
        className={classNames(
          "relative p-4 mb-9 rounded-md elevation-1 min-h-[3rem] bg-white",
        )}
      >
        <NoBenchmarksAvailable attributionModel={attributionModel} />
      </div>
    );
  }
  return <BenchmarkSettingSection attributionModel={attributionModel} />;
};

export const BenchmarkSettingSection = ({
  attributionModel,
}: {
  attributionModel: BenchmarkAttributionModelEnum;
}) => {
  const [, setUsableBenchmarkWindows] = useUsableBenchmarkWindowsAtom();
  const [, setHasCompleteDatasetByAttrWindow] =
    useHasCompleteDatasetByAttrWindowAtom();
  const [collapsed, setCollapsed] = useShowBenchmarks();
  const [modalIsOpen, setModalOpen] = useState(false);

  const [initialModalNavigation, setInitialModalNavigation] =
    useState<BenchmarkModalNavigationRoute | null>(null);

  const [currentAttributionWindowOption, setCurrentAttributionWindowOption] =
    useBenchmarkAttributionWindow();

  const {
    data: currentBenchmarkSettings,
    loading: loadingCurrentBenchmarksSettings,
  } = useNorthbeamQuery<
    FetchBenchmarkSettings,
    FetchBenchmarkSettingsVariables
  >(FETCH_BENCHMARK_SETTINGS, {
    variables: {
      attributionWindow: currentAttributionWindowOption.value,
      attributionModel,
    },
  });

  const customBenchmarks = compact(
    flatten(
      currentBenchmarkSettings?.me.benchmarks.benchmarkSettings?.map(
        (setting) => setting.customBenchmarks,
      ) || [],
    ),
  );

  const { loading: loadingUsableAttributionWindows } =
    useNorthbeamQuery<FetchUsableBenchmarkAttributionWindows>(
      FETCH_USABLE_BENCHMARK_ATTRIBUTION_WINDOWS,
      {
        variables: {
          attributionModel,
        },
        onCompleted(data) {
          const hasCompleteDataset: HasCompleteDatasetByAttrWindow = {};
          const usableAttributionWindows =
            data.me.benchmarks.usableAttributionWindows.map((attrWindow) => {
              // during the mapping process, add to the `hasCompleteDataset`
              hasCompleteDataset[attrWindow.attributionWindow] =
                attrWindow.hasCompleteDataset;
              return attrWindow.attributionWindow;
            });

          setHasCompleteDatasetByAttrWindow(hasCompleteDataset);
          setUsableBenchmarkWindows(new Set(usableAttributionWindows));
        },
      },
    );

  const loading =
    loadingUsableAttributionWindows || loadingCurrentBenchmarksSettings;

  return (
    <div
      className={classNames(
        "relative p-4 mb-9 mt-9 rounded-md elevation-1 min-h-[3rem] bg-white",
      )}
    >
      {modalIsOpen && (
        <BenchmarkModalProvider>
          {initialModalNavigation === "review-with-custom-benchmarks" ? (
            <CustomBenchmarksFlowModal
              closeModal={() => setModalOpen(false)}
              initialModalNavigation={initialModalNavigation}
              setInitialModalNavigation={setInitialModalNavigation}
              attributionModel={attributionModel}
            />
          ) : (
            <BenchmarkModal
              closeModal={() => setModalOpen(false)}
              attributionModel={attributionModel}
            />
          )}
        </BenchmarkModalProvider>
      )}

      <BenchmarksAvailable
        onEdit={() => {
          if (customBenchmarks.length > 0)
            setInitialModalNavigation("review-with-custom-benchmarks");
          else setInitialModalNavigation(null);
          setModalOpen(true);
        }}
        hasBenchmarkSettings={Boolean(
          currentBenchmarkSettings?.me.benchmarks.benchmarkSettings,
        )}
        currentAttributionWindowOption={currentAttributionWindowOption}
        loading={loading}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        attributionModel={attributionModel}
      />

      {!collapsed && (
        <BenchmarkSettingSectionInner
          attributionModel={attributionModel}
          loading={loading}
          openModal={() => setModalOpen(true)}
          setCurrentAttributionWindowOption={setCurrentAttributionWindowOption}
          currentAttributionWindowOption={currentAttributionWindowOption}
        />
      )}
    </div>
  );
};
