import { BenchmarkPlatformFriendlyNames } from "@/constants/benchmarking";
import { GenericTooltip } from "@components/tooltip-container";
import {
  BenchmarkPlatformEnum,
  BenchmarkTypeEnum,
} from "@nb-api-graphql-generated/global-types";
import { AdIcon } from "@pages/objects/utils";
import { getPrettyBenchmark } from "@utils/benchmarking";
import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Control, UseFormRegister, useFormState } from "react-hook-form";
import {
  BenchmarksByPlatform,
  PlatformBenchmarks,
} from "./custom-benchmarks-modal";
import { NoDataNotification } from "./notification";

const TableCell = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={classNames("table-cell text-center  relative", className)}>
    {children}
  </div>
);
export const HeaderTableCell = ({
  children,
  missingBorder,
}: PropsWithChildren<{ missingBorder?: boolean }>) => (
  <TableCell
    className={classNames(
      "font-semibold py-1 rounded-xl bg-[#FCFCFF] text-[#717680]",
      missingBorder ? "" : "border-r-[1px]",
    )}
  >
    {children}
  </TableCell>
);
export const BodyTableCell = ({
  children,
  missingBorder,
}: PropsWithChildren<{ missingBorder?: boolean }>) => (
  <TableCell
    className={classNames(
      "py-1",
      missingBorder ? "" : "border-r-[1px] items-start",
    )}
  >
    {children}
  </TableCell>
);

type TableCellInput = {
  missingBorder?: boolean;
  hasError?: boolean;
  cellRegistration: Parameters<UseFormRegister<BenchmarksByPlatform>>[0];
  register: UseFormRegister<BenchmarksByPlatform>;
  inputClassname?: string;
};
export const CacBodyTableCellInput = ({
  missingBorder,
  hasError,
  cellRegistration,
  register,
}: TableCellInput) => {
  return (
    <TableCell className={classNames(missingBorder ? "" : "border-r-[1px] ")}>
      <div className="left-2 top-1 absolute">$</div>
      <input
        {...register(cellRegistration)}
        className={classNames(
          "w-full pl-6 h-full focus:border focus:outline-none focus:border-[#2684FF] focus:ring-1 focus:ring-[#2684FF]",
          hasError ? "bg-error placeholder:text-black" : "",
        )}
        placeholder="--.-"
        autoComplete="off"
      />
    </TableCell>
  );
};

export const RoasBodyTableCellInput = ({
  missingBorder,
  hasError,
  register,
  cellRegistration,
  inputClassname,
}: TableCellInput) => (
  <TableCell className={classNames(missingBorder ? "" : "border-r-[1px] ")}>
    <input
      {...register(cellRegistration)}
      className={classNames(
        "w-full pl-6 h-full  focus:border focus:outline-none focus:border-[#2684FF] focus:ring-1 focus:ring-[#2684FF]",
        hasError ? "bg-error placeholder:text-black" : "",
        inputClassname,
      )}
      placeholder="--.-"
      autoComplete="off"
    />
  </TableCell>
);

export type BenchmarkReviewCardProps = {
  platform: BenchmarkPlatformEnum;
  cac: PlatformBenchmarks["cac"];
  roas: PlatformBenchmarks["roas"];
  label: string | null;
  loading: boolean;
  ignoreNotEnoughData?: boolean;
  neverGreyOut?: boolean;
  benchmarkType: BenchmarkTypeEnum;
  benchmarkTarget: number | null;
};

export const BenchmarkReviewCardSkeleton = ({
  platform,
  cac,
  roas,
  label,
  loading,
  ignoreNotEnoughData,
  neverGreyOut,
  children,
  benchmarkTarget,
  benchmarkType,
}: PropsWithChildren<BenchmarkReviewCardProps>) => {
  const noData =
    !loading &&
    !cac?.blended &&
    !cac?.returning &&
    !cac?.firstTime &&
    !roas?.blended &&
    !roas?.returning &&
    !roas?.firstTime;

  return (
    <div
      className={classNames(
        "relative mt-2 mb-3 flex flex-col",
        label ? "h-[9rem]" : "h-[6.5rem]",
      )}
    >
      {!loading && noData && !ignoreNotEnoughData && (
        <NoDataNotification placement="topLeft" className="z-[2002]" />
      )}
      {label && (
        <div className="text-end mb-1">
          {
            <span className="rounded-2xl p-2 text-xs bg-[#F0F8FF] text-primary">
              {label}
            </span>
          }
        </div>
      )}
      <div
        className={classNames(
          { "opacity-25": !loading && noData && !neverGreyOut },
          "flex mt-2 mb-2 items-center  border rounded-xl relative grow",
        )}
      >
        <div className="flex flex-col items-center justify-center h-full border-r-[1px]  w-[10rem] ">
          <AdIcon sizeInEM={2} platform={platform} style={{ marginRight: 0 }} />
          <div className={classNames("font-bold mt-3")}>
            {BenchmarkPlatformFriendlyNames[platform]}
          </div>
          <div className="text-xs truncate max-w-[11rem] px-3 text-subtitle">
            {benchmarkType} Target:{" "}
            {getPrettyBenchmark(benchmarkType, benchmarkTarget)}
          </div>
        </div>

        <div
          className={classNames(
            `table table-fixed border-collapse w-full mb-0 h-full grow`,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const Headers = () => (
  <div className="table-header-group rounded-lg  ">
    <div className="table-row border-b-[1px]">
      <HeaderTableCell>
        Metric{" "}
        <GenericTooltip content="The unit for measuring your performance over time" />
      </HeaderTableCell>
      <HeaderTableCell>
        Blended{" "}
        <GenericTooltip content="Weighted average of first-time and returning attribution" />
      </HeaderTableCell>
      <HeaderTableCell>
        First Time{" "}
        <GenericTooltip content="Measurement for first-time customer attribution" />
      </HeaderTableCell>
      <HeaderTableCell missingBorder>
        Returning{" "}
        <GenericTooltip content="Measurement for returning customer attribution" />
      </HeaderTableCell>
    </div>
  </div>
);

export const BenchmarkReviewCard = (props: BenchmarkReviewCardProps) => {
  const { cac, roas } = props;
  return (
    <BenchmarkReviewCardSkeleton {...props}>
      <Headers />
      <div className="table-row-group border-b-[1px]">
        <BodyTableCell>CAC</BodyTableCell>
        <BodyTableCell>
          {getPrettyBenchmark(BenchmarkTypeEnum.CAC, cac?.blended)}
        </BodyTableCell>
        <BodyTableCell>
          {getPrettyBenchmark(BenchmarkTypeEnum.CAC, cac?.firstTime)}
        </BodyTableCell>
        <BodyTableCell missingBorder>
          {getPrettyBenchmark(BenchmarkTypeEnum.CAC, cac?.returning)}
        </BodyTableCell>
      </div>
      <div className="table-row-group">
        <BodyTableCell>ROAS</BodyTableCell>
        <BodyTableCell>
          {getPrettyBenchmark(BenchmarkTypeEnum.ROAS, roas?.blended)}
        </BodyTableCell>
        <BodyTableCell>
          {getPrettyBenchmark(BenchmarkTypeEnum.ROAS, roas?.firstTime)}
        </BodyTableCell>
        <BodyTableCell missingBorder>
          {getPrettyBenchmark(BenchmarkTypeEnum.ROAS, roas?.returning)}
        </BodyTableCell>
      </div>
    </BenchmarkReviewCardSkeleton>
  );
};

export const EditableBenchmarkReviewCard = ({
  control,
  register,
  index,
  ...otherProps
}: Omit<BenchmarkReviewCardProps, "fake"> & {
  control: Control<BenchmarksByPlatform>;
  register: UseFormRegister<BenchmarksByPlatform>;
  index: number;
}) => {
  const { platform } = otherProps;

  const { errors } = useFormState({ control });

  return (
    <BenchmarkReviewCardSkeleton {...otherProps}>
      <Headers />

      <div className="table-row-group border-b-[1px]">
        <BodyTableCell>CAC</BodyTableCell>

        <CacBodyTableCellInput
          hasError={!!errors?.[platform]?.[index]?.cac?.blended}
          {...register(`${platform}.${index}.cac.blended`)}
          cellRegistration={`${platform}.${index}.cac.blended`}
          register={register}
        />

        <CacBodyTableCellInput
          hasError={!!errors?.[platform]?.[index]?.cac?.firstTime}
          register={register}
          cellRegistration={`${platform}.${index}.cac.firstTime`}
        />

        <CacBodyTableCellInput
          missingBorder
          hasError={!!errors?.[platform]?.[index]?.cac?.returning}
          register={register}
          cellRegistration={`${platform}.${index}.cac.returning`}
        />
      </div>
      <div className="table-row-group">
        <BodyTableCell>ROAS</BodyTableCell>
        <RoasBodyTableCellInput
          hasError={!!errors?.[platform]?.[index]?.roas?.blended}
          register={register}
          cellRegistration={`${platform}.${index}.roas.blended`}
        />

        <RoasBodyTableCellInput
          hasError={!!errors?.[platform]?.[index]?.roas?.firstTime}
          register={register}
          cellRegistration={`${platform}.${index}.roas.firstTime`}
        />

        <RoasBodyTableCellInput
          missingBorder
          hasError={!!errors?.[platform]?.[index]?.roas?.returning}
          register={register}
          cellRegistration={`${platform}.${index}.roas.returning`}
          inputClassname={"rounded-br-[0.8rem]"}
        />
      </div>
    </BenchmarkReviewCardSkeleton>
  );
};
