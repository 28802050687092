import DebugCodeBlock from "@components/DebugCodeBlock";
import React from "react";
import ExpandedTextAd from "./ExpandedTextAd";
import PrettyDisplayAttributes from "./PrettyDisplayAttributes";

export default function MicrosoftAd({ microsoftAd: ad }: { microsoftAd: any }) {
  if (ad.Type === "DynamicSearch") {
    return (
      <ExpandedTextAd
        headlinePart1="{Dynamically generated headline}"
        headlinePart2={null}
        headlinePart3={null}
        description={ad.Text}
        description2={ad.TextPart2}
        path1={ad.Path1}
        path2={ad.Path2}
        domain={ad.Domain}
      />
    );
  } else if (ad.Type === "ExpandedText") {
    return (
      <ExpandedTextAd
        description={ad.Text}
        description2={ad.TextPart2}
        headlinePart1={ad.TitlePart1}
        headlinePart2={ad.TitlePart2}
        headlinePart3={ad.TitlePart3}
        path1={ad.Path1}
        path2={ad.Path2}
        domain={ad.Domain}
      />
    );
  } else if (ad.Type === "Product") {
    return (
      <PrettyDisplayAttributes
        attributes={[
          { key: "Type", value: ad["Type"] },
          { key: "ID", value: ad.Id },
        ]}
      />
    );
  }
  return <DebugCodeBlock data={ad} style={{ minWidth: "480px" }} />;
}
