import { PrimaryButton, TertiaryButton } from "@shared/buttons";
import { Modal } from "@shared/modals";
import { notifyError, notifySuccess } from "@shared/notify";
import { TextInput } from "@shared/text-input";
import { maxCharacterLength, uniqueFromList } from "@shared/validators";
import { logEvent } from "@utils/analytics";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import loadingScreen from "@assets/loading-screen-no-text.gif";
import { useSalesViews } from "../sales-views-context";

interface SaveNewSalesViewModalProps {
  isOpen: boolean;
  close: () => void;
}

export const SaveNewSalesViewModal = ({
  isOpen,
  close,
}: SaveNewSalesViewModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { saveNewSalesView, salesViews } = useSalesViews();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ name: string }>();

  const saveView = async ({ name }: { name: string }) => {
    logEvent("Saved Views: User clicked Save on new sales view modal");
    try {
      setIsLoading(true);
      await saveNewSalesView(name);
      reset();
      close();
      notifySuccess({ message: "Saved view created successfully" });
    } catch (error) {
      console.error(error);
      notifyError({ message: "Failed to create saved view" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal title="Save current page selections as a new view?" testId="save-new-sales-view-modal" isOpen={isOpen}>
      <form onSubmit={handleSubmit(saveView)}>
        <div className="py-6">
          <TextInput
            className="w-1/2"
            label="Saved view name"
            isDisabled={isLoading}
            placeholder="My new saved view"
            {...register("name", {
              required: "Name is required",
              validate: {
                maxCharacterLength: maxCharacterLength({ maxLength: 40 }),
                uniqueFromList: uniqueFromList({
                  compareValues: salesViews.map(({ name }) => name),
                  errorMessage: "Name must be unique",
                }),
              },
            })}
            errors={errors}
          />
        </div>
        {isLoading && (
          <div className="absolute flex-center h-full w-full top-0 left-0 bg-[rgba(200,200,200,0.5)]">
            <img
              src={loadingScreen}
              alt="Loading screen gif"
              className="w-3/4 max-w-xl"
            />
          </div>
        )}
        <div className="flex space-x-4">
          <PrimaryButton isDisabled={isLoading} type="submit">
            Save
          </PrimaryButton>
          <TertiaryButton isDisabled={isLoading} onClick={close}>
            Cancel
          </TertiaryButton>
        </div>
      </form>
    </Modal>
  );
};
