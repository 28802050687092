import React, { FC } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { HistoricalDataCSVUploader } from "./historical-data-csv-uploader";
import appsList from "./images/appsList.png";
import enquireDownloadScreen from "./images/enquireDownloadScreen.png";
import enquireSplashPage from "./images/enquireSplashPage.png";
import shopifyMainPage from "./images/shopifyMainPage.png";

export const UploadHistoricalData: FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  previousStep,
  nextStep,
  isActive,
}) => {
  return (
    <div className={(!isActive && "d-none") || ""}>
      <button className="btn btn-secondary mb-4" onClick={previousStep}>
        Back to setting up Shopify Flow
      </button>
      <h3>
        <b>Step {currentStep}: Upload historical data</b>
      </h3>
      <ol>
        <li>
          If you don&apos;t have any historical data, skip this step and
          continue to{" "}
          <button className="btn pl-0 pr-0 pt-0 btn-link" onClick={nextStep}>
            setting the attribution question id
          </button>
          .
        </li>
        <li>
          Go to your Shopify store page and click on <b>Apps</b>.
          <ul>
            <li>
              If you have multiple Shopify stores, please repeat these
              instructions for each store.
            </li>
          </ul>
        </li>
        <img
          className="img-fluid mb-4 mt-3"
          src={shopifyMainPage}
          alt="Shopify main page screencap"
        />
        <li>
          Choose <b>Enquire Post-Purchase Surveys</b> from the list.
        </li>
        <img
          className="img-fluid mb-4 mt-3"
          src={appsList}
          alt="Shopify apps list screencap"
        />
        <li>
          Click on the <b>Analytics</b> button for your attribution question.
          The text of this question often looks like &ldquo;Where&apos;d you
          first hear about us?&rdquo; or &ldquo;What brought you to our
          store?&rdquo;{" "}
          <ul>
            <li>
              If you have multiple attribution questions, please repeat the
              following steps for each question.
            </li>
          </ul>
        </li>
        <img
          className="img-fluid mb-4 mt-3"
          src={enquireSplashPage}
          alt="Shopify Enquire app splash page screencap"
        />
        <li>
          Click on the <b>Responses</b> tab.
        </li>
        <li className="mt-3">
          Set the time frame to <b>All time</b> and the response filter to{" "}
          <b>All</b>. Then, click on <b>Export Responses</b>.
        </li>
        <img
          className="img-fluid mb-4 mt-3"
          src={enquireDownloadScreen}
          alt="Enquire export data downloads form screencap"
        />
        <li>
          Click <b>Download</b>
        </li>
        <li className="mt-3">
          <p>
            Upload the CSV here. If you have multiple Shopify Stores, you can
            upload multiple files.
          </p>
          <HistoricalDataCSVUploader />
        </li>
      </ol>
    </div>
  );
};
