import classNames from "classnames";
import React from "react";

export default function Icon({
  type,
  className = null,
  ...props
}: {
  type: string;
  className?: string | null;
}) {
  return (
    <i className={classNames("fas", `fa-${type}`, className)} {...props} />
  );
}

export { Icon };
