import { AxiosError } from "axios";
import lscache from "lscache";
import { join } from "path";
import {
  DateInterval,
  FixedDateRange,
  formatDate,
  parseDateRange,
} from "@north-beam/nb-common";
import { dateRangeChoiceToValue, DateRangeChoiceV2 } from "./constants";

export function extractError(e: any): any {
  if (e.isAxiosError) {
    if (e.response) {
      if (e.response.data) {
        return e.response.data;
      } else {
        return e.response;
      }
    } else {
      return e;
    }
  } else {
    return e;
  }
}

export async function withCache<T>(
  key: string,
  expirationMinutes: number,
  thunk: () => Promise<T>,
): Promise<T> {
  lscache.flushExpired();
  let response = lscache.get(key);
  if (!response) {
    response = await thunk();
    lscache.set(key, response, expirationMinutes);
  }
  return response;
}

export function isAxiosError(e: any): e is AxiosError {
  return e && e.isAxiosError;
}

export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}

export function arrayMove<T>(array: T[], from: number, to: number) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

export function resolvePath(path: string): string {
  return new URL(path, window.location.href).pathname;
}

export function relativeToRoot(pathFromRoot: string): string {
  const root = absolutePathPrefix(window.location.pathname, 1);
  return join(root, pathFromRoot);
}

export function absolutePathPrefix(path: string, numSegments: number): string {
  if (!path.startsWith("/")) {
    throw new Error(`${path} is not an absolute path`);
  }

  return path
    .split("/")
    .slice(0, numSegments + 1)
    .join("/");
}

export function parseDateRangeArgument(
  arg: DateRangeChoiceV2 | FixedDateRange,
  anchor: string,
): DateInterval {
  const asRange = typeof arg === "string" ? dateRangeChoiceToValue(arg) : arg;
  return parseDateRange(asRange, anchor);
}

export interface ReactSelectOption<T extends string> {
  value: T;
  label: string;
}

export function toReactSelectLabel<T extends string>(
  value: T | null | undefined,
): ReactSelectOption<T> | null {
  if (typeof value === "undefined" || value === null) {
    return null;
  }
  return { value, label: value };
}

export function toReactSelectLabelMulti<T extends string>(
  values: readonly (T | null | undefined)[],
): ReactSelectOption<T>[] {
  return values.map(toReactSelectLabel).filter((v) => v) as any;
}

export function mergeLocationSearchParams(
  search: string,
  newParams: URLSearchParams,
): string {
  const current = new URLSearchParams(search);
  for (const [key, value] of newParams.entries()) {
    current.set(key, value);
  }
  return current.toString();
}

export function defer<T>(func: () => T | Promise<T>): Promise<T> {
  return new Promise((resolve) => resolve(func()));
}

export function fmtDateInterval(v: DateInterval) {
  if (v.startDate === v.endDate) {
    return formatDate(v.startDate);
  }
  return `${formatDate(v.startDate)} — ${formatDate(v.endDate)}`;
}
