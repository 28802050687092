export const DIMENSION_COLUMN_NAME = "Name";
// Since the fixed dimension column is unchangeable we don't show it in the customize modal
export const dimensionColumnDef = {
  name: DIMENSION_COLUMN_NAME,
  id: DIMENSION_COLUMN_NAME,
  title: DIMENSION_COLUMN_NAME,
  width: 450,
  visible: true,
  getCellValue: (row: any) => row.display?.Name,
};

// Hidden column for searching on additional values
export const EXTRA_DETAILS_COLUMN_NAME = "extraDetails";
export const extraDetailsColumnDef = {
  name: EXTRA_DETAILS_COLUMN_NAME,
  width: 0,
  visible: false,
  getCellValue: (row: any) =>
    JSON.stringify(row?.rowMetadata?.extraDetails ?? ""),
};

// Hidden column for searching on additional values
export const AD_DETAILS_COLUMN_NAME = "Details";
export const adDetailsColumnDef = {
  name: AD_DETAILS_COLUMN_NAME,
  width: 73,
  visible: true,
};
