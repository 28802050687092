import { SalesViewTemplate } from "../../sales-views-context";

export const siteTraffic: SalesViewTemplate = {
  id: "site_traffic",
  name: "Site Traffic",
  isTemplate: true,
  data: {
    filters: {
      attributionModel: "northbeam_custom",
      attributionWindow: "1",
      nbAccountingMode: "accrual",
      granularity: "platform",
      timeGranularity: "daily",
      dateRange: "last7Days",
      compareDateRange: "lastPeriod",
      breakdowns: [
        {
          key: "Platform (Northbeam)",
          values: [],
        },
      ],
    },
    columnConfigurations: [
      {
        name: "Name",
        width: 450,
        visible: true,
      },
      {
        name: "Spend",
        width: 180,
        visible: true,
      },
      {
        name: "New Visits",
        width: 120,
        visible: true,
      },
      {
        name: "Visits",
        width: 120,
        visible: true,
      },
      {
        name: "% New Visits",
        width: 120,
        visible: true,
      },
      {
        name: "eCPNV",
        width: 120,
        visible: true,
      },
      {
        name: "LTV ROAS",
        width: 120,
        visible: true,
      },
      {
        name: "LTV CAC",
        width: 120,
        visible: true,
      },
    ],
    selectedMetricBoxes: [
      "Spend",
      "Attributed Rev",
      "ROAS",
      "CAC",
      "LTV CAC",
      "LTV ROAS",
    ],
  },
};
