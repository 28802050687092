import React, {
  Reducer,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { v4 as uuidv4 } from "uuid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import {
  createRowCache,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  Sorting,
  SortingDirection,
  SortingState,
  VirtualTableState,
} from "@devexpress/dx-react-grid";
import {
  TableHeaderRowCell,
  TableHeaderRowContent,
  TableHeaderRowSortLabel,
} from "@pages/sales/tab-table-section/table/table-components";
import {
  FetchOrderSummary,
  FetchOrderSummaryVariables,
} from "@nb-api-graphql-generated/FetchOrderSummary";

import { FETCH_ORDER_SUMMARY } from "@pages/settings/queries";
import { QueryLazyOptions } from "@apollo/client";
import { useUser } from "@components/user-context";
import { initialState, OrderAction, OrderTableState, reducer } from "./reducer";
import { VIRTUAL_PAGE_SIZE } from "./constant";

import {
  AttributedEditor,
  CustomerTagsEditor,
  DiscountCodesEditor,
  OrderIdEditor,
  OrderNumberOfOriginalItemsEditor,
  OrderTagsEditor,
  OrderTouchpointsEditor,
  OrderTypesEditor,
  OrderValueEditor,
  ProductEditor,
  SubscriptionsEditor,
} from "./editors";
import {
  AttributedDataFormatter,
  CurrencyDataFormatter,
  CustomerTagsDataFormatter,
  CustomerTypeDataFormatter,
  dateComparator,
  DateTypeProvider,
  DiscountCodesDataFormatter,
  OrderIdDataFormatter,
  OrderTouchpointsFormatter,
  ProductsPurchasedDataFormatter,
  SubscriptionsDataFormatter,
  TagsDataFormatter,
  ValueDataFormatter,
} from "./formatters";
import { useOrdersPageFilters } from "@hooks/use-orders-filters";
import {
  useOrderPageSortingAtom,
  useOrderTableLoading,
} from "@/atoms/order-page-atoms";
import { SubscriptionTypeEnum } from "@nb-api-graphql-generated/global-types";

export type OrderTableProps = {
  totalCount: number;
  isLoading: boolean;
  loadOrders: (
    options?: QueryLazyOptions<FetchOrderSummaryVariables> | undefined,
  ) => void;
  limit: number;
  orders: {
    orderId: string;
    date: Date;
    orderValue: number;
    numberOfOriginalItems: number;
    customerType: string;
    numberOfTouchpoints: number;
    isAttributed: string;
    tags: string[];
    customerTags: string[];
    discountCodes: string[];
    productsPurchased: string[];
    subscriptionType: SubscriptionTypeEnum[];
  }[];
};

export function OrdersTable() {
  const { user } = useUser();
  const [, setOrderQueryLoading] = useOrderTableLoading();

  // filter options
  const filterOptions = useOrdersPageFilters();

  const [state, dispatch] = useReducer<Reducer<OrderTableState, OrderAction>>(
    reducer,
    initialState,
  );
  const [sorting, setSorting] = useOrderPageSortingAtom();

  const handleSorting = (sorting: Sorting[]) => {
    const newSorting = sorting?.[0]
      ? {
          sortingField: sorting?.[0].columnName,
          sortingOrder: sorting?.[0].direction,
        }
      : null;
    setSorting(newSorting);
  };

  const cache = useMemo(() => createRowCache(VIRTUAL_PAGE_SIZE), []);

  useEffect(() => {
    cache.invalidate();
    dispatch({ type: "RESET" });
  }, [cache, filterOptions, sorting]);

  const updateRows = (skip: number, count: number, newTotalCount?: number) => {
    const maxRows = 1_000_000;

    dispatch({
      type: "UPDATE_ROWS",
      payload: {
        skip,
        rows: cache.getRows(skip, count),
        totalCount:
          newTotalCount && newTotalCount < maxRows ? newTotalCount : maxRows,
      },
    });
  };

  const getRemoteRows = (requestedSkip: number, take: number) => {
    dispatch({ type: "START_LOADING", payload: { requestedSkip, take } });
  };

  const [columns] = useState([
    { name: "orderId", title: "Order ID" },
    { name: "date", title: "Date" },
    { name: "orderValue", title: "Order Value" },
    { name: "numberOfOriginalItems", title: "No. of original Items" },
    { name: "customerType", title: "Customer Type" },
    { name: "numberOfTouchpoints", title: "No. of Touchpoints" },
    { name: "isAttributed", title: "Attributed?" },
    { name: "tags", title: "Tags" },
    { name: "customerTags", title: "Customer Tags" },
    ...(user.featureFlags.showSubscriptionOrderTags
      ? [
          {
            name: "subscriptionType",
            title: "Subscription",
          },
        ]
      : []),
    { name: "productsPurchased", title: "Products Purchased" },
    { name: "discountCodes", title: "Discount Codes" },
  ]);

  const loadData = () => {
    const { requestedSkip, take, lastQuery, loading } = state;
    const query = `filterOptions=${JSON.stringify(
      filterOptions,
    )}&requestedSkip=${requestedSkip}&take=${take}`;

    if (query !== lastQuery && !loading) {
      const cached = cache.getRows(requestedSkip, take);
      if (cached.length === take) {
        updateRows(requestedSkip, take);
      } else {
        setOrderQueryLoading(true);
        dispatch({ type: "FETCH_INIT" });

        user.apolloClient
          .query<FetchOrderSummary, FetchOrderSummaryVariables>({
            query: FETCH_ORDER_SUMMARY,
            variables: {
              ...(sorting
                ? {
                    sorting: {
                      sortingField: sorting.sortingField as any,
                      sortingOrder: sorting.sortingOrder as any,
                    },
                  }
                : {}),
              filterOptions,
              limit: take,
              offset: requestedSkip as number,
            },
          })
          .then(
            ({
              data: {
                me: {
                  orders: { data, totalCount: newTotalCount },
                },
              },
            }) => {
              const orders = (data || []).map((order) => {
                const numberOfOriginalItems = order.products.reduce(
                  (acc, curr) => acc + curr.quantity,
                  0,
                );

                return {
                  orderId: order.conversionEventId,
                  date: new Date(order.occurredAt.value),
                  orderValue: order.revenueInDollars,
                  orderNumber: order.orderNumber,
                  numberOfOriginalItems,
                  customerType: order.orderType,
                  numberOfTouchpoints: order.numberOfTouchpoints,
                  isAttributed: order.attributed ? "Yes" : "No",
                  tags: order.orderTags,
                  customerTags: order.customerTags,
                  subscriptionType: order.subscriptionType,
                  productsPurchased: order.products.map((p) => p.title),
                  discountCodes: order.discountCodes,
                };
              });

              cache.setRows(requestedSkip, orders);
              updateRows(requestedSkip, take, newTotalCount);
              setOrderQueryLoading(false);
            },
          )
          .catch(() => dispatch({ type: "REQUEST_ERROR" }));
      }
      dispatch({ type: "UPDATE_QUERY", payload: { lastQuery: query } });
    }
  };

  useEffect(() => loadData());

  const { rows, skip, totalCount, loading } = state;

  return (
    <div className="card h-[43rem] my-4">
      <Grid
        rows={rows}
        columns={columns}
        getRowId={(row) => `${row.orderId}-${uuidv4()}`}
      >
        <FilteringState
          defaultFilters={[]}
          columnExtensions={[{ columnName: "date", filteringEnabled: false }]}
        />
        <IntegratedFiltering />
        <SortingState
          columnExtensions={[
            { columnName: "orderId", sortingEnabled: false },
            { columnName: "customerType", sortingEnabled: false },
            { columnName: "isAttributed", sortingEnabled: false },
            { columnName: "tags", sortingEnabled: false },
            { columnName: "subscriptionType", sortingEnabled: false },
            { columnName: "productsPurchased", sortingEnabled: false },
          ]}
          sorting={
            sorting
              ? [
                  {
                    columnName: sorting?.sortingField,
                    direction: sorting?.sortingOrder as SortingDirection,
                  },
                ]
              : []
          }
          onSortingChange={handleSorting}
        />
        <IntegratedSorting
          columnExtensions={[
            {
              columnName: "date",
              compare: dateComparator,
            },
          ]}
        />

        {/* Custom Cells */}
        <DateTypeProvider for={["date"]} />
        <DataTypeProvider
          for={["subscriptionType"]}
          formatterComponent={SubscriptionsDataFormatter}
          editorComponent={SubscriptionsEditor}
        />
        <DataTypeProvider
          for={["tags"]}
          formatterComponent={TagsDataFormatter}
          editorComponent={OrderTagsEditor}
        />
        <DataTypeProvider
          for={["customerTags"]}
          formatterComponent={CustomerTagsDataFormatter}
          editorComponent={CustomerTagsEditor}
        />
        <DataTypeProvider
          for={["productsPurchased"]}
          formatterComponent={ProductsPurchasedDataFormatter}
          editorComponent={ProductEditor}
        />
        <DataTypeProvider
          for={["discountCodes"]}
          formatterComponent={DiscountCodesDataFormatter}
          editorComponent={DiscountCodesEditor}
        />
        <DataTypeProvider
          for={["orderValue"]}
          formatterComponent={CurrencyDataFormatter}
          editorComponent={OrderValueEditor}
        />
        <DataTypeProvider
          for={["isAttributed"]}
          formatterComponent={AttributedDataFormatter}
          editorComponent={AttributedEditor}
        />
        <DataTypeProvider
          for={["customerType"]}
          formatterComponent={CustomerTypeDataFormatter}
          editorComponent={OrderTypesEditor}
        />

        <DataTypeProvider
          for={["orderId"]}
          formatterComponent={OrderIdDataFormatter}
          editorComponent={OrderIdEditor}
        />
        <DataTypeProvider
          for={["numberOfTouchpoints"]}
          formatterComponent={OrderTouchpointsFormatter}
          editorComponent={OrderTouchpointsEditor}
        />
        <DataTypeProvider
          for={["numberOfOriginalItems"]}
          formatterComponent={ValueDataFormatter}
          editorComponent={OrderNumberOfOriginalItemsEditor}
        />

        <VirtualTableState
          infiniteScrolling
          loading={loading}
          totalRowCount={totalCount}
          pageSize={VIRTUAL_PAGE_SIZE}
          skip={skip}
          getRows={getRemoteRows}
        />
        <VirtualTable height="43rem" />

        <TableColumnResizing
          defaultColumnWidths={[
            { columnName: "orderId", width: 100 },
            { columnName: "date", width: 100 },
            { columnName: "orderValue", width: 175 },
            { columnName: "numberOfOriginalItems", width: 175 },
            { columnName: "customerType", width: 150 },
            { columnName: "numberOfTouchpoints", width: 175 },
            { columnName: "isAttributed", width: 100 },
            { columnName: "tags", width: 120 },
            { columnName: "customerTags", width: 120 },
            { columnName: "subscriptionType", width: 120 },
            { columnName: "productsPurchased", width: 120 },
            { columnName: "discountCodes", width: 120 },
          ]}
        />

        <TableHeaderRow
          showSortingControls
          contentComponent={TableHeaderRowContent}
          sortLabelComponent={TableHeaderRowSortLabel}
          cellComponent={TableHeaderRowCell}
        />
        <TableFilterRow />
      </Grid>
    </div>
  );
}
