import React, { useState } from "react";
import { serializeError } from "serialize-error";

interface UpdateLoginCustomerIdProps {
  updateConnection: any;
  loginCustomerId: string;
}

enum RequestStatus {
  NO_REQUEST,
  LOADING,
  SUCCESSFUL,
  HAS_ERROR,
}

export default function UpdateLoginCustomerId({
  loginCustomerId,
  updateConnection,
}: UpdateLoginCustomerIdProps) {
  const [newLoginId, setNewLoginId] = useState(loginCustomerId);
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.NO_REQUEST);
  const [error, setError] = useState<string>("");

  const onSave = async () => {
    setStatus(RequestStatus.LOADING);
    const result = await updateConnection({
      login_customer_id: newLoginId,
    });

    if (result.errors) {
      setStatus(RequestStatus.HAS_ERROR);
      setError(JSON.stringify(serializeError(result.error)));
    } else {
      setStatus(RequestStatus.SUCCESSFUL);
    }
  };

  const renderErrorStatus = () => {
    if (status === RequestStatus.HAS_ERROR) {
      return (
        <div className="alert alert-danger mb-3" role="alert">
          An error occurred: &ldquo;{error}&rdquo;
        </div>
      );
    }
    return null;
  };

  const renderButtonContent = () => {
    return status === RequestStatus.LOADING ? (
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      />
    ) : (
      "Save"
    );
  };

  const renderUpdateLoginIdButton = () => (
    <div style={{ display: "flex", width: "100%", justifyContent: "start" }}>
      <input
        className="form-control"
        type="text"
        placeholder="Login Account ID"
        onChange={(e) => setNewLoginId(e.target.value)}
        value={newLoginId}
        style={{ marginRight: "1em" }}
        disabled={status === RequestStatus.LOADING}
      />
      <button
        className="btn btn-sm btn-primary"
        onClick={onSave}
        disabled={status === RequestStatus.LOADING}
      >
        {renderButtonContent()}
      </button>
    </div>
  );

  return (
    <>
      <label>Update your login customer id (parent MCC account)</label>
      {renderErrorStatus()}
      {renderUpdateLoginIdButton()}
    </>
  );
}
