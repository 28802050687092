import React from "react";
import { ConnectionType } from "@nb-api-graphql-generated/global-types";
import {
  getAccountIdNameFromConnectionType,
  getConnectionValidationKeysFromConnectionType,
} from "./helpers/graphql-helpers";
import LoadingButton from "@components/loading-button";
import { ApolloError } from "@apollo/client";

interface MutationMetadata {
  loading: boolean;
  error?: ApolloError;
}
interface DeleteConnectionButtonProps {
  updateConnection: any;
  updateInfo: MutationMetadata;
  destroyConnection: any;
  destroyInfo: MutationMetadata;
  additionalAccounts: Array<string>;
  accountId: string;
  connectionType: ConnectionType;
}

export default function DeleteConnectionButton({
  updateConnection,
  updateInfo,
  destroyConnection,
  destroyInfo,
  additionalAccounts,
  accountId,
  connectionType,
}: DeleteConnectionButtonProps) {
  const { loading: updateLoading, error: updateError } = updateInfo;
  const { loading: destroyLoading, error: destroyError } = destroyInfo;

  const getCustomerIdFromId = (accountId: string): string => {
    // Note: Don't love this pattern, but accountId has been JSON-stringified
    // conditionally based on whether it was initially a string or not.
    // The best way I can see to reverse this is via the method below.
    // Note that JSON.parse will throw an error if the ID has a letter in it
    // and it was just initially a string

    try {
      const parsedAccountId = JSON.parse(accountId);
      if (typeof parsedAccountId === "object") {
        // This should only be for google adwords
        const keyNameForAccountId =
          getConnectionValidationKeysFromConnectionType(connectionType);
        const accountId = parsedAccountId[keyNameForAccountId];
        if (accountId === undefined) {
          // make sure we don't make database mutations based on bad frontend logic
          throw new Error("Could not find the associated account id");
        }
        return accountId;
      }

      return parsedAccountId.toString();
    } catch {
      return accountId;
    }
  };

  const renderErrorStatus = () => {
    if (updateError || destroyError) {
      return (
        <div className="alert alert-danger mb-3" role="alert">
          An error occurred: &ldquo;
          {`${JSON.stringify(updateError || destroyError)}`}
          &rdquo;
        </div>
      );
    }
    return null;
  };

  const getFilteredAdditionalAccounts = (
    accountToRemove: string,
  ): Array<string> => {
    return additionalAccounts.filter(
      (additionalAccount) => additionalAccount !== accountToRemove,
    );
  };

  const deleteAccount = (
    accountId: string,
    additionalAccounts: Array<string>,
  ) => {
    const customerId = getCustomerIdFromId(accountId);
    const isAdditionalAccount = additionalAccounts.includes(customerId);
    const hasAdditionalAccounts = additionalAccounts.length > 0;
    if (isAdditionalAccount) {
      // remove extraAccount from metadata array of extra accounts
      const newAdditionalAccountList =
        getFilteredAdditionalAccounts(customerId);
      const updatedConnectionData = {
        metadata: {
          extraAccountIds: newAdditionalAccountList,
        },
      };
      updateConnection(updatedConnectionData);
    } else {
      // replace an extra account id with the row's primary account id
      if (hasAdditionalAccounts) {
        const accountToSwap = additionalAccounts[0];
        const newAdditionalAccountList =
          getFilteredAdditionalAccounts(accountToSwap);
        const accountIdColumnName =
          getAccountIdNameFromConnectionType(connectionType);
        const updatedConnectionData: any = {
          metadata: {
            extraAccountIds: newAdditionalAccountList,
          },
        };
        updatedConnectionData[accountIdColumnName] = accountToSwap;
        updateConnection(updatedConnectionData);
      } else {
        // no extra account exists, soft-delete (set force_reconnect to true)
        destroyConnection();
      }
    }
  };

  return (
    <>
      {renderErrorStatus()}
      <LoadingButton
        className="btn btn-sm btn-danger"
        onClick={() => deleteAccount(accountId, additionalAccounts)}
        isLoading={updateLoading || destroyLoading}
        text="Delete"
      />
    </>
  );
}
