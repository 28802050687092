import { gql } from "@apollo/client";
import Interweave from "interweave";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { User, useUser } from "@components/user-context";
import {
  ExcludeSubjectItemFromAlert,
  ExcludeSubjectItemFromAlertVariables,
} from "@nb-api-graphql-generated/ExcludeSubjectItemFromAlert";
import { AlertRunResultStatus } from "@nb-api-graphql-generated/global-types";
import {
  UpdateAlertRunResult,
  UpdateAlertRunResultVariables,
} from "@nb-api-graphql-generated/UpdateAlertRunResult";
import {
  AlarmConditionResultRow,
  AlarmConditionV2,
  AlertPreviewRowV2,
  SimpleAlarmConditionV2,
  SubjectItem,
} from "@north-beam/nb-common";
import { Breakdown } from "@north-beam/nb-common";
import {
  FixedDateRange,
  formatNumberExact,
  jsonHash,
  shiftISODate,
  slugify,
  todayLocalAsISODate,
} from "@north-beam/nb-common";
import { isLeafNode } from "@north-beam/nb-common";
import { logEvent } from "@utils/analytics";
import { useNorthbeamMutation } from "@utils/hooks";
import { makeReportStateQuery as makeReportStateQueryAdObject } from "@pages/objects/report-state";
import { makeReportBodyStateQuery as makeReportStateQuerySales } from "@pages/sales/report-body-control";
import { flattenAlarmCondition } from "./utils";

interface AlertRunId {
  configId: string;
  runAt: string;
}

export interface AlertRunResult {
  context: AlertPreviewRowV2;
  status?: AlertRunResultStatus;
}

export function AlertRunTable(props: {
  results: AlertRunResult[];
  subjectItemToColor?: Record<string, string>;
  alarmCondition: AlarmConditionV2;
  status?: string;
  runAt: string;
  id?: AlertRunId;
  excludedSubjectItemIds?: Record<string, true>;
}) {
  const { user } = useUser();
  const {
    results,
    id,
    alarmCondition,
    subjectItemToColor,
    runAt,
    excludedSubjectItemIds,
  } = props;

  const header = (
    <thead className="thead-light">
      <tr>
        {subjectItemToColor && (
          <th className="text-center" scope="col">
            <i className="fas fa-circle"></i>
          </th>
        )}
        <th className="text-left" scope="col">
          Alarm?
        </th>
        <th className="text-left" scope="col">
          Subject
        </th>
        <th className="text-left" scope="col">
          Metrics
        </th>
        <th className="text-left" scope="col">
          Other details
        </th>
        {id && (
          <th className="text-center" scope="col">
            Actions
          </th>
        )}
      </tr>
    </thead>
  );

  const rows = _.chain(results)
    .map(makeRow)
    .sortBy("name")
    .sortBy("sortOrder")
    .map("row")
    .value();

  return (
    <table className="table table-bordered mb-3" style={{ fontSize: "14px" }}>
      {header}
      <tbody>{rows}</tbody>
    </table>
  );

  function makeRow(result: AlertRunResult) {
    const { context, status } = result;
    const {
      subjectItem,
      subjectItemName,
      resultRow,
      conditionTriggered,
      averageDailySpend,
      skipReason,
      numAdObjectsFound,
      numAdObjectsSkipped,
    } = context;

    const isSimulatePage = !status;
    const isAlert = !!conditionTriggered;

    let alarmStatus: "OK" | "ALARM" | "SKIPPED" = "OK";
    if (skipReason) {
      alarmStatus = "SKIPPED";
    } else if (conditionTriggered) {
      alarmStatus = "ALARM";
    }

    let rowClass = "table-default";
    let sortOrder = 4;
    if (alarmStatus === "SKIPPED") {
      rowClass = "table-info";
      sortOrder = 3;
    } else if (isAlert && (status === "new" || isSimulatePage)) {
      rowClass = "table-warning";
      sortOrder = 2;
    }

    let icon: React.ReactNode = null;
    if (subjectItemToColor) {
      icon = (
        <i
          className="fas fa-circle"
          style={{ color: subjectItemToColor[jsonHash(subjectItem.key)] }}
        ></i>
      );
    }

    const rc = <FormatMetricColumn resultRow={resultRow} />;
    const od: React.ReactNodeArray = [];

    if (skipReason) {
      od.push(
        <li key="skipReason">Skipped: {translateSkipReason(skipReason)}</li>,
      );
    }

    if (
      typeof averageDailySpend !== "undefined" &&
      averageDailySpend !== null
    ) {
      od.push(
        <li key="spend">
          Average daily spend: {formatNumberExact(averageDailySpend, "dollars")}
        </li>,
      );
    }

    // HACK: due to the way numAdObjectsSkipped is counted, it's possible that
    // HACK: there are more active ads than total ads (i.e. skipped ads < 0).
    // HACK: this hack makes that accounting issue not show up in the UI.
    if (
      typeof numAdObjectsSkipped !== "undefined" &&
      numAdObjectsSkipped !== null &&
      numAdObjectsSkipped > 0 &&
      numAdObjectsFound
    ) {
      od.push(
        <li key="numSkip">
          {numAdObjectsFound - numAdObjectsSkipped} active campaigns
        </li>,
      );
    }

    let actionRow: React.ReactNode = null;
    if (id) {
      actionRow = (
        <td align="center">
          <Buttons
            id={id}
            subjectItem={subjectItem.key}
            isAcked={status === "acked"}
            isAlert={isAlert}
            objectHashes={excludedSubjectItemIds ?? {}}
          />
        </td>
      );
    }

    const simpleAlarmCondition = flattenAlarmCondition(alarmCondition)[0];

    const link = subjectItemToLinkSuffix(
      user,
      simpleAlarmCondition,
      subjectItem,
      runAt,
    );

    const otherDetails = od.length > 0 ? <ul>{od}</ul> : <span>—</span>;

    return {
      row: (
        <tr className={rowClass} key={jsonHash(subjectItem)}>
          {icon && <td align="center">{icon}</td>}
          <td align="left">{alarmStatus}</td>
          <td align="left">
            <SubjectItemName
              name={subjectItemName}
              subjectItem={subjectItem}
              link={link}
            />
          </td>
          <td align="left">{rc}</td>
          <td align="left">{otherDetails}</td>
          {actionRow}
        </tr>
      ),
      sortOrder,
      name: subjectItem.name,
    };
  }
}

function FormatMetricColumn({
  resultRow,
}: {
  resultRow: AlarmConditionResultRow;
}) {
  if (isLeafNode(resultRow)) {
    const {
      conditionTriggered,
      currentValueComputed,
      isMissing,
      normalityBounds,
      referenceValueComputed,
      metric,
    } = resultRow;

    let latterPart = "(value missing)";
    if (!isMissing) {
      const currentFormatted = formatNumberExact(
        Number(currentValueComputed),
        metric.format,
      );
      let description: string;
      const [lo, hi] = normalityBounds;
      if (lo === "-Infinity") {
        const directionText = conditionTriggered ? "greater than" : "less than";
        const referenceFormatted = formatNumberExact(
          Number(referenceValueComputed),
          metric.format,
        );
        description = `${directionText} ${referenceFormatted}`;
      } else if (hi === "Infinity") {
        const directionText = conditionTriggered ? "less than" : "greater than";
        const referenceFormatted = formatNumberExact(
          Number(referenceValueComputed),
          metric.format,
        );
        description = `${directionText} ${referenceFormatted}`;
      } else {
        const directionText = conditionTriggered
          ? "outside the range"
          : "within the range";
        const rangeFormatted = normalityBounds
          .map((v) => formatNumberExact(Number(v), metric.format))
          .join(", ");
        description = `${directionText} [${rangeFormatted}]`;
      }

      latterPart = `<b>${currentFormatted}</b> (${description})`;
    }

    const circle = (
      <i
        className="fas fa-circle"
        style={{ color: conditionTriggered ? "var(--blue)" : "var(--gray)" }}
      ></i>
    );

    return (
      <span>
        {circle} {metric.name}: <Interweave content={latterPart} />
      </span>
    );
  }

  const { children, combinator } = resultRow;

  return (
    <>
      {combinator === "and" ? "All" : "Any"} of the following:
      <ul className="list-unstyled">
        {children.map((child) => (
          <li key={jsonHash(child)}>
            <FormatMetricColumn resultRow={child} />
          </li>
        ))}
      </ul>
    </>
  );
}

interface ButtonsProps {
  id: AlertRunId;
  subjectItem: SubjectItem;
  isAcked: boolean;
  isAlert: boolean;
  objectHashes: Record<string, boolean>;
}

function Buttons(props: ButtonsProps) {
  const { id, subjectItem, isAcked, isAlert, objectHashes } = props;
  const [updateAlertRunResult, { loading: updateLoading }] =
    useNorthbeamMutation<UpdateAlertRunResult, UpdateAlertRunResultVariables>(
      UPDATE_ALERT_RUN_RESULT,
    );

  const [excludeSubjectItemFromAlert, { loading: excludeLoading }] =
    useNorthbeamMutation<
      ExcludeSubjectItemFromAlert,
      ExcludeSubjectItemFromAlertVariables
    >(EXCLUDE_SUBJECT_ITEM_FROM_ALERT);

  const loading = updateLoading || excludeLoading;

  const dismiss = React.useCallback(() => {
    logEvent("Dismiss Notification", {
      "Alert ID": id.configId,
      "Alert Run At": id.runAt,
    });
    updateAlertRunResult({
      variables: {
        ...id,
        subjectItem,
        status: AlertRunResultStatus.acked,
      },
    });
  }, [updateAlertRunResult, id, subjectItem]);

  const exclude = React.useCallback(() => {
    logEvent("Exclude Subject Item from Alert", {
      "Subject Item": subjectItem,
      "Alert ID": id.configId,
      "Alert Run At": id.runAt,
    });
    const today = todayLocalAsISODate();
    excludeSubjectItemFromAlert({
      variables: {
        configId: id.configId,
        subjectItem: subjectItem,
        today,
      },
    });
  }, [excludeSubjectItemFromAlert, id, subjectItem]);

  const snooze = React.useCallback(() => {
    logEvent("Snooze Subject Item from Alert", {
      "Subject Item": subjectItem,
      "Alert ID": id.configId,
      "Alert Run At": id.runAt,
    });
    const today = todayLocalAsISODate();
    excludeSubjectItemFromAlert({
      variables: {
        configId: id.configId,
        subjectItem: subjectItem,
        until: shiftISODate(today, 7),
        today,
      },
    });
  }, [excludeSubjectItemFromAlert, id, subjectItem]);

  const buttonStyle = React.useMemo(() => ({ width: "7em" }), []);

  const buttons: React.ReactNodeArray = [];
  const isNewAlert = !isAcked && isAlert;
  if (isNewAlert) {
    buttons.push(
      <button
        key="dismiss"
        disabled={loading}
        className="btn btn-sm btn-primary my-1"
        onClick={dismiss}
        style={buttonStyle}
      >
        Dismiss
      </button>,
    );
  }

  const key = jsonHash(_.omit(subjectItem, "name"));
  if (!objectHashes[key]) {
    buttons.push(
      <button
        key="snooze"
        disabled={loading}
        className="btn btn-sm btn-secondary my-1"
        onClick={snooze}
        style={buttonStyle}
      >
        <span>Snooze&nbsp;(7d)</span>
      </button>,
    );
    buttons.push(
      <button
        key="exclude"
        disabled={loading}
        className="btn btn-sm btn-danger my-1"
        onClick={exclude}
        style={buttonStyle}
      >
        <span>Exclude</span>
      </button>,
    );
  }

  return <div style={{ width: "8em" }}>{buttons}</div>;
}

function subjectItemToLinkSuffix(
  user: User,
  alarmCondition: SimpleAlarmConditionV2,
  subjectItem: SubjectItem,
  runAt: string,
): string {
  const metricId: string = alarmCondition.metric.id;
  const breakdowns: Breakdown[] = [];
  if (subjectItem.type === "grouped") {
    for (const { key, value } of subjectItem.groups) {
      breakdowns.push({ key, values: [value] });
    }
  }

  const { windowEndOffsetDays, windowStartOffsetDays } =
    alarmCondition.currentValue;
  const endDate = shiftISODate(runAt, -windowStartOffsetDays);
  const startDate = shiftISODate(runAt, -windowEndOffsetDays);
  const dateRange: FixedDateRange = {
    type: "fixed",
    endDate,
    startDate,
  };

  const attributionModel = metricId.match(/^ga[A-Z]/)
    ? "last_touch"
    : metricId.match(/^nb[A-Z]/)
    ? "northbeam_custom"
    : "northbeam_custom";

  if (subjectItem.type === "individual") {
    const search = makeReportStateQueryAdObject({
      dateRange,
      attributionModel,
      nbAccountingMode: "accrual",
      attributionWindow: "1",
      compareDateRange: "lastPeriod",
      timeGranularity: "daily",
    }).toString();
    return `objects/${slugify(subjectItem.ad_key)}?${search}`;
  } else {
    const params = makeReportStateQuerySales({
      attributionModel: attributionModel,
      attributionWindow: "1",
      nbAccountingMode: "accrual",
      columnSet: "overview",
      breakdowns,
      granularity: "campaign",
      timeGranularity: "daily",
      dateRange,
      compareDateRange: "lastPeriod",
    });
    params.set("attributionModel", attributionModel);
    return user.pathFromRoot(`/sales?${params.toString()}`);
  }
}

function SubjectItemName({
  subjectItem,
  name,
  link,
}: {
  subjectItem: SubjectItem;
  name: string;
  link?: string;
}) {
  const { user } = useUser();
  let inner = <span>{name}</span>;

  if (subjectItem.type === "grouped") {
    const groups = subjectItem.groups;
    inner = (
      <div>
        Campaigns matching all of the following:
        <ul className="mb-1">
          {groups.map(({ key, value }) => (
            <li key={key}>
              <b>{key}</b> is <b>{value}</b>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (link) {
    link = user.pathFromRoot(link);
    if (subjectItem.type === "grouped") {
      inner = (
        <div className="mb-2">
          {inner}
          <Link to={link}>See report</Link>
        </div>
      );
    } else {
      inner = <Link to={link}>{inner}</Link>;
    }
  }
  return inner;
}

const UPDATE_ALERT_RUN_RESULT = gql`
  mutation UpdateAlertRunResult(
    $configId: ID!
    $runAt: String!
    $subjectItem: JSONObject!
    $status: AlertRunResultStatus
  ) {
    updateAlertRunResult(
      configId: $configId
      runAt: $runAt
      subjectItem: $subjectItem
      status: $status
    ) {
      id
      status
    }
  }
`;

const EXCLUDE_SUBJECT_ITEM_FROM_ALERT = gql`
  mutation ExcludeSubjectItemFromAlert(
    $configId: ID!
    $subjectItem: JSONObject!
    $today: String!
    $until: String
  ) {
    excludeSubjectItemFromAlertConfig(
      configId: $configId
      subjectItem: $subjectItem
      until: $until
    ) {
      id
      excludedSubjectItems(exclusionEndsAfter: $today) {
        subjectItem {
          id
          key
          name
        }
        excludedUntil
      }
    }
  }
`;

export function translateSkipReason(skipReason: string) {
  if (skipReason === "ALL_AD_OBJECTS_NOT_ACTIVE") {
    return `All monitored campaigns inactive`;
  } else if (skipReason === "EXCLUDED_FROM_ALERTING") {
    return `Manually snoozed or excluded from alerting`;
  } else if (skipReason === "NO_COMPARISON_VALUE") {
    return `Unable to compute a comparison value`;
  } else if (skipReason === "NO_PRESENT_VALUE") {
    return `Unable to compute a present value`;
  } else if (skipReason === "METRIC_VALUE_MISSING") {
    return `Either current value or reference value is missing for this day`;
  } else {
    return `${skipReason}`;
  }
}
