import { AnimatePresence, motion } from "framer-motion";
import React, { PropsWithChildren, useState } from "react";

export interface AccordionProps {
  title: string;
  isExpandedByDefault?: boolean;
}

export function Accordion({
  title,
  isExpandedByDefault,
  children,
}: PropsWithChildren<AccordionProps>) {
  const [isExpanded, setExpanded] = useState(isExpandedByDefault || false);

  return (
    <div className="border-b border-gray-100 mx-8">
      <h4
        className="text-[1rem] font-semibold m-0 flex items-center pt-5 pb-5 cursor-pointer"
        onClick={() => setExpanded(!isExpanded)}
      >
        <span className="flex-1">{title}</span>
        <motion.i
          initial={false}
          animate={{ rotate: isExpanded ? 0 : 180 }}
          transition={{ duration: 0.2 }}
          className="fa-solid fa-chevron-up"
        ></motion.i>
      </h4>

      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ ease: "easeOut", duration: 0.2 }}
            className="overflow-hidden px-[2rem] mx-[-2rem]"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
