import { HTMLTooltip } from "@components/tooltip-container";
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from "@devexpress/dx-react-grid";
import React from "react";
import ChangeFractionDisplay from "../table-components/change-fraction";
import { ColumnDef } from "../table-utils";

export const MetricDataFormatter = ({
  row,
  column,
  value,
}: DataTypeProvider.ValueFormatterProps) => {
  const metric = (column as any)?.metric;
  let changeFractionArea = <small>&nbsp;</small>;
  if (metric && !metric?.hideComparisonFromTableCell && row.changeFractions) {
    changeFractionArea = (
      <ChangeFractionDisplay
        changeFraction={row.changeFractions[metric.id]}
        isPositiveChangeGood={metric.isPositiveChangeGood}
        muted={true}
      />
    );
  }

  let mainDisplay: any =
    row?.display?.[(column as ColumnDef).id] ?? value ?? "";
  const cellHoverHTML = row?.cellHoverHTML?.[(column as ColumnDef)?.id];
  if (cellHoverHTML) {
    mainDisplay = (
      <HTMLTooltip html={cellHoverHTML} noInfoCircle mouseEnterDelay={0.1}>
        <u className="nb-cell-hover">{mainDisplay}</u>
      </HTMLTooltip>
    );
  }

  return (
    <div className="text-right">
      <div>{mainDisplay}</div>
      <div className="-mt-1">{changeFractionArea}</div>
    </div>
  );
};

export const MetricTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={MetricDataFormatter} {...props} />
);
