import { GetCampaign_me_adObject } from "@nb-api-graphql-generated/GetCampaign";
import { NB_PLATFORM } from "@north-beam/nb-common";

import React from "react";
import Select from "react-select";
import { SUPPORTED_PLATFORMS } from "./flyout";
import { BidBudgetSection } from "./shared/bid-budget";
import { CreativeAssetSection } from "./shared/creative-asset";
import { RawJsonSection } from "./shared/raw-json";

interface AdDetailProps {
  adObject: GetCampaign_me_adObject;
  refetchData: (id: any) => void;
}

export const AdDetail = ({ adObject, refetchData }: AdDetailProps) => {
  let currentAdIndex = 0;
  let campaignName = "";
  let adsetName = "";

  if (adObject.topLevel?.rows?.length) {
    campaignName =
      adObject.topLevel.rows[0].searchableStrings?.campaignName ?? "";
    adsetName = adObject.topLevel.rows[0].searchableStrings?.adsetName ?? "";
  } else if (adObject.hierarchy) {
    campaignName = adObject.hierarchy.name;
  }

  const adsets = (adObject.hierarchy?.adsets || []).filter((adset: any) => {
    return adset.name === adsetName;
  });

  let adOptions = [];
  if (adsets.length) {
    adOptions = adsets[0].ads.map((ad: any, index: number) => {
      if (ad.id === adObject.id) {
        currentAdIndex = index;
      }
      return {
        value: ad.id,
        label: `Ad: ${ad.name}`,
      };
    });
  }

  return (
    <>
      {campaignName && (
        <div className="bg-[#E5E5E5] px-2 py-2 break-words">
          Campaign: {campaignName}
        </div>
      )}
      <div className="my-3" />
      {adsetName && (
        <div className="bg-[#E5E5E5] px-2 py-2 break-words">
          Ad Set: {adsetName}
        </div>
      )}
      {adOptions.length ? (
        <div className="py-2">
          <Select
            options={adOptions}
            value={adOptions[currentAdIndex]}
            onChange={refetchData}
          />
        </div>
      ) : (
        <div className="bg-[#E5E5E5] py-2 px-2 py-2 break-words">
          Ad: {adObject.name}
        </div>
      )}
      <BidBudgetSection statusLine={adObject.statusLine} />
      {(adObject.nbtPlatformID === NB_PLATFORM.FACEBOOK ||
        adObject.nbtPlatformID === NB_PLATFORM.GOOGLE ||
        adObject.nbtPlatformID === NB_PLATFORM.TIKTOK ||
        adObject.nbtPlatformID === NB_PLATFORM.SNAPCHAT) && (
        <CreativeAssetSection adObject={adObject} />
      )}
      {!SUPPORTED_PLATFORMS.includes(adObject.nbtPlatformID) && (
        <RawJsonSection metaData={adObject.metadata} />
      )}
    </>
  );
};
