import React from "react";
import { Select } from "@shared/selects";
import { Annotation } from "@features/custom-annotations";
import { compareAsc, compareDesc } from "date-fns";

interface AnnotationSortSelectProps {
  value: SortValue | null;
  onChange: (value: SortValue | null) => void;
}

const SORT_OPTIONS = [
  {
    label: "Chronological",
    value: "chronological",
    sort: (annotations: Annotation[]) =>
      annotations.sort((a, b) =>
        compareAsc(new Date(a.annotatedDate), new Date(b.annotatedDate)),
      ),
  },
  {
    label: "Recently updated",
    value: "recently_updated",
    sort: (annotations: Annotation[]) =>
      annotations.sort((a, b) =>
        compareDesc(new Date(a.updatedAt), new Date(b.updatedAt)),
      ),
  },
];

export type SortValue = (typeof SORT_OPTIONS)[number]["value"];
export const INITIAL_SORT: SortValue = "chronological";
export const getSort = (
  value: SortValue | null,
): (typeof SORT_OPTIONS)[number]["sort"] => {
  const sort = SORT_OPTIONS.find((option) => option.value === value)?.sort;
  if (!sort) {
    throw new Error(`Invalid sort value: ${value}`);
  }
  return sort;
};

export const AnnotationSortSelect = ({
  value,
  onChange,
}: AnnotationSortSelectProps) => (
  <Select
    className="w-52"
    label="Sort by"
    value={value}
    onChange={onChange}
    options={SORT_OPTIONS}
  />
);
