import React, { PureComponent } from "react";
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import loadingScreen from "@assets/loading-screen-no-text.gif";
import classNames from "classnames";

interface LoadingPanelProps {
  isLoading: boolean;
}

export class LoadingPanel extends PureComponent<LoadingPanelProps> {
  render() {
    const { isLoading } = this.props;

    return (
      <Plugin name="LoadingPanel">
        <Template name="root">
          <div className="relative">
            <TemplatePlaceholder />
            <div
              className={classNames(
                !isLoading && "hidden",
                "absolute flex-center h-full w-full top-0 bg-[rgba(200,200,200,0.5)] z-10",
              )}
            >
              <img
                src={loadingScreen}
                alt="Loading screen gif"
                className="w-3/4 max-w-xl"
              />
            </div>
          </div>
        </Template>
      </Plugin>
    );
  }
}
