import React from "react";
import { IconButton } from "@shared/buttons";
import { useAnnotationParam } from "./hooks/use-annotation-param";
import { usePrettyLogEventPage } from "@utils/analytics";

interface AnnotationsFlyoutButtonProps {
  dateRange: { startDate: string; endDate: string };
}

export const AnnotationsFlyoutButton = ({
  dateRange,
}: AnnotationsFlyoutButtonProps) => {
  const { listDateRangeAnnotations } = useAnnotationParam();
  const prettyLogEventPage = usePrettyLogEventPage();
  const openAnnotationsFlyout = () => {
    listDateRangeAnnotations(dateRange);
    prettyLogEventPage({
      domain: "Annotations",
      action: "onClick",
      additionalActionInfo: "Notes button on graph",
      data: { dateRange },
    });
  };

  return (
    <IconButton
      onClick={openAnnotationsFlyout}
      variant="secondary"
      leftIcon={<i className="fa-solid fa-message" />}
    >
      Notes
    </IconButton>
  );
};
